import React from 'react'
import I18n from 'i18n'
import SyntaxHighlighter from 'react-syntax-highlighter'
import ReactMarkdown from 'react-markdown'

const DisplayData = ({ data }) => {
  if ('image/png' in data) {
    const img = `data:image/png;base64,${data['image/png']}`
    return <img className='responsive-img jupyter-figure' src={img} />
  }
  if ('text/markdown' in data) {
    return <ReactMarkdown source={Array.isArray(data['text/markdown']) ? data['text/markdown'].join('') : data['text/markdown']} />
  }
  if ('text/plain' in data) {
    return <div className='jupyter-plaintext'>{Array.isArray(data['text/plain']) ? data['text/plain'].join('') : data['text/plain']}</div>
  }

  console.log('unsupported format for DisplayData')
  return <></>
}

// text === stdout || stderr so we render as bash
const Stream = ({ text }) => {
  return (
    <SyntaxHighlighter language='bash'>
      {Array.isArray(text) ? text.join('') : text}
    </SyntaxHighlighter>
  )
}

const JupyterError = ({ ename, evalue, traceback }) => {
  return (
    (
      <SyntaxHighlighter language='bash'>
        {ename + '\n'}
        {evalue + '\n'}

        {Array.isArray(traceback) ? traceback.join('') : traceback}
      </SyntaxHighlighter>
    )
  )
}

/* eslint-disable camelcase */
const CodeCell = ({ source, outputs, execution_count, language }) => {
  return (
    <div className='row'>
      <div className='col s12 l1 jupyter-execution-count'>{I18n.t('jupyterhub.in')} [{execution_count}]:</div>
      <div className='col s12 l11'>
        <SyntaxHighlighter language={language}>
          {Array.isArray(source) ? source.join('') : source}
        </SyntaxHighlighter>
      </div>
      <div className='col s12 l1 jupyter-execution-count'>{outputs.length > 0 && (`${I18n.t('jupyterhub.out')} [${execution_count}]:`)}</div>
      {outputs.map((output, idx) => (
        <div key={idx} className={`jupyter-output col s12 ${idx === 0 ? 'l11' : 'l12'}`}>
          {output.output_type === 'display_data' && (<DisplayData {...output} />)}
          {output.output_type === 'stream' && (<Stream {...output} />)}
          {output.output_type === 'error' && (<JupyterError {...output} />)}
        </div>
      ))}
    </div>
  )
}
/* eslint-enable camelcase */

const MarkdownCell = ({ source }) => {
  return <ReactMarkdown source={Array.isArray(source) ? source.join('') : source} />
}

const RawCell = ({ source }) => {
  return (
    <SyntaxHighlighter language='plaintext'>
      {Array.isArray(source) ? source.join('') : source}
    </SyntaxHighlighter>
  )
}

const Cell = ({ cell, language }) => {
  switch (cell.cell_type) {
    case 'raw': return <RawCell {...cell} />
    case 'code': return <CodeCell language={language} {...cell} />
    case 'markdown': return <MarkdownCell {...cell} />
  }
}

const NotebookView = ({ data }) => {
  if (!data) {
    return (<></>)
  }
  // data used to be stored in 'content' but is stored in 'data' for newer notebooks
  const content = data.data_rows[0].content || data.data_rows[0].data
  const language = content && content.metadata && content.metadata.kernelspec && content.metadata.kernelspec.language
  return (
    <div>
      {language && content.cells.map((cell, idx) => (<Cell key={idx} cell={cell} language={language} />))}
    </div>
  )
}

export default NotebookView
