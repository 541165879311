import { dateOfWeek, formatDateTime, pad, smartTranslate } from 'components/common/Utils'
import {
  average,
  getWeekNumber, nanToZero, round,
  standardDeviation,
  sum,
  unique
} from 'components/common/Math'
import moment from 'moment'
import I18n from 'i18n'
import {
  dailyQuestionnaires,
  momentFullDateFormat,
  sundayQuestionnaire,
  weeklyWellbeing
} from 'components/common/Constants'
import { shortSessionName, addMovingAverageColumn } from 'components/analysis/dashboards/questionnaire/Utils'
import { QuestionnaireObject } from 'components/common/types/questionnaires/QuestionnaireObject'
import { getFirstDataRowObject } from 'components/common/utils/QuestionnaireUtilsTS'
import { TrainingLogRow } from 'components/common/types/questionnaires/TrainingLogRow'
import { TrainingType } from 'components/common/types/TrainingType'
import { SundaySummary } from 'components/common/types/questionnaires/SundaySummary'
import { WeeklyWellbeingRow } from 'components/common/types/questionnaires/WeeklyWellbeingRow'
import { DailyLogRow } from 'components/common/types/questionnaires/DailyLogRow'
import { getWellnessScore } from 'components/common/utils/QuestionnaireUtils'
import { DataRow } from 'components/common/types/questionnaires/DataRow'
import { determineColor, nanToNull } from 'components/analysis/dashboards/questionnaire/weekly/WellbeingUtils'

const determineDaypart = (myDate: Date): string => {
  const hour = myDate.getHours()
  if (hour >= 5 && hour <= 11) return I18n.t('components.dashboards.questionnaire.dayparts.morning')
  if (hour >= 12 && hour <= 17) return I18n.t('components.dashboards.questionnaire.dayparts.afternoon')
  if (hour >= 18 && hour <= 23) return I18n.t('components.dashboards.questionnaire.dayparts.evening')
  if (hour >= 0 && hour <= 4) return I18n.t('components.dashboards.questionnaire.dayparts.night')
  return 'N/A'
}

export interface ScoreData {
  x?: Date
  y?: number
  formatted_date?: string
  wellness_sleep: number | null
  wellness_fatigue: number | null
  wellness_stress: number | null
  wellness_soreness: number | null
  wellness_mood: number | null
  color: string
}
export const mapToWellbeingScoreData = (dataNoDuplicates: DailyLogRow[] | WeeklyWellbeingRow[]): ScoreData[] => {
  return dataNoDuplicates.map(entry => {
    return {
      x: entry.date_only,
      y: entry.wellness_sum_p,
      formatted_date: entry.formatted_date,
      weight: entry.weight !== null && entry.weight !== undefined ? `${entry.weight} kg` : '-',
      wellness_sleep: nanToNull(entry.wellness_sleep),
      wellness_fatigue: nanToNull(entry.wellness_fatigue),
      wellness_stress: nanToNull(entry.wellness_stress),
      wellness_soreness: nanToNull(entry.wellness_soreness ?? null),
      wellness_mood: nanToNull(entry.wellness_mood),
      color: determineColor(entry.wellness_sum_p)
    }
  })
}

export interface MovingAvgData {
  x: Date | undefined
  y: number | undefined
  formatted_date: string | undefined
}
export const mapToWellnessMovingAvgData = (dataNoDuplicates: DailyLogRow[] | WeeklyWellbeingRow[]): MovingAvgData[] => {
  return dataNoDuplicates.map(entry => {
    return {
      x: entry.date_only,
      y: entry.wellness_moving_average,
      formatted_date: entry.formatted_date
    }
  })
}

export interface SicknessData {
  x: Date | undefined
  y: number | null
  formatted_date?: string
}
export const mapToSicknessData = (dataNoDuplicates: DailyLogRow[]): SicknessData[] => {
  return dataNoDuplicates.map(entry => {
    return {
      x: entry.date_only,
      y: entry.sick === 1 ? -5 : null, // -5 so that it appears below the x-axis on the gray bar, acting as a status indicator.
      formatted_date: entry.formatted_date
    }
  })
}

export interface RestingHRData {
  x: Date | undefined
  y: number | undefined
  formatted_date: string | undefined
}
export const mapToRestingHRData = (dataNoDuplicates: DailyLogRow[] | WeeklyWellbeingRow[]): RestingHRData[] => {
  return dataNoDuplicates.map(entry => {
    return {
      x: entry.date_only,
      y: entry.resting_hr,
      formatted_date: entry.formatted_date
    }
  })
}

export interface SleepDurationData {
  x: Date | undefined
  y: number
  formatted_date: string | undefined
  formatted_sleep_duration: string
}
export const mapToSleepDurationData = (dataNoDuplicates: DailyLogRow[] | WeeklyWellbeingRow[]): SleepDurationData[] => {
  return dataNoDuplicates.map(entry => {
    return {
      x: entry.date_only,
      y: entry.sleep_duration_num,
      formatted_date: entry.formatted_date,
      formatted_sleep_duration: entry.sleep_duration
    }
  })
}

// wkNumArr is the output of getWeekNumber, index 0 is the year, index 1 the week number.
export const wkNumFormat = (wkNumArr: [number, number]): string => {
  // e.g. '2021.52'
  return `${wkNumArr[0]}.${String(wkNumArr[1]).padStart(2, '0')}`
}

interface WeeklySummary {
  wknum: string
  wknum_arr: [number, number]
  wknum_formatted: string
  date_only: Date
}
interface WeeklyTrainingLogSummary extends WeeklySummary {
  srpes: number[]
  training_load?: number
  formatted_date?: string
  training_monotony?: number | null
  training_strain?: number | null
  training_load_moving_average?: number
  training_ac_radio_rpe_coupled?: number
}

// TODO should be refactored into more functions
export const preprocessTrainingLogs = (questionnaires: QuestionnaireObject[]): {
  weeklySummariesArr: WeeklyTrainingLogSummary[]
  trainingLogRows: TrainingLogRow[]
} => {
  // TODO Instead of passing QuestionnaireObjects, unpack the objects in a different function so that you can pass
  // objects of type TrainingLogRow to this function
  let trainingLogRows: TrainingLogRow[] = questionnaires
    .map(item => getFirstDataRowObject(item))
    .filter((row): row is TrainingLogRow => row !== undefined)

  trainingLogRows?.forEach(entry => {
    entry.training_type = smartTranslate(entry.values.v1) as TrainingType
    entry.training_session = smartTranslate(entry.values.v2) as string
    entry.training_session_short = shortSessionName(entry.training_session) as string
    entry.training_start = `${entry.values.v3.toString()} ${pad((entry.values.v4_uur != null ? entry.values.v4_uur : entry.values.v4_uren).toString(), 2) as string}:${pad(entry.values.v4_minuten.toString(), 2) as string}:00`.replace(/-/g, '/')
    entry.training_start_date = new Date(entry.training_start)
    entry.formatted_training_start_date = formatDateTime(entry.training_start_date)
    entry.training_start_wk = getWeekNumber(entry.training_start_date) as [number, number]
    entry.training_daypart = determineDaypart(entry.training_start_date)
    entry.training_duration = parseInt(entry.values.v5 as string)
    entry.rpe = parseInt(entry.values.v6 as string)
    entry.training_satisfaction = parseFloat(entry.values.v7 as string)
    entry.comments = entry.values.v8 as string
    entry.srpe = entry.rpe * entry.training_duration
  })

  trainingLogRows = trainingLogRows.sort((a, b) => a.training_start_date.getTime() - b.training_start_date.getTime())
  trainingLogRows.forEach(entry => {
    entry.training_start_wknum = wkNumFormat(entry.training_start_wk)
  })

  // Make weekly summaries
  const weeklySummaries: Record<string, WeeklyTrainingLogSummary> = {}

  trainingLogRows.forEach(entry => {
    if (weeklySummaries[entry.training_start_wknum] === undefined) {
      weeklySummaries[entry.training_start_wknum] = {
        wknum: entry.training_start_wknum,
        wknum_arr: entry.training_start_wk,
        wknum_formatted: `${entry.training_start_wk[1]}, ${entry.training_start_wk[0]}`,
        date_only: dateOfWeek(entry.training_start_wk[1], entry.training_start_wk[0]),
        srpes: []
      }
    }
    weeklySummaries[entry?.training_start_wknum]?.srpes?.push(entry?.srpe)
  })
  let weeklySummariesArr: WeeklyTrainingLogSummary[] = Object.values(weeklySummaries)
  weeklySummariesArr = weeklySummariesArr.sort((a, b) => (a.date_only > b.date_only) ? 1 : ((b.date_only > a.date_only) ? -1 : 0))
  weeklySummariesArr.forEach(entry => {
    entry.training_load = sum(entry.srpes)
    entry.formatted_date = moment(entry.date_only).locale(I18n.locale).format(momentFullDateFormat)
    if (unique(entry.srpes).length > 1) {
      entry.training_monotony = average(entry.srpes) / standardDeviation(entry.srpes)
      entry.training_strain = (entry.training_load != null && entry.training_monotony != null) ? entry.training_load * entry.training_monotony : null
    } else {
      entry.training_monotony = null // NA
      entry.training_strain = null // NA
    }
  })

  addMovingAverageColumn(weeklySummariesArr, 'training_load', 4, 'training_load_moving_average')
  Object.values(weeklySummariesArr).forEach(entry => {
    if (entry.training_load !== undefined && entry.training_load_moving_average !== undefined) {
      entry.training_ac_radio_rpe_coupled = entry.training_load / entry.training_load_moving_average
    }
  })
  return { weeklySummariesArr, trainingLogRows }
}

const calculateWeeklyOSTRCSeverity = (entry: SundaySummary): number => {
  let result = 0
  const parameters: Array<keyof SundaySummary> = ['participation', 'reduced_training', 'affected_performance', 'symptoms_complaints']
  for (const parameter of parameters) {
    result += entry[parameter] as number
  }
  result = result / 4
  return result
}

const preprocessDailyLogRows = (dataRows: DataRow[], hourUnit: string, minuteUnit: string): DailyLogRow[] => {
  const filteredRows: DataRow[] = dataRows.filter(
    item => dailyQuestionnaires.includes(item.questionnaire.key) &&
      item.questionnaire.key !== sundayQuestionnaire)

  let dailyLogRows: DailyLogRow[] = filteredRows.map(entry => {
    const result: DailyLogRow = {
      ...entry,
      date: new Date(entry.open_from),
      date_only: new Date((new Date(entry.open_from)).toDateString()),
      formatted_date: moment(new Date(entry.open_from)).locale(I18n.locale).format(momentFullDateFormat),
      wellness_sleep: parseFloat(entry.values.v1 as string),
      wellness_fatigue: parseFloat(entry.values.v3 as string),
      wellness_stress: parseFloat(entry.values.v4 as string),
      wellness_soreness: parseFloat(entry.values.v5 as string),
      wellness_mood: parseFloat(entry.values.v6 as string),
      readiness_to_train: parseFloat(entry.values.v7 as string),
      resting_hr: parseFloat(entry.values.v8 as string),
      weight: parseFloat(entry.values.v8a as string),
      sick: ['ja', 'yes'].includes(entry.values.v9 as string) ? 1 : 0,
      injured: ['ja', 'yes'].includes(entry.values.v10 as string) ? 1 : 0,
      wellness_sum_p: getWellnessScore(entry.values),
      wellness_sum_p_rounded: round(getWellnessScore(entry.values), 0),
      sleep_duration: `${entry.values.v2_uren}${hourUnit}${entry.values.v2_minuten}${minuteUnit}`,
      sleep_duration_num: parseInt(entry.values.v2_uren as string) + (parseInt(entry.values.v2_minuten as string) / 60),
      comments: entry.values.v11 as string
    }
    return result
  })

  dailyLogRows = dailyLogRows.sort((a, b) => a.date.getTime() - b.date.getTime())

  addMovingAverageColumn(dailyLogRows, 'wellness_sum_p', 7, 'wellness_moving_average')
  addMovingAverageColumn(dailyLogRows, 'sleep_duration_num', 7, 'sleep_duration_moving_average')
  addMovingAverageColumn(dailyLogRows, 'resting_hr', 7, 'resting_hr_moving_average')
  addMovingAverageColumn(dailyLogRows, 'readiness_to_train', 7, 'readiness_to_train_moving_average')
  addMovingAverageColumn(dailyLogRows, 'sleep_duration_num', 7, 'sleep_duration_num_moving_average')

  return dailyLogRows
}

const preprocessSundaySummary = (dataRows: DataRow[]): SundaySummary[] => {
  const sundaySummaryFilteredRows = dataRows.filter(item => item.questionnaire.key === sundayQuestionnaire)

  let sundaySummaryRows: SundaySummary[] = sundaySummaryFilteredRows.map(entry => {
    const result: SundaySummary = {
      ...entry,
      date: new Date(entry.open_from),
      date_only: new Date(entry.open_from),
      formatted_date: formatDateTime(new Date(entry.open_from)),

      weekly_feedback: entry.values.v7 as string,
      participation: nanToZero(parseFloat(entry.values.v3 as string)) / 3,
      reduced_training: nanToZero(parseFloat(entry.values.v4 as string)) / 4,
      affected_performance: nanToZero(parseFloat(entry.values.v5 as string)) / 4,
      symptoms_complaints: nanToZero(parseFloat(entry.values.v6 as string)) / 3,
      weekly_ostrc_severity: calculateWeeklyOSTRCSeverity(entry as SundaySummary)
    }
    return result
  })

  sundaySummaryRows = sundaySummaryRows.sort((a, b) => a.date.getTime() - b.date.getTime())
  return sundaySummaryRows
}

const preprocessWeeklyWellbeingRows = (dataRows: DataRow[], hourUnit: string, minuteUnit: string): WeeklyWellbeingRow[] => {
  const weeklyWellbeingFilteredRows = dataRows.filter(item => item.questionnaire.key === weeklyWellbeing)

  let weeklyWellbeingRows: WeeklyWellbeingRow[] = weeklyWellbeingFilteredRows.map(entry => {
    const result: WeeklyWellbeingRow = {
      ...entry,
      date: new Date(entry.open_from),
      date_only: new Date(entry.open_from),
      formatted_date: formatDateTime(new Date(entry.open_from)),
      sleep_quality: nanToZero(parseFloat(entry.values.v1 as string)),
      sleep_duration: `${entry.values.v2_uren}${hourUnit}${entry.values.v2_minuten}${minuteUnit}`,
      sleep_duration_num: parseInt(entry.values.v2_uren as string) + (parseInt(entry.values.v2_minuten as string) / 60),
      wellness_fatigue: parseFloat(entry.values.v3 as string),
      wellness_stress: parseFloat(entry.values.v4 as string),
      wellness_mood: parseFloat(entry.values.v5 as string),
      wellness_sum_p: getWellnessScore(entry.values),
      wellness_sum_p_rounded: round(getWellnessScore(entry.values), 0),
      comments: entry.values.v6 as string,
      wellness_sleep: nanToZero(parseFloat(entry.values.v1 as string))
    }
    return result
  })
  weeklyWellbeingRows = weeklyWellbeingRows.sort((a, b) => a.date.getTime() - b.date.getTime())

  return weeklyWellbeingRows
}

// TODO pass datarows to this function instead of QuestionnaireObjects
export const preprocessWellbeingQuestionnaires = (questionnaires: QuestionnaireObject[]): {
  dailyLogRows: DailyLogRow[] | undefined
  sundaySummary: SundaySummary[] | undefined
  weeklyWellbeingRows: WeeklyWellbeingRow[] | undefined
} => {
  const dataRows: DataRow[] = questionnaires
    .map(item => getFirstDataRowObject(item))
    .filter((item): item is DataRow => item !== undefined)

  const hourUnit: string = I18n.t('components.dashboards.questionnaire.units.h')
  const minuteUnit: string = I18n.t('components.dashboards.questionnaire.units.m')

  const dailyLogRows: DailyLogRow[] = preprocessDailyLogRows(dataRows, hourUnit, minuteUnit)
  const sundaySummary: SundaySummary[] = preprocessSundaySummary(dataRows)
  const weeklyWellbeingRows: WeeklyWellbeingRow[] = preprocessWeeklyWellbeingRows(dataRows, hourUnit, minuteUnit)

  return { dailyLogRows, sundaySummary, weeklyWellbeingRows }
}
