import React from 'react'
import ApiConnector from '../helpers/ApiConnector'
import { WithSession } from '../session/SessionProvider'
import EditMetaData from './layout/EditMetaData'
import { MyAxios as axios } from '../MyAxios'

class MetaData extends ApiConnector {
  constructor (props) {
    super(props)
    this.state = { endpoint: '' }
  }

  setEndpoint (url) {
    this.setState({ ...this.state, endpoint: url })
  }

  resetEndpoint () {
    // this.setState({ ...this.state, endpoint: '' })
  }

  /* eslint-disable camelcase */
  metadata_editFormMapper (fields) {
    return fields
  }
  /* eslint-enable camelcase */

  /* eslint-disable camelcase */
  metadata_editApiMapper (fields, url) {
    return fields
  }
  /* eslint-enable camelcase */

  componentDidMount () {
    super.componentDidMount()
    axios({
      method: 'GET',
      url: '/api/v1/tags',
      headers: { Authorization: this.props.sessionToken }
    }).then(res => this.setState({ tags: res.data.map(tag => tag.name) }))
  }

  render () {
    const editProps = this.defaultFormProps('metadata_edit', {
      translateErrors: false,
      submitMethod: 'PATCH',
      sessionToken: this.props.sessionToken,
      endpoint: this.state.endpoint,
      setEndpoint: this.setEndpoint.bind(this),
      resetEndpoint: this.resetEndpoint.bind(this),
      vdoId: this.props.vdoId,
      isBulkUpdate: this.props.isBulkUpdate,
      handleSuccess: this.props.handleSuccess,
      availableTags: this.state.tags
    })

    return (
      <>
        <EditMetaData {...this.props} {...editProps} />
      </>
    )
  }
}

export default WithSession(MetaData)
