import ReactDatePicker from 'react-datepicker'
import React from 'react'
import I18n from 'i18n'
import { getDatePickerLocale } from 'components/common/DatePickerUtils'
import Info from 'components/atomic/atoms/Info'

export default class DatePicker extends React.Component {
  // Renders the label with the tooltip in case the label is active
  renderLabel (fieldName, isActive, translationKey, tooltipText) {
    const label = (
      <label style={{ display: 'inline' }} htmlFor={fieldName} className={isActive && 'active'}>
        {I18n.t(translationKey)}
      </label>
    )
    if (!isActive || !tooltipText) return label

    const tooltip = (
      <Info text={tooltipText} tooltipId={`${translationKey}-tooltip`} />
    )

    return (
      <div>
        {label}
        {tooltipText && tooltip}
      </div>
    )
  }

  render () {
    const errors = this.props.errors || []
    const { component, fieldName, formName, showTimeSelect, infoBox } = this.props
    let { defaultValue, currentValue, type } = this.props
    const fieldProps = { ...this.props }
    delete fieldProps.component
    delete fieldProps.fieldName
    delete fieldProps.translateErrors
    delete fieldProps.formName
    delete fieldProps.defaultValue
    delete fieldProps.currentValue

    defaultValue = defaultValue && new Date(defaultValue)
    currentValue = currentValue && new Date(currentValue)
    type = type || 'date_picker'

    return (
      <div className='row'>
        <div className='col s12'>
          <div className='input-field'>
            {this.renderLabel(fieldName, currentValue || defaultValue, `${formName}.${component}.${type}.${fieldName}`, infoBox)}
            <ReactDatePicker
              {...getDatePickerLocale()}
              id={fieldName}
              selected={currentValue || defaultValue}
              {...fieldProps}
              onChange={(e) => this.props.onChange({ target: { value: e } }, formName, fieldName)}
              dateFormat={showTimeSelect ? 'dd-MM-yyyy H:mm' : 'dd-MM-yyyy'}
            />
            {errors.map(err => (
              <div key={err} style={{ color: 'red' }}>
                {this.props.translateErrors ? I18n.t(err) : err}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
