import React from 'react'
import I18n from 'i18n'
import Field from '../common/Field'
import { smartTranslate } from '../../../../common/Utils'

export default class TrainingLog extends React.Component {
  render () {
    const values = this.props.data.values
    const rpeTable = {
      0: I18n.t('questionnaires.training_log.rpe.rest'),
      1: I18n.t('questionnaires.training_log.rpe.very_light'),
      2: I18n.t('questionnaires.training_log.rpe.light'),
      3: I18n.t('questionnaires.training_log.rpe.average'),
      4: I18n.t('questionnaires.training_log.rpe.tough'),
      5: I18n.t('questionnaires.training_log.rpe.heavy'),
      6: I18n.t('questionnaires.training_log.rpe.heavy'),
      7: I18n.t('questionnaires.training_log.rpe.very_heavy'),
      8: I18n.t('questionnaires.training_log.rpe.very_heavy'),
      9: I18n.t('questionnaires.training_log.rpe.very_very_heavy'),
      10: I18n.t('questionnaires.training_log.rpe.maximal')
    }
    const satTable = {
      1: I18n.t('questionnaires.training_log.sat.dissatisfied'),
      1.5: I18n.t('questionnaires.training_log.sat.unsatisfied'),
      2: I18n.t('questionnaires.training_log.sat.unsatisfied'),
      2.5: I18n.t('questionnaires.training_log.sat.normal'),
      3: I18n.t('questionnaires.training_log.sat.normal'),
      3.5: I18n.t('questionnaires.training_log.sat.normal'),
      4: I18n.t('questionnaires.training_log.sat.satisfied'),
      4.5: I18n.t('questionnaires.training_log.sat.satisfied'),
      5: I18n.t('questionnaires.training_log.sat.totally_satisfied')
    }
    return (
      <>
        <div className='sport-fields'>
          <Field title={I18n.t('questionnaires.training_log.training_type')} value={smartTranslate(values.v1)} />
          <Field title={I18n.t('questionnaires.training_log.session_type')} value={smartTranslate(values.v2)} />
          <Field title={I18n.t('questionnaires.training_log.duration_min')} value={values.v5} />
          <Field title={I18n.t('questionnaires.training_log.satisfaction')} value={satTable[values.v7]} />
          <Field title={I18n.t('questionnaires.training_log.rpe_score')} value={rpeTable[values.v6]} extraClasses={`rpe rpe-${values.v6}`} />
        </div>
      </>
    )
  }
}
