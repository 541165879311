import React from 'react'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PremiumOverlay from '../premium_membership/PremiumOverlay'
import { subscriptionTypes } from './Constants'
import Routes from './Routes'
import { Link } from 'react-router-dom'

const ScheduleTrainingButton: React.FC = () => {
  return (
    <div className='col s6 m12'>
      <PremiumOverlay showButton={false} activeFor={[subscriptionTypes.coach, subscriptionTypes.researcher]}>
        <Link
          className='waves-effect text-m waves-light button-primary text-primary-color background-background text-medium button welcome-container-button schedule-training-button'
          to={Routes.training.index}
        >
          <FontAwesomeIcon icon='calendar-plus' className='margin-right' />
          {I18n.t('components.welcome.schedule_training')}
        </Link>
      </PremiumOverlay>
    </div>
  )
}

export default ScheduleTrainingButton
