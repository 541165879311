import { useState } from 'react'

export interface IuseNotificationWindow {
  page: number
  handleNavigate: (page: number) => void
}

const useNotificationWindow = (): IuseNotificationWindow => {
  const [page, setPage] = useState(1)
  const handleNavigate = (page: number): void => {
    setPage(page)
  }

  return (
    {
      page: page,
      handleNavigate: handleNavigate
    }

  )
}
useNotificationWindow.defaultProps = {
}
export default useNotificationWindow
