import React from 'react'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

const ToggleAdvanced = (props) => {
  return (
    <button
      id='deploy-advanced'
      className='button-primary background-primary button-autowidth waves-effect waves-light text-background-color'
      onClick={props.onToggle}
    >
      {
        props.showAdvanced && (
          <>
            <FontAwesomeIcon icon='chevron-up' className='button-icon' />
            {I18n.t('components.data.overview.search.hide_advanced')}
          </>
        )
      }
      {!props.showAdvanced && (
        <>
          <FontAwesomeIcon icon='chevron-down' className='button-icon' />
          {I18n.t('components.data.overview.search.show_advanced')}
        </>
      )}
    </button>
  )
}

ToggleAdvanced.propTypes = {
  showAdvanced: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
}

export default ToggleAdvanced
