import React, { useContext, useEffect, useState } from 'react'
import Card from '../../atomic/atoms/Card'
import Header from '../../atomic/atoms/headers/Header'
import StartAndEndDate from '../../atomic/molecules/StartAndEndDate'
import I18n from 'i18n'
import moment from 'moment'
import { useGroupsDTOQuery } from '../../backend/Queries'
import GroupMembersSelect, { GroupMember } from '../../atomic/molecules/GroupMembersSelect'
import Button from '../../atomic/atoms/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SessionContext } from '../../session/SessionProvider'
import Spinner from '../../common/Spinner'
import RedirectRoutes from '../../backend/RedirectRoutes'

const TrainingSessionsExporter: React.FC = () => {
  // Holds the ids of the selected athletes
  const [selectedAthletes, setSelectedAthletes] = useState<GroupMember[]>([])

  const initialStartDate = moment().subtract(1, 'month').startOf('day').toDate()
  const initialEndDate = moment().add(1, 'month').endOf('day').toDate()

  const [startDate, setStartDate] = useState<Date>(initialStartDate)
  const [endDate, setEndDate] = useState<Date>(initialEndDate)

  const { data: groups = [], refetch, isSuccess } = useGroupsDTOQuery()

  useEffect(() => {
    // We ignore errors for refetch because it's not that big a deal since we
    // use react-query and it retries by default a couple of times.
    void refetch()
  }, [startDate, endDate])

  const session = useContext(SessionContext)
  const myProfileId: number | undefined = session.myProfile?.id
  if (myProfileId === null || myProfileId === undefined) return <Spinner />

  const constructDownload = (): string => {
    const selectedAthleteIds: number[] = selectedAthletes.map((athlete) => athlete.profile.id)
    return RedirectRoutes.schedules.exportCsvFn(startDate, endDate, selectedAthleteIds)
  }

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <Card>
            <Header isTopHeader>{I18n.t('components.trainings.export_training_schedules')}</Header>
            <div className='row'>
              <StartAndEndDate
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                sizeClass='s6'
              />
            </div>
            <div className='row'>
              <div className='col s12'>
                {isSuccess && (
                  <div className='row'>
                    <div className='col s12'>
                      <GroupMembersSelect
                        groups={groups}
                        selectedMembers={selectedAthletes}
                        setSelectedMembers={setSelectedAthletes}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col s12'>
                <Button autowidth isDisabled={selectedAthletes.length === 0} href={constructDownload()}>
                  <FontAwesomeIcon icon='download' className='button-icon margin-right' />
                  {I18n.t('components.trainings.exporter.download_training_sessions')}
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  )
}

export default TrainingSessionsExporter
