import { MyAxios as axios } from '../../MyAxios'

const fetchAmount = (subscriptionType, sessionToken) => {
  return axios({
    method: 'GET',
    url: `/api/v1/checkouts/amount?subscription_type=${subscriptionType}`,
    headers: {
      Authorization: sessionToken
    }
  })
}

export {
  fetchAmount
}
