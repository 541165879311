import React from 'react'
import { WithSession } from '../session/SessionProvider'
import { WithBackend } from '../backend/BackendProvider'
import EditOauthApplications from './layout/EditOauthApplications'

class OauthApplications extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      oauthApplications: []
    }
  }

  fetch () {
    this.props.backend.listOauthApplications().then(res => {
      this.setState({ oauthApplications: res.data })
    })
  }

  destroyOauthApplication = (id) => {
    this.props.backend.destroyOauthApplication(id).then(() => {
      this.fetch()
    })
  }

  componentDidMount () {
    this.fetch()
  }

  render () {
    return (
      <EditOauthApplications
        oauthApplications={this.state.oauthApplications}
        destroyOauthApplication={this.destroyOauthApplication}
      />
    )
  }
}

export default WithBackend(WithSession(OauthApplications))
