import React, { useContext, useState } from 'react'
import I18n from 'i18n'
import useCallFeedbackMessage from '../../hooks/CallFeedbackMessage'
import { BackendContext } from '../../backend/BackendProvider'
import { GroupProtocolSubscription } from 'components/common/types/GroupProtocolSubscription'

export interface IGroupProtocolSubscriptionsHook {
  fetchGroupProtocolSubscriptions: (groupId: number | string) => void
  handleCreateGroupProtocolSubscriptions: (groupId: number | string, protocolName: string, durationInDays: number | string, invitationTextNl: string, invitationTextEn: string, refetch: boolean) => void
  handleDestroyGroupProtocolSubscriptions: (groupId: number | string, groupProtocolSubscriptionId: number | string) => void
  groupProtocolSubscriptions: GroupProtocolSubscription[]
  groupProtocolSubscriptionsRetrieved: boolean
}

const GroupProtocolSubscriptionsHook = (): IGroupProtocolSubscriptionsHook => {
  const backend = useContext(BackendContext)?.backend
  const { handleCallFeedback } = useCallFeedbackMessage()
  const [groupProtocolSubscriptions, setGroupProtocolSubscriptions] = useState<GroupProtocolSubscription[] | []>([])
  const [groupProtocolSubscriptionsRetrieved, setGroupProtocolSubscriptionsRetrieved] = useState(false)

  const fetchGroupProtocolSubscriptions = (groupId: number | string): void => {
    backend.groupProtocolSubscriptions.index(groupId)
      .then((res: Record<string, any>) => {
        setGroupProtocolSubscriptions(res.data)
        setGroupProtocolSubscriptionsRetrieved(true)
      })
      .catch((err: Error) => {
        console.error('Error fetching active group protocols:')
        console.log(err)
      })
  }

  const handleCreateGroupProtocolSubscriptions = (groupId: number | string, protocolName: string, durationInDays: number | string, invitationTextNl: string, invitationTextEn: string, refetch: boolean = false): void => {
    const pm = backend.groupProtocolSubscriptions.create(
      groupId,
      {
        group_protocol_subscription: {
          protocol_name: protocolName,
          duration_in_days: durationInDays,
          invitation_text_nl: invitationTextNl,
          invitation_text_en: invitationTextEn
        }
      })
    handleCallFeedback(pm,
      I18n.t('flashmessages.questionnaires.creation_success'),
      I18n.t('flashmessages.questionnaires.creation_error'),
      (success: boolean) => { success && refetch && fetchGroupProtocolSubscriptions(groupId) }
    )
  }

  const removeSubscriptionFromList = (groupProtocolSubscriptionId: number | string): void => {
    const newSubs = groupProtocolSubscriptions.filter((sub: GroupProtocolSubscription) => {
      return sub.id !== groupProtocolSubscriptionId
    })
    setGroupProtocolSubscriptions(newSubs)
  }

  const handleDestroyGroupProtocolSubscriptions = (groupId: number | string, groupProtocolSubscriptionId: number | string): void => {
    const pm = backend.groupProtocolSubscriptions.destroy(groupId, groupProtocolSubscriptionId)
    handleCallFeedback(
      pm,
      I18n.t('flashmessages.questionnaires.deletion_success'),
      I18n.t('flashmessages.questionnaires.deletion_error'),
      (success: boolean) => {
        success && removeSubscriptionFromList(groupProtocolSubscriptionId)
      }
    )
  }

  return {
    fetchGroupProtocolSubscriptions: fetchGroupProtocolSubscriptions,
    handleCreateGroupProtocolSubscriptions: handleCreateGroupProtocolSubscriptions,
    handleDestroyGroupProtocolSubscriptions: handleDestroyGroupProtocolSubscriptions,
    groupProtocolSubscriptions: groupProtocolSubscriptions,
    groupProtocolSubscriptionsRetrieved: groupProtocolSubscriptionsRetrieved
  }
}

GroupProtocolSubscriptionsHook.defaultProps = {
}

export default GroupProtocolSubscriptionsHook

// This is a workaround to get this hook working in a class component. Remove when class components are not needed anymore.
export const WithGroupProtocolSubscriptionHook = (Component: any) => {
  return (props: any) => {
    const hook = GroupProtocolSubscriptionsHook()

    return <Component {...hook} {...props} />
  }
}
