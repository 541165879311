import React, { FC, useState } from 'react'
import I18n from 'i18n'
import { Field } from 'components/common/form'

// See https://stackoverflow.com/questions/44717164/unable-to-import-svg-files-in-typescript for why whe need to import
// this way
const sdvLogo = require('../../../images/sdv-logo.svg') as string // eslint-disable-line @typescript-eslint/no-var-requires

interface AddTransponderFormProps {
  onSubmit: (transponderNumber: string) => void
  numberErrors: string[]
}

const AddTransponderForm: FC<AddTransponderFormProps> = (props) => {
  const [transponderNumber, setTransponderNumber] = useState<string>('')

  const handleTransponderNumberChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTransponderNumber(e.currentTarget.value)
  }

  return (
    <div className='row'>
      <div className='col s12'>
        <img className='sdv-logo' src={sdvLogo} />
      </div>
      <div className='col s12 m6'>
        <h1>{I18n.t('components.transponder.add_form.title')}</h1>
        <p>{I18n.t('components.transponder.add_form.connection_description')}</p>
        <p>{I18n.t('components.transponder.add_form.agreement_statement')}</p>
      </div>
      <div className='col s12'>
        <Field formName='components.transponder.add_form' component='input' type='text' fieldName='transponder_number' onChange={handleTransponderNumberChange} currentValue={transponderNumber} errors={props.numberErrors} />
        <button
          id='submit-tranponder'
          onClick={() => props.onSubmit(transponderNumber)}
          className='button-primary background-primary button-autowidth waves-effect waves-light text-background-color'
        >
          {I18n.t('components.transponder.add_form.submit')}
        </button>
      </div>
    </div>
  )
}

export default AddTransponderForm
