// For things like app/models/concerns/date_paginateable.rb
// Should contain the exact properties of render_page from app/controllers/application_controller.rb
export default interface Page {
  current_page: number | null
  next_page: number | null
  prev_page: number | null
  total_count: number
  total_pages: number
  has_next: boolean
}

export const DefaultPage: Page = {
  current_page: null,
  next_page: null,
  prev_page: null,
  total_count: 0,
  total_pages: 0,
  has_next: false
}
