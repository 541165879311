import React from 'react'
import classNames from 'classnames'
import CombinedFontAwesomeIcon from 'components/atomic/molecules/CombinedFontAwesomeIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import I18n from 'i18n'
import { IconLookup } from '@fortawesome/fontawesome-svg-core'

const SuperPremiumIcon = (): React.ReactElement => {
  const star1: IconLookup = { prefix: 'fas', iconName: 'star' }
  const star2: IconLookup = { prefix: 'fas', iconName: 'star' }
  return (
    <CombinedFontAwesomeIcon
      icons={[
        { icon: star1, id: 'first-star' },
        { icon: star2, id: 'second-star' }
      ]}
      combinedIconClass='super-premium-icon-wrapper'
      className='premium-badge-timeline-icon super-premium'
    />
  )
}

const PremiumIcon = (): React.ReactElement => {
  return (
    <FontAwesomeIcon
      icon='star'
      className='premium-badge-timeline-icon'
      title={I18n.t('premium.types.generic')}
    />
  )
}

type PremiumBadgeProps = Readonly<{
  isTimeline?: boolean
  isSuperPremium: boolean
}>

const PremiumBadge = (props: PremiumBadgeProps): React.ReactElement => {
  const { isTimeline = false, isSuperPremium } = props
  const badgeClassNames: string = isTimeline ? 'premium-badge-timeline-insets' : ''

  const colorClass: string = isSuperPremium ? 'super-premium' : ''
  return (
    <div className={classNames('premium-badge-timeline no-filter', badgeClassNames, colorClass)}>
      {isSuperPremium ? <SuperPremiumIcon /> : <PremiumIcon />}
    </div>
  )
}

export default PremiumBadge
