import React from 'react'
import { WithModal } from '../modal/ModalProvider'
import { WithFlashMessages } from '../flashmessages/FlashMessageProvider'
import { WithSession } from '../session/SessionProvider'
import ConnectionCreate from './layout/ConnectionCreate'
import I18n from 'i18n'

class ConnectionCreateController extends React.Component {
  render () {
    return (
      <ConnectionCreate
        handleSuccess={() => {
          this.props.hide()
          this.props.flashMessages.push(
            I18n.t('network.connection.request.success'),
            this.props.flashMessages.duration.SHORT,
            this.props.flashMessages.levels.INFO)
        }}
        onProfileSearch={this.props.onProfileSearch}
        onConnectionRequest={(arg) => { this.props.onConnectionRequest(arg) }}
        handleError={() => {
          this.props.flashMessages.push(
            I18n.t('network.connection.request.error'),
            this.props.flashMessages.duration.SHORT,
            this.props.flashMessages.levels.ERROR)
        }}
      />
) // eslint-disable-line indent
  }
}

export default WithFlashMessages(WithModal(WithSession(ConnectionCreateController)))
