import React from 'react'
import { WithSession } from '../session/SessionProvider'
import { MyAxios as axios } from '../MyAxios'
import DashboardGrid from './layout/DashboardGrid'
import Spinner from '../common/Spinner'
import { dashboardLink, profileIsPremium } from '../common/Utils'
import I18n from 'i18n'
import { sportsExcludedFromFrontend } from '../common/Constants'
import Routes from 'components/common/Routes'
import { WithFeatureFlag } from 'components/feature_flags/FeatureFlagProvider'
import { MYLAPS_THIALF, JUPYTERHUB } from 'components/feature_flags/FeatureFlag'
import WithProfileMetadata from 'components/helpers/WithProfileMetadata'

class DashboardController extends React.Component {
  constructor (props) {
    super(props)
    this.questionnairesBaseUrl = process.env.QUESTIONNAIRES_ENGINE_URL
    this.state = {
      dataRetrieved: false
    }
    this.intervals = {
      week: (date) => new Date(date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate() - 6,
        0,
        0,
        (-date.getTimezoneOffset() * 60) + 1),
      month: (date) => new Date(date.getUTCFullYear(),
        date.getUTCMonth() - 1,
        date.getUTCDate(),
        0,
        0,
        (-date.getTimezoneOffset() * 60) + 1),
      year: (date) => new Date(date.getUTCFullYear() - 1,
        date.getUTCMonth(),
        date.getUTCDate(),
        0,
        0,
        (-date.getTimezoneOffset() * 60) + 1)
    }

    this.implementedDashboards = [
      'triathlon',
      'skating',
      'questionnaire',
      'steps',
      'running',
      'riding',
      'swimming',
      'hiking',
      'jupyterhub',
      'generic_csv',
      'soccer',
      'inline_skating',
      'coach'
    ]
  }

  componentDidMount () {
    !!this.props.myProfile && !!this.props.myProfile.id && this.loadData()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.myProfile) !== JSON.stringify(this.props.myProfile) ||
      JSON.stringify(prevProps.myProfileMetadata) !== JSON.stringify(this.props.myProfileMetadata)) {
      this.loadData()
    }
  }

  fetchAvailableDashboards () {
    return axios.get('/api/v1/sports', { headers: { Authorization: this.props.sessionToken } })
  }

  fetchSubscriptionStatus () {
    // TODO: Move to questionnaire provider
    return axios.get(this.questionnairesBaseUrl + '/api/v1/person/me', { headers: { Authorization: this.props.sessionToken } }).then(
      // If the axios request fails, we return a default array which specifies that the account is not active
      function (v) { return v },
      function (e) { return { data: { account_active: false } } }
    )
  }

  // this new endpoint only returns jupyter token instead of all tokens
  fetchJupyterHubToken () {
    return axios.get('/api/v1/api_tokens/jupyter_tokens', { headers: { Authorization: this.props.sessionToken } })
  }

  // this new endpoint only queries for csv file ids and returns true if array of ids contains one or more items
  // instead of returning all csv files
  fetchHasCsvFiles () {
    return axios({
      method: 'GET',
      url: '/api/v1/dashboards/has_csv_files',
      headers: { Authorization: this.props.sessionToken }
    })
  }

  loadData () {
    Promise.all([this.fetchAvailableDashboards(), this.fetchSubscriptionStatus(), this.fetchJupyterHubToken(), this.fetchHasCsvFiles()])
      .then(responses => {
        const [sportsResponse, subscriptionStatusResponse, jupyterHubTokenResponse, hasCsvFilesResponse] = responses

        let jupyterHubToken = null
        if (jupyterHubTokenResponse) { jupyterHubToken = jupyterHubTokenResponse.data?.[0]?.token }

        let sports = sportsResponse.data
        sports = sports.filter(s => s.name !== 'sports.soccer') // Remove soccer for now

        const questionnaireEnabled = subscriptionStatusResponse.data.account_active

        const myProfile = this.props.myProfile
        const myInterestSports = myProfile.sports.map(s => s.name)
        const myDataSports = this.props.myProfileMetadata.sports_from_data?.map(s => s.name)
        const mySports = new Set(myInterestSports.concat(myDataSports))
        const dashboards = []

        if (questionnaireEnabled) {
          const questionnaireSportName = sports
            .filter((sport) => sport.name === 'sports.questionnaire')
            .map((sport) => sport.name)[0]
          mySports.add(questionnaireSportName)
        }

        const jupyterDashboard = {
          id: 0,
          name: 'analysis.dashboard.research_tools',
          icon: ['fab', 'python'],
          link: `${process.env.JUPYTER_HUB_URL}/hub/login?token=${jupyterHubToken}`,
          enabled: !!(jupyterHubToken),
          implemented: true,
          abbrevName: 'jupyterhub',
          cssKlass: 'dashboard-card-jupyterhub',
          extraInfoTooltip: I18n.t('components.dashboards.jupyterhub.extra_info'),
          disabledTooltip: !jupyterHubToken ? I18n.t('components.dashboards.jupyterhub.disabled_tooltip_token') : I18n.t('components.dashboards.jupyterhub.disabled_tooltip')
        }

        const coachDashboard = {
          id: 100, // Arbitrary number that is unique.
          name: 'components.dashboards.coach_individual.name',
          icon: ['fa', 'user-tie'],
          link: Routes.analysis.coach,
          enabled: profileIsPremium(myProfile),
          implemented: true,
          abbrevName: 'coach',
          cssKlass: 'dashboard-card-coach',
          extraInfoTooltip: I18n.t('components.dashboards.coach_individual.extra_info'),
          disabledTooltip: I18n.t('components.dashboards.coach_individual.disabled_tooltip')
        }

        const iceSkatingDashboard = {
          id: 101, // Arbitrary number that is unique.
          name: 'components.dashboards.ice_skating.title',
          icon: ['fa', 'circle-notch'],
          link: Routes.analysis.iceSkatingSingle.single.index,
          enabled: mySports.has('sports.skating'),
          implemented: true,
          abbrevName: 'ice_skating',
          cssKlass: 'dashboard-card-ice-skating',
          extraInfoTooltip: I18n.t('components.dashboards.ice_skating.extra_info'),
          disabledTooltip: I18n.t('components.dashboards.ice_skating.disabled_tooltip')
        }

        if (this.props.features?.[JUPYTERHUB]) {
          dashboards.push(jupyterDashboard)
        }

        dashboards.push(coachDashboard)

        if (this.props.features?.[MYLAPS_THIALF]) {
          dashboards.push(iceSkatingDashboard)
        }

        sports.forEach(s => {
          // We don't have a "generic" or "workout" dashboard
          if (sportsExcludedFromFrontend.includes(s.name)) return
          const dashboard = { ...s }
          if (s.icon === 'futbol') {
            dashboard.icon = ['far', 'futbol']
          }
          if (s.name === 'sports.generic_csv') {
            dashboard.enabled = hasCsvFilesResponse.data
          } else {
            dashboard.enabled = mySports.has(s.name)
          }

          dashboard.abbrevName = s.name.replace('sports.', '')
          dashboard.cssKlass = `dashboard-card-${dashboard.abbrevName}`
          dashboard.implemented = this.implementedDashboards.includes(dashboard.abbrevName)
          dashboard.disabledTooltip = I18n.t(`components.dashboards.${dashboard.abbrevName}.disabled_tooltip`)
          dashboard.link = dashboardLink(s.name)
          dashboards.push(dashboard)
        })

        const enabledDashboards = dashboards.filter(dashboard => dashboard.enabled && dashboard.implemented)
        const disabledDashboards = dashboards.filter(dashboard => !dashboard.enabled || !dashboard.implemented)
        this.setState({ dashboards: enabledDashboards.concat(disabledDashboards), dataRetrieved: true })
      })
      .catch(error => (
        console.log(error)
      ))
  }

  render () {
    return (
      <>
        <Spinner ready={this.state.dataRetrieved} />
        {this.state.dataRetrieved && <DashboardGrid {...this.props} dashboards={this.state.dashboards} />}
      </>
    )
  }
}

export default WithSession(WithFeatureFlag(WithProfileMetadata(DashboardController)))
