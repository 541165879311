import React from 'react'
import Table from '../../../common/Table'
import I18n from 'i18n'
import { WithErrorBoundary } from '../../../errorboundary/ErrorBoundaryProvider'
import Histogram from '../common/Histogram'
import { SimpleSlider } from '../../../common/Slider'

/**
 * Renders a histogram for the given dataset. If data is numeric, it will be split into bins first
 * The number of bins can be controller with the nbins prop
 */
const CsvNumericHistogram = ({ summaryBuilder, description = [], data = [], nbins, height = 250, onSetBins }) => {
  /**
   * Use the extremes and number of bins to determine the bin boundaries
   */
  const minVal = Math.min(...data)
  const maxVal = Math.max(...data)
  const binWidth = ((maxVal - minVal) + 1) / nbins

  /**
   * Initialize each bin with a label and a y-value of 0
   */
  const hist = []
  for (let i = 0; i < nbins; i++) {
    hist.push({ x: `${parseFloat((minVal + (i * binWidth)).toFixed(2))} - ${parseFloat((minVal + ((i + 1) * binWidth)).toFixed(2))}`, y: 0 })
  }

  /**
   * Iterate over each data point and increment the corresponding bin by 1
   */
  data.forEach((value) => {
    const bin = Math.floor((value - minVal) / binWidth)
    hist[bin].y += 1
  })

  /**
   * Column headers for the selected column's summary table
   */
  const columns = summaryBuilder.getNumericSummaryColumns()

  /**
   * Values for the selected column's summary table (only a single row because a histogram is for a single variable)
   */
  const rows = summaryBuilder.getNumericSummaryRows([description.name])

  const yLabel = description.label
  const xLabels = hist.map((bin) => bin.x)
  const ys = hist.map((bin) => bin.y)

  return (
    <div className='row'>
      <div className='col s12'>
        <Table columns={columns} rows={rows} />
      </div>
      <div className='col s12'>
        <SimpleSlider
          value={nbins}
          onChange={(e) => onSetBins(e.target.value)}
          minVal={1}
          maxVal={100}
          toolTipTranslation='components.visualization.csv.slider.bins'
        />
      </div>
      <div className='col s12'>
        <Histogram yLabel={yLabel} xLabels={xLabels} data={ys} height={height} />
      </div>
    </div>
  )
}

export default WithErrorBoundary(CsvNumericHistogram, I18n.t('components.visualization.csv.errors.histogram'))
