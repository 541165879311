import React, { useMemo } from 'react'
import I18n from 'i18n'
import Table from '../../../common/Table'
import ModalLink from '../../../common/ModalLink'
import { sortTogether } from '../../../common/Utils'
import { WithErrorBoundary } from '../../../errorboundary/ErrorBoundaryProvider'
import { NUMERIC_TYPE, CATEGORICAL_TYPE } from '../../../common/Constants'
import _ from 'lodash'
import SpinnerWrapper from '../../../common/SpinnerWrapper'
import { useDisplayFetchErrors, useFetchData } from '../../../common/Hooks'
import PropTypes from 'prop-types'
import InfoNotice from '../detail/InfoNotice'

// Maximum number of rows to display
const MAX_ROWS = 10

const CategoriesModal = ({ column, categoryRepresentation }) => {
  return (
    <div className='row'>
      <div className='col s12'>
        <h1>{`Categories for ${column}`}</h1>
      </div>
      <div className='col s12'>
        {categoryRepresentation}
      </div>
    </div>
  )
}

export class CsvDetailBuilder {
  constructor (summary, rows) {
    this.summary = summary
    this.csvRows = rows
    this.descriptions = _.get(summary, 'columns_description')
  }

  /**
   * Return the first min(n_rows, max_rows) rows from the csv
   */
  getDataRows (maxRows) {
    const orderedColumns = this.getDataColumns()
    return this.csvRows.slice(0, Math.min(maxRows, this.csvRows.length)).map(row => {
      return orderedColumns.map(colName => row[colName])
    })
  }

  /**
   * Return the column names from the csv
   */
  getDataColumns () {
    return this.descriptions.map((description) => description.name)
  }

  /**
   * Return a list containing translated table headers for numeric variables
   */
  getNumericSummaryColumns () {
    return [
      I18n.t('components.visualization.csv.name'),
      I18n.t('components.visualization.csv.mean'),
      I18n.t('components.visualization.csv.median'),
      I18n.t('components.visualization.csv.standard_deviation', { sigma: '\u03C3', interpolation: { escapeValue: false } }),
      I18n.t('components.visualization.csv.variance', { sigma: '\u03C3', squared: '\u00B2', interpolation: { escapeValue: false } }),
      I18n.t('components.visualization.csv.min'),
      I18n.t('components.visualization.csv.max'),
      I18n.t('components.visualization.csv.sum'),
      I18n.t('components.visualization.csv.missing'),
      I18n.t('components.visualization.csv.kurtosis'),
      I18n.t('components.visualization.csv.skewness')
    ]
  }

  /**
   * Return a list containing translated table headers for categorical variables
   */
  getCategoricalSummaryColumns () {
    return [
      I18n.t('components.visualization.csv.name'),
      I18n.t('components.visualization.csv.n_categories'),
      I18n.t('components.visualization.csv.most_frequent'),
      I18n.t('components.visualization.csv.most_frequent_count'),
      I18n.t('components.visualization.csv.categories'),
      I18n.t('components.visualization.csv.missing')
    ]
  }

  /**
   * Return a list containing translated table headers for other variables variables
   */
  getOtherVariableColumns () {
    return [
      I18n.t('components.visualization.csv.name'),
      I18n.t('components.visualization.csv.type'),
      I18n.t('components.visualization.csv.missing')
    ]
  }

  getRowsByType (descriptions, type, variableNames = undefined) {
    return descriptions.map(
      (entry, idx) => [idx, entry]
    ).filter(
      (entryWithIndex) => {
        if (variableNames) {
          return variableNames.includes(entryWithIndex[1].name) && entryWithIndex[1].type === type
        }
        return entryWithIndex[1].type === type
      }
    )
  }

  /**
   * Returns summary information about numerical columns in the CSV: each row describes a single column
   */
  getNumericSummaryRows (variableNames = undefined) {
    const numericDescriptions = this.getRowsByType(this.descriptions, NUMERIC_TYPE, variableNames)

    return numericDescriptions.map(([_idx, description]) => {
      const nRows = typeof this.summary.n_rows === 'number' ? this.summary.n_rows : 0
      return [
        description.name,
        description.mean,
        description.median,
        description.sd,
        description.variance,
        description.min,
        description.max,
        description.sum,
        this.getMissingValueRepresentation(nRows, description.missing),
        description.kurtosis,
        description.skewness
      ]
    })
  }

  /**
   * Returns summary information about the categorical columns in the CSV: each row describes a single column
   */
  getCategoricalSummaryRows (variableNames = undefined) {
    const categoricalDescriptions = this.getRowsByType(this.descriptions, CATEGORICAL_TYPE, variableNames)

    return categoricalDescriptions.map(([_idx, description]) => {
      const histogram = description.histogram
      const categories = description.categories
      const [sortedCats, sortedHist] = sortTogether(categories, histogram).map(a => a.reverse())

      return [
        description.name,
        description.n_categories,
        ...this.getMostFrequent(sortedHist, sortedCats),
        this.getCategoriesRepresentation(description.name, sortedHist, sortedCats, this.summary.n_rows),
        this.getMissingValueRepresentation(this.summary.n_rows, description.missing)
      ]
    })
  }

  /**
   * Returns a single row containing general information about the CSV
   */
  getGeneralSummaryRows () {
    return [
      ['Rows', this.summary.n_rows],
      ['Columns', this.summary.n_cols]
    ]
  }

  /**
   * Returns summary information for columns that are neither numerical nor categorical: 1 row describes a column
   */
  getOtherVariableSummaryRows () {
    const otherDescriptions = this.descriptions.map(
      (entry, idx) => [idx, entry]
    ).filter(
      (entryWithIndex) => entryWithIndex[1].type !== CATEGORICAL_TYPE && entryWithIndex[1].type !== NUMERIC_TYPE
    )

    return otherDescriptions.map(([_idx, description]) => {
      return [
        description.name,
        description.type,
        this.getMissingValueRepresentation(this.summary.n_rows, description.missing)
      ]
    })
  }

  /**
   * Returns a representation for the categories present in the given column. If the number of categirues
   * is greater than 5 it returns a modal link to the full list of categories. Otherwise, the categories are
   * returned as a list of <div>
   */
  getCategoriesRepresentation (column, hist, categories, nRows) {
    const categoryRepresentation = categories.map(
      (category, idx) => <div key={idx}>{`${category} (${parseFloat(((hist[idx] / nRows) * 100).toFixed(2))}%)`}</div>
    )

    if (categories.length <= 5) {
      return categoryRepresentation
    } else {
      return (
        <ModalLink
          modalComponent={CategoriesModal}
          linkText={I18n.t('components.visualization.csv.see_categories')}
          column={column}
          categoryRepresentation={categoryRepresentation}
        />
      )
    }
  }

  /**
   * Returns a string representation of the percentage of missing values given the total
   * number of rows and the amount of missing values
   */
  getMissingValueRepresentation = (nRows, nMissingValues) => {
    if (nRows === 0) {
      // return default string to prevent division by 0 error
      return '0% (0)'
    }
    return `${parseFloat(((nMissingValues / nRows) * 100).toFixed(2))}% (${nMissingValues})`
  }

  /**
    * Returns the most frequent element and its count given a sorted histogram
    */
  getMostFrequent (histogram, categories) {
    return [categories[0], histogram[0]]
  }
}

const CsvDetailView = ({ id, data }) => {
  const { data: rows, fetched, error } = useFetchData(`/api/v1/data/${id}/csv_rows`, { from: 0, to: MAX_ROWS })
  useDisplayFetchErrors([error], fetched, I18n.t('components.visualization.csv.errors.fetch_rows'))
  const csv = useMemo(() => { return new CsvDetailBuilder(data, rows) }, [rows])
  return (
    <>
      {// eslint-disable-next-line camelcase
        data?.invalid_encoding &&
          <InfoNotice msg={I18n.t('components.visualization.csv.errors.encoding')} className='margin-top' />
      }
      <Table
        title={I18n.t('components.visualization.csv.title.general_info')}
        rows={csv.getGeneralSummaryRows()}
      />
      <Table
        title={I18n.t('components.visualization.csv.title.numerical_variables')}
        columns={csv.getNumericSummaryColumns()}
        rows={csv.getNumericSummaryRows()}
      />
      <Table
        title={I18n.t('components.visualization.csv.title.other_variables')}
        columns={csv.getOtherVariableColumns()}
        rows={csv.getOtherVariableSummaryRows()}
      />
      <Table
        title={I18n.t('components.visualization.csv.title.categorical_variables')}
        columns={csv.getCategoricalSummaryColumns()}
        rows={csv.getCategoricalSummaryRows()}
      />
      <SpinnerWrapper ready={fetched} failed={!!error}>
        {
          fetched && !error &&
            <Table
              title={I18n.t('components.visualization.csv.title.preview')}
              columns={csv.getDataColumns()}
              rows={csv.getDataRows(MAX_ROWS)}
            />
        }
      </SpinnerWrapper>
    </>
  )
}

CsvDetailView.propTypes = {
  id: PropTypes.number.isRequired
}

export default WithErrorBoundary(CsvDetailView, 'components.visualization.csv.errors.detailview')
