import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import I18n from 'i18n'
import Info from '../../atomic/atoms/Info'

function EditApiTokens (props) {
  const [mustRenderCreateForm, setMustRenderCreateForm] = useState(false)
  const [description, setDescription] = useState('')

  // ComponentDidMount
  useEffect(() => {
    M.updateTextFields()
  }, [])

  function addToken () {
    props.add(description)
    setDescription('')
    setMustRenderCreateForm(false)
  }

  function handleDescriptionChange (event) {
    setDescription(event.target.value)
  }

  function handleButtonPress () {
    if (mustRenderCreateForm) {
      addToken()
    } else {
      setMustRenderCreateForm(true)
    }
  }

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <div className='text-heavy text-l text-muted data-header'>
            {I18n.t('components.api_tokens.title')}
            <Info text={I18n.t('components.api_tokens.personal_token_info')} tooltipId='api-token-title-tooltip' />
          </div>
        </div>
      </div>
      {props.apiTokens.length > 0 && (
        <div className='row'>
          <div className='col s12'>
            {
              props.apiTokens.map(apiToken => (
                <div className='row' key={`token-${apiToken.id}`}>
                  <div className='col s3 text-heavy'>Token</div>
                  <div className='col s7 x-scrollable'>{apiToken.token}</div>
                  <div
                    className='col s2 text-right pointer text-muted' title={I18n.t('components.api_tokens.copy')}
                    onClick={() => navigator.clipboard.writeText(apiToken.token)}
                  ><FontAwesomeIcon icon='copy' />
                  </div>

                  <div className='col s3 text-heavy'>{I18n.t('components.api_tokens.description')}</div>
                  <div className='col s7'>{apiToken.description}</div>
                  <div className='col s2 text-right pointer text-muted' title={I18n.t('components.api_tokens.delete')} onClick={() => props.delete(apiToken.id)}>
                    <FontAwesomeIcon icon='trash' />
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      )}
      {mustRenderCreateForm && (
        <div className='row'>
          <div style={{ display: 'flex' }}>
            <div className='input-field col s12'>
              <input id='api-token-description-field' type='text' onChange={e => handleDescriptionChange(e)} />
              <label htmlFor='api-token-description-field'>
                {I18n.t('components.api_tokens.description')}
              </label>
            </div>
          </div>
        </div>
      )}
      <div className='row'>
        <div className='col s12'>
          <button
            id='create-api-token'
            className='button-primary background-primary button-autowidth waves-effect waves-light text-background-color'
            onClick={handleButtonPress}
          >
            {mustRenderCreateForm && (
              <>
                <FontAwesomeIcon icon='check' className='button-icon' />
                {I18n.t('components.api_tokens.save')}
              </>
            )}
            {!mustRenderCreateForm && (
              <>
                <FontAwesomeIcon icon='plus' className='button-icon' />
                {I18n.t('components.api_tokens.add')}
              </>
            )}
          </button>
          {mustRenderCreateForm && (
            <button
              className='button-primary text-primary-color background-background button-autowidth waves-effect waves-light'
              onClick={() => setMustRenderCreateForm(false)}
            >
              {I18n.t('components.api_tokens.cancel')}
            </button>
          )}
        </div>
      </div>
      {/* // They wanted this removed from the frontend
      <div className='row'>
        <div className='col s12'>
          <div className='text-heavy text-l text-muted data-header'>
            {I18n.t('components.api_tokens.jupyter_hub_title')}
            <Info text={I18n.t('components.api_tokens.jupyterhub_token_info')} tooltipId='jupyterhub-title-tooltip' />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <button
            className='button-primary background-primary button-autowidth waves-effect waves-light text-background-color'
            onClick={props.onUpdateJHubToken}
          >
            <FontAwesomeIcon icon='sync-alt' className='button-icon' />
            {I18n.t('components.api_tokens.refresh')}
          </button>
        </div>
      </div>
      */}
    </>
  )
}

export default EditApiTokens
