import React from 'react'
import { useHistory } from 'react-router-dom'
// The react-router api has changed and now it only lets you use withRouter from within <Router>. In order to inject
// navigation history into a class-component, you can use this HOC instead

const WithHistory = (Component) => {
  return (props) => {
    const history = useHistory()

    return (
      <Component {...props} history={history} />
    )
  }
}

export default WithHistory
