import React from 'react'
import I18n from 'i18n'
import classNames from 'classnames'

import { Link } from 'react-router-dom'
import { defaultProfilePicture } from './Constants'

export default class HoverableProfile extends React.Component {
  constructor () {
    super()
    this.state = { show: false }
  }

  handleShow () {
    this.setState({ show: true })
  }

  handleHide () {
    this.setState({ show: false })
  }

  render () {
    const { profile, avatarOnly } = this.props
    const displayName = `${profile.first_name} ${profile.last_name}`
    if (avatarOnly) {
      return (
        <img src={profile.picture || defaultProfilePicture} alt={displayName} title={displayName} className='circle avatar-overlapping' />
      )
    }
    return (
      <Link to={profile.public_url || '/'} className={classNames(this.props.noWrap && 'hoverable-profile-link')}>
        <span onMouseLeave={this.handleHide.bind(this)} onMouseEnter={this.handleShow.bind(this)} className='hoverable-profile-wrapper pointer'>
          <span>{displayName}</span>
          <div className={`hoverable-profile ${(!this.state.show && 'hidden') || ''}`}>
            <ul className='collection no-margin'>
              <li className='collection-item no-margin'>
                <div className='row no-margin valign-wrapper'>
                  <div className='col s2 no-padding'>
                    <img src={profile.picture || defaultProfilePicture} className='responsive-img circle' alt={displayName} />
                  </div>
                  <div className='col s10 text-responsive' style={{ marginLeft: '10px' }}>
                    {`${profile.first_name || I18n.t('profile.show.first_name')} ${profile.last_name || I18n.t('profile.show.last_name')}`}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </span>
      </Link>
    )
  }
}
