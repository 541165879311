import React from 'react'
import PremiumBadge from '../../atomic/atoms/PremiumBadge'
import I18n from 'i18n'
import { get } from 'lodash'
import Subscription, { SubscriptionType, SUPER_PREMIUM } from 'components/common/types/Subscription'

interface Props {
  subscription: Subscription
}

const SubscriptionBanner = (props: Props): React.ReactElement => {
  const { subscription } = props
  const type = get(subscription, 'type', SubscriptionType.FREE)
  function color (): string {
    switch (type) {
      case SubscriptionType.FREE:
        return 'banner-greyed-out'
      default:
        return 'banner-subscribed'
    }
  }

  return (
    <>
      <div className='subscription-badge'>
        {type !== SubscriptionType.FREE &&
          <PremiumBadge isSuperPremium={type === SUPER_PREMIUM} />}
      </div>
      <div className={`subscription-banner ${color()}`}>
        <div id='text' className='subscription-title center-align text-xl text-bold'>
          {I18n.t(`premium.types.${type}`)}
        </div>
      </div>
    </>
  )
}

export default SubscriptionBanner
