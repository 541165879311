import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const AlertEntry = (props) => {
  useEffect(() => {
    M.updateTextFields()
  }, [])

  return (
    <>
      <div className='input-field col s12 m4'>
        <input
          id={props.id}
          name={props.id}
          type='text'
          defaultValue={props.defaultValue || ''}
          onChange={(e) => {
            props.onHandleUpdate(e)
          }}
        />
        <label htmlFor={props.id}>{props.name}</label>
      </div>
    </>
  )
}

const AlertInputList = (props) => {
  const renderMyAlertInputs = () => {
    const keys = Object.keys(props.alertThresholds)
    let elem
    return keys.map((key, idx) => {
      elem = props.alertThresholds[key]
      return <AlertEntry onHandleUpdate={props.handleUpdateThresholds} key={elem.key} id={elem.key} name={elem.name} defaultValue={elem.defaultThreshold} />
    })
  }

  return (<div className='row'>{renderMyAlertInputs()}</div>)
}

AlertInputList.propTypes = {
  handleUpdateThresholds: PropTypes.func.isRequired,
  alertThresholds: PropTypes.object.isRequired
}

export default AlertInputList
