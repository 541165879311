import React, { FC, ReactElement } from 'react'
import { Route } from 'react-router'
import useSdvNextHook from 'components/atomic/molecules/SdvNextToggle/index.hooks'

export interface NextRouteProps {
  path: string
  [x: string]: any
}

/**
 * This replaces the normal <Route> element for pages that are supported on SDV NExt.
 * Its a drop-in replacement, if you change <Route... /> to <NextRoute... /> it
 * will use SDV next when it is enabled on the profile page.
 */
const NextRoute: FC<NextRouteProps> = ({ path, ...props }): ReactElement => {
  const { sdvNextEnabled } = useSdvNextHook()

  // Fixes type check errors
  const safeHostUrl = process.env.HOST_URL === undefined ? '' : process.env.HOST_URL
  const safePath = path === undefined ? '/' : path

  if (sdvNextEnabled) {
    return (
      <Route
        path={path} component={() => {
          window.location.href = `${safeHostUrl}${safePath}`
          return null
        }}
      />
    )
  }

  return (<Route {...props} />)
}
NextRoute.defaultProps = {
}
export default NextRoute
