import { usePaginatedNotifications, usePaginatedUnreadNotifications } from 'components/backend/Queries'
import { QuestionnaireContext } from 'components/questionnaires/QuestionnaireProvider'
import { useContext } from 'react'
import Notification from 'components/common/types/Notification'
import I18n from 'i18n'
import _ from 'lodash'
import { useQueryClient } from 'react-query'
import { BackendContext } from 'components/backend/BackendProvider'
import { Pagination } from 'components/common/types/PaginatedResponse'

// Properties for grouping Notifications
const groupByProperties: Array<keyof Notification> = ['link', 'title', 'priority', 'read', 'icon']

/**
* Perform grouping of notifications based on the properties
* Function will return the number of occurance and the associated notification ids
**/
const groupByMultiProps = (notifications: Notification[], props: Array<keyof Notification>): Notification[] => {
  if (props.length === 0) { return notifications }
  const modObj = notifications.map((obj) => {
    const candidateKey = props.map((prop) => {
      return obj[prop]
    }).join('|')
    return { ...obj, groupId: candidateKey }
  })
  const groupObj = _.groupBy(modObj, 'groupId')
  return Object.keys(groupObj).map(key => {
    const ids = groupObj[key].map(obj => { return obj.id })
    groupObj[key][0].title = groupObj[key][0].title + (ids.length > 1 ? ` (${ids.length})` : '')
    return { ...groupObj[key][0], groupId: ids }
  })
}

/**
 * Create a 'notification' representation of an open questionnaire
 */
const buildQuestionnaireNotification = (questionnaires: any): Notification => {
  return {
    title: questionnaires.myOpenResponses[0]?.invitation_texts?.join(' & ') ?? I18n.t('components.notifications.questionnaire_available'),
    read: false,
    link: questionnaires.openQuestionnaireUrl(),
    id: -1,
    icon: 'sliders-h',
    created_at: new Date().toISOString()
  }
}

/*
* Get all notifications related to questionnaires. For now this is 1 or 0
* notifications.
*/
export const useQuestionnaireNotifications = (): Notification[] => {
  const { questionnaires } = useContext(QuestionnaireContext)

  if (questionnaires.areEnabled as boolean && questionnaires.hasOpenResponses as boolean) {
    return [buildQuestionnaireNotification(questionnaires)]
  }

  return []
}

interface UtilTypes {
  markNotificationAsRead: (arg0: number | number[]) => Promise<void>
  markAllNotificationsAsRead: () => Promise<void>
}

/*
* Provide functions to mark notifications as read that are shared among
* multiple notification hooks
*/
export const useNotificationUtils = (): UtilTypes => {
  const queryClient = useQueryClient()
  const { backend } = useContext(BackendContext)

  const refetch = (): void => {
    void queryClient.invalidateQueries('notificationsPaginated')
    void queryClient.invalidateQueries('notificationsUnreadPaginated')
  }

  /**
   * Mark a notification (or set of notifications) as read
   */
  const markNotificationAsRead = async (ids: number | number[]): Promise<void> => {
    // Don't try to mark questionnaire notifications as read
    if (ids === -1) return
    const data = { ids: Array.isArray(ids) ? ids.filter(id => id !== -1) : ids }

    await backend.notifications.markBulkNotificationRead(data)
    refetch()
  }

  /**
   * mark all notifications as read
   */
  const markAllNotificationsAsRead = async (): Promise<void> => {
    await backend.notifications.markNotificationsRead()
    refetch()
  }

  return { markNotificationAsRead, markAllNotificationsAsRead }
}

interface UnreadNotificationProps {
  refetchInterval: number
}

interface UnreadNotifications {
  notifications: Notification[]
  totalCount: number
  notificationsNotOnPage: number
}

/*
* Get all notifications used in the notification dropdown in the header
* This hook:
*  - uses the paginated notification endpoint, and only gets the first page
*  - groups notifications that are similar and merges them into one notification
*  - only returns unread notifications
*  - prepends a questionnaire notification if there is one
*/
export const useUnreadNotifications = ({ refetchInterval }: UnreadNotificationProps): UnreadNotifications => {
  // fetch from page 1 continuously. other pages can be fetched in the modal
  const { data } = usePaginatedUnreadNotifications(1, { refetchInterval })

  const paginatedNotifications = typeof data === 'undefined' ? [] : data.data
  const groupedNotifications = groupByMultiProps(paginatedNotifications, groupByProperties)

  const questionnaireNotifications = useQuestionnaireNotifications()

  const notifications = [...questionnaireNotifications, ...groupedNotifications]

  const totalCount = questionnaireNotifications.length + ((data != null) ? data.page.total_count : 0)

  // calculate the notifications that are not included in the the set of notifications returned by this hook
  const notificationsNotOnPage = totalCount - (questionnaireNotifications.length + paginatedNotifications.length)

  return { notifications, totalCount, notificationsNotOnPage }
}

interface LargeNotifications {
  notifications: Notification[]
  pagination?: Pagination['page']
}

/*
* Get all notifications used for the large notification modal
* This hook:
*  - uses the paginated notification endpoint
*  - prepends the questionnaire notifications (if there are any)
*  - returns all notifications (read and unread)
*/
export const useLargeNotifications = (page: number): LargeNotifications => {
  const { data } = usePaginatedNotifications(page, { keepPreviousData: true })
  const questionnaireNotifications = useQuestionnaireNotifications()
  const notifications = typeof data === 'undefined' ? [] : data.data

  if (page === 1) {
    return { notifications: [...questionnaireNotifications, ...notifications], pagination: data?.page }
  }

  return { notifications, pagination: data?.page }
}
