import React from 'react'
import I18n from 'i18n'
import PropTypes from 'prop-types'

import Info from '../../../atomic/atoms/Info'

// Informs which type of datasets are being used in the displayed dashboard
const InfoComplaintsIndicatorBar = (props) => {
  const { id } = props

  // Array with the keys of the color types, as they are in the translation file
  const colors = ['grey', 'red', 'green']

  let text = `${I18n.t('components.dashboards.complaints_indicator_info.introduction')}<br />`
  colors.forEach((type) => {
    text += `- ${I18n.t(`components.dashboards.complaints_indicator_info.types.${type}`)}<br />`
  })

  return (
    <Info text={text} tooltipId={id} multiline />
  )
}

InfoComplaintsIndicatorBar.propTypes = {
  id: PropTypes.string.isRequired,
  // Array with the keys of the data types, as they are in the translation file
  colors: PropTypes.arrayOf(PropTypes.string)
}

export default InfoComplaintsIndicatorBar
