import React from 'react'
import TimelineEntry from './TimelineEntry'
import TimelinePlaceholder from './TimelinePlaceholder'
import WelcomeController from '../../welcome/WelcomeController'
import TimelineTabs from '../TimelineTabs'
import Spinner from '../../common/Spinner'
import { waypointThreshold, events } from '../../common/Constants'
import { Waypoint } from 'react-waypoint'
import { WithSession } from '../../session/SessionProvider'
import { WithModal } from '../../modal/ModalProvider'
import Uploader from '../../data/layout/Uploader'
import ReactGA from 'react-ga'
import I18n from 'i18n'
import TimelineRefreshReminder from './TimelineRefreshReminder'
import Search from '../../common/Search'
import TimelinePendingJobs from './TimelinePendingJobs'
import { gAEnabled } from '../../common/Utils'
import FeatureFlag, { BACKGROUND_JOBS_INFO_CARD } from '../../feature_flags/FeatureFlag'
import Tour, { HOME_PAGE_STEPS } from '../../atomic/organisms/Tour'
import { WithAppsignal } from 'components/appsignal/AppsignalProvider'
import { WithBackend } from 'components/backend/BackendProvider'
import WithProfileMetadata from '../../helpers/WithProfileMetadata'

class DataUploadButton extends React.Component {
  render () {
    return (
      <button
        id='timeline-upload-data'
        className='button waves-effect waves-light button-accent text-background-color background-accent text-medium text-m button'
        onClick={this.props.onShowModal}
      >
        {I18n.t('components.welcome.add_data')}
      </button>
    )
  }
}

class Timeline extends React.Component {
  constructor (props) {
    super(props)

    this.backend = props.backend
    this.appsignal = props.appsignal
    this.tourId = 'timeline-tour'
    this.state = {
      tourStep: parseInt(window.localStorage.getItem(this.tourId) || 0),
      isContinuous: false // Always starts by showing the tour beacon
    }
  }

  componentDidMount () {
    this.props.onInit()
    if (this.props.showIntegrations) {
      this.props.setModalComponent(
        Uploader,
        { handleClose: this.onCloseUploadModal.bind(this) }
      )
    }
    this.updateCheckIteration = 0
    const checkForUpdates = () => {
      this.props.refetchSession()
      const timer = this.updateCheckIteration++ < 3 ? 10000 : 30000
      this.updateCheckTimeout = setTimeout(checkForUpdates, timer)
    }
    this.updateCheckTimeout = setTimeout(checkForUpdates, 1000)
    this.searchRef = React.createRef()
  }

  componentWillUnmount () {
    clearTimeout(this.updateCheckTimeout)
  }

  onCloseUploadModal () {
    this.props.history.replace('/')
  }

  showModal () {
    if (gAEnabled()) {
      ReactGA.event({
        category: events.category.button_click,
        action: 'DataUploadButton'
      })
    }
    this.props.setModalComponent(Uploader, {
      onRequest: this.props.onRequest,
      submitMethod: 'POST',
      endpoint: '/api/v1/data',
      onUploadComplete: this.props.onAfterUpload
    })
  }

  onRefresh () {
    this.backend.profiles.resetMyCache(
    ).then(() => {
      // Now that the cache has been reset, refetch the profile.
      this.props.refetchSession()
      this.props.refetchMyProfileMetadata()
    }).catch((err) => {
      console.log(err)
      this.appsignal.sendError(err)
    })
    this.props.onInit(this.props.network ? 'network' : 'own')
  }

  showRefreshReminder () {
    // If we are searching, then the total count no longer corresponds to the
    // dataset count. This is because the total count is the number of matches
    // for the search query, whereas the dataset count is just the number
    // of datasets regardless of search query.
    // So if a search query is in effect, we can no longer determine if the
    // timeline needs refreshing, so don't show the refresh reminder.
    if (this.props.isLoading ||
        typeof this.props.datasetCount !== 'number' ||
        (this.searchRef && this.searchRef.current && this.searchRef.current.value.length > 0)) return <></>
    const datasetDiff = this.props.datasetCount - this.props.total
    if (datasetDiff === 0) return <></>
    return (
      <TimelineRefreshReminder
        count={datasetDiff}
        onRefresh={this.onRefresh.bind(this)}
      />
    )
  }

  onTourUpdate (updateEvent) {
    const { newStep } = updateEvent

    try {
      window.localStorage.setItem(this.tourId, newStep)
    } catch (e) {
      console.error(e) // Either local storage is disabled or no more space is available there
    }

    this.setState({
      tourStep: newStep,
      isContinuous: true // Every interaction with the Tour should set the continuous mode to true.
    })
  }

  render () {
    const {
      metadata,
      metadataIdSeq,
      isLoading,
      data,
      lastContentFetch,
      isFetching,
      myProfileMetadata
    } = this.props

    const metadataIdArray = Array.from(metadataIdSeq || [])
    const noData = !isLoading && ((this.props.network && !myProfileMetadata.network_dataset_count) ||
                                  (!this.props.network && !myProfileMetadata.own_dataset_count))

    return (
      <>
        <Tour
          steps={HOME_PAGE_STEPS}
          stepIndex={this.state.tourStep}
          onStepChange={this.onTourUpdate.bind(this)}
          continuous={this.state.isContinuous}
          showProgress
        />
        <div className='row'>
          <div className='col s12 m4 l4 welcome-left-menu'>
            <WelcomeController
              ModalButton={DataUploadButton}
              modalButtonProps={{ onShowModal: this.showModal.bind(this) }}
            />
          </div>
          <div className='col s12 m8 l8'>
            <TimelineTabs
              history={this.props.history}
              location={this.props.location}
              myDataObjectCount={myProfileMetadata.own_dataset_count || 0}
              networkDataObjectCount={myProfileMetadata.network_dataset_count || 0}
            />
          </div>
          <div className='col s12 m8 timeline-cards'>
            {
              this.props.network &&
                <Search
                  useRef={this.searchRef}
                  id='network-timeline-search'
                  onSearch={this.props.handleTimelineFilter}
                  label={I18n.t('components.timeline.search')}
                />
            }
            {this.showRefreshReminder()}

            <FeatureFlag name={BACKGROUND_JOBS_INFO_CARD}>
              <TimelinePendingJobs profileMetadata={this.props.myProfileMetadata} />
            </FeatureFlag>

            {metadataIdArray.map((id) => (
              <TimelineEntry
                key={id}
                isFetching={isFetching[metadata[id].versioned_data_object_id]}
                onRefetchMetaData={this.props.onRefetchMetaData}
                fetchedAt={lastContentFetch[metadata[id].versioned_data_object_id]}
                metadatum={metadata[id]}
                data={data[metadata[id].versioned_data_object_id]}
                onFetchContent={this.props.onFetchContent}
                minRefetchDelay={this.props.minRefetchDelay}
                onShowGroupDetails={this.props.onShowGroupDetails}
                myProfile={this.props.myProfile}
                showCompact={false}
                history={this.props.history}
              />
            ))}
            {noData && <TimelinePlaceholder network={this.props.network} />}
            {!isLoading && !noData && this.props.total === 0 && this.searchRef && this.searchRef.current && this.searchRef.current.value.length > 0 && <p className='center-align'>{I18n.t('components.timeline.no_items_match_the_search_query')}</p>}
            {isLoading && <Spinner />}
            {!isLoading && (
              <Waypoint threshold={waypointThreshold} onEnter={this.props.onNextPage} />
            )}
          </div>
        </div>
      </>
    )
  }
}

export default WithAppsignal(WithBackend(WithSession(WithModal(WithProfileMetadata(Timeline)))))
