import React, { useState } from 'react'
import I18n from 'i18n'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MyAxios as axios } from '../../../MyAxios'
import { WithSession } from '../../../session/SessionProvider'
import { WithFlashMessages } from '../../../flashmessages/FlashMessageProvider'
import PropTypes from 'prop-types'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Info from '../../../atomic/atoms/Info'

const SnapshotCreator = (props) => {
  const [name, setName] = useState('')

  const handleCreate = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: props.sessionToken
    }
    const data = { snapshot: { name: name, vdo_ids: props.selectedItems.map(curItem => curItem.id) } }
    axios({ method: 'POST', url: '/api/v1/snapshots', headers, data: data })
      .then((_response) => {
        props.flashMessages.push(
          I18n.t('flashmessages.snapshots.successful'),
          props.flashMessages.duration.SHORT,
          props.flashMessages.levels.INFO
        )
        props.setSelectedItems([])
        setName('')
        document.getElementById('name').blur()
        document.getElementById('name-lbl').className = ''
      })
      .catch(error => {
        console.error(error)
        props.flashMessages.push(
          I18n.t('flashmessages.snapshots.failed'),
          props.flashMessages.duration.SHORT,
          props.flashMessages.levels.ERROR
        )
      })
  }

  const disabled = name.replace(/\s/g, '').length === 0 || props.selectedItems.length <= 0
  const createTip = disabled ? I18n.t('components.data.overview.snapshot_creator.create_tip') : undefined

  const removeItem = (itemId) => {
    props.setSelectedItems(props.selectedItems.filter(curItem => curItem.id !== itemId))
  }

  return (
    <div className='background-background data-overview-card'>
      <div className='row'>
        <div
          className='text-heavy text-primary-color text-xl col s12'
        >{I18n.t('components.data.overview.snapshot_creator.title')}
          <Info text={I18n.t('components.data.overview.snapshot_creator.tooltip')} tooltipId='snapshots-tooltip' />
        </div>
        <div className='col s12 l6'>
          <div className='input-field'>
            <label id='name-lbl' htmlFor='name'>{I18n.t('components.snapshots.name')}</label>
            <input
              id='name' type='text' value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 l6'>
          <label>{I18n.t('components.data.overview.snapshot_creator.selected_files')}</label>
          <ul className='collection files'>
            <TransitionGroup className='data-files'>
              {props.selectedItems.map(selectedItem => {
                return (
                  <CSSTransition
                    key={selectedItem.id}
                    timeout={200}
                    classNames='item'
                    appear
                  >
                    <div>
                      <li key={selectedItem.id} className='collection-item'>
                        <div>
                          {selectedItem.title}
                          <span
                            className='secondary-content'
                            title={I18n.t('components.data.overview.snapshot_creator.remove')}
                            onClick={() => removeItem(selectedItem.id)}
                          >
                            <FontAwesomeIcon icon='times' className='list-icon' />
                          </span>
                        </div>
                      </li>
                    </div>
                  </CSSTransition>
                )
              })}
            </TransitionGroup>
          </ul>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          {/* NOTE: the tooltip on the button below does not work because tooltips do not work on disabled elements. */}
          <button
            id='create-current-snapshot'
            disabled={disabled}
            className={classNames('data-button button-primary background-primary waves-effect waves-light text-background-color inline-block', disabled && 'button-disabled')}
            onClick={() => handleCreate()}
            data-tip={createTip}
            data-for='higher-z-tooltip'
          >
            <FontAwesomeIcon icon='file-archive' className='button-icon' />
            {I18n.t('components.snapshots.create')}
          </button>
        </div>
      </div>
    </div>
  )
}

SnapshotCreator.propTypes = {
  selectedItems: PropTypes.array.isRequired,
  setSelectedItems: PropTypes.func.isRequired
}

export default WithFlashMessages(WithSession(SnapshotCreator))
