import React, { useEffect, useState } from 'react'
import I18n from 'i18n'
import BackButton from '../../common/BackButton'
import { termsVersions } from '../../common/Constants'
import queryString from 'query-string'

const TermsAndConditions = (props) => {
  /* Fallback to latest version */
  const [termsVersion, setTermsVersion] = useState(process.env.TERMS_AND_CONDITIONS_LATEST)
  useEffect(() => {
    /* Retrieve the version (date) from url, and set te correct version of ToS */
    const url = window.location.search
    const urlParams = queryString.parse(url)
    /* use the version string in URL to set the correct terms version. Only allow versions
     * that are explicitly defined in termsVersions constant, otherwise keep default verison (latest) */
    urlParams.v in termsVersions && setTermsVersion(termsVersions[urlParams.v])
  }, [window.location.search])

  return (
    <div className='static-page-wrapper'>
      <div className='row'>
        <div className='col s12'>
          <BackButton {...props} />
        </div>
        <div className='col s12'>
          <div dangerouslySetInnerHTML={{ __html: I18n.t(`components.static.terms_and_conditions_${termsVersion}`) }} />
        </div>
      </div>
    </div>
  )
}

export default TermsAndConditions
