import React, { useMemo } from 'react'
import I18n from 'i18n'
import GroupOverviewDTO from '../../../common/types/DTOs/GroupOverviewDTO'
import { ColDef, GridOptions } from 'ag-grid-community'
import AgGridTable from '../../../atomic/organisms/AgGridTable'

interface Props {
  groupOverview: GroupOverviewDTO
}

const GroupOverviewTable: React.FunctionComponent<Props> = (props: Props) => {
  // Use memo because we have to define it in class because we need the i18n with the proper locale.
  const columnDefs: GridOptions['columnDefs'] = useMemo(() => {
    const protocolKeys = new Set<string>()
    props.groupOverview.by_member
      .forEach(member => Object.keys(member.protocols).forEach(key => protocolKeys.add(key)))

    const protocolCounts: ColDef[] = []

    protocolKeys.forEach((key: string) => protocolCounts.push({
      field: key,
      headerName: I18n.t(`group.detail.questionnaires.questionnaires.${key}`),
      headerTooltip: I18n.t(`group.detail.questionnaires.questionnaires.${key}`),
      valueGetter: (params) => params.data.protocols[key] ?? 0,
      minWidth: 150
    }))

    return [
      {
        field: 'member',
        initialPinned: 'left',
        /* checkboxSelection: true, */
        headerName: I18n.t('network.group.overview.members'),
        filter: 'agTextColumnFilter',
        initialWidth: 200,
        suppressSizeToFit: true,
        initialSort: 'asc'
      },
      {
        headerName: I18n.t('network.group.overview.types_of_data'),
        groupId: 'data-group',
        children: [
          {
            field: 'protocols_total',
            headerName: `${I18n.t('network.group.overview.protocols') as string} ⓘ`,
            filter: 'agNumberColumnFilter',
            minWidth: 150,
            headerTooltip: I18n.t('network.group.overview.protocols_tooltip')
            /* columnGroupShow: 'open' */
          },
          {
            field: 'sport_data',
            headerName: `${I18n.t('network.group.overview.sport_data') as string} ⓘ`,
            filter: 'agNumberColumnFilter',
            minWidth: 130,
            headerTooltip: I18n.t('network.group.overview.sport_data_tooltip')
          },
          {
            field: 'unstructured',
            headerName: `${I18n.t('network.group.overview.unstructured') as string} ⓘ`,
            filter: 'agNumberColumnFilter',
            minWidth: 170,
            headerTooltip: I18n.t('network.group.overview.unstructured_tooltip')
          }
        ]
      },
      {
        headerName: I18n.t('network.group.overview.protocols'),
        groupId: 'protocols',
        children: protocolCounts
      },
      {
        field: 'total',
        /* initialPinned: 'right', */
        headerName: I18n.t('network.group.overview.total'),
        filter: 'agNumberColumnFilter',
        minWidth: 150
      }
    ]
  }, [props.groupOverview.by_member])

  return (
    <AgGridTable columnDefs={columnDefs} rowData={props.groupOverview.by_member} />
  )
}

export default GroupOverviewTable
