import React from 'react'
import { cloneDeep, findIndex } from 'lodash'

import { convertLaps, getDefaultMetrics } from '../../../../common/Units'
import { lapDistance, lapDuration } from '../../../../common/utils/ActivityDashboardUtils'
import { getFastestLap } from '../CoachDashboardHelper'
import Table from 'components/atomic/molecules/Table'

const TrainingSessionLaps = (props) => {
  const { activity } = props
  const { vdo, data } = activity
  const { laps, collection_type: collectionType } = vdo?.summary || {}

  const addDurationToLapSummary = (lap, timeSeries) => {
    if (!lap?.summaries || !timeSeries?.offset) return

    lap.summaries.duration = {
      total: lapDuration(lap, timeSeries.offset) // Shouldn't this be a responsibility of the backend?
    }
  }

  const { metricsForSeries: metrics } = getDefaultMetrics(collectionType) || {}
  const fastestLap = cloneDeep(getFastestLap(laps))
  const fastestLapNumber = 1 + findIndex(laps, (lap) => lap?.start_index === fastestLap?.start_index)
  addDurationToLapSummary(fastestLap, data)
  const lapsWithUnits = convertLaps([fastestLap], metrics)

  const lapFields = [
    { kind: 'values', name: 'components.dashboards.laps_table.lap', values: [fastestLapNumber] },
    { kind: 'fn', name: 'components.dashboards.laps_table.distance', fn: lapDistance },
    { kind: 'path', name: 'components.dashboards.laps_table.time', path: 'summaries.duration.total' },
    { kind: 'path', name: 'components.dashboards.laps_table.avg_speed', path: 'summaries.speed.mean' },
    { kind: 'path', name: 'components.dashboards.laps_table.max_speed', path: 'summaries.speed.max' },
    { kind: 'path', name: 'components.dashboards.laps_table.avg_hr', path: 'summaries.hr.mean' },
    { kind: 'path', name: 'components.dashboards.laps_table.max_hr', path: 'summaries.hr.max' },
    { kind: 'path', name: 'components.dashboards.laps_table.avg_power', path: 'summaries.power.mean' },
    { kind: 'path', name: 'components.dashboards.laps_table.max_power', path: 'summaries.power.max' }
  ]

  if (!activity || !metrics || !lapsWithUnits || lapsWithUnits.length <= 0) {
    return <Table entries={[]} fields={lapFields} />
  }
  return <Table entries={lapsWithUnits} fields={lapFields} />
}

export default TrainingSessionLaps
