import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { omit } from 'lodash'
import { findIconDefinition, IconDefinition, IconLookup } from '@fortawesome/fontawesome-svg-core'

interface IconProp {
  icon: IconLookup
  id?: string // Id only necessary when there are repeated icons
}

interface Props {
  icons: IconProp[]
  combinedIconClass: string
  [index: string]: any
}

const CombinedFontAwesomeIcon = ({ icons, ...props }: Props): React.ReactElement => {
  const { combinedIconClass } = props
  const propsWithoutIconClass = omit(props, 'combinedIconClass')

  return (
    <div className='combined-icon-wrapper'>
      {icons.map((iconProp) => {
        const { icon: iconLookup, id = '' } = iconProp
        const iconDefinition: IconDefinition = findIconDefinition(iconLookup)
        return (
          <div key={`${id}-${iconLookup.iconName}`} className={combinedIconClass}>
            <FontAwesomeIcon icon={iconDefinition} {...propsWithoutIconClass} />
          </div>
        )
      })}
    </div>
  )
}

export default CombinedFontAwesomeIcon
