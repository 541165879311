import React from 'react'
import I18n from 'i18n'

export default class UploadOverview extends React.Component {
  getFileRendering () {
    let key
    const files = this.props.files.map((file, idx) => {
      key = `${file.name}-${idx}`
      return (
        <li key={key}>
          {file.name} - {file.size} bytes <span id='edit-metadata' className='pointer decoration-primary text-primary-color' onClick={() => this.props.toEditMetaData(this.props.vdoIds[idx])}>Edit metadata</span>
        </li>
      )
    })
    return files
  }

  render () {
    if (this.props.files.length === 0) {
      return (<></>)
    }
    return (
      <>
        <div className='row'>
          <div className='col s12'>
            <div className='text-heavy text-primary-color text-l'>
              {I18n.t('components.data.files')}
            </div>
            <ul>{this.getFileRendering()}</ul>
          </div>
        </div>
      </>
    )
  }
}
