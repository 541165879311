import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { hcInactiveIconColor } from '../../../../common/Constants'
import _ from 'lodash'
import I18n from 'i18n'

class QuestionnaireFeedbackTable extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      query: '',
      sortByColumn: this.props.columns[0].column,
      sortDescending: true
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!prevProps.data && this.props.data) {
      M.updateTextFields()
    }
  }

  sortData (data) {
    const sortAscending = this.state.sortAscending
    return data.sort((a, b) => {
      const aval = a[this.state.sortByColumn]
      const bval = b[this.state.sortByColumn]
      if (aval === bval) return 0
      const comparison = aval > bval ? 1 : -1
      return sortAscending ? comparison : (-1 * comparison)
    })
  }

  handleClick (column) {
    if (column === this.state.sortByColumn) {
      this.setState({ sortAscending: !this.state.sortAscending })
    } else {
      this.setState({ sortByColumn: column, sortAscending: true })
    }
  }

  renderSortIcon (column) {
    if (column !== this.state.sortByColumn) { return <FontAwesomeIcon style={{ marginLeft: '5px', color: hcInactiveIconColor }} icon='sort' /> }
    if (this.state.sortAscending) { return <FontAwesomeIcon style={{ marginLeft: '5px' }} icon='sort-up' /> }
    return <FontAwesomeIcon style={{ marginLeft: '5px' }} icon='sort-down' />
  }

  renderColumnHead (column) {
    return (
      <th key={`col-${column.column}`} onClick={(e => this.handleClick(column.column))} className='pointer' style={column.style}>
        {column.label}
        {this.renderSortIcon(column.column)}
      </th>
    )
  }

  formatValue (value) {
    if (value === undefined) return ''
    if (value instanceof Date) return value.toLocaleDateString('nl-NL')
    return value.toString()
  }

  itemMatches (item) {
    let matches = false
    const query = this.state.query.toLowerCase()
    this.props.columns.forEach(column => {
      if (this.formatValue(item[column.column]).toLowerCase().includes(query) || (column.tooltip_column && this.formatValue(item[column.tooltip_column]).toLowerCase().includes(query))) {
        matches = true
        return false
      }
    })
    return matches
  }

  filterData (data) {
    return data.filter(item => this.itemMatches(item))
  }

  renderDataItem (item) {
    return (
      <tr key={item.uuid}>
        {this.props.columns.map(column => <td key={`data-${column.column}`} title={column.tooltip_column && item[column.tooltip_column]}>{this.formatValue(item[column.column])}</td>)}
      </tr>
    )
  }

  handleChange (e) {
    this.setState({ query: e.target.value })
  }

  render () {
    if (!this.props.data) return (<></>)
    let data = Array.from(this.props.data)
    // Remove duplicates
    data = _.uniqBy(data, 'uuid')
    if (this.props.requiredAttribute) {
      data = data.filter(item => item[this.props.requiredAttribute] && item[this.props.requiredAttribute].length > 0)
    }
    if (this.state.query && this.state.query.length > 2) {
      data = this.filterData(data)
    }
    if (this.state.sortByColumn) {
      data = this.sortData(data)
    }
    return (
      <>
        <div className='input-field inline'>
          <input
            id={`search_inline_${this.props.id}`} type='text' value={this.state.query}
            onChange={this.handleChange.bind(this)}
          />
          <label htmlFor={`search_inline_${this.props.id}`}>{I18n.t('components.dashboards.questionnaire.search')}</label>
        </div>
        <table className='striped feedback-table'>
          <thead>
            <tr>
              {this.props.columns.map(column => this.renderColumnHead(column))}
            </tr>
          </thead>
          <tbody>
            {data.map(item => this.renderDataItem(item))}
          </tbody>
        </table>
      </>
    )
  }
}

export default QuestionnaireFeedbackTable
