import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import I18n from 'i18n'

const DropDownButton = (props) => {
  const { content, onClick } = props
  const [expanded, setExpanded] = useState(false)

  function showDropDown () {
    setExpanded(!expanded)
  }

  function useOutsideAlerter (ref) {
    useEffect(() => {
      function handleClickOutside (event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setExpanded(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return (
    <div
      className='text-muted'
      onClick={showDropDown}
      title={I18n.t('components.dashboards.drop_down.label')}
    >
      <FontAwesomeIcon icon='plus' size='lg' className='pointer-grow timeline-group-name-wrapper' />
      <div ref={wrapperRef}>
        {expanded &&
          <div style={{ position: 'absolute' }}>
            <ul className='dropdown'>
              {
                Object.entries(content).map(([key, value]) => {
                  return (
                    <li className='dropdown-item' key={value} onClick={() => onClick(value)}>
                      <span className='dropdown-item-span'>{I18n.t(`sports.${key}`)}</span>
                    </li>
                  )
                })
              }
            </ul>
          </div>}
      </div>

    </div>
  )
}

export default DropDownButton
