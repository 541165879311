import React from 'react'
import { MyAxios as axios } from '../MyAxios'
import NetworkRoutes from './NetworkRoutes'
import { WithSession } from '../session/SessionProvider'
import { regularGroups, groupTypes, roles } from '../common/Constants'

class Network extends React.Component {
  constructor () {
    super()
    this.state = {
      connections: [],
      followerCount: 0,
      groups: [],
      connectionRequests: [],
      groupInvites: [],
      connectionsRetrieved: false,
      pendingConnectionsRetrieved: false,
      query: ''
    }
  }

  fetchGroups () {
    return axios({
      method: 'GET',
      url: '/api/v1/groups',
      headers: {
        Authorization: this.props.sessionToken
      }
    })
  }

  fetch () {
    this.fetchGroups().then((groupsResponse) => {
      const groups = groupsResponse.data.filter(
        (group) => regularGroups.includes(group.group_type)).map(
        (group) => {
          group.admin = group.group_memberships.filter(
            (member) => member.role === roles.administrator
          )[0]
          return group
        }
      )
      const connections = groupsResponse.data.filter((group) => group.group_type === groupTypes.MUTUAL_CONNECTION && group.group_memberships.length === 2)
      const connectionCount = connections.count

      const followersGroup = groupsResponse.data.filter(
        (group) => group.group_type === groupTypes.FOLLOWERS
      )
      const followerCount = followersGroup.length === 1 ? followersGroup[0].group_memberships.filter((membership) => membership.profile.id !== this.props.myProfile?.id) : 0
      this.setState({ ...this.state, connectionCount, followerCount, groups, connections, connectionsRetrieved: true })
    }).catch(e => console.error(`Problem loading group information ${e}`))

    axios({
      method: 'GET',
      url: '/api/v1/groups/pending',
      headers: { Authorization: this.props.sessionToken }
    }).then((response) => {
      const connectionRequests = response.data.filter((group) => group.group_type === groupTypes.MUTUAL_CONNECTION)

      const groupInvites = response.data.filter((group) => regularGroups.includes(group.group_type))
      this.setState({ ...this.state, connectionRequests, groupInvites, pendingConnectionsRetrieved: true })
    })
  }

  findMyMembership (connection) {
    /* eslint-disable camelcase */
    return connection?.group_memberships.find(gm => gm.profile.id === this.props.myProfile?.id)
    /* eslint-enable camelcase */
  }

  handleFilterConnections (query) {
    this.setState({ ...this.state, query: query })
  }

  applyFilter (connections) {
    if (!this.state.query) {
      return connections
    }

    const query = this.state.query.replace(/\s/g, '').toLowerCase()

    return connections.filter((connection) => {
      return connection.group_memberships.filter((membership) => {
        if (membership.profile.id === this.props.myProfile?.id) {
          // exclude groups where own profile is only match
          return false
        }
        // concat first name + last name; remove spaces; convert to lowercase then match to query
        return `${membership.profile.first_name}${membership.profile.last_name}`.replace(/\s/g, '').toLowerCase().includes(query)
      }).length > 0
    })
  }

  render () {
    return (
      <NetworkRoutes
        groupsControllerProps={{
          fetch: this.fetch.bind(this),
          connections: this.state.connections,
          groupInvites: this.state.groupInvites,
          groups: this.state.groups,
          pendingConnectionsRetrieved: this.state.pendingConnectionsRetrieved,
          connectionsRetrieved: this.state.connectionsRetrieved
        }}
        connectionsControllerProps={{
          fetch: this.fetch.bind(this),
          connections: this.applyFilter(this.state.connections),
          connectionRequests: this.applyFilter(this.state.connectionRequests),
          groups: this.state.groups,
          pendingConnectionsRetrieved: this.state.pendingConnectionsRetrieved,
          connectionsRetrieved: this.state.connectionsRetrieved,
          onFilterConnections: this.handleFilterConnections.bind(this)
        }}
      />
    )
  }
}

export default WithSession(Network)
