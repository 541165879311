import React from 'react'

const LinkedDatasets = ({ datasets }) => (
  datasets?.map(dataset => (
    // For some reason Link changes the path but does not re-render the view
    <a key={dataset.id} className='margin-right' href={`/data/${dataset.id}`}>{dataset.title || dataset.id}</a>
  ))
)

export default LinkedDatasets
