import React from 'react'
import MetaData from '../../metadata/MetaData'
import VersionsView from './detail/VersionsView'
import I18n from 'i18n'
import BackButton from '../../common/BackButton'
import { Link } from 'react-router-dom'
import Spinner from '../../common/Spinner'

class EditData extends React.Component {
  componentDidMount () {
    const { match: { params: { dataId } } } = this.props
    this.props.loadData(dataId)
  }

  componentDidUpdate (prevProps, _prevState, _snapshot) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      const elems = document.querySelectorAll('.tabs')
      M.Tabs.init(elems)
    }
  }

  render () {
    if (!this.props.data || !this.props.data.versions) {
      return (
        <>
          <div className='container general-container'>
            <div className='col s12 m8 l8'>
              <div className='general-content'>
                <div className='col s12'>
                  <div className='row'>
                    <div className='col s12'>
                      <BackButton {...this.props} />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col s12'>
                      <Spinner ready={false} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    }
    /* eslint-disable camelcase */
    const { id, shared_with_jupyterhub } = this.props.data.versioned_data_object
    const sharedWithJupyter = shared_with_jupyterhub
    /* eslint-enable camelcase */
    return (
      <>
        <div className='container general-container'>
          <div className='col s12 m8 l8'>
            <div className='general-content'>
              <div className='col s12'>
                <div className='row'>
                  <div className='col s12'>
                    <BackButton {...this.props} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col s12'>
                    <ul className='tabs'>
                      <li id='file-details-view' className='tab col s4'><Link className='active' to='#file_details'>{I18n.t('components.data.tabs.file_details')}</Link></li>
                      <li id='versioning-view' className='tab col s4'><Link to='#version_management'>{I18n.t('components.data.tabs.version_management')}</Link></li>
                      <li id='advanced-view' className='tab col s4'><Link to='#advanced'>{I18n.t('components.data.tabs.advanced')}</Link></li>
                      <li style={{ display: 'none' }} id='loader-view' className='tab col s4'><Link to='#loader'>Loader</Link></li>
                    </ul>
                  </div>
                  <div id='loader' className='col s12 tab-content active'>
                    <Spinner ready={false} />
                  </div>
                  <div id='file_details' className='col s12 tab-content'>
                    <div className='row'>
                      <div className='text-heavy text-primary-color text-xl col s12'>
                        {I18n.t('components.data.tabs.edit_file_details')}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col s12'>
                        <MetaData {...this.props} vdoId={id} />
                      </div>
                    </div>
                  </div>
                  <div id='version_management' className='col s12 tab-content'>
                    <div className='row'>
                      <div className='text-heavy text-primary-color text-xl col s12'>
                        {I18n.t('components.data.tabs.version_management')}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col s12'>
                        <VersionsView {...this.props} />
                      </div>
                    </div>
                  </div>
                  <div id='advanced' className='col s12 tab-content'>
                    <div className='row'>
                      <div className='text-heavy text-primary-color text-xl col s12'>
                        {I18n.t('components.data.tabs.advanced')}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col s12'>
                        <div className='text-heavy text-l text-muted data-header'>{I18n.t('components.data.sharing')}</div>
                        <br />
                        <label>
                          <input
                            type='checkbox'
                            id='shared-with-jupyter-checkbox'
                            checked={(!!sharedWithJupyter && sharedWithJupyter) || false}
                            onChange={() => this.props.toggleShareWithJupyter(id)}
                          />
                          <span>{I18n.t('components.data.share_with_jupyter')}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default EditData
