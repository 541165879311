import React from 'react'
import { WithSession } from '../session/SessionProvider'
import { WithFlashMessages } from '../flashmessages/FlashMessageProvider'
import { WithBackend } from '../backend/BackendProvider'
import ShowAdminDashboard from './layout/ShowAdminDashboard'
import { errorToString } from '../common/ErrorHandling'
import I18n from 'i18n'

class AdminDashboard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      statistics: undefined,
      statisticsCurrentYear: undefined,
      oauthApplications: undefined,
      vouchers: undefined
    }
  }

  fetch () {
    this.props.backend.admin.getStatistics().then(res => {
      this.setState({ statistics: res.data })
    })
    this.props.backend.admin.getStatisticsCurrentYear().then(res => {
      this.setState({ statisticsCurrentYear: res.data })
    })
  }

  retrieveApplications () {
    this.props.backend.admin.listOauthApplications().then(res => {
      this.setState({ oauthApplications: res.data })
    })
  }

  retrieveVouchers () {
    this.props.backend.admin.listVouchers().then(res => {
      this.setState({ vouchers: res.data })
    })
  }

  handleCreateApplication (applicationDto) {
    this.props.backend.admin.createOauthApplication(applicationDto).then(res => {
      this.props.flashMessages.push(I18n.t('components.admin_dashboard.oauth_applications.create.success'), this.props.flashMessages.duration.SHORT, this.props.flashMessages.levels.INFO)
      this.retrieveApplications()
    }).catch(e => {
      this.props.flashMessages.push(errorToString(e), this.props.flashMessages.duration.LONG, this.props.flashMessages.levels.ERROR)
    })
  }

  handleCreateVoucher (voucherDto) {
    this.props.backend.admin.createVoucher(voucherDto).then(res => {
      this.retrieveVouchers()
    }).catch(e => {
      this.props.flashMessages.push(errorToString(e), this.props.flashMessages.duration.LONG, this.props.flashMessages.levels.ERROR)
      this.retrieveVouchers()
    })
  }

  handleDeleteVoucher (voucherId) {
    this.props.backend.admin.destroyVoucher(voucherId).then(res => {
      this.retrieveVouchers()
    }).catch(e => {
      this.props.flashMessages.push(errorToString(e), this.props.flashMessages.duration.LONG, this.props.flashMessages.levels.ERROR)
      this.retrieveVouchers()
    })
  }

  componentDidMount () {
    this.fetch()
    this.retrieveApplications()
    this.retrieveVouchers()
  }

  render () {
    return (
      <ShowAdminDashboard
        statistics={this.state.statistics}
        statisticsCurrentYear={this.state.statisticsCurrentYear}
        oauthApplications={this.state.oauthApplications}
        vouchers={this.state.vouchers}
        createApplication={this.handleCreateApplication.bind(this)}
        createVoucher={this.handleCreateVoucher.bind(this)}
        deleteVoucher={this.handleDeleteVoucher.bind(this)}
      />
    )
  }
}

export default WithBackend(WithFlashMessages(WithSession(AdminDashboard)))
