import React from 'react'
import WeFitterDefaultFlavour from './WeFitterDefaultFlavour'

const WeFitterDetails = (props) => {
  const sdos = props.data.structured_data_objects
  if (!sdos || sdos.length < 1) return <></>

  return (
    <WeFitterDefaultFlavour
      sdo={sdos[0]}
      icon={props.icon}
      metadatum={props.metadatum}
    />
  )
}

export default WeFitterDetails
