import React from 'react'
import SdvLogo from './images/sdv-logo.svg'
import I18n from 'i18n'

export default class FallbackComponent extends React.Component {
  render () {
    return (
      <div className='fallback-component-wrapper'>
        <div className='fallback-component-inner'>
          <div className='fallback-component'>
            <div id='bg_logo'>
              <img alt='Sport Data Valley' className='sdv-logo login-logo' src={SdvLogo} />
            </div>
            <div style={{ paddingTop: '20px' }}>
              <p>{I18n.t(`errors.${this.props.message || 'generic'}`)}</p><br />
              <p><a href='/'>{I18n.t('errors.return')}</a>.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
