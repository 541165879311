import I18n from 'i18n'
import { groupTagRegexExpression } from '../Constants'

import Group from '../types/Group'
import { smartTranslate } from '../Utils'

export interface GroupToTagMap {
  [groupId: string]: string
}

export const isGroupTag = (tag: string): boolean => groupTagRegexExpression.test(tag)

export const getPrettyGroupTag = (group: Group): string => {
  return I18n.t('components.tag.datasets_from_group', { groupname: group.name })
}

// Get a map between a groupID and its corresponding tag
export const createGroupToTagMap = (groups: Group[]): GroupToTagMap => {
  const groupToTagMap: { [groupId: string]: string } = {}
  // Fill the map with groupId : prettyTag
  groups.forEach((group) => {
    groupToTagMap[group.id.toString()] = getPrettyGroupTag(group)
  })
  return groupToTagMap
}

export const prettifyTag = (rawTag: string, groups: Group[]): string => {
  if (isGroupTag(rawTag)) {
    const groupToTagMap: GroupToTagMap = createGroupToTagMap(groups) // This is a bit inefficient to do in every call, but it gives more code clarity

    const groupId: string = rawTag.substring(2)
    return groupToTagMap[groupId] ?? rawTag
  } else {
    return smartTranslate(rawTag)
  }
}
