/**
 * Calculate the training load of an activity based on one metric (e.g. speed, power, hr, etc).
 * @param {number} metricMean The mean value of the given metric
 * @param {number} durationInSeconds The duration of the activity in seconds
 * @returns The value of training load
 */
export const getTrainingLoad = (metricMean, durationInSeconds) => {
  if (!metricMean || !durationInSeconds) {
    return null
  } else {
    return metricMean * (durationInSeconds / 60)
  }
}
