import React from 'react'
import GroupMembers from './GroupMembers'
import { defaultProfilePicture, groupTypes, membershipStates } from '../../common/Constants'
import I18n from 'i18n'

export default class Group extends React.Component {
  render () {
    const { group } = this.props
    if (!group) {
      return (<></>)
    }
    return (
      <div className='col s12 group-container'>
        <div className='network-group-container'>
          <div className='row' style={{ marginBottom: '0px' }}>
            <div className='col s2'>
              <img className='circle responsive-img' src={group.picture || defaultProfilePicture} />
            </div>
            <div className='col s10 l7'>
              <div className='group-name text-heavy text-primary-color text-l pointer' style={{ marginTop: '0.75rem' }} onClick={() => this.props.onShowDetails(group)}>
                {group.name}
              </div>
              <div className='text-m text-light'>
                <GroupMembers memberships={group.group_memberships.filter(membership => membership.state === membershipStates.ACCEPTED)} />
              </div>
              {group.group_type === groupTypes.ANONYMIZED && (
                <div className='text-s text-light text-muted'>
                  {I18n.t('network.groups.labels.anonymized')}
                </div>
              )}
            </div>
            <div className='col s12 l3 right-align'>
              <button
                onClick={() => this.props.onShowDetails(group)}
                className='group-button text-m waves-effect waves-light text-primary-color button-primary background-background'
                style={{ minWidth: '75px' }}
              >
                {I18n.t('network.group.label.open')}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
