/* The purpose of this file is to include functions that construct backend routes
   and returns those as strings. These functions do not belong in BackendProvider.jsx
   because those are Axios calls that are being executed, and no URL string is being returned.
   These functions do not belong in Routes.jsx either, since that file only includes
   front-end routes.
*/

export default {
  schedules: {
    exportCsvFn: (startDate: Date, endDate: Date, selectedAthleteIds: number[]): string => {
      // Can't use the URL() function here to construct this because we don't have a base URL.
      return `/api/v1/schedules/export.csv?start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}${selectedAthleteIds.map(athleteId => `&athletes[]=${athleteId}`).join('')}`
    }
  }
}
