import React from 'react'
import { Link } from 'react-router-dom'
import I18n from 'i18n'
import FeatureFlag, { TEST_FEATURE_FLAG } from '../../../feature_flags/FeatureFlag'
import Routes from '../../../common/Routes'

interface FooterProps {
  isLoggedIn: boolean
}

const Footer: React.FC<FooterProps> = ({ isLoggedIn }): React.ReactElement => {
  const hasFAQ = process.env.FAQ_URL !== undefined
  const linkClassName = `item col ${hasFAQ ? 'm2' : 'm3'} l2 s12 text-light overflow-text-elipsis`

  if (!isLoggedIn) {
    return <></>
  }

  return (
    <footer className='footer footer-copyright'>
      <div className='container'>
        <div className='row'>
          <div className='col m4 l4 s12' dangerouslySetInnerHTML={{ __html: I18n.t('components.footer.tagline') }} />
          <div className='col m2 l2 s12 compatible-with-strava' />
          <FeatureFlag name={TEST_FEATURE_FLAG} defaultChildren={<></>}><span>1</span></FeatureFlag>
          <Link className={linkClassName} id='privacy-statement' to={Routes.static.privacyStatement}>
            {I18n.t('login.link.privacy_statement')}
          </Link>
          <Link className={linkClassName} id='terms-and-conditions' to={Routes.static.termsAndConditionsLatest}>
            {I18n.t('login.link.terms_and_conditions')}
          </Link>
          {hasFAQ &&
            <a href={process.env.FAQ_URL} className={linkClassName} id='terms-and-conditions'>
              {I18n.t('login.link.faq')}
            </a>}
        </div>
      </div>
    </footer>
  )
}

export default Footer
