import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TimeAgoElement } from '../../common/TimeAgo'
import { defaultProfilePicture, dataObjectStages, skeletonLines, groupTypes } from '../../common/Constants'
import I18n from 'i18n'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import { Waypoint } from 'react-waypoint'
import DetailsWrapper from './details/DetailsWrapper'
import { collectionTypeToIcon } from '../../common/Utils'
import Routes from '../../common/Routes'
import _ from 'lodash'

export default class TimelineEntryFull extends React.Component {
  handleRefetch () {
    if (this.props.fetchedAt && new Date().getTime() - this.props.fetchedAt.timestamp > this.props.minRefetchDelay) {
      this.props.onFetchContent(this.props.metadatum.versioned_data_object_id)
    }
  }

  decorateWithGroup () {
    const group = _.get(this, 'props.metadatum.group')
    if (!group) return <></>

    if (group.group_type === groupTypes.MUTUAL_CONNECTION) {
      const otherProfile = group.group_memberships.filter(groupMembership => groupMembership.profile.id !== this.props.myProfile?.id)[0].profile
      return (
        <div className='timeline-group-name-wrapper text-muted text-s'>
          <span className='timeline-shared-by text-heavy'>{I18n.t('components.timeline.card.shared_by')}</span>
          <Link className='decoration-primary text-muted' to={otherProfile.public_url}>&nbsp;{otherProfile.first_name} {otherProfile.last_name}</Link>
        </div>
      )
    }
    return (
      <div className='timeline-group-name-wrapper text-muted text-s'>
        <span className='timeline-shared-by text-heavy'>{I18n.t('components.timeline.card.shared_in')}</span>
        <span className='pointer decoration-primary' onClick={() => this.props.onShowGroupDetails(group.id)}>&nbsp;{group.name}</span>
      </div>
    )
  }

  decorateWithVdoGroup () {
    // Do not decorate with vdo group if we are in network tab
    if (_.get(this, 'props.metadatum.group')) return <></>
    const vdoGroup = _.get(this, 'props.data.versioned_data_object.linked_vdos', [])
    // Do not decorate if vdo has no linked files
    if (vdoGroup.length === 0) return

    return (
      <div className='timeline-group-name-wrapper text-muted text-s max-width-250 overflow-text-elipsis no-breaks'>
        <span className='timeline-shared-by text-heavy'>{I18n.t('components.timeline.card.linked_with')}</span>
        <span className='pointer decoration-primary'>&nbsp;{vdoGroup[0].title}</span>
        <div>&nbsp;{I18n.t('components.timeline.card.et_al', { count: vdoGroup.length - 1 })}</div>
      </div>
    )
  }

  linkToDataObject (e) {
    if (e.target.classList.contains('clickable-card')) {
      this.props.history.push(Routes.data.showFn(this.props.metadatum && this.props.metadatum.versioned_data_object_id))
    }
  }

  determineIcon (metadatum, collectionType) {
    if (metadatum && metadatum.sport && metadatum.sport.icon) {
      if (metadatum.sport.icon === 'futbol') {
        return ['far', 'futbol']
      }
      return metadatum.sport.icon
    }
    return collectionTypeToIcon(collectionType) || 'question'
  }

  render () {
    /* eslint-disable camelcase */
    const { metadatum, data } = this.props
    const { versioned_data_object: { summary } = {} } = data || {}
    const collectionType = summary && summary.collection_type
    return (
      <>
        {/* The .clickablecard class is needed to prevent click bubbling from the other elements (e.g. the map) */}
        <div className='grow timeline-card clickable-card' onClick={this.linkToDataObject.bind(this)}>
          <div className='timeline-card-header'>
            {metadatum && (
              <div className='timeline-card-header decoration-primary'>
                <Link to={metadatum.owner.public_url}>
                  <img
                    className='circle responsive-img timeline-profile-picture'
                    src={metadatum.owner.picture || defaultProfilePicture}
                  />
                </Link>
                <div className='margin-right-on-small'>
                  <Link to={metadatum.owner.public_url}>
                    <p className='timeline-profile-details text-m'>
                      {`${metadatum.owner.first_name || I18n.t('profile.show.first_name')} ${metadatum.owner.last_name || I18n.t('profile.show.last_name')}`}
                    </p>
                  </Link>
                  <p className='timeline-profile-details text-s text-muted'>
                    <TimeAgoElement date={new Date(metadatum.event_start)} />
                  </p>
                </div>
              </div>
            )}
            <div className='timeline-icon center-align clickable-card'>
              <FontAwesomeIcon icon={this.determineIcon(metadatum, collectionType)} />
            </div>
          </div>
          <div className='timeline-card-content'>
            <Link to={Routes.data.showFn(metadatum && metadatum.versioned_data_object_id)}>
              <div className={`text-l text-heavy text-primary-color pointer timeline-title${metadatum && metadatum.group ? ' from-group' : ' '}`}>
                {(metadatum && (metadatum.title || metadatum.filename)) || I18n.t('components.data.untitled_file')}
              </div>
            </Link>
            {this.decorateWithGroup()}
            {this.decorateWithVdoGroup()}
            {(!data || data.versioned_data_object.data_type.data_type === undefined || !metadatum || (metadatum.stage !== dataObjectStages.brewed_stage && metadatum.stage !== dataObjectStages.failed_stage)) && (
              <Skeleton key={metadatum && metadatum.id} count={skeletonLines} />
            )}
            {metadatum && metadatum.stage === dataObjectStages.brewed_stage && data && data.versioned_data_object.data_type.data_type !== undefined && (
              <div className='timeline-card-details'>
                <DetailsWrapper vdo={data} />
              </div>
            )}
            <Waypoint onPositionChange={this.handleRefetch.bind(this)} />
          </div>
        </div>
      </>
    )
  }
}
/* eslint-enable camelcase */
