// Finds the index of the element in the array that is the closest to the value. Assumes that the array is sorted
// from low to high
export const findClosestIndexInSortedArray = (value: number, array: number[]): number => {
  let idx = -1
  let shortestDistance = Number.MAX_VALUE
  let lastValue = null
  let closestIndex = 0

  for (const el of array) {
    const currentDistance = Math.abs(value - el)
    const repeatedValue = lastValue === el
    if (currentDistance >= shortestDistance && !repeatedValue) break

    idx++

    if (repeatedValue) continue

    closestIndex = idx
    shortestDistance = currentDistance
    lastValue = el
  }

  return closestIndex
}
