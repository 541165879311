import { Moment } from 'moment'

export interface WeekInfo {
  value: string // the value is a code that contains the year and the week number
  start: Moment
  end: Moment
  label: string
}

// Helper function to create a week info object
export const createWeekInfo = (weekStart: Moment, weekEnd: Moment, weekValue: string, weekLabel: string): WeekInfo => ({
  value: weekValue,
  start: weekStart,
  end: weekEnd,
  label: weekLabel
})

// Type alias for WeekInfo value
type WeekValue = WeekInfo['value']

export interface WeekInfoDict {
  [index: WeekValue]: WeekInfo
}
