import React from 'react'

const SummaryTableMultiple = (props) => {
  const { fields, headers, rows } = props
  return (
    <table className='summary-table-multiple'>
      <thead>
        {fields && headers &&
          <tr>
            {fields.map((field) => <th key={field}>{headers[field]}</th>)}
          </tr>}
      </thead>

      <tbody>
        {rows?.map((row) => (
          <tr key={row.label}>
            {fields.map((field) => <td key={field}>{row[field]}</td>)}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default SummaryTableMultiple
