import React from 'react'
import DashboardPicker from '../common/DashboardPicker'
import Routes from '../../../common/Routes'

const RunningDashboardPicker = (props) => {
  const dashboards = [
    {
      id: 1,
      name: 'components.dashboards.riding.sub_dashboards.single',
      icon: 'running',
      enabled: true,
      abbrevName: 'running',
      cssKlass: 'dashboard-card-questionnaire',
      implemented: true,
      link: Routes.analysis.running.single.index
    }, {
      id: 2,
      name: 'components.dashboards.riding.sub_dashboards.trends',
      icon: 'calendar-week',
      enabled: true,
      abbrevName: 'running',
      cssKlass: 'dashboard-card-questionnaire',
      implemented: true,
      link: Routes.analysis.running.trends
    }
  ]

  return (
    <DashboardPicker dashboards={dashboards} {...props} />
  )
}

export default RunningDashboardPicker
