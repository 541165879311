import React, { createContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { cookieOptions, parseSessionToken, removeBearerFromToken } from './CookieUtils'
import Profile from 'components/common/types/Profile'
import { useHistory } from 'react-router'
import axios from 'axios'
import { I18nLoader } from 'components/I18nLoader'
import { get, isEmpty } from 'lodash'
import { useQueryClient } from 'react-query'

interface SessionValues {
  sessionToken: string
  myProfile: Profile
}

interface SessionContextProps {
  handleLogout: (redirect?: boolean) => void
  updateSession: (sessionValues: SessionValues) => void
  refetchSession: () => void
  session: {
    redirectToLoginIfNoSession: (redirectUri: string) => boolean
    isValid: () => boolean
    sessionToken?: string
  }
  myProfile?: Profile
  sessionToken?: string
}

export const SessionContext = createContext<SessionContextProps>({
  handleLogout: () => {},
  updateSession: (_sessionValues) => {},
  refetchSession: () => {},
  session: {
    redirectToLoginIfNoSession: (_redirectUri) => { return false },
    isValid: () => { return false },
    sessionToken: undefined
  },
  myProfile: undefined,
  sessionToken: undefined
})

export const SessionProvider: React.FC = (props) => {
  const { children } = props

  const queryClient = useQueryClient()
  const [myProfile, setMyProfile] = useState<Profile | undefined>(undefined)
  const history = useHistory()
  const [cookies, setCookie] = useCookies(['sessionToken'])
  const token: string | undefined = parseSessionToken(cookies.sessionToken)

  const updateTokenCookie = (token: string): void => {
    setCookie('sessionToken', token, cookieOptions())
  }

  const fixCookieEncoding = (token: string): string => {
    // If the token is already valid, just return it
    if (!token.startsWith('Bearer+')) return token

    // Replace the + with a space. Rails cookies are auto encoded, and it
    // encodes the ' ' as a '+', instead of a '%20'. Hence this correction.
    token = token.replace('Bearer+', 'Bearer ')

    // Make sure that the token in the cookies is also fine.
    updateTokenCookie(token)
    return token
  }

  const isValid = (): boolean => { return Boolean(token) }

  const redirectToLoginIfNoSession = (redirectUri: string = window.location.href): boolean => {
    if (isValid()) return false

    const encodedRedirectUri: string = encodeURIComponent(redirectUri)

    let url: string = '/login'
    if (url !== '') url += `?redirect_uri=${encodedRedirectUri}`
    history.push(url)
    return true
  }

  const logout = (redirect: boolean = true): void => {
    if (token === undefined) return

    axios({ method: 'DELETE', url: '/api/v1/logout', headers: { Authorization: token, 'Content-Type': 'application/json' } }).then((_response) => {
      updateTokenCookie('')
      I18nLoader.load() // ensure locale is reset to browser default
      if (redirect) {
        history.push('/')
      }
    }).catch(err => {
      console.log(err)
      updateTokenCookie('')
      history.push('/')
      window.location.reload()
    }).finally(() => {
      queryClient.removeQueries()
    })
    // TODO Maybe remove the token in the .finally method?
  }

  const fetchMyProfile = (): void => {
    if (token === undefined) return

    axios({
      method: 'GET',
      url: '/api/v1/profiles/my',
      headers: { Authorization: token, 'Content-Type': 'application/json' }
    }).then((response) => {
      // If the names are missing, we set them to the empty string, so that any
      // text fields that require a string still work, and so that it doesn't render
      // as "null" anywhere, and so that a check on if (first_name) will render false,
      // indicating to the ProfileCompleteness component that the first_name or last_name
      // is missing if it is.
      const profile: Profile = response.data
      profile.first_name = response.data.first_name ?? ''
      profile.last_name = response.data.last_name ?? ''
      if (!isEmpty(profile.locale)) {
        I18nLoader.loadFromGiven(profile.locale)
      }
      setMyProfile(profile)
    }).catch((err) => {
      /* We only want to logout the user if we did get a reply from the backend
       *  (like a 400 or 401). If the user's internet dropped for a second, we
       * don't want to log them out. We also don't want to logout the user if the
       * authentication failed because of updated terms of service. This is handled by a
       * global axios error handler instead. */
      const errorKey: string | undefined = get(err, 'response.data.error_key', undefined)
      if (errorKey !== undefined && errorKey === 'tos_required') {
        return
      }
      // Wrong cookie or cookie expired
      if (err.response.status === 401) {
        console.log('Error retrieving profile, logging out:')
        logout()
      }
    })
  }

  const updateSession = (sessionValues: SessionValues): void => {
    updateTokenCookie(sessionValues.sessionToken)
    setMyProfile(sessionValues.myProfile)
  }

  // Fixes the encoding of the token, sets it, and reloads the component.
  useEffect(() => {
    token !== undefined && fixCookieEncoding(token)
  }, [token])

  useEffect(() => {
    isValid() && fetchMyProfile()
  }, [token])

  return (
    <SessionContext.Provider value={{
      handleLogout: logout,
      updateSession: updateSession,
      refetchSession: fetchMyProfile,
      session: {
        redirectToLoginIfNoSession: redirectToLoginIfNoSession,
        isValid: isValid,
        sessionToken: removeBearerFromToken(token)
      },
      myProfile: myProfile,
      // This token includes the "Bearer" at the beginning
      sessionToken: token
    }}
    >
      {children}
    </SessionContext.Provider>
  )
}

export const WithSession = (Component: React.ElementType): React.ForwardRefExoticComponent<React.RefAttributes<React.ElementType>> => React.forwardRef<React.ElementType>((props, ref) => {
  return (
    <SessionContext.Consumer>
      {(context) => (<Component ref={ref} {...context} {...props} />)}
    </SessionContext.Consumer>
  )
})
