import React from 'react'
import Search from './Search'
import GroupResult from './Search/GroupResult'
import I18n from 'i18n'

export default class GroupHeader extends React.Component {
  componentDidMount () {
    M.FormSelect.init(document.querySelectorAll('select'))
  }

  render () {
    return (
      <>
        <div className='col s6'>
          <Search textColorClass='muted-header' fieldId='groups-search' labelClass='text-light' onClick={this.props.onShowDetails.bind(this)} Component={GroupResult} label={I18n.t('network.group.label.search')} onSearch={this.props.onSearchGroups.bind(this)} />
        </div>
        <div className='col s6'>
          <div className='input-field'>
            <select defaultValue='created' id='sort'>
              <option value='created'>
                {I18n.t('network.group.label.added')}
              </option>
            </select>
            <label className='text-light'>{I18n.t('network.group.label.sort_by')}</label>
          </div>
        </div>
      </>
    )
  }
}
