import React from 'react'

const InputWrapper = (props) => {
  const { children, helpText, errorText } = props

  const hasText = helpText || errorText
  return (
    <>
      {children}
      {hasText && (
        <span className='helper-text'>
          {helpText && <span>{helpText}</span>}
          {helpText && errorText && <br />}
          {errorText && <span className='red-text'>{errorText}</span>}
        </span>
      )}
    </>
  )
}

export default InputWrapper
