import React from 'react'
import DataRoutes from './DataRoutes'
import ApiConnector from '../helpers/ApiConnector'
import { WithSession } from '../session/SessionProvider'
import { MyAxios as axios, getAllPages } from '../MyAxios'
import I18n from 'i18n'
import { myHandleRequest, smartTranslate } from '../common/Utils'
import _ from 'lodash'

class Data extends ApiConnector {
  constructor () {
    super()
    this.state = {
      dataNotFound: false,
      myMetadata: {},
      networkMetadata: {}
    }
  }

  // Should be called with a vdo id, NOT with a metadatum ID.
  loadDataAndVersions (id) {
    Promise.all([this.getVersionedDataObject(id), this.getVersions(id)])
      .then(responses => {
        const vdo = { ...responses[0].data, versions: responses[1].data }
        this.setState({ ...this.state, data: vdo })
      })
  }

  loadData (id) {
    this.getVersionedDataObject(id)
      .then(res => {
        this.setState({ ...this.state, data: res.data })
      })
      .catch(err => {
        // This could be triggered when someone tries to access data that is
        // not theirs or does not exist.
        console.log(err)
        this.setState({ dataNotFound: true })
      })
  }

  // Appends new metadata to this.state.metadataType
  updateMetadata (response, metadataType) {
    const newState = { ...this.state }

    response.data.data.forEach(md => {
      newState[metadataType][md.id] = md
    })

    this.setState(newState)
  }

  loadMyMetadata () {
    this.setState({ loadingMetadata: true })
    getAllPages('/api/v1/metadata/my', { Authorization: this.props.sessionToken }, newData => this.updateMetadata(newData, 'myMetadata'))
      .then(data => {
        const newMetadata = data.reduce((map, obj) => {
          map[obj.id] = obj
          return map
        }, {})

        this.setState({ myMetadata: newMetadata, loadingMetadata: false })
      }).catch(e => {
        console.log(e)
      })
  }

  loadNetworkMetadata () {
    this.setState({ loadingMetadata: true })
    getAllPages('/api/v1/metadata/network', { Authorization: this.props.sessionToken }, newData => this.updateMetadata(newData, 'networkMetadata'))
      .then(data => {
        const newMetadata = data.reduce((map, obj) => {
          map[obj.id] = obj
          return map
        }, {})

        this.setState({ networkMetadata: newMetadata, loadingMetadata: false })
      }).catch(e => {
        console.log(e)
      })
  }

  processMetadata (object) {
    const sortedMetadata = _.orderBy(Object.values(object), ['event_start', 'id'], ['desc', 'desc'])
    return this.translateMetadata(sortedMetadata)
  }

  removeData (id) {
    return axios.delete(`/api/v1/data/${id}`, { headers: { Authorization: this.props.sessionToken } })
  }

  getVersionedDataObject (id) {
    return axios.get(`/api/v1/data/${id}`, { headers: { Authorization: this.props.sessionToken } })
  }

  getVersions (id) {
    return axios.get(`/api/v1/data/${id}/versions`, { headers: { Authorization: this.props.sessionToken } })
  }

  updateField (objectId, fieldName, fieldValue) {
    const newState = { ...this.state, data: { ...this.state.data, [fieldName]: fieldValue } }
    this.setState(newState)
    axios.patch(`/api/v1/data/${objectId}`,
      { versioned_data_object: { [fieldName]: fieldValue } },
      { headers: { Authorization: this.props.sessionToken } })
      .then(res => {
        this.setState({ ...this.state, data: { versioned_data_object: res.data, versions: this.state.data.versions } })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  updateVersion (objectId, versionId) {
    this.updateField(objectId, 'current_version_idx', versionId)
    // // Provide immediate feedback of the update without waiting for backend
    // this.setState({ ...this.state, data: { ...this.state.data, current_version_idx: version_id } })
    // axios.patch(`/api/v1/data/${object_id}`,
    //   { versioned_data_object: { current_version_idx: version_id } },
    //   { headers: { Authorization: this.props.sessionToken } })
    //   .then(res => {
    //     this.setState({ ...this.state, data: { versioned_data_object: res.data, versions: this.state.data.versions } })
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
  }

  toggleShareWithJupyter (objectId) {
    this.updateField(objectId, 'shared_with_jupyterhub', !this.state.data.versioned_data_object.shared_with_jupyterhub)
  }

  doNothing () {

  }

  // Translates I18n strings in metadata objects so it can be searched
  translateMetadata (metadata) {
    const translated = [...metadata]
    translated.forEach(d => {
      // Check that sport exists. Might be missing if file has not been brewed
      if (d.sport && d.sport.name && d.sport.name.indexOf('sports.') > -1) d.sport.name = I18n.t(d.sport.name)
      d.tags = d.tags.map(t => smartTranslate(t))
    })
    return translated
  }

  render () {
    return (
      <DataRoutes
        sessionToken={this.props.sessionToken}
        onRequest={myHandleRequest}
        myDataOverviewProps={{
          loadMetadata: this.loadMyMetadata.bind(this),
          loading: this.state.loadingMetadata,
          metadata: this.processMetadata(this.state.myMetadata)
        }}
        networkDataOverviewProps={{
          loadMetadata: this.loadNetworkMetadata.bind(this),
          loading: this.state.loadingMetadata,
          metadata: this.processMetadata(this.state.networkMetadata),
          network: true
        }}
        uploaderProps={this.defaultFormProps(
          'uploader', {
            translateErrors: false,
            submitMethod: 'POST',
            endpoint: '/api/v1/data'
          })}
        showProps={{
          loadData: this.loadData.bind(this),
          dataNotFound: this.state.dataNotFound,
          data: this.state.data,
          removeData: this.removeData.bind(this)
        }}
        editProps={{
          data: this.state.data,
          loadData: this.loadDataAndVersions.bind(this),
          onClick: this.updateVersion.bind(this),
          handleSuccess: this.loadDataAndVersions.bind(this),
          toggleShareWithJupyter: this.toggleShareWithJupyter.bind(this)
        }}
      />
    )
  }
}

export default WithSession(Data)
