import React from 'react'
import { Link } from 'react-router-dom'
import { WithModal } from '../modal/ModalProvider'

const ModalLink = WithModal(({ modalComponent, linkText, ...props }) => (
  <Link
    to='' onClick={(e) => {
      e.preventDefault()
      props.setModalComponent(modalComponent, props)
    }}
  >
    {linkText}
  </Link>
))

export default ModalLink
