import React, { useState, useEffect } from 'react'
import I18n from 'i18n'

function UpdatePassword (props) {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmationPassword, setConfirmationPassword] = useState('')
  const [newPasswordValid, setNewPasswordValid] = useState(false)
  const [passwordError, setPasswordError] = useState('')

  function handleUpdatePassword () {
    props.onUpdatePassword(oldPassword, newPassword, confirmationPassword)
  }

  // Validate new password
  useEffect(() => {
    setNewPasswordValid(newPassword.length > 0 && confirmationPassword.length > 0 && newPassword === confirmationPassword)
    if (newPassword.length > 0 && confirmationPassword.length > 0 && newPassword !== confirmationPassword) {
      setPasswordError(I18n.t('profile.edit.reset_password.errors.passwords_must_match'))
    } else {
      setPasswordError('')
    }
  }, [newPassword, confirmationPassword])

  return (
    <>
      <div className='row'>
        <div className='input-field col s12 m6'>
          <input id='old-password' type='password' value={oldPassword} onChange={e => setOldPassword(e.target.value)} />
          <label htmlFor='old-password'>{I18n.t('profile.edit.reset_password.old_password')}</label>
        </div>
      </div>
      <div className='row'>
        <div className='input-field col s12 m6'>
          <input id='new-password' type='password' value={newPassword} onChange={e => setNewPassword(e.target.value)} />
          <label htmlFor='new-password'>{I18n.t('profile.edit.reset_password.new_password')}</label>
        </div>
        <div className='input-field col s12 m6'>
          <input id='confirm-new-password' type='password' value={confirmationPassword} onChange={e => setConfirmationPassword(e.target.value)} />
          <label htmlFor='confirm-new-password'>{I18n.t('profile.edit.reset_password.confirm_new_password')}</label>
          {
            passwordError.length > 0 &&
              <span className='helper-text' data-error='wrong' data-success='right' style={{ color: 'red' }}>
                {passwordError}
              </span>
          }
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <button
            className={`button-primary background-primary button-autowidth waves-effect waves-light text-background-color ${newPasswordValid ? '' : 'button-disabled'}`}
            disabled={!newPasswordValid}
            onClick={handleUpdatePassword}
          >
            {I18n.t('profile.edit.reset_password.submit')}
          </button>
        </div>
      </div>
    </>
  )
}

export default UpdatePassword
