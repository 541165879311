import React from 'react'
import { smartTranslate } from '../../../common/Utils'
import SharingIcon from '../informed_consent/SharingIcon'

interface SharingObject {
  name: string
  icon: string
}

export interface SharingResult {
  id: number
  obj: SharingObject
  type: string
}

export interface SharingResultProps {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
  item: SharingResult
  hide: boolean
}

const OnboardingSharingSearchResult: React.FC<SharingResultProps> = ({ onClick, item }) => {
  return (
    <div className='row no-margin dropdown-item' onClick={onClick}>
      <div className='col s12 valign-wrapper search-result-margin'>
        <div className='text-bold'>
          {smartTranslate(item.obj.name)}
          {item.type === 'tag' &&
            <span className='text-muted text-s information-icon'>TAG</span>}
        </div>
        <div className='data-buttons-wrapper'>
          <SharingIcon item={item} />
        </div>
      </div>
    </div>
  )
}

export default OnboardingSharingSearchResult
