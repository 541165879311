import React from 'react'
import I18n from 'i18n'
import Spinner from '../../../../common/Spinner'
import { subscriptionTypes, dataTypes } from 'components/common/Constants'
import AlertSearcher from '../alerts/AlertSearcher'
import { smartTranslate } from 'components/common/Utils'
import {
  shortSessionName,
  makeHistogram,
  preprocessTrainingLogs, preprocessWellbeingQuestionnaires
} from '../Utils'
import PremiumOverlay from '../../../../premium_membership/PremiumOverlay'
import { isDailyQuestionnaire, isTrainingLog } from 'components/common/utils/QuestionnaireUtils'
import InfoRequiredData, { DASHBOARD_REQUIRED_DATA_TYPES } from '../../common/RequiredDataInfo'
import TrainingQuestionnaireDashboard from './TrainingQuestionnaireDashboard'
import WellbeingQuestionnaireDashboard from './WellbeingQuestionnaireDashboard'
import { sortBy } from 'lodash'
import { WithBackend } from 'components/backend/BackendProvider'
import { WithFlashMessages } from 'components/flashmessages/FlashMessageProvider'
import { errorToString } from 'components/common/ErrorHandling'
import DateRangePicker from 'components/analysis/dashboards/questionnaire/common/DateRangePicker'
import WeekSlider from 'components/analysis/dashboards/questionnaire/common/WeekSlider'

class FullQuestionnaireDashboard extends React.Component {
  constructor (props) {
    super(props)
    const { match: { params: { id } } } = this.props
    this.id = id

    // Note: This is only temporary here. In the future, all of this will be
    // moved to the roasters and the profile of the user. See
    // https://freedcamp.com/Researchable_gZB/SDVMVP_0Up/todos/32478431/
    this.alertsToCheckFor = {
      sleep_duration_num: {
        name: I18n.t('components.dashboards.questionnaire.warnings.poor_sleep_name'),
        description: I18n.t('components.dashboards.questionnaire.warnings.poor_sleep'),
        key: 'sleep_duration_num',
        comparison: '<',
        threshold: 3,
        defaultThreshold: 3
      },
      wellness_sleep: {
        name: I18n.t('components.dashboards.questionnaire.warnings.sleepquality_name'),
        description: I18n.t('components.dashboards.questionnaire.warnings.sleepquality'),
        key: 'wellness_sleep',
        comparison: '<=',
        threshold: 1.5,
        defaultThreshold: 1.5
      },
      readiness_to_train: {
        name: I18n.t('components.dashboards.questionnaire.warnings.readiness_to_train_name'),
        description: I18n.t('components.dashboards.questionnaire.warnings.readiness_to_train'),
        key: 'readiness_to_train',
        comparison: '<=',
        threshold: 2,
        defaultThreshold: 2
      }
    }

    this.state = {
      preprocessingDone: true,
      dailyPreprocessingDone: true,
      selectedTrainingTypes: [],
      selectedSessionTypes: [],
      questionnaireData: {},
      isLoading: false,
      isError: false
    }
    this.mySort = (a, b) => {
      const aid = a.id.toLowerCase()
      const bid = b.id.toLowerCase()
      if (aid === bid) return 0
      if (aid > bid) return 1
      return -1
    }
  }

  componentDidMount () {
    M.FormSelect.init(document.querySelectorAll('select'))
    this.loadQuestionnaireData()
  }

  loadQuestionnaireData () {
    this.setState({ isLoading: true })
    this.props.backend.data.find(dataTypes.questionnaire_type).then((response) => {
      this.setState({ questionnaireData: response.data, isLoading: false })
    }).catch(e => {
      this.props.flashMessages.push(errorToString(e), this.props.flashMessages.duration.LONG, this.props.flashMessages.levels.ERROR)
      this.setState({ isLoading: false, isError: true })
    })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevState.profiles !== this.state.profiles) this.initProfileSelect()
    if (prevState.trainingTypes !== this.state.trainingTypes) this.initTrainingTypeSelect()
    if (prevState.sessionTypes !== this.state.sessionTypes) this.initSessionTypeSelect()
    if (prevProps.selectedProfileId !== this.props.selectedProfileId || (!prevState.profiles && prevState.profiles !== this.state.profiles && this.props.selectedProfileId)) {
      this.setState({ preprocessingDone: false }, () => {
        const elem = M.FormSelect.getInstance(document.getElementById('questionnaire-dashboard-select-profile'))
        if (elem) {
          elem._setValueToInput()
          elem._setSelectedStates()
        }
        this.setTrainingAndSessionTypes()
      })
    }

    if (!this.props.selectedProfileId && this.state.profiles?.length > 0) {
      if (this.id) {
        if (this.state.profiles.map(profile => profile.id).includes(parseInt(this.id))) {
          this.props.setSelectedProfileId(parseInt(this.id))
        } else if (!this.state.unknownId) {
          this.setState({ unknownId: true })
        }
      } else {
        this.props.setSelectedProfileId(parseInt(this.state.profiles[0].id))
      }
    }
    if (prevState.preprocessingDone !== this.state.preprocessingDone && this.state.preprocessingDone) {
      const elems = document.querySelectorAll('.tabs')
      M.Tabs.init(elems)
    }

    if (prevState.questionnaireData !== this.state.questionnaireData) {
      this.processResponses()
    }
  }

  initTrainingTypeSelect () {
    M.FormSelect.init(document.getElementById('questionnaire-dashboard-select-training-type'),
      {
        dropdownOptions: {
          container: document.getElementById('select-training-type-buttons'),
          onOpenStart: () => { document.getElementById('select-training-type-buttons').style.display = 'block' },
          onCloseEnd: () => { document.getElementById('select-training-type-buttons').style.display = 'none' }
        }
      }
    )
  }

  initSessionTypeSelect () {
    M.FormSelect.init(document.getElementById('questionnaire-dashboard-select-session-type'),
      {
        dropdownOptions: {
          container: document.getElementById('select-session-type-buttons'),
          onOpenStart: () => { document.getElementById('select-session-type-buttons').style.display = 'block' },
          onCloseEnd: () => { document.getElementById('select-session-type-buttons').style.display = 'none' }
        }
      }
    )
  }

  initProfileSelect () {
    M.FormSelect.init(document.getElementById('questionnaire-dashboard-select-profile'),
      {
        dropdownOptions: {
          coverTrigger: false
        }
      }
    )
  }

  selectTrainingTypes (selected, updateSelect = false) {
    this.setState({ selectedTrainingTypes: selected }, () => {
      if (updateSelect) M.FormSelect.getInstance(document.getElementById('questionnaire-dashboard-select-training-type'))._handleInputClick()
      this.filterAndProcessQuestionnaires()
    })
  }

  selectSessionTypes (selected, updateSelect = false) {
    this.setState({ selectedSessionTypes: selected }, () => {
      if (updateSelect) M.FormSelect.getInstance(document.getElementById('questionnaire-dashboard-select-session-type'))._handleInputClick()
      this.filterAndProcessQuestionnaires()
    })
  }

  filterAndProcessQuestionnaires () {
    this.processQuestionnaires(this.state.questionnaireData.filter(questionnaire => {
      return questionnaire.owner.id === this.props.selectedProfileId && isTrainingLog(questionnaire) &&
        this.state.selectedTrainingTypes.includes(smartTranslate(questionnaire.data_rows[0].values.v1)) &&
        this.state.selectedSessionTypes.includes(smartTranslate(questionnaire.data_rows[0].values.v2))
    }))
  }

  filterDailyQuestionnaires (onlySelected = true) {
    return this.state.questionnaireData.filter(questionnaire => {
      return (questionnaire.owner.id === this.props.selectedProfileId || !onlySelected) && isDailyQuestionnaire(questionnaire)
    })
  }

  filterAndProcessDailyQuestionnaires () {
    this.processDailyQuestionnaires(this.filterDailyQuestionnaires())
  }

  setTrainingAndSessionTypes () {
    const ownerTrainingLogQuestionnaires = this.state.questionnaireData.filter(questionnaire => {
      return questionnaire.owner.id === this.props.selectedProfileId &&
        questionnaire.data_rows[0].questionnaire.key === 'training_log' &&
        questionnaire.data_rows[0].values.v1 && questionnaire.data_rows[0].values.v1.length > 0 &&
        questionnaire.data_rows[0].values.v2 && questionnaire.data_rows[0].values.v2.length > 0
    })

    const trainingTypeSet = new Set(ownerTrainingLogQuestionnaires.map(questionnaire => JSON.stringify({ id: smartTranslate(questionnaire.data_rows[0].values.v1) })))
    const trainingTypes = [...trainingTypeSet].map(str => JSON.parse(str)).sort(this.mySort)

    const sessionTypeSet = new Set(ownerTrainingLogQuestionnaires.map(questionnaire => JSON.stringify({ id: smartTranslate(questionnaire.data_rows[0].values.v2) })))
    const sessionTypes = [...sessionTypeSet].map(str => JSON.parse(str)).sort(this.mySort)

    this.setState({
      trainingTypes,
      selectedTrainingTypes: trainingTypes.map(trainingType => trainingType.id),
      sessionTypes,
      selectedSessionTypes: sessionTypes.map(sessionType => sessionType.id)
    }, () => this.filterAndProcessQuestionnaires())

    this.setState({ allDailyQuestionnaires: this.filterDailyQuestionnaires(false) })
    this.filterAndProcessDailyQuestionnaires()
  }

  processResponses () {
    const ownerSet = new Set(this.state.questionnaireData.map(questionnaire => JSON.stringify(questionnaire.owner)))
    const profiles = sortBy(Array.from(ownerSet).map(str => JSON.parse(str)), profile => profile.first_name)

    this.setState({ profiles })
  }

  processQuestionnaires (data) {
    if (data.length === 0) {
      this.setState({
        trainingLogRows: undefined,
        weeklySummary: undefined,
        sessionTypeHistogram: undefined,
        trainingTypeHistogram: undefined,
        trainingDaypartHistogram: undefined,
        trainingRPEHistogram: undefined,
        preprocessingDone: true
      })
      return
    }

    const preprocessedTrainingLogs = preprocessTrainingLogs(data)

    // Make histograms
    const sessionTypeHistogram = makeHistogram(preprocessedTrainingLogs.trainingLogRows, 'training_session', shortSessionName)
    const trainingTypeHistogram = makeHistogram(preprocessedTrainingLogs.trainingLogRows, 'training_type')
    const trainingDaypartHistogram = makeHistogram(preprocessedTrainingLogs.trainingLogRows, 'training_daypart')
    const trainingRPEHistogram = makeHistogram(preprocessedTrainingLogs.trainingLogRows, 'rpe', undefined, true)

    this.setState({
      trainingLogRows: preprocessedTrainingLogs.trainingLogRows,
      weeklySummary: preprocessedTrainingLogs.weeklySummary,
      sessionTypeHistogram,
      trainingTypeHistogram,
      trainingDaypartHistogram,
      trainingRPEHistogram,
      preprocessingDone: true
    })
  }

  processDailyQuestionnaires (data) {
    if (data.length === 0) {
      this.setState({
        dailyLogRows: undefined,
        sundaySummary: undefined,
        dailyPreprocessingDone: true
      })
      return
    }
    const preprocessedDailyQuestionnaires = preprocessWellbeingQuestionnaires(data)

    this.setState({
      dailyLogRows: preprocessedDailyQuestionnaires.dailyLogRows,
      sundaySummary: preprocessedDailyQuestionnaires.sundaySummary,
      dailyPreprocessingDone: true
    })
  }

  handleSelectSubject (e) {
    const subjectId = e?.target?.value || ''
    this.props.setSelectedProfileId(parseInt(subjectId))
  }

  handleSelectTrainingType (e) {
    this.selectTrainingTypes(e.getSelectedValues())
  }

  handleSelectSessionType (e) {
    this.selectSessionTypes(e.getSelectedValues())
  }

  render () {
    if (this.state.unknownId) {
      return (
        <div>
          <div className='row'>
            <div className='col s12'>
              <h5>{I18n.t('components.dashboards.questionnaire.no_questionnaire_data')}</h5>
            </div>
          </div>
        </div>
      )
    }
    return (
      <PremiumOverlay activeFor={[subscriptionTypes.coach, subscriptionTypes.researcher]} showBadge={false}>
        <div>
          <div className='row'>
            <div className='col s12 text-xl text-primary-color text-heavy'>
              {I18n.t('components.dashboards.questionnaire.title')}
              <InfoRequiredData
                dataTypes={DASHBOARD_REQUIRED_DATA_TYPES.full_questionnaire}
                id='dashboard-data-tooltip'
              />
            </div>
          </div>
          <div className='row'>
            <DateRangePicker {...this.props} />
            <div className='input-field col s12 m4'>
              <select id='questionnaire-dashboard-select-profile' onChange={this.handleSelectSubject.bind(this)} value={this.props.selectedProfileId}>
                {this.state.profiles && this.state.profiles.map(f => (
                  <option
                    key={f.id}
                    value={f.id}
                  >
                    {f.first_name} {f.last_name}
                  </option>
                ))}
              </select>
              <label>{I18n.t('components.dashboards.questionnaire.select_profile')}</label>
            </div>
            <div className='input-field col s12 m4'>
              <select id='questionnaire-dashboard-select-training-type' onChange={e => this.handleSelectTrainingType(M.FormSelect.getInstance(e.target))} value={this.state.selectedTrainingTypes} multiple>
                {this.state.trainingTypes && this.state.trainingTypes.map(f => (
                  <option
                    key={f.id}
                    value={f.id}
                  >
                    {f.id}
                  </option>
                ))}
              </select>
              <label>{I18n.t('components.dashboards.questionnaire.select_training_type')}</label>
              <div className='select-buttons-wrapper' id='select-training-type-buttons'>
                <div className='select-buttons dropdown-content'>
                  <a className='select-button pointer text-s' onClick={e => { e.preventDefault(); this.selectTrainingTypes(this.state.trainingTypes.map(trainingType => trainingType.id), true) }}>{I18n.t('components.dashboards.questionnaire.select_all')}</a>
                  <a className='select-button pointer text-s' onClick={e => { e.preventDefault(); this.selectTrainingTypes([], true) }}>{I18n.t('components.dashboards.questionnaire.deselect_all')}</a>
                </div>
              </div>
            </div>
            <div className='input-field col s12 m4'>
              <select id='questionnaire-dashboard-select-session-type' onChange={e => this.handleSelectSessionType(M.FormSelect.getInstance(e.target))} value={this.state.selectedSessionTypes} multiple>
                {this.state.sessionTypes && this.state.sessionTypes.map(f => (
                  <option
                    key={f.id}
                    value={f.id}
                  >
                    {f.id}
                  </option>
                ))}
              </select>
              <label>{I18n.t('components.dashboards.questionnaire.select_session_type')}</label>
              <div className='select-buttons-wrapper' id='select-session-type-buttons'>
                <div className='select-buttons dropdown-content'>
                  <a className='select-button pointer text-s' onClick={e => { e.preventDefault(); this.selectSessionTypes(this.state.sessionTypes.map(sessionType => sessionType.id), true) }}>{I18n.t('components.dashboards.questionnaire.select_all')}</a>
                  <a className='select-button pointer text-s' onClick={e => { e.preventDefault(); this.selectSessionTypes([], true) }}>{I18n.t('components.dashboards.questionnaire.deselect_all')}</a>
                </div>
              </div>
            </div>
            <WeekSlider {...this.props} />
          </div>
          <AlertSearcher data={this.state.allDailyQuestionnaires} alertsToCheckFor={this.alertsToCheckFor} />
          {!this.state.preprocessingDone && <Spinner ready={false} />}
          {this.state.preprocessingDone && this.props.training && <TrainingQuestionnaireDashboard {...this.state} />}
          {this.state.preprocessingDone && this.props.wellbeing && <WellbeingQuestionnaireDashboard {...this.state} />}
        </div>
      </PremiumOverlay>
    )
  }
}

export default WithFlashMessages(WithBackend(FullQuestionnaireDashboard))
