import React from 'react'
import WelcomeController from '../welcome/WelcomeController'
import NetworkTabs from './NetworkTabs'
import { WithModal } from '../modal/ModalProvider'
import { WithSession } from '../session/SessionProvider'
import { WithBackend } from '../backend/BackendProvider'
import ConnectionCreateController from './ConnectionCreateController'
import ConnectionList from './layout/ConnectionList'
import ConnectionRequestList from './layout/ConnectionRequestList'
import SentConnectionRequestList from './layout/SentConnectionRequestList'
import Spinner from '../common/Spinner'
import { MyAxios as axios } from '../MyAxios'
import { events, membershipStates, popupKeys, subscriptionTypes } from '../common/Constants'
import ReactGA from 'react-ga'
import I18n from 'i18n'
import ConnectionHeader from './layout/ConnectionHeader'
import MyModal from '../modal/MyModal'
import { withCookies } from 'react-cookie'
import _ from 'lodash'
import { gAEnabled } from '../common/Utils'
import FirstConnectionMessage from './layout/first_connection/FirstConnectionMessage'

class ConnectionsCreateButton extends React.Component {
  render () {
    return (
      <button
        className='waves-effect waves-light button-accent text-background-color background-accent text-medium text-m button'
        onClick={this.props.onShowModal}
        id='connections-create-button'
      >
        {I18n.t('network.connection.create_new')}
      </button>
    )
  }
}

export class ConnectionsController extends React.Component {
  constructor (props) {
    super(props)
    this.state = { refetch: () => {} }
  }

  showModal () {
    if (gAEnabled()) {
      ReactGA.event({
        category: events.category.button_click,
        action: 'ConnectionsCreateButton'
      })
    }
    this.setState({ createConnection: true })
  }

  componentDidMount () {
    // check if user already has seen popup from cookies. If so, mark it on their profile as well
    if (this.props.cookies.get('firstConnectionPopupDismissed') === 'true' &&
       !_.get(this, 'props.myProfile.seen_popups.first_connection', false)) {
      this.setPopupDismissed()
    }
    this.props.fetch()
  }

  showPopup () {
    // connections is a list of all active or pending connections. Its values are not updated before this method is reached.
    // Therefore, a length of 0 implies no accepted or pending connections, thus indicating it's the first request.
    if (_.get(this, 'props.myProfile.seen_popups.first_connection', false) && _.get('props.connections.length', 0) !== 0) return
    this.setState({ showFirstConnectionPopup: true })
  }

  addOtherProfileToConnection (connections) {
    if (!this.props.myProfile) return []

    return connections.map((connection) => {
      connection.other_membership = connection.group_memberships.filter(
        (membership) => membership.profile.id !== this.props.myProfile.id)[0]
      return connection
    })
  }

  handleProfileSearch (e, refetch) {
    this.props.onFilterConnections(e.target.value)
    const targetString = e.target.value.trim()

    return new Promise((resolve, reject) => {
      this.props.backend.profiles.searchProfiles(targetString)
        .then((response) => {
          resolve(response.data)
          this.setState({ ...this.state, refetch })
        }).catch((err) => {
          reject(err)
        })
    })
  }

  handleConnectionRequest (profile) {
    axios({
      method: 'POST',
      url: '/api/v1/groups/request_mutual_connection',
      headers: { Authorization: this.props.sessionToken, ContentType: 'application/json' },
      data: { profile_id: profile.id }
    }).then((_result) => {
      this.showPopup()
      this.props.fetch()
      this.handleCloseConnectionCreate()
    })
  }

  handleAcceptConnection (connection) {
    const myMembership = connection.group_memberships.filter((membership) => membership.profile.id === this.props.myProfile?.id)[0]
    axios({
      method: 'PATCH',
      url: `/api/v1/group_memberships/${myMembership.id}/accept`,
      headers: { Authorization: this.props.sessionToken }
    }).then((_response) => {
      this.props.fetch()
    })
  }

  handleRejectConnection (connection) {
    const myMembership = connection.group_memberships.filter((membership) => membership.profile.id === this.props.myProfile?.id)[0]
    axios({
      method: 'DELETE',
      url: `/api/v1/group_memberships/${myMembership.id}`,
      headers: { Authorization: this.props.sessionToken }
    }).then((_response) => {
      this.props.fetch()
    })
  }

  handleCloseConnectionCreate () {
    this.setState({ createConnection: false })
  }

  splitConnections (connections) {
    const extendedConnections = this.addOtherProfileToConnection(connections)

    const sentRequests = extendedConnections.filter(
      (connection) => connection.group_memberships.filter(
        (membership) => membership.state === membershipStates.ACCEPTED).length === 1)

    const acceptedConnections = extendedConnections.filter(
      (connection) => connection.group_memberships.filter(
        (membership) => membership.state === membershipStates.ACCEPTED).length > 1)

    return { sentRequests: sentRequests, acceptedConnections: acceptedConnections }
  }

  setPopupDismissed () {
    axios({
      method: 'POST',
      url: '/api/v1/profiles/mark_popup_seen',
      headers: { Authorization: this.props.sessionToken },
      data: {
        key: popupKeys.FIRST_CONNECTION
      }
    }).then((response) => {
      this.props.refetchSession()
      this.setState({ showFirstConnectionPopup: false })
    }).catch((err) => {
      console.log(err)
    })
  }

  render () {
    const { groups, connectionRequests } = this.props
    const { sentRequests, acceptedConnections } = this.splitConnections(this.props.connections)
    const extendedConnectionRequests = this.addOtherProfileToConnection(connectionRequests)
    const popupDismissed = _.get(this, 'props.myProfile.seen_popups.first_connection', false)
    const showUpgradeButton = _.get(this, 'props.myProfile.subscription.valid', false) && _.get(this, 'props.myProfile.subscription.type', subscriptionTypes.free) !== subscriptionTypes.free

    return (
      <>
        <MyModal
          modalClassName='new-connection-modal'
          isOpen={this.state.createConnection}
          onClose={this.handleCloseConnectionCreate.bind(this)}
        >
          <ConnectionCreateController
            onConnectionRequest={(profile) => { this.handleConnectionRequest(profile) }}
            onProfileSearch={this.handleProfileSearch.bind(this)}
          />
        </MyModal>
        <MyModal
          modalClassName='first-connection-modal'
          isOpen={this.state.showFirstConnectionPopup && !popupDismissed}
          onClose={this.setPopupDismissed.bind(this)}
        >
          <FirstConnectionMessage />
        </MyModal>
        <div className='row'>
          <div className='col s12 m4 l4 welcome-left-menu'>
            <WelcomeController ModalButton={ConnectionsCreateButton} modalButtonProps={{ onShowModal: this.showModal.bind(this) }} />
          </div>
          <div className='col s12 m8 l8'>
            <NetworkTabs
              history={this.props.history}
              location={this.props.location}
              groupCount={groups.length}
              connectionCount={acceptedConnections.length}
            />
            <div className='padding-on-small'>
              <div className='row'>
                <ConnectionHeader
                  onConnectionRequest={this.handleConnectionRequest.bind(this)}
                  onProfileSearch={this.handleProfileSearch.bind(this)}
                />
              </div>
              <div className='row'>
                <Spinner ready={this.props.myProfile?.id} transparent />
                {this.props.myProfile?.id && extendedConnectionRequests.length > 0 && (
                  <ConnectionRequestList
                    connectionRequests={extendedConnectionRequests}
                    onAcceptConnection={this.handleAcceptConnection.bind(this)}
                    onRejectConnection={this.handleRejectConnection.bind(this)}
                    dataRetrieved={this.props.pendingConnectionsRetrieved}
                  />
                )}
              </div>
              <div className='row'>
                <Spinner ready={this.props.myProfile?.id} transparent />
                {this.props.myProfile?.id && sentRequests.length > 0 && (
                  <SentConnectionRequestList
                    connections={sentRequests}
                    onDeleteConnection={this.handleRejectConnection.bind(this)}
                    dataRetrieved={this.props.pendingConnectionsRetrieved}
                  />
                )}
              </div>
              <div className='row'>
                <Spinner ready={this.props.myProfile?.id} transparent />
                {this.props.myProfile?.id && (
                  <ConnectionList
                    onDeleteConnection={this.handleRejectConnection.bind(this)}
                    connections={acceptedConnections}
                    dataRetrieved={this.props.connectionsRetrieved}
                    showUpgradeButton={showUpgradeButton}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default WithBackend(WithSession(WithModal(withCookies(ConnectionsController))))
