import { useMemo } from 'react'
import moment from 'moment'

export const useImputedResponses = (startDay, endDay, responseVdos) => {
  return useMemo(() => {
    // Return early if responseVdos is not yet defined. That way we save 2 re-renders (1 when start day is defined and
    // another when end day is defined)
    if (!responseVdos) return
    const responses = []
    for (let currentDate = startDay.clone(); currentDate.isSameOrBefore(endDay, 'day'); currentDate.add(1, 'day')) {
      const selectedQuestionnaire = responseVdos?.find((q) => moment(q.structured_data_objects?.[0]?.data_rows?.[0]?.open_from).isSame(currentDate, 'day'))

      if (!selectedQuestionnaire) {
        responses.push({ date: currentDate.clone(), answers: null })
        continue
      }

      const questionnaireAnswers = selectedQuestionnaire.structured_data_objects?.[0]?.data_rows?.[0]?.values

      responses.push({ date: currentDate.clone(), answers: questionnaireAnswers })
    }
    return responses
  }, [startDay, endDay, responseVdos])
}
