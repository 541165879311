import React from 'react'
import I18n from 'i18n'
import FormFactory from '../../helpers/FormFactory'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import _ from 'lodash'

class EditMetaData extends FormFactory {
  updateFormFields () {
    const formSingleVdo = {
      title: {
        component: 'field',
        from_endpoint: this.props.endpoint,
        properties: {
          type: 'text'
        }
      },
      tags: {
        component: 'chips',
        from_endpoint: this.props.endpoint,
        properties: {
          type: 'text',
          suggestions: _.get(this, 'props.availableTags', [])
        }
      },
      event_start: {
        component: 'date_picker',
        from_endpoint: this.props.endpoint,
        properties: {
          showTimeSelect: true,
          timeFormat: 'HH:mm',
          timeIntervals: 15,
          timeCaption: 'time',
          // dateFormat: 'dd-MM-yyyy H:mm',
          popperClassName: 'z-top'
        }
      },
      event_end: {
        component: 'date_picker',
        from_endpoint: this.props.endpoint,
        properties: {
          showTimeSelect: true,
          timeFormat: 'HH:mm',
          timeIntervals: 15,
          timeCaption: 'time',
          dateFormat: 'dd-MM-yyyy H:mm',
          popperClassName: 'z-top'
        }
      }
    }
    const formBulkVdo = {
      tags: {
        component: 'chips',
        properties: {
          type: 'text',
          suggestions: _.get(this, 'props.availableTags', [])
        }
      }
    }
    this.form_fields = this.props.isBulkUpdate ? formBulkVdo : formSingleVdo
    if (this.props.isBulkUpdate) {
      this.hidden_fields = [
        { vdo_ids: this.props.vdoId }
      ]
    }
  }

  componentDidMount () {
    this.updateFormFields()
    if (this.props.isBulkUpdate === true) {
      this.props.setEndpoint('/api/v1/metadata/bulk_update_tags')
    } else {
      this.props.setEndpoint(`/api/v1/metadata/${this.props.vdoId}`)
    }
  }

  componentWillUnmount () {
    this.props.resetEndpoint()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const vdoId = this.props.vdoId
    if (vdoId !== prevProps.vdoId) {
      if (this.props.isBulkUpdate === true) {
        this.props.setEndpoint('/api/v1/metadata/bulk')
      } else {
        this.props.setEndpoint(`/api/v1/metadata/${vdoId}`)
      }
    }

    if (prevProps.endpoint !== this.props.endpoint) {
      this.updateFormFields()
      this.fetchDefaults()
    }

    if (prevProps.availableTags !== this.props.availableTags) {
      this.updateFormFields()
      if (this.props.isBulkUpdate) this.forceUpdate()
    }
  }

  handleSuccess (response) {
    this.props.flashMessages.push(
      I18n.t(
        'flashmessages.metadata.update_successful'
      ),
      this.props.flashMessages.duration.SHORT,
      this.props.flashMessages.levels.INFO
    )
    if (this.props.handleSuccess !== undefined) {
      this.props.handleSuccess(this.props.vdoId)
    }
    if (!this.props.isBulkUpdate) {
      this.props.history.goBack()
    }
  }

  render () {
    return (
      <div>
        <form>
          {this.renderFields()}
          {this.renderSubmitAndCancel(this.props.isBulkUpdate ? 'metadata_edit.add_tags' : 'metadata_edit.submit', 'metadata_edit.cancel', { handleCancel: this.props.handleCancel })}
        </form>
      </div>
    )
  }
}

export default WithFlashMessages(EditMetaData)
