import React, { FC, ReactElement } from 'react'
import { isEmpty } from 'lodash'
import { EMPTY_RECORD } from '../../../common/Constants'

// the value types a cell can contain
type CellValueTypes = React.ReactElement | undefined | string | number

// the content of each entry
interface Content {
  acwr: number
  athlete: ReactElement
  fatigue: number
  healthStatus: ReactElement
  mood: number
  musclePain: number
  powerLoad: number
  readiness: number
  restingHr: number
  rpe: number
  satisfaction: number
  sleepDuration: string
  sleepQuality: number
  speedLoad: number
  srpe: number
  stress: number
  trimp: number
  wellbeingScore: number
}

// a single entry
interface Entry {
  handleClick: () => void
  content: Content
}

// PROPS
interface MobileTableProps {
  titles: Record<string, string>
  entries: Record<string, Entry>
  id: string
}

// An array of category labels to ignore while rendering the table headers
// These are only displayed on the non-mobile table (TableInteractive.jsx)
// as categories which contain a group of titles
const IGNORED_CATEGORIES = ['health', 'wellbeing', 'training']

// The MobileTable component was created as a replacment table for the coach home tab.
// This component renders a mobile-friendly table unlike the regular coach home tab table (TableInteractive.jsx).
export const MobileTable: FC<MobileTableProps> = ({ titles, entries, id }) => {
  const hasEntries = !isEmpty(entries)
  const filteredTitles = Object.keys(titles).filter(title => !IGNORED_CATEGORIES.includes(title))

  // Check if the cell should be considered empty
  const isEmptyRecord = (value: CellValueTypes): boolean => {
    return value === undefined || value === null || Number.isNaN(value) || value === 0
  }

  // Render a table cell based on the value and the display parameter.
  // It returns EMPTY_RECORD ('--') for undefined, null, NaN, or zero values.
  // If the value is a percentage string, it rounds to the nearest whole number.
  const renderCell = (value: CellValueTypes, displayParameter: string): ReactElement => {
    if (isEmptyRecord(value)) {
      return <td key={displayParameter}>{EMPTY_RECORD}</td>
    }

    // Handle rounding for percentages
    if (typeof value === 'string' && value.endsWith('%')) {
      const number = parseFloat(value.slice(0, -1))
      if (!isNaN(number) && Math.trunc(number) !== number) {
        return <td key={displayParameter}>{number.toFixed(0) + '%'}</td>
      }
    }

    return <td key={displayParameter}>{value}</td>
  }

  return (
    <div className='mobile-table-wrapper hide-on-large-only' id={id}>
      <table>
        <thead className='mobile-table-headers'>
          <tr>
            {filteredTitles.map((titleKey) => (
              <th key={titleKey}>{titles[titleKey]}</th>
            ))}
          </tr>
        </thead>
        <tbody className='show-on-large'>
          {hasEntries &&
            Object.keys(entries).map((entryId) => {
              const entry = entries[entryId]
              return (
                <tr className='mobile-row' key={entryId} onClick={entry.handleClick}>
                  {Object.entries(entry.content).map(([displayParameter, value]) => {
                    return renderCell(value, displayParameter)
                  })}
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}
