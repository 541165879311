import React from 'react'
import RedeemRoutes from './RedeemRoutes'

const RedeemVoucher = (props) => {
  return (
    <RedeemRoutes />
  )
}

export default RedeemVoucher
