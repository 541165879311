import React from 'react'
import FitbitDefaultFlavour from './FitbitDefaultFlavour'
import moment from 'moment'

const FitbitDetails = (props) => {
  const sdos = props.data.structured_data_objects
  if (!sdos || sdos.length < 1) return <></>
  const createdAt = moment(props.data.metadatum.created_at)
  const updatedAt = moment(props.data.metadatum.updated_at)
  return <FitbitDefaultFlavour sdo={sdos[0]} createdAt={createdAt} updatedAt={updatedAt} icon={props.icon} metadatum={props.metadatum} />
}

export default FitbitDetails
