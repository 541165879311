import React from 'react'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GroupMembers from './GroupMembers'
import { defaultProfilePicture, membershipStates } from '../../common/Constants'
import { TimeAgoPlain } from '../../common/TimeAgo'
import { WithModal } from '../../modal/ModalProvider'

class GroupInvite extends React.Component {
  showDeleteDialog (groupInvite) {
    this.props.setConfirmationDialog({ onConfirmation: () => this.props.onDelete(groupInvite.my_membership), action: 'reject_group', target: groupInvite.name })
  }

  render () {
    const { groupInvite, onAccept } = this.props
    if (!groupInvite.my_membership) {
      return (<></>)
    }
    return (
      <div className='col s12 group-container'>
        <div className='network-group-container'>
          <div className='row' style={{ marginBottom: '0px' }}>
            <div className='col s2'>
              <img className='circle responsive-img' src={groupInvite.picture || defaultProfilePicture} />
            </div>
            <div className='col s10 l6'>
              <div className='text-heavy text-primary-color text-l' style={{ marginTop: '0.75rem' }}>
                {groupInvite.name}
              </div>
              <div className='text-m text-light'>
                <GroupMembers memberships={groupInvite.group_memberships.filter(membership => membership.state === membershipStates.ACCEPTED)} />
              </div>
              <div className='text-m text-light text-muted'>
                {I18n.t('network.groups.group_invite.invited_at', { timestamp: TimeAgoPlain(new Date(groupInvite.my_membership.created_at)) })}
              </div>
            </div>
            <div className='col s12 l4 right-align'>
              <button
                onClick={() => onAccept(groupInvite.my_membership)}
                className='group-button text-m waves-effect waves-light text-background-color button-primary background-primary'
              >
                <FontAwesomeIcon icon='check' className='button-icon' />
                {I18n.t('network.groups.group_invite.accept')}
              </button>
              <button
                onClick={() => this.showDeleteDialog(groupInvite)}
                className='group-button text-m waves-effect waves-light text-accent-color button-accent background-background'
              >
                <FontAwesomeIcon icon='times' className='button-icon' />
                {I18n.t('network.groups.group_invite.decline')}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WithModal(GroupInvite)
