import React from 'react'
import Summary from './Summary'
import PropTypes from 'prop-types'
import { formatTemp } from '../../../../common/Utils'

const TempSummary = ({ summary, metadatum, icon, compact }) => {
  const {
    time_series_summaries: {
      temp: {
        min,
        mean,
        max
      }
    }
  } = summary
  const fields = { min: formatTemp(min), mean: formatTemp(mean), max: formatTemp(max) }
  const defaultProps = { icon: icon, metadatum: metadatum, compact: compact }
  return <Summary {...defaultProps} fields={fields} />
}

TempSummary.propTypes = {
  // Summary as processed by useSummaryStringUnits
  summary: PropTypes.object,
  metadatum: PropTypes.object,
  compact: PropTypes.bool
}

export default TempSummary
