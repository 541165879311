import { TransferRequest } from 'components/common/types/TransferRequest'
import { UseMutationResult, useMutation, useQueryClient } from 'react-query'
import { BackendContext } from '../BackendProvider'
import { useContext } from 'react'
import { transferRequestsQueryId } from './Queries'
import { NewTransferRequestParams } from './types'

export const useAcceptTransferRequestMutation = (): UseMutationResult<unknown, unknown, TransferRequest> => {
  const { backend } = useContext(BackendContext)
  const queryClient = useQueryClient()
  return useMutation(
    (request: TransferRequest) => backend.transfer_requests.accept(request.id),
    { onSuccess: async () => await queryClient.invalidateQueries(transferRequestsQueryId) }
  )
}

export const useRejectTransferRequestMutation = (): UseMutationResult<unknown, unknown, TransferRequest> => {
  const { backend } = useContext(BackendContext)
  const queryClient = useQueryClient()
  return useMutation(
    (request: TransferRequest) => backend.transfer_requests.reject(request.id),
    { onSuccess: async () => await queryClient.invalidateQueries(transferRequestsQueryId) }
  )
}

export const useCreateTransferRequestMutation = (): UseMutationResult<TransferRequest, unknown, NewTransferRequestParams> => {
  const { backend } = useContext(BackendContext)
  return useMutation((params: NewTransferRequestParams) => backend.transfer_requests.create(params))
}
