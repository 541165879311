import React from 'react'
import Tabs from '../common/Tabs'
import Routes from '../common/Routes'

export default class NetworkTabs extends React.Component {
  render () {
    const tabs = [
      {
        id: 'group',
        title: 'network.tabs.groups',
        subtitle: 'network.tabs.group_count',
        subtitleParams: { count: this.props.groupCount },
        name: 'groups',
        path: Routes.network.groups.index
      }, {
        id: 'connections',
        title: 'network.tabs.connections',
        subtitle: 'network.tabs.connection_count',
        subtitleParams: { count: this.props.connectionCount },
        name: 'connections',
        path: Routes.network.connections.index
      }
    ]
    return (
      <Tabs tabs={tabs} history={this.props.history} location={this.props.location} defaultActive='/network/groups' />
    )
  }
}
