import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'

import { datePickerDefaultOptions } from '../../../common/Constants'
import Label from '../Label'

export const DateInput = (props) => {
  const [internalValue, setInternalValue] = useState(undefined) // When component is uncontrolled
  const {
    id,
    className = '',
    label,
    placeholder,
    disabled,
    value,
    onChange = () => {},
    startDate,
    endDate
  } = props

  const isControlled = !!value

  const inputHandlerControlled = (date) => {
    onChange(date)
  }

  const inputHandlerUncontrolled = (date) => {
    setInternalValue(date)
  }

  // When component is uncontrolled
  useEffect(() => {
    if (!isControlled) {
      onChange(internalValue)
    }
  }, [internalValue])

  const inputHandler = isControlled ? inputHandlerControlled : inputHandlerUncontrolled

  return (
    <div className={`input-field ${className}`}>
      <div className='input-field'> {/* TODO Remove this div once the SelectDropdown refactor is done. This is just here to make the element size equal to DropdownInput */}
        <DatePicker
          id={id}
          {...datePickerDefaultOptions()}
          selected={value}
          onChange={inputHandler}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          disabled={disabled}
          placeholderText={placeholder}
        />
        {label && <Label isActive={!!value} text={label} />}
      </div>
    </div>
  )
}

DateInput.propTypes = {
  // Id of the input element
  id: PropTypes.string,
  // Class of the input field container. Useful to define the size of the container in the grid.
  className: PropTypes.string,
  // Label text that shows above the input field
  label: PropTypes.string,
  // TODO implement the optional icon to the left of DatePicker
  // Name of the icon that shows to the left of the input element. Should correspond to an Font Awesome icon.
  // icon: PropTypes.string,

  // Placeholder text that shows in the input element before user types anything
  placeholder: PropTypes.string,

  // If set, disables the input element
  disabled: PropTypes.bool,
  // Value to show in the input element. If set, this component becomes controlled
  value: PropTypes.instanceOf(Date),
  // Callback function that gets called when the date in the datepicker changes
  onChange: PropTypes.func,
  // Minimum date that the datepicker allows to pick (inclusive)
  startDate: PropTypes.instanceOf(Date),
  // Maximum date that the datepicker allows to pick (incluse)
  endDate: PropTypes.instanceOf(Date)
}
