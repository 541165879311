import React, { useMemo, useEffect } from 'react'
import I18n from 'i18n'
import moment from 'moment'
import Tag from '../../../atomic/atoms/Tag'
import Spinner from '../../../common/Spinner'
import { Link } from 'react-router-dom'
import { accessLevel, momentDateFormat } from '../../../common/Constants'
import CheckOnly from './CheckOnly'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { WithModal } from '../../../modal/ModalProvider'
import { MyAxios as axios } from '../../../MyAxios'
import { WithSession } from '../../../session/SessionProvider'
import { WithFlashMessages } from '../../../flashmessages/FlashMessageProvider'
import PropTypes from 'prop-types'
import BulkMetaData from '../../../metadata/layout/BulkMetadData'
import Routes from '../../../common/Routes'
import { useBbox } from '../../../common/Hooks'
import Info from 'components/atomic/atoms/Info'
import CreateTransferRequest from 'components/data/layout/data_overview/CreateTransferRequest'

const FilteredData = (props) => {
  const { metadata = [] } = props
  const [showBulkEdit, setShowBulkEdit] = React.useState(false)
  const selectedVdoIds = useMemo(() => props.selectedItems.map(item => item.id), [props.selectedItems])
  const shownVdoIds = useMemo(() => metadata.map(item => item.versioned_data_object_id), [metadata])
  const allSelected = useMemo(() => {
    for (const vdoId of shownVdoIds) {
      if (!selectedVdoIds.includes(vdoId)) return false
    }
    return shownVdoIds.length > 0
  }, [selectedVdoIds, shownVdoIds])
  const [tableHeaderBbox, ref] = useBbox()

  const getTitle = (metadatum) => {
    return metadatum.title || metadatum.filename || I18n.translate('components.data.overview.filtered.table.title_placeholder')
  }

  const toSuccessFilteredData = () => {
    setShowBulkEdit(false)
    props.setSelectedItems([])
    props.onReload()
  }

  const toCancelFilteredData = () => {
    setShowBulkEdit(false)
  }

  const showEditMetadata = () => {
    setShowBulkEdit(true)
  }

  const showTransferOwnershipDialog = () => {
    props.setModalComponent(CreateTransferRequest, {
      selectedFiles: props.selectedItems,
      setSelectedFiles: props.setSelectedItems
    })
  }

  useEffect(() => {
    const header = ref.current
    // This can happen when the user clicks the Add Tags button
    if (!header) return

    const bbox = header.getBoundingClientRect()
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const top = scrollTop + bbox.top
    M.Pushpin.init(header, { top })
  }, [tableHeaderBbox])

  useEffect(() => {
    if (selectedVdoIds.length === 0) {
      toCancelFilteredData()
    }
  })

  const toggleAll = () => {
    if (allSelected) {
      props.setSelectedItems(props.selectedItems.filter(item => !shownVdoIds.includes(item.id)))
    } else {
      const newSelectedItems = Array.from(props.selectedItems)
      for (const metadatum of metadata) {
        if (!selectedVdoIds.includes(metadatum.versioned_data_object_id) && metadatum.access_level !== accessLevel.READ) {
          newSelectedItems.push({ id: metadatum.versioned_data_object_id, title: getTitle(metadatum) })
        }
      }
      props.setSelectedItems(newSelectedItems)
    }
  }

  const removeSelected = () => {
    const deletionRequests = []
    for (const vdoId of shownVdoIds) {
      if (!selectedVdoIds.includes(vdoId)) continue
      deletionRequests.push(axios.delete(`/api/v1/data/${vdoId}`, { headers: { Authorization: props.sessionToken } }))
    }
    if (deletionRequests.length === 0) return
    Promise.all(deletionRequests)
      .then(_responses => {
        props.flashMessages.push(
          I18n.t('flashmessages.data.remove_selected.successful'),
          props.flashMessages.duration.SHORT,
          props.flashMessages.levels.INFO
        )
        props.setSelectedItems([])
        props.onReload()
      })
      .catch(error => {
        console.error(error)
        props.flashMessages.push(
          I18n.t('flashmessages.data.remove_selected.failed'),
          props.flashMessages.duration.SHORT,
          props.flashMessages.levels.ERROR
        )
        props.setSelectedItems([])
        props.onReload()
      })
  }

  const showRemoveDialog = () => {
    const displayTitle = I18n.t('components.data.overview.filtered.table.selected_data_sets')
    props.setConfirmationDialog({ onConfirmation: () => removeSelected(), target: displayTitle, action: 'delete', noQuotes: true })
  }

  const withSelection = !props.network && props.selectedItems.length > 0

  return (
    <div className='background-background data-overview-card'>
      <div className='row'>
        <div className='col s12'>
          {withSelection &&
          (
            <div className='edit-delete-section data-overview-table'>
              <button
                className='waves-effect waves-light button-accent text-background-color background-accent text-medium text-m button-autowidth'
                onClick={() => showRemoveDialog()}
              >
                <FontAwesomeIcon className='button-icon' icon='trash' />
                {I18n.t('components.data.overview.filtered.table.remove_data_sets')}
              </button>
              <button
                className='waves-effect waves-light button-primary text-primary-color background-background text-medium text-m button-autowidth'
                onClick={() => showEditMetadata()}
              >
                <FontAwesomeIcon icon='edit' className='button-icon' />
                {I18n.t('components.data.overview.filtered.table.add_tags')}
              </button>
              <button
                className='waves-effect waves-light button-primary text-primary-color background-background text-medium text-m button-autowidth'
                onClick={() => showTransferOwnershipDialog()}
              >
                <FontAwesomeIcon icon='paper-plane' className='button-icon' />
                {I18n.t('components.data.overview.filtered.table.transfer_ownership')}
              </button>
            </div>
          )}
          {!(showBulkEdit && selectedVdoIds.length > 0) && (
            <table className={classNames('responsive-table data-overview-table', withSelection && 'with-selection')}>
              <thead id='table-header' className='table-header' ref={ref}>
                <tr>
                  <th>
                    <p className='checkonly'>
                      <label>
                        <input
                          type='checkbox'
                          checked={allSelected}
                          onChange={() => toggleAll()}
                        />
                        <span />
                      </label>
                    </p>
                  </th>
                  <th>{I18n.t('components.data.overview.filtered.table.item_title')}</th>
                  <th>{I18n.t('components.data.overview.filtered.table.start')}</th>
                  <th>{I18n.t('components.data.overview.filtered.table.end')}</th>
                  <th>{I18n.t('components.data.overview.filtered.table.sport')}</th>
                  <th>{I18n.t('components.data.overview.filtered.table.tags')}</th>
                  {props.network && <th>{I18n.t('components.data.overview.filtered.table.owner')}</th>}
                </tr>
              </thead>
              <tbody>
                {
                  metadata &&
                metadata.map(metadatum => {
                  // If the user types a query, metadatum gets 'magically' embedded into an 'item' key. We don't know
                  // why this is happening. The following is a workaround while we figure it out
                  const d = { ...metadatum, ...metadatum.item }
                  const title = getTitle(d)
                  // On the "my data" page, the access levels are all null, so it is not read only.
                  const readOnly = d.access_level === accessLevel.READ
                  return (
                    <tr key={d.id}>
                      <td>
                        <CheckOnly
                          id={d.versioned_data_object_id} title={title} selectedItems={props.selectedItems}
                          setSelectedItems={props.setSelectedItems}
                          readOnly={readOnly}
                        />
                        {readOnly && (
                          <div style={{ position: 'absolute', marginLeft: '-4px', marginTop: '-50px' }}>
                            <Info
                              text={I18n.t('components.data.overview.filtered.table.disabled')}
                              tooltipId='higher-z-tooltip'
                              reuseTooltip
                            />
                          </div>
                        )}
                      </td>
                      <td><Link to={Routes.data.showFn(d.versioned_data_object_id)}>{title}</Link></td>
                      <td>{moment(d.event_start).format(momentDateFormat)}</td>
                      <td>{moment(d.event_end).format(momentDateFormat)}</td>
                      <td>{d.sport && d.sport.name}</td>
                      <td className='tag-field'><Tag tags={d.tags} onClick={props.onTagClick} /></td>
                      {props.network &&
                        <td>{`${d.owner.first_name || I18n.t('profile.show.first_name')} ${d.owner.last_name || I18n.t('profile.show.last_name')}`}</td>}
                    </tr>
                  )
                })
                }
              </tbody>
            </table>
          )}
          {(showBulkEdit && selectedVdoIds.length > 0) ? BulkMetaData(props, selectedVdoIds, toCancelFilteredData, toSuccessFilteredData) : null}
          <Spinner ready={props.ready} />
        </div>
      </div>
    </div>
  )
}

FilteredData.propTypes = {
  // Array of metadata object
  metadata: PropTypes.arrayOf(PropTypes.object),
  selectedItems: PropTypes.array
}

export default WithFlashMessages(WithModal(WithSession(FilteredData)))
