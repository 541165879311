import React from 'react'
import TimelineEntry from '../../timeline/layout/TimelineEntry'
import Spinner from '../../common/Spinner'
import I18n from 'i18n'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class RecentActivity extends React.Component {
  getPageButtons () {
    // If we have no pages (this.props.pageCount === 0) or if everything fits on a
    // single page (this.props.pageCount === 1), don't show pagination navigation.
    if (!this.props.recentActivity || this.props.pageCount < 2) {
      return (<></>)
    }
    // maxPagesToShow must be 5 or more. Can also be an even value, but use odd numbers for best results
    const maxPagesToShow = 9

    // create an array with values [1 .. pageCount]
    let pagesToShow = Array.from(Array(this.props.pageCount + 1).keys())

    // remove page 0, since it is not a valid page
    pagesToShow.shift()

    if (this.props.pageCount > maxPagesToShow) {
      const currentPageIdx = this.props.currentPage - 1
      // 1st page (index 0) is always shown, so start at least at index 1
      const minPageIdx = 1

      // last page (at index pageCount - 1) is always included, so end latest at pageCount - 2
      const maxPageIdx = this.props.pageCount - 1

      // prevent startIdx from going out of bounds on both sides
      const startIdx = Math.min(Math.max(currentPageIdx - Math.floor((maxPagesToShow - 2) / 2), minPageIdx), maxPageIdx)
      // we know startIdx is valid, so prevent startIdx + maxPagesToShow from going out of bounds on the right
      const endIdx = Math.min(startIdx + maxPagesToShow - 2, maxPageIdx)

      // if endIdx - startIdx < maxPagesToShow - 2, we need to crrect the startIdx for the difference
      // Since this case only occurs at the end of the page list, and we know that the number of pages is greater
      // than what will be shown, we can safely subtract the difference from the startIdx
      const correction = maxPagesToShow - 2 - (endIdx - startIdx)
      // apply the correction to startIdx, and add 1 to endIdx, since slice returns elements up to (but not including) the given end
      pagesToShow = pagesToShow.slice(startIdx - correction, endIdx)
    }
    // only add the first page if it is not yet in the list
    if (pagesToShow[0] !== 1) { pagesToShow.unshift(1) }
    // only add the last page if it is not yet in the list

    if (pagesToShow[pagesToShow.length - 1] !== this.props.pageCount) { pagesToShow.push(this.props.pageCount) }

    // render each page link, and add a first and last button if the current range does not include the first or last page
    return (
      <div className='row'>
        <div className='col s12 center-align'>
          {pagesToShow.map(page => {
            return (
              <span
                key={`pagination-${page}`}
                className={classNames('page-button', page === this.props.currentPage && 'page-button-active')}
                onClick={() => this.props.onSetPage(page)}
              >
                {page}
              </span>
            )
          })}
        </div>
      </div>
    )
  }

  render () {
    return (
      <>
        {this.getPageButtons()}
        <div className='row no-margin'>
          {this.props.recentActivity && this.props.recentActivity.length > 0 && (
            <div className='col s12'>
              {this.props.recentActivity.map((vdo) => (
                <TimelineEntry
                  key={vdo.id}
                  data={{ versioned_data_object: vdo }}
                  metadatum={vdo.metadatum}
                  showCompact={this.props.showCompact}
                />
              ))}
            </div>
          )}
          {this.props.recentActivity === undefined && (
            <Spinner ready={false} transparent />
          )}
          {this.props.recentActivity && this.props.recentActivity.length === 0 && (
            <p className='text-muted'>
              {this.props.noDataMessage || I18n.t('network.group.label.no_activity_in_the_past_month')}
            </p>
          )}
        </div>
        {this.getPageButtons()}
      </>
    )
  }
}

RecentActivity.defaultProps = {
  showCompact: false
}

RecentActivity.propTypes = {
  recentActivity: PropTypes.array,
  myProfile: PropTypes.object,
  title: PropTypes.string,
  noDataMessage: PropTypes.string,
  showCompact: PropTypes.bool
}

export default RecentActivity
