import React from 'react'
import QuestionnaireDetails from './QuestionnaireDetails'
import VolleyballDetails from './VolleyballDetails'
import PropTypes from 'prop-types'
import { dataTypes, collectionTypes } from '../../../common/Constants'
import VolleyballJumpDetails from './VolleyballJumpDetails'
import FitbitDetails from './FitbitDetails/FitbitDetails'
import GarminDetails from './GarminDetails/GarminDetails'
import PolarDetails from './PolarDetails/PolarDetails'
import SoccerDetails from './SoccerDetails'
import Summary from './common/Summary'
import VideoDetails from './VideoDetails/VideoDetails'
import _ from 'lodash'
import { useSummaryStringUnits } from '../../../common/Units'
import { WithErrorBoundary } from '../../../errorboundary/ErrorBoundaryProvider'
import ActivitySummary from './common/ActivitySummary'
import CsvSummary from './common/CsvSummary'
import TempSummary from './common/TempSummary'
import WeFitterDetails from './WeFitterDetails/WeFitterDetails'

// TIMELINE DETAILS STRUCTURE
// ==========================
// Details are rendered according to data type, structured data object flavour and sport (in that order).
// This top level component points to the right data type parser. When implementing a new file type visualization follow
// this hierarchy:
// details/<name of data type>
// |- <name of data type>Details.jsx: Component that will choose which parsers to render depending on the flavours
// |- <name of data type><name of flavour>Flavour.jsx: This is the lowest parsing component. It will adapt the data to
//                                                     something that the visualization components can understand
// There is separation of concern between data processing and data rendering. The components that render a visualization
// live under details/common. the <Sport>Summary.jsx components have a list of fields common for all sports. This way,
// we can for example share the same visualization for all cycling files, independently of whether they are strava or FIT
// For examples check FitFileDetails and common/CyclingSummary.jsx

const detailsFactory = (dataType, vdo, icon, metadatum, summary) => {
  switch (dataType) {
    case dataTypes.volleybal_stats_type:
      return VolleyballDetails
    case dataTypes.volleybal_jump_type:
      return VolleyballJumpDetails
    case dataTypes.questionnaire_type:
      return QuestionnaireDetails
    case dataTypes.scisports_soccer_type:
      return SoccerDetails
    case dataTypes.fitbit_type:
      return FitbitDetails
    case dataTypes.garmin_type:
      return GarminDetails
    case dataTypes.polar_type:
      return PolarDetails
    case dataTypes.we_fitter_type:
      return WeFitterDetails
    case dataTypes.mp4_video_file_type:
      return VideoDetails
    case dataTypes.ice_skating_hr_type:
      return Summary
    case dataTypes.ice_skating_lap_type:
      return Summary
    case dataTypes.unstructured:
      return Summary
    default:
      return undefined
  }
}

const DetailsWrapper = (props) => {
  const vdo = _.get(props, 'vdo.versioned_data_object')
  const dataType = _.get(vdo, 'data_type.data_type')
  const summary = _.get(vdo, 'summary')
  const compact = _.get(props, 'compact', false)
  const icon = props.icon
  const metadatum = props.metadatum
  if (!vdo) return <></>
  if (!dataType) return <></>

  function processSummary (summary, icon, metadatum, compact) {
    if (!summary) {
      /* Jupyter notebooks do not have a summary, but are valid datatypes */
      // We use the 'whatever' datatype in mock specs. Don't print a warning for it.
      if (dataType !== dataTypes.jupyterhub_type && (dataType !== 'whatever' || process.env.NODE_ENV !== 'test')) {
        console.log(`data_type not understood: ${dataType}`)
      }
      return <></>
    }

    const summaryWithStringUnits = useSummaryStringUnits(summary, 2)
    if (!summaryWithStringUnits) {
      return <></>
    }

    const defaultProps = { icon: icon, metadatum: metadatum, compact: compact }
    // At the moment we only support csv files and activities (training events) for the SDV format. In the future this
    // will be extended to other things like questionnaire data
    switch (summary.collection_type) {
      case collectionTypes.CSV_FILE:
        return <CsvSummary summary={summaryWithStringUnits} {...defaultProps} />
      case collectionTypes.TEMP:
        return <TempSummary summary={summaryWithStringUnits} {...defaultProps} />
      default:
        return <ActivitySummary summary={summaryWithStringUnits} {...defaultProps} />
    }
  }
  const Details = detailsFactory(dataType, vdo, icon, metadatum, summary)
  if (Details) {
    return <Details data={vdo} icon={icon} metadatum={metadatum} compact={compact} />
  }

  return processSummary(summary, icon, metadatum, compact)
}

DetailsWrapper.propTypes = {
  vdo: PropTypes.object,
  compact: PropTypes.bool
}

export default WithErrorBoundary(DetailsWrapper, 'timeline.error.detailed_view')
