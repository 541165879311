import React from 'react'
import { Route, Redirect } from 'react-router'
import NotFoundWrapper from '../router/NotFoundWrapper'
import Routes from '../common/Routes'
import GroupsController from './GroupsController'
import ConnectionsController from './ConnectionsController'

export default class NetworkRoutes extends React.Component {
  render () {
    const { groupsControllerProps, connectionsControllerProps } = this.props
    return (
      <NotFoundWrapper slug='network'>
        <Route exact path={Routes.network.index}> <Redirect to={Routes.network.groups.index} /> </Route>
        <Route exact path={Routes.network.groups.index} render={(props) => <GroupsController {...groupsControllerProps} {...props} />} />
        <Route exact path={Routes.network.groups.create} render={(props) => <GroupsController {...groupsControllerProps} {...props} createGroup />} />
        <Route exact path={Routes.network.groups.show} render={(props) => <GroupsController {...groupsControllerProps} {...props} showGroup />} />
        <Route exact path={Routes.network.groups.join} render={(props) => <GroupsController {...groupsControllerProps} {...props} joinGroup />} />
        <Route exact path={Routes.network.groups.edit} render={(props) => <GroupsController {...groupsControllerProps} {...props} editGroup />} />
        <Route exact path={Routes.network.groups.announcement.show} render={(props) => <GroupsController {...groupsControllerProps} {...props} groupAnnouncement />} />
        <Route exact path={Routes.network.connections.index} render={(props) => <ConnectionsController {...connectionsControllerProps} {...props} />} />
      </NotFoundWrapper>
    )
  }
}
