import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { hcBlack, hcGreen, hcOrange, hcRed } from 'components/common/Constants'
import I18n from 'i18n'
import { formatDateTime, initializeHighcharts, titleWithTooltip } from 'components/common/Utils'
import GenericPlaceholder from '../../../../common/GenericPlaceholder'
class TrainingSatisfactionChart extends React.Component {
  constructor (props) {
    super(props)

    initializeHighcharts(true)
    this.state = {}
  }

  calculateColor (entry) {
    if (entry.training_satisfaction <= 2) return hcRed
    if (entry.training_satisfaction >= 4) return hcGreen
    if (entry.training_satisfaction > 2 && entry.training_satisfaction < 4) return hcOrange
    return hcBlack
  }

  render () {
    if (!this.props.trainingLogRows) {
      return (
        <GenericPlaceholder title={I18n.t('components.dashboards.questionnaire.training_satisfaction.title')} />
      )
    }

    const scatterData = this.props.trainingLogRows.map(entry => {
      return {
        x: entry.training_start_date,
        y: entry.training_satisfaction,
        color: this.calculateColor(entry),
        rpe: entry.rpe,
        training_type: entry.training_type,
        training_session: entry.training_session,
        formatted_start_date: formatDateTime(entry.training_start_date)
      }
    })
    return (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            title: titleWithTooltip(
              I18n.t('components.dashboards.questionnaire.training_satisfaction.title'),
              I18n.t('components.dashboards.questionnaire.training_satisfaction.info')
            ),
            series: [
              {
                type: 'scatter',
                data: scatterData,
                tooltip: {
                  headerFormat: '',
                  pointFormat: I18n.t('components.dashboards.questionnaire.training_satisfaction.scatter.tooltip')
                }
              }
            ],
            plotOptions: {
              line: {
                color: hcBlack,
                marker: {
                  enabled: false
                }
              },
              series: {
                animation: false
              }
            },
            yAxis: {
              title: {
                text: I18n.t('components.dashboards.questionnaire.training_satisfaction.yaxis')
              },
              min: 0,
              max: 5.1
            },
            xAxis: {
              title: {
                text: ''
              },
              type: 'datetime',
              dateTimeLabelFormats: {
                day: '%d %b'
              }
            }
          }}
        />
      </>
    )
  }
}

export default TrainingSatisfactionChart
