import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faVolleyballBall,
  faLowVision,
  faExclamationTriangle,
  faStreetView,
  faStar,
  faPlay,
  faPause,
  faCalendarWeek,
  faCalendarPlus,
  faRetweet,
  faRunning,
  faSkating,
  faQuestion,
  faEdit,
  faEye,
  faDownload,
  faHeartbeat,
  faTrash,
  faTimes,
  faCheck,
  faPlus,
  faBell,
  faUser,
  faPen,
  faSignOutAlt,
  faCircleNotch,
  faInfoCircle,
  faChartLine,
  faChartBar,
  faCopy,
  faSyncAlt,
  faPoll,
  faUserCog,
  faBiking,
  faWalking,
  faHiking,
  faSwimmer,
  faDumbbell,
  faSlidersH,
  faUserPlus,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faFile,
  faShare,
  faSortDown,
  faSortUp,
  faSort,
  faExternalLinkAlt,
  faExpand,
  faCompress,
  faSync,
  faHashtag,
  faArrowCircleRight,
  faEnvelopeOpenText,
  faUsers,
  faFileCode,
  faFileCsv,
  faFileArchive,
  faEllipsisH,
  faSlash,
  faHourglassStart,
  faChair,
  faBed,
  faTree,
  faStethoscope,
  faSmile,
  faLink,
  faTemperatureLow,
  faFileDownload,
  faUserInjured,
  faStarOfLife,
  faGamepad,
  faUserTie,
  faPaperPlane
} from '@fortawesome/free-solid-svg-icons'

import {
  faFutbol
} from '@fortawesome/free-regular-svg-icons'
import {
  faStrava,
  faGoogle,
  faPython
} from '@fortawesome/free-brands-svg-icons'
// Initialize the icons
library.add(
  faExclamationTriangle,
  faLowVision,
  faRetweet,
  faCalendarWeek,
  faCalendarPlus,
  faVolleyballBall,
  faFutbol,
  faRunning,
  faSkating,
  faPlay,
  faPause,
  faQuestion,
  faEdit,
  faEye,
  faDownload,
  faStrava,
  faHeartbeat,
  faGoogle,
  faTrash,
  faTimes,
  faCheck,
  faPlus,
  faBell,
  faUser,
  faPen,
  faSignOutAlt,
  faCircleNotch,
  faInfoCircle,
  faChartLine,
  faChartBar,
  faCopy,
  faSyncAlt,
  faPoll,
  faStreetView,
  faUserCog,
  faBiking,
  faWalking,
  faHiking,
  faSwimmer,
  faDumbbell,
  faPython,
  faSlidersH,
  faUserPlus,
  faShare,
  faStar,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faFile,
  faSortDown,
  faSortUp,
  faSort,
  faExternalLinkAlt,
  faExpand,
  faCompress,
  faSync,
  faHashtag,
  faArrowCircleRight,
  faEnvelopeOpenText,
  faUsers,
  faFileCode,
  faFileCsv,
  faFileArchive,
  faEllipsisH,
  faSlash,
  faHourglassStart,
  faChair,
  faBed,
  faTree,
  faStethoscope,
  faSmile,
  faLink,
  faTemperatureLow,
  faFileDownload,
  faUserInjured,
  faStarOfLife,
  faGamepad,
  faUserTie,
  faPaperPlane
)
