import React, { useRef } from 'react'
import { WithSession } from '../../session/SessionProvider'
import { WithBackend } from '../../backend/BackendProvider'
import GroupEditOrCreateForm from './GroupEditOrCreateForm'
import I18n from 'i18n'

const GroupCreate = props => {
  const icOptionalRef = useRef(null)
  const icRequiredRef = useRef(null)
  const icOptionalEnabledRef = useRef(null)

  const actuallySubmit = pictureId => {
    const title = document.getElementById('group-name').value
    const description = document.getElementById('group-description').value
    const anonymized = document.getElementById('group-anonymized').checked
    const informedConsentChecked = document.getElementById('group-informed-consent')?.checked
    const informedConsentBody = document.getElementById('informed-consent-text')?.value
    const informedConsentRequired = icRequiredRef.current?.value
    const informedConsentOptional = icOptionalRef.current?.value
    const informedConsentOptionalEnabled = icOptionalEnabledRef.current?.props?.currentValue

    const informedConsentStruct = {
      body: informedConsentBody,
      required: informedConsentRequired
    }

    if (informedConsentOptionalEnabled) {
      // we do the checking in the backend because otherwise we have to do it twice.
      informedConsentStruct.optional = informedConsentOptional
    }

    const data = {
      group: {
        name: title,
        description: description,
        anonymized: anonymized,
        informed_consent: informedConsentChecked ? informedConsentStruct : undefined
      }
    }
    if (pictureId) {
      data.group.picture = pictureId
    }

    props.backend.createGroup(data)
      .then(() => props.onSuccess())
      .catch((errors) => props.onFailure(errors.response.data.errors))
  }

  const handleSubmit = picture => {
    if (!picture) {
      actuallySubmit(null)
    } else {
      // If picture is not null, then it is a promise, which has to be resolved. Resolving the promise
      // returns an id that we can submit.
      picture.then(id => {
        actuallySubmit(id)
      })
    }
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='col s12 center-align text-heavy muted-header'>
          <h5>{I18n.t('network.groups.create_new_group')}</h5>
        </div>
      </div>
      <GroupEditOrCreateForm
        handleSubmit={handleSubmit}
        icOptionalRef={icOptionalRef}
        icRequiredRef={icRequiredRef}
        icOptionalEnabledRef={icOptionalEnabledRef}
      />
    </div>
  )
}

export default WithBackend(WithSession(GroupCreate))
