import { defaultProfilePicture, membershipStates, roles } from '../../common/Constants'
import HoverableProfile from '../../common/HoverableProfile'
import ChangeRoleButton from './ChangeRoleButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef } from 'react'
import I18n from 'i18n'
import Search from './Search'
import ProfileGroupResult from './Search/ProfileGroupResult'
import ReactTooltip from 'react-tooltip'
import { TimeAgoPlain } from '../../common/TimeAgo'
import { WithModal } from '../../modal/ModalProvider'

const MemberList = (props) => {
  const searchRef = useRef(null)

  useEffect(() => {
    ReactTooltip.hide() // Hide tooltip, in case a membership was deleted
    ReactTooltip.rebuild() // This makes tooltips available if new membership is created
  }, [props.memberships])

  useEffect(() => {
    const elems = document.querySelectorAll('.dropdown-trigger')
    M.Dropdown.init(elems, { constrainWidth: false })
  }, [props.memberships])

  const deleteMembershipConfirmation = membership => {
    const memberName = `${membership.profile.first_name} ${membership.profile.last_name}`
    props.setConfirmationDialog({
      onConfirmation: () => props.onDeleteMembership(membership),
      target: memberName,
      action: 'delete'
    })
  }

  const includeMembership = (membership) => {
    const queryString = searchRef.current?.value
    if (!queryString) return true

    return `${membership.profile.first_name.toLowerCase()} ${membership.profile.last_name.toLowerCase()}`.includes(queryString.toLowerCase())
  }

  return (
    <>
      {props.isAdmin && (
        <div className='row'>
          <div className='col s12 xl6'>
            <Search
              fieldId='group-detail-members' Component={ProfileGroupResult}
              onSearch={props.onSearch}
              label={I18n.t('network.group.label.search_profile')}
              onClick={props.onSendInvite}
              inputRef={searchRef}
            />
          </div>
        </div>
      )}
      <div className='row margin-top-large'>
        {props.memberships.filter(membership => includeMembership(membership)).map((membership) => (
          <div className='col s12 l6 xl4' key={`member-${membership.id}`}>
            <div className='row profile-row member-list'>
              <div className='col no-margin valign-wrapper s12'>
                <div className='col no-margin s2 l3' style={{ minWidth: '50px' }}>
                  <img src={membership.profile.picture || defaultProfilePicture} className='responsive-img circle' />
                </div>
                <div className='col no-margin s8 l9'>
                  <div className='row no-margin'>
                    <div className='text-medium text-primary-color text-m'>
                      <HoverableProfile profile={membership.profile} />
                    </div>
                    <div className='text-light text-muted text-s'>
                      {(membership.state === membershipStates.ACCEPTED && I18n.t('group.detail.member_since', { since: TimeAgoPlain(new Date(membership.created_at || '2019-08-20T13:57:50.039+02:00')) })) ||
                      I18n.t('group.detail.invited_at', { since: TimeAgoPlain(new Date(membership.created_at || '2019-08-20T13:57:50.039+02:00')) })}
                    </div>
                    <div className='profile-roles'>
                      {membership.state !== membershipStates.ACCEPTED && (
                        <div
                          className='chip no-margin center-align text-primary-color text-s text-heavy'
                        >
                          {I18n.t('group.detail.pending')}
                        </div>
                      )}
                      {membership.role === roles.administrator && (
                        <div
                          className='chip no-margin center-align text-primary-color text-s text-heavy'
                        >
                          {I18n.t('group.detail.admin')}
                        </div>
                      )}
                      {membership.role === roles.owner && (
                        <div
                          className='chip no-margin center-align text-primary-color text-s text-heavy'
                        >
                          {I18n.t('group.detail.owner')}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='col no-margin s1'>
                  {props.isAdmin && membership.role !== roles.owner && membership.id !== props.myMembership.id && (
                    <>
                      <div className='dropdown-trigger member-dropdown' data-target={`dropdown-${membership.id}`}>
                        <FontAwesomeIcon
                          size='2x'
                          icon='ellipsis-h'
                          style={{ color: 'grey' }}
                        />
                      </div>
                      <ul id={`dropdown-${membership.id}`} className='dropdown-content member-roles'>
                        <li>
                          <ChangeRoleButton membership={membership} onChangeRole={props.onChangeRole}>
                            {I18n.t('group.detail.change_role')}
                          </ChangeRoleButton>
                        </li>
                        <li className='remove-member text-accent-color' onClick={() => deleteMembershipConfirmation(membership)}>
                          {I18n.t('group.detail.remove_from_group')}
                          <FontAwesomeIcon
                            className='pointer-grow change-role'
                            icon='trash'
                          />
                        </li>
                      </ul>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default WithModal(MemberList)
