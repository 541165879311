import React from 'react'
import { EMPTY_RECORD } from '../../../common/Constants'

const TableInteractiveEntry = (props) => {
  const { elements, displayOrder, onClick, dragndropProps } = props

  const pointerCursorStyle = { cursor: 'pointer' }
  return (
    <tr onClick={onClick} style={onClick && pointerCursorStyle} ref={dragndropProps?.innerRef} {...dragndropProps?.draggableProps} {...dragndropProps?.dragHandleProps}>
      {displayOrder.map((displayParameter) => {
        const content = elements[displayParameter]

        if (content === undefined || content === null || Number.isNaN(content)) {
          return <td key={displayParameter}>{EMPTY_RECORD}</td>
        }

        // prevent a lot of decimals in table cell content
        if (typeof content === 'string' && content.endsWith('%')) {
          const number = parseFloat(content.slice(0, -1))
          if (!isNaN(number) && Math.trunc(number) !== number) {
            // return number as string with percentage symbol, but rounded up or down
            return <td key={displayParameter}>{(number.toFixed(0) + '%')}</td>
          }
        }

        return <td key={displayParameter}>{content}</td>
      })}
    </tr>
  )
}

export default TableInteractiveEntry
