import React from 'react'
import PropTypes from 'prop-types'
import { useObjectStringUnits } from '../../../../common/Units'
import I18n from 'i18n'
import Info from '../../../../atomic/atoms/Info'
import _ from 'lodash'

const defaultFields = [
  'avg_distance_per_week',
  'avg_duration_per_week',
  'avg_trainings_per_week',
  'avg_elevation_per_week',
  'avg_hr',
  'max_hr',
  'avg_speed',
  'max_speed',
  'avg_power',
  'max_power'
]

const SeasonTable = (props) => {
  if (!props.seasons) return <></>

  let currentSeason = _.get(props, 'seasons.current.summary', {})
  let previousSeason = _.get(props, 'seasons.previous.summary', {})

  const { unitConversionTable = {}, fields = defaultFields } = props

  currentSeason = useObjectStringUnits(currentSeason, unitConversionTable)
  previousSeason = useObjectStringUnits(previousSeason, unitConversionTable)

  return (
    <>
      <div className='row'>
        <div className='col s12 m6'>
          <p> <b> {I18n.t('components.dashboards.multi_activity.season.current_start')} </b> {currentSeason.start_date}</p>
          <p> <b> {I18n.t('components.dashboards.multi_activity.season.current_end')} </b> {previousSeason.start_date}</p>
        </div>
        <div className='col s12 m6'>
          <p> <b> {I18n.t('components.dashboards.multi_activity.season.previous_start')} </b> {currentSeason.end_date}</p>
          <p> <b> {I18n.t('components.dashboards.multi_activity.season.previous_end')} </b> {previousSeason.end_date}</p>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <table className='responsive-table'>
            <thead>
              <tr>
                <th>{I18n.t('components.dashboards.multi_activity.season.season')}</th>
                {fields.map(f => (
                  <th key={f}>
                    {I18n.t(`components.dashboards.multi_activity.season.${f}`)}
                    {
                      f.includes('per_week') && <Info text={I18n.t('components.dashboards.multi_activity.season.weekly_avg_explanation')} tooltipId={`season-table-tooltip-${f}`} inline />
                    }
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{I18n.t('components.dashboards.multi_activity.season.current')}</td>
                {fields.map(f => (
                  <td key={f}>{currentSeason[f]}</td>
                ))}
              </tr>
              <tr>
                <td>{I18n.t('components.dashboards.multi_activity.season.previous')}</td>
                {fields.map(f => (
                  <td key={f}>{previousSeason[f]}</td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

SeasonTable.propTypes = {
  // seasons object from the cycling multiactivity dashboard
  seasons: PropTypes.object,
  fields: PropTypes.arrayOf(PropTypes.string),
  unitConversionTable: PropTypes.object
}

export default SeasonTable
