import React from 'react'
import { Link } from 'react-router-dom'
import I18n from 'i18n'

import Routes from '../../../common/Routes'

const FirstConnectionMessage = () => {
  return (
    <div className='row valign-wrapper'>
      <div className='col s2 emoji-icon bouncing-animation'>
        🎉
      </div>
      <div className='col s10 text-primary-color text-l'>
        <Link to={Routes.sharing.index}>
          {I18n.translate('network.connection.first_connection_request_message')}
        </Link>
      </div>
    </div>
  )
}

export default FirstConnectionMessage
