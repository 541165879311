import React from 'react'
import { capitalizeFirstLetter, smartTranslate } from '../../../common/Utils'
import I18n from 'i18n'
import useMaterializeSelect from 'components/hooks/MaterializeSelect'

interface Protocol {
  name: string
}

export interface ProtocolSelectionDropdownProps {
  onChange: () => void
  selected: string
  availableProtocols: Protocol[]
}

const ProtocolSelectionDropdown: React.FC<ProtocolSelectionDropdownProps> = ({
  onChange, selected,
  availableProtocols
}): React.ReactElement => {
  useMaterializeSelect()

  return (
    <>
      <div className='input-field'>
        <select
          className='protocol-name'
          onChange={onChange}
          value={selected}
        >
          {availableProtocols.map(q => (
            <option
              key={q.name}
              value={q.name}
            >{smartTranslate(`group.detail.questionnaires.protocols.${q.name}`, capitalizeFirstLetter(q.name))}
            </option>
          ))}
        </select>
        <label className='colored'>
          {I18n.t('group.detail.questionnaires.select_model')}
        </label>
      </div>
    </>
  )
}

ProtocolSelectionDropdown.defaultProps = {
  availableProtocols: []
}

export default ProtocolSelectionDropdown
