import React from 'react'
import I18n from 'i18n'
import GroupInvite from './GroupInvite'
import Spinner from '../../common/Spinner'

class GroupInviteList extends React.Component {
  render () {
    const { groupInvites, onAccept, onDelete } = this.props
    return (
      <>
        <div className='col s12 text-heavy muted-header'>
          <div className='row'>
            <div className='col s12'>
              <h5>{I18n.t('network.groups.group_invite.title', { count: groupInvites.length })}</h5>
            </div>
          </div>
        </div>
        <div className='col s12'>
          <Spinner ready={this.props.dataRetrieved} transparent />
          {groupInvites.map(groupInvite => (
            <GroupInvite onAccept={onAccept} key={groupInvite.id} groupInvite={groupInvite} onDelete={onDelete} />
          ))}
        </div>
      </>
    )
  }
}

export default GroupInviteList
