import React from 'react'
import { defaultProfilePicture } from '../Constants'
import classNames from 'classnames'
import HoverableProfile from '../HoverableProfile'

const UserProfileTableEntry = (props) => {
  const { profile } = props
  const { id, picture, first_name: firstName, last_name: lastName } = profile
  return (
    <div className={classNames('profile-row available-member', props.singleLine && 'single-line')} key={id}>
      <div className='valign-wrapper'>
        <img
          src={picture || defaultProfilePicture} className='responsive-img circle'
          alt={`${firstName} ${lastName}`}
        />
        <div className='text-medium text-m'>
          {!props.singleLine && `${firstName} ${lastName}`}
          {props.singleLine && <HoverableProfile profile={profile} />}
        </div>
      </div>
    </div>
  )
}

export default UserProfileTableEntry
