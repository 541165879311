import React, { useMemo, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { identity } from '../../../common/Units'
import _ from 'lodash'
import 'chartjs-plugin-crosshair'
import 'chartjs-plugin-annotation'
import PropTypes from 'prop-types'
import { Toggle } from '../../../common/form'
import { hexToRgbaString, interpolateColors, zipZoneLimits, zipData, sampleData } from '../../../common/Utils'
import Variables from '../../../../stylesheets/variables.module.scss'
import classNames from 'classnames'
import I18n from 'i18n'
import { movingAverage } from '../../../common/Math'
import { SimpleSlider } from '../../../common/Slider'
import MyReactTooltip from '../../../MyReactTooltip'
import { chartjsCrosshairProperties } from '../../../common/Constants'
const { primaryColorLight, primaryColor, blackTransparent } = Variables

const minKernelSize = 0
const maxKernelSize = 60
const maxTicksLimit = 5
export const PRIMARY_Y_AXIS = 'primary-y'
export const SECONDARY_Y_AXIS = 'secondary-y'
export const HIDDEN_AXIS = 'hidden'

const SmoothSlider = ({ kernelSize, onKernelSizeChange }) => (
  <div className='row'>
    <div className='col s12'>
      <div className='smoothness-label'>
        {I18n.t('components.dashboards.smooth_factor')}
      </div>
      <div>
        <MyReactTooltip id='time-series-smoothness-slider' />
        <SimpleSlider
          minVal={minKernelSize}
          maxVal={maxKernelSize}
          value={kernelSize}
          onChange={onKernelSizeChange}
          toolTipTranslation='components.dashboards.smooth_factor'
          noDataTip
        />
      </div>
    </div>
  </div>
)

const TimeSeriesChart = (props) => {
  const {
    x,
    ys,
    datasetConfigs = [],
    height = 250,
    xTickCallback = identity,
    yTickCallback = identity,
    elTickCallback = identity,
    xLabel = '',
    yLabels = [''],
    tooltipCallbacks = {},
    onSelect = () => {},
    selection = [],
    zones,
    zoneColors,
    zoneAlpha = 0.6, // Alpha channel for the zone annotations color
    suggestedMin = 0,
    suggestedMax,
    elMinOffset = -15,
    elMinRange = 200,
    suppressTooltips = false,
    maxSamples,
    smooth = false,
    range = [undefined, undefined],
    scaleColors = []
  } = props
  if (!x || !ys) return <></>

  // These are only need if elevation is included in the chart.
  let elMin
  let elMax
  // Only calculate them if it is.
  if (ys[1] !== undefined) {
    const ysMin = _.min(ys[1])
    const ysMax = _.max(ys[1])
    const ysRange = ysMax - ysMin
    elMin = Math.floor((ysMin + elMinOffset) / 5) * 5
    elMax = Math.ceil((ysRange > elMinRange ? ysMax : ysMin + elMinRange) / 5) * 5
  }

  // If suggestedMax is undefined chart.js will use the max value in the dataset
  const realSuggestedMax = suggestedMax || (zones && zones[zones.length - 1])

  const { fromColor = primaryColorLight, toColor = primaryColor } = _.get(datasetConfigs, '[0]')

  const [zonesEnabled, setZonesEnabled] = useState(false)
  const [kernelSize, setKernelSize] = useState(0)

  const filterXTicks = (value, index, ticks) => {
    if (ticks.length < 3) {
      // for few ticks, always display all of them.
      return xTickCallback(value, index, ticks)
    }

    const tickdistance = ticks[1] - ticks[0]

    if (value % tickdistance !== 0) {
      // hide all ticks that are not equally spaced
      return ''
    }

    return xTickCallback(value, index, ticks)
  }

  // trims .0's from the speeds.
  const trimYTickZeros = (value, index, ticks) =>
    yTickCallback(value, index, ticks).toString().replace(/\.0+ /, ' ')

  const scales = {
    xAxes: [{
      type: 'linear', // Needs to be linear for the crosshairs to work
      id: 'x-axis-0',
      ticks: {
        callback: filterXTicks,
        maxTicksLimit: 10,
        maxRotation: 45,
        minRotation: undefined,
        min: range[0] || 0,
        max: range[1] || _.max(x),
        autoSkip: true,
        autoSkipPadding: 5
      },
      scaleLabel: {
        display: true,
        labelString: xLabel
      }
    }],
    yAxes: [{
      id: PRIMARY_Y_AXIS,
      ticks: {
        callback: trimYTickZeros,
        maxTicksLimit: maxTicksLimit,
        suggestedMin: suggestedMin,
        suggestedMax: realSuggestedMax
      },
      scaleLabel: {
        display: true,
        labelString: yLabels[0],
        fontColor: scaleColors[0]
      }
    }, {
      id: SECONDARY_Y_AXIS,
      display: ys.length > 1,
      gridLines: false,
      position: 'right',
      ticks: {
        callback: elTickCallback,
        maxTicksLimit: maxTicksLimit,
        min: elMin,
        max: elMax,
        suggestedMin: suggestedMin, // tick_min = min(suggestedMin, min_value_in_data)
        suggestedMax: realSuggestedMax,
        padding: 5
      },
      scaleLabel: {
        display: !!yLabels[1],
        labelString: yLabels[1],
        fontColor: scaleColors[1]
      }
    }, {
      id: HIDDEN_AXIS,
      display: false
    }]
  }

  const crosshair = {
    ...chartjsCrosshairProperties,
    sync: {
      enabled: true,
      suppressTooltips: suppressTooltips
    },
    zoom: { enabled: true },
    callbacks: {
      beforeZoom: (start, end) => {
        onSelect(start, end)
        return false
      }
    }
  }

  const annotation = {
    annotations: [
      ...selectionAnnotation(),
      ...zoneAnnotations(),
      ...selectionMuteAnnotations()
    ]
  }

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    animation: {
      duration: 0
    },
    legend: {
      display: false
    },
    layout: {
      padding: {
        left: 10
      }
    },
    tooltips: {
      mode: 'index',
      axis: 'x',
      position: 'nearest', // do not use average, as the value of invisible lines is off the chart
      intersect: false,
      callbacks: tooltipCallbacks
    },
    scales: scales,
    annotation: annotation,
    plugins: {
      crosshair: crosshair
    },
    onClick: () => onSelect(undefined, undefined)
  }

  const smoothYs = useMemo(() => {
    return smooth && kernelSize > 0 ? ys.map(y => movingAverage(y, kernelSize)) : ys
  }, [ys, kernelSize, smooth])

  const data = useMemo(() => ({
    datasets: smoothYs.map((y, idx) => ({
      label: idx,
      data: zipData(sampleData(x, maxSamples), sampleData(y, maxSamples)),
      pointRadius: 0,
      fill: false,
      ...datasetConfigs[idx]
    }))
  }), [smoothYs, x, datasetConfigs])

  function handleToggle (_a, _b, _c) {
    setZonesEnabled(!zonesEnabled)
  }

  function selectionAnnotation () {
    return selection.map(s => ({
      type: 'line',
      mode: 'vertical',
      scaleID: 'x-axis-0',
      value: s,
      borderWidth: 3,
      borderDash: [4, 5]
    }))
  }

  function selectionMuteAnnotations () {
    if (!selection?.length) return []

    const limits = [
      { to: selection[0] },
      { from: selection[1] }
    ]

    return limits.map(lim => ({
      drawTime: 'beforeDatasetsDraw',
      type: 'box',
      xScaleID: 'x-axis-0',
      xMin: lim.from ?? undefined,
      xMax: lim.to ?? undefined,
      backgroundColor: blackTransparent
    }))
  }

  function zoneAnnotations () {
    if (!zones || !zonesEnabled) return []

    const colors = zoneColors || interpolateColors(toColor, fromColor, zones.length)
    return zipZoneLimits(zones).map((lims, idx) => ({
      drawTime: 'beforeDatasetsDraw',
      type: 'box',
      yScaleID: PRIMARY_Y_AXIS,
      yMin: lims[0],
      yMax: lims[1],
      backgroundColor: hexToRgbaString(colors[idx], zoneAlpha),
      borderWidth: 0,
      borderColor: hexToRgbaString(colors[idx], 0.05) // Even with borderWith 0, the borders show/there is some overlap.
    })) // and they are about 3 times as bright as background colors
  }

  function handleKernelSizeChange (e) {
    setKernelSize(e.target.value)
  }

  return (
    <>
      <div className='row'>
        <div className='col s12' style={{ position: 'relative' }}>
          <Line height={height} data={data} options={options} datasetKeyProvider={() => Math.random()} />
        </div>
      </div>
      <div className='row no-margin-bottom chartjs-padding-left'>
        {smooth && (
          <div className={classNames('col s4 m6 l4 xl3', zones && 'no-margin-bottom-inside')}>
            <SmoothSlider kernelSize={kernelSize} onKernelSizeChange={handleKernelSizeChange} />
          </div>
        )}
        {zones && (
          <div className={classNames('col s9 m7 l4 xl3', smooth && 'bring-row-down', !smooth && 'extra-bottom-margin')}>
            <Toggle
              currentValue={zonesEnabled} onChange={handleToggle}
              label={I18n.t('components.dashboards.checkbox.zone_toggle')}
            />
          </div>
        )}
      </div>
    </>
  )
}

TimeSeriesChart.propTypes = {
  // Data for the x axis
  x: PropTypes.array,
  // Data for the y axis
  ys: PropTypes.arrayOf(PropTypes.array),
  // Chartjs config for the dataset
  datasetConfigs: PropTypes.arrayOf(PropTypes.object),
  // Height in pixels of the graph
  height: PropTypes.number,
  // Callback to customize x ticks
  xTickCallback: PropTypes.func,
  // Callback to customize y ticks
  yTickCallback: PropTypes.func,
  // Calback to customize elevation ticks
  elTickCallback: PropTypes.func,
  // Callback to execute when user click and drags an area of the chart
  onSelect: PropTypes.func,
  // Object of callbacks according to chartjs options.tooltips.callbacks
  tooltipCallbacks: PropTypes.object,
  // [start, end] values in the x axis of the selected range
  selection: PropTypes.array,
  // zone limits
  zones: PropTypes.array,
  // label for the x-axis
  xLabel: PropTypes.string,
  // labels for the y-axis
  yLabels: PropTypes.arrayOf(PropTypes.string),
  // Whether to display M.A. controls or not
  smooth: PropTypes.bool,
  // suggested minimum value for the left y-axis, defaults to 0
  suggestedMin: PropTypes.number,
  // how much to offset the minimum value for elevation. Defaults to -15.
  elMinOffset: PropTypes.number,
  // The minimum range to use for the elivation axis. Defaults to 200.
  elMinrange: PropTypes.number,
  // If set to false, shows tooltips for all synced graphs. Defaults to false.
  suppressTooltips: PropTypes.bool,
  // The range in the data to plot. Defaults to [undefined, undefined].
  range: PropTypes.array,
  // Max number of samples to plot. Defaults to undefined.
  maxSamples: PropTypes.number,
  // Colors of the scales,
  scaleColors: PropTypes.arrayOf(PropTypes.string)
}

export default TimeSeriesChart
