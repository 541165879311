import React, { useState } from 'react'
import I18n from 'i18n'
import { debounce } from 'lodash'
import CsvTimeseries from './CsvTimeseries'
import CsvScatterPlot from './CsvScatterPlot'
import CsvNumericHistogram from './CsvNumericHistogram'
import CsvCategoricalHistogram from './CsvCategoricalHistogram'
import CsvBoxPlot from './CsvBoxPlot'
import { numericalIndex, numericalNone, extraVariableTypes } from './CsvVariableSelector'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MyReactTooltip from '../../../MyReactTooltip'
import { NUMERIC_TYPE, CATEGORICAL_TYPE, graphTypes } from '../../../common/Constants'

/**
 * Number of milliseconds to wait before re-rendering the graphs
 */
const DEBOUNCE_TIMEOUT = 200

/**
 * Height (in px) for the histogram
 */
const BAR_HEIGHT = 500

/**
 * Map two given column descriptions to the appropriate type of graph
 */
const getGraphType = (t1, t2) => {
  if (t1 === extraVariableTypes.TIME) {
    return graphTypes.TIMESERIES
  } else if (t1 === extraVariableTypes.HIST && t2 === NUMERIC_TYPE) {
    return graphTypes.NUMERIC_HIST
  } else if (t1 === extraVariableTypes.HIST && t2 === CATEGORICAL_TYPE) {
    return graphTypes.CATEGORICAL_HIST
  } else if (t1 === NUMERIC_TYPE && t2 === NUMERIC_TYPE) {
    return graphTypes.SCATTER
  } else if (t1 === NUMERIC_TYPE && t2 === CATEGORICAL_TYPE) {
    return graphTypes.BOX
  }
}

const getColumnIdx = (description, colName) => {
  return (description || []).map(column => column.name).indexOf(colName)
}

/**
 * Given the full dataset and a description of the X and Y variables, extract X and Y from the dataset and visualize them
 * using the appropriate graph type
 */
const CsvGraph = ({ summaryBuilder, data, description, x, y, onUpdateGraph, graphIdx }) => {
  /**
   * Store the number of bins (if not already categorical) and the number of samples in state
   */
  const [nbins, setBins] = useState(30)
  const [nsamples, setSamples] = useState(1000)

  /**
   * Determine the type of graph to display
   */
  const type = getGraphType(x.type, y.type)

  /**
   * Filter the descriptions to find the index of the column to use as Y variable and X variable (if applicable)
   */
  const yColIdx = getColumnIdx(description, y.name)
  const xColIdx = getColumnIdx(description, x.name)

  /**
   * Extract the column for the X axis (if applicable)
   */
  let xs = []
  if (x.type === NUMERIC_TYPE) {
    xs = data.map((row) => row[x.name])
  } else {
    xs = data.map((_, idx) => idx)
  }
  /**
   * Extract the column for the Y axis
   */
  const ys = data.map((row) => row[y.name])

  /**
   * Get the descriptions for the Y and X variables (if applicable)
   */
  const descriptionY = (yColIdx || yColIdx === 0) && description[yColIdx]
  const descriptionX = (xColIdx || xColIdx === 0) && description[xColIdx]

  /**
   * Render the appropriate graph for the determined type
   */
  const getGraph = (type, title) => {
    switch (type) {
      case graphTypes.TIMESERIES: return (<CsvTimeseries summaryBuilder={summaryBuilder} title={title} description={descriptionY} nsamples={Math.min(nsamples, ys.length)} onSetSamples={debounce(setSamples, DEBOUNCE_TIMEOUT, { leading: true, trailing: false })} x={x} y={y} xs={xs} ys={ys} />)
      case graphTypes.NUMERIC_HIST: return (<CsvNumericHistogram summaryBuilder={summaryBuilder} title={title} description={descriptionY} data={ys} nbins={nbins} onSetBins={debounce(setBins, DEBOUNCE_TIMEOUT, { leading: true, trailing: false })} />)
      case graphTypes.CATEGORICAL_HIST: return (<CsvCategoricalHistogram summaryBuilder={summaryBuilder} title={title} description={descriptionY} data={ys} />)
      case graphTypes.SCATTER: return (<CsvScatterPlot summaryBuilder={summaryBuilder} title={title} descriptionY={descriptionY} descriptionX={descriptionX} nsamples={Math.min(nsamples, ys.length)} onSetSamples={debounce(setSamples, DEBOUNCE_TIMEOUT, { leading: true, trailing: false })} height={BAR_HEIGHT} xs={xs} ys={ys} />)
      case graphTypes.BOX: return <CsvBoxPlot summaryBuilder={summaryBuilder} title={title} descriptionY={descriptionY} descriptionX={descriptionX} xs={xs} ys={ys} />
      default: return <>An error occured when rendering graph of type {type}</>
    }
  }

  /**
   * Leave some extra space for toggle buttons in the case of (numeric) histograms and timeseries
   */
  const colWidth = ((x.type === 'hist' || x.type === 'time') && y.type === NUMERIC_TYPE && 's10') || 's12'
  const title = I18n.t('components.visualization.csv.graph_title', { x: x.label, y: y.label, type: I18n.t(`components.visualization.csv.${type}`) })
  return (
    <>
      <MyReactTooltip id='csv' />
      <div className='row valign-wrapper'>
        <div className={`col ${colWidth}`}>
          <h2 className='text-l text-muted'>{title}</h2>
        </div>
        {colWidth === 's10' && (
          <div className='col s2 right-align'>
            <span onClick={() => onUpdateGraph(graphIdx, numericalIndex())} className={`margin-right ${(x.type !== 'time' && 'text-primary-color') || ''}`}><FontAwesomeIcon size='2x' icon='chart-line' /></span>
            <span onClick={() => onUpdateGraph(graphIdx, numericalNone())} className={`${(x.type !== 'hist' && 'text-primary-color') || ''}`}><FontAwesomeIcon size='2x' icon='chart-bar' /></span>
          </div>
        )}
      </div>
      <div className='row'>
        <div className='col s12'>
          {getGraph(type, title)}
        </div>
      </div>
    </>
  )
}

export default CsvGraph
