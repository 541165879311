
import Highcharts from 'highcharts'
import { useEffect } from 'react'

const useExpansionHelperHook = (expanded: boolean): void => {
  const documentQuery = '.chart-container, .my-highcharts-container, .chart-wrapper>.chart, .overtime-chart'
  const appContainerQuery = '.app > .wrapper > .container, .app > .wrapper > .container > .container'

  // Use Effect functions are in place to handle the expanding container outside of the ExpandCompressButton component.
  useEffect(() => {
    document.querySelectorAll(appContainerQuery).forEach((mainContainer: any) => {
      mainContainer.style.width = expanded ? '100%' : null
      mainContainer.style.maxWidth = expanded ? '100%' : null
    })
    document.querySelectorAll(documentQuery).forEach((myChartEl: any) => {
      // TODO: Fix the TS here properly
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!myChartEl.firstChild || !myChartEl.firstChild.getAttribute || !myChartEl.firstChild.getAttribute('data-highcharts-chart')) { return true }
      const myChart: any = Highcharts.charts[myChartEl.firstChild.getAttribute('data-highcharts-chart')]
      myChart.setSize(myChart.width, myChart.height)
    })
    return () => {
      document.querySelectorAll(appContainerQuery).forEach((mainContainer: any) => {
        mainContainer.style.width = null
        mainContainer.style.maxWidth = null
      })
    }
  }, [expanded])
}

export default useExpansionHelperHook
