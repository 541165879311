import React, { FC, ReactElement } from 'react'
import { Line, LinearComponentProps } from 'react-chartjs-2'

export interface LineGraphProps extends LinearComponentProps {
}

const LineGraph: FC<LineGraphProps> = (props): ReactElement => {
  return <Line {...props} />
}
LineGraph.defaultProps = {
}
export default LineGraph
