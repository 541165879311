import React, { useMemo } from 'react'
import { Radar } from 'react-chartjs-2'
import I18n from 'i18n'
import { PsychologicalCompetenciesData } from '../../../../common/types/questionnaires/PsychologicalCompetenciesData'

interface Props {
  data: PsychologicalCompetenciesData
}

const PsychologicalCompetenciesView: React.FC<Props> = (props) => {
  const data = useMemo(() => {
    return {
      labels: [
        I18n.t('questionnaires.psychological_competencies.growth_mindset.title'),
        I18n.t('questionnaires.psychological_competencies.reflection.title'),
        I18n.t('questionnaires.psychological_competencies.commitment.title'),
        I18n.t('questionnaires.psychological_competencies.resilience.title'),
        I18n.t('questionnaires.psychological_competencies.coachability.title'),
        I18n.t('questionnaires.psychological_competencies.focus.title'),
        I18n.t('questionnaires.psychological_competencies.perseverance.title'),
        I18n.t('questionnaires.psychological_competencies.confidence.title'),
        I18n.t('questionnaires.psychological_competencies.set_and_protect_boundaries.title')
      ],
      datasets: [
        {
          label: I18n.t('questionnaires.psychological_competencies.score'),
          data: [
            props.data.values.s3,
            props.data.values.s2,
            props.data.values.s4,
            props.data.values.s7,
            props.data.values.s1,
            props.data.values.s6,
            props.data.values.s8,
            props.data.values.s5,
            props.data.values.s9].map(score => parseInt(score) / 100.0),
          backgroundColor: '#66FF9955',
          borderColor: 'green'
        }
      ]
    }
  }, [props.data, I18n.locale])
  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <Radar data={data} options={RADAR_OPTIONS} />
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <ul className='browser-default'>
            <li>
              <p>
                <strong>{I18n.t('questionnaires.psychological_competencies.growth_mindset.title')}</strong><br />
                {I18n.t('questionnaires.psychological_competencies.growth_mindset.explanation')}
              </p>
            </li>
            <li>
              <p>
                <strong>{I18n.t('questionnaires.psychological_competencies.reflection.title')}</strong><br />
                {I18n.t('questionnaires.psychological_competencies.reflection.explanation')}
              </p>
            </li>
            <li>
              <p>
                <strong>{I18n.t('questionnaires.psychological_competencies.commitment.title')}</strong><br />
                {I18n.t('questionnaires.psychological_competencies.commitment.explanation')}
              </p>
            </li>
            <li>
              <p>
                <strong>{I18n.t('questionnaires.psychological_competencies.resilience.title')}</strong><br />
                {I18n.t('questionnaires.psychological_competencies.resilience.explanation')}
              </p>
            </li>
            <li>
              <p>
                <strong>{I18n.t('questionnaires.psychological_competencies.coachability.title')}</strong><br />
                {I18n.t('questionnaires.psychological_competencies.coachability.explanation')}
              </p>
            </li>
            <li>
              <p>
                <strong>{I18n.t('questionnaires.psychological_competencies.focus.title')}</strong><br />
                {I18n.t('questionnaires.psychological_competencies.focus.explanation')}
              </p>
            </li>
            <li>
              <p>
                <strong>{I18n.t('questionnaires.psychological_competencies.perseverance.title')}</strong><br />
                {I18n.t('questionnaires.psychological_competencies.perseverance.explanation')}
              </p>
            </li>
            <li>
              <p>
                <strong>{I18n.t('questionnaires.psychological_competencies.confidence.title')}</strong><br />
                {I18n.t('questionnaires.psychological_competencies.confidence.explanation')}
              </p>
            </li>
            <li>
              <p>
                <strong>{I18n.t('questionnaires.psychological_competencies.set_and_protect_boundaries.title')}</strong><br />
                {I18n.t('questionnaires.psychological_competencies.set_and_protect_boundaries.explanation')}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

const RADAR_OPTIONS = {
  legend: {
    display: false
  },
  scale: {
    ticks: {
      beginAtZero: true,
      min: 0,
      max: 1,
      stepSize: 0.1
    }
  }
}

export default PsychologicalCompetenciesView
