import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

const ActionGroup = ({ onDelete, onEdit, onCopy }) => (
  <>
    <FontAwesomeIcon className='text-primary-color pointer margin-right' icon='edit' onClick={onEdit} />
    <FontAwesomeIcon className='text-primary-color pointer margin-right' icon='copy' onClick={onCopy} />
    <FontAwesomeIcon className='text-accent-color pointer' icon='trash' onClick={onDelete} />
  </>
)

ActionGroup.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired
}

export default ActionGroup
