import React, { FC } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { hcGreen, hcBlack } from 'components/common/Constants'
import I18n from 'i18n'
import { initializeHighcharts, titleWithTooltip } from 'components/common/Utils'
import GenericPlaceholder from 'components/common/GenericPlaceholder'
import _ from 'lodash'
import {
  mapToSleepDurationData,
  mapToWellbeingScoreData,
  mapToWellnessMovingAvgData
} from 'components/analysis/dashboards/questionnaire/UtilsTS'
import { WeeklyWellbeingRow } from 'components/common/types/questionnaires/WeeklyWellbeingRow'
import { getYAxisOptions } from 'components/analysis/dashboards/questionnaire/weekly/WellbeingChartOptions'

interface WellnessProps {
  weeklyWellbeingRows?: WeeklyWellbeingRow[]
  withRestingHr?: boolean
  withSleepDuration?: boolean
}

const WeeklyWellnessBar: FC<WellnessProps> = ({ weeklyWellbeingRows, withSleepDuration }) => {
  initializeHighcharts(true)

  if (_.isEmpty(weeklyWellbeingRows) || !Array.isArray(weeklyWellbeingRows)) {
    return (
      <GenericPlaceholder title={I18n.t('components.dashboards.questionnaire.weekly_wellness.title')} />
    )
  }

  // Remove duplicates
  const dataNoDuplicates = _.uniqBy(weeklyWellbeingRows, 'formatted_date')

  // map to graph data without duplicates
  const wellbeingScoreData = mapToWellbeingScoreData(dataNoDuplicates)
  const wellnessMovingAvgData = mapToWellnessMovingAvgData(dataNoDuplicates)
  const sleepDurationData = mapToSleepDurationData(dataNoDuplicates)

  const {
    sleepDurationOptionsIndex,
    yAxisOptions
  } = getYAxisOptions(false, withSleepDuration)

  const xAxisOptions: Highcharts.XAxisOptions = {
    title: {
      text: ''
    },
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%d %b'
    },
    // add padding to prevent overlap of bars on edges
    minPadding: 0.05, // styling and alignment @DateRangeQuestionnaireDashboard
    maxPadding: 0.05 // styling and alignment @DateRangeQuestionnaireDashboard
  }

  const wellbeingSeries = [
    {
      type: 'line',
      data: wellbeingScoreData,
      name: '',
      tooltip: {
        headerFormat: '',
        pointFormat: I18n.t('components.dashboards.questionnaire.weekly_wellness.column.tooltip')
      },
      color: 'black'
    },
    {
      type: 'scatter',
      data: wellbeingScoreData,
      name: '',
      tooltip: {
        headerFormat: '',
        pointFormat: I18n.t('components.dashboards.questionnaire.weekly_wellness.column.tooltip')
      },
      marker: {
        symbol: 'circle'
      }
    }
  ]

  const wellnessMovingAvgSeries = {
    type: 'line',
    data: wellnessMovingAvgData,
    name: 'Weekly wellness', // is not displayed to user
    tooltip: {
      headerFormat: '',
      pointFormat: I18n.t('components.dashboards.questionnaire.weekly_wellness.line.tooltip')
    }
  }

  const sleepDurationSeries = {
    type: 'column',
    data: sleepDurationData,
    name: 'SleepDuration',
    tooltip: {
      headerFormat: '',
      pointFormat: I18n.t('components.dashboards.questionnaire.daily_wellness.sleep_duration.tooltip')
    },
    color: hcGreen,
    yAxis: sleepDurationOptionsIndex,
    marker: {
      enabled: false,
      states: {
        hover: {
          enabled: true
        }
      }
    }
  }

  const datasets = [wellnessMovingAvgSeries, sleepDurationSeries, ...wellbeingSeries]

  const chartOptions = {
    title: titleWithTooltip(
      I18n.t('components.dashboards.questionnaire.weekly_wellness.title'),
      I18n.t('components.dashboards.questionnaire.weekly_wellness.info')
    ),
    subtitle: {
      text: I18n.t('components.dashboards.questionnaire.weekly_wellness.subtitle')
    },
    series: datasets,
    plotOptions: {
      column: { // styling and alignment @DateRangeQuestionnaireDashboard
        pointPadding: 0,
        groupPadding: 0
      },
      series: {
        stacking: 'normal',
        maxPointWidth: 21 // styling and alignment @DateRangeQuestionnaireDashboard
      },
      scatter: {
        dataLabels: {
          enabled: false
        },
        color: 'black',
        stacking: 'normal',
        enableMouseTracking: true
      },
      line: {
        color: hcBlack,
        marker: {
          enabled: false
        }
      }
    },
    yAxis: yAxisOptions,
    xAxis: xAxisOptions
  }

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
    </>
  )
}

export default WeeklyWellnessBar
