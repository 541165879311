import React, { useState, useEffect } from 'react'
import I18n from 'i18n'
import Spinner from '../../common/Spinner'
import { defaultProfilePicture, regularGroups, groupTypes } from '../../common/Constants'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import { MyAxios as axios } from '../../MyAxios'
import { WithSession } from '../../session/SessionProvider'
import { errorToString } from '../../common/ErrorHandling'
import { filterActiveConnections } from '../../common/Utils'

const SnapshotSharingRules = (props) => {
  const knownIds = []
  return props.rules.map(rule => {
    const otherProfile = rule.other_profile
    if (knownIds.includes(otherProfile.id)) return <React.Fragment key={rule.id} />
    knownIds.push(otherProfile.id)
    return (
      <div className='row' key={rule.id}>
        <div className='col s12'>
          <div className='snapshot-sharing-rule valign-wrapper'>
            <img className='circle responsive-img' src={otherProfile.picture || defaultProfilePicture} />
            <span>
              {otherProfile.first_name} {otherProfile.last_name}
            </span>
            {/* Don't allow for destroying object rights: snapshot sharing is permanent! */}
            {/* <FontAwesomeIcon className='pointer' icon='trash' onClick={() => props.handleClick(rule)} /> */}
          </div>
        </div>
      </div>
    )
  })
}

const GroupSelect = (props) => {
  const { publicGroup, availableGroups, availableConnections } = props
  return (
    <div className='row'>
      <div id='group-selector' className='col s12 input-field z-top'>
        <select id='who'>
          {publicGroup && (
            <optgroup label={I18n.t('datasharing.labels.followers')}>
              <option
                key={publicGroup.id}
                value={publicGroup.id}
              >
                {I18n.t('datasharing.labels.followers')}
              </option>
            </optgroup>
          )}
          {availableGroups && (
            <optgroup label={I18n.t('datasharing.labels.groups')}>
              {availableGroups.map(
                (group) => (
                  <option
                    key={group.id}
                    value={group.id}
                    data-icon={group.picture}
                  >
                    {group.name}
                  </option>
                ))}
            </optgroup>
          )}
          {availableConnections && (
            <optgroup label={I18n.t('datasharing.labels.connections')}>
              {availableConnections.map(
                (connection) => (
                  <option
                    key={connection.id}
                    value={connection.id}
                    data-icon={connection.picture}
                  >
                    {connection.name}
                  </option>
                ))}
            </optgroup>
          )}
        </select>
        <label>{I18n.t('datasharing.labels.shared_with')}</label>
      </div>
    </div>
  )
}

const SnapshotSharingView = (props) => {
  const [createRule, setCreateRule] = useState(false)
  const [rules, setRules] = useState([])
  const [groups, setGroups] = useState([])
  const [availableGroups, setAvailableGroups] = useState([])
  const [availableConnections, setAvailableConnections] = useState([])
  const [publicGroup, setPublicGroup] = useState([])
  const [rulesRetrieved, setRulesRetrieved] = useState(false)

  // DidMount
  useEffect(() => {
    fetchRules()
    props.groups.then(groups => {
      setGroups(groups)
      setAvailableGroups(groups.filter((group) => regularGroups.includes(group.group_type)))
      setAvailableConnections(filterActiveConnections(groups))
      setPublicGroup(groups.filter((group) => group.group_type === groupTypes.PUBLIC)[0])
    })
  }, [])

  // Init materialize select every time we toggle the create rule button
  useEffect(() => {
    M.FormSelect.init(document.querySelectorAll('select'))
  }, [createRule])

  useEffect(() => {
    // Updated rules
  }, [rules])

  const fetchRules = () => {
    setRulesRetrieved(false)
    axios({
      method: 'GET',
      url: `/api/v1/snapshots/${props.snapshot.id}/object_rights`,
      headers: {
        Authorization: props.sessionToken
      }
    }).then(res => {
      setRules(res.data)
      setRulesRetrieved(true)
    }).catch(e => {
      console.log(e)
      setRulesRetrieved(true)
    })
  }

  const fetchRulesAndSnapshots = () => {
    fetchRules()
    props.fetchSnapshots()
  }

  const handleCreateRule = () => {
    const groupId = document.getElementById('who').value
    setRulesRetrieved(false)
    axios({
      method: 'POST',
      url: `/api/v1/snapshots/${props.snapshot.id}/object_rights`,
      data: {
        object_right: { access_level: 'download', group_id: groupId }
      },
      headers: {
        Authorization: props.sessionToken
      }
    }).then(fetchRulesAndSnapshots)
      .catch(e => {
        props.flashMessages.push(errorToString(e), props.flashMessages.duration.LONG, props.flashMessages.levels.ERROR)
        setRulesRetrieved(true)
      })
    setCreateRule(false)
  }

  const handleDeleteRule = (rule) => {
    setRulesRetrieved(false)
    axios({
      method: 'DELETE',
      url: `/api/v1/object_rights/${rule.id}`,
      headers: {
        Authorization: props.sessionToken
      }
    }).then(fetchRulesAndSnapshots)
      .catch(e => {
        console.log(e)
        setRulesRetrieved(true)
      })
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='col s12 text-heavy text-primary-color text-xl'>
          {`${I18n.t('analysis.snapshot.sharing.title')} ${props.snapshot.name}`}
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <p>{I18n.t('analysis.snapshot.sharing.explanation')}</p>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='text-heavy text-l text-muted'>
            {I18n.t('analysis.snapshot.sharing.shared_with')}
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <Spinner ready={rulesRetrieved} />
        </div>
      </div>
      <SnapshotSharingRules groups={groups} rules={rules} handleClick={handleDeleteRule} />
      {
        createRule &&
          <GroupSelect availableGroups={availableGroups} availableConnections={availableConnections} publicGroup={publicGroup} />
      }
      <div className='row'>
        <div className='col s12 right-align'>
          <button
            className='waves-effect waves-light text-primary-color text-heavy background-primary button-primary button-primary text-background-color button-autowidth'
            onClick={() => createRule ? handleCreateRule() : setCreateRule(true)}
            style={{ marginRight: 0 }}
          >
            {!createRule && I18n.t('datasharing.create_new_rule')}
            {createRule && I18n.t('datasharing.save_rule')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default WithSession(WithFlashMessages(SnapshotSharingView))
