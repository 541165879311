import React from 'react'

const Card: React.FC = ({ children }) => {
  return (
    <div className='card-panel'>
      {children}
    </div>
  )
}

export default Card
