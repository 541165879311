// From https://josephkhan.me/javascript-copy-clipboard-safari/

function isIOS () {
  // can use a better detection logic here
  return navigator.userAgent.match(/ipad|iphone/i)
}

function createTextArea (text, theDocument) {
  const textArea = theDocument.createElement('textArea')
  textArea.readOnly = true
  textArea.contentEditable = true
  textArea.value = text
  theDocument.body.appendChild(textArea)
  return textArea
}

function selectText (textArea, theDocument) {
  let range, selection

  if (isIOS()) {
    range = theDocument.createRange()
    range.selectNodeContents(textArea)
    selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)
    textArea.setSelectionRange(0, 999999)
  } else {
    textArea.select()
  }
}

function copyTo (textArea, theDocument) {
  theDocument.execCommand('copy')
  theDocument.body.removeChild(textArea)
}

// The document is injected here to test the functionality
export function copy (text, theDocument = document) {
  const textArea = createTextArea(text, theDocument)
  selectText(textArea, theDocument)
  copyTo(textArea, theDocument)
}
