import React from 'react'
import I18n from 'i18n'
import Spinner from '../../../../common/Spinner'
import { subscriptionTypes, dailyQuestionnaires } from 'components/common/Constants'
import AlertSearcher from '../alerts/AlertSearcher'
import { pad, smartTranslate } from 'components/common/Utils'
import {
  shortSessionName,
  makeHistogram,
  preprocessTrainingLogs, preprocessWellbeingQuestionnaires
} from '../Utils'
import PremiumOverlay from '../../../../premium_membership/PremiumOverlay'
import { isDailyQuestionnaire, isTrainingLog } from 'components/common/utils/QuestionnaireUtils'
import InfoRequiredData, { DASHBOARD_REQUIRED_DATA_TYPES } from '../../common/RequiredDataInfo'
import TrainingQuestionnaireDashboard from './TrainingQuestionnaireDashboard'
import WellbeingQuestionnaireDashboard from './WellbeingQuestionnaireDashboard'
import WithQuestionnaireData from '../common/WithQuestionnaireData'
import WeekSlider from 'components/analysis/dashboards/questionnaire/common/WeekSlider'
import DateRangePicker from 'components/analysis/dashboards/questionnaire/common/DateRangePicker'
import moment from 'moment/moment'

class TrainingOrWellbeingQuestionnaireDashboardOptimized extends React.Component {
  constructor (props) {
    super(props)
    const { match: { params: { id } } } = this.props
    this.id = id

    // Note: This is only temporary here. In the future, all of this will be
    // moved to the roasters and the profile of the user. See
    // https://freedcamp.com/Researchable_gZB/SDVMVP_0Up/todos/32478431/
    this.alertsToCheckFor = {
      sleep_duration_num: {
        name: I18n.t('components.dashboards.questionnaire.warnings.poor_sleep_name'),
        description: I18n.t('components.dashboards.questionnaire.warnings.poor_sleep'),
        key: 'sleep_duration_num',
        comparison: '<',
        threshold: 3,
        defaultThreshold: 3
      },
      wellness_sleep: {
        name: I18n.t('components.dashboards.questionnaire.warnings.sleepquality_name'),
        description: I18n.t('components.dashboards.questionnaire.warnings.sleepquality'),
        key: 'wellness_sleep',
        comparison: '<=',
        threshold: 1.5,
        defaultThreshold: 1.5
      },
      readiness_to_train: {
        name: I18n.t('components.dashboards.questionnaire.warnings.readiness_to_train_name'),
        description: I18n.t('components.dashboards.questionnaire.warnings.readiness_to_train'),
        key: 'readiness_to_train',
        comparison: '<=',
        threshold: 2,
        defaultThreshold: 2
      }
    }

    this.state = {
      preprocessingDone: true,
      dailyPreprocessingDone: true,
      selectedTrainingTypes: [],
      selectedSessionTypes: [],
      questionnaireData: [],
      isLoading: true,
      isError: false
    }

    this.mySort = (a, b) => {
      const aid = a.id.toLowerCase()
      const bid = b.id.toLowerCase()
      if (aid === bid) return 0
      if (aid > bid) return 1
      return -1
    }
  }

  componentDidMount () {
    M.FormSelect.init(document.querySelectorAll('select'))

    const questionnaireTypes = this.props.training ? ['training_log'] : dailyQuestionnaires

    const profileIsSelected = this.props.selectedProfileId && this.props.selectedProfileId !== ''

    this.props.setParams({
      // TODO (next iteration): make this user selectable with some sensible defaults
      start_date: this.props.minDate,
      end_date: this.props.maxDate,
      type: questionnaireTypes,
      profile_id: profileIsSelected ? this.props.selectedProfileId : undefined
    })

    if (profileIsSelected) {
      this.props.setQuestionnairesOptions({ refetchOnWindowFocus: false, enabled: true })
    }

    this.props.setProfilesOptions({ refetchOnWindowFocus: false, enabled: true })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const profileIsSelected = this.props.selectedProfileId && this.props.selectedProfileId !== ''

    if (prevProps.profiles !== this.props.profiles) this.initProfileSelect()
    if (prevProps.profiles !== this.props.profiles && this.props.profiles.length > 0 && profileIsSelected) {
      const selectedProfile = this.props.profiles.find(p => p.id.toString() === this.props.selectedProfileId.toString())
      // If the selected profile doesn't exist for this set of questionnaires, select the first profile that has data
      if (!selectedProfile) {
        this.selectProfile(parseInt(this.props.profiles[0].id))
      }
    }

    if (prevProps.minDate !== this.props.minDate || prevProps.maxDate !== this.props.maxDate) {
      this.props.setTimeParams(this.props.minDate, this.props.maxDate)
    }

    if (prevProps.sliderStartDate !== this.props.sliderStartDate || prevProps.sliderEndDate !== this.props.sliderEndDate) {
      this.processQuestionnaireData(this.props.questionnaireData)
    }

    if (prevState.trainingTypes !== this.state.trainingTypes) this.initTrainingTypeSelect()
    if (prevState.sessionTypes !== this.state.sessionTypes) this.initSessionTypeSelect()
    if (prevProps.selectedProfileId !== this.props.selectedProfileId || (!prevProps.profiles && prevProps.profiles !== this.props.profiles && this.props.selectedProfileId)) {
      this.props.setProfileIdParam(this.props.selectedProfileId)
      // Only make a questionnaire request if we have a profile selected
      if (this.props.selectedProfileId) {
        this.props.setQuestionnairesOptions({ refetchOnWindowFocus: false, enabled: true })
      }
    }

    if (!this.props.selectedProfileId && this.props.profiles?.length > 0) {
      if (this.id) {
        if (this.props.profiles.map(profile => profile.id).includes(parseInt(this.id))) {
          this.selectProfile(parseInt(this.id))
        } else if (!this.state.unknownId) {
          this.setState({ unknownId: true })
        }
      } else {
        this.selectProfile(this.props.profiles[0].id)
      }
    }

    if (prevState.preprocessingDone !== this.state.preprocessingDone && this.state.preprocessingDone) {
      const elems = document.querySelectorAll('.tabs')
      M.Tabs.init(elems)
    }

    if (this.props.questionnaireData !== prevProps.questionnaireData) {
      M.FormSelect.init(document.querySelectorAll('select')) // refresh select controls, solves issue with the 'Select Athlete' dropdown remaining disabled even when there is data
      if (this.props.questionnaireData.length === 0) {
        // If there is no data, set isLoading to false, clear training and session types because no data
        this.setState({ isLoading: false, trainingTypes: [], sessionTypes: [] })
      } else { // there is data
        this.processQuestionnaireData(this.props.questionnaireData)
      }
    }

    if (this.state.questionnaireData !== prevState.questionnaireData) {
      this.setState({ preprocessingDone: false }, () => {
        const elem = M.FormSelect.getInstance(document.getElementById('questionnaire-dashboard-select-profile'))
        if (elem) {
          elem._setValueToInput()
          elem._setSelectedStates()
        }
        this.setTrainingAndSessionTypes()
      })
    }
  }

  // This dashboard was designed for /data/find which returns a weird serialized version of a vdo that has the data_rows
  // at the root of the object instead of under the structured_data_objects hierarchy. This function converts the
  // response from /questionnaire_responses (which is a properly serialized vdo) into the format the dashboard expects
  processQuestionnaireData (data) {
    const processedData = []
    data.forEach(({ structured_data_objects: sdos, ...vdo }) => {
      processedData.push({
        ...vdo,
        data_rows: [
          sdos[0].data_rows[0]
        ]
      })
    })

    this.setState({ questionnaireData: processedData })
  }

  initTrainingTypeSelect () {
    M.FormSelect.init(document.getElementById('questionnaire-dashboard-select-training-type'),
      {
        dropdownOptions: {
          container: document.getElementById('select-training-type-buttons'),
          onOpenStart: () => { document.getElementById('select-training-type-buttons').style.display = 'block' },
          onCloseEnd: () => { document.getElementById('select-training-type-buttons').style.display = 'none' }
        }
      }
    )
  }

  initSessionTypeSelect () {
    M.FormSelect.init(document.getElementById('questionnaire-dashboard-select-session-type'),
      {
        dropdownOptions: {
          container: document.getElementById('select-session-type-buttons'),
          onOpenStart: () => { document.getElementById('select-session-type-buttons').style.display = 'block' },
          onCloseEnd: () => { document.getElementById('select-session-type-buttons').style.display = 'none' }
        }
      }
    )
  }

  initProfileSelect () {
    M.FormSelect.init(document.getElementById('questionnaire-dashboard-select-profile'),
      {
        dropdownOptions: {
          coverTrigger: false
        }
      }
    )
  }

  selectProfile (selected) {
    this.props.setSelectedProfileId(selected)
    this.props.setLastSelectedAthleteId(selected)
  }

  selectTrainingTypes (selected, updateSelect = false) {
    this.setState({ selectedTrainingTypes: selected }, () => {
      if (updateSelect) M.FormSelect.getInstance(document.getElementById('questionnaire-dashboard-select-training-type'))._handleInputClick()
      this.filterAndProcessQuestionnaires()
    })
  }

  selectSessionTypes (selected, updateSelect = false) {
    this.setState({ selectedSessionTypes: selected }, () => {
      if (updateSelect) M.FormSelect.getInstance(document.getElementById('questionnaire-dashboard-select-session-type'))._handleInputClick()
      this.filterAndProcessQuestionnaires()
    })
  }

  // This function filters and processes the state questionnaireData
  // based on: the slider start/end values, owner_id, isTrainingLog, and session/training types.
  filterAndProcessQuestionnaires () {
    const startDate = this.props.sliderStartDate
    const endDate = this.props.sliderEndDate
    const startMoment = startDate !== null ? moment(startDate) : null
    const endMoment = endDate !== null ? moment(endDate) : null

    this.processQuestionnaires(this.state.questionnaireData.filter(questionnaire => {
      // Extract training_start_date variable so we can filter the training_log questionnaires based on slider start/end values
      const entry = questionnaire?.data_rows?.[0]

      if (isTrainingLog(questionnaire)) {
        const trainingStart = `${entry.values.v3} ${pad(entry.values.v4_uur || entry.values.v4_uren, 2)}:${pad(entry.values.v4_minuten, 2)}:00`.replace(/-/g, '/')
        const trainingStartDate = new Date(trainingStart)

        return questionnaire.owner.id === this.props.selectedProfileId &&
          ((startDate === null || moment(trainingStartDate) >= startMoment) &&
            (endDate === null || moment(trainingStartDate) <= endMoment)) &&
          this.state.selectedTrainingTypes.includes(smartTranslate(questionnaire.data_rows[0].values.v1)) &&
          this.state.selectedSessionTypes.includes(smartTranslate(questionnaire.data_rows[0].values.v2))
      }
      return false
    }))
  }

  filterDailyQuestionnaires (onlySelected = true) {
    const startDate = this.props.sliderStartDate
    const endDate = this.props.sliderEndDate
    const startMoment = startDate !== null ? moment(startDate) : null
    const endMoment = endDate !== null ? moment(endDate) : null

    return this.state.questionnaireData.filter(questionnaire => {
      if (isDailyQuestionnaire(questionnaire)) {
        // Extract open_from variable so we can filter the dailyLog questionnaires based on slider start/end values
        const entry = questionnaire?.data_rows?.[0]
        const dailyLogOpenFrom = entry?.open_from
        const dailyLogOpenFromDate = new Date(dailyLogOpenFrom)

        return (questionnaire.owner.id === this.props.selectedProfileId || !onlySelected) &&
          ((startDate === null || moment(dailyLogOpenFromDate) >= startMoment) &&
            (endDate === null || moment(dailyLogOpenFromDate) <= endMoment))
      }
      return false
    })
  }

  filterAndProcessDailyQuestionnaires () {
    this.processDailyQuestionnaires(this.filterDailyQuestionnaires())
  }

  setTrainingAndSessionTypes () {
    const ownerTrainingLogQuestionnaires = this.state.questionnaireData.filter(questionnaire => {
      return questionnaire.owner.id === this.props.selectedProfileId &&
        questionnaire.data_rows[0].questionnaire.key === 'training_log' &&
        questionnaire.data_rows[0].values.v1 && questionnaire.data_rows[0].values.v1.length > 0 &&
        questionnaire.data_rows[0].values.v2 && questionnaire.data_rows[0].values.v2.length > 0
    })

    const trainingTypeSet = new Set(ownerTrainingLogQuestionnaires.map(questionnaire => JSON.stringify({ id: smartTranslate(questionnaire.data_rows[0].values.v1) })))
    const trainingTypes = [...trainingTypeSet].map(str => JSON.parse(str)).sort(this.mySort)

    const sessionTypeSet = new Set(ownerTrainingLogQuestionnaires.map(questionnaire => JSON.stringify({ id: smartTranslate(questionnaire.data_rows[0].values.v2) })))
    const sessionTypes = [...sessionTypeSet].map(str => JSON.parse(str)).sort(this.mySort)

    this.setState({
      trainingTypes,
      selectedTrainingTypes: trainingTypes.map(trainingType => trainingType.id),
      sessionTypes,
      selectedSessionTypes: sessionTypes.map(sessionType => sessionType.id)
    }, () => this.filterAndProcessQuestionnaires())

    this.setState({ allDailyQuestionnaires: this.filterDailyQuestionnaires(false) })
    this.filterAndProcessDailyQuestionnaires()
  }

  processQuestionnaires (data) {
    if (data.length === 0) {
      this.setState({
        trainingLogRows: undefined,
        weeklySummary: undefined,
        sessionTypeHistogram: undefined,
        trainingTypeHistogram: undefined,
        trainingDaypartHistogram: undefined,
        trainingRPEHistogram: undefined,
        preprocessingDone: true
      })
      return
    }

    const preprocessedTrainingLogs = preprocessTrainingLogs(data)

    // Make histograms
    const sessionTypeHistogram = makeHistogram(preprocessedTrainingLogs.trainingLogRows, 'training_session', shortSessionName)
    const trainingTypeHistogram = makeHistogram(preprocessedTrainingLogs.trainingLogRows, 'training_type')
    const trainingDaypartHistogram = makeHistogram(preprocessedTrainingLogs.trainingLogRows, 'training_daypart')
    const trainingRPEHistogram = makeHistogram(preprocessedTrainingLogs.trainingLogRows, 'rpe', undefined, true)

    this.setState({
      trainingLogRows: preprocessedTrainingLogs.trainingLogRows,
      weeklySummary: preprocessedTrainingLogs.weeklySummary,
      sessionTypeHistogram,
      trainingTypeHistogram,
      trainingDaypartHistogram,
      trainingRPEHistogram,
      preprocessingDone: true
    })
  }

  processDailyQuestionnaires (data) {
    if (data.length === 0) {
      this.setState({
        dailyLogRows: undefined,
        sundaySummary: undefined,
        dailyPreprocessingDone: true
      })
      return
    }

    const preprocessedDailyQuestionnaires = preprocessWellbeingQuestionnaires(data)

    this.setState({
      dailyLogRows: preprocessedDailyQuestionnaires.dailyLogRows,
      sundaySummary: preprocessedDailyQuestionnaires.sundaySummary,
      dailyPreprocessingDone: true
    })
  }

  handleSelectProfile (_e) {
    const elem = document.getElementById('questionnaire-dashboard-select-profile')
    const selected = parseInt(elem.options[elem.selectedIndex].value)
    this.selectProfile(selected)
  }

  handleSelectTrainingType (e) {
    this.selectTrainingTypes(e.getSelectedValues())
  }

  handleSelectSessionType (e) {
    this.selectSessionTypes(e.getSelectedValues())
  }

  render () {
    const hasNoData = this.props.questionnaireData.length === 0 // if true, disables dropdowns

    if (this.state.unknownId) {
      return (
        <div>
          <div className='row'>
            <div className='col s12'>
              <h5>{I18n.t('components.dashboards.questionnaire.no_questionnaire_data')}</h5>
            </div>
          </div>
        </div>
      )
    }
    return (
      <PremiumOverlay activeFor={[subscriptionTypes.coach, subscriptionTypes.researcher]} showBadge={false}>
        <div>
          <div className='row'>
            <div className='col s12 text-xl text-primary-color text-heavy'>
              {I18n.t('components.dashboards.questionnaire.title')}
              <InfoRequiredData
                dataTypes={DASHBOARD_REQUIRED_DATA_TYPES.full_questionnaire}
                id='dashboard-data-tooltip'
              />
            </div>
          </div>
          <div className='row'>
            <DateRangePicker {...this.props} />
            <div className='input-field col s12 m4'>
              <select id='questionnaire-dashboard-select-profile' onChange={this.handleSelectProfile.bind(this)} value={this.props.selectedProfileId} disabled={hasNoData}>
                {this.props.profiles && this.props.profiles.map(f => (
                  <option
                    key={f.id}
                    value={f.id}
                  >
                    {f.first_name} {f.last_name}
                  </option>
                ))}
              </select>
              <label>{I18n.t('components.dashboards.questionnaire.select_profile')}</label>
            </div>
            {this.props.training &&
              <div className='input-field col s12 m4'>
                <select
                  id='questionnaire-dashboard-select-training-type'
                  onChange={e => this.handleSelectTrainingType(M.FormSelect.getInstance(e.target))}
                  value={this.state.selectedTrainingTypes} multiple
                  // disable when no data, tried adding a "No data" placeholder to the dropdowns but couldnt, so this is workaround
                  disabled={hasNoData}
                >
                  {this.state.trainingTypes && this.state.trainingTypes.map(f => (
                    <option
                      key={f.id}
                      value={f.id}
                    >
                      {f.id}
                    </option>
                  ))}
                </select>
                <label>{I18n.t('components.dashboards.questionnaire.select_training_type')}</label>
                <div className='select-buttons-wrapper' id='select-training-type-buttons'>
                  <div className='select-buttons dropdown-content'>
                    <a
                      className='select-button pointer text-s' onClick={e => {
                        e.preventDefault()
                        this.selectTrainingTypes(this.state.trainingTypes.map(trainingType => trainingType.id), true)
                      }}
                    >{I18n.t('components.dashboards.questionnaire.select_all')}
                    </a>
                    <a
                      className='select-button pointer text-s' onClick={e => {
                        e.preventDefault()
                        this.selectTrainingTypes([], true)
                      }}
                    >{I18n.t('components.dashboards.questionnaire.deselect_all')}
                    </a>
                  </div>
                </div>
              </div>}
            {this.props.training &&
              <div className='input-field col s12 m4'>
                <select
                  id='questionnaire-dashboard-select-session-type'
                  onChange={e => this.handleSelectSessionType(M.FormSelect.getInstance(e.target))}
                  value={this.state.selectedSessionTypes} multiple
                  disabled={hasNoData}
                >
                  {this.state.sessionTypes && this.state.sessionTypes.map(f => (
                    <option
                      key={f.id}
                      value={f.id}
                    >
                      {f.id}
                    </option>
                  ))}
                </select>
                <label>{I18n.t('components.dashboards.questionnaire.select_session_type')}</label>
                <div className='select-buttons-wrapper' id='select-session-type-buttons'>
                  <div className='select-buttons dropdown-content'>
                    <a
                      className='select-button pointer text-s' onClick={e => {
                        e.preventDefault()
                        this.selectSessionTypes(this.state.sessionTypes.map(sessionType => sessionType.id), true)
                      }}
                    >{I18n.t('components.dashboards.questionnaire.select_all')}
                    </a>
                    <a
                      className='select-button pointer text-s' onClick={e => {
                        e.preventDefault()
                        this.selectSessionTypes([], true)
                      }}
                    >{I18n.t('components.dashboards.questionnaire.deselect_all')}
                    </a>
                  </div>
                </div>
              </div>}
            <WeekSlider {...this.props} />
          </div>
          <AlertSearcher data={this.state.allDailyQuestionnaires} alertsToCheckFor={this.alertsToCheckFor} />
          {!this.state.preprocessingDone && <Spinner ready={false} />}
          {this.state.preprocessingDone && this.props.training && <TrainingQuestionnaireDashboard {...this.state} />}
          {this.state.preprocessingDone && this.props.wellbeing && <WellbeingQuestionnaireDashboard {...this.state} />}
        </div>
      </PremiumOverlay>
    )
  }
}

export default WithQuestionnaireData(TrainingOrWellbeingQuestionnaireDashboardOptimized)
