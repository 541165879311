import React from 'react'
import { Scatter } from 'react-chartjs-2'
import Variables from '../../../../../javascript/stylesheets/variables.module.scss'
import { WithErrorBoundary } from '../../../errorboundary/ErrorBoundaryProvider'
import I18n from 'i18n'
import { zipData } from '../../../common/Utils'
import {
  chartjsDefaultArguments,
  chartjsDefaultOptions,
  chartjsScaleLabelProperties,
  chartjsTitleProperties
} from '../../../common/Constants'
const { primaryColor, accentColor } = Variables

/**
 * Renders a scatter plot for the given x and y datasets. Also adds a linear regression line
 * A slider is rendered above the plot to control the number of samples that are shown.
 */
const ScatterPlot = ({ xs, ys, xLabel = 'X', yLabel = 'Y', title = 'Scatterplot', height = 250, showLegend = false, xTickCallback = (v) => v, fit = [], fitLabel = 'Regression' }) => {
  /**
   * render both the scatterplot and regression line
   */
  const chartData = {
    labels: xs,
    datasets: [
      {
        type: 'scatter',
        yAxisId: 'y-axis-1',
        fill: false,
        label: `${xLabel} - ${yLabel}`,
        backgroundColor: primaryColor,
        borderColor: primaryColor,
        borderWidth: 1,
        data: zipData(xs, ys),
        order: 1
      }
    ]
  }

  if (fit.length) {
    chartData.datasets.unshift({
      type: 'line',
      yAxisId: 'y-axis-1',
      label: fitLabel,
      data: fit,
      fill: false,
      backgroundColor: '',
      borderColor: accentColor,
      pointRadius: 0
    })
  }

  /**
   * Styling options for the scatterplot
   */
  const options = {
    ...chartjsDefaultOptions,
    title: {
      ...chartjsTitleProperties,
      display: true,
      text: title
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      mode: 'label'
    },
    elements: {
      line: {
        fill: false
      }
    },
    legend: { display: showLegend },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false
          },
          labels: {
            show: true
          },
          scaleLabel: {
            display: true,
            labelString: xLabel
          },
          ticks: {
            // Include a dollar sign in the ticks
            callback: xTickCallback
          }
        }
      ],
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
          gridLines: {
            display: true
          },
          labels: {
            show: true
          },
          scaleLabel: {
            ...chartjsScaleLabelProperties,
            display: true,
            labelString: yLabel
          }
        }
      ]
    }
  }

  return (
    <Scatter {...chartjsDefaultArguments} height={height} data={chartData} options={options} />
  )
}

export default WithErrorBoundary(ScatterPlot, I18n.t('components.visualization.scatterplot.error'))
