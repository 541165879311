import React from 'react'
import { Link } from 'react-router-dom'

interface RowLinkProps {
  onClick: React.MouseEventHandler
  children: React.ReactElement
}

const RowLink: React.FC<RowLinkProps> = ({ onClick, children }) => {
  return (
    <div className='row notification-row valign-wrapper'>
      <div className='col s12 center-align'>
        <Link
          className='responsive-link text-s'
          to=''
          onClick={onClick}
        >
          {children}
        </Link>
      </div>
    </div>

  )
}

export default RowLink
