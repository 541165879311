import React from 'react'
import SummaryView from '../detail/SummaryView'

const InlineSkatingView = (props) => {
  const { data: { summary = {} } } = props
  const fieldsOfInterest = ['start_date', 'distance', 'duration', 'avg_speed', 'elevation_gain', 'elevation_loss', 'calories']

  return <SummaryView fieldsOfInterest={fieldsOfInterest} summary={summary} />
}

export default InlineSkatingView
