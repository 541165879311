import React from 'react'
import PropTypes from 'prop-types'
import SoccerField from './SoccerField'
import Slider from '../../../common/Slider'
import _ from 'lodash'
import moment from 'moment'
import I18n from 'i18n'
import { soccerReplayDuration, soccerFieldOutOfBounds } from '../../../common/Constants'

export default class SoccerReplay extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      playerPositions: [],
      animate: false,
      startTime: 0
    }
  }

  // In milliseconds.
  // This is the duration of each segment in the whole animation
  // measurementRate in Hz
  getAnimationDuration (measurementRate) {
    if (!measurementRate) {
      return 0
    }
    return Math.floor(1000 / measurementRate)
  }

  getPositionalData (startSecond) {
    const endSecond = startSecond + soccerReplayDuration
    const positionalData = _.map(this.props.positions, function (playerPositions) {
      return _.filter(
        playerPositions,
        function (_, offset) { return offset >= startSecond && offset <= endSecond })
    })

    const formattedData = _.map(
      this.props.players,
      function (player, index) {
        let positions = _.at(positionalData[index], _.range(0, endSecond - startSecond))
        positions = _.map(
          positions,
          function (position) { return position || { x: soccerFieldOutOfBounds, y: soccerFieldOutOfBounds } })
        return {
          number: player.number ? player.number : index,
          team: player.team,
          positions: positions
        }
      }
    )
    return formattedData
  }

  handlePlayClick () {
    this.setState({
      animate: !this.state.animate
    })
  }

  handleEndAnimation () {
    this.setState({
      animate: false
    })
  }

  handleTimeChange (_values, _handle, unencodedValues) {
    const newPlayerPositions = this.getPositionalData(Math.floor(unencodedValues[0]))
    this.setState({
      playerPositions: newPlayerPositions,
      animate: false,
      startTime: unencodedValues[0]
    })
  }

  componentDidMount () {
    const newPlayerPositions = this.getPositionalData(this.state.startTime)
    this.setState({
      playerPositions: newPlayerPositions
    })
  }

  componentDidUpdate (prevProps) {
    if (JSON.stringify(prevProps.positions) !== JSON.stringify(this.props.positions)) {
      const newPlayerPositions = this.getPositionalData(this.state.startTime)
      this.setState({
        playerPositions: newPlayerPositions,
        animate: false,
        startTime: 0
      })
    }
  }

  render () {
    const animationDuration = this.getAnimationDuration(this.props.measurementRate)
    const matchDuration = moment.duration(this.props.duration, 'seconds').asSeconds()

    const range = matchDuration > 0 ? [0, Math.ceil(matchDuration) - 1] : [0, 1]

    let buttonExtension = ''
    if (!_.keys(this.state.playerPositions).length) {
      buttonExtension = 'disabled-button'
    }

    return (
      <div id='soccer-replay'>
        <div className='row'>
          <div className='col s12 soccer-replay-field-container'>
            <SoccerField animate={this.state.animate} sectionDuration={animationDuration} playerData={this.state.playerPositions} id='soccer-replay-field' onEnd={this.handleEndAnimation.bind(this)} />
          </div>
        </div>
        <div className='row' style={{ marginBottom: 0 }}>
          <div className='col s12'>
            <div className='soccer-replay-button-div'>
              <a className={`button-primary background-primary button-autowidth waves-effect waves-light text-background-color ${buttonExtension}`} onClick={this.handlePlayClick.bind(this)}>
                {!this.state.animate && <i className='small material-icons'>play_arrow</i>}
                {this.state.animate && <i className='small material-icons'>stop</i>}
              </a>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col s12'>
            <Slider
              id='match-replay-time-slider'
              isTime
              onChange={this.handleTimeChange.bind(this)}
              range={range}
              start={[0]}
              step={1}
              title={I18n.t('components.dashboards.soccer.replay.time_picker')}
            />
          </div>
        </div>
      </div>
    )
  }
}

SoccerReplay.propTypes = {
  players: PropTypes.array,
  positions: PropTypes.arrayOf(PropTypes.object),
  measurementRate: PropTypes.number,
  duration: PropTypes.number
}

SoccerReplay.defaultProps = {
  positions: []
}
