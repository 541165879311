import React from 'react'
import Table from '../../../common/Table'
import I18n from 'i18n'
import Histogram from '../common/Histogram'
import { WithErrorBoundary } from '../../../errorboundary/ErrorBoundaryProvider'
/**
 * Renders a histogram for the given dataset. If data is numeric, it will be split into bins first
 * The number of bins can be controller with the nbins prop
 */
const CsvCategoricalHistogram = ({ summaryBuilder, description, data, height = 250 }) => {
  /**
   * reduce data to a histogram
   */
  const hist = data.reduce((acc, value) => {
    if (value in acc) {
      acc[value] += 1
      return acc
    }
    acc[value] = 1
    return acc
  }, {})

  /**
   * Column headers for the selected column's summary table
   */
  const columns = summaryBuilder.getCategoricalSummaryColumns()

  /**
   * Values for the selected column's summary table (only a single row because a histogram is for a single variable)
   */
  const rows = summaryBuilder.getCategoricalSummaryRows([description.name])

  const yLabel = description.label
  const xLabels = Object.keys(hist)
  const ys = Object.keys(hist).map((key) => hist[key])

  return (
    <div className='row'>
      <div className='col s12'>
        <Table columns={columns} rows={rows} />
      </div>
      <div className='col s12'>
        <Histogram yLabel={yLabel} xLabels={xLabels} data={ys} height={height} />
      </div>
    </div>
  )
}

export default WithErrorBoundary(CsvCategoricalHistogram, I18n.t('components.visualization.csv.errors.histogram'))
