import React, { useImperativeHandle, useRef, useState, useEffect, forwardRef } from 'react'
import PropTypes from 'prop-types'

// The forwardRef here is needed in order to allow other components to keep a
// ref to this component. For most components this is not a good idea, but in
// this case it makes the Poller component very generic.
const Poller = forwardRef((props, ref) => {
  const defaultCount = 1
  const [poll, setPoll] = useState(false)
  const [pollCount, setPollCount] = useState(defaultCount)

  const countRef = useRef(pollCount)
  countRef.current = pollCount

  const clearPoll = () => {
    poll && clearTimeout(poll)
  }

  const performPoll = () => {
    props.functionToPoll()
    setPollCount(count => count * 2)
    setPoll(setTimeout(performPoll, countRef.current * 500))
  }
  useEffect(() => {
    performPoll()
  }, [])

  useEffect(() => {
    return () => {
      clearPoll()
    }
  }, [poll])

  useImperativeHandle(ref, () => ({
    reset () {
      setPollCount(defaultCount)
      countRef.current = defaultCount
      performPoll()
    }
  }))

  return <></>
})

Poller.propTypes = {
  functionToPoll: PropTypes.func.isRequired
}

export default Poller
