import React from 'react'
import Tab from './Tab'
import I18n from 'i18n'

export default class Tabs extends React.Component {
  constructor (props) {
    super()
    this.state = { active: props.tabs[0].path }
  }

  handleSwitchTab (tab, path) {
    this.props.history.push(path)
    this.setState({ ...this.state, active: tab })
  }

  render () {
    const { id, tabs, location, defaultActive } = this.props
    const hasNoActiveTab = tabs.filter((tab) => tab.path === location.pathname).length === 0
    return (
      <div className='contains-tabs' id={id}>
        <div className='row'>
          {tabs.map((tab) => (
            <Tab
              id={tab.id}
              key={tab.title}
              title={I18n.t(tab.title)}
              subtitle={tab.subtitle && I18n.t(tab.subtitle, tab.subtitleParams)}
              active={(location.pathname === tab.path) || (hasNoActiveTab && defaultActive === tab.path)}
              onSwitchTab={this.handleSwitchTab.bind(this)}
              tab={tab.path}
              path={tab.path}
            />
          ))}
        </div>
      </div>
    )
  }
}
