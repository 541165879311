export default {
  root: '/',
  addedIntegration: '/added_integration',

  static: {
    termsAndConditionsLatest: '/static/terms-and-conditions?v=latest',
    termsAndConditionsVersionless: '/static/terms-and-conditions',
    termsAndConditionsVersionlessDutchLegacy: '/static/algemene-voorwaarden',
    privacyStatement: '/static/privacy-statement',
    questionnaires: '/static/questionnaires',
    acceptTerms: '/static/accept-terms-and-conditions'
  },

  network: {
    index: '/network',
    groups: {
      index: '/network/groups',
      create: '/network/groups/create',
      show: '/network/groups/:groupId',
      join: '/network/groups/:groupId/memberships/:membershipId/join',
      joinFn: (groupId, membershipId) => `/network/groups/${groupId}/memberships/${membershipId}/join`,
      showFn: (groupId = '') => (`/network/groups/${groupId}`),
      edit: '/network/groups/:groupId/edit',
      editFn: (groupId) => (`/network/groups/${groupId}/edit`),
      typeFn: (groupId, type) => (`/network/groups/${groupId}/${type}`),
      announcement: {
        show: '/network/groups/:groupId/announcement',
        showFn: (groupId) => (`/network/groups/${groupId}/announcement`)
      }
    },
    connections: {
      index: '/network/connections'
    }
  },

  timeline: {
    index: '/timeline',
    network: {
      index: '/timeline/network'
    }
  },

  data: {
    index: '/data',
    // fjb: Deleted the (\d+) because of standard here
    show: '/data/:dataId',
    showFn: (id = '') => (`/data/${id}`),

    // fjb: Deleted the (\d+) because of standard here
    edit: '/data/:dataId/edit',
    editFn: (dataId) => (`/data/${dataId}/edit`),

    network: {
      index: '/data/network'
    },
    upload: {
      index: '/data/upload'
    }
  },

  premium: {
    subscribe: {
      index: '/premium/subscribe',
      statusFn: (statusMsg) => (`/premium/subscribe?status=${statusMsg}`),
      authorise: '/premium/subscribe/authorise',
      status: '/premium/subscribe/status'
    }
  },

  reconfirm: {
    index: '/confirmation'
  },

  redeem: {
    voucher: {
      index: '/redeem'
    }
  },

  training: {
    index: '/training-scheduler'
  },

  profiles: {
    showFn: (slug = '') => (`/profiles/${slug}`),
    show: '/profiles/:slug',
    edit: '/profile/edit'
  },

  sharing: {
    index: '/delen'
  },

  snapshots: {
    index: '/snapshots'
  },

  analysis: {
    index: '/analysis',
    iceSkating: '/analysis/schaatsen',

    iceSkatingSingle: {
      single: {
        index: '/analysis/schaatsen/single'
      }
    },

    questionnaire: {
      index: '/analysis/vragenlijsten',
      weekly: '/analysis/vragenlijsten/wekelijks',
      full: '/analysis/vragenlijsten/volledig/:id?',
      full_opt: '/analysis/vragenlijsten/volledig_opt/:id?',
      indexFn: (id = '') => (`/analysis/vragenlijsten/volledig/${id}`),
      indexOptFn: (id = '') => (`/analysis/vragenlijsten/volledig_opt/${id}`),
      trends: '/analysis/vragenlijsten/trends',
      rheuma: {
        show: '/analysis/vragenlijsten/rheuma/:id?',
        showFn: (id = '') => (`/analysis/vragenlijsten/rheuma/${id}`)
      },
      complaints: {
        show: '/analysis/vragenlijsten/complaints/:id?',
        showFn: (id = '') => (`/analysis/vragenlijsten/complaints/${id}`)
      }
    },

    activity: {
      index: '/analysis/activiteiten',
      periodFn: (period = '') => (`/analysis/activiteiten/${period}`),
      weekly: '/analysis/activiteiten/week',
      monthly: '/analysis/activiteiten/maand',
      yearly: '/analysis/activiteiten/jaar'
    },

    running: {
      index: '/analysis/hardlopen',
      single: {
        index: '/analysis/hardlopen/single',
        show: '/analysis/hardlopen/single/:id?',
        showFn: (id = '') => (`/analysis/hardlopen/single/${id}`)
      },
      trends: '/analysis/hardlopen/trends'
    },

    swimming: {
      index: '/analysis/zwemmen',
      single: {
        index: '/analysis/zwemmen/single',
        show: '/analysis/zwemmen/single/:id?',
        showFn: (id = '') => (`/analysis/zwemmen/single/${id}`)
      },
      trends: '/analysis/zwemmen/trends'
    },

    cycling: {
      index: '/analysis/fietsen',
      single: {
        index: '/analysis/fietsen/single',
        show: '/analysis/fietsen/single/:id?',
        showFn: (id = '') => (`/analysis/fietsen/single/${id}`)
      },
      trends: '/analysis/fietsen/trends'
    },
    inlineSkating: {
      index: '/analysis/inline_skating',
      showFn: (suffix = '') => (`/analysis/inline_skating/${suffix}`)
    },

    hiking: {
      show: '/analysis/hiking/:id?',
      showFn: (id = '') => (`/analysis/hiking/${id}`)
    },

    soccer: {
      show: '/analysis/voetbal/:id?',
      showFn: (id = '') => (`/analysis/voetbal/${id}`)
    },

    csv: {
      show: '/analysis/csv/:id?',
      showFn: (id = '') => (`/analysis/csv/${id}`)
    },
    triathlon: '/analysis/triathlon',

    coach: '/analysis/coach'
  },

  join: {
    code: '/join/:code',
    codeFn: (code) => (`/join/${code}`)
  },

  admin: {
    index: '/admin',
    premium: '/admin/premium',
    exportEmailAddresses: '/api/v1/admin/export_email_addresses'
  },

  transponder: {
    add: '/transponders/add'
  },

  developer: {
    index: '/developer',
    gettingStarted: '/developer/getting-started',
    gettingStartedSource: '/getting-started-guide/index.md'
  }
}
