import React from 'react'
import TimelineController from '../timeline/TimelineController'
import Network from '../network/Network'
import GroupJoin from '../network/GroupJoin'
import Data from '../data/Data'
import Profile from '../profile/Profile'
import PremiumMembership from '../premium_membership/PremiumMembership'
import DataSharing from '../datasharing/DataSharing'
import NotFoundProvider from './NotFoundProvider'
import FeedbackButton from '../common/FeedbackButton'
import FeedbackButtonPanel from '../common/FeedbackButtonPanel'
import OAuthController from '../oauth/OAuthController'
import StaticRoutes from '../static/StaticRoutes'
import Analysis from '../analysis/Analysis'
import Navigation from '../navigation/Navigation'
import AdminRoutes from '../admin/AdminRoutes'
import LoggedInRedirector from '../login_wrapper/LoggedInRedirector'
import I18n from 'i18n'
import Redeem from '../redeem/Redeem'
import Reconfirm from '../reconfirm/Reconfirm'
import Transponder from 'components/transponder/Transponder'
import TrainingController from 'components/training/TrainingController'

export default class LoggedInRouter extends React.Component {
  render () {
    return (
      <>
        <Navigation isLoggedIn={this.props.isLoggedIn} location={this.props.location} />
        <div className='container'>
          <FeedbackButtonPanel>
            <FeedbackButton />
            <FeedbackButton url='https://tutorial.sportdatavalley.nl' className='tutorial-button' labelText={I18n.t('general.tutorial')} />
          </FeedbackButtonPanel>
          <NotFoundProvider>
            <LoggedInRedirector />
            <OAuthController />
            <StaticRoutes />
            <Network />
            <TimelineController />
            <TrainingController />
            <Data />
            <PremiumMembership />
            <Redeem />
            <Profile />
            <DataSharing />
            <Analysis />
            <GroupJoin />
            <Reconfirm />
            <Transponder />
            {this.props.admin && <AdminRoutes />}
          </NotFoundProvider>
        </div>
      </>
    )
  }
}
