import React from 'react'
import { Line } from 'react-chartjs-2'
import _ from 'lodash'
import { bin } from '../../common/Math'
import { soccerColors as defaultColors } from '../../common/Constants'

export class OvertimeChartConfigs {
  constructor () {
    this.xAxis = []
    this.binSize = 1
    this.colors = defaultColors
    this.range = []
  }

  setXAxis (values) {
    this.xAxis = values
  }

  getColor (index) {
    return this.colors[index % this.colors.length]
  }

  setColors (colors) {
    if (!colors) {
      this.colors = defaultColors
    } else {
      this.colors = colors
    }
  }

  setRange (min, max) {
    this.range = [min, max]
  }

  transformXAxis (xAxis, min, max, bins) {
    let newXAxis = xAxis
    if (min && max) {
      newXAxis = _.filter(newXAxis, elem => (elem >= min && elem <= max))
    }
    if (bins > 1) {
      newXAxis = bin(newXAxis).map(bin => bin[0])
    }

    return newXAxis
  }

  toDict () {
    let xAxis = this.xAxis
    if (this.range) {
      xAxis = this.transformXAxis(this.xAxis, this.range[0], this.range[1], this.binSize)
    }

    return {
      data: {
        labels: xAxis
      },
      options: {
        scales: {
          xAxes: [{
            ticks: {
              min: this.range[0],
              max: this.range[1]
            }
          }]
        }
      }
    }
  }
}

export default class OvertimeChart extends React.Component {
  constructor (props) {
    super(props)
    this.data = this.parseData(props.datasets)
  }

  parseData (datasets, configs) {
    const data = { datasets: [] }
    const datasetsArray = _.entries(datasets)
    for (const [index, [label, dataPoints]] of _.entries(datasetsArray)) {
      const filteredData = _.slice(dataPoints, ...configs.range)
      data.datasets.push({ data: filteredData, label: label, backgroundColor: configs.getColor(index, true) })
    }
    return data
  }

  render () {
    if (!(this.props.configs instanceof OvertimeChartConfigs)) {
      return <></>
    }
    const configs = this.props.configs.toDict()
    const parsedData = this.parseData(this.props.datasets, this.props.configs)
    const finalData = _.merge(parsedData, configs.data)

    return (
      <Line data={finalData} options={configs.options} />
    )
  }
}
