import moment from 'moment'
import I18n from 'i18n'
import { average, getWeekNumber, movingAverage, round, standardDeviation, sum, unique, missingValueImputation, nanToZero } from '../../../common/Math'
import { momentFullDateFormat, imputation } from '../../../common/Constants'
import { dateOfWeek, formatDateTime, pad, smartTranslate } from 'components/common/Utils'
import { getWellnessScore } from 'components/common/utils/QuestionnaireUtils'

// processing daily questionnaires and sunday questionnaires

export const addMovingAverageColumn = (dailyLogRows, origAttr, windowSize, newAttr) => {
  let origArr = dailyLogRows.map(item => item[origAttr])
  if (origAttr === 'sleep_duration_num') {
    origArr = missingValueImputation(origArr, imputation.MEAN, undefined, [0, null])
  }
  const newArr = movingAverage(origArr, windowSize)
  dailyLogRows.forEach((values, idx) => {
    values[newAttr] = newArr[idx]
  })
}

export const calculateWeeklyAverages = data => {
  data.sort((a, b) => a.x - b.x) // needs data to be sorted in order to work properly

  const weeklyAverages = []
  let currentWeekTotal = 0
  let currentWeekNumber = null
  let prevWeekNumber = null
  let dayOfWeekCount = 0
  let lastDate = new Date()

  data.forEach(datum => {
    currentWeekNumber = getWeekNumber(new Date(datum.x))[1] // current week

    if (prevWeekNumber && prevWeekNumber !== currentWeekNumber) {
      // new week detected, push and reset needed
      const weeklyAverage = currentWeekTotal / dayOfWeekCount // calculate prev week average
      const weekData = {
        x: lastDate,
        y: round(weeklyAverage, 1),
        formatted_date: moment(lastDate).locale(I18n.locale).format(momentFullDateFormat)
      }

      weeklyAverages.push(weekData)

      // reset values after pushing for start of new week
      currentWeekTotal = 0
      dayOfWeekCount = 0
    }

    dayOfWeekCount++
    currentWeekTotal += datum.y

    lastDate = datum.x
    prevWeekNumber = currentWeekNumber // store prev week number for next iteration
  })

  // handle last week of data
  if (dayOfWeekCount > 0) {
    const weeklyAverage = currentWeekTotal / dayOfWeekCount
    const weekData = {
      x: lastDate,
      y: round(weeklyAverage, 1),
      formatted_date: moment(lastDate).locale(I18n.locale).format(momentFullDateFormat)
    }
    weeklyAverages.push(weekData)
  }

  return weeklyAverages
}

// legacy function
// new version in : UtilsTS.tsx
const calculateWeeklyOSTRCSeverity = entry => {
  let result = 0
  const parameters = ['participation', 'reduced_training', 'affected_performance', 'symptoms_complaints']
  for (const parameter of parameters) {
    result += entry[parameter]
  }
  result = result / 4
  return result
}

// legacy function: uses old object structure (used by full dashboards)
// new version in : UtilsTS.tsx
const preprocessDailyLogRows = (dataRows, hourUnit, minuteUnit) => {
  let dailyLogRows = dataRows.reduce((accumulator, item) => {
    if (item.questionnaire.key !== 'sunday_questionnaire' && item.questionnaire.key !== 'weekly_wellbeing') {
      accumulator.push(item)
    }
    return accumulator
  }, [])

  dailyLogRows.forEach(entry => {
    entry.date = new Date(entry.open_from)
    entry.date_only = new Date((new Date(entry.open_from)).toDateString())
    entry.formatted_date = moment(entry.date_only).locale(I18n.locale).format(momentFullDateFormat)

    entry.wellness_sleep = parseFloat(entry.values.v1)
    entry.wellness_fatigue = parseFloat(entry.values.v3)
    entry.wellness_stress = parseFloat(entry.values.v4)
    entry.wellness_soreness = parseFloat(entry.values.v5)
    entry.wellness_mood = parseFloat(entry.values.v6)
    entry.readiness_to_train = parseFloat(entry.values.v7)
    entry.resting_hr = parseFloat(entry.values.v8)
    entry.weight = parseFloat(entry.values.v8a)
    entry.sick = ['ja', 'yes'].includes(entry.values.v9) ? 1 : 0
    entry.injured = ['ja', 'yes'].includes(entry.values.v10) ? 1 : 0
    entry.wellness_sum_p = getWellnessScore(entry.values)
    entry.wellness_sum_p_rounded = round(entry.wellness_sum_p, 0)
    entry.sleep_duration = `${entry.values.v2_uren}${hourUnit}${entry.values.v2_minuten}${minuteUnit}`
    entry.sleep_duration_num = parseInt(entry.values.v2_uren) + (parseInt(entry.values.v2_minuten) / 60)

    entry.comments = entry.values.v11
  })

  dailyLogRows = dailyLogRows.sort((a, b) => a.date - b.date)
  addMovingAverageColumn(dailyLogRows, 'wellness_sum_p', 7, 'wellness_moving_average')
  addMovingAverageColumn(dailyLogRows, 'sleep_duration_num', 7, 'sleep_duration_moving_average')
  addMovingAverageColumn(dailyLogRows, 'resting_hr', 7, 'resting_hr_moving_average')
  addMovingAverageColumn(dailyLogRows, 'readiness_to_train', 7, 'readiness_to_train_moving_average')
  addMovingAverageColumn(dailyLogRows, 'sleep_duration_num', 7, 'sleep_duration_num_moving_average')

  return dailyLogRows
}

// legacy function: uses old object structure (used by full dashboards)
// new version in : UtilsTS.tsx
const preprocessSundaySummary = dataRows => {
  let sundaySummary = dataRows.filter(item => item.questionnaire.key === 'sunday_questionnaire')

  sundaySummary.forEach(entry => {
    entry.date = new Date(entry.open_from)
    entry.date_only = new Date((new Date(entry.open_from)).toDateString())
    entry.formatted_date = formatDateTime(entry.date)

    entry.weekly_feedback = entry.values.v7
    entry.participation = nanToZero(parseFloat(entry.values.v3)) / 3
    entry.reduced_training = nanToZero(parseFloat(entry.values.v4)) / 4
    entry.affected_performance = nanToZero(parseFloat(entry.values.v5)) / 4
    entry.symptoms_complaints = nanToZero(parseFloat(entry.values.v6)) / 3
    entry.weekly_ostrc_severity = calculateWeeklyOSTRCSeverity(entry)
  })

  sundaySummary = sundaySummary.sort((a, b) => a.date - b.date)
  return sundaySummary
}

// legacy function: uses old object structure (used by full dashboards)
// new version in : UtilsTS.tsx
const preprocessWeeklyWellbeingRows = (dataRows, hourUnit, minuteUnit) => {
  let weeklyWellbeingRows = dataRows.filter(item => item.questionnaire.key === 'weekly_wellbeing')

  weeklyWellbeingRows.forEach(entry => {
    entry.date = new Date(entry.open_from)
    entry.date_only = new Date((new Date(entry.open_from)).toDateString())
    entry.formatted_date = formatDateTime(entry.date)
    entry.sleep_quality = nanToZero(parseFloat(entry.values.v1))
    entry.sleep_duration = `${entry.values.v2_uren}${hourUnit}${entry.values.v2_minuten}${minuteUnit}`
    entry.sleep_duration_num = parseInt(entry.values.v2_uren) + (parseInt(entry.values.v2_minuten) / 60)
    entry.wellness_fatigue = parseFloat(entry.values.v3)
    entry.wellness_stress = parseFloat(entry.values.v4)
    entry.wellness_mood = parseFloat(entry.values.v5)
    entry.wellness_sum_p = getWellnessScore(entry.values)
    entry.wellness_sum_p_rounded = round(entry.wellness_sum_p, 0)
    entry.comments = entry.values.v6
    entry.wellness_sleep = entry.sleep_quality
  })
  weeklyWellbeingRows = weeklyWellbeingRows.sort((a, b) => a.date - b.date)

  return weeklyWellbeingRows
}

// legacy function: uses old object structure (used by full dashboards)
// new version in : UtilsTS.tsx
export const preprocessWellbeingQuestionnaires = questionnaires => {
  const dataRows = questionnaires.map(item => item.data_rows[0])
  const hourUnit = I18n.t('components.dashboards.questionnaire.units.h')
  const minuteUnit = I18n.t('components.dashboards.questionnaire.units.m')

  const dailyLogRows = preprocessDailyLogRows(dataRows, hourUnit, minuteUnit)
  const sundaySummary = preprocessSundaySummary(dataRows)
  const weeklyWellbeingRows = preprocessWeeklyWellbeingRows(dataRows, hourUnit, minuteUnit)

  return { dailyLogRows, sundaySummary, weeklyWellbeingRows }
}

// processing training logs

export const shortSessionName = sessionType => {
  switch (sessionType) {
    case 'Extensive duration':
    case 'Extensieve duur':
      return I18n.t('components.dashboards.questionnaire.short_session_names.ext_duration')
    case 'Extensive tempo':
    case 'Extensieve tempo':
      return I18n.t('components.dashboards.questionnaire.short_session_names.ext_tempo')
    case 'Intensive duration':
    case 'Intensieve duur':
      return I18n.t('components.dashboards.questionnaire.short_session_names.int_duration')
    case 'Intensive tempo':
    case 'Intensieve tempo':
      return I18n.t('components.dashboards.questionnaire.short_session_names.int_tempo')
    case 'Teamtraining':
    case 'Team training':
      return I18n.t('components.dashboards.questionnaire.short_session_names.team_training')
    case 'int uithoudingsvermogen':
      return I18n.t('components.dashboards.questionnaire.short_session_names.int_endurance')
    case 'ext uithoudingsvermogen':
      return I18n.t('components.dashboards.questionnaire.short_session_names.ext_endurance')
    case 'Intensieve interval':
    case 'Intensive interval':
    case 'int interval':
      return I18n.t('components.dashboards.questionnaire.short_session_names.int_interval')
    case 'Extensieve interval':
    case 'Extensive interval':
    case 'ext interval':
      return I18n.t('components.dashboards.questionnaire.short_session_names.ext_interval')
    case 'general fitness':
      return I18n.t('components.dashboards.questionnaire.short_session_names.general_fitness')
    default:
      return sessionType
  }
}

// legacy function
// new version in : UtilsTS.tsx
const determineDaypart = myDate => {
  const hour = myDate.getHours()
  if (hour >= 5 && hour <= 11) return I18n.t('components.dashboards.questionnaire.dayparts.morning')
  if (hour >= 12 && hour <= 17) return I18n.t('components.dashboards.questionnaire.dayparts.afternoon')
  if (hour >= 18 && hour <= 23) return I18n.t('components.dashboards.questionnaire.dayparts.evening')
  if (hour >= 0 && hour <= 4) return I18n.t('components.dashboards.questionnaire.dayparts.night')
  return 'N/A'
}

// legacy function: uses old object structure (used by full dashboards)
// new version in : UtilsTS.tsx
export const preprocessTrainingLogs = questionnaires => {
  let trainingLogRows = questionnaires.map(item => item.data_rows[0])
  trainingLogRows.forEach(entry => {
    entry.training_type = smartTranslate(entry.values.v1)
    entry.training_session = smartTranslate(entry.values.v2)
    entry.training_session_short = shortSessionName(entry.training_session)

    entry.training_start = `${entry.values.v3} ${pad(entry.values.v4_uur || entry.values.v4_uren, 2)}:${pad(entry.values.v4_minuten, 2)}:00`.replace(/-/g, '/')
    entry.training_start_date = new Date(entry.training_start)
    entry.formatted_training_start_date = formatDateTime(entry.training_start_date)
    entry.training_start_wk = getWeekNumber(entry.training_start_date)
    entry.training_daypart = determineDaypart(entry.training_start_date)

    entry.training_duration = parseInt(entry.values.v5)
    entry.rpe = parseInt(entry.values.v6)
    entry.training_satisfaction = parseFloat(entry.values.v7)
    entry.comments = entry.values.v8

    entry.srpe = entry.rpe * entry.training_duration
  })

  trainingLogRows = trainingLogRows.sort((a, b) => a.training_start_date - b.training_start_date)
  trainingLogRows.forEach(entry => {
    entry.training_start_wknum = wkNumFormat(entry.training_start_wk)
  })

  // Make weekly summaries
  let weeklySummary = {}
  trainingLogRows.forEach(entry => {
    if (weeklySummary[entry.training_start_wknum] === undefined) {
      weeklySummary[entry.training_start_wknum] = {
        wknum: entry.training_start_wknum,
        wknum_arr: entry.training_start_wk,
        wknum_formatted: `${entry.training_start_wk[1]}, ${entry.training_start_wk[0]}`,
        date_only: dateOfWeek(entry.training_start_wk[1], entry.training_start_wk[0]),
        srpes: []
      }
    }
    weeklySummary[entry.training_start_wknum].srpes.push(entry.srpe)
  })
  weeklySummary = Object.values(weeklySummary)
  weeklySummary = weeklySummary.sort((a, b) => (a.date_only > b.date_only) ? 1 : ((b.date_only > a.date_only) ? -1 : 0))
  weeklySummary.forEach(entry => {
    entry.training_load = sum(entry.srpes)
    entry.formatted_date = moment(entry.date_only).locale(I18n.locale).format(momentFullDateFormat)
    if (unique(entry.srpes).length > 1) {
      entry.training_monotony = average(entry.srpes) / standardDeviation(entry.srpes)
      entry.training_strain = entry.training_load * entry.training_monotony
    } else {
      entry.training_monotony = null // NA
      entry.training_strain = null // NA
    }
  })
  addMovingAverageColumn(weeklySummary, 'training_load', 4, 'training_load_moving_average')
  weeklySummary.forEach(entry => {
    entry.training_ac_radio_rpe_coupled = entry.training_load / entry.training_load_moving_average
  })
  return { weeklySummary, trainingLogRows }
}

// legacy function
// new version in : UtilsTS.tsx
const wkNumFormat = wkNumArr => {
  // e.g. '2021.52'
  return `${wkNumArr[0]}.${String(wkNumArr[1]).padStart(2, '0')}`
}

// Making histograms for the training logs
export const makeHistogram = (trainingLogRows, myAttribute, nameShorteningFunction = (value) => { return value }, sortValues = false) => {
  let hist = {}
  trainingLogRows.forEach(entry => {
    const rowVal = entry[myAttribute]
    if (hist[rowVal] === undefined) {
      hist[rowVal] = {
        count: 0,
        value: rowVal,
        short_value: nameShorteningFunction(rowVal),
        formatted_percentage: '',
        percentage: 0
      }
    }
    hist[rowVal].count += 1
  })
  hist = Object.values(hist)
  hist = hist.sort((a, b) => b.count - a.count)
  const total = hist.reduce((a, b) => {
    return { count: a.count + b.count }
  }, { count: 0 }).count
  if (total > 0) {
    hist.forEach(entry => {
      entry.percentage = (100.0 * entry.count) / total
      entry.formatted_percentage = `${round(entry.percentage, 1)}%`
    })
  }
  if (sortValues) {
    hist = hist.sort((a, b) => a.value - b.value)
  }
  return hist
}

// Fill up gaps in data with undefined records
// @param durationThreshold is in seconds
// @param daysPeriod is in days
export const imputateGapsWithUndefinedRecords = (data, durationThreshold, daysPeriod, dataAttribute) => {
  let previousDate = null
  let currentDate = null
  const durationThresholdMillis = durationThreshold * 1000
  const newData = []
  data.sort((a, b) => (a.date_only > b.date_only) ? 1 : ((b.date_only > a.date_only) ? -1 : 0))
  for (const dataPoint of data) {
    currentDate = dataPoint.date_only
    // If the duration between two subsequent data points is more than the given threshold,
    // insert new data points until it is not. The new data points are spaced apart by the
    // given daysPeriod parameter.
    while (previousDate && currentDate - previousDate > durationThresholdMillis) {
      const newPrevious = new Date(previousDate.valueOf())
      newPrevious.setDate(previousDate.getDate() + daysPeriod)
      previousDate = newPrevious
      newData.push({
        date_only: previousDate,
        [dataAttribute]: undefined
      })
    }
    newData.push(dataPoint)
    previousDate = currentDate
  }
  return newData
}
