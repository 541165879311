import React from 'react'
import I18n from 'i18n'

import Bins from '../Bins'
import { DEFAULT_HR_ZONES } from '../../../../common/Constants'

const TrainingSessionZones = (props) => {
  const { activity } = props

  const hrMeasurements = activity?.data?.time_series?.hr
  const hrZones = activity?.vdo?.summary?.times_series_summaries?.hr?.zone_limits
  const hrZonesWithoutZone0 = hrZones?.slice(1) || DEFAULT_HR_ZONES

  const zoneString = I18n.t('components.dashboards.coach_individual.training.zone')
  const hrZoneConfigs = [
    { label: `${zoneString} 1`, shouldInclude: (v) => v > hrZonesWithoutZone0[0] && v <= hrZonesWithoutZone0[1], backgroundColor: '#FFD1D1', borderColor: '#FFD1D1' },
    { label: `${zoneString} 2`, shouldInclude: (v) => v > hrZonesWithoutZone0[1] && v <= hrZonesWithoutZone0[2], backgroundColor: '#F89C9C', borderColor: '#F89C9C' },
    { label: `${zoneString} 3`, shouldInclude: (v) => v > hrZonesWithoutZone0[2] && v <= hrZonesWithoutZone0[3], backgroundColor: '#F26868', borderColor: '#F26868' },
    { label: `${zoneString} 4`, shouldInclude: (v) => v > hrZonesWithoutZone0[3] && v <= hrZonesWithoutZone0[4], backgroundColor: '#EB3434', borderColor: '#EB3434' },
    { label: `${zoneString} 5`, shouldInclude: (v) => v > hrZonesWithoutZone0[4] && v <= hrZonesWithoutZone0[5], backgroundColor: '#E50000', borderColor: '#E50000' }
  ]

  return (
    <div className='row'>
      <div className='col s12'>
        {/* TODO HR should be a constant somewhere? */}
        <Bins values={hrMeasurements} bins={hrZoneConfigs} yLabel='HR' isPercentage />
      </div>
    </div>
  )
}

export default TrainingSessionZones
