import React from 'react'
import ExpandCompressButton from '../../../common/ExpandCompressButton'

interface Props {
  withExpandCompressButton?: boolean
  rowContents?: React.ReactElement
}

// The component for a default, non-clickable title.
const Title: React.FC<Props> = ({ withExpandCompressButton = false, rowContents, children }) => {
  return (
    <div className='row'>
      <div className='col s12 text-xl text-primary-color text-heavy' style={{ position: 'relative' }}>
        {children}
        {withExpandCompressButton && (
          <ExpandCompressButton />
        )}
      </div>
      {rowContents ?? null}
    </div>
  )
}

export default Title
