import React from 'react'
import Welcome from './layout/Welcome'
import { WithSession } from '../session/SessionProvider'
import { useHistory } from 'react-router-dom'

const WelcomeController = props => {
  const { sessionToken, onPictureClick } = props

  const history = useHistory()

  const handlePictureClick = link => onPictureClick?.call() || history.push(link)

  if (!sessionToken) {
    return (<></>)
  }

  return (
    <Welcome {...props} onPictureClick={handlePictureClick} />
  )
}

export default WithSession(WelcomeController)
