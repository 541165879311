import React, { useMemo, useState, useEffect } from 'react'
import I18n from 'i18n'
import SelectDropdown from '../../../common/SelectDropdown'
import { datePickerDefaultOptions, hcBlack, hcLightGrey } from '../../../common/Constants'
import _ from 'lodash'
import { interpolateColors } from '../../../common/Utils'
import DatePicker from 'react-datepicker'

import Variables from '../../../../stylesheets/variables.module.scss'
import { normalizeHistogram } from '../../../common/Math'
import classNames from 'classnames'
import Rings from '../common/Rings'
import SeasonTable from './components/SeasonTable'
import TrainingLoadChart, { LOAD_TYPES } from './components/TrainingLoadChart'
import TrainingIntensityChart, { INTENSITY_TYPES } from './components/TrainingIntensityChart'
import CumulativeTrainingLoadChart from './components/CumulativeTrainingLoadChart'
import PropTypes from 'prop-types'
import SDVMapView from '../../../data/layout/detail/SDVMapView'
import { useDisplayFetchErrors, useFetchData } from '../../../common/Hooks'
import SpinnerWrapper from '../../../common/SpinnerWrapper'
import {
  getMultiActivityDashboardDescriptiveStatistics,
  addEmptyRecords,
  getCountSelectedActivities
} from './GenericDashboardsUtils'
import { WithSession } from '../../../session/SessionProvider'
import ACWRChart from './components/ACWRChart'
import Slider from '../../../common/Slider'
import ChartContainer from 'components/atomic/molecules/ChartContainer'
const { green, greenDark, greenLight, accentColorDark, accentColorLight } = Variables

const MIN_TYPES = 3

const GenericMultiActivityDashboard = (props) => {
  const [start, setStart] = useState(undefined)
  const [end, setEnd] = useState(undefined)
  const [rangeFilter, setRangeFilter] = useState(undefined)
  const [timeOfDay, setTimeOfDay] = useState('all')
  const [type, setType] = useState(undefined)
  const [load, setLoad] = useState('duration')
  const [intensity, setIntensity] = useState('hr')
  const [selectedProfiles, setSelectedProfiles] = useState([])
  const periodType = 'week' // TODO Change to useState. This will be useful once we have the month view too.
  const queryParams = {
    collection_type: props.collectionType,
    collection_subtype: type,
    time_of_day: timeOfDay === 'all' ? undefined : timeOfDay,
    start_date: start,
    end_date: end,
    profile_ids: selectedProfiles
  }
  // Memoized to the user's locale
  const timesOfDay = useMemo(() => (
    ['all', 'morning', 'afternoon', 'evening'].map(int => ({ value: int, name: I18n.t(`components.dashboards.multi_activity.period.${int}`) }))
  ), [props.myProfile])
  const sliderId = 'date-filter-slider'

  const { data: seasons, fetched, error, reFetch } = useFetchData('/api/v1/dashboards/trends/seasons', queryParams)
  const { data: profiles } = useFetchData('/api/v1/dashboards/trends/profiles', _.pick(queryParams, 'collection_type'))
  const { data: acwr, fetched: acwrFetched, error: acwrError, reFetch: reFetchAcwr } = useFetchData('/api/v1/dashboards/trends/acwr', _.pick(queryParams, ['collection_type', 'profile_ids', 'start_date', 'end_date']))

  const {
    data: geolocations, fetched: geoFetched, error: geoError, reFetch: reFetchGeo
  } = useFetchData('/api/v1/dashboards/trends/geolocations', queryParams)

  useDisplayFetchErrors([error], fetched, I18n.t('components.dashboards.multi_activity.errors.fetching_data'))
  useDisplayFetchErrors([geoError], geoFetched, I18n.t('components.dashboards.multi_activity.errors.fetching_geo'))
  useDisplayFetchErrors([acwrError], acwrFetched, I18n.t('components.dashboards.multi_activity.errors.fetching_acwr'))

  const types = [{ value: undefined, name: '' }].concat(props.categories.map(c => ({ value: c.name, name: c.label })))
  // Whether the type dropdown should be disabled
  const typesDisabled = types.length < MIN_TYPES
  const intensities = props.intensityMetrics || []

  // Filter the season activities according to the Slider range
  const currentSeasonActivitiesFiltered = _.slice(seasons?.current?.activities, rangeFilter?.[0], rangeFilter?.[1])
  const {
    outdoorCount,
    indoorCount,
    morningCount,
    afternoonCount,
    eveningCount
  } = getMultiActivityDashboardDescriptiveStatistics(currentSeasonActivitiesFiltered)

  const { selectedActivities, notSelectedActivities } = getCountSelectedActivities(seasons)

  const [trainingLoad, trainingIntensity, previousTrainingLoad] = useMemo(() => {
    const trainingLoadRecords = _.get(seasons, 'current.training_load.weekly')
    const previousTrainingLoadRecords = _.get(seasons, 'previous.training_load.weekly')
    const trainingIntensityRecords = _.get(seasons, 'current.training_intensity.weekly')

    const startDate = _.get(seasons, 'current.summary.start_date', start)
    const endDate = _.get(seasons, 'current.summary.end_date', end)

    const previousStart = _.get(seasons, 'previous.summary.start_date')
    const previousEnd = _.get(seasons, 'previous.summary.end_date')

    return [
      addEmptyRecords(trainingLoadRecords, startDate, endDate, periodType, LOAD_TYPES),
      addEmptyRecords(trainingIntensityRecords, startDate, endDate, periodType, INTENSITY_TYPES),
      addEmptyRecords(previousTrainingLoadRecords, previousStart, previousEnd, periodType, LOAD_TYPES)
    ]
  }, [seasons, periodType])

  const numRecords = trainingLoad?.length || trainingIntensity?.length || previousTrainingLoad?.length

  const seasonZones = normalizeHistogram(_.get(seasons, `current.training_intensity.season.${intensity}`, []))
  const zoneLabels = [...Array(seasonZones.length).keys()].map(z => `${I18n.t('components.dashboards.multi_activity.zone')} ${z}`)

  const profileList = useMemo(() => {
    if (!profiles) return []

    return profiles.map(profile => ({
      name: `${profile.first_name} ${profile.last_name}`,
      value: profile.id,
      icon: profile.picture
    }))
  }, [profiles])

  // set myProfile.Id as selectedProfile by default on page load
  // We need this useEffect because the default value of the state cannot be set
  // This is either due to the handleProfilesChange function overriding the default state value,
  // or the myProfile variable value not being available at the time of initialization.
  // https://gitlab.com/researchable/sport-data-valley/mvp/base-platform/-/merge_requests/2071
  useEffect(() => {
    if (props.myProfile?.id) {
      const myId = `${props.myProfile.id}`
      setSelectedProfiles([myId])
    }
  }, [props.myProfile])

  function handleStartChange (date) {
    setStart(date)
  }

  function handleEndChange (date) { setEnd(date) }

  function handlePeriodChange (e) {
    setTimeOfDay(e.target.value)
  }

  function handleTypeChange (e) {
    setType(e.target.value)
  }

  function handleLoadChange (e) {
    setLoad(e.target.value)
  }

  function handleIntensityChange (e) {
    setIntensity(e.target.value)
  }

  function handleProfilesChange (e) {
    const profileIds = [..._.get(e, 'target.selectedOptions', [])]
      .map(o => o.value || undefined)
      .filter(n => n)
    setSelectedProfiles(profileIds)
  }

  function handleApplyFilter () {
    reFetch(queryParams)
    reFetchGeo(queryParams)
    reFetchAcwr(queryParams)
    setRangeFilter(undefined)
    Slider.reset(sliderId)
  }

  function handleApplyDateFilter (newRange) {
    setRangeFilter(newRange.map((v) => parseInt(v)))
  }

  const ringsCommonOptions = {
    title: {
      display: true
    },
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    animation: {
      animateRotate: false
    }
  }

  const typeRing = {
    data: {
      datasets: [{
        data: [outdoorCount, indoorCount],
        backgroundColor: props.categories.map(c => c.color)
      }],
      labels: props.categories.map(c => c.label)
    },
    options: _.merge(_.cloneDeep(ringsCommonOptions), { title: { text: I18n.t('components.dashboards.multi_activity.type.label') } })
  }

  const timeRing = {
    data: {
      datasets: [{
        data: [morningCount, afternoonCount, eveningCount],
        backgroundColor: [greenLight, green, greenDark]
      }],
      labels: [I18n.t('components.dashboards.multi_activity.period.morning'), I18n.t('components.dashboards.multi_activity.period.afternoon'), I18n.t('components.dashboards.multi_activity.period.evening')]
    },
    options: _.merge(_.cloneDeep(ringsCommonOptions), { title: { text: I18n.t('components.dashboards.multi_activity.period.label') } })
  }

  function hrSeasonLabelCb (tooltipItem, data) {
    const idx = tooltipItem.index
    const value = data.datasets[0].data[idx]
    const label = data.labels[idx]
    return `${label}: ${value.toFixed(2)} %`
  }

  const intensityRing = {
    data: {
      datasets: [{
        data: seasonZones,
        backgroundColor: interpolateColors(accentColorDark, accentColorLight, seasonZones.length)
      }],
      labels: zoneLabels
    },
    options: _.merge(_.cloneDeep(ringsCommonOptions), {
      title: { text: I18n.t('components.dashboards.multi_activity.intensity.label') },
      tooltips: {
        callbacks: {
          label: hrSeasonLabelCb
        }
      }
    })
  }

  const ringConfigs = [timeRing, typeRing, intensityRing]
  const loads = LOAD_TYPES.map(int => ({ value: int, name: I18n.t(`components.dashboards.multi_activity.load.${int}`) }))
  const selectedRingConfig = [{
    data: {
      datasets: [{
        data: [selectedActivities, notSelectedActivities],
        backgroundColor: [hcBlack, hcLightGrey]
      }],
      labels: [I18n.t('components.dashboards.multi_activity.activities_ring_chart.selected'), I18n.t('components.dashboards.multi_activity.activities_ring_chart.not_selected')]
    },
    options: _.merge(_.cloneDeep(ringsCommonOptions), {
      title: { text: I18n.t('components.dashboards.multi_activity.activities_ring_chart.title') }
    })
  }]

  return (
    <div className=''>
      <div className='row'>
        <div className='col s12 text-xl text-primary-color text-heavy'>
          {props.title || ''}
        </div>
      </div>

      <div className='row'>
        <div className='col s3 input-field'>
          <DatePicker
            id='start' {...datePickerDefaultOptions()} selected={start} onChange={handleStartChange} selectsStart startDate={start} endDate={end}
          />
          <label htmlFor='start' className={classNames({ active: start })}>{I18n.t('components.dashboards.multi_activity.start_date')}</label>
        </div>
        <div className='col s3 input-field'>
          <DatePicker
            id='end' {...datePickerDefaultOptions()} selected={end} onChange={handleEndChange} selectsEnd startDate={start} endDate={end} minDate={start}
          />
          <label htmlFor='end' className={classNames({ active: end })}>{I18n.t('components.dashboards.multi_activity.end_date')}</label>
        </div>
        <div className='col s3'>
          <SelectDropdown id='period' content={timesOfDay} onChange={handlePeriodChange} defaultValue={timeOfDay} label={I18n.t('components.dashboards.multi_activity.period.label')} />
        </div>
        <div className='col s3'>
          <SelectDropdown id='type' disabled={typesDisabled} content={types} onChange={handleTypeChange} defaultValue={type} label={I18n.t('components.dashboards.multi_activity.type.label')} />
        </div>
      </div>

      <div className='row'>
        <div className='col s12'>
          <SelectDropdown
            id='select-profiles'
            isMultipleChoice
            content={profileList}
            value={selectedProfiles}
            onChange={handleProfilesChange}
            label={I18n.t('components.dashboards.multi_activity.advanced_filters.athletes')}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col s12'>
          <button
            id='submit-advanced-filter'
            className='button-primary background-primary button-autowidth waves-effect waves-light text-background-color'
            onClick={handleApplyFilter}
          >
            {I18n.t('components.dashboards.multi_activity.advanced_filters.submit')}
          </button>
        </div>
      </div>

      <div className='row'>
        <div className='col s12 l8'>
          <div className='row'>
            <div className='col s12'>
              <SpinnerWrapper ready={geoFetched} transparent={false}>
                <SDVMapView heatmap={geolocations} bw />
              </SpinnerWrapper>
            </div>
          </div>
        </div>
        <div className='col s12 l4'>
          <ChartContainer size='s12' componentStyle={{ mapHeight: true }}>
            <SpinnerWrapper ready={fetched} transparent={false}>
              <Rings ringConfigs={selectedRingConfig} height={180} translatedUp />
            </SpinnerWrapper>
          </ChartContainer>
        </div>
      </div>

      <div className='row'>
        <div className='col s12'>
          <Slider
            id={sliderId}
            range={[0, numRecords - 1 || 1]}
            start={[0, numRecords - 1 || 1]}
            onChange={handleApplyDateFilter}
            title={I18n.t('components.dashboards.multi_activity.weeks')}
            disableTooltips
          />
        </div>
      </div>

      <div className='row'>
        <div className='col s12 m6'>
          <div className='row'>
            <div className='col s12'>
              <SelectDropdown id='training-load' content={loads} onChange={handleLoadChange} defaultValue={load} label={I18n.t('components.dashboards.multi_activity.load.label')} />
            </div>
          </div>

          <ChartContainer size='s12'>
            <SpinnerWrapper ready={fetched} transparent={false}>
              <TrainingLoadChart trainingLoad={trainingLoad} loadType={load} categories={props.categories} range={rangeFilter} />
            </SpinnerWrapper>
          </ChartContainer>
          <ChartContainer size='s12'>
            <SpinnerWrapper ready={fetched} transparent={false}>
              <CumulativeTrainingLoadChart trainingLoad={trainingLoad} previousTrainingLoad={previousTrainingLoad} loadType={load} range={rangeFilter} />
            </SpinnerWrapper>
          </ChartContainer>
        </div>

        <div className='col s12 m6'>
          <div className='row'>
            <div className='col s12'>
              <SelectDropdown id='training-intensity' content={intensities} onChange={handleIntensityChange} defaultValue={intensity} label={I18n.t('components.dashboards.multi_activity.intensity.label')} />
            </div>
          </div>
          <ChartContainer size='s12'>
            <SpinnerWrapper ready={fetched} transparent={false}>
              <TrainingIntensityChart trainingIntensity={trainingIntensity} intensityType={intensity} zoneLabels={zoneLabels} range={rangeFilter} />
            </SpinnerWrapper>
          </ChartContainer>
          <ChartContainer size='s12' componentStyle={{ fixed: true }}>
            <SpinnerWrapper ready={fetched} transparent={false}>
              <Rings ringConfigs={ringConfigs} translatedUp />
            </SpinnerWrapper>
          </ChartContainer>
        </div>
      </div>

      <div className='row'>
        <div className='col s12'>
          <SeasonTable seasons={seasons} fields={props.seasonFields} unitConversionTable={props.seasonUnitsTable} />
        </div>
      </div>

      <ChartContainer size='s12'>
        <SpinnerWrapper ready={acwrFetched} transparent={false}>
          <ACWRChart acwr={acwr} />
        </SpinnerWrapper>
      </ChartContainer>
    </div>
  )
}

GenericMultiActivityDashboard.propTypes = {
  title: PropTypes.string,
  // The key of the fields to render in the season table.
  seasonFields: PropTypes.arrayOf(PropTypes.string),
  collectionType: PropTypes.string.isRequired,
  intensityMetrics: PropTypes.arrayOf(PropTypes.shape({
    // One of Constants.jsx timeSeries
    value: PropTypes.string,
    name: PropTypes.string
  })),
  seasonUnitsTable: PropTypes.object,
  categories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    color: PropTypes.string
  }))
}

export default WithSession(GenericMultiActivityDashboard)
