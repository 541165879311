import React from 'react'
import I18n from 'i18n'
import IntegrationIcon from '../../icons/integrations/IntegrationIcon'
import PropTypes from 'prop-types'
import { MYLAPS_THIALF_KEY } from 'components/common/Constants'

const IntegrationList = (props) => {
  if (props.available.length === 0) return <></>

  const handleOnRemoveFactory = (svc) => {
    return svc.key === MYLAPS_THIALF_KEY ? props.handleTransponderRemoval : () => props.handleServiceRemoval(svc)
  }

  const handleOnClickFactory = (svc) => {
    return svc.key === MYLAPS_THIALF_KEY ? props.handleAddTransponder : () => props.handleServiceClick(svc)
  }

  const myIntegrations = props.available.reduce((result, svc) => {
    if (svc.enabled === props.renderEnabled) {
      result.push(
        <IntegrationIcon
          key={svc.key}
          name={svc.key}
          icon={svc.key}
          iconUrl={svc.iconUrl}
          enabled={svc.enabled}
          active={props.active[svc.key]}
          onClick={handleOnClickFactory(svc)}
          onRemove={handleOnRemoveFactory(svc)}
          canRevoke={svc.canRevoke}
        />
      )
    }
    return result
  }, [])

  if (myIntegrations.length === 0) return <></>

  return (
    <div className='row'>
      <div className='col s12'>
        {!!props?.title &&
          <div className='text-primary-color text-s text-heavy'>
            {props.title}
          </div>}
        {!!props?.description &&
          <p>{props.description}</p>}
        <div className='data-sources-container'>
          {myIntegrations}
        </div>
      </div>
    </div>
  )
}

IntegrationList.propTypes = {
  available: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      iconUrl: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired,
      canRevoke: PropTypes.bool.isRequired
    })
  ),
  active: PropTypes.object.isRequired,
  handleServiceClick: PropTypes.func,
  renderEnabled: PropTypes.bool.isRequired
}

class IntegrationsForm extends React.Component {
  render () {
    return (
      <>
        <IntegrationList
          renderEnabled
          {...this.props}
        />
        {/* Should we keep the one below? */}
        <IntegrationList
          title={I18n.t('components.integrations.future.title')}
          description={I18n.t('components.integrations.future.description')}
          renderEnabled={false} {...this.props}
        />
      </>
    )
  }
}

IntegrationsForm.propTypes = {
  available: PropTypes.array.isRequired,
  active: PropTypes.object.isRequired,
  handleServiceClick: PropTypes.func,
  handleServiceRemoval: PropTypes.func,
  handleTransponderRemoval: PropTypes.func
}

IntegrationsForm.defaultProps = {
  available: [],
  active: {}
}

export default IntegrationsForm
