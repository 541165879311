import React from 'react'
import { HorizontalBar } from 'react-chartjs-2'
import { sum } from '../../../../common/Math'
import I18n from 'i18n'
import { identity } from '../../../../common/Units'

// NOTE: the only purpose of all of these lines of code is to be
// able to draw rectangles with rounded corners. Note that we override
// the Chart.elements.Rectangle.prototype.draw function, which is
// potentially an issue, as I believe that it affects any Chart
// JS instance drawn by the application. However, because the
// corner radius is a (new) optional parameter, not all rectangles
// are immediately drawn with rounded corners.
Chart.elements.Rectangle.prototype.draw = function () {
  const ctx = this._chart.ctx
  const vm = this._view
  let left, right, top, bottom, signX, signY, borderSkipped, radius
  let borderWidth = vm.borderWidth

  // If radius is less than 0 or is large enough to cause drawing errors a max
  //      radius is imposed. If cornerRadius is not defined set it to 0.
  let cornerRadius = this._chart.config.options.cornerRadius
  let fullCornerRadius = this._chart.config.options.fullCornerRadius
  const typeOfChart = this._chart.config.type

  if (cornerRadius < 0 || typeof cornerRadius === 'undefined') {
    cornerRadius = 0
  }
  if (typeof fullCornerRadius === 'undefined') {
    fullCornerRadius = true
  }

  if (!vm.horizontal) {
    // bar
    left = vm.x - vm.width / 2
    right = vm.x + vm.width / 2
    top = vm.y
    bottom = vm.base
    signX = 1
    signY = bottom > top ? 1 : -1
    borderSkipped = vm.borderSkipped || 'bottom'
  } else {
    // horizontal bar
    left = vm.base
    right = vm.x
    top = vm.y - vm.height / 2
    bottom = vm.y + vm.height / 2
    signX = right > left ? 1 : -1
    signY = 1
    borderSkipped = vm.borderSkipped || 'left'
  }

  // Canvas doesn't allow us to stroke inside the width so we can
  // adjust the sizes to fit if we're setting a stroke on the line
  if (borderWidth) {
    // borderWidth shold be less than bar width and bar height.
    const barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom))
    borderWidth = borderWidth > barSize ? barSize : borderWidth
    const halfStroke = borderWidth / 2
    // Adjust borderWidth when bar top position is near vm.base(zero).
    const borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0)
    const borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0)
    const borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0)
    const borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0)
    // not become a vertical line?
    if (borderLeft !== borderRight) {
      top = borderTop
      bottom = borderBottom
    }
    // not become a horizontal line?
    if (borderTop !== borderBottom) {
      left = borderLeft
      right = borderRight
    }
  }

  ctx.beginPath()
  ctx.fillStyle = vm.backgroundColor
  ctx.strokeStyle = vm.borderColor
  ctx.lineWidth = borderWidth

  // Corner points, from bottom-left to bottom-right clockwise
  // | 1 2 |
  // | 0 3 |
  const corners = [
    [left, bottom],
    [left, top],
    [right, top],
    [right, bottom]
  ]
  // Find first (starting) corner with fallback to 'bottom'
  const borders = ['bottom', 'left', 'top', 'right']
  let startCorner = borders.indexOf(borderSkipped, 0)
  if (startCorner === -1) {
    startCorner = 0
  }
  const cornerAt = index => {
    return corners[(startCorner + index) % 4]
  }
  // Draw rectangle from 'startCorner'
  const corner = cornerAt(0)
  ctx.moveTo(corner[0], corner[1])
  let width, height, x, y
  for (let i = 1; i < 4; i++) {
    /*
    corner = cornerAt(i)
    nextCornerId = i + 1
    if (nextCornerId === 4) {
      nextCornerId = 0
    }
    nextCorner = cornerAt(nextCornerId)
    */
    width = corners[2][0] - corners[1][0]
    height = corners[0][1] - corners[1][1]
    x = corners[1][0]
    y = corners[1][1]
    radius = cornerRadius
    // Fix radius being too large
    if (radius > Math.abs(height) / 2) {
      radius = Math.floor(Math.abs(height) / 2)
    }
    if (radius > Math.abs(width) / 2) {
      radius = Math.floor(Math.abs(width) / 2)
    }
    let xTl, xTr, yTl, yTr, xBl, xBr, yBl, yBr
    if (height < 0) {
      // Negative values in a standard bar chart
      xTl = x
      xTr = x + width
      yTl = y + height
      yTr = y + height
      xBl = x
      xBr = x + width
      yBl = y
      yBr = y
      // Draw
      ctx.moveTo(xBl + radius, yBl)
      ctx.lineTo(xBr - radius, yBr)
      // bottom right
      ctx.quadraticCurveTo(xBr, yBr, xBr, yBr - radius)
      ctx.lineTo(xTr, yTr + radius)
      // top right
      fullCornerRadius ? ctx.quadraticCurveTo(xTr, yTr, xTr - radius, yTr) : ctx.lineTo(xTr, yTr, xTr - radius, yTr)
      ctx.lineTo(xTl + radius, yTl)
      // top left
      fullCornerRadius ? ctx.quadraticCurveTo(xTl, yTl, xTl, yTl + radius) : ctx.lineTo(xTl, yTl, xTl, yTl + radius)
      ctx.lineTo(xBl, yBl - radius)
      //  bottom left
      ctx.quadraticCurveTo(xBl, yBl, xBl + radius, yBl)
    } else if (width < 0) {
      // Negative values in a horizontal bar chart
      xTl = x + width
      xTr = x
      yTl = y
      yTr = y
      xBl = x + width
      xBr = x
      yBl = y + height
      yBr = y + height
      // Draw
      ctx.moveTo(xBl + radius, yBl)
      ctx.lineTo(xBr - radius, yBr)
      //  Bottom right corner
      fullCornerRadius ? ctx.quadraticCurveTo(xBr, yBr, xBr, yBr - radius) : ctx.lineTo(xBr, yBr, xBr, yBr - radius)
      ctx.lineTo(xTr, yTr + radius)
      // top right Corner
      fullCornerRadius ? ctx.quadraticCurveTo(xTr, yTr, xTr - radius, yTr) : ctx.lineTo(xTr, yTr, xTr - radius, yTr)
      ctx.lineTo(xTl + radius, yTl)
      // top left corner
      ctx.quadraticCurveTo(xTl, yTl, xTl, yTl + radius)
      ctx.lineTo(xBl, yBl - radius)
      //  bttom left corner
      ctx.quadraticCurveTo(xBl, yBl, xBl + radius, yBl)
    } else {
      /*
      let lastVisible = 0
      for (let findLast = 0, findLastTo = this._chart.data.datasets.length; findLast < findLastTo; findLast++) {
        if (!this._chart.getDatasetMeta(findLast).hidden) {
          lastVisible = findLast
        }
      }
      */
      const rounded = true // this._datasetIndex === lastVisible;
      if (rounded) {
        // Positive Value
        ctx.moveTo(x + radius, y)
        ctx.lineTo(x + width - radius, y)
        // top right
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
        ctx.lineTo(x + width, y + height - radius)
        // bottom right
        if (fullCornerRadius || typeOfChart === 'horizontalBar') {
          ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
        } else {
          ctx.lineTo(x + width, y + height, x + width - radius, y + height)
        }
        ctx.lineTo(x + radius, y + height)
        // bottom left
        if (fullCornerRadius) {
          ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
        } else {
          ctx.lineTo(x, y + height, x, y + height - radius)
        }
        ctx.lineTo(x, y + radius)
        // top left
        if (fullCornerRadius || typeOfChart === 'bar') {
          ctx.quadraticCurveTo(x, y, x + radius, y)
        } else {
          ctx.lineTo(x, y, x + radius, y)
        }
      } else {
        ctx.moveTo(x, y)
        ctx.lineTo(x + width, y)
        ctx.lineTo(x + width, y + height)
        ctx.lineTo(x, y + height)
        ctx.lineTo(x, y)
      }
    }
  }
  ctx.fill()
  if (borderWidth) {
    ctx.stroke()
  }
}

const ActivitiesBar = props => {
  const total = props.total
  const options = {
    elements: {
      point: {
        radius: 25,
        hoverRadius: 35,
        pointStyle: 'rectRounded'
      }
    },
    cornerRadius: 5,
    fullCornerRadius: true,
    tooltips: {
      // Disable the on-canvas tooltip
      enabled: false,
      callbacks: props.tooltipCallbacks,
      custom: function (tooltipModel) {
        // Tooltip Element
        let tooltipEl = document.getElementById('chartjs-tooltip')

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div')
          tooltipEl.id = 'chartjs-tooltip'
          tooltipEl.innerHTML = '<table></table>'
          tooltipEl.style.backgroundColor = '#FFFFFF'
          tooltipEl.style.borderColor = '#000000'
          tooltipEl.style.borderWidth = 'thin'
          tooltipEl.style.borderStyle = 'solid'
          document.body.appendChild(tooltipEl)
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = '0'
          return
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform')
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign)
        } else {
          tooltipEl.classList.add('no-transform')
        }

        // Set Text
        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || []
          const bodyLines = tooltipModel.body.map(item => item.lines)

          let innerHtml = '<thead>'

          titleLines.forEach(function (title) {
            innerHtml += '<tr><th>' + title + '</th></tr>'
          })
          innerHtml += '</thead><tbody>'

          bodyLines.forEach(function (body, i) {
            const colors = tooltipModel.labelColors[i]
            let style = 'background:' + colors.backgroundColor
            style += '; border-color:' + colors.borderColor
            style += '; border-width: 2px'
            const span = '<span style="' + style + '"></span>'
            innerHtml += '<tr><td>' + span + body + '</td></tr>'
          })
          innerHtml += '</tbody>'

          const tableRoot = tooltipEl.querySelector('table')
          tableRoot.innerHTML = innerHtml
        }

        // `this` will be the overall tooltip
        const position = this._chart.canvas.getBoundingClientRect()

        // Display, position, and set styles for font
        tooltipEl.style.opacity = '1'
        tooltipEl.style.position = 'absolute'
        tooltipEl.style.left = position.left + window.pageXOffset + 'px'
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px'
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px'
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
        tooltipEl.style.pointerEvents = 'none'
      }
    },
    scales: {
      xAxes: [{
        display: true,
        stacked: true,
        gridLines: {
          color: props.showAxes ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
          zeroLineColor: props.showAxes ? 'rgba(0, 0, 0, 0.25)' : 'transparent'
        },
        ticks: {
          beginAtZero: true,
          suggestedMax: total,
          fontColor: props.showAxes ? '#666' : 'transparent',
          callback: props.xTickCallback || identity
        }
      }],
      yAxes: [{
        display: false
      }]
    },
    legend: {
      display: false
    }
  }

  const datasets = []
  for (let idx = 0; idx < props.values.length; idx++) {
    datasets.push(
      {
        label: props.labels[idx],
        fill: false,
        backgroundColor: props.backgroundColors[idx],
        borderSkipped: idx === 0 ? false : 'left',
        borderColor: props.borderColors[idx],
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        stack: props.id,
        data: [props.values[idx]],
        borderWidth: 1
      }
    )
  }
  const data = {
    labels: [props.title],
    datasets: datasets
  }

  const notEnoughData = (sum(props.values) === 0)

  return (
    <div className='row'>
      <div className='col s1 horizontal-bar-icon'>
        {props.children}
      </div>
      <div className='col s11 contains-horizontal-bar'>
        {notEnoughData && <em className='insufficient-data'>{I18n.t('components.dashboards.steps.summary.not_enough_data')}</em>}
        {!notEnoughData && <HorizontalBar height={15} width={100} data={data} options={options} redraw />}
      </div>
    </div>
  )
}

export default ActivitiesBar
