import React from 'react'
import DashboardPicker from '../common/DashboardPicker'
import Routes from '../../../common/Routes'

const RidingDashboardPicker = (props) => {
  const dashboards = [
    {
      id: 1,
      name: 'components.dashboards.riding.sub_dashboards.single',
      icon: 'biking',
      enabled: true,
      abbrevName: 'riding',
      cssKlass: 'dashboard-card-questionnaire',
      implemented: true,
      link: Routes.analysis.cycling.single.index
    }, {
      id: 2,
      name: 'components.dashboards.riding.sub_dashboards.trends',
      icon: 'calendar-week',
      enabled: true,
      abbrevName: 'questionnaire',
      cssKlass: 'dashboard-card-questionnaire',
      implemented: true,
      link: Routes.analysis.cycling.trends
    }
  ]

  return (
    <DashboardPicker dashboards={dashboards} {...props} />
  )
}

export default RidingDashboardPicker
