import React from 'react'
import BackButton from '../../common/BackButton'
import MetaData from '../MetaData'

const BulkMetaData = (props, selectedVdoIds, toCancelFilteredData, toSuccessFilteredData) => {
  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <BackButton onGoBack={toCancelFilteredData}>Back</BackButton>
        </div>
      </div>
      <MetaData
        {...props} vdoId={selectedVdoIds} isBulkUpdate handleSuccess={toSuccessFilteredData}
        handleCancel={toCancelFilteredData}
      />
    </>
  )
}

export default BulkMetaData
