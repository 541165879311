import React, { memo, forwardRef, useState, useRef, useEffect, useImperativeHandle } from 'react'
import I18n from 'i18n'
import { ICellEditorParams } from 'ag-grid-community/dist/lib/interfaces/iCellEditor'

// NOTE: Double clicking a cell with this editor gives a "[object Object]: `key` is not a prop"
// warning message in the console. This is because key is a property in ICellEditorParams,
// which is part of Ag-Grid and so we cannot change it.
const NumericEditor = memo(
  forwardRef((props: ICellEditorParams & { min: number, max: number, showRpeExplanation: boolean }, ref) => {
    const createInitialState = (): InitialState => {
      let startValue
      let highlightAllOnFocus = true

      if (props.key === KEY_BACKSPACE || props.key === KEY_DELETE) {
        // if backspace or delete pressed, we clear the cell
        startValue = ''
      } else if (props.charPress !== null) {
        // if a letter was pressed, we start with the letter
        startValue = props.charPress
        highlightAllOnFocus = false
      } else {
        // otherwise we start with the current value
        startValue = props.value
        if (props.key === KEY_F2) {
          highlightAllOnFocus = false
        }
      }

      return {
        value: startValue,
        highlightAllOnFocus
      }
    }

    const initialState = createInitialState()
    const [value, setValue] = useState(initialState.value)
    const [highlightAllOnFocus, setHighlightAllOnFocus] = useState(
      initialState.highlightAllOnFocus
    )
    const refInput = useRef(null)

    // focus on the input. We don't depend on highlightAllOnFocus here because
    // we only want to run this when loading the component for the first time
    // (not when changing the value of highlightAllOnFocus).
    useEffect(() => {
      if (refInput.current === null) return
      // get ref from React component
      const eInput: HTMLInputElement = refInput.current

      eInput.focus()
      if (highlightAllOnFocus) {
        eInput.select()

        setHighlightAllOnFocus(false)
      } else {
        // when we started editing, we want the caret at the end, not the start.
        // this comes into play in two scenarios:
        //   a) when user hits F2
        //   b) when user hits a printable character
        const length = eInput.value !== null ? eInput.value.length : 0
        if (length > 0) {
          eInput.setSelectionRange(length, length)
        }
      }
    }, [])

    /* Utility Methods */
    const cancelBeforeStart =
      props.charPress !== null && !'1234567890'.includes(props.charPress)

    const isLeftOrRight = (event: React.KeyboardEvent<HTMLInputElement>): boolean => {
      return ['ArrowLeft', 'ArrowLeft'].includes(event.key)
    }

    const isCharNumeric = (charStr: string): boolean => {
      return /\d/.test(charStr)
    }

    const isKeyPressedNumeric = (event: React.KeyboardEvent<HTMLInputElement>): boolean => {
      const charStr = event.key
      return isCharNumeric(charStr)
    }

    const deleteOrBackspace = (event: React.KeyboardEvent<HTMLInputElement>): boolean => {
      return [KEY_DELETE, KEY_BACKSPACE].includes(event.key)
    }

    const finishedEditingPressed = (event: React.KeyboardEvent<HTMLInputElement>): boolean => {
      const key = event.key
      return key === KEY_ENTER || key === KEY_TAB
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (isLeftOrRight(event) || deleteOrBackspace(event)) {
        event.stopPropagation()
        return
      }

      if (!finishedEditingPressed(event) && !isKeyPressedNumeric(event)) {
        if (event.preventDefault !== null) event.preventDefault()
      }

      if (finishedEditingPressed(event)) {
        props.stopEditing()
      }
    }

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue () {
          return value
        },

        // Gets called once before editing starts, to give editor a chance to
        // cancel the editing before it even starts.
        isCancelBeforeStart () {
          return cancelBeforeStart
        },

        // Gets called once when editing is finished (eg if Enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        isCancelAfterEnd () {
          // will reject the number if it greater than 1,000,000
          // not very practical, but demonstrates the method.
          return (props.min !== undefined && value < props.min) || (props.max !== undefined && value > props.max) || value > 1000000
        }
      }
    })

    return (
      <div style={{ width: props.showRpeExplanation ? '150px' : 'initial' }}>
        {props.showRpeExplanation && (
          <div className='col s12 text-muted'>
            {I18n.t('components.trainings.rpe.info')}&nbsp;
            <a
              target='_blank' rel='noopener noreferrer'
              href='https://www.researchgate.net/profile/Roger-Eston/publication/225064358_Use_of_Ratings_of_Perceived_Exertion_in_Sports/links/00b7d51a5a879a3979000000/Use-of-Ratings-of-Perceived-Exertion-in-Sports.pdf'
            >
              {I18n.t('components.trainings.rpe.here')}
            </a>
          </div>
        )}
        <input
          ref={refInput}
          value={value}
          onChange={(event) => setValue(event.target.value)}
          onKeyDown={(event) => onKeyDown(event)}
          style={{ width: '100%', paddingLeft: '10%' }}
        />
      </div>
    )
  })
)

interface InitialState {
  value: any
  highlightAllOnFocus: boolean
}

const KEY_BACKSPACE = 'Backspace'
const KEY_DELETE = 'Delete'
const KEY_F2 = 'F2'
const KEY_ENTER = 'Enter'
const KEY_TAB = 'Tab'

export default NumericEditor
