import React from 'react'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class EditOauthApplications extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      renderCreateForm: false,
      description: ''
    }
  }

  componentDidMount () {
    M.updateTextFields()
  }

  render () {
    return (
      <>
        <div className='row'>
          <div className='col s12'>
            <div className='text-heavy text-l text-muted data-header'>
              {I18n.t('components.oauth.title')}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col s12'>
            {this.props.oauthApplications.length === 0 && (
              <p className='text-muted no-margin'>
                <em>
                  {I18n.t('components.oauth.none')}
                </em>
              </p>
            )}
            {
              this.props.oauthApplications.map(oauthApplication => (
                <div className='row' key={`oauth-${0}`}>
                  <div className='col s3 text-heavy'>{I18n.t('components.oauth.application')}</div>
                  <div className='col s7'>{oauthApplication.name}</div>
                  <div className='col s2 text-accent-color'>
                    <FontAwesomeIcon
                      className='pointer-grow'
                      onClick={this.props.destroyOauthApplication.bind(this, oauthApplication.client_id)}
                      size='2x'
                      icon='times'
                    />
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </>
    )
  }
}
export default EditOauthApplications
