import React from 'react'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { generateUuid } from '../../common/Utils'

import { DASHBOARD_NAMES, subscriptionTypes } from '../../common/Constants'
import PremiumOverlay from '../../premium_membership/PremiumOverlay'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { BetaRibbon } from '../../atomic/atoms/BetaRibbon'

const rolesForDashboardCard = (name) => {
  switch (name) {
    case DASHBOARD_NAMES.QUESTIONNAIRE_FULL:
    case DASHBOARD_NAMES.QUESTIONNAIRE_MULTI_ATHLETE:
    case DASHBOARD_NAMES.COACH:
      return [subscriptionTypes.coach, subscriptionTypes.researcher]
    case DASHBOARD_NAMES.RESEARCH_TOOL:
    case DASHBOARD_NAMES.CSV:
      return [subscriptionTypes.researcher]
    default:
      return [subscriptionTypes.free, subscriptionTypes.coach, subscriptionTypes.researcher]
  }
}

const DashboardCard = (props) => {
  const dashboardTile = (
    <>
      <BetaRibbon hidden={!props.beta} />
      <div className='center-align text-xl text-primary-color text-heavy dashboard-card-title'>
        {I18n.t(props.name)}
      </div>
      <div className='valign-wrapper text-primary-color center-align dashboard-card-icon-wrapper'>
        <div className='dashboard-card-icon'>
          <FontAwesomeIcon icon={props.icon} />
        </div>
      </div>
    </>
  )

  // Note that the props.name ID is only added for capybara specs here
  const id = props.name ? `${props.name.replace('.', '-')}-card` : generateUuid()
  const isPremium = [
    DASHBOARD_NAMES.QUESTIONNAIRE_FULL,
    DASHBOARD_NAMES.QUESTIONNAIRE_FULL_OPT,
    DASHBOARD_NAMES.QUESTIONNAIRE_MULTI_ATHLETE,
    DASHBOARD_NAMES.RESEARCH_TOOL,
    DASHBOARD_NAMES.CSV,
    DASHBOARD_NAMES.COACH
  ].includes(props.name)
  const activeForRoles = rolesForDashboardCard(props.name)
  return (
    <div id={id} className={classNames('col s8 offset-s2 m6 l4 xl3', props.cssKlass)}>
      <div className='dashboard-card-wrapper'>
        <PremiumOverlay isPremium={isPremium} activeFor={activeForRoles}>
          <div className={classNames((!props.implemented || !props.enabled) && 'grayed-out')}>
            {!props.implemented && (
              <div className='dashboard-card' data-tip={I18n.t('components.dashboards.not_implemented_yet')} data-for='higher-z-tooltip'>{dashboardTile}</div>
            )}

            {props.implemented && !props.enabled && (
              <div className='dashboard-card' data-tip={props.disabledTooltip} data-for='higher-z-tooltip'>
                {dashboardTile}
              </div>
            )}

            {props.implemented && props.enabled && props.link && props.link.startsWith('http') && (
              <a href={props.link} target='_blank' rel='noopener noreferrer' className='dashboard-card-enabled' data-tip={props.extraInfoTooltip && props.extraInfoTooltip} data-for='higher-z-tooltip'>{dashboardTile}</a>
            )}

            {props.implemented && props.enabled && props.link && !props.link.startsWith('http') && (
              <Link
                className='dashboard-card-enabled' to={{
                  pathname: props.link,
                  state: {
                    dashboards: props.dashboards
                  }

                }} data-tip={props.extraInfoTooltip && props.extraInfoTooltip} data-for='higher-z-tooltip'
              >
                {dashboardTile}
              </Link>
            )}
          </div>
        </PremiumOverlay>
      </div>
    </div>
  )
}

DashboardCard.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  enabled: PropTypes.bool.isRequired,
  abbrevName: PropTypes.string.isRequired,
  cssKlass: PropTypes.string.isRequired,
  implemented: PropTypes.bool.isRequired,
  disabledTooltip: PropTypes.string
}

export default DashboardCard
