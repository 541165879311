import _ from 'lodash'
import numeric from 'numeric'
import moment from 'moment'
import { imputation } from './Constants'

export function round (number, decimalPlaces = 0) {
  return Math.round(number * 10 ** decimalPlaces) / 10 ** decimalPlaces
}

export function smartRounding (value, decimalPlaces = 0) {
  const smartDecimalPlaces = (value % 1 !== 0 || decimalPlaces < 1) ? decimalPlaces : decimalPlaces - 1
  return round(value, smartDecimalPlaces)
}

export function unCorrectedStandardDeviation (values) {
  const avg = average(values)

  const squareDiffs = values.map(function (value) {
    const diff = value - avg
    const sqrDiff = diff * diff
    return sqrDiff
  })

  const avgSquareDiff = average(squareDiffs)

  const stdDev = Math.sqrt(avgSquareDiff)
  return stdDev
}

export function standardDeviation (numbersArr) {
  let total = 0
  for (const key in numbersArr) { total += numbersArr[key] }
  const meanVal = total / numbersArr.length
  let SDprep = 0
  for (const key2 in numbersArr) { SDprep += Math.pow((parseFloat(numbersArr[key2]) - meanVal), 2) }
  const SDresult = Math.sqrt(SDprep / (numbersArr.length - 1))
  return SDresult
}

export function average (data) {
  const sum = data.reduce(function (sum, value) {
    return sum + value
  }, 0)

  const avg = sum / data.length
  return avg
}

export const movingAverageKernel = (windowSize) => {
  return function (v, index, array) {
    return _.slice(array, Math.max(0, index + 1 - windowSize), index + 1)
  }
}

export const movingAverage = (array, windowSize) => {
  return _.chain(array)
    .map(movingAverageKernel(windowSize))
    .map(_.mean)
    .value()
}

export function bin (array, binSize) {
  const totalBins = Math.floor(array.length / binSize)
  const bins = []
  for (let iBin = 0; iBin < totalBins; iBin++) {
    const bin = _.slice(array, iBin * binSize, (iBin + 1) * binSize)
    bins.push(bin)
  }

  // Add remaining elements to last bin
  if (array.length % binSize !== 0) {
    const remainingElements = _.slice(array, totalBins * binSize)
    bins[totalBins - 1].push(...remainingElements)
  }

  return bins
}

export function binnedAverage (array, binSize) {
  const bins = bin(array, binSize)
  return bins.map((bin) => { return (average(bin)) })
}

export function sum (arr) {
  return arr.reduce((a, b) => a + b, 0)
}

export function unique (arr) {
  return arr.filter((v, i, s) => s.indexOf(v) === i)
}

// We use isoWeeks everywhere now because I found that when using regular week, the week number
// was off by one to what a google search for week number or a lookup on any calendar would give you
// as the actual week number. So I changed the entire codebase to use isoWeek instead and that
// fixes all the one-off inconsistencies I found.
export function getWeekNumber (d) {
  return [moment(d).isoWeekYear(), moment(d).isoWeek()]
}

/* Divides an array (data) into zones according to the limits (limits) and returns the number of counts per zone.
 * Limits is an array of floats determining the bounds of each zone. The first value is the lower bound of the first
 * zone. The last value is the higher bound of the last zone. E.g. for a 3 zone division between 60% and 100% limits
 * could look like:
 * [60, 70, 80, 100]
 * Lower bounds are inclusive and upper bounds exclusive.
 */
export function getZoneCount (limits, data) {
  const zoneCount = []
  for (let idx = 1; idx < limits.length; idx += 1) {
    zoneCount.push(data.filter(d => d >= limits[idx - 1] && d < limits[idx]).length)
  }
  return zoneCount
}

export function getCumulativeDistribution (distributions) {
  const cumulative = [0]
  distributions.forEach(v => {
    cumulative.push(cumulative[cumulative.length - 2] + v)
  })
  cumulative.shift()
  return cumulative
}

// Gets an array were each element represents the counts of a bin an normalizes the counts such that they add up to 100%
export function normalizeHistogram (histogram) {
  const totalSamples = _.sum(histogram) || 1
  return histogram.map(h => h / totalSamples * 100)
}

export function polynomialRegression (x, y) {
  const order = 3

  const xMatrix = []
  const yMatrix = numeric.transpose([y])

  for (let j = 0; j < x.length; j++) {
    const xTemp = []
    for (let i = 0; i <= order; i++) {
      xTemp.push(Math.pow(x[j], i))
    }
    xMatrix.push(xTemp)
  }

  const xMatrixT = numeric.transpose(xMatrix)
  const dot1 = numeric.dot(xMatrixT, xMatrix)
  const dotInv = numeric.inv(dot1)
  const dot2 = numeric.dot(xMatrixT, yMatrix)
  const solution = numeric.dot(dotInv, dot2)
  return solution.map((coeff) => coeff[0])
}

/**
 * Perform missing value imputation on a scalar or vectorial array (matrix). Assumes null to represent missing values.
 * @param arr The array to do missing value imputation on
 * @param method The method to use for the imputation. Must be one of: ['constant', 'linear-interpolation', 'mean'].
 * Defaults to 'constant'
 * @param constant The constant value to use for the constant imputation method. Defaults to 0
 * @param missingValue An array of numbers or nulls to be used for imputation
 * @param interpolateBoundaries Only applies to interpolation. If set to true, it will interpolate all values (even
 * missing head and tail values). If set to false, it will only interpolate from the first non-missing to the last non-
 * missing value.
 * @return arr with the imputed values
 */
export function missingValueImputation (arr, method = imputation.CONSTANT, constant = 0, missingValue = [null], interpolateBoundaries = true) {
  if (arr === undefined) return arr
  switch (method) {
    case imputation.CONSTANT:
      return constantValueImputation(arr, constant)
    case imputation.LINEAR_INTERPOLATION:
      return linearInterpolationValueImputation(arr, interpolateBoundaries)
    case imputation.MEAN:
      return meanValueImputation(arr, missingValue)
    default:
      throw new Error(`method must be one of ${imputation.keys} but ${method} was received`)
  }
}

function constantValueImputation (arr, constant) {
  return arr.map(el => el === null ? constant : el)
}

function meanValueImputation (arr, missingValue) {
  const origArrFiltered = arr.filter(val => (missingValue.indexOf(val) === -1))
  if (origArrFiltered.length !== arr.length) {
    const origArrMean = origArrFiltered.reduce((a, b) => a + b, 0) / origArrFiltered.length
    arr = arr.map(record => (missingValue.indexOf(record) !== -1) ? +origArrMean.toFixed(2) : record)
  }
  return arr
}

function linearInterpolationValueImputation (arr, interpolateBoundaries = true) {
  let previous = null
  // Distance to next non-null value
  let distance = 0
  // Next non-null value. Undefined means not yet searched. Null means not found (no more non-null in the array)
  let next, imputed
  const setNext = (idx) => {
    if (distance < 1) {
      next = _.find(arr, el => { distance += 1; return el !== null }, idx + 1)
      next = next === undefined ? null : next
    }
  }
  return arr.map((el, idx) => {
    if (!interpolateBoundaries && previous === null && el === null) {
      return null
    }
    if (el !== null) {
      previous = el
      setNext(idx)
      distance -= 1
      return el
    }
    // If the array has no non-null values throw error
    if (next === null && previous === null) throw new Error('Array must have at least one non-null value')
    // This is an optimization. If next is null there are no more non-null values in the array. Exit early
    if (next === null) {
      return interpolateBoundaries ? previous : null
    }
    setNext(idx)
    if (next === null) {
      imputed = previous
    } else if (previous === null) {
      imputed = next
    } else {
      imputed = linearInterpolation(previous, next, distance + 1); previous = imputed
    }
    distance -= 1
    return imputed
  })
}

/**
 * Returns the value that lies in a straight line between 'from' & 'to' 1/steps the distance between from & to
 * away from 'from'
 * @param from Starting value of the interpolation
 * @param to Finishing value of the interpolation
 * @param steps Number of steps to interpolate in. Defaults to 2 (midpoint)
 */
export function linearInterpolation (from, to, steps = 2) {
  if (!Array.isArray(from) && !Array.isArray(to)) return scalarLinearInterpolation(from, to, steps)
  if (from.length !== to.length) throw new Error('from and to must have the same dimensionality')
  return from.map((f, idx) => scalarLinearInterpolation(f, to[idx], steps))
}

function scalarLinearInterpolation (from, to, steps) {
  return (to - from) / steps + from
}

/* Computes the Nth quartile of the given data
 * params:
 *    data: a list of length n
 *    N:    compute the Nth quartile (0 <= N <= 4)
 **/
export function computeQuartile (data, N) {
  if (N < 0 || N > 4) {
    throw new Error(`Cannoth compute ${N}th quartile: only values between 0 and 4 are valid`)
  }

  const size = data.length
  const realIdx = N / 4 * (size - 1)
  const minIdx = Math.floor(realIdx)
  const diff = realIdx - minIdx

  if (data[minIdx + 1] === undefined) {
    /* There is no next element, so we cannot compute a weighted average */
    return data[minIdx]
  } else {
    /* Return the weighted average of the current element and the next */
    return data[minIdx] + diff * (data[minIdx + 1] - data[minIdx])
  }
}

// Circular statistics
// See https://en.wikipedia.org/wiki/Directional_statistics#Distribution_of_the_mean
export const rCircular = (arr) => {
  let total = 0
  let sCirc = 0.0
  let cCirc = 0.0
  for (const val of arr) {
    if (!val || val === 0) continue

    total += 1
    sCirc += Math.sin(val)
    cCirc += Math.cos(val)
  }
  if (!total || total === 1) return NaN
  return Math.sqrt(Math.pow(sCirc, 2) + Math.pow(cCirc, 2)) / total
}

// See https://en.wikipedia.org/wiki/Directional_statistics#Measures_of_location_and_spread
// We follow the definition of Var(z) for circular variance, assuming population means.
export const circularVariance = (arr) => {
  return 1 - rCircular(arr)
}

export const circularStandardDeviation = (arr) => {
  return Math.sqrt(circularVariance(arr))
}

export const timeToRad = (val) => 2 * Math.PI * (val % 86400) / 86400.0
export const radToTime = (val) => (86400 + (86400.0 * (val / (2 * Math.PI)))) % 86400
export const nanToZero = num => {
  return Number.isNaN(num) ? 0 : num
}
