import React from 'react'
import I18n from 'i18n'
import { validateAction } from './ConfirmationDialogUtils'
import { ActionType } from 'components/common/types/ConfirmationDialogAction'

// This can be extended to other actions (like edit, leave group ...)
// If you update here you should also update the Action in the ConfirmationDialogAction file
const validActions = Object.freeze(['delete', 'leave', 'reject_connection', 'reject_group', 'reject_transfer_request', 'create_transfer_request', 'delete_connection',
  'delete_group', 'stop_protocol', 'change_end_date', 'skip_optional_consent'])

// Normally, the ConfirmationDialog will render a message such as:
// "Wilt u deze 'geselecteerde bestanden' verwijderen"
// I.e., with single quotes around the specified target string.
// If those quotes do not make sense, specify the noQuotes: true property to this component to leave them out.
class ConfirmationDialog extends React.Component {
  onConfirmation () {
    this.props.onConfirmation()
    this.props.onClose()
  }

  render () {
    const actionBefore = validateAction(this.props.action, ActionType.action_before, validActions)
    const actionAfter = validateAction(this.props.action, ActionType.action_after, validActions)
    let targetString = `'${this.props.target}'`
    if (this.props.noQuotes) {
      targetString = `${this.props.target}`
    }
    return (
      <div id='confirmation-dialog' className='custom-modal-wrapper'>
        <div className='confirmation-dialog'>
          <div className='confirmation-dialog-title text-xl text-primary-color text-heavy text-center'>
            {`${I18n.t('components.confirmation_dialog.title')} ${actionBefore} ${targetString} ${actionAfter}?`}
          </div>
          <div className='confirmation-dialog-buttons-wrapper'>
            <button
              id='confirmation-dialog-confirm'
              className='waves-effect waves-light button-primary button-autowidth background-primary text-background-color'
              onClick={this.onConfirmation.bind(this)}
              style={{ minWidth: '150px' }}
            >
              {I18n.t('components.confirmation_dialog.confirm')}
            </button>
            <button
              id='confirmation-dialog-cancel'
              className='waves-effect waves-light button-primary button-autowidth background-background text-primary-color'
              onClick={this.props.onClose}
              style={{ minWidth: '150px' }}
            >
              {I18n.t('components.confirmation_dialog.cancel')}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default ConfirmationDialog
