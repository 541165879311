import { useMutation } from 'react-query'
import { BackendContext } from './BackendProvider'
import { useContext } from 'react'
import PhysiologicalAttributeDTO from 'components/common/types/DTOs/PhysiologicalAttributeDTO'
import Notebook from 'components/common/types/Notebook'
import { UseMutationResult } from 'react-query/types/react/types'

export const useMyPhysiologicalAttributesMutation = (): UseMutationResult<unknown, unknown, PhysiologicalAttributeDTO> => {
  const { backend } = useContext(BackendContext)
  return useMutation((attributes: PhysiologicalAttributeDTO) => backend.physiologicalAttributes.update(attributes))
}

export const useRunNotebookMutation = (): UseMutationResult<unknown, unknown, Notebook > => {
  const { backend } = useContext(BackendContext)
  return useMutation((attributes: Notebook) => backend.notebook.run(attributes))
}
