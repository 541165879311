import React from 'react'
import I18n from 'i18n'
import classNames from 'classnames'
import DatePicker from 'react-datepicker'

import _ from 'lodash'

import TableInteractive from './TableInteractive'
import { WithBackend } from '../../../backend/BackendProvider'
import { COACH_DASHBOARD_HOME_CATEGORIES, datePickerDefaultOptions } from '../../../common/Constants'
import { createAthleteRowEntry } from './CoachDashboardHelper'
import Toggle from '../../../atomic/atoms/Toggle.tsx'
import { MobileTable } from 'components/analysis/dashboards/coach_individual/MobileTable'

const CoachHomeTab = (props) => {
  const { athletesEntryData, titles, day, onDayChange, onAthleteClick, excludeAdmins, setExcludeAdmins } = props

  // returns sorted array
  // the more properties inside 'datasets' property that are not undefined or null,
  // the higher in the array the athlete is sorted.
  // meaning the more data (for the table) an athlete has, the higher in the list they will appear.
  function sortAthletesObject (athletes) {
    return _.sortBy(athletes, function (athlete) {
      return _.filter(Object.values(athlete.datasets), val => val !== undefined && val !== null).length
    }).reverse()
  }

  const sortedAthletesArray = sortAthletesObject(athletesEntryData)
  // Make sure array order is retained in object by making array index id (instead of athleteId).
  const sortedAthletesObject = _.fromPairs(_.map(sortedAthletesArray, (athlete, index) => [index, athlete]))

  const athleteEntries = _.mapValues(sortedAthletesObject, (entryData) => {
    const { datasets, profile } = entryData
    return createAthleteRowEntry(
      profile,
      datasets,
      onAthleteClick
    )
  })

  return (
    <>
      <div className='row'>
        <div className='col s12 input-field'>
          <DatePicker
            id='home-tab-date-picker' {...datePickerDefaultOptions()} selected={day} onChange={onDayChange}
          />
          <label htmlFor='home-tab-date-picker' className={classNames({ active: day })}>{I18n.t('components.dashboards.coach_individual.home.select_day')}</label>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <Toggle id='exclude-admins-toggle' title={I18n.t('components.dashboards.coach_individual.home.toggle')} onChange={setExcludeAdmins} isChecked={excludeAdmins} />
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='titlebar'>{
            excludeAdmins
              ? I18n.t('components.dashboards.coach_individual.home.banner.athletes')
              : I18n.t('components.dashboards.coach_individual.home.banner.members')
          }
          </div>
          {/* The two tables below are used interchangeably depending on screen size */}
          <TableInteractive
            categories={COACH_DASHBOARD_HOME_CATEGORIES}
            titles={titles}
            entries={athleteEntries}
            id='home-table'
            className='hide-on-med-and-down'
          />
          <MobileTable titles={titles} entries={athleteEntries} />
        </div>
      </div>
    </>
  )
}

export default WithBackend(CoachHomeTab)
