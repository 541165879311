import React from 'react'
import PropTypes from 'prop-types'
import OrderedBarGraph from '../../../common/OrderedBarGraph'
import I18n from 'i18n'
import _ from 'lodash'
import SelectDropdown from '../../../common/SelectDropdown'
import { soccerBarGraphMetrics, soccerMetrics, soccerTeamColor, soccerBothTeamsIndex, soccerDecimalRounding } from '../../../common/Constants'

export class SoccerBarGraph extends React.Component {
  constructor (props) {
    super(props)

    this.ids = {
      metricsDropdown: 'metrics-dropdown',
      teamsDropdown: 'teams-dropdown',
      ascendingDescendingDropdown: 'ascending-descending-dropdown'
    }
    this.metrics = soccerBarGraphMetrics

    let startMetric = ''
    let startData = []
    const players = props.players || []
    if (players.length > 0) {
      startMetric = this.metrics[0]
      startData = this.getBarsData(players, this.metrics[0])
    }
    this.state = {
      metric: startMetric,
      data: startData,
      ordering: 'descending',
      team: 2
    }
  }

  filterDataByTeam (barsData, team) {
    return _.filter(barsData, function (datum) { return datum.team === team })
  }

  getBarsData (playersData, metric) {
    const path = metric ? soccerMetrics[metric].path : null
    const barsData = _.map(
      playersData,
      function (player) {
        return {
          label: player.name,
          value: _.round(_.at(player, path)[0], soccerDecimalRounding) || null,
          team: player.team,
          color: soccerTeamColor[player.team]
        }
      })
    return barsData
  }

  handleChangeMetric (e) {
    const newMetric = e.target.value
    this.setState({
      metric: newMetric,
      data: this.getBarsData(this.props.players, newMetric)
    })
  }

  handleChangeTeam (e) {
    const newTeam = parseInt(e.target.value)
    this.setState({
      team: newTeam
    })
  }

  handleChangeOrdering (e) {
    const newOrdering = e.target.value
    this.setState({
      ordering: newOrdering
    })
  }

  initialize () {
    const players = this.props.players || []
    if (_.entries(players).length > 0) {
      this.setState({
        metric: this.metrics[0],
        data: this.getBarsData(players, this.metrics[0])
      })
    } else {
      this.setState({
        metric: '',
        data: []
      })
    }
  }

  componentDidUpdate (prevProps, _prevState, _snapshot) {
    if (JSON.stringify(prevProps.players) !== JSON.stringify(this.props.players)) {
      this.initialize()
    }
  }

  render () {
    const { datasetId } = this.props
    const metricsDropdownList = this.metrics.map(metric => { return { value: metric, name: I18n.t(soccerMetrics[metric].title) } })
    const defaultMetric = metricsDropdownList[0] ? metricsDropdownList[0].value : ''

    const teams = Object.keys(this.props.teams).length > 0 ? this.props.teams : SoccerBarGraph.defaultProps.teams
    const teamsDropdownList = _.map(teams, function (team, index) { return { value: index, name: team.name } })
    teamsDropdownList.push({ value: soccerBothTeamsIndex, name: I18n.t('components.dashboards.soccer.overall_metrics.both_teams') }) // This adds the Both teams option
    const defaultTeam = soccerBothTeamsIndex

    const ascendingDescendingDropdownList = [
      { value: 'ascending', name: I18n.t('components.dashboards.soccer.overall_metrics.ordering.ascending') },
      { value: 'descending', name: I18n.t('components.dashboards.soccer.overall_metrics.ordering.descending') }
    ]

    let barGraphData = this.state.data
    if (this.state.team in [0, 1]) {
      barGraphData = this.filterDataByTeam(barGraphData, this.state.team)
    }

    return (
      <div className='soccer-bar-graph'>
        <div className='row'>
          <div className='col s4'>
            <SelectDropdown
              id={this.ids.metricsDropdown}
              contentId={datasetId}
              content={metricsDropdownList}
              onChange={this.handleChangeMetric.bind(this)}
              placeholder={I18n.t('components.dashboards.soccer.selectors.metric')}
              label={I18n.t('components.dashboards.soccer.selectors.metric')}
              defaultValue={defaultMetric}
            />
          </div>
          <div className='col s4'>
            <SelectDropdown
              id={this.ids.teamsDropdown}
              contentId={datasetId}
              content={teamsDropdownList}
              onChange={this.handleChangeTeam.bind(this)}
              placeholder={I18n.t('components.dashboards.soccer.selectors.team')}
              label={I18n.t('components.dashboards.soccer.selectors.team')}
              defaultValue={defaultTeam}
            />
          </div>
          <div className='col s4'>
            <SelectDropdown
              id={this.ids.ascendingDescendingDropdown}
              contentId={datasetId}
              content={ascendingDescendingDropdownList}
              onChange={this.handleChangeOrdering.bind(this)}
              placeholder={I18n.t('components.dashboards.soccer.selectors.ordering')}
              label={I18n.t('components.dashboards.soccer.selectors.ordering')}
              defaultValue='descending'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col s12'>
            <OrderedBarGraph
              data={barGraphData}
              metric={this.state.metric}
              ordering={this.state.ordering}
            />
          </div>
        </div>
      </div>
    )
  }
}

SoccerBarGraph.propTypes = {
  players: PropTypes.array,
  teams: PropTypes.array,
  datasetId: PropTypes.number
}

SoccerBarGraph.defaultProps = {
  players: [],
  teams: []
}
