import React from 'react'
import {
  sports,
  momentFullTimeFormat,
  momentMinuteSecondFormat
} from '../../../../common/Constants'
import Summary from '../common/Summary'
import moment from 'moment'

// Session SDOs for FIT files may contain different fields depending on the sport. This component renders the proper
// component according to the data
const FitFileSessionFlavour = (props) => {
  // This should not happen
  if (props.sdo.data_rows.length < 1) return <></>

  const data = props.sdo.data_rows[0]

  let distance = 0
  let time = 0
  let speed = 0
  let height = 0
  let calories = 0
  let pace = 0
  let d = 0
  let duration = 0

  switch (props.sdo.sport.name) {
    case sports.cycling:
      distance = `${Math.round(data.total_distance / 1000)} km`
      time = moment.utc(moment.duration(data.total_elapsed_time * 1000).as('milliseconds')).format(momentFullTimeFormat)
      speed = `${Math.round(data.avg_speed * 3.6)} km/h`
      height = `${data.total_ascent} m`
      calories = Math.round(data.total_calories)

      return <Summary fields={{ distance, time, speed, height, calories }} />
    case sports.running:
      d = data.total_distance / 1000
      distance = `${Math.round(d)} km`
      duration = moment.duration(data.total_elapsed_time * 1000)
      time = moment.utc(duration.asMilliseconds()).format(momentFullTimeFormat)
      pace = `${moment.utc(duration.asMilliseconds() / d).format(momentMinuteSecondFormat)} min/km`
      height = `${data.total_ascent} m`
      calories = Math.round(data.total_calories)

      return <Summary fields={{ distance, time, pace, height, calories }} />
    default:
      return <></>
  }
}

export default FitFileSessionFlavour
