/* This file handles unit conversions for the SDV Data Format. Values returned by the API have SI units. Each sport
 * however prefers to represent some values in a particular set of units. The helper functions in this file make
 * converting an entire summary object into the appropriate set of unist for the sport easy and consistent.
 */
import {
  collectionTypes,
  momentMinuteSecondFormat,
  momentFullTimeFormat,
  momentDurationDayFormat,
  momentTimeFormat,
  EMPTY_RECORD
} from './Constants'
import convert from 'convert-units'
import moment from 'moment'
import { formatDate, formatDateTime, smartTranslate } from './Utils'
import _ from 'lodash'
import { useMemo } from 'react'
import I18n from 'i18n'
import { Object } from 'core-js'

// This method rounds the lest significant component of a sexagesimal encoded value (e.g. time: 2:30.6) taking into
// account carries from the least significant component to the most significant one (e.g. from 4:59.9 it rounds
// to 5:00)
const roundLeastSignificantSexagesimal = (mostSignificant, leastSignificant) => {
  let most = mostSignificant
  let least = leastSignificant
  if (least >= 60) {
    most += 1
    least = least % 60
  }
  return [most, least]
}

export const identity = v => v
export const ms2kmh = v => convert(v).from('m/s').to('km/h')
export const min2mmss = v => {
  if (!Number.isFinite(v)) return EMPTY_RECORD

  const minutes = Math.floor(v)
  const seconds = Math.round(convert(v % 1).from('min').to('s'))
  // It could be that the input looks like 4.999, which yields 60 for the second value (because of the rounding). In that
  // case we need to carry the 1 to the minutes and bring the seconds back to the 0-59 range
  const [roundedMinutes, roundedSeconds] = roundLeastSignificantSexagesimal(minutes, seconds)
  return `${roundedMinutes}:${roundedSeconds.toString().padStart(2, '0')}`
}
export const min2mm = v => `${Math.floor(v)}`
export const h2hhmm = v => min2mmss(v)
export const ms2minkm = v => 1.0 / v * 100 / 6
export const ms2minhm = v => 1.0 / v * 10 / 6
export const m2km = v => convert(v).from('m').to('km')
export const s2hms = v => moment.duration(parseInt(v), 'seconds').format(momentFullTimeFormat, { trim: false })
export const s2ms = v => moment.duration(parseInt(v), 'seconds').format(momentMinuteSecondFormat, { trim: false })
export const s2hm = v => moment.duration(parseInt(v), 'seconds').format(momentTimeFormat, { trim: false })
export const s2dhms = v => {
  const duration = moment.utc(moment.duration(v * 1000).asMilliseconds())
  let nDays = duration.format(momentDurationDayFormat) - 1
  nDays = nDays === 0 ? '' : nDays.toString() + '-'
  const hms = duration.format(momentFullTimeFormat)
  return `${nDays}${hms}`
}
export const s2dhm = v => {
  const duration = moment.utc(moment.duration(v * 1000).asMilliseconds())
  let nDays = duration.format(momentDurationDayFormat) - 1
  nDays = nDays === 0 ? '' : nDays.toString() + '-'
  const hms = duration.format(momentTimeFormat)
  return `${nDays}${hms}`
}
export const stridemin2stepmin = v => 2.0 * v
// Lotte requested the temperature in the summary to be prefixed with a flag if it was higher than 40C.
const possibleFlagPrefix = (temp) => {
  if (temp >= 40) return `🚩 ${temp}`
  return temp
}

// Summary units with sensible defaults
export const summaryMetric = {
  avg_speed: { units: 'units.km/h', conversion: ms2kmh },
  max_speed: { units: 'units.km/h', conversion: ms2kmh },
  avg_hr: { units: 'units.bpm', conversion: identity },
  max_hr: { units: 'units.bpm', conversion: identity },
  duration: { units: 'units.h', conversion: s2hms },
  distance: { units: 'units.km', conversion: m2km },
  elevation_gain: { units: 'units.m', conversion: identity },
  elevation_loss: { units: 'units.m', conversion: identity },
  calories: { units: 'units.kcal', conversion: identity },
  start_date: { units: '', conversion: formatDateTime },
  end_date: { units: '', conversion: formatDateTime },
  weighted_avg_watts: { units: 'units.W', conversion: identity },
  max_core_temperature: { units: 'units.C', conversion: possibleFlagPrefix },
  tags: { units: '', conversion: (arr) => arr.map(item => smartTranslate(item)).join(', ') }
}

// Duplicate of summaryMetric in case we want to override something in the future
export const summaryRidingMetric = {
  ...summaryMetric
}

export const summaryRunningMetric = {
  ...summaryMetric,
  avg_speed: { units: 'units.min/km', conversion: ms2minkm },
  max_speed: { units: 'units.min/km', conversion: ms2minkm }
}

export const summarySwimmingMetric = {
  ...summaryMetric,
  distance: { units: 'units.m', conversion: identity },
  avg_speed: { units: 'units.min/hm', conversion: ms2minhm }
}

export const summarySoccerMetric = {
  ...summaryMetric,
  duration: { units: '', conversion: s2hms }
}

export const summaryIceSkatingMetric = {
  ...summaryMetric,
  distance: { units: 'units.km', conversion: m2km },
  avg_acceleration: { units: 'units.m/s', conversion: identity }
}

// Convert season objects (multi activity dashboard)
export const seasonRidingMetric = {
  start_date: { units: '', conversion: formatDate },
  end_date: { units: '', conversion: formatDate },
  avg_distance_per_week: { units: 'units.km', conversion: m2km },
  avg_duration_per_week: { units: 'units.h', conversion: s2hms },
  avg_trainings_per_week: { units: '', conversion: identity },
  avg_elevation_per_week: { units: 'units.m', conversion: identity },
  avg_hr: { units: 'units.bpm', conversion: identity },
  max_hr: { units: 'units.bpm', conversion: identity },
  avg_speed: { units: 'units.km/h', conversion: ms2kmh },
  max_speed: { units: 'units.km/h', conversion: ms2kmh },
  avg_power: { units: 'units.W', conversion: identity },
  max_power: { units: 'units.W', conversion: identity }
}

export const seasonRunningMetric = {
  ...seasonRidingMetric,
  avg_speed: { units: 'units.min/km', conversion: ms2minkm },
  max_speed: { units: 'units.min/km', conversion: ms2minkm }
}

export const seasonSwimmingMetric = {
  ...seasonRidingMetric,
  avg_speed: { units: 'units.min/hm', conversion: ms2minhm },
  max_speed: { units: 'units.min/hm', conversion: ms2minhm }
}

// Lap and Timeseries units with sensible defaults
export const seriesMetric = {
  hr: { units: 'units.bpm', conversion: identity },
  speed: { units: 'units.km/h', conversion: ms2kmh },
  power: { units: 'units.W', conversion: identity },
  cadence: { units: 'units.rpm', conversion: identity },
  elevation: { units: 'units.m', conversion: identity },
  distance: { units: 'units.km', conversion: m2km },
  duration: { units: 'units.h', conversion: s2hms }
}

export const seriesRidingMetric = {
  ...seriesMetric
}

export const seriesRunningMetric = {
  ...seriesMetric,
  speed: { units: 'units.min/km', conversion: ms2minkm },
  cadence: { units: 'units.steps/min', conversion: stridemin2stepmin }
}

export const seriesHikingMetric = {
  ...seriesRunningMetric
}

export const seriesInlineSkatingMetric = {
  ...seriesMetric
}

export const seriesSwimmingMetric = {
  ...seriesMetric,
  speed: { units: 'units.min/hm', conversion: ms2minhm }
}

export const seriesSoccerMetric = {
  distance: { units: 'units.m', conversion: identity },
  speed: { units: 'units.km/h', conversion: ms2kmh }
}

export const seriesIceSkatingMetric = {
  ...seriesMetric,
  duration: { units: 'units.mm:ss', conversion: s2ms },
  distance: { units: 'units.km', conversion: m2km },
  acceleration: { units: 'units.m/s', conversion: identity }
}

// Participant Summaries
export const participantSoccerMetric = {
  duration: { units: 'units.s', conversion: identity }
}

/* Generic function to convert any object to the right units given a conversion table. Works only for top level keys
 * (nested objects won't get translated). For example for the following input object:
 * {
 *    k1: v1,
 *    k2: v2,
 *    k3: { k1: v1, k2: v2}
 * }
 * the converted output would be:
 * {
 *    k1: { value: v1, units: u1 },
 *    k2: { value: v2, units: u2 },
 *    k3: { k1: v1, k2: v2}
 * }
 * (note how k3 has been left intact)
 */
export function convertObject (object, objectConversionTable) {
  if (!object) return
  return Object.keys(objectConversionTable).reduce((withUnits, key) => {
    const conversionEntry = objectConversionTable[key]
    if (object[key]) {
      withUnits[key] = { value: conversionEntry.conversion(withUnits[key]), units: conversionEntry.units }
    }
    return withUnits
  }, _.cloneDeep(object))
}

// Overrides summary keys in conversionTable with a value/units pair converted to the correct units
function convertSummary (summary, summaryConversionTable, seriesConversionTable) {
  const withUnitsSummary = convertObject(summary, summaryConversionTable)
  convertLaps(withUnitsSummary.laps, seriesConversionTable)

  if (withUnitsSummary.time_series_summaries) {
    withUnitsSummary.time_series_summaries = convertTimeSeriesSummaries(withUnitsSummary.time_series_summaries,
      seriesConversionTable)
  }
  return withUnitsSummary
}

// Takes a summary. Overrides the laps.
export function convertLaps (laps, conversionTable) {
  if (!laps) return null
  if (!conversionTable) return laps

  laps.forEach(lap => {
    return Object.keys(conversionTable).reduce((withUnits, key) => {
      const conversionEntry = conversionTable[key]
      const lapSummary = _.get(lap, `summaries[${key}]`)
      if (lapSummary) {
        Object.keys(lapSummary).forEach(summaryKey => {
          withUnits.summaries[key][summaryKey] = {
            value: conversionEntry.conversion(lapSummary[summaryKey]),
            units: conversionEntry.units
          }
        })
      }
      return withUnits
    }, lap)
  })

  return laps
}

export function convertTimeSeriesSummaries (timeSeriesSummaries, conversionTable) {
  const summaries = timeSeriesSummaries
  timeSeriesSummaries = Object.keys(conversionTable).reduce((withUnits, key) => {
    const conversionEntry = conversionTable[key]
    const seriesSummary = summaries[key]
    if (seriesSummary) {
      Object.keys(summaries[key]).forEach(summaryKey => {
        withUnits[key][summaryKey] = {
          value: conversionEntry.conversion(summaries[key][summaryKey]),
          units: conversionEntry.units
        }
      })
    }
    return withUnits
  }, summaries)

  return timeSeriesSummaries
}

function convertMultiAthleteSummary (summary, summaryConversionTable, participantConversionTable, seriesConversionTable) {
  const withUnitsSummary = convertObject(summary, summaryConversionTable)

  if (!withUnitsSummary.participant_summaries) return withUnitsSummary

  withUnitsSummary.participant_summaries.forEach((playerSummary, playerIndex) => {
    playerSummary = convertObject(playerSummary, participantConversionTable)
    playerSummary.time_series_summaries = convertTimeSeriesSummaries(playerSummary.time_series_summaries,
      seriesConversionTable)
    withUnitsSummary.participant_summaries[playerIndex] = playerSummary
  })
  return withUnitsSummary
}

export function activitySummaryToMetric (summary) {
  const { collection_type: collectionType } = summary || {}
  if (!collectionType) return undefined

  const metrics = getDefaultMetrics(collectionType)

  if (collectionType === collectionTypes.SOCCER) {
    const { metricsForSummary, metricsForSeries, metricsForParticipants } = metrics
    return convertMultiAthleteSummary(summary, metricsForSummary, metricsForParticipants, metricsForSeries)
  } else if (metrics) {
    const { metricsForSummary, metricsForSeries } = metrics
    return convertSummary(summary, metricsForSummary, metricsForSeries)
  } else {
    return metrics === null ? summary : undefined
  }
}

export function getDefaultMetrics (collectionType) {
  if (!collectionType) return undefined

  switch (collectionType) {
    case collectionTypes.SWIMMING:
      return { metricsForSummary: summarySwimmingMetric, metricsForSeries: seriesSwimmingMetric }
    case collectionTypes.RIDING:
    case collectionTypes.INLINE_SKATING:
      return { metricsForSummary: summaryRidingMetric, metricsForSeries: seriesRidingMetric }
    case collectionTypes.HIKING:
    case collectionTypes.RUNNING:
      return { metricsForSummary: summaryRunningMetric, metricsForSeries: seriesRunningMetric }
    case collectionTypes.SOCCER:
      return { metricsForSummary: summarySoccerMetric, metricsForSeries: seriesSoccerMetric, metricsForParticipants: participantSoccerMetric }
    case collectionTypes.CSV_FILE:
    case collectionTypes.TEMP:
      return null
    case collectionTypes.FIT:
      return { metricsForSummary: summaryMetric, metricsForSeries: seriesMetric }
    case collectionTypes.ICE_SKATING:
      return { metricsForSummary: summaryIceSkatingMetric, metricsForSeries: seriesIceSkatingMetric }
    default:
      console.warn(`Conversion to metric was not implemented for collection type ${collectionType}`)
      return undefined
  }
}

export function objectWithUnitsToString (object, nDigits = 1) {
  if (!object) return

  const objectWithStrings = _.cloneDeep(object)
  Object.keys(objectWithStrings).forEach(key => {
    const field = objectWithStrings[key]
    objectWithStrings[key] = fieldWithUnitsToString(field, nDigits)
  })
  return objectWithStrings
}

// Converts the { value: '' units: '' } in a summary to a string such as `${value} ${units}`
export function summaryWithUnitsToString (summary, nDigits = 1) {
  if (!summary) return

  const summaryWithStrings = objectWithUnitsToString(summary, nDigits)
  return lapsWithUnitsToString(summaryWithStrings, nDigits)
}

function lapsWithUnitsToString (summary, nDigits = 1) {
  if (!summary.laps) return summary

  summary.laps.forEach((lap, idx) => {
    const lapSummary = lap.summaries
    if (lapSummary) {
      Object.entries(lapSummary).forEach(([tsKey, tsValue]) => {
        Object.entries(tsValue).forEach(([fieldKey, field]) => {
          summary.laps[idx].summaries[tsKey][fieldKey] = fieldWithUnitsToString(field, nDigits)
        })
      })
    }
  })

  return summary
}

function fieldWithUnitsToString (field, nDigits) {
  let { value, units } = field || {}

  if (value !== undefined && units !== undefined) {
    if (['units.min/km', 'units.min/hm'].includes(units)) value = min2mmss(value)
    if (typeof value === 'number') value = value.toFixed(nDigits)
    if (units.includes('units.')) units = I18n.t(units)
    return `${value} ${units}`.trim()
  }

  return field
}

export const useSummaryUnits = (summary) => {
  return useMemo(() => activitySummaryToMetric(summary), [summary])
}

export const useSummaryStringUnits = (summary, nDigits = 1) => {
  return useMemo(() => summaryWithUnitsToString(activitySummaryToMetric(summary), nDigits), [summary])
}

export const useObjectStringUnits = (object, objectConversionTable) => {
  return useMemo(() => objectWithUnitsToString(convertObject(object, objectConversionTable)), [object, objectConversionTable])
}
