import React from 'react'
import I18n from 'i18n'
import AnimatedDots from '../../common/animations/AnimatedDots'

const UploadingMessage = (props) => {
  if (props.uploadsInProgress > 0) {
    return (
      <div className='row'>
        <div className='col s12'>
          {I18n.t('components.data.uploading_message', { count: props.uploadsInProgress })}<AnimatedDots />
        </div>
      </div>
    )
  } else if (props.uploadsFinished > 0) {
    return (
      <div className='row'>
        <div className='col s12'>
          {I18n.t('components.data.upload_successful')}
        </div>
      </div>
    )
  }

  return <> </>
}

export default UploadingMessage
