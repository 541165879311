import _ from 'lodash'
import React from 'react'
import I18n from 'i18n'
import Field from '../common/Field'
import { readComplaintsQuestionnaire } from '../../../../common/utils/QuestionnaireUtils'

export default class ComplaintsQuestionnaire extends React.Component {
  extraClass (score) {
    if (score >= 50) return 'highcharts-red'
    if (score <= 25) return 'highcharts-green'
    if (score > 25 && score < 50) return 'highcharts-orange'
    return undefined
  }

  render () {
    const values = this.props.data.values
    const complaints = readComplaintsQuestionnaire(values)
    const complaintsText = complaints.complaints ? I18n.t('questionnaires.complaints_questionnaire.reported_complaints.answer_yes') : I18n.t('questionnaires.complaints_questionnaire.reported_complaints.answer_no')
    const illnessScore = complaints.illness?.score
    const locations = Object.keys(_.omit(complaints.injury, 'hoursOfExerciseLastWeek')).map(location => I18n.t(`questionnaires.complaints_questionnaire.${_.snakeCase(location)}`)).join(', ')
    const injuryScores = Object.entries(_.omit(complaints.injury, 'hoursOfExerciseLastWeek')).map(injury => injury[1].score)
    const injuryScoresText = injuryScores.join(', ')
    const maxInjuryScore = _.max(injuryScores) || injuryScores

    return (
      <>
        <div className='sport-fields'>
          <Field title={I18n.t('questionnaires.complaints_questionnaire.reported_complaints.question_short')} value={complaintsText} />
          {!complaints.complaints && <Field title={I18n.t('questionnaires.complaints_questionnaire.severity_score')} value='0 ' extraClasses={this.extraClass(0)} />}
          {complaints.illness && <Field title={I18n.t('questionnaires.complaints_questionnaire.illness_score')} value={illnessScore} extraClasses={this.extraClass(illnessScore)} />}
          {complaints.injury && <Field title={I18n.t('questionnaires.complaints_questionnaire.injury_location')} value={locations} />}
          {complaints.injury && <Field title={I18n.t('questionnaires.complaints_questionnaire.injury_score')} value={injuryScoresText} extraClasses={this.extraClass(maxInjuryScore)} />}
        </div>
      </>
    )
  }
}
