import React from 'react'
import { Route } from 'react-router'
import NotFoundWrapper from '../router/NotFoundWrapper'

import GroupJoinController from './GroupJoinController'
import Routes from '../common/Routes'

export default class GroupJoinRoutes extends React.Component {
  render () {
    const { groupJoinControllerProps } = this.props
    return (
      <NotFoundWrapper slug='join'>
        <Route exact path={Routes.join.code} render={(props) => <GroupJoinController {...groupJoinControllerProps} {...props} />} />
      </NotFoundWrapper>
    )
  }
}
