import React from 'react'
import I18n from 'i18n'
import useMaterializeTextField from 'components/hooks/MaterializeTextField'

export interface InvitationTextConfigurationProps {
  maxInvitationLength: number
  invitationTextDutch: string
  invitationTextEnglish: string
  onChangeDutch: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChangeEnglish: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const MAX_INVITATION_LENGTH = 140
const InvitationTextConfiguration: React.FC<InvitationTextConfigurationProps> = ({
  onChangeDutch, onChangeEnglish,
  maxInvitationLength,
  invitationTextEnglish,
  invitationTextDutch
}): React.ReactElement => {
  useMaterializeTextField()
  return (
    <>
      <div className='row'>
        <div className='col s12 input-field'>
          <input id='invitation-text-nl' maxLength={maxInvitationLength} type='text' className='validate' value={invitationTextDutch} onChange={(e) => onChangeDutch(e)} />
          <label htmlFor='invitation-text-nl'>{I18n.t('group.detail.questionnaires.invitation_text_nl')}</label>
        </div>
        <div className='col s12 input-field'>
          <input id='invitation-text-en' maxLength={maxInvitationLength} type='text' className='validate' value={invitationTextEnglish} onChange={(e) => onChangeEnglish(e)} />
          <label htmlFor='invitation-text-en'>{I18n.t('group.detail.questionnaires.invitation_text_en')}</label>
        </div>
      </div>
    </>
  )
}

InvitationTextConfiguration.defaultProps = {
  maxInvitationLength: MAX_INVITATION_LENGTH
}

export default InvitationTextConfiguration
