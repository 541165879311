import React from 'react'
import { Route } from 'react-router'
import WelcomeController from './WelcomeController'
import Routes from '../common/Routes'

export default class WelcomeRoutes extends React.Component {
  render () {
    const { welcomeProps } = this.props
    return (
      <>
        <Route exact path={Routes.root} render={(props) => <WelcomeController {...props} {...welcomeProps} />} />
        <Route exact path={Routes.timeline.index} render={(props) => <WelcomeController {...props} {...welcomeProps} />} />
        <Route exact path={Routes.timeline.network.index} render={(props) => <WelcomeController {...props} {...welcomeProps} />} />
        <Route path={Routes.profiles.show} render={(props) => <WelcomeController {...props} {...welcomeProps} />} />
      </>
    )
  }
}
