import React from 'react'
import I18n from 'i18n'
import Info from '../../atomic/atoms/Info'

export default class CheckBox extends React.Component {
  render () {
    const errors = this.props.errors || []
    const { infoBox, component, fieldName, formName, currentValue, defaultValue, label, showLabel = true, noWrappers = false } = this.props
    const type = this.props.type || 'checkbox'
    const fieldProps = { ...this.props }
    delete fieldProps.component
    delete fieldProps.fieldName
    delete fieldProps.translateErrors
    delete fieldProps.formName
    delete fieldProps.defaultValue
    delete fieldProps.currentValue
    delete fieldProps.type
    delete fieldProps.infoBox
    delete fieldProps.label
    delete fieldProps.showLabel
    delete fieldProps.noWrappers

    let infoBoxText = infoBox
    if (typeof infoBoxText === 'function') {
      infoBoxText = infoBoxText()
    }

    let isChecked = !!currentValue || (defaultValue && currentValue == null) || false
    if (fieldProps.checked === false) {
      isChecked = false
    }
    delete fieldProps.checked

    const renderInputField = () => {
      return (
        <div className='input-field'>
          <div>
            <label>
              <input
                type={type}
                name={fieldName}
                id={fieldName}
                {...fieldProps}
                checked={isChecked}
                onChange={() => this.props.onChange({ target: { type: type } }, formName, fieldName)}
              />
              <span>
                {showLabel && (label || I18n.t(`${formName}.${component}.${type}.${fieldName}`))}
              </span>
            </label>
            {errors.map(err => (
              <span className='helper-text' data-error='wrong' data-success='right' key={err} style={{ color: 'red' }}>
                {this.props.translateErrors ? I18n.t(err) : err}
              </span>
            ))}
            {infoBox && <Info text={infoBoxText} tooltipId={`${formName}.${component}.${type}.${fieldName}-tooltip`} />}
          </div>
        </div>
      )
    }

    if (noWrappers) return renderInputField()

    return (
      <div className='row'>
        <div className='col s12'>
          {renderInputField()}
        </div>
      </div>
    )
  }
}
