import React from 'react'

export default class Spinner extends React.Component {
  render () {
    const ready = this.props.ready || false
    if (ready) {
      return <></>
    }

    return (
      <div className='spin-wrapper' style={this.props.transparent ? { backgroundColor: 'transparent' } : {}}>
        <div className='spinner' />
      </div>
    )
  }
}
