import I18n from 'i18n'
import { sortBy } from 'lodash'

import Group from 'components/common/types/Group'
import Sport from 'components/common/types/Sport'
import CollectionRight from 'components/common/types/Right'
import { IconMap } from 'components/common/types/Icon'
import Permission from 'components/common/types/Permission'
import Connection from 'components/common/types/Connection'
import { prettifyTag } from 'components/common/utils/TagUtils'

interface DropdownOption {
  name: string
  value: string | number | string[] | number[]
  icon?: string
}

interface DropdownOptionGroup {
  groupName: string
  elements: DropdownOption[]
}

type DropdownListElement = DropdownOption | DropdownOptionGroup

/* check if the sport for this sharing rule is still enabled in the current profile's sport list.
   * if it is not included, find it in the list of all sports and add it to the list */
export const fillMissingSports = (right: CollectionRight | undefined, sports: Sport[], allSports: Sport[]): Sport[] => {
  const hasSport: boolean = !(right == null) && sports.filter((sport) => sport.name === right.collection_type).length > 0
  const extraSport = !hasSport && !(right == null) && allSports.filter((sport) => sport.name === right.collection_type)[0]
  const cpy = [...sports]
  !hasSport && extraSport && cpy.push(extraSport)
  return cpy
}

export const getCategoryDropdownContent = (sports: Sport[], sportIcons: IconMap): DropdownListElement[] => {
  if (sports.length <= 0 || Object.keys(sportIcons).length !== sports.length) return []

  return sports.map((sport) => {
    return {
      name: I18n.t(sport.name),
      value: sport.name,
      icon: sportIcons[sport.icon]
    }
  })
}

export const getTagsDropdownContent = (tags: string[], groups: Group[]): DropdownOption[] => {
  const unsortedDropdownElements: DropdownOption[] = tags.map(tag => {
    return {
      name: prettifyTag(tag, groups),
      value: tag
    }
  })

  return sortBy(unsortedDropdownElements, (elem) => elem.name.toLowerCase())
}

export const getPermissionsDropdownContent = (permissions: Permission[], permissionIcons: IconMap): DropdownListElement[] => {
  if (Object.keys(permissionIcons).length !== permissions.length) return []

  return permissions.map((permission) => {
    return {
      name: I18n.t(`permissions.${permission.name}`),
      value: permission.name,
      icon: permissionIcons[permission.icon]
    }
  })
}

export const getSharedWithDropdownContent = (publicGroup: Group | undefined, availableGroups: Group[], availableConnections: Connection[]): DropdownListElement[] => {
  const sharedWithDropdownContent: DropdownListElement[] = []

  availableGroups.length > 0 && sharedWithDropdownContent.push(
    {
      groupName: I18n.t('datasharing.labels.groups'),
      elements: availableGroups.map((group) => {
        return { name: group.name, value: group.id, icon: group.picture }
      })
    }
  )
  availableConnections.length > 0 && sharedWithDropdownContent.push(
    {
      groupName: I18n.t('datasharing.labels.connections'),
      elements: availableConnections.map((connection) => {
        // connection name is build by backend, by placing space between the names. Therefore if both are empty, it'll simply be a space
        const name = connection.name === ' '
        // TODO Remove the "as string" once we find out how to use types with I18n
          ? `${I18n.t('profile.show.first_name') as string} ${I18n.t('profile.show.last_name') as string}`
          : connection.name
        return { name: name, value: connection.id, icon: connection.picture }
      })
    }
  );
  (publicGroup !== undefined) && sharedWithDropdownContent.push(
    {
      groupName: I18n.t('datasharing.labels.followers'),
      elements: [{ name: I18n.t('datasharing.labels.followers'), value: publicGroup.id }]
    }
  )

  return sharedWithDropdownContent
}
