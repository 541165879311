import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import I18n from 'i18n'

import GroupedBarsChart from '../../common/GroupedBarsChart'
import { BODY_TO_COLOR, momentDateFormat, OSTRC_PARSED_ANSWERS_MAP } from '../../../../common/Constants'
import { entryMap2Entries } from './utils'

const paramName = (param, owner) => `${param} - ${owner.id} - ${owner.first_name} ${owner.last_name}`
const InjuriesOverTimeChart = (props) => {
  const { questionnaires, barColors } = props

  // Object where the keys are date strings and the values are the bars for that date
  const entryMap = {}
  questionnaires.forEach(questionnaire => {
    const { week, answers, owner } = questionnaire
    const label = moment(week).toString()

    if (!entryMap[label]) {
      entryMap[label] = []
    }

    const bars = entryMap[label]

    // Push injuries to bars
    Object.entries(answers?.injury || []).forEach(([bodyPart, answers]) => {
      bars.push({
        value: _.sum([
          answers.participationDifficulty,
          answers.trainingAdjustment,
          answers.performanceInfluence,
          answers.painAmount
        ]),
        param: paramName(bodyPart, owner)
      })
    })

    // Push illness to bars
    if (answers?.illness) {
      const score = answers?.illness?.score || _.sum([
        answers.illness.participationDifficulty,
        answers.illness.trainingAdjustment,
        answers.illness.performanceInfluence,
        answers.illness.symptomsIntensity
      ])
      bars.push({
        value: score,
        param: paramName('illness', owner)
      })
    }
  })

  // GroupedBarsChart expects an array of object with label and bars keys
  const entries = entryMap2Entries(entryMap)

  // TODO select just the questions I want
  const getAnswersString = (bodyPartAnswers) => {
    let answerString = ''
    const answersToDisplay = _.pick(bodyPartAnswers, [
      'participationDifficulty',
      'trainingAdjustment',
      'performanceInfluence',
      'painAmount',
      'lastTimeReportingInjury',
      'typeOfInjury'
    ])
    _.entries(answersToDisplay).forEach(([question, answer]) => {
      const questionNameSnakeCase = _.snakeCase(question)
      const displayedQuestion = I18n.t(`components.dashboards.questionnaire.complaints.question_names.${questionNameSnakeCase}`)

      const possibleAnswers = OSTRC_PARSED_ANSWERS_MAP[question]
      const parsedAnswer = possibleAnswers ? possibleAnswers[answer] : answer

      let displayedAnswer = I18n.t(`components.dashboards.questionnaire.complaints.answers.${parsedAnswer}`)
      if (_.first(displayedAnswer) === '[') { // If the translation does not exist
        displayedAnswer = parsedAnswer
      }
      answerString += `- ${displayedQuestion}: ${displayedAnswer}\n`
    })
    return answerString
  }

  const tooltips = {
    label: (item, data) => {
      const [bodyPart, _, ownerName] = data?.datasets?.[item?.datasetIndex]?.label.split(' - ') // eslint-disable-line no-unused-vars
      const severityScore = item?.value
      return `${I18n.t(`components.dashboards.questionnaire.complaints.body_parts.${bodyPart}`)} - ${ownerName}: ${severityScore}`
    },
    afterBody: (items, data) => {
      const currentItem = items?.[0] // We just have 1 dataset per bar, so we will always have just 1 item
      const bodyPart = data?.datasets?.[currentItem?.datasetIndex]?.label

      const answers = questionnaires[currentItem?.index]?.answers?.injury?.[bodyPart]
      return getAnswersString(answers)
    },
    title: (tooltipItems) => moment(new Date(tooltipItems[0].xLabel)).format(momentDateFormat)
  }

  const paramsInData = entries.reduce((allParams, entry) => {
    entry.bars.forEach(bar => allParams.add(bar.param))
    return allParams
  }, new Set())

  const bodypartColorMap = _.mapValues(BODY_TO_COLOR, (color) => { return { color } })

  // Our params include the body part and the profile id. Here we construct a property hash where each param has the
  // color properties of its corresponding body part
  const properties = paramsInData.reduce((workingProperties, param) => {
    const equivalentParam = param.split(' - ')[0]
    workingProperties[param] = bodypartColorMap[equivalentParam]
    return workingProperties
  }, {})

  const options = {
    suggestedMin: 0,
    suggestedMax: 100,
    title: I18n.t('components.dashboards.questionnaire.complaints.injuries_over_time.title'),
    tooltipCallbacks: tooltips
  }

  const xAxis = {
    scaleLabel: {
      display: true,
      labelString: I18n.t('components.dashboards.questionnaire.complaints.injuries_over_time.x_axis')
    },
    ticks: { callback: (value) => value.week() }
  }

  return (
    <GroupedBarsChart entries={entries} paramProperties={properties} {...options} xAxis={xAxis} barColors={barColors} />
  )
}

export default InjuriesOverTimeChart
