import _ from 'lodash'
import React from 'react'
import I18n from 'i18n'
import SummaryTable from '../../../analysis/dashboards/common/SummaryTable'
import { readComplaintsQuestionnaire } from '../../../common/utils/QuestionnaireUtils'

const ComplaintsQuestionnaireView = (props) => {
  const values = props.data.values
  const complaints = readComplaintsQuestionnaire(values)

  const complaintsAnswer = complaints.complaints
    ? I18n.t(`questionnaires.complaints_questionnaire.reported_complaints.answer_${_.snakeCase(complaints.complaints)}`)
    : I18n.t('questionnaires.complaints_questionnaire.reported_complaints.answer_no')

  function getQuestionText (question, context) {
    switch (question) {
      case 'wasTreated':
        return I18n.t(`questionnaires.complaints_questionnaire.${_.snakeCase(question)}.question`)
      default:
        return I18n.t(`questionnaires.complaints_questionnaire.${_.snakeCase(question)}.question`, { complaint: _.toLower(I18n.t(`questionnaires.complaints_questionnaire.${_.snakeCase(context)}`)) })
    }
  }

  function getAnswerText (question, props) {
    switch (question) {
      case 'daysWithoutParticipation':
        return String(_.get(props, question))
      case 'symptoms':
        return Object.values(_.get(props, question)).map(symptom => I18n.t(`questionnaires.complaints_questionnaire.${_.snakeCase(question)}.answer_${_.snakeCase(symptom)}`, { defaultValue: symptom })).join(', ')
      case 'causeOfInjury':
      case 'causeExplanation':
        return String(_.get(props, question))
      case 'activityThatCausedInjury':
        return I18n.t(`questionnaires.complaints_questionnaire.${_.snakeCase(question)}.answer_${_.snakeCase(_.get(props, question))}`, { defaultValue: _.get(props, question) })
      default:
        return I18n.t(`questionnaires.complaints_questionnaire.${_.snakeCase(question)}.answer_${_.snakeCase(_.get(props, question))}`)
    }
  }

  const introTable = (
    <div key='intro'>
      <div className='text-medium text-l text-muted data-header'>
        {I18n.t('questionnaires.complaints_questionnaire.reported_complaints.question')}
      </div>
      <div className='text-light text-muted'>
        {complaintsAnswer}
      </div>
      {complaints?.complaints &&
        <>
          <div className='text-medium text-l text-muted data-header'>
            {I18n.t('questionnaires.complaints_questionnaire.hours_of_exercise')}
          </div>
          <div className='text-light text-muted'>
            {complaints.hoursOfExerciseLastWeek.toString()}
          </div>
        </>}
    </div>
  )

  function getIllnessTable (illnessProps) {
    const illnessFields = _.map(Object.keys(_.omit(illnessProps, 'score')),
      question => ({
        name: getQuestionText(question, 'illness'),
        value: getAnswerText(question, illnessProps)
      })
    )
    return (
      <div key='illness'>
        <div className='row text-l text-muted data-header'>
          <div className='col s9 text-medium '>{I18n.t('questionnaires.complaints_questionnaire.illness')}</div>
          <div className='col s3 text-heavy text-secondary-color'>{I18n.t('questionnaires.complaints_questionnaire.severity_score')}: {illnessProps?.score}</div>
        </div>
        <div className='row'>
          <div className='col s12'>
            <SummaryTable fields={illnessFields} />
          </div>
        </div>
      </div>
    )
  }

  function getInjuryTable (injuryProps) {
    const injuryFields = _.map(Object.keys(_.omit(injuryProps[1], ['sharedTheCauseOfInjury', 'score'])),
      question => ({
        name: getQuestionText(question, injuryProps[0]),
        value: getAnswerText(question, injuryProps[1])
      })
    )
    return (
      <div key={injuryProps[0]}>
        <div className='row text-l text-muted data-header'>
          <div className='col s9 text-medium '>{I18n.t(`questionnaires.complaints_questionnaire.${_.snakeCase(injuryProps[0])}`)}</div>
          <div className='col s3 text-heavy text-secondary-color'>{I18n.t('questionnaires.complaints_questionnaire.severity_score')}: {injuryProps[1].score}</div>
        </div>
        <div className='row'>
          <div className='col s12'>
            <SummaryTable fields={injuryFields} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {introTable}
      {complaints.illness && getIllnessTable(complaints.illness)}
      {complaints.injury && Object.entries(complaints.injury).map(injury => getInjuryTable(injury))}
    </>
  )
}

export default ComplaintsQuestionnaireView
