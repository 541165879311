import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'i18n'
import SelectDropdown from '../../../common/SelectDropdown'
import _ from 'lodash'

const PlayerDropdown = (props) => {
  const {
    players,
    datasetId,
    defaultValue,
    id,
    isMultipleChoice,
    label,
    onChange,
    placeholder,
    teamNames
  } = props

  const dropdownList = getPlayerDropdownList(players, teamNames || null)
  const dropdownLabel = label || I18n.t('components.dashboards.soccer.selectors.player')
  return (
    <SelectDropdown
      id={id}
      content={dropdownList}
      contentId={datasetId}
      label={dropdownLabel}
      placeholder={placeholder || I18n.t('components.dashboards.soccer.selectors.player')}
      onChange={onChange}
      defaultValue={defaultValue}
      isMultipleChoice={isMultipleChoice}
    />
  )

  function getPlayerDropdownList (players, teamNames) {
    const playerDropdownList = [[], []]
    if (!players) return playerDropdownList

    players.forEach(player => {
      const name = `${player.number}: ${player.name}`
      const team = player.team || 0
      playerDropdownList[team].push({ value: player.id, name: name })
    })

    if (teamNames) {
      return teamNames.map((teamName, teamId) => { return { groupName: teamName, elements: playerDropdownList[teamId] } })
    } else {
      return _.flatten(playerDropdownList)
    }
  }
}

export default PlayerDropdown

PlayerDropdown.propTypes = {
  players: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      number: PropTypes.number,
      team: PropTypes.number
    })
  ),
  id: PropTypes.string.isRequired,
  isMultipleChoice: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  teamNames: PropTypes.array,
  datasetId: PropTypes.number
}
