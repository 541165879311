import React from 'react'
import I18n from 'i18n'
import { round } from '../../../common/Math'
import { s2hm } from '../../../common/Units'
import { durationFromMinutes } from '../../../common/Utils'
import SummaryTable from '../../../analysis/dashboards/common/SummaryTable'
import _ from 'lodash'
import Info from '../../../atomic/atoms/Info'

const FitBitDetailView = (props) => {
  const summary = _.get(props, 'data.data_rows[0]')
  if (!summary) {
    // TODO: Add appsignal logging here of the summary and why its actually null
    return (<p> {I18n.t('timeline.error.detailed_view')} </p>)
  }

  const fieldsOfInterest = Object.keys(summary).filter(key => key !== 'dateTime' && !key.endsWith('_zone') && !key.includes('heart_rate')).sort()
  const zonesOfInterest = Object.keys(summary).filter(key => key.endsWith('_zone'))
  // TODO: Currently the Intra HR has been filtered out. Intra HR is received as an array of objects
  const physicalActivities = getActivity('physical_activity', sortFilterFields(['steps', 'distance', 'floors', 'elevation', 'minutes_sedentary', 'minutes_lightly_active', 'minutes_fairly_active', 'minutes_very_active'], fieldsOfInterest.filter(key => !key.startsWith('sleep') && !key.includes('calories') && !key.includes('heart_intra_day'))))
  const sleepActivities = getActivity('sleep_activity', sortFilterFields(['sleep_minutes', 'sleep_start_time', 'sleep_get_up_time', 'sleep_efficiency'], fieldsOfInterest.filter(key => key.startsWith('sleep'))))
  const energyActivities = getActivity('energy_activity', sortFilterFields(['calories', 'calories_bmr', 'activity_calories'], fieldsOfInterest.filter(key => key.includes('calories'))))
  const zones = getZones(sortFilterFields(['hr_fat_burn_zone', 'hr_cardio_zone', 'hr_peak_zone'], zonesOfInterest))

  function getFields (fieldsOfInterest) {
    return fieldsOfInterest.map(key => {
      let value = typeof (summary[key]) === 'object' && summary[key] !== null ? summary[key].minutes : summary[key]

      switch (key) {
        case 'sleep_minutes':
          value = durationFromMinutes(value)
          break
        case 'sleep_start_time':
        case 'sleep_get_up_time':
          value = s2hm(value)
          break
        default:
          value = round(value, 2)
          break
      }

      return (
        {
          name: I18n.t(`components.timeline.card.${key}`),
          value: value
        }
      )
    })
  }

  function sortFilterFields (sortOrder, fields) {
    const diffFieldArray = _.differenceBy(fields, sortOrder, 0)

    if (diffFieldArray.length > 0 || fields.length !== sortOrder.length) {
      return sortOrder.map(sortedField => {
        if (fields.includes(sortedField)) {
          return sortedField
        }
        return undefined
      }).filter(key => key !== undefined).concat(diffFieldArray)
    } else {
      return sortOrder
    }
  }

  function getZones (zones) {
    return (
      <div key='heart_rate'>
        <div className='text-heavy text-l text-muted data-header'>{I18n.t('components.dashboards.fitbit.heart_rate')}</div>
        <div className='row'>
          <div className='col s12'>
            <SummaryTable fields={getFields(['resting_heart_rate'])} />
            {zones && zones.map(zone => {
              if (!summary[zone]) {
                return <div key={zone} />
              }
              const fieldsForZone = []
              for (const [key, value] of Object.entries(summary[zone])) {
                if (key !== 'name') {
                  fieldsForZone.push({ name: I18n.t(`components.dashboards.fitbit.${key}`), value: round(value, 2) })
                }
              }
              return (
                <div key={zone}>
                  <div className='text-heavy text-muted data-header'>
                    {I18n.t(`components.dashboards.fitbit.${zone}`)}
                    <Info text={I18n.t(`components.dashboards.fitbit.${zone}_tooltip`)} tooltipId={`${zone}-tooltip`} />
                  </div>
                  <div className='row'>
                    <div className='col s12'>
                      <SummaryTable fields={fieldsForZone} />
                    </div>
                  </div>
                </div>
              )
            }
            )}
          </div>
        </div>
      </div>
    )
  }

  function getActivity (activityName, activityList) {
    if (activityList.length === 0) return null
    const fieldsForActivity = getFields(activityList)

    return (
      <div key={activityName}>
        <div className='text-heavy text-l text-muted data-header'>{I18n.t(`components.dashboards.fitbit.${activityName}`)}</div>
        <div className='row'>
          <div className='col s12'>
            <SummaryTable fields={fieldsForActivity} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {physicalActivities != null && physicalActivities}
      {sleepActivities != null && sleepActivities}
      {energyActivities != null && energyActivities}
      {zones}
    </>
  )
}

export default FitBitDetailView
