import React from 'react'
import GroupOverviewDTO, { GroupOverviewData, GroupOverviewEntry } from '../../../common/types/DTOs/GroupOverviewDTO'
import StackedBarChart from '../../../analysis/dashboards/steps/StackedBarChart'
import I18n from 'i18n'
import moment from 'moment'
import { momentDateFormat } from '../../../common/Constants'

interface Props {
  groupOverview: GroupOverviewDTO
}

const GroupOverviewGraph: React.FunctionComponent<Props> = ({ groupOverview }) => {
  return (
    <StackedBarChart
      title={I18n.t('network.group.overview_graph.title')}
      labels={localizeLabels(groupOverview.by_date.labels)}
      datasets={formatIntoDatasets(groupOverview.by_date.data)}
      yLabel={I18n.t('network.group.overview_graph.y_label')}
      yMin={0}
      stacked
      xAxisFontSize={12}
      precision={0}
    />
  )
}

const chartColors = (label: GroupOverviewEntry): string => {
  switch (label) {
    case 'protocols_total':
      return '#D49996'
    case 'sport_data':
      return '#AFD6AF'
    case 'unstructured':
    default:
      return '#DBC895'
  }
}

const BARCHART_BARS = ['unstructured', 'sport_data', 'protocols_total']

const formatIntoDatasets = (groupOverviewData: GroupOverviewData): any[] => {
  const result = []
  for (const [key, value] of Object.entries(groupOverviewData)) {
    // Don't plot totals: the stacked bar is the total.
    if (key === 'total') continue

    const idx = BARCHART_BARS.findIndex(elem => elem === key)
    result.push({
      order: idx,
      label: I18n.t(`network.group.overview.${key}`),
      data: value,
      backgroundColor: chartColors(key as GroupOverviewEntry)
    })
  }
  return result
}

const localizeLabels = (dateLabels: string[]): string[] => {
  return dateLabels.map(dateLabel => moment(dateLabel, 'YYYY-MM-DD').format(momentDateFormat))
}

export default GroupOverviewGraph
