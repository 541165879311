import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const InfoNotice = ({ className, msg, title }) => (
  <div className={classNames('info-notice-container', className)}>
    <FontAwesomeIcon icon='info-circle' className='text-muted text-xxl' />
    <div className='text-m text-muted' title={title}>
      {msg}
    </div>
  </div>
)

InfoNotice.propTypes = {
  className: PropTypes.string,
  msg: PropTypes.string.isRequired,
  title: PropTypes.string
}

export default InfoNotice
