class VDO {
  static id (vdo) {
    return vdo?.id
  }

  static summary (vdo) {
    return vdo?.summary
  }

  static eventStart (vdo) {
    return vdo?.metadatum?.event_start
  }

  static hrZones (vdo) {
    return vdo?.summary?.time_series_summaries?.hr?.zone_limits
  }

  static elapsedTimePerZone (vdo, param) {
    return vdo?.summary?.time_series_summaries?.[param]?.zone_elapsed_seconds
  }

  static sdo (vdo) {
    return vdo?.structured_data_objects[0]
  }
}

export default VDO
