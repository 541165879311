import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DataSharingToggle from './DataSharingToggle'
import I18n from 'i18n'
import { WithModal } from '../../modal/ModalProvider'
import DataSharingForm from './DataSharingForm'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import { regularGroups, groupTypes } from '../../common/Constants'
import Spinner from '../../common/Spinner'
import SnapshotsController from '../../analysis/SnapshotsController'
import { MyAxios as axios } from '../../MyAxios'
import SnapshotSharingView from '../../analysis/layout/SnapshotSharingView'
import { WithSession } from '../../session/SessionProvider'
import DataSharingRuleList from './DataSharingRuleList'
import { filterActiveConnections } from '../../common/Utils'

class DataSharingPreferencesPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      editIdx: undefined,
      retrievedSnapshots: false,
      retrieveSharedWithMe: false
    }
  }

  componentDidMount () {
    const elems = document.querySelectorAll('select')
    M.FormSelect.init(elems, {})
    this.props.sessionToken && this.props.fetch()
  }

  componentDidUpdate (prevProps) {
    prevProps.sessionToken !== this.props.sessionToken && this.props.fetch()

    if (this.props.sports && this.props.allSports && this.props.permissions && this.props.rights && !this.state.sportIcons) {
      const icons = {}
      this.props.sports.forEach((sport) => {
        icons[sport.icon] = this.renderIcon(sport, 'sport')
      })
      this.setState({ ...this.state, sportIcons: icons })
    }

    if (this.props.sports && this.props.allSports && this.props.permissions && this.props.rights && !this.state.permissionIcons) {
      const icons = {}
      this.props.permissions.forEach((permission) => {
        icons[permission.icon] = this.renderIcon(permission, 'permission')
      })
      this.setState({ ...this.state, permissionIcons: icons })
    }

    const elems = document.querySelectorAll('select')
    M.FormSelect.init(elems, {})
  }

  fetchSnapshots (path = '/api/v1/snapshots') {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: this.props.sessionToken
    }

    axios({ url: path, method: 'GET', headers }).then((response) => {
      this.setState({ snapshots: response.data, retrievedSnapshots: true })
    }).catch(error => {
      if (!process.env.NODE_ENV === 'test') console.log(error)
    })
  }

  fetchSnapshotsSharedWithMe () {
    this.setState({ retrieveSharedWithMe: false })
    axios({
      method: 'GET',
      headers: {
        Authorization: this.props.sessionToken
      },
      url: '/api/v1/snapshots/shared_with_me'
    }).then(res => {
      this.setState({ sharedWithMe: res.data, retrieveSharedWithMe: true })
    }).catch(error => {
      if (!process.env.NODE_ENV === 'test') console.log(error)
    })
  }

  fetchGroups () {
    this.setState({
      groups: axios({
        method: 'GET',
        url: '/api/v1/groups',
        headers: {
          Authorization: this.props.sessionToken
        }
      }).then(res => res.data).catch(error => {
        if (!process.env.NODE_ENV === 'test') console.log(error)
      })
    })
  }

  handleShareSnapshot (snapshot) {
    this.props.setModalComponent(SnapshotSharingView, {
      snapshot: snapshot,
      permissions: this.permissions,
      groups: this.state.groups,
      fetchSnapshots: this.fetchSnapshots.bind(this)
    })
  }

  renderIcon (item, prefix) {
    const iconId = document.getElementById(`${prefix}-${item.icon.iconName || item.icon}`)
    if (!iconId) {
      // It doesn't render the icons in jest for some reason.
      if (process.env.NODE_ENV !== 'test') console.error(`cannot find iconId for ${JSON.stringify(item)}`)
      return
    }
    const data = iconId.innerHTML
    const DOMURL = window.URL || window.webkitURL || window

    const svg = new Blob([data], {
      type: 'image/svg+xml'
    })
    return DOMURL.createObjectURL(svg)
  }

  handleShowSharingForm (right) {
    const groups = this.props.groups
    let availableConnections = []
    let availableGroups = []
    let publicGroup
    if (groups) {
      availableGroups = groups.filter((group) => regularGroups.includes(group.group_type))
      availableConnections = filterActiveConnections(groups)
      publicGroup = groups.filter((group) => group.group_type === groupTypes.PUBLIC)[0]
    }
    const startDate = right && right.start_date
    const endDate = right && right.end_date
    this.props.setModalComponent(DataSharingForm, {
      ...this.props,
      sportIcons: this.state.sportIcons,
      permissionIcons: this.state.permissionIcons,
      availableConnections: availableConnections,
      availableGroups: availableGroups,
      availableTags: this.props.availableTags,
      publicGroup: publicGroup,
      right: right,
      startDate: startDate,
      endDate: endDate,
      onSave: this.handleSave.bind(this)
    })
  }

  handleSave (rightId) {
    this.props.onSave(this.props.hide.bind(this), this.handleSaveError.bind(this), rightId)
  }

  handleSaveError (errorMessage) {
    this.props.flashMessages.push(
      errorMessage,
      this.props.flashMessages.duration.LONG,
      this.props.flashMessages.levels.ERROR)
  }

  render () {
    const { allSports, permissions, rights, sports, groups } = this.props
    if (!allSports || !permissions || !sports || !rights || !groups) {
      return (
        <Spinner ready={false} transparent />
      )
    }
    return (
      <>
        {allSports && allSports.map((sport) => (
          <div
            id={'sport-' + sport.icon}
            style={{ display: 'none' }}
            key={sport.id}
          >
            <FontAwesomeIcon icon={sport.icon === 'futbol' ? ['far', 'futbol'] : sport.icon} color='#bbb' />
          </div>
        ))}
        {permissions && permissions.map((permission) => (
          <div
            id={'permission-' + permission.icon}
            style={{ display: 'none' }}
            key={permission.name}
          >
            <FontAwesomeIcon icon={permission.icon === 'futbol' ? ['far', 'futbol'] : permission.icon} color='#bbb' />
          </div>
        ))}
        <div className='row'>
          <div className='col s12 m12 l12'>
            <div className='padding-on-small'>
              <DataSharingToggle isChecked={this.props.isSharingDisabled} onChange={this.props.onToggleEnable} />
              <div>
                <div className='row'>
                  <div className='col s12'>
                    <p className='text-medium text-l text-muted no-margin'>
                      {I18n.t('datasharing.explanation')}
                    </p>
                  </div>
                </div>
              </div>
              {!this.props.isSharingDisabled && this.props.isSharingDisabled !== undefined && (
                <>
                  <div>
                    <DataSharingRuleList
                      onDelete={this.props.onDelete}
                      rights={rights}
                      allSports={allSports}
                      edit={this.handleShowSharingForm.bind(this)}
                      {...this.props}
                    />
                    <div className='row'>
                      <div className='col s12 right-align'>
                        <button
                          className='waves-effect waves-light text-primary-color text-heavy background-primary button-primary button-primary text-background-color button-autowidth'
                          onClick={() => this.handleShowSharingForm()}
                          style={{ marginRight: '0' }}
                        >{I18n.t('datasharing.create_new_rule')}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className='row'>
                <div className='col s12'>
                  <div className='divider' />
                </div>
              </div>
              <SnapshotsController
                {...this.props} {...this.state}
                handleShareSnapshot={this.handleShareSnapshot.bind(this)}
                fetchSnapshotsSharedWithMe={this.fetchSnapshotsSharedWithMe.bind(this)}
                handleFetchGroups={this.fetchGroups.bind(this)}
                handleFetchSnapshots={this.fetchSnapshots.bind(this)}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default WithFlashMessages(WithSession(WithModal(DataSharingPreferencesPage)))
