import React, { FC, ReactElement } from 'react'
import I18n from 'i18n'
import Notification from '../../../notifications/layout/Notification'
import NumericPaginationNavigation from 'components/atomic/molecules/NumericPaginationNavigation'
import { useLargeNotifications, useNotificationUtils } from '../../../notifications/hooks/notificationHooks'
import NoResultsRow from '../../molecules/NoResultsRow'
import TitleRow from '../../molecules/TitleRow'
import Button from '../../atoms/Button'
import useNotificationWindow from './index.hooks'

export interface Props {
  hide: () => void
}

const NotificationWindow: FC<Props> = ({ hide }): ReactElement => {
  const { page, handleNavigate } = useNotificationWindow()
  const { notifications, pagination } = useLargeNotifications(page)
  const { markNotificationAsRead, markAllNotificationsAsRead } = useNotificationUtils()

  const FIRST_PAGE = 1

  const { current_page: currentPage = FIRST_PAGE, total_pages: totalPages = 1 } = pagination !== undefined ? pagination : {}

  const hasNotifications = notifications?.length !== 0

  return (
    <>
      <TitleRow title={I18n.t('components.notifications.title')} />
      {!hasNotifications && (
        <NoResultsRow title={I18n.t('components.notifications.none')} />
      )}
      {hasNotifications && (
        <>
          <NumericPaginationNavigation firstPage={FIRST_PAGE} lastPage={totalPages} onNavigate={handleNavigate} activePage={currentPage} />
          <div className='row'>
            <div className='col s12 center-align'>
              <Button type='secondary' autowidth onClick={async () => await markAllNotificationsAsRead()}>
                {I18n.t('navigation.notifications.mark_all_read')}
              </Button>
            </div>
          </div>
        </>
      )}
      {notifications?.map((notification) => (
        <Notification
          key={notification.id} {...notification} viewNotification={(_id: any) => { hide() }}
          markNotification={markNotificationAsRead}
        />
      ))}
    </>
  )
}

export default NotificationWindow
