import React, { useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import moment from 'moment'
import {
  chartjsDefaultArguments,
  chartjsDefaultOptions,
  chartjsScaleLabelProperties,
  chartjsTitleProperties,
  momentTimeFormat
} from '../../../common/Constants'

const StackedLineChart = (props) => {
  const data = {
    labels: props.labels,
    datasets: Array.from(props.datasets)
  }

  useEffect(() => {
    for (const serie of data.datasets) {
      for (const [idx, value] of serie.data.entries()) {
        serie.data[idx] = { x: idx, t: moment.utc(Number(value)) }
      }
    }
  }, [props.datasets, props.labels])

  const options = {
    ...chartjsDefaultOptions,
    title: {
      text: props.title,
      ...chartjsTitleProperties
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function (tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || ''
          if (label) label += ': '
          label += moment(tooltipItem.yLabel, 'MMM. D, YYYY, h:mm:ss a').utc().format(momentTimeFormat)
          return label
        }
      }
    },
    plugins: {
      crosshair: false
    },
    scales: {
      xAxes: [{
        ticks: {
          fontSize: 17.5
        }
      }],
      yAxes: [{
        ticks: {
          min: 18 * 3600 * 1000,
          source: 'auto',
          maxTicksLimit: 11,
          callback: (_value, index, values) => {
            return moment(values[index].value).utc().format(momentTimeFormat)
          }
        },
        type: 'time',
        time: {
          parser: value => { return moment(Number(value)).utc() },
          unit: 'minute',
          stepSize: 60,
          displayFormats: {
            minute: momentTimeFormat
          }
        },
        scaleLabel: {
          labelString: props.yLabel,
          ...chartjsScaleLabelProperties
        }
      }]
    }
  }
  return (
    <div className='chartjs-container'>
      <Line {...chartjsDefaultArguments} data={data} options={options} redraw />
    </div>
  )
}

export default StackedLineChart
