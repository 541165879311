import React, { useState } from 'react'
import AlertInputList from './AlertInputList'
import I18n from 'i18n'
import { useAlertProcessor } from './AlertProcessor'
import AlertInDashboard from '../../../../common/AlertInDashboard'

const AlertSearcher = ({ data, alertsToCheckFor }) => {
  const [expanded, setExpanded] = useState(false)

  const { alerts, alertThresholds, handleUpdateThresholds } = useAlertProcessor({
    alertsToCheckFor: alertsToCheckFor,
    data: data
  })

  return (
    <>
      <div className='row'>
        <div className='col s12 m6'>
          <button
            id='toggle-search'
            className='button-primary background-primary button-autowidth waves-effect waves-light text-background-color'
            onClick={(e) => setExpanded(!expanded)}
          >
            {expanded
              ? I18n.t('components.dashboards.questionnaire.warnings.hide_search')
              : I18n.t('components.dashboards.questionnaire.warnings.show_search')}
          </button>
        </div>
        {expanded && <AlertInDashboard alerts={alerts} mergeTitles />}
      </div>
      <div className='row'>
        <div className='col s12'>
          {expanded &&
            <AlertInputList
              handleUpdateThresholds={handleUpdateThresholds}
              alertThresholds={alertThresholds}
            />}
        </div>
      </div>
    </>
  )
}

export default AlertSearcher
