import React, { useState } from 'react'
import I18n from 'i18n'

import VoucherEntry from './VoucherEntry'
import SelectDropdown from '../../common/SelectDropdown'
import { subscriptionTypes, VOUCHER_CREATION } from '../../common/Constants'
import Field from '../../common/form/Field'
import { CheckBox } from 'components/common/form'

const VoucherController = (props) => {
  const [subscription, setSubscription] = useState(subscriptionTypes.coach)
  const [quantity, setQuantity] = useState(VOUCHER_CREATION.MIN_DURATION)
  const [isGeneric, setIsGeneric] = useState(false)
  const [code, setCode] = useState('')
  const [redemptionLimit, setRedemptionLimit] = useState(VOUCHER_CREATION.REDEMPTION_MIN_LIMIT)

  const vouchers = (props.vouchers || []).map((voucher) => {
    return (<VoucherEntry key={voucher.id} deleteVoucher={props.deleteVoucher} {...voucher} />)
  })

  const toggleIsGeneric = () => {
    setIsGeneric(!isGeneric)
  }

  const handleSubmit = () => {
    const dto = {
      subscription_type: subscription,
      quantity: quantity,
      limit: 1
    }
    if (isGeneric) {
      dto.code = code
      dto.limit = redemptionLimit
    }
    props.handleSubmit(dto)
  }

  // 1 time use vouchers are formatted with a ' - ' every 4 characters. Generic vouchers however are not, whatever the
  // admin types is sent to the backend as a voucher code. In the VoucherController, where the voucher redemption
  // happens we remove all ' - ' strings because they are not part of the valid 1 time voucher. If we allowed the
  // generic vouchers to have these characters, they could not be redeemed. So we force the admins to only give
  // alphanumeric values to the generic voucher codes
  const handleCodeChange = e => setCode(e.target.value.trim().replace(/[\W_]+/g, ''))

  return (
    <>
      <div className='row'>
        <div className='col s12 text-heavy muted-header'>
          <h5>
            {I18n.t('components.admin_dashboard.voucher_controller.title')}
          </h5>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <form>
            <div className='row valign-wrapper'>
              <div className='col s12 m3'>
                <SelectDropdown
                  id='subscription'
                  label={I18n.t('components.admin_dashboard.voucher_controller.subscription_type')}
                  placeholder='subscription'
                  content={[
                    { name: I18n.t('admin_dashboard.select.premium_coach'), value: subscriptionTypes.coach },
                    { name: I18n.t('admin_dashboard.select.premium_researcher'), value: subscriptionTypes.researcher }
                  ]}
                  defaultValue={subscription}
                  onChange={e => setSubscription(e?.target?.value)}
                />
              </div>
              <div className='col s12 m1'>
                <Field
                  id='quantity'
                  formName='add_voucher'
                  component='admin_dashboard'
                  fieldName='quantity'
                  type='number'
                  currentValue={quantity}
                  noWrappers
                  step={1}
                  min={VOUCHER_CREATION.MIN_DURATION}
                  max={VOUCHER_CREATION.MAX_DURATION}
                  onChange={e => setQuantity(e.target.value)}
                />
              </div>
              <div className='col s12 m1'>
                <CheckBox
                  id='is_generic'
                  formName='add_voucher'
                  component='admin_dashboard'
                  fieldName='is_generic'
                  type='checkbox'
                  currentValue={isGeneric}
                  noWrappers
                  onChange={toggleIsGeneric}
                />
              </div>
              {
                isGeneric &&
                  <>
                    <div className='col s12 m3'>
                      <Field
                        id='code'
                        formName='add_voucher'
                        component='admin_dashboard'
                        fieldName='code'
                        type='text'
                        currentValue={code}
                        noWrappers
                        onChange={handleCodeChange}
                      />
                    </div>
                    <div className='col s12 m1'>
                      <Field
                        id='limit'
                        formName='add_voucher'
                        component='admin_dashboard'
                        fieldName='limit'
                        type='number'
                        currentValue={redemptionLimit}
                        noWrappers
                        step={1}
                        min={VOUCHER_CREATION.REDEMPTION_MIN_LIMIT}
                        max={VOUCHER_CREATION.REDEMPTION_MAX_LIMIT}
                        onChange={e => setRedemptionLimit(e.target.value)}
                      />
                    </div>
                  </>
              }
              <div className='col s12 m8'>
                <button
                  className='button-primary background-primary text-background-color waves-effect waves-light button-autowidth'
                  onClick={(e) => {
                    e.preventDefault()
                    handleSubmit()
                  }}
                >
                  {I18n.t('components.admin_dashboard.voucher_controller.submit')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='overflow-y-scroll'>
            <table className='table-auto'>
              <thead>
                <tr>
                  {
                    ['voucher_code', 'subscription_type', 'quantity', 'created_at', 'profile_id', 'redeemed_at', 'remaining'].map((val) =>
                      (<td key={val}><b>{I18n.t(`components.admin_dashboard.voucher_controller.${val}`)}</b></td>)
                    )
                  }
                  <td key='empty' />
                </tr>
              </thead>
              <tbody>
                {vouchers}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default VoucherController
