import React from 'react'
import { WithSession } from '../session/SessionProvider'

class LoggedInLinks extends React.Component {
  render () {
    return (
      <></>
    )
  }
}

export default WithSession(LoggedInLinks)
