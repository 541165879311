import React from 'react'
import Search from './Search'
import ProfileConnectionResult from './Search/ProfileConnectionResult'
import I18n from 'i18n'

export default class ConnectionHeader extends React.Component {
  componentDidMount () {
    M.FormSelect.init(document.querySelectorAll('select'))
  }

  render () {
    return (
      <>
        <div className='col s6'>
          <Search
            textColorClass='muted-header'
            fieldId='connection-search'
            labelClass='text-light'
            onClick={this.props.onConnectionRequest}
            Component={ProfileConnectionResult}
            label={I18n.t('network.connection.label.search')}
            onSearch={this.props.onProfileSearch}
            infoBox={I18n.t('network.group.label.search_profile_tooltip')}
          />
        </div>
        <div className='col s6'>
          <div className='input-field'>
            <select defaultValue='created' id='sort'>
              <option value='created'>
                {I18n.t('network.connection.label.added')}
              </option>
            </select>
            <label className='text-light'>{I18n.t('network.connection.label.sort_by')}</label>
          </div>
        </div>
      </>
    )
  }
}
