import React from 'react'
import { HorizontalBar } from 'react-chartjs-2'
import I18n from 'i18n'
import PropTypes from 'prop-types'
import { interpolateColors } from '../../common/Utils'
import Variables from '../../../stylesheets/variables.module.scss'
const { accentColorDark, accentColorLight } = Variables

const PhysiologicalDetailBarView = (props) => {
  const BOTTOM_PADDING = 55
  const colors = interpolateColors(accentColorDark, accentColorLight, props.limits.length + 1)

  const tooltipRangeDisplay = (tooltipItem, data) => {
    const rangeStart = (tooltipItem.datasetIndex - 1) < 0 ? 0 : data.datasets[tooltipItem.datasetIndex - 1].data[0]
    const rangeEnd = tooltipItem.value
    return `HR Zone ${tooltipItem.datasetIndex}: ${rangeStart} - ${rangeEnd}`
  }

  const createBarOptions = (title) => {
    title = I18n.t(`profile.physiological.attributes.${title}`)
    return {
      title: {
        display: false,
        text: title || 'Zones'
      },
      tooltips: {
        callbacks: {
          title: () => title,
          label: tooltipRangeDisplay
        }
      },
      scales: {
        xAxes: [
          {
            ticks: {
              min: props.min,
              max: props.max
            },
            stacked: false
          }
        ],
        yAxes: [{ stacked: true }]
      },
      responsive: true,
      legend: {
        display: false
      },
      maintainAspectRatio: false,
      layout: {
        padding: {
          bottom: BOTTOM_PADDING
        }
      }
    }
  }
  const convertToBarData = (values) => {
    const fullValues = [props.min].concat(values, props.max)
    const result = fullValues.slice(1).map((elem, idx) => {
      return {
        label: `Zone ${idx + 1}`,
        data: [elem],
        stack: 'Stack 0',
        backgroundColor: colors[idx]// % 2 === 0 ? highlightColor : '#ccc'
      }
    })
    return { datasets: result }
  }

  return (
    <div className='row'>
      <div className='col s12'>
        <div style={{ height: `${100 + BOTTOM_PADDING}px` }}>
          <HorizontalBar data={convertToBarData(props.limits)} options={createBarOptions(props.measureType)} />
        </div>
      </div>
    </div>
  )
}

PhysiologicalDetailBarView.propTypes = {
  // The name of the attribute this chart applies to. Used for the plot title
  measureType: PropTypes.string,
  // Array of the inner limits of the attribute zones
  limits: PropTypes.arrayOf(PropTypes.number),
  // The lowes bound for the attribute
  min: PropTypes.number,
  // The highest bound for the attribute
  max: PropTypes.number
}

export default PhysiologicalDetailBarView
