import React from 'react'
import { Route } from 'react-router'
import NotFoundWrapper from '../router/NotFoundWrapper'
import DataSharingPreferencesPage from './layout/DataSharingPreferencesPage'
import Routes from '../common/Routes'

export default class DataSharingRoutes extends React.Component {
  render () {
    const { dataSharingProps } = this.props
    return (
      <NotFoundWrapper slug='datasharing'>
        <Route exact path={Routes.sharing.index} render={(props) => (<DataSharingPreferencesPage {...props} {...dataSharingProps} />)} />
      </NotFoundWrapper>
    )
  }
}
