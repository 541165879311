import React from 'react'
import I18n from 'i18n'
import { TimeAgoElement } from '../../common/TimeAgo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { WithModal } from '../../modal/ModalProvider'

const SnapshotCard = props => {
  const { snapshot } = props
  const titleKlassNames = classNames('text-heavy text-l',
    props.onShareSnapshot && 'text-primary-color pointer',
    !props.onShareSnapshot && 'text-muted')

  const deleteSnapshotConfirmation = () => {
    const snapshotName = `snapshot '${snapshot.label}'`
    props.setConfirmationDialog({
      onConfirmation: () => props.deleteSnapshot(snapshot.id),
      target: snapshotName,
      action: 'delete'
    })
  }

  return (
    <div className='col s12'>
      <div className='general-container'>
        <div className='snapshot-container'>
          <div className='row no-margin valign-wrapper'>
            <div className='timeline-icon center-align'>
              <FontAwesomeIcon icon='file-archive' />
            </div>
            <div className='col s12 m8'>
              <div className='row no-margin'>
                <div className='col s12'>
                  <div
                    className={titleKlassNames}
                    onClick={() => { props.onShareSnapshot && props.onShareSnapshot(snapshot) }}
                  >
                    {snapshot.label}
                  </div>
                </div>
              </div>
              <div className='row no-margin'>
                <div className='col s12 text-muted text-light text-s'>
                  <TimeAgoElement date={new Date(snapshot.created_at)} /> {I18n.t('analysis.snapshot.created_at')}
                </div>
              </div>
              {snapshot.files.length > 0 && (
                <div className='row no-margin padding-bottom'>
                  <div className='col s12 text-muted text-light text-s one-line'>
                    <span className='text-medium'>{I18n.t('analysis.snapshot.sharing.contents')}:&nbsp;</span>
                    {snapshot.files.join(', ')}
                  </div>
                </div>
              )}
              {snapshot.created_by && snapshot.created_by.length > 0 && (
                <div className='row no-margin padding-bottom'>
                  <div className='col s12'>
                    <span className='text-heavy text-s'>{I18n.t('analysis.snapshot.sharing.created_by')}&nbsp;</span>
                    <span className='text-medium text-s'>{snapshot.created_by}</span>
                  </div>
                </div>
              )}
              {snapshot.shared_with && snapshot.shared_with.length > 0 && (
                <div className='row no-margin padding-bottom'>
                  <div className='col s12'>
                    <span className='text-heavy text-s'>{I18n.t('analysis.snapshot.sharing.shared_with')}&nbsp;</span>
                    <span className='text-medium text-s'>{snapshot.shared_with.join(', ')}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col snapshot-buttons-wrapper'>
              <button
                className='no-margin data-button waves-effect text-m waves-light button-primary text-primary-color background-background text-medium'
                onClick={() => { props.downloadSnapshot(snapshot.id) }}
              >
                {I18n.t('analysis.snapshot.download')}
              </button>
              <button
                className='no-margin data-button waves-effect text-m waves-light button-accent text-accent-color background-background text-medium'
                onClick={deleteSnapshotConfirmation}
              >
                {I18n.t('analysis.snapshot.delete')}
              </button>
              {!props.shared &&
                <button
                  className='no-margin data-button waves-effect text-m waves-light button-primary text-primary-color background-background text-medium'
                  onClick={() => { props.onShareSnapshot(snapshot) }}
                >
                  {I18n.t('analysis.snapshot.share')}
                  <FontAwesomeIcon style={{ marginLeft: '5px' }} icon='share' />
                </button>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WithModal(SnapshotCard)
