import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { momentDateFormat } from './Constants'
import classNames from 'classnames'
import I18n from 'i18n'

const DatePaginator = ({
  children,
  page: { next_page: nextPage, current_page: currentPage, prev_page: prevPage },
  fetchPage
}) => {
  // If there is no current page then there is no data. Return the children without adding pagination decoration
  if (!currentPage) {
    return children
  }

  function formatDate (date) {
    return moment(date).format(momentDateFormat)
  }

  function currentWeek () {
    const start = formatDate(currentPage)
    let end = new Date(currentPage)
    end.setDate(end.getDate() + 6)
    end = formatDate(end)
    return `${start} / ${end}`
  }

  function handleFetchPage (page) {
    if (page) fetchPage(page)
  }
  return (
    <>
      <div className='row valign-wrapper'>
        <div className='col s4 left-align text-primary-color'>
          <span onClick={() => handleFetchPage(prevPage)} className={classNames({ pointer: prevPage })}>
            <FontAwesomeIcon icon='chevron-left' className='button-icon margin-right' />
            {prevPage ? formatDate(prevPage) : I18n.t('date_paginator.no_prev_data')}
          </span>
        </div>
        <div className='col s4 center-align text-xl text-primary-color'>{currentWeek()}</div>
        <div className='col s4 right-align text-primary-color'>
          <span onClick={() => handleFetchPage(nextPage)} className={classNames({ pointer: nextPage })}>
            {nextPage ? formatDate(nextPage) : I18n.t('date_paginator.no_next_data')}
            <FontAwesomeIcon icon='chevron-right' className='button-icon margin-left' />
          </span>
        </div>
      </div>
      {children}
    </>
  )
}

export default DatePaginator
