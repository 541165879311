import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { hcOrange, hcGreen, hcRed, hcBlack } from 'components/common/Constants'
import I18n from 'i18n'
import { initializeHighcharts, titleWithTooltip } from 'components/common/Utils'
import GenericPlaceholder from '../../../../common/GenericPlaceholder'
import { isEmpty } from 'lodash'

class DailyReadinessToTrainChart extends React.Component {
  constructor (props) {
    super(props)

    initializeHighcharts(true)
    this.state = {}
  }

  calculateColor (readinessToTrain) {
    if (readinessToTrain <= 2) return hcRed
    if (readinessToTrain >= 4) return hcGreen
    if (readinessToTrain > 2 && readinessToTrain < 4) return hcOrange
    return hcBlack
  }

  render () {
    if (isEmpty(this.props.dailyLogRows)) {
      return (
        <GenericPlaceholder
          title={I18n.t('components.dashboards.questionnaire.daily_readiness_to_train.title')}
          message={I18n.t('components.dashboards.generic.not_enough_data')}
        />
      )
    }

    const scatterData = this.props.dailyLogRows.map(entry => {
      return {
        x: entry.date_only,
        y: entry.readiness_to_train,
        color: this.calculateColor(entry.readiness_to_train),
        formatted_date: entry.formatted_date,
        wellness_sum_p: entry.wellness_sum_p,
        sleep_quality: entry.wellness_sleep,
        sleep_duration: entry.sleep_duration,
        weight: entry.weight ? `${entry.weight} kg` : '-'
      }
    })

    const xAxisOptions = {
      title: {
        text: ''
      },
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%d %b'
      }
    }

    return (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            title: titleWithTooltip(
              I18n.t('components.dashboards.questionnaire.daily_readiness_to_train.title'),
              I18n.t('components.dashboards.questionnaire.daily_readiness_to_train.info')
            ),
            series: [
              {
                type: 'scatter',
                data: scatterData,
                name: '⬤',
                tooltip: {
                  headerFormat: '',
                  pointFormat: I18n.t('components.dashboards.questionnaire.daily_readiness_to_train.scatter.tooltip')
                }
              }
            ],
            plotOptions: {
              line: {
                color: hcBlack,
                marker: {
                  enabled: false
                }
              }
            },
            yAxis: {
              title: {
                text: I18n.t('components.dashboards.questionnaire.daily_readiness_to_train.yaxis')
              },
              min: 0,
              max: 5.1
            },
            xAxis: xAxisOptions
          }}
        />
      </>
    )
  }
}

export default DailyReadinessToTrainChart
