import React from 'react'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { WithBackend } from '../../backend/BackendProvider'
import { formatDateTime } from '../../common/Utils'

const VoucherEntry = (props) => {
  const { id, code, subscription_type: subscription, quantity, created_at: createdAt, profile_id: redeemedBy, redeemed_at: redeemedAt, deleteVoucher, remaining, limit, total_redemptions: redemptionCount } = props
  const redeemedByText = redeemedBy !== null ? `profile ${redeemedBy}` : `${redemptionCount} profiles`
  return (
    <tr>
      <td key='code'>{limit === 1 ? code.match(/.{1,4}/g).join(' - ') : code}</td>
      <td key='subscriptionType'>{I18n.t(`admin_dashboard.select.${subscription}`)}</td>
      <td key='quantity'>{quantity}</td>
      <td key='createdAt'>{formatDateTime(createdAt)}</td>
      <td key='redeemedBy'>{redeemedByText || '-'}</td>
      <td key='redeemedAt'>{redeemedAt ? formatDateTime(redeemedAt) : '-'}</td>
      <td key='remaining'>{remaining}</td>
      <td key='buttons'>
        <button
          className='button-accent background-accent text-background-color waves-effect waves-light'
          onClick={() => deleteVoucher(id)}
        >
          <FontAwesomeIcon icon='trash' className='button-icon' />
        </button>
      </td>
    </tr>
  )
}

export default WithBackend(VoucherEntry)
