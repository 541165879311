import React from 'react'
import Field from '../common/Field'
import I18n from 'i18n'
import { round } from 'components/common/Math'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TimeAgoElement } from 'components/common/TimeAgo'
import { Link } from 'react-router-dom'
import Routes from '../../../../common/Routes'

const PolarDefaultFlavour = (props) => {
  const icon = props.icon
  const metadatum = props.metadatum

  const polarActivitiesSummary = (dataRow) => {
    const activities = [
      'active-steps',
      'calories',
      'heart_rate_avg',
      'sleep_score',
      'ans_charge'
    ]
    return activities.map(activity => {
      let value
      if (activity === 'calories') {
        value = dataRow.calories + dataRow['active-calories']
      } else {
        value = typeof (dataRow[activity]) === 'object' && dataRow[activity] !== null ? dataRow[activity].minutes : dataRow[activity]
      }
      value = round(value, 2)
      return (
        <Field
          key={activity}
          title={I18n.t(`components.timeline.card.${activity}`)}
          value={value}
        />
      )
    })
  }

  const content = () => {
    if (!props.sdo.data_rows || props.sdo.data_rows.length === 0) {
      return <></>
    }

    return (
      <div className='sport-fields'>
        {polarActivitiesSummary(props.sdo.data_rows[props.sdo.data_rows.length - 1])}
      </div>
    )
  }

  return (
    <div className='timeline-compact-wrapper'>
      {icon &&
        <div className='timeline-icon-compact center-align'>
          <FontAwesomeIcon icon={icon} />
        </div>}
      <div style={{ width: '100%' }}>
        {metadatum &&
          <div className='timeline-compact-title-wrapper'>
            <Link to={Routes.data.showFn(metadatum && metadatum.versioned_data_object_id)}>
              <div className='text-l text-heavy text-primary-color pointer  from-group'>
                {(metadatum && (metadatum.title || metadatum.filename)) || I18n.t('components.data.untitled_file')}
              </div>
            </Link>
            <p className='timeline-profile-details text-s text-muted'>
              <TimeAgoElement date={new Date(metadatum.event_start)} />
            </p>
          </div>}
        {content()}
      </div>
    </div>
  )
}

export default PolarDefaultFlavour
