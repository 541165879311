import React from 'react'

interface Props {
  name: string
  label?: string
  value?: string | number
  onChange?: React.ChangeEventHandler
  disabled?: boolean
  readonly?: boolean
}

const TextArea = (props: Props): React.ReactElement => {
  const { label, name, onChange, value, disabled = false, readonly = false } = props
  return (
    <div className='input-field'>
      <textarea id={name} className='materialize-textarea' onChange={onChange} value={value} disabled={disabled} readOnly={readonly} />
      <label htmlFor={name}>{label}</label>
    </div>
  )
}

export default TextArea
