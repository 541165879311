import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import I18n from 'i18n'
import { WithSession } from '../../session/SessionProvider'
import { MyAxios as axios } from '../../MyAxios'
import {
  defaultProfilePicture,
  roles,
  subscriptionTypes,
  /* momentDateTimeFormat, */ membershipStates, groupTypes
} from '../../common/Constants'
import { copy } from '../../common/CopyToClipboard'
import { WithModal } from '../../modal/ModalProvider'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import MemberList from './MemberList'
import ManageGroupQuestionnaires from './ManageGroupQuestionnaires'
import ReactTooltip from 'react-tooltip'
import RecentActivity from '../../profile/layout/RecentActivity'
import Routes from '../../common/Routes'
import classNames from 'classnames'
/* import moment from 'moment' */
import GroupAnnouncement from './GroupAnnouncement'
import { Toggle } from '../../common/form'
import { capitalizeFirstLetter, smartTranslate } from '../../common/Utils'
import { WithBackend } from '../../backend/BackendProvider'
import GroupAnonymousIcon from './GroupAnonymousIcon'
import { WithCallFeedbackMessageHook } from '../../hooks/CallFeedbackMessage'
import { WithGroupProtocolSubscriptionHook } from '../../hooks/GroupProtocolSubscriptions'
import GroupOverview from 'components/network/layout/GroupOverview'
import TabPanel, { Tab } from 'components/atomic/atoms/TabPanel'
import InfoNotice from 'components/data/layout/detail/InfoNotice'

class GroupDetail extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      edit: null,
      recentActivity: undefined,
      availableProtocols: [],
      protocolSubscriptions: [],
      protocolSubscriptionsRetrieved: false,
      membersWithQuestionnairesEnabled: [],
      muteNotifications: false,
      emailNotifications: false,
      message: '',
      sendAnnouncementDisabled: false,
      currentPage: 1,
      pageCount: 1,
      totalCount: undefined,
      selectedTab: 'activity'
    }
    this.tabs = undefined
  }

  componentDidMount () {
    this.props.fetch ? this.props.fetch() : this.fetchData()
    ReactTooltip.rebuild()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (
      (JSON.stringify(prevProps.group) !== JSON.stringify(this.props.group) ||
        JSON.stringify(prevProps.groupAnnouncement) !== JSON.stringify(this.props.groupAnnouncement)) &&
      this.props.group
    ) {
      this.fetchData()
    }
    if (prevState.currentPage !== this.state.currentPage) {
      this.fetchRecentActivity(this.props.group.id)
    }
  }

  fetchData () {
    if (!this.props.group) {
      return
    }
    const { group } = this.props
    const isAdmin =
      group.my_membership &&
      (group.my_membership.role === roles.owner ||
        group.my_membership.role === roles.administrator)
    const isPremium = [subscriptionTypes.researcher, subscriptionTypes.coach].includes(this.props.myProfile?.subscription?.type)
    this.fetchRecentActivity(group.id)
    if (isAdmin && isPremium) {
      this.fetchAvailableProtocols()
      this.fetchMembersWithQuestionnairesEnabled(group)
      this.fetchProtocolSubscriptions(group)
      this.props.fetchGroupProtocolSubscriptions(group.id)
    }
  }

  setSelectedTab (newTab) {
    this.setState({ selectedTab: newTab })
  }

  handleProfileSearch (e, refetch) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `/api/v1/profiles/search_connections?q=${encodeURIComponent(
          e.target.value
        )}`,
        headers: { Authorization: this.props.sessionToken }
      })
        .then((response) => {
          const members = this.props.group.group_memberships.map(
            (membership) => membership.profile.id
          )
          resolve(
            response.data.filter((profile) => !members.includes(profile.id))
          )
          this.setState({ ...this.state, refetch })
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  setPage (page) {
    this.setState({ currentPage: Math.min(this.state.pageCount, page) })
  }

  fetchRecentActivity (groupId) {
    // TODO paginate
    return axios({
      method: 'GET',
      url: `/api/v1/groups/${groupId}/recent_activity?page=${this.state.currentPage}`,
      headers: { Authorization: this.props.sessionToken }
    }).then((res) =>
      this.setState({ ...this.state, recentActivity: res.data.data, pageCount: res.data.page.total_pages, totalCount: res.data.page.total_count }, () => {
        const elems = document.querySelectorAll('.tabs.groupdetail')
        this.tabs = M.Tabs.init(elems)
      })
    ).catch(err => {
      console.error('Error fetching recent activity:')
      console.log(err)
    })
  }

  fetchAvailableProtocols () {
    // TODO: Move to questionnaire provider
    axios
      .get(process.env.QUESTIONNAIRES_ENGINE_URL + '/api/v1/protocol', {
        headers: { Authorization: this.props.sessionToken }
      })
      .then((res) => {
        this.setState({ availableProtocols: res.data })
      })
      .catch(err => {
        console.error('Error fetching available protocols:')
        console.log(err)
      })
  }

  fetchProtocolSubscriptions (group) {
    this.props.backend.protocolSubscriptions.index(group.id)
      .then((res) => {
        this.setState({ protocolSubscriptions: res.data, protocolSubscriptionsRetrieved: true })
      })
      .catch(err => {
        console.error('Error fetching active protocols:')
        console.log(err)
      })
  }

  fetchMembersWithQuestionnairesEnabled (group) {
    this.props.backend.protocolSubscriptions.collection.membersWithQuestionnairesEnabled(group.id)
      .then((res) => {
        this.setState({ membersWithQuestionnairesEnabled: res.data })
      })
      .catch(err => {
        console.error('Error fetching members with questionnaires enabled:')
        console.log(err)
      })
  }

  handleSendInvite (profile) {
    axios({
      method: 'POST',
      url: `/api/v1/groups/${this.props.group.id}/invite`,
      data: { profile_id: profile.id },
      headers: { Authorization: this.props.sessionToken }
    }).then((_response) => {
      this.props.fetch()
    }).catch(err => {
      console.error('Error sending invite:')
      console.log(err)
    })
  }

  handleCreateProtocolSubscriptions (groupId, protocolName, startDate, endDate, members, invitationTextNl, invitationTextEn) {
    const pm = this.props.backend.protocolSubscriptions.create(
      groupId,
      {
        protocol_subscription: {
          protocol_name: protocolName,
          start_date: startDate,
          end_date: endDate,
          members: members,
          invitation_text_nl: invitationTextNl,
          invitation_text_en: invitationTextEn,
          open_from_day_uses_start_date_offset: true // NOTE: always set to true so we can override offsets ourselves
        }
      })
    this.props.handleCallFeedback(pm,
      I18n.t('flashmessages.questionnaires.creation_success'),
      I18n.t('flashmessages.questionnaires.creation_error')
    )
  }

  handleDestroyProtocolSubscription (groupId, protocolSubscriptionId) {
    const pm = this.props.backend.protocolSubscriptions.destroy(groupId, protocolSubscriptionId)
    this.props.handleCallFeedback(
      pm,
      I18n.t('flashmessages.questionnaires.deletion_success'),
      I18n.t('flashmessages.questionnaires.deletion_error')
    )
  }

  handleUpdateProtocolSubscriptionEndDate (groupId, protocolSubscriptionId, endDate) {
    const pm = this.props.backend.protocolSubscriptions.update(
      groupId,
      protocolSubscriptionId,
      {
        protocol_subscription: {
          end_date: endDate
        }
      }
    )
    this.props.handleCallFeedback(
      pm,
      I18n.t('flashmessages.questionnaires.update_success'),
      I18n.t('flashmessages.questionnaires.update_error')
    )
  }

  showLeaveDialog (group) {
    this.props.setConfirmationDialog({
      onConfirmation: this.props.onLeaveGroup,
      action: 'leave',
      target: group.name
    })
  }

  showDeleteDialog (group) {
    this.props.setConfirmationDialog({
      onConfirmation: () => this.props.onDeleteGroup(group),
      action: 'delete_group',
      target: group.name
    })
  }

  handleChangeRole (membership, roles) {
    this.props.onChangeRole(membership, roles, this.fetchData.bind(this))
  }

  handleChangeNotificationMute (muted) {
    this.setState({ ...this.state, muteNotifications: muted })
    this.props.onUpdateNotificationMute(muted)
  }

  handleChangeEmailNotifications (sendEmailNotifications) {
    this.setState({ ...this.state, emailNotifications: sendEmailNotifications })
    this.props.onUpdateEmailNotifications(sendEmailNotifications)
  }

  handleChangeDailyQuestionnaireNotifications (sendDailyQuestionnaireNotifications) {
    this.setState({ ...this.state, dailyQuestionnaireNotifications: sendDailyQuestionnaireNotifications })
    this.props.onUpdateDailyQuestionnaireNotifications(sendDailyQuestionnaireNotifications)
  }

  handleShareButton (link, group) {
    copy(link)
    /* const _validUntil = moment(group.code_expires_at).format(momentDateTimeFormat) */
    this.props.flashMessages.push(
      I18n.t('flashmessages.general.copied_link_to_clipboard'),
      // The below line should be used again after re-enabling expiration of group invite codes.
      /* I18n.t('flashmessages.general.copied_link_to_clipboard', { validUntil }), */
      this.props.flashMessages.duration.LONG,
      this.props.flashMessages.levels.INFO
    )
  }

  handleAddAnnouncement () {
    if (this.state.sendAnnouncementDisabled) return
    this.setState({ sendAnnouncementDisabled: true }, () => {
      this.props.backend.addGroupAnnouncement(this.props.group.id, { message: this.state.message }).then(_response => {
        this.setState({ message: '', sendAnnouncementDisabled: false }, () => {
          M.updateTextFields()
        })
        this.props.flashMessages.push(
          I18n.t('flashmessages.announcements.sent'),
          this.props.flashMessages.duration.SHORT,
          this.props.flashMessages.levels.INFO)
        this.props.fetch()
        this.tabs[0].select('recent-activities')
      }).catch(err => {
        console.log(err)
        this.setState({ sendAnnouncementDisabled: false })
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        this.props.flashMessages.push(
          errorTitles,
          this.props.flashMessages.duration.LONG,
          this.props.flashMessages.levels.ERROR)
      })
    })
  }

  handleDeleteAnnouncement (groupAnnouncementId) {
    this.props.backend.deleteGroupAnnouncement(this.props.group.id, groupAnnouncementId).then(_response => {
      this.props.flashMessages.push(
        I18n.t('flashmessages.announcements.delete'),
        this.props.flashMessages.duration.SHORT,
        this.props.flashMessages.levels.INFO)
      this.props.fetch()
      this.tabs[0].select('recent-activities')
    }).catch(err => {
      console.log(err)
      const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([_k, v]) => `${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
      this.props.flashMessages.push(
        errorTitles,
        this.props.flashMessages.duration.LONG,
        this.props.flashMessages.levels.ERROR)
    })
  }

  renderGroupDetail () {
    const { group } = this.props
    if (!group || !this.props.myProfile || !this.props.myProfile.id) { return <></> }
    const isAdmin =
      group.my_membership &&
      (group.my_membership.role === roles.owner ||
        group.my_membership.role === roles.administrator)
    const isPremium = [subscriptionTypes.researcher, subscriptionTypes.coach].includes(this.props.myProfile?.subscription?.type)
    const joinUrl = `${process.env.HOST_URL}${Routes.join.codeFn(group.code)}`
    const membersCount = group.group_memberships.filter(membership => membership.state === membershipStates.ACCEPTED).length

    const groupProtocolSubscriptionHandlers = {
      fetchGroupProtocolSubscriptions: this.props.fetchGroupProtocolSubscriptions,
      handleCreateGroupProtocolSubscriptions: this.props.handleCreateGroupProtocolSubscriptions,
      handleDestroyGroupProtocolSubscriptions: this.props.handleDestroyGroupProtocolSubscriptions,
      groupProtocolSubscriptions: this.props.groupProtocolSubscriptions,
      groupProtocolSubscriptionsRetrieved: this.props.groupProtocolSubscriptionsRetrieved
    }

    const groupOverviewEnabled = isAdmin || group.group_type !== groupTypes.ANONYMIZED

    const notificationsEnabled = group.group_type === groupTypes.GROUP ||
                                (isAdmin && group.group_type === groupTypes.ANONYMIZED)

    return (
      <>
        <div className='general-content group-detail'>
          <div className='row profile-row valign-wrapper'>
            <div className='col s4 m3 l2'>
              <div className='picture-wrapper'>
                <img
                  src={group.picture || defaultProfilePicture}
                  className='responsive-img circle'
                  alt={group.name}
                />
              </div>
            </div>
            <div className='col s8 m9 l10'>
              <div
                style={{ marginRight: '-0.75rem' }}
                className='col s12'
              >
                <span className='text-heavy text-primary-color text-xl'>{group.name} </span>
                <span className='text-s text-muted'>
                  <GroupAnonymousIcon groupType={group.group_type} />
                </span>
                <p className='text-l text-muted'>
                  {group.description}
                </p>
              </div>
            </div>
            {isAdmin && group.code.length > 0 && (
              <div className='col data-buttons-wrapper right-align buttons-col'>
                <button
                  className='data-button waves-effect text-m waves-light button-primary text-primary-color background-background text-medium inline-block'
                  onClick={() => this.handleShareButton(joinUrl, group)}
                >
                  <FontAwesomeIcon icon='copy' className='button-icon' />
                  {I18n.t('group.detail.share')}
                </button>
              </div>
            )}
          </div>
          <TabPanel id='group-detail-tabs' onShow={this.setSelectedTab.bind(this)}>
            <Tab id='activity' title={I18n.t('network.group.label.activity')}>
              {this.state.selectedTab === 'activity' && (
                <>
                  {this.props.groupAnnouncement && (
                    <>
                      <div className='row'>
                        <div className='col s12'>
                          <div className='text-heavy text-l text-muted'>
                            {I18n.t('network.group.announcements')}
                          </div>
                        </div>
                      </div>
                      <div className='row group-announcements'>
                        <GroupAnnouncement
                          group={group}
                          isAdmin={isAdmin}
                          groupAnnouncements={this.props.groupAnnouncement}
                          onDeleteAnnouncement={this.handleDeleteAnnouncement.bind(this)}
                        />
                      </div>
                    </>
                  )}
                  <div className='row'>
                    <div className='col s12'>
                      <div className='text-heavy text-l text-muted data-header'>
                        {I18n.t('network.group.recent_activity')} {this.state.totalCount !== undefined && `(${this.state.totalCount})`}
                      </div>
                    </div>
                  </div>
                  <RecentActivity
                    group={group}
                    recentActivity={this.state.recentActivity}
                    goToActivity={this.props.goToActivity}
                    myProfile={this.props.myProfile}
                    currentPage={this.state.currentPage}
                    pageCount={this.state.pageCount}
                    onSetPage={this.setPage.bind(this)}
                  />
                </>
              )}
            </Tab>
            <Tab id='overview' title={I18n.t('network.group.label.overview')} disabled={!groupOverviewEnabled} tooltip={groupOverviewEnabled ? undefined : I18n.t('network.group.only_available_to_group_admins')}>
              {this.state.selectedTab === 'overview' && groupOverviewEnabled && (
                <>
                  <div className='row'>
                    <div className='col s12'>
                      <div className='text-heavy text-l text-muted data-header'>
                        {I18n.t('network.group.label.overview')}
                      </div>
                    </div>
                  </div>
                  <GroupOverview group={group} />
                </>
              )}
            </Tab>
            <Tab id='memberlist' title={`${I18n.t('group.detail.member_list_title')} (${membersCount})`}>
              {this.state.selectedTab === 'memberlist' && (
                <MemberList
                  memberships={group.group_memberships}
                  isAdmin={isAdmin}
                  myMembership={group.my_membership}
                  onDeleteMembership={this.props.onDeleteMembership}
                  onChangeRole={this.handleChangeRole.bind(this)}
                  onSearch={this.handleProfileSearch.bind(this)}
                  onSendInvite={this.handleSendInvite.bind(this)}
                />
              )}
            </Tab>
            <Tab id='questionnaires' title={I18n.t('network.group.questionnaires')} tooltip={I18n.t('network.group.only_available_to_group_admins')} disabled={!isAdmin || !isPremium} showPremiumBadge={!isPremium}>
              {this.state.selectedTab === 'questionnaires' && (
                <>
                  {isAdmin && isPremium && (
                    <ManageGroupQuestionnaires
                      members={this.state.membersWithQuestionnairesEnabled}
                      availableProtocols={this.state.availableProtocols.filter(protocol => !['squash_otr', 'training_log'].includes(protocol.name)) /* Both these protocols have an OTR (One-Time-Response) attached, meaning that they are meant to be invoked from a URL. As such, the questionnaire engine won't send invites for these protocols. This can be confusing for users, hence we don't allow them to selectd these protocols. Both protocols have non-OTR counterparts anyways, and otherwise these protocols would show up twice (as their OTR and non-OTR version), which would lead to even more confusion. */}
                      protocolSubscriptions={this.state.protocolSubscriptions}
                      protocolSubscriptionsRetrieved={this.state.protocolSubscriptionsRetrieved}
                      onCreateProtocolSubscriptions={this.handleCreateProtocolSubscriptions.bind(this)}
                      onDestroyProtocolSubscription={this.handleDestroyProtocolSubscription.bind(this)}
                      onUpdateProtocolSubscriptionEndDate={this.handleUpdateProtocolSubscriptionEndDate.bind(this)}
                      refreshProtocolSubscriptions={() => this.fetchProtocolSubscriptions(group)}
                      group={this.props.group}
                      groupProtocolSubscriptionHandlers={groupProtocolSubscriptionHandlers}
                    />
                  )}
                </>
              )}
            </Tab>
            <Tab id='settings' title={isAdmin ? I18n.t('network.group.settings_and_administration') : I18n.t('network.group.settings')}>
              {this.state.selectedTab === 'settings' && (
                <>
                  {isAdmin && (
                    <>
                      <div className='row'>
                        <div className='col s12'>
                          <div className='text-heavy text-l text-muted'>{I18n.t('network.group.announcements')}</div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='input-field col s12'>
                          <textarea
                            id='new-announcement'
                            className='materialize-textarea'
                            value={this.state.message}
                            onChange={e => this.setState({ message: e.target.value })}
                          />
                          <label htmlFor='new-announcement'>{I18n.t('network.group.write_your_announcement')}</label>
                          <button
                            className={classNames('button-primary background-primary button-autowidth waves-effect waves-light text-background-color', this.state.sendAnnouncementDisabled && 'disabled')}
                            onClick={this.handleAddAnnouncement.bind(this)}
                            disabled={this.state.sendAnnouncementDisabled}
                          >
                            <i className='material-icons left'>send</i>
                            {I18n.t('network.group.send_announcement')}
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {isAdmin && (
                    <div className='row'>
                      <div className='col s12'>
                        <div className='text-heavy text-l text-muted data-header'>{I18n.t('network.group.settings')}</div>
                      </div>
                    </div>
                  )}
                  <div className={classNames('row', !isAdmin && 'margin-top')}>
                    <div className='col s12'>
                      {/* eslint-disable camelcase */}
                      <Toggle
                        disabled={!this.props.group?.my_membership}
                        currentValue={!this.props.group?.my_membership.mute_notifications}
                        onChange={() => this.handleChangeNotificationMute(!this.props.group?.my_membership.mute_notifications)}
                        label={I18n.t('network.group.notify_me_on_group_activity')}
                        fieldName='notifications-toggle'
                      />
                      {/* eslint-enable camelcase */}
                    </div>
                    <div className='col s12'>
                      {/* eslint-disable camelcase */}
                      {notificationsEnabled && <Toggle
                        disabled={!this.props.group?.my_membership || this.props.group?.my_membership.mute_notifications}
                        currentValue={this.props.group?.my_membership.email_notifications}
                        onChange={() => this.handleChangeEmailNotifications(!this.props.group?.my_membership.email_notifications)}
                        label={I18n.t('network.group.notify_me_by_email')}
                        fieldName='email-notifications-toggle'
                                               />}
                      {/* eslint-enable camelcase */}
                    </div>
                    <div className='col s12'>
                      {/* eslint-disable camelcase */}
                      {notificationsEnabled &&
                        <Toggle
                          disabled={!this.props.group?.my_membership || this.props.group?.my_membership.mute_notifications}
                          currentValue={this.props.group?.my_membership.daily_questionnaire_notifications}
                          onChange={() => this.handleChangeDailyQuestionnaireNotifications(
                            !this.props.group?.my_membership.daily_questionnaire_notifications
                          )}
                          label={I18n.t('network.group.notify_me_of_daily_questionnaire_warnings')}
                          fieldName='daily-questionnaire-notifications-toggle'
                        />}
                      {/* eslint-enable camelcase */}
                    </div>
                    <div className='col s12'>
                      {!this.props.group?.my_membership.mute_notificationsthis &&
                        this.props.group?.my_membership.daily_questionnaire_notifications &&
                          <InfoNotice msg={I18n.t('network.group.daily_questionnaire_thresholds_description')} />}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col s12'>
                      {this.props.onDeleteMembership && (
                        <button
                          className='data-button waves-effect text-m waves-light button-primary text-primary-color background-background text-medium inline-block'
                          onClick={() => this.showLeaveDialog(group)}
                        >
                          <FontAwesomeIcon icon='sign-out-alt' className='button-icon' />
                          {I18n.t('network.group.leave_group')}
                        </button>
                      )}
                    </div>
                  </div>
                  {isAdmin && (
                    <div className='row spaced-down'>
                      <div className='col s12'>
                        {this.props.onUpdateGroup && (
                          <button
                            className='data-button waves-effect text-m waves-light button-primary text-primary-color background-background text-medium inline-block'
                            onClick={() => this.props.onUpdateGroup(group, 'edit')}
                          >
                            <FontAwesomeIcon icon='edit' className='button-icon' />
                            {I18n.t('network.group.edit_group')}
                          </button>
                        )}
                        {this.props.onDeleteGroup && (
                          <button
                            className='data-button waves-effect text-m waves-light button-accent text-accent-color background-background text-medium inline-block'
                            onClick={() => {
                              this.showDeleteDialog(group)
                            }}
                          >
                            <FontAwesomeIcon icon='trash' className='button-icon' />
                            {I18n.t('network.group.delete_group')}
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </Tab>
          </TabPanel>
        </div>
      </>
    )
  }

  render () {
    return this.renderGroupDetail()
  }
}

export default WithCallFeedbackMessageHook(WithGroupProtocolSubscriptionHook(WithFlashMessages(WithBackend(WithSession(WithModal(GroupDetail))))))
