import { useEffect, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

const useAlertProcessor = (props) => {
  const [alerts, setAlerts] = useState([])
  const [alertThresholds, setAlertThresholds] = useState(props.alertsToCheckFor)

  useEffect(() => {
    if (props.data) processAlerts()
  }, [props.data, alertThresholds])

  const performComparison = (a, b, comparison) => {
    a = parseInt(a)
    b = parseInt(b)
    let expr = false
    switch (comparison) {
      case '>':
        expr = a > b
        break
      case '>=':
        expr = a >= b
        break
      case '<':
        expr = a < b
        break
      case '<=':
        expr = (a <= b)
        break
    }
    return expr
  }

  const processAlerts = () => {
    const newAlerts = Object.values(alertThresholds).map(alertToCheck => {
      const alertsByType = (props.data || []).reduce((alerts, dp) => {
        // do not add the same type of alert for the same person
        if (alerts.seen.includes(dp.owner.id)) {
          return alerts
        }

        const curData = _.get(dp, 'data_rows[0]', false)
        if (performComparison(curData[alertToCheck.key], alertToCheck.threshold, alertToCheck.comparison)) {
          alerts.seen.push(dp.owner.id)
          alerts.alerts.push({
            title: alertToCheck.description,
            description: `${dp.owner.first_name} ${dp.owner.last_name}`
          })
        }

        return alerts
      }, { seen: [], alerts: [] })

      return alertsByType.alerts
    })

    // This is not supported everywhere setAlerts(newAlerts.flat(1)), hence the
    // following workaround. Note that setAlerts([...newAlerts]) does not give
    // the same result here!
    // Also note that {AlertInDashboard.jsx} pretty much rebuilds the map alertsByType.
    // There is room for optimization here if this gets slow.
    setAlerts([].concat(...newAlerts))
  }

  const handleUpdateThresholds = (e) => {
    const alertsCopy = { ...alertThresholds }
    alertsCopy[e.target.name].threshold = e.target.value
    setAlertThresholds(alertsCopy)
  }

  return { alerts: alerts, alertThresholds: alertThresholds, handleUpdateThresholds: handleUpdateThresholds }
}

useAlertProcessor.propTypes = {
  alertsToCheckFor: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      key: PropTypes.string,
      comparison: PropTypes.string,
      defaultThreshold: PropTypes.number
    })
  ),
  data: PropTypes.array.isRequired
}

export { useAlertProcessor }
