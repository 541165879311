import React from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

const Notification = (props) => {
  const id = props.groupId === undefined ? props.id : props.groupId
  return (
    <div className='row notification-row valign-wrapper' onClick={() => props.markNotification(id)}>
      <div className='col s2'>
        <FontAwesomeIcon icon={props.icon} size='2x' className='notification-type' />
      </div>
      <div className='col s10'>
        <div className='row notifications-title'>
          <div className='col s12 text-m'>
            {props.link && props.link.startsWith('http') && (
              <a
                className={classNames(!props.read && 'unread-notification')}
                href={props.link}
                onClick={() => props.viewNotification(id)}
              >
                {props.title}
              </a>
            )}
            {props.link && !props.link.startsWith('http') && (
              <Link
                className={classNames(!props.read && 'unread-notification')}
                to={props.link}
                onClick={() => props.viewNotification(id)}
              >
                {props.title}
              </Link>
            )}
          </div>
        </div>
        <div className='row notification-timestamp'>
          <div className='col s12 text-s'>
            {moment(props.created_at).fromNow()}
          </div>
        </div>
      </div>
      <div className='col s2'>
        {props.link && props.link.startsWith('http') && (
          <a href={props.link} onClick={() => props.viewNotification(id)}>
            <FontAwesomeIcon icon='arrow-circle-right' size='2x' className='pointer-grow notification-view' />
          </a>
        )}
        {props.link && !props.link.startsWith('http') && (
          <Link to={props.link} onClick={() => props.viewNotification(id)}>
            <FontAwesomeIcon icon='arrow-circle-right' size='2x' className='pointer-grow notification-view' />
          </Link>
        )}
      </div>
    </div>
  )
}

export default Notification
