import React from 'react'
import PropTypes from 'prop-types'
import { VictoryAnimation } from 'victory'
import PlayerAvatar from './soccerreplayelems/PlayerAvatar'
import _ from 'lodash'
import {
  conversionMultiplier,
  soccerFieldHeight,
  soccerFieldWidth,
  soccerTeamColor,
  soccerTeamLabelColor
} from '../../../common/Constants'

/*
** Expects player data in the following format:
**  { positions: [[10, 10], [50, 100], [525, 340]], team: 1, number: 3},
    { positions: [[30, 40], [40, 50], [50, 60]], team: 2, number: 7},
*/
export default class SoccerField extends React.Component {
  constructor (props) {
    super(props)
    this.field = {
      width: soccerFieldWidth * 10,
      height: soccerFieldHeight * 10
    }
  }

  convertToSVGPosition (coordinate) {
    return coordinate * conversionMultiplier
  }

  getPlayerAvatar (player, index) {
    const color = { color: soccerTeamColor[player.team], labelColor: soccerTeamLabelColor[player.team] }
    if (this.props.animate) {
      return (
        <VictoryAnimation
          duration={this.props.sectionDuration} easing='linear' data={player.positions}
          onEnd={this.props.onEnd} key={index}
        >
          {(position, _animationInfo) =>
            <PlayerAvatar
              x={this.convertToSVGPosition(position.x)}
              y={this.convertToSVGPosition(position.y)}
              id={`player${index}-avatar`}
              key={`player${index}-avatar`}
              number={player.number}
              color={color}
            />}
        </VictoryAnimation>
      )
    } else {
      return (
        <PlayerAvatar
          x={this.convertToSVGPosition(player.positions[0].x)}
          y={this.convertToSVGPosition(player.positions[0].y)}
          id={`player${index}-avatar`}
          key={`player${index}-avatar`}
          number={player.number}
          color={color}
        />
      )
    }
  }

  getPlayersAvatars (players) {
    const playerAvatars = _.map(
      players,
      this.getPlayerAvatar.bind(this)
    )
    return playerAvatars
  }

  render () {
    const { playerData: players, id } = this.props
    return (
      <svg id={id} className='soccer-field' viewBox={`0 0 ${this.field.width} ${this.field.height}`}>
        {this.getPlayersAvatars(players)}
      </svg>
    )
  }
}

SoccerField.propTypes = {
  animate: PropTypes.bool,
  sectionDuration: PropTypes.number,
  id: PropTypes.string,
  onEnd: PropTypes.func,
  playerData: PropTypes.array
}

SoccerField.defaultProps = {
  animate: false,
  sectionDuration: 1000
}
