import React from 'react'
import _ from 'lodash'
import I18n from 'i18n'

import GroupedBarsChart from '../../common/GroupedBarsChart'
import { BODY_TO_COLOR, momentDateFormat } from '../../../../common/Constants'
import moment from 'moment'

const ReportedComplaintsChart = (props) => {
  const { questionnaires, barColors } = props

  const weeklyComplaints = []
  questionnaires.forEach(questionnaire => {
    const { week, answers } = questionnaire

    if (!weeklyComplaints[week]) {
      weeklyComplaints[week] = {}
    }

    if (answers === null) {
      return
    }

    const { injury: injuriesObj, illness } = answers

    const bars = weeklyComplaints[week]

    // injuriesObj could still be null
    if (injuriesObj) {
      Object.keys(injuriesObj).forEach(injury => {
        if (!bars[injury]) {
          bars[injury] = 0
        }
        bars[injury] += 1
      })
    }

    if (illness) {
      if (!bars.illness) {
        bars.illness = 0
      }
      bars.illness += 1
    }
  })

  const entries = _.map(Object.entries(weeklyComplaints), ([week, complaints]) => {
    const bars = _.entries(complaints).map(([bodyPart, count]) => {
      return {
        value: count,
        param: bodyPart
      }
    })

    return {
      label: moment(week),
      bars
    }
  })

  const properties = _.mapValues(BODY_TO_COLOR, (color) => { return { color } })

  const tooltips = {
    title: (tooltipItems) => tooltipItems[0].xLabel.format(momentDateFormat),
    label: (item, data) => {
      const dataset = data?.datasets?.[item?.datasetIndex]

      const bodyPart = dataset?.label
      const complaints = dataset?.data?.[item?.index]

      return I18n.t('components.dashboards.questionnaire.complaints.reported_complaints.tooltip', {
        bodyPart: I18n.t(`components.dashboards.questionnaire.complaints.body_parts.${bodyPart}`),
        complaints
      })
    }
  }

  const options = {
    suggestedMin: 0,
    suggestedMax: 1,
    title: I18n.t('components.dashboards.questionnaire.complaints.reported_complaints.title'),
    stacked: true,
    legendPosition: 'left',
    legendWidth: 10,
    disableLegend: true,
    tooltipCallbacks: tooltips
  }

  const xAxis = {
    scaleLabel: {
      display: true,
      labelString: I18n.t('components.dashboards.questionnaire.complaints.injuries_over_time.x_axis')
    },
    ticks: {
      callback: (value) => value.week()
    }
  }

  const yAxis = {
    ticks: {
      stepSize: 1
    }
  }

  return (
    <GroupedBarsChart entries={entries} paramProperties={properties} {...options} xAxis={xAxis} yAxis={yAxis} barColors={barColors} />
  )
}

export default ReportedComplaintsChart
