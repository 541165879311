import React from 'react'
import DirectUploader from '../../../helpers/DirectUploader'
import DragMessage from '../DragMessage'
import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import I18n from 'i18n'
import { MyAxios as axios } from '../../../MyAxios'
import { momentDateTimeFormat, MULTIFILE_SPORTS } from '../../../common/Constants'
import moment from 'moment'

export default class VersionsView extends React.Component {
  constructor (props) {
    super(props)
    this.handleDrop = this.handleDrop.bind(this)
    this.direct_uploader = new DirectUploader()
    this.direct_uploader.setSessionToken(this.props.sessionToken)
    this.state = {
      isMultipart: props.data.versioned_data_object.metadatum.sport !== null ? MULTIFILE_SPORTS.indexOf(props.data.versioned_data_object.metadatum.sport.name) > -1 : false
    }
    this.handleMultipartFileUpload = this.handleMultipartFileUpload.bind(this)
  }

  constructObject (blob) {
    return { id: this.props.data.versioned_data_object.id, new_version: { data_blob: blob } }
  }

  async handleMultipartFileUpload (dataBlob) {
    const body = this.constructObject(dataBlob)
    await this.upload(body)
  }

  async uploadSingleDropFile (file) {
    /* eslint-disable no-unused-vars */
    const [blob, _fieldName] = await this.direct_uploader.uploadFile(file)
    /* eslint-enable no-unused-vars */
    const body = this.constructObject([blob.signed_id])
    await this.upload(body)
  }

  async upload (dataBlob) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: this.props.sessionToken
    }
    axios({ method: 'POST', url: `/api/v1/data/${dataBlob.id}/versions`, headers: headers, data: dataBlob }).then((response) => {
      this.props.handleSuccess(this.props.data.versioned_data_object.id)
    }).catch((err) => {
      console.log(err)
    })
  }

  handleDrop (files) {
    this.uploadSingleDropFile(files[0])
  }

  renderVersions () {
    let key
    let selected
    const currentVersionIdx = this.props.data.versioned_data_object.current_version_idx
    return this.props.data.versions.map((version, idx) => {
      key = `${this.props.data.versioned_data_object.id}-${version.id}`
      selected = idx === currentVersionIdx
      return (
        <li className='collection-item  pointer-non-hoverable version-item' key={key} onClick={() => this.props.onClick(this.props.data.versioned_data_object.id, idx)}>
          <div className='row valign-wrapper'>
            <div className='col s10'>
              <span>{version.filename}</span>
              -
              <span className='text-s'>
                {I18n.t(
                  'components.data.uploaded_at',
                  { time: moment(new Date(version.created_at)).format(momentDateTimeFormat) }
                )}
              </span>
            </div>
            <div className='col s2'>
              <span className='secondary-content'>
                <i className={`material-icons ${(selected && 'selected-version') || 'not-selected-version'}`}>check</i>
              </span>
            </div>
          </div>
        </li>
      )
    })
  }

  renderDropzone () {
    return (
      <Dropzone onDrop={this.handleDrop}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div>
            <div {...getRootProps()} className={classNames('dropzone', 'center-align', { 'dropzone--isActive': isDragActive })}>
              <input type='file' id='dropzone-input' {...getInputProps()} />
              <DragMessage
                isDragActive={isDragActive}
                dragActiveText={I18n.t('components.data.drag_active_text')}
                dragInActiveText={I18n.t('components.data.drag_inactive_text')}
              />
            </div>
          </div>
        )}
      </Dropzone>
    )
  }

  render () {
    if (!this.props.data.versioned_data_object || !this.props.data.versions) {
      return (<></>)
    }

    return (
      <>
        <div>
          <div className='text-heavy text-l text-muted data-header'>{I18n.t('components.data.upload_new_version')}</div>
          <br />
          {this.renderDropzone()}
          <br />
          <div className='text-heavy text-l text-muted data-header'>{I18n.t('components.data.available_versions')}</div>
          <ul className='collection'>{this.renderVersions()}</ul>
        </div>
      </>
    )
  }
}
