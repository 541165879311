import React, { useState, useEffect } from 'react'
import I18n from 'i18n'
import _ from 'lodash'
import moment from 'moment'
import PlayerDropdown from './PlayerDropdown'
import { soccerMetrics, soccerPlayerComparisonTableMetrics, metricName, soccerTeamColor, soccerTeamLabelColor, soccerDecimalRounding } from '../../../common/Constants'

const SoccerPlayerComparisonTable = (props) => {
  const { players, teams, datasetId } = props

  const defaultPlayers = {
    playerLeft: players.length > 0 ? 0 : '',
    playerRight: players.length > 1 ? 1 : ''
  }
  const [playerLeft, setPlayerLeft] = useState(defaultPlayers.playerLeft)
  const [playerRight, setPlayerRight] = useState(defaultPlayers.playerRight)

  const ids = {
    playerLeftDropdown: 'player-left-comparison-dropdown',
    playerLeftTable: 'player-left-comparison-table',
    playerRightDropdown: 'player-right-comparison-dropdown',
    playerRightTable: 'player-right-comparison-table'
  }
  const metrics = soccerPlayerComparisonTableMetrics

  function getPlayerStatsList (playerData, metrics) {
    const playerStatsList = {}
    for (const metric of metrics) {
      const path = soccerMetrics[metric].path
      let statValue = _.at(playerData, path)[0] || I18n.t('components.dashboards.soccer.player_comparison_table.no_data')
      switch (soccerMetrics[metric].unit) {
        case 'min':
          statValue = Math.floor(moment.duration(statValue, 's').asMinutes())
          break
        case 'km/h':
          statValue = _.round(statValue, soccerDecimalRounding)
      }
      playerStatsList[metric] = statValue.toString()
    }
    return playerStatsList
  }

  function getPlayerStyle (team) {
    return { backgroundColor: soccerTeamColor[team], color: soccerTeamLabelColor[team] }
  }

  function createStatsTable (player1Data, player2Data, metrics) {
    const statsList1 = getPlayerStatsList(player1Data, metrics)
    const statsList2 = getPlayerStatsList(player2Data, metrics)

    const header = (
      <tr>
        <th>{I18n.t(soccerMetrics[metricName].title)}</th>
        <th style={getPlayerStyle(player1Data.team)}>{statsList1[metricName]}</th>
        <th style={getPlayerStyle(player2Data.team)}>{statsList2[metricName]}</th>
      </tr>
    )

    const rowList = []
    const concatenatedStats = _.union(_.keys(statsList1), _.keys(statsList2))
    concatenatedStats.splice(concatenatedStats.indexOf(metricName), 1) // Remove player name
    for (const stat of concatenatedStats) {
      let statTitle = I18n.t(soccerMetrics[stat].title)
      if (soccerMetrics[stat].unit) {
        statTitle += ' (' + soccerMetrics[stat].unit + ')'
      }
      rowList.push(
        <tr key={stat}>
          <th>{statTitle}</th>
          <td>{statsList1[stat]}</td>
          <td>{statsList2[stat]}</td>
        </tr>
      )
    }
    return (
      <table className='soccer-dashboard-table' id='soccer-player-comparison-table'>
        <thead>{header}</thead>
        <tbody>{rowList}</tbody>
      </table>
    )
  }

  function handleChangePlayerLeft (e) {
    const newPlayer = parseInt(e.target.value)
    setPlayerLeft(newPlayer)
  }

  function handleChangePlayerRight (e) {
    const newPlayer = parseInt(e.target.value)
    setPlayerRight(newPlayer)
  }

  useEffect(() => {
    const playerIds = _.keys(players)
    setPlayerLeft(playerIds[0] || '')
    setPlayerRight(playerIds[1] || '')
  }, [players, teams])

  const teamNames = teams.map(team => { return team.name })

  const leftPlayerSummary = players[playerLeft] || {}
  const rightPlayerSummary = players[playerRight] || {}
  const combinedStatsList = createStatsTable(leftPlayerSummary, rightPlayerSummary, metrics)

  return (
    <div className='soccer-player-comparison-table'>
      <div className='row'>
        <div className='col s6'>
          <PlayerDropdown
            id={ids.playerLeftDropdown}
            datasetId={datasetId}
            players={players}
            label={I18n.t('components.dashboards.soccer.selectors.player1')}
            onChange={handleChangePlayerLeft}
            defaultValue={defaultPlayers.playerLeft}
            teamNames={teamNames}
          />
        </div>
        <div className='col s6'>
          <PlayerDropdown
            id={ids.playerRightDropdown}
            datasetId={datasetId}
            players={players}
            label={I18n.t('components.dashboards.soccer.selectors.player2')}
            onChange={handleChangePlayerRight.bind(this)}
            defaultValue={defaultPlayers.playerRight}
            teamNames={teamNames}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          {combinedStatsList}
        </div>
      </div>
    </div>
  )
}

export default SoccerPlayerComparisonTable
