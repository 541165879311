import React from 'react'
import I18n from 'i18n'
import TimelineRoutes from './TimelineRoutes'
import { WithSession } from '../session/SessionProvider'
import { WithFlashMessages } from '../flashmessages/FlashMessageProvider'
import { MyAxios as axios } from '../MyAxios'
import { WithModal } from '../modal/ModalProvider'
import GroupDetail from '../network/layout/GroupDetail'
import { isUnauthenticatedError } from '../common/ErrorChecking'
import { debounce } from 'lodash'
import WithProfileMetadata from 'components/helpers/WithProfileMetadata'

class TimelineController extends React.Component {
  constructor (props) {
    super(props)
    this.initial_state = {
      timeline: {
        nextPage: 1, // pointer to next page
        total: 0,
        metadataIdSeq: new Set(), // ID sequence that determines ordering of timeline entries
        metadata: {}, // metadatum.ID => metadatum mapping
        data: {}, // VDO.versioned_data_object_id => VDO mapping
        lastContentFetch: {}, // VDO.versioned_data_object_id => last fetch timestamp
        isFetching: {}, // VDO.versioned_data_object_id => boolean (is fetching )
        isLoading: true // boolean whether a new metadata page is being fetched
      },
      network: {
        nextPage: 1, // pointer to next page
        total: 0,
        metadataIdSeq: new Set(), // ID sequence that determines ordering of timeline entries
        metadata: {}, // metadatum.ID => metadatum mapping
        data: {}, // VDO.versioned_data_object_id => VDO mapping
        lastContentFetch: {}, // VDO.versioned_data_object_id => last fetch timestamp
        isFetching: {}, // VDO.versioned_data_object_id => boolean (is fetching )
        isLoading: true // boolean whether a new metadata page is being fetched
      },
      query: undefined
    }
    this.state = this.initial_state
    this.handleRefetchTimelineMetaData = debounce(this.handleRefetchTimelineMetaData.bind(this), 2000)
    this.minRefetchDelay = 10000 // wait at least 10 seconds before refetching timeline data
  }

  logErrorToUser (err) {
    // It could be the request is pending while a user logs out. In that
    // case, don't show a message.
    if (isUnauthenticatedError(err)) { return }
    this.props.flashMessages.push(I18n.t('errors.try_again'), this.props.flashMessages.duration.LONG, this.props.flashMessages.levels.ERROR)
    console.log(err)
  }

  handleRequest (body, method, endpoint, formName, sessionToken, handleSuccess) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: this.props.sessionToken
    }
    axios({ method, url: endpoint, headers: headers, data: body }).then((response) => {
      handleSuccess(response)
    }).catch((err) => {
      this.logErrorToUser(err)
    })
  }

  fetchGroup (id) {
    return axios({
      method: 'GET',
      url: `/api/v1/groups/${id}`,
      headers: { Authorization: this.props.sessionToken }
    }).then(res => res.data).catch((err) => {
      this.logErrorToUser(err)
    })
  }

  async handleShowGroupDetail (id) {
    const group = await this.fetchGroup(id)

    this.props.setModalComponent(GroupDetail, {
      group: group
    }).catch((err) => {
      this.logErrorToUser(err)
    })
  }

  handleInit (timelineGroup = undefined) {
    // this.props.refetchSession() // This isn't needed here because it's already being called elsewhere
    let newState = { ...this.initial_state }
    if (timelineGroup && timelineGroup === 'network') {
      newState = { timeline: { ...this.state.timeline }, network: { ...this.initial_state.network } }
    } else if (timelineGroup && timelineGroup === 'own') {
      newState = { network: { ...this.state.network }, timeline: { ...this.initial_state.timeline } }
    }
    this.setState(newState, () => {
      if (!timelineGroup || timelineGroup === 'network') {
        this.handleNextNetworkPage()
      } else {
        this.setState({ network: { ...this.state.network, isLoading: false } })
      }
      if (!timelineGroup || timelineGroup === 'own') {
        this.handleNextTimelinePage()
      } else {
        this.setState({ timeline: { ...this.state.timeline, isLoading: false } })
      }
    })
  }

  handleAfterUpload () {
    /*
     * Reset to initial state and fetch the first page of both
     * the timeline and network component
     */
    this.handleInit()
  }

  // TODO: the methods below are namespaces for 'Timeline' and 'Network'
  //       this causes quite a bit of duplication which needs to be refactored

  /**
   * Adds a new page of metadata to the timeline state
   */
  addTimelinePage (data) {
    /**
     * Extract the IDs from the response and append them to the ID sequence
     */
    const metadataIdSeq = new Set([
      ...this.state.timeline.metadataIdSeq,
      ...data.data.map((metadatum) => metadatum.id)
    ])

    /*
     * Append fetched metadata to state, mapping the ID => metadatum
     */
    const metadata = this.state.timeline.metadata
    // eslint-disable no-return-assign
    data.data.forEach((metadatum) => {
      metadata[metadatum.id] = metadatum
    })
    // eslint-enable no-return-assign
    this.setState({
      timeline: {
        ...this.state.timeline,
        metadata,
        metadataIdSeq,
        nextPage: data.has_next_page ? parseInt(data.current_page) + 1 : null,
        total: data.total,
        isLoading: false
      }
    })
  }

  /**
   * Fetches the next page of timeline data
   */
  handleNextTimelinePage () {
    if (this.state.timeline.nextPage === null) {
      /*
       * No new page to fetch
       */
      return
    }

    /*
     * Set current state to loading in order to prevent another fetch from being triggered
     */
    this.setState({ timeline: { ...this.state.timeline, isLoading: true } })
    axios({
      method: 'GET',
      url: `/api/v1/timeline/my_metadata?page=${this.state.timeline.nextPage || 1}`,
      headers: { Authorization: this.props.sessionToken }
    }).then(res => this.addTimelinePage(res.data)).catch((err) => {
      this.logErrorToUser(err)
    })
  }

  handleFetchTimelineContent (id) {
    /*
     * id = vdo id
     * Set current state to isFetching to prevent another fetch until this is complete.
     * Also set the current time as latest fetch timestamp for the current VDO
     */
    const lastContentFetch = this.state.timeline.lastContentFetch
    const isFetching = this.state.timeline.isFetching
    const attemptCount = (lastContentFetch[id] && lastContentFetch[id].attempts + 1) || 1
    lastContentFetch[id] = { timestamp: new Date().getTime(), attempts: attemptCount }
    isFetching[id] = true

    this.setState({
      timeline: {
        ...this.state.timeline,
        lastContentFetch,
        isFetching
      }
    }, () => {
      /*
       * Once the current state reflects that a query is in progress, actually execute the query
       */
      axios({
        method: 'GET',
        url: `/api/v1/data/${id}`
      }).then(res => {
        /*
         * Query complete: store data in timeline state and set
         * isFetching to false to allow new requests
         */

        const data = this.state.timeline.data
        const isFetching = this.state.timeline.isFetching
        data[id] = res.data
        isFetching[id] = false
        this.setState({ timeline: { ...this.state.timeline, data, isFetching } })
      }).catch((err, dat) => {
        // Don't log any errors if the data has been deleted in the meantime
        if (err.response.status === 404) {
          return
        }
        this.logErrorToUser(err)
      })
    })
  }

  handleRefetchTimelineMetaData (id) {
    /*
     * id = metadatum id
     * Set current state to isFetching to prevent another fetch until this is complete.
     */
    const isFetching = this.state.timeline.isFetching
    isFetching[this.state.timeline.metadata[id].versioned_data_object_id] = true
    this.setState({ timeline: { ...this.state.timeline, isFetching } }, () => {
      axios({
        method: 'GET',
        url: `/api/v1/metadata/${this.state.timeline.metadata[id].versioned_data_object_id}`,
        headers: { Authorization: this.props.sessionToken }
      }).then((res) => {
        /*
         * Store the refereshed metadata in the mapping ID => metadatum
         * Then do a follow-up request that retrieves the actual content of the VDO
         */
        const metadata = { ...this.state.timeline.metadata }
        metadata[res.data.id] = res.data
        this.setState({
          timeline: { ...this.state.timeline, metadata }
        }, () => this.handleFetchTimelineContent(metadata[id].versioned_data_object_id))
      }).catch((err) => {
        this.logErrorToUser(err)
      })
    })
  }

  /**
   * Adds a new page of metadata to the timeline state
   */
  addNetworkPage (data) {
    /**
     * Extract the IDs from the response and append them to the ID sequence
     */
    const metadataIdSeq = new Set([
      ...this.state.network.metadataIdSeq,
      ...data.data.map((metadatum) => metadatum.metadatum.id)
    ])

    /*
     * Append fetched metadata to state, mapping the ID => metadatum
     */
    const metadata = this.state.network.metadata
    data.data.forEach((metadatum) => { metadata[metadatum.metadatum.id] = metadatum.metadatum; metadata[metadatum.metadatum.id].group = metadatum.group })
    this.setState({
      network: {
        ...this.state.network,
        metadata,
        metadataIdSeq,
        nextPage: data.has_next_page ? parseInt(data.current_page) + 1 : null,
        total: data.total,
        isLoading: false
      }
    })
  }

  /**
   * Fetches the next page of timeline data
   */
  handleNextNetworkPage () {
    if (this.state.network.nextPage === null) {
      /*
       * No new page to fetch
       */
      return
    }

    /*
     * Set current state to loading in order to prevent another fetch from being triggered
     */
    this.setState({ network: { ...this.state.network, isLoading: true } })
    axios({
      method: 'GET',
      url: `/api/v1/timeline/network_metadata?page=${this.state.network.nextPage || 1}${this.state.query ? `&query=${this.state.query}` : ''}`,
      headers: { Authorization: this.props.sessionToken }
    }).then(res => this.addNetworkPage(res.data)).catch((err) => {
      this.logErrorToUser(err)
    })
  }

  handleFetchNetworkContent (id, network = false) {
    /*
     * id = vdo id
     * Set current state to isFetching to prevent another fetch until this is complete.
     * Also set the current time as latest fetch timestamp for the current VDO
     */
    const lastContentFetch = this.state.network.lastContentFetch
    const isFetching = this.state.network.isFetching
    const attemptCount = (lastContentFetch[id] && lastContentFetch[id].attempts + 1) || 1
    lastContentFetch[id] = { timestamp: new Date().getTime(), attempts: attemptCount }
    isFetching[id] = true

    this.setState({
      network: {
        ...this.state.network,
        lastContentFetch,
        isFetching
      }
    }, () => {
      /*
       * Once the current state reflects that a query is in progress, actually execute the query
       */
      axios({
        method: 'GET',
        url: `/api/v1/data/${id}`
      }).then(res => {
        /*
         * Query complete: store data in timeline state and set
         * isFetching to false to allow new requests
         */
        const data = this.state.network.data
        const isFetching = this.state.network.isFetching
        data[id] = res.data
        isFetching[id] = false
        this.setState({ network: { ...this.state.network, data, isFetching } })
      }).catch((err) => {
        // Don't log any errors if the data has been deleted in the meantime
        if (err.response.status === 404) {
          return
        }
        this.logErrorToUser(err)
      })
    })
  }

  handleRefetchNetworkMetaData (id, network = false) {
    /*
     * id = metadatum id
     * Set current state to isFetching to prevent another fetch until this is complete.
     */
    const isFetching = this.state.network.isFetching
    isFetching[this.state.network.metadata[id].versioned_data_object_id] = true
    this.setState({ network: { ...this.state.network, isFetching } }, () => {
      axios({
        method: 'GET',
        url: `/api/v1/metadata/${this.state.network.metadata[id].versioned_data_object_id}`,
        headers: { Authorization: this.props.sessionToken }
      }).then((res) => {
        /*
         * Store the refereshed metadata in the mapping ID => metadatum
         * Then do a follow-up request that retrieves the actual content of the VDO
         */
        const metadata = { ...this.state.network.metadata }
        metadata[res.data.id] = res.data
        this.setState({
          network: { ...this.state.network, metadata }
        }, () => this.handleFetchNetworkContent(metadata[id].versioned_data_object_id))
      }).catch((err) => {
        this.logErrorToUser(err)
      })
    })
  }

  handleNetworkFilter (query) {
    this.setState({ query: query }, () => {
      this.handleInit('network')
    })
  }

  render () {
    return (
      <>
        <TimelineRoutes
          timelineProps={{
            minRefetchDelay: this.minRefetchDelay,
            metadata: this.state.timeline.metadata,
            metadataIdSeq: this.state.timeline.metadataIdSeq,
            data: this.state.timeline.data,
            isLoading: this.state.timeline.isLoading,
            lastContentFetch: this.state.timeline.lastContentFetch,
            isFetching: this.state.timeline.isFetching,
            total: this.state.timeline.total,
            onNextPage: this.handleNextTimelinePage.bind(this),
            onFetchContent: this.handleFetchTimelineContent.bind(this),
            onAfterUpload: this.handleAfterUpload.bind(this),
            onRequest: this.handleRequest.bind(this),
            onRefetchMetaData: this.handleRefetchTimelineMetaData.bind(this),
            onLoadFirstPage: this.handleNextTimelinePage.bind(this),
            onInit: this.handleInit.bind(this),
            datasetCount: this.props.myProfileMetadata.own_dataset_count
          }} networkProps={{
            minRefetchDelay: this.minRefetchDelay,
            metadata: this.state.network.metadata,
            metadataIdSeq: this.state.network.metadataIdSeq,
            data: this.state.network.data,
            isLoading: this.state.network.isLoading,
            lastContentFetch: this.state.network.lastContentFetch,
            isFetching: this.state.network.isFetching,
            total: this.state.network.total,
            onNextPage: this.handleNextNetworkPage.bind(this),
            onFetchContent: this.handleFetchNetworkContent.bind(this),
            onAfterUpload: this.handleAfterUpload.bind(this),
            onRequest: this.handleRequest.bind(this),
            onRefetchMetaData: this.handleRefetchNetworkMetaData.bind(this),
            onLoadFirstPage: this.handleNextNetworkPage.bind(this),
            onShowGroupDetails: this.handleShowGroupDetail.bind(this),
            onInit: this.handleInit.bind(this),
            datasetCount: this.props.myProfileMetadata.network_dataset_count,
            handleTimelineFilter: this.handleNetworkFilter.bind(this)
          }}
        />
      </>
    )
  }
}

export default WithFlashMessages(WithSession(WithModal(WithProfileMetadata(TimelineController))))
