import React, { useState } from 'react'
import I18n from 'i18n'
import BackButton from '../../common/BackButton'
import { WithQuestionnaires } from '../../questionnaires/QuestionnaireProvider'
import { Link } from 'react-router-dom'
import PremiumOverlay from '../../premium_membership/PremiumOverlay'
import { subscriptionTypes } from '../../common/Constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import TrainingLogButton from '../../common/TrainingLogButton'
import QuestionnaireDashboardPicker from '../../analysis/dashboards/questionnaire/QuestionnaireDashboardPicker'
import Info from '../../atomic/atoms/Info'
import ClimbingAccidentButton from 'components/common/ClimbingAccidentButton'

const buttonClasses = 'button-primary background-primary button-autowidth waves-effect waves-light text-background-color'

const QuestionnaireCard = props => {
  const innerCard = () => {
    return (
      <div className='data-overview-card'>
        <div className='row'>
          <div className='timeline-icon center-align'>
            <FontAwesomeIcon icon={props.icon} />
          </div>
          <div className='col s12'>
            <p className='text-l'>{props.tagline}</p>
          </div>
        </div>
        {props.children}
      </div>
    )
  }

  const cardContents = () => {
    if (props.premium) {
      return (
        <PremiumOverlay activeFor={[subscriptionTypes.coach, subscriptionTypes.researcher]} showButton>
          {innerCard()}
        </PremiumOverlay>
      )
    }
    return innerCard()
  }

  return (
    <>
      <div className='row'>
        <div className='col s12 questionnaire-card'>
          <div className={classNames('background-background questionnaire-card-with-background-image', props.className)}>
            {cardContents()}
          </div>
        </div>
      </div>
    </>
  )
}

const QuestionnaireLanding = props => {
  const [questionnaireButtonsDisabled, setQuestionnaireButtonsDisabled] = useState(false)

  const handleClick = e => {
    if (questionnaireButtonsDisabled) {
      e.preventDefault()
      return
    }
    setQuestionnaireButtonsDisabled(true)
  }

  const renderQuestionnairesEnabled = () => {
    // precondition: questionnaires are enabled
    const responseButtons = []
    const seenQuestionnaires = new Set()
    for (const response of props.questionnaires.myOpenResponses) {
      if (seenQuestionnaires.has(response.questionnaire.key)) continue
      seenQuestionnaires.add(response.questionnaire.key)
      responseButtons.push({
        url: props.questionnaires.openQuestionnaireUrlForUuid(response.uuid),
        invitationTexts: response.invitation_texts,
        title: (response.questionnaire.title.length > 0 ? response.questionnaire.title : response.questionnaire.key),
        key: response.questionnaire.key,
        uuid: response.uuid
      })
    }

    return (
      <>
        <div className='row'>
          <div className='col s12 text-heavy muted-header'>
            <h5>{I18n.t('components.static.questionnaires.fill_out_questionnaires.title')}</h5>
          </div>
        </div>
        <QuestionnaireCard
          icon='sliders-h'
          tagline={I18n.t('components.static.questionnaires.fill_out_questionnaires.tagline')}
          className='questionnaire-questionnaire'
        >
          <div className='row'>
            <div className='col s12'>
              {responseButtons.map(responseButton => {
                return (
                  <React.Fragment key={responseButton.uuid}>
                    {responseButton.invitationTexts.map(invitationText => (
                      <p key={invitationText}>{invitationText}</p>
                    ))}
                    <div className='contains-button-badge contains-wider-button'>
                      <a
                        className={classNames(
                          'waves-effect text-m waves-light button-primary text-background-color background-primary text-medium data-button wider-data-button smart-height-data-button',
                          questionnaireButtonsDisabled && 'disabled-button')}
                        href={responseButton.url}
                        onClick={handleClick}
                      >
                        <FontAwesomeIcon icon='sliders-h' className='button-icon' />
                        {I18n.t('components.welcome.fill_out', { title: I18n.t(`group.detail.questionnaires.questionnaires.${responseButton.key}`) })}
                      </a>
                      <span className='button-badge' />
                    </div>
                  </React.Fragment>
                )
              })}
              <TrainingLogButton widerButton />
              <ClimbingAccidentButton widerButton />
            </div>
          </div>
        </QuestionnaireCard>
      </>
    )
  }

  const inviteOthersToFillOutQuestionnaires = () => {
    return (
      <>
        <div className='row'>
          <div className='col s12 text-heavy muted-header'>
            <h5>{I18n.t('components.static.questionnaires.invite_others_to_fill_out_questionnaires.title')}</h5>
          </div>
        </div>
        <QuestionnaireCard
          icon='users'
          tagline={I18n.t('components.static.questionnaires.invite_others_to_fill_out_questionnaires.tagline')}
          className='questionnaire-fill-out'
          premium
        >
          <div className='row'>
            <div className='col s12'>
              <ol>
                <li><Link to='/network/groups/create'>{I18n.t('components.static.questionnaires.invite_others_to_fill_out_questionnaires.steps.create_a_new_group')}</Link></li>
                <li><Link to='/network/groups'>{I18n.t('components.static.questionnaires.invite_others_to_fill_out_questionnaires.steps.select_a_group')}</Link> {I18n.t('components.static.questionnaires.invite_others_to_fill_out_questionnaires.steps.to_invite_others')}</li>
                <li>{I18n.t('components.static.questionnaires.invite_others_to_fill_out_questionnaires.steps.then_click_manage_questionnaires')}</li>
                <li>{I18n.t('components.static.questionnaires.invite_others_to_fill_out_questionnaires.steps.check_which_group_members')}</li>
                <li>{I18n.t('components.static.questionnaires.invite_others_to_fill_out_questionnaires.steps.activate_a_new_protocol')}
                  <ul className='browser-default'>
                    <li>{I18n.t('components.static.questionnaires.invite_others_to_fill_out_questionnaires.steps.select_type_of_questionnaire')}</li>
                    <li>{I18n.t('components.static.questionnaires.invite_others_to_fill_out_questionnaires.steps.select_begin_and_end_date')}</li>
                    <li>{I18n.t('components.static.questionnaires.invite_others_to_fill_out_questionnaires.steps.select_which_group_members')}</li>
                  </ul>
                </li>
                <li>{I18n.t('components.static.questionnaires.invite_others_to_fill_out_questionnaires.steps.check_progress')} <span className='text-muted'>{I18n.t('components.static.questionnaires.invite_others_to_fill_out_questionnaires.steps.once_the_protocol_is_activated')}</span>
                </li>
              </ol>
            </div>
          </div>
        </QuestionnaireCard>
      </>
    )
  }

  const analyzeQuestionnaires = () => {
    return (
      <>
        <div className='row'>
          <div className='col s12 text-heavy muted-header'>
            <h5>{I18n.t('components.static.questionnaires.analyze_questionnaires.title')}</h5>
          </div>
        </div>
        <div className='row'>
          <div className='col s12 questionnaire-card'>
            <div className='questionnaire-landing'>
              <div className='questionnaire-feedback'>
                <div className='questionnaire-card-with-background-image'>
                  <div className='data-overview-card no-padding-bottom'>
                    <div className='row'>
                      <div className='timeline-icon center-align'>
                        <FontAwesomeIcon icon='chart-line' />
                      </div>
                      <div className='col s12'>
                        <p className='text-l'>{I18n.t('components.static.questionnaires.analyze_questionnaires.tagline')}</p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col s12'>
                        <ul className='browser-default'>
                          <li>
                            <strong>
                              {I18n.t('components.dashboards.questionnaire.sub_dashboards.rheuma')}
                            </strong>: {I18n.t('components.static.questionnaires.analyze_questionnaires.rheuma.title')}
                            <Info
                              text={I18n.t('components.static.questionnaires.analyze_questionnaires.rheuma.tooltip')}
                              tooltipId='rheuma-tooltip'
                            />
                          </li>
                          <li>
                            <strong>
                              {I18n.t('components.dashboards.questionnaire.sub_dashboards.complaints')}
                            </strong>: {I18n.t('components.static.questionnaires.analyze_questionnaires.complaints.title')}
                            <Info
                              text={I18n.t('components.static.questionnaires.analyze_questionnaires.complaints.tooltip')}
                              tooltipId='complaints-tooltip'
                            />
                          </li>
                          <li>
                            <strong>
                              {I18n.t('components.dashboards.questionnaire.sub_dashboards.weekly')}
                            </strong>: {I18n.t('components.static.questionnaires.analyze_questionnaires.weekly.title')}
                            <Info
                              text={I18n.t('components.static.questionnaires.analyze_questionnaires.weekly.tooltip')}
                              tooltipId='weekly-tooltip'
                            />
                          </li>
                          <li>
                            <strong>
                              {I18n.t('components.dashboards.questionnaire.sub_dashboards.full')}
                            </strong>: {I18n.t('components.static.questionnaires.analyze_questionnaires.full.title')}
                            <Info
                              text={I18n.t('components.static.questionnaires.analyze_questionnaires.full.tooltip')}
                              tooltipId='full-tooltip'
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='data-overview-card'>
                  <div className='row no-icon'>
                    <div className='col s12'>
                      <QuestionnaireDashboardPicker {...props} hideBackButton />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderQuestionnairesNotEnabled = () => {
    return (
      <>
        <div className='row'>
          <div className='col s12 text-heavy muted-header'>
            <h5>{I18n.t('components.static.questionnaires.getting_started.title')}</h5>
          </div>
        </div>
        <QuestionnaireCard
          tagline={I18n.t('components.static.questionnaires.getting_started.tagline')}
          className='questionnaire-questionnaire'
          icon='sliders-h'
        >
          <div className='row'>
            <div className='col s12'>
              <Link to='/profile/edit' className={buttonClasses}>
                <FontAwesomeIcon icon='edit' className='button-icon' />
                {I18n.t('components.static.questionnaires.getting_started.edit_profile')}
              </Link>
            </div>
          </div>
        </QuestionnaireCard>
      </>
    )
  }

  const renderPage = () => {
    return (
      <>
        <div className='row'>
          {props.questionnaires.areEnabled && renderQuestionnairesEnabled()}
          {!props.questionnaires.areEnabled && renderQuestionnairesNotEnabled()}
        </div>
        <div className='row'>
          {inviteOthersToFillOutQuestionnaires()}
        </div>
        <div className='row'>
          {analyzeQuestionnaires()}
        </div>
      </>
    )
  }

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <BackButton {...props} />
        </div>
      </div>
      {renderPage()}
    </>
  )
}

export default WithQuestionnaires(QuestionnaireLanding)
