import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export default class OverTimeChart extends React.Component {
  constructor (props) {
    super(props)
    this.xLabel = props.xLabel ? '{value} ' + props.xLabel : '{value}'
    this.yLabel = props.yLabel ? props.yLabel : ''
    this.title = props.title ? props.title : 'Chart'
    /// The sync attribute defines if the crosshair of this chart should be aligned with the others
    this.sync = props.sync ? props.sync : false
    this.color = props.color ? props.color : null

    // Override the reset function, we don't need to hide the tooltips and
    // crosshairs.
    Highcharts.Pointer.prototype.reset = function () {
      return undefined
    }

    // Highlight a point by showing tooltip, setting hover state and draw crosshair
    Highcharts.Point.prototype.highlight = function (event) {
      event = this.series.chart.pointer.normalize(event)
      this.onMouseOver() // Show the hover marker
      this.series.chart.tooltip.refresh(this) // Show the tooltip
      this.series.chart.xAxis[0].drawCrosshair(event, this) // Show the crosshair
    }
  }

  // In order to synchronize tooltips and crosshairs, override the
  // built-in events with handlers defined on the parent element.
  overrideBuiltInEvent () {
    return function (e) {
      let chart,
        point,
        i,
        event

      for (i = 0; i < Highcharts.charts.length; i = i + 1) {
        chart = Highcharts.charts[i]
        // Find coordinates within the chart
        event = chart.pointer.normalize(e)
        // Get the hovered point
        point = chart.series[0].searchPoint(event, true)

        if (point) {
          point.highlight(e)
        }
      }
    }
  }

  // Synchronize zooming through the setExtremes event handler.
  syncExtremes (e) {
    const thisChart = this.chart

    // Prevent feedback loop
    if (e.trigger !== 'syncExtremes') {
      Highcharts.each(Highcharts.charts, function (chart) {
        if (chart !== thisChart) {
          // It is null while updating
          if (chart.xAxis[0].setExtremes) {
            chart.xAxis[0].setExtremes(
              e.min,
              e.max,
              undefined,
              false,
              { trigger: 'syncExtremes' }
            )
          }
        }
      })
    }
  }

  // TODO Move all the appearance configurations to a SCSS file
  buildChartConfigs (data, isSync) {
    const syncFunc = isSync ? this.syncExtremes : null

    const xAxisOptions = {
      crosshair: true,
      events: {
        setExtremes: syncFunc
      },
      labels: {
        format: this.xLabel
      }
    }

    const series = {
      type: 'line',
      data: data,
      tooltip: {
        valueSuffix: ' ' + this.yLabel
      },
      color: this.color
    }

    const titleOptions = {
      text: this.title,
      align: 'left',
      margin: 0,
      x: 30
    }

    const options = {
      series: [series],
      title: titleOptions,
      xAxis: xAxisOptions,
      legend: {
        enabled: false
      },
      chart: {
        marginLeft: 40, // Keep all charts left aligned
        spacingTop: 20,
        spacingBottom: 20,
        height: '45%'
      },
      yAxis: {
        title: {
          text: null
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        positioner: function () {
          return {
            // right aligned
            x: this.chart.chartWidth - this.label.width,
            y: 10 // align to title
          }
        },
        borderWidth: 0,
        backgroundColor: 'none',
        pointFormat: '{point.y}',
        headerFormat: '',
        shadow: false,
        style: {
          fontSize: '18px'
        },
        valueDecimals: 2
      }
    }

    return options
  }

  render () {
    if (!this.props.data) {
      console.warn('data is missing')
      return <></>
    }
    if (!this.props.yName) {
      console.warn('yName is missing')
      return <></>
    }
    const name = this.props.yName
    const configs = this.buildChartConfigs(this.props.data, name)

    return (

      <div
        className='overtime-chart'
        {...(this.sync &&
                    {
                      onMouseOver: this.overrideBuiltInEvent(),
                      onTouchMove: this.overrideBuiltInEvent(),
                      onTouchStart: this.overrideBuiltInEvent()
                    })}
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={configs}
        />
      </div>
    )
  }
}
