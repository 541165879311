import React from 'react'
import { datePickerDefaultOptions } from '../../../common/Constants'
import classNames from 'classnames'
import moment from 'moment'
import I18n from 'i18n'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'

interface Props {
  startDate: Date
  setStartDate: (startDate: Date) => void
  endDate: Date
  setEndDate: (endDate: Date) => void
  customDatePickerOptions?: Partial<ReactDatePickerProps>
  minDate?: Date
  maxDate?: Date
  sizeClass?: string
}

// This component requires that startDate and endDate cannot be null. If we have a case where they can be null,
// it is probably best to create a separate component for this, so that the typescript definitions can stay strict.
const StartAndEndDate: React.FC<Props> = ({ startDate, setStartDate, endDate, setEndDate, customDatePickerOptions = {}, minDate, maxDate, sizeClass = 's6' }) => {
  return (
    <>
      <div className={classNames('col input-field', sizeClass)}>
        {/* Webpacker complained on adding locale here */}
        <ReactDatePicker
          id='start-date' {...datePickerDefaultOptions()} {...customDatePickerOptions}
          selected={startDate}
          onChange={date => { if (date !== null) { setStartDate(moment(date).startOf('day').toDate()) } }}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={endDate} // endDate ?? maxDate // if endDate can be null
          openToDate={startDate} // startDate ?? minDate // if startDate can be null
        />
        <label
          htmlFor='start-date'
          className={classNames({ active: startDate })}
        >{I18n.t('components.dashboards.multi_activity.start_date')}
        </label>
      </div>
      <div className={classNames('col input-field', sizeClass)}>
        {/* Webpacker complained on adding locale here */}
        <ReactDatePicker
          id='end-date' {...datePickerDefaultOptions()} {...customDatePickerOptions}
          selected={endDate}
          onChange={date => { if (date !== null) { setEndDate(moment(date).endOf('day').toDate()) } }}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate} // startDate ?? minDate // if startDate can be null
          maxDate={maxDate}
          openToDate={endDate} // endDate ?? maxDate // if endDate can be null
        />
        <label
          htmlFor='end-date'
          className={classNames({ active: endDate })}
        >{I18n.t('components.dashboards.multi_activity.end_date')}
        </label>
      </div>
    </>
  )
}

export default StartAndEndDate
