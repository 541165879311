import React from 'react'
import I18n from 'i18n'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export default class ColumnChartView extends React.Component {
  showColumnChart (ids, series) {
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            type: 'column'
          },
          title: {
            text: I18n.t('components.detail_views.volleyball.volleyball_data')
          },
          subtitle: {
            text: I18n.t('components.detail_views.volleyball.mean_jump')
          },
          series: series,
          yAxis: {
            title: {
              text: 'cm'
            }
          },
          xAxis: {
            // Plot the corresponding player id's on x
            categories: ids
          },
          credits: {
            enabled: false
          }
        }}
      />
    )
  }

  processData (data) {
    return data.sort(function (a, b) {
      // In some cases the data in the platform did not have these ids.
      // This should only happen in develop, but just to be sure, make sure the
      // whole platform  doesn't crash if these ids are missing.
      if (!a.id || !b.id) {
        return 0
      }
      return a.id.split(' ')[1] - b.id.split(' ')[1]
    })
  }

  render () {
    if (!this.props.data) {
      return (<></>)
    }
    const data = this.processData(this.props.data.data_rows)
    // const data = this.props.data.data_rows
    // Note that the following only workds because the data is sorted.
    const ids = [...new Set(data.map((entry) => entry.id))]

    const matchTypes = [...new Set((data.map((elem) => elem.type)))]
    const splittedData = matchTypes.map((type) => {
      return {
        name: type,
        data: data
          .filter((entry) => entry.type === type)
          .map((entry) => entry.sprong_mean)
      }
    })

    return (
      <div>
        {this.showColumnChart(ids, splittedData)}
      </div>
    )
  }
}
