import classNames from 'classnames'
import { ButtonColour, ButtonWidth } from './types'

const MATERIALIZE_CLASSES_FOR = {
  'hollow-blue-button': classNames('text-primary-color', 'background-background', 'button-primary'),
  'hollow-red-button': classNames('text-accent-color', 'background-background', 'button-accent'),
  'filled-blue-button': classNames('text-background-color', 'background-primary', 'button-primary'),
  'filled-red-button': classNames('text-background-color', 'background-accent', 'button-accent')
}

export const getColourClasses = (colour: ButtonColour, isFilled: boolean): string => {
  if (isFilled) {
    return MATERIALIZE_CLASSES_FOR[`filled-${colour}-button`]
  } else {
    return MATERIALIZE_CLASSES_FOR[`hollow-${colour}-button`]
  }
}

export const getWidthClasses = (autowidth: boolean, widthType?: ButtonWidth): string => {
  if (autowidth) {
    return classNames('button-autowidth')
  } else {
    switch (widthType) {
      case 'auto':
        return classNames('button-autowidth')
      case 'group-width':
        return classNames('group-button')
      default:
        return ''
    }
  }
}
