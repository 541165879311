import React, { useState, useMemo } from 'react'
import { WithQuestionnaires } from '../questionnaires/QuestionnaireProvider'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import I18n from 'i18n'
import Info from '../atomic/atoms/Info'
import classNames from 'classnames'

const TrainingLogButton = (props) => {
  const [trainingLogDisabled, setTrainingLogDisabled] = useState(false)

  const buttonClasses = useMemo(() => {
    return classNames('waves-effect text-m waves-light button-primary text-primary-color background-background text-medium',
      props.withContainer && 'button welcome-container-button',
      !props.withContainer && 'data-button data-detail-page',
      props.widerButton && 'wider-data-button')
  }, [props.withContainer])

  if (!props.questionnaires.areEnabled || props.hideTrainingLog) {
    return <></>
  }

  const link = props.questionnaires.oneTimeResponseUrl('training_log')

  const handleClick = (e) => {
    if (trainingLogDisabled) {
      e.preventDefault()
      return
    }
    setTrainingLogDisabled(true)
  }

  const renderButton = () => {
    return (
      <a
        className={classNames(buttonClasses, trainingLogDisabled && 'disabled-button')}
        href={link}
        onClick={e => handleClick(e)}
      >
        <FontAwesomeIcon icon='sliders-h' className='button-icon' />
        {I18n.t('components.welcome.fill_out_training_log')}
        <Info
          text={I18n.t('components.welcome.fill_out_training_log_tooltip')}
          tooltipId='fill-out-training-log-tooltip'
        />
      </a>
    )
  }

  if (props.withContainer) {
    return (
      <div className='col s6 m12'>
        {renderButton()}
      </div>
    )
  }
  return renderButton()
}

TrainingLogButton.propTypes = {
  hideTrainingLog: PropTypes.bool,
  withContainer: PropTypes.bool,
  widerButton: PropTypes.bool
}

TrainingLogButton.defaultProps = {
  hideTrainingLog: false,
  withContainer: false,
  widerButton: false
}

export default WithQuestionnaires(TrainingLogButton)
