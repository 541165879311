import React from 'react'
import { WithFlashMessages } from './FlashMessageProvider'
import Message from './Message'

class FlashMessages extends React.Component {
  constructor (props) {
    super(props)
    this.color_map = {
      1: 'green',
      2: 'orange',
      3: 'red'
    }
  }

  render () {
    const messages = this.props.flashMessages.messages // .sort((msg1, msg2) => msg1.type <= msg2.type)
    return (
      <div className='flash-messages-container'>
        {messages && messages.map((msg) => (
          <Message key={msg.message} msg={msg.message} state={msg.state} color={this.color_map[msg.type]} removeMessage={this.props.flashMessages.removeMessage} />
        ))}
      </div>
    )
  }
}

export default WithFlashMessages(FlashMessages)
