import React from 'react'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

const GroupAnonymousIcon = ({ groupType }) => {
  const isAnonymous = (groupType === 'anonymized')
  if (!isAnonymous) { return <></> }
  return <><FontAwesomeIcon icon='low-vision' /> {I18n.t('network.groups.labels.anonymized')} </>
}
GroupAnonymousIcon.propTypes = {
  groupType: PropTypes.string.isRequired
}
export default GroupAnonymousIcon
