import React from 'react'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import { WithSession } from '../../session/SessionProvider'
import I18n from 'i18n'
import { WithBackend } from '../../backend/BackendProvider'
import { locationParam } from '../../common/Utils'

class Confirmation extends React.Component {
  constructor (props) {
    super(props)
    this.state = { message: I18n.t('flashmessages.confirmation.processing') }
  }

  componentDidMount () {
    this.props.updateSession({ sessionToken: '' })
    this.props.setHeight(100)
    this.props.backend.confirm(locationParam(this.props.location, 'confirmation_token')).then((success) => {
      this.props.flashMessages.push(
        I18n.t('flashmessages.confirmation.successful'),
        this.props.flashMessages.duration.SHORT,
        this.props.flashMessages.levels.INFO)
      // Redirected to login page to prevent auto-redirect to info page
      this.props.history.push('/login')
    }).catch((err) => {
      console.log('An error occurent when activating your account:')
      console.log(err)
      this.setState({ message: I18n.t('flashmessages.confirmation.failed') })
    })
  }

  render () {
    const { message } = this.state
    return (
      <span>
        {message}
      </span>
    )
  }
}

export default WithBackend(WithFlashMessages(WithSession(Confirmation)))
