/**
 * Creates a configs object that adds a text annotation to a ChartJS graph.
 * The configs object must be added to the `annotation` section of the graph options.
 *
 * NOTE 1: This uses the specifications of ChartJS Annotation plugin (v0.5.7)
 * NOTE 2: The only way to create a box of text in ChartJS Annotation v.0.5.7 is
 * to create a line with label, and make the line transparent
 * @returns the configs object
 */
export const createHorizontalAnnotation = (positionProps, labelProps) => {
  const { y, alignment, yScaleID } = positionProps
  const { text, backgroundColor } = labelProps
  return {
    type: 'line',
    mode: 'horizontal',
    scaleID: yScaleID,
    value: y,
    borderWidth: 0,
    borderColor: 'rgba(0,0,0,0)', // Make the line transparent
    label: {
      content: text,
      enabled: true,
      position: alignment,
      backgroundColor: backgroundColor
    }
  }
}
