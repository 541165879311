import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { hcBlack, hcOrange, hcRed, hcGreen, hcDarkGrey } from '../../../../common/Constants'
import I18n from 'i18n'
import GenericPlaceholder from '../../../../common/GenericPlaceholder'
import { initializeHighcharts, titleWithTooltip } from '../../../../common/Utils'

const WeeklyInjuryRiskSeverityScoreOSTRC = props => {
  if (!props.sundaySummary || props.sundaySummary.length === 0 ||
      !props.dailyLogRows || props.dailyLogRows.length === 0) {
    return (
      <GenericPlaceholder
        title={I18n.t('components.dashboards.questionnaire.weekly_injury_risk_severity_score_ostrc.title')}
      />
    )
  }

  const calculateColor = entry => {
    if (entry.weekly_ostrc_severity <= 0.25) return hcGreen
    if (entry.weekly_ostrc_severity >= 0.50) return hcRed
    if (entry.weekly_ostrc_severity > 0.25 && entry.weekly_ostrc_severity < 0.50) return hcOrange
    return hcBlack
  }

  const scatterData = props.dailyLogRows.map(entry => {
    return {
      x: entry.date_only,
      y: entry.injured === 1 ? -5 : null, // -5 so that it appears below the x-axis on the gray bar, acting as a status indicator.
      daily_date: entry.formatted_date
    }
  })
  const columnData = props.sundaySummary.map(entry => {
    return {
      x: entry.date_only,
      y: Math.max(0.01, entry.weekly_ostrc_severity) * 100, // always show at least 1% so the bar is visible
      color: calculateColor(entry),
      daily_date: entry.formatted_date,
      weekly_ostrc_severity: entry.weekly_ostrc_severity * 100,
      weekly_ostrc_participation: entry.participation * 100,
      weekly_ostrc_reduced_training: entry.reduced_training * 100,
      weekly_ostrc_affected_performance: entry.affected_performance * 100,
      weekly_ostrc_symptoms_complaints: entry.symptoms_complaints * 100
    }
  })
  initializeHighcharts(true)
  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          title: titleWithTooltip(
            I18n.t('components.dashboards.questionnaire.weekly_injury_risk_severity_score_ostrc.title'),
            I18n.t('components.dashboards.questionnaire.weekly_injury_risk_severity_score_ostrc.info')
          ),
          subtitle: {
            text: I18n.t('components.dashboards.questionnaire.weekly_injury_risk_severity_score_ostrc.subtitle')
          },
          series: [
            {
              type: 'scatter',
              data: scatterData,
              tooltip: {
                headerFormat: '',
                pointFormat: I18n.t('components.dashboards.questionnaire.weekly_injury_risk_severity_score_ostrc.scatter.tooltip')
              }
            },
            {
              type: 'column',
              data: columnData,
              name: '⬤',
              tooltip: {
                headerFormat: '',
                pointFormat: I18n.t('components.dashboards.questionnaire.weekly_injury_risk_severity_score_ostrc.column.tooltip')
              }
            }
          ],
          plotOptions: {
            series: {
              stacking: 'normal',
              maxPointWidth: 1
            },
            column: {
              color: hcOrange
            },
            scatter: {
              color: 'black',
              fillOpacity: 1
            },
            line: {
              color: hcBlack,
              marker: {
                enabled: true
              }
            }
          },
          yAxis: {
            title: {
              text: I18n.t('components.dashboards.questionnaire.weekly_injury_risk_severity_score_ostrc.yaxis')
            },
            min: -9,
            max: 100,
            tickInterval: 10,
            startOnTick: false,
            plotBands: [{
              color: hcDarkGrey,
              borderColor: 'black',
              borderWidth: 0.5,
              from: -7.5,
              to: -2.5,
              label: {
                text: ''
              }
            }]
          },
          xAxis: {
            title: {
              text: ''
            },
            type: 'datetime',
            dateTimeLabelFormats: {
              day: '%d %b'
            }
          }
        }}
      />
    </>
  )
}

export default WeeklyInjuryRiskSeverityScoreOSTRC
