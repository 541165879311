import { DirectUpload } from '@rails/activestorage'

export default class DirectUploader {
  constructor (uploader = DirectUpload, sessionToken = undefined, delegate = undefined) {
    this.api_url = '/rails/active_storage/direct_uploads'
    this.Uploader = uploader
    this.sessionToken = sessionToken
    this.delegate = delegate
  }

  setSessionToken (sessionToken) {
    if (sessionToken === undefined) { return }

    let tokenOnly = sessionToken
    if (sessionToken.includes('Bearer')) {
      tokenOnly = sessionToken.split(' ')[1]
    }
    this.sessionToken = tokenOnly
  }

  getApiUrl () {
    if (this.sessionToken === undefined) {
      console.warn('SessionToken is not set!')
      return `${this.api_url}`
    }
    return `${this.api_url}?auth=${this.sessionToken}`
  }

  uploadFile (file, field) {
    const upload = new this.Uploader(file, this.getApiUrl(), this)

    return new Promise((resolve, reject) => {
      upload.create((error, blob) => {
        if (error) {
          // TODO: Log this to appsignal and resolve
          console.log(error)
          reject(error)
        } else {
          resolve([blob, field])
        }
      })
    })
  }

  directUploadWillStoreFileWithXHR (request) {
    if (this.delegate != null) {
      request.upload.addEventListener('progress',
        event => this.delegate.handleProcessChange(event))
    }
  }
}
