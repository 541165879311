import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import I18n from 'i18n'
import { roles } from '../../common/Constants'

export default class ChangeRoleButton extends React.Component {
  makeMemberButton (onChangeRole, membership) {
    return (
      <div onClick={() => onChangeRole(membership, roles.member)}>
        {this.props.children}
        <div className='text-primary-color change-role make-member' data-tip={I18n.t('group.detail.make_member')} data-for='higher-z-tooltip'>
          <FontAwesomeIcon className='pointer-grow' icon='user' />
        </div>
      </div>
    )
  }

  makeAdminButton (onChangeRole, membership) {
    return (
      <div onClick={() => onChangeRole(membership, roles.administrator)}>
        {this.props.children}
        <div className='text-primary-color change-role make-admin' data-tip={I18n.t('group.detail.make_admin')} data-for='higher-z-tooltip'>
          <FontAwesomeIcon className='pointer-grow' icon='user-cog' />
        </div>
      </div>
    )
  }

  render () {
    const { membership, onChangeRole } = this.props
    let button = ''
    if (membership.role === roles.administrator) {
      button = this.makeMemberButton(onChangeRole, membership)
    } else {
      button = this.makeAdminButton(onChangeRole, membership)
    }
    return (
      <>
        {button}
      </>
    )
  }
}
