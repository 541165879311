import { CookieSetOptions } from 'universal-cookie'
import { isEmpty } from 'lodash'

const THIRTY_DAYS: number = 30 * 24 * 3600

export const cookieOptions = (): CookieSetOptions => {
  // , domain: process.env.SESSION_COOKIE_HOSTNAME || ''
  const cookie: CookieSetOptions = { path: '/', maxAge: THIRTY_DAYS }
  if (!isEmpty(process.env.SESSION_COOKIE_HOSTNAME)) {
    cookie.domain = process.env.SESSION_COOKIE_HOSTNAME
  }
  return cookie
}

// If token is string 'null', sets it to undefined instead
export const parseSessionToken = (token?: string): string | undefined => {
  return token !== 'null' ? token : undefined
}

export const removeBearerFromToken = (token?: string): string | undefined => {
  if (token === undefined) return undefined

  if (token?.includes('Bearer')) {
    return token.split(' ')[1]
  }
  return token
}
