import { useContext } from 'react'
import { useQuery } from 'react-query'
import { UseQueryOptions, UseQueryResult } from 'react-query/types'
import { BackendContext } from '../BackendProvider'
import { AxiosResponse } from 'axios'
import Profile from 'components/common/types/Profile'

export const useProfileQuery = (
  slug: string,
  options?: UseQueryOptions<Profile, Error>
): UseQueryResult<Profile, Error> => {
  const { backend } = useContext(BackendContext)

  const queryId = ['profile-info', slug]
  const queryFn = async (): Promise<Profile> => {
    return backend.profiles.show(slug).then((response: AxiosResponse<Profile>) => {
      return response.data
    })
  }

  return useQuery<Profile, Error>(queryId, queryFn, options)
}
