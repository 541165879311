import { Progress } from 'reactstrap'
import I18n from 'i18n'
import { round } from '../common/Math'
import React from 'react'

const DiaryStudyProgress = (protocolCompletion) => {
  const totalCount = protocolCompletion.length
  if (!totalCount) return <p className='state-spacer'>N/A</p>
  const pastCount = protocolCompletion.filter(entry => entry.completed || !entry.future_or_current).length
  const completedCount = protocolCompletion.filter(entry => entry.completed).length
  const missingCount = protocolCompletion.filter(entry => !entry.completed && !entry.future_or_current).length
  return (
    <>
      <Progress multi>
        <Progress
          bar color='warning'
          value={100.0 * missingCount / totalCount}
        >{I18n.t('group.detail.questionnaires.missed')}
        </Progress>
        <Progress
          bar color='success'
          value={100.0 * completedCount / totalCount}
        >{I18n.t('group.detail.questionnaires.filled_out')}
        </Progress>
      </Progress>
      <span className='text-muted'>
        {I18n.t('group.detail.questionnaires.progress_line', {
          pastCount: pastCount,
          totalCount: totalCount,
          percentage: round(100 * pastCount / totalCount)
        })}
      </span>
      <br />
      <span className='text-muted'>
        {I18n.t('group.detail.questionnaires.progress_missing', { missingCount: missingCount })}
      </span>
    </>
  )
}

export default DiaryStudyProgress
