import React, { useState, useEffect, useMemo } from 'react'
import I18n from 'i18n'
import _ from 'lodash'
import moment from 'moment'

import { MyAxios as axios } from '../../../MyAxios'
import { SoccerBarGraph } from './SoccerBarGraph'
import SoccerPlayerComparisonTable from './SoccerPlayerComparisonTable'
import SoccerGeneralInfo from './SoccerGeneralInfo'
import SoccerHeatmap from './SoccerHeatmap'
import SoccerOvertimeChart from './SoccerOvertimeChart'
import SoccerReplay from './SoccerReplay'
import { soccerDecimalRounding, collectionTypes } from '../../../common/Constants'
import Dashboard from '../../layout/Dashboard'
import { activitySummaryToMetric, ms2kmh } from '../../../common/Units'
import { fillParticipantNames } from '../../../common/Utils'

const SoccerDashboard = (props) => {
  const [file, setFile] = useState(undefined)
  const [playersTimeseries, setPlayersTimeseries] = useState([])
  const measurementRate = 1 // Hz

  function handleFileSelect (file) {
    setFile(file)
    setPlayersTimeseries([])
  }

  const { summary = {}, id } = file || {}
  useMemo(() => fillParticipantNames(summary), [summary])
  const summaryWithUnits = useMemo(() => activitySummaryToMetric(summary), [summary])

  const {
    participant_summaries: participantSummaries = [],
    duration: durationVariable,
    teams,
    periods
  } = summaryWithUnits || {}

  let { value: duration = 0 } = durationVariable || { }
  duration = moment.duration(duration).asSeconds()

  useEffect(() => {
    M.FormSelect.init(document.querySelectorAll('select'))
  }, [file])

  useEffect(() => {
    if (id) {
      Promise.all([fetchPlayersTimeseries(id)])
        .then(response => {
          const { data } = response[0]
          setPlayersTimeseries(data)
        })
        .catch(e => console.log(e))
    }
  }, [file])

  // TODO Add UseMemo here
  const positionSeries = _.map(playersTimeseries, player => {
    const playerTimeseries = player.time_series
    const result = {}
    playerTimeseries.offset.forEach((offset, i) => {
      result[offset] = playerTimeseries.time_series.position[i]
    })
    return result
  })
  const speedSeries = _.map(playersTimeseries, player => {
    const playerTimeseries = player.time_series
    const result = []
    playerTimeseries.offset.forEach((offset, i) => {
      result[offset] = _.round(ms2kmh(playerTimeseries.time_series.speed[i]), soccerDecimalRounding)
    })
    return result
  })

  function fetchPlayersTimeseries (datasetId) {
    return axios.get(`/api/v1/data/${datasetId}/player_time_series`)
  }

  return (
    <Dashboard
      title={I18n.t('components.dashboards.soccer.title')}
      onFileSelect={handleFileSelect}
      collectionType={collectionTypes.SOCCER}
      history={props.history}
      ready={!!file}
      {...props}
    >
      <div className='row'>
        <div className='col s12 l4'>
          <div className='graph-outline'>
            <span className='graph-title text-background-color text-heavy'>
              {I18n.t('components.dashboards.soccer.general_info_table.title')}
            </span>
            <SoccerGeneralInfo data={summaryWithUnits} />
          </div>
        </div>
        <div className='col s12 l8'>
          <div className='graph-outline'>
            <span className='graph-title text-background-color text-heavy'>
              {I18n.t('components.dashboards.soccer.replay.title')}
            </span>
            <SoccerReplay
              positions={positionSeries}
              players={participantSummaries}
              measurementRate={measurementRate}
              duration={duration}
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 l4'>
          <div className='graph-outline'>
            <span className='graph-title text-background-color text-heavy'>
              {I18n.t('components.dashboards.soccer.player_comparison_table.title')}
            </span>
            <SoccerPlayerComparisonTable
              players={participantSummaries}
              teams={teams}
              datasetId={id}
            />
          </div>
        </div>
        <div className='col s12 l8'>
          <div className='graph-outline'>
            <span className='graph-title text-background-color text-heavy'>
              {I18n.t('components.dashboards.soccer.overall_metrics.title')}
            </span>
            <SoccerBarGraph
              players={participantSummaries}
              teams={teams}
              datasetId={id}
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 l4'>
          <div className='graph-outline'>
            <span className='graph-title text-background-color text-heavy'>
              {I18n.t('components.dashboards.soccer.heatmap.title')}
            </span>
            <SoccerHeatmap
              players={participantSummaries}
              teams={teams}
              positions={positionSeries}
              datasetId={id}
            />
          </div>
        </div>
        <div className='col s12 l8'>
          <div className='graph-outline'>
            <span className='graph-title text-background-color text-heavy'>
              {I18n.t('components.dashboards.soccer.overtime_graph.title')}
            </span>
            <SoccerOvertimeChart
              periods={periods}
              players={participantSummaries}
              teams={teams}
              speedSeries={speedSeries}
              datasetId={id}
            />
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default SoccerDashboard
