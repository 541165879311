import React, { useContext } from 'react'
import DashboardPicker from '../common/DashboardPicker'
import Routes from '../../../common/Routes'
import { FeatureFlagContext } from '../../../feature_flags/FeatureFlagProvider'
import { OPTIMIZED_QUESTIONNAIRE_DASHBOARD } from '../../../feature_flags/FeatureFlag'

const QuestionnaireDashboardPicker = (props) => {
  const featureFlag = useContext(FeatureFlagContext)

  let fullQuestionnaireDashLink = Routes.analysis.questionnaire.indexFn()

  if (featureFlag.features?.[OPTIMIZED_QUESTIONNAIRE_DASHBOARD]) {
    fullQuestionnaireDashLink = Routes.analysis.questionnaire.indexOptFn()
  }
  const dashboards = [
    {
      id: 1,
      name: 'components.dashboards.questionnaire.sub_dashboards.full',
      icon: 'sliders-h',
      enabled: true,
      abbrevName: 'questionnaire',
      cssKlass: 'dashboard-card-questionnaire',
      implemented: true,
      link: fullQuestionnaireDashLink
    }, {
      id: 2,
      name: 'components.dashboards.questionnaire.sub_dashboards.weekly',
      icon: 'retweet',
      enabled: true,
      abbrevName: 'questionnaire',
      cssKlass: 'dashboard-card-questionnaire',
      implemented: true,
      link: Routes.analysis.questionnaire.weekly
    }, {
      id: 3,
      name: 'components.dashboards.questionnaire.sub_dashboards.complaints',
      icon: 'user-injured',
      enabled: true,
      abbrevName: 'questionnaire',
      cssKlass: 'dashboard-card-questionnaire',
      implemented: true,
      link: Routes.analysis.questionnaire.complaints.showFn()
    }, {
      id: 4,
      name: 'components.dashboards.questionnaire.sub_dashboards.rheuma',
      icon: 'star-of-life',
      enabled: true,
      abbrevName: 'questionnaire',
      cssKlass: 'dashboard-card-questionnaire',
      implemented: true,
      link: Routes.analysis.questionnaire.rheuma.showFn()
    }
  ]

  return (
    <DashboardPicker dashboards={dashboards} {...props} />
  )
}

export default QuestionnaireDashboardPicker
