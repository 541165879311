import { QuestionnaireObject } from 'components/common/types/questionnaires/QuestionnaireObject'
import {
  createWeekInfo,
  WeekInfoDict
} from 'components/common/types/questionnaires/WeekInfo'
import moment from 'moment'
import I18n from 'i18n'
import { getWeekNumber } from 'components/common/Math'
import { dateOfWeek, pad } from 'components/common/Utils'
import { momentWeekFormat, momentWeekYearFormat } from 'components/common/Constants'
import { isDailyQuestionnaire, isTrainingLog, getOpenFromDate } from 'components/common/utils/QuestionnaireUtilsTS'

// simplified mySort for sports
export const sortById = (a: { id: number }, b: { id: number }): number => {
  return a.id - b.id
}

export const getQuestionnaireWeekInfo = (questionnaires: QuestionnaireObject[]): WeekInfoDict => {
  const openFromDates = questionnaires
    .map(getOpenFromDate)
    .filter(Boolean) // remove falsy values
    .sort((a, b) => moment(a).valueOf() - moment(b).valueOf())

  if (openFromDates.length === 0) {
    return {}
  }

  const toStr: string = I18n.t('components.dashboards.questionnaire.weekly.to')
  const questionnaireWeekInfo: WeekInfoDict = {}

  for (const cDate of openFromDates) {
    const curDate = moment(cDate)
    const [curYear, curWeekNo] = getWeekNumber(curDate.toDate())
    const weekValue: string = `${Number(curYear)}-${String(pad(Number(curWeekNo), 2))}`

    // continue if week already processed
    if (questionnaireWeekInfo[weekValue] != null) continue

    const weekStart = moment(dateOfWeek(curWeekNo, curYear))
    const weekEnd = moment(dateOfWeek(curWeekNo, curYear)).add(1, 'week').subtract(1, 'second')

    const weekLabel = `${weekStart.format(String(momentWeekFormat))} ${toStr} ${weekEnd.format(String(momentWeekYearFormat))}`

    const weekQuestionnaires = questionnaires.filter(q => moment(getOpenFromDate(q)).isBetween(weekStart, weekEnd, undefined, '[]'))

    if (weekQuestionnaires.some(q => isDailyQuestionnaire(q) || isTrainingLog(q))) {
      questionnaireWeekInfo[weekValue] = createWeekInfo(weekStart, weekEnd, weekValue, weekLabel)
    }
  }

  return questionnaireWeekInfo
}
