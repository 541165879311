import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class Modal extends React.Component {
  handleClick (e) {
    // Close modal when clicking outside of the modal
    if (e.target && !e.target.closest('.custom-modal.container')) {
      if (this.props.children.props.handleClose) {
        this.props.children.props.handleClose()
      }
      this.props.onClose()
    }
  }

  render () {
    return (
      <div id='modal' className='custom-modal-wrapper' onClick={this.handleClick.bind(this)}>
        <div className='custom-modal container'>
          <div className='close-button-wrapper'>
            <div
              onClick={() => {
                this.props.children.props.handleClose && this.props.children.props.handleClose()
                this.props.onClose()
              }} className='close-button-container center pointer-grow text-primary-color'
            >
              <FontAwesomeIcon icon='times' size='2x' />
            </div>
          </div>
          <div className='custom-modal-inner'>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}
