import React, { useState, useMemo } from 'react'
import { WithQuestionnaires } from '../questionnaires/QuestionnaireProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import I18n from 'i18n'
import Info from '../atomic/atoms/Info'
import classNames from 'classnames'

interface Questionnaires {
  areEnabled: boolean
  oneTimeResponseUrl: (key: string) => string
  myOpenRestrictedOtrs: string[]
}

interface Props {
  hideClimbingAccident?: boolean
  withContainer?: boolean
  widerButton?: boolean
  questionnaires: Questionnaires
}

const ClimbingAccidentButton: React.FunctionComponent<Props> = ({ hideClimbingAccident = false, withContainer = false, widerButton = false, questionnaires }) => {
  const [climbingAccidentDisabled, setClimbingAccidentDisabled] = useState(false)

  const buttonClasses = useMemo(() => {
    return classNames('waves-effect text-m waves-light button-primary text-primary-color background-background text-medium',
      withContainer && 'button welcome-container-button',
      !withContainer && 'data-button data-detail-page',
      widerButton && 'wider-data-button')
  }, [withContainer])

  if (!questionnaires.areEnabled || hideClimbingAccident || !questionnaires.myOpenRestrictedOtrs.includes('climbing_accident')) {
    return <></>
  }

  const link = questionnaires.oneTimeResponseUrl('climbing_accident')

  // Disable the button after clicking it so that we can't accidentally
  // click it twice (which can due to race conditions in some cases
  // lead to two responses being created on the questionnaire engine).
  const handleClick = (e: React.MouseEvent): void => {
    if (climbingAccidentDisabled) {
      e.preventDefault()
      return
    }
    setClimbingAccidentDisabled(true)
  }

  const renderButton = (): React.ReactElement => {
    return (
      <a
        className={classNames(buttonClasses, climbingAccidentDisabled && 'disabled-button')}
        href={link}
        onClick={e => handleClick(e)}
      >
        <FontAwesomeIcon icon='sliders-h' className='button-icon' />
        {I18n.t('components.welcome.fill_out_climbing_accident')}
        <Info
          text={I18n.t('components.welcome.fill_out_climbing_accident_tooltip')}
          tooltipId='fill-out-climbing-accident-tooltip'
        />
      </a>
    )
  }

  if (withContainer) {
    return (
      <div className='col s6 m12'>
        {renderButton()}
      </div>
    )
  }
  return renderButton()
}

export default WithQuestionnaires(ClimbingAccidentButton) as typeof ClimbingAccidentButton
