import React from 'react'
import I18n from 'i18n'

export default class Picture extends React.Component {
  render () {
    const errors = this.props.errors || []
    const { component, fieldName, formName, defaultValue, currentValue } = this.props
    const type = this.props.type || 'file'
    const accept = this.props.accept || 'image/*'
    const fieldProps = { ...this.props }
    const placeholder = '/images/pfp-placeholder.png'
    delete fieldProps.component
    delete fieldProps.fieldName
    delete fieldProps.translateErrors
    delete fieldProps.formName
    delete fieldProps.defaultValue
    delete fieldProps.currentValue
    delete fieldProps.type
    delete fieldProps.accept
    return (
      <div className='row'>
        <div className='col s4' />
        <div className='col s4'>
          <label className='pfp-label'>{I18n.t(`${formName}.${component}.${type}.${fieldName}`)}</label>
          {errors.map(err => (
            <div key={err} style={{ color: 'red' }}>
              {this.props.translateErrors ? I18n.t(err) : err}
            </div>
          ))}
          <label className='pfp-uploader'>
            <input
              type={type}
              name={fieldName}
              id={fieldName}
              accept={accept}
              {...fieldProps}
            />
            <img className='responsive-img circle' src={currentValue || defaultValue || placeholder} alt='' />
          </label>
        </div>
      </div>
    )
  }
}
