import React from 'react'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PremiumOptionsCard from './PremiumOptionsCard'
import { WithSession } from '../../session/SessionProvider'
import { subscriptionTypes, feedbackEmail } from '../../common/Constants'
import { WithModal } from '../../modal/ModalProvider'
import Variables from '../../../stylesheets/variables.module.scss'
import PremiumFeaturesTable from './PremiumFeaturesTable'
import RedeemVoucher from '../../redeem/layout/RedeemVoucher'
const { primaryColor } = Variables

const PremiumOptions = (props) => {
  const { myProfile, sessionToken } = props
  const subscription = myProfile?.subscription

  const mailToUrl = () => {
    let id = ''
    if (myProfile) {
      id = myProfile.id
    }
    return `mailto:${feedbackEmail}?subject=${I18n.t('premium.mail.custom')} sub: ${id}`
  }

  function getContentKeys (type) {
    let content = []

    switch (type) {
      case subscriptionTypes.free:
        content = [
          'connect',
          'overview',
          `storage.${type}`,
          'share',
          'activities',
          'feedback'
        ]
        break
      case subscriptionTypes.coach:
        content = [
          'manage',
          'questionnaires',
          `storage.${type}`,
          'scheduler',
          'performance_multi',
          'activities_multi'
        ]
        break
      case subscriptionTypes.researcher:
        content = [
          'anonymous_data',
          'informed_consent',
          `storage.${type}`,
          'csv',
          'jupyter'
        ]
        break
    }
    return content
  }

  function renderContent (type) {
    const content = getContentKeys(type)

    /**
     * TODO: after merging with rest of payments, make parts of text bold through translations
     */
    const listElements = content.map((textKey) => {
      return (
        <tr key={textKey}>
          <td><FontAwesomeIcon icon='check' style={{ color: primaryColor }} fixedWidth /></td>
          <td>{I18n.t(`premium.advertisement.feature_summaries.${textKey}`)}</td>
        </tr>
      )
    })
    return (
      <>
        <span className='premium-card-content-header'>{I18n.t(`premium.advertisement.headers.${type}`)}</span>
        <table className='premium-card-list'>
          <tbody>
            {listElements}
          </tbody>
        </table>
      </>
    )
  }

  const { researcher, coach, free } = subscriptionTypes
  const PREMIUM_FEATURES = Object.freeze({
    features: {
      data_collection: {
        integrations: [researcher, coach, free],
        storage: [researcher, coach, free],
        create_groups: [researcher, coach, free],
        anonymised_groups: [researcher, coach],
        send_questionnaires: [researcher, coach],
        compare_athletes: [researcher, coach]
      },
      data_sharing: {
        share_data: [researcher, coach, free],
        training_scheduler: [researcher, coach],
        anonymise_data: [researcher],
        automated_consent: [researcher, coach]
      },
      analysis: {
        training_feedback: [researcher, coach, free],
        monitor_own_performance: [researcher, coach, free],
        monitor_athlete_performance: [researcher, coach],
        csv_dashboard: [researcher],
        jupyter: [researcher]
      }
    },
    inDevelopment: [
      'anonymise_data'
    ],
    differByPlan: [
      'storage'
    ]
  })

  // TODO I18n
  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <h1 className='subscription-page-title' id='pick-a-plan-title'>{I18n.t('premium.page.title')}</h1>
          <p className='subscription-page-subtitle'>
            {I18n.t('premium.page.subtitle')} <a className='contact-us-custom-link' href={mailToUrl()} target='_blank' rel='noopener noreferrer'>{I18n.t('premium.page.contact_us')}</a>
          </p>
        </div>
      </div>
      <div className='row'>
        {/* TODO the profile prop is not necessary here anymore */}
        <PremiumOptionsCard profile={props.myProfile} onSetSubscription={props.onSetSubscription} type={subscriptionTypes.researcher} subscription={subscription} sessionToken={sessionToken} content={renderContent(subscriptionTypes.researcher)} description={I18n.t('premium.advertisement.description.premium_researcher')} />
        <PremiumOptionsCard highlight profile={props.myProfile} onSetSubscription={props.onSetSubscription} type={subscriptionTypes.coach} subscription={subscription} sessionToken={sessionToken} content={renderContent(subscriptionTypes.coach)} description={I18n.t('premium.advertisement.description.premium_coach')} />
        <PremiumOptionsCard profile={props.myProfile} onSetSubscription={props.onSetSubscription} type={subscriptionTypes.free} subscription={subscription} content={renderContent(subscriptionTypes.free)} description={I18n.t('premium.advertisement.description.free')} />
      </div>

      <div className='row'>
        <div className='col s12'>
          <RedeemVoucher />
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <h1 className='subscription-page-title' id='full-overview-title'>{I18n.t('premium.page.full_overview')}</h1>
        </div>
      </div>
      <div className='row'>
        <PremiumFeaturesTable features={PREMIUM_FEATURES} />
      </div>
    </>
  )
}

export default WithSession(WithModal(PremiumOptions))
