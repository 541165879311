import React from 'react'
import { ReactNode } from 'react-markdown/lib/react-markdown'
import { RouteComponentProps, StaticContext } from 'react-router'
import SdvLogo from '../../images/sdv-logo.svg'

type RouteProps = RouteComponentProps<
{
  [x: string]: string | undefined
},
StaticContext,
unknown
>

export default (_props: RouteProps): ReactNode => {
  return (
    <div className='static-page-wrapper'>
      <div className='row'>
        <div className='col s12 markdown-wrapper'>
          <div className='logo-wrapper'>
            <img className='sdv-logo' src={SdvLogo as unknown as string} />
          </div>
          <h1>Sport Data Valley Developer Resources</h1>
          <p>
            Welcome to the Sport Data Valley Developer Resources page. Here you
            will find all the information you need to get started using the
            Sport Data Valley API.
          </p>
          <ul>
            <li>
              For domain specific knowledge and information on how to get access
              to the API and an overview of how to use it, check out the
              <a href='/developer/getting-started'>
                &nbsp;Getting Started Guide
              </a>
            </li>
            <li>
              For detailed documentation on all the availabe API endpoints and
              how to use them, check out the
              <a href='/developer/api-docs'>&nbsp;API Documentation</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
