import React from 'react'
import { Router, Route } from 'react-router-dom'

import { SessionProvider } from './session/SessionProvider'
import { QuestionnaireProvider } from './questionnaires/QuestionnaireProvider'
import { CookiesProvider } from 'react-cookie'
import { FlashMessageProvider } from './flashmessages/FlashMessageProvider'
import { BackendProvider } from './backend/BackendProvider'
import { FeatureFlagProvider } from './feature_flags/FeatureFlagProvider'
import { ModalProvider } from './modal/ModalProvider'
import { AppsignalProvider } from './appsignal/AppsignalProvider'
import Session from './session/Session'

// Just for testing!
import Fail from './common/Fail'
import FailErrorBoundary from './common/FailErrorBoundary'

class SdvRouter extends Router {
  componentDidMount () {
    M.Tabs.init(document.getElementById('tabs'), {})
  }

  render () {
    return (
      <Router history={this.props.history}>
        <AppsignalProvider>
          <CookiesProvider>
            <SessionProvider>
              <BackendProvider>
                <FeatureFlagProvider>
                  <QuestionnaireProvider>
                    <FlashMessageProvider>
                      <ModalProvider>
                        <Route exact path='/fail-appsignal' render={(props) => (<Fail {...props} />)} />
                        <Route exact path='/fail-error-boundary' render={(props) => (<FailErrorBoundary {...props} />)} />
                        <Route path='/' render={(props) => <Session {...props} />} />
                      </ModalProvider>
                    </FlashMessageProvider>
                  </QuestionnaireProvider>
                </FeatureFlagProvider>
              </BackendProvider>
            </SessionProvider>
          </CookiesProvider>
        </AppsignalProvider>
      </Router>
    )
  }
}

export default SdvRouter
