import React from 'react'
import { WithSession } from '../session/SessionProvider'
import EditApiTokens from './layout/EditApiTokens'
import { MyAxios as axios } from '../MyAxios'

class ApiTokens extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      apiTokens: [],
      jHubToken: null
    }
    this.JHUB_TOKEN_DESCRIPTION = 'JupyterHub Token'
  }

  fetch () {
    axios.get('/api/v1/api_tokens', { headers: { Authorization: this.props.sessionToken } })
      .then(res => {
        let jHubToken = null
        res.data.forEach((token, idx) => {
          if (token.is_jupyter_hub) {
            [jHubToken] = res.data.splice(idx, 1)
          }
        })
        this.setState({ apiTokens: res.data, jHubToken: jHubToken })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  delete (id) {
    const newTokens = this.state.apiTokens
    delete newTokens.find(t => t.id === id)
    this.setState({})
    axios.delete(`/api/v1/api_tokens/${id}`, { headers: { Authorization: this.props.sessionToken } })
      .then(res => {
        this.fetch()
      })
  }

  add (description) {
    axios.post('/api/v1/api_tokens',
      { api_token: { description: description } },
      { headers: { Authorization: this.props.sessionToken } })
      .then(() => this.fetch())
      .catch(e => console.log(e))
  }

  handleUpdateJHubToken () {
    axios.post('/api/v1/api_tokens',
      { api_token: { description: this.JHUB_TOKEN_DESCRIPTION, is_jupyter_hub: true } },
      { headers: { Authorization: this.props.sessionToken } })
      .then(() => this.fetch())
      .catch(e => console.log(e))
  }

  componentDidMount () {
    this.fetch()
  }

  render () {
    return (
      <EditApiTokens
        apiTokens={this.state.apiTokens}
        jHubToken={this.state.jHubToken}
        delete={this.delete.bind(this)}
        add={this.add.bind(this)}
        onUpdateJHubToken={this.handleUpdateJHubToken.bind(this)}
      />
    )
  }
}

export default WithSession(ApiTokens)
