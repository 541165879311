import React, { useEffect } from 'react'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import { Redirect } from 'react-router'
import I18n from 'i18n'
import { WithBackend } from '../../backend/BackendProvider'
import { locationParam } from '../../common/Utils'

const ShowReconfirmationFeedback = ({ location, flashMessages, backend }) => {
  useEffect(() => {
    const token = locationParam(location, 'confirmation_token')
    backend.confirm(token)
      .then(() => flashMessages.push(I18n.t('flashmessages.reconfirmation.successful'), flashMessages.duration.SHORT, flashMessages.levels.INFO))
      .catch(() => flashMessages.push(I18n.t('flashmessages.reconfirmation.failed'), flashMessages.duration.LONG, flashMessages.levels.ERROR))
  }, [])
  return <Redirect to='/' />
}

export default WithBackend(WithFlashMessages(ShowReconfirmationFeedback))
