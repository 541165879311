import React, { useMemo } from 'react'
import StackedBarChart from '../../steps/StackedBarChart'
import PropTypes from 'prop-types'
import I18n from 'i18n'
import _ from 'lodash'
import { dateStringToDayMonthYear, interpolateColors } from '../../../../common/Utils'
import Variables from '../../../../../stylesheets/variables.module.scss'
import { normalizeHistogram } from '../../../../common/Math'
const { accentColorDark, accentColorLight } = Variables

export const INTENSITY_HR = 'hr'
export const INTENSITY_POWER = 'power'

export const INTENSITY_TYPES = Object.freeze([INTENSITY_HR, INTENSITY_POWER])
const INTENSITY_CHART_ROUNDING = 2
const NUMBER_INTENSITY_ZONES = 5

const TrainingIntensityChart = (props) => {
  const [intensityData, intensityLabels] = useMemo(() => {
    const entries = props.trainingIntensity || []
    const labels = []
    const palette = interpolateColors(accentColorDark, accentColorLight, NUMBER_INTENSITY_ZONES)

    const intensityDatasets = props.zoneLabels.map((z, zIdx) => ({
      label: z,
      backgroundColor: palette[zIdx],
      data: []
    }))

    entries.forEach(e => {
      // This date format is necessary to not break the x-axis slider (otherwise there are might be repeated dates)
      labels.push(dateStringToDayMonthYear(e._id))
      const histogram = normalizeHistogram(_.get(e, props.intensityType, []))
      props.zoneLabels.forEach((_z, zIdx) => {
        intensityDatasets[zIdx].data.push(histogram[zIdx])
      })
    })
    return [intensityDatasets, labels]
  }, [props.trainingIntensity, props.intensityType])

  function hrWeekLabelCb (tooltipItem, data) {
    const dsIdx = tooltipItem.datasetIndex
    const value = Number(tooltipItem.value)
    return `${data.datasets[dsIdx].label}: ${value.toFixed(INTENSITY_CHART_ROUNDING)} %`
  }

  const ttCb = {
    label: hrWeekLabelCb
  }

  function yCb (v) {
    return `${v} %`
  }

  const range = props.range?.map((idx) => intensityLabels[idx])

  return (
    <StackedBarChart
      title={I18n.t('components.dashboards.multi_activity.intensity.label')}
      labels={intensityLabels} datasets={intensityData}
      yTickCallback={yCb}
      tooltipCallbacks={ttCb}
      yMax={100}
      range={range}
      stacked
    />
  )
}

TrainingIntensityChart.propTypes = {
  trainingIntensity: PropTypes.array,
  intensityType: PropTypes.string,
  zoneLabels: PropTypes.arrayOf(PropTypes.string)
}

export default TrainingIntensityChart
