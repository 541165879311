import React from 'react'
import NotFoundWrapper from '../router/NotFoundWrapper'
import { Route } from 'react-router'
import Routes from '../common/Routes'
import ShowReconfirmationFeedback from './layout/ShowReconfirmationFeedback'

const ReconfirmRoutes = () => (
  <NotFoundWrapper slug='reconfirm'>
    <Route exact path={Routes.reconfirm.index} render={(props) => <ShowReconfirmationFeedback {...props} />} />
  </NotFoundWrapper>
)

export default ReconfirmRoutes
