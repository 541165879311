import React, { useState } from 'react'
import { WithSession } from '../session/SessionProvider'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { withCookies } from 'react-cookie'
import Routes from '../common/Routes'

const ProfileCompleteness = (props) => {
  if (!props.myProfile || _.isEmpty(props.myProfile) || !props.myProfile.id) return <></>

  const [dismissed, setDismissed] = useState(false)

  const cookieDismissed = props.cookies.get('dismissedCompleteness')

  const setDismissedWithCookie = () => {
    props.cookies.set('dismissedCompleteness', true)
    setDismissed(true)
  }

  const requiredFields = ['first_name', 'last_name', 'picture', 'sports']
  const missingFields = requiredFields.reduce((result, key) => {
    const value = props.myProfile[key]
    if (!value || (typeof value === 'object' && _.isEmpty(value))) {
      result.push(key)
    }
    return result
  }, [])

  const progress = (requiredFields.length - missingFields.length) / requiredFields.length * 100 || 0
  return (
    <>
      {
        missingFields.length > 0 && !dismissed && !cookieDismissed &&
          <div className='container'>
            <div className='right-align text-l text-primary-color text-heavy pointer'>
              <FontAwesomeIcon className='profile-progress-dismiss' icon='times' onClick={setDismissedWithCookie} />
            </div>
            <div className='row'>
              <div className='col s12 text-l text-primary-color text-heavy'>
                <Link to={Routes.profiles.edit}>
                  {I18n.translate('components.profile_completeness.title')}
                </Link>
              </div>
            </div>
            <div className='row'>
              <div className='col s12'>
                {
                  missingFields.map((f, i) => (
                    <span className='profile-progress-bar-missing-items' key={f}>{I18n.t(`profile.edit.${f}`)}{i === missingFields.length - 1 ? '.' : ','}</span>
                  ))
                }
              </div>
            </div>
            <div className='row'>
              <div className='col s12'>
                <div className='profile-progress-bar'>
                  <span className='profile-progress-bar-fill' style={{ width: `${progress}%` }} />
                </div>
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default WithSession(withCookies(ProfileCompleteness))
