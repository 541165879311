import React from 'react'
import { WithErrorBoundary } from '../errorboundary/ErrorBoundaryProvider'

// This component is only used for testing whether Appsignal picks up JS errors
class Fail extends React.Component {
  render () {
    const a = undefined
    a.anyMethod()
    return <div> I fail </div>
  }
}

export default WithErrorBoundary(Fail)
