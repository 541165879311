import React from 'react'
import { Bar } from 'react-chartjs-2'
import I18n from 'i18n'
import { roundTooltip } from '../../../common/Utils.jsx'

class HRZones extends React.Component {
  constructor (props) {
    super(props)

    this.state = { showPercentage: true }
    this.handleRadioButton.bind(this)
  }

  handleRadioButton (e) {
    this.setState({ showPercentage: e.target.value === 'percentage' })
  }

  render () {
    // if (!this.props.percentagesHist || !this.props.minutesHist){
    //   return <></>
    // }

    const data = {
      labels: ['Zone 1 (50%-60%)', 'Zone 2 (60%-70%)', 'Zone 3 (70%-80%)', 'Zone 4 (80%-90%)', 'Zone 5 (90%-100%)'],
      datasets: [
        {
          data: this.state.showPercentage ? this.props.percentagesHist : this.props.minutesHist,
          label: this.state.showPercentage
            ? I18n.t('components.dashboards.skating.percentage_in_zone')
            : I18n.t('components.dashboards.skating.minutes_in_zone'),
          backgroundColor: ['#FFD1D1', '#F89C9C', '#F26868', '#EB3434', '#E50000'],
          borderColor: ['#FFD1D1', '#F89C9C', '#F26868', '#EB3434', '#E50000']
        }
      ]
    }

    const options = {
      tooltips: {
        callbacks: {
          label: roundTooltip
        }
      },
      legend: {
        display: false
      }
    }

    return (
      <div className='row graph-outline'>
        <span className='graph-title text-background-color text-heavy'>{I18n.t('components.dashboards.skating.heartrate')} ({I18n.t('components.dashboards.skating.hr')})</span>
        <div className='col s12 m4'>
          <div className='value-outline'>
            <div className='input-field'>
              <input className='text-heavy center-align' style={{ fontSize: '26px' }} type='number' onChange={this.props.handleMaxBpmChange} value={this.props.maxBpm} />
            </div>
            <div className='center-align'>
              {I18n.t('components.dashboards.skating.max_hr')}
            </div>
          </div>
          <div className='value-outline'>
            <div className='text-xl text-heavy center-align'>
              {this.props.meanBpm || 0}
            </div>
            <div className='center-align'>
              {I18n.t('components.dashboards.skating.average_hr')}
            </div>
          </div>
          <div className='value-outline'>
            <div className='text-xl text-heavy center-align'>
              {this.props.varBpm || 0}
            </div>
            <div className='center-align'>
              {I18n.t('components.dashboards.skating.variation_hr')}
            </div>
          </div>
        </div>
        <div className='col s12 m8'>
          <Bar data={data} options={options} />
          <div className='col s12 zone-radios'>
            <p>
              <label>
                <input
                  id='percentage' name='group1' type='radio'
                  value='percentage' onChange={this.handleRadioButton.bind(this)}
                  checked={this.state.showPercentage}
                />
                <span>{I18n.t('components.dashboards.skating.percentage_in_zone')}</span>
              </label>
            </p>
            <p>
              <label>
                <input
                  id='minutes' name='group1' type='radio' value='minutes' onChange={this.handleRadioButton.bind(this)}
                  checked={!this.state.showPercentage}
                />
                <span>{I18n.t('components.dashboards.skating.minutes_in_zone')}</span>
              </label>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default HRZones
