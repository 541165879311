import React from 'react'
import LineGraph from 'components/atomic/atoms/graphs/LineGraph'
import { UseQueryOptions } from 'react-query/types'
import moment from 'moment'
import I18n from 'i18n'
import { isEmpty } from 'lodash'
import { useWeeklyTrainingLoadQuery } from 'components/backend/Queries'
import WeeklyTrainingLoadDTO from 'components/common/types/specific_queries/WeeklyTrainingLoad'
import {
  hcBlack,
  hcOrange,
  hcRed
} from 'components/common/Constants'
import {
  fillWithEmptyWeeks,
  getLabels,
  calculateDateRange,
  createLoadValues,
  getQueryOptions, generateChartOptions, getPaddedLabels, getPaddedAverageTrainingSatisfactionData
} from './logic'
import SpinnerWrapper from 'components/common/SpinnerWrapper'
import GenericPlaceholder from 'components/common/GenericPlaceholder'
import { TrainingLogRow } from 'components/common/types/questionnaires/TrainingLogRow'

interface Props {
  start: Date
  end: Date
  profileId: number // warning: this prop can be undefined, but typing it as such will cause TS to complain.
  // There is no way of solving it with how the code is currently structured.
  // To solve it requires a major refactor, which involves making the hook accept undefined profileId,
  // and just return nothing in that case or ensuring that profileId is never undefined when passed to this component
  withTrainingSatisfaction?: boolean
  trainingLogRows?: TrainingLogRow[]
}

const TrainingLoadPerWeekGraph: React.FC<Props> = (props: Props): React.ReactElement => {
  const { start, end, profileId, withTrainingSatisfaction = false, trainingLogRows = [] } = props

  const queryOptions: UseQueryOptions<WeeklyTrainingLoadDTO[], Error> = getQueryOptions(profileId)

  const { isLoading, isError, isSuccess, data: weeklyTrainingLoadData = [] } = useWeeklyTrainingLoadQuery(start, end, profileId, queryOptions)

  // set min and max dates so that fillWithEmptyWeeks does not fill empty weeks before/after datapoints start/end
  // Get the start and end dates from weeklyTrainingLoadData
  const { minDate, maxDate } = calculateDateRange(weeklyTrainingLoadData)
  const dataWithEmptyWeeks = fillWithEmptyWeeks(weeklyTrainingLoadData, minDate, maxDate)

  // we want padding on the sides when the withTrainingSatisfaction = true
  // to prevent the bars from being cut in half on the sides in the wellbeing dash
  const { actualLoadValues, expectedLoadValues } = createLoadValues(dataWithEmptyWeeks, withTrainingSatisfaction)

  const labels: string[] = getPaddedLabels(getLabels(dataWithEmptyWeeks), withTrainingSatisfaction)

  // Filter the rows to only include those within the min and max date range
  // this makes sure the line does not begin or continue after the bars start / end
  const filteredTrainingLogRows = trainingLogRows.filter(entry => moment(entry?.training_start_date).isBetween(minDate, maxDate, null, '[]'))
  const trainingSatisfactionData = getPaddedAverageTrainingSatisfactionData(filteredTrainingLogRows)

  const options = generateChartOptions(withTrainingSatisfaction)

  const dataStruct = {
    labels,
    datasets: [
      ...(withTrainingSatisfaction
        ? [
            {
              label: I18n.t('components.dashboards.questionnaire.training_satisfaction.title'),
              fill: false,
              backgroundColor: hcRed,
              borderColor: hcRed,
              data: trainingSatisfactionData,
              type: 'line',
              yAxisID: 'satisfaction',
              order: 1,
              borderWidth: 2,
              pointRadius: 0,
              pointHoverRadius: 5,
              tension: 0 // remove soft curves
            }
          ]
        : []),
      {
        label: I18n.t('components.dashboards.coach_individual.charts.training_load_per_week.labels.actual_load'),
        fill: false,
        backgroundColor: hcOrange,
        borderColor: hcOrange,
        data: actualLoadValues,
        type: 'bar',
        order: 3
      },
      ...(!withTrainingSatisfaction
        ? [
            {
              label: I18n.t('components.dashboards.coach_individual.charts.training_load_per_week.labels.expected_load'),
              fill: false,
              backgroundColor: undefined,
              borderColor: hcBlack,
              data: expectedLoadValues,
              order: 2
            }
          ]
        : [])
    ]
  }

  if (!isLoading && isEmpty(weeklyTrainingLoadData)) {
    return (
      <GenericPlaceholder
        title={I18n.t('components.dashboards.coach_individual.charts.training_load_per_week.title')}
        message={I18n.t('components.dashboards.generic.not_enough_data')}
      />
    )
  }

  return (
    <SpinnerWrapper failed={isError} ready={isSuccess} transparent={false}>
      <LineGraph data={dataStruct} options={options} />
    </SpinnerWrapper>
  )
}

export default TrainingLoadPerWeekGraph
