import React from 'react'
import { WithSession } from '../session/SessionProvider'
import { withCookies } from 'react-cookie'
import AddToHomescreen from 'react-add-to-homescreen'
import I18n from 'i18n'
import SdvLogo from '../images/sdv-logo.svg'
import classNames from 'classnames'
import LoggedInRouter from '../router/LoggedInRouter'
import LoggedOutRouter from '../router/LoggedOutRouter'
import FlashMessages from '../flashmessages/FlashMessages'
import Footer from '../atomic/organisms/Footer'
import MyReactTooltip from '../MyReactTooltip'

class LoginWrapper extends React.Component {
  handleAddToHomeScreenClick () {
    window.alert(I18n.t('home_screen.instructions'))
    this.props.cookies.set('addedToHomeScreen', true, { path: '/', maxAge: 30 * 24 * 3600, domain: process.env.SESSION_COOKIE_HOSTNAME })
  }

  render () {
    return (
      <>
        <FlashMessages />
        <MyReactTooltip className='higher-z' effect='solid' id='higher-z-tooltip' multiline />
        {this.props.cookies.get('addedToHomeScreen') !== 'true' &&
          <AddToHomescreen onAddToHomescreenClick={this.handleAddToHomeScreenClick.bind(this)} title={I18n.t('home_screen.add_to_home_screen')} icon={SdvLogo} />}
        <div className={classNames('wrapper', 'app-wrapper', this.props.loggedOutClass)}>
          {this.props.isLoggedIn && (
            <LoggedInRouter
              isLoggedIn={this.props.isLoggedIn}
              location={this.props.location}
              admin={this.props.myProfile?.admin}
            />
          )}

          {!this.props.isLoggedIn && <LoggedOutRouter />}
          <Footer isLoggedIn={this.props.isLoggedIn} />
        </div>
      </>
    )
  }
}

export default WithSession(withCookies(LoginWrapper))
