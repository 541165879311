import React from 'react'
import I18n from 'i18n'
import PropTypes from 'prop-types'

import Info from '../../../atomic/atoms/Info'
import { dailyQuestionnaire, rheumaDailyQuestionnaire, sundayQuestionnaire, trainingLog } from 'components/common/Constants'

// Note: this constant is only meant to be used with this component.
// Thus, it should not be in Constants.jsx/tsx to avoid misusage
export const DASHBOARD_REQUIRED_DATA_TYPES = {
  full_questionnaire: [trainingLog, dailyQuestionnaire, sundayQuestionnaire],
  weekly_questionnaire: [trainingLog, dailyQuestionnaire, sundayQuestionnaire],
  multi_athlete_questionnaire: [trainingLog, dailyQuestionnaire, sundayQuestionnaire],
  complaints_questionnaire: [sundayQuestionnaire],
  rheuma_questionnaire: [rheumaDailyQuestionnaire]
}

// Informs which type of datasets are being used in the displayed dashboard
const InfoRequiredData = (props) => {
  const { id, dataTypes } = props

  let text = `${I18n.t('components.dashboards.datasets_info.introduction')}<br>`
  dataTypes.forEach((type) => {
    text += `- ${I18n.t(`components.dashboards.datasets_info.types.${type}`)}<br>`
  })

  return (
    <Info text={text} tooltipId={id} multiline />
  )
}

InfoRequiredData.propTypes = {
  id: PropTypes.string.isRequired,
  // Array with the keys of the data types, as they are in the translation file
  dataTypes: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default InfoRequiredData
