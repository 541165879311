import React from 'react'
import HighchartsReact from 'highcharts-react-official'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { durationFromMinutes, initializeHighcharts, titleWithTooltip } from '../../common/Utils'
import { minYAxis, momentTimeFormat, timeParameters, durationParameters } from '../../common/Constants'
import moment from 'moment'

export default class Chart extends React.Component {
  constructor () {
    super()
    this.state = { type: 'linechart' }
    initializeHighcharts()
    this.chart = React.createRef()
  }

  componentDidUpdate () {
    this.chart?.current?.chart?.redraw()
  }

  render () {
    const { param, mean, errors, weekLine, interval } = this.props
    const series = []
    if (weekLine && weekLine.mean && weekLine.mean.length) {
      series.push({
        name: `${I18n.t(`analysis.dashboard.${param}.yaxis`)} ${I18n.t(`analysis.dashboard.${param}.yaxis`)} in ${I18n.t('analysis.dashboard.interval.week')}`,
        data: weekLine.mean,
        zIndex: 1,
        type: this.state.type === 'barchart' ? 'column' : 'spline',
        color: '#d34316'
      })
    }
    series.push({
      name: `${interval === 'week' ? ' ' : `${I18n.t('analysis.dashboard.average')} `}${I18n.t(`analysis.dashboard.${param}.yaxis`)} in ${I18n.t(`analysis.dashboard.interval.${interval}`)}`,
      data: mean,
      zIndex: 1,
      type: this.state.type === 'barchart' ? 'column' : 'spline',
      color: '#39acdd'
    })

    if (this.state.type === 'barchart') {
      series.push({
        name: I18n.t(`analysis.dashboard.${param}.stdev`),
        data: errors,
        linkedTo: ':previous',
        type: 'errorbar',
        color: '#011258',
        lineWidth: 2,
        zIndex: 2
      })
    } else {
      series.push({
        name: I18n.t(`analysis.dashboard.${param}.stdev`),
        data: errors,
        type: 'areasplinerange',
        linkedTo: ':previous',
        fillOpacity: 0.2,
        lineWidth: 0,
        zIndex: 0,
        color: '#39acdd',
        marker: {
          enabled: false
        }
      })
    }
    let myTitle = {
      text: I18n.t(`analysis.dashboard.${param}.title`)
    }
    if (I18n.t(`analysis.dashboard.${param}.tooltip`).length > 0) {
      myTitle = {
        ...titleWithTooltip(I18n.t(`analysis.dashboard.${param}.title`),
          I18n.t(`analysis.dashboard.${param}.tooltip`),
          this.props.tooltipId)
      }
    }

    let extraYAxisParams = {}
    let tooltipParams = {
      crosshairs: true,
      shared: true
    }
    const minY = minYAxis[param] || 0
    if (timeParameters.includes(param)) {
      extraYAxisParams = {
        type: 'datetime',
        dateTimeLabelFormats: { // force all formats to be hour:minute
          second: '%H:%M',
          minute: '%H:%M',
          hour: '%H:%M',
          day: '%H:%M',
          week: '%H:%M',
          month: '%H:%M',
          year: '%H:%M'
        }
      }
      tooltipParams = {
        crosshairs: true,
        shared: true,
        useHTML: true,
        formatter: function () {
          const date = moment.utc(this.y).format(momentTimeFormat)
          return `<span style="font-size: 10px">${this.points[0].key}</span><br/><span style="color:${this.points[0].color}">●</span> ${this.points[0].series.name}: <b>${date}</b><br/>`
        }
      }
    }
    if (durationParameters.includes(param)) {
      extraYAxisParams = {
        labels: {
          formatter: function () {
            return durationFromMinutes(this.value)
          }
        }
      }
      tooltipParams = {
        crosshairs: true,
        shared: true,
        useHTML: true,
        formatter: function () {
          const value = durationFromMinutes(this.y)
          return `<span style="font-size: 10px">${this.points[0].key}</span><br/><span style="color:${this.points[0].color}">●</span> ${this.points[0].series.name}: <b>${value}</b><br/>`
        }
      }
    }
    const days = [
      I18n.t('components.dashboards.steps.sunday'),
      I18n.t('components.dashboards.steps.monday'),
      I18n.t('components.dashboards.steps.tuesday'),
      I18n.t('components.dashboards.steps.wednesday'),
      I18n.t('components.dashboards.steps.thursday'),
      I18n.t('components.dashboards.steps.friday'),
      I18n.t('components.dashboards.steps.saturday')]
    const today = (new Date().getDay()) % 7
    const sortedDays = days.slice((today + 1) % 7, days.length).concat(days.slice(0, (today + 1) % 7))
    series.forEach((serie) => {
      serie.data = serie.data.slice((today + 1) % 7, serie.data.length).concat(serie.data.slice(0, (today + 1) % 7))
    })

    return (
      <div className='chart-wrapper'>
        <div className='chart'>
          <HighchartsReact
            highcharts={this.props.highcharts}
            updateArgs={[true, true, true]}
            ref={this.chart}
            options={{
              series,
              title: myTitle,
              chart: {
                height: 400
              },
              yAxis: {
                ...extraYAxisParams,
                min: minY,
                title: {
                  text: I18n.t(`analysis.dashboard.${param}.yaxis`),
                  style: {
                    fontSize: '1.2rem'
                  }
                }
              },
              xAxis: {
                categories: sortedDays,
                labels: {
                  style: {
                    fontSize: '1.25rem'
                  }
                }
              },
              legend: {
                enabled: false
              },
              credits: {
                enabled: false
              },
              tooltip: tooltipParams
            }}
          />
        </div>
        <div className='chart-controls'>
          <span onClick={() => this.setState({ type: 'linechart' })} className={`margin-right pointer-grow ${(this.state.type === 'linechart' && 'text-primary-color active') || 'text-inactive-color inactive'}`}>
            <FontAwesomeIcon icon='chart-line' size='2x' />
          </span>
          <span onClick={() => this.setState({ type: 'barchart' })} className={`pointer-grow ${(this.state.type === 'barchart' && 'text-primary-color active') || 'text-inactive-color inactive'}`}>
            <FontAwesomeIcon icon='chart-bar' size='2x' />
          </span>
        </div>
      </div>
    )
  }
}
