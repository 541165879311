import React from 'react'
import I18n from 'i18n'
import Group from './Group'
import Spinner from '../../common/Spinner'
import GenericPlaceholder from '../../common/GenericPlaceholder'

class GroupList extends React.Component {
  render () {
    const { groups, onLeave } = this.props
    return (
      <>
        <div className='col s12 text-heavy muted-header'>
          <div className='row'>
            <div className='col s12'>
              <h5>{I18n.t('network.groups.title', { count: groups.length })}</h5>
            </div>
          </div>
        </div>
        <div className='col s12'>
          <Spinner ready={this.props.dataRetrieved} transparent />
          {this.props.dataRetrieved && groups.length === 0 && <GenericPlaceholder title={I18n.t('connections.groups.no_groups_placeholder.title')} message={I18n.t('connections.groups.no_groups_placeholder.message')} />}
          {groups.map(group => (
            <Group key={`group-${group.id}`} onShowDetails={this.props.onShowDetails} group={group} onLeave={onLeave} />
          ))}
        </div>
      </>
    )
  }
}

export default GroupList
