import React from 'react'
import Variables from '../../../../../javascript/stylesheets/variables.module.scss'
const { primaryColor } = Variables

interface Props {
  valueFormatted?: string
  value: any
  data: any
}

const TextRenderer: React.FC<Props> = ({ value, data }): React.ReactElement => {
  const shouldDisplayAsLink = data.instructions_is_link
  if (shouldDisplayAsLink === true) {
    return <span style={{ color: primaryColor, textDecoration: 'underline' }}>{value}</span>
  }
  return <>{value}</>
}

export default TextRenderer
