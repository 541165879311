import React, { useContext } from 'react'
import { FlashMessageContext } from '../../flashmessages/FlashMessageProvider'

export interface ICallFeedbackMessageHook {
  handleCallFeedback: (creationPromise: Promise<unknown>, successMessage: string, errorMessage: string, callbackFn?: (succes: boolean) => void) => void
}

// This hook allows you to easily send a message upon completion or rejection
// of a promise to the user. It uses the flashmessage provider to send the
// message, and can also call a callbackfunction
const CallFeedbackMessageHook = (): ICallFeedbackMessageHook => {
  const flashMessages = useContext(FlashMessageContext)?.flashMessages

  const handleCallFeedback = (creationPromise: Promise<unknown>, successMessage: string, errorMessage: string, callbackFn?: (succes: boolean) => void): void => {
    creationPromise.then((_response: unknown) => {
      flashMessages.push(
        successMessage,
        flashMessages.duration.SHORT,
        flashMessages.levels.INFO
      )
      callbackFn?.(true)
    }).catch((e: Error) => {
      console.error(e)
      flashMessages.push(
        errorMessage,
        flashMessages.duration.SHORT,
        flashMessages.levels.ERROR
      )
      callbackFn?.(false)
    })
  }
  return {
    handleCallFeedback: handleCallFeedback
  }
}
CallFeedbackMessageHook.defaultProps = {
}
export default CallFeedbackMessageHook

// This is a workaround to get this hook working in a class component. Remove when class components are not needed anymore.
export const WithCallFeedbackMessageHook = (Component: any) => {
  return (props: any) => {
    const hook = CallFeedbackMessageHook()

    return <Component {...hook} {...props} />
  }
}
