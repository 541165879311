import { Step } from 'react-joyride'

const INTRODUCTION = {
  target: '.app-wrapper #timeline-nav-item',
  placementBeacon: 'bottom',
  placement: 'bottom',
  content: 'components.tour_steps.home.introduction'
}

const INTRODUCE_TIMELINE = {
  target: '.timeline-cards',
  placement: 'top',
  content: 'components.tour_steps.home.timeline'
}

const INTRODUCE_ACTION_PANEL = {
  target: '.welcome-left-menu',
  content: 'components.tour_steps.home.action_panel'
}

const INTRODUCE_ADD_DATA_BUTTON = {
  target: 'button#timeline-upload-data',
  placement: 'right',
  content: 'components.tour_steps.home.add_data_button'
}

const INTRODUCE_QUESTIONNAIRES_BUTTON = {
  target: '.questionnaires-button',
  placement: 'right',
  content: 'components.tour_steps.home.questionnaires_button'
}

const INTRODUCE_PLAN_TRAININGS_BUTTON = {
  target: '.schedule-training-button',
  placement: 'right',
  content: 'components.tour_steps.home.plan_trainings_button'
}

const INTRODUCE_TIMELINE_TABS = {
  target: '#timeline-tabs',
  content: 'components.tour_steps.home.timeline_tabs'
}

const INTRODUCE_NAV_BAR = {
  target: '.app-wrapper .top-menu-pages',
  placement: 'bottom',
  content: 'components.tour_steps.home.nav_bar'
}

const INTRODUCE_HELP_BUTTONS = {
  target: '.feedback-panel',
  placement: 'left',
  content: 'components.tour_steps.home.help_buttons'
}

export const HOME_PAGE_STEPS: Step[] = [
  INTRODUCTION,
  INTRODUCE_TIMELINE,
  INTRODUCE_TIMELINE_TABS,
  INTRODUCE_ACTION_PANEL,
  INTRODUCE_ADD_DATA_BUTTON,
  INTRODUCE_QUESTIONNAIRES_BUTTON,
  INTRODUCE_PLAN_TRAININGS_BUTTON,
  INTRODUCE_NAV_BAR,
  INTRODUCE_HELP_BUTTONS
]
