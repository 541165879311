import React from 'react'
import { WithSession } from '../session/SessionProvider'

class GroupJoinController extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      code: undefined
    }
  }

  componentDidMount () {
    const { match: { params: { code } } } = this.props
    this.setState({
      code: code
    })
  }

  render () {
    if (this.state.code !== undefined) {
      this.props.join(this.state.code)
      // Redirected to login page to prevent auto-redirect to info page
      this.props.history.push(`/login/?c=${this.state.code}`)
    }

    return (<></>)
  }
}

export default WithSession(GroupJoinController)
