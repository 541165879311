import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const LoggedInRedirector = (props) => {
  return (
    <>
      <Route exact path='/login'>
        <Redirect to='/' />
      </Route>
      <Route exact path='/signup'>
        <Redirect to='/' />
      </Route>
    </>
  )
}

export default LoggedInRedirector
