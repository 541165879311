import React, { useMemo, useState } from 'react'
import SelectDropdown from '../../../common/SelectDropdown'
import I18n from 'i18n'
import Variables from '../../../../stylesheets/variables.module.scss'
import TrainingLoadChart, { LOAD_DURATION, LOAD_TYPES } from '../generic/components/TrainingLoadChart'
import CumulativeTrainingLoadChart from '../generic/components/CumulativeTrainingLoadChart'
import { collectionTypes, datePickerDefaultOptions, subscriptionTypes, timeSeries } from '../../../common/Constants'
import GenericPlaceholder from '../../../common/GenericPlaceholder'
import TrainingIntensityChart, { INTENSITY_TYPES } from '../generic/components/TrainingIntensityChart'
import { WithFlashMessages } from '../../../flashmessages/FlashMessageProvider'
import {
  addEmptyRecords,
  useDashboardDataFromMultipleCollectionTypes,
  useGeolocationDataFromMultipleCollectionTypes,
  getMultiActivityDashboardDescriptiveStatistics,
  useTrainingIntensityDataFromMultipleSports,
  useTrainingLoadDataFromMultipleSports
} from '../generic/GenericDashboardsUtils'
import { useDisplayFetchErrors, useEffectSkipDefault } from '../../../common/Hooks'
import _ from 'lodash'
import SpinnerWrapper from '../../../common/SpinnerWrapper'
import SDVMapView from '../../../data/layout/detail/SDVMapView'
import DatePicker from 'react-datepicker'

import classNames from 'classnames'
import Rings from '../common/Rings'
import DisciplineHoursChart from './DisciplineHoursChart'
import { s2hm } from '../../../common/Units'
import moment from 'moment'
import AdvancedOptions from '../common/AdvancedOptions'
import PremiumOverlay from '../../../premium_membership/PremiumOverlay'
import Slider from '../../../common/Slider'
const {
  primaryColor,
  secondaryColor,
  accentColor,
  greenLight,
  green,
  greenDark
} = Variables

const loads = LOAD_TYPES.map(int => ({ value: int, name: I18n.t(`components.dashboards.multi_activity.load.${int}`) }))
const intensities = INTENSITY_TYPES.map(int => ({ value: int, name: I18n.t(`components.dashboards.multi_activity.intensity.${int}`) }))
const sportsOfInterest = [collectionTypes.RIDING, collectionTypes.RUNNING, collectionTypes.SWIMMING]
const sportsColors = [accentColor, secondaryColor, primaryColor]
// Used in the disabled daypart dropdown
// const timesOfDay = ['all', 'morning', 'afternoon', 'evening'].map(int => ({ value: int, name: I18n.t(`components.dashboards.multi_activity.period.${int}`) }))

const N_ZONES = 5
const zoneLabels = _.range(N_ZONES).map(z => `${I18n.t('components.dashboards.multi_activity.zone')} ${z + 1}`)

const trainingLoadCategories = sportsOfInterest.map((sport, spIdx) => (
  { name: sport, color: sportsColors[spIdx], label: I18n.t(sport) })
)

const disciplineDurationLegend = `${I18n.t('units.h')}:${I18n.t('units.min')}`

function computeStatistics (data) {
  let [mor, aft, eve, totC, totR, totS] = [0, 0, 0, 0, 0, 0]
  // Number of sportsOfInterest is know at compile time, number of data entries is not know since it depends on how
  // quickly the backend responds. Thus we iterate on sports of interest, otherwise react will freak out about the
  // number of calls to the hook not being consistent
  sportsOfInterest.forEach((collectionType, idx) => {
    const { morningCount, afternoonCount, eveningCount, totalTime } = getMultiActivityDashboardDescriptiveStatistics(data[idx]?.current?.activities)
    mor += morningCount
    aft += afternoonCount
    eve += eveningCount
    switch (collectionType) {
      case collectionTypes.RIDING: totC += totalTime; break
      case collectionTypes.RUNNING: totR += totalTime; break
      case collectionTypes.SWIMMING: totS += totalTime; break
    }
  })
  return { totalCyclingTime: totC, totalRunningTime: totR, totalSwimmingCount: totS, morningCount: mor, afternoonCount: aft, eveningCount: eve }
}

const TriathlonDashboard = (props) => {
  const [start, setStart] = useState(undefined)
  const [end, setEnd] = useState(undefined)
  const [rangeFilter, setRangeFilter] = useState(undefined)
  // Used in the disabled daypart dropdown
  // const [timeOfDay, setTimeOfDay] = useState('all')
  const [load, setLoad] = useState(LOAD_DURATION)
  const [intensity, setIntensity] = useState(timeSeries.HR)
  const [selectedProfiles, setSelectedProfiles] = useState([])
  const periodType = 'week' // TODO Change to useState. This will be useful once we have the month view too.
  const queryParams = { start: start, end: end, profile_ids: selectedProfiles }
  const { data, fetched, errors, reFetch } = useDashboardDataFromMultipleCollectionTypes(sportsOfInterest, queryParams)
  const {
    data: geolocations,
    fetched: geoFetched,
    errors: geoErrors,
    reFetch: reFetchGeo
  } = useGeolocationDataFromMultipleCollectionTypes(sportsOfInterest, queryParams)

  useDisplayFetchErrors(errors, fetched, I18n.t('components.dashboards.triathlon.error_fetching_dashboard'))
  useDisplayFetchErrors(geoErrors, geoFetched, I18n.t('components.dashboards.triathlon.error_fetching_geolocation'))

  const trainingLoadWithMissingDates = useTrainingLoadDataFromMultipleSports(data, sportsOfInterest)
  const trainingIntensityWithMissingDates = useTrainingIntensityDataFromMultipleSports(data)
  const [trainingLoad, trainingIntensity] = useMemo(() => {
    const startDate = moment(_.get(_.first(trainingLoadWithMissingDates), '_id.date'))
    const endDate = moment(_.get(_.last(trainingLoadWithMissingDates), '_id.date'))

    return [
      addEmptyRecords(trainingLoadWithMissingDates, startDate, endDate, periodType, LOAD_TYPES, collectionTypes.RIDING),
      addEmptyRecords(trainingIntensityWithMissingDates, startDate, endDate, periodType, INTENSITY_TYPES, collectionTypes.RIDING)
    ]
  }, [trainingLoadWithMissingDates, trainingIntensityWithMissingDates])

  const numRecords = trainingLoad.length

  const { totalCyclingTime, totalRunningTime, totalSwimmingCount, morningCount, afternoonCount, eveningCount } = computeStatistics(data)

  const paramsArray = sportsOfInterest.map(s => ({ ...queryParams, collection_type: s }))

  const sliderId = 'date-filter-slider'

  function handleApplyFilter () {
    reFetch(paramsArray)
    reFetchGeo(paramsArray)
    setRangeFilter(undefined)
    Slider.reset(sliderId)
  }

  useEffectSkipDefault(() => {
    handleApplyFilter()
    // Add timeOfDay as dependency once BE functionality is included
  }, [start, end])

  const profiles = useMemo(() => {
    if (_.get(data, 'length', 0) < 1) return []
    const allProfiles = data.map(sportData => _.get(sportData, 'profiles', [])).flat()
    return _.uniqBy(allProfiles, 'id').map(profile => ({
      name: `${profile.first_name} ${profile.last_name}`,
      value: profile.id,
      icon: profile.picture
    }))
  }, [data])

  function handleLoadChange (e) { setLoad(e.target.value) }
  function handleIntensityChange (e) { setIntensity(e.target.value) }
  function handleStartChange (d) { setStart(d) }
  function handleEndChange (d) { setEnd(d) }
  // Used in the disabled daypart dropdown
  // function handlePeriodChange (e) { setTimeOfDay(e.target.value) }

  function handleProfilesChange (e) {
    const profileIds = [..._.get(e, 'target.selectedOptions', [])]
      .map(o => o.value || undefined)
      .filter(n => n)
    setSelectedProfiles(profileIds)
  }

  function handleApplyDateFilter (newRange) {
    setRangeFilter(newRange.map((v) => parseInt(v)))
  }

  const timeRing = {
    data: {
      datasets: [{
        data: [morningCount, afternoonCount, eveningCount],
        backgroundColor: [greenLight, green, greenDark]
      }],
      labels: [I18n.t('components.dashboards.multi_activity.period.morning'), I18n.t('components.dashboards.multi_activity.period.afternoon'), I18n.t('components.dashboards.multi_activity.period.evening')]
    },
    options: {
      title: {
        text: I18n.t('components.dashboards.multi_activity.period.label'),
        title: {
          display: true
        },
        maintainAspectRatio: false,
        legend: {
          display: false
        }
      }
    }
  }

  const disciplines = [
    {
      name: I18n.t(collectionTypes.RIDING),
      duration: totalCyclingTime,
      color: accentColor
    },
    {
      name: I18n.t(collectionTypes.RUNNING),
      duration: totalRunningTime,
      color: secondaryColor
    },
    {
      name: I18n.t(collectionTypes.SWIMMING),
      duration: totalSwimmingCount,
      color: primaryColor
    }
  ]

  return (
    <>
      <div className='questionnaire-dashboard-wrapper'>
        <div className='row'>
          <div className='col s12 text-xl text-primary-color text-heavy'>
            {I18n.t('components.dashboards.triathlon.title')}
          </div>
        </div>

        <div className='row'>
          <div className='col s6 m3 input-field'>
            <DatePicker
              id='start' {...datePickerDefaultOptions()} selected={start} onChange={handleStartChange}
            />
            <label htmlFor='start' className={classNames({ active: start })}>{I18n.t('components.dashboards.multi_activity.start_date')}</label>
          </div>
          <div className='col s6 m3 input-field'>
            <DatePicker
              id='end' {...datePickerDefaultOptions()} selected={end} onChange={handleEndChange}
            />
            <label htmlFor='end' className={classNames({ active: end })}>{I18n.t('components.dashboards.multi_activity.end_date')}</label>
          </div>
          {/* Uncomment once functionality is implemented */}
          {/* <div className='col s12 m3'>
            <SelectDropdown disabled id='period' content={timesOfDay} onChange={handlePeriodChange} defaultValue={timeOfDay} label={I18n.t('components.dashboards.multi_activity.period.label')} />
          </div> */}
        </div>

        <div className='row'>
          <div className='col s12'>
            <AdvancedOptions>
              <PremiumOverlay activeFor={[subscriptionTypes.coach, subscriptionTypes.researcher]} showBadge={false}>
                <div className='row'>
                  <div className='col s12'>
                    <SelectDropdown
                      id='select-profiles'
                      isMultipleChoice
                      content={profiles}
                      onChange={handleProfilesChange}
                      label={I18n.t('components.dashboards.multi_activity.advanced_filters.athletes')}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col s12'>
                    <button
                      id='submit-advanced-filter'
                      className='button-primary background-primary button-autowidth waves-effect waves-light text-background-color'
                      onClick={handleApplyFilter}
                    >
                      {I18n.t('components.dashboards.multi_activity.advanced_filters.submit')}
                    </button>
                  </div>
                </div>
              </PremiumOverlay>
            </AdvancedOptions>
          </div>
        </div>

        <div className='row'>
          <div className='col m12 l8'>
            <div className='chart-container'>
              <SpinnerWrapper ready={fetched} transparent>
                <DisciplineHoursChart disciplines={disciplines} tickCallback={s2hm} durationLegend={disciplineDurationLegend} />
              </SpinnerWrapper>
            </div>
          </div>
          <div className='col m12 l4'>
            <div className='chart-container'>
              <SpinnerWrapper ready={fetched} transparent>
                <Rings ringConfigs={[timeRing]} translatedUp />
              </SpinnerWrapper>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col s12'>
            <SpinnerWrapper ready={geoFetched}>
              <SDVMapView heatmap={geolocations} bw />
            </SpinnerWrapper>
          </div>
        </div>

        <div className='row'>
          <div className='col s12'>
            <Slider
              id={sliderId}
              range={[0, numRecords - 1 || 1]}
              start={[0, numRecords - 1 || 1]}
              onChange={handleApplyDateFilter}
              title={I18n.t('components.dashboards.multi_activity.weeks')}
              disableTooltips
            />
          </div>
        </div>

        <div className='row'>
          <div className='col s6'>
            <SelectDropdown id='training-load' content={loads} onChange={handleLoadChange} defaultValue={load} label={I18n.t('components.dashboards.multi_activity.load.label')} />
          </div>
          <div className='col s6'>
            <SelectDropdown id='training-intensity' content={intensities} onChange={handleIntensityChange} defaultValue={intensity} label={I18n.t('components.dashboards.multi_activity.intensity.label')} />
          </div>
        </div>

        <div className='row'>
          <div className='col s12 l6'>

            <div className='row'>
              <div className='col s12'>
                <div className='chart-container'>
                  <SpinnerWrapper ready={fetched} transparent>
                    <TrainingLoadChart categories={trainingLoadCategories} trainingLoad={trainingLoad} loadType={load} range={rangeFilter} />
                  </SpinnerWrapper>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col s12'>
                <div className='chart-container'>
                  <SpinnerWrapper ready={fetched} transparent>
                    <CumulativeTrainingLoadChart trainingLoad={trainingLoad} loadType={load} nested range={rangeFilter} />
                  </SpinnerWrapper>
                </div>
              </div>
            </div>

          </div>
          <div className='col s12 l6'>

            <div className='row'>
              <div className='col s12'>
                <div className='chart-container'>
                  <SpinnerWrapper ready={fetched} transparent>
                    <TrainingIntensityChart trainingIntensity={trainingIntensity} intensityType={intensity} zoneLabels={zoneLabels} range={rangeFilter} />
                  </SpinnerWrapper>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col s12'>
                <div className='chart-container'>
                  <GenericPlaceholder message={I18n.t('components.dashboards.under_development.message')} title={I18n.t('components.dashboards.triathlon.hours_sleep.title')} />
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className='row'>
          <div className='col s6'>
            <div className='chart-container'>
              <GenericPlaceholder message={I18n.t('components.dashboards.under_development.message')} title={I18n.t('components.dashboards.questionnaire.weekly.health.resting_hr')} />
            </div>
          </div>
          <div className='col s6'>
            <div className='chart-container'>
              <GenericPlaceholder message={I18n.t('components.dashboards.under_development.message')} title={I18n.t('components.dashboards.triathlon.heart_rate_training_load.title')} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WithFlashMessages(TriathlonDashboard)
