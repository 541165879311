
import React, { useMemo } from 'react'
import { GoogleMap, Polyline, Marker, MarkerClusterer, HeatmapLayer } from '@react-google-maps/api'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { bwMapStyle } from '../../../common/Constants'

// TODO: At some point this will be only MapView needed and will be renamed to MapView
const SDVMapView = (props) => {
  const { path, markers = [], mapHeight = '250px' } = props
  const processedPath = useMemo(() => processPath(path), [path])
  const heatmapData = useMemo(() => processHeatmap(props.heatmap), [props.heatmap])
  const geoData = processedPath || heatmapData

  const options = {
    streetViewControl: false,
    zoomControl: false,
    mapTypeControl: false,
    styles: props.bw ? bwMapStyle : undefined
  }

  if (!geoData) return <></>

  function processPath (path) {
    if (!path) return

    return (path.map(entry => ({ lat: entry[0], lng: entry[1] })))
  }

  function fitBounds (map) {
    if (!geoData || !map) return

    const bounds = new window.google.maps.LatLngBounds()
    if (processedPath) {
      geoData.forEach(entry => {
        bounds.extend(new window.google.maps.LatLng(entry.lat, entry.lng))
      })
    } else {
      geoData.forEach(entry => {
        bounds.extend(entry)
      })
    }
    map.fitBounds(bounds)
  }

  function onUpdate (mapRef) {
    fitBounds(_.get(mapRef, 'state.map'))
  }

  function titleCalculator () {
    return {
      text: '',
      index: markers.length - 1
    }
  }

  function processHeatmap (heatmap) {
    if (!heatmap) return undefined
    // Keep only truthy values
    const cleanHeatmap = heatmap.filter(h => h)
    return cleanHeatmap.map(h => new window.google.maps.LatLng(h[0], h[1]))
  }

  return (
    <>
      <GoogleMap
        mapContainerStyle={{ height: mapHeight, width: '100%' }}
        defaultZoom={15}
        minimumClusterSize={2}
        defaultCenter={{ lat: 52.156961, lng: 4.485483 }}
        onLoad={fitBounds}
        options={options}
        ref={onUpdate}
      >
        <Polyline
          onLoad={polyline => { }}
          path={processedPath}
          options={{
            strokeColor: '#e24a00',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#e24a00',
            fillOpacity: 0.35,
            clickable: false,
            draggable: false,
            editable: false,
            visible: true,
            radius: 30000,
            zIndex: 1
          }}
        />
        <MarkerClusterer
          averageCenter
          calculator={titleCalculator}
          styles={[{
            url: '/images/maps-marker-icon.png',
            width: 27,
            height: 43,
            anchorIcon: [43, 12]
          }]}
        >
          {
            (clusterer) => markers.map(marker => {
              if (!marker.position) return <></>

              const [lat, lng] = marker.position
              return (
                <Marker key={`${lat}${lng}`} {...marker} position={{ lat: lat, lng: lng }} clusterer={clusterer} />
              )
            })
          }
        </MarkerClusterer>
        {
          heatmapData &&
            <HeatmapLayer data={heatmapData} />
        }
      </GoogleMap>
    </>
  )
}

SDVMapView.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.shape({
    // lat, long array
    position: PropTypes.array,
    label: PropTypes.string
  })),
  // Array of [lat, long]
  heatmap: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  // Use black & white style
  bw: PropTypes.bool
}

export default SDVMapView
