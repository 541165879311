import WeeklyTrainingLoadDTO, { WeeklyTrainingLoad } from 'components/common/types/specific_queries/WeeklyTrainingLoad'
import moment, { Moment } from 'moment'
import _ from 'lodash'
import { formatDateTime } from 'components/common/Utils'
import { TrainingLogRow } from 'components/common/types/questionnaires/TrainingLogRow'
import { formatDateRange } from 'components/common/utils/DateUtils'
import { chartjsDefaultOptions, chartjsTitleProperties } from 'components/common/Constants'
import I18n from 'i18n'

// This method assumes that data is sorted from oldest to newest
// TODO: Renaming of WeeklyTrainingLoad + make the loop cleaner
export const fillWithEmptyWeeks = (data: WeeklyTrainingLoadDTO[], start: Date, end: Date): WeeklyTrainingLoad[] => {
  const rangeStart = moment(start)
  const rangeEnd = moment(end)

  const result: WeeklyTrainingLoad[] = []
  let dataIndex: number = 0

  for (let iterator = rangeStart.clone(); iterator.isSameOrBefore(rangeEnd, 'isoWeek'); iterator.add(1, 'week')) {
    const currentRecordWithData: WeeklyTrainingLoadDTO = data[dataIndex]

    const newRecord: WeeklyTrainingLoad = {
      start: firstDayOfWeek(iterator).toDate(),
      end: lastDayOfWeek(iterator).toDate()
    }

    // If there is already a record for that week
    if (currentRecordWithData !== undefined && moment(currentRecordWithData?.start).isSame(iterator, 'isoWeek')) {
      newRecord.actual_load = currentRecordWithData.actual_load
      newRecord.expected_load = currentRecordWithData.expected_load
      dataIndex += 1
    }

    result.push(newRecord)
  }

  // Correction in case firstDayOfWeek/lastDayOfWeek are before/after the start/end date
  if (result.length > 0) {
    result[0].start = rangeStart.toDate()
    result[result.length - 1].end = rangeEnd.toDate()
  }
  return result
}

interface WeekAverage {
  x: string
  y: number
  rpe: number
  training_type: string
  training_session: string
  formatted_start_date: string
}
// functions to calculate weekly average for training satisfaction
export const getWeek = (date: Date): Date => moment(date).startOf('isoWeek').toDate()
export const groupByWeekAndAverage = (data: TrainingLogRow[]): WeekAverage[] => {
  const groupedByWeek = _.groupBy(data, entry => getWeek(entry.training_start_date))
  return _.map(groupedByWeek, (entries, week) => {
    const averageSatisfaction = _.round(_.meanBy(entries, 'training_satisfaction'), 1)
    const averageRPE = _.meanBy(entries, 'rpe')
    return {
      x: week,
      y: averageSatisfaction,
      rpe: averageRPE,
      training_type: entries[0]?.training_type,
      training_session: entries[0]?.training_session,
      formatted_start_date: formatDateTime(week)
    }
  })
}

export const firstDayOfWeek = (momentDate: Moment): Moment => momentDate.clone().startOf('isoWeek')
export const lastDayOfWeek = (momentDate: Moment): Moment => momentDate.clone().endOf('isoWeek')

export const getLabels = (data: WeeklyTrainingLoad[]): string[] => {
  return data.map((record) => {
    const weekStart: Moment = moment(record.start)
    const weekEnd: Moment = weekStart.clone().endOf('isoWeek')
    return formatDateRange(weekStart.toDate(), weekEnd.toDate())
  })
}

export const calculateDateRange = (data: WeeklyTrainingLoadDTO[]): { minDate: Date, maxDate: Date } => {
  const minDate = data[0]?.start
  const maxDate = data[data.length - 1]?.end
  return { minDate, maxDate }
}

export const createLoadValues = (dataWithEmptyWeeks: WeeklyTrainingLoad[], withTrainingSatisfaction: boolean): { actualLoadValues: number[], expectedLoadValues: number[] } => {
  let actualLoadValues = dataWithEmptyWeeks.map((record) => record?.actual_load ?? 0)
  let expectedLoadValues = dataWithEmptyWeeks.map((record) => record?.expected_load ?? 0)

  if (withTrainingSatisfaction) {
    actualLoadValues = [NaN, ...actualLoadValues, NaN]
    expectedLoadValues = [NaN, ...expectedLoadValues, NaN]
  }

  return { actualLoadValues, expectedLoadValues }
}

export function getQueryOptions (profileId: number | null): { enabled: boolean } {
  return {
    enabled: profileId !== null && profileId > 0 // Do not query if there is no profileId
  }
}

export function generateChartOptions (withTrainingSatisfaction: boolean): Record<string, unknown> {
  return {
    ...chartjsDefaultOptions,
    elements: {
      point: {
        hoverRadius: 5
      }
    },
    title: {
      ...chartjsTitleProperties,
      text: I18n.t('components.dashboards.coach_individual.charts.training_load_per_week.title')
    },
    scales: {
      yAxes: [{
        ticks: {
          suggestedMin: 0,
          maxTicksLimit: 7
        },
        scaleLabel: {
          display: true,
          labelString: I18n.t('components.dashboards.coach_individual.charts.training_load_per_week.labels.y_axis'),
          fontSize: 15
        }
      },
      ...(withTrainingSatisfaction
        ? [
            {
              id: 'satisfaction',
              position: 'right',
              ticks: {
                suggestedMin: 0,
                max: 6
              },
              scaleLabel: {
                display: true,
                labelString: I18n.t('components.dashboards.questionnaire.training_satisfaction.yaxis'),
                fontSize: 15
              }
            }
          ]
        : [])
      ]
    },
    tooltips: {
      mode: 'index',
      intersect: false
    }
  }
}

export function getPaddedLabels (labels: string[], withTrainingSatisfaction: boolean): string[] {
  return withTrainingSatisfaction ? [' ', ...labels, ' '] : labels
}

interface TrainingLogRowPadding {
  x: undefined
  y: undefined
  rpe: undefined
  training_type: undefined
  training_session: undefined
  formatted_start_date: undefined
}
export function getPaddedAverageTrainingSatisfactionData (trainingSatisfactionData: TrainingLogRow[]): Array<TrainingLogRowPadding | WeekAverage> {
  const emptyData: TrainingLogRowPadding = {
    x: undefined,
    y: undefined,
    rpe: undefined,
    training_type: undefined,
    training_session: undefined,
    formatted_start_date: undefined
  }

  return [emptyData, ...groupByWeekAndAverage(trainingSatisfactionData), emptyData]
}
