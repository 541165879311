import React from 'react'
import classNames from 'classnames'
import { useGroupsQuery } from 'components/backend/Queries'
import Group, { GroupType } from 'components/common/types/Group'
import { prettifyTag } from 'components/common/utils/TagUtils'

interface TagProps {
  onClick?: (tag: string) => void
  tags: string[]
  margin: 'full' | 'none' | 'side'
}

const Tag: React.FC<TagProps> = ({ tags, onClick, margin }): React.ReactElement => {
  const { data: groupsAndConnections = [] } = useGroupsQuery()

  if (tags === undefined) return (<></>)

  let classes = 'chip'
  classes = classNames(classes, { pointer: onClick })
  classes = classNames(classes, { 'margin-none': margin === 'none' })
  classes = classNames(classes, { 'margin-side': margin === 'side' })

  const groups: Group[] = groupsAndConnections.filter((group) => [GroupType.ANONYMIZED, GroupType.GROUP].includes(group.group_type))
  return (
    <>
      {
        tags.map((tag) => {
          return (
            <div key={tag} className={classes} onClick={() => onClick?.(tag)}>
              {prettifyTag(tag, groups)}
            </div>
          )
        })
      }
    </>
  )
}

Tag.defaultProps = {
  margin: 'full',
  tags: []
}

export default Tag
