import React from 'react'
import I18n from 'i18n'
import moment from 'moment'
import { reduce, last, maxBy, get, cloneDeep, find } from 'lodash'

import { isDailyQuestionnaire, isQuestionnaire, isTrainingLog, readDailyQuestionnaire, readTrainingQuestionnaire } from '../../../common/utils/QuestionnaireUtils'
import { dataTypes, DECIMALS_ROUNDING, EMPTY_RECORD, sdvDataFormatSports } from '../../../common/Constants'
import { round } from '../../../common/Math'
import { ms2kmh, s2hms } from '../../../common/Units'
import { getFirstDataRow } from '../../../common/Utils'
import { getTrainingLoad } from '../../../common/Calculations'
import UserProfileTableEntry from '../../../common/profile_representations/UserProfileTableEntry'
import Variables from '../../../../stylesheets/variables.module.scss'
import VDO from 'components/common/VDO'
const { accentColor, green } = Variables

const ColoredTextWrapper = (props) => {
  const { color, children } = props
  return <p style={{ color: color }}>{children}</p>
}

const getHealthColor = (isSick, isInjured) => isSick || isInjured ? accentColor : green

const getHealthProblems = (dailyData) => {
  const sickStatus = dailyData?.isSick
  const injuryStatus = dailyData?.isInjured

  if (sickStatus === undefined && injuryStatus === undefined) {
    return EMPTY_RECORD
  }

  const healthProblems = []
  if (sickStatus) { healthProblems.push(I18n.t('components.dashboards.coach_individual.health.sick')) }
  if (injuryStatus) { healthProblems.push(I18n.t('components.dashboards.coach_individual.health.injured')) }
  if (healthProblems.length === 0) { healthProblems.push(I18n.t('components.dashboards.coach_individual.health.healthy')) }

  return healthProblems.join(' & ')
}

const getSleepDurationString = (hours, minutes) => {
  const duration = moment.duration({ hours: hours, minutes: minutes })
  if (!hours || !minutes) {
    return EMPTY_RECORD
  } else {
    return `${parseInt(duration.asHours())}${I18n.t('units.h')}${duration.minutes()}${I18n.t('units.min')}`
  }
}

export const prepareDailyData = (readDailyData) => {
  const preparedDailyData = cloneDeep(readDailyData)
  preparedDailyData.healthProblems = getHealthProblems(readDailyData)

  return preparedDailyData
}

export const prepareTrainingLogData = (readTrainingLog) => {
  const preparedLogData = cloneDeep(readTrainingLog)
  const { rpe, duration, training_type: trainingType, session_type: sessionType } = preparedLogData

  preparedLogData.training_type = I18n.t(trainingType)
  preparedLogData.session_type = I18n.t(sessionType)

  preparedLogData.srpe = round(getTrainingLoad(rpe, duration), DECIMALS_ROUNDING)
  preparedLogData.duration = `${s2hms(duration)} ${I18n.t('units.h')}`
  return preparedLogData
}

export const createAthleteRowEntry = (athleteProfile, athleteDatasets, onRowClick = () => {}) => {
  const rowEntry = {
    handleClick: () => {},
    content: {}
  }
  const { dailyQuestionnaire, trainingLog, activity, scheduledTraining, extraData } = athleteDatasets

  const parsedDailyData = prepareDailyData(readDailyQuestionnaire(getFirstDataRow(dailyQuestionnaire)?.values))
  const parsedTrainingData = prepareTrainingLogData(readTrainingQuestionnaire(getFirstDataRow(trainingLog)?.values))
  const expectedRPE = scheduledTraining?.expected_rpe
  const avgSpeed = ms2kmh(activity?.summary?.time_series_summaries?.speed?.mean)
  const avgPower = activity?.summary?.time_series_summaries?.power?.mean
  const duration = activity?.summary?.duration || parsedTrainingData?.duration
  const elapsedTimeHrZones = activity?.summary?.time_series_summaries?.hr?.zone_elapsed_seconds

  const { content } = rowEntry

  content.athlete = <UserProfileTableEntry profile={athleteProfile} />

  content.healthStatus = (
    <ColoredTextWrapper color={getHealthColor(parsedDailyData?.isSick, parsedDailyData?.isInjured)}>
      {parsedDailyData?.healthProblems}
    </ColoredTextWrapper>
  )

  content.restingHr = parsedDailyData?.restingHr
  content.wellbeingScore = parsedDailyData.wellnessScore ? `${parsedDailyData.wellnessScore}%` : undefined
  content.sleepDuration = getSleepDurationString(parsedDailyData?.sleepDuration?.hours, parsedDailyData?.sleepDuration?.minutes)
  content.sleepQuality = round(parsedDailyData?.sleepQuality, DECIMALS_ROUNDING)
  content.readiness = round(parsedDailyData?.readiness, DECIMALS_ROUNDING)
  content.stress = round(parsedDailyData?.stress, DECIMALS_ROUNDING)
  content.mood = round(parsedDailyData?.mood, DECIMALS_ROUNDING)
  content.fatigue = round(parsedDailyData?.fatigue, DECIMALS_ROUNDING)
  content.musclePain = round(parsedDailyData?.generalMusclePain, DECIMALS_ROUNDING)

  content.rpe = round(parsedTrainingData?.rpe, DECIMALS_ROUNDING)
  if (expectedRPE) {
    const rpe = parsedTrainingData?.rpe ? `${parsedTrainingData?.rpe}` : EMPTY_RECORD
    content.rpe = `${rpe} (${I18n.t('components.dashboards.coach_individual.scheduled')}: ${expectedRPE})`
  }
  content.srpe = round(parsedTrainingData?.srpe, DECIMALS_ROUNDING)
  content.acwr = round(extraData?.acwr, DECIMALS_ROUNDING)
  content.trimp = round(calculateTrimp(elapsedTimeHrZones), DECIMALS_ROUNDING)
  content.satisfaction = round(parsedTrainingData?.satisfaction, DECIMALS_ROUNDING)

  content.speedLoad = round(getTrainingLoad(avgSpeed, duration), DECIMALS_ROUNDING)
  content.powerLoad = round(getTrainingLoad(avgPower, duration), DECIMALS_ROUNDING)

  rowEntry.handleClick = () => { return onRowClick(athleteProfile) }

  return rowEntry
}

export const isSportsActivity = (vdo) => {
  return Object.values(sdvDataFormatSports).includes(vdo?.metadatum?.sport?.name) ||
    vdo?.data_type?.data_type === dataTypes.strava_type ||
    vdo?.data_type?.data_type === dataTypes.fit_data_type
}

export const isCorrespondingSchedule = (schedule, trainingLog) => {
  const trainingTime = trainingLog?.metadatum?.event_start // TODO Retrive the time of the activity if the training log does not exist
  return schedule?.athlete?.id === trainingLog?.owner?.id && moment(schedule.start_date).isSame(trainingTime, 'hour')
}

// TRIMP = SUM( minutesInZone1 * 1, minutesInZone2 * 2, ..., minutesInZone5 * 5)
export const calculateTrimp = (elapsedTimePerZone) => {
  if (!elapsedTimePerZone) {
    return null
  }

  const elapsedTimesWithoutZone0 = elapsedTimePerZone?.slice(1)
  const zonesMinutesCount = elapsedTimesWithoutZone0.map((seconds) => seconds / 60)

  const trimp = reduce(zonesMinutesCount, (sum, count, index) => {
    sum += count * (index + 1)
    return sum
  }, 0)

  return trimp
}

export const fetchUserData = (backend, groupId, profileId, start, end, pageNumber = 1, userDatasets = []) => {
  if (!groupId || !profileId) {
    return Promise.resolve([])
  }

  const isoStart = start.toISOString()
  const isoEnd = end.toISOString()

  // Caller of this function is responsible for catching errors
  return backend.groups.recentActivity(groupId, pageNumber, [profileId], isoStart, isoEnd).then((recentDataResponse) => {
    const { has_next: hasNext, next_page: nextPage } = recentDataResponse.data.page
    userDatasets.push(...recentDataResponse.data?.data)
    if (hasNext) {
      return fetchUserData(backend, groupId, profileId, start, end, nextPage, userDatasets)
    } else {
      return userDatasets
    }
  })
}

// TODO Change backend. The endpoint should NOT need a collectionType
export const fetchUserAcwr = (backend, profileId, day, collectionType) => {
  return backend.dashboards.trends.acwr(collectionType, day, day, undefined, undefined, [profileId])
    .then((aggregatedResults) => {
      const acwrValues = aggregatedResults?.data?.acwr
      if (acwrValues) {
        const mostRecentAcwr = last(acwrValues)
        return Promise.resolve(mostRecentAcwr)
      } else {
        return Promise.reject(aggregatedResults)
      }
    })
}

// Fetches schedules created by the coach
export const fetchUserSchedule = (backend, day) => {
  return backend.schedules.index(day).then((weekSchedulesResponse) => {
    Promise.resolve(weekSchedulesResponse?.data?.data)
  })
}

// Adds the first dataset of each type to the data object
export const addFirstDatasetOfEachType = (data, datasets) => {
  data.activity = find(datasets, (vdo) => isSportsActivity(vdo))

  const questionnaires = datasets.filter((vdo) => isQuestionnaire(vdo))
  data.trainingLog = find(questionnaires, (vdo) => isTrainingLog(VDO.sdo(vdo)))
  data.dailyQuestionnaire = find(questionnaires, (vdo) => isDailyQuestionnaire(VDO.sdo(vdo)))
}

// Fetches datasets of a certain day and adds the first of each type to the data object
export const willAddDatasetsOfChosenDay = (data, backend, groupId, profileId, day) => {
  const beginningOfDay = moment(day).startOf('day')
  const endOfDay = moment(day).endOf('day')

  return fetchUserData(backend, groupId, profileId, beginningOfDay, endOfDay).then((datasets) => {
    addFirstDatasetOfEachType(data, datasets)
    return Promise.resolve(data)
  })
}

// Fetches the acwr data of a certain day and adds it to the data object
export const willAddAcwr = (data, backend, profileId, day) => {
  const collectionType = data?.activity?.summary?.collection_type // TODO This should not be needed in the future once all datasets have a collectionType
  if (!collectionType) {
    return Promise.resolve(data)
  }

  return fetchUserAcwr(backend, profileId, day, collectionType).then((acwr) => {
    data.extraData = { acwr: acwr }
    return Promise.resolve(data)
  })
}

// Fetches the first scheduled training of a certain day and adds it to the data object
export const willAddScheduledTraining = (data, backend, day) => {
  return fetchUserSchedule(backend, day).then((weekSchedules) => {
    const scheduledTraining = find(weekSchedules, (schedule) => isCorrespondingSchedule(schedule, data?.trainingLog))
    data.scheduledTraining = scheduledTraining
    return Promise.resolve(data)
  })
}

export const getFastestLap = (laps) => {
  if (!laps || laps?.length <= 0) {
    return null
  }

  return maxBy(laps, (lap) => get(lap, 'summaries.speed.mean', null)) || null
}
