import React from 'react'
import { WithSession } from '../../session/SessionProvider'
import { WithBackend } from '../../backend/BackendProvider'
import GroupEditOrCreateForm from './GroupEditOrCreateForm'
import I18n from 'i18n'

class GroupEdit extends React.Component {
  getFormData (picture) {
    return new Promise((resolve, reject) => {
      const title = document.getElementById('group-name').value
      const description = document.getElementById('group-description').value
      const anonymized = document.getElementById('group-anonymized').checked
      if (picture) {
        picture.then((id) => {
          resolve({
            group: {
              name: title,
              description: description,
              anonymized: anonymized,
              picture: id
            }
          })
        })
      } else {
        resolve({
          group: {
            name: title,
            description: description,
            anonymized: anonymized
          }
        })
      }
    })
  }

  handleSubmit (picture) {
    this.getFormData(picture).then((data) => {
      this.props.backend.updateGroup(this.props.group.id, data).then((_result) => {
        this.props.fetch()
      })
    })
  }

  render () {
    return (
      <div className='container'>
        <div className='row'>
          <div className='col s12 center-align text-heavy muted-header'>
            <h5>{I18n.t('network.group.edit_group')}</h5>
          </div>
        </div>
        <GroupEditOrCreateForm handleSubmit={this.handleSubmit.bind(this)} group={this.props.group} edit />
      </div>
    )
  }
}

export default WithBackend(WithSession(GroupEdit))
