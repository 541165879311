import React from 'react'
import { Line } from 'react-chartjs-2'
import 'chartjs-plugin-crosshair'
import 'chartjs-plugin-annotation'
import 'materialize-css/extras/noUiSlider/nouislider.min'
import _ from 'lodash'
import Slider from '../../../common/Slider'
import I18n from 'i18n'
import { roundTooltip } from '../../../common/Utils'
import moment from 'moment'

class HRLaps extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      zoneCheckboxes: Array(5).fill(false),
      timeRange: this.getDataTimeRange(), // When loading the dataset for the first time, the slider has the same values as the start/end time of the dataset
      selectedLoop: 0,
      hrRange: this.getDataHrRange(),
      vRange: this.getDataVRange()
    }

    this.colors = ['#FFD1D1', '#F89C9C', '#F26868', '#EB3434', '#E50000']
  }

  componentDidMount () {
    M.FormSelect.init(document.querySelectorAll('select'))
  }

  handleTimeRangeChange (_values, handle, unformattedValues) {
    const newTimeRange = [...this.state.timeRange]
    newTimeRange[handle] = parseInt(unformattedValues[handle])
    this.setState({ timeRange: newTimeRange })
  }

  timeFormatter (milliseconds) {
    const initialTime = moment(this.getDataTimeRange()[0])
    const selectedTime = moment(milliseconds)
    const duration = moment.duration(selectedTime.diff(initialTime), 'millisecond')
    const formattedMin = Math.floor(duration.asMinutes())
    const formattedSec = ('0' + duration.seconds().toString()).slice(-2)
    return (`${formattedMin}:${formattedSec}`)
  }

  handleHRRangeChange (values, handle) {
    const newHRRange = this.state.hrRange
    newHRRange[handle] = parseInt(values[handle])
    this.setState({ hrRange: newHRRange })
  }

  handleVRangeChange (values, handle) {
    const newVRange = this.state.vRange
    newVRange[handle] = parseInt(values[handle])
    this.setState({ vRange: newVRange })
  }

  /**
   * @returns The start/end time of the records in the dataset.
   */
  getDataTimeRange () {
    const speed = this.props.lapsSpeed
    const bpm = this.props.lapsBpms
    const min = _.min([speed && speed.length && speed[0].t, bpm && bpm.length && bpm[0].t]) || 0
    const max = _.max([speed && speed.length && speed[speed.length - 1].t, bpm && bpm.length && bpm[bpm.length - 1].t]) || 100
    return [min, max]
  }

  getDataHrRange (padding = 10) {
    let hrRange = [0, 200]
    const bpms = this.props.lapsBpms && this.props.lapsBpms.map(v => v.y)
    if (bpms) {
      hrRange = [_.min(bpms) - padding, _.max(bpms) + padding]
    }
    return hrRange
  }

  getDataVRange () {
    let vRange = [0, 70]
    const v = this.props.lapsSpeed && this.props.lapsSpeed.map(v => v.y)
    if (v) {
      vRange = [_.min(v), _.max(v)]
    }
    return vRange
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.lapsBpms !== this.props.lapsBpms || prevProps.lapsSpeed !== this.props.lapsSpeed) {
      const timeRange = this.getDataTimeRange()
      const hrRange = this.getDataHrRange()
      const vRange = this.getDataVRange()
      this.setState({ hrRange: hrRange, vRange: vRange, timeRange: timeRange })
    }
  }

  generateAnnotations () {
    const commonConfig = {
      drawTime: 'beforeDatasetsDraw',
      type: 'box',
      xScaleID: 'x-axis-0',
      yScaleID: 'HR',
      borderWidth: 1,
      borderColor: '#111' // They have specifically asked to have same borderColor to all annotations
    }

    const limits = this.props.zoneLimits
    const loops = this.state.selectedLoop && this.props.loopDates ? this.props.loopDates[this.state.selectedLoop] : []

    return {
      annotation: {
        annotations: [...this.state.zoneCheckboxes.map((state, index) => {
          if (!state) return undefined
          return {
            ...commonConfig,
            yMin: limits && limits[index][0],
            yMax: limits && limits[index][1],
            xMin: this.state.timeRange[0],
            xMax: this.state.timeRange[1],
            backgroundColor: this.colors[index]
          }
        }).filter(a => a !== undefined),
        ...loops.map(t => {
          if (t >= this.state.timeRange[0] && t <= this.state.timeRange[1]) {
            return {
              drawTime: 'beforeDatasetsDraw',
              type: 'line',
              mode: 'vertical',
              scaleID: 'x-axis-0',
              value: t,
              borderColor: 'gray',
              borderWidth: 1
            }
          }
          return undefined
        }).filter(a => a !== undefined)
        ]
      }
    }
  }

  handleZoneCheckbox (id) {
    const newCheckboxes = this.state.zoneCheckboxes
    newCheckboxes[id] = !newCheckboxes[id]
    this.setState({ zoneCheckboxes: newCheckboxes })
  }

  handleLoopChange () {
    const e = document.getElementById('laps-select-loop')
    const selected = parseInt(e.options[e.selectedIndex].value)

    this.setState({ selectedLoop: selected })
  }

  render () {
    const data = {
      datasets: [
        {
          data: this.props.lapsBpms && this.props.lapsBpms.filter(d => d.t >= this.state.timeRange[0] && d.t <= this.state.timeRange[1]),
          label: I18n.t('components.dashboards.skating.hr_legend'),
          pointRadius: 0,
          borderColor: '#555',
          showLine: true,
          fill: false,
          yAxisID: 'HR'
        },
        {
          data: this.props.lapsSpeed && this.props.lapsSpeed.filter(d => d.t >= this.state.timeRange[0] && d.t <= this.state.timeRange[1]),
          label: I18n.t(`components.dashboards.skating.${this.props.legend}`),
          pointRadius: 0,
          borderColor: '#008989',
          showLine: true,
          fill: false,
          yAxisID: 'v',
          tension: 0
        }
      ]
    }

    const options = {
      ...this.generateAnnotations(),
      scales: {
        yAxes: [{
          id: 'HR',
          type: 'linear',
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: I18n.t('components.dashboards.skating.hr')
          },
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            min: this.state.hrRange[0],
            max: this.state.hrRange[1],
            stepSize: 1
          }
        }, {
          id: 'v',
          type: 'linear',
          position: 'right',
          scaleLabel: {
            display: true,
            labelString: I18n.t(`components.dashboards.skating.${this.props.yAxisLabel}`)
          },
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            suggestedMin: this.state.vRange[0],
            suggestedMax: this.state.vRange[1],
            stepSize: 1
          }
        }],
        xAxes: [{
          type: 'time',
          ticks: {
            maxTicksLimit: 15,
            maxRotation: 50,
            minRotation: 0
          },
          time: {
            displayFormats: {
              minute: 'H:mm'
            }
          },
          gridLines: {
            drawOnChartArea: false
          }
        }]
      },
      animation: {
        duration: 0
      },
      tooltips: {
        intersect: false,
        axis: 'x',
        callbacks: {
          label: roundTooltip
        }
      },
      plugins: {
        crosshair: {
          line: {
            color: 'black',
            width: 1
          },
          zoom: {
            enabled: true,
            zoomButtonText: I18n.t('components.dashboards.skating.reset_zoom'),
            zoomButtonClass: 'zoomboxBackgroundColor button waves-effect waves-light button-primary text-background-color background-primary text-medium text-m button-autowidth'
          },
          callbacks: {
            beforeZoom: function (start, end) { // called before zoom, return false to prevent zoom
              return true
            },
            afterZoom: function (start, end) { // called after zoom
            }
          }
        }
      }
    }

    return (
      <div className='row graph-outline'>
        <span className='graph-title text-heavy text-background-color'>{I18n.t('components.dashboards.skating.analysis_hr_and_speed')}</span>
        <div className='col s12 xl8'>
          <Line data={data} options={options} />
        </div>

        <div className='col s12 xl4'>
          <div className='padding-on-small'>
            <div className='row'>
              <div className='input-field col s6'>
                <select id='laps-select-hr' onChange={this.props.handleHRSelect} defaultValue={1}>
                  <option value='off'>{I18n.t('components.dashboards.skating.off_label')}</option>
                  <option value={1}>1 {I18n.t('components.dashboards.skating.sec_raw')}</option>
                  <option value={5}>5 {I18n.t('components.dashboards.skating.sec_avg')}</option>
                  <option value={15}>15 {I18n.t('components.dashboards.skating.sec_avg')}</option>
                  <option value={30}>30 {I18n.t('components.dashboards.skating.sec_avg')}</option>
                </select>
                <label>{I18n.t('components.dashboards.skating.heartrate')}</label>
              </div>
              <div className='input-field col s6'>
                <select id='laps-select-speed' onChange={this.props.handleTransponderSelect} defaultValue='off'>
                  <option value='off'>{I18n.t('components.dashboards.skating.off_label')}</option>
                  <option value='vIn'>{I18n.t('components.dashboards.skating.speed')} ({I18n.t('components.dashboards.skating.inside')})</option>
                  <option value='vOut'>{I18n.t('components.dashboards.skating.speed')} ({I18n.t('components.dashboards.skating.outside')})</option>
                  <option value='aIn'>{I18n.t('components.dashboards.skating.acceleration')} ({I18n.t('components.dashboards.skating.inside')})</option>
                  <option value='aOut'>{I18n.t('components.dashboards.skating.acceleration')} ({I18n.t('components.dashboards.skating.outside')})</option>
                </select>
                <label>{I18n.t('components.dashboards.skating.transponder')}</label>
              </div>
            </div>

            <div className='row'>
              <div className='col s12'>
                <span className='text-heavy'>
                  {I18n.t('components.dashboards.skating.hr_zones')}:
                </span>
                <div className='hr-zones'>
                  {
                    [...Array(5).keys()].map(v => (
                      <p key={v}>
                        <label>
                          <input type='checkbox' checked={this.state.zoneCheckboxes[v]} onChange={() => this.handleZoneCheckbox(v)} />
                          <span>{v + 1}</span>
                        </label>
                      </p>
                    ))
                  }
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col s12'>
                <Slider
                  id='time-range'
                  customFormatter={this.timeFormatter.bind(this)}
                  onChange={this.handleTimeRangeChange.bind(this)}
                  range={this.getDataTimeRange()} // Total time range of the dataset
                  start={this.state.timeRange} // Currently selected time range of the dataset.
                  step={1}
                  title={I18n.t('components.dashboards.skating.time')}
                />
              </div>
              <div className='col s12'>
                <Slider
                  id='hr-range'
                  onChange={this.handleHRRangeChange.bind(this)}
                  range={[0, 230]}
                  start={this.state.hrRange}
                  step={1}
                  title={I18n.t('components.dashboards.skating.hr')}
                />
              </div>
              <div className='col s12'>
                <Slider
                  id='speed-range'
                  onChange={this.handleVRangeChange.bind(this)}
                  range={[-50, 100]}
                  start={this.state.vRange}
                  step={1}
                  title={I18n.t('components.dashboards.skating.speed')}
                />
              </div>

              <div className='row'>
                <div className='input-field col s6 offset-s6'>
                  <select id='laps-select-loop' onChange={this.handleLoopChange.bind(this)}>
                    <option value={0}>{I18n.t('components.dashboards.skating.off_label')}</option>
                    {[...Array(12).keys()].map(v => (
                      <option key={`loop-${v}`} value={v + 1}>{v + 1}</option>
                    ))}
                  </select>
                  <label>{I18n.t('components.dashboards.skating.loop_nr')}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HRLaps
