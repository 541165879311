import React from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import _ from 'lodash'
import { chartjsTitleProperties } from '../../../common/Constants'

const GroupedBarsChart = (props) => {
  const {
    entries,
    paramProperties,
    legendPosition = 'top',
    legendWidth = 40,
    legendEnabled = true,
    suggestedMin,
    suggestedMax,
    title,
    yLabel,
    stacked,
    tooltipCallbacks,
    xAxis,
    yAxis,
    barColors
  } = props

  const getAllParameters = (entries) => {
    const parameters = _.reduce(entries, (params, entry) => {
      entry.bars.forEach((bar) => params.add(bar.param))
      return params
    }, new Set())

    return Array.from(parameters)
  }

  const getChartJsData = (entries, paramProperties) => {
    const parameters = getAllParameters(entries)
    const datasets = _.reduce(parameters, (startingDatasets, param) => {
      startingDatasets[param] = []
      return startingDatasets
    }, {})
    const labels = []

    entries.forEach((entry) => {
      const { bars, label: entryLabel } = entry
      labels.push(entryLabel)

      _.forOwn(datasets, (values) => values.push(0)) // Fill the latest values with a 0
      bars.forEach((bar) => { // Save the values of the parameters that are not 0
        const { value, param } = bar
        const lastIndex = datasets[param].length - 1
        datasets[param][lastIndex] = value
      })
    })

    const datasetsExpanded = _.entries(datasets).map(([param, values]) => {
      return {
        label: paramProperties[param]?.title || param,
        backgroundColor: paramProperties[param]?.color,
        data: values,
        order: 1 // behind horizontal line
      }
    })

    // Add custom line chart to add multiple colors in one line
    // As well as adjusted alignment to (re-)align datapoints with xticks
    Chart.defaults.multicolorLine = Chart.defaults.line
    Chart.controllers.multicolorLine = Chart.controllers.line.extend({
      // custom updateElement:
      // re-scale x-ticks so that offset = true does not start/end line points in middle of x-ticks
      // From: https://stackoverflow.com/questions/42858440/is-it-able-to-align-line-chart-at-left-margin-in-mixed-chart
      updateElement: function (point, index, reset) {
        Chart.controllers.line.prototype.updateElement.call(this, point, index, reset)
        const meta = this.getMeta()
        const xScale = this.getScaleForId(meta.xAxisID)
        point._model.x = xScale.getPixelForValue(undefined, index - 0.5)
      },
      // custom draw: enables multiple colors in one line
      // (normal line graph only allows one color per line)
      // From: https://stackoverflow.com/questions/59053392/different-color-for-line-segments-in-chartjs
      draw: function (ease) {
        let startIndex = 0
        const meta = this.getMeta()
        const points = meta.data || []
        const colors = this.getDataset().colors
        const area = this.chart.chartArea
        const originalDatasets = meta.dataset._children
          .filter(function (data) {
            return !isNaN(data._view.y)
          })

        function _setColor (newColor, meta) {
          meta.dataset._view.borderColor = newColor
        }

        if (!colors) {
          Chart.controllers.line.prototype.draw.call(this, ease)
          return
        }

        for (let i = 2; i <= colors.length; i++) {
          if (colors[i - 1] !== colors[i]) {
            _setColor(colors[i - 1], meta)
            meta.dataset._children = originalDatasets.slice(startIndex, i)
            meta.dataset.draw()
            startIndex = i - 1
          }
        }

        meta.dataset._children = originalDatasets.slice(startIndex)
        meta.dataset.draw()
        meta.dataset._children = originalDatasets

        points.forEach(function (point) {
          point.draw(area)
        })
      }
    })

    // create horizontal line at 0 y value
    const lineData = Array(barColors.length).fill(0)
    datasetsExpanded.push({
      label: 'Complaints indicator',
      data: lineData,
      colors: barColors,
      borderWidth: 5,
      pointRadius: 0.0,
      pointHitRadius: 0.0,
      fill: false,
      type: 'multicolorLine',
      order: 0, // on top
      xAxisID: 'x-hidden'
    })

    return {
      labels,
      datasets: datasetsExpanded
    }
  }

  const options = {
    legend: {
      display: !legendEnabled,
      position: legendPosition,
      labels: {
        boxWidth: legendWidth
      }
    },
    scales: {
      xAxes: [{
        stacked,
        ...xAxis
      }, {
        // hidden x axis for the horizontal line complaints indicator
        id: 'x-hidden',
        display: false,
        stacked: false,
        offset: true
      }],
      yAxes: [{
        stacked,
        ticks: {
          suggestedMin,
          suggestedMax
        },
        scaleLabel: {
          display: !!yLabel,
          labelString: yLabel
        },
        ...yAxis
      }]
    },
    title: {
      text: title,
      ...chartjsTitleProperties
    }
  }
  if (tooltipCallbacks) {
    _.merge(options, {
      tooltips: {
        callbacks: tooltipCallbacks
      }
    })
  }

  const data = getChartJsData(entries, paramProperties)

  return (
    <Bar data={data} options={options} />
  )
}

GroupedBarsChart.propTypes = {
  // The options and properties of each parameter
  paramProperties: PropTypes.objectOf(PropTypes.shape({
    color: PropTypes.string,
    title: PropTypes.string
  })),
  // The data entries. Each entry is a group of several bars with a matching label
  entries: PropTypes.arrayOf(PropTypes.shape({
    // The bars of an entry. Each bar represents the value of a parameter for that entry's label
    bars: PropTypes.arrayOf(PropTypes.shape({
      param: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })).isRequired,
    // The entry's label
    label: PropTypes.any.isRequired
  })).isRequired,
  options: PropTypes.object
}

export default GroupedBarsChart
