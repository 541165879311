import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import I18n from 'i18n'
import { MyAxios as axios } from '../../../MyAxios'
import Chart from '../../layout/Chart'
import { WithSession } from '../../../session/SessionProvider'
import MyReactTooltip from '../../../MyReactTooltip'
import { circularStandardDeviation, radToTime, standardDeviation, timeToRad } from '../../../common/Math'
import { timeParameters, momentDateFormat } from '../../../common/Constants'
import Routes from '../../../common/Routes'
import moment from 'moment'
import Spinner from '../../../common/Spinner'
import WeeklyStackedBarChart from './WeeklyStackedBarChart'
import WeeklySleepChart from './WeeklySleepChart'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import PredictionModelChart from './PredictionModelChart'
import ActivitiesSummary from './activities_summary/ActivitiesSummary'
import { h2hhmm } from '../../../common/Units'
import { BetaRibbon, BetaRibbonContainer } from '../../../atomic/atoms/BetaRibbon'
import FeatureFlag, { PREDICTION_MODEL } from 'components/feature_flags/FeatureFlag'

const DashboardIntervalSelect = ({ index, onChange, value }) => {
  useEffect(() => {
    const elems = document.querySelectorAll(`#dashboard-interval-${index}`)
    M.FormSelect.init(elems)
  }, [index])

  return (
    <div className='input-field'>
      <select id={`dashboard-interval-${index}`} name='dashboard-interval' onChange={onChange} defaultValue={value}>
        <option value='week'>{I18n.t('analysis.dashboard.interval.week')}</option>
        <option value='month'>{I18n.t('analysis.dashboard.interval.month')}</option>
        <option value='year'>{I18n.t('analysis.dashboard.interval.year')}</option>
      </select>
      <label htmlFor='dashboard-interval'>{I18n.t('analysis.dashboard.interval.label')}</label>
    </div>
  )
}

const MyActivityDashboard = ({ period, history, sessionToken, index }) => {
  const [fetchingData, setFetchingData] = useState(true)
  const [data, setData] = useState([])
  const [datasets, setDatasets] = useState({})
  const [weekLine, setWeekLine] = useState({})
  const [interval, setInterval] = useState(period || 'week')
  const { hash } = useLocation()

  const isActiveTab = (tab) => hash === `#${tab}-${index}`

  const intervals = {
    week: (date) => new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() - 6, 0, 0, (-date.getTimezoneOffset() * 60) + 1),
    month: (date) => new Date(date.getUTCFullYear(), date.getUTCMonth() - 1, date.getUTCDate(), 0, 0, (-date.getTimezoneOffset() * 60) + 1),
    year: (date) => new Date(date.getUTCFullYear() - 1, date.getUTCMonth(), date.getUTCDate(), 0, 0, (-date.getTimezoneOffset() * 60) + 1)
  }
  const calorieParams = ['fitbit.activity_calories', 'fitbit.calories', 'fitbit.calories_bmr']
  const activityMinuteParams = ['fitbit.minutes_sedentary', 'fitbit.minutes_lightly_active', 'fitbit.minutes_fairly_active', 'fitbit.minutes_very_active']
  const hrZoneParams = ['fitbit.hr_fat_burn_zone', 'fitbit.hr_cardio_zone', 'fitbit.hr_peak_zone']
  const sleepLineParams = ['fitbit.sleep_efficiency', 'fitbit.sleep_minutes']
  const sleepBarParams = ['fitbit.sleep_start_time', 'fitbit.sleep_get_up_time']
  const sleepParams = [...sleepLineParams, ...sleepBarParams]
  const calorieBarParams = ['fitbit.calories_bmr', 'fitbit.activity_calories']
  // const restingHeartRateParams = ['fitbit.resting_heart_rate']

  // minuteParams is used to identify parameters whose values will be converted from minutes to hours.
  const minuteParams = [...activityMinuteParams, ...hrZoneParams]

  // TODO: Currently the Intra HR has been filtered out. Intra HR is received as an array of objects
  const intraHeartRateParams = ['fitbit.heart_intra_day']

  const specialCases = [...calorieParams, ...sleepParams, ...activityMinuteParams, ...hrZoneParams, ...intraHeartRateParams]
  const min2hourTooltipCbs = {
    label: (tooltipItem, data) => `${data.datasets[tooltipItem.datasetIndex].label} ${displayMinutesAsHours(tooltipItem.value)}`
  }

  const refetch = (interval) => {
    setFetchingData(true)
    setInterval(interval)

    const now = new Date()
    const start = intervals[interval](now)

    axios({
      method: 'GET',
      url: `/api/v1/day_data?start_date=${start.getUTCFullYear()}-${start.getUTCMonth() + 1}-${start.getUTCDate()}`,
      headers: { Authorization: sessionToken }
    }).then(response => {
      setData(response.data)
      setFetchingData(false)

      // Initialize Materialize tabs after data is fetched and state is updated.
      const elems = document.querySelectorAll(`.tabs-${index}`)
      M.Tabs.init(elems)
    }).catch(error => {
      console.error('An error occurred while fetching data:', error)
    })
  }
  const selectInterval = (interval, history) => {
    const urlTable = { week: 'week', month: 'maand', year: 'jaar' }
    history.replace(Routes.analysis.activity.periodFn(urlTable[interval]))
  }

  useEffect(() => {
    if (data.length > 0) {
      setDatasets(prepareDatasets(data))
    }
  }, [data])

  useEffect(() => {
    if (data && interval && interval !== 'week') {
      const sevenDaysAgo = moment().subtract(7, 'd').format(momentDateFormat)
      const weekLine = prepareDatasets(data.filter(dayDatum => dayDatum.day > sevenDaysAgo))
      setWeekLine(weekLine)
    }
  }, [data, interval])

  useEffect(() => {
    refetch(interval)
  }, [interval])

  useEffect(() => {
    if (period) {
      refetch(period)
    }
  }, [period])

  const sumByDay = (acc, current, parameter) => {
    // Current is an object with the structure defined in DayDatumSerializer
    // Acc is the accumulated list of elements. These elements have the structure
    //  - count (the number of elements measured that day
    //  - allValues (an array of elemenents of that day and parameter)

    if (timeParameters.includes(parameter)) {
      const newValue = current.value % 86400
      // This allows for calculating the average and variances correctly as long as all
      // times we average within a 12 hour span, and all either before noon or after noon.
      current.value = (newValue < 12 * 3600 ? newValue + 86400 : newValue) * 1000
    }

    // We don't want to add the data that is 0 at the beginning of the
    // measurements. E.g., if a user just acquired a fitbit device.
    if (acc.length === 0 && current.value === 0) {
      return []
    }

    // If no last entry was found, create a new entry for this specific day. If
    // the accumulated array is still empty, create a new array with count 1
    // and the current value
    const last = acc[acc.length - 1] // Note that this might be undefined, which is fine as long as we check it.
    if (!last || last.day !== current.day) {
      current.count = 1
      current.allValues = [current.value]
      return acc.concat([current])
    }

    // If a previous observation was found for the current day, add the current
    // values to the last observation.
    last.value += current.value
    last.allValues = last.allValues.concat([current.value])
    last.count += 1
    return acc
  }

  const convertMinutesToHours = (data) => {
    const convertedData = []
    for (const entry of data) {
      // Copy the data
      convertedData.push({ ...entry })
      // Convert only parameters with the word minutes
      if (minuteParams.includes(entry.parameter)) {
        convertedData[convertedData.length - 1].value = entry.value / 60
      }
    }
    // return data
    return convertedData
  }

  const sortedGroupedMeanByDay = (data) => {
    const grouped = {}
    const size = data.length

    const sorted = data.map(
      /* copy data */
      (item) => { return { ...item } }
    ).map(
      /* check if 'complex_value' exists and calculate mean if necessary */
      (entry) => {
        if (entry.complex_value && typeof entry.complex_value === 'object') {
          const values = Object.values(entry.complex_value)
          // Calculate the mean of the values in 'complex_value'
          if (values.length > 0) {
            const sum = values.reduce((a, b) => a + b, 0)
            entry.value = sum / values.length // Override 'value' with the mean of 'complex_value'
          } else {
            /* default value when complex_value is empty object */
            entry.value = 0
          }
        }
        return entry
      }
    ).map(
      /* convert timestamp string to day of the week */
      (entry) => { entry.day = new Date(entry.day).getDay(); return entry }
    ).sort(
      /* sort data by parameter name and occurrence date */
      (a, b) => {
        // here I'm assuming that you want to sort by 'parameter' first and then by 'day'.
        // you may need to adjust this comparison function to meet your requirements.
        if (a.parameter === b.parameter) {
          return a.day - b.day
        }
        return a.parameter > b.parameter ? 1 : -1
      }
    )

    /* extract the parameter names from the sorted data */
    const params = sorted.map((entry) => entry.parameter)

    /* use list slicing to group the sorted results */
    let elem = sorted[0]
    let firstIdx = 0; let lastIdx = 0
    while (firstIdx < size) {
      /* use index of parameter name to slice all entries for current parameter */
      lastIdx = params.lastIndexOf(elem.parameter)
      const slice = sorted.slice(firstIdx, lastIdx + 1)

      /* reduce the current parameter by day and then compute average for each day */
      grouped[elem.parameter] = slice.reduce((acc, current) => sumByDay(acc, current, elem.parameter), []).map(
        (entry) => {
          entry.value = entry.value / entry.count
          // Apply circular statistics to calculate the STD for time parameters
          if (timeParameters.includes(elem.parameter)) {
            entry.stdev = 1000 * radToTime(circularStandardDeviation(entry.allValues.filter(val => val && val !== 0).map(val => timeToRad(val / 1000))))
          } else {
            entry.stdev = standardDeviation(entry.allValues)
          }
          return entry
        }
      )
      firstIdx = lastIdx + 1
      elem = sorted[firstIdx]
    }

    return grouped
  }

  const prepareDatasets = (data) => {
    const convertedData = convertMinutesToHours(data)
    const grouped = sortedGroupedMeanByDay(convertedData)
    // prevent showing empty graphs with .filter(key => grouped[key].length > 0)
    const params = Object.keys(grouped).filter(key => grouped[key].length > 0)

    const datasets = {}
    for (const param of params) {
      datasets[param] = {
        errors: new Array(7).fill(0),
        mean: new Array(7).fill(0),
        lowerbound: new Array(7).fill(0),
        upperbound: new Array(7).fill(0)
      }
    }
    for (const key in grouped) {
      grouped[key].forEach((item) => {
        /* shift days by 6, then modulo 7 (because JS does not support modulo of negative numbers) */
        /* determine mean */
        datasets[key].mean[item.day] = Math.round(item.value * 100) / 100

        if (item.stdev > 0) {
          /* add/subtract stdev from mean for errorbars */
          datasets[key].errors[item.day] = [
            Math.round((item.value - item.stdev) * 100) / 100,
            Math.round((item.value + item.stdev) * 100) / 100
          ]
        } else if (!Number.isNaN(item.stdev) && item.stdev !== 0) {
          datasets[key].errors[item.day] = [
            Math.round(item.value * 100) / 100,
            Math.round(item.value * 100) / 100
          ]
        }
      })
    }
    return datasets
  }

  const hasAtleastOneOf = (datasets, params) => {
    for (const key of Object.keys(datasets)) {
      if (params.includes(key)) {
        return true
      }
    }
    return false
  }

  const displayMinutesAsHours = (minutes) => {
    return `${h2hhmm(parseFloat(minutes))}${I18n.t('units.h')}`
  }

  HighchartsMore(Highcharts)

  const params = Object.keys(datasets) || []

  const dashboardHeader = (
    <div className='row'>
      <div className='col s12 text-xl text-primary-color text-heavy'>
        {I18n.t('components.dashboards.steps.title')}
      </div>
    </div>
  )

  if (fetchingData) {
    return (
      <>
        {dashboardHeader}
        <div className='row'>
          <div className='col s12'>
            <Spinner transparent ready={false} />
          </div>
        </div>
      </>
    )
  }

  return (
    <div>
      {dashboardHeader}
      <div className='row'>
        <div className='col s12 m6'>
          <DashboardIntervalSelect onChange={e => selectInterval(e.target.value, history)} value={interval} />
        </div>
      </div>
      {interval && interval === 'week' && (
        <div className='row'>
          <div className='col s12'>
            <p className='text-medium text-m'>
              {I18n.t('components.dashboards.steps.week_hint')}
            </p>
          </div>
        </div>
      )}
      <div className='row'>
        <div className='col s12'>
          <ul className={`tabs tabs-${index}`}>
            <li id={`summary-view-${index}`} className='tab col s3'>
              <Link className={isActiveTab('summary') ? 'active' : ''} to={`#summary-${index}`}>{I18n.t('components.dashboards.steps.tabs.summary')}</Link>
            </li>
            <li id={`activities-view-${index}`} className='tab col s3'>
              <Link className={isActiveTab('activities') ? 'active' : ''} to={`#activities-${index}`}>{I18n.t('components.dashboards.steps.tabs.activities')}</Link>
            </li>
            <li id={`sleep-view-${index}`} className={classNames('tab col s3', !hasAtleastOneOf(datasets, sleepParams) && 'disabled')}>
              <Link className={isActiveTab('sleep') ? 'active' : ''} to={`#sleep-${index}`}>{I18n.t('components.dashboards.steps.tabs.sleep')}</Link>
            </li>
            <li id={`calories-view-${index}`} className={classNames('tab col s3', !hasAtleastOneOf(datasets, calorieBarParams) && 'disabled')}>
              <Link className={isActiveTab('calories') ? 'active' : ''} to={`#calories-${index}`}>{I18n.t('components.dashboards.steps.tabs.calories')}</Link>
            </li>
            <li style={{ display: 'none' }} id={`loader-view-${index}`} className='tab col s4'>
              <Link className={isActiveTab('loader') ? 'active' : ''} to={`#loader-${index}`}>Loader</Link>
            </li>
          </ul>
        </div>
        <div id={`loader-${index}`} className='col s12 tab-content active'>
          <Spinner ready={false} />
        </div>
        <div id={`summary-${index}`} className='col s12 tab-content'>
          <div className='row'>
            <div className='col s12'>
              <div className='background-background'>
                <div className='data-overview-card'>
                  <ActivitiesSummary datasets={datasets} params={params} rawData={data} tooltipCallbacks={min2hourTooltipCbs} xTickCallback={displayMinutesAsHours} />
                </div>
              </div>
            </div>
          </div>
          <FeatureFlag name={PREDICTION_MODEL}>
            <div className='row'>
              <div className='col s12 text-heavy muted-header'>
                <h5>{I18n.t('components.dashboards.steps.summary.prediction_model')}</h5>
              </div>
            </div>
            <div className='row'>
              <div className='col s12'>
                <BetaRibbonContainer className='background-background'>
                  <BetaRibbon />
                  <div className='data-overview-card'>
                    <PredictionModelChart />
                  </div>
                </BetaRibbonContainer>
              </div>
            </div>
          </FeatureFlag>
        </div>
        <div id={`activities-${index}`} className='col s12 tab-content'>
          <div className='row'>
            <div className='col s12 xl6' key='fitbit.activity_minutes' style={{ marginBottom: '3rem', marginTop: '0.5rem' }}>
              {hasAtleastOneOf(datasets, activityMinuteParams) && (
                <WeeklyStackedBarChart
                  datasets={datasets}
                  params={params}
                  barParams={activityMinuteParams}
                  yLabel={I18n.t('analysis.dashboard.fitbit.minutes_active.yaxis')}
                  title={I18n.t('analysis.dashboard.fitbit.minutes_active.title')}
                  tooltipCallbacks={min2hourTooltipCbs}
                  yTickCallback={displayMinutesAsHours}
                  stacked
                />
              )}
            </div>
            <div className='col s12 xl6' key='fitbit.hr_zones' style={{ marginBottom: '3rem', marginTop: '0.5rem' }}>
              {hasAtleastOneOf(datasets, hrZoneParams) && (
                <WeeklyStackedBarChart
                  datasets={datasets}
                  params={params}
                  barParams={hrZoneParams}
                  yLabel={I18n.t('analysis.dashboard.fitbit.minutes_hr_zone.yaxis')}
                  title={I18n.t('analysis.dashboard.fitbit.minutes_hr_zone.title')}
                  tooltipCallbacks={min2hourTooltipCbs}
                  yTickCallback={displayMinutesAsHours}
                  stacked
                />
              )}
            </div>
            {params.filter(param => !specialCases.includes(param)).map(param => (
              <div className='col s12 xl6' key={param} style={{ marginBottom: '3rem', marginTop: '0.5rem' }}>
                <Chart
                  highcharts={Highcharts}
                  param={param}
                  mean={datasets[param].mean}
                  errors={datasets[param].errors}
                  lowerbound={datasets[param].lowerbound}
                  upperbound={datasets[param].upperbound}
                  weekLine={weekLine[param]}
                  interval={interval}
                  tooltipId={`steps-tooltip-${index}`}
                />
              </div>
            ))}
          </div>
        </div>
        <div id={`sleep-${index}`} className='col s12 tab-content'>
          <div className='row'>
            <div className='col s12 xl6 larger-chart' key='fitbit.sleep' style={{ marginBottom: '3rem', marginTop: '0.5rem' }}>
              {hasAtleastOneOf(datasets, sleepBarParams) && (
                <WeeklySleepChart
                  datasets={datasets}
                  params={params}
                  barParams={sleepBarParams}
                  yLabel={I18n.t('analysis.dashboard.fitbit.sleep.yaxis')}
                  title={I18n.t('analysis.dashboard.fitbit.sleep.title')}
                />
              )}
            </div>
            {params.filter(param => sleepLineParams.includes(param)).map(param => (
              <div className='col s12 xl6' key={param} style={{ marginBottom: '3rem', marginTop: '0.5rem' }}>
                <Chart
                  highcharts={Highcharts}
                  param={param}
                  mean={datasets[param].mean}
                  errors={datasets[param].errors}
                  lowerbound={datasets[param].lowerbound}
                  upperbound={datasets[param].upperbound}
                  weekLine={weekLine[param]}
                  interval={interval}
                  tooltipId={`steps-tooltip-${index}`}
                />
              </div>
            ))}
          </div>
        </div>
        <div id={`calories-${index}`} className='col s12 tab-content'>
          <div className='row'>
            <div className='col s12' key='fitbit.calories' style={{ marginBottom: '3rem', marginTop: '0.5rem' }}>
              {hasAtleastOneOf(datasets, calorieBarParams) && (
                <WeeklyStackedBarChart
                  datasets={datasets}
                  params={params}
                  barParams={calorieBarParams}
                  yLabel={I18n.t('analysis.dashboard.fitbit.calories.yaxis')}
                  title={I18n.t('analysis.dashboard.fitbit.calories.title')}
                  stacked
                />
              )}
            </div>
          </div>
        </div>
        <MyReactTooltip id={`steps-tooltip-${index}`} effect='solid' />
      </div>
    </div>
  )
}

export default WithSession(MyActivityDashboard)
