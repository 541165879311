import React, { useState, useEffect, useRef } from 'react'
import { debounce } from 'lodash'
import I18n from 'i18n'

const PremiumFilters = (props) => {
  const [collapsed, setCollapsed] = useState(true)

  const [filterEmail, setFilterEmail] = useState(false)

  const applyFiltersDebounced = useRef(debounce(props.applyFilters, 500)).current

  useEffect(() => {
    const filters = {
      email: filterEmail
    }
    applyFiltersDebounced(filters)
  }, [filterEmail])

  return (
    <>
      <div className='col s12' onClick={() => setCollapsed(!collapsed)}>
        <div className='collapsible-menu'>
          Filters:
        </div>
      </div>
      <div className='col s12'>
        <div className={`collapsible-content ${!collapsed ? 'active' : ''}`}>
          <div className='row'>
            <div className='col s12 m6 l3'>
              <input className='input-field' name='filter-email' type='text' placeholder={I18n.t('premium-filters.field.text.filter-email')} onChange={(e) => setFilterEmail(e.target.value)} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PremiumFilters
