import React, { useState } from 'react'
import I18n from 'i18n'
import { subscriptionTypes } from 'components/common/Constants'
import PremiumOverlay from '../../../../premium_membership/PremiumOverlay'
import TabPanel, { Tab } from '../../../../atomic/atoms/TabPanel'
import PhysicalComplaintsDashboard from '../complaints/PhysicalComplaintsDashboard'
import MultiAthleteQuestionnaireDashboard from '../multi_athlete/MultiAthleteQuestionnaireDashboard'
import TrainingOrWellbeingQuestionnaireDashboardOptimized
  from 'components/analysis/dashboards/questionnaire/full/TrainingOrWellbeingQuestionnaireDashboardOptimized'
import DashboardStateWrapper from 'components/analysis/dashboards/questionnaire/common/DashboardStateWrapperTS'

const FullQuestionnaireDashboardComponent = (props) => {
  // By default the selected tab is training
  const [selectedTab, setSelectedTab] = useState('training')

  return (
    <PremiumOverlay activeFor={[subscriptionTypes.coach, subscriptionTypes.researcher]} showBadge={false}>
      <TabPanel id='full-questionnaire-dashboard-tabs' onShow={setSelectedTab}>
        <Tab id='training' title={I18n.t('components.dashboards.questionnaire.training.tab_title')}>
          {selectedTab === 'training' && <TrainingOrWellbeingQuestionnaireDashboardOptimized training {...props} />}
        </Tab>
        <Tab id='welzijn' title={I18n.t('components.dashboards.questionnaire.wellbeing.tab_title')}>
          {selectedTab === 'welzijn' && <TrainingOrWellbeingQuestionnaireDashboardOptimized wellbeing {...props} />}
        </Tab>
        <Tab id='lichamelijke_klachten' title={I18n.t('components.dashboards.questionnaire.physical_complaints.tab_title')}>
          {selectedTab === 'lichamelijke_klachten' && <PhysicalComplaintsDashboard {...props} premium />}
        </Tab>
        <Tab id='vergelijken' title={I18n.t('components.dashboards.questionnaire.compare.tab_title')}>
          {selectedTab === 'vergelijken' && <MultiAthleteQuestionnaireDashboard {...props} />}
        </Tab>
      </TabPanel>
    </PremiumOverlay>
  )
}

export default DashboardStateWrapper(FullQuestionnaireDashboardComponent)
