import React from 'react'
import I18n from 'i18n'
import ReactTooltip from 'react-tooltip'
import Info from '../../atomic/atoms/Info'

class Choice extends React.Component {
  getTranslation (iconName) {
    if (iconName.includes('sports')) {
      return I18n.t(iconName)
    }
    return I18n.t(`icons.${iconName}`)
  }

  render () {
    const grayedOut = (this.props.grayedOut && 'grayed-out') || ''
    const clickable = (this.props.clickable && 'pointer') || ''
    const IconClass = this.props.iconClass
    return (
      <div onClick={this.props.onClick} className={`${grayedOut} ${clickable}`} data-tip={this.getTranslation(this.props.iconName)} data-for='higher-z-tooltip'>
        <IconClass icon={this.props.iconFileName} />
      </div>
    )
  }
}

export default class SelectMultipleIcon extends React.Component {
  componentDidMount () {
    ReactTooltip.rebuild()
  }

  renderChoices (choices, currentValue, iconClass) {
    return choices && currentValue && choices.map(choice => {
      const selected = currentValue.includes(choice.properties.value)
      const iconName = choice.properties.name
      const iconFileName = choice.properties.icon
      const key = `${iconName}-${choice.properties.value}`
      return (
        <Choice
          onClick={() => this.toggleChoice(choice.properties.value, currentValue)}
          key={key}
          grayedOut={!selected}
          iconFileName={iconFileName}
          iconName={iconName}
          iconClass={iconClass}
          clickable
        />
      )
    })
  }

  toggleChoice (idx, currentValue) {
    const selected = Array.from(currentValue)
    const i = selected.indexOf(idx)
    if (i >= 0) {
      selected.splice(i, 1)
    } else {
      selected.push(idx)
      selected.sort()
    }
    this.props.onChange({ getSelectedValues: () => selected })
  }

  render () {
    const errors = this.props.errors || []
    const { infoBox, component, type, fieldName, choices, formName, defaultValue, currentValue, iconClass } = this.props
    const fieldProps = { ...this.props }
    delete fieldProps.component
    delete fieldProps.fieldName
    delete fieldProps.choices
    delete fieldProps.translateErrors
    delete fieldProps.formName
    delete fieldProps.defaultValue
    delete fieldProps.currentValue
    delete fieldProps.type

    /* valueProp should be an empty object if there is no current and no default value.
     * This prevents the value from from being given to an empty field when its used
     * together with the spread operator (...) */
    const valueProp = {}
    if (currentValue) {
      valueProp.value = currentValue.map((val) => parseInt(val))
    } else if (defaultValue) {
      valueProp.value = defaultValue
    }

    let infoBoxText = infoBox
    if (typeof infoBoxText === 'function') {
      infoBoxText = infoBoxText()
    }

    // let selectedChoices = []
    // if (choices && currentValue) {
    //   selectedChoices = choices.filter(choice => (currentValue.includes(choice.properties.value)) )
    // }

    return (
      <div className='row'>
        <div className='col s12'>
          <div className='input-field'>
            {errors.map((err, i) => (
              <div key={i}>
                {this.props.translateErrors ? I18n.t(err) : err}
              </div>
            ))}
            <span>
              <label className='active'>
                {I18n.t(`${formName}.${component}.${type}.${fieldName}`)}
              </label>
              {infoBox && <Info text={infoBoxText} tooltipId={`${formName}.${component}.${type}.${fieldName}-tooltip`} />}
            </span>
            <div className='select-multiple-icon'>
              {this.renderChoices(choices, currentValue, iconClass)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
