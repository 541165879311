import React from 'react'

export default class Info extends React.Component {
  constructor (props) {
    super(props)
    this.state = { transition: 'hidden' }
    this.message = React.createRef()
  }

  componentDidMount () {
    setTimeout(() => { this.setState({ transition: 'scale-in' }) }, 25)
  }

  componentDidUpdate (prevProps) {
    if (this.state.transition !== 'hidden' && this.message.current && this.props.state !== 'hide') {
      window.window.scrollTo(0, 0)
    }
    if (this.props.state === 'hide' && this.props.state !== prevProps.state) {
      const msg = this.props.msg
      setTimeout(() => { this.props.removeMessage(msg, this.props.type) }, 250)
      this.setState({ transition: 'scale-out' })
    }
  }

  render () {
    return (
      <div ref={this.message} className={`message scale-transition ${this.state.transition} row rounded ${this.props.color} toast`}>
        <span className='col s12 center-align'>{this.props.msg}</span>
      </div>
    )
  }
}
