import React from 'react'
import I18n from 'i18n'
import { WithModal } from '../../modal/ModalProvider'
import { WithSession } from '../../session/SessionProvider'
import ConnectionRequest from './ConnectionRequest'
import Spinner from '../../common/Spinner'

class ConnectionRequestList extends React.Component {
  constructor () {
    super()
    this.state = { }
  }

  render () {
    const { connectionRequests } = this.props
    return (
      <>
        <div className='col s12 text-heavy muted-header'>
          <div className='row'>
            <div className='col s12'>
              <h5>{I18n.t('connections.requests.title', { count: connectionRequests.length })}</h5>
            </div>
          </div>
        </div>
        <div className='col s12'>
          <div className='row'>
            <Spinner ready={this.props.dataRetrieved} transparent />
            {connectionRequests.map(connectionRequest => (
              <ConnectionRequest
                onAcceptConnection={this.props.onAcceptConnection}
                onRejectConnection={this.props.onRejectConnection}
                key={connectionRequest.id}
                connectionRequest={connectionRequest}
                onDelete={() => {}}
              />
            ))}
          </div>
        </div>
      </>
    )
  }
}

export default WithSession(WithModal(ConnectionRequestList))
