import React from 'react'
import { Route } from 'react-router'
import DashboardController from './DashboardController'
import NotFoundWrapper from '../router/NotFoundWrapper'
import DashboardWrapper from './DashboardWrapper'
import Routes from '../common/Routes'

export default class Analysis extends React.Component {
  render () {
    return (
      // These routes all return a DashboardWrapper component for now. Intention is to remove the specific routing from here and handle it in
      // the wrapper itself. For concept purposes this is sufficient.
      <NotFoundWrapper slug='analysis'>
        <Route exact path={Routes.analysis.index} render={props => (<DashboardController {...props} />)} />
        <Route exact path={Routes.analysis.iceSkating} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.questionnaire.index} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.questionnaire.weekly} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.questionnaire.full} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.questionnaire.full_opt} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.questionnaire.trends} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.questionnaire.complaints.show} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.questionnaire.rheuma.show} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.activity.index} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.activity.weekly} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.activity.monthly} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.activity.yearly} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.running.index} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.running.single.show} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.running.trends} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.swimming.index} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.swimming.single.show} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.swimming.trends} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.cycling.index} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.cycling.single.show} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.cycling.trends} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.inlineSkating.index} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.hiking.show} render={props => (<DashboardWrapper {...props} />)} />
        {/* <Route exact path={Routes.analysis.soccer.show} render={props => (<DashboardWrapper {...props} />)} /> */}
        <Route exact path={Routes.analysis.csv.show} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.triathlon} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.coach} render={props => (<DashboardWrapper {...props} />)} />
        <Route exact path={Routes.analysis.iceSkatingSingle.single.index} render={props => (<DashboardWrapper {...props} />)} />
      </NotFoundWrapper>
    )
  }
}
