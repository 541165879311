import React, { useEffect } from 'react'
import { hcRed, hcBlue, hcBlack } from 'components/common/Constants'
import { initializeHighcharts, titleWithTooltip } from 'components/common/Utils'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import I18n from 'i18n'
import { WeeklyWellbeingRow } from 'components/common/types/questionnaires/WeeklyWellbeingRow'
import GenericPlaceholder from 'components/common/GenericPlaceholder'
import _ from 'lodash'

interface Props {
  weeklyWellbeingRows?: WeeklyWellbeingRow[]
}

const StressAndMoodWeeklyChart: React.FC<Props> = ({ weeklyWellbeingRows }) => {
  useEffect(() => {
    initializeHighcharts(true)
  }, [])

  if (_.isEmpty(weeklyWellbeingRows) || !Array.isArray(weeklyWellbeingRows)) {
    return (
      <GenericPlaceholder title={I18n.t('components.dashboards.questionnaire.stress_and_mood.title')} />
    )
  }

  const moodData = weeklyWellbeingRows?.map(entry => ({
    x: entry.date_only,
    y: entry.wellness_mood,
    formatted_date: entry.formatted_date,
    color: hcBlue
  }))

  const stressData = weeklyWellbeingRows?.map(entry => ({
    x: entry.date_only,
    y: entry.wellness_stress,
    formatted_date: entry.formatted_date,
    color: hcRed
  }))

  const xAxisOptions: Highcharts.XAxisOptions = {
    title: {
      text: ''
    },
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%d %b'
    },
    minPadding: 0.1,
    maxPadding: 0.1
  }

  // The entire chart will appear white/empty when only one datapoint for the line is present.
  // In this case, enable datapoints
  const showMarkers = moodData.length === 1 || stressData.length === 1

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          title: titleWithTooltip(
            I18n.t('components.dashboards.questionnaire.stress_and_mood.title'),
            I18n.t('components.dashboards.questionnaire.stress_and_mood.info')
          ),
          series: [
            {
              type: 'line',
              data: moodData,
              name: I18n.t('components.dashboards.questionnaire.stress_and_mood.mood_line.name'),
              color: hcBlue,
              lineWidth: 2,
              marker: {
                enabled: showMarkers,
                states: {
                  hover: {
                    enabled: true
                  }
                }
              },
              tooltip: {
                headerFormat: '',
                pointFormat: I18n.t('components.dashboards.questionnaire.stress_and_mood.mood_line.tooltip')
              }
            },
            {
              type: 'line',
              data: stressData,
              name: I18n.t('components.dashboards.questionnaire.stress_and_mood.stress_line.name'),
              color: hcRed,
              lineWidth: 2,
              marker: {
                enabled: showMarkers,
                states: {
                  hover: {
                    enabled: true
                  }
                }
              },
              tooltip: {
                headerFormat: '',
                pointFormat: I18n.t('components.dashboards.questionnaire.stress_and_mood.stress_line.tooltip')
              }
            }
          ],
          plotOptions: {
            series: {},
            scatter: {
              dataLabels: {
                enabled: false
              },
              stacking: 'normal',
              enableMouseTracking: true
            },
            line: {
              color: hcBlack,
              marker: {
                enabled: true
              }
            }
          },
          yAxis: {
            title: {
              text: I18n.t('components.dashboards.questionnaire.stress_and_mood.yaxis')
            },
            min: 0
          },
          xAxis: xAxisOptions,
          legend: {
            enabled: true,
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'top'
          }
        }}
      />
    </>
  )
}

export default StressAndMoodWeeklyChart
