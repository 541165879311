import React, { useEffect } from 'react'
import I18n from 'i18n'
import { momentDateFormat } from '../../common/Constants'
import { questionnaireName, smartTranslate } from '../../common/Utils'
import Info from '../../atomic/atoms/Info'
import { WithModal } from '../../modal/ModalProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DiaryStudyProgress from '../DiaryStudyProgress'
import moment from 'moment'
import UserProfileTableEntry from '../../common/profile_representations/UserProfileTableEntry'
import ReactTooltip from 'react-tooltip'

const stateIcon = state => {
  switch (state) {
    case 'completed':
      return 'check'
    case 'canceled':
      return 'times'
    case 'active':
      return 'ellipsis-h'
  }
  return 'ellipsis-h'
}

const ListInactiveProtocolSubscriptions = (props) => {
  const noProtocolSubscriptions = !props.protocolSubscriptions || props.protocolSubscriptions.length === 0

  const realState = (state, protocolCompletion) => {
    const totalCount = protocolCompletion.length
    if (!totalCount) {
      return state
    }
    const percentage = protocolCompletion.filter(entry => entry.completed || !entry.future_or_current).length / totalCount
    if (Math.abs(percentage - 1) < 0.001 && state === 'canceled') {
      return 'completed'
    }
    return state
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [props.protocolSubscriptions])

  return (
    <>
      {!noProtocolSubscriptions && (
        <div className='row'>
          <div className='col s12'>
            <table className='striped inactive-protocols-table responsive-table'>
              <thead>
                <tr>
                  <th>{I18n.t('group.detail.questionnaires.person')}</th>
                  <th>{I18n.t('group.detail.questionnaires.protocol')}</th>
                  <th>{I18n.t('group.detail.questionnaires.start_date')}</th>
                  <th className='no-wrap'>
                    {I18n.t('group.detail.questionnaires.end_date')}
                    <Info text={I18n.t('group.detail.questionnaires.end_date_tooltip_inactive')} tooltipId='higher-z-tooltip' reuseTooltip />
                  </th>
                  <th>{I18n.t('group.detail.questionnaires.progress')}</th>
                  <th>{I18n.t('group.detail.questionnaires.status')}</th>
                </tr>
              </thead>
              <tbody>
                {props.protocolSubscriptions && props.protocolSubscriptions.length > 0 && props.protocolSubscriptions.map(q => {
                  const startDate = moment(q.start_date).toDate()
                  const endDate = moment(q.end_date).toDate()
                  const qState = realState(q.state, q.protocol_completion)
                  return (
                    <tr key={q.id}>
                      <td>
                        <UserProfileTableEntry profile={q.profile} />
                      </td>
                      <td className='nowrap'>
                        {smartTranslate(`group.detail.questionnaires.protocols.${q.name}`)}
                        <Info
                          text={`${I18n.t('group.detail.questionnaires.questionnaires_overview')}: ${q.questionnaires.filter(qname => qname && qname.length > 0).map(qname => questionnaireName(qname)).join(', ')}`}
                          tooltipId='higher-z-tooltip'
                          reuseTooltip
                        />
                      </td>
                      <td>{moment(startDate).format(momentDateFormat)}</td>
                      <td>{moment(endDate).format(momentDateFormat)}</td>
                      <td>{DiaryStudyProgress(q.protocol_completion)}</td>
                      <td><FontAwesomeIcon icon={stateIcon(qState)} className={`protocol-subscription-${qState}`} /> {I18n.t(`group.detail.questionnaires.state.${qState}`)}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {noProtocolSubscriptions && (
        <div className='row'>
          <div className='col s12'>
            <em className='text-muted'>{I18n.t('group.detail.questionnaires.no_inactive_protocol_subscriptions')}</em>
          </div>
        </div>
      )}
    </>
  )
}

export default WithModal(ListInactiveProtocolSubscriptions)
