import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'i18n'
import { stravaActivitiesBaseUrl } from '../../../../common/Constants'

const ExternalStravaDetails = (props) => {
  /* eslint-disable camelcase */
  const externalId = props.summary?.external_id
  /* eslint-enable camelcase */

  if (!externalId) return <></>

  const activityUrl = `${stravaActivitiesBaseUrl}${externalId}`
  return (
    <>
      {/* <div className='text-muted' dangerouslySetInnerHTML={{ __html: I18n.t('components.detail_views.strava.external_url', { url: `${stravaActivitiesBaseUrl}${externalId}` }) }} /> */}
      <span>{I18n.t('components.detail_views.strava.external_url')} &nbsp;</span>
      <a className='strava-link' href={activityUrl}>{activityUrl}</a>
    </>
  )
}

ExternalStravaDetails.propTypes = {
  summary: PropTypes.object.isRequired
}

export default ExternalStravaDetails
