import _ from 'lodash'
import { membershipStates, roles } from '../Constants'

export const isCoachOfGroup = (groupMemberships, userId) => {
  const userMembershipInfo = _.find(groupMemberships, (membership) => membership.profile.id === userId)
  return !!_.includes([roles.owner, roles.administrator], userMembershipInfo?.role)
}

export const getGroupAcceptedMembers = (groupMemberships) => {
  const currentMembers = _.filter(groupMemberships, (membership) => membership.state === membershipStates.ACCEPTED)
  return currentMembers.map((member) => member.profile)
}

export const getGroupAcceptedMembersNotAdmin = (groupMemberships) => {
  const membersNoAdmin = _.filter(groupMemberships, (membership) =>
    membership.role !== 'administrator' && membership.role !== 'owner' &&
    membership.state === membershipStates.ACCEPTED)
  return membersNoAdmin.map((member) => member.profile)
}
