import React from 'react'
import AutocompleteProfileDTO from '../../../common/types/DTOs/AutocompleteProfileDTO'
import { defaultProfilePicture } from '../../../common/Constants'
import { Link } from 'react-router-dom'
import I18n from 'i18n'

export interface ResultProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  item: AutocompleteProfileDTO
  hide: boolean
  buttonText: string
}

const ProfileGroupResult: React.FC<ResultProps> = (props) => {
  const displayName = `${(props.item.first_name !== null && props.item.first_name !== '') ? props.item.first_name : I18n.t('profile.show.first_name') as string} ${(props.item.last_name !== null && props.item.last_name !== '') ? props.item.last_name : I18n.t('profile.show.last_name') as string}`
  return (
    <div className='row no-margin'>
      <div className='col s12 l8 valign-wrapper search-result-margin'>
        <div className='search-result-image-container'>
          <img
            src={props.item.picture !== '' ? props.item.picture : defaultProfilePicture}
            className='responsive-img circle search-result-image'
            alt={displayName}
          />
        </div>
        <div className='overflow-text-elipsis search-result-text'>
          <Link to={`/profiles/${props.item.slug}`} target='_blank'> {displayName}</Link>
        </div>
      </div>
      <div className='col s12 l4 search-result-margin'>
        <button
          onClick={(e) => { props.onClick(e) }}
          className='waves-effect waves-light button-primary text-primary-color background-background text-medium text-s'
          style={{ paddingLeft: '6px' }}
        >{props.buttonText}
        </button>
      </div>
    </div>
  )
}

export default ProfileGroupResult
