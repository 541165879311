import React from 'react'
import I18n from 'i18n'
import { smartTranslate } from '../Utils'
import classNames from 'classnames'
import _ from 'lodash'

/* global M */
export default class Chips extends React.Component {
  componentDidUpdate (prevProps, prevState, snapshot) {
    this.initializeComponent()
  }

  componentDidMount (prevProps, prevState, snapshot) {
    this.initializeComponent()
  }

  initializeComponent () {
    const { fieldName, currentValue, defaultValue, suggestions = [] } = this.props
    let data = currentValue || defaultValue || []
    // Get only truthy values
    data = data.filter(d => d !== '')
    const options = {
      data: data.map((elem) => ({ tag: smartTranslate(elem), value: elem })),
      onChipAdd: this.onChipChange.bind(this),
      onChipDelete: this.onChipDelete.bind(this),
      hasAutocomplete: suggestions.length > 0,
      autocompleteOptions: this.autocompleteOptions()
    }
    const elem = document.getElementById(fieldName)
    M.Chips.init(elem, options)
  }

  autocompleteOptions () {
    const { minLengthSuggestion = 0, suggestions = [] } = this.props
    if (!_.get(suggestions, 'length', 0) > 0) return {}

    const autocompleteData = suggestions.reduce((acc, curr) => {
      acc[curr] = null
      return acc
    }, {})

    return ({
      data: autocompleteData,
      minLength: minLengthSuggestion
    })
  }

  onChipChange (_e) {
    const instance = M.Chips.getInstance(document.getElementById(this.props.fieldName))
    // If there is a value (untranslated original string for the server) we use
    // that. Otherwise it is a user defined tag and we know it has not been
    // translated
    this.props.onChange({ target: { value: instance.chipsData.map(elem => elem.value || elem.tag) } })
  }

  onChipDelete (e, _data, _a, _b, _c) {
    this.onChipChange(e)

    const instance = M.Chips.getInstance(document.getElementById(this.props.fieldName))
    if (instance.chipsData.length >= 1) {
      instance.selectChip(instance.chipsData.length - 1)
    }
  }

  addChip (e) {
    const instance = M.Chips.getInstance(document.getElementById(this.props.fieldName))
    instance.addChip({
      tag: e.target.value.trim()
    })

    e.target.value = ''
  }

  handleKeyDown (e) {
    // Prevent adding tags prepended by whitespace
    // Which enables adding duplicate tags
    if (e.key === ' ' && e.target.value.trim() === '') {
      e.preventDefault()
    }

    if (e.key === ' ' && e.target.value.trim() !== '') {
      // by default, the spacebar causes the page to scroll down. Prevent this
      e.preventDefault()

      // set value of the form field as a new chip and reset form field's value
      this.addChip(e)
    }

    if (e.key === 'Enter' || e.key === ' ' || e.keyCode === 13) {
      // persist the event such that it is still available after the timeout
      e.persist()
      // add a small timeout because addChip runs asynchronously
      setTimeout(() => e.target.focus(), 50)
    }
  }

  handleLoseFocus (e) {
    // Do not add chip if user clicked away without typing anything
    const hasAutocomplete = _.get(this, 'props.suggestions.length', 0) > 0
    if (e.target.value && !hasAutocomplete) {
      this.addChip(e)
    }
  }

  render () {
    const errors = this.props.errors || []
    const { component, type, fieldName, formName, label } = this.props
    const fieldProps = { ...this.props }
    delete fieldProps.component
    delete fieldProps.fieldName
    delete fieldProps.translateErrors
    delete fieldProps.formName
    delete fieldProps.defaultValue
    delete fieldProps.currentValue
    delete fieldProps.type
    delete fieldProps.label
    const hasAutocomplete = _.get(this, 'props.suggestions.length', 0) > 0
    return (
      <div className='row'>
        <div className='col s12'>
          <div className='input-field'>
            <div className={classNames('chips', { 'chips-autocomplete': hasAutocomplete })} id={fieldName}>
              <input id={`${fieldName}-input`} className='input' onBlur={this.handleLoseFocus.bind(this)} onKeyDown={this.handleKeyDown.bind(this)} />
            </div>
            <label htmlFor={fieldName} className='active'>
              {label || I18n.t(`${formName}.${component}.${type}.${fieldName}`)}
            </label>
            {errors.map(err => (
              <span className='helper-text' data-error='wrong' data-success='right' key={err} style={{ color: 'red' }}>
                {this.props.translateErrors ? I18n.t(err) : err}
              </span>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
