import React from 'react'
import { WithSession } from '../session/SessionProvider'
import PremiumBadge from '../atomic/atoms/PremiumBadge'
import I18n from 'i18n'
import Routes from '../common/Routes'
import { Link } from 'react-router-dom'
import { get, without } from 'lodash'
import Profile from '../common/types/Profile'
import { SubscriptionType, SUPER_PREMIUM } from 'components/common/types/Subscription'

interface Props {
  isPremium?: boolean
  showBadge?: boolean
  showButton?: boolean
  activeFor: SubscriptionType[]
  myProfile?: Profile // Optional, because withSession might not yet have retrieved the profile
  children: React.ReactElement
}

const PremiumOverlay: React.FC<Props> = ({ isPremium = true, showBadge = true, showButton = true, activeFor, myProfile, children }) => {
  // isPremium indicates that this is indeed a premium feature
  // showBadge indicates whether the small badge should be shown
  // showButton indicates whether a button to the upgrade page needs to be shown
  const subscriptionType: SubscriptionType = get(myProfile, 'subscription.type', SubscriptionType.FREE)
  const overlayActiveForCurrentRole: boolean = activeFor.includes(subscriptionType)
  const isSuperPremium: boolean = activeFor.length > 0 && without(activeFor, SUPER_PREMIUM).length === 0

  if (!isPremium) { return (<>{children}</>) }

  // TODO: Move some of the inline styling to css
  return (
    <div className='premium-overlay'>
      <div className='wrapper'>
        <div className='blur-overlay' style={{ background: `rgba(255,255,255,${overlayActiveForCurrentRole ? '0' : '0.8'})`, pointerEvents: overlayActiveForCurrentRole ? 'none' : 'all' }}>
          {(showBadge) && (<PremiumBadge isSuperPremium={isSuperPremium} />)}
          {!overlayActiveForCurrentRole && (
            <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
              <Link to={Routes.premium.subscribe.index}>
                <div className='valign-wrapper' style={{ width: '100%', height: '100%' }}>
                  <div className='center-align' style={{ width: '100%' }}>
                    {showButton && (
                      <button className={`no-filter waves-effect waves-light text-background-color text-medium text-m premium-button background-accent button-accent ${isSuperPremium ? 'super-premium' : ''}`} style={{ width: 'auto' }}>{I18n.t('premium.buttons.upgrade_now')}</button>
                    )}
                  </div>
                </div>
              </Link>
            </div>
          )}
        </div>
        {children}
      </div>
    </div>
  )
}

export default WithSession(PremiumOverlay) as typeof PremiumOverlay
