import React from 'react'
import ActivitiesBar from './ActivitiesBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SleepDiagram from './SleepDiagram'
import { average } from '../../../../common/Math'
import I18n from 'i18n'
import BoxPlotBar from './BoxPlotBar'
import MyReactTooltip from '../../../../MyReactTooltip'

const ActivitiesSummary = props => {
  const calcAverage = parameter => {
    if (!props.datasets[parameter] || !props.datasets[parameter].mean) return 0

    // Return the average of the nonzero values
    const nonZeroValues = props.datasets[parameter].mean.filter(value => Math.abs(value) > 0.0001)
    if (nonZeroValues.length === 0) return 0

    return average(nonZeroValues)
  }

  const minutesLightlyActive = calcAverage('fitbit.minutes_lightly_active')
  const minutesFairlyActive = calcAverage('fitbit.minutes_fairly_active')
  const minutesVeryActive = calcAverage('fitbit.minutes_very_active')
  const greenBarLength = minutesLightlyActive + minutesFairlyActive + minutesVeryActive

  const minutesSedentary = calcAverage('fitbit.minutes_sedentary')
  const redBarLength = minutesSedentary

  // The other variables are actually in hours. This is the only one that is correct and in minutes.
  // So convert it to hours also.
  const sleepMinutes = calcAverage('fitbit.sleep_minutes') / 60
  const blueBarLength = sleepMinutes

  const fullMinutes = Math.max(Math.max(redBarLength, blueBarLength), greenBarLength)

  return (
    <div className='row no-margin-bottom'>
      <div className='col s12 m6 l3 half-of-left-side-activity-summary'>
        <SleepDiagram
          title={I18n.t('components.dashboards.steps.summary.to_bed')}
          parameter='fitbit.sleep_start_time'
          datasets={props.datasets}
          params={props.params}
        />
      </div>
      <div className='col s12 m6 l3 half-of-left-side-activity-summary'>
        <SleepDiagram
          title={I18n.t('components.dashboards.steps.summary.wake_up')}
          parameter='fitbit.sleep_get_up_time'
          datasets={props.datasets}
          params={props.params}
        />
      </div>
      <div className='col s12 m12 l6 right-side-activity-summary'>
        <BoxPlotBar
          id='heartbeat'
          value={60}
          title='heart rate at rest'
          label='resting heart rate'
          parameter='fitbit.resting_heart_rate'
          rawData={props.rawData}
          datasets={props.datasets}
        >
          <FontAwesomeIcon
            style={{ color: 'grey' }}
            size='3x'
            icon='heartbeat'
            data-tip={I18n.t('components.dashboards.steps.summary.heartrate_tooltip')}
            data-for='activities-summary'
          />
        </BoxPlotBar>
        <ActivitiesBar
          id='minutes-active'
          values={[minutesLightlyActive, minutesFairlyActive, minutesVeryActive]}
          total={fullMinutes}
          title={I18n.t('components.dashboards.steps.summary.average_hours_active')}
          labels={[
            I18n.t('components.dashboards.steps.summary.lightly_active'),
            I18n.t('components.dashboards.steps.summary.fairly_active'),
            I18n.t('components.dashboards.steps.summary.very_active')
          ]}
          backgroundColors={['rgb(234, 243, 233)', 'rgb(213, 232, 212)', 'rgb(187, 203, 186)']}
          borderColors={['#888c88', '#6a746a', '#656e65']}
          tooltipCallbacks={props.tooltipCallbacks}
          xTickCallback={props.xTickCallback}
        >
          <FontAwesomeIcon
            style={{ color: 'grey' }}
            size='3x'
            icon='walking'
            data-tip={I18n.t('components.dashboards.steps.summary.activity_tooltip')}
            data-for='activities-summary'
          />
        </ActivitiesBar>
        <ActivitiesBar
          id='minutes-sitting'
          values={[minutesSedentary]}
          total={fullMinutes}
          title={I18n.t('components.dashboards.steps.summary.average_number_of_hours_sitting_per_day')}
          labels={[I18n.t('components.dashboards.steps.summary.sitting')]}
          backgroundColors={['#f8cecc']}
          borderColors={['#b85450']}
          tooltipCallbacks={props.tooltipCallbacks}
          xTickCallback={props.xTickCallback}
        >
          <FontAwesomeIcon
            style={{ color: 'grey' }}
            size='3x'
            icon='chair'
            data-tip={I18n.t('components.dashboards.steps.summary.sitting_tooltip')}
            data-for='activities-summary'
          />
        </ActivitiesBar>
        <ActivitiesBar
          id='minutes-sleeping'
          values={[sleepMinutes]}
          total={fullMinutes}
          title={I18n.t('components.dashboards.steps.summary.average_number_of_sleep_hours_per_day')}
          labels={[I18n.t('components.dashboards.steps.summary.sleeping')]}
          backgroundColors={['#dae8fc']}
          borderColors={['#6c8ebf']}
          tooltipCallbacks={props.tooltipCallbacks}
          xTickCallback={props.xTickCallback}
          showAxes
        >
          <FontAwesomeIcon
            style={{ color: 'grey' }}
            size='3x'
            icon='bed'
            data-tip={I18n.t('components.dashboards.steps.summary.sleeping_tooltip')}
            data-for='activities-summary'
          />
        </ActivitiesBar>
        <p className='tooltip-explanation'><em className='text-muted'>{I18n.t('components.dashboards.steps.summary.tooltip_explanation')}</em></p>
        <MyReactTooltip id='activities-summary' effect='solid' />
      </div>
    </div>
  )
}

export default ActivitiesSummary
