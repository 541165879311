import React, { useState, FC } from 'react'
import I18n from 'i18n'
import TabPanel, { Tab } from '../../../../atomic/atoms/TabPanel'
import WeeklyQuestionnaireDashboard from './WeeklyQuestionnaireDashboard'
import DateRangeQuestionnaireDashboard from './DateRangeQuestionnaireDashboard'
import DashboardStateWrapper, { DashboardWrapperProps } from 'components/analysis/dashboards/questionnaire/common/DashboardStateWrapperTS'
import SpinnerWrapper from 'components/common/SpinnerWrapper'
import Profile from 'components/common/types/Profile'

interface OwnProps {
  sessionToken: string
  myProfile: Profile
}

type Props = OwnProps & DashboardWrapperProps

const WeeklyOrMonthlyWellbeingDashboard: FC<Props> = (props: Props) => {
  const [selectedTab, setSelectedTab] = useState<string>('weekly')

  return (
    <SpinnerWrapper ready transparent={false}>
      <TabPanel id='log-questionnaire-dashboard-tabs' onShow={setSelectedTab}>
        <Tab id='weekly' title={I18n.t('components.dashboards.questionnaire.weekly.tab_title')}>
          <div className={selectedTab === 'weekly' ? '' : 'hidden'}>
            <WeeklyQuestionnaireDashboard />
          </div>
        </Tab>
        <Tab id='monthly' title={I18n.t('components.dashboards.questionnaire.monthly.tab_title')}>
          <div className={selectedTab === 'monthly' ? '' : 'hidden'}>
            <DateRangeQuestionnaireDashboard {...props} />
          </div>
        </Tab>
      </TabPanel>
    </SpinnerWrapper>
  )
}

export default DashboardStateWrapper(WeeklyOrMonthlyWellbeingDashboard)
