import React from 'react'
import I18n from 'i18n'
import moment from 'moment'
import { datePickerDefaultOptions, QUESTIONNAIRE_PERIOD } from 'components/common/Constants'
import DatePicker from 'react-datepicker'
import InputWrapper from 'components/common/form/InputWrapper'
import { isEndOfWeek, isStartOfWeek } from 'components/common/Utils'
import classNames from 'classnames'

const DateRangePicker = (props) => {
  const { minDate, setMinDate, maxDate, setMaxDate, smartSetDateRange } = props

  const customDatePickerOptions = {
    showWeekNumbers: true
  }

  const datesError = minDate > maxDate

  const handleMinDateChange = (d) => {
    const m = moment(d).startOf(QUESTIONNAIRE_PERIOD)
    setMinDate(m)
    smartSetDateRange(m, maxDate)
  }
  const handleMaxDateChange = (d) => {
    const m = moment(d).endOf(QUESTIONNAIRE_PERIOD)
    setMaxDate(m)
    smartSetDateRange(minDate, m)
  }

  return (
    <>
      <div className='col s6 input-field'>
        {/* // TODO: move to a molecule (see https://gitlab.com/researchable/sport-data-valley/mvp/base-platform/-/merge_requests/1578#note_738042941) */}
        <InputWrapper
          errorText={datesError && I18n.t('components.placeholders.invalid_date')}
        >
          <DatePicker
            id='start' {...datePickerDefaultOptions} {...customDatePickerOptions} selected={minDate.toDate()}
            startDate={minDate.toDate()}
            endDate={maxDate.toDate()}
            selectsStart
            filterDate={isStartOfWeek}
            onChange={handleMinDateChange}
            dateFormat='dd/MM/yyyy'
          />
          <label
            htmlFor='start'
            className={classNames({ active: minDate })}
          >
            {I18n.t('components.dashboards.multi_activity.start_date')}
          </label>
        </InputWrapper>
      </div>
      <div className='col s6 input-field'>
        <InputWrapper
          errorText={datesError && I18n.t('components.placeholders.invalid_date')}
        >
          <DatePicker
            id='end' {...datePickerDefaultOptions} {...customDatePickerOptions} selected={maxDate.toDate()}
            startDate={minDate.toDate()}
            endDate={maxDate.toDate()}
            filterDate={isEndOfWeek}
            selectsEnd
            onChange={handleMaxDateChange}
            maxDate={moment(new Date()).endOf(QUESTIONNAIRE_PERIOD)}
            dateFormat='dd/MM/yyyy'
          />
          <label
            htmlFor='end'
            className={classNames({ active: maxDate })}
          >
            {I18n.t('components.dashboards.multi_activity.end_date')}
          </label>
        </InputWrapper>
      </div>
    </>
  )
}
export default DateRangePicker
