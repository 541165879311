import React from 'react'
import { sampleData } from '../../../common/Utils'
import { linearRegression } from '../../../common/Regression'
import { WithErrorBoundary } from '../../../errorboundary/ErrorBoundaryProvider'
import I18n from 'i18n'
import Table from '../../../common/Table'
import ScatterPlot from '../common/ScatterPlot'
import { SimpleSlider } from '../../../common/Slider'

/**
 * Renders a scatter plot for the given x and y datasets. Also adds a linear regression line
 * A slider is rendered above the plot to control the number of samples that are shown.
 */
const CsvScatterPlot = ({ summaryBuilder, xs, ys, descriptionY = [], descriptionX = [], height = 250, regression = true, nsamples, onSetSamples, title }) => {
  /**
   * Compute a linear regression between the x and y variables
   */
  const { slope, intercept, r2 } = regression && linearRegression(ys, xs)

  /**
   * Prepare data to display in scatterplot
   */
  const xLabel = descriptionX.name
  const yLabel = descriptionY.name
  const sampledXs = sampleData(xs, nsamples)
  const sampledYs = sampleData(ys, nsamples)
  const fit = [{ x: 0, y: intercept }, { x: Math.max(...xs), y: intercept + slope * Math.max(...xs) }]
  const fitLabel = I18n.t('components.visualization.csv.linear_regression')

  /**
   * Headers for the column summary table
   */
  const summaryColumns = summaryBuilder.getNumericSummaryColumns()

  /**
   * Two rows with the summary values since 2 columns are rendered in a scatterplot
   */
  const summaryRows = summaryBuilder.getNumericSummaryRows([descriptionY.name, descriptionX.name])

  /**
   * Table headers for the regression summary
   */
  const regressionColumns = [
    I18n.t('components.visualization.csv.r2', { squared: '\u00B2', interpolation: { escapeValue: false } }),
    I18n.t('components.visualization.csv.intercept'),
    I18n.t('components.visualization.csv.slope')
  ]

  /**
   * Table rows containing the regression parameters
   */
  const regressionRows = [[
    r2, intercept, slope
  ]]

  return (
    <div className='row'>
      <div className='col s12'>
        <Table rows={summaryRows} columns={summaryColumns} />
      </div>
      <div className='col s12'>
        <Table rows={regressionRows} columns={regressionColumns} />
      </div>
      <div className='col s12'>
        <SimpleSlider
          value={nsamples}
          onChange={(e) => onSetSamples(e.target.value)}
          minVal={1}
          maxVal={Math.min(ys.length, 10000)}
          toolTipTranslation='components.visualization.csv.slider.samples'
        />
      </div>
      <div className='col s12'>
        <ScatterPlot title={title} xs={sampledXs} ys={sampledYs} xLabel={xLabel} yLabel={yLabel} fit={fit} fitLabel={fitLabel} showLegend />
      </div>
    </div>
  )
}

export default WithErrorBoundary(CsvScatterPlot, I18n.t('components.visualization.csv.errors.scatterplot'))
