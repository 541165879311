import React from 'react'
import I18n from 'i18n'
import PropTypes from 'prop-types'
import { feedbackEmail } from '../common/Constants'
import { WithSession } from '../session/SessionProvider'

const FeedbackButton = (props) => {
  const url = () => {
    if (props.url) return props.url

    let id = ''
    if (props.myProfile) {
      id = props.myProfile.id
    }
    return `mailto:${feedbackEmail}?subject=${I18n.t('general.feedback_subject')} sub: ${id} op pag: ${document.location.href}&body=${props.body}`
  }

  let className = 'feedback-button'
  if (props.className) {
    className = props.className
  }

  return (
    <a href={url()} target='_blank' rel='noopener noreferrer' className={className}>
      {props.labelText || I18n.t('general.feedback')}
    </a>
  )
}

FeedbackButton.propTypes = {
  body: PropTypes.string,
  labelText: PropTypes.string,
  className: PropTypes.string,
  url: PropTypes.string
}

FeedbackButton.defaultProps = {
  body: '',
  className: 'feedback-button'
}
export default WithSession(FeedbackButton)
