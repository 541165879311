import React, { useState, useEffect } from 'react'
import I18n from 'i18n'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { datePickerDefaultOptions, momentDateFormat } from '../../common/Constants'
import { questionnaireName, smartTranslate } from '../../common/Utils'
import Info from '../../atomic/atoms/Info'
import { WithModal } from '../../modal/ModalProvider'
import moment from 'moment'
import DatePicker from 'react-datepicker'

import classNames from 'classnames'
import DiaryStudyProgress from '../DiaryStudyProgress'
import UserProfileTableEntry from '../../common/profile_representations/UserProfileTableEntry'
import InputGroupWithExtraOptions from '../../atomic/molecules/InputGroupWithExtraOptions'
import { DateInput, DropdownInput, TextInput } from '../../atomic/atoms/inputs'
import DeleteButton from 'components/atomic/atoms/DeleteButton'
import ReactTooltip from 'react-tooltip'

const ListActiveProtocolSubscriptions = (props) => {
  const nameFieldDesignation = 'member'
  const protocolFieldDesignation = 'protocol'
  const dateScopeFieldDesignation = 'scope'
  const datePickerFieldDesignation = 'date'

  const ACTIVE_AFTER = 'after'
  const ACTIVE_BEFORE = 'before'
  const ACTIVE_ON = 'on'

  const [filters, setFilters] = useState({
    [nameFieldDesignation]: '',
    [protocolFieldDesignation]: [],
    [dateScopeFieldDesignation]: ACTIVE_ON,
    [datePickerFieldDesignation]: null
  })
  const [showExtraFilters, setShowExtraFilters] = useState(false)

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [props.protocolSubscriptions])

  const destroyProtocolSubscription = (groupId, protocolSubscriptionId) => {
    props.createProtocolSubscriptionProps.onDestroyProtocolSubscription(groupId, protocolSubscriptionId)
  }

  const updateProtocolSubscriptionEndDate = (groupId, protocolSubscriptionId, endDate) => {
    props.createProtocolSubscriptionProps.onUpdateProtocolSubscriptionEndDate(groupId, protocolSubscriptionId, endDate)
  }

  const showDestroyDialog = (groupId, protocolSubscriptionId) => {
    const displayTitle = I18n.t('group.detail.questionnaires.this_protocol')
    props.setConfirmationDialog({
      onConfirmation: () => destroyProtocolSubscription(groupId, protocolSubscriptionId),
      target: displayTitle,
      action: 'stop_protocol',
      noQuotes: true
    })
  }

  const showChangeEndDateDialog = (groupId, protocolSubscriptionId, endDate) => {
    const endDateString = moment(endDate).format(momentDateFormat)
    props.setConfirmationDialog({
      onConfirmation: () => updateProtocolSubscriptionEndDate(groupId, protocolSubscriptionId, endDate),
      target: endDateString,
      action: 'change_end_date',
      noQuotes: true
    })
  }

  const getSubscribedProtocolTypes = (protocolSubscriptions) => {
    const subscribedProtocols = new Set()
    protocolSubscriptions?.forEach((protocol) => subscribedProtocols.add(protocol.name))
    return [...subscribedProtocols]
  }

  const handleFiltersChange = (newFilters) => setFilters(newFilters)
  const handleToggleShowExtraOptions = () => setShowExtraFilters(!showExtraFilters)

  const mainFilters = [
    <TextInput
      name={nameFieldDesignation}
      className='col s12 m4'
      id='memberfilter'
      key='memberfilter'
      icon='search'
      label={I18n.t('group.detail.questionnaires.filters.filter_by_person')}
      value={filters[nameFieldDesignation]}
    />
  ]

  const extraFilters = [
    <DropdownInput
      name={protocolFieldDesignation}
      className='col s12 m4'
      id='protocol-filter'
      key='protocol-filter'
      label={I18n.t('group.detail.questionnaires.filters.filter_by_protocol')}
      isMultipleChoice
      content={getSubscribedProtocolTypes(props?.protocolSubscriptions).map((protocolName) => {
        return {
          name: smartTranslate(`group.detail.questionnaires.protocols.${protocolName}`), value: protocolName
        }
      })}
      value={filters[protocolFieldDesignation]}
      placeholder={I18n.t('group.detail.questionnaires.filters.select_protocol')}
    />,
    <DropdownInput
      name={dateScopeFieldDesignation}
      className='col s4 m1'
      id='date-scope-filter'
      key='date-scope-filter'
      label={I18n.t('group.detail.questionnaires.filters.active')}
      value={filters[dateScopeFieldDesignation]}
      content={[
        { name: I18n.t('group.detail.questionnaires.filters.date_picker.active_before'), value: ACTIVE_BEFORE },
        { name: I18n.t('group.detail.questionnaires.filters.date_picker.active_after'), value: ACTIVE_AFTER },
        { name: I18n.t('group.detail.questionnaires.filters.date_picker.active_on'), value: ACTIVE_ON }
      ]}
    />,
    <DateInput
      name={datePickerFieldDesignation}
      className='col s8 m3'
      id='date-filter'
      key='date-filter'
      placeholder={I18n.t('components.datepicker.placeholder.day')}
      value={filters[datePickerFieldDesignation]}
    />
  ]

  const noProtocolSubscriptions = !props.protocolSubscriptions || props.protocolSubscriptions.length === 0
  const tomorrow = moment().add(1, 'days').toDate()

  const filterByActiveFn = (protocol) => protocol.state === 'active'

  const nameFilter = _.lowerCase(filters?.[nameFieldDesignation])
  const filterByNameFn = (protocol) => {
    const wordsInUserName = _.words(_.lowerCase(`${protocol.profile.first_name} ${protocol.profile.last_name}`))
    const foundMatch = wordsInUserName.some((word) => _.startsWith(word, nameFilter))
    return foundMatch
  }

  const protocolFilter = filters?.[protocolFieldDesignation]
  const filterByProtocolFn = (protocol) => {
    return protocolFilter?.includes(protocol.name)
  }

  const dateScopeFilter = filters?.[dateScopeFieldDesignation]
  const dateFilter = filters?.[datePickerFieldDesignation]
  const filterByActivityDateFn = (protocol) => {
    const protocolStartDate = moment(protocol.start_date)
    const protocolEndDate = moment(protocol.end_date)

    switch (dateScopeFilter) {
      case ACTIVE_BEFORE:
        return protocolStartDate.isSameOrBefore(dateFilter, 'day')
      case ACTIVE_AFTER:
        return protocolEndDate.isSameOrAfter(dateFilter, 'day')
      case ACTIVE_ON:
        return protocolStartDate.isSameOrBefore(dateFilter, 'day') && protocolEndDate.isSameOrAfter(dateFilter, 'day')
      default:
        return false
    }
  }

  const filtersToApply = [filterByActiveFn]
  if (nameFilter) { filtersToApply.push(filterByNameFn) }
  if (protocolFilter?.length > 0) { filtersToApply.push(filterByProtocolFn) } // TODO Should Filter return undefined instead of [] for consistency?
  if (dateScopeFilter && dateFilter) { filtersToApply.push(filterByActivityDateFn) }

  let filteredProtocols = _.clone(props.protocolSubscriptions)
  filtersToApply.forEach((filter) => {
    filteredProtocols = _.filter(filteredProtocols, filter)
  })

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <div className='text-heavy text-l text-muted data-header inline-block'>
            {I18n.t('group.detail.questionnaires.active_questionnaires')}
          </div>
          <FontAwesomeIcon
            icon='sync'
            className={classNames('title-icon pointer-grow', props.rotating && 'rotating disabled-link')}
            onClick={props.handleRefreshProtocolSubscriptions}
            title={I18n.t('group.detail.questionnaires.refresh_active_questionnairs')}
          />
        </div>
      </div>
      {!noProtocolSubscriptions && (
        <>
          <div className='row'>
            <div className='col s12'>
              <InputGroupWithExtraOptions
                inputComponents={{ main: mainFilters, extra: extraFilters }}
                onChange={handleFiltersChange}
                onToggleExtraOptions={handleToggleShowExtraOptions}
                showExtraOptions={showExtraFilters}
                values={filters}
                extraOptionsButtonText={{
                  whenOpen: I18n.t('group.detail.questionnaires.filters.hide_filters'),
                  whenClosed: I18n.t('group.detail.questionnaires.filters.show_more_filters')
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col s12'>
              <table className='striped protocols-table responsive-table'>
                <thead>
                  <tr>
                    <th>{I18n.t('group.detail.questionnaires.person')}</th>
                    <th>{I18n.t('group.detail.questionnaires.protocol')}</th>
                    <th>{I18n.t('group.detail.questionnaires.start_date')}</th>
                    <th className='no-wrap'>
                      {I18n.t('group.detail.questionnaires.end_date')}
                      <Info text={I18n.t('group.detail.questionnaires.end_date_tooltip_active')} tooltipId='higher-z-tooltip' reuseTooltip />
                    </th>
                    <th>{I18n.t('group.detail.questionnaires.progress')}</th>
                    <th>{I18n.t('group.detail.questionnaires.actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredProtocols && filteredProtocols.length > 0 && filteredProtocols.map(q => {
                    const startDate = moment(q.start_date).toDate()
                    const endDate = moment(q.end_date).toDate()
                    return (
                      <tr key={q.id}>
                        <td>
                          <UserProfileTableEntry profile={q.profile} />
                        </td>
                        <td className='nowrap'>
                          {smartTranslate(`group.detail.questionnaires.protocols.${q.name}`)}
                          <Info
                            text={`${I18n.t('group.detail.questionnaires.questionnaires_overview')}: ${q.questionnaires.filter(qname => qname && qname.length > 0).map(qname => questionnaireName(qname)).join(', ')}`}
                            tooltipId='higher-z-tooltip'
                            reuseTooltip
                          />
                        </td>
                        <td>{moment(startDate).format(momentDateFormat)}</td>
                        <td className={classNames(props.rotating && 'disabled-button')}>
                          <DatePicker
                            id={`end-${q.id}`}
                            {...datePickerDefaultOptions()}
                            selected={endDate}
                            onChange={date => date && showChangeEndDateDialog(props.createProtocolSubscriptionProps.group.id, q.id, date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={tomorrow}
                          />
                        </td>
                        <td>{DiaryStudyProgress(q.protocol_completion)}</td>
                        <td>
                          <DeleteButton
                            onClick={() => showDestroyDialog(props.createProtocolSubscriptionProps.group.id, q.id)}
                            isDisabled={props.rotating}
                          >
                            {I18n.t('group.detail.questionnaires.stop')}
                          </DeleteButton>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
      {noProtocolSubscriptions && (
        <div className='row'>
          <div className='col s12'>
            <em className='text-muted'>{I18n.t('group.detail.questionnaires.no_active_protocol_subscriptions')}</em>
          </div>
        </div>
      )}
    </>
  )
}

export default WithModal(ListActiveProtocolSubscriptions)
