import React from 'react'
import I18n from 'i18n'
import Connection from './Connection'
import { WithModal } from '../../modal/ModalProvider'
import { WithSession } from '../../session/SessionProvider'
import Spinner from '../../common/Spinner'

const ConnectionList = ({ connections, showUpgradeButton, ...props }) => {
  return (
    <>
      <div className='col s12 text-heavy muted-header'>
        <div className='row'>
          <div className='col s12'>
            <h5>{I18n.t('connections.connections.title', { count: connections.length })}</h5>
          </div>
        </div>
      </div>
      <div className='col s12'>
        <div className='row'>
          <Spinner ready={props.dataRetrieved} transparent />
          {connections.map(connection => (
            <Connection onDeleteConnection={props.onDeleteConnection} key={connection.id} connection={connection} onLeave={() => {}} />
          ))}
        </div>
      </div>
    </>
  )
}

export default WithSession(WithModal(ConnectionList))
