// Expand later with properties as needed

import GroupMembership from './GroupMembership'

// All connections between users in the platform are modelled as "Groups"
interface Group {
  id: number
  my_membership: GroupMembership
  group_type: GroupType
  name: string
  code: string
  code_expires_at: string
  created_at: string
  description: string
  group_memberships: GroupMembership[]
  informed_consent: any
  picture: string
}

enum GroupType {
  PUBLIC = 'public',
  MUTUAL_CONNECTION = 'mutual_connection',
  GROUP = 'group',
  FOLLOWERS = 'followers',
  ANONYMIZED = 'anonymized'
}

export default Group
export { GroupType }
