import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MyReactTooltip from '../../MyReactTooltip'

// The two required properties are `text` and `tooltipId`.
// `tooltipId` should be unique within the page.
interface Props {
  text: string
  tooltipId: string // Must be unique within the page
  inline?: boolean
  reuseTooltip?: boolean
  [propName: string]: any
}

const Info = (props: Readonly<Props>): React.ReactElement => {
  const { inline = false, tooltipId, text, reuseTooltip = false, ...theRest } = props
  if (inline) {
    return (
      <>
        <i className='material-icons information-icon' data-tip={text} data-for={tooltipId}>info</i>
        {!reuseTooltip && <MyReactTooltip id={tooltipId} effect='solid' {...theRest} />}
      </>
    )
  }

  return (
    <>
      <FontAwesomeIcon icon='info-circle' data-tip={text} data-for={tooltipId} className='information-icon' />
      {!reuseTooltip && <MyReactTooltip id={tooltipId} effect='solid' {...theRest} />}
    </>
  )
}

export default Info
