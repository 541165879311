import React from 'react'

interface ToggleProps {
  id: string
  isChecked?: boolean
  onChange?: () => void
  title: string
  errors?: string[]
}

const Toggle: React.FC<ToggleProps> = ({ id, isChecked, onChange, title, errors }): React.ReactElement => {
  return (
    <div>
      <div className='row'>
        <div className='col s12 valign-wrapper toggle'>
          <label htmlFor={id} className='switch'>
            <input
              type='checkbox'
              id={id}
              name={id}
              checked={isChecked}
              onChange={onChange}
            />
            <div className='slider' />
            <span>
              {title}
            </span>
          </label>
        </div>
        <div className='col s6 valign-wraper'>
          {errors?.map(err => (
            <span className='helper-text' data-error='wrong' data-success='right' key={err} style={{ color: 'red' }}>
              {err}
            </span>
          ))}
        </div>
      </div>
    </div>
  )
}

Toggle.defaultProps = {
  errors: [],
  isChecked: false
}

export default Toggle
