import Info from '../atomic/atoms/Info'
import React, { useRef } from 'react'
import { debounce } from 'lodash'

const Search = (props) => {
  // useRef prevents the debounce to be bound with every re-render
  const handleSearch = useRef(
    debounce(
      e => {
        const query = e.target.value
        if (query.length !== 0 && query.length < 2) return
        props.onSearch(query)
      },
      500,
      { leading: true }
    )
  ).current

  function handleClearField () {
    handleSearch({ target: { value: '' } })
    const element = document.getElementById(props.id)
    element.value = ''
  }

  const name = props.name || 'search'
  const { id, className, type, defaultValue, labelClass, label, infoBox } = props

  let infoBoxText = infoBox
  if (typeof infoBoxText === 'function') {
    infoBoxText = infoBoxText()
  }

  return (
    <div className='input-field'>
      <input
        id={id}
        name={name}
        className={className}
        type={type || 'text'}
        defaultValue={defaultValue || ''}
        onChange={e => { e.persist(); handleSearch(e) }}
        ref={props.useRef}
      />
      <label htmlFor={id} className={labelClass}>
        {label}
      </label>
      <span className='field-postfix text-primary-color'>
        <i className='material-icons pointer-grow' onClick={handleClearField}>cancel</i>
        {infoBox && <Info inline text={infoBoxText} tooltipId='new-connection-search-tooltip' />}
      </span>
    </div>
  )
}

export default Search
