import React, { useEffect, useState } from 'react'
import OAuthRoutes from './OAuthRoutes.jsx'
import { WithSession } from '../session/SessionProvider'
import { WithBackend } from '../backend/BackendProvider'

const OAuthController = (props) => {
  useEffect(() => {
    fetchApplicationDetails()
  }, [])

  const [applicationData, setApplicationData] = useState(undefined)
  const [authorizedResult, setAuthorizedResult] = useState(undefined)

  const fetchApplicationDetails = (clientId) => {
    if (!clientId) return undefined

    const result = props.backend.getOauthApplicationDetails(clientId)
    result.then((res) => { setApplicationData(res.data) })
  }

  const authorizeApplication = (application, code_challenge = undefined, code_challenge_method = undefined, state = undefined, response_type = 'code') => {
    if (!application) return undefined

    // is_sdv flag used to restrict the backend to return a rendered result of redirect rather than backend performing redirect
    const result = props.backend.authorizeOauthApplication(
      {
        client_id: application.client_id,
        redirect_uri: application.redirect_uri,
        response_type: response_type,
        scope: application.scopes.join(' '),
        code_challenge: code_challenge,
        code_challenge_method: code_challenge_method,
        state,
        is_sdv: true
      }
    )
    result.then((res) => {
      setAuthorizedResult(res.data)
      window.location.assign(res.data.redirect_uri)
    })
  }

  return (
    <OAuthRoutes
      oauthProps={{
        fetchApplicationDetails: fetchApplicationDetails,
        authorizeApplication: authorizeApplication,
        applicationData: applicationData,
        authorizedResult: authorizedResult
      }}
    />
  )
}
export default WithBackend(WithSession(OAuthController))
