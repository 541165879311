import qs from 'qs'
import I18n from 'i18n'
import {
  soccerColors,
  momentFullDateFormatWithTime,
  momentDateFormat,
  momentMonthDayFormat,
  collectionTypes,
  groupTypes,
  membershipStates,
  accessLevels,
  highchartsThemeElementary,
  momentDayMonthYearFormat,
  complaintsQuestionnaire,
  subscriptionTypes,
  rheumaDailyQuestionnaire,
  intakeQuestionnaireRheumatism,
  asesRheumatism,
  brafRheumatism,
  eq5d5lRheumatism,
  hadsRheumatism,
  restq,
  squashQuestionnaire,
  psychologicalCompetenciesQuestionnaire, climbingAccidentQuestionnaire
} from './Constants'

import moment from 'moment'
import _ from 'lodash'
import { getWeekNumber, smartRounding } from './Math'
import Routes from '../common/Routes'
import DOMPurify from 'dompurify'
import Highcharts from 'highcharts'
import { MyAxios as axios } from '../MyAxios'

export function secondsSinceMidnightToString (seconds) {
  return new Date(seconds * 1000).toISOString().substr(11, 5)
}

export function secondsToHrMin (seconds) {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  return `${hours} h ${minutes} m`
}

export function accessLevelIsAtLeast (accessLevel, minLevel) {
  const minLevelIdx = accessLevels.indexOf(minLevel)
  if (minLevelIdx < 0) {
    console.log('Error in \'accessLevelIsAtLeast\': \'minLevel\' not recognized')
    return false
  }
  return accessLevels.indexOf(accessLevel) >= minLevelIdx
}

export function isMyData (me, data) {
  const dataOwnerId = _.get(data, 'owner.id', undefined)
  const myId = _.get(me, 'id', undefined)
  if (myId === undefined || dataOwnerId === undefined) {
    return false
  }
  return (myId === dataOwnerId)
}

export function generateUuid () {
  let dt = new Date().getTime()
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
}

export function getCode () {
  const { location: { search } } = this.props
  if (search === undefined || !search.includes('c=')) {
    return ''
  }
  return search.replace('?c=', '')
}

export function getRedirectUri () {
  const { location: { search } } = this.props
  if (search === undefined) {
    return ''
  }
  const encodedRedirectUri = qs.parse(search, { ignoreQueryPrefix: true }).redirect_uri || ''
  return decodeURIComponent(encodedRedirectUri)
}

export function addSearchToPath (path) {
  const { location: { search } } = this.props
  if (search === undefined) {
    return path
  }
  return `${path}${search}`
}

function formatDateTimeAux (date) {
  return moment(date).locale(I18n.locale).format(momentFullDateFormatWithTime)
}

function formatDateAux (date) {
  return moment(date).format(momentDateFormat)
}

export async function asyncForEach (array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

export function formatDateTime (date) {
  if (date instanceof Date) {
    return formatDateTimeAux(date)
  } else {
    return formatDateTimeAux(new Date(date))
  }
}

export function formatDate (date) {
  if (date instanceof Date) {
    return formatDateAux(date)
  } else {
    return formatDateAux(new Date(date))
  }
}

export function dateStringToYearWeek (string) {
  const weekNumber = getWeekNumber(new Date(string))
  return `${weekNumber[0]}-${weekNumber[1]}`
}

export function dateStringToMonthDay (string) {
  return moment(new Date(string)).format(momentMonthDayFormat)
}

export function dateStringToDayMonthYear (string) {
  return moment(new Date(string)).format(momentDayMonthYearFormat)
}

export function addConditional (classNames, condition, conditionalClassNames) {
  return `${classNames}${condition ? ` ${conditionalClassNames}` : ''}`
}

export function pad (n, width, z) {
  z = z || '0'
  n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

// Providing isTransparent = true returns color value with 50% transparency
export function getColor (index, isTransparent = false) {
  const moduledIndex = index % soccerColors.length
  return isTransparent ? soccerColors[moduledIndex] + '80' : soccerColors[moduledIndex]
}

export function shouldShowDashboardLink (sport, dataRows) {
  if (!dataRows) return true

  // Deal with questionnaire logic
  if (sport === 'sports.questionnaire') {
    const hideableQuestionnaires = [squashQuestionnaire, psychologicalCompetenciesQuestionnaire, climbingAccidentQuestionnaire, restq, intakeQuestionnaireRheumatism, asesRheumatism, brafRheumatism, eq5d5lRheumatism, hadsRheumatism]
    const key = _.get(dataRows, '[0].questionnaire.key')
    return !(key && hideableQuestionnaires.includes(key))
  }
  return true
}

export function dashboardLink (name, id, dataIsMine, subType) {
  const suffix = id ? `${id}` : ''
  switch (name) {
    case 'sports.skating':
      // We currently do not support multiple files passed into a dashboard
      return Routes.analysis.iceSkating
    // We currently don't have a volleybal dashboard
    // case 'sports.volleyball':
    //  return `${prefix}/volleybal`
    case collectionTypes.RUNNING:
      return Routes.analysis.running.single.showFn(suffix)
    case 'sports.running':
      return Routes.analysis.running.index
    case 'sports.triathlon':
      return Routes.analysis.triathlon
    case collectionTypes.SOCCER:
    case 'sports.soccer':
      // We currently do not support multiple files passed into a dashboard
      return Routes.analysis.soccer.showFn(suffix)
    case 'sports.steps':
      // Does not have a suffix as it shows summaries over a timespan, not a
      // data set.
      return Routes.analysis.activity.index
    case collectionTypes.HIKING:
    case 'sports.hiking':
      return Routes.analysis.hiking.showFn(suffix)
    case collectionTypes.RIDING:
      return Routes.analysis.cycling.single.showFn(suffix)
    case 'sports.riding':
      return Routes.analysis.cycling.index
    case collectionTypes.INLINE_SKATING:
    case 'sports.inline_skating':
      return Routes.analysis.inlineSkating.showFn(suffix)
    case collectionTypes.SWIMMING:
      return Routes.analysis.swimming.single.showFn(suffix)
    case 'sports.swimming':
      return Routes.analysis.swimming.index
    case 'sports.questionnaire':
      // We currently do not support multiple files passed into a dashboard
      if (!suffix || suffix.length === 0) return Routes.analysis.questionnaire.index
      if (subType.includes(complaintsQuestionnaire)) return Routes.analysis.questionnaire.complaints.showFn(suffix)
      if (subType.includes(rheumaDailyQuestionnaire)) return Routes.analysis.questionnaire.rheuma.showFn(suffix)
      if (dataIsMine) return Routes.analysis.questionnaire.weekly
      return Routes.analysis.questionnaire.indexFn(suffix)
    case collectionTypes.CSV_FILE:
    case 'sports.generic_csv':
      return Routes.analysis.csv.showFn(suffix)
    default:
      return undefined
  }
}

// Don't translate strings that were already translated. This function is needed because the database validations
// can return strings that are sometimes already translated (for the default activerecord stuff) and sometimes not
// (for our own custom validations). We chose not to translate our own custom validations so that translation
// occurs in the front-end as much possible.
export function smartTranslate (str, defaultValue = str) {
  let translatedStr = I18n.t(str, { defaults: [{ message: defaultValue }] })
  if (typeof translatedStr !== 'string') translatedStr = defaultValue
  if (translatedStr.substr(0, 10) === '[missing "' && translatedStr.substr(-14) === '" translation]') {
    return defaultValue
  }
  return translatedStr
}

export function capitalizeFirstLetter (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function titleWithTooltip (title, tooltip, tooltipId = 'questionnaire-tooltip') {
  return {
    text: `${title}<i class='material-icons information-icon' data-tip="${tooltip}" data-for='${tooltipId}'>info</i>`,
    useHTML: true
  }
}

export function roundTooltip (tooltipItem, data) {
  let label = data.datasets[tooltipItem.datasetIndex].label || ''

  if (label) {
    label += ': '
  }
  label += Math.round(tooltipItem.yLabel * 100) / 100
  return label
}

export const transparentize = (color, opacity) => {
  const alpha = opacity === undefined ? 0.5 : 1 - opacity
  return global.Color(color).alpha(alpha).rgbString()
}

const today = (new Date().getDay()) % 7

export const rotateTodayToEnd = (arr) => {
  return arr.slice((today + 1) % 7, 7).concat(arr.slice(0, (today + 1) % 7))
}

export const sortedDays = () => {
  // Needs to be evaluated because the locale is not necessarily the proper locale when this file is read.
  const days = [
    I18n.t('components.dashboards.steps.sunday'),
    I18n.t('components.dashboards.steps.monday'),
    I18n.t('components.dashboards.steps.tuesday'),
    I18n.t('components.dashboards.steps.wednesday'),
    I18n.t('components.dashboards.steps.thursday'),
    I18n.t('components.dashboards.steps.friday'),
    I18n.t('components.dashboards.steps.saturday')
  ]
  return rotateTodayToEnd(days)
}

// Converts a given weeknumber and year into a date (it chooses monday of that week).
// We use isoWeeks everywhere now because I found that when using regular week, the week number
// was off by one to what a google search for week number or a lookup on any calendar would give you
// as the actual week number. So I changed the entire codebase to use isoWeek instead and that
// fixes all the one-off inconsistencies I found.
export const dateOfWeek = (wknum, year) => {
  // return moment().weekYear(year).week(wknum).day(1).hour(0).minute(0).second(0).millisecond(0).toDate()
  return moment().isoWeekYear(year).isoWeek(wknum).startOf('isoWeek').toDate()
}

export function collectionTypeToIcon (collectionType) {
  const iconMap = {
    [collectionTypes.STRAVA]: 'ellipsis-h',
    [collectionTypes.FIT]: 'ellipsis-h',
    [collectionTypes.STEPS]: 'walking',
    [collectionTypes.RIDING]: 'biking',
    [collectionTypes.RUNNING]: 'running',
    [collectionTypes.SWIMMING]: 'swimmer',
    [collectionTypes.INLINE_SKATING]: 'skating',
    [collectionTypes.ICE_SKATING]: 'skating',
    [collectionTypes.SOCCER]: ['far', 'futbol'],
    [collectionTypes.HIKING]: 'hiking',
    [collectionTypes.CSV_FILE]: 'file-csv',
    [collectionTypes.TEMP]: 'temperature-low'
  }
  return iconMap[collectionType]
}

export const durationFromMinutes = (minutes) => {
  const duration = moment.duration(minutes, 'minutes')
  return `${duration.hours()}h${duration.minutes()}m`
}

export const durationFromISOString = (isoString) => {
  if (!isoString) return undefined
  const duration = moment.duration(isoString)
  return `${duration.hours()}h${duration.minutes()}m`
}

export function colorMap (hex1, hex2, weight) {
  const color1 = hexToRgb(hex1)
  const color2 = hexToRgb(hex2)
  const w1 = weight
  const w2 = 1 - w1
  const rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2)]
  return rgbToHex(...rgb)
}

function componentToHex (c) {
  const hex = c.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

export function rgbToHex (r, g, b) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

export function hexToRgb (hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  if (!result) {
    return null
  }
  return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
}

export function hexToRgbaString (hex, a) {
  const rgb = hexToRgb(hex)
  if (!rgb) return ''

  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${a})`
}

export function interpolateColors (color1, color2, nColors) {
  return [...Array(nColors).keys()].map(v => colorMap(color1, color2, v / nColors))
}

// transforms a 1d zone limit array to an array of tuples
export function zipZoneLimits (zoneLimits) {
  const length = _.get(zoneLimits, 'length', 0)
  if (length < 2) return []

  return zoneLimits.reduce((acc, value, idx, arr) => {
    if (idx !== 0) {
      acc.push([arr[idx - 1], value])
    }
    return acc
  }, [])
}

export function prepareSummaryFields (summaryWithStrings, fieldsOfInterest) {
  if (!summaryWithStrings) return []

  return fieldsOfInterest.map(key => (
    { key: key, name: I18n.t(`components.dashboards.table.${key}`), value: summaryWithStrings[key] }
  ))
}

/**
 * Because jupyter notebooks that were uploaded through the create_raw endpoint have an extra
 * layer of JSON wrapper around the actual data, we need to strip that from the file before
 * serving it back to the user. This is done by first downloading the file, then modfying it
 * and finally we create a 'fake' url from which the local file will be served
 */
export function doJupyterNotebookDownload (response) {
  // need to remove most outer layer of JSON in order to keep downloaded file equivalent to uploaded file
  const data = JSON.stringify(response.data.data)
  const filename = response.data.filename
  const blob = new Blob([data], { type: response.headers['content-type'] })
  if (window.navigator.msSaveOrOpenBlob) {
    // if Internet Explorer: save the blob
    window.navigator.msSaveBlob(blob, filename)
  } else {
    // create temp url for file, create hidden href and click it to trigger a download
    const url = window.URL.createObjectURL(blob)
    const elem = window.document.createElement('a')
    elem.href = url
    elem.download = filename
    document.body.appendChild(elem)
    elem.click()
    // remove element and temp url
    document.body.removeChild(elem)
    window.URL.revokeObjectURL(url)
  }
}

export function preprocessJupyterNotebook (file) {
  return new Promise((resolve, reject) => {
    try {
      const contentType = file.type
      const filename = file.name
      if (contentType === 'application/x-ipynb+json' && filename.substr(filename.length - 6) === '.ipynb') {
        file.text().then((data) => {
          try {
            const parsed = JSON.parse(data)
            const fileWithSignature = new File(
              [JSON.stringify(withJupyterhubSignature(parsed, filename))],
              filename,
              { type: file.type, lastModified: file.lastModified }
            )
            fileWithSignature.path = file.path
            resolve(fileWithSignature)
          } catch (e) {
            resolve(undefined)
          }
        })
      } else {
        resolve(file)
      }
    } catch (e) {
      resolve(undefined)
    }
  })
}

export function withJupyterhubSignature (data, filename) {
  return { serviceName: 'jupyterhub', uuid: '', data, filename }
}

export function zipData (x, y) { return _.zipWith(x, y, (x, y) => ({ x: x, y: y })).filter((entry) => !!entry.x && !!entry.y) }

export function sampleData (data, n) {
  if (!data) return []

  if (!n || n >= data.length) {
    return data
  }
  const sampleFrequency = data.length / Math.min(data.length, n)
  const sampled = []
  for (let i = 0; i < Math.min(data.length, n); i++) {
    sampled.push(data[Math.floor(i * sampleFrequency)])
  }
  return sampled
}

// returns morning/afternoon/evening depending on the input time
export function timeOfDay (time) {
  const dateTime = new Date(time)
  const hour = dateTime.getHours()
  if (hour < 12) return 'morning'
  if (hour < 18) return 'afternoon'
  return 'evening'
}

export function fillParticipantNames (datasetSummary) {
  const participants = datasetSummary.participant_summaries
  if (!datasetSummary || !participants) return
  participants.forEach(participant => {
    if (!(participant.name && participant.name.length > 0)) {
      participant.name = `${I18n.t('misc.player.one')}${[participants.indexOf(participant)]}`
    }
  })
}

export function getMostFrequentKey (obj) {
  return Object.keys(obj).reduce((a, b) => obj[a] >= obj[b] ? a : b)
}

/**
 * Sorts arr2 ascending and applies the same permutations to arr1
 * @param arr1 Array to sort
 * @param arr2 Array to sort and to sort by
 * @returns [sortedArr1, sortedArr2] sorted arrays
 */
export function sortTogether (arr1, arr2) {
  return arr1
    .map((item, index) => [arr2[index], item]) // add the args to sort by
    .sort(([arg1], [arg2]) => arg1 - arg2) // sort by the args
    .reduce((acc, [el2, el1]) => { acc[0].push(el1); acc[1].push(el2); return acc }, [[], []])
}

/**
 * Partitions array into two groups (pass/fail) depending on whether they satisfy the filterCallback or not
 * @param arr
 * @param filterCallback
 * @return [pass, fail] Arrays containing the elements that pass/fail the filterCallback check
 */
export function partition (arr, filterCallback) {
  return arr.reduce((acc, elem) => {
    filterCallback(elem) ? acc[0].push(elem) : acc[1].push(elem)
    return acc
  }, [[], []])
}

export function filterActiveConnections (groups) {
  return groups.filter((group) => {
    return group.group_type === groupTypes.MUTUAL_CONNECTION && group.group_memberships.filter(
      (membership) => {
        return membership.state === membershipStates.ACCEPTED
      }
    ).length === 2
  })
}

export const gAEnabled = () => {
  return (process.env.GOOGLE_ANALYTICS_TOKEN && process.env.GOOGLE_ANALYTICS_TOKEN.length > 0)
}

export const trans = (...args) => {
  const clean = DOMPurify.sanitize(
    I18n.t(...args),
    {
      ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'u'],
      ALLOWED_ATTR: ['href', 'target']
    }
  )
  return clean
}

export function hexWithAlpha (hex, alpha) {
  const alphaHex = Math.round(255 * alpha).toString(16).padStart(2, '0')
  return `${hex}${alphaHex}`
}

export function fileDescription (file) {
  const { title, event_start: eventStart } = file || {}
  return `${title} (${formatDateTime(eventStart)})`
}

// Fixing and unfixing for modal dialogs to
// make sure that the dialog doesn't change the vertical scroll position,
// and that the background of the modal dialog is not scrollable.

export const fixBody = (setOriginalFixer) => {
  // If we already fixed it, don't fix it again or we lose the scroll position
  if (document.body.style.position === 'fixed') return

  const scrollY = window.scrollY
  document.body.style.position = 'fixed'
  document.body.style.top = `-${scrollY}px`
  document.body.style.width = '100%'
  const app = document.querySelector('.wrapper.app-wrapper')
  if (app) {
    app.style.filter = 'blur(8px)'
  }
  setOriginalFixer(true)
}

export const unFixBody = (originalFixer, setOriginalFixer) => {
  // Return if we didn't fix the body: It means there was another Modal component.
  if (!originalFixer) return

  setOriginalFixer(false)

  const app = document.querySelector('.wrapper.app-wrapper')
  if (app) {
    app.style.filter = ''
  }

  // Only unfix it if it wasn't already unfixed, or we lose the scroll position
  if (document.body.style.position !== 'fixed') return

  const scrollY = document.body.style.top
  document.body.style.position = ''
  document.body.style.top = ''
  document.body.style.width = ''
  window.scrollTo(0, parseInt(scrollY || '0') * -1)
}

// Initialize Highcharts
export const initializeHighcharts = (theme = false) => {
  ['data-for', 'data-tip'].forEach(item => {
    if (!Highcharts.AST.allowedAttributes.includes(item)) {
      Highcharts.AST.allowedAttributes.push(item)
    }
  })
  if (theme) {
    Highcharts.theme = highchartsThemeElementary
    Highcharts.setOptions(Highcharts.theme)
  }
}

export const getFirstDataRow = (vdo) => {
  return vdo?.structured_data_objects[0]?.data_rows[0]
}

export function myHandleRequest (body, method, endpoint, formName, sessionToken, handleSuccess) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: sessionToken
  }
  axios({ method: method, url: endpoint, headers: headers, data: body }).then((response) => {
    handleSuccess(response)
  }).catch((err) => {
    console.log(err)
  })
}

export function locationParam (location, key) {
  const search = _.get(location, 'search', '')
  const params = search.replace('?', '').split('&').map(p => p.split('='))
  return params.find(param => param[0] === key)?.[1] || null
}

export const formatTemp = (value) => {
  return `${smartRounding(value, 1)} ${I18n.t('units.C')}`
}

// Week day can be a number 0-6 or the week day name (e.g. friday, Monday)
export const getNextWeekDayOccurrence = (currentDay, weekday) => {
  const result = moment(currentDay).day(weekday)
  if (result.isBefore(currentDay)) {
    result.add(1, 'week')
  }
  return result
}

export const getLastWeekDayOccurrence = (currentDay, weekday) => {
  const result = moment(currentDay).day(weekday)
  if (result.isAfter(currentDay)) {
    result.subtract(1, 'week')
  }
  return result
}

export const questionnaireName = questionnaireKey => {
  return smartTranslate(`group.detail.questionnaires.questionnaires.${questionnaireKey}`, capitalizeFirstLetter(questionnaireKey))
}

export const isStartOfWeek = (date) => {
  const day = (new Date(date)).getDay()
  return day === 1
}

export const isEndOfWeek = (date) => {
  const day = (new Date(date)).getDay()
  return day === 0
}

export const profileIsPremium = (profile) => (
  [subscriptionTypes.coach, subscriptionTypes.researcher].includes(_.get(profile, 'subscription.type'))
)
