import React from 'react'
import I18n from 'i18n'
import Field from '../common/Field'
import { PsychologicalCompetenciesData } from '../../../../common/types/questionnaires/PsychologicalCompetenciesData'

interface Props {
  data: PsychologicalCompetenciesData
}

const PsychologicalCompetenciesQuestionnaire: React.FC<Props> = (props) => {
  const values = props.data.values
  return (
    <>
      <div className='sport-fields'>
        <Field
          title={I18n.t('questionnaires.psychological_competencies.coachability.title')}
          value={`${parseInt(values.s1) / 100.0}`}
        />
        <Field
          title={I18n.t('questionnaires.psychological_competencies.reflection.title')}
          value={`${parseInt(values.s2) / 100.0}`}
        />
        <Field
          title={I18n.t('questionnaires.psychological_competencies.growth_mindset.title')}
          value={`${parseInt(values.s3) / 100.0}`}
        />
        <Field
          title={I18n.t('questionnaires.psychological_competencies.commitment.title')}
          value={`${parseInt(values.s4) / 100.0}`}
        />
        <Field
          title={I18n.t('questionnaires.psychological_competencies.confidence.title')}
          value={`${parseInt(values.s5) / 100.0}`}
        />
      </div>
      <div className='sport-fields'>
        <Field
          title={I18n.t('questionnaires.psychological_competencies.focus.title')}
          value={`${parseInt(values.s6) / 100.0}`}
        />
        <Field
          title={I18n.t('questionnaires.psychological_competencies.resilience.title')}
          value={`${parseInt(values.s7) / 100.0}`}
        />
        <Field
          title={I18n.t('questionnaires.psychological_competencies.perseverance.title')}
          value={`${parseInt(values.s8) / 100.0}`}
        />
        <Field
          title={I18n.t('questionnaires.psychological_competencies.set_and_protect_boundaries.title')}
          value={`${parseInt(values.s9) / 100.0}`}
        />
      </div>
    </>
  )
}

export default PsychologicalCompetenciesQuestionnaire
