import React, { useState } from 'react'

import { useMyPhysiologicalAttributesQuery } from 'components/backend/Queries'
import I18n from 'i18n'
import HeartRateZoneEditor from 'components/atomic/organisms/HeartRateZoneEditor'
import { useMyPhysiologicalAttributesMutation } from 'components/backend/Mutations'
import { useFlashMessages } from 'components/common/Hooks'
import SpinnerWrapper from 'components/common/SpinnerWrapper'

const PhysiologicalDetails = (): JSX.Element => {
  const flashMessages = useFlashMessages()
  const { data: physiologicalAttributes, refetch, isSuccess, isError } = useMyPhysiologicalAttributesQuery()
  const { mutateAsync } = useMyPhysiologicalAttributesMutation()
  const [currentHRZoneLimits, setCurrentHRZoneLimits] = useState<undefined|number[]>()

  const hrZoneLimits = currentHRZoneLimits ?? physiologicalAttributes?.find(attr => attr.variable === 'heart_rate_zones')?.values

  const handleSubmit = (): void => {
    if (currentHRZoneLimits != null) {
      mutateAsync({ physiological_attribute: { heart_rate_zones: currentHRZoneLimits } })
        .then(() => {
          flashMessages.push(
            I18n.t('flashmessages.profile.update_successful'),
            flashMessages.duration.SHORT,
            flashMessages.levels.INFO)
          // There is no reason why the fetch should fail other than connectivity issues. Therefore, if the request fails
          // we ignore it (using void). To the user everything will look normal (at this point we know that the server
          // received the update and therefore the server and frontend have the same state).
          void refetch().then(() => setCurrentHRZoneLimits(undefined))
        }
        )
        .catch((e) => {
          flashMessages.push(
            I18n.t('flashmessages.profile.update_failed'),
            flashMessages.duration.LONG,
            flashMessages.levels.ERROR
          )
          console.error(e)
        })
    }
  }

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <div className='text-heavy text-l text-muted data-header'>
            {I18n.t('profile.physiological.attributes.heart_rate_zones')}
          </div>
          <div className='text-m text-muted'>
            {I18n.t('profile.physiological.effect_explanation')}
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <SpinnerWrapper ready={isSuccess} failed={isError} transparent>
            <HeartRateZoneEditor hrZoneLimits={hrZoneLimits} onZoneLimitsChange={setCurrentHRZoneLimits} />
          </SpinnerWrapper>
        </div>
      </div>
      <div className='row section'>
        <div className='col s12 m6'>
          <button
            onClick={handleSubmit}
            className='button-primary background-primary text-background-color waves-effect waves-light margin-top'
            disabled={currentHRZoneLimits === undefined}
          >
            {I18n.t('profile.physiological.save')}
          </button>
        </div>
      </div>
    </>
  )
}

export default PhysiologicalDetails
