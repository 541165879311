import React from 'react'
import classNames from 'classnames'

interface Props {
  isTopHeader?: boolean
  children: React.ReactElement
}

// The component for a default, non-clickable header.
const Header: React.FC<Props> = ({ isTopHeader = false, children }) => {
  return (
    <div className='row'>
      <div className='col s12 text-heavy muted-header'>
        <h5 className={classNames(isTopHeader && 'no-margin-top')}>{children}</h5>
      </div>
    </div>
  )
}

export default Header
