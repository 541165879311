import { SportType } from './Sport'

export enum Status {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected'
}

export interface Dataset {
  id: number
  title: string
  type: SportType
}

interface ProfileInfo {
  id: number
  slug: string
}

export interface TransferRequest {
  id: number
  status: Status
  from: ProfileInfo
  to: ProfileInfo
  datasets: Dataset[]
  created_at: Date
}
