import React, { useMemo } from 'react'
import { WithErrorBoundary } from '../../../errorboundary/ErrorBoundaryProvider'
import I18n from 'i18n'
import 'chartjs-chart-box-and-violin-plot/build/Chart.BoxPlot.js'
import BoxPlot from '../common/BoxPlot'
import computeBoxes from '../../../common/BoxPlotUtils'
import Table from '../../../common/Table'

const canvasId = (descriptionX, descriptionY) => `csv-boxplot-${descriptionX.name.replace(' ', '-').toLowerCase()}-${descriptionY.name.replace(' ', '-').toLowerCase()}`

const CsvBoxPlot = ({ xs, ys, descriptionX, descriptionY, title, summaryBuilder }) => {
  const boxes = useMemo(() => computeBoxes(xs, ys), [xs, ys])
  const label = `${descriptionX.name} - ${descriptionY.name}`
  const chartId = canvasId(descriptionX, descriptionY)

  /**
   * Column headers for the selected column's summary table
   */
  const categoricalColumns = summaryBuilder.getCategoricalSummaryColumns()
  const numericColumns = summaryBuilder.getNumericSummaryColumns()

  /**
   * Values for the selected column's summary table (only a single row because a histogram is for a single variable)
   */
  const categoricalRows = summaryBuilder.getCategoricalSummaryRows([descriptionY.name])
  const numericRows = summaryBuilder.getNumericSummaryRows([descriptionX.name])

  return (
    <div className='row'>
      <div className='col s12'>
        <Table columns={categoricalColumns} rows={categoricalRows} />
      </div>
      <div className='col s12'>
        <Table columns={numericColumns} rows={numericRows} />
      </div>
      <div className='col s12'>
        <BoxPlot boxes={boxes} datasetLabel={label} title={title} chartId={chartId} />
      </div>
    </div>
  )
}

export default WithErrorBoundary(CsvBoxPlot, I18n.t('components.visualization.csv.errors.boxplot'))
