import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { hcBlack, hcRed } from '../../../../common/Constants'
import I18n from 'i18n'
import GenericPlaceholder from '../../../../common/GenericPlaceholder'
import { initializeHighcharts } from '../../../../common/Utils'

const DailyRestingHeartrateChart = props => {
  if (!props.dailyLogRows) {
    return (
      <GenericPlaceholder title={I18n.t('components.dashboards.questionnaire.daily_resting_heartrate.title')} />
    )
  }
  const scatterData = props.dailyLogRows.map(entry => {
    return {
      x: entry.date_only,
      y: entry.resting_hr,
      daily_date: entry.formatted_date,
      daily_hrrest: entry.resting_hr
    }
  })
  const lineData = props.dailyLogRows.map(entry => {
    return {
      x: entry.date_only,
      y: entry.resting_hr_moving_average,
      formatted_date: entry.formatted_date
    }
  })
  initializeHighcharts(true)
  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          title: {
            text: I18n.t('components.dashboards.questionnaire.daily_resting_heartrate.title')
          },
          plotOptions: {
            scatter: {
              color: hcRed
            },
            line: {
              color: hcBlack,
              marker: {
                enabled: false
              }
            }
          },
          series: [
            {
              type: 'scatter',
              data: scatterData,
              name: '⬤',
              opacity: 1,
              tooltip: {
                headerFormat: '',
                pointFormat: I18n.t('components.dashboards.questionnaire.daily_resting_heartrate.scatter.tooltip')
              }
            },
            {
              type: 'line',
              data: lineData,
              tooltip: {
                headerFormat: '',
                pointFormat: I18n.t('components.dashboards.questionnaire.daily_resting_heartrate.line.tooltip')
              }
            }
          ],
          yAxis: {
            title: {
              text: I18n.t('components.dashboards.questionnaire.daily_resting_heartrate.yaxis')
            },
            min: 30
          },
          xAxis: {
            title: {
              text: ''
            },
            type: 'datetime',
            dateTimeLabelFormats: {
              day: '%d %b'
            }
          }
        }}
      />
    </>
  )
}

export default DailyRestingHeartrateChart
