import React from 'react'
import I18n from 'i18n'
import _ from 'lodash'
import SoccerField from '../../../analysis/dashboards/soccer/SoccerField'
import PropTypes from 'prop-types'
import Summary from '../../../timeline/layout/details/common/Summary'

const SoccerDetails = ({ icon, metadatum, data, compact }) => {
  const { summary } = data

  let teamNames = [
    `${I18n.t('components.timeline.card.unnamed_team')} 1`,
    `${I18n.t('components.timeline.card.unnamed_team')} 2`
  ]
  if (summary.teams) {
    teamNames = teamNames.map((teamName, idx) => { return summary.teams[idx].name || teamName })
  }
  const finalScore = summary.final_score && `${summary.final_score[0]} - ${summary.final_score[1]}`
  const title = teamNames && `${teamNames[0]} - ${teamNames[1]}`
  const summaries = summary.participant_summaries || []
  const initialPlayerData = _.filter(summaries.map(player => {
    return {
      number: player.number,
      team: player.team,
      positions: [player.time_series_summaries.position.start]
    }
  }), data => { return !!data.positions[0] })

  const getTeamTitle = (name) => {
    return (
      <div className='soccer-team-name-div'>
        <div className='row'>
          <div className='col s12'>
            <h5>{name}</h5>
          </div>
        </div>
      </div>
    )
  }

  if (compact) {
    metadatum.title = title
    const defaultProps = { icon: icon, metadatum: metadatum, compact: compact }
    return <Summary {...defaultProps} fields={{ finalScore }} />
  }

  return (
    <div>
      <div className='soccer-details-desktop'>
        <div className='row soccer-details-score-div'>
          <div className='col s12'>
            <h5>{finalScore}</h5>
          </div>
        </div>
        <div className='row soccer-content-timeline-container'>
          <div className='col s12'>
            <div className='row valign-wrapper'>
              <div className='col s2 flow-text'>
                {getTeamTitle(teamNames[0])}
              </div>
              <div className='col s8 soccer-field-timeline-div'>
                <SoccerField playerData={initialPlayerData} id='soccer-field-timeline' />
              </div>
              <div className='col s2 flow-text'>
                {getTeamTitle(teamNames[1])}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='soccer-details-mobile'>
        <div className='row soccer-details-score-div valign-wrapper'>
          <div className='col s4'>
            <h5>{teamNames[0]}</h5>
          </div>
          <div className='col s4'>
            <h5>{finalScore}</h5>
          </div>
          <div className='col s4'>
            <h5>{teamNames[1]}</h5>
          </div>
        </div>
        <div className='row soccer-content-timeline-container'>
          <div className='col s12 soccer-field-timeline-div'>
            <SoccerField playerData={initialPlayerData} id='soccer-field-timeline' />
          </div>
        </div>
      </div>
    </div>
  )
}

SoccerDetails.propTypes = {
  data: PropTypes.object,
  icon: PropTypes.string,
  metadatum: PropTypes.object,
  compact: PropTypes.bool
}

export default SoccerDetails
