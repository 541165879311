import React from 'react'
import I18n from 'i18n'
import BackButton from '../../common/BackButton'

export default class PrivacyStatement extends React.Component {
  render () {
    return (
      <div className='static-page-wrapper'>
        <div className='row'>
          <div className='col s12'>
            <BackButton {...this.props} />
          </div>
          <div className='col s12'>
            <div dangerouslySetInnerHTML={{ __html: I18n.t('components.static.privacy_statement') }} />
          </div>
        </div>
      </div>
    )
  }
}
