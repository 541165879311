import React, { useEffect, useState, useMemo } from 'react'
import { WithErrorBoundary } from '../../../errorboundary/ErrorBoundaryProvider'
import Variables from '../../../../../javascript/stylesheets/variables.module.scss'
import I18n from 'i18n'
import { Chart as Graph } from 'react-chartjs-2'
import 'chartjs-chart-box-and-violin-plot/build/Chart.BoxPlot.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MyReactTooltip from '../../../MyReactTooltip'
const { primaryColor, secondaryColor, accentColor } = Variables

const BoxPlotChart = ({ data, options, type, id }) => {
  const chartRef = useMemo(() => React.createRef(), [data])
  const [chart, setChart] = useState(undefined)
  useEffect(() => {
    const { current } = chartRef
    chart && chart.destroy()
    const chartContext = current.getContext('2d')
    setChart(new Graph(chartContext, {
      type,
      data,
      options
    }))
  }, [data])
  return (
    <>
      <canvas id={id} ref={chartRef} className='Chart' />
    </>
  )
}

const BoxPlot = ({ boxes, datasetLabel = 'Dataset 1', title = 'Boxplot', chartId = 'boxplot', pageSize = 5, boxBackgroundColor = primaryColor, boxBorderColor = secondaryColor, outlierColor = accentColor }) => {
  const [page, setPage] = useState(0)
  const pageCount = Math.ceil(boxes.length / pageSize)

  const nextPage = () => {
    page < pageCount && setPage(page + 1)
  }

  const previousPage = () => {
    page > 0 && setPage(page - 1)
  }

  const pageStart = page * pageSize
  const pageEnd = Math.min((page + 1) * pageSize, boxes.length)
  const paginatedBoxes = boxes.slice(pageStart, pageEnd)
  const labels = paginatedBoxes.map((box) => box.category)

  const finalTitle = title.concat(boxes.length > pageSize ? I18n.t('components.visualization.csv.page', { page: page + 1, total: pageCount }) : '')

  const boxplotData = {
    labels: labels,
    datasets: [{
      label: datasetLabel,
      backgroundColor: boxBackgroundColor,
      borderColor: boxBorderColor,
      borderWidth: 1,
      outlierColor: outlierColor,
      padding: 10,
      itemRadius: 0,
      data: paginatedBoxes
    }]
  }

  const chartData = {
    type: 'boxplot',
    data: boxplotData,
    options: {
      responsive: true,
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: finalTitle
      }
    }
  }

  return (
    <div className='row'>
      <MyReactTooltip id='boxplot' />

      <div className='col s12'>
        <div className='boxplot-wrapper'>
          <div className='bar'>
            <BoxPlotChart {...chartData} id={chartId} />
          </div>
          {boxes.length > pageSize && (
            <div className='page-button-container'>
              <div className='row'>
                <div onClick={previousPage} className='col s3 m2 l1 page-button center-align pointer-grow all-pointer-events'>
                  {page > 0 && (<FontAwesomeIcon size='2x' icon='chevron-left' data-tip={I18n.t('components.visualization.csv.previous_page')} data-for='boxplot' />)}
                </div>
                <div className='col s6 m8 l10 no-pointer-events' />
                <div onClick={nextPage} className='col s3 m2 l1 page-button center-align pointer-grow all-pointer-events'>
                  {page < (pageCount - 1) && (<FontAwesomeIcon size='2x' icon='chevron-right' data-tip={I18n.t('components.visualization.csv.next_page')} data-for='boxplot' />)}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default WithErrorBoundary(BoxPlot, I18n.t('components.visualization.csv.errors.boxplot'))
