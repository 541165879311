import React from 'react'
import SpinnerWrapper from '../../common/SpinnerWrapper'

// TODO: Maybe this layout should also be adopted on the GenericMultiDashboards
const DashboardMulti = (props) => {
  const { title, children, ready, logos } = props
  return (
    <div className=''>
      <div className='col s6 text-xl text-primary-color text-heavy no-padding'>
        {title}
      </div>
      {logos &&
        <div className='col s6 right-align'>
          {logos}
        </div>}
      <SpinnerWrapper ready={ready} transparent={false}>
        {children}
      </SpinnerWrapper>
    </div>
  )
}

export default DashboardMulti
