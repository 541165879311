import React from 'react'
import FormFactory from '../../helpers/FormFactory'
import I18n from 'i18n'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import Routes from '../../common/Routes'
import { Link } from 'react-router-dom'
import { addSearchToPath } from '../../common/Utils'
import { differenceInYears, startOfDay } from 'date-fns'

class SignUp extends FormFactory {
  constructor (props) {
    super(props)
    this.addSearchToPath = addSearchToPath.bind(this)
    this.form_fields = {
      email: {
        component: 'field',
        properties: {
          type: 'email'
        }
      },
      password: {
        component: 'field',
        properties: {
          type: 'password'
        }
      },
      password_confirm: {
        component: 'field',
        properties: {
          type: 'password'
        }
      },
      first_name: {
        component: 'field',
        propKey: 'first_name',
        properties: {
          type: 'text'
        }
      },
      last_name: {
        component: 'field',
        propKey: 'last_name',
        properties: {
          type: 'text'
        }
      },
      code: {
        component: 'hidden-get',
        propKey: 'c'
      },
      locale: {
        component: 'hidden-props',
        propKey: 'locale',
        properties: {
          locale: I18n.locale
        }
      }
    }

    this.state = { isUnder16: false }
  }

  componentDidMount () {
    this.props.setHeight(775)
  }

  handleSuccess (response) {
    this.props.flashMessages.push(
      I18n.t(
        'devise.registrations.signed_up_but_unconfirmed',
        { email: this.props.getFields(this.props.formName).email }
      ),
      this.props.flashMessages.duration.LONG,
      this.props.flashMessages.levels.INFO
    )
    this.props.history.push('/login')
  }

  renderUnder16Panel () {
    const datePickerFieldName = 'birth_date'
    const guardianEmailFieldName = 'guardian_email'
    const guardianEmailConfirmFieldName = 'guardian_email_confirm'

    const datePickerFormField = {
      component: 'date_picker',
      propKey: 'birth_date',
      onChange: (e) => {
        const birthDate = e.target.value
        const today = startOfDay(new Date())
        const yearsOld = differenceInYears(today, birthDate)
        if (yearsOld < 16) {
          this.setState({ isUnder16: true })
        } else {
          this.setState({ isUnder16: false })
        }
        this.props.handleChange(e, this.props.formName, datePickerFieldName)
      },
      properties: {
        infoBox: I18n.t('signup.birthdate_wont_be_saved')
      }
    }

    const guardianEmailFormField = {
      component: 'field',
      propKey: 'guardian_email',
      properties: {
        type: 'email'
      }
    }

    const guardianEmailConfirmFormField = {
      component: 'field',
      propKey: 'guardian_email',
      properties: {
        type: 'email'
      }
    }

    return (
      <>
        {this.renderSingleField(datePickerFieldName, datePickerFormField)}
        {this.state.isUnder16 && <p>{I18n.t('signup.under16_warning')}</p>}
        {this.state.isUnder16 && this.renderSingleField(guardianEmailFieldName, guardianEmailFormField)}
        {this.state.isUnder16 && this.renderSingleField(guardianEmailConfirmFieldName, guardianEmailConfirmFormField)}
      </>
    )
  }

  renderTermsAndConditionsCheckBox () {
    const termsFormField = {
      component: 'checkbox',
      properties: {
        type: 'checkbox'
      }
    }

    return this.renderSingleField('terms', termsFormField)
  }

  render () {
    return (
      <form>
        {this.renderFields()}
        {this.renderUnder16Panel()}
        {this.renderTermsAndConditionsCheckBox()}
        {this.renderSubmit(`${this.props.formName}.submit`, {
          id: 'signup-button',
          className: 'button-primary background-primary text-background-color waves-effect waves-light'
        })}
        <div className='row'>
          <div className='col s12'>
            <a
              className='button-primary background-background text-primary-color waves-effect waves-light'
              style={{ textAlign: 'center' }}
              href={this.addSearchToPath('/login')}
            >
              {I18n.t('login.link.login')}
            </a>
            <ul>
              <li>
                <Link id='terms-and-conditions' to={Routes.static.termsAndConditionsLatest}>
                  {I18n.t('login.link.terms_and_conditions')}
                </Link>
              </li>
              <li>
                <Link id='privacy-statement' to={Routes.static.privacyStatement}>
                  {I18n.t('login.link.privacy_statement')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </form>
    )
  }
}

export default WithFlashMessages(SignUp)
