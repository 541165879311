import React, { FC, ReactElement } from 'react'
import classNames from 'classnames'
import { getColourClasses, getWidthClasses } from './logic'
import { ButtonColour, ButtonWidth } from './types'

// Using the following interface extension would automatically add several
// default props (onClick, etc). However it would also add a type field, which
// we missuse here. We can use the interface but it requires some refactoring.
// interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
// Also doesn't work because of the onClick function: the typing is different
// when we extend the React.ComponentPropsWithoutRef<'button'>, the typing of
// the onClick function is wrong when we return an <A /> tag here because
// it expects an Anchor Element event instead of a buttonclick event.
interface ButtonProps {
  children: React.ReactNode
  href?: string
  type?: 'primary' | 'secondary'
  buttonPrimitive?: 'a' | 'button'
  autowidth?: boolean
  cy?: string
  onClick?: (e: any) => void
  isDisabled?: boolean
  colour?: ButtonColour
  width?: ButtonWidth
}

const Button: FC<ButtonProps> = ({
  children,
  type,
  href,
  onClick,
  autowidth,
  buttonPrimitive,
  cy,
  width,
  isDisabled = false,
  colour = 'blue'
}): ReactElement => {
  const baseClasses = classNames('text-m text-medium align-center waves-effect waves-light', isDisabled && 'disabled-button')
  const colourClasses = getColourClasses(colour, type === 'primary')
  const widthClass = getWidthClasses(autowidth ?? false, width)
  const clsname = classNames(baseClasses, colourClasses, widthClass)

  if (buttonPrimitive === 'button') {
    return <button className={clsname} onClick={onClick} data-cy={cy}>{children}</button>
  }
  return <a className={clsname} href={href} onClick={onClick} data-cy={cy}>{children}</a>
}
Button.defaultProps = {
  type: 'primary',
  href: undefined,
  onClick: () => {},
  autowidth: false,
  cy: 'button',
  buttonPrimitive: 'a',
  isDisabled: false,
  colour: 'blue'
}

export default Button
