import { useEffect } from 'react'
declare let M: any

const useMaterializeSelect = (triggers: any[] = []): void => {
  useEffect(() => {
    const elems = document.querySelectorAll('select')
    M.FormSelect.init(elems)
    M.updateTextFields()
  }, triggers)
}

export default useMaterializeSelect
