import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { hcOrange, hcBlack } from 'components/common/Constants'
import I18n from 'i18n'
import { initializeHighcharts, titleWithTooltip } from 'components/common/Utils'
import GenericPlaceholder from 'components/common/GenericPlaceholder'

class DailySleepChart extends React.Component {
  constructor (props) {
    super(props)

    initializeHighcharts(true)
    this.state = {}
  }

  render () {
    if (!this.props.dailyLogRows) {
      return (
        <GenericPlaceholder title={I18n.t('components.dashboards.questionnaire.daily_sleep.title')} />
      )
    }

    const scatterData = this.props.dailyLogRows.map(entry => {
      return {
        x: entry.date_only,
        y: entry.sleep_duration_num,
        formatted_date: entry.formatted_date,
        sleep_duration: entry.sleep_duration
      }
    })
    const lineData = this.props.dailyLogRows.map(entry => {
      return {
        x: entry.date_only,
        y: entry.sleep_duration_moving_average,
        formatted_date: entry.formatted_date
      }
    })

    const xAxisOptions = {
      title: {
        text: ''
      },
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%d %b'
      }
    }

    return (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            title: titleWithTooltip(
              I18n.t('components.dashboards.questionnaire.daily_sleep.title'),
              I18n.t('components.dashboards.questionnaire.daily_sleep.info')
            ),
            subtitle: {
              text: I18n.t('components.dashboards.questionnaire.daily_sleep.subtitle')
            },
            plotOptions: {
              scatter: {
                color: hcOrange
              },
              series: {
                maxPointWidth: 1
              },
              line: {
                color: hcBlack,
                marker: {
                  enabled: false
                }
              }
            },
            series: [
              {
                type: 'scatter',
                data: scatterData,
                name: '⬤',
                tooltip: {
                  headerFormat: '',
                  pointFormat: I18n.t('components.dashboards.questionnaire.daily_sleep.scatter.tooltip')
                }
              },
              {
                type: 'line',
                data: lineData,
                lineWidth: 2,
                tooltip: {
                  headerFormat: '',
                  pointFormat: I18n.t('components.dashboards.questionnaire.daily_sleep.line.tooltip')
                }
              }
            ],
            yAxis: [
              {
                title: {
                  text: I18n.t('components.dashboards.questionnaire.daily_sleep.yaxis')
                },
                min: 0,
                opposite: false
              }
            ],
            xAxis: xAxisOptions
          }}
        />
      </>
    )
  }
}

export default DailySleepChart
