import React, { useState, useEffect, useRef } from 'react'
import I18n from 'i18n'
import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import DragMessage from './DragMessage'
import DirectUploader from '../../helpers/DirectUploader'
import { WithSession } from '../../session/SessionProvider'
import { WithModal } from '../../modal/ModalProvider'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import MetaData from '../../metadata/MetaData'
import { asyncForEach, preprocessJupyterNotebook } from '../../common/Utils'
import IntegrationsController from '../../integrations/IntegrationsController'
import BackButton from '../../common/BackButton'
import UploadFilesList from 'components/data/layout/UploadFilesList'

const Uploader = (props) => {
  const [process, setProcess] = useState(0)
  const [showEditMetadata, setShowEditMetadata] = useState(false)
  const [uploadsInProgress, setUploadsInProgress] = useState(0)
  const [pendingFiles, setPendingFiles] = useState([])
  const [pendingFileTags, setPendingFileTags] = useState([])
  const directUploader = useRef(new DirectUploader())

  useEffect(() => {
    const tokenOnly = props.sessionToken.split(' ')[1]

    if (tokenOnly !== undefined) {
      directUploader.current.setSessionToken(tokenOnly)
    }
  }, [props.sessionToken])

  useEffect(() => {
    // Calculate the percentage of completed uploads
    const completedPercentage = (1 - (uploadsInProgress / pendingFiles.length)) * 100

    setProcess(completedPercentage) // progress bar value
  }, [uploadsInProgress])

  const constructObject = (blob, linkedVdoId, tags) => {
    return { data_object: { data_blob: blob.signed_id, linked_vdo_id: linkedVdoId, tags: tags || [] } }
  }

  const reduceInProgressFiles = () => {
    setUploadsInProgress(prev => {
      return prev - 1
    })
  }

  const upload = async (file, tags) => {
    const [blob] = await directUploader.current.uploadFile(file)
    reduceInProgressFiles()

    return new Promise((resolve, reject) => {
      props.onRequest(constructObject(blob, props.linkedVdoId, tags), props.submitMethod,
        props.endpoint, props.formName, props.sessionToken, (response) => {
          resolve(({ data: { ...blob, link: blob.service_url } }))
        })
    })
  }

  const handleUpload = async (_e) => {
    setProcess(0)
    setUploadsInProgress(pendingFiles.length)

    await asyncForEach(pendingFiles, async (file) => {
      if (file instanceof File) {
        await upload(file, pendingFileTags)
      } else {
        console.log('unable to process file')
        props.flashMessages.push(
          I18n.t('components.data.flashmessages.upload_failed'),
          props.flashMessages.duration.SHORT,
          props.flashMessages.levels.WARNING
        )
      }
    })

    // Clear the pending files and tags once uploads are completed
    setPendingFiles([])
    setPendingFileTags([])

    props.refetchSession()
    // Show flash message only if all files have been uploaded
    if (uploadsInProgress === 0) {
      props.flashMessages.push(
        I18n.t('components.data.flashmessages.upload_successful'),
        props.flashMessages.duration.SHORT,
        props.flashMessages.levels.INFO
      )
      props.onUploadComplete && props.onUploadComplete()
    }
  }

  const handleDrop = async (files) => {
    files = await Promise.all(files.map(preprocessJupyterNotebook))
    setPendingFiles(prev => [...prev, ...files])
  }

  const toUploader = () => {
    setShowEditMetadata(false)
  }

  const onHandleBackButton = () => {
    props.hide()
  }

  const renderUploader = () => {
    return (
      <>
        {!props.justDropzone &&
          <div className='row'>
            <div className='col s12'>
              <BackButton onGoBack={onHandleBackButton}>{I18n.t('components.data.back_to_timeline')}</BackButton>
            </div>
          </div>}
        <>
          <div className='row'>
            <div className='col s12'>
              <div className='text-heavy text-primary-color text-l'>
                {I18n.t('components.data.title')}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col s12 m6 mobile-bottom-margin'>
              <div>{props.error}</div>
              <Dropzone onDrop={handleDrop}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div>
                    <div
                      {...getRootProps()}
                      className={classNames('dropzone', 'center-align', 'vertical-center', { 'dropzone-isActive': isDragActive })}
                    >
                      <input type='file' name='dropzone-input' id='dropzone-input' {...getInputProps()} />
                      <DragMessage
                        className='drag-message'
                        isDragActive={isDragActive}
                        dragActiveText={I18n.t('components.data.drag_active_text')}
                        dragInActiveText={I18n.t('components.data.drag_inactive_text')}
                      />
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
            <div className='col s12 m6'>
              <div className='pending-uploads-list'>
                <UploadFilesList pendingFiles={pendingFiles} setPendingFiles={setPendingFiles} handleUpload={handleUpload} pendingFileTags={pendingFileTags} setPendingFileTags={setPendingFileTags} />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col s12'>
              {uploadsInProgress > 0 &&
                <div className='profile-progress-bar'>
                  <span
                    className='profile-progress-bar-fill' style={{
                      width: `${process}%`
                    }}
                  />
                </div>}
            </div>
          </div>
        </>
        {!props.justDropzone &&
          <div>
            <div className='row'>
              <div className='col s12'>
                <div className='text-heavy text-primary-color text-l'>
                  {I18n.t('components.data.or_connect_with_fav_device')}
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col s12'>
                <IntegrationsController />
              </div>
            </div>
          </div>}
      </>
    )
  }

  const renderMetaData = () => {
    return (
      <>
        <div className='row'>
          <div className='col s12'>
            <BackButton onGoBack={toUploader}>{I18n.t('components.data.back_to_uploads')}</BackButton>
          </div>
        </div>
        <MetaData {...props} vdoId={showEditMetadata} handleSuccess={toUploader} handleCancel={toUploader} />
      </>
    )
  }

  return (
    <>
      {(showEditMetadata && renderMetaData()) || renderUploader()}
    </>
  )
}

export default WithFlashMessages(WithSession(WithModal(Uploader)))
