import { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { useFetchMultipleData } from '../../../common/Hooks'
import { collectionSubTypes, AFTERNOON_START, EVENING_START } from '../../../common/Constants'
import moment from 'moment'
import { LOAD_TYPES } from './components/TrainingLoadChart'
import { INTENSITY_TYPES } from './components/TrainingIntensityChart'

/*
 * Flattens an array of sports data  into a single one
 * @param sportsData Array where each element is the data of a response from the 'dashboards/multi_activity' entry point
 * @param sportsName The corresponding sport for each element in sportsData
 */
export function useTrainingLoadDataFromMultipleSports (sportsData, sportsName) {
  return useMemo(() => (
    sportsData.reduce((acc, sportData, sportIdx) => {
      const trainingLoad = _.get(sportData, 'training_load')
      // Replace collection_subtype with sport name so we can use it as a unique category in the chart
      trainingLoad.forEach((_week, idx) => {
        _.set(trainingLoad, `[${idx}]._id.collection_subtype`, sportsName[sportIdx])
      })
      return acc.concat(trainingLoad)
    }, []).sort((a, b) => {
      const dateA = new Date(_.get(a, '_id.date', 0))
      const dateB = new Date(_.get(b, '_id.date', 0))
      return dateA - dateB
    })
  ), [sportsData])
}

/*
 * Sums the training intensities per week from that come from different sports
 * @param sportsData Array where each element is the data of a response from the 'dashboards/multi_activity' entry point
 */
export function useTrainingIntensityDataFromMultipleSports (sportsData) {
  return useMemo(() => {
    const weeks = sportsData.map(sport => sport.weekly_zones).flat().reduce((weeks, week) => {
      // A week is a weekly entry in the weekly_zones array. For triathlon we could potentially have 3 weekly entries
      // per actual week of the year (one per sport), which we need to add week-&-zone-wise
      if (week._id in weeks) {
        // There can be 2 cases: existing week has the correct time series zone (e.g. hr);
        // or it doesn't (e.g. the first week found was from running and the current week is from cycling which also
        // has power data
        const existingWeek = weeks[week._id]
        const tsZones = _.omit(week, '_id')
        Object.entries(tsZones).forEach(([tsKey, tsZone]) => {
          if (tsKey in existingWeek) {
            existingWeek[tsKey] = existingWeek[tsKey].map((z, zIdx) => z + tsZone[zIdx])
          } else {
            existingWeek[tsKey] = tsZone
          }
        })
      } else {
        weeks[week._id] = week
      }
      return weeks
    }, {})

    const sortedKeys = Object.keys(weeks).sort((a, b) => new Date(a) - new Date(b))
    return sortedKeys.map(key => weeks[key])
  }, [sportsData])
}

export function getMultiActivityDashboardDescriptiveStatistics (seasonActivities) {
  let [outdoorCount, indoorCount, morningCount, afternoonCount, eveningCount, totalTime] = [0, 0, 0, 0, 0, 0]
  seasonActivities?.forEach(activity => {
    activity.collection_subtype === collectionSubTypes.GENERIC_SUBTYPE ? outdoorCount += 1 : indoorCount += 1
    const hours = (new Date(activity.start_date)).getHours()
    if (hours < AFTERNOON_START) morningCount += 1
    else if (hours < EVENING_START) afternoonCount += 1
    else eveningCount += 1
    totalTime += activity.duration
  })
  return { outdoorCount, indoorCount, morningCount, afternoonCount, eveningCount, totalTime }
}

export function useDashboardDataFromMultipleCollectionTypes (collectionTypes, params) {
  const urls = collectionTypes.map(_ct => '/api/v1/dashboards/multi_activity')
  const paramsArray = collectionTypes.map(ct => ({ ...params, collection_type: ct }))
  return useFetchMultipleData(urls, paramsArray)
}

export function useGeolocationDataFromMultipleCollectionTypes (collectionTypes, params) {
  const [flatData, setFlatData] = useState([])

  const urls = collectionTypes.map(_ct => '/api/v1/dashboards/geolocation')
  const paramsArray = collectionTypes.map(ct => ({ ...params, collection_type: ct }))

  const { data, errors, fetched, reFetch } = useFetchMultipleData(urls, paramsArray)

  useEffect(() => {
    setFlatData(_.flatten(data))
  }, [fetched])

  return { data: flatData, errors: errors, fetched: fetched, reFetch }
}

function getDatePath (metricTypes) {
  switch (metricTypes) {
    case LOAD_TYPES: return '_id.date'
    case INTENSITY_TYPES: return '_id'
  }
}

export function addEmptyRecords (dataArray, startDate, endDate, periodType, metricTypes, defaultSubType = collectionSubTypes.GENERIC_SUBTYPE) {
  if (!dataArray) { return [] }

  const datePath = getDatePath(metricTypes)
  let currentActivityIndex = 0
  const dataWithEmptyRecords = []
  // We force dutch locale to ensure weeks start on Monday, which is the server convention
  for (let period = moment(startDate).locale('nl'); period.isSameOrBefore(endDate, periodType); period.add(1, periodType)) {
    let activity = dataArray[currentActivityIndex]
    let activityDate = _.get(activity, datePath, null)

    if (period.isSame(activityDate, periodType)) { // If there is an activity registered in this period
      // There may be more than one activity in the same period for different collection_subtype
      while (period.isSame(activityDate, periodType)) {
        dataWithEmptyRecords.push(activity)
        currentActivityIndex += 1
        activity = dataArray[currentActivityIndex]
        activityDate = _.get(activity, datePath, null)
      }
    } else { // If there were no activities registered in this period
      dataWithEmptyRecords.push(createEmptyActivity(period, metricTypes, defaultSubType))
    }
  }

  return dataWithEmptyRecords
}

export function getCountSelectedActivities (seasons) {
  const selected = _.get(seasons, 'current.activity_count', 0)
  const total = _.get(seasons, 'current.total_activity_count', 0)
  return { selectedActivities: selected, notSelectedActivities: total - selected }
}

function createEmptyActivity (date, metricTypes, defaultSubType) {
  let emptyDay = {}
  switch (metricTypes) {
    case LOAD_TYPES:
      emptyDay = {
        _id: {
          collection_subtype: defaultSubType,
          date: date.toISOString()
        }
      }
      emptyDay = Object.assign(emptyDay, ...metricTypes.map((metricType) => ({ [metricType]: 0 })))
      break
    case INTENSITY_TYPES:
      emptyDay = {
        _id: date.toISOString()
      }
      emptyDay = Object.assign(emptyDay, ...metricTypes.map((metricType) => ({ [metricType]: [] })))
      break
  }
  return emptyDay
}
