import React, { useMemo } from 'react'
import Spinner from '../../../common/Spinner'
import { getZoneCount } from '../../../common/Math'
import I18n from 'i18n'
import { interpolateColors } from '../../../common/Utils'
import Variables from '../../../../../javascript/stylesheets/variables.module.scss'
import Rings from './Rings'
import { timeSeries as timeSeriesKeys, timeSeriesKeyToZonesKey } from '../../../common/Constants'
import _ from 'lodash'
const { primaryColor, primaryColorLight, secondaryColor, secondaryColorLight, accentColorDark, accentColorLight } = Variables

// Sensible defaults until cadence is supported in the backend
const defaultCadenceZoneLimits = [50, 66, 80, 95, 105, Infinity]

const ZoneRings = props => {
  const { timeSeries, physAttrs } = props
  if (!timeSeries) return <Spinner ready={false} />

  const { hr = [], cadence = [], power = [] } = timeSeries.time_series

  const commonOptions = {
    title: {
      display: true
    },
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const dataset = data.datasets[tooltipItem.datasetIndex]
          let label = data.labels[tooltipItem.index]
          label += `: ${Math.round(dataset.data[tooltipItem.index])}%`
          return label
        }
      }
    },
    plugins: {
      crosshair: false
    }
  }

  function limitsToLabels (limits) {
    return limits.reduce((labels, lower, idx, arr) => {
      const upper = arr[idx + 1] === Infinity ? '∞' : arr[idx + 1]
      if (upper !== undefined) labels.push(`${lower}-${upper}`)

      return labels
    }, [])
  }

  function generateDataset (data, limits, colorRange) {
    return {
      datasets: [{
        data: getZoneCount(limits, data).map(z => z / data.length * 100),
        backgroundColor: interpolateColors(colorRange[0], colorRange[1], limits.length)
      }],
      labels: limitsToLabels(limits)
    }
  }

  function getZoneLimitForSerie (physAttrs, timeSeriesKey) {
    if (!physAttrs) return []

    const attr = physAttrs.find(attr => _.get(attr, 'variable') === timeSeriesKeyToZonesKey[timeSeriesKey])
    return _.get(attr, 'values')
  }

  const [hrZoneLimits, powerZoneLimits, cadenceZoneLimits] = useMemo(() => {
    const hrLimits = getZoneLimitForSerie(physAttrs, timeSeriesKeys.HR)
    const powerLimits = getZoneLimitForSerie(physAttrs, timeSeriesKeys.POWER)
    return [hrLimits, powerLimits, defaultCadenceZoneLimits]
  }, [physAttrs])

  const cadenceData = useMemo(() => generateDataset(cadence, cadenceZoneLimits, [secondaryColor, secondaryColorLight]), [timeSeries, cadenceZoneLimits])
  const powerData = useMemo(() => generateDataset(power, powerZoneLimits, [primaryColor, primaryColorLight]), [timeSeries, powerZoneLimits])
  const hrData = useMemo(() => generateDataset(hr, hrZoneLimits, [accentColorDark, accentColorLight]), [timeSeries, hrZoneLimits])

  const cadenceOptions = { ...commonOptions, title: { ...commonOptions.title, text: I18n.t('components.dashboards.zone_rings.cadence') } }
  const powerOptions = { ...commonOptions, title: { ...commonOptions.title, text: I18n.t('components.dashboards.zone_rings.power') } }
  const hrOptions = { ...commonOptions, title: { ...commonOptions.title, text: I18n.t('components.dashboards.zone_rings.hr') } }

  const ringConfigs = [
    { data: cadenceData, options: cadenceOptions },
    { data: powerData, options: powerOptions },
    { data: hrData, options: hrOptions }
  ]

  return (
    <Rings ringConfigs={ringConfigs} translatedUp />
  )
}

export default ZoneRings
