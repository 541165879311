import React from 'react'
import I18n from 'i18n'

/* global M */
export default class SelectMultiple extends React.Component {
  componentDidMount () {
    this.initSelect()
  }

  componentDidUpdate (prevProps) {
    /* reinit materialize select if API returned new set of choices or set of default values */
    const initDefaultValue = !(
      this.props.defaultValue &&
      prevProps.defaultValue &&
      prevProps.defaultValue.size === this.props.defaultValue.size &&
      [...prevProps.defaultValue].every(value => this.props.defaultValue.includes(value))
    )

    const initChoices = !(
      this.props.choices &&
      prevProps.choices &&
      Object.keys(prevProps.choices).length === Object.keys(this.props.choices).length &&
      Object.keys(prevProps.choices).every(value => value in this.props.choices)
    )

    if (initChoices || (this.props.defaultValue && initDefaultValue)) {
      this.initSelect()
    }
  }

  initSelect () {
    const elems = document.getElementById(this.props.id)
    let options = {}
    if (this.props.selectAll && this.props.selectNone) {
      options = {
        dropdownOptions: {
          container: document.getElementById(`${this.props.id}-buttons`),
          onOpenStart: () => { document.getElementById(`${this.props.id}-buttons`).style.display = 'block' },
          onCloseEnd: () => { document.getElementById(`${this.props.id}-buttons`).style.display = 'none' }
        }
      }
    }
    M.FormSelect.init(elems, options)
  }

  render () {
    const errors = this.props.errors || []
    const { component, type, fieldName, choices, formName, defaultValue, currentValue, noWrapper, noTranslate, selectAll, selectNone, cy } = this.props
    const fieldProps = { ...this.props }
    delete fieldProps.component
    delete fieldProps.fieldName
    delete fieldProps.choices
    delete fieldProps.translateErrors
    delete fieldProps.formName
    delete fieldProps.defaultValue
    delete fieldProps.currentValue
    delete fieldProps.type
    delete fieldProps.noWrapper
    delete fieldProps.noTranslate
    delete fieldProps.selectAll
    delete fieldProps.selectNone
    delete fieldProps.cy

    /* valueProp should be an empty object if there is no current and no default value.
     * This prevents the value from from being given to an empty field when its used
     * together with the spread operator (...) */
    const valueProp = {}
    if (currentValue) {
      valueProp.value = currentValue
    } else if (defaultValue) {
      valueProp.value = defaultValue
    }
    const result = (
      <div className='input-field' data-cy={cy || 'select-multiple'}>
        {errors.map((err, i) => (
          <div key={i}>
            {this.props.translateErrors ? I18n.t(err) : err}
          </div>
        ))}
        <select multiple {...fieldProps} onChange={e => this.props.onChange(M.FormSelect.getInstance(e.target))} {...valueProp}>
          {choices && !(typeof choices === 'string') && choices.map((choice, i) => (
            <option key={i} {...choice.properties}>
              {noTranslate && choice.properties.name}
              {!noTranslate && I18n.t(`${component}.${type}.${choice.properties.name}`, { defaults: [{ scope: choice.properties.name }] })}
            </option>
          ))}
        </select>
        <label className='colored'>
          <span>{I18n.t(`${formName}.${component}.${type}.${fieldName}`)}</span>
        </label>
        {selectAll && selectNone && (
          <div className='select-buttons-wrapper' id={`${this.props.id}-buttons`}>
            <div className='select-buttons dropdown-content'>
              <a
                className='select-button pointer text-s'
                onClick={e => {
                  e.preventDefault()
                  selectAll()
                  setTimeout(() => M.FormSelect.getInstance(document.getElementById(this.props.id))._handleInputClick(), 1)
                }}
              >
                {I18n.t('components.dashboards.questionnaire.select_all')}
              </a>
              <a
                className='select-button pointer text-s'
                onClick={e => {
                  e.preventDefault()
                  selectNone()
                  setTimeout(() => M.FormSelect.getInstance(document.getElementById(this.props.id))._handleInputClick(), 1)
                }}
              >
                {I18n.t('components.dashboards.questionnaire.deselect_all')}
              </a>
            </div>
          </div>
        )}
      </div>
    )
    if (noWrapper) {
      return result
    }
    return (
      <div className='row'>
        <div className='col s12'>
          {result}
        </div>
      </div>
    )
  }
}
