import React from 'react'
import { Route } from 'react-router'
import NotFoundWrapper from '../router/NotFoundWrapper'
import AdminDashboard from './AdminDashboard'
import Routes from '../common/Routes'

export default class AdminRoutes extends React.Component {
  render () {
    return (
      <NotFoundWrapper slug='admin'>
        <Route exact path={Routes.admin.index} render={props => (<AdminDashboard {...props} />)} />
      </NotFoundWrapper>
    )
  }
}
