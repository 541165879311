import React, { useState, useEffect } from 'react'
import I18n from 'i18n'
import Group from '../../../common/types/Group'
import { useGroupOverviewQuery } from '../../../backend/Queries'
import SpinnerWrapper from '../../../common/SpinnerWrapper'
import GroupOverviewTable from './GroupOverviewTable'
import { datePickerDefaultOptions } from '../../../common/Constants'
import classNames from 'classnames'
import ReactDatePicker from 'react-datepicker'

import moment from 'moment'
import GroupOverviewGraph from './GroupOverviewGraph'

interface Props {
  group: Group
}

const GroupOverview: React.FunctionComponent<Props> = (props: Props) => {
  const [start, setStart] = useState<Date | null>(moment().subtract(2, 'weeks').startOf('day').toDate())
  const [end, setEnd] = useState<Date | null>(moment().endOf('day').toDate())

  const { data: groupOverview = { by_member: [], by_date: { labels: [], data: { protocols: [], protocols_total: [], sport_data: [], unstructured: [], total: [] } } }, refetch, isSuccess, isError } = useGroupOverviewQuery(props.group.id, start, end)

  useEffect(() => {
    // TODO: don't ignore errors for refetch
    void refetch()
  }, [start, end])

  return (
    <>
      <div className='row'>
        <div className='col s6 m3 input-field'>
          {/* Webpacker complained on adding locale here */}
          <ReactDatePicker
            id='start' {...datePickerDefaultOptions()}
            selected={start}
            onChange={(date: Date | null) => setStart((date != null) ? moment(date).startOf('day').toDate() : date)}
            selectsStart
            startDate={start}
            endDate={end}
            openToDate={start ?? undefined}
            isClearable
          />
          <label
            htmlFor='start'
            className={classNames({ active: start })}
          >{I18n.t('components.dashboards.multi_activity.start_date')}
          </label>
        </div>
        <div className='col s6 m3 input-field'>
          {/* Webpacker complained on adding locale here */}
          <ReactDatePicker
            id='end' {...datePickerDefaultOptions()}
            selected={end}
            onChange={(date: Date | null) => setEnd((date != null) ? moment(date).endOf('day').toDate() : date)}
            selectsEnd
            startDate={start}
            endDate={end}
            minDate={start}
            openToDate={end ?? undefined}
            isClearable
          />
          <label
            htmlFor='end'
            className={classNames({ active: end })}
          >{I18n.t('components.dashboards.multi_activity.end_date')}
          </label>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <SpinnerWrapper ready={isSuccess} failed={isError} transparent>
            <GroupOverviewTable groupOverview={groupOverview} />
          </SpinnerWrapper>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <SpinnerWrapper ready={isSuccess} failed={isError} transparent>
            <GroupOverviewGraph groupOverview={groupOverview} />
          </SpinnerWrapper>
        </div>
      </div>
    </>
  )
}

export default GroupOverview
