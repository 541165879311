import React from 'react'
import I18n from 'i18n'
import { get } from 'lodash'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

interface BaseField {
  readonly kind: string // Required for the discriminated union (check TS docs)
  readonly name: string
}

interface FieldWithPath extends BaseField {
  readonly kind: 'path'
  // path within an entry to acces the value to be rendered (used by lodash get)
  readonly path: string
}

interface FieldWithFn extends BaseField {
  readonly kind: 'fn'
  // function that takes an entry and returns the string to be rendered
  readonly fn: (entry: Object) => string | number
}

interface FieldWithValues extends BaseField {
  readonly kind: 'values'
  // array of values to be rendered for the field for each entry
  readonly values?: string[] | number[]
}

type Field = FieldWithPath | FieldWithFn | FieldWithValues

interface Props {
  entries: Object[]
  fields: Field[] // Fields to render per entry
  onClick?: (idx: number) => any
  nDigits?: number // number of decimal digits to display
  selectedIdx?: number // Index of the selected entry
  onClearSelection?: () => any // Callback when the selection clear button is clicked
}

// When we have FieldsWithPath, then Value objects might be anywhere in the object tree,
// so it cannot be added in the Props interface
interface Value {
  value: string | number
  units: string
}

const Table = (props: Props): React.ReactElement => {
  const { entries, fields, onClick = () => {}, nDigits = 1, selectedIdx, onClearSelection = () => {} } = props
  if (entries == null) return <></>

  function renderByPath (field: FieldWithPath, entry: Object): React.ReactElement {
    const value: Value | null = get(entry, field.path, null)

    if (value == null) return <td key={field.name}>--</td>

    const valueDisplayed = (typeof value.value === 'number') ? value.value.toFixed(nDigits) : value.value
    return (
      <td key={field.name}>{valueDisplayed} {I18n.t(value.units, { defaults: [{ message: '' }] })}</td>
    )
  }

  function renderByFunction (field: FieldWithFn, entry: Object): React.ReactElement {
    return (
      <td key={field.name}>{field.fn(entry)}</td>
    )
  }

  function renderByValue (field: FieldWithValues, idx: number): React.ReactElement {
    if (field.values == null) {
      return <td key={field.name}>--</td>
    }
    return <td key={field.name}>{field.values[idx]}</td>
  }

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <Link to='#' onClick={onClearSelection} replace>{I18n.t('molecules.table.clear')}</Link>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 smaller-font'>
          <div className='atomic-table'>
            <table className='responsive-table striped'>
              <thead>
                <tr>
                  {fields.map((f: Field) => (
                    <th key={f.name}>{I18n.t(f.name)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {
                  entries.map((entry: Object, idx: number) => (
                    <tr className={classNames('entry', 'pointer', { 'entry-selected': idx === selectedIdx })} key={idx} onClick={() => onClick(idx)}>
                      {
                        fields.map((field: Field, fIdx: number) => {
                          if (field.kind === 'path') return renderByPath(field, entry)
                          if (field.kind === 'fn') return renderByFunction(field, entry)
                          if (field.kind === 'values') return renderByValue(field, idx)
                          return <td key={`${idx}-${fIdx}`}>--</td>
                        })
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default Table
