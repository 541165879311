import React from 'react'
import { WithSession } from '../session/SessionProvider'
import GroupJoinRoutes from './GroupJoinRoutes'
import { withCookies } from 'react-cookie'
import { MyAxios as axios } from '../MyAxios'
import { WithFlashMessages } from '../flashmessages/FlashMessageProvider'
import I18n from 'i18n'
import Routes from '../common/Routes'
import { withRouter } from 'react-router-dom'

class GroupJoin extends React.Component {
  constructor (props) {
    super(props)
    this.withoutSessionJoin = this.withoutSessionJoin.bind(this)
    this.withSessionJoin = this.withSessionJoin.bind(this)
    this.flashError = this.flashError.bind(this)
  }

  flashError () {
    this.props.flashMessages.push(
      I18n.t(
        'group.joined.failure'
      ),
      this.props.flashMessages.duration.LONG,
      this.props.flashMessages.levels.WARNING
    )
  }

  withoutSessionJoin (code) {}

  withSessionJoin (code) {
    axios({
      method: 'POST',
      url: '/api/v1/group_memberships',
      headers: {
        Authorization: this.props.sessionToken
      },
      data: {
        group: {
          code: code
        }
      }
    }).then((response) => {
      const membership = response.data.result && response.data.result[0].instance
      if (response.status === 201 && membership) {
        const gid = membership.group_id
        const mid = membership.id

        this.props.history.push(Routes.network.groups.joinFn(gid, mid))
      } else if (response.status === 204) {
        this.props.flashMessages.push(
          I18n.t(
            'group.joined.already_accepted'
          ),
          this.props.flashMessages.duration.LONG,
          this.props.flashMessages.levels.WARNING
        )
      }
    }).catch((e) => {
      console.log(e)
      this.flashError()
    })
  }

  join (code) {
    // Also store the code in a cookie, so we can retrieve it after we've
    // come back from surfconext.
    this.props.cookies.set('group_join_code', code,
      {
        path: '/',
        maxAge: 5 * 60,
        domain: process.env.SESSION_COOKIE_HOSTNAME
      }
    )
    if (this.props.sessionToken) {
      return this.withSessionJoin(code)
    }
    return this.withoutSessionJoin(code)
  }

  render () {
    return (
      <GroupJoinRoutes
        groupJoinControllerProps={{
          join: this.join.bind(this)
        }}
      />
    )
  }
}

export default withRouter(withCookies(WithFlashMessages(WithSession(GroupJoin))))
