import React, { useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { hcOrange, hcGreen, hcRed, hcBlack } from 'components/common/Constants'
import I18n from 'i18n'
import { initializeHighcharts, titleWithTooltip } from 'components/common/Utils'
import GenericPlaceholder from '../../../../common/GenericPlaceholder'
import { calculateWeeklyAverages } from '../Utils'
import { DailyLogRow } from 'components/common/types/questionnaires/DailyLogRow'
import _ from 'lodash'

interface DailyReadinessToTrainProps {
  dailyLogRows?: DailyLogRow[]
}

const DailyReadinessToTrainBar: React.FC<DailyReadinessToTrainProps> = ({ dailyLogRows }) => {
  useEffect(() => {
    initializeHighcharts(true)
  }, [])

  if (_.isEmpty(dailyLogRows) || !Array.isArray(dailyLogRows)) {
    return (
      <GenericPlaceholder
        title={I18n.t('components.dashboards.questionnaire.daily_readiness_to_train.title')}
        message={I18n.t('components.dashboards.generic.not_enough_data')}
      />
    )
  }

  const calculateColor = (readinessToTrain: number): string => {
    if (readinessToTrain <= 2) return hcRed
    if (readinessToTrain > 2 && readinessToTrain < 4) return hcOrange
    if (readinessToTrain >= 4) return hcGreen
    return hcBlack
  }

  const readinessToTrainData = dailyLogRows.map(entry => {
    return {
      x: entry.date_only,
      y: entry.readiness_to_train,
      color: calculateColor(entry.readiness_to_train),
      formatted_date: entry.formatted_date,
      wellness_sum_p: entry.wellness_sum_p,
      sleep_quality: entry.wellness_sleep,
      sleep_duration: entry.sleep_duration,
      weight: _.isNil(entry.weight) ? '-' : `${entry.weight} kg`
    }
  })

  const xAxisOptions = {
    title: {
      text: ''
    },
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%d %b'
    },
    minPadding: 0.1,
    maxPadding: 0.1
  }

  const averageReadinessData = calculateWeeklyAverages(readinessToTrainData)

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          title: titleWithTooltip(
            I18n.t('components.dashboards.questionnaire.weekly_readiness_to_train.title'),
            I18n.t('components.dashboards.questionnaire.weekly_readiness_to_train.info')
          ),
          series: [
            {
              type: 'column',
              data: readinessToTrainData,
              name: '⬤',
              tooltip: {
                headerFormat: '',
                pointFormat: I18n.t('components.dashboards.questionnaire.daily_readiness_to_train.scatter.short_tooltip')
              }
            },
            {
              type: 'line',
              data: averageReadinessData,
              name: '⬤',
              color: hcBlack,
              marker: {
                enabled: false,
                states: {
                  hover: {
                    enabled: true
                  }
                }
              },
              tooltip: {
                headerFormat: '',
                pointFormat: I18n.t('components.dashboards.questionnaire.daily_readiness_to_train.average.tooltip')
              },
              lineWidth: 2
            }
          ],
          plotOptions: {
            line: {
              color: hcBlack,
              marker: {
                enabled: false
              }
            }
          },
          yAxis: {
            title: {
              text: I18n.t('components.dashboards.questionnaire.daily_readiness_to_train.yaxis')
            },
            min: 0,
            max: 5.1
          },
          xAxis: xAxisOptions
        }}
      />
    </>
  )
}

export default DailyReadinessToTrainBar
