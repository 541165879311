import React from 'react'
import { smartTranslate } from '../../../common/Utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AccessToggle from './AccessToggle'
import I18n from 'i18n'
import SharingIcon from './SharingIcon'

function SharingTableRow ({ item, handleUpdate, handleDelete, dataOfThisGroup }) {
  return (
    <tr>
      <td className='onboarding-data-sharing-c1'>
        <SharingIcon item={item} />
        {!dataOfThisGroup && <span className='margin-left'>{smartTranslate(item.obj.name)}</span>}
        {dataOfThisGroup && <span className='margin-left'>{I18n.t('datasharing.data_of_this_group')}</span>}
      </td>
      <td className='onboarding-data-sharing-c2'>
        <AccessToggle item={item} handleUpdate={handleUpdate} />
      </td>
      <td className='onboarding-data-sharing-c3'>
        <span className='sharing-rule-button'>
          <FontAwesomeIcon
            icon='trash' size='1x' className='pointer-grow' onClick={(e) => {
              e.preventDefault()
              handleDelete(item)
            }}
          />
        </span>
      </td>
    </tr>
  )
}

export default SharingTableRow
