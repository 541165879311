import React from 'react'
import { Link } from 'react-router-dom'
import I18n from 'i18n'
import PropTypes from 'prop-types'
import { WithSession } from '../../../session/SessionProvider'
import Routes from '../../../common/Routes'

const LinkToMyPhysAttrs = () => (
  <Link to={{
    pathname: Routes.profiles.edit,
    state: { activeIndex: 3 }
  }}
  >
    {I18n.t('components.dashboards.adjust_physiological_measures_link')}
  </Link>
)

// For now this is just static test. In the future we might want to link to the others person's phys attrs in readonly
// mode
const LinkToOthersPhysAttrs = ({ name }) => (
  <>
    {I18n.t('components.dashboards.someone_elses_phys_attrs', { other_profile: name })}
  </>
)

const PhysAttrsLink = ({ slug, name, myProfile }) => {
  if (myProfile?.slug === slug || slug === undefined) {
    return <LinkToMyPhysAttrs />
  } else {
    return <LinkToOthersPhysAttrs name={name} />
  }
}

PhysAttrsLink.propTypes = {
  // The slug of the user for which we are linking the phys attrs
  slug: PropTypes.string,
  name: PropTypes.string
}

export default WithSession(PhysAttrsLink)
