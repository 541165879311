import React from 'react'
import PropTypes from 'prop-types'
import Summary from './Summary'

const CsvSummary = ({ summary, metadatum, icon }) => {
  const { n_rows: nRows, n_cols: nCols } = summary
  return <Summary metadatum={metadatum} icon={icon} fields={{ nRows, nCols }} />
}

CsvSummary.propTypes = {
  // Summary as processed by useSummaryStringUnits
  summary: PropTypes.object,
  metadatum: PropTypes.object
}

export default CsvSummary
