import React from 'react'
import simpleheat from 'simpleheat'
import PropTypes from 'prop-types'
import _ from 'lodash'

class HeatmapGrid {
  constructor (ncols, nrows, canvasWidth, canvasHeight) {
    this.cols = ncols
    this.rows = nrows
    this.colSize = Math.floor(canvasWidth / ncols)
    this.rowSize = Math.floor(canvasHeight / nrows)
  }

  toCanvas (pointCol, pointRow, pointValue) {
    const withinBoundaries = pointCol >= 0 && pointCol < this.cols && pointRow >= 0 && pointRow < this.rows
    if (withinBoundaries) {
      const tileXCenter = (pointCol * this.colSize) + Math.floor(this.colSize / 2)
      const tileYCenter = (pointRow * this.rowSize) + Math.floor(this.rowSize / 2)
      return [tileXCenter, tileYCenter, pointValue]
    } else {
      return null
    }
  }
}

export default class Heatmap extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      canvasWidth: null,
      canvasHeight: null,
      heatmap: null
    }
  }

  getGridSize (data) {
    if (!data || !data[0]) {
      return { cols: 0, rows: 0 }
    }
    const nCols = data[0].length
    const nRows = data.length
    return { cols: nCols, rows: nRows }
  }

  getMaxDatum (data) {
    const maxRow = _.map(data, row => _.max(row))
    return _.max(maxRow)
  }

  parseData (data, grid) {
    const parsedData = []
    if (!data || !data[0]) {
      return ([])
    }

    for (let row = 0; row < grid.rows; row++) {
      for (let col = 0; col < grid.cols; col++) {
        parsedData.push(grid.toCanvas(col, row, data[row][col]))
      }
    }
    return parsedData
  }

  loadCanvasDimensions ({ target: image }) {
    const canvas = document.getElementById(this.props.id).getElementsByClassName('heatmap-canvas')[0]
    if (canvas) {
      canvas.width = image.width
      canvas.height = image.height
      this.setState({
        canvasWidth: canvas.width,
        canvasHeight: canvas.height,
        heatmap: simpleheat(canvas)
      })
    }
  }

  initializeCanvas () {
    const canvas = document.getElementById(this.props.id).getElementsByClassName('heatmap-canvas')[0]
    const canvasStyle = window.getComputedStyle(canvas)

    if (canvasStyle.backgroundImage && canvasStyle.backgroundImage !== '') {
      const originalImage = new Image()
      originalImage.src = canvasStyle.backgroundImage.slice(4, -1).replace(/"/g, '')
      originalImage.onload = this.loadCanvasDimensions.bind(this)
    } else {
      canvas.height = canvas.width
      this.setState({
        canvasHeight: canvas.height,
        canvasWidth: canvas.width,
        heatmap: simpleheat(canvas)
      })
    }
  }

  componentDidMount () {
    this.initializeCanvas()
  }

  render () {
    if (this.state.heatmap) {
      this.state.heatmap.clear()

      if (this.props.data && this.props.data !== []) {
        const gridSize = this.getGridSize(this.props.data)
        const grid = new HeatmapGrid(gridSize.cols, gridSize.rows, this.state.canvasWidth, this.state.canvasHeight)

        const parsedData = this.parseData(this.props.data, grid)
        this.state.heatmap.data(parsedData)

        const max = this.getMaxDatum(this.props.data)
        this.state.heatmap.max(max)

        this.state.heatmap.radius(this.props.pointRadius, this.props.blurRadius)
      }
      this.state.heatmap.draw()
    }

    return (
      <div className='heatmap-div' id={this.props.id}>
        <canvas className='heatmap-canvas' />
      </div>
    )
  }
}

Heatmap.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
  backgroundImage: PropTypes.string,
  pointRadius: PropTypes.number,
  blurRadius: PropTypes.number
}

Heatmap.defaultProps = {
  pointRadius: 25,
  blurRadius: 15
}
