import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import Notification from './layout/Notification'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WithSession } from '../session/SessionProvider'
import I18n from 'i18n'
import { WithQuestionnaires } from '../questionnaires/QuestionnaireProvider'
import { WithModal } from '../modal/ModalProvider'
import NotificationWindow from '../atomic/organisms/NotificationWindow'
import { WithBackend } from '../backend/BackendProvider'
import { useNotificationUtils, useUnreadNotifications } from './hooks/notificationHooks'
import RowLink from './layout/RowLink'

const REFRESH_INTERVAL = 60000 // 1 minute

// show at most 5 notifications, or 4 notifications and a "and x more unread" item
const MAX_NOTIFICATION_ITEMS = 5

const handleError = (err) => {
  console.log(err)
}

const Notifications = (props) => {
  const [showNotifications, setShowNotifications] = useState(false)

  const hideNotifications = (e) => {
    if (!e.target.closest('.notifications-wrapper') &&
        !e.target.closest('.notifications-nav-button')) {
      setShowNotifications(false)
    }
  }
  // TODO: Right now this listens for every click on the page,
  //       is there a way to make it work so that it only checks for page clicks
  //       if the notifications window is open?
  useEffect(() => {
    document.addEventListener('click', hideNotifications)
    document.addEventListener('touchstart', hideNotifications)
    return () => {
      document.removeEventListener('click', hideNotifications)
      document.removeEventListener('touchstart', hideNotifications)
    }
  }, [])

  // note that useUnreadNotifications uses the paginated endpoint, which has a
  // default page size of 30. If the MAX_NOTIFICATION_ITEMS exceeds this number
  // (30) then funny things happen where it tells the user that there are hidden notifications
  const { notifications: pagedUnreadNotifications, notificationsNotOnPage } = useUnreadNotifications({ refetchInterval: REFRESH_INTERVAL })
  const { markNotificationAsRead, markAllNotificationsAsRead } = useNotificationUtils()

  // we show at most MAX_NOTIFICATION_ITEMS items, and if we exceed this number
  // of notifications we replace the last notification slot with a "and ..
  // other unread notifications"

  const notifications = pagedUnreadNotifications.length > MAX_NOTIFICATION_ITEMS
    ? pagedUnreadNotifications.slice(0, MAX_NOTIFICATION_ITEMS - 1)
    : pagedUnreadNotifications

  // calculating the number of notifications that we are not displaying is extremely tricky due to the compression of
  // notifications. each notification object can represent more notifications with identical titles (see
  // ./hooks/notificationHooks -> groupByMultiProps) these grouped notifications contain a groupId which is an array of
  // original notification IDs. the number of notifications that is contained in one single notification can be derived
  // from the length of this array. If this attribute does not exist, it's a single notification.

  // cutOffNotifications are the notifications objects that have been sliced off
  const cutOffNotifications = pagedUnreadNotifications.length > MAX_NOTIFICATION_ITEMS
    ? pagedUnreadNotifications.slice(MAX_NOTIFICATION_ITEMS - 1)
    : []

  // the total number of notifications contained in cutOffNotifications (when compressed notifications are expanded)
  const ungroupedHiddenNotificationCount = cutOffNotifications
    .map(n => 'groupId' in n ? n.groupId.length : 1) // find number of notifications per notification object
    .reduce((curr, accum) => curr + accum, 0) // sum

  // the total amount of notifications that are not in the popup are the number of notifications not on the page, plus
  // the number of notifications we sliced off
  const hiddenNotifications = notificationsNotOnPage + ungroupedHiddenNotificationCount

  const viewNotification = (ids) => {
    setShowNotifications(false)
    props.closeNotificationMenu()
  }

  /*
   * open a modal to show all notifications
   */
  const handleNotificationModal = () => {
    props.setModalComponent(NotificationWindow, {})
    props.closeNotificationMenu()
  }

  const handleMarkNotificationAsRead = (ids) => {
    setShowNotifications(false)
    props.closeNotificationMenu()
    markNotificationAsRead(ids).catch(handleError)
  }

  const handleMarkAllNotificationsAsRead = () => {
    setShowNotifications(false)
    props.closeNotificationMenu()
    markAllNotificationsAsRead().catch(handleError)
  }

  /**
   * If there is a questionnaire notification, build it insert it at the start of the list of notifications
   */
  const showBell = notifications.filter((notification) => !notification.read).length > 0
  const classnames = classNames('bell-notification', { ringing: showBell })
  return (
    <>
      <div className={classnames} onClick={() => { setShowNotifications(!showNotifications) }}>
        <FontAwesomeIcon
          icon='bell'
          className={(showNotifications && 'icon-active') || ''}
          data-tip={I18n.t('navigation.notifications.tooltip')}
          data-for='higher-z-tooltip'
        />
        {showBell && <span className='bell-badge' />}
      </div>
      {showNotifications && (
        <div className={classNames('notifications-wrapper', props.onMobile && 'notifications-wrapper-mobile')}>
          <div className={`notifications ${props.onMobile && 'mobile'}`}>
            <div className='notifications-list'>
              {notifications.length > 0 && (
                <RowLink onClick={(e) => { e.preventDefault(); handleMarkAllNotificationsAsRead() }}>
                  {I18n.t('navigation.notifications.mark_all_read')}
                </RowLink>
              )}
              {notifications.map((notification) => <Notification key={notification.id} {...notification} markNotification={handleMarkNotificationAsRead} viewNotification={viewNotification} />)}
              {hiddenNotifications > 0 &&
                <RowLink onClick={(e) => { e.preventDefault(); handleNotificationModal() }}>
                  {I18n.t('navigation.notifications.and_x_unread', { count: hiddenNotifications })}
                </RowLink>}
              <RowLink onClick={(e) => { e.preventDefault(); handleNotificationModal() }}>
                {I18n.t('navigation.notifications.view_all')}
              </RowLink>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default WithBackend(WithModal(WithSession(WithQuestionnaires(Notifications))))
