import React from 'react'
import I18n from 'i18n'
import { withCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
import { WithBackend } from '../../backend/BackendProvider'
import Routes from '../../common/Routes'
import { PREVIOUS_URL_COOKIE } from '../../MyAxios'

const handleAcceptTerms = (acceptTermsAndConditions, cookies) => {
  acceptTermsAndConditions().then((response) => {
    const redirectLocation = cookies.get(PREVIOUS_URL_COOKIE)

    // Clean up the old cookie
    cookies.set(PREVIOUS_URL_COOKIE, '', { path: '/' })

    // Try to redirect to the location that we got from the cookies. If it does
    // not exist, fallback to something else.
    window.location = redirectLocation || document.referrer || Routes.root
  })
}

const AcceptTermsAndConditions = withCookies(WithBackend((props) => {
  return (
    <div className='static-page-wrapper container'>
      <div className='row'>
        <div className='col s12 center'>
          <p>
            <span>{I18n.t('login.tos_changed')} <Link to={Routes.static.termsAndConditionsLatest}>{I18n.t('login.tos_changed_view')}</Link> {I18n.t('login.reject_tos')}</span>
          </p>
        </div>
        <div className='col s12 center'>
          <p>
            <button onClick={() => handleAcceptTerms(props.backend.profiles.acceptTermsAndConditions, props.cookies)} className='waves-effect waves-light text-primary-color background-primary button-primary text-background-color button-autowidth'>
              {I18n.t('login.accept')}
            </button>
          </p>
        </div>
      </div>
    </div>
  )
}))

export default AcceptTermsAndConditions
