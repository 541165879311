import React, { useState } from 'react'
import I18n from 'i18n'
import _ from 'lodash'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import classNames from 'classnames'
import Chart from './Chart'

import {
  datePickerDefaultOptions, momentDateFormat, rheumaDailyQuestionnaire
} from '../../../../common/Constants'
import InfoRequiredData, { DASHBOARD_REQUIRED_DATA_TYPES } from '../../common/RequiredDataInfo'
import SelectDropdown from '../../../../common/SelectDropdown'
import GenericPlaceholder from '../../../../common/GenericPlaceholder'
import Slider from '../../../../common/Slider'
import { getWeekNumber } from '../../../../common/Math'
import {
  useQuestionnaireProfilesQuery,
  useQuestionnaireResponsesQuery
} from '../../../../backend/Queries'
import InputWrapper from '../../../../common/form/InputWrapper'
import { useImputedResponses } from './hooks'
import SpinnerWrapper from '../../../../common/SpinnerWrapper'

// Whenever dealing with week numbers, we use isoWeeks. Because the regular ones are off by one from what we'd expect.
const QUESTIONNAIRE_PERIOD = 'day'

const RheumaQuestionnaireDashboard = (props) => {
  const { match: { params: { id: routeUserId } = {} } = {} } = props

  const today = new Date()
  const twoMonthsAgo = moment().subtract(2, 'weeks').startOf(QUESTIONNAIRE_PERIOD)

  const [selectedProfileId, setSelectedProfileId] = useState(routeUserId || '')

  // Min/Max time have to be Date objects because of the datepicker
  const [minTime, setMinTime] = useState(twoMonthsAgo.startOf(QUESTIONNAIRE_PERIOD))
  const [maxTime, setMaxTime] = useState(moment(today).endOf(QUESTIONNAIRE_PERIOD))

  const datesError = minTime > maxTime

  const [timeRange, setTimeRange] = useState([
    minTime.clone().startOf(QUESTIONNAIRE_PERIOD),
    maxTime.clone().endOf(QUESTIONNAIRE_PERIOD)
  ])

  const [start, end] = timeRange

  const queryParams = {
    start_date: minTime.toISOString(),
    end_date: maxTime.toISOString(),
    type: [rheumaDailyQuestionnaire],
    profile_id: selectedProfileId
  }
  const { data: { data: profilesWithData = [] } = {} } = useQuestionnaireProfilesQuery(_.omit(queryParams, 'profile_id'))
  const { data: { data: responseVdos } = {}, isError, isSuccess } = useQuestionnaireResponsesQuery(queryParams, { enabled: selectedProfileId !== '' })

  const sliderId = 'rheuma-date-filter-slider'
  const sliderPivot = moment('2021-09-20') // It's a fixed arbitrary day

  /**
   * Using the pivot as reference, converts the time difference given by slider
   * into the corresponding moment.
   */
  const dayDifferenceToMoment = (timeDifference) => {
    return sliderPivot.clone().add(timeDifference, 'day')
  }

  /**
   * Sets the time range smartly
   * The start time is converted to that week's starting time (Monday at 12 am)
   * The end time is converted to that week's ending time (Sunday at 11:59 pm)
   * E.g. [3rd day, 10th day] -> [1st day at 12 am, 14th day at 11:59 pm]
   * */
  const smartSetTimeRange = (start, end) => {
    const smartStart = start.clone().startOf(QUESTIONNAIRE_PERIOD)
    const smartEnd = end.clone().endOf(QUESTIONNAIRE_PERIOD)
    setTimeRange([smartStart, smartEnd])
  }

  const handleSelectSubject = (e) => {
    // SelectDropdown triggers the onChange on first render when the component is controlled, which kind of defeats the purpose of a
    // controlled component. I think this was introduced to solve a bug/issue in some other dashboard. Anyways, if the
    // component is controlled (like in this case), it is impossible to select an undefined item. In other words, this
    // function will only receive undefined if it is triggered on first render, and if we have already a selectedProfileId
    // defined (e.g. because it is given by the path) we definitely don't want to revert to an undefined profile, so
    // we are overriding undefined values here
    const subjectId = e?.target?.value

    if (subjectId !== undefined) {
      setSelectedProfileId(subjectId)
    }
  }

  const handleMinTimeChange = (d) => {
    const m = moment(d).startOf(QUESTIONNAIRE_PERIOD)
    setMinTime(m)
    smartSetTimeRange(m, maxTime)
  }
  const handleMaxTimeChange = (d) => {
    const m = moment(d).endOf(QUESTIONNAIRE_PERIOD)
    setMaxTime(m)
    smartSetTimeRange(minTime, m)
  }

  const handleTimeRangeChange = (_range, _handle, unencodedRange) => {
    const newStart = dayDifferenceToMoment(unencodedRange[0])
    const newEnd = dayDifferenceToMoment(unencodedRange[1])
    smartSetTimeRange(newStart, newEnd)
  }

  const getSliderRange = (minTime, maxTime) => {
    // Avoids selection of the same week for min/max, which causes an error with the slider
    const oneDayBeforeMaxTime = moment(maxTime).subtract(1, 'day')
    const isDateValid = minTime.isSameOrBefore(oneDayBeforeMaxTime)

    if (isDateValid) {
      const minTimeMonday = minTime.clone().startOf(QUESTIONNAIRE_PERIOD)
      const maxTimeMonday = maxTime.clone().startOf(QUESTIONNAIRE_PERIOD)
      const pivotMonday = sliderPivot.clone().startOf(QUESTIONNAIRE_PERIOD)
      return [
        minTimeMonday.diff(pivotMonday, 'days'),
        maxTimeMonday.diff(pivotMonday, 'days')
      ]
    } else {
      return undefined
    }
  }

  let subjectList = []
  if (profilesWithData && profilesWithData.length > 0) {
    subjectList = _.values(profilesWithData)?.map((profile) => ({
      name: `${profile.first_name} ${profile.last_name}`,
      value: profile.id,
      icon: profile?.picture
    }))
  }

  // Array of vdos. If for a given day a vdo is not found, the answers are imputed with null
  const responses = useImputedResponses(start, end, responseVdos)

  const sliderRange = getSliderRange(minTime, maxTime)
  const sliderFormatter = (dayDifference) => {
    const selectedMoment = dayDifferenceToMoment(dayDifference)
    return getWeekNumber(selectedMoment)[1]
  }

  const sliderTitle = () => {
    return `${start.format(momentDateFormat)} - ${end.format(momentDateFormat)}`
  }

  const isDashboardActive = !!selectedProfileId
  const notSelectedAthletePlaceholder = <GenericPlaceholder message={I18n.t('components.placeholders.select_person')} />

  const customDatePickerOptions = {
    showWeekNumbers: true
  }

  return (
    <div>
      <div className='row'>
        <div className='col s12 text-xl text-primary-color text-heavy'>
          {I18n.t('components.dashboards.questionnaire.rheuma.title')}
          <InfoRequiredData
            id='dataset-types-info'
            dataTypes={DASHBOARD_REQUIRED_DATA_TYPES.rheuma_questionnaire}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col s6 input-field'>
          {/* // TODO: move to a molecule (see https://gitlab.com/researchable/sport-data-valley/mvp/base-platform/-/merge_requests/1578#note_738042941) */}
          <InputWrapper
            errorText={datesError && I18n.t('components.placeholders.invalid_date')}
          >
            <DatePicker
              id='start' {...datePickerDefaultOptions()} {...customDatePickerOptions} selected={minTime.toDate()}
              startDate={minTime.toDate()}
              endDate={maxTime.toDate()}
              selectsStart
              onChange={handleMinTimeChange}
            />
            <label
              htmlFor='start'
              className={classNames({ active: minTime })}
            >{I18n.t('components.dashboards.multi_activity.start_date')}
            </label>
          </InputWrapper>
        </div>
        <div className='col s6 input-field'>
          <InputWrapper
            errorText={datesError && I18n.t('components.placeholders.invalid_date')}
          >
            <DatePicker
              id='end' {...datePickerDefaultOptions()} {...customDatePickerOptions} selected={maxTime.toDate()}
              startDate={minTime.toDate()}
              endDate={maxTime.toDate()}
              selectsEnd
              onChange={handleMaxTimeChange} maxDate={moment(today).endOf(QUESTIONNAIRE_PERIOD)}
            />
            <label
              htmlFor='end'
              className={classNames({ active: maxTime })}
            >{I18n.t('components.dashboards.multi_activity.end_date')}
            </label>
          </InputWrapper>
        </div>
        <div className='col s12'>
          <SelectDropdown
            id='profile-selector' onChange={handleSelectSubject}
            label={I18n.t('components.dashboards.questionnaire.select_person')}
            placeholder={I18n.t('components.dashboards.select_dropdown.person')} content={subjectList}
            value={selectedProfileId}
          />
        </div>
        <div className='col s12 no-noui-tooltips'>
          <Slider
            id={sliderId}
            range={sliderRange || [0, 1]}
            start={sliderRange}
            onChange={handleTimeRangeChange}
            title={I18n.t('components.dashboards.questionnaire.rheuma.date_range', { value: sliderTitle() })}
            customFormatter={sliderFormatter}
            disabled={!isDashboardActive || !sliderRange}
            largerLabel
          />
        </div>
      </div>

      <div className='row'>
        <div className='col s12 l6'>
          <div className='chart-container fixed-height'>
            {!selectedProfileId && notSelectedAthletePlaceholder}
            {isDashboardActive && (
              <SpinnerWrapper failed={isError} ready={isSuccess && responses}>
                <Chart responses={responses} persona='fatigue' />
              </SpinnerWrapper>
            )}
          </div>
        </div>
        <div className='col s12 l6'>
          <div className='chart-container'>
            {!selectedProfileId && notSelectedAthletePlaceholder}
            {isDashboardActive && (
              <SpinnerWrapper failed={isError} ready={isSuccess && responses}>
                <Chart responses={responses} persona='pain' />
              </SpinnerWrapper>
            )}
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 l6'>
          <div className='chart-container'>
            {!selectedProfileId && notSelectedAthletePlaceholder}
            {isDashboardActive && (
              <SpinnerWrapper failed={isError} ready={isSuccess && responses}>
                <Chart responses={responses} persona='stress' />
              </SpinnerWrapper>
            )}
          </div>
        </div>
        <div className='col s12 l6'>
          <div className='chart-container'>
            {!selectedProfileId && notSelectedAthletePlaceholder}
            {isDashboardActive && (
              <SpinnerWrapper failed={isError} ready={isSuccess && responses}>
                <Chart responses={responses} persona='rest' />
              </SpinnerWrapper>
            )}
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 l6'>
          <div className='chart-container'>
            {!selectedProfileId && notSelectedAthletePlaceholder}
            {isDashboardActive && (
              <SpinnerWrapper failed={isError} ready={isSuccess && responses}>
                <Chart responses={responses} persona='physical' />
              </SpinnerWrapper>
            )}
          </div>
        </div>
        <div className='col s12 l6'>
          <div className='chart-container'>
            {!selectedProfileId && notSelectedAthletePlaceholder}
            {isDashboardActive && (
              <SpinnerWrapper failed={isError} ready={isSuccess && responses}>
                <Chart responses={responses} persona='cognitive' />
              </SpinnerWrapper>
            )}
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 l6'>
          <div className='chart-container'>
            {!selectedProfileId && notSelectedAthletePlaceholder}
            {isDashboardActive && (
              <SpinnerWrapper failed={isError} ready={isSuccess && responses}>
                <Chart responses={responses} persona='sleep' />
              </SpinnerWrapper>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RheumaQuestionnaireDashboard
