import React from 'react'
import TrainingPeriodizationChart from './TrainingPeriodizationChart'
import TrainingAcuteChronicWorkloadRatioChart from './TrainingAcuteChronicWorkloadRatioChart'
import I18n from 'i18n'
import Info from '../../../../atomic/atoms/Info'
import GenericPlaceholder from '../../../../common/GenericPlaceholder'
import TrainingSessionsChart from './TrainingSessionsChart'
import { hcDayPartColor, hcSessionColor, hcTypeColor } from 'components/common/Constants'
import TrainingSatisfactionChart from './TrainingSatisfactionChart'
import TrainingIntensityDistributionChart from './TrainingIntensityDistributionChart'
import QuestionnaireFeedbackTable from './QuestionnaireFeedbackTable'
import MyReactTooltip from '../../../../MyReactTooltip'
import SpinnerWrapper from 'components/common/SpinnerWrapper'

const TrainingQuestionnaireDashboard = (props) => (
  <>
    <div className='row'>
      <div className='col s12 l6'>
        <div className='chart-container'>
          <SpinnerWrapper ready={!props.isLoading && !props.isError} failed={props.isError && !props.isLoading}>
            <TrainingPeriodizationChart weeklySummary={props.weeklySummary} />
          </SpinnerWrapper>
        </div>
      </div>
      <div className='col s12 l6'>
        <div className='chart-container'>
          <SpinnerWrapper ready={!props.isLoading && !props.isError} failed={props.isError && !props.isLoading}>
            <TrainingAcuteChronicWorkloadRatioChart weeklySummary={props.weeklySummary} />
          </SpinnerWrapper>
        </div>
      </div>
    </div>

    <div className='row'>
      <div className='col s12 l6'>
        <div className='chart-container'>
          <SpinnerWrapper ready={!props.isLoading && !props.isError} failed={props.isError && !props.isLoading}>
            {(props.trainingDaypartHistogram || props.trainingTypeHistogram || props.sessionTypeHistogram) && !props.isLoading && (
              <div className='highcharts-plot-title-container'>
                <div className='highcharts-plot-title'>
                  {I18n.t('components.dashboards.questionnaire.training_sessions.title.plot')}
                  <Info text={I18n.t('components.dashboards.questionnaire.training_sessions.info')} tooltipId='questionnaire-tooltip-training-sessions' inline />
                </div>
              </div>
            )}
            {!(props.trainingDaypartHistogram || props.trainingTypeHistogram || props.sessionTypeHistogram || props.isLoading) && (!props.isError) && (
              <GenericPlaceholder title={I18n.t('components.dashboards.questionnaire.training_sessions.title.plot')} />
            )}
            <div className='highcharts-plots-container'>
              <div className='highcharts-plot-container'>
                <TrainingSessionsChart
                  title={I18n.t('components.dashboards.questionnaire.training_sessions.title.daypart')}
                  data={props.trainingDaypartHistogram}
                  color={hcDayPartColor}
                />
              </div>
              <div className='highcharts-plot-container'>
                <TrainingSessionsChart
                  title={I18n.t('components.dashboards.questionnaire.training_sessions.title.type')}
                  data={props.trainingTypeHistogram}
                  color={hcTypeColor}
                />
              </div>
              <div className='highcharts-plot-container'>
                <TrainingSessionsChart
                  title={I18n.t('components.dashboards.questionnaire.training_sessions.title.session')}
                  data={props.sessionTypeHistogram}
                  color={hcSessionColor}
                />
              </div>
            </div>
          </SpinnerWrapper>
        </div>
      </div>
      <div className='col s12 l6'>
        <div className='chart-container'>
          <SpinnerWrapper ready={!props.isLoading && !props.isError} failed={props.isError && !props.isLoading}>
            <TrainingSatisfactionChart
              trainingLogRows={props.trainingLogRows}
            />
          </SpinnerWrapper>
        </div>
      </div>
    </div>

    <div className='row'>
      <div className='col s12 l6'>
        <div className='chart-container'>
          <SpinnerWrapper ready={!props.isLoading && !props.isError} failed={props.isError && !props.isLoading}>
            <TrainingIntensityDistributionChart
              data={props.trainingRPEHistogram}
            />
          </SpinnerWrapper>
        </div>
      </div>
      <div className='col s12 l6'>
        <div className='chart-container feedback'>
          {!props.trainingLogRows && !props.isLoading && (
            <GenericPlaceholder title={I18n.t('components.dashboards.questionnaire.training_feedback.title')} />
          )}
          {props.trainingLogRows && !props.isLoading && (
            <>
              <div className='highcharts-plot-title-container'>
                <div className='highcharts-plot-title'>
                  {I18n.t('components.dashboards.questionnaire.training_feedback.title')}
                  <Info text={I18n.t('components.dashboards.questionnaire.training_feedback.info')} tooltipId='questionnaire-tooltip-training-feedback' inline />
                </div>
              </div>
              <div className='feedback-table-container'>
                <SpinnerWrapper ready={!props.isLoading && !props.isError} failed={props.isError && !props.isLoading}>
                  <QuestionnaireFeedbackTable
                    id='training_sessions'
                    data={props.trainingLogRows}
                    columns={[
                      {
                        column: 'training_start_date',
                        label: I18n.t('components.dashboards.questionnaire.training_feedback.training_start_date'),
                        tooltip_column: 'formatted_training_start_date',
                        style: { minWidth: '75px' }
                      },
                      {
                        column: 'training_session_short',
                        label: I18n.t('components.dashboards.questionnaire.training_feedback.training_session_short'),
                        tooltip_column: 'training_session',
                        style: { minWidth: '77px' }
                      },
                      { column: 'rpe', label: I18n.t('components.dashboards.questionnaire.training_feedback.rpe') },
                      { column: 'training_duration', label: I18n.t('components.dashboards.questionnaire.training_feedback.training_duration') },
                      {
                        column: 'comments',
                        label: I18n.t('components.dashboards.questionnaire.training_feedback.comments')
                      }
                    ]}
                  />
                </SpinnerWrapper>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
    <MyReactTooltip id='questionnaire-tooltip' effect='solid' className='wider-tooltip' />
  </>
)

export default TrainingQuestionnaireDashboard
