import React, { useEffect } from 'react'
import qs from 'qs'
import I18n from 'i18n'
import Button from '../../atomic/atoms/Button'
import { WithSession } from '../../session/SessionProvider'

const Authorization = (props) => {
  if (props.session.redirectToLoginIfNoSession()) return <></>

  const {
    client_id: clientId,
    code_challenge: codeChallenge,
    code_challenge_method: codeChallengeMethod,
    state
  } = qs.parse(props.location.search, { ignoreQueryPrefix: true })

  useEffect(() => {
    props.fetchApplicationDetails(clientId)
  }, [])

  const scopes = () => {
    const renderedScopes = props.applicationData.scopes.map((scope) => {
      return (
        <div key={scope} className='no-margin collection-item'>
          <span className='badge text-muted'>{I18n.t(`oauth.scope.short.${scope}`)}</span>
          <span>{I18n.t(`oauth.scope.description.${scope}`)}</span>
        </div>
      )
    })
    return (
      <div className='collection'>{renderedScopes} </div>
    )
  }
  // Extract the code from the redirect url to be displayed in UI
  const oauthCode = props?.authorizedResult?.redirect_uri?.code

  const handleSubmit = (e) => {
    e.preventDefault()
    if (codeChallenge === undefined) {
      props.authorizeApplication(props.applicationData)
    } else {
      props.authorizeApplication(props.applicationData, codeChallenge, codeChallengeMethod, state)
    }
  }

  if (!props.applicationData) return <></>

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <div className='text-heavy text-primary-color text-l'>
            {I18n.t('oauth.title')}
          </div>
          <p>{I18n.t('oauth.application_description', { name: props.applicationData.name })}</p><br />
          <div className='row'>
            <div className='col s12' />
          </div>
          <ul>{scopes()}</ul>
        </div>
      </div>
      <div className='row'>
        <form>
          <div className='col s12'>
            <Button
              onClick={handleSubmit}
              autowidth
              cy='submit'
            >{I18n.t('oauth.authorize')}
            </Button>
          </div>
        </form>
      </div>
      {props.authorizedResult &&
        <div className='row'>
          <div className='col s12'>
            <p>Code: {oauthCode}</p>
          </div>
        </div>}
    </>
  )
}
export default WithSession(Authorization)
