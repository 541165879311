import React from 'react'
import { WithNotFound, NotFound } from './NotFoundProvider'
import { Switch, Route } from 'react-router'

class RouterWrapper extends React.Component {
  /* eslint-disable camelcase */
  UNSAFE_componentWillMount () {
    if (!this.props.slug) {
      throw new Error('The NotFoundWrapper must be given a slug')
    }
    this.props.register(this.props.slug)
  }
  /* eslint-enable camelcase */

  render () {
    return (
      <Switch>
        {this.props.children}
        <Route render={() => <NotFound componentSlug={this.props.slug} />} />
      </Switch>
    )
  }
}

export default WithNotFound(RouterWrapper)
