import React, { useEffect, useState } from 'react'
import I18n from 'i18n'
import _ from 'lodash'

import Slider from '../../../../common/Slider'
import TimeSeriesChart, { PRIMARY_Y_AXIS, SECONDARY_Y_AXIS } from '../../common/TimeSeriesChart'
import SelectDropdown from '../../../../common/SelectDropdown'
import { ms2kmh, s2ms } from '../../../../common/Units'
import { DECIMALS_ROUNDING, DEFAULT_HR_ZONES, hcBlack, hcRed, HR_ZONES_COLORS, timeSeries as timeSeriesNames } from '../../../../common/Constants'
import { round } from '../../../../common/Math'
import { interpolateColors } from '../../../../common/Utils'

const TimeseriesWithSelectors = (props) => {
  const [timeRange, setTimeRange] = useState([0, 1])
  const [selectedTimeSeries, setSelectedTimeSeries] = useState([null, null])

  const { activity } = props

  const handleTimeRangeChange = (_newRange, _handle, unencodedNewRange) => {
    const integerNewRange = unencodedNewRange.map(Math.floor)
    setTimeRange(integerNewRange)
  }

  const handleSelectActivityMetricFactory = (dropdownIdx) => {
    return (e) => {
      const selectedMetric = e?.target?.value
      const currentMetrics = _.clone(selectedTimeSeries)
      currentMetrics[dropdownIdx] = selectedMetric
      setSelectedTimeSeries(currentMetrics)
    }
  }

  const getTotalActivityDuration = (timeOffsets) => {
    if (timeOffsets && timeOffsets.length > 0) {
      return [_.first(timeOffsets), _.last(timeOffsets)]
    } else {
      return [0, 1]
    }
  }

  const generateMetricSelector = (index, metrics) => {
    const dropdownOptions = metrics?.map((metric) => ({ value: metric, name: I18n.t(`components.dashboards.coach_individual.training.timeseries.${metric}`) }))
    dropdownOptions.unshift({ value: 'noSelection', name: I18n.t('components.dashboards.coach_individual.training.timeseries.no_selection') })
    return (
      <SelectDropdown
        id={`metric-dropdown-${index}`}
        content={dropdownOptions}
        defaultValue={_.first(dropdownOptions?.value)}
        label={I18n.t('components.dashboards.select_dropdown.metric')}
        placehold={I18n.t('components.dashboards.select_dropdown.metric')}
        onChange={handleSelectActivityMetricFactory(index)}
      />
    )
  }

  const getZonesConfigs = (metric, zones) => {
    const defaultConfigs = {
      colors: undefined,
      maxBoundary: undefined
    }

    if (!zones) {
      return defaultConfigs
    }

    switch (metric) {
      case timeSeriesNames.HR:
        return {
          colors: interpolateColors(HR_ZONES_COLORS.to, HR_ZONES_COLORS.from, zones?.length),
          maxBoundary: _.last(DEFAULT_HR_ZONES)
        }
      default:
        return defaultConfigs
    }
  }

  // Obtain the default time range -> Whole time range of the activity
  useEffect(() => {
    setTimeRange(getTotalActivityDuration(activity?.data?.offset))
  }, [activity?.vdo?.id])

  const time = activity?.data?.offset
  const totalTimeRange = getTotalActivityDuration(time)
  const availableTimeSeries = _.keys(activity?.data?.time_series)
  const selectedTimeSeriesValues = selectedTimeSeries.map((timeSeriesName) => _.get(activity?.data?.time_series, `[${timeSeriesName}]`))

  // Do any unit conversions necessary
  selectedTimeSeries.forEach((timeSeriesName, idx) => {
    if (timeSeriesName === timeSeriesNames.SPEED) {
      selectedTimeSeriesValues[idx] = selectedTimeSeriesValues[idx].map((value) => round(ms2kmh(value), DECIMALS_ROUNDING))
    }
  })

  // Only shows the zones for the first parameter
  const mainMetric = _.first(selectedTimeSeries)
  const zoneLimits = activity?.vdo?.summary?.time_series_summaries?.[mainMetric]?.zone_limits
  const { colors: zoneColors, maxBoundary: zoneMaxBoundary } = getZonesConfigs(mainMetric, zoneLimits)

  const colorPalette = [hcBlack, hcRed] // TODO Standardize the order of the color palette on these kind of dashboards
  const yAxisIds = [PRIMARY_Y_AXIS, SECONDARY_Y_AXIS]
  const chartOptions = {
    xTickCallback: (value) => `${s2ms(value)}`,
    tooltipCallbacks: {
      title: (values, data) => {
        const { index, datasetIndex } = values[0]
        const offset = data.datasets[datasetIndex].data[index].x
        return `${s2ms(offset)}`
      },
      label: (value) => {
        const { datasetIndex, value: yValue } = value
        const metric = selectedTimeSeries[datasetIndex]
        return `${I18n.t(`components.dashboards.coach_individual.training.timeseries.${metric}`)}: ${yValue}`
      }
    },
    datasetConfigs: [0, 1].map((index) => {
      return {
        backgroundColor: colorPalette[index],
        borderColor: colorPalette[index],
        yAxisID: yAxisIds[index]
      }
    }),
    scaleColors: colorPalette,
    yLabels: selectedTimeSeries.map((metric) => {
      return metric ? I18n.t(`components.dashboards.coach_individual.training.timeseries.${metric}`) : ''
    }),
    suggestedMax: zoneMaxBoundary
  }

  return (
    <div className='row'>
      <div className='col s6'>
        {generateMetricSelector(0, availableTimeSeries)}
      </div>
      <div className='col s6'>
        {generateMetricSelector(1, availableTimeSeries)}
      </div>
      <div className='col s12'>
        <Slider
          id='time-slider'
          isTime
          title={I18n.t('components.dashboards.select_dropdown.time')}
          range={totalTimeRange}
          start={totalTimeRange}
          onChange={handleTimeRangeChange}
        />
      </div>
      <div className='col s12'>
        <TimeSeriesChart
          x={time}
          ys={selectedTimeSeriesValues}
          range={timeRange}
          zones={zoneLimits}
          zoneColors={zoneColors}
          {...chartOptions}
        />
      </div>
    </div>
  )
}

export default TimeseriesWithSelectors
