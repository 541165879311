import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Only renders a checkbox, not a label
const CheckOnly = (props) => {
  const isSelected = useMemo(() => props.selectedItems.filter(curItem => curItem.id === props.id).length,
    [props.selectedItems, props.id])
  const setChecked = (checked) => {
    if (checked && isSelected === 0) {
      const newSelectedItems = Array.from(props.selectedItems)
      newSelectedItems.push({ id: props.id, title: props.title })
      props.setSelectedItems(newSelectedItems)
    } else if (!checked && isSelected > 0) {
      props.setSelectedItems(props.selectedItems.filter(curItem => curItem.id !== props.id))
    }
  }
  return (
    <p className={classNames('checkonly', { 'read-only': props.readOnly })}>
      <label>
        <input
          type='checkbox'
          checked={isSelected > 0}
          onChange={e => setChecked(e.target.checked)}
          readOnly={props.readOnly}
        />
        <span />
      </label>
    </p>
  )
}

CheckOnly.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func.isRequired
}

CheckOnly.defaultProps = {
  selectedItems: [],
  readOnly: false
}

export default CheckOnly
