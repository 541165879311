import React, { useContext } from 'react'
import I18n from 'i18n'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TransferRequest } from 'components/common/types/TransferRequest'
import { useProfileQuery } from 'components/backend/profile/Queries'
import { getFullName } from 'components/common/utils/ProfileUtils'
import { defaultProfileName } from 'components/common/Constants'
import { useAcceptTransferRequestMutation, useRejectTransferRequestMutation } from 'components/backend/transfer_requests/Mutations'
import { isEmpty } from 'lodash'
import InfoNotice from '../../detail/InfoNotice'
import Button from 'components/atomic/atoms/Button'
import { FlashMessageContext } from 'components/flashmessages/FlashMessageProvider'

interface Props {
  request: TransferRequest
  afterAccept: () => void
  afterReject: () => void
}

const IncomingTransferRequestDetails: React.FC<Props> = (props): React.ReactElement => {
  const { request, afterAccept, afterReject } = props

  const { data: profile } = useProfileQuery(request.from.slug)
  const profileName = profile !== undefined ? getFullName(profile) : defaultProfileName
  const hasDatasets: boolean = !isEmpty(request.datasets)

  const { flashMessages } = useContext(FlashMessageContext)

  const { mutate: accept } = useAcceptTransferRequestMutation()
  const handleAccept = (): void => {
    accept(request, {
      onSuccess: () => flashMessages.pushInfoMessage(I18n.t('flashmessages.transfer_requests.accepted')),
      onError: () => flashMessages.pushErrorMessage(I18n.t('flashmessages.transfer_requests.error_on_accept')),
      onSettled: afterAccept
    })
  }

  const { mutate: reject } = useRejectTransferRequestMutation()
  const handleReject = (): void => {
    reject(request, {
      onSuccess: () => flashMessages.pushInfoMessage(I18n.t('flashmessages.transfer_requests.rejected')),
      onError: () => flashMessages.pushErrorMessage(I18n.t('flashmessages.transfer_requests.error_on_reject')),
      onSettled: afterReject
    })
  }

  return (
    <div className='general-content card-panel'>
      <div className='row'>
        <div className='col s12 m6'>
          <h5 className='text-primary-color text-heavy'>
            {I18n.t('components.data.ownership.request.title')}
          </h5>
        </div>
      </div>

      {/* Transfer ownership request */}
      <div className='row'>
        <div className='col s12 m6'>
          <p>{I18n.t('components.data.ownership.request.person', { person: profileName })}</p>
          <p>{I18n.t('components.data.ownership.request.message', { person: profileName })}</p>
        </div>

        <div className='col s12 m6'>
          <label htmlFor='selected-files'>{I18n.t('components.data.ownership.request.selected_files')}</label>
          {hasDatasets &&
            <ul className='collection files fixed-height-list'>
              <TransitionGroup className='data-files'>
                {request.datasets.map((dataset, index) => (
                  <CSSTransition
                    key={index}
                    timeout={200}
                    classNames='item'
                    appear
                  >
                    <div>
                      <li className='collection-item'>
                        <div>
                          {dataset.title}
                        </div>
                      </li>
                    </div>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </ul>}
          {!hasDatasets &&
            <div>
              <InfoNotice className='margin-top' msg={I18n.t('components.data.ownership.request.no_files')} />
            </div>}
        </div>
      </div>

      {/* Confirmation buttons */}
      <div className='row'>
        <div className='col s12'>
          <div className='right-align'>
            <Button onClick={() => handleAccept()} width='group-width' isDisabled={!hasDatasets}>
              <FontAwesomeIcon icon='check' className='button-icon' />
              {I18n.t('components.data.ownership.request.accept')}
            </Button>
            <Button onClick={() => handleReject()} width='group-width' type='secondary' colour='red'>
              <FontAwesomeIcon icon='times' className='button-icon' />
              {I18n.t('components.data.ownership.request.decline')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IncomingTransferRequestDetails
