import React from 'react'
import I18n from 'i18n'
import Field from '../common/Field'

export default class SundayQuestionnaire extends React.Component {
  render () {
    const values = this.props.data.values
    return (
      <>
        <div className='sport-fields'>
          <Field title={I18n.t('questionnaires.sunday_questionnaire.mental_fatigue')} value={`${parseFloat(values.v1).toFixed(1)} uit 5.0`} />
          <Field title={I18n.t('questionnaires.sunday_questionnaire.physical_fatigue')} value={`${parseFloat(values.v2).toFixed(1)} uit 5.0`} />
          <Field title={I18n.t('questionnaires.sunday_questionnaire.feedback')} value={values.v7} />
        </div>
      </>
    )
  }
}
