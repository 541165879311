import { rotateTodayToEnd } from '../../../common/Utils'
import { circularStandardDeviation, radToTime, timeToRad } from '../../../common/Math'

const metrics = ['mean', 'errors', 'lowerbound', 'upperbound']

export const filterAndRotateActivityData = (params, filterParams, datasets) => {
  const filteredData = params.filter(param => filterParams.includes(param)).map(param => {
    return {
      param: param,
      ...Object.fromEntries(metrics.map(metric => [metric, datasets[param][metric]]))
    }
  })
  for (const metric of metrics) {
    for (const serie of filteredData) {
      serie[metric] = rotateTodayToEnd(serie[metric])
    }
  }
  return filteredData
}

// See https://en.wikipedia.org/wiki/Directional_statistics#Distribution_of_the_mean
export const calcAverage = (data, scaleFactor = 1) => {
  if (!data) return 0

  let total = 0
  let sum = 0.0
  let sCirc = 0.0
  let cCirc = 0.0
  for (const val of data.mean) {
    if (!val || val === 0) continue

    total += 1
    const scaledVal = val / scaleFactor
    sum += scaledVal
    const radVal = timeToRad(scaledVal)
    sCirc += Math.sin(radVal)
    cCirc += Math.cos(radVal)
  }
  if (total === 0) return 0.0
  if (Math.sqrt(Math.pow(sCirc, 2) + Math.pow(cCirc, 2)) / total > 0.0001) {
    // If we can calculate the mean using circular statistics, we prefer it.
    return radToTime(Math.atan2(sCirc, cCirc))
  }
  // If the circular average is zero for whatever reason, return the "regular" average,
  // i.e., the sum of the values in data.mean divided by the number of values, modulo 24 hours.
  return (sum / total) % 86400
}

export const calcErrorBar = (idx, data, scaleFactor = 1) => {
  // The scaleFactor is just a way to turn milliseconds into seconds.
  // This is only needed for time variables, but to avoid duplication,
  // we use this same function for non-time variables as well, and then
  // the scaleFactor is set to 1 (so it has no effect).
  // The idx param is the type of error bar.
  // if idx === 0, we return the lower bar (average - 1 std).
  // if idx === 1, we return the upper bar (average + 1 std).
  if (!data) return 0

  let total = 0
  let sum = 0.0
  for (const val of data.errors) {
    if (!val || val === 0 || val[idx] === 0) continue

    total += 1
    sum += val[idx] / scaleFactor
  }
  if (total !== 0) return (86400 + (sum / total)) % 86400
  // For the weekly overview, we don't have errorbars, since the days of the week
  // do not average over something. They are a single value. However, since we
  // are averaging over the weekdays, we would still like to calculate the standard
  // deviation and take that as errors for the average value.
  // const allValues = []
  const allRadValues = []
  for (const val of data.mean) {
    if (!val || val === 0) continue

    // allValues.push(val / scaleFactor)
    allRadValues.push(timeToRad(val / scaleFactor))
    total += 1
  }
  if (total === 0) return 0.0
  const weeklyAverage = calcAverage(data, scaleFactor)
  // const oldStd = standardDeviation(allValues)
  const newStd = radToTime(circularStandardDeviation(allRadValues))
  if (idx === 0) {
    return (86400 + weeklyAverage - newStd) % 86400
  }
  return (86400 + weeklyAverage + newStd) % 86400
}
