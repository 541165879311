import React from 'react'
import classNames from 'classnames'
import I18n from 'i18n'

export default class DragMessage extends React.Component {
  render () {
    if (this.props.isDragActive) {
      return (
        <p className={classNames('dropzone-location', 'dropzone-active', this.props.dragActiveClassnames)}>
          {this.props.dragActiveText}
        </p>
      )
    }
    return (
      <div className={classNames('dropzone-location', 'dropzone-inactive', this.props.dragInActiveClassnames)}>
        <p>
          {this.props.dragInActiveText}
        </p>
        <p>
          {I18n.t('components.data.or')}
        </p>
        <div className='row'>
          <div className='col s12'>
            <button id='upload-data' className='waves-effect waves-light background-primary text-background-color button-primary'>{I18n.t('components.data.select_file')}</button>
          </div>
        </div>
      </div>
    )
  }
}
