import React from 'react'
import classNames from 'classnames'

interface Props {
  name: string
  label?: string
  type?: React.HTMLInputTypeAttribute
  value?: string | number
  onChange?: React.ChangeEventHandler
  errors?: string[]
  disabled?: boolean
  readonly?: boolean
}

const TextField = (props: Props): React.ReactElement => {
  const { label, name, type = 'text', onChange, errors, value, disabled = false, readonly = false } = props

  // Set label to active if the value is defined, to fix the label overlapping with the value
  const labelClass = classNames({ active: value })
  return (
    <div className='input-field'>
      <input id={name} type={type} onChange={onChange} value={value} disabled={disabled} readOnly={readonly} />
      <label className={labelClass} htmlFor={name}>{label}</label>
      {errors?.map(err => (
        <span className='helper-text' data-error='wrong' data-success='right' key={err} style={{ color: 'red' }}>
          {err}
        </span>
      ))}
    </div>
  )
}

export default TextField
