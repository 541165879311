import React from 'react'
import PremiumMembershipRoutes from './PremiumMembershipRoutes'

const PremiumMembership = (props) => {
  return (
    <PremiumMembershipRoutes />
  )
}

export default PremiumMembership
