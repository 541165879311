import React, { useMemo } from 'react'
import StackedBarChart from '../../steps/StackedBarChart'
import _ from 'lodash'
import PropTypes from 'prop-types'
import I18n from 'i18n'
import { dateStringToDayMonthYear } from '../../../../common/Utils'
import { m2km, s2hm } from '../../../../common/Units'

export const LOAD_DURATION = 'duration'
export const LOAD_DISTANCE = 'distance'
export const LOAD_COUNT = 'count'

export const LOAD_TYPES = Object.freeze([LOAD_DURATION, LOAD_DISTANCE, LOAD_COUNT])

const TrainingLoadChart = (props) => {
  // Convert training_load into something chartjs understands
  const [trainingLoadData, trainingLoadLabels] = useMemo(() => {
    const entries = props.trainingLoad || []
    const labels = []
    // sum of the load across all categories per week
    // const summedLoads = []

    const loadDatasets = props.categories.map(category => ({
      label: category.label,
      backgroundColor: category.color,
      data: []
    }))

    entries.forEach(e => {
      const lastLabel = labels[labels.length - 1]
      // This date format is necessary to not break the x-axis slider (otherwise there are might be repeated dates)
      const date = dateStringToDayMonthYear(e._id.date)
      const loadValue = getLoadValue(e)
      // This will happen if there is both an indoors and outdoors activity in the same week
      if (lastLabel === date) {
        loadDatasets[entryIndex(e)].data[labels.length - 1] = loadValue
        // summedLoads[summedLoads.length - 1] += loadValue
      } else { // First time a date shows, or there is only one type of activity for this date
        labels.push(date)
        const entryIdx = entryIndex(e)
        loadDatasets[entryIdx].data.push(loadValue)
        // All arrays must be of same length even if no data is available
        const indices = _.range(loadDatasets.length)
        const othersIdxs = _.filter(indices, e => e !== entryIdx)
        othersIdxs.forEach(idx => loadDatasets[idx].data.push(0))
        // summedLoads.push(loadValue)
      }
    })

    // return [loadDatasets, labels, summedLoads]
    return [loadDatasets, labels]
  }, [props.trainingLoad, props.loadType])

  function yCb (v) {
    switch (props.loadType) {
      case LOAD_DURATION:
        return s2hm(v)
      case LOAD_DISTANCE:
        return `${Math.round(m2km(v))} ${I18n.t('units.km')}`
      default:
        return v
    }
  }

  const ttCb = {
    label: ti => yCb(ti.value)
  }

  // Returns the index of the dataset according to the entry collection_subtype
  function entryIndex (entry) {
    // Follows the order defined in categories
    return props.categories.map(category => category.name).indexOf(entry._id.collection_subtype)
  }

  // Gets the right value for the training load according to what the user selected
  function getLoadValue (entry) {
    return _.get(entry, props.loadType)
  }

  const yLabel = useMemo(() => {
    let label = I18n.t(`components.dashboards.multi_activity.load.${props.loadType}`)
    if (props.loadType === LOAD_DURATION) {
      label += ` (${I18n.t('units.h')}:${I18n.t('units.min')})`
    }
    return label
  }, [props.loadType])

  const range = props.range?.map((idx) => trainingLoadLabels[idx])

  return (
    <StackedBarChart
      title={I18n.t('components.dashboards.multi_activity.load.label')}
      labels={trainingLoadLabels} datasets={trainingLoadData}
      yTickCallback={yCb}
      tooltipCallbacks={ttCb}
      yLabel={yLabel}
      range={range}
      stacked
    />
  )
}

TrainingLoadChart.propTypes = {
  trainingLoad: PropTypes.array,
  loadType: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.shape({
    // The corresponding _id.collection_subtype
    name: PropTypes.string,
    label: PropTypes.string,
    color: PropTypes.string
  })),
  // The (subset) range to be displayed
  range: PropTypes.array
}

export default TrainingLoadChart
