import React, { useState, useEffect, MouseEventHandler } from 'react'
import { Link } from 'react-router-dom'
import Routes from '../../common/Routes'
import I18n from 'i18n'
import PremiumBadge from '../../atomic/atoms/PremiumBadge'
import Spinner from '../../common/Spinner'
import { WithModal } from '../../modal/ModalProvider'
import { WithSession } from '../../session/SessionProvider'
import { fetchAmount } from './PaymentUtils'
import { get } from 'lodash'
import moment from 'moment'
import Profile from 'components/common/types/Profile'
import Subscription, { SubscriptionType, SUPER_PREMIUM } from 'components/common/types/Subscription'

const renderSubscriptionButton = (disabled: boolean, content: React.ReactElement | string, handleOnClick: MouseEventHandler, isSuperPremium: boolean): React.ReactElement => {
  const classes: string = `waves-effect waves-light text-background-color text-medium text-m premium-button \
  ${disabled ? 'button-muted background-muted' : 'button-accent background-accent'} \
  ${isSuperPremium ? 'super-premium' : ''}`

  const btn: React.ReactElement = <button className={classes} disabled={disabled}>{content}</button>
  if (!disabled) {
    return (
      <Link to={Routes.premium.subscribe.authorise} onClick={handleOnClick}>
        {btn}
      </Link>
    )
  }
  return btn
}

const getSubscriptionButton = (type: SubscriptionType, subscription: Subscription | undefined, handleOnClick: MouseEventHandler, isSuperPremium: boolean): React.ReactElement => {
  // Just show a disabled button when subscription has not loaded yet.
  if (subscription === undefined) {
    return renderSubscriptionButton(true, I18n.t('premium.buttons.upgrade'), handleOnClick, isSuperPremium)
  }

  if (type === SubscriptionType.FREE) {
    if (get(subscription, 'valid') && get(subscription, 'renewable')) {
      return renderSubscriptionButton(false, I18n.t('premium.buttons.cancel'), handleOnClick, isSuperPremium)
    }
    if (get(subscription, 'valid') && get(subscription, 'expires', '') !== '') {
      return renderSubscriptionButton(true, I18n.t('premium.buttons.active_on', { date: moment(new Date(subscription.expires)).calendar() }), handleOnClick, isSuperPremium)
    } else {
      return renderSubscriptionButton(true, I18n.t('premium.buttons.active'), handleOnClick, isSuperPremium)
    }
  }
  /* eligible for trial means no active subscription, so show active button with link to activate trial */
  if (get(subscription, 'eligible_for_trial')) {
    return renderSubscriptionButton(false, I18n.t('premium.buttons.upgrade_trial'), handleOnClick, isSuperPremium)
  }
  /* either current subscription is invalid, or button is for a different subscription type, so show upgrade now button */
  if (!(type === subscription.type && subscription.valid)) {
    return renderSubscriptionButton(false, I18n.t('premium.buttons.upgrade'), handleOnClick, isSuperPremium)
  }
  /* current subscription is renewable, and previous if clauses ensured that the button is for the current subscription, so show cancel button */
  if (subscription.renewable) {
    return renderSubscriptionButton(true, I18n.t('premium.buttons.active'), handleOnClick, isSuperPremium)
  }
  /* final case: already cancelled and no further action is possible */

  return renderSubscriptionButton(false, (
    <>
      {I18n.t('premium.buttons.active_cancelled')}
      <br />
      {I18n.t('premium.buttons.end_at', { date: moment(new Date(subscription.expires)).calendar() })}
      <br />
      {I18n.t('premium.buttons.renew')}
    </>
  ), handleOnClick, isSuperPremium)
}

interface Props {
  type: SubscriptionType
  content: React.ReactElement
  subscription?: Subscription
  myProfile?: Profile
  sessionToken: string
  description: string
  highlight: boolean
  onSetSubscription: Function
}

const PremiumOptionsCard = (props: Props): React.ReactElement => {
  const { type, content, subscription, myProfile, sessionToken, description, highlight } = props
  const [value, setValue] = useState(0)

  useEffect(() => {
    type !== SubscriptionType.FREE && fetchAmount(type, sessionToken).then((response) => {
      setValue(response.data / 100)
    })
  }, [type])

  const handleOnClick: MouseEventHandler = () => {
    props.onSetSubscription({
      type: type,
      subscription: subscription
    })
  }

  /* TODO: move this to common/utils */
  if ((myProfile == null) || (I18n.locale !== myProfile.locale)) {
    return (
      <div className='col l4 m12 s12'>
        <div className='dashboard-card-wrapper'>
          <Spinner ready={false} />
        </div>
      </div>
    )
  }

  const cardClass: string = `premium-card-wrapper ${highlight ? 'highlighted' : ''}`
  const isSuperPremium: boolean = type === SUPER_PREMIUM
  return (
    <div className='col l4 m12 s12'>
      <div className={cardClass}>
        {type !== SubscriptionType.FREE &&
          <PremiumBadge isSuperPremium={isSuperPremium} />}
        <div className='premium-card'>
          <div className='center-align text-xl text-secondary-color text-bold padding-top'>
            {I18n.t(`premium.types.${type}`)}
            <p className='premium-card-price-number'>
              {`${value !== 0 ? `€${value}` : '€0'}`}
              {value !== 0 &&
                <span className='text-m text-heavy'> / {I18n.t('premium.other.month')}</span>}
              {value === 0 &&
                <span className='text-m text-heavy'> ({I18n.t('premium.other.free')})</span>}
            </p>
          </div>
          <div>
            <p>{description}</p>
          </div>
          <div className='horizontal-divider' />
          <div className='premium-card-content'>
            {content}
          </div>
          <div className='premium-button-insets'>
            {getSubscriptionButton(type, subscription, handleOnClick, isSuperPremium)}
          </div>
        </div>
      </div>
    </div>

  )
}

export default WithModal(WithSession(PremiumOptionsCard)) as typeof PremiumOptionsCard
