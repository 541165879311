import React from 'react'
import { useProfileMetadataQuery } from 'components/backend/Queries'

// Not sure how to make this play nice with typescript, hence the any
export default (Component: any) => {
  return (props: unknown) => {
    const { data, refetch: refetchMyProfileMetadata } = useProfileMetadataQuery()

    const childProps = {
      myProfileMetadata: (data?.data.attributes != null) ? data?.data.attributes : {},
      refetchMyProfileMetadata
    }

    return (
      <Component {...childProps} {...props} />
    )
  }
}
