import React from 'react'
import BackButton from '../../../common/BackButton'
import Title from './Title'

interface Props {
  withExpandCompressButton?: boolean
  backButtonProps?: Record<string, any>
}

// The component for a default, non-clickable title with a back button.
const TitleWithBackButton: React.FC<Props> = ({ withExpandCompressButton = false, backButtonProps = {}, children }) => {
  return (
    <Title
      withExpandCompressButton={withExpandCompressButton}
      rowContents={<div className='col s12'><BackButton {...backButtonProps} /></div>}
    >
      {children}
    </Title>
  )
}

export default TitleWithBackButton
