import React from 'react'
import I18n from 'i18n'
import { MyAxios as axios } from '../../MyAxios'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'

class ResetPasswordEdit extends React.Component {
  constructor (props) {
    super(props)
    this.state = { }
  }

  componentDidMount () {
    this.props.logout(false)
    this.props.setHeight(270)
  }

  locationParam (key) {
    let paramIdx = -1
    const params = this.props.location.search.replace('?', '').split(/[=&]/)

    params.forEach((str, i) => {
      if (str === key) {
        paramIdx = i + 1
      }
    })
    return paramIdx >= 0 ? params[paramIdx] : null
  }

  handleSubmit () {
    const password = document.getElementById('password').value
    const passwordConfirm = document.getElementById('password-confirm').value
    const resetPasswordToken = this.locationParam('reset_password_token')
    axios({
      method: this.props.submitMethod,
      url: this.props.endpoint,
      data: { user: { password, password_confirm: passwordConfirm, reset_password_token: resetPasswordToken } }
    }).then((response) => {
      this.handleSuccess(response)
    }).catch((error) => {
      this.handleError(error.response.data)
    })
  }

  handleSuccess (response) {
    this.props.flashMessages.push(
      I18n.t('flashmessages.password_edit.successful'),
      this.props.flashMessages.duration.SHORT,
      this.props.flashMessages.levels.INFO
    )
    this.props.history.push('/login')
  }

  handleError (response) {
    this.props.setHeight(280)
    this.setState({ errors: response.errors })
    response.errors && response.errors.reset_password_token && this.props.flashMessages.push(
      I18n.t('reset_password_edit.error.token_invalid'),
      this.props.flashMessages.duration.SHORT,
      this.props.flashMessages.levels.ERROR
    )
  }

  render () {
    const errors = this.state.errors && this.state.errors[0].detail
    return (
      <div>
        <form>
          <div className='row'>
            <div className='col s12'>
              <div className='input-field'>
                <input
                  name='password'
                  id='password'
                  type='password'
                />
                <label htmlFor='password'>
                  {I18n.t('reset_password.label.password')}
                </label>
                {errors && errors.password && errors.password.map(err => (
                  <span
                    className='helper-text' data-error='wrong' data-success='right' key={err}
                    style={{ color: 'red' }}
                  >
                    {err}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col s12'>
              <div className='input-field'>
                <input
                  name='password-confirm'
                  id='password-confirm'
                  type='password'
                />
                <label htmlFor='password-confirm'>
                  {I18n.t('reset_password.label.password_confirm')}
                </label>
                {errors && errors.password_confirm && errors.password_confirm.map(err => (
                  <span
                    className='helper-text' data-error='wrong' data-success='right' key={err}
                    style={{ color: 'red' }}
                  >
                    {err}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col s12'>
              <button onClick={(e) => { e.preventDefault(); this.handleSubmit() }} className='button-primary background-primary text-background-color waves-effect waves-light'>
                {I18n.t('reset_password.label.submit')}
              </button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default WithFlashMessages(ResetPasswordEdit)
