import React, { useEffect, useState } from 'react'
import PhysiologicalDetailBarView from 'components/profile/layout/PhysiologicalDetailBarView'
import { physiologicalAttributes, MIN_HR, MAX_HR } from '../../../common/Constants'
import I18n from 'i18n'
import EditToggle from 'components/common/EditToggle'
import Slider from 'components/common/Slider'
import MyReactTooltip from 'components/MyReactTooltip'
import { calculateZones, getInnerBounds } from 'components/atomic/organisms/HeartRateZoneEditor/logic'
import _ from 'lodash'

interface Props {
  hrZoneLimits?: number[]
  onZoneLimitsChange: (value: number[]) => void
}

const HeartRateZoneEditor = ({ hrZoneLimits, onZoneLimitsChange }: Props): JSX.Element => {
  const [currentMaxHR, setCurrentMaxHR] = useState<undefined|number>()
  // This controls the toggle between automatic mode (based on max HR) and manual mode (manual control of zone boundary)
  const [hrFromMax, setHrFromMax] = useState<boolean>(false)

  const innerBounds = getInnerBounds(hrZoneLimits)

  const maxHR = currentMaxHR ?? hrZoneLimits?.[hrZoneLimits.length - 1] ?? MAX_HR

  const handleMaxHRChange = (e: React.FormEvent<HTMLInputElement>): void => {
    setCurrentMaxHR(parseInt(e.currentTarget.value))
  }

  const toggleHrSimple = (): void => setHrFromMax(!hrFromMax)

  const handleSliderChange = (e: number[]): void => {
    onZoneLimitsChange([MIN_HR, ...e, maxHR])
  }

  // By default edit mode is set to manual. This might not match the way the user originally set their hr zones (if data
  // already exists). The moment new data comes, we compare the zones against what would result from computing it
  // automatically from the max, and if they are the same we set the mode to automatic
  useEffect(() => {
    if (hrZoneLimits == null) {
      return
    }

    const calculatedZones = calculateZones(hrZoneLimits?.[hrZoneLimits.length - 1])

    if (_.isEqual(calculatedZones, hrZoneLimits)) {
      setHrFromMax(true)
    }
  }, [hrZoneLimits])

  // If the user modifies the max HR, we reset the mode to automatic and recompute the boundaries based on the new max
  useEffect(() => {
    if (currentMaxHR !== undefined) {
      setHrFromMax(true)
      onZoneLimitsChange(calculateZones(currentMaxHR))
    }
  }, [currentMaxHR])

  // If the user switches from manual to automatic mode, we recompute the zones based on the max HR
  useEffect(() => {
    if (hrFromMax && currentMaxHR !== undefined) {
      onZoneLimitsChange(calculateZones(currentMaxHR))
    }
  }, [hrFromMax, currentMaxHR])

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <PhysiologicalDetailBarView
            min={MIN_HR} max={maxHR} measureType={physiologicalAttributes.HR_ZONES}
            limits={innerBounds}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col s12 m6'>
          <div className='input-field'>
            <input
              type='number'
              min={MIN_HR}
              max={MAX_HR}
              name='hr_simple'
              id='hr_simple'
              value={maxHR}
              onChange={handleMaxHRChange}
            />
            <label className='active'>
              {I18n.t('profile.physiological.attributes.max_heart_rate')}
            </label>
          </div>
        </div>
      </div>
      <div className='row no-margin-bottom'>
        <div className='col s12'>
          <EditToggle
            title={hrFromMax ? I18n.t('profile.physiological.simple_input') : I18n.t('profile.physiological.manual_input')}
            id='toggle-simple-hr'
            isChecked={!hrFromMax}
            onChange={toggleHrSimple}
          />
        </div>
      </div>
      <div className='row no-margin-bottom'>
        <div className='col s12' data-for='instruction-tooltip' data-tip={I18n.t('profile.physiological.manual_explanation')}>
          <Slider
            id='hr_range'
            connect={false}
            onChange={handleSliderChange}
            range={[MIN_HR, maxHR]}
            start={innerBounds}
            step={1}
            disabled={hrFromMax}
            title={I18n.t('profile.physiological.attributes.heart_rate_zones')}
          />
        </div>
        {!hrFromMax && <MyReactTooltip id='instruction-tooltip' effect='solid' />}
      </div>
    </>
  )
}

export default HeartRateZoneEditor
