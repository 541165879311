import _, { has } from 'lodash'
import { complaintsQuestionnaire, dailyQuestionnaires, dataTypes, OSTRC_H_ANSWERS_MAP, OSTRC_H_QUESTIONS_MAP, OSTRC_INTRO_ANSWERS_MAP, OSTRC_LETTER_TO_BODY_PART, OSTRC_O_ANSWERS_MAP, OSTRC_O_QUESTIONS_MAP, trainingLog } from '../Constants'
import { nanToZero } from '../Math'
import { formatDateTime, formatDate } from '../Utils'
import I18n from 'i18n'
import moment from 'moment'

export const getQuestionnaireValues = (questionnaire) => {
  return questionnaire?.data_rows?.[0]?.values
}

export const getWellnessScore = (dailyQuestionnaireValues) => {
  const sleep = parseFloat(dailyQuestionnaireValues.v1)
  const fatigue = parseFloat(dailyQuestionnaireValues.v3)
  const stress = parseFloat(dailyQuestionnaireValues.v4)
  const soreness = parseFloat(dailyQuestionnaireValues.v5)
  const mood = parseFloat(dailyQuestionnaireValues.v6)
  const wellnessScore = (nanToZero(sleep) +
    nanToZero(fatigue) +
    nanToZero(stress) +
    nanToZero(soreness) +
    nanToZero(mood)) / 25 * 100
  return wellnessScore
}

export const isQuestionnaire = (vdo) => {
  return vdo?.data_type?.data_type === dataTypes.questionnaire_type
}

// legacy function: uses old object structure
// new version in : QuestionnaireUtilsTS.tsx
export const isDailyQuestionnaire = questionnaire => {
  return dailyQuestionnaires.includes(questionnaire?.data_rows?.[0]?.questionnaire?.key)
}

// legacy function: uses old object structure
// new version in : QuestionnaireUtilsTS.tsx
export const isTrainingLog = questionnaire => {
  return questionnaire?.data_rows?.[0]?.questionnaire?.key === trainingLog &&
    questionnaire.data_rows[0].values?.v1 && questionnaire.data_rows[0].values.v1.length > 0 &&
    questionnaire.data_rows[0].values?.v2 && questionnaire.data_rows[0].values.v2.length > 0
}

export const isComplaintsQuestionnaire = questionnaire => {
  return questionnaire?.data_rows?.[0]?.questionnaire?.key?.includes(complaintsQuestionnaire)
}

export const readDailyQuestionnaire = (questionnaireValues) => {
  const parsedData = {}
  if (questionnaireValues) {
    parsedData.sleepQuality = questionnaireValues.v1
    parsedData.sleepDuration = {
      hours: questionnaireValues.v2_uren,
      minutes: questionnaireValues.v2_minuten
    }
    parsedData.fatigue = questionnaireValues.v3
    parsedData.stress = questionnaireValues.v4
    parsedData.generalMusclePain = questionnaireValues.v5
    parsedData.mood = questionnaireValues.v6
    parsedData.readiness = questionnaireValues.v7
    parsedData.restingHr = questionnaireValues.v8
    parsedData.isSick = ['yes', 'ja'].includes(questionnaireValues.v9)
    parsedData.isInjured = ['yes', 'ja'].includes(questionnaireValues.v10)
    parsedData.comments = questionnaireValues.v11
    parsedData.wellnessScore = getWellnessScore(questionnaireValues)
  }
  return parsedData
}

export const readTrainingQuestionnaire = (questionnaireValues) => {
  const readData = {}
  if (questionnaireValues) {
    readData.training_type = questionnaireValues.v1
    readData.session_type = questionnaireValues.v2
    readData.duration = questionnaireValues.v5 * 60 // Transform min into sec, for consistency
    readData.startedAt = {
      date: questionnaireValues.v3,
      hours: questionnaireValues.v4_uur,
      minutes: questionnaireValues.v4_minuten
    }
    readData.rpe = questionnaireValues.v6
    readData.satisfaction = questionnaireValues.v7
    readData.comments = questionnaireValues.v8
  }
  return readData
}

const readComplaintsHealthSection = (questionnaireValues) => {
  const filteredValues = _.pickBy(questionnaireValues, (value, key) => {
    return key.match(/^v[1-7]/) && !key.match(/timing$/)
  })

  const parsedValues = {}
  _.entries(filteredValues).forEach(([key, value]) => {
    const questionNumber = key[1]
    const parsedQuestion = OSTRC_H_QUESTIONS_MAP[questionNumber]

    if (questionNumber === '6') {
      const questionParameters = key.split(/_(.+)/) // question = 'v<question_number>_<extra_stuff>'
      const symptomId = questionParameters[1]
      let parsedAnswer = ''
      if (symptomId !== undefined) {
        parsedAnswer = OSTRC_H_ANSWERS_MAP[questionNumber][symptomId]
        if (parsedAnswer !== 'other') { // When the user picks "Other, namely: ___", then we need to handle it differently: we have to check for the "text" key, which has the user-filled symptom
          if (symptomId.includes('text')) { // The text field of the "Other" option
            parsedValues[parsedQuestion] = _.union(parsedValues[parsedQuestion], [value], [])
          } else {
            parsedValues[parsedQuestion] = _.union(parsedValues[parsedQuestion], [parsedAnswer], [])
          }
        }
      }
    } else { // Question 6 is a checkbox, so we need to handle it differently
      const parsedAnswer = OSTRC_H_ANSWERS_MAP[questionNumber][value]
      parsedValues[parsedQuestion] = parsedAnswer
    }
  })

  parsedValues.score = parseInt(questionnaireValues.s_h_1)

  return parsedValues
}

const readComplaintsInjurySection = (questionnaireValues) => {
  const filteredValues = _.pickBy(questionnaireValues, (value, key) => {
    //  This regex will not work on safari. Left here as a reminder
    // return key.match(/^v_o_[a-z]_\d+[a-z]?(_[a-z]+)*(?<!_timing)$/)
    return key.match(/^v_o_[a-z]_\d+[a-z]?(_[a-z]+)*/) && !key.match(/timing$/)
  })

  const parsedValues = {}
  _.entries(filteredValues).forEach(([key, value]) => {
    const questionParameters = key.split('_') // question = 'v_o_<letter>_<question_number>'

    // Parse the body part
    const bodyLetter = questionParameters[2]
    const parsedBodyPart = OSTRC_LETTER_TO_BODY_PART[bodyLetter]

    // Parse the questions and answer into something understandable
    const questionId = questionParameters[3]
    const parsedQuestion = OSTRC_O_QUESTIONS_MAP[questionId]
    const parsedAnswer = parseOstrcOAnswers(questionId, value)

    // If 9a exists, then the answer to 9 is true
    if (questionId === '9a') {
      parsedValues[parsedBodyPart][OSTRC_O_QUESTIONS_MAP[9]] = true
    }

    // Add the parsed question/answer to the section of the dataset relative to that body part
    parsedValues[parsedBodyPart] = _.assign(parsedValues[parsedBodyPart], { [parsedQuestion]: parsedAnswer })
  })

  const otherBodyPart = questionnaireValues.v_o_i_0
  if (otherBodyPart) {
    parsedValues.other.bodyPart = otherBodyPart
  }

  _.entries(parsedValues).forEach(([bodyPart, answers]) => {
    answers.score = _.sum([
      answers.participationDifficulty,
      answers.trainingAdjustment,
      answers.performanceInfluence,
      answers.painAmount
    ])
  })

  return parsedValues
}

const parseOstrcOAnswers = (questionId, answer) => {
  switch (questionId) {
    case '1':
    case '2':
    case '3':
    case '4':
    case '5':
    case '6':
    case '8':
    case '12':
      return OSTRC_O_ANSWERS_MAP[questionId][answer]
    case '13':
    case '7':
      return OSTRC_O_ANSWERS_MAP[questionId][answer] || answer // In the case they filled in the "Others" field
    case '9':
      return !answer // true = "I dont want to share", so sharedTheCauseOfInjury = false
    case '9a':
    case '10':
    case '11':
      return answer
    default:
      return undefined
  }
}

export const readComplaintsQuestionnaire = (questionnaireValues) => {
  const readData = {}
  if (questionnaireValues) {
    readData.complaints = OSTRC_INTRO_ANSWERS_MAP[questionnaireValues.v0] || OSTRC_INTRO_ANSWERS_MAP[questionnaireValues.v_o_1] || null
    readData.illness = null
    readData.injury = null
    readData.hoursOfExerciseLastWeek = parseInt(questionnaireValues.v_o_3)

    if (readData?.complaints?.includes('illness')) {
      readData.illness = readComplaintsHealthSection(questionnaireValues)
    }

    if (readData?.complaints?.includes('injury')) { // Parse the big list of questions
      readData.injury = readComplaintsInjurySection(questionnaireValues)
    }
  }

  return readData
}

const cleanupString = (str) => {
  if (!str || typeof (str) !== 'string') return str

  return str.replace(/<br ?\/>/gi, '  ').replace(/(<([^>]+)>)/gi, '').replace(/&hellip;/gi, '...')
}

export const readGenericQuestionnaire = (questionnaireData, questionnaireDefinition, locale) => {
  const questions = _.filter(questionnaireDefinition.content.questions,
    (q) => {
      const answeredQIds = _.map(Object.keys(questionnaireData.values), id => id.replace('_uren', ''))
      return _.includes(answeredQIds, q.id)
    })

  const getQuestionName = (question) => {
    const title = _.get(question, `title.${locale}`, question.title)
    switch (question.type) {
      case 'range':
        return `${title} (${parseFloat(question.min).toFixed(1)} - ${parseFloat(question.max).toFixed(1)})`
      default:
        return title
    }
  }

  const getQuestionValue = (question) => {
    switch (question.type) {
      // TODO: add more options
      // case 'checkbox':
      case 'date': {
        const dateAnswer = _.get(questionnaireData.values, question.id)
        return formatDate(dateAnswer)
      }
      case 'date_and_time': {
        const dateAnswer = _.get(questionnaireData.values, question.id, '')
        const hoursAnswer = _.get(questionnaireData.values, question.hours_id, '00')
        const minutesAnswer = _.get(questionnaireData.values, question.minutes_id, '00')
        return formatDateTime(`${dateAnswer} ${hoursAnswer}:${minutesAnswer}`)
      }
      case 'time': {
        const hoursAnswer = _.get(questionnaireData.values, `${question.id}_uren`)
        const hoursUnit = _.get(question.hours_label, locale, 'h')
        const minutesAnswer = _.get(questionnaireData.values, `${question.id}_minuten`)
        const minutesUnit = _.get(question.minutes_label, locale, 'm')
        return `${hoursAnswer} ${hoursUnit} ${minutesAnswer} ${minutesUnit}`
      }
      case 'number': {
        const numberAnswer = _.get(questionnaireData.values, question.id)
        return parseFloat(numberAnswer)
      }
      case 'range': {
        const rangeAnswer = _.get(questionnaireData.values, question.id)
        return parseFloat(rangeAnswer).toFixed(1)
      }
      case 'radio': {
        // Support that the otherwise label may be used as one of the options
        // WARNING: Currently, the platform stores the otherwise label as the answer, and not the otherwise value written in the text box.
        // This code works for this situation, but if it changes, then the code must also take into account the otherwise value.
        const extendedQuestionOptions = [...question.options]
        if (question.otherwise_label) extendedQuestionOptions.push(question.otherwise_label)

        const isString = (option) => typeof option === 'string' || option instanceof String
        const isOptionTheChosenAnswer = (option) => {
          // optionValues is an array because an option can have multiple values, one for each language (depending on the option format)
          let optionValues = []
          // Question options might come in different formats. Below we retrieve the value of the option according to its format
          if (has(option, 'value')) { // [ { title: { nl: "Een", en: "One" }, value: "one" }, { title: { nl: "Twee", en: "Two" }, value: "two" } ]
            optionValues = [option.value]
          } else if (has(option, 'title')) { // [ { title: { nl: "Een", en: "One" } }, { title: { nl: "Twee", en: "Two" } } ]
            optionValues = _.values(option.title)
          } else if (isString(option)) { // ["one", "two"]
            optionValues = [option]
          } else { // [ { nl: "Een", en: "One" }, { nl: "Twee", en: "Two" } ]
            optionValues = _.values(option)
          }
          const answeredValue = _.get(questionnaireData.values, question.id)
          return _.includes(optionValues, answeredValue)
        }

        const chosenOption = _.find(extendedQuestionOptions, isOptionTheChosenAnswer)
        const chosenOptionTitle = _.get(chosenOption, `title.${locale}`, _.get(chosenOption, locale, chosenOption))
        return _.capitalize(chosenOptionTitle)
      }
      case 'dropdown': {
        const rawAnswer = _.get(questionnaireData.values, question.id)
        const answerOption = _.find(question.options, { [questionnaireData.values.locale]: rawAnswer })
        const translatedAnswer = _.get(answerOption, locale, rawAnswer)
        return I18n.t(rawAnswer, { locale, defaultValue: translatedAnswer })
      }
      default:
        return _.get(questionnaireData.values, question.id)
    }
  }

  return _.map(questions,
    (question) => {
      const questionName = cleanupString(getQuestionName(question))
      const questionValue = cleanupString(getQuestionValue(question))
      return { name: questionName, value: questionValue }
    }
  )
}

// Calculate the start date of a protocol subscription based on one of its response VDOs (using the included
// protocol_completion). The given `period` argument can be either 'weeks' or 'days', corresponding to whether
// the questionnaire is asked weekly or daily. If no start date could be found, it returns the current date (`moment()`).
// This function always returns a Moment object.
export const protocolSubscriptionStartDate = (questionnaireVdo, period) => {
  const nrCompletedMeasurements = questionnaireVdo.data_rows[0]?.protocol_completion?.filter(protComp => !protComp.future)?.length
  if (!nrCompletedMeasurements) return moment()
  // Assume that the questionnaire is asked once per period, so subtract from the current open_from datetime,
  // as many periods as there were measurements before the current measurement.
  return moment(questionnaireVdo.data_rows[0].open_from).subtract(nrCompletedMeasurements - 1, period)
}

// This function uses `protocolSubscriptionStartDate` to return the earliest start date of the protocol
// subscriptions found in the `questionnaireVdos`. This uses protocol_completions embedded in the
// questionnaire responses. Period is the period of the questionnaire and should be either 'weeks' or 'days'.
// If no start date could be found, the function returns null.
// Otherwise it returns a Date object.
export const earliestProtocolSubscriptionStartDate = (questionnaireVdos, period) => {
  let minDate = new Date()
  let flipped = false
  for (const questionnaireVdo of questionnaireVdos) {
    const protSubStartDate = protocolSubscriptionStartDate(questionnaireVdo, period)
    if (protSubStartDate.toDate() < minDate) {
      minDate = protSubStartDate.toDate()
      flipped = true
    }
  }
  return flipped ? minDate : null
}

export const filterOutTimings = (questionnaireValues) => {
  return _.pickBy(questionnaireValues, (value, key) => {
    return !key.match(/timing$/)
  })
}

// Gets either an ostrc injury or illness object and returns whether the entry corresponds to a severe complaint or not
export const isSevereComplaint = (complaint) => (
  complaint.daysWithoutParticipation >= 1 ||
    complaint.trainingAdjustment === 100 ||
    complaint.participationDificulty === 100 ||
    complaint.performanceInfluence === 100
)
