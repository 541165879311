import React from 'react'
import CheckBox from '../../../common/form/CheckBox'
import I18n from 'i18n'
import moment from 'moment'
import { momentDateFormat } from '../../../common/Constants'

const PremiumTableRow = (props) => {
  const stripVoucher = (code) => {
    return code.replaceAll(/([^a-zA-Z0-9])/g, '').substring(0, 12)
  }

  const expiresFormatted = props.profile.subscription.expires ? moment(props.profile.subscription.expires).format(momentDateFormat) : I18n.t('premium-table.row.never')
  return (
    <tr>
      <td>{props.profile.email}</td>
      <td>{I18n.t(`premium.types.${props.profile.subscription.type}`)}</td>
      <td>{expiresFormatted}</td>
      <td>{props.profile.subscription.infinite ? I18n.t('premium-table.row.infinite') : I18n.t('premium-table.row.not_infinite')}</td>
      <td>
        <CheckBox
          fieldName='select-subscription'
          formName='subscriptions'
          component='checkbox'
          showLabel={false}
          noWrappers
          currentValue={props.isSelected}
          onChange={() => { props.selectProfile(props.profile.id) }}
        />
      </td>
      <td>
        <input
          className='input-field'
          name='apply-voucher'
          type='text'
          placeholder={I18n.t('premium-table.field.text.apply-voucher')}
          onChange={(e) => props.setVoucherCode(props.profile.id, e.target.value)}
        />
      </td>
      <td>
        <button onClick={() => props.redeemVoucherCode(props.profile.id, stripVoucher(props.voucher))} className='button-primary background-primary text-background-color waves-effect waves-light'>{I18n.t('premium-table.button.text.apply-voucher')}</button>
      </td>
    </tr>
  )
}

export default PremiumTableRow
