import React from 'react'

const Label = (props) => {
  const { text, isActive } = props

  const className = isActive ? 'active' : ''
  return (
    <label className={className}>{text}</label>
  )
}

export default Label
