import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import I18n from 'i18n'
import { Line } from 'react-chartjs-2'
import _ from 'lodash'
import { dateStringToDayMonthYear } from '../../../../common/Utils'
import {
  chartjsDefaultArguments,
  chartjsDefaultOptions,
  chartjsScaleLabelProperties,
  chartjsTitleProperties
} from '../../../../common/Constants'
import Variables from '../../../../../stylesheets/variables.module.scss'
import { LOAD_COUNT, LOAD_DISTANCE, LOAD_DURATION } from './TrainingLoadChart'
import { m2km, s2hm } from '../../../../common/Units'
const { secondaryColor, primaryColor } = Variables

function computeCumulativeTrainingLoad (trainingLoad, loadType) {
  const cumulativeLoad = []
  const dates = []
  // Each element in trainingLoad is an ordered list of training load entries (ordered by the date of the first day of
  // the week where the aggregated training data took place). There might be duplicate dates, if in a week trainings of
  // different types have taken place (e.g. outdoor and indoor running)
  trainingLoad.forEach(entry => {
    const lastDate = dates[dates.length - 1]

    // This date format is necessary to not break the x-axis slider (otherwise there are might be repeated dates)
    const date = dateStringToDayMonthYear(_.get(entry, '_id.date'))
    const load = _.get(entry, loadType)
    const lastCumulativeLoad = cumulativeLoad[cumulativeLoad.length - 1] || 0
    if (date === lastDate) {
      // We've encountered a repeated day, because 2 activities of different kind took place in the same week. Add them
      // to the last element of the array (which represents the current week)
      cumulativeLoad[cumulativeLoad.length - 1] += load
    } else {
      // The date is new so we've moved on to a date not seen so far, so append a new entry and compute the cumulative
      // value
      dates.push(date)
      cumulativeLoad.push(lastCumulativeLoad + load)
    }
  })
  if (dates.length < 1) dates.push('0')
  return [cumulativeLoad, dates]
}

const CumulativeTrainingLoadChart = (props) => {
  const [cumulativeLoad, previousCumulativeLoad, labels] = useMemo(() => {
    const [cumulativeLoad = [], labels = []] = computeCumulativeTrainingLoad(props.trainingLoad, props.loadType)
    // Since cumulativeLoad and previousCumulativeLoad have the same number of elements we can use the labels of the cumulativeLoad
    const [previousCumulativeLoad = []] = computeCumulativeTrainingLoad(props.previousTrainingLoad, props.loadType)
    return [cumulativeLoad, previousCumulativeLoad, labels]
  }, [props.trainingLoad, props.loadType])

  const datasets = [{
    label: I18n.t('components.dashboards.multi_activity.cumulative_load.current_season'),
    data: cumulativeLoad,
    backgroundColor: secondaryColor,
    borderColor: secondaryColor,
    fill: false,
    lineTension: 0,
    pointRadius: 0
  }, {
    label: I18n.t('components.dashboards.multi_activity.cumulative_load.previous_season'),
    data: previousCumulativeLoad,
    backgroundColor: primaryColor,
    borderColor: primaryColor,
    fill: false,
    lineTension: 0,
    pointRadius: 0
  }]

  const data = {
    labels: labels,
    datasets: datasets
  }

  function formatLoadType (v) {
    switch (props.loadType) {
      case LOAD_DISTANCE:
        return `${m2km(v).toFixed(0)} ${I18n.t('units.km')}`
      case LOAD_DURATION:
        return s2hm(v)
      case LOAD_COUNT:
        return v
    }
  }

  const yLabel = useMemo(() => {
    let label = I18n.t(`components.dashboards.multi_activity.load.${props.loadType}`)
    if (props.loadType === LOAD_DURATION) {
      label += ` (${I18n.t('units.h')}:${I18n.t('units.min')})`
    }
    return label
  }, [props.loadType])

  const range = props.range?.map((idx) => labels[idx])

  const options = {
    ...chartjsDefaultOptions,
    title: {
      ...chartjsTitleProperties,
      text: I18n.t('components.dashboards.multi_activity.cumulative_load.title')
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: ti => formatLoadType(ti.value)
      }
    },
    plugins: {
      crosshair: false
    },
    scales: {
      xAxes: [{
        ticks: {
          fontSize: 17.5,
          min: range?.[0],
          max: range?.[1]
        }
      }],
      yAxes: [{
        ticks: {
          callback: v => formatLoadType(v)
        },
        scaleLabel: {
          labelString: yLabel,
          ...chartjsScaleLabelProperties
        }
      }]
    }
  }

  return (
    <div className='chartjs-container'>
      <Line {...chartjsDefaultArguments} data={data} options={options} redraw />
    </div>
  )
}

CumulativeTrainingLoadChart.propTypes = {
  trainingLoad: PropTypes.array,
  previousTrainingLoad: PropTypes.array,
  loadType: PropTypes.string,
  range: PropTypes.array
}

CumulativeTrainingLoadChart.defaultProps = {
  trainingLoad: [],
  previousTrainingLoad: []
}

export default CumulativeTrainingLoadChart
