import React from 'react'
import { WithBackend } from '../backend/BackendProvider'
import { FEATURE_FLAG_OPTIONS } from './FeatureFlag'
export const FeatureFlagContext = React.createContext()

class FeatureFlagProviderCls extends React.Component {
  constructor () {
    super()
    this.state = { features: {} }
  }

  async componentDidMount () {
    try {
      const features = await this.props.backend.checkFeatures(FEATURE_FLAG_OPTIONS)
      const result = features.data.reduce((agg, cur) => {
        agg[cur.feature] = cur.enabled
        return (agg)
      }, {})
      this.setState({ features: result })
    } catch (error) {
      // We're not logging the unauthorized messages, as this is not
      // interesting for people.
      if (error.response.status !== 401) {
        console.error(error)
      }
    }
  }

  render () {
    return (
      <FeatureFlagContext.Provider value={{ features: this.state.features }}>
        {this.props.children}
      </FeatureFlagContext.Provider>
    )
  }
}

export const FeatureFlagProvider = WithBackend(FeatureFlagProviderCls)

export const WithFeatureFlag = Component => props => {
  return (
    <FeatureFlagContext.Consumer>
      {(context) => (<Component {...context} {...props} />)}
    </FeatureFlagContext.Consumer>
  )
}
