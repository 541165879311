import React, { useMemo, useState } from 'react'
import Dashboard from '../../layout/Dashboard'
import { collectionTypes, subscriptionTypes } from '../../../common/Constants'
import I18n from 'i18n'
import Spinner from '../../../common/Spinner'
import { VariableSelector, getVariables } from './CsvVariableSelector'
import CsvGraph from './CsvGraph'
import { CsvDetailBuilder } from '../../../data/layout/detailedview/CsvDetailView'
import _ from 'lodash'
import { WithSession } from '../../../session/SessionProvider'
import { MyAxios as axios } from '../../../MyAxios'
import PremiumOverlay from '../../../premium_membership/PremiumOverlay'

const CsvDashboard = (props) => {
  /**
   * A CSV consists of a summary (number of rows and columns), a description for each column and a list of rows.
   * These parameters for the currently selected CSV are stored in the dashboard's state
   */
  const [summary, setSummary] = useState(undefined)
  const [rows, setRows] = useState([])
  const description = useMemo(() => _.get(summary, 'columns_description', []), [summary])

  const summaryBuilder = new CsvDetailBuilder(summary, rows)
  /**
   * A list of graph descriptions of the format {x: {name: String, type: String, label: String}, y: {name: String, type: String, label: String}}
   * this information is used to retrieve the correct columns from the CSV
   */
  const [graphs, setGraphs] = useState([])
  /**
   * The currently selected X and Y variables are stored in state
   */
  const [selectedY, selectY] = useState(undefined)
  const [selectedX, selectX] = useState(undefined)
  /**
   * hasCsv is used to determine whether the user should see a loading animation or a variable selection form
   */
  const [hasCsv, setHasCsv] = useState(false)

  /**
   * Reset this component's initial variable state
   */
  const clearVariables = () => {
    selectX(undefined)
    selectY(undefined)
    const elem = document.getElementById('variable-selector')
    if (!elem) return

    const instance = M.FormSelect.getInstance(elem)
    instance.el[0].selected = true
    M.FormSelect.init(elem)
  }

  /**
   * Remove all rendered graphs from state and reset the variable selection form
   */
  const clearGraphs = () => { setGraphs([]); clearVariables() }

  /**
   * Update the variable descriptions for a specific graph
   */
  const handleUpdateGraph = (graphIdx, newX) => {
    setGraphs(graphs.map((g, idx) => (idx === graphIdx && { x: newX, y: g.y }) || g))
  }

  const handleFileSelected = (vdo) => {
    setSummary(_.get(vdo, 'summary'))

    axios({
      method: 'GET',
      url: `/api/v1/data/${vdo.id}/csv_rows`,
      headers: { Authorization: props.sessionToken }
    }).then(res => {
      setRows(res.data)
      setHasCsv(true)
    })

    clearGraphs()
  }

  /**
   * Retrieve numerical and categorical variables from the column descriptions
   */
  const numericalVariables = getVariables(description, 'numeric')
  const categoricalVariables = getVariables(description, 'categorical')
  return (
    <div className='row'>
      <PremiumOverlay activeFor={[subscriptionTypes.researcher]} showBadge={false}>
        <Dashboard
          title={I18n.t('components.visualization.csv.title.dashboard')}
          onFileSelect={handleFileSelected}
          onBeforeFetchVdo={() => { setHasCsv(false) }}
          collectionType={collectionTypes.CSV_FILE}
          history={props.history}
          ready
          {...props}
        >
          <div className='row'>
            <div className='col s12'>
              {hasCsv && (
                <>
                  <VariableSelector
                    numerical={numericalVariables}
                    categorical={categoricalVariables}
                    onSelectY={selectY}
                    onSelectX={selectX}
                    selectedY={selectedY}
                    selectedX={selectedX}
                    onAddGraph={(selectedX, selectedY) => { setGraphs([...graphs, { x: selectedX, y: selectedY }]); clearVariables() }}
                  />
                  {graphs.map((g, idx) => <CsvGraph
                    key={`${idx}-${g.x.type}`}
                    summaryBuilder={summaryBuilder}
                    onUpdateGraph={handleUpdateGraph}
                    graphIdx={idx}
                    data={rows}
                    description={description}
                    {...g}
                  /* eslint-disable react/jsx-closing-bracket-location */
                  />
                  /* eslint-enable react/jsx-closing-bracket-location */
                  )}
                </>
              )}
              {!hasCsv && <Spinner />}
            </div>
          </div>
        </Dashboard>
      </PremiumOverlay>
    </div>
  )
}

export default WithSession(CsvDashboard)
