import React from 'react'
import { Route } from 'react-router'
import PrivacyStatement from './layout/PrivacyStatement'
import TermsAndConditions from './layout/TermsAndConditions'
import NotFoundWrapper from '../router/NotFoundWrapper'
import Routes from '../common/Routes'
import QuestionnaireLanding from './layout/QuestionnaireLanding'
import AcceptTermsAndConditions from './layout/AcceptTermsAndConditions'
import DeveloperDocs from './layout/DeveloperDocs'
import GettingStartedGuide from './layout/GettingStartedGuide'

export default class StaticRoutes extends React.Component {
  render () {
    // The enlish version should be used, but as this page is also referred to
    // from external pages, leave the old (Dutch) version in too.
    const termsAndConditionRoutes = [Routes.static.termsAndConditionsVersionlessDutchLegacy, Routes.static.termsAndConditionsVersionless]
    return (
      <NotFoundWrapper slug='static'>
        <Route exact path={Routes.static.privacyStatement} render={(props) => (<PrivacyStatement {...props} />)} />
        <Route exact path={termsAndConditionRoutes} render={(props) => (<TermsAndConditions {...props} />)} />
        <Route exact path={Routes.static.acceptTerms} render={(props) => (<AcceptTermsAndConditions {...props} />)} />
        <Route exact path={Routes.static.questionnaires} render={(props) => (<QuestionnaireLanding {...props} />)} />
        <Route exact path={Routes.developer.index} render={(props) => (<DeveloperDocs {...props} />)} />
        <Route exact path={Routes.developer.gettingStarted} render={(props) => (<GettingStartedGuide {...props} />)} />
      </NotFoundWrapper>
    )
  }
}
