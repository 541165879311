import React from 'react'
import _ from 'lodash'
import I18n from 'i18n'
import { WithErrorBoundary } from '../../../errorboundary/ErrorBoundaryProvider'
import { HorizontalBar } from 'react-chartjs-2'
import Variables from '../../../../stylesheets/variables.module.scss'
const { greenDark } = Variables

const PredictionCoefficientChart = ({ data }) => {
  const renderNoData = () => {
    return <p className='text-s text-muted'>{I18n.t('components.dashboards.steps.predictions.not_available')}</p>
  }

  if (_.isEmpty(data)) return renderNoData()
  if (data.length < 1) return renderNoData()

  const labels = data.map((entry) => I18n.t(`components.dashboards.steps.predictions.variables.${entry.name}`))
  const values = data.map((entry) => entry.coefficient)
  const backgroundColors = data.map((entry) => entry.coefficient < 0 ? greenDark : '#be1c12')

  const datasets = {
    labels: labels,
    datasets: [
      {
        label: I18n.t('components.dashboards.steps.predictions.coefficients.graph_title'),
        backgroundColor: backgroundColors,
        data: values
      }
    ]
  }
  const options = {
    legend: {
      display: false
    }
  }

  return (
    <>
      <p className='text-heavy text-l text-muted data-header'>{I18n.t('components.dashboards.steps.predictions.coefficients.title')}</p>
      <p>{I18n.t('components.dashboards.steps.predictions.coefficients.subtitle')}</p>
      <HorizontalBar data={datasets} options={options} />
    </>
  )
}

export default WithErrorBoundary(PredictionCoefficientChart, 'components.dashboards.steps.predictions.not_available')
