import React from 'react'

// This component is only used for testing whether Appsignal picks up JS errors
export default class Fail extends React.Component {
  render () {
    const a = undefined
    a.anyMethod()
    return <div> I fail </div>
  }
}
