import React from 'react'
import { HorizontalBar } from 'react-chartjs-2'
import PropTypes from 'prop-types'
import { identity } from '../../../common/Units'
import { chartjsDefaultOptions, chartjsTitleProperties } from '../../../common/Constants'
import I18n from 'i18n'

const DisciplineHoursChart = (props) => {
  const { tickCallback = identity, leftPadding = 80 } = props

  const data = {
    labels: props.disciplines.map(discipline => discipline.name),
    datasets: [{
      backgroundColor: props.disciplines.map(d => d.color),
      data: props.disciplines.map(d => d.duration),
      barThickness: 25
    }]
  }

  const options = {
    ...chartjsDefaultOptions,
    title: { text: `${I18n.t('components.dashboards.triathlon.discipline_hours.title')} (${props.durationLegend})`, ...chartjsTitleProperties },
    legend: { display: false },
    layout: {
      padding: {
        left: leftPadding
      }
    },
    scales: {
      xAxes: [{ display: false, ticks: { beginAtZero: true } }],
      yAxes: [
        { gridLines: { display: false }, ticks: { mirror: true, padding: leftPadding } },
        {
          type: 'category',
          labels: props.disciplines.map(d => tickCallback(d.duration)),
          position: 'right',
          offset: true,
          gridLines: { display: false }
        }
      ]
    },
    tooltips: { enabled: false }
  }

  return (
    <div className='chartjs-container'>
      <HorizontalBar data={data} options={options} />
    </div>
  )
}

DisciplineHoursChart.propTypes = {
  disciplines: PropTypes.arrayOf(PropTypes.shape({
    // The name of the discipline to be displayed
    name: PropTypes.string,
    // Amount of time spent on the discipline. Unit agnostic
    duration: PropTypes.number,
    color: PropTypes.string
  })),
  durationLegend: PropTypes.string,
  tickCallback: PropTypes.func,
  leftPadding: PropTypes.number
}

export default DisciplineHoursChart
