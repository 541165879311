import React from 'react'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FeatureFlag, { QUESTIONNAIRES_AUTOMATIC_GROUP_JOIN_PROTOCOL } from '../../feature_flags/FeatureFlag'
import _ from 'lodash'
import {
  dateAndTimePickerDefaultOptions,
  datePickerDefaultOptions,
  DEFAULT_PROTOCOL_DURATION
} from '../../common/Constants'
import DatePicker from 'react-datepicker'

import SelectMultiple from '../../common/form/SelectMultiple'
import { WithSession } from '../../session/SessionProvider'
import moment from 'moment'
import ProtocolPreview from './ProtocolPreview'
import classNames from 'classnames'
import { round } from '../../common/Math'
import Toggle from '../../atomic/atoms/Toggle'
import GroupProtocolConfiguration from '../../atomic/organisms/GroupProtocolConfiguration'
import ProtocolSelectionDropdown from '../../atomic/molecules/ProtocolSelectionDropdown'
import InvitationTextConfiguration from '../../atomic/organisms/InvitationTextConfiguration'

const endOfDay = date => {
  return moment(date).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toDate()
}
const endOfPreviousDay = date => {
  return moment(date).subtract(1, 'days').set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toDate()
}

class CreateProtocolSubscription extends React.Component {
  constructor (props) {
    super(props)
    // Set a default protocol duration of 30 days
    const defaultProtocolEndDate = new Date()
    defaultProtocolEndDate.setDate(defaultProtocolEndDate.getDate() + DEFAULT_PROTOCOL_DURATION)
    this.state = {
      forGroup: false,
      deployed: false,
      startDate: new Date(),
      endDate: endOfPreviousDay(defaultProtocolEndDate),
      members: props.members.map(member => `${member.id}`),
      protocolDuration: DEFAULT_PROTOCOL_DURATION * 86400,
      selectedProtocol: undefined,
      durationInDays: DEFAULT_PROTOCOL_DURATION, // Only used for the group protocol subscriptions,
      invitationTextEn: `You have been invited by ${this.props.myProfile?.first_name} ${this.props.myProfile?.last_name} to fill out a questionnaire for group "${this.props.group.name}"`,
      invitationTextNl: `Je bent door ${this.props.myProfile?.first_name} ${this.props.myProfile?.last_name} uitgenodigd om een vragenlijst in te vullen voor groep "${this.props.group.name}"`,
      responses: undefined
    }
  }

  newStartDate (selectedProtocol) {
    // Special case for periodic, daily protocols
    if (['daily_protocol', 'ostrc_o', 'ostrc_h_o', 'weekly_wellbeing'].includes(selectedProtocol)) {
      return moment().add(1, 'days').set({ hour: 7, minute: 0, second: 0, millisecond: 0 }).toDate()
    }
    if (['daily_protocol_rheumatism'].includes(selectedProtocol)) {
      return moment().add(1, 'days').set({ hour: 19, minute: 0, second: 0, millisecond: 0 }).toDate()
    }
    return new Date()
  }

  setResponses (newResponses) {
    this.setState({ responses: newResponses })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevState.deployed !== this.state.deployed && this.state.deployed) {
      const elems = document.querySelectorAll('#protocol-name')
      M.FormSelect.init(elems)
      M.updateTextFields()
    }
    if (!this.state.selectedProtocol &&
      prevProps.availableProtocols !== this.props.availableProtocols &&
      this.props.availableProtocols &&
      this.props.availableProtocols.length > 0) {
      const firstProtocolName = _.get(this.props.availableProtocols, '[0].name')
      this.handleProtocolChange({ target: { value: firstProtocolName } })
    }
  }

  handleGroupProtocolCreation () {
    this.props.groupProtocolSubscriptionHandlers.handleCreateGroupProtocolSubscriptions(
      this.props.group.id,
      this.state.selectedProtocol,
      this.state.durationInDays,
      this.state.invitationTextNl,
      this.state.invitationTextEn,
      true
    )
  }

  handleMemberProtocolCreation () {
    if (!this.state.members || this.state.members.length === 0) return
    this.props.onCreateProtocolSubscriptions(
      this.props.group.id,
      this.state.selectedProtocol,
      this.state.startDate,
      this.calcDisableEndDate() ? null : this.state.endDate,
      this.state.members,
      this.state.invitationTextNl,
      this.state.invitationTextEn
    )
  }

  handleClick () {
    if (!this.state.deployed) {
      this.setState({ deployed: !this.state.deployed })
      return this.setState({ members: this.props.members.map(member => `${member.id}`) })
    }
    if (this.state.forGroup) {
      this.handleGroupProtocolCreation()
    } else {
      this.handleMemberProtocolCreation()
    }

    this.setState({ deployed: !this.state.deployed })
  }

  handleChangeStartDate (date) {
    let protocolDuration = this.props.availableProtocols.filter(protocol => protocol.name === this.state.selectedProtocol)[0]?.duration
    if (!protocolDuration) {
      protocolDuration = DEFAULT_PROTOCOL_DURATION * 86400
    }
    this.setState({
      startDate: date,
      endDate: endOfPreviousDay(moment(Math.max(date, new Date().getTime())).add(protocolDuration, 'seconds').toDate()),
      protocolDuration: protocolDuration
    })
  }

  handleChangeEndDate (date) {
    if (!date) return

    this.setState({ endDate: endOfDay(date) })
  }

  handleProtocolChange (e) {
    const selectedProtocol = e.target.value
    const currentStartDate = this.newStartDate(selectedProtocol)
    let protocolDuration = this.props.availableProtocols.filter(protocol => protocol.name === selectedProtocol)[0]?.duration
    if (!protocolDuration) {
      protocolDuration = DEFAULT_PROTOCOL_DURATION * 86400
    }
    this.setState({
      startDate: currentStartDate,
      endDate: endOfPreviousDay(moment(currentStartDate).add(protocolDuration, 'seconds').toDate()),
      protocolDuration: protocolDuration,
      selectedProtocol: selectedProtocol
    })
  }

  handleDurationChange (value) {
    this.setState({
      durationInDays: value
    })
  }

  // Returns default protocol duration in seconds.
  defaultProtocolDuration () {
    let protocolDuration = this.props.availableProtocols.filter(protocol => protocol.name === this.state.selectedProtocol)[0]?.duration
    if (!protocolDuration) {
      protocolDuration = DEFAULT_PROTOCOL_DURATION * 86400
    }
    return protocolDuration
  }

  // If the default protocol duration is less than or equal to 24 hours AND currently we see a single response
  // from the protocol previewer (as opposed to e.g., no response), then it is safe to assume that we are
  // asking people to fill out a "one off" questionnaire.
  // In this case, we disable the input control for the enddate, and when creating the protocol subscription,
  // we pass null as an enddate, so that it will use the default protocol duration instead.
  // This is to prevent the force setting of the enddate to 23:59:59 and then when starting a protocol at
  // 23:58:59 on the same day, people would only have a minute to fill out the questionnaire.
  calcDisableEndDate () {
    return this.defaultProtocolDuration() <= 86400 && this.state.responses && this.state.responses.length === 1
  }

  toggleGroupProtocols () {
    this.setState({ forGroup: !this.state.forGroup })
  }

  render () {
    const dateDifference = 1 + moment(this.state.endDate).diff(moment(this.state.startDate), 'days')
    const disableEndDate = this.calcDisableEndDate()
    // Add one because it's not nice to calculate with 23:59:59 (we use midnight instead).
    const timeToFillOutQuestionnaire = 1 + moment(this.state.endDate).diff(moment(this.state.startDate), 'seconds')

    const fullChoices = this.props.members.map(member => {
      return {
        properties: {
          name: `${member.first_name} ${member.last_name}`,
          value: member.id
        }
      }
    })
    const allMembers = this.props.members.map(member => `${member.id}`)

    const durationManagementProps = {
      handleDurationChange: this.handleDurationChange.bind(this),
      durationInDays: this.state.durationInDays,
      disableDuration: this.calcDisableEndDate.bind(this)
    }

    const protocolSelectionDropdownProps = {
      onChange: this.handleProtocolChange.bind(this),
      selected: this.state.selectedProtocol,
      availableProtocols: this.props.availableProtocols
    }

    const invitationTextConfigurationProps = {
      invitationTextDutch: this.state.invitationTextNl,
      invitationTextEnglish: this.state.invitationTextEn,
      onChangeDutch: (e) => this.setState({ invitationTextNl: e.target.value }),
      onChangeEnglish: (e) => this.setState({ invitationTextEn: e.target.value })
    }

    return (
      <>
        <div className='row'>
          <div className='col s12'>
            <div className='text-heavy text-l text-muted data-header'>
              {I18n.t('group.detail.questionnaires.add_protocol')}
            </div>
          </div>
        </div>
        {
          this.state.deployed && (
            <>
              <FeatureFlag debug name={QUESTIONNAIRES_AUTOMATIC_GROUP_JOIN_PROTOCOL} defaultChildren={<></>}>
                <Toggle id='group-protocol' title={I18n.t('group.detail.questionnaires.group_protocol_toggle')} isChecked={this.state.forGroup} onChange={this.toggleGroupProtocols.bind(this)} />
              </FeatureFlag>

              {this.state.forGroup &&
                <>
                  <GroupProtocolConfiguration
                    durationManagementProps={durationManagementProps}
                    protocolSelectionDropdownProps={protocolSelectionDropdownProps}
                    invitationTextConfigurationProps={invitationTextConfigurationProps}
                  />
                </>}
              {!this.state.forGroup &&
                <>
                  <div className='row'>
                    <div className='col s12 l6'>
                      <ProtocolSelectionDropdown {...protocolSelectionDropdownProps} />
                    </div>
                    <div className='col s12 l6'>
                      <SelectMultiple
                        id='members'
                        formName='group'
                        component='detail'
                        type='select_multiple'
                        fieldName='members'
                        currentValue={this.state.members}
                        choices={fullChoices}
                        onChange={e => this.setState({ members: e.getSelectedValues() })}
                        selectAll={() => this.setState({ members: allMembers })}
                        selectNone={() => this.setState({ members: [] })}
                        noWrapper
                        noTranslate
                      />
                    </div>
                    <div className='input-field col s12 l6'>
                      <DatePicker
                        id='questionnaire-start-date' selected={this.state.startDate}
                        onChange={date => this.handleChangeStartDate(date)}
                        minDate={new Date()}
                        selectsStart
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        {...dateAndTimePickerDefaultOptions()}
                      />
                      <label htmlFor='questionnaire-start-date' className='active'>
                        {I18n.t('group.detail.questionnaires.start_date')}
                      </label>
                      <span className={classNames('helper-text', { 'red-text': (moment().diff(moment(this.state.startDate), 'minutes') >= 1) })}>{I18n.t('group.detail.questionnaires.cannot_lie_in_the_past')}</span>
                    </div>
                    <div className='input-field col s12 l6'>
                      <DatePicker
                        id='questionnaire-end-date' selected={this.state.endDate}
                        selectsEnd
                        startDate={this.state.startDate}
                        minDate={this.state.startDate}
                        endDate={this.state.endDate}
                        onChange={date => this.handleChangeEndDate(date)}
                        disabled={disableEndDate}
                        {...datePickerDefaultOptions()}
                      />
                      <label htmlFor='questionnaire-end-date' className='active'>
                        {I18n.t('group.detail.questionnaires.end_date')}
                      </label>
                      <span className='helper-text'>
                        {I18n.t('group.detail.questionnaires.end_date_helper', { measurementDays: dateDifference })}
                        {!disableEndDate && timeToFillOutQuestionnaire < 86400 && (
                          <>
                            <br />
                            <span className='red-text'>{I18n.t('group.detail.questionnaires.low_time_warning', { hours: Math.floor(timeToFillOutQuestionnaire / 3600), minutes: round((timeToFillOutQuestionnaire % 3600) / 60, 0) })}</span>
                          </>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col s12'>
                      {this.state.selectedProtocol && (
                        <ProtocolPreview
                          protocolName={this.state.selectedProtocol}
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          responses={this.state.responses}
                          setResponses={this.setResponses.bind(this)}
                          disableEndDate={disableEndDate}
                          defaultProtocolDuration={this.defaultProtocolDuration()}
                        />
                      )}
                    </div>
                  </div>
                  <InvitationTextConfiguration {...invitationTextConfigurationProps} />
                </>}
            </>
          )
        }
        <div className='row'>
          <div className='col s12'>
            <button
              id='submit-questionnaire-subscription' onClick={this.handleClick.bind(this)}
              className='data-button button-primary background-primary waves-effect waves-light text-background-color inline-block'
            >
              {this.state.deployed && I18n.t('group.detail.questionnaires.save_subscription')}
              {!this.state.deployed && (
                <>
                  <FontAwesomeIcon icon='plus' className='button-icon' />
                  {I18n.t('group.detail.questionnaires.create_subscription')}
                </>
              )}
            </button>
            {this.state.deployed && (
              <button
                onClick={() => this.setState({ deployed: false })}
                className='data-button waves-effect text-m waves-light button-primary text-primary-color background-background text-medium inline-block'
              >
                {I18n.t('group.detail.questionnaires.cancel')}
              </button>
            )}
          </div>
        </div>
      </>
    )
  }
}

export default WithSession(CreateProtocolSubscription)
