import React, { useEffect } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import PropTypes from 'prop-types'
import _ from 'lodash'
import I18n from 'i18n'

/**
 * Select Dropdown should receive, in the content prop, and array with the elements in this format
 * { value: <value_of_the_option>, name: <name_displayed_in_the_option> }
 *
 * The order of the elements in the array is the order they are going to have in the dropdown.
 *
 * May also contain group elements:
 * { groupName: <group_name_displayed>, elements: <array_with_elements> }
 * In the dropdown, it will show the group's name and the elements of that group below the name
 */
const SelectDropdown = (props) => {
  const { content, contentId, placeholder, defaultValue, isMultipleChoice, onChange, id, label, value, className, errors = [], disabled } = props
  const defVal = getDefaultValue()

  function getDefaultValue () {
    // Controlled component
    if (value !== undefined) return undefined
    // Uncontrolled component
    if (defaultValue || defaultValue === 0) {
      return defaultValue
    } else if (isMultipleChoice) {
      return []
    } else {
      return ''
    }
  }

  function getDropdownElements (content) {
    let dropdownElements = []
    if (content != null) {
      dropdownElements = dropdownElements.concat(content.map(elem => {
        if ('groupName' in elem) {
          return (<optgroup key={elem.groupName} label={elem.groupName}>{getDropdownElements(elem.elements)}</optgroup>)
        } else {
          return (<option key={`${elem.name}${elem.value}`} value={elem.value} color={elem.color || null} data-icon={elem.icon}>{elem.name}</option>)
        }
      }))
    }

    return dropdownElements
  }

  // Note that we are not triggering an onChange here, so it is safe and does not cause infinite loops
  useDeepCompareEffect(() => {
    M.FormSelect.init(document.querySelector(`#${id}`), { classes: className || '' })
  }, [value, disabled, content])

  useEffect(() => {
    M.FormSelect.init(document.querySelector(`#${id}`), { classes: className || '' })
    onChange({ target: { value: defVal } })
  }, [contentId])

  const dropdownElements = getDropdownElements(content)
  if (placeholder) {
    dropdownElements.unshift(
      <option key='' value='' disabled>{placeholder}</option>
    )
  }
  // key in top level div forces react to re-render <select> when content changes so that defaultValue is enforced
  const key = contentId || content.length
  const selectProps = _.omit(props, ['contentId', 'isMultipleChoice'])
  return (
    <div className='input-field' key={key}>
      <select {...selectProps} multiple={isMultipleChoice} onChange={onChange} id={id} defaultValue={defVal} value={value}>
        {dropdownElements}
      </select>
      {label && <label>{label}</label>}
      {errors.map((err) => (
        <span className='helper-text' data-error='wrong' data-success='right' key={err} style={{ color: 'red' }}>
          {props.translateErrors ? I18n.t(err) : err}
        </span>
      ))}
    </div>
  )
}

SelectDropdown.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any,
      groupName: PropTypes.string,
      elements: PropTypes.array
    })
  ),
  // Allows to trigger a "rerender" when the contents are the same
  contentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  id: PropTypes.string.isRequired,
  isMultipleChoice: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
}

export default SelectDropdown
