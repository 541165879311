import React, { useEffect, useState, useMemo } from 'react'
import I18n from 'i18n'
import { WithBackend } from '../../../backend/BackendProvider'
import { WithSession } from '../../../session/SessionProvider'
import { CheckBox, Toggle } from '../../../common/form'
import Search from '../Search'
import OnboardingSharingSearchResult from '../Search/OnboardingSharingSearchResult'
import { WithModal } from '../../../modal/ModalProvider'
import EditQuestionnaireProfileDetailsForm from '../../../questionnaires/layout/EditQuestionnaireProfileDetailsForm'
import { WithQuestionnaires } from '../../../questionnaires/QuestionnaireProvider'
import { smartTranslate } from '../../../common/Utils'
import { useStages } from '../../../common/Hooks'
import { accessLevel, STATIC_TAGS } from '../../../common/Constants'
import SharingTableRow from './SharingTableRow'
import ShowIfStage from './ShowIfStage'
import { WithAppsignal } from '../../../appsignal/AppsignalProvider'
import { createGroupSharingTag, createGroupSharingTagId } from 'components/common/utils/SharingUtils'
import WithProfileMetadata from 'components/helpers/WithProfileMetadata'
import Spinner from 'components/common/Spinner'

const AcceptInformedConsentForm = ({ appsignal, backend, myProfile, myProfileMetadata, sessionToken, invite, questionnaires, onAccept, onFinish, ...props }) => {
  const [informedConsent, setInformedConsent] = useState(undefined)
  const [requiredAccepted, setRequiredAccepted] = useState(false)
  const [optionalAccepted, setOptionalAccepted] = useState(false)

  const { body = '', consents = [] } = informedConsent || {}
  const { details: requiredConsentDetails = '', id: requiredConsentId } = consents.find(c => c.required) || {}
  const { details: optionalConsentDetails = '', id: optionalConsentId } = consents.find(c => !c.required) || {}

  const consentResponse = {
    consent_responses: [
      { consent_id: requiredConsentId, accepted: requiredAccepted }
    ]
  }
  if (optionalConsentId) {
    consentResponse.consent_responses.push({ consent_id: optionalConsentId, accepted: optionalAccepted })
  }

  const [questionnaireErrors, setQuestionnaireErrors] = useState({})

  useEffect(() => {
    if (!invite.requires_informed_consent) {
      return
    }

    backend.groups.informed_consent.index(invite.group_id).then(res => {
      setInformedConsent(res.data)
    })
  }, [])

  function submitQuestionnairePromise () {
    return questionnaires.updateMyProfile()
  }

  async function accept (e) {
    // first we change the questionnaire settings. if this goes wrong, we do
    // not attempt to do anything else and show the errors so that the user can
    // change their settings.

    try {
      // The below promise will also validate the form
      await submitQuestionnairePromise(e)
      setQuestionnaireErrors({})
    } catch (questionnaireError) {
      if (questionnaireError?.response?.status === 422) {
        // unprocessable entity => most likely invalid form info
        setQuestionnaireErrors(questionnaireError.response.data.errors[0].detail)
      } else {
        appsignal.sendError(questionnaireError)
      }

      // If we end up in the error state, then we do not try to submit any
      // information so that the user can modify their inputs
      return
    }

    // when the questionnaire settings are updated, we accept the invite and
    // update the sharing rules. lastly we close the modal (onFinish).

    try {
      // any of these should not go wrong, unless the backend is down. if any
      // but the last promise reject, then we make sure not to call onFinish, as
      // that will close the modal. This gives the user an opportunity to try and
      // submit again.
      await onAccept(invite, consentResponse)
      await shareDataWithGroup(sharingQueue, invite.group_id)
      await onFinish()
    } catch (err) {
      appsignal.sendError(err)
    }
  }

  function handleAccept (e) {
    e.persist()

    if (!invite.requires_informed_consent || !optionalConsentId || optionalAccepted) {
      accept(e)
    } else {
      props.setConfirmationDialog({
        onConfirmation: () => accept(e),
        target: '',
        action: 'skip_optional_consent',
        noQuotes: true
      })
    }
  }

  // The stage layout of the modal is as follows:
  // 0. Informed consent accepting
  // 1. Data sharing
  // In case there is no informed consent, start at stage = 1
  // If this gets more complicated in the future, we probably want
  // to refactor this to extract each stage in separate modal flows
  const firstStage = invite.requires_informed_consent ? 0 : 1
  const numStages = 2 - firstStage
  const { stage: currentStage, nextStage, prevStage } = useStages({
    firstStage: firstStage,
    numStages: numStages,
    onClickThrough: handleAccept
  })

  const continueButtonText = currentStage === firstStage + numStages - 1
    ? I18n.t('network.groups.group_invite.informed_consent.cta')
    : I18n.t('network.groups.group_invite.next')

  const shareDataWithGroup = (data, id) => {
    const promises = data
      .map(d => dataToRight(d, id))
      .map(backend.collectionRights.create)

    return Promise.all(promises)
  }

  const dataToRight = (data, groupId) => {
    const isTag = data.type === 'tag'
    return {
      collection_right: {
        access_level: data.access,
        collection_type: isTag ? 'tag' : data.obj.name,
        tag: isTag ? data.obj.name : null,
        group_id: groupId,
        start_date: null,
        end_date: null
      }
    }
  }

  const [sports, setSports] = useState([])
  const [questionnaireSingleton, setQuestionnaireSingleton] = useState([])
  const [tags, setTags] = useState([])

  // The search list has a special data type that wraps the original objects.
  // Attached to this type are useful properties, such as the access level that
  // the uesr picks, and the translated name.
  // This is a helper to convert sport or tag types into this helper type.
  const intoQueueType = (object, type) => ({
    id: `${type}-${object.name}`,
    type: type,
    name: object.name,
    translatedName: smartTranslate(object.name),
    access: accessLevel.READ,
    obj: object
  })

  const myProfileMetadataNotYetRetrieved = !myProfileMetadata || Object.keys(myProfileMetadata).length === 0

  // when the component loads, query the available sports and tags so that they
  // can be searched for faster.
  useEffect(() => {
    if (myProfileMetadataNotYetRetrieved) return

    // make sure that under no circumstances, the questionnaire "sport" gets
    // included in the list of sports. we control this separately according to
    // the sharing toggle.
    const sportItems = myProfileMetadata.sports_from_data
      .map(s => intoQueueType(s, 'sport'))

    setSports(sportItems)

    // Set the list of available tags
    backend.getTags()
      .then(tags => {
        const tagData = tags.data.map(t => intoQueueType(t, 'tag'))

        setTags(tagData)
      })

    // After setting all sports and tags, add the current group questionnaire tag sharing rule, and enable it by default.
    const questionnaireTag = intoQueueType({ name: createGroupSharingTag(invite.group_id) }, 'tag')
    setQuestionnaireSingleton([questionnaireTag])
    setSharingQueue(q => [...q, questionnaireTag])
  }, [myProfileMetadata])

  // Static tags are tags that we always want to allow users to share by, even if they don't yet have data tagged with
  // that value (think strava, polar etc, where the user is new to the platform and they haven't still connected
  // the account)
  const staticTags = useMemo(() => (
    STATIC_TAGS.map(t => intoQueueType({ name: t }, 'tag'))
  ), [])

  // triggers when the search debouncer determines that a query should be
  // searched for. must be async, and returns the list of search results.
  const handleSearch = async (query) => {
    // filter elements that only match the (translated) name
    const filterByName = item => {
      const q = query.target.value.toLowerCase()
      return item.name.toLowerCase().includes(q) || item.translatedName.toLowerCase().includes(q)
    }

    // filter out values that are already in the sharing queue
    const filterByExisting = item => {
      return !sharingQueue.some(s => s.id === item.id)
    }

    // filter out values already existing in the given collection
    const filterOutDuplicate = collection => item => {
      return !collection.some(e => e.id === item.id)
    }

    const sportResults = sports
      .filter(filterByName)
      .filter(filterByExisting)

    const tagResults = tags
      .filter(filterByName)
      .filter(filterByExisting)

    const staticTagResult = staticTags
      .filter(filterByName)
      .filter(filterByExisting)
      .filter(filterOutDuplicate(tagResults))

    const questionnaireResult = questionnaireSingleton
      .filter(filterByExisting)

    return [...sportResults, ...questionnaireResult, ...tagResults, ...staticTagResult]
  }

  // sharingQueue contains the sports and tags that the user will share with
  // the group after joining
  const [sharingQueue, setSharingQueue] = useState([])

  const handleAddToSharingQueue = (item) => {
    setSharingQueue(q => [...q, item])
  }

  const handleDelete = (item) => {
    setSharingQueue(q => q.filter(qItem => qItem.id !== item.id))
  }

  const handleUpdate = (newItem) => {
    // use a map to update all items that have the correct ID (should only be
    // one). this ensures that the operation is atomic which is nice.
    setSharingQueue(q => q.map(oldItem => (oldItem.id === newItem.id) ? newItem : oldItem))
  }

  const isSharingQuestionnaires = () => {
    return sharingQueue.some(item => item.id === `${createGroupSharingTagId(invite.group_id)}`)
  }

  const handleToggleShareQuestionnaires = () => {
    if (isSharingQuestionnaires()) {
      handleDelete({ id: `${createGroupSharingTagId(invite.group_id)}` })
    } else {
      handleAddToSharingQueue(...questionnaireSingleton)
    }
  }

  if (myProfileMetadataNotYetRetrieved || !myProfile || Object.keys(myProfile).length === 0 || questionnaires.areEnabled === undefined) {
    return <Spinner transparent />
  }

  return (
    <div className='informed-consent-accept group-detail'>
      <div className='row'>
        <div className='col s12'>
          <ShowIfStage stage={0} currentStage={currentStage}>
            <h5>{I18n.t('network.groups.group_invite.informed_consent.title')}</h5>
          </ShowIfStage>
          <ShowIfStage stage={1} currentStage={currentStage}>
            <h5 className='text-primary-color text-center'>{I18n.t('network.groups.group_invite.onboarding.title')}</h5>
          </ShowIfStage>
        </div>
      </div>
      <ShowIfStage stage={0} currentStage={currentStage}>
        <div className='row'>
          <div className='col s12 display-linebreak'>
            {body}
          </div>
        </div>
        <CheckBox label={requiredConsentDetails} currentValue={requiredAccepted} onChange={() => setRequiredAccepted(!requiredAccepted)} />
        {optionalConsentId && <CheckBox label={optionalConsentDetails} currentValue={optionalAccepted} onChange={() => setOptionalAccepted(!optionalAccepted)} />}
      </ShowIfStage>
      <ShowIfStage stage={1} currentStage={currentStage}>
        <div className='row'>
          <div className='col m6 s12'>
            <h5>{I18n.t('network.groups.group_invite.informed_consent.want_to_share_questionnaires')}</h5>
            <div>
              <Toggle
                currentValue={isSharingQuestionnaires()}
                onChange={() => handleToggleShareQuestionnaires()}
                label={I18n.t('network.groups.group_invite.informed_consent.share_questionnaires')}
                fieldName='share-questionnaires'
              />
            </div>

            {isSharingQuestionnaires() &&
              <div>
                <EditQuestionnaireProfileDetailsForm
                  enableOnLoad
                  errors={questionnaireErrors}
                />
              </div>}
          </div>

          <div className='col m6 s12'>
            <h5>{I18n.t('network.groups.group_invite.informed_consent.share_other_data')}</h5>
            <div id='search-area'>
              <Search
                id='data-search'
                fieldId='sport_tag_search'
                className=''
                minSearchLength={0}
                label={I18n.t('network.groups.group_invite.informed_consent.search_sports_tags')}
                onSearch={handleSearch}
                Component={OnboardingSharingSearchResult}
                onClick={handleAddToSharingQueue}
              />
            </div>

            <div className='row'>
              <div className='col s12'>
                <table className='responsive-table table-data-sharing-scroll'>
                  <thead>
                    <tr>
                      <th className='onboarding-data-sharing-c1'>{I18n.t('datasharing.headers.source')}</th>
                      <th className='onboarding-data-sharing-c2'>{I18n.t('datasharing.headers.permission')}</th>
                      <th className='onboarding-data-sharing-c3'>{I18n.t('datasharing.headers.options')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sharingQueue.map(item => {
                      const thisGroup = (item.id === createGroupSharingTagId(invite.group_id))
                      return <SharingTableRow key={item.id} item={item} handleDelete={handleDelete} handleUpdate={handleUpdate} dataOfThisGroup={thisGroup} />
                    }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </ShowIfStage>
      <div className='row'>
        <div className='col s6 left-align'>
          {numStages > 1 &&
            <button
              className='button-muted button-autowidth waves-effect waves-light align'
              onClick={prevStage}
              disabled={currentStage === 0}
            >
              {I18n.t('network.groups.group_invite.previous')}
            </button>}
        </div>
        <div className='col s6 right-align'>
          <button
            className='button-primary background-primary button-autowidth waves-effect waves-light text-background-color align'
            onClick={nextStage}
            disabled={invite.requires_informed_consent && !requiredAccepted}
          >
            {continueButtonText}
          </button>
        </div>
      </div>
    </div>
  )
}

export default WithAppsignal(WithSession(WithQuestionnaires(WithBackend(WithModal(WithProfileMetadata(AcceptInformedConsentForm))))))
