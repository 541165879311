import React, { useEffect, useRef, useState } from 'react'
import TabHeader from './TabHeader'
declare let M: any

interface Props {
  id: string
  children: React.ReactNode
  onShow: (id: string) => void
  swipeable?: boolean
  duration?: number
  responsiveThreshold?: number
  selectedTab?: string
}

const TabPanel: React.FC<Props> = ({ id, children, onShow, swipeable = false, duration = 300, responsiveThreshold = Infinity, selectedTab }) => {
  const [tabs, setTabs] = useState<Tabs | undefined>(undefined)
  const tabRef = useRef<HTMLUListElement>(null)

  useEffect(() => {
    setTabs(M.Tabs.init(document.getElementById(id), { swipeable, duration, responsiveThreshold }))
  }, [])

  // If component is controlled
  useEffect(() => {
    if (selectedTab !== undefined) {
      tabs?.select(selectedTab)
    }
  }, [selectedTab])

  return (
    <div className='row'>
      <div className='col s12'>
        <ul id={id} className='tabs tabs-fixed-width' ref={tabRef}>
          <TabHeader tabs={children} onShow={onShow} tabRef={tabRef} />
        </ul>
      </div>
      {children}
    </div>
  )
}

interface Tabs {
  select: (tabName: string) => void
}

export default TabPanel
