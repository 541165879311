import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import BackButton from '../common/BackButton'
import DropDownButton from '../common/DropDownButton'
import { WithSession } from '../session/SessionProvider'
import DashboardStateWrapper from 'components/analysis/dashboards/questionnaire/common/DashboardStateWrapper'
import ExpandCompressButton from '../common/ExpandCompressButton'

import MyActivityDashboard from './dashboards/steps/MyActivityDashboard'
import SoccerDashboard from './dashboards/soccer/SoccerDashboard'
import HikingDashboard from './dashboards/hiking/HikingDashboard'
import SkatingDashboard from './dashboards/skating/SkatingDashboard'
import RunningDashboardPicker from './dashboards/running/RunningDashboardPicker'
import RunningDashboardMulti from './dashboards/running/RunningDashboardMulti'
import RunningDashboardSingle from './dashboards/running/RunningDashboardSingle'
import SwimmingDashboardPicker from './dashboards/swimming/SwimmingDashboardPicker'
import SwimmingDashboardMulti from './dashboards/swimming/SwimmingDashboardMulti'
import SwimmingDashboardSingle from './dashboards/swimming/SwimmingDashboardSingle'
import RidingDashboardMulti from './dashboards/riding/RidingDashboardMulti'
import RidingDashboardSingle from './dashboards/riding/RidingDashboardSingle'
import RidingDashboardPicker from './dashboards/riding/RidingDashboardPicker'
import InlineSkatingDashboard from './dashboards/inline_skating/InlineSkatingDashboard'
import QuestionnaireDashboardPicker from './dashboards/questionnaire/QuestionnaireDashboardPicker'
import FullQuestionnaireDashboard from './dashboards/questionnaire/full/FullQuestionnaireDashboard'
import RheumaQuestionnaireDashboard from './dashboards/questionnaire/RheumaQuestionnaireDashboard'
import CsvDashboard from './dashboards/csv/CsvDashboard'
import TriathlonDashboard from './dashboards/triathlon/TriathlonDashboard'
import IceSkatingDashboard from './dashboards/ice_skating/IceSkatingDashboard'
import MultiAthleteQuestionnaireDashboard
  from './dashboards/questionnaire/multi_athlete/MultiAthleteQuestionnaireDashboard'

import Routes from '../common/Routes'
import CoachDashboardIndividual from './dashboards/coach_individual/CoachDashboardIndividual'
import FeatureFlag, { COACH_DASHBOARD } from '../feature_flags/FeatureFlag'
import PhysicalComplaintsDashboard from './dashboards/questionnaire/complaints/PhysicalComplaintsDashboard'
import useExpansionHelperHook from 'components/helpers/ExpansionHelper'
import QuestionnaireDashboardProfileWrapper from './dashboards/questionnaire/full/QuestionnaireDashboardProfileWrapper'
import FullQuestionnaireDashboardOptimizedComponent
  from 'components/analysis/dashboards/questionnaire/full/FullQuestionnaireDashboardOptimizedComponent'
import WeeklyOrMonthlyWellbeingDashboard from 'components/analysis/dashboards/questionnaire/weekly/WeeklyOrMonthlyWellbeingDashboard'

const types = {
  skating: Routes.analysis.iceSkating,
  running: Routes.analysis.running.index,
  running_single: Routes.analysis.running.single.show,
  running_multi: Routes.analysis.running.trends,
  swimming: Routes.analysis.swimming.index,
  swimming_single: Routes.analysis.swimming.single.show,
  swimming_multi: Routes.analysis.swimming.trends,
  hiking: Routes.analysis.hiking.show,
  soccer: Routes.analysis.soccer.show,
  inline_skating: Routes.analysis.inlineSkating.index,
  riding: Routes.analysis.cycling.index,
  riding_single: Routes.analysis.cycling.single.show,
  riding_multi: Routes.analysis.cycling.trends,
  questionnaire: Routes.analysis.questionnaire.index,
  questionnaire_weekly: Routes.analysis.questionnaire.weekly,
  questionnaire_full: Routes.analysis.questionnaire.full,
  questionnaire_full_opt: Routes.analysis.questionnaire.full_opt,
  questionnaire_multi: Routes.analysis.questionnaire.trends,
  questionnaire_complaints: Routes.analysis.questionnaire.complaints.show,
  questionnaire_rheuma: Routes.analysis.questionnaire.rheuma.show,
  steps: Routes.analysis.activity.index,
  steps_weekly: Routes.analysis.activity.weekly,
  steps_monthly: Routes.analysis.activity.monthly,
  steps_yearly: Routes.analysis.activity.yearly,
  csv: Routes.analysis.csv.show,
  triathlon: Routes.analysis.triathlon,
  ice_skating: Routes.analysis.iceSkatingSingle.single.index,
  coach: Routes.analysis.coach
}

const DashboardWrapper = (props) => {
  const [pathsToRender, setPathsToRender] = useState([props.match.path])
  const [expanded, setExpanded] = useState(false)
  const [multiple, setMultiple] = useState(false)
  const [dropDown, setDropdown] = useState(undefined)

  useEffect(() => {
    setPathsToRender([props.match.path])
  }, [props.match.path])

  // Make sure the expand / contract works
  useExpansionHelperHook(expanded)

  // Effect is implemented for Materialize tabs functionality to reset tabs functionality
  // This is used here because ExpandCompressButton also implements same feature
  useEffect(() => {
    const resetTabs = setTimeout(() => {
      const elems = document.querySelectorAll('.tabs')
      if (elems && elems.length > 0) { M.Tabs.init(elems) }
    }, 0)
    return () => {
      clearTimeout(resetTabs)
    }
  }, [expanded])

  function getDropdown () {
    let dropDownTypes = types

    // Temporary fix for dashboards always available when directly accessing through URL
    if (props.location.state) {
      const enabledDashboards = props.location.state.dashboards
      enabledDashboards.forEach(dashboard => {
        const key = dashboard.name.replace('sports.', '')
        if (!dashboard.enabled || !(key in dropDownTypes)) {
          dropDownTypes = _.omit(dropDownTypes, [key])
        }
      })
    }

    if (dropDownTypes.steps === undefined) {
      dropDownTypes = _.omit(dropDownTypes, ['steps_monthly', 'steps_weekly', 'steps_yearly'])
    }

    if (dropDownTypes.questionnaire === undefined) {
      dropDownTypes = _.omit(dropDownTypes, ['questionnaire_full', 'questionnaire_full_opt', 'questionnaire_weekly', 'questionnaire_multi', 'questionnaire_complaints', 'questionnaire_rheuma'])
    }

    if (dropDownTypes.riding === undefined) {
      dropDownTypes = _.omit(dropDownTypes, ['riding_multi', 'riding_single'])
    }

    if (dropDownTypes.running === undefined) {
      dropDownTypes = _.omit(dropDownTypes, ['running_multi', 'running_single'])
    }

    if (dropDownTypes.swimming === undefined) {
      dropDownTypes = _.omit(dropDownTypes, ['swimming_multi', 'swimming_single'])
    }

    // omit the riding, questionnaire and steps anyways because those are pickers
    dropDownTypes = _.omit(dropDownTypes, ['steps', 'questionnaire', 'riding', 'swimming', 'running', 'coach'])
    setDropdown(dropDownTypes)
  }

  function renderDashboardSwitch (path, index) {
    // This function decides which dashboard to render based on the path OR key form dropdown
    // Switch case is big and could be optimized. For concept purposes this solution was chosen
    const switchKey = Object.keys(types).find(key => types[key] === path)
    // If this is not the original (first) dashboard, don't pick the file from the path id, but the first in the selector list
    const pickFirstFile = index !== 0
    const passedProps = { ...props, index }
    switch (switchKey) {
      case 'skating':
        return <SkatingDashboard {...passedProps} />
      case 'questionnaire':
        return <QuestionnaireDashboardPicker {...passedProps} hideBackButton />
      case 'questionnaire_weekly':
        return <WeeklyOrMonthlyWellbeingDashboard {...passedProps} /> // weekly and monthly daily/weekly wellbeing dashboards
      case 'questionnaire_full':
        return <FullQuestionnaireDashboard {...passedProps} />
      case 'questionnaire_full_opt':
        return <QuestionnaireDashboardProfileWrapper component={FullQuestionnaireDashboardOptimizedComponent} {...passedProps} />
      case 'questionnaire_multi':
        return <QuestionnaireDashboardProfileWrapper component={MultiAthleteQuestionnaireDashboard} {...passedProps} />
      case 'questionnaire_complaints':
        return <QuestionnaireDashboardProfileWrapper component={PhysicalComplaintsDashboard} {...passedProps} />
      case 'questionnaire_rheuma':
        return <RheumaQuestionnaireDashboard {...passedProps} />
      case 'steps':
        return <MyActivityDashboard {...passedProps} />
      case 'steps_weekly':
        return <MyActivityDashboard {...passedProps} period='week' />
      case 'steps_monthly':
        return <MyActivityDashboard {...passedProps} period='month' />
      case 'steps_yearly':
        return <MyActivityDashboard {...passedProps} period='year' />
      case 'running':
        return <RunningDashboardPicker {...passedProps} hideBackButton />
      case 'running_single':
        return <RunningDashboardSingle {...passedProps} pickFirstFile={pickFirstFile} />
      case 'running_multi':
        return <RunningDashboardMulti {...passedProps} />
      case 'swimming':
        return <SwimmingDashboardPicker {...passedProps} hideBackButton />
      case 'swimming_single':
        return <SwimmingDashboardSingle {...passedProps} pickFirstFile={pickFirstFile} />
      case 'swimming_multi':
        return <SwimmingDashboardMulti {...passedProps} />
      case 'inline_skating':
        return <InlineSkatingDashboard {...passedProps} />
      case 'riding':
        return <RidingDashboardPicker {...passedProps} hideBackButton />
      case 'riding_single':
        return <RidingDashboardSingle {...passedProps} pickFirstFile={pickFirstFile} />
      case 'riding_multi':
        return <RidingDashboardMulti {...passedProps} />
      case 'hiking':
        return <HikingDashboard {...passedProps} pickFirstFile={pickFirstFile} />
      case 'soccer':
        return <SoccerDashboard {...passedProps} />
      case 'csv':
        return <CsvDashboard {...passedProps} pickFirstFile={pickFirstFile} />
      case 'triathlon':
        return <TriathlonDashboard {...passedProps} />
      case 'ice_skating':
        return <IceSkatingDashboard {...passedProps} />
      case 'coach':
        return <FeatureFlag name={COACH_DASHBOARD} defaultChildren={<></>}><CoachDashboardIndividual {...props} /></FeatureFlag>
    }
  }

  function handleScale () {
    setExpanded(multiple ? true : !expanded)
  }

  function handleOnClick (type) {
    const newArr = [...pathsToRender, type]
    if (type) {
      setMultiple(true)
      setPathsToRender(newArr)
      setExpanded(false)
      setTimeout(() => setExpanded(true), 1)
    }
  }

  function calculateColumns (index, size) {
    // If there is one dashboard OR if the last item is on it's own line, render it full width
    // in all other cases render it half width
    if ((index === size - 1 && (size - 1) % 2 === 0) || size === 1) {
      return 'col s12 l12 m12'
    }
    return 'col s12 l6 m12'
  }

  const onGoBack = e => {
    e.preventDefault()
    setExpanded(false)
    setMultiple(false)
    props.history.goBack()
  }

  return (
    <div className='questionnaire-dashboard-wrapper'>
      <div className='contains-expand-compress-button'>
        {pathsToRender.length === 1 &&
          <ExpandCompressButton handleOnClick={handleScale} expanded={expanded} />}

        <div className='dashboard-add-wrapper'>
          <BackButton history={props.history} onGoBack={onGoBack} />
          <DropDownButton content={dropDown || getDropdown()} onClick={handleOnClick} />
        </div>
      </div>

      <div className='row'>
        {pathsToRender.map((path, index) => {
          return (
            <div className={calculateColumns(index, pathsToRender.length)} key={index}>{renderDashboardSwitch(path, index)}</div>
          )
        })}
      </div>
    </div>
  )
}

export default WithSession(DashboardStateWrapper(DashboardWrapper))
