import React from 'react'
import { dataObjectStages, skeletonLines } from '../../common/Constants'
import Skeleton from 'react-loading-skeleton'
import { Waypoint } from 'react-waypoint'
import DetailsWrapper from './details/DetailsWrapper'
import { collectionTypeToIcon } from '../../common/Utils'

export default class TimelineEntryCompact extends React.Component {
  handleRefetch () {
    if (this.props.fetchedAt && new Date().getTime() - this.props.fetchedAt.timestamp > this.props.minRefetchDelay) {
      this.props.onFetchContent(this.props.metadatum.versioned_data_object_id)
    }
  }

  render () {
    /* eslint-disable camelcase */
    const { metadatum, data, isLoading } = this.props
    const { versioned_data_object: { summary } = {} } = data || {}
    const collectionType = summary && summary.collection_type

    return (
      <div className='timeline-card-compact'>
        <div className='timeline-card-content-compact'>
          {isLoading && (
            <Skeleton key={metadatum && metadatum.id} count={skeletonLines} />
          )}
          {metadatum && metadatum.stage === dataObjectStages.brewed_stage && data && data.versioned_data_object.data_type.data_type !== undefined && (
            <div className='timeline-card-details'>
              <DetailsWrapper compact vdo={data} icon={(metadatum && metadatum.sport && metadatum.sport.icon) || collectionTypeToIcon(collectionType) || 'question'} metadatum={metadatum} />
            </div>
          )}
          <Waypoint onPositionChange={this.handleRefetch.bind(this)} />
        </div>
      </div>
    )
  }
}
/* eslint-enable camelcase */
