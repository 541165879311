import React from 'react'
import WelcomeController from '../../welcome/WelcomeController'
import TwoFactor from '../../2fa/TwoFactor'
import { WithModal } from '../../modal/ModalProvider'
import { WithSession } from '../../session/SessionProvider'
import FormFactory from '../../helpers/FormFactory'
import SportIcon from '../../icons/sports/SportIcon'
import ApiTokens from '../../api_tokens/ApiTokens'
import OauthApplications from '../../oauth_applications/OauthApplications'
import EditQuestionnaireProfileDetailsForm from '../../questionnaires/layout/EditQuestionnaireProfileDetailsForm'
import { Link } from 'react-router-dom'
import I18n from 'i18n'
import Spinner from '../../common/Spinner'
import { Prompt } from 'react-router'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import { WithQuestionnaires } from '../../questionnaires/QuestionnaireProvider'
import classNames from 'classnames'
import UpdatePassword from './UpdatePassword'
import UpdateEmail from './UpdateEmail'
import FeatureFlag, { CHANGE_EMAIL } from '../../feature_flags/FeatureFlag'
import PhysiologicalDetails from 'components/profile/layout/PhysiologicalDetails'
import SdvNextToggle from 'components/atomic/molecules/SdvNextToggle'
import InfoNotice from 'components/data/layout/detail/InfoNotice'
import Variables from 'stylesheets/variables.module.scss'
const { mainBackgroundColor } = Variables

class TwoFAButton extends React.Component {
  render () {
    let text = 'profile.enable_twofa'

    if (this.props.twoFaEnabled) {
      text = 'profile.disable_twofa'
    }

    return (
      <button
        className={classNames('waves-effect waves-light button-accent text-background-color background-accent text-medium',
          this.props.className && `${this.props.className}`)} onClick={this.props.onShowModal}
      >
        {I18n.t(text)}
      </button>
    )
  }
}

class EditProfile extends FormFactory {
  constructor (props) {
    super(props)
    this.form_fields = {
      picture: {
        from_endpoint: '/api/v1/profiles/my',
        component: 'picture',
        properties: {
          onStartEditing: () => this.setState({ editingPicture: true }),
          onStopEditing: () => this.setState({ editingPicture: false })
        }
      },
      first_name: {
        from_endpoint: '/api/v1/profiles/my',
        component: 'field',
        properties: {
          type: 'text',
          required: true
        }
      },
      last_name: {
        from_endpoint: '/api/v1/profiles/my',
        component: 'field',
        properties: {
          type: 'text',
          required: true
        }
      },
      birth_date: {
        from_endpoint: '/api/v1/profiles/my',
        component: 'date_picker',
        properties: {
          type: 'date_picker'
        }
      },
      gender: {
        from_endpoint: '/api/v1/profiles/my',
        component: 'select',
        properties: {
          type: 'select',
          defaultValue: 'undisclosed',
          choices: [
            { properties: { name: 'undisclosed', value: 'undisclosed' } },
            { properties: { name: 'male', value: 'male' } },
            { properties: { name: 'female', value: 'female' } },
            { properties: { name: 'other', value: 'other' } }
          ]
        }
      },
      organization: {
        from_endpoint: '/api/v1/profiles/my',
        component: 'field',
        properties: {
          type: 'text'
        }
      },
      specialization: {
        from_endpoint: '/api/v1/profiles/my',
        component: 'field',
        properties: {
          type: 'text'
        }
      },
      bio: {
        from_endpoint: '/api/v1/profiles/my',
        component: 'field',
        properties: {
          type: 'text'
        }
      },
      locale: {
        from_endpoint: '/api/v1/profiles/my',
        component: 'radio',
        properties: {
          id: 'locale',
          name: 'locale',
          type: 'radio',
          choices: [
            { properties: { name: 'english', value: 'en' } },
            { properties: { name: 'dutch', value: 'nl' } }
          ],
          defaultChoice: I18n.locale
        }
      },
      sports: {
        component: 'select_multiple_icon',
        from_endpoint: '/api/v1/profiles/my',
        properties: {
          id: 'sports_select',
          type: 'select',
          defaultValue: [0],
          choices: '/api/v1/sports',
          iconClass: SportIcon,
          infoBox: () => I18n.t('profile_edit.select_multiple_icon.tooltip') // This is a function because the correct i18n locale isn't yet loaded. It will be evaluated when it is.
        }
      },
      allow_anon_data_usage: {
        from_endpoint: '/api/v1/profiles/my',
        component: 'toggle',
        properties: {
          disabled: true,
          defaultValue: false,
          infoBox: () => I18n.t('profile_edit.toggle.tooltip.allow_anon_data_usage_tooltip') // This is a function because the correct i18n locale isn't yet loaded. It will be evaluated when it is.
        }
      },
      publicly_visible: {
        component: 'toggle',
        from_endpoint: '/api/v1/profiles/my',
        properties: {
          infoBox: () => I18n.t('profile_edit.toggle.tooltip.publicly_visible_tooltip') // This is a function because the correct i18n locale isn't yet loaded. It will be evaluated when it is.
        }
      },
      mute_group_notifications: {
        component: 'toggle',
        from_endpoint: '/api/v1/profiles/my',
        properties: {
          infoBox: () => I18n.t('profile_edit.toggle.tooltip.mute_group_notifications_tooltip') // This is a function because the correct i18n locale isn't yet loaded. It will be evaluated when it is.
        }
      },
      receives_marketing_email: {
        component: 'toggle',
        from_endpoint: '/api/v1/profiles/my'
      }
    }
    this.state = {
      questionnaireErrors: {},
      editingPicture: false,
      activeIndex: props.location?.state?.activeIndex === undefined ? 0 : props.location.state.activeIndex
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps !== this.props && this.props.defaults && Object.entries(this.props.defaults).length > 0) {
      const elems = document.querySelectorAll('.tabs')
      M.Tabs.init(elems)
    }
    if (this.shouldBlockNavigation()) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }

  shouldBlockNavigation (target = null) {
    if (!document.getElementById('edit-profile-tabs')) { return false }
    if (this.profileTabIsActive() && target !== 'profile-details-view' && this.isQuestionnaireSettingsModified()) { return true }
    if (!this.profileTabIsActive() || target === 'profile-details-view') return false
    if (this.state.editingPicture) return true
    let shouldBlock = false
    const properties = [
      'first_name',
      'last_name',
      'organization',
      'allow_anon_data_usage',
      'picture',
      'publicly_visible',
      'bio',
      'specialization',
      'locale',
      'sports',
      'receives_marketing_email'
    ]
    for (const property of properties) {
      if (JSON.stringify(this.props.defaults[property]) !== JSON.stringify(this.props.values[property])) {
        shouldBlock = true
        break
      }
    }
    return shouldBlock
  }

  handleSuccess (request) {
    this.props.flashMessages.push(
      I18n.t(
        'flashmessages.profile.update_successful'
      ),
      this.props.flashMessages.duration.SHORT,
      this.props.flashMessages.levels.INFO
    )
    this.fetchDefaults()
    // If we detect a language change, full refresh is needed. Otherwise refetch is enough.
    if (this.props.values.locale !== this.props.myProfile?.locale) {
      window.location.reload(false)
    } else {
      this.props.refetchSession()
    }
  }

  showQuestionnaireSettingsUpdatedFlashMessage () {
    this.props.flashMessages.push(
      I18n.t(
        'flashmessages.questionnaires.update_successful'
      ),
      this.props.flashMessages.duration.SHORT,
      this.props.flashMessages.levels.INFO
    )
  }

  showModal () {
    this.props.setModalComponent(TwoFactor, {})
  }

  profileTabIsActive () {
    const activeTabs = document.querySelectorAll('.tabs .indicator')
    // the indicator sliders aroudn with some animation duration, so leave some margin for error
    return (activeTabs && activeTabs[0] && parseInt(activeTabs[0].style.left) < 15)
  }

  isQuestionnaireSettingsModified () {
    return this.props.questionnaires.hasPendingChanges()
  }

  handleClick (e) {
    e.preventDefault()
    if (this.shouldBlockNavigation(e.target.closest('li').id)) {
      if (window.confirm(I18n.t('components.profile.not_saved'))) return
      if (this.profileTabIsActive()) {
        document.getElementById('profile_details_link').click()
        document.getElementById('submit').focus()
      }
      window.scrollTo(0, 1000)
    }
  }

  render () {
    const dataLoaded = this.props.defaults && Object.entries(this.props.defaults).length !== 0
    if (!dataLoaded) {
      return (
        <div className='row'>
          <div className='col s12'>
            <Spinner ready={dataLoaded} />
          </div>
        </div>
      )
    }
    const handleClick = function (e) {
      // HTML 5 required attribute handling
      if (!e.target.parentNode.checkValidity()) {
        return
      }
      this.props.handleSubmit(e, this.props.formName, this.props.submitMethod, this.props.endpoint, this.handleSuccess.bind(this), this.handleError.bind(this), this.getFieldsToSubmit()[this.props.stage])
      this.props.questionnaires.updateMyProfile()
        .then(() => {
          this.setState({ ...this.state, questionnaireErrors: {} })
          this.showQuestionnaireSettingsUpdatedFlashMessage()
        })
        .catch(e => {
          if (e.response.status === 422) {
            this.setState({ ...this.state, questionnaireErrors: e.response.data.errors[0].detail })
          }
        })
    }.bind(this)

    return (
      <div className='row'>
        <Prompt
          when={this.shouldBlockNavigation()}
          message={I18n.t('components.profile.not_saved')}
        />
        <div className='col s12 m4 welcome-left-menu'>
          <WelcomeController
            profileLink={this.props.myProfile?.public_url}
          />
        </div>
        <div className='col s12 m8'>
          <div className='edit-profile'>
            <ul className='tabs' id='edit-profile-tabs'>
              <li id='profile-details-view' className='tab col m3'><Link className={this.state.activeIndex === 1 ? 'active' : ''} to='#profile_details' id='profile_details_link' onClick={this.handleClick.bind(this)}>{I18n.t('profile.tabs.profile_details')}</Link></li>
              <li id='security-view' className='tab col m3'><Link className={this.state.activeIndex === 2 ? 'active' : ''} to='#security' onClick={this.handleClick.bind(this)}><span>{I18n.t('profile.tabs.security')}</span></Link></li>
              <li id='physiology-view' className='tab col m3'><Link className={this.state.activeIndex === 3 ? 'active' : ''} to='#physiology' id='physiology_link' onClick={this.handleClick.bind(this)}>{I18n.t('profile.tabs.physiology')}</Link></li>
              <li id='advanced-view' className='tab col m3'><Link className={this.state.activeIndex === 4 ? 'active' : ''} to='#advanced' onClick={this.handleClick.bind(this)}>{I18n.t('profile.tabs.advanced')}</Link></li>
            </ul>
            <div id='profile_details' className='tab-content active'>
              <div className='row no-margin-bottom'>
                <div className='col s12' style={{ position: 'relative' }}>
                  {this.props.myProfile?.under16 &&
                    <div style={{ 'background-color': mainBackgroundColor }}>
                      <InfoNotice msg={I18n.t('components.profile.under16', { guardian_email: this.props.myProfile?.guardian_email })} className='margin-bottom padding-top padding-bottom' />
                    </div>}
                  <form className='edit-profile-form'>
                    {this.renderFields()}
                    {/* Max:
                      the submit button has to be in a form for the API connector to work. however, it
                      is a bit ambiguous which form gets submitted, therefore the submission is done by
                      calling the handleSubmit functions on the respective formbuilders.

                      the reason why the API connector wants this is because it dervives the form from
                      event, and iterates over the form for image uploads, which it then takes care of.
                      in previous versions of this code, the profile form was also submitted with an
                      event from a different form, and the image uploads still worked (they still do).
                    */}
                    <input
                      id='submit'
                      type='submit'
                      onClick={handleClick}
                      className={classNames('button-primary background-primary text-background-color waves-effect waves-light profile-submit-button',
                        this.state.editingPicture && 'button-disabled')}
                      disabled={this.state.editingPicture}
                      value={I18n.t('components.questionnaires.save')}
                    />
                  </form>
                  <EditQuestionnaireProfileDetailsForm
                    errors={this.state.questionnaireErrors}
                  />
                </div>
              </div>
              <div className='profile-delete-message' dangerouslySetInnerHTML={{ __html: I18n.t('profile.edit.delete', { email: process.env.SUPPORT_EMAIL }) }} />
            </div>
            <div id='security' className='tab-content'>
              <div className='row'>
                <div className='col s12'>
                  <div className='text-heavy text-l text-muted data-header'>
                    {I18n.t('profile.tabs.security')}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col s12'>
                  <TwoFAButton onShowModal={this.showModal.bind(this)} twoFaEnabled={this.props.myProfile?.two_fa_enabled} className='button-autowidth' />
                </div>
              </div>
              <FeatureFlag name={CHANGE_EMAIL} defaultChildren={<></>}>
                <div className='row'>
                  <div className='col s12'>
                    <div className='text-heavy text-l text-muted data-header'>
                      {I18n.t('profile.tabs.change_email')}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col s12'>
                    <UpdateEmail onUpdatePassword={this.props.handleUpdatePassword} />
                  </div>
                </div>
              </FeatureFlag>
              <div className='row'>
                <div className='col s12'>
                  <div className='text-heavy text-l text-muted data-header'>
                    {I18n.t('profile.tabs.change_password')}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col s12'>
                  <UpdatePassword onUpdatePassword={this.props.handleUpdatePassword} />
                </div>
              </div>
            </div>
            <div id='physiology' className='tab-content'>
              <PhysiologicalDetails />
            </div>
            <div id='advanced' className='tab-content'>
              <OauthApplications />
              <ApiTokens />
              <SdvNextToggle />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WithModal(WithQuestionnaires(WithSession(WithFlashMessages(EditProfile))))
