import React from 'react'

export const FlashMessageContext = React.createContext()

export class FlashMessageProvider extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      messages: []
    }

    this.SHORT = 5000
    this.LONG = 10000

    this.INFO = 1
    this.WARNING = 2
    this.ERROR = 3
  }

  push (message, timeout, type) {
    const hasMessage = this.state.messages.filter((msg) => msg.message === message).length > 0

    if (hasMessage) {
      return
    }

    // TODO: can we do an UNSAFE_componentWillUnmount that stops this timer?
    setTimeout(() => {
      const newState = { ...this.state }
      newState.messages = this.state.messages.map((msg) => { return msg.message === message && msg.type === type ? { ...msg, state: 'hide' } : msg })
      this.setState(newState)
    }, timeout)
    const newState = { ...this.state }
    newState.messages.push({ message, type, state: 'show' })
    this.setState(newState)
  }

  pushErrorMessage (message) {
    this.push(message, this.LONG, this.ERROR)
  }

  pushInfoMessage (message) {
    this.push(message, this.SHORT, this.INFO)
  }

  removeMessage (message, type) {
    const newState = { ...this.state }
    newState.messages = this.state.messages.filter((msg) => msg.type !== type && msg.message !== message)
    this.setState(newState)
  }

  getMessages () {

  }

  render () {
    return (
      <FlashMessageContext.Provider value={{
        flashMessages: {
          push: this.push.bind(this),
          duration: { SHORT: this.SHORT, LONG: this.LONG },
          levels: { INFO: this.INFO, WARNING: this.WARNING, ERROR: this.ERROR },
          messages: this.state.messages,
          removeMessage: this.removeMessage.bind(this),
          pushErrorMessage: this.pushErrorMessage.bind(this),
          pushInfoMessage: this.pushInfoMessage.bind(this)
        }
      }}
      >
        {this.props.children}
      </FlashMessageContext.Provider>
    )
  }
}

export const WithFlashMessages = Component => React.forwardRef((props, ref) => {
  return (
    <FlashMessageContext.Consumer>
      {(context) => (<Component ref={ref} {...context} {...props} />)}
    </FlashMessageContext.Consumer>
  )
})
