import React from 'react'
import I18n from 'i18n'
import { round } from '../../common/Math'
import { highlightColor, momentWrittenMonthShortYearFormat } from '../../common/Constants'
import { Bar } from 'react-chartjs-2'
import moment from 'moment'
import Spinner from '../../common/Spinner'
import OauthApplicationsOverview from './OauthApplicationOverview'
import UsersController from '../UserController'
import VoucherController from './VoucherController'
import AdminPremium from '../premium/AdminPremium'
import TabPanel, { Tab } from '../../atomic/atoms/TabPanel'
import EmailAddressExport from './EmailAddressExport'

class ShowAdminDashboard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      renderCreateForm: false,
      description: '',
      selectedTab: 'statistics'
    }
  }

  componentDidMount () {
    M.updateTextFields()
  }

  createEntry (title, value, suffix) {
    return (
      <div key={title} className='no-margin collection-item'>
        <span className='badge'>{round(value, 2)} {suffix && I18n.t(suffix)}</span>
        <span className='text-muted'><strong>{I18n.t(title)}</strong></span>
      </div>
    )
  }

  createEntries (data, key) {
    const result = Object.keys(data[key]).map(entryKey => {
      return this.createEntry(`components.admin_dashboard.${key}.${entryKey}`, data[key][entryKey])
    })
    return (
      <div className='collection'>
        <div className='text-heavy text-s text-muted data-header'>
          {I18n.t(`components.admin_dashboard.${key}.title`)}
        </div>
        {result}
      </div>
    )
  }

  limitAndBackfillHistogram (histogramData) {
    /* These are not display dates, but used as index for the histogram. Therefore it is not neccesary to i18n them */
    const dates = Object.keys(histogramData).map(date => {
      return moment(date, 'MM/YYYY')
    }).sort((a, b) => a - b)
    const data = dates.map(elem => histogramData[elem.format('MM/YYYY')])

    let firstDate = moment().add(1, 'months')
    if (dates.length !== 0) {
      firstDate = dates[0]
    }

    while (dates.length < 12) {
      firstDate = moment(firstDate).subtract(1, 'months')
      dates.unshift(firstDate)
      data.unshift(0)
    }

    const labels = dates.map(d => d.format(momentWrittenMonthShortYearFormat))
    return ({ labels: labels, data: data })
  }

  createHistogram (histogramData, key) {
    const histData = this.limitAndBackfillHistogram(histogramData[key])
    const data = {
      labels: histData.labels,
      datasets: [
        {
          data: histData.data,
          label: I18n.t('components.admin_dashboard.histograms.label'),
          backgroundColor: highlightColor
        }
      ]
    }

    const options = {
      legend: {
        display: false
      }
    }

    return (
      <>
        <div className='text-heavy text-s text-muted data-header'>
          {I18n.t(`components.admin_dashboard.histograms.${key}.title`)}
        </div>
        <Bar data={data} options={options} />
      </>
    )
  }

  setSelectedTab (tabName) {
    this.setState({ selectedTab: tabName })
  }

  render () {
    if (this.props.statistics === undefined) {
      return (
        <div className='row'>
          <div className='col s12'>
            <Spinner ready={false} />
          </div>
        </div>
      )
    }

    const currentYear = new Date().getFullYear()

    return (
      <>
        <div>
          <div className='row'>
            <div className='col s12'>
              <div className='text-heavy text-primary-color text-xl'>
                {I18n.t('components.admin_dashboard.title')}
              </div>
            </div>
          </div>
          <TabPanel id='admin-dashboard-tabs' onShow={this.setSelectedTab.bind(this)}>
            <Tab id='statistics' title={I18n.t('components.admin_dashboard.statistics.title')}>
              {this.state.selectedTab === 'statistics' && (
                <>
                  <div className='row'>
                    <div className='col s12 text-heavy muted-header'>
                      <h5>{I18n.t('components.admin_dashboard.statistics.title')}</h5>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col s12 l6'>
                      {this.createEntries(this.props.statistics, 'user')}
                    </div>
                    <div className='col s12 l6'>
                      {this.createHistogram(this.props.statistics.histograms, 'user')}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col s12 l6'>
                      {this.createEntries(this.props.statistics, 'group')}
                    </div>
                    <div className='col s12 l6'>
                      {this.createHistogram(this.props.statistics.histograms, 'group')}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col s12 l6'>
                      {this.createEntries(this.props.statistics, 'data')}
                      {this.createEntries(this.props.statistics.data_distribution, 'collection_type')}
                      {this.createEntries(this.props.statistics.data_distribution, 'data_type')}
                    </div>
                    <div className='col s12 l6'>
                      {this.createHistogram(this.props.statistics.histograms, 'data')}
                    </div>
                  </div>
                </>
              )}
            </Tab>
            <Tab id='statistics-current-year' title={`${I18n.t('components.admin_dashboard.statistics.title')} ${currentYear}`}>
              {this.state.selectedTab === 'statistics-current-year' && (
                <>
                  <div className='row'>
                    <div className='col s12 text-heavy muted-header'>
                      <h5>{I18n.t('components.admin_dashboard.statistics.title')} {currentYear}</h5>
                    </div>
                    <div className='col s12'>
                      <p>{I18n.t('components.admin_dashboard.statistics.explanation_current_year')}</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col s12 l6'>
                      {this.createEntries(this.props.statisticsCurrentYear, 'user')}
                    </div>
                    <div className='col s12 l6'>
                      {this.createHistogram(this.props.statisticsCurrentYear.histograms, 'user')}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col s12 l6'>
                      {this.createEntries(this.props.statisticsCurrentYear, 'group')}
                    </div>
                    <div className='col s12 l6'>
                      {this.createHistogram(this.props.statisticsCurrentYear.histograms, 'group')}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col s12 l6'>
                      {this.createEntries(this.props.statisticsCurrentYear, 'data')}
                      {this.createEntries(this.props.statisticsCurrentYear.data_distribution, 'collection_type')}
                      {this.createEntries(this.props.statisticsCurrentYear.data_distribution, 'data_type')}
                    </div>
                    <div className='col s12 l6'>
                      {this.createHistogram(this.props.statisticsCurrentYear.histograms, 'data')}
                    </div>
                  </div>
                </>
              )}
            </Tab>
            <Tab cy='admin-oauth-applications-link' id='oauth-applications' title={I18n.t('components.admin_dashboard.oauth_applications.title')}>
              {this.state.selectedTab === 'oauth-applications' && <OauthApplicationsOverview handleSubmit={this.props.createApplication} oauthApplications={this.props.oauthApplications} />}
            </Tab>
            <Tab id='manage-users' title={I18n.t('components.admin_dashboard.manage_users.title')}>
              {this.state.selectedTab === 'manage-users' && (
                <>
                  <UsersController />
                  <EmailAddressExport />
                </>
              )}
            </Tab>
            <Tab id='premium-and-vouchers' title={I18n.t('components.admin_dashboard.premium_and_vouchers.title')}>
              {this.state.selectedTab === 'premium-and-vouchers' && (
                <>
                  <VoucherController handleSubmit={this.props.createVoucher} deleteVoucher={this.props.deleteVoucher} vouchers={this.props.vouchers} />
                  <AdminPremium />
                </>
              )}
            </Tab>
          </TabPanel>
        </div>
      </>
    )
  }
}

export default ShowAdminDashboard
