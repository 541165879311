import React from 'react'
import _ from 'lodash'
import ComplaintsQuestionnaireView from './ComplaintsQuestionnaireView'
import GenericQuestionnaireView from './GenericQuestionnaireView'
import {
  trainingLog,
  dailyQuestionnaireMonday,
  dailyQuestionnaireTuesday,
  dailyQuestionnaireWednesday,
  dailyQuestionnaireThursday,
  dailyQuestionnaireFriday,
  dailyQuestionnaireSaturday,
  dailyQuestionnaireSunday,
  sundayQuestionnaire,
  physicalComplaintsQuestionnaire,
  physicalAndHealthComplaintsQuestionnaire,
  restq,
  rheumaDailyQuestionnaire,
  intakeQuestionnaireRheumatism,
  asesRheumatism,
  brafRheumatism,
  eq5d5lRheumatism,
  hadsRheumatism,
  psychologicalCompetenciesQuestionnaire,
  moveMoodMotivationQuestionnaire
} from '../../../common/Constants'
import RestQView from './questionnaires/RestQView'
import PsychologicalCompetenciesView from 'components/data/layout/detail/questionnaires/PsychologicalCompetenciesView'

const QuestionnaireView = (props) => {
  if (!props.data) {
    return (<></>)
  }

  const responseData = _.get(props.data, 'data_rows[0]', undefined)

  const getView = (key) => {
    switch (key) {
      case physicalAndHealthComplaintsQuestionnaire:
      case physicalComplaintsQuestionnaire:
        return <ComplaintsQuestionnaireView data={responseData} />
      case restq:
        return <RestQView data={responseData} />
      case psychologicalCompetenciesQuestionnaire:
        return <PsychologicalCompetenciesView data={responseData} />
      case trainingLog:
      case dailyQuestionnaireMonday:
      case dailyQuestionnaireTuesday:
      case dailyQuestionnaireWednesday:
      case dailyQuestionnaireThursday:
      case dailyQuestionnaireFriday:
      case dailyQuestionnaireSaturday:
      case dailyQuestionnaireSunday:
      case sundayQuestionnaire:
      case rheumaDailyQuestionnaire:
      case intakeQuestionnaireRheumatism:
      case asesRheumatism:
      case brafRheumatism:
      case eq5d5lRheumatism:
      case hadsRheumatism:
      case moveMoodMotivationQuestionnaire:
      default:
        return <GenericQuestionnaireView data={responseData} />
    }
  }

  const key = _.get(responseData, 'questionnaire.key', undefined)
  const view = getView(key)

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          {view}
        </div>
      </div>
    </>
  )
}

export default QuestionnaireView
