import React from 'react'
import { collectionTypes } from '../../../common/Constants'
import { seriesHikingMetric } from '../../../common/Units'
import GenericActivityDashboard, {
  hrChartConfiguration,
  tempChartConfiguration
} from '../generic/GenericActivityDashboard'

const HikingDashboard = (props) => {
  const summaryFields = [
    'start_date',
    'distance',
    'duration',
    'avg_speed',
    'elevation_gain',
    'elevation_loss',
    'calories',
    'max_core_temperature',
    'tags'
  ]

  const lapFields = [
    { kind: 'path', name: 'components.dashboards.laps_table.avg_speed', path: 'summaries.speed.mean' },
    { kind: 'path', name: 'components.dashboards.laps_table.max_speed', path: 'summaries.speed.max' },
    { kind: 'path', name: 'components.dashboards.laps_table.avg_hr', path: 'summaries.hr.mean' },
    { kind: 'path', name: 'components.dashboards.laps_table.max_hr', path: 'summaries.hr.max' }
  ]

  const charts = [
    hrChartConfiguration,
    tempChartConfiguration
  ]

  return (
    <GenericActivityDashboard
      {...props}
      collectionType={collectionTypes.HIKING}
      speedUnit={seriesHikingMetric.speed}
      summaryFields={summaryFields}
      lapFields={lapFields}
      charts={charts}
    />
  )
}

export default HikingDashboard
