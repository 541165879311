import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import I18n from 'i18n'
import { MyAxios as axios } from '../../../MyAxios'
import { WithQuestionnaires } from '../../../questionnaires/QuestionnaireProvider'
import { readGenericQuestionnaire } from '../../../common/utils/QuestionnaireUtils'
import SummaryTable from '../../../analysis/dashboards/common/SummaryTable'

const GenericQuestionnaireView = (props) => {
  const key = _.get(props.data, 'questionnaire.key', undefined)
  const [questionnaireDefinition, setQuestionnaireDefinition] = useState([])
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([])

  useEffect(() => {
    axios({
      method: 'GET',
      url: props.questionnaires.questionnaireDefinitionUrl(key),
      headers: { Accept: 'application/json' }
    }).then((response) => {
      setQuestionnaireDefinition(response.data)
    }).catch((err) => {
      console.log('Error retrieving questionnaire definition')
      console.log(err)
    })
  }, [])

  useEffect(() => {
    if (questionnaireDefinition.content) setQuestionsAndAnswers(readGenericQuestionnaire(props.data, questionnaireDefinition, I18n.locale))
  }, [questionnaireDefinition])

  return (
    <>
      {questionsAndAnswers && (
        <div className='questionnaire-view'>
          <SummaryTable fields={questionsAndAnswers} />
        </div>
      )}
    </>
  )
}

export default WithQuestionnaires(GenericQuestionnaireView)
