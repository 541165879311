import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import TableInteractiveRow from './TableInteractiveRow'
import SelectDropdown from '../../../common/SelectDropdown'
import useDeepCompareEffect from 'use-deep-compare-effect'

const TableInteractive = (props) => {
  // TODO Make this more generic and remove these hardcoded values from here
  const [selectedHeaders, setSelectedHeaders] = useState({})
  const [rowIds, setRowIds] = useState([])

  const { id, categories, titles, entries } = props

  const optionChangeHandler = (optionIndex) => {
    return (e) => {
      const newSelectedHeaders = _.clone(selectedHeaders)
      newSelectedHeaders[optionIndex] = e?.target?.value
      setSelectedHeaders(newSelectedHeaders)
    }
  }

  const rowChangeHandler = (result) => {
    const { source, destination, draggableId } = result

    const didObjectLocationChange = !(destination.droppableId === source.droppableId && destination.index === source.index)
    if (!destination || !didObjectLocationChange) {
      return
    }

    const newRowIds = _.clone(rowIds)
    newRowIds.splice(source.index, 1)
    newRowIds.splice(destination.index, 0, draggableId)
    setRowIds(newRowIds)
  }

  const generateHeaderDropdown = (options, headerIndex, tableId) => {
    const dropdownOptions = options.map((option) => { return { name: titles[option], value: option } })
    return (
      <SelectDropdown
        id={`header-dropdown-${tableId}-${headerIndex}`}
        contentId={headerIndex}
        content={dropdownOptions}
        className='header-dropdown'
        defaultValue={_.first(options)}
        onChange={optionChangeHandler(headerIndex)}
      />
    )
  }

  const generateHeaderElement = (options, headerKey, icon, spans, headerClass) => {
    let content = null
    if (!Array.isArray(options)) {
      content = titles[options]
    } else {
      content = generateHeaderDropdown(options, headerKey, id)
    }

    return (
      <th colSpan={spans?.col} rowSpan={spans?.row} key={headerKey}>
        <div className={`valign-wrapper ${headerClass}`}>
          {icon &&
            <div className='category-icon'>
              <FontAwesomeIcon icon={icon} size='2x' />
            </div>}
          <div className='category-content'>
            {content}
          </div>
        </div>
      </th>
    )
  }

  const generateHeaders = (categoryRows) => {
    return (
      <thead>
        {categoryRows.map((row, rowIdx) => {
          let className = ''
          if (rowIdx === 0) { // large table headers = rowIdx 0
            // this class hides the large table headers for screen size medium and below
            className = 'tr-with-category-main'
          }

          return (
            <tr key={rowIdx} className={className}>
              {row.map((category) => {
                const { name, options, icon, colSpan, rowSpan, className } = category

                return generateHeaderElement(options || name, name, icon, { col: colSpan, row: rowSpan }, className)
              })}
            </tr>
          )
        })}
      </thead>
    )
  }

  // Retrieves the categories that are at the bottom of the header, closest to the table body
  const getLeafCategories = (categoryRows) => {
    const leavesLayer = categoryRows.length
    const leafCategories = []
    categoryRows.forEach((row, rowIdx) => {
      row.forEach((category) => {
        const rowSpan = category?.rowSpan || 1
        if (rowIdx + rowSpan === leavesLayer) {
          leafCategories.push(category)
        }
      })
    })
    return leafCategories
  }

  useEffect(() => {
    const defaultParameters = {}
    getLeafCategories(categories).forEach((category) => {
      defaultParameters[category.name] = _.first(category?.options) || category.name
    })
    setSelectedHeaders(defaultParameters)
  }, [categories])

  useDeepCompareEffect(() => {
    setRowIds(_.keys(entries))
  }, [_.keys(entries)])

  const displayOrder = getLeafCategories(categories).map((category) => selectedHeaders[category.name])
  return (
    <table className='responsive-table highlight hide-on-med-and-down' id={id}>
      {generateHeaders(categories)}

      <DragDropContext onDragEnd={rowChangeHandler}>
        <Droppable droppableId='interactive-table'>
          {(provided) => (
            <tbody ref={provided.innerRef} {...provided.droppableProps}>
              {rowIds.map((entryId, entryIndex) => {
                const entry = entries[entryId]
                return (entry && <TableInteractiveRow
                  key={entryId}
                  id={`${entryId}`}
                  index={entryIndex}
                  elements={entry.content}
                  displayOrder={displayOrder}
                  onClick={entry.handleClick}
                                 />)
              })}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    </table>
  )
}

export default TableInteractive
