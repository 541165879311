import { DEFAULT_HR_ZONES } from 'components/common/Constants'

export const getInnerBounds = (bounds?: number[]): number[] => {
  const limits = bounds?.slice(1, 6)
  // If the limit returned here becomes [], it will crash the Slider.
  if (limits === undefined || limits.length === 0) {
    return DEFAULT_HR_ZONES
  }
  return limits
}

export const calculateZones = (value: number): number[] => {
  const result = Array<number>(0)
  result.push(0)
  // zone 1: <50 % of the max
  result.push(Math.floor(0.5 * value))
  // zone 2: >50 - 60 % of the max
  result.push(Math.floor(0.6 * value))
  // zone 3: >60 - 70 % of the max
  result.push(Math.floor(0.7 * value))
  // zone 4: >70 - 80 % of the max
  result.push(Math.floor(0.8 * value))
  // zone 5: >80 - 90 % of the max
  result.push(Math.floor(0.9 * value))
  // zone 6: >90 % of the max
  result.push(value)
  return result
}
