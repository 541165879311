import React from 'react'
import GenericMultiActivityDashboard from '../generic/GenericMultiActivityDashboard'
import { collectionSubTypes, collectionTypes, timeSeries } from '../../../common/Constants'
import I18n from 'i18n'
import { seasonSwimmingMetric } from '../../../common/Units'
import Variables from '../../../../../javascript/stylesheets/variables.module.scss'
const { secondaryColor } = Variables

const SwimmingDashboardMulti = props => {
  const fields = [
    'avg_distance_per_week',
    'avg_duration_per_week',
    'avg_trainings_per_week',
    'avg_elevation_per_week',
    'avg_hr',
    'max_hr',
    'avg_speed',
    'max_speed'
  ]

  const categories = [
    { name: collectionSubTypes.GENERIC_SUBTYPE, color: secondaryColor, label: I18n.t('collection_type.swimming') }
  ]

  const intensityMetrics = [
    { value: timeSeries.HR, name: I18n.t('components.dashboards.multi_activity.intensity.hr') }
  ]

  return (
    <GenericMultiActivityDashboard
      title={I18n.t('components.dashboards.swimming.title')}
      seasonFields={fields}
      collectionType={collectionTypes.SWIMMING}
      categories={categories}
      intensityMetrics={intensityMetrics}
      seasonUnitsTable={seasonSwimmingMetric}
      {...props}
    />
  )
}

export default SwimmingDashboardMulti
