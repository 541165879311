import React, { useEffect, useState } from 'react'
import { WithSession } from 'components/session/SessionProvider'
import I18n from 'i18n'
import PropTypes from 'prop-types'
import { dailyQuestionnaires, trainingLog } from 'components/common/Constants'
import SelectMultiple from '../../../../common/form/SelectMultiple'
import { preprocessTrainingLogs, preprocessWellbeingQuestionnaires } from '../Utils'
import MultiTrainingAcuteChronicWorkloadRatioChart from './MultiTrainingAcuteChronicWorkloadRatioChart'
import MultiWeeklyInjuryRiskSeverityScoreOSTRCChart from './MultiWeeklyInjuryRiskSeverityScoreOSTRCChart'
import MultiDailyRestingHeartrateChart from './MultiDailyRestingHeartrateChart'
import MultiDailyWellnessChart from './MultiDailyWellnessChart'
import MultiDailyReadinessToTrainChart from './MultiDailyReadinessToTrainChart'
import MultiDailySleepChart from './MultiDailySleepChart'
import Spinner from '../../../../common/Spinner'
import MyReactTooltip from '../../../../MyReactTooltip'
import PremiumOverlay from '../../../../premium_membership/PremiumOverlay'
import { isDailyQuestionnaire, isTrainingLog } from 'components/common/utils/QuestionnaireUtils'
import InfoRequiredData, { DASHBOARD_REQUIRED_DATA_TYPES } from '../../common/RequiredDataInfo'
import { isEmpty, isNil, sortBy } from 'lodash'
import { useQuestionnaireData } from '../Hooks'
import VDO from 'components/common/VDO'
import DateRangePicker from 'components/analysis/dashboards/questionnaire/common/DateRangePicker'
import WeekSlider from 'components/analysis/dashboards/questionnaire/common/WeekSlider'
import SpinnerWrapper from 'components/common/SpinnerWrapper'

const MultiAthleteQuestionnaireDashboard = (props) => {
  const { myProfile, minDate, maxDate, sliderStartDate, sliderEndDate, filterDataRowsBySlider } = props

  const [selectedAthletes, setSelectedAthletes] = useState(props.selectedProfileId ? [`${props.selectedProfileId}`] : [`${props.lastSelectedAthleteId}`])
  const [selectedTrainingLogsVdo, setSelectedTrainingLogsVdo] = useState([])
  const [selectedDailyQuestionnairesVdo, setSelectedDailyQuestionnairesVdo] = useState([])
  const [sundaySummary, setSundaySummary] = useState([])
  const [dailyLogRows, setDailyLogRows] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [trainingLogRows, setTrainingLogRows] = useState([])
  const [weeklySummary, setWeeklySummary] = useState([])
  const [allDailyLogRows, setAllDailyLogRows] = useState([])
  const [allSundaySummary, setAllSundaySummary] = useState([])
  const [allTrainingLogRows, setAllTrainingLogRows] = useState([])
  const [allWeeklySummary, setAllWeeklySummary] = useState([])

  // NOTE: in the below line, `type` is an array of questionnaire names.
  const queryParams = {
    start_date: minDate?.toISOString(),
    end_date: maxDate?.toISOString(),
    type: [trainingLog, ...dailyQuestionnaires]
  }
  const {
    questionnaires: { data: questionnaireData, isSuccess: questionnairesAreFetched, isLoading, isError },
    profiles: { data: profiles }
  } = useQuestionnaireData({
    params: queryParams,
    questionnairesOptions: { enabled: !!myProfile && !isEmpty(selectedAthletes) },
    profilesOptions: { enabled: true },
    groupsOptions: { enabled: false }
  })

  const athletes = sortBy(profiles, [profile => profile.first_name, profile => profile.last_name])

  useEffect(() => {
    const selectedQuestionnaires = questionnaireData.filter(questionnaire => selectedAthletes.includes(`${questionnaire.owner.id}`))
    const filteredTrainingLogs = selectedQuestionnaires.filter(questionnaire => isTrainingLog(VDO.sdo(questionnaire)))
    setSelectedTrainingLogsVdo(filteredTrainingLogs)

    const filteredDailyQuestionnaires = selectedQuestionnaires.filter(questionnaire => isDailyQuestionnaire(VDO.sdo(questionnaire)))
    setSelectedDailyQuestionnairesVdo(filteredDailyQuestionnaires)
  }, [questionnairesAreFetched, selectedAthletes])

  const getAthleteObj = (athleteId) => {
    return athletes?.filter(curAthlete => `${curAthlete.id}` === athleteId)?.[0]
  }

  const filterQuestionnairesByAthlete = (questionnaireList, athlete) => {
    return questionnaireList.filter(questionnaire => questionnaire.owner.id === athlete.id)
  }

  useEffect(() => {
    const tempTrainingLogRows = []
    const tempWeeklySummary = []
    for (const athleteId of selectedAthletes) {
      const athlete = getAthleteObj(athleteId)
      if (isNil(athlete)) continue // This can be the case if it there is no questionnaireData
      if (!selectedTrainingLogsVdo.length) continue

      const filteredVdos = filterQuestionnairesByAthlete(selectedTrainingLogsVdo, athlete)
      const filteredSdos = filteredVdos.map(q => q.structured_data_objects[0])

      const processedTrainingLogs = preprocessTrainingLogs(filteredSdos)
      const { trainingLogRows, weeklySummary } = processedTrainingLogs

      trainingLogRows && tempTrainingLogRows.push({ athlete, data: trainingLogRows })
      weeklySummary && tempWeeklySummary.push({ athlete, data: weeklySummary })
    }
    setAllTrainingLogRows(tempTrainingLogRows)
    setAllWeeklySummary(tempWeeklySummary)
  }, [selectedTrainingLogsVdo])

  useEffect(() => {
    const tempDailyLogRows = []
    const tempSundaySummary = []
    for (const athleteId of selectedAthletes) {
      const athlete = getAthleteObj(athleteId)
      if (isNil(athlete)) continue // This can be the case if it there is no questionnaireData

      const selectedDQVdo = filterQuestionnairesByAthlete(selectedDailyQuestionnairesVdo, athlete)
      const selectedDQSdo = selectedDQVdo.map(q => q.structured_data_objects[0])

      const { dailyLogRows, sundaySummary } = preprocessWellbeingQuestionnaires(selectedDQSdo)
      dailyLogRows && tempDailyLogRows.push({ athlete, data: dailyLogRows })
      sundaySummary && tempSundaySummary.push({ athlete, data: sundaySummary })
    }
    setAllDailyLogRows(tempDailyLogRows)
    setAllSundaySummary(tempSundaySummary)
  }, [selectedDailyQuestionnairesVdo])

  useEffect(() => {
    const tempDailyLogRows = []
    for (const athleteSet of allDailyLogRows) {
      const { data, ...theRest } = athleteSet
      tempDailyLogRows.push({ ...theRest, data: filterDataRowsBySlider(data, 'date_only') })
    }
    setDailyLogRows(tempDailyLogRows)
  }, [allDailyLogRows, sliderStartDate, sliderEndDate])

  useEffect(() => {
    const tempSundaySummary = []
    for (const athleteSet of allSundaySummary) {
      const { data, ...theRest } = athleteSet
      tempSundaySummary.push({ ...theRest, data: filterDataRowsBySlider(data, 'date_only') })
    }
    setSundaySummary(tempSundaySummary)
  }, [allSundaySummary, sliderStartDate, sliderEndDate])

  useEffect(() => {
    const tempTrainingLogRows = []
    for (const athleteSet of allTrainingLogRows) {
      const { data, ...theRest } = athleteSet
      tempTrainingLogRows.push({ ...theRest, data: filterDataRowsBySlider(data, 'training_start_date') })
    }
    setTrainingLogRows(tempTrainingLogRows)
  }, [allTrainingLogRows, sliderStartDate, sliderEndDate])

  useEffect(() => {
    const tempWeeklySummary = []
    for (const athleteSet of allWeeklySummary) {
      const { data, ...theRest } = athleteSet
      tempWeeklySummary.push({ ...theRest, data: filterDataRowsBySlider(data, 'date_only') })
    }
    setWeeklySummary(tempWeeklySummary)
  }, [allWeeklySummary, sliderStartDate, sliderEndDate])

  const commonProperties = {
    noDataMessage: athletes.length > 0 && selectedAthletes.length === 0 ? I18n.t('components.placeholders.select_athletes') : I18n.t('components.placeholders.default_message')
  }

  if (!myProfile || Object.keys(myProfile).length === 0) return <Spinner transparent />
  return (
    <PremiumOverlay activeFor={['premium_coach', 'premium_researcher']} showBadge={false}>
      <div>
        <div className='row'>
          <div className='col s12 text-xl text-primary-color text-heavy'>
            {I18n.t('components.dashboards.multi_athlete.title')}
            <InfoRequiredData
              id='dataset-types-info'
              dataTypes={DASHBOARD_REQUIRED_DATA_TYPES.multi_athlete_questionnaire}
            />
          </div>
        </div>

        <div className='row'>
          {/* no-padding to get the layout right, because the DateRangePicker already adds padding */}
          <div className='col s8 no-padding'>
            <DateRangePicker {...props} />
          </div>
          <div className='col s4'>
            <SelectMultiple
              id='athletes'
              formName='components.dashboards'
              component='multi_athlete'
              type='select_multiple'
              fieldName='athletes'
              currentValue={selectedAthletes}
              choices={athletes.map(athlete => {
                return {
                  properties: {
                    name: `${athlete.first_name} ${athlete.last_name}`,
                    value: athlete.id
                  }
                }
              })}
              onChange={e => {
                setSelectedAthletes(e.getSelectedValues())
              }}
              selectAll={() => setSelectedAthletes(athletes.map(athlete => `${athlete.id}`))}
              selectNone={() => setSelectedAthletes([])}
              noWrapper
              noTranslate
            />
          </div>
          <WeekSlider {...props} />
        </div>
        <div className='row'>
          <div className='col s12'>
            <div className='titlebar'>Injury risk</div>
            <div className='row'>
              <div className='col s12 l6'>
                <div className='chart-container'>
                  <SpinnerWrapper ready={!isLoading && !isError} failed={isError && !isLoading}>
                    <MultiTrainingAcuteChronicWorkloadRatioChart weeklySummary={weeklySummary} {...commonProperties} />
                  </SpinnerWrapper>
                </div>
              </div>
              <div className='col s12 l6'>
                <div className='chart-container'>
                  <SpinnerWrapper ready={!isLoading && !isError} failed={isError && !isLoading}>
                    <MultiWeeklyInjuryRiskSeverityScoreOSTRCChart sundaySummary={sundaySummary} {...commonProperties} />
                  </SpinnerWrapper>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col s12'>
            <div className='titlebar'>Health</div>
            <div className='row'>
              <div className='col s12 l6'>
                <div className='chart-container'>
                  <SpinnerWrapper ready={!isLoading && !isError} failed={isError && !isLoading}>
                    <MultiDailyWellnessChart dailyLogRows={dailyLogRows} {...commonProperties} />
                  </SpinnerWrapper>
                </div>
              </div>
              <div className='col s12 l6'>
                <div className='chart-container'>
                  <SpinnerWrapper ready={!isLoading && !isError} failed={isError && !isLoading}>
                    <MultiDailyRestingHeartrateChart dailyLogRows={dailyLogRows} {...commonProperties} />
                  </SpinnerWrapper>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col s12 l6'>
                <div className='chart-container'>
                  <SpinnerWrapper ready={!isLoading && !isError} failed={isError && !isLoading}>
                    <MultiDailyReadinessToTrainChart dailyLogRows={dailyLogRows} {...commonProperties} />
                  </SpinnerWrapper>
                </div>
              </div>
              <div className='col s12 l6'>
                <div className='chart-container'>
                  <SpinnerWrapper ready={!isLoading && !isError} failed={isError && !isLoading}>
                    <MultiDailySleepChart dailyLogRows={dailyLogRows} {...commonProperties} />
                  </SpinnerWrapper>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MyReactTooltip id='questionnaire-tooltip' effect='solid' className='wider-tooltip' />
      </div>
    </PremiumOverlay>
  )
}

MultiAthleteQuestionnaireDashboard.propTypes = {
  history: PropTypes.object,
  questionnaireData: PropTypes.array,
  setSelectedProfileId: PropTypes.func,
  selectedProfileId: PropTypes.number
}

export default WithSession(MultiAthleteQuestionnaireDashboard)
