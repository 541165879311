import React, { useState } from 'react'
import I18n from 'i18n'
import { validateAction } from 'components/modal/ConfirmationDialogUtils'
import { Action, ActionType } from 'components/common/types/ConfirmationDialogAction'

interface SafeDeleteModalProps {
  onConfirmation: () => void
  onClose: () => void
  action: Action
  target: string
  noQuotes: boolean
}

const ConfirmationDialogSafeDelete: React.FC<SafeDeleteModalProps> = (props: SafeDeleteModalProps): React.ReactElement => {
  const [confirmationInput, setConfirmationInput] = useState<string>('')
  const { onConfirmation: handleConfirmation, onClose, action, target, noQuotes } = props

  const onConfirmation = (): void => {
    handleConfirmation()
    onClose()
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setConfirmationInput(e.target.value)
  }

  // This list can be extended to allow actions (like edit, leave group ...)
  const validActions: readonly Action[] = Object.freeze(['delete'])

  const actionBefore: string = validateAction(action, ActionType.action_before, validActions)
  const actionAfter: string = validateAction(action, ActionType.action_after, validActions)

  const targetString: string = noQuotes ? `${target}` : `'${target}'`

  const confirmTitle: string = I18n.t('components.confirmation_dialog.title')
  const confirmBefore: string = I18n.t('components.confirmation_dialog.action_before.delete_confirmation')
  const confirmAfter: string = I18n.t('components.confirmation_dialog.action_after.delete_confirmation')
  const confirmMessage: string = `${confirmBefore} ${targetString} ${confirmAfter}.`

  const doStringsMatch: boolean = target === confirmationInput

  return (
    <div id='confirmation-dialog' className='custom-modal-wrapper'>
      <div className='confirmation-dialog'>
        <div className='confirmation-dialog-title text-xl text-primary-color text-heavy text-center'>
          {`${confirmTitle} ${actionBefore} ${targetString} ${actionAfter}?`}
        </div>
        <div className='confirmation-dialog-body text-l text-primary-color text-heavy text-center'>
          {confirmMessage}
        </div>
        <div className='confirmation-dialog-input'>
          <input
            id='text-validate-confirm'
            type='text'
            value={confirmationInput}
            onChange={handleTextChange}
            autoFocus
          />
        </div>
        <div className='confirmation-dialog-buttons-wrapper'>
          <button
            id='confirmation-dialog-confirm'
            className='waves-effect waves-light button-accent button-autowidth background-accent text-background-color'
            onClick={onConfirmation}
            style={{ minWidth: '150px' }}
            disabled={!doStringsMatch}
          >
            {I18n.t('components.confirmation_dialog.confirm')}
          </button>
          <button
            id='confirmation-dialog-cancel'
            className='waves-effect waves-light button-primary button-autowidth background-background text-primary-color'
            onClick={onClose}
            style={{ minWidth: '150px' }}
          >
            {I18n.t('components.confirmation_dialog.cancel')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationDialogSafeDelete
