import React from 'react'
import _ from 'lodash'
import I18n from 'i18n'

import { subscriptionTypes } from '../../common/Constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PremiumFeaturesTable = (props) => {
  const { features } = props
  const { features: featureList, inDevelopment, differByPlan } = features
  const elements = []

  const SUBSCRIPTION_ORDER = [subscriptionTypes.researcher, subscriptionTypes.coach, subscriptionTypes.free]

  const generateDescription = (feature, isInDevelopment, isDifferentBetweenPlans) => {
    let description = I18n.t(`premium.advertisement.features.${feature}${isDifferentBetweenPlans ? '.description' : ''}`)
    if (isInDevelopment) {
      description += ` ${I18n.t('premium.advertisement.features.in_development')}`
    }
    return <td className='feature-description' key='description'>{description}</td>
  }

  const generateEntries = (feature, availablePlans, isDifferentBetweenPlans) => {
    return SUBSCRIPTION_ORDER.map((subscription) => {
      if (availablePlans.includes(subscription)) {
        return (
          <td className='feature-availability-entry' key={subscription}>
            {isDifferentBetweenPlans && I18n.t(`premium.advertisement.features.${feature}.${subscription}`)}
            {!isDifferentBetweenPlans && <FontAwesomeIcon icon='check' />}
          </td>
        )
      } else {
        return (
          <td className='feature-availability-entry' key={subscription} />
        )
      }
    })
  }

  let isHeaderPushed = false
  _.forIn(featureList, (categoryFeatureList, category) => {
    if (isHeaderPushed) {
      elements.push(
        <tr className='header-row' key={category}>
          <th className='category-table-header'>{I18n.t(`premium.advertisement.categories.${category}`)}</th>
          <th className='subscription-table-header' />
          <th className='subscription-table-header' />
          <th className='subscription-table-header' />
        </tr>)
    } else {
      elements.push(
        <tr className='header-row' key={category}>
          <th className='category-table-header'>{I18n.t(`premium.advertisement.categories.${category}`)}</th>
          <th className='subscription-table-header'>{I18n.t(`premium.types.${SUBSCRIPTION_ORDER[0]}`)}</th>
          <th className='subscription-table-header'>{I18n.t(`premium.types.${SUBSCRIPTION_ORDER[1]}`)}</th>
          <th className='subscription-table-header'>{I18n.t(`premium.types.${SUBSCRIPTION_ORDER[2]}`)}</th>
        </tr>)
      isHeaderPushed = true
    }

    _.forIn(categoryFeatureList, (plans, feature) => {
      elements.push(
        <tr key={feature}>
          {generateDescription(feature, inDevelopment.includes(feature), differByPlan.includes(feature))}
          {generateEntries(feature, plans, differByPlan.includes(feature))}
        </tr>
      )
    })
  })

  return (
    <div className='col s12'>
      <table className='premium-features-table'>
        <tbody>
          {elements}
        </tbody>
      </table>
    </div>
  )
}

export default PremiumFeaturesTable
