import React, { FC, ReactElement } from 'react'
import { Route, RouteComponentProps } from 'react-router'
import Timeline from './layout/Timeline'
import NotFoundWrapper from '../router/NotFoundWrapper'
import Routes from '../common/Routes'

export interface TimelineRoutesProps {
  timelineProps: any
  networkProps: any
}

const TimelineRoutes: FC<TimelineRoutesProps> = ({ timelineProps, networkProps }): ReactElement => {
  return (
    <NotFoundWrapper slug='timeline'>
      <Route exact path={Routes.root} render={(props: RouteComponentProps) => (<Timeline {...props} {...timelineProps} />)} />
      <Route exact path={Routes.timeline.index} render={(props: RouteComponentProps) => (<Timeline {...props} {...timelineProps} />)} />
      <Route
        exact path={Routes.timeline.network.index}
        render={(props: RouteComponentProps) => (<Timeline {...props} {...networkProps} network />)}
      />
      <Route
        exact path={Routes.addedIntegration}
        render={(props: RouteComponentProps) => (<Timeline {...props} {...timelineProps} network showIntegrations />)}
      />
    </NotFoundWrapper>
  )
}
TimelineRoutes.defaultProps = {
}
export default TimelineRoutes
