import React from 'react'
import _ from 'lodash'
import I18n from 'i18n'
import PropTypes from 'prop-types'

import InputGroup from './InputGroup'
import Collapse from '../atoms/Collapse'

const InputGroupWithExtraOptions = (props) => {
  const {
    inputComponents,
    onChange,
    onToggleExtraOptions,
    showExtraOptions,
    extraOptionsButtonText = {},
    disableWhenOpen,
    values
  } = props
  const { main: mainComponents, extra: extraComponents } = inputComponents
  const { whenOpen, whenClosed } = extraOptionsButtonText

  const handleInputChange = (newInputs) => {
    const currentInputs = _.cloneDeep(values)
    onChange(_.assign(currentInputs, newInputs))
  }

  const buttonText = {
    whenOpen: whenOpen || I18n.t('components.collapse.close'),
    whenClosed: whenClosed || I18n.t('components.collapse.open')
  }

  const disableMainInputs = disableWhenOpen && showExtraOptions

  const mainInputValues = _.pick(values, mainComponents.map((component) => component.props?.name))
  const extraInputValues = _.pick(values, extraComponents.map((component) => component.props?.name))

  return (
    <>
      <InputGroup inputComponents={mainComponents} disabled={disableMainInputs} onChange={handleInputChange} values={mainInputValues} />
      <Collapse open={showExtraOptions} onToggle={onToggleExtraOptions} buttonText={buttonText}>
        <div className='extra-inputs-container'>
          <InputGroup inputComponents={extraComponents} onChange={handleInputChange} values={extraInputValues} />
        </div>
      </Collapse>
    </>
  )
}

InputGroupWithExtraOptions.propTypes = {
  // Input elements to display. Divided into 'main' and 'extra' inputs
  inputComponents: PropTypes.shape({
    // Input elements that are always visible.
    main: PropTypes.arrayOf(PropTypes.element),
    // Input elements that only show when the collapse is open. Useful for things like "Advanced Options"
    extra: PropTypes.arrayOf(PropTypes.element)
  }),
  // Callback that is called when one of filters change
  onChange: PropTypes.func.isRequired,
  // Callback that is called when the collapse button is clicked
  onToggleExtraOptions: PropTypes.func,
  // If true, opens the collapse to show the extra input elements.
  showExtraOptions: PropTypes.bool,
  // Text to be displayed in the collapse button
  extraOptionsButtonText: PropTypes.shape({
    whenOpen: PropTypes.string,
    whenClosed: PropTypes.string
  }),
  // If true, disables the main inputs when the extra inputs are shown
  disableWhenOpen: PropTypes.bool,
  // Values to show in the input elements.
  values: PropTypes.object.isRequired
}

export default InputGroupWithExtraOptions
