export enum SubscriptionType {
  FREE = 'free',
  COACH = 'premium_coach',
  RESEARCHER = 'premium_researcher'
}

export const SUPER_PREMIUM: SubscriptionType = SubscriptionType.RESEARCHER

export interface Subscription {
  valid: boolean
  renewable: boolean
  expires: string // TODO Should this be Date instead, and the string value would be converted to date after fetching?
  eligible_for_trial: boolean
  type: SubscriptionType
}

export default Subscription
