import React from 'react'
import Joyride, { EVENTS, ACTIONS, Step, CallBackProps } from 'react-joyride'
import I18n from 'i18n'
import { cloneDeep } from 'lodash'

import styles from 'stylesheets/variables.module.scss'
import FeatureFlag, { TOUR } from 'components/feature_flags/FeatureFlag'
import { smartTranslate } from 'components/common/Utils'

const { accentColorDark, topMostZIndex } = styles

/**
 * The Tour component renders a step-by-step tooltip tour that the user can interact with.
 * The steps of each tour should be defined in a separate file in this directory.
 *
 * This component is a wrapper around react-joyride.
 * The Joyride component was first designed to be uncontrolled.
 * It can be made controlled by setting the stepIndex and using the onStepChange callback.
 * However, there is a caveat. Check the warning below.
 *
 * WARNING: The current handler (handleEvent) cannot handle situations where the tour steps
 * parameters are { target: 'body', placement: 'center' }. This is due to a bug in Joyride, which triggers the
 * callback multiple times in this situation, and makes stepIndex increment more times than intended,
 * giving thes sensation of "skipping" steps.
 */

// Interface between the React Joyride actions and the Tour actions
export enum TOUR_ACTION {
  NEXT = 'next',
  PREV = 'prev',
  NONE = 'none'
}

interface StepChangeHandlerProps {
  currentStep: number
  newStep: number
  action: TOUR_ACTION
}

interface Props {
  steps: Step[]
  stepIndex: number
  onStepChange: (props: StepChangeHandlerProps) => void
  continuous: boolean
  showProgress: boolean
  ready?: boolean
}

const Tour: React.FunctionComponent<Props> = (props): React.ReactElement => {
  // For the props explanation, check https://docs.react-joyride.com/props
  const { steps, stepIndex, onStepChange, continuous, showProgress, ready = true } = props

  // Handles React Joyride events, and determines when the tour step should change, and to which value.
  // A prototype diagram explaining when each event is triggered can be found here: https://app.diagrams.net/#G1y69l05CBq_TJPt9zXtt96LYMY3knEvb_
  const handleEvent = (tourEvent: CallBackProps): void => {
    const { action, type } = tourEvent

    const eventsTypesToHandle: string[] = [
      EVENTS.STEP_AFTER, // Triggers after the user performs some action in the tooltip (next, prev, close)
      EVENTS.BEACON // Triggers when the beacon is shown
    ]

    if (eventsTypesToHandle.includes(type)) {
      let newIndex: number
      let newAction: TOUR_ACTION

      switch (action) {
        case ACTIONS.NEXT:
          newIndex = stepIndex + 1
          newAction = TOUR_ACTION.NEXT
          break
        case ACTIONS.PREV:
          newIndex = stepIndex - 1
          newAction = TOUR_ACTION.PREV
          break
        default:
          newIndex = stepIndex
          newAction = TOUR_ACTION.NONE
          break
      }

      onStepChange({
        currentStep: stepIndex,
        newStep: newIndex,
        action: newAction
      })
    }
  }

  const style = {
    primaryColor: accentColorDark,
    zIndex: parseInt(topMostZIndex)
  }
  const locale = {
    back: I18n.t('components.tour.back'),
    close: I18n.t('components.tour.close'),
    last: I18n.t('components.tour.last'),
    next: I18n.t('components.tour.next'),
    open: I18n.t('components.tour.open'),
    skip: I18n.t('components.tour.skip')
  }

  const translatedSteps: Step[] = steps.map((step: Step) => {
    const clonedStep: Step = cloneDeep(step)
    clonedStep.content = smartTranslate(clonedStep.content)
    return clonedStep
  })

  return (
    <FeatureFlag name={TOUR} debug={false}>
      {ready && <Joyride
        steps={translatedSteps}
        stepIndex={stepIndex}
        callback={handleEvent}
        continuous={continuous}
        styles={{
          options: style
        }}
        locale={locale}
        showProgress={showProgress}
                />}
    </FeatureFlag>
  )
}

export default Tour
