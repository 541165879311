import React, { FC, ReactElement } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { smartTranslate } from '../../../common/Utils'
import I18n from 'i18n'
import { WithModal } from '../../../modal/ModalProvider'
import DeleteButton from 'components/atomic/atoms/DeleteButton'
import Group from 'components/common/types/Group'
import { GroupProtocolSubscription } from 'components/common/types/GroupProtocolSubscription'

export interface ActiveGroupProtocolListProps {
  groupProtocolSubscriptions: any[]
  rotating: boolean
  // From the ModalProvider
  setConfirmationDialog: (options: Record<string, unknown>) => void
  group: Group
  onDestroy: (groupId: number, protocolSubscriptionId: number) => void
  onRefresh: () => void
}

const ActiveGroupProtocolList: FC<ActiveGroupProtocolListProps> = ({
  rotating,
  groupProtocolSubscriptions,
  onDestroy,
  onRefresh,
  setConfirmationDialog,
  group
}): ReactElement => {
  const hasSubscriptions = groupProtocolSubscriptions?.length > 0

  const showDestroyDialog = (groupId: number, protocolSubscriptionId: number): void => {
    const displayTitle = I18n.t('group.detail.questionnaires.this_protocol')
    setConfirmationDialog({
      onConfirmation: () => onDestroy(groupId, protocolSubscriptionId),
      target: displayTitle,
      action: 'delete',
      noQuotes: true
    })
  }
  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <div className='text-heavy text-l text-muted data-header inline-block'>
            {I18n.t('group.detail.questionnaires.active_group_questionnaires')}
          </div>
          <FontAwesomeIcon
            icon='sync'
            className={classNames('title-icon pointer-grow', rotating && 'rotating disabled-link')}
            onClick={() => onRefresh()}
            title={I18n.t('group.detail.questionnaires.refresh_active_questionnairs')}
          />
          {hasSubscriptions && (
            <table className='striped protocols-table responsive-table'>
              <thead>
                <tr>
                  <th>{I18n.t('group.detail.questionnaires.protocol')}</th>
                  <th>{I18n.t('group.detail.questionnaires.duration')}</th>
                  <th>{I18n.t('group.detail.questionnaires.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {groupProtocolSubscriptions?.length > 0 && groupProtocolSubscriptions.map((q: GroupProtocolSubscription) => {
                  return (
                    <tr key={q.id}>
                      <td className='nowrap'>
                        {smartTranslate(`group.detail.questionnaires.protocols.${q.protocol_name}`)}
                      </td>
                      <td>
                        {I18n.t('datetime.distance_in_words.x_days', { count: q.duration_in_days })}
                      </td>
                      <td>
                        <DeleteButton onClick={() => showDestroyDialog(group.id, q.id)} isDisabled={rotating}>
                          {I18n.t('group.detail.questionnaires.delete')}
                        </DeleteButton>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )}
          {!hasSubscriptions && (
            <div className='row'>
              <div className='col s12'>
                <em className='text-muted'>{I18n.t('group.detail.questionnaires.no_active_group_protocol_subscriptions')}</em>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
ActiveGroupProtocolList.defaultProps = {
}
export default WithModal(ActiveGroupProtocolList) as typeof ActiveGroupProtocolList
