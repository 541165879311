import React from 'react'
import FormFactory from '../../helpers/FormFactory'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import SdvLogo from '../../images/sdv-logo.svg'
import { Link } from 'react-router-dom'
import AnimateHeight from 'react-animate-height'
import I18n from 'i18n'

class LoginContainer extends FormFactory {
  constructor () {
    super()
    this.state = {
      callback: null,
      animationFinished: false
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ animationFinished: false })
    } else {
      this.state.animationFinished && prevProps.targetHeight === this.props.targetHeight && this.checkAndUpdateHeight()
    }
  }

  /* re-animates the height of the container if neccesary (i.e. when error messages are rendered in the form) */
  checkAndUpdateHeight () {
    const height = document.getElementById('test-id').scrollHeight

    if (this.props.targetHeight !== height) {
      this.props.setHeight(height)
    }
  }

  onAnimationEnd (props) {
    this.setState({ animationFinished: true })
  }

  changeLanguage () {
    let loginUrl = process.env.HOST_URL + '/login'

    if (I18n.locale === 'en') {
      loginUrl = loginUrl.concat('?lang=nl')
    } else if (I18n.locale === 'nl') {
      loginUrl = loginUrl.concat('?lang=en')
    }
    window.location = loginUrl
  }

  render () {
    return (
      <div className='login-wrapper'>
        <div className={`login background-background top-rounded ${this.props.appendClassName || ''}`}>
          <Link to='/'>
            <img className='sdv-logo login-logo' src={SdvLogo} />
          </Link>
          <p className='text-secondary-color text-xl login-title'>Sport <b>Data</b> Valley</p>
          <AnimateHeight id='test-id' duration={this.props.duration} height={this.props.targetHeight} onAnimationEnd={this.onAnimationEnd.bind(this)}>
            {this.props.children}
          </AnimateHeight>
          <button
            id='change-language-button'
            onClick={this.changeLanguage.bind(this)}
            className='button-primary background-primary button-autowidth waves-effect waves-light text-background-color'
          >
            {I18n.locale === 'en' ? I18n.t('radio.radio.locale.dutch') : I18n.t('radio.radio.locale.english')}
          </button>
        </div>
      </div>
    )
  }
}

export default WithFlashMessages(LoginContainer)
