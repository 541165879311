import React, { useMemo } from 'react'
import polyline from '@mapbox/polyline'
import SDVMapView from '../../../data/layout/detail/SDVMapView'

const PolylineView = (props) => {
  if (!props.polyline) return <></>

  const latlong = useMemo(() => polyline.decode(props.polyline), [props.polyline])
  return (
    <SDVMapView path={latlong} />
  )
}

export default PolylineView
