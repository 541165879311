import React from 'react'
import Account from '../account/Account'
import StaticRoutes from '../static/StaticRoutes'
import GroupJoin from '../network/GroupJoin'
import NotFoundProvider from './NotFoundProvider'
import OAuthController from '../oauth/OAuthController'

export default class LoggedOutRouter extends React.Component {
  render () {
    return (
      <div className='container'>
        <NotFoundProvider>
          <Account />
          <StaticRoutes />
          <GroupJoin />
          <OAuthController />
        </NotFoundProvider>
      </div>
    )
  }
}
