import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import PropTypes from 'prop-types'
import { Chips } from '../../common/form'
import I18n from 'i18n'

const UploadFilesList = (props) => {
  const removePendingFile = (fileIndex) => {
    const updatedFiles = [...props.pendingFiles]
    updatedFiles.splice(fileIndex, 1)
    props.setPendingFiles(updatedFiles)
  }

  const handleTagChange = (e) => {
    props.setPendingFileTags(e.target.value)
  }

  return (
    <>
      <label>{I18n.t('components.data.files_to_upload')}</label>
      <ul className='collection files fixed-height-list'>
        <TransitionGroup className='data-files'>
          {props.pendingFiles.map((file, index) => (
            <CSSTransition
              key={index}
              timeout={200}
              classNames='item'
              appear
            >
              <div>
                <li className='collection-item'>
                  <div>
                    {file.name}
                    <span
                      className='secondary-content'
                      title='Remove'
                      onClick={() => removePendingFile(index)}
                    >
                      <FontAwesomeIcon icon='times' className='list-icon' />
                    </span>
                  </div>
                </li>
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </ul>
      <Chips
        fieldName='tag-chips'
        label={I18n.t('components.data.add_tags')}
        currentValue={props.pendingFileTags}
        onChange={handleTagChange}
      />
      <button
        id='upload-files-button'
        className='button-primary background-primary button-autowidth waves-effect waves-light text-background-color'
        onClick={props.handleUpload}
      >{I18n.t('components.data.upload_files')}
      </button>
    </>
  )
}

UploadFilesList.propTypes = {
  pendingFiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  setPendingFiles: PropTypes.func.isRequired
}

export default UploadFilesList
