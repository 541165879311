import React, { forwardRef, useState, useRef, useEffect, useImperativeHandle } from 'react'
import { ICellEditorParams } from 'ag-grid-community/dist/lib/interfaces/iCellEditor'
import DatePicker from 'react-datepicker'
import { dateAndTimePickerDefaultOptions } from '../../../common/Constants'

const DateAndTimeEditor = forwardRef((props: ICellEditorParams, ref) => {
  const [date, setDate] = useState<Date | null>(new Date(props.value ?? new Date()))
  const refInput = useRef<DatePicker>(null)

  useEffect(() => {
    // focus on the input
    refInput.current?.setFocus()
  }, [])

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue () {
        return date
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart () {
        return false
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd () {
        // A date is required
        return date === null
      }
    }
  })

  return (
    <div style={{ minHeight: '277px', minWidth: '312px' }}>
      <DatePicker
        {...dateAndTimePickerDefaultOptions()}
        id='start-date-picker'
        ref={refInput}
        selected={date}
        onChange={dateOrNull => {
          setDate(dateOrNull)
          setTimeout(props.stopEditing, 200)
        }}
      />
    </div>
  )
})

export default DateAndTimeEditor
