import React from 'react'

interface Props {
  id: string
  tooltip?: string // The optional properties are used in ./TabHeader.tsx
  disabled?: boolean
  showPremiumBadge?: boolean
  title?: string
}

const Tab: React.FC<Props> = ({ id, children }) => {
  return (
    <div id={id} className='col s12 tab-content'>
      {children}
    </div>
  )
}

export default Tab
