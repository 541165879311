import React from 'react'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WithModal } from '../../modal/ModalProvider'
import { defaultProfilePicture } from '../../common/Constants'
import { TimeAgoPlain } from '../../common/TimeAgo.jsx'

class ConnectionRequest extends React.Component {
  showRejectDialog (connectionRequest) {
    this.props.setConfirmationDialog({ onConfirmation: () => this.props.onRejectConnection(connectionRequest), action: 'reject_connection', target: connectionRequest.name })
  }

  render () {
    const { connectionRequest } = this.props
    if (!connectionRequest || !connectionRequest.other_membership) {
      return (<></>)
    }
    return (
      <div className='col s12 m6 l4'>
        <div className='square-card'>
          <div className='card-content'>
            <div className='row center-align padding-top' style={{ marginBottom: '0.75rem' }}>
              <div className='col s4 offset-s4'>
                <img className='circle responsive-img' src={connectionRequest.other_membership.profile.picture || defaultProfilePicture} />
              </div>
            </div>
            <div className='card-vertical-center'>
              <div className='row' style={{ marginBottom: '0.25rem' }}>
                <div className='col s12 center-align text-primary-color text-heavy text-m connection-one-line'>
                  {`${connectionRequest.other_membership.profile.first_name} ${connectionRequest.other_membership.profile.last_name}`}
                </div>
                <div className='col s12 center-align text-muted text-light text-m connection-one-line'>
                  {I18n.t('connections.requests.since', { since: TimeAgoPlain(new Date(connectionRequest.created_at)) })}
                </div>
              </div>
            </div>
            <div className='card-bottom row'>
              <div className='col s10 offset-s1 m12'>
                <button onClick={() => this.props.onAcceptConnection(connectionRequest)} className='no-breaks waves-effect waves-light button-primary text-background-color background-primary text-medium text-m'>
                  <FontAwesomeIcon className='margin-right' icon='check' />
                  {I18n.t('connections.requests.accept')}
                </button>
              </div>
              <div className='col s10 offset-s1 m12'>
                <button onClick={() => this.showRejectDialog(connectionRequest)} className='no-breaks waves-effect waves-light button-accent text-accent-color background-background text-medium text-m'>
                  <FontAwesomeIcon className='margin-right' icon='times' />
                  {I18n.t('connections.requests.decline')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WithModal(ConnectionRequest)
