import React from 'react'
import GroupMembers from '../../../network/layout/GroupMembers'
import MinimalProfileDTO from 'components/common/types/DTOs/MinimalProfileDTO'
import TrainingScheduleAthletes from 'components/common/types/TrainingScheduleAthletes'

interface Props {
  valueFormatted?: string
  value: TrainingScheduleAthletes
}

const AthletesRenderer: React.FC<Props> = ({ value }): React.ReactElement => {
  return (
    <GroupMembers
      memberships={value.profiles.reduce((acc: any[], v: MinimalProfileDTO) => acc.concat({ id: v.id, profile: v }), [])}
      avatarOnly
      noComma
    />
  )
}

export default AthletesRenderer
