import React from 'react'
import FitFileSessionFlavour from './FitFileSessionFlavour'
import FitFileRecordFlavour from './FitFileRecordFlavour'
import { flavours } from '../../../../common/Constants'

const FitFileDetails = (props) => {
  const sdos = props.data.structured_data_objects
  // Summary of the activity
  const session = sdos.find(sdo => sdo.flavour === flavours.fit_file_session)
  // GPS coordinates
  const records = sdos.find(sdo => sdo.flavour === flavours.fit_file_records)

  return (
    <>
      <FitFileSessionFlavour sdo={session} />
      <FitFileRecordFlavour sdo={records} />
    </>
  )
}

export default FitFileDetails
