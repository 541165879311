import { IconName } from '@fortawesome/fontawesome-svg-core'

// Check Constants.jsx. When converting that file to TS, these types should be taken into account.
export default interface Permission {
  id: number
  name: PermissionType
  icon: IconName
}

export enum PermissionType {
  READ = 'read',
  FULL = 'full'
}
