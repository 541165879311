import React, { useState, useMemo } from 'react'
import { WithBackend } from '../../../backend/BackendProvider'
import _ from 'lodash'
import I18n from 'i18n'
import { WithErrorBoundary } from '../../../errorboundary/ErrorBoundaryProvider'
import PredictionTimelineChart from './PredictionTimelineChart'
import PredictionCoefficientChart from './PredictionCoefficientChart'

const PredictionModelChart = (props) => {
  const [data, setData] = useState()

  const getData = () => {
    props.backend.showAggregatedResults('DataMiningAnalysisFitbit').then((response) => {
      setData(response.data?.content?.data)
    }).catch((e) => {
      console.error(e)
    })
  }

  const renderNoData = () => {
    return <div>{I18n.t('components.dashboards.steps.predictions.not_available')}</div>
  }

  // Memoization is needed here, otherwise we'll perform a ton of calls
  useMemo(() => getData(), [])

  if (_.isEmpty(data)) return renderNoData()

  let timelineData
  let coefficientData

  if ('data' in data) {
    timelineData = data.data
    coefficientData = data.coeff
  } else {
    // Backwards compatibility. The old format did not have coefficient data.
    timelineData = data
  }

  return (
    <div className='row'>
      <div className='col l12 xl6' key='fitbit.predictions' style={{ marginBottom: '3rem', marginTop: '0.5rem' }}>
        <PredictionTimelineChart data={timelineData} />
      </div>
      <div className='col l12 xl6' key='fitbit.coefficients' style={{ marginBottom: '3rem', marginTop: '0.5rem' }}>
        <PredictionCoefficientChart data={coefficientData} />
      </div>
    </div>
  )
}

export default WithErrorBoundary(WithBackend(PredictionModelChart), 'components.dashboards.steps.predictions.not_available')
