import { ModalContext } from 'components/modal/ModalProvider'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import AddTransponderForm from 'components/transponder/layout/AddTransponder/AddTransponderForm'
import { BackendContext } from 'components/backend/BackendProvider'
import axios from 'axios'
import { FlashMessageContext } from 'components/flashmessages/FlashMessageProvider'
import I18n from 'i18n'

const AddTransponder = (props: RouteComponentProps): ReactElement => {
  const modal = useContext(ModalContext)
  const { backend } = useContext(BackendContext)
  const { flashMessages } = useContext(FlashMessageContext)
  const [numberErrors, setNumberErrors] = useState<string[]>([])

  const closeModal = (): void => {
    props.history.push('/')
    modal.hide()
  }

  const handleOnSubmit = async (transponderNumber: string): Promise<void> => {
    try {
      await backend.transponders.create({ transponder: { number: transponderNumber } })
      flashMessages.push(I18n.t('components.transponder.add_form.success'), flashMessages.duration.SHORT, flashMessages.levels.INFO)
      closeModal()
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const error = e
        if (error.response?.status === 400) {
          flashMessages.push(I18n.t('components.transponder.add_form.errors.invalid_params'), flashMessages.duration.LONG, flashMessages.levels.ERROR)
          setNumberErrors(error.response.data?.errors?.[0]?.detail?.number)
        } else if (error.response != null) {
          flashMessages.push(I18n.t('components.transponder.add_form.errors.unexpected'), flashMessages.duration.LONG, flashMessages.levels.ERROR)
          console.error(error.response)
        } else if (error.request != null) {
          flashMessages.push(I18n.t('components.transponder.add_form.errors.unexpected'), flashMessages.duration.LONG, flashMessages.levels.ERROR)
          console.error(error.request)
        } else {
          flashMessages.push(I18n.t('components.transponder.add_form.errors.unexpected'), flashMessages.duration.LONG, flashMessages.levels.ERROR)
          console.error(error)
        }
      } else {
        flashMessages.push(I18n.t('components.transponder.add_form.errors.unexpected'), flashMessages.duration.LONG, flashMessages.levels.ERROR)
        console.error(e)
        // rethrow so it bubbles up to appsignal
        throw (e)
      }
    }
  }

  useEffect(() => {
    modal.setModalComponent(AddTransponderForm, { onSubmit: handleOnSubmit, numberErrors })
  }, [numberErrors])
  return <></>
}

export default AddTransponder
