import React, { useState, useEffect, useContext } from 'react'
import { MyAxios as axios } from '../MyAxios'
import GroupList from './layout/GroupList'
import { SessionContext } from '../session/SessionProvider'
import WelcomeController from '../welcome/WelcomeController'
import NetworkTabs from './NetworkTabs'
import { FlashMessageContext } from '../flashmessages/FlashMessageProvider'
import GroupCreateController from './GroupCreateController'
import I18n from 'i18n'
import GroupHeader from './layout/GroupHeader'
import GroupDetail from './layout/GroupDetail'
import GroupInviteList from './layout/GroupInviteList'
import GroupEdit from './layout/GroupEdit'
import { events } from '../common/Constants'
import ReactGA from 'react-ga'
import MyModal from '../modal/MyModal'
import Spinner from '../common/Spinner'
import { cloneDeep, set } from 'lodash'
import { filterActiveConnections, gAEnabled } from '../common/Utils'
import Routes from '../common/Routes'
import { WithBackend } from '../backend/BackendProvider'
import { WithQuestionnaires } from '../questionnaires/QuestionnaireProvider'
import AcceptInformedConsentForm from './layout/informed_consent/AcceptInformedConsentForm'
import { WithAppsignal } from '../appsignal/AppsignalProvider'

function GroupsCreateButton (props) {
  return (
    <button className='waves-effect waves-light button-accent text-background-color background-accent text-medium text-m button' onClick={props.onShowModal}>
      {I18n.t('network.groups.create_new_group')}
    </button>
  )
}

function GroupsController (props) {
  const session = useContext(SessionContext)
  const { flashMessages } = useContext(FlashMessageContext)

  const [currentGroup, setCurrentGroup] = useState(null)
  const [groupAnnouncements, setGroupAnnouncements] = useState(null)
  const [isAcceptingInvite, setIsAcceptingInvite] = useState(undefined)

  const { connections, groups, groupInvites } = props
  const extendedGroups = addMyMembershipToGroup(groups)
  const extendedGroupInvites = addMyMembershipToGroup(groupInvites)
  const acceptedConnections = filterActiveConnections(connections)

  // componentDidMount
  useEffect(() => {
    props.fetch()
  }, [])

  useEffect(() => {
    // In case we are currently accepting an invitation (props.joinGroup), set
    // the isAcceptingInvite state to show the modal
    const { match: { params: { groupId, membershipId } } } = props

    if (props.joinGroup) {
      // find the extended invite in the group invite list (including informed consent information)
      const invite = extendedGroupInvites?.find(inv => {
        return inv.id === parseInt(groupId) && inv.my_membership?.id === parseInt(membershipId)
      })

      // note that the actual invitation information is actually in the
      // .my_membership (see ./layout/GroupInvite.jsx)
      // setting the invitation to a null-ish value means the popup won't show
      setIsAcceptingInvite(invite?.my_membership)
    }
  }, [props.joinGroup, props.groupInvites, session.myProfile])

  function fetchGroup () {
    const { match: { params: { groupId } } } = props
    props.backend.groups.show(groupId).then(res => {
      setCurrentGroup(addMyMembershipToGroup([res.data])[0])
    })
  }

  function fetchGroupAnnouncements () {
    const { match: { params: { groupId } } } = props
    axios
      .get(`/api/v1/groups/${groupId}/group_announcements`,
        { headers: { Authorization: session.sessionToken } }
      )
      .then((res) => {
        setGroupAnnouncements(res.data)
      })
  }

  function openCreateGroup () {
    if (gAEnabled()) {
      ReactGA.event({
        category: events.category.button_click,
        action: 'GroupsCreateButton'
      })
    }
    props.history.push(Routes.network.groups.create)
  }

  function handleCloseCreateGroup () {
    props.history.push(Routes.network.groups.index)
  }

  function openGroupDetails (group) {
    props.history.push(Routes.network.groups.showFn(group.id))
  }

  function handleCloseGroupDetails () {
    props.history.push(Routes.network.groups.index)
    setCurrentGroup(null)
    setIsAcceptingInvite(undefined)
    props.questionnaires.restoreProfileState()
  }

  function addMyMembershipToGroup (groups) {
    return groups.map((group) => {
      group.my_membership = group.group_memberships.filter(
        (membership) => membership.profile.id === session.myProfile?.id)[0]
      return group
    })
  }

  function handleDeleteMembership (membership) {
    axios({
      method: 'DELETE',
      url: `/api/v1/group_memberships/${membership.id}`,
      headers: { Authorization: session.sessionToken }
    }).then((response) => {
      currentGroup && currentGroup.id && fetchGroup(currentGroup.id)
      props.fetch()
    })
  }

  function handleLeaveGroup () {
    axios({
      method: 'DELETE',
      url: `/api/v1/group_memberships/${currentGroup.my_membership.id}`,
      headers: { Authorization: session.sessionToken }
    }).then(() => {
      props.fetch()
      handleCloseGroupDetails()
    })
  }

  function handleGroupNavigationByType (group, type) {
    const id = currentGroup && currentGroup.id
    id && props.history.push(Routes.network.groups.typeFn(id, type))
  }

  function handleDeleteGroup (group) {
    axios({
      method: 'DELETE',
      url: `/api/v1/groups/${group.id}`,
      headers: { Authorization: session.sessionToken }
    }).then((response) => {
      props.fetch()
      handleCloseGroupDetails()
    })
  }

  function handleChangeRole (membership, newRole, cb = null) {
    axios({
      method: 'PUT',
      url: `/api/v1/group_memberships/${membership.id}`,
      data: {
        role: newRole
      },
      headers: { Authorization: session.sessionToken }
    }).then((response) => {
      currentGroup && currentGroup.id && fetchGroup(currentGroup.id)
      if (cb) cb()
    })
  }

  function handleChangeNotificationMute (muted) {
    const groupCopy = cloneDeep(currentGroup)
    set(groupCopy, 'my_membership.mute_notifications', muted)
    setCurrentGroup(groupCopy)

    props.backend.updateGroupMembershipSettings(currentGroup.my_membership.id, {
      group_membership: {
        mute_notifications: muted
      }
    }).then((response) => {
      flashMessages.push(
        I18n.t('flashmessages.groups.notification_settings_updated'),
        flashMessages.duration.SHORT,
        flashMessages.levels.INFO
      )
    })
  }

  function handleChangeEmailNotifications (emailNotifications) {
    const groupCopy = cloneDeep(currentGroup)
    set(groupCopy, 'my_membership.email_notifications', emailNotifications)
    setCurrentGroup(groupCopy)

    props.backend.updateGroupMembershipSettings(currentGroup.my_membership.id, {
      group_membership: {
        email_notifications: emailNotifications
      }
    }).then((response) => {
      flashMessages.push(
        I18n.t('flashmessages.groups.notification_settings_updated'),
        flashMessages.duration.SHORT,
        flashMessages.levels.INFO
      )
    })
  }

  function handleChangeDailyQuestionnaireNotifications (dailyQuestionnaireNotifications) {
    const groupCopy = cloneDeep(currentGroup)
    set(groupCopy, 'my_membership.daily_questionnaire_notifications', dailyQuestionnaireNotifications)
    setCurrentGroup(groupCopy)

    props.backend.updateGroupMembershipSettings(currentGroup.my_membership.id, {
      group_membership: {
        daily_questionnaire_notifications: dailyQuestionnaireNotifications
      }
    }).then((response) => {
      flashMessages.push(
        I18n.t('flashmessages.groups.notification_settings_updated'),
        flashMessages.duration.SHORT,
        flashMessages.levels.INFO
      )
    })
  }

  function sendAcceptResponse (invite, consent_response = undefined) {
    return props.backend.groupMemberships.accept(invite, consent_response).then(() => {
      flashMessages.push(
        I18n.t('flashmessages.groups.accepted'),
        flashMessages.duration.SHORT,
        flashMessages.levels.INFO)
    }).catch((e) => {
      flashMessages.push(
        I18n.t('flashmessages.groups.join.error'),
        flashMessages.duration.LONG,
        flashMessages.levels.ERROR
      )
      props.appsignal.sendError(e)
      console.error(e)
    })
  }

  function onFinishAcceptResponse () {
    props.fetch()
    // close the modal in case the invitation needed an informed consent
    handleCloseGroupDetails()
  }

  function handleAccept (invite) {
    // This will open the informed consent form in a modal
    setIsAcceptingInvite(invite)
  }

  function handleDelete (invite) {
    axios({
      method: 'DELETE',
      url: `/api/v1/group_memberships/${invite.id}`,
      headers: { Authorization: session.sessionToken }
    }).then((response) => {
      flashMessages.push(
        I18n.t('flashmessages.groups.declined'),
        flashMessages.duration.SHORT,
        flashMessages.levels.INFO)
      props.fetch()
    })
  }

  function goToActivity (id) {
    props.history.push(Routes.data.showFn(id))
  }

  function handleSearchGroups (e) {
    return new Promise((resolve, reject) => {
      resolve(props.groups.filter((group) => group.name.toLowerCase().includes(e.target.value.toLowerCase())))
    })
  }

  function handleFetchAll (isGroupDetail = true) {
    fetchGroup()
    fetchGroupAnnouncements()
    isGroupDetail ? props.fetch() : openGroupDetails(currentGroup)
  }

  if (!session.myProfile || !session.myProfile?.id) return <Spinner ready={false} />

  return (
    <>
      <MyModal isOpen={props.editGroup || props.createGroup || props.showGroup || !!isAcceptingInvite} onClose={handleCloseGroupDetails}>
        {props.createGroup && <GroupCreateController fetchGroups={props.fetch} hide={handleCloseCreateGroup} />}
        {props.showGroup &&
          <GroupDetail
            group={currentGroup}
            groupAnnouncement={groupAnnouncements}
            fetch={() => { handleFetchAll() }}
            onDeleteMembership={handleDeleteMembership}
            onLeaveGroup={handleLeaveGroup}
            onUpdateGroup={handleGroupNavigationByType}
            onAnnouncementGroup={handleGroupNavigationByType}
            onCreateProtocolSubscriptions={() => {}}
            onUpdateNotificationMute={handleChangeNotificationMute}
            onUpdateEmailNotifications={handleChangeEmailNotifications}
            onUpdateDailyQuestionnaireNotifications={handleChangeDailyQuestionnaireNotifications}
            onDeleteGroup={handleDeleteGroup}
            onChangeRole={handleChangeRole}
            goToActivity={goToActivity}
          />}
        {props.editGroup && <GroupEdit group={currentGroup} fetch={() => { handleFetchAll(false) }} />}
        {isAcceptingInvite && <AcceptInformedConsentForm invite={isAcceptingInvite} onAccept={sendAcceptResponse} onFinish={onFinishAcceptResponse} />}
      </MyModal>

      <div className='row'>
        <div className='col s12 m4 l4 welcome-left-menu'>
          <WelcomeController ModalButton={GroupsCreateButton} modalButtonProps={{ onShowModal: openCreateGroup }} />
        </div>
        <div className='col s12 m8 l8'>
          <NetworkTabs
            history={props.history}
            location={props.location}
            groupCount={groups.length}
            connectionCount={acceptedConnections.length}
          />
        </div>
        <div className='col s12 m8 l8'>
          <div className='padding-on-small'>
            <div className='row'>
              <GroupHeader
                onShowDetails={openGroupDetails}
                onSearchGroups={handleSearchGroups}
              />
            </div>
            <div className='row'>
              <GroupInviteList
                groupInvites={extendedGroupInvites}
                onAccept={handleAccept}
                onDelete={handleDelete}
                dataRetrieved={props.pendingConnectionsRetrieved}
              />
            </div>
            <div className='row'>
              {(extendedGroups && (
                <GroupList
                  groups={extendedGroups}
                  fetch={props.fetch}
                  onShowDetails={openGroupDetails}
                  dataRetrieved={props.connectionsRetrieved}
                />)) || <span>{I18n.t('network.groups.no_groups_yet')}</span>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WithAppsignal(WithQuestionnaires(WithBackend(GroupsController)))
