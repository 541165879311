import React, { useState, useEffect } from 'react'
import I18n from 'i18n'

import PremiumFilters from './layout/PremiumFilters'
import PremiumActions from './layout/PremiumActions'
import PremiumTable from './layout/PremiumTable'

import { WithBackend } from '../../backend/BackendProvider'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import { subscriptionTypes } from '../../common/Constants'

const AdminPremium = (props) => {
  const [profiles, setProfiles] = useState([])
  const [vouchers, setVouchers] = useState({})
  const [filters, setFilters] = useState({})
  const [selectedProfiles, setSelectedProfiles] = useState({})

  const isSelected = (id) => {
    return id in selectedProfiles && selectedProfiles[id]
  }

  const selectProfile = (id) => {
    selectedProfiles[id] = !isSelected(id)
    setSelectedProfiles({ ...selectedProfiles })
  }

  const handleError = (err) => {
    const msg = err.response.data.errors[0].detail
    props.flashMessages.push(msg, props.flashMessages.duration.LONG, props.flashMessages.levels.ERROR)
  }

  const applyAction = (action) => {
    const selectedShownProfiles = profiles.filter((prf) => selectedProfiles[prf.id])
    const ids = selectedShownProfiles.map((prf) => prf.id)
    if (action === 'cancel') {
      const msg = I18n.t('admin_dashboard.flashmessages.cancelled')
      props.flashMessages.push(msg, props.flashMessages.duration.LONG, props.flashMessages.levels.INFO)
      props.backend.admin.cancelSubscriptions(ids).then((resp) => {
        setFilters({ ...filters })
      }).catch(handleError)
    }
    if (action === 'infinite-research') {
      const msg = I18n.t('admin_dashboard.flashmessages.upgraded_research')
      props.flashMessages.push(msg, props.flashMessages.duration.LONG, props.flashMessages.levels.INFO)
      props.backend.admin.makeInfiniteSubscription(ids, subscriptionTypes.researcher).then((resp) => {
        setFilters({ ...filters })
      }).catch(handleError)
    }
    if (action === 'infinite-coach') {
      const msg = I18n.t('admin_dashboard.flashmessages.upgraded_coach')
      props.flashMessages.push(msg, props.flashMessages.duration.LONG, props.flashMessages.levels.INFO)
      props.backend.admin.makeInfiniteSubscription(ids, subscriptionTypes.coach).then((resp) => {
        setFilters({ ...filters })
      }).catch(handleError)
    }
  }

  const setVoucherCode = (profileId, code) => {
    const currentVouchers = { ...vouchers }
    currentVouchers[profileId] = code
    setVouchers(currentVouchers)
  }

  const redeemVoucherCode = (profileId, code) => {
    props.backend.admin.redeemVoucherCode(profileId, code).then((resp) => {
      const msg = I18n.t('redeem.flashmessages.voucher_redeemed')
      props.flashMessages.push(msg, props.flashMessages.duration.LONG, props.flashMessages.levels.INFO)
      setFilters({ ...filters })
    }).catch(handleError)
  }

  useEffect(() => {
    const queryString = `?${new URLSearchParams(filters)}`

    props.backend.admin.filterSubscriptions(queryString).then((resp) => {
      setProfiles(resp.data)
    })
  }, [filters])

  const applyFilters = (f) => { setFilters(f) }
  return (
    <>
      <div className='row spaced-down'>
        <div className='col s12 text-heavy muted-header'>
          <h5>{I18n.t('premium-title')}</h5>
        </div>
      </div>
      <div className='row'>
        <PremiumFilters applyFilters={applyFilters} />
        <PremiumActions applyAction={applyAction} />
      </div>
      <div className='row'>
        <div className='col s12'>
          <PremiumTable
            selectProfile={selectProfile}
            selectedProfiles={selectedProfiles}
            profiles={profiles}
            vouchers={vouchers}
            setVoucherCode={setVoucherCode}
            redeemVoucherCode={redeemVoucherCode}
          />
        </div>
      </div>
    </>
  )
}

export default WithFlashMessages(WithBackend(AdminPremium))
