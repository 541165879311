import React from 'react'
import I18n from 'i18n'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StravaLightLogo from '../../images/strava-light.svg'

const IntegrationIcon = ({ enabled, active, icon, iconUrl, name, onRemove, canRevoke, ...props }) => {
  const connected = enabled && active
  const revocable = connected && canRevoke
  const isStrava = icon === 'strava'
  const classes = [
    {
      'active-integration': active,
      'grayed-out': !isStrava && (!connected || !enabled),
      'faded-out': !isStrava && (!connected || !enabled),
      'color-on-hover': !isStrava && enabled && !active,
      'fade-in-on-hover': !isStrava && enabled && !active,
      pointer: !connected && enabled,
      'disabled-integration': !enabled, // Only used for testing purposes, not styling,
      'enabled-integration': enabled // Only used for testing purposes, not styling
    }
  ]
  const onClick = (connected || !enabled) ? undefined : props.onClick

  const dataTip = () => {
    if (!enabled) {
      return I18n.t('components.integrations.disabled')
    } else {
      return connected && !canRevoke ? I18n.t('components.integrations.cannotRevoke', { service: name }) : undefined
    }
  }

  return (
    <span className={classNames(...classes)} data-tip={dataTip()} data-for='higher-z-tooltip'>
      {revocable &&
        <span className='remove-integration z-depth-1'>
          <FontAwesomeIcon icon='times' onClick={onRemove} />
        </span>}
      {isStrava && (
        <div className='integration-icon' onClick={onClick}>
          <div className='show-only-on-hover' style={{ backgroundImage: `url(${iconUrl})` }} />
          <div className='hide-on-hover' style={{ backgroundImage: `url(${StravaLightLogo})` }} />
        </div>
      )}
      {!isStrava && (
        <img
          className={classNames('integration-icon hide-on-hover')}
          src={iconUrl}
          alt={icon}
          onClick={onClick}
        />
      )}
    </span>
  )
}

IntegrationIcon.propTypes = {
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  iconUrl: PropTypes.string,
  enabled: PropTypes.bool,
  active: PropTypes.bool,
  name: PropTypes.string
}

export default IntegrationIcon
