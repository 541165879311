import React from 'react'
import PolarDefaultFlavour from './PolarDefaultFlavour'

const PolarDetails = (props) => {
  const sdos = props.data.structured_data_objects
  if (!sdos || sdos.length < 1) return <></>
  return <PolarDefaultFlavour sdo={sdos[0]} icon={props.icon} metadatum={props.metadatum} />
}

export default PolarDetails
