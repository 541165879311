import React from 'react'
import _ from 'lodash'
import I18n from 'i18n'
import { HorizontalBar } from 'react-chartjs-2'
import 'chartjs-plugin-annotation'

import { filterOutTimings } from '../../../../common/utils/QuestionnaireUtils'
import { LIKERT_SCALE_MAX, LIKERT_SCALE_MIN, RESTQ_ANSWERS_MAP } from '../../../../common/Constants'
import { average } from '../../../../common/Math'
import Variables from '../../../../../stylesheets/variables.module.scss'
import { createHorizontalAnnotation } from '../../../../common/utils/ChartJSUtils'
const { primaryColor, blackTransparent } = Variables

const CATEGORIES_RESTQ_CHART = [
  'general_stress',
  'emotional_stress',
  'social_stress',
  'conflicts/pressure',
  'fatigue',
  'lack_of_energy',
  'physical_complaints',
  'success',
  'social_recovery',
  'physical_recovery',
  'general_wellbeing',
  'sleep_quality',
  'disturbed_breaks',
  'emotional_exhaustion',
  'injury',
  'being_in_shape',
  'personal_accomplishments',
  'self_efficacy',
  'self_regulation'
]
const DEFAULT_GRID_LINE = 'rgba(0, 0, 0, 0.1)'
const ACCENT_GRID_LINE = 'rgba(0, 0, 0, 1)'
const GRID_LINE_COLORS_RESTQ_CHART = Array(7).fill(DEFAULT_GRID_LINE)
  .concat(
    [ACCENT_GRID_LINE],
    Array(4).fill(DEFAULT_GRID_LINE),
    [ACCENT_GRID_LINE],
    Array(2).fill(DEFAULT_GRID_LINE),
    [ACCENT_GRID_LINE],
    Array(4).fill(DEFAULT_GRID_LINE)
  )

// You don't see answers 1-7 here because they are just user info
// You don't see answer 8 here because it is a warm up question
const ANSWER_IDS_FOR_EACH_CATEGORY = {
  general_stress: [29, 31, 37, 52],
  emotional_stress: [12, 15, 35, 44],
  social_stress: [28, 33, 46, 55],
  'conflicts/pressure': [19, 25, 39, 51],
  fatigue: [9, 23, 32, 42],
  lack_of_energy: [11, 18, 38, 47],
  physical_complaints: [14, 22, 27, 49],
  success: [10, 24, 48, 56],
  social_recovery: [13, 21, 30, 40],
  physical_recovery: [16, 20, 36, 45],
  general_wellbeing: [17, 41, 50, 54],
  sleep_quality: [26, 34, 43, 53],
  disturbed_breaks: [58, 65, 73, 79],
  emotional_exhaustion: [61, 70, 75, 83],
  injury: [57, 64, 71, 80],
  being_in_shape: [60, 68, 76, 82],
  personal_accomplishments: [62, 67, 77, 84],
  self_efficacy: [59, 66, 72, 78],
  self_regulation: [63, 69, 74, 81]
}
const ANSWER_IDS_WITH_INVERSE_SCORES = [36, 46]

const RestQView = (props) => {
  const { data } = props
  const { values } = data

  const numericalValues = _.mapValues(filterOutTimings(values), (answer) => RESTQ_ANSWERS_MAP[answer])
  // Scores are from 0 (never) - 6 (always).
  // Some of them need to be inverted, because, for the purpose of scoring, "always feeling bad" should instead be "never feel good".
  ANSWER_IDS_WITH_INVERSE_SCORES.forEach((answerId) => {
    numericalValues[`v${answerId}`] = 6 - numericalValues[`v${answerId}`]
  })

  const categoryScores = _.mapValues(ANSWER_IDS_FOR_EACH_CATEGORY, (answerIds) => {
    const individualScores = _.map(answerIds, (id) => numericalValues[`v${id}`])
    return average(individualScores)
  })

  const chartJsData = {
    labels: CATEGORIES_RESTQ_CHART,
    datasets: [{
      data: CATEGORIES_RESTQ_CHART.map((category) => categoryScores[category]),
      backgroundColor: primaryColor
    }]
  }

  const ANNOTATION_POSITION_CONFIGS = {
    alignment: 'right',
    yScaleID: 'y'
  }
  const ANNOTATION_LABEL_CONFIGS = {
    backgroundColor: blackTransparent
  }

  const chartJsConfig = {
    legend: {
      display: false
    },
    scales: {
      xAxes: [{
        id: 'x',
        ticks: {
          min: LIKERT_SCALE_MIN,
          max: LIKERT_SCALE_MAX,
          stepSize: 1,
          fontSize: 15
        },
        scaleLabel: {
          display: true,
          labelString: I18n.t('score'),
          fontSize: 15
        }
      }],
      yAxes: [
        {
          id: 'y',
          gridLines: {
            color: GRID_LINE_COLORS_RESTQ_CHART
          },
          ticks: {
            autoSkip: false,
            fontSize: 15,
            callback: (label) => {
              return I18n.t(`questionnaires.restq.summary_fields.${label}`)
            }
          }
        }
      ]
    },
    tooltips: {
      callbacks: {
        title: (tooltipItems) => I18n.t(`questionnaires.restq.summary_fields.${tooltipItems[0].label}`)
      }
    },
    annotation: {
      annotations: [
        createHorizontalAnnotation(
          { y: 3, ...ANNOTATION_POSITION_CONFIGS },
          { text: I18n.t('questionnaires.restq.sections.general_stress'), ...ANNOTATION_LABEL_CONFIGS }
        ),
        createHorizontalAnnotation(
          { y: 9, ...ANNOTATION_POSITION_CONFIGS },
          { text: I18n.t('questionnaires.restq.sections.general_recovery'), ...ANNOTATION_LABEL_CONFIGS }
        ),
        createHorizontalAnnotation(
          { y: 13, ...ANNOTATION_POSITION_CONFIGS },
          { text: I18n.t('questionnaires.restq.sections.sport_specific_stress'), ...ANNOTATION_LABEL_CONFIGS }
        ),
        createHorizontalAnnotation(
          { y: 16.5, ...ANNOTATION_POSITION_CONFIGS },
          { text: I18n.t('questionnaires.restq.sections.sport_specific_recovery'), ...ANNOTATION_LABEL_CONFIGS }
        )
      ]
    }
  }

  return (
    <HorizontalBar data={chartJsData} options={chartJsConfig} />
  )
}

export default RestQView
