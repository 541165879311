import { useContext, useMemo } from 'react'
import { useQuery } from 'react-query'
import { UseQueryOptions, UseQueryResult } from 'react-query/types'
import { BackendContext } from './BackendProvider'
import PhysiologicalAttribute from 'components/common/types/PhysiologicalAttribute'
import PaginatedResponse from 'components/common/types/PaginatedResponse'
import Notification from 'components/common/types/Notification'
import GroupOverviewDTO from '../common/types/DTOs/GroupOverviewDTO'
import Group from 'components/common/types/Group'
import { AxiosResponse, AxiosError } from 'axios'
import AggregatedResult from 'components/common/types/AggregatedResult'
import WeeklyTrainingLoadDTO from 'components/common/types/specific_queries/WeeklyTrainingLoad'
import TrainingDTO from '../common/types/DTOs/TrainingDTO'
import GroupDTO from '../common/types/DTOs/GroupDTO'
import FastJSON from '../common/types/FastJSON'
import ProfileMetadatum from '../common/types/ProfileMetadatum'

export const useQuestionnaireProfilesQuery = (params: unknown, options?: UseQueryOptions): UseQueryResult => {
  const { backend } = useContext(BackendContext)
  const { data, ...query } = useQuery<any>(['questionnaire-profiles', params], () => backend.questionnaireResponses.profiles(params), options)
  const transformedData = useMemo(() => {
    return data?.data?.data?.map((datum: any) => ({
      id: parseInt(datum.id),
      ...datum.attributes
    }))
  }, [data])
  return { ...query, data: { ...data, data: transformedData } }
}

export const useAggregatedDataQuery = (modelId: string, options?: UseQueryOptions<AggregatedResult, Error>): UseQueryResult<AggregatedResult, Error> => {
  const { backend } = useContext(BackendContext)
  return useQuery<AggregatedResult, Error>(['aggregated-data-query', modelId],
    async () => {
      const res = await backend.showAggregatedResults(modelId)
      return res.data
    },
    options)
}

export const useQuestionnaireGroupsQuery = (params: unknown, options?: UseQueryOptions): UseQueryResult => {
  const { backend } = useContext(BackendContext)
  return useQuery<unknown>(['questionnaire-groups', params], () => backend.questionnaireResponses.groups(params), options)
}

export const useQuestionnaireResponsesQuery = (params: unknown, options?: UseQueryOptions): UseQueryResult => {
  const { backend } = useContext(BackendContext)
  return useQuery<unknown>(['questionnaires', params], () => backend.questionnaireResponses.index(params), options)
}

export const useProtocolSubscriptionsQuery = (groupId: unknown, options?: UseQueryOptions): UseQueryResult => {
  const { backend } = useContext(BackendContext)
  return useQuery<unknown>(['protocolSubscriptions', groupId], () => backend.protocolSubscriptions.index(groupId), options)
}

export const useMyPhysiologicalAttributesQuery = (options?: UseQueryOptions<PhysiologicalAttribute[], Error>): UseQueryResult<PhysiologicalAttribute[], Error> => {
  const { backend } = useContext(BackendContext)
  return useQuery<PhysiologicalAttribute[], Error>(['myPhysiologicalAttributes'],
    async () => {
      const res = await backend.physiologicalAttributes.index()
      return res.data
    },
    options)
}

type NotificationResponse = PaginatedResponse<Notification>

export const usePaginatedNotifications = (page: number, options: UseQueryOptions<NotificationResponse, Error> = {}): UseQueryResult<NotificationResponse, Error> => {
  const { backend } = useContext(BackendContext)
  return useQuery<NotificationResponse, Error>(['notificationsPaginated', page], async () => {
    const res = await backend.notifications.getPaginatedNotifications(page)

    return res.data
  }, options)
}

export const usePaginatedUnreadNotifications = (page: number, options: UseQueryOptions<NotificationResponse, Error> = {}): UseQueryResult<NotificationResponse, Error> => {
  const { backend } = useContext(BackendContext)
  return useQuery<NotificationResponse, Error>(['notificationsUnreadPaginated', page], async () => {
    const res = await backend.notifications.getPaginatedUnreadNotifications(page)

    return res.data
  }, options)
}

export const useGroupOverviewQuery = (
  groupId: number,
  start: Date | null,
  end: Date | null,
  options?: UseQueryOptions<GroupOverviewDTO, Error>
): UseQueryResult<GroupOverviewDTO, Error> => {
  const { backend } = useContext(BackendContext)
  return useQuery<GroupOverviewDTO, Error>(['groupOverview', groupId],
    async () => {
      const res = await backend.groups.overview(groupId, start, end)
      return res.data
    },
    options)
}

// Retrieves all groups (including the public group) and connections
export const useGroupsQuery = (
  options?: UseQueryOptions<Group[], Error>
): UseQueryResult<Group[], Error> => {
  const { backend } = useContext(BackendContext)

  const queryFn = async (): Promise<Group[]> => {
    return backend.groups.get().then((res: AxiosResponse<Group[]>) => {
      return res.data
    })
  }

  const groupStaleTime: number = 5 /* min */ * 60 * 1000 // Default duration until the group/connection info is invalidated
  const updatedOptions = {
    ...options,
    staleTime: groupStaleTime
  }

  return useQuery<Group[], Error>('groups', queryFn, updatedOptions)
}

export const useWeeklyTrainingLoadQuery = (
  start: Date,
  end: Date,
  profileId: number,
  options?: UseQueryOptions<WeeklyTrainingLoadDTO[], Error>
): UseQueryResult<WeeklyTrainingLoadDTO[], Error> => {
  const { backend } = useContext(BackendContext)
  return useQuery<WeeklyTrainingLoadDTO[], Error>(['weeklyTrainingLoad', profileId, start.toDateString(), end.toDateString()],
    async () => {
      const res = await backend.dashboards.training.weeklyTrainingLoad(start, end, profileId)
      return res.data
    }, options)
}

// Using AxiosError here so that any non-connection related error is not suppressed(?)
export const useMonthlySchedules = (
  startDate: Date,
  options?: UseQueryOptions<TrainingDTO[], AxiosError>
): UseQueryResult<TrainingDTO[], AxiosError> => {
  const { backend } = useContext(BackendContext)
  return useQuery<TrainingDTO[], AxiosError>(['monthlySchedules', startDate],
    async () => {
      const res = await backend.schedules.monthly(startDate)
      return res.data
    },
    options)
}

export const useProfileMetadataQuery = (options?: UseQueryOptions<FastJSON<ProfileMetadatum>>): UseQueryResult<FastJSON<ProfileMetadatum>> => {
  const { backend } = useContext(BackendContext)

  return useQuery<FastJSON<ProfileMetadatum>>('useProfileMetadataQuery',
    async () => {
      const res = await backend.profiles.getMyProfileMetadata()
      return res.data
    },
    options
  )
}

// NOTE: this technically calls the same route as `useGroupsQuery` above, but the duplication
// is because that function uses different query parameters. useGroupsQuery also retrieves data
// in a different format (a non-DTO), which is not preferred. Ideally, `useGroupsQuery` should
// be changed to use GroupDTO instead, but I think that should be a separate ticket with low priority.
// For now, we can keep them separate without much overhead, as long as we're not adding more.
export const useGroupsDTOQuery = (options: UseQueryOptions<GroupDTO[], AxiosError> = {}): UseQueryResult<GroupDTO[], AxiosError> => {
  const { backend } = useContext(BackendContext)
  return useQuery<GroupDTO[], AxiosError>(['groupsIndex'],
    async () => {
      const res = await backend.groups.get()
      return res.data
    },
    options)
}
