import React from 'react'
import { WithModal } from '../modal/ModalProvider'
import { WithFlashMessages } from '../flashmessages/FlashMessageProvider'
import { WithSession } from '../session/SessionProvider'
import GroupCreate from './layout/GroupCreate'

class GroupCreateController extends React.Component {
  handleOnSuccess () {
    this.props.fetchGroups()
    this.props.hide()
  }

  handleOnFailure (errors) {
    this.props.flashMessages.push(
      errors[0].detail,
      this.props.flashMessages.duration.LONG,
      this.props.flashMessages.levels.ERROR
    )
  }

  render () {
    return (
      <GroupCreate
        onSuccess={this.handleOnSuccess.bind(this)}
        onFailure={this.handleOnFailure.bind(this)}
      />
    )
  }
}

export default WithFlashMessages(WithModal(WithSession(GroupCreateController)))
