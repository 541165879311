import React from 'react'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Download = (props) => (
  <a href={props.url || ''} onClick={props.onClick || (() => {})} className='data-button data-detail-page waves-effect text-m waves-light button-primary text-primary-color background-background text-medium'>
    <FontAwesomeIcon icon='download' className='button-icon' />
    {I18n.t('components.data.download')}
  </a>
)

export default Download
