import React from 'react'
import { WithSession } from '../session/SessionProvider'
import ConfirmUsers from './layout/ConfirmUsers'
import I18n from 'i18n'
import { WithFlashMessages } from '../flashmessages/FlashMessageProvider'
import { WithBackend } from '../backend/BackendProvider'
import { WithModal } from '../modal/ModalProvider'

const UsersController = (props) => {
  const confirmUser = (email) => {
    props.backend.admin.confirmUser({ email: email }).then(res => {
      props.flashMessages.push(I18n.t('components.admin_dashboard.user_controller.confirm.success'), props.flashMessages.duration.SHORT, props.flashMessages.levels.INFO)
    }).catch((error) => {
      console.log(error)
      props.flashMessages.push(I18n.t('errors.try_again'), props.flashMessages.duration.LONG, props.flashMessages.levels.ERROR)
    })
  }

  const deleteUserConfirmation = (email) => {
    props.setConfirmationDialogSafeDelete({
      onConfirmation: () => deleteUser(email),
      target: email,
      action: 'delete'
    })
  }

  const deleteUser = (email) => {
    props.backend.admin.destroyUser({ email: email }).then(res => {
      props.flashMessages.push(I18n.t('components.admin_dashboard.user_controller.delete.success'), props.flashMessages.duration.SHORT, props.flashMessages.levels.INFO)
    }).catch((error) => {
      console.log(error)
      props.flashMessages.push(`${I18n.t('errors.try_again')}: ${error?.response?.data?.errors[0]?.detail}`, props.flashMessages.duration.LONG, props.flashMessages.levels.ERROR)
    })
  }

  return (
    <>
      <ConfirmUsers handleSubmit={confirmUser} handleDelete={deleteUserConfirmation} />
    </>
  )
}

export default WithModal(WithBackend(WithSession(WithFlashMessages(UsersController))))
