import React from 'react'
import GenericMultiActivityDashboard from '../generic/GenericMultiActivityDashboard'
import { collectionSubTypes, collectionTypes, timeSeries } from '../../../common/Constants'
import I18n from 'i18n'
import { seasonRidingMetric } from '../../../common/Units'
import Variables from '../../../../../javascript/stylesheets/variables.module.scss'
import WielerFitheidGraph from 'components/atomic/organisms/WielerFitheidGraph'
import ChartContainer from 'components/atomic/molecules/ChartContainer'
import { BetaRibbon } from 'components/atomic/atoms/BetaRibbon'
import FeatureFlag, { WIELER_FITHEID } from 'components/feature_flags/FeatureFlag'
const { primaryColor, secondaryColor } = Variables

const RidingDashboardMulti = props => {
  const fields = [
    'avg_distance_per_week',
    'avg_duration_per_week',
    'avg_trainings_per_week',
    'avg_elevation_per_week',
    'avg_hr',
    'max_hr',
    'avg_speed',
    'max_speed',
    'avg_power',
    'max_power'
  ]

  const categories = [
    { name: collectionSubTypes.GENERIC_SUBTYPE, color: secondaryColor, label: I18n.t('components.dashboards.multi_activity.type.outdoors') },
    { name: collectionSubTypes.INDOOR_RIDING, color: primaryColor, label: I18n.t('components.dashboards.multi_activity.type.indoors') }
  ]

  const intensityMetrics = [
    { value: timeSeries.HR, name: I18n.t('components.dashboards.multi_activity.intensity.hr') },
    { value: timeSeries.POWER, name: I18n.t('components.dashboards.multi_activity.intensity.power') }
  ]

  return (
    <>
      <GenericMultiActivityDashboard
        title={I18n.t('components.dashboards.riding.title')}
        seasonFields={fields}
        collectionType={collectionTypes.RIDING}
        categories={categories}
        intensityMetrics={intensityMetrics}
        seasonUnitsTable={seasonRidingMetric}
        {...props}
      />
      <FeatureFlag name={WIELER_FITHEID}>
        <ChartContainer size='s12'>
          <BetaRibbon />
          <WielerFitheidGraph />
        </ChartContainer>
      </FeatureFlag>
    </>
  )
}

export default RidingDashboardMulti
