import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import I18n from 'i18n'
import React from 'react'
import PropTypes from 'prop-types'
import WithProfileMetadata from 'components/helpers/WithProfileMetadata'

function shouldRender (profileMetadata) {
  const pendingBeans = !!profileMetadata.pending_beans && profileMetadata.pending_beans > 0
  const pendingDataObjects = !!profileMetadata.pending_data_objects && profileMetadata.pending_data_objects > 0
  return pendingBeans || pendingDataObjects
}

const TimelinePendingJobs = ({ profileMetadata }) => {
  if (!shouldRender(profileMetadata)) return <></>

  return (
    <div className='timeline-card' id='timeline-refresh-prompt'>
      <div className='text-l text-heavy text-primary-color timeline-title center-align'>
        <div className='timeline-icon center-align'>
          <FontAwesomeIcon icon='hourglass-start' />
        </div>
        <p>{I18n.t('components.timeline.background_jobs.info')}</p>
      </div>
    </div>
  )
}

TimelinePendingJobs.propTypes = {
  profileMetadata: PropTypes.object
}

export default WithProfileMetadata(TimelinePendingJobs)
