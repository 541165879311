import React from 'react'
import { collectionTypes, timeSeries } from '../../../common/Constants'
import I18n from 'i18n'
import { seriesSwimmingMetric } from '../../../common/Units'
import GenericActivityDashboard, {
  BORDER_WIDTH,
  hrChartConfiguration,
  lapMinutesFormat,
  tempChartConfiguration
} from '../generic/GenericActivityDashboard'
import { smartRounding } from '../../../common/Math'
import Variables from '../../../../stylesheets/variables.module.scss'
const { secondaryColor, secondaryColorLight } = Variables

const SwimmingDashboardSingle = (props) => {
  const summaryFields = [
    'start_date',
    'distance',
    'duration',
    'avg_speed',
    'calories',
    'max_core_temperature',
    'tags'
  ]

  const lapFields = [
    { kind: 'fn', name: 'components.dashboards.laps_table.avg_speed', fn: (lap) => lapMinutesFormat(lap, 'summaries.speed.mean') },
    { kind: 'fn', name: 'components.dashboards.laps_table.max_speed', fn: (lap) => lapMinutesFormat(lap, 'summaries.speed.max') },
    { kind: 'path', name: 'components.dashboards.laps_table.avg_hr', path: 'summaries.hr.mean' },
    { kind: 'path', name: 'components.dashboards.laps_table.max_hr', path: 'summaries.hr.max' }
  ]

  const charts = [
    hrChartConfiguration,
    tempChartConfiguration,
    {
      timeSeries: timeSeries.CADENCE,
      config: {
        yTickCallback: v => `${smartRounding(v, 1)} ${I18n.t('units.strokes/min')}`,
        tooltipCallback: { label: (ti) => (`${smartRounding(ti.value, 1)} ${I18n.t('units.strokes/min')}`) },
        datasetConfig: {
          borderColor: secondaryColor,
          backgroundColor: secondaryColor,
          borderWidth: BORDER_WIDTH,
          fromColor: secondaryColorLight,
          toColor: secondaryColor
        }
      }
    }
  ]

  return (
    <GenericActivityDashboard
      {...props}
      collectionType={collectionTypes.SWIMMING}
      speedUnit={seriesSwimmingMetric.speed}
      summaryFields={summaryFields}
      lapFields={lapFields}
      charts={charts}
    />
  )
}

export default SwimmingDashboardSingle
