import React from 'react'
import AutocompleteProfileDTO from '../../../common/types/DTOs/AutocompleteProfileDTO'
import { defaultProfilePicture } from '../../../common/Constants'

interface Props {
  profiles: AutocompleteProfileDTO[]
  setProfiles: (newProfiles: AutocompleteProfileDTO[]) => void
}

const ManageProfiles: React.FC<Props> = ({ profiles, setProfiles }): React.ReactElement => {
  const removeProfile = (profile: AutocompleteProfileDTO): void => {
    setProfiles(profiles.filter(curProfile => curProfile.id !== profile.id))
  }

  return (
    <ul className='collection' style={{ margin: 0 }}>
      {profiles.map((profile: AutocompleteProfileDTO) => {
        const displayName = `${profile.first_name ?? ''} ${profile.last_name ?? ''}`
        return (
          <li className='collection-item avatar' key={profile.id} style={{ margin: 0, minHeight: '63px' }}>
            <img src={profile.picture !== '' ? profile.picture : defaultProfilePicture} alt={displayName} className='circle' />
            <span className='title'>{displayName}</span>
            <a href='#!' className='secondary-content' onClick={() => removeProfile(profile)}><i className='material-icons'>delete</i></a>
          </li>
        )
      })}
    </ul>
  )
}

export default ManageProfiles
