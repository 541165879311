import React from 'react'
import Spinner from '../../common/Spinner'
import I18n from 'i18n'
import PropTypes from 'prop-types'
import { defaultProfilePicture } from '../../common/Constants'
import { Link } from 'react-router-dom'
import { TimeAgoElement } from '../../common/TimeAgo'
import { CSSTransition } from 'react-transition-group'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WithModal } from '../../modal/ModalProvider'

class GroupAnnouncement extends React.Component {
  showRemoveDialog = (announcementId) => {
    const displayTitle = I18n.t('group.detail.group_announcement.delete_announcement')
    this.props.setConfirmationDialog({ onConfirmation: () => this.props.onDeleteAnnouncement(announcementId), target: displayTitle, action: 'delete', noQuotes: true })
  }

  render () {
    return (
      <>
        <div className='col s12'>
          {this.props.groupAnnouncements &&
              this.props.groupAnnouncements.map(announcement => (
                <CSSTransition
                  key={announcement.id}
                  timeout={200}
                  classNames='group-announcement-row'
                  appear
                  in
                >
                  <div className='row profile-row member-list group-announcement-row'>
                    <div className='col valign-wrapper s12'>
                      <div className='col no-margin s2 l2' style={{ minWidth: '50px', maxWidth: '100px', alignSelf: 'baseline' }}>
                        <img src={announcement.profile.picture || defaultProfilePicture} className='responsive-img circle' />
                      </div>
                      <div className='col no-margin s11 l11'>
                        <div className='row group-announcement-remove-margin'>
                          <div className='col no-margin'>
                            <div className='text-medium text-primary-color text-m'>
                              <Link to={announcement.profile.public_url}>
                                <p className='timeline-profile-details text-m'>
                                  {`${announcement.profile.first_name || I18n.t('profile.show.first_name')} ${announcement.profile.last_name || I18n.t('profile.show.last_name')}`}
                                </p>
                              </Link>
                              <p className='timeline-profile-details text-s text-muted'>
                                <TimeAgoElement date={new Date(announcement.created_at)} />
                              </p>
                            </div>
                          </div>
                          {this.props.isAdmin &&
                            <div className='col no-margin right-align text-l text-primary-color text-heavy pointer'>
                              <FontAwesomeIcon className='profile-progress-dismiss' icon='trash' onClick={() => this.showRemoveDialog(announcement.id)} />
                            </div>}
                        </div>
                        <div className='row no-margin s12 l12'>
                          <div key={announcement.id} className='col s12 group-announcement-message'>{announcement.message}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CSSTransition>
              ))}
          {this.props.groupAnnouncements === undefined && (
            <Spinner ready={false} transparent />
          )}
          {this.props.groupAnnouncements && this.props.groupAnnouncements.length === 0 && (
            <p className='text-muted'>
              {this.props.noDataMessage || I18n.t('network.group.label.no_announcements')}
            </p>
          )}
        </div>
      </>
    )
  }
}

GroupAnnouncement.propTypes = {
  groupAnnouncements: PropTypes.array,
  noDataMessage: PropTypes.string
}

export default WithModal(GroupAnnouncement)
