import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useFetchData } from '../../common/Hooks'
import TrainingDTO from '../../common/types/DTOs/TrainingDTO'
import Card from '../../atomic/atoms/Card'
import Header from '../../atomic/atoms/headers/Header'
import I18n from 'i18n'
import ScheduledTrainingSessions from './ScheduledTrainingSessions'
import moment from 'moment'
import StartAndEndDate from '../../atomic/molecules/StartAndEndDate'
import { useLocation } from 'react-router-dom'
import Training from '../../common/types/Training'
import { WithSession } from '../../session/SessionProvider'
import Profile from '../../common/types/Profile'
import { convertToTraining, parseSchedules } from './Utils'

// Provided optional history state if navigating to this page from the calendar view
export interface State {
  onDate?: string
}

interface Props {
  myProfile?: Profile
}

const TrainingSchedulesAthleteView: React.FC<Props> = (props) => {
  const { onDate } = useLocation<State>().state ?? {}
  const selectedAthleteIds = props.myProfile?.id !== undefined ? [props.myProfile?.id] : []

  let initialStartDate = moment().subtract(1, 'month').startOf('day').toDate()
  let initialEndDate = moment().add(1, 'month').endOf('day').toDate()
  if (onDate !== undefined) {
    initialStartDate = moment(onDate).startOf('day').toDate()
    initialEndDate = moment(onDate).endOf('day').toDate()
  }

  const [startDate, setStartDate] = useState<Date>(initialStartDate)
  const [endDate, setEndDate] = useState<Date>(initialEndDate)
  // Used to trigger an update
  const [update, setUpdate] = useState<boolean>(false)

  const fetchingParams = { start_date: startDate.toISOString(), end_date: endDate.toISOString() }
  const {
    data: schedules = [] as TrainingDTO[],
    reFetch: reFetchSchedules
  } = useFetchData('/api/v1/trainings', fetchingParams)

  const firstUpdate = useRef<boolean>(true)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    reFetchSchedules(fetchingParams)
  }, [startDate, endDate])

  const schedulesWithFixedDates: Training[] = useMemo(() => {
    return [...schedules.map(schedule => convertToTraining(schedule))]
  }, [schedules])

  const parsedSchedules = parseSchedules(schedulesWithFixedDates, selectedAthleteIds)

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <Card>
            <Header isTopHeader>{I18n.t('components.trainings.preview_schedule_title')}</Header>
            <div className='row'>
              <StartAndEndDate
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                sizeClass='s4'
              />
            </div>
            <ScheduledTrainingSessions
              schedules={parsedSchedules}
              onDelete={() => {}}
              updateTrainingProps={{ updateTraining: () => {}, refetch: () => reFetchSchedules(fetchingParams) }}
              toggleUpdate={() => setUpdate(!update)}
              readOnly
            />
          </Card>
        </div>
      </div>
    </>
  )
}

export default WithSession(TrainingSchedulesAthleteView) as typeof TrainingSchedulesAthleteView
