import I18n from 'i18n'
import { MyAxios as axios } from './MyAxios'
import moment from 'moment'
import { localeMapping, URL_LANGUAGE_PARAM, languages } from './common/Constants'
import queryString from 'query-string'
import _ from 'lodash'
import { Cookies } from 'react-cookie'
require('../../../public/javascripts/translations')

const getLocaleFromUrl = () => {
  const url = window.location.search
  const urlParams = queryString.parse(url)
  const lang = _.get(urlParams, URL_LANGUAGE_PARAM)
  lang && setLocaleInCookie(lang)
  return lang
}

const getLocaleFromCookie = () => {
  return new Cookies().get('preferredLocale')
}

const setLocaleInCookie = (locale) => {
  new Cookies().set('preferredLocale', locale)
}

const getBrowserLocale = () => {
  return window.navigator.userLanguage || window.navigator.language
}

const getDefaultLocale = () => {
  const locale = getLocaleFromUrl() || getLocaleFromCookie() || getBrowserLocale()
  // default NL for test environment, otherwise choose default
  return window.navigator.webdriver ? languages.NL : (locale in localeMapping && localeMapping[locale]) || languages.EN
}

/**
 * Default to NL locale if the requester is a headless browser.
 * This is required because for some reason Chromium does not want to change its preferred locale
 * A 'nl' locale is required for testing
 */
const DEFAULT_LOCALE = getDefaultLocale()

I18n.locale = DEFAULT_LOCALE
moment.locale(DEFAULT_LOCALE)

const loadLocaleAsync = () => {
  return new Promise((resolve, reject) => {
    const locales = axios.get('/api/v1/locales')
    locales.then((result) => {
      const locale = result.data.current
      if (locale !== null) {
        moment.locale(locale)
        I18n.locale = locale
      } else {
        moment.locale(DEFAULT_LOCALE)
        I18n.locale = DEFAULT_LOCALE
      }
      resolve(I18n)
    })
  })
}

const loadFromGivenLocale = givenLocale => {
  const locale = givenLocale
  if (locale !== null) {
    moment.locale(locale)
    I18n.locale = locale
  } else {
    moment.locale(DEFAULT_LOCALE)
    I18n.locale = DEFAULT_LOCALE
  }
}

export const I18nLoader = {
  load: () => {
    // returns a promise, but since load() doesnt return anything we can just wait
    // for it to be resolved in the background
    loadLocaleAsync()
  },
  loadAsync: () => loadLocaleAsync(),
  loadFromGiven: (givenLocale) => loadFromGivenLocale(givenLocale)
}
