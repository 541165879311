import React, { useEffect } from 'react'
import classNames from 'classnames'
import PremiumBadge from '../PremiumBadge'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
declare let M: any

interface Props {
  tabs: React.ReactNode // Tab components
  onShow: (id: string) => void
  tabRef: React.RefObject<HTMLUListElement>
}

const TabHeader: React.FC<Props> = ({ tabs, onShow, tabRef }) => {
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  // We need to reinitialize the tabs when the contents of a tab has changed, in case it has either introduced or
  // removed a scrollbar in the content. Because if this is the case, then the length of the indicator is off,
  // which we fix by reinitializing the tabs component. The reason for the 300ms delay is so that we don't interrupt
  // the normal movement of the indicator toward the new tab as a user clicks it (i.e., this way, the movement
  // of the indicator is still smooth, and we adjust its length as soon as it reaches its position (assuming a 300ms
  // duration)).
  useEffect(() => {
    const resetTabs = setTimeout(() => M.Tabs.init(tabRef.current, { swipeable: false, duration: 300, responsiveThreshold: Infinity }), 300)
    return () => {
      clearTimeout(resetTabs)
    }
  }, [tabs])

  return (
    <>
      {
        React.Children.toArray(tabs).map(tab => {
          if (typeof tab === 'string' || typeof tab === 'number' || !('props' in tab)) return null

          const { id, title, disabled = false, tooltip, showPremiumBadge = false, cy } = tab.props as {
            id: string
            title: string
            disabled: boolean | undefined
            tooltip: string | undefined
            showPremiumBadge: boolean | undefined
            cy: string | undefined
          }
          return (
            <li
              data-cy={cy ?? `${id}-link`}
              key={`${id}-header`}
              onClick={() => { if (!disabled) { onShow(id) } }}
              className={classNames('tab col s3', { disabled: disabled })}
            >
              {showPremiumBadge && <PremiumBadge isSuperPremium={false} />}
              <a href={`#${id}`}>
                {title}
                {tooltip !== undefined && tooltip && (
                  <FontAwesomeIcon
                    icon='info-circle'
                    data-tip={tooltip}
                    data-for='higher-z-tooltip'
                    className='information-icon'
                  />
                )}
              </a>
            </li>
          )
        })
      }
    </>
  )
}

export default TabHeader
