import React from 'react'
import DataSharingRoutes from './DataSharingRoutes'
import { WithSession } from '../session/SessionProvider'
import { WithBackend } from '../backend/BackendProvider'
import I18n from 'i18n'
import { capitalizeFirstLetter, smartTranslate } from '../common/Utils'
import moment from 'moment'
import { permissions, momentDateFormat } from '../common/Constants'
import { WithAppsignal } from '../appsignal/AppsignalProvider'
import WithProfileMetadata from 'components/helpers/WithProfileMetadata'

class DataSharing extends React.Component {
  constructor (props) {
    super(props)

    this.backend = props.backend
    this.appsignal = props.appsignal
  }

  async fetchSports () {
    const sports = await this.backend.sports.get()
    this.setState({ ...this.state, allSports: sports.data })
  }

  async fetchGroups () {
    const groups = await this.backend.groups.get()
    this.setState({ ...this.state, groups: groups.data })
  }

  async fetchCollectionRights () {
    const rights = await this.backend.collectionRights.get()
    this.setState({ ...this.state, rights: rights.data })
  }

  async fetchMySharing () {
    const sharing = await this.backend.profiles.getMySharing()
    this.setState({ ...this.state, isSharingDisabled: sharing.data.isSharingDisabled })
  }

  async fetchTags () {
    const tags = await this.backend.getTags()
    this.setState({ ...this.state, availableTags: tags.data.map(t => t.name) })
  }

  fetch () {
    Promise.all([
      this.fetchSports(),
      this.fetchGroups(),
      this.fetchCollectionRights(),
      this.fetchMySharing(),
      this.fetchTags()
    ])
      .catch((err) => {
        console.error(err)
        this.appsignal.sendError(err)
      })
  }

  handleSave (successCallback, errorCalback, rightId) {
    const sourceElement = document.getElementById('source')
    const tagElement = document.getElementById('tag')
    const shareByTag = document.getElementById('share-by-tag').checked

    const data = {
      collection_right: {
        access_level: document.getElementById('permission').value,
        collection_type: !shareByTag ? sourceElement.value : 'tag',
        tag: shareByTag ? tagElement.value : null,
        group_id: document.getElementById('who').value,
        start_date: moment(document.getElementById('start').value, momentDateFormat).toDate(),
        end_date: moment(document.getElementById('end').value, momentDateFormat).toDate()
      }
    }
    const start = data.collection_right.start_date
    const end = data.collection_right.end_date
    if (!isNaN(start.getDate()) && !isNaN(end.getDate()) && start > end) {
      errorCalback && errorCalback(I18n.t('datasharing.end_after_start'))
      return
    }

    // depending on whether rightId is set we either create a new or update an
    // existing collection right.
    let request

    if (rightId) {
      data.collection_right.id = rightId
      request = this.backend.collectionRights.update(rightId, data)
    } else {
      request = this.backend.collectionRights.create(data)
    }

    request.then(() => {
      successCallback && successCallback()
      this.fetch()
    }).catch((err) => {
      this.appsignal.sendError(err)
      const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
      errorCalback && errorCalback(errorTitles)
    })
  }

  handleDelete (idx) {
    this.backend.collectionRights.delete(idx)
      .then((response) => {
        this.fetch()
      })
      .catch((response) => {
        // ensure that the rights stay up to date?
        this.fetch()
        console.log(response)
        this.appsignal.sendError(response)
      })
  }

  handleToggleEnable (e) {
    this.backend.profiles.setMySharing({
      isSharingDisabled: this.state && !this.state.isSharingDisabled
    }).catch((err) => {
      console.log(err)
      this.appsignal.sendError(err)
    })
    this.setState({ ...this.state, isSharingDisabled: this.state && !this.state.isSharingDisabled })
  }

  render () {
    return (
      <DataSharingRoutes dataSharingProps={{
        ...this.state,
        permissions: permissions,
        fetch: this.fetch.bind(this),
        sessionToken: this.props.sessionToken,
        onSave: this.handleSave.bind(this),
        onDelete: this.handleDelete.bind(this),
        sports: this.props.myProfileMetadata.sports_from_data,
        isSharingDisabled: this.state && this.state.isSharingDisabled,
        onToggleEnable: this.handleToggleEnable.bind(this)
      }}
      />
    )
  }
}

export default WithAppsignal(WithBackend(WithSession(WithProfileMetadata(DataSharing))))
