import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { hcRed, hcGreen, hcOrange, hcBlack, MINIMUM_DAILY_DURATION_TO_SHOW_GAP } from '../../../../common/Constants'
import I18n from 'i18n'
import { initializeHighcharts, titleWithTooltip } from '../../../../common/Utils'
import GenericPlaceholder from '../../../../common/GenericPlaceholder'
import { imputateGapsWithUndefinedRecords } from '../Utils'

const MultiDailyWellnessChart = props => {
  if (!props.dailyLogRows || props.dailyLogRows.length === 0) {
    return (
      <GenericPlaceholder title={I18n.t('components.dashboards.questionnaire.daily_wellness.title')} message={props.noDataMessage} />
    )
  }

  const determineColor = wellness => {
    if (wellness <= 40) return hcRed
    if (wellness >= 80) return hcGreen
    if (wellness > 40 && wellness < 80) return hcOrange
    return hcBlack
  }

  const nanToNull = num => {
    return Number.isNaN(num) ? null : num
  }

  const allData = []
  for (const athleteSet of props.dailyLogRows) {
    const lineData = imputateGapsWithUndefinedRecords(athleteSet.data, MINIMUM_DAILY_DURATION_TO_SHOW_GAP, 1, 'wellness_moving_average').map(entry => {
      return {
        x: entry.date_only,
        y: entry.wellness_moving_average === undefined ? null : entry.wellness_moving_average,
        formatted_date: entry.formatted_date,
        wellness_sleep: nanToNull(entry.wellness_sleep),
        wellness_fatigue: nanToNull(entry.wellness_fatigue),
        wellness_stress: nanToNull(entry.wellness_stress),
        wellness_soreness: nanToNull(entry.wellness_soreness),
        wellness_mood: nanToNull(entry.wellness_mood),
        color: determineColor(entry.wellness_sum_p)
      }
    })
    allData.push({
      type: 'line',
      data: lineData,
      name: `${athleteSet.athlete.first_name} ${athleteSet.athlete.last_name}`,
      tooltip: {
        headerFormat: '',
        pointFormat: I18n.t('components.dashboards.questionnaire.daily_wellness.column.tooltip')
      }
    })
  }
  initializeHighcharts(true)
  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          title: titleWithTooltip(
            I18n.t('components.dashboards.questionnaire.daily_wellness.title'),
            I18n.t('components.dashboards.questionnaire.daily_wellness.info')
          ),
          subtitle: {
            text: I18n.t('components.dashboards.questionnaire.daily_wellness.subtitle')
          },
          series: allData,
          plotOptions: {
            line: {
              marker: {
                enabled: false
              }
            }
          },
          legend: {
            enabled: true
          },
          yAxis: {
            title: {
              text: I18n.t('components.dashboards.questionnaire.daily_wellness.yaxis')
            },
            min: 0
          },
          xAxis: {
            title: {
              text: ''
            },
            type: 'datetime',
            dateTimeLabelFormats: {
              day: '%d %b'
            }
          }
        }}
      />
    </>
  )
}

export default MultiDailyWellnessChart
