
export const dateWeekStartFormat: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short'
}

export const dateWeekEndFormat: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric'
}

export const formatDateRange = (start: Date, end: Date): string => {
  const locale = 'default'
  return `${start.toLocaleDateString(locale, dateWeekStartFormat)} - ${end.toLocaleDateString(locale, dateWeekEndFormat)}`
}
