import React from 'react'
import { WithBackend } from '../../backend/BackendProvider'
import { Line } from 'react-chartjs-2'
import PropTypes from 'prop-types'

const LineWithConfidenceGraph = (props) => {
  const createCISet = (name, values, hi, lo, color, bgColor, borderDash) => {
    const result = []
    const defaultOpts = {
      pointRadius: 0.0,
      backgroundColor: bgColor
    }
    result.push({
      label: `${name}_lo`,
      data: lo,
      borderWidth: 0.1,
      fill: false,
      ...defaultOpts
    })
    result.push({
      label: name,
      data: values,
      borderColor: color,
      fill: false,
      borderDash: borderDash,
      ...defaultOpts
    })
    result.push({
      label: `${name}_hi`,
      data: hi,
      borderWidth: 0.1,
      fill: '-2',
      ...defaultOpts
    })
    return result
  }

  if (!props.datasets) return <></>
  const datasets = props.datasets.map((ds) => createCISet(
    ds.label,
    ds.data,
    ds.hi,
    ds.lo,
    ds.borderColor,
    ds.backgroundColor,
    ds.borderDash
  )).flat(1) // This .flat is needed as we want 1 array with all datasets.

  const dataIn = {
    labels: props.labels,
    datasets: datasets
  }

  const options = {
    responsive: true,
    title: {
      display: true
    },
    scales: {
      xAxes: [{
        ticks: {
          autoSkip: true,
          maxTicksLimit: 6,
          maxRotation: 0,
          minRotation: 0
        },
        gridLines: {
          display: true,
          drawOnChartArea: false
        }
      }],
      yAxes: [{
        gridLines: {
          display: true,
          drawOnChartArea: false
        }
      }]
    },
    legend: {
      display: true,
      // this toggles on / off the confidence intervals
      labels: {
        filter: function (item, _chart) {
          return !item.text.includes('_')
        }
      },
      // This is needed in order to toggle everything based on the legend
      onClick: function (_e, legendItem) { // need to hide index -1 and index +1
        const index = legendItem.datasetIndex
        const ci = this.chart
        const alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden

        const metaLo = ci.getDatasetMeta(index - 1)
        const meta = ci.getDatasetMeta(index)
        const metaHi = ci.getDatasetMeta(index + 1)

        const assignableVar = alreadyHidden ? null : true
        metaLo.hidden = assignableVar
        meta.hidden = assignableVar
        metaHi.hidden = assignableVar

        ci.update()
      },
      position: 'top'
    }
  }

  return (
    <Line data={dataIn} options={options} />
  )
}

LineWithConfidenceGraph.propTypes = {
  datasets: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    hi: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    lo: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    borderColor: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    borderDash: PropTypes.arrayOf(PropTypes.number)
  }))
}

export default WithBackend(LineWithConfidenceGraph)
