import React from 'react'
import I18n from 'i18n'
import { WithModal } from '../../modal/ModalProvider'
import { WithSession } from '../../session/SessionProvider'
import Connection from './Connection'
import Spinner from '../../common/Spinner'

class SentConnectionRequestList extends React.Component {
  constructor () {
    super()
    this.state = { }
  }

  render () {
    const { connections } = this.props
    return (
      <>
        <div className='col s12 text-heavy muted-header'>
          <div className='row'>
            <div className='col s12'>
              <h5>{I18n.t('connections.sent_requests.title', { count: connections.length })}</h5>
            </div>
          </div>
        </div>
        <div className='col s12'>
          <div className='row'>
            <Spinner ready={this.props.dataRetrieved} transparent />
            {connections.map(connection => (
              <Connection
                onDeleteConnection={this.props.onDeleteConnection}
                key={connection.id}
                connection={connection}
                onLeave={() => {}}
              />
            ))}
          </div>
        </div>
      </>
    )
  }
}

export default WithSession(WithModal(SentConnectionRequestList))
