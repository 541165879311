import React, { useState } from 'react'
import I18n from 'i18n'

import { Field } from '../../common/form'
import { WithBackend } from '../../backend/BackendProvider'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import { WithSession } from '../../session/SessionProvider'
import { subscriptionTypes } from '../../common/Constants'
import _ from 'lodash'

const RedeemVoucher = (props) => {
  const [voucher, setVoucher] = useState('')

  const voucherRedeemed = (response) => {
    props.flashMessages.push(
      I18n.t('redeem.flashmessages.voucher_redeemed'),
      props.flashMessages.duration.SHORT,
      props.flashMessages.levels.INFO
    )
    setVoucher('')

    setTimeout(() => { props.refetchSession() }, 3000)
  }

  const voucherInvalid = (err) => {
    props.flashMessages.push(
      err.response.data.errors[0].detail,
      props.flashMessages.duration.SHORT,
      props.flashMessages.levels.ERROR
    )
  }

  const handleClick = async (e) => {
    e.preventDefault()
    // There are 2 kinds of vouchers, 1 time use and generic vouchers. The code of generic vouchers can be any arbitrary
    // alphanumeric string chosen by the admins. The 1 time use vouchers have an autogenerated code
    // composed of 12 alphanumeric characters. In the admin frontend, we format this code by inserting a ' - ' every
    // 4 characters, presumably to make it easier to share it / copy it manually. However that string is not a valid
    // voucher code, so we filter that out here before we send the request.
    await props.backend.voucher
      .redeem({ code: voucher.replaceAll(/([^a-zA-Z0-9])/g, '') })
      .then(voucherRedeemed, voucherInvalid)
  }

  const type = _.get(props, 'myProfile.subscription.type', subscriptionTypes.free)

  return (
    <div className='general-container redeem-container'>
      <div className='redeem-voucher-title text-heavy text-secondary-color text-xl'>
        {I18n.t('redeem.voucher.title')}
      </div>
      <div className='redeem-voucher-title'>
        <p>
          {(type === subscriptionTypes.free && (
            I18n.t('redeem.voucher.redeem_free')
          )) || (
            I18n.t('redeem.voucher.redeem_subscribed')
          )}
        </p>
      </div>
      <form className='redeem-voucher-form'>
        <div className='row'>
          <div className='col s9 voucher-field'>
            <Field
              id='value'
              formName='redeem'
              component='voucher'
              fieldName='value'
              type='text'
              currentValue={voucher}
              onChange={e => setVoucher(e.target.value)}
            />
          </div>
          <div className='col s3'>
            <button
              className='background-accent button-accent text-background-color waves-effect waves-light'
              onClick={handleClick}
            >
              {I18n.t('redeem.voucher.submit')}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default WithSession(WithBackend(WithFlashMessages(RedeemVoucher)))
