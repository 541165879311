import React from 'react'
import I18n from 'i18n'
import PropTypes from 'prop-types'
import UserProfileTableEntry from '../../common/profile_representations/UserProfileTableEntry'

const AvailableMembers = (props) => (
  <>
    <div className='row'>
      <div className='available-members col s12'>
        {
          props.members.map(member => (
            <UserProfileTableEntry profile={member} key={member.id} singleLine />
          ))
        }
        {props.members.length === 0 &&
          <p className='text-muted'>{I18n.t('group.detail.questionnaires.no_available_members')}</p>}
      </div>
    </div>
  </>
)

AvailableMembers.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({
    first_name: PropTypes.string,
    id: PropTypes.number.isRequired,
    last_name: PropTypes.string,
    picture: PropTypes.string,
    public_url: PropTypes.string
  })).isRequired
}

export default AvailableMembers
