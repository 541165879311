import React, { useState } from 'react'
import I18n from 'i18n'

const PremiumActions = (props) => {
  const [collapsed, setCollapsed] = useState(true)

  return (
    <>
      <div className='col s12' onClick={() => setCollapsed(!collapsed)}>
        <div className='collapsible-menu'>
          Actions:
        </div>
      </div>
      <div className='col s12'>
        <div className={`collapsible-content ${!collapsed ? 'active' : ''}`}>
          <div className='row'>
            <div className='col s12 l4'>
              <button onClick={() => { props.applyAction('cancel') }} className='button-primary background-primary text-background-color waves-effect waves-light'>{I18n.t('premium-actions.cancel')}</button>
            </div>
            <div className='col s12 l4'>
              <button onClick={() => props.applyAction('infinite-research')} className='button-primary background-primary text-background-color waves-effect waves-light'>{I18n.t('premium-actions.researcher')}</button>
            </div>
            <div className='col s12 l4'>
              <button onClick={() => props.applyAction('infinite-coach')} className='button-primary background-primary text-background-color waves-effect waves-light'>{I18n.t('premium-actions.coach')}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PremiumActions
