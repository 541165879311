import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'i18n'
import { Link } from 'react-router-dom'

const QuestionnairesButton = (props) => {
  return (
    <div className='col s6 m12 contains-button-badge'>
      <Link
        className='waves-effect text-m waves-light button-primary text-primary-color background-background text-medium button welcome-container-button questionnaires-button'
        to='/static/questionnaires'
      >
        {I18n.t('components.welcome.questionnaires')}
      </Link>
      {props.hasOpenResponses && <span className='button-badge' />}
    </div>
  )
}

QuestionnairesButton.propTypes = {
  hasOpenResponses: PropTypes.bool
}

QuestionnairesButton.defaultProps = {
  hasOpenResponses: false
}

export default QuestionnairesButton
