import React, { useState, useEffect } from 'react'
import I18n from 'i18n'
import { debounce } from 'lodash'
import { WithSession } from '../../../session/SessionProvider'
import Spinner from '../../../common/Spinner'
import Info from '../../../atomic/atoms/Info'
import AutocompleteProfileDTO from '../../../common/types/DTOs/AutocompleteProfileDTO'
import classNames from 'classnames'
import { SharingResult, SharingResultProps } from './OnboardingSharingSearchResult'
import { GroupResultItem, GroupResultProps } from './GroupResult'
import { ResultProps } from './ProfileGroupResult'
import { useFlashMessages } from '../../../common/Hooks'

interface Props {
  minSearchLength?: number
  inputRef?: React.RefObject<HTMLInputElement>
  fieldId: string
  onSearch: (event: any, refetch?: any) => Promise<(SharingResult[] | GroupResultItem[] | AutocompleteProfileDTO[])>
  infoBox?: string | (() => string)
  Component: React.FC<SharingResultProps> | React.FC<GroupResultProps> | React.FC<ResultProps>
  label: string
  labelClass?: string
  textColorClass?: string
  onClick: (event: any) => void
  buttonText?: string
}

const Search: React.FC<Props> = ({ minSearchLength = 2, inputRef, fieldId, onSearch, infoBox, Component, label, labelClass, textColorClass, onClick, buttonText = I18n.t('network.profile.label.invite') }) => {
  const [results, setResults] = useState<undefined | Array<SharingResult | GroupResultItem | AutocompleteProfileDTO>>(undefined)
  const [hidden, setHidden] = useState<boolean>(true)
  const flashMessages = useFlashMessages()

  useEffect(() => {
    return () => {
      handleSearch.cancel()
    }
  }, [])

  const handleSearchUnbounced = (e: any): void => {
    // We're using this notation instead of e?.target?.value?.length < 2
    // because nil < any number (or nil > any number for that matter) will always return false.
    // While here we want it to return true in that case
    if (e === null || e === undefined || e.target === null || e.target === undefined || e.target.value.length < 2) {
      void onSearch({ target: { value: '' } })
    } else {
      setResults(undefined)
      onSearch(e, handleSearch(e)).then((result) => {
        setResults(result)
        setHidden(false)
        handleSearch.cancel()
      }).catch((err) => {
        console.log(err)
        flashMessages.push(
          I18n.t('flashmessages.search.error'),
          flashMessages.duration.LONG,
          flashMessages.levels.ERROR)
        handleSearch.cancel()
      })
    }
  }

  const handleSearch = debounce(handleSearchUnbounced, 500, { leading: true })

  const handleFieldClick = (e: React.MouseEvent<HTMLInputElement>): void => {
    e.preventDefault()
    if (minSearchLength === 0) {
      const val = (document.getElementById(fieldId) as HTMLInputElement)?.value
      const e = { target: { value: val } }

      onSearch(e, handleSearch(e)).then((result) => {
        setResults(result)
        setHidden(false)
      }).catch((err) => {
        console.log(err)
        flashMessages.push(
          I18n.t('flashmessages.search.error'),
          flashMessages.duration.LONG,
          flashMessages.levels.ERROR)
      })
    }
  }

  const handleClearField = (): void => {
    handleSearch.cancel()
    void onSearch({ target: { value: '' } })
    const el = document.getElementById(fieldId) as HTMLInputElement
    (el.nextSibling as HTMLLabelElement)?.classList?.remove('active')
    el.value = ''
    setHidden(true)
  }

  let infoBoxText = infoBox ?? ''
  if (typeof infoBoxText === 'function') {
    infoBoxText = infoBoxText()
  }

  return (
    <div className='search-wrapper text-white'>
      <div className='input-field'>
        <input
          ref={inputRef}
          name='search'
          className={classNames('primary-border-bottom', textColorClass)}
          id={fieldId}
          type='text'
          defaultValue=''
          autoComplete='off'
          onChange={(e) => { e.persist(); handleSearch(e) }}
          onClick={(e) => { handleFieldClick(e) }}
        />
        <label htmlFor={fieldId} className={labelClass}>
          {label}
        </label>
        <span className='field-postfix text-primary-color'>
          <i className='material-icons pointer-grow' onClick={(e) => { e.target = document.getElementById('search') as EventTarget; handleSearch(e) }}>search</i>
          <i className='material-icons pointer-grow' onClick={handleClearField}>cancel</i>
          {infoBox !== undefined && infoBox !== '' && <Info inline text={infoBoxText} tooltipId='new-connection-search-tooltip' />}
        </span>
      </div>
      <div className='search-results-wrapper' onBlur={handleClearField}>
        <div className='search-results-container'>
          <ul className={classNames('collection search-results', { hidden })}>
            {results?.map((result: (SharingResult | GroupResultItem | AutocompleteProfileDTO)) => (<li key={`result-${result.id}`} className='collection-item search-result-item'><Component buttonText={buttonText} onClick={() => { onClick(result); handleClearField() }} hide={hidden} item={result as any /* We assume the API call matches the Component's properties for rendering it */} /></li>))}
            {results !== undefined && results.length === 0 && (<li className='collection-item search-result-item'>{I18n.t('network.search.no_results')}</li>)}
            {results === undefined && <Spinner ready={false} />}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default WithSession(Search) as typeof Search
