import React, { useEffect } from 'react'
import I18n from 'i18n'
import Toggle from 'components/atomic/atoms/Toggle'
import { WithQuestionnaires } from '../../questionnaires/QuestionnaireProvider'
import { WithSession } from 'components/session/SessionProvider'
import TextField from '../../atomic/atoms/TextField'
import PropTypes from 'prop-types'

const EditQuestionnaireProfileDetailsForm = ({ questionnaires, errors, myProfile, enableOnLoad = false }) => {
  useEffect(() => {
    M.updateTextFields()

    // If the user does not have questionnaires enabled, enable them here.
    if (enableOnLoad && !questionnaires.pendingProfile.account_active) {
      handleOnChange('account_active', true)
    }
  }, [questionnaires.pendingProfile.email, questionnaires.pendingProfile.mobile_phone])

  const handleOnChange = (field, value) => {
    questionnaires.setPendingProfile({ [field]: value })
  }

  const handleToggleChange = (field) => {
    questionnaires.setPendingProfile({ [field]: !questionnaires.pendingProfile[field] })
  }

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <div className='text-heavy text-l text-muted questionnaire-header'>
            {I18n.t('profile.questionnaires.title')}
          </div>
        </div>
      </div>
      <div className='questionnaires-toggle-overview'>
        <div className='row'>
          <div className='col s12'>
            <form className='profile-questionnaire-form'>
              <div className='form-field'>
                <Toggle
                  id='account_active'
                  title={I18n.t('questionnaires_edit.toggle.checkbox.account_active')}
                  isChecked={questionnaires.pendingProfile.account_active}
                  onChange={(e) => handleToggleChange('account_active')}
                  errors={errors?.account_active}
                />
              </div>
              {questionnaires.pendingProfile.account_active &&
                <>
                  <div className='form-field'>
                    <TextField
                      name='email'
                      label={I18n.t('questionnaires_edit.field.email.email')}
                      type='email'
                      onChange={(e) => handleOnChange('email', e.currentTarget.value)}
                      value={questionnaires.pendingProfile.email ?? myProfile?.email ?? ''}
                      errors={errors?.email}
                    />
                  </div>
                  <div className='form-field'>
                    <TextField
                      name='mobile_phone'
                      label={I18n.t('questionnaires_edit.field.tel.mobile_phone')}
                      type='tel'
                      onChange={(e) => handleOnChange('mobile_phone', e.currentTarget.value)}
                      value={questionnaires.pendingProfile.mobile_phone ?? ''}
                      errors={errors?.mobile_phone}
                    />
                  </div>
                </>}
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

EditQuestionnaireProfileDetailsForm.propTypes = {
  questionnaires: PropTypes.object,
  errors: PropTypes.object,
  myProfile: PropTypes.object,
  // Whether the form should toggle the questionnaires if included
  enableOnLoad: PropTypes.bool
}

export default WithSession(WithQuestionnaires(EditQuestionnaireProfileDetailsForm))
