import { TextInput } from 'components/atomic/atoms/inputs'
import I18n from 'i18n'
import React, { useState, useEffect } from 'react'
import ProtocolSelectionDropdown, { ProtocolSelectionDropdownProps } from '../../molecules/ProtocolSelectionDropdown'
import InvitationTextConfiguration, { InvitationTextConfigurationProps } from '../../organisms/InvitationTextConfiguration'

interface DurationManagementProps {
  handleDurationChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  durationInDays: number
  disableDuration: () => boolean
}

interface GroupProtocolConfigurationProps {
  durationManagementProps: DurationManagementProps
  protocolSelectionDropdownProps: ProtocolSelectionDropdownProps
  invitationTextConfigurationProps: InvitationTextConfigurationProps
}

const GroupProtocolConfiguration: React.FC<GroupProtocolConfigurationProps> = ({ durationManagementProps, protocolSelectionDropdownProps, invitationTextConfigurationProps }): React.ReactElement => {
  const [disabledDuration, setDisabledDuration] = useState(false)
  useEffect(() => {
    setDisabledDuration(durationManagementProps.disableDuration())
  })

  return (
    <>
      <div className='row'>
        <div className='col s12 l6'>
          <ProtocolSelectionDropdown {...protocolSelectionDropdownProps} />
        </div>
        <div className='col s12 l6'>
          <TextInput
            onChange={durationManagementProps.handleDurationChange}
            value={durationManagementProps.durationInDays}
            label={I18n.t('group.detail.questionnaires.group_protocol_duration')}
            type='number'
            disabled={disabledDuration}
            required
          />
        </div>

        <div className='col s12'>
          <InvitationTextConfiguration {...invitationTextConfigurationProps} />
        </div>
      </div>
    </>
  )
}

GroupProtocolConfiguration.defaultProps = {
}

export default GroupProtocolConfiguration
