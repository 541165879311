import React, { useState } from 'react'
import { useQuestionnaireData } from '../Hooks'

// This component was created with TrainingOrWellbeingQuestionnaireDashboard in mind, and is meant to allow using
// react hooks from class components
const WithQuestionnaireData = (Component) => {
  return (props) => {
    const [params, setParams] = useState(undefined)
    const [profilesOptions, setProfilesOptions] = useState(undefined)
    const [groupsOptions, setGroupsOptions] = useState(undefined)
    const [questionnairesOptions, setQuestionnairesOptions] = useState(undefined)
    const [protocolSubscriptionsOptions, setProtocolsSubscriptionsOptions] = useState(undefined)

    const setProfileIdParam = (profileId) => {
      setParams({ ...params, profile_id: profileId })
    }

    const setTimeParams = (minTime, maxTime) => {
      setParams(
        {
          ...params,
          start_date: minTime?.toISOString(),
          end_date: maxTime?.toISOString()
        }
      )
    }

    const {
      questionnaires: { data: questionnaireData, isLoading, ...questionnaireMetadata },
      profiles: { data: profiles }
    } = useQuestionnaireData({
      params,
      groupsOptions,
      profilesOptions,
      questionnairesOptions,
      protocolSubscriptionsOptions
    })

    const hocProps = {
      setParams,
      setProfileIdParam,
      setTimeParams,
      setGroupsOptions,
      setProfilesOptions,
      questionnaireData,
      questionnaireMetadata,
      profiles,
      setQuestionnairesOptions,
      setProtocolsSubscriptionsOptions,
      isLoading
    }
    return (
      <Component {...props} {...hocProps} />
    )
  }
}

export default WithQuestionnaireData
