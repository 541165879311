import React from 'react'
import _, { isEmpty } from 'lodash'
import SummaryTable from '../../../analysis/dashboards/common/SummaryTable'
import I18n from 'i18n'
import { formatDateTime } from 'components/common/Utils'

interface PolarData {
  id: number
  'transaction-id': number
  date: string
  created: string
  calories: number
  'active-calories': number
  duration: string
  'active-steps': number
  'sleep_start_time': string
  'sleep_end_time': string
  'device_id': string
  continuity: number
  'continuity_class': number
  'light_sleep': number
  'deep_sleep': number
  'rem_sleep': number
  'unrecognized_sleep_stage': number
  'sleep_score': number
  'total_interruption_duration': number
  'sleep_charge': number
  'sleep_rating': number
  'sleep_goal': number
  'short_interruption_duration': number
  'long_interruption_duration': number
  'sleep_cycles': number
  'group_duration_score': number
  'group_solidity_score': number
  'group_regeneration_score': number
  'heart_rate_avg': number
  'beat_to_beat_avg': number
  'heart_rate_variability_avg': number
  'breathing_rate_avg': number
}

type PolarKeys = keyof PolarData

interface PolarDetailViewProps {
  data: {
    dailyActivitySummary: PolarData[]
  }
}

const PolarDetailView: React.FC<PolarDetailViewProps> = ({ data }) => {
  const summary = _.get(data, 'data_rows[0]')
  if (_.isEmpty(summary)) {
    return <p>Summary data not available</p>
  }

  const omittedKeys: PolarKeys[] = [
    'id',
    'transaction-id',
    'created',
    'date',
    'device_id'
  ]

  const sections: Record<string, PolarKeys[]> = {
    activity: [
      'active-steps',
      'duration',
      'group_duration_score',
      'group_solidity_score',
      'group_regeneration_score'
    ],
    energy: [
      'calories',
      'active-calories'
    ],
    sleep: [
      'sleep_start_time',
      'sleep_end_time',
      'continuity',
      'continuity_class',
      'light_sleep',
      'deep_sleep',
      'rem_sleep',
      'unrecognized_sleep_stage',
      'sleep_score',
      'total_interruption_duration',
      'sleep_charge',
      'sleep_rating',
      'sleep_goal',
      'short_interruption_duration',
      'long_interruption_duration',
      'sleep_cycles'
    ],
    heart_rate: [
      'heart_rate_avg',
      'beat_to_beat_avg',
      'heart_rate_variability_avg',
      'breathing_rate_avg'
    ]
  }

  const formatDuration = (duration: string): string => {
    let result = duration.toUpperCase()

    result = result.replace('PT', '')
    result = result.replace('H', ' hours ')
    result = result.replace('M', ' minutes ')
    result = result.replace('S', ' seconds')

    return result
  }

  const isIncludedInOmittedKeys = (key: PolarKeys): boolean => {
    return omittedKeys.includes(key)
  }

  interface ProcessedField { name: string, value: string | number | number[], key: string, units: string }
  const processKey = (key: PolarKeys, value: PolarData[PolarKeys]): ProcessedField[] => {
    const formattedValue: string | number =
      key === 'sleep_start_time' || key === 'sleep_end_time'
        ? formatDateTime(value as string)
        : key === 'duration'
          ? formatDuration(value as string)
          : value

    return [{
      name: I18n.t(`components.dashboards.polar.${key}`),
      value: formattedValue,
      key,
      units: ''
    }]
  }

  const sectionFields = Object.entries(sections).map(([, keys]) =>
    keys.flatMap((key: PolarKeys) => {
      if (isEmpty(summary) || isIncludedInOmittedKeys(key) || !(key in summary)) return []

      return processKey(key, summary[key])
    })
  )

  return (
    <div>
      {sectionFields.map((fields, index) => (
        <div key={Object.keys(sections)[index]}>
          <div className='text-heavy text-l text-muted data-header'>
            {I18n.t(`components.dashboards.sections.${Object.keys(sections)[index]}`)}
          </div>
          <SummaryTable fields={fields} />
        </div>
      ))}
    </div>
  )
}

export default PolarDetailView
