import React from 'react'
import { Pie } from 'react-chartjs-2'
import I18n from 'i18n'
import Info from '../../../../atomic/atoms/Info'
import { calcErrorBar, calcAverage } from '../ActivityUtils'
import { pad } from '../../../../common/Utils'
import { chartjsActiveClockColor, chartjsInactiveClockColor } from '../../../../common/Constants'

const SleepDiagram = props => {
  const percentualTime = time => {
    const moduloTime = parseInt(time) % (12 * 3600)
    return 100 * moduloTime / (12.0 * 3600)
  }
  const lowerBar = calcErrorBar(0, props.datasets[props.parameter], 1000)
  const upperBar = calcErrorBar(1, props.datasets[props.parameter], 1000)
  const hms = time => {
    time = time % 86400
    const hours = Math.floor(time / 3600)
    const minutes = pad(Math.floor((time % 3600) / 60), 2)
    return `${hours}:${minutes}`
  }
  const averageTime = calcAverage(props.datasets[props.parameter], 1000)
  const lowerPercent = percentualTime(lowerBar)
  const upperPercent = percentualTime(upperBar)
  let lowValue = lowerPercent
  let midValue = upperPercent - lowerPercent
  let topValue = 100 - upperPercent
  let backGroundColors = [chartjsInactiveClockColor, chartjsActiveClockColor, chartjsInactiveClockColor]
  if (lowerPercent > upperPercent) {
    lowValue = upperPercent
    midValue = lowerPercent - upperPercent
    topValue = 100 - lowerPercent
  }
  if (lowerBar % 86400 > upperBar % 86400) {
    backGroundColors = [chartjsActiveClockColor, chartjsInactiveClockColor, chartjsActiveClockColor]
  }
  const data = {
    datasets: [{
      label: props.label,
      data: [lowValue, midValue, topValue],
      borderWidth: 0,
      hoverBorderWidth: 0,
      backgroundColor: backGroundColors
    }]
  }
  const options = {
    tooltips: {
      enabled: false
    },
    legend: {
      display: false
    }
  }
  const notEnoughData = isNaN(lowerBar) || lowerBar === 0.0
  return (
    <div className='row'>
      <div className='col s12 clock-ticks'>
        <div className='clock-title'><span className='summary-subtitle'>{props.title}</span><Info text={I18n.t('components.dashboards.steps.summary.std_explanation')} tooltipId={`std-explanation-${props.parameter}`} /></div>
        {notEnoughData && <p className='center-align'><em className='insufficient-data center-align'>{I18n.t('components.dashboards.steps.summary.not_enough_data')}</em></p>}
        {!notEnoughData && <Pie height={230} data={data} options={options} redraw />}
        {averageTime !== 0.0 && <p className='center-align'>{I18n.t('components.dashboards.steps.summary.average')}: <span className='summary-subtitle'>{hms(averageTime)}</span></p>}
      </div>
    </div>
  )
}

export default SleepDiagram
