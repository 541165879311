import React, { ChangeEvent, useEffect, useState } from 'react'
import I18n from 'i18n'
import Spinner from '../../../../common/Spinner'
import { average, sum, round } from 'components/common/Math'
import {
  hcDayPartColor,
  hcTypeColor,
  hcSessionColor,
  DAYS_IN_A_WEEK, trainingLog, dailyQuestionnaires, hcLightGrey, hcBrightRed, hcGreen, hcOrange
} from 'components/common/Constants'
import { smartTranslate } from 'components/common/Utils'
import TrainingSessionsChart from '../full/TrainingSessionsChart'
import WeeklyWellnessChart from './WeeklyWellnessChart'
import moment, { Moment } from 'moment'
import MyReactTooltip from '../../../../MyReactTooltip'
import ComplianceChart from '../common/ComplianceChart'
import GenericPlaceholder from '../../../../common/GenericPlaceholder'
import {
  makeHistogram,
  shortSessionName
} from '../Utils'
import { preprocessTrainingLogs, preprocessWellbeingQuestionnaires } from '../UtilsTS'
import {
  initFormSelect,
  readOwnersProfiles,
  mapProfiles,
  MappedProfile
} from './WellbeingUtils'
import { getQuestionnaireWeekInfo, sortById } from './WeeklyQuestionnaireUtils'
import {
  isDailyQuestionnaire,
  isTrainingLog,
  getFirstDataRowObject,
  getOpenFromDate
} from 'components/common/utils/QuestionnaireUtilsTS'
import InfoRequiredData, { DASHBOARD_REQUIRED_DATA_TYPES } from '../../common/RequiredDataInfo'
import DailyReadinessToTrainChart from '../full/DailyReadinessToTrainChart'
import SelectDropdown from 'components/common/SelectDropdown'
import { Owner } from 'components/common/types/questionnaires/Owner'
import { QuestionnaireObject } from 'components/common/types/questionnaires/QuestionnaireObject'
import { TrainingLogRow } from 'components/common/types/questionnaires/TrainingLogRow'
import { DailyLogRow } from 'components/common/types/questionnaires/DailyLogRow'
import {
  WeekInfo, WeekInfoDict
} from 'components/common/types/questionnaires/WeekInfo'
import { TrainingType } from 'components/common/types/TrainingType'
import { SessionType } from 'components/common/types/SessionType'
import { Histogram } from 'components/common/types/questionnaires/Histogram'
import { SundaySummary } from 'components/common/types/questionnaires/SundaySummary'
import { useQuestionnaireData } from 'components/analysis/dashboards/questionnaire/Hooks'
import _, { isEmpty } from 'lodash'

const WeeklyQuestionnaireDashboard: React.FC = () => {
  const [preprocessingDone, setPreprocessingDone] = useState<boolean>(true)
  const [dailyPreprocessingDone, setDailyPreprocessingDone] = useState<boolean>(true)
  const [selectedWeek, setSelectedWeek] = useState<string | undefined>(undefined)
  const [profiles, setProfiles] = useState<Owner[] | undefined>(undefined)
  const [questionnaireWeekInfo, setQuestionnaireWeekInfo] = useState<WeekInfoDict>({})
  const [questionnaireWeeks, setQuestionnaireWeeks] = useState<WeekInfo[]>([])
  const [filteredQuestionnaires, setFilteredQuestionnaires] = useState<QuestionnaireObject[]>([])
  const [fetchedDone, setFetchedDone] = useState<boolean>(false)
  const [selectedProfile, setSelectedProfile] = useState<number | undefined>(undefined)
  const [sessionTypeHistogram, setSessionTypeHistogram] = useState<Histogram | undefined>(undefined)
  const [trainingTypeHistogram, setTrainingTypeHistogram] = useState<Histogram | undefined>(undefined)
  const [trainingDaypartHistogram, setTrainingDaypartHistogram] = useState<Histogram | undefined>(undefined)
  const [trainingLogRows, setTrainingLogRows] = useState<TrainingLogRow[] | undefined>(undefined)
  const [dailyLogRows, setDailyLogRows] = useState<DailyLogRow[] | undefined>(undefined)
  const [sundaySummaryRows, setSundaySummaryRows] = useState<SundaySummary[] | undefined>(undefined)
  const [weeklyFeedback, setWeeklyFeedback] = useState<string>('N/A')
  const [mappedProfiles, setMappedProfiles] = useState<MappedProfile[]>([])

  const SELECT_PROFILE_ID = 'weekly-wellbeing-profile-select'
  const SELECT_WEEK_ID = 'weekly-wellbeing-week-select'
  const PAST_WEEK_OPTION_LABEL = 'past_week'

  const queryParams = {
    start_date: moment().startOf('day').subtract(2, 'months').toISOString(),
    end_date: moment().endOf('day').toISOString(),
    type: [trainingLog, ...dailyQuestionnaires]
  }

  const {
    questionnaires: { data: fetchedQuestionnaires, isSuccess: questionnairesAreFetched, isLoading }
  } = useQuestionnaireData({
    params: queryParams,
    profilesOptions: { enabled: false },
    groupsOptions: { enabled: false },
    questionnairesOptions: { enabled: true },
    protocolSubscriptionsOptions: { enabled: false }
  })

  useEffect(() => {
    if (fetchedQuestionnaires.length > 0) {
      extractAndSetQuestionnaireWeekInfo()
    }
  }, [fetchedQuestionnaires])

  // when profiles loaded, init/fill control and auto-select first profile
  useEffect(() => {
    if (profiles != null && profiles.length > 0) {
      initProfileSelect()
      const mappedProfiles = mapProfiles(profiles)
      setMappedProfiles(mappedProfiles)

      if (selectedProfile == null) { // if no selected profile detected, default to first profile in list
        selectProfile(profiles[0].id)
      } else if (profiles.some(profile => profile.id === selectedProfile)) { // if selectedProfile in profiles, keep it
        selectProfile(selectedProfile)
      }
    } else {
      setSelectedProfile(undefined)
    }
  }, [profiles])

  useEffect(() => {
    // set profiles to undefined to prevent the state
    // containing a profile that is not present in new filteredQuestionnaires
    setSelectedProfile(undefined)
    setProfiles(undefined)

    setProfiles(readOwnersProfiles(filteredQuestionnaires))
  }, [filteredQuestionnaires])

  useEffect(() => {
    setFetchedDone(questionnairesAreFetched)
  }, [questionnairesAreFetched])

  useEffect(() => {
    if (questionnaireWeeks.length > 0) {
      initWeekSelect()
    }
  }, [questionnaireWeeks])

  useEffect(() => {
    if (!_.isEmpty(questionnaireWeekInfo)) { // if questionnaireWeekInfo is not an empty object
      filterForSelectedWeek()
    }
  }, [questionnaireWeekInfo])

  useEffect(() => {
    if (selectedProfile !== null) {
      setPreprocessingDone(false)
      setDailyPreprocessingDone(false)
      setTrainingAndSessionTypes()
      filterAndProcessDailyQuestionnaires()
    }
  }, [selectedProfile, filteredQuestionnaires])

  useEffect(() => {
    if (!_.isEmpty(selectedWeek?.trim())) {
      filterForSelectedWeek()
    }
  }, [selectedWeek])

  const initProfileSelect = (): void => {
    initFormSelect(SELECT_PROFILE_ID)
    initFormSelect(SELECT_WEEK_ID)
  }

  const initWeekSelect = (): void => {
    initFormSelect(SELECT_WEEK_ID)
  }

  const selectProfile = (selected: number): void => {
    setSelectedProfile(selected)
  }

  const sortWeeks = (weekInfoData: WeekInfoDict): WeekInfo[] => {
    return Object.keys(weekInfoData)
      .sort((a, b) => {
        return b.localeCompare(a)
      })
      .map(key => weekInfoData[key])
  }

  // Function to extract and set the week information from questionnaires
  const extractAndSetQuestionnaireWeekInfo = (): void => {
    // Retrieve week info data from the questionnaires
    const weekInfoData: WeekInfoDict = getQuestionnaireWeekInfo(fetchedQuestionnaires)

    // Sort the weeks in descending order
    const weeksData = sortWeeks(weekInfoData)

    // Set the state variables with the extracted and sorted week information
    setQuestionnaireWeekInfo(weekInfoData) // Save the raw week information
    setQuestionnaireWeeks(weeksData) // Save the sorted week information
  }

  const getLatestWeekWithData = (): string | undefined => {
    let latestWeek: string | undefined
    Object.keys(questionnaireWeekInfo).forEach(weekKey => {
      if (latestWeek == null || weekKey > latestWeek) {
        latestWeek = weekKey
      }
    })
    return latestWeek
  }

  const getWeekInfoByKey = (key: string | undefined): WeekInfo | undefined => {
    if (key !== undefined) {
      return questionnaireWeekInfo[key]
    }
    return undefined
  }

  const filterQuestionnairesByWeek = (start: Moment, end: Moment): QuestionnaireObject[] => {
    return fetchedQuestionnaires.filter((questionnaire: QuestionnaireObject) => {
      const openFromMoment = moment(getOpenFromDate(questionnaire))
      return openFromMoment.isSameOrAfter(start, 'week') && openFromMoment.isSameOrBefore(end, 'week')
    })
  }

  const filterForSelectedWeek = (): void => {
    if (selectedWeek === PAST_WEEK_OPTION_LABEL) {
      const weekAgo = moment().subtract(7, 'days').startOf('day')
      setFilteredQuestionnaires(fetchedQuestionnaires.filter((questionnaire: QuestionnaireObject) => {
        const openFromMoment = moment(getOpenFromDate(questionnaire))
        return openFromMoment.isAfter(weekAgo)
      }))
    } else {
      // If week was not selected by user, default to latest week with data
      const weekKey: string | undefined = selectedWeek ?? getLatestWeekWithData()
      const weekInfo: WeekInfo | undefined = getWeekInfoByKey(weekKey)
      setSelectedWeek(weekKey)
      if (weekInfo != null) {
        setFilteredQuestionnaires(filterQuestionnairesByWeek(weekInfo.start, weekInfo.end))
      }
    }
  }

  const filterAndProcessTrainingLogs = (selectedTrainingTypes: TrainingType[], selectedSessionTypes: SessionType[]): void => {
    const filteredTrainingLogs = filteredQuestionnaires.filter(questionnaire => {
      const firstDataRow = getFirstDataRowObject(questionnaire)
      return questionnaire.owner.id === selectedProfile && isTrainingLog(questionnaire) &&
        selectedTrainingTypes.includes(smartTranslate(firstDataRow?.values.v1)) &&
        selectedSessionTypes.includes(smartTranslate(firstDataRow?.values.v2))
    })
    processQuestionnaires(filteredTrainingLogs)
  }

  const filterAndProcessDailyQuestionnaires = (): void => {
    processDailyQuestionnaires(filteredQuestionnaires.filter(questionnaire => {
      return questionnaire.owner.id === selectedProfile && isDailyQuestionnaire(questionnaire)
    }))
  }

  const setTrainingAndSessionTypes = (): void => {
    const ownerTrainingLogQuestionnaires = filteredQuestionnaires.filter(questionnaire => {
      return questionnaire.owner.id === selectedProfile && isTrainingLog(questionnaire)
    })

    const trainingTypeSet = new Set(ownerTrainingLogQuestionnaires.map((questionnaire) => {
      return { id: smartTranslate(getFirstDataRowObject(questionnaire)?.values.v1) }
    }))
    const newTrainingTypes = Array.from(trainingTypeSet).sort(sortById)

    const sessionTypeSet = new Set(ownerTrainingLogQuestionnaires.map((questionnaire) => {
      return { id: smartTranslate(getFirstDataRowObject(questionnaire)?.values.v2) }
    }))
    const newSessionTypes = Array.from(sessionTypeSet).sort(sortById)

    const newTrainingTypesIds = newTrainingTypes.map(trainingType => trainingType.id)
    const newSessionTypesIds = newSessionTypes.map(sessionType => sessionType.id)

    filterAndProcessTrainingLogs(newTrainingTypesIds, newSessionTypesIds)
  }

  // TODO refactor so the received datatype is more specific
  const processQuestionnaires = (data: QuestionnaireObject[]): void => {
    if (data.length === 0) {
      setTrainingLogRows(undefined)
      setSessionTypeHistogram(undefined)
      setTrainingTypeHistogram(undefined)
      setTrainingDaypartHistogram(undefined)
      setPreprocessingDone(true)
      return
    }

    const preprocessedTrainingLogs = preprocessTrainingLogs(data)

    // Make histograms
    const sessionTypeHistogram: Histogram = makeHistogram(preprocessedTrainingLogs.trainingLogRows, 'training_session', shortSessionName) as Histogram
    const trainingTypeHistogram: Histogram = makeHistogram(preprocessedTrainingLogs.trainingLogRows, 'training_type') as Histogram
    const trainingDaypartHistogram: Histogram = makeHistogram(preprocessedTrainingLogs.trainingLogRows, 'training_daypart') as Histogram

    setTrainingLogRows(preprocessedTrainingLogs.trainingLogRows)
    setSessionTypeHistogram(sessionTypeHistogram)
    setTrainingTypeHistogram(trainingTypeHistogram)
    setTrainingDaypartHistogram(trainingDaypartHistogram)
    setPreprocessingDone(true)
  }

  // TODO pass datarows to this function instead of QuestionnaireObjects
  const processDailyQuestionnaires = (data: QuestionnaireObject[]): void => {
    if (data.length === 0) {
      setDailyLogRows(undefined)
      setSundaySummaryRows(undefined)
      setDailyPreprocessingDone(true)
      return
    }
    const preprocessedDailyQuestionnaires = preprocessWellbeingQuestionnaires(data)

    const sundaySummaryRows = preprocessedDailyQuestionnaires.sundaySummary
    setWeeklyFeedbackFromSummary(sundaySummaryRows)
    setSundaySummaryRows(sundaySummaryRows)
    setDailyLogRows(preprocessedDailyQuestionnaires.dailyLogRows)

    setDailyPreprocessingDone(true)
  }

  // TODO remove all of the undefined types where they
  //  are not necessary and then if possible also remove the IsArray checks
  const setWeeklyFeedbackFromSummary = (sundaySummaryRows: SundaySummary[] | undefined): void => {
    // additional type check needed because TS will complain that sundaySummaryRows can be undefined, even after lodash's isEmpty check
    if (!_.isEmpty(sundaySummaryRows) && Array.isArray(sundaySummaryRows)) {
      setWeeklyFeedback(sundaySummaryRows[0]?.weekly_feedback ?? 'N/A')
    }
  }

  const handleSelectProfile = (e: ChangeEvent<HTMLSelectElement>): void => {
    const targetValue = parseInt(e.target.value, 10)

    // Prevent selectedProfile from being set to NaN, as this triggers a warning with the select dropdown
    // Passing -1 to selectProfile here instead will trigger a default to the first person in the dropdown profiles
    if (isNaN(targetValue)) return selectProfile(-1)
    else {
      selectProfile(targetValue)
    }
  }

  const handleSelectWeek = (_e: ChangeEvent<HTMLSelectElement>): void => {
    setSelectedWeek(_e.target.value)
  }

  // unknown parameter type because the function is meant to check whether type is (not a) number
  const nanToDash = (num: unknown): number | '-' => {
    return Number.isNaN(num) ? '-' : num as number
  }

  const hasHistogramData = (trainingDaypartHistogram ?? trainingTypeHistogram ?? sessionTypeHistogram) !== undefined

  if (!fetchedDone) {
    return (
      <div>
        <div className='row' />
        <div className='row'>
          <Spinner ready={false} transparent />
        </div>
      </div>
    )
  }

  let trainingSatisfaction: string | number = 'N/A'
  let trainingSessions: number = 0
  let trainingLoad: number = 0
  let illness: boolean = false
  let injury: boolean = false
  let readiness: string | number = 'N/A'
  let sleep: number = 0
  let restingHR: string | number = 0

  if (trainingLogRows != null) {
    trainingSatisfaction = round(average(trainingLogRows.map(row => row.training_satisfaction)), 1)
    trainingSessions = trainingLogRows.length
    trainingLoad = sum(trainingLogRows.map(row => row.srpe))
  }

  if (dailyLogRows != null) {
    illness = dailyLogRows.some(row => ['ja', 'yes'].includes(row.values.v9 as string))
    injury = dailyLogRows.some(row => ['ja', 'yes'].includes(row.values.v10 as string))
    readiness = round(average(dailyLogRows.map(row => row.readiness_to_train)), 1)
    sleep = round(average(dailyLogRows.map(row => row.sleep_duration_num)), 1)
    restingHR = nanToDash(round(average(dailyLogRows.map(row => row.resting_hr))))
  }

  const trainingColor = (value: string | number): string => {
    if (value === 'N/A') return hcLightGrey
    if (typeof value === 'number') {
      if (value < 2) return hcBrightRed
      if (value >= 3) return hcGreen
    }
    return hcOrange
  }

  const injuryIllnessColor = (value: boolean | undefined): string => {
    if (value === undefined) return '#fafafa'
    if (value) return '#d61c31'
    return '#238B13'
  }

  const dropdownContent = !isEmpty(profiles)
    ? mappedProfiles
    : [{
        name: I18n.t('components.dashboards.select_dropdown.no_data'),
        value: '',
        disabled: true
      }] as Array<{ name: string, value: string | number, disabled?: boolean }>

  return (
    <div>
      <div className='row'>
        <div className='col s12 text-xl text-primary-color text-heavy'>
          {I18n.t('components.dashboards.questionnaire.weekly.title')}
          <InfoRequiredData
            id='dataset-types-info'
            dataTypes={DASHBOARD_REQUIRED_DATA_TYPES.weekly_questionnaire}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col s12 m6'>
          <SelectDropdown
            id={SELECT_PROFILE_ID}
            value={selectedProfile}
            onChange={handleSelectProfile}
            label={I18n.t('components.dashboards.questionnaire.select_profile')}
            placeholder={I18n.t('components.dashboards.select_dropdown.athlete')}
            content={dropdownContent}
          />
        </div>
        <div className='col s12 m6'>
          <SelectDropdown
            id={SELECT_WEEK_ID}
            value={selectedWeek}
            onChange={handleSelectWeek}
            label={I18n.t('components.dashboards.questionnaire.select_week')}
            placeholder={I18n.t('components.dashboards.questionnaire.weekly.past_week')}
            content={questionnaireWeeks?.map(week => ({
              name: week.label,
              value: week.value
            }))}
          />
        </div>
      </div>
      {!preprocessingDone && isLoading && <Spinner ready={false} />}
      {
        preprocessingDone && (
          <>
            <div className='row'>
              <div className='col s12 l6'>
                <div className='chart-container'>
                  {dailyLogRows == null && <GenericPlaceholder />}
                  {dailyLogRows != null && (
                    <>
                      <div className='highcharts-plot-title-container'>
                        <div className='highcharts-plot-title'>
                          {I18n.t('components.dashboards.questionnaire.weekly.compliance.title')}
                        </div>
                        <p>{I18n.t('components.dashboards.questionnaire.weekly.compliance.description')}
                        </p>
                      </div>
                      <div className='compliance-chart'>
                        <ComplianceChart
                          filledIn={dailyLogRows?.length}
                          total={DAYS_IN_A_WEEK}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className='col s12 l6'>
                <div className='chart-container'>
                  <Spinner ready={dailyPreprocessingDone} />
                  {dailyLogRows == null && <GenericPlaceholder />}
                  {dailyLogRows != null && (
                    <DailyReadinessToTrainChart dailyLogRows={dailyLogRows} />
                  )}
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col s12 l6'>
                <div className='chart-container taller'>
                  {!hasHistogramData && <GenericPlaceholder />}
                  {hasHistogramData && (
                    <div className='highcharts-plot-title-container'>
                      <div className='highcharts-plot-title'>
                        {I18n.t('components.dashboards.questionnaire.weekly.training.title')}
                      </div>
                      <p>{I18n.t('components.dashboards.questionnaire.weekly.training.description')}
                      </p>
                    </div>
                  )}
                  <div className='highcharts-plots-container with-text'>
                    <div className='highcharts-plot-container'>
                      {trainingDaypartHistogram != null && (
                        <TrainingSessionsChart
                          title={I18n.t('components.dashboards.questionnaire.training_sessions.title.daypart')}
                          data={trainingDaypartHistogram}
                          color={hcDayPartColor}
                        />
                      )}
                    </div>
                    <div className='highcharts-plot-container'>
                      {trainingTypeHistogram != null && (
                        <TrainingSessionsChart
                          title={I18n.t('components.dashboards.questionnaire.training_sessions.title.type')}
                          data={trainingTypeHistogram}
                          color={hcTypeColor}
                        />
                      )}
                    </div>
                    <div className='highcharts-plot-container'>
                      {sessionTypeHistogram != null && (
                        <TrainingSessionsChart
                          title={I18n.t('components.dashboards.questionnaire.training_sessions.title.session')}
                          data={sessionTypeHistogram}
                          color={hcSessionColor}
                        />
                      )}
                    </div>
                  </div>
                  {trainingLogRows != null && (
                    <div className='dashboard-table'>
                      <table className='centered responsive-table'>
                        <thead>
                          <tr>
                            <th>{I18n.t('components.dashboards.questionnaire.weekly.training.training_satisfaction')}</th>
                            <th>{I18n.t('components.dashboards.questionnaire.weekly.training.training_sessions')}</th>
                            <th>{I18n.t('components.dashboards.questionnaire.weekly.training.training_load')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span
                                className='colored-background'
                                style={{ backgroundColor: trainingColor(trainingSatisfaction) }}
                              >
                                {trainingSatisfaction}
                              </span>
                            </td>
                            <td>{trainingSessions}</td>
                            <td>{trainingLoad}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
              <div className='col s12 l6'>
                <div className='chart-container taller'>
                  <Spinner ready={dailyPreprocessingDone} />
                  {dailyLogRows == null && <GenericPlaceholder />}
                  {dailyLogRows != null && (
                    <>
                      <div className='highcharts-plot-title-container relative-position'>
                        <div className='highcharts-plot-title'>
                          {I18n.t('components.dashboards.questionnaire.weekly.health.title')}
                        </div>
                        <p>
                          {I18n.t('components.dashboards.questionnaire.weekly.health.description')}
                        </p>
                      </div>
                      <div className='dashboard-table'>
                        <table className='centered'>
                          <thead>
                            <tr>
                              <th>{I18n.t('components.dashboards.questionnaire.weekly.health.illness')}</th>
                              <th>{I18n.t('components.dashboards.questionnaire.weekly.health.injury')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <span
                                  className='colored-background'
                                  style={{ backgroundColor: injuryIllnessColor(illness) }}
                                >
                                  {illness ? I18n.t('components.dashboards.questionnaire.weekly.health.sick') : I18n.t('components.dashboards.questionnaire.weekly.health.not_sick')}
                                </span>
                              </td>
                              <td>
                                <span
                                  className='colored-background'
                                  style={{ backgroundColor: injuryIllnessColor(injury) }}
                                >
                                  {injury ? I18n.t('components.dashboards.questionnaire.weekly.health.injured') : I18n.t('components.dashboards.questionnaire.weekly.health.not_injured')}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <WeeklyWellnessChart dailyLogRows={dailyLogRows} />
                      <div className='dashboard-table'>
                        <table className='centered'>
                          <thead>
                            <tr>
                              <th>{I18n.t('components.dashboards.questionnaire.weekly.health.readiness_to_train')}</th>
                              <th>{I18n.t('components.dashboards.questionnaire.weekly.health.sleep')}</th>
                              <th>{I18n.t('components.dashboards.questionnaire.weekly.health.resting_hr')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <span
                                  className='colored-background'
                                  style={{ backgroundColor: trainingColor(readiness) }}
                                >
                                  {readiness}
                                </span>
                              </td>
                              <td>{`${sleep} ${I18n.t('components.dashboards.questionnaire.weekly.health.h') as string}`}</td>
                              <td>{`${restingHR} ${I18n.t('components.dashboards.questionnaire.weekly.health.bpm') as string}`}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col s12'>
                <div className='chart-container comments'>
                  {(sundaySummaryRows == null || sundaySummaryRows.length === 0) && <GenericPlaceholder />}
                  {sundaySummaryRows != null && sundaySummaryRows.length > 0 && (
                    <>
                      <div className='highcharts-plot-title-container relative-position'>
                        <div className='highcharts-plot-title'>
                          {I18n.t('components.dashboards.questionnaire.weekly.comments.title')}
                        </div>
                        <p>
                          {I18n.t('components.dashboards.questionnaire.weekly.comments.description')}
                        </p>
                      </div>
                      <div className='dashboard-table'>
                        <table className='centered'>
                          <thead>
                            <tr>
                              <th>{I18n.t('components.dashboards.questionnaire.weekly.comments.your_feedback')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{weeklyFeedback}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <MyReactTooltip id='questionnaire-tooltip' effect='solid' className='wider-tooltip' />
          </>
        )
      }
    </div>
  )
}

export default WeeklyQuestionnaireDashboard
