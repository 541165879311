import React from 'react'
import DashboardGrid from '../../layout/DashboardGrid'
import BackButton from '../../../common/BackButton'
import PropTypes from 'prop-types'

const DashboardPicker = (props) => {
  return (
    <>
      {!props.hideBackButton &&
        <div className='row'>
          <div className='col s12'>
            <BackButton {...props} />
          </div>
        </div>}
      <DashboardGrid dashboards={props.dashboards} />
    </>
  )
}

DashboardPicker.propTypes = {
  dashboards: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    abbrevName: PropTypes.string.isRequired,
    cssKlass: PropTypes.string.isRequired,
    implemented: PropTypes.bool.isRequired,
    link: PropTypes.string.isRequired
  }))
}

export default DashboardPicker
