import React from 'react'
import I18n from 'i18n'
import DataSharingRow from './DataSharingRow'
import moment from 'moment'

function DataSharingRuleList (props) {
  const rights = props.rights
  rights && rights.sort((a, b) => { return moment(a.created_at).isSameOrAfter(b.created_at) ? -1 : 1 })
  return (
    <div className='row'>
      <div className='col s12'>
        <table className='responsive-table table-data-sharing-scroll'>
          <thead>
            <tr>
              <th className='data-sharing-c1'>{I18n.t('datasharing.headers.source')}</th>
              <th className='data-sharing-c2'>{I18n.t('datasharing.headers.permission')}</th>
              <th className='data-sharing-c3'>{I18n.t('datasharing.headers.shared_with')}</th>
              <th className='data-sharing-c4'>{I18n.t('datasharing.headers.from')}</th>
              <th className='data-sharing-c5'>{I18n.t('datasharing.headers.to')}</th>
              <th className='data-sharing-c6'>{I18n.t('datasharing.headers.options')}</th>
            </tr>
          </thead>
          <tbody>
            {rights && rights.map(right => (
              <tr key={right.id}>
                <DataSharingRow
                  id={right.id}
                  key={right.id}
                  right={right}
                  {...props}
                />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default DataSharingRuleList
