import React from 'react'
import { Bar } from 'react-chartjs-2'
import _ from 'lodash'
import { BINS_DEFAULT_COLOR } from '../../../common/Constants'

/**
 * bins is an array. Each bin should come in the format:
 * { label: 'nameoflabel', shouldInclude: f(), backgroundColor: 'color1', borderColor: 'color2' }
 * @param {*} props
 */
const Bins = (props) => {
  const { values, bins, yLabel, isPercentage, withLegend } = props
  const ROUNDING_PRECISION = 2

  // Counts the number of observations there are in each bin
  const getBinCounts = (values, bins) => {
    const binCounts = _.reduce(bins, (accum, bin, binIdx) => {
      const count = _.filter(values, bin.shouldInclude)?.length || 0
      accum[binIdx] = isPercentage ? _.round((count / values?.length) * 100, ROUNDING_PRECISION) : count
      return accum
    }, [])
    return binCounts
  }

  const xLabels = bins.map((bin, binIdx) => bin?.label || binIdx)
  const backgroundColors = bins.map((bin) => bin?.backgroundColor || BINS_DEFAULT_COLOR)
  const borderColors = bins.map((bin) => bin?.borderColor || BINS_DEFAULT_COLOR)

  const data = {
    labels: xLabels,
    datasets: [
      {
        data: getBinCounts(values, bins),
        label: yLabel,
        backgroundColor: backgroundColors,
        borderColor: borderColors
      }
    ]

  }

  // TODO Show the percentage sign if isPercentage is true

  const options = {
    legend: {
      display: withLegend
    }
  }

  return (
    <Bar data={data} options={options} />
  )
}

export default Bins
