import React from 'react'
import I18n from 'i18n'
import _ from 'lodash'
import moment from 'moment'
import {
  dataParameterDuration,
  soccerDataParameters,
  soccerGeneralInfoParameters,
  soccerTeamColor,
  soccerTeamLabelColor
} from '../../../common/Constants'

export default class SoccerGeneralInfo extends React.Component {
  constructor (props) {
    super(props)
    this.parameters = soccerGeneralInfoParameters
  }

  getScoreDisplay (scoreStr, teams) {
    if (!teams || !teams.length || teams.length !== 2) {
      return <></>
    }

    const team1Style = { backgroundColor: soccerTeamColor[0], color: soccerTeamLabelColor[0] }
    const team2Style = { backgroundColor: soccerTeamColor[1], color: soccerTeamLabelColor[1] }
    let [team1Score, team2Score] = ['-', '-']
    if (scoreStr) {
      [team1Score, team2Score] = _.map(scoreStr.split('-'), function (teamScore) { return parseInt(teamScore) })
    }

    return (
      <div className='soccer-team-standings'>
        <div className='row'>
          <div className='col s5' style={team1Style}><h6>{teams[0].name}</h6></div>
          <div className='col s2'><h6>vs</h6></div>
          <div className='col s5' style={team2Style}><h6>{teams[1].name}</h6></div>
        </div>
        {team1Score && team2Score &&
          <div className='row'>
            <div className='col s5'><h6>{team1Score}</h6></div>
            <div className='col s2' />
            <div className='col s5'><h6>{team2Score}</h6></div>
          </div>}
      </div>
    )
  }

  getMetadataTable (data) {
    return this.parameters.map(parameter => {
      if (!data[parameter]) {
        return <></>
      }
      const parameterTitle = I18n.t(soccerDataParameters[parameter].title)
      let parameterValue = data[parameter].value || data[parameter]
      switch (parameter) {
        case dataParameterDuration:
          parameterValue = Math.floor(moment.duration(parameterValue, 'seconds').asMinutes())
          break
        default:
          if (soccerDataParameters[parameter].unit) {
            parameterValue = parameterValue + ' ' + soccerDataParameters[parameter].unit
          }
      }
      return (
        <tr key={parameter}>
          <th>{parameterTitle}</th>
          <td>{parameterValue}</td>
        </tr>
      )
    })
  }

  render () {
    let score = <></>
    let matchMetadata = [<tr key='noData'><th>{I18n.t('components.dashboards.soccer.general_info_table.no_data')}</th></tr>]
    if (this.props.data && Object.keys(this.props.data).length !== 0) {
      score = this.getScoreDisplay(this.props.data.final_score, this.props.data.teams)
      matchMetadata = this.getMetadataTable(this.props.data)
    }
    return (
      <div className='soccer-general-info'>
        <div className='row'>
          <div className='col s12'>
            {score}
          </div>
        </div>
        <div className='row'>
          <div className='col s12'>
            <table className='soccer-dashboard-table'>
              <tbody>
                {matchMetadata}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}
