import React from 'react'
import _ from 'lodash'
import I18n from 'i18n'

import Rings from '../../common/Rings'
import { readComplaintsQuestionnaire } from '../../../../common/utils/QuestionnaireUtils'
import { BODY_TO_COLOR, chartjsTitleProperties } from '../../../../common/Constants'
import { abbreviateString } from '../../../../common/utils/StringUtils'

const InjuryLocationsChart = (props) => {
  const { questionnaires } = props

  const complaints = _.reduce(questionnaires, (result, questionnaire) => {
    const parsedQuestionnaire = readComplaintsQuestionnaire(questionnaire?.structured_data_objects?.[0]?.data_rows?.[0]?.values)
    const injuriesObj = parsedQuestionnaire.injury || {}
    Object.keys(injuriesObj).forEach((injury) => { result[injury] = _.add(result[injury], 1) })

    if (parsedQuestionnaire?.illness) {
      result.illness = _.add(result.illness, 1)
    }
    return result
  }, {})

  const datasets = []
  const colors = []
  const labels = []
  _.entries(complaints).forEach(([injury, count]) => {
    datasets.push(count)
    colors.push(BODY_TO_COLOR[injury])
    labels.push(I18n.t(`components.dashboards.questionnaire.complaints.body_parts.${injury}`))
  })

  const MAX_LEGEND_STRING_LENGTH = 15
  // TODO Fix the legend being cut off if too many items
  const configs = [{
    data: {
      datasets: [{
        data: datasets,
        backgroundColor: colors
      }],
      labels: labels
    },
    options: {
      animation: {
        animateRotate: false
      },
      title: {
        text: I18n.t('components.dashboards.questionnaire.complaints.injury_locations.title'),
        ...chartjsTitleProperties
      },
      legend: {
        position: 'right',
        labels: {
          boxWidth: 10,
          // Hacky way of changing the legend labels. Explained here: https://stackoverflow.com/questions/39454586/pie-chart-legend-chart-js/68619866#68619866
          filter: (item, data) => {
            item.text = abbreviateString(item.text, MAX_LEGEND_STRING_LENGTH)
            return true
          }
        }
      }
    }
  }]

  return (
    <Rings ringConfigs={configs} translatedUp />
  )
}

export default InjuryLocationsChart
