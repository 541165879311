import React from 'react'
import { Route } from 'react-router'

import ResetPassword from './layout/ResetPassword'
import ResetPasswordEdit from './layout/ResetPasswordEdit'
import Login from './layout/Login'
import SignUp from './layout/SignUp'
import Confirmation from './layout/Confirmation'
import LoginContainer from './layout/LoginContainer'
import NotFoundWrapper from '../router/NotFoundWrapper'

export default class AccountRoutes extends React.Component {
  constructor () {
    super()
    this.state = {
      targetHeight: 'auto',
      duration: 500
    }
  }

  setHeight (targetHeight, duration = 500) {
    this.setState({ targetHeight, duration })
  }

  render () {
    const { resetPasswordProps, resetPasswordEditProps, loginProps, signupProps, confirmationProps } = this.props
    return (
      <NotFoundWrapper slug='account'>
        <Route
          exact path='(/login|/|/password|/password/edit|/confirmation|/signup)' render={(props) => (
            <LoginContainer location={props.location} targetHeight={this.state.targetHeight} duration={this.state.duration} setHeight={this.setHeight.bind(this)}>
              <Route
                exact path='/signup' render={(props) =>
                  <SignUp
                    {...props} {...signupProps} setHeight={this.setHeight.bind(this)}
                    targetHeight={this.state.targetHeight}
                  />}
              />
              <Route
                exact path='/confirmation' render={(props) =>
                  <Confirmation
                    {...props} {...confirmationProps} setHeight={this.setHeight.bind(this)}
                    targetHeight={this.state.targetHeight}
                  />}
              />
              <Route
                exact path='/password' render={(props) =>
                  <ResetPassword
                    {...props} {...resetPasswordProps} setHeight={this.setHeight.bind(this)}
                    targetHeight={this.state.targetHeight}
                  />}
              />
              <Route
                exact path='/password/edit' render={(props) =>
                  <ResetPasswordEdit
                    {...props} {...resetPasswordEditProps} setHeight={this.setHeight.bind(this)}
                    targetHeight={this.state.targetHeight}
                  />}
              />
              <Route
                exact path='(/login|/)' render={(props) =>
                  <Login
                    {...props}
                    {...loginProps}
                    signupProps={signupProps}
                    resetPasswordProps={resetPasswordProps}
                    updateSession={this.props.updateSession}
                    sessionToken={this.props.sessionToken}
                    setHeight={this.setHeight.bind(this)}
                    targetHeight={this.state.targetHeight}
                  />}
              />
            </LoginContainer>
          )}
        />
      </NotFoundWrapper>

    )
  }
}
