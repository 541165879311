import React from 'react'
import SelectDropdown from '../../../common/SelectDropdown'
import I18n from 'i18n'

export const extraVariableTypes = {
  TIME: 'time',
  HIST: 'hist'
}

/**
 * Placeholder option that represents the X-axis in a timeseries chart
 */
export const numericalIndex = () => {
  return {
    name: I18n.t('components.visualization.csv.timeseries_axis_name'),
    type: extraVariableTypes.TIME,
    label: I18n.t('components.visualization.csv.timeseries_axis_label')
  }
}

/**
 * Placeholder option that indicates the user wants a histogram for a continuous variable
 */
export const numericalNone = () => {
  return {
    name: I18n.t('components.visualization.csv.histogram_axis_name'),
    type: extraVariableTypes.HIST,
    label: I18n.t('components.visualization.csv.histogram_axis_label')
  }
}

/**
 * Return all variables of the given type from column description
 */
export const getVariables = (description, type) => {
  return (description || []).filter(d => d.type === type)
}

/**
 * Form component that allows the user to select two variables from a CSV in order to visualize them
 */
export const VariableSelector = ({ numerical, categorical, onSelectY, onSelectX, selectedY, selectedX, onAddGraph }) => {
  const content = []

  /**
   * Add optgroups for numerical and categorical variables
   */
  numerical && numerical.length > 0 && content.push({
    groupName: I18n.t('components.visualization.csv.numeric_optgroup'),
    elements: numerical
  })

  categorical && categorical.length > 0 && content.push({
    groupName: I18n.t('components.visualization.csv.categorical_optgroup'),
    elements: categorical
  })

  /**
   * For columns that are present in the CSV we simply us the element's name as label
   */
  numerical && numerical.forEach((elem) => { elem.label = elem.name })
  categorical && categorical.forEach((elem) => { elem.label = elem.name })

  /**
   * the currently selected X and Y variables
   */
  const selectedYVariable = [...numerical, ...categorical].filter((variable) => variable.name === selectedY)[0]
  const selectedXVariable = [numericalIndex(), numericalNone(), ...numerical, ...categorical].filter((variable) => variable.name === selectedX)[0]

  /**
   * Remove any numerical variables that are already selected
   */
  const filteredNumerical = numerical.filter((variable) => variable.name !== (selectedYVariable && selectedYVariable.name) || '')

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <SelectDropdown
            placeholder={I18n.t('components.visualization.csv.y_variable_default')}
            defaultValue=''
            content={content}
            onChange={(e) => { onSelectY(e.target.value) }}
            id='variable-selector'
            label={I18n.t('components.visualization.csv.y_variable_label')}
          />
        </div>
      </div>
      {selectedYVariable && selectedYVariable.type === 'numeric' && (
        <div className='row'>
          <div className='col s12'>
            <SelectDropdown
              placeholder={I18n.t('components.visualization.csv.x_variable_numeric_default', { name: selectedYVariable.name })}
              defaultValue=''
              value={selectedX}
              content={[numericalIndex(), numericalNone(), ...filteredNumerical]}
              onChange={(e) => { onSelectX(e.target.value) }}
              id='variable-selector-numeric'
              label={I18n.t('components.visualization.csv.x_variable_numeric_label', { name: selectedYVariable.name })}
            />
          </div>
        </div>
      )}
      {selectedYVariable && selectedYVariable.type === 'categorical' && (
        <div className='row'>
          <div className='col s12'>
            <SelectDropdown
              placeholder={I18n.t('components.visualization.csv.x_variable_categorical_default', { name: selectedYVariable.name })}
              defaultValue=''
              value={selectedX}
              content={[numericalNone(), ...filteredNumerical]}
              onChange={(e) => { onSelectX(e.target.value) }}
              id='variable-selector-categorical'
              label={I18n.t('components.visualization.csv.x_variable_categorical_label', { name: selectedYVariable.name })}
            />
          </div>
        </div>
      )}
      {selectedYVariable && selectedXVariable && <button onClick={() => onAddGraph(selectedXVariable, selectedYVariable)} className='waves-effect waves-light background-primary text-background-color button-primary'>{I18n.t('components.visualization.csv.add_graph')}</button>}
    </>
  )
}
