import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Rings = (props) => {
  const { ringConfigs, translatedUp } = props
  const nRings = ringConfigs.length || 1
  const col = (12 / nRings).toFixed(0)
  const translatedUpClass = translatedUp ? 'translated-up' : ''

  return (
    <div className={classnames('row', 'rings-container', `${translatedUpClass}`)}>
      {
        ringConfigs.map((conf, idx) => (
          <div key={idx} className={classnames(`col s${col}`, 'm12', `l${col}`)}>
            <Doughnut data={conf.data} options={conf.options} height={props.height} redraw />
          </div>
        ))
      }
    </div>
  )
}

Rings.propTypes = {
  ringConfigs: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired
  })).isRequired,
  height: PropTypes.number,
  // Applies translationY(-50%) to the rings container
  translatedUp: PropTypes.bool
}

export default Rings
