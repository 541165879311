import React from 'react'
import { Route } from 'react-router'
import TrainingScheduler from './layout/TrainingScheduler'
import NotFoundWrapper from '../router/NotFoundWrapper'
import Routes from '../common/Routes'

interface Props {
  indexProps: {}
}

const TrainingRoutes: React.FC<Props> = (props) => {
  const { indexProps } = props
  return (
    <NotFoundWrapper slug='training'>
      <Route
        exact
        path={Routes.training.index}
        render={(props) => (<TrainingScheduler {...props} {...indexProps} />)}
      />
    </NotFoundWrapper>
  )
}

export default TrainingRoutes
