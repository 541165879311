const attribute2Value = {
  fatigue: 'v1',
  pain: 'v3',
  stress: 'v4',
  physical: 'v5',
  cognitive: 'v6',
  rest: 'v7',
  sleep: 'v8'
}

const possibleTimeAttribute = {
  fatigue: false,
  pain: false,
  stress: false,
  physical: true,
  cognitive: true,
  rest: true,
  sleep: true
}

const extractTimeOrNumberResponse = (answers, attributeKey) => {
  // Backwards compatible because in an earlier version of the questionnaire, these were numbers instead of time entries.
  const hoursKey = `${attributeKey}_uren`
  const minutesKey = `${attributeKey}_minuten`
  if (Object.keys(answers).includes(hoursKey) && Object.keys(answers).includes(minutesKey)) {
    return (parseInt(answers[hoursKey]) + parseInt(answers[minutesKey]) / 60.0)
  } else {
    return parseInt(answers[attributeKey])
  }
}

export const extractValueForAttribute = (response, attribute) => {
  // Some questions are of the :time type, but in an earlier version they were numbers.
  if (possibleTimeAttribute[attribute]) {
    return extractTimeOrNumberResponse(response.answers, attribute2Value[attribute])
  } else {
    return parseInt(response.answers[attribute2Value[attribute]])
  }
}

export const suggestedMaxForPersona = (persona) => {
  switch (persona) {
    case 'fatigue':
    case 'pain':
    case 'stress':
      return 10
    case 'physical':
    case 'cognitive':
    case 'rest':
    case 'sleep':
      return 24
    default:
      console.warn(`Persona '${persona}' not understood`)
      return 10
  }
}
