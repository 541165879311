import Variables from '../../stylesheets/variables.module.scss'
import { getDatePickerLocale } from './DatePickerUtils'
import { SubscriptionType } from './types/Subscription'
import { SessionType } from './types/SessionType'
import { ReactDatePickerProps } from 'react-datepicker'
const { grayChartjs, accentColorLight, accentColorDark } = Variables

export const defaultProfilePicture = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAM1BMVEXk5ueutLfn6eqrsbTp6+zg4uOwtrnJzc/j5earsbW0uby4vcDQ09XGyszU19jd3+G/xMamCvwDAAAFLklEQVR4nO2d2bLbIAxAbYE3sDH//7WFbPfexG4MiCAcnWmnrzkjIRaD2jQMwzAMwzAMwzAMwzAMwzAMwzAMwzAMwzAMwzAMw5wQkHJczewxZh2lhNK/CBOQo1n0JIT74/H/qMV0Z7GU3aCcVPuEE1XDCtVLAhgtpme7H0s1N1U7QjO0L8F7llzGeh1hEG/8Lo7TUmmuSrOfns9xnGXpXxsONPpA/B6OqqstjC6Ax/0ujkNdYQQbKNi2k64qiiEZ+ohi35X+2YcZw/WujmslYewiAliVYrxgJYrdwUmwXsU+RdApUi83oNIE27YvrfB/ZPg8+BJETXnqh9CVzBbTQHgojgiCvtqU9thFJg/CKz3VIMKMEkIXxIWqIpIg2SkjYj+xC816mrJae2aiWGykxRNsW0UwiJghJDljYI5CD8GRiCtIsJxizYUPQ2pzItZy5pcisTRdk/a9m4amtNNfBuQkdVhSaYqfpNTSFGfb9GRIakrE2Pm+GFLaCQPqiu0OpWP+HMPQQcgQMiQprWXNmsVwIjQjYi/ZrhAqNTCgr2gu0Jnz85RSSjso0HkMFZ0YZjKkc26a/jlmh9JiDyDxi9oeorTYAzZkwwoMz19pzj9bnH/GP/+qbchjSGflneWYhtTuKdMOmNKZcJ5TjInQKcYXnESd/jQxy0ENpULTNGOGgxpap/oyw9pbUAqhfx2Dbkhovvfgz4iUzoM9+GlK6/Mh4q29hyC1mwro30hpVVLPF9wYQr71RazOeM5/cw81iBRD+A03aM9/C/obbrKjbYSpCmIVG3qT/Q8oeUo3Rz0IL7vI1tEbCB9pSiu8I/aV8x3Kg/BGWrWp4ZVs0nZfmAoEG4h/61yHYIJiFSl6Q0Vk6tTW1N8kYp8hdOkfHYYMXd2Qft+8CYwqYDSKvqIh+MCF8Wgca2u/cwdgeW3TtuVn6+1oBs3yLo5C2JpK6CvQzGpfUkz9UG/87gCsi5o2LIXolxN0FbwAsjOLEr+YJmXn7iR6N0BCt5p5cMxm7eAsfS+/CACQf4CTpKjzgkvr2cVarVTf96372yut7XLJ1sa7lv6VcfgYrWaxqr3Wlo1S6pvStr22sxOtTNPLzdY3nj20bPP+ejFdJYkLsjGLdtPBEbe/mr2bQKiXWJDroA+vtzc0p9aahuwqHMDYrQEXHEw9jwQl3drMpts9JBU1SdktPe5FBRdJQ6bwXBpa57ib2A8kukQDzMjh++Uo7Fo6Wd02Pkf4fknqoo4HtvAIjsqUcjx6DIPgWCaOML9rKI/oqD9/lgNrn+eF+p7j8tnzHBiR7+kdUGw/+V1Kzkc75mMy6U+FMaxjPibiM1U1uGM+puInHpmALZCgP4pt7i840MV8+0R1zPsRB6UTcqpizncYwZ89syDydfyWCwXB1l8/zRNGWbTG/GHKUm9AkxHMc/EGSk3z2+ArEhPEV5TUBLEvUGFcjEUH80J/jveTGOAJEljJbILWGQT3zRYiwuKsUXN1EEJAzBhRJFll7mBUG7KD8EqPkKekBREaL8hMDZLQSG6AQjtHPYmvTQnX0TtpC1SYCe2YdkkyLP3jj5BSbKiuR585eQhTgoje6yIb0Yb0C+mV6EYvebqw5SDy2WmubogZiF2AVxPC2FpDf8H2Q9QWo6IkjUxTWVEI3WY/wrCeSuqJ+eRWzXR/JXwgVjUMozbCOfoEZiSiKVGepqv5CJ8RyR4D7xBeamqa7z3BJ/z17JxuBPdv93d/a2Ki878MMAzDMAzDMAzDMAzDMF/KP09VUmxBAiI3AAAAAElFTkSuQmCC'
export const defaultProfileName = 'Profile'

export const events = Object.freeze({
  category: {
    button_click: 'button.click'
  }
})

export const feedbackEmail = process.env.FEEDBACK_EMAIL

export const DAYS_IN_A_WEEK = 7
export const DAYS_IN_A_MONTH = 30

// QUESTIONNAIRE_PERIOD is used on the sliders of questionnaire dashboards.
// Whenever dealing with week numbers, we use isoWeeks. Because the regular ones are off by one from what we'd expect.
export const QUESTIONNAIRE_PERIOD = 'isoWeek'

export const momentDateFormat = 'DD-MM-YYYY'
export const momentDateTimeFormat = 'DD-MM-YYYY HH:mm:ss'
export const momentDateTimeFormatNoSecs = 'DD-MM-YYYY HH:mm'
export const momentFullDateFormat = 'dddd LL'
export const momentFullDateFormatWithTime = 'LLL'
export const momentFullDateFormatWithTimeAndDay = 'dddd LLL'
export const momentShortDateFormatWithTime = 'lll'
export const momentTimeFormat = 'HH:mm'
export const momentFullTimeFormat = 'HH:mm:ss'
export const momentMinuteSecondFormat = 'mm:ss'
export const momentMonthDayFormat = 'DD MMM'
export const momentDayMonthYearFormat = 'DD MMM YY'
export const momentWrittenMonthShortYearFormat = "MMMM 'YY"
export const momentDurationDayFormat = 'DDD'
export const momentWeekFormat = 'D MMM'
export const momentWeekYearFormat = 'D MMM YYYY'

export const dbDateFormat = 'YYYY-MM-DD'

export const stravaActivitiesBaseUrl = 'https://www.strava.com/activities/'

// This needs to be rendered at the time of rendering the component
// because the locale is not available at the time of import.
export const datePickerDefaultOptions = (): Partial<ReactDatePickerProps> => {
  return {
    dateFormat: 'dd-MM-yyyy',
    showYearDropdown: true,
    showMonthDropdown: true,
    autoComplete: 'off',
    popperClassName: 'safari-proof-popper',
    ...getDatePickerLocale()
  }
}

export const dateAndTimePickerDefaultOptions = (): Partial<ReactDatePickerProps> => {
  return {
    dateFormat: 'dd-MM-yyyy HH:mm',
    showYearDropdown: true,
    showMonthDropdown: true,
    showTimeSelect: true,
    autoComplete: 'off',
    timeIntervals: 15,
    popperClassName: 'safari-proof-popper',
    ...getDatePickerLocale()
  }
}

export const dataTypes = Object.freeze({
  unstructured: 'unstructured',
  volleybal_stats_type: 'volleybal_stats_type',
  volleybal_jump_type: 'volleybal_jump_type',
  fit_data_type: 'fit_data_type',
  fitbit_type: 'fitbit_type',
  garmin_type: 'garmin_type',
  polar_type: 'polar_type',
  we_fitter_type: 'we_fitter_type',
  ice_skating_hr_type: 'ice_skating_hr_type',
  ice_skating_lap_type: 'ice_skating_lap_type',
  scisports_soccer_type: 'scisports_soccer_type',
  strava_type: 'strava_type',
  jupyterhub_type: 'jupyterhub_type',
  questionnaire_type: 'questionnaire_type',
  generic_csv_type: 'generic_csv_type',
  mp4_video_file_type: 'mp4_video_file_type',
  my_temp_csv_type: 'my_temp_csv_type'
})

export const sportsExcludedFromFrontend = Object.freeze([
  'sports.generic',
  'sports.workout',
  'sports.jupyterhub',
  'sports.esports'
])

export const interestsExcludedFromFrontend = Object.freeze([
  'sports.generic',
  'sports.workout',
  'sports.generic_csv',
  'sports.jupyterhub',
  'sports.questionnaire'
])

export const dataObjectStages = Object.freeze({
  plantation_stage: 'plantation',
  ready_for_harvest_stage: 'ready-for-harvest',
  harvesting_stage: 'harvesting',
  ready_for_roasting_stage: 'ready-for-roasting',
  roasting_stage: 'roasting',
  brewed_stage: 'brewed',
  failed_stage: 'failed'
})

export const flavours = Object.freeze({
  default: 'default',
  fit_file_session: 'fit_file_session',
  fit_file_records: 'fit_file_records',
  csv_rows: 'csv_rows',
  columns_description: 'columns_description',
  summary: 'summary'
})

export const regularGroups = Object.freeze([
  'group',
  'anonymized'
])

export const sports = Object.freeze({
  volleyball: 'sports.volleyball',
  skating: 'sports.skating',
  soccer: 'sports.soccer',
  running: 'sports.running',
  hiking: 'sports.hiking',
  cycling: 'sports.riding',
  swimming: 'sports.swimming',
  steps: 'sports.steps',
  workout: 'sports.workout',
  questionnaire: 'sports.questionnaire',
  jupyterhub: 'sports.jupyterhub',
  esports: 'sports.esports',
  generic: 'sports.generic'
})

export const sdvDataFormatSports = Object.freeze({
  soccer: 'sports.soccer',
  running: 'sports.running',
  hiking: 'sports.hiking',
  cycling: 'sports.riding',
  swimming: 'sports.swimming',
  steps: 'sports.steps',
  workout: 'sports.workout',
  generic: 'sports.generic'
})

export const roles = Object.freeze({
  owner: 'owner',
  administrator: 'administrator',
  member: 'member'
})

export const timeParameters = [
  'fitbit.sleep_get_up_time',
  'fitbit.sleep_start_time'
]
export const durationParameters = [
  'fitbit.sleep_minutes'
]
export const minYAxis = Object.freeze({
  'fitbit.sleep_get_up_time': 24 * 3600 * 1000,
  'fitbit.sleep_start_time': 16 * 3600 * 1000
})

export const waypointThreshold = 2
export const skeletonLines = 4

export const hcOrange = '#fa8832'
export const hcDarkOrange = '#da6105'
export const hcLightGreen = '#daeada'
export const hcGreen = '#0a720a'
export const hcRed = '#be1111'
export const hcBrightRed = '#ff0000'
export const hcBlack = '#34393c'

export const hcLightGrey = '#F3F3F3'
export const hcDarkGrey = '#b2b2b2'
export const hcFadedRed = '#E46151'
export const hcBlue = '#41B5E9'

export const hcDayPartColor = '#08306b'
export const hcTypeColor = '#00441b'
export const hcSessionColor = '#67000d'

export const hcInactiveIconColor = '#dcdcdc'
export const hcTitleColor = '#333'
export const hcSubTitleColor = '#444'
export const hcAxisLabelColor = '#666'

export const hcTitleFont = '"Raleway", arial, sans-serif'
export const hcChartFont = '"Open Sans", arial, sans-serif'

export const hcTitleFontSize = 18
export const hcTitleLineHeight = 1
export const hcTitlefontWeight = 'bold'

export const hcAxisFontSize = 16.8 // For the labels, not for the ticks

export const chartjsDefaultOptions = Object.freeze({
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    duration: 0
  }
})

export const chartjsDefaultArguments = Object.freeze({
  height: 400
})

export const chartjsTitleProperties = Object.freeze({
  display: true,
  fontSize: hcTitleFontSize,
  fontFamily: hcTitleFont,
  fontStyle: hcTitlefontWeight,
  fontColor: hcTitleColor,
  lineHeight: hcTitleLineHeight
})

export const chartjsScaleLabelProperties = Object.freeze({
  display: true,
  fontColor: hcAxisLabelColor,
  fontFamily: hcTitleFont,
  fontSize: hcAxisFontSize
})

export const chartjsCrosshairProperties = Object.freeze({
  line: {
    color: 'black'
  },
  zoom: { enabled: false }
})

export const chartjsInactiveClockColor = 'rgba(0, 0, 0, 0.1)'
export const chartjsActiveClockColor = 'rgb(255, 99, 132, 0.75)'
export const chartjsRestHeartRateColor = '#fbe6e5'
export const chartjsRestHeartRateBorderColor = '#191717'

export const stackedChartColors = [
  'rgb(255, 99, 132)',
  'rgb(255, 159, 64)',
  'rgb(255, 205, 86)',
  'rgb(75, 192, 192)',
  'rgb(54, 162, 235)',
  'rgb(153, 102, 255)',
  'rgb(201, 203, 207)'
]

export const highlightColor = '#39acdd'

export const highchartsThemeElementary = Object.freeze({
  colors: [
    hcBlue,
    hcOrange,
    hcBlack,
    hcFadedRed
  ],
  chart: {
    style: {
      color: hcTitleColor,
      fontFamily: hcChartFont
    },
    height: 300
  },
  title: {
    style: {
      fontFamily: hcTitleFont,
      fontWeight: 'bold'
    }
  },
  subtitle: {
    style: {
      fontFamily: hcTitleFont,
      fontWeight: '100'
    }
  },
  plotOptions: {
    series: {
      states: {
        inactive: {
          opacity: 0.7
        }
      }
    }
  },
  legend: {
    enabled: false,
    align: 'right',
    verticalAlign: 'bottom'
  },
  xAxis: {
    gridLineWidth: 1,
    gridLineColor: hcLightGrey,
    lineColor: hcLightGrey,
    minorGridLineColor: hcLightGrey,
    tickColor: hcLightGrey,
    tickWidth: 1
  },
  yAxis: {
    gridLineColor: hcLightGrey,
    lineColor: hcLightGrey,
    minorGridLineColor: hcLightGrey,
    tickColor: hcLightGrey,
    tickWidth: 1
  },
  credits: {
    enabled: false
  },
  tooltip: {
    crosshairs: false,
    outside: true
  },
  global: {
    useUTC: false
  }
})

// Soccer
export const transparentBlue = 'rgba(0, 0, 255, 1)'
export const transparentRed = 'rgba(255, 0, 0, 1)'
export const soccerTeamColor = [transparentBlue, transparentRed]
export const soccerTeamLabelColor = ['white', 'black'] // matched to soccerTeamColor by index
export const soccerColors = [
  '#e6194b',
  '#3cb44b',
  '#ffe119',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#808080',
  '#ffffff',
  '#000000'
]

export const metricAvgSpeed = 'averageSpeed'
export const metricMaxSpeed = 'maxSpeed'
export const metricDistance = 'distance'
export const metricDuration = 'duration'
export const metricName = 'name'
export const soccerMetrics = {
  [metricAvgSpeed]: {
    title: 'components.dashboards.soccer.metrics.avg_speed',
    unit: 'km/h',
    path: 'time_series_summaries.speed.mean.value'
  },
  [metricMaxSpeed]: {
    title: 'components.dashboards.soccer.metrics.max_speed',
    unit: 'km/h',
    path: 'time_series_summaries.speed.max.value'
  },
  [metricDistance]: {
    title: 'components.dashboards.soccer.metrics.distance',
    unit: 'm',
    path: null
  },
  [metricDuration]: {
    title: 'components.dashboards.soccer.metrics.play_time',
    unit: 'min',
    path: 'duration.value'
  },
  [metricName]: {
    title: 'components.dashboards.soccer.metrics.name',
    unit: '',
    path: 'name'
  }
}

export const dataParameterDuration = 'duration'
export const dataParameterLocation = 'location'
export const dataParameterStartDate = 'start_date'
export const dataParameterEndDate = 'end_date'
export const soccerDataParameters = {
  [dataParameterDuration]: {
    title: 'components.dashboards.soccer.data_parameters.duration',
    unit: 'min'
  },
  [dataParameterLocation]: {
    title: 'components.dashboards.soccer.data_parameters.location'
  },
  [dataParameterStartDate]: {
    title: 'components.dashboards.soccer.data_parameters.start_date'
  },
  [dataParameterEndDate]: {
    title: 'components.dashboards.soccer.data_parameters.end_date'
  }
}

export const soccerViewParameters = [dataParameterLocation, dataParameterDuration, dataParameterStartDate, dataParameterEndDate]
export const soccerGeneralInfoParameters = [dataParameterDuration, dataParameterLocation, dataParameterStartDate, dataParameterEndDate]
export const soccerBarGraphMetrics = [metricAvgSpeed, metricMaxSpeed]
export const soccerOvertimeChartMetrics = [metricAvgSpeed]
export const soccerPlayerComparisonTableMetrics = [metricName, metricDuration, metricAvgSpeed, metricMaxSpeed]

export const conversionMultiplier = 10
export const defaultPlayerAvatarRadius = 20
export const soccerFieldWidth = 105
export const soccerFieldHeight = 68
export const soccerFieldOutOfBounds = 10000000
export const soccerDecimalRounding = 2

export const soccerReplayDuration = 5 // Seconds

export const soccerBothTeamsIndex = 2

// The width/height are switched because the soccer heatmap is aligned vertically
export const soccerHeatmapGridSize = {
  width: Math.floor(soccerFieldHeight / 2),
  height: Math.floor(soccerFieldWidth / 2)
}

export const collectionTypes = {
  RUNNING: 'collection_type.running',
  RIDING: 'collection_type.riding',
  HIKING: 'collection_type.hiking',
  ICE_SKATING: 'collection_type.ice_skating',
  INLINE_SKATING: 'collection_type.inline_skating',
  SOCCER: 'collection_type.soccer',
  SWIMMING: 'collection_type.swimming',
  STRAVA: 'collection_type.strava',
  FIT: 'collection_type.fit',
  STEPS: 'collection_type.steps',
  CSV_FILE: 'collection_type.csv_file',
  TEMP: 'collection_type.temp'
}

export const collectionSubTypes = {
  GENERIC_SUBTYPE: 'collection_subtype.generic',
  INDOOR_RIDING: 'collection_subtype.indoor_riding',
  INDOOR_RUNNING: 'collection_subtype.indoor_running'
}

export const timeSeries = {
  HR: 'hr',
  SPEED: 'speed',
  POWER: 'power',
  CADENCE: 'cadence',
  ELEVATION: 'elevation',
  LATLONG: 'latlong',
  DISTANCE: 'distance',
  POSITION: 'position',
  TEMPERATURE: 'temperature',
  ACCELERATION: 'acceleration'
}

export const physiologicalAttributes = {
  HR_ZONES: 'heart_rate_zones',
  POWER_ZONES: 'power_zones'
}

export const timeSeriesKeyToZonesKey = {
  [timeSeries.HR]: physiologicalAttributes.HR_ZONES,
  [timeSeries.POWER]: physiologicalAttributes.POWER_ZONES
}

export const localeMapping = Object.freeze({
  'en-US': 'en',
  'en-GB': 'en',
  'nl-NL': 'nl',
  'nl-BE': 'nl',
  nl: 'nl',
  en: 'en'
})

export const subscriptionTypes = Object.freeze({
  free: SubscriptionType.FREE,
  coach: SubscriptionType.COACH,
  researcher: SubscriptionType.RESEARCHER
})

/**
 * Soccer Units from the backend (SciSports)
 * distance: m
 * speed: m/s
 */

export const ROWS_FLAVOUR = 'csv_rows'
export const DESCRIPTION_FLAVOUR = 'columns_description'
export const SUMMARY_FLAVOUR = 'summary'

export const NUMERIC_TYPE = 'numeric'
export const CATEGORICAL_TYPE = 'categorical'

export const graphTypes = {
  TIMESERIES: 'timeseries',
  NUMERIC_HIST: 'numeric-histogram',
  CATEGORICAL_HIST: 'categorical-histogram',
  SCATTER: 'scatterplot',
  BOX: 'boxplot'
}

export const bwMapStyle = [
  {
    featureType: 'all',
    elementType: 'geometry.fill',
    stylers: [
      {
        weight: '2.00'
      }
    ]
  },
  {
    featureType: 'all',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#9c9c9c'
      }
    ]
  },
  {
    featureType: 'all',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'on'
      }
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f2f2f2'
      }
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff'
      }
    ]
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100
      },
      {
        lightness: 45
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#eeeeee'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#7b7b7b'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#ffffff'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified'
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#46bcec'
      },
      {
        visibility: 'on'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#c8d7d4'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#070707'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#ffffff'
      }
    ]
  }
]

export const dailyQuestionnaire = 'daily_questionnaire'
export const dailyQuestionnaireMonday = dailyQuestionnaire + '_maandag'
export const dailyQuestionnaireTuesday = dailyQuestionnaire + '_dinsdag'
export const dailyQuestionnaireWednesday = dailyQuestionnaire + '_woensdag'
export const dailyQuestionnaireThursday = dailyQuestionnaire + '_donderdag'
export const dailyQuestionnaireFriday = dailyQuestionnaire + '_vrijdag'
export const dailyQuestionnaireSaturday = dailyQuestionnaire + '_zaterdag'
export const dailyQuestionnaireSunday = dailyQuestionnaire + '_zondag'
export const sundayQuestionnaire = 'sunday_questionnaire'
export const dailyQuestionnaires = [
  dailyQuestionnaireMonday,
  dailyQuestionnaireTuesday,
  dailyQuestionnaireWednesday,
  dailyQuestionnaireThursday,
  dailyQuestionnaireFriday,
  dailyQuestionnaireSaturday,
  dailyQuestionnaireSunday,
  sundayQuestionnaire
]

export const weeklyWellbeing = 'weekly_wellbeing'
export const trainingLog = 'training_log'
export const complaintsQuestionnaire = 'ostrc'
export const physicalComplaintsQuestionnaire = complaintsQuestionnaire + '_o'
export const physicalAndHealthComplaintsQuestionnaire = complaintsQuestionnaire + '_h_o'
export const COMPLAINTS_QUESTIONAIRES = [physicalComplaintsQuestionnaire, physicalAndHealthComplaintsQuestionnaire]

export const restq = 'restq'
export const squashQuestionnaire = 'squash'
export const psychologicalCompetenciesQuestionnaire = 'psychological_competencies'
export const climbingAccidentQuestionnaire = 'climbing_accident'

export const rheumaDailyQuestionnaire = 'daily_questionnaire_rheumatism'
export const intakeQuestionnaireRheumatism = 'intake_questionnaire_rheumatism'
export const asesRheumatism = 'ases_rheumatism'
export const brafRheumatism = 'braf_rheumatism'
export const eq5d5lRheumatism = 'eq5d5l_rheumatism'
export const hadsRheumatism = 'hads_rheumatism'

export const moveMoodMotivationQuestionnaire = 'move_mood_motivation'

export const URL_LANGUAGE_PARAM = 'lang'

export const languages = {
  NL: 'nl',
  EN: 'en'
}

export const groupTypes = {
  MUTUAL_CONNECTION: 'mutual_connection',
  GROUP: 'group',
  PUBLIC: 'public',
  FOLLOWERS: 'followers',
  ANONYMIZED: 'anonymized'
}

export const membershipStates = {
  PENDING: 'pending',
  ACCEPTED: 'accepted'
}

export const MULTIFILE_SPORTS = ['sports.soccer'] // TODO: add sailing

export const popupKeys = {
  FIRST_CONNECTION: 'first_connection'
}

export const imputation = Object.freeze({
  CONSTANT: 'constant',
  LINEAR_INTERPOLATION: 'linear_interpolation',
  MEAN: 'mean'
})

// start hours of times of the day
export const AFTERNOON_START = 12
export const EVENING_START = 18

export const accessLevel = {
  READ: 'read',
  FULL: 'full',
  OWNER: 'owner'
}

export const accessLevels = [accessLevel.READ, accessLevel.FULL, accessLevel.OWNER]

export const accessLevelI18nKeys = Object.freeze({
  [accessLevel.READ]: `permissions.${accessLevel.READ}`,
  [accessLevel.FULL]: `permissions.${accessLevel.FULL}`
})

export const permissionIconMapping = Object.freeze({
  [accessLevel.READ]: 'eye',
  [accessLevel.FULL]: 'edit'
})

export const permissions = [
  { id: 1, name: accessLevel.READ },
  { id: 2, name: accessLevel.FULL }
].map(p => ({ ...p, icon: permissionIconMapping[p.name] }))

export const QUESTIONNAIRE_ENGINE_TIMEOUT = 600000 // 10 minutes
export const MINIMUM_DAILY_DURATION_TO_SHOW_GAP = 86400 * 7 + 12 * 3600
export const MINIMUM_WEEKLY_DURATION_TO_SHOW_GAP = 86400 * 10
export const DEFAULT_PROTOCOL_DURATION = 30 // days

// Min-max hr zones for graph limits
export const MIN_HR = 0
export const MAX_HR = 250

export const EMPTY_RECORD = '--'

// Coach Dashboard

const TITLE_BASE_COACH_DASHBOARD = 'components.dashboards.coach_individual.home.categories'
export const COACH_DASHBOARD_TITLES = Object.freeze({
  athlete: `${TITLE_BASE_COACH_DASHBOARD}.athlete`,
  health: `${TITLE_BASE_COACH_DASHBOARD}.health`,
  healthStatus: `${TITLE_BASE_COACH_DASHBOARD}.health_status`,
  restingHr: `${TITLE_BASE_COACH_DASHBOARD}.resting_hr`,
  wellbeing: `${TITLE_BASE_COACH_DASHBOARD}.wellbeing`,
  wellbeingScore: `${TITLE_BASE_COACH_DASHBOARD}.total_score`,
  sleepDuration: `${TITLE_BASE_COACH_DASHBOARD}.sleep_duration`,
  sleepQuality: `${TITLE_BASE_COACH_DASHBOARD}.sleep_quality`,
  readiness: `${TITLE_BASE_COACH_DASHBOARD}.readiness`,
  training: `${TITLE_BASE_COACH_DASHBOARD}.training`,
  rpe: `${TITLE_BASE_COACH_DASHBOARD}.rpe`,
  acwr: `${TITLE_BASE_COACH_DASHBOARD}.acwr`,
  satisfaction: `${TITLE_BASE_COACH_DASHBOARD}.satisfaction`,
  stress: `${TITLE_BASE_COACH_DASHBOARD}.stress`,
  mood: `${TITLE_BASE_COACH_DASHBOARD}.mood`,
  fatigue: `${TITLE_BASE_COACH_DASHBOARD}.fatigue`,
  musclePain: `${TITLE_BASE_COACH_DASHBOARD}.muscle_pain`,
  trimp: `${TITLE_BASE_COACH_DASHBOARD}.trimp`,
  speedLoad: `${TITLE_BASE_COACH_DASHBOARD}.speed_load`,
  powerLoad: `${TITLE_BASE_COACH_DASHBOARD}.power_load`,
  srpe: `${TITLE_BASE_COACH_DASHBOARD}.srpe`
})

const COACH_DASHBOARD_TITLE_CLASS = 'category-main'
// const coach_dashboard_title_class = 'category-title-text text-l'
const COACH_DASHBOARD_SUBTITLE_CLASS = 'category-leaf' // TODO Change this to a better name
export const COACH_DASHBOARD_HOME_CATEGORIES = [
  [
    { name: 'athlete', rowSpan: 2, className: COACH_DASHBOARD_TITLE_CLASS },
    { name: 'health', icon: 'stethoscope', colSpan: 2, className: COACH_DASHBOARD_TITLE_CLASS },
    { name: 'wellbeing', icon: 'smile', colSpan: 3, className: COACH_DASHBOARD_TITLE_CLASS },
    { name: 'training', icon: 'running', colSpan: 3, className: COACH_DASHBOARD_TITLE_CLASS }
  ],
  [
    { name: 'healthStatus', className: COACH_DASHBOARD_SUBTITLE_CLASS },
    { name: 'restingHr', className: COACH_DASHBOARD_SUBTITLE_CLASS },
    { name: 'wellbeingScore', className: COACH_DASHBOARD_SUBTITLE_CLASS },
    { name: 'wellbeingParameters', options: ['sleepDuration', 'sleepQuality', 'stress', 'mood', 'fatigue', 'musclePain'], className: COACH_DASHBOARD_SUBTITLE_CLASS },
    { name: 'readiness', className: COACH_DASHBOARD_SUBTITLE_CLASS },
    { name: 'workloadParameters', options: ['rpe', 'srpe', 'trimp', 'speedLoad', 'powerLoad'], className: COACH_DASHBOARD_SUBTITLE_CLASS },
    { name: 'acwr', className: COACH_DASHBOARD_SUBTITLE_CLASS },
    { name: 'satisfaction', className: COACH_DASHBOARD_SUBTITLE_CLASS }
  ]
]

export const COACH_DASHBOARD_TRAINING_CATEGORIES = [
  [
    { name: 'athlete', className: COACH_DASHBOARD_SUBTITLE_CLASS },
    { name: 'healthStatus', className: COACH_DASHBOARD_SUBTITLE_CLASS },
    { name: 'restingHr', className: COACH_DASHBOARD_SUBTITLE_CLASS },
    { name: 'wellbeingScore', className: COACH_DASHBOARD_SUBTITLE_CLASS },
    { name: 'wellbeingParameters', options: ['sleepDuration', 'sleepQuality', 'stress', 'mood', 'fatigue', 'musclePain'], className: COACH_DASHBOARD_SUBTITLE_CLASS },
    { name: 'readiness', className: COACH_DASHBOARD_SUBTITLE_CLASS },
    { name: 'workloadParameters', options: ['rpe', 'srpe', 'trimp', 'speedLoad', 'powerLoad'], className: COACH_DASHBOARD_SUBTITLE_CLASS },
    { name: 'acwr', className: COACH_DASHBOARD_SUBTITLE_CLASS },
    { name: 'satisfaction', className: COACH_DASHBOARD_SUBTITLE_CLASS }
  ]
]

export const COACH_DASHBOARD_MAIN_SUMMARY_FIELDS = [
  'training_type',
  'session_type',
  'duration',
  'rpe',
  'srpe'
]

export const COACH_DASHBOARD_COL1_SUMMARY_FIELDS = [
  'start_date',
  'satisfaction',
  'speed_load',
  'power_load',
  /* TODO Check with coaches if they want these too
  'elevation_gain',
  'elevation_loss'
  */
  'trimp'
]

export const COACH_DASHBOARD_COL2_SUMMARY_FIELDS = [
  'avg_hr',
  'max_hr',
  'avg_speed',
  'max_speed',
  'avg_cadence',
  'max_cadence'
]

export const DEFAULT_HR_ZONES = [0, 110, 132, 154, 176, 198, 220]
export const HR_ZONES_COLORS = {
  from: accentColorLight,
  to: accentColorDark
}

export const BINS_DEFAULT_COLOR = grayChartjs

// Vouchers
export const VOUCHER_CREATION = Object.freeze({
  MIN_DURATION: 1, // Month
  MAX_DURATION: 12, // Month
  REDEMPTION_MIN_LIMIT: 2, // Number of redemptions
  REDEMPTION_MAX_LIMIT: 1000 // Number of redemptions
})

/* Edge case undefined: indicates legacy Terms (v0)
 * Other cases: return identity:  termsVersions[x] === x
 * We must maintain a referenc to all historic versions of the Terms of Service,
 * since the ToS that were applicable at the time a payment was made, are valid
 * for that payment. Therefore, mails containing payment receipts must refer to
 * that specific version of the ToS. */
export const termsVersions = {
  undefined: '',
  latest: process.env.TERMS_AND_CONDITIONS_LATEST,
  '22-03-2021': '22-03-2021'
}

export const loginStages = {
  LOGIN: 0, /* main login form */
  TWOFA: 1, /* two-factor authentication */
  TOS: 2 /* terms of service */
}

// Premium Features
export const FEATURE_UNDER_DEVELOPMENT = 'in_development'

export const trainingTypes = Object.freeze({
  ATHLETICS: 'athletics',
  BADMINTON: 'badminton',
  BASKETBALL: 'basketball',
  BOXING: 'boxing',
  DANCING: 'dancing',
  FITNESS_POWER_TRAINING: 'fitness_power_training',
  GOLF: 'golf',
  GYMNASTICS: 'gymnastics',
  HANDBALL: 'handball',
  RUNNING: 'running',
  HIKING: 'hiking',
  FIELD_HOCKEY: 'field_hockey',
  BASEBALL: 'baseball',
  ICE_HOCKEY: 'ice_hockey',
  INLINE_SKATING: 'inline_skating',
  JUDO: 'judo',
  KARATE: 'karate',
  CLIMBING: 'climbing',
  KORFBALL: 'korfball',
  MOUNTAIN_BIKING: 'mountain_biking',
  HORSE_RIDING: 'horse_riding',
  POWER_LIFTING_BODY_BUILDING: 'power_lifting_body_building',
  ROWING: 'rowing',
  RUGBY: 'rugby',
  ICE_SKATING: 'ice_skating',
  SHORT_TRACK: 'short_track',
  SOFTBALL: 'softball',
  SQUASH: 'squash',
  TENNIS: 'tennis',
  FOOTBALL: 'football',
  VOLLEYBALL: 'volleyball',
  CYCLING: 'cycling',
  SAILING: 'sailing',
  SWIMMING: 'swimming'
} as const)

export const trainingSessionTypes = Object.freeze({
  CIRCUIT: 'circuit',
  EXTENSIVE_ENDURANCE: 'extensive_endurance',
  EXTENSIVE_INTERVAL: 'extensive_interval',
  EXTENSIVE_TEMPO: 'extensive_tempo',
  RECOVERY: 'recovery',
  INTENSIVE_ENDURANCE: 'intensive_endurance',
  INTENSIVE_INTERVAL: 'intensive_interval',
  INTENSIVE_TEMPO: 'intensive_tempo',
  POWER: 'power',
  STRENGTH: 'strength',
  SKILLS: 'skills',
  SPRINT: 'sprint',
  TEAM_TRAINING: 'team_training',
  TECHNIQUE: 'technique',
  TESTING: 'testing',
  FARTLEK: 'fartlek',
  MATCH: 'match',
  MATCH_PREPARATION: 'match_preparation',
  OTHER: 'other'
} as const)

export const trainingWeekTypes = Object.freeze({
  INTENSITY: 'intensity',
  LOAD: 'load'
} as const)

export const MatchSessionTypes: SessionType[] = ['match', 'match_preparation']

export const MAX_SLEEP_DURATION = 12 // h
export const DECIMALS_ROUNDING = 2

export const OSTRC_LETTER_TO_BODY_PART = Object.freeze({
  h: 'head',
  n: 'neck',
  s: 'shoulder',
  u: 'upperArm',
  e: 'elbow',
  r: 'forearm',
  w: 'hand', // or wrist
  c: 'chest',
  o: 'torso',
  d: 'upperBack',
  l: 'lowerBack',
  b: 'pelvis',
  g: 'hip',
  t: 'thigh',
  k: 'knee',
  q: 'lowerLeg',
  a: 'ankle',
  f: 'foot',
  x: 'locationNotSpecified',
  i: 'other'
})

export const OSTRC_NAME_TO_BODY_PART = Object.freeze({
  Hoofd: 'head',
  Nek: 'neck',
  Schouder: 'shoulder',
  Bovenarm: 'upperArm',
  Elleboog: 'elbow',
  Onderarm: 'forearm',
  'Hand/Pols': 'hand', // or wrist
  Borstkas: 'chest',
  Romp: 'torso',
  'Wervelkolom, Thoracaal (hoge rug)': 'upperBack',
  'Wervelkolom, Lumbaal (lage rug)': 'lowerBack',
  'Bekken/Billen': 'pelvis',
  'Heup/Lies': 'hip',
  Bovenbeen: 'thigh',
  Knie: 'knee',
  Onderbeen: 'lowerLeg',
  Enkel: 'ankle',
  Voet: 'foot',
  'Locatie overstijgend/Locatie niet gespecificeerd': 'other'
})

export const OSTRC_INTRO_ANSWERS_MAP = {
  Ja: 'injury',
  'Nee, ik heb volledig deelgenomen zonder klachten aan mijn lichaam': null,
  'Nee, ik heb volledig deelgenomen zonder klachten': null,
  'Ja, ik heb hinder ondervonden door <strong>een blessure / fysieke klachten</strong>': 'injury',
  'Ja, ik heb hinder ondervonden door <strong>een ziekte / gezondheidsklachten</strong>': 'illness',
  'Ja, ik heb hinder ondervonden door <strong>beide</strong>, zowel een blessure / fysieke klachten als een ziekte / gezondheidsklachten': 'injury and illness'
}

export const OSTRC_O_QUESTIONS_MAP = Object.freeze({
  1: 'participationDifficulty',
  2: 'trainingAdjustment',
  3: 'performanceInfluence',
  4: 'painAmount',
  5: 'daysWithoutParticipation',
  6: 'lastTimeReportingInjury',
  7: 'activityThatCausedInjury',
  8: 'typeOfInjury',
  9: 'sharedTheCauseOfInjury',
  '9a': 'causeOfInjury',
  10: 'natureOfInjury',
  11: 'causeExplanation',
  12: 'wasTreated',
  13: 'whoTreated'
})

export const OSTRC_H_QUESTIONS_MAP = Object.freeze({
  1: 'participationDifficulty',
  2: 'trainingAdjustment',
  3: 'performanceInfluence',
  4: 'symptomsIntensity',
  5: 'daysWithoutParticipation',
  6: 'symptoms',
  7: 'wasTreated'
})

export const OSTRC_PARSED_ANSWERS_MAP = Object.freeze({
  participationDifficulty: {
    8: 'full',
    17: 'reduced',
    100: 'none'
  },
  trainingAdjustment: {
    0: 'none',
    8: 'slight',
    17: 'moderate',
    25: 'big'
  },
  performanceInfluence: {
    0: 'none',
    8: 'small',
    17: 'moderate',
    25: 'big'
  },
  painAmount: {
    0: 'none',
    8: 'slight',
    17: 'moderate',
    25: 'severe'
  },
  lastTimeReportingInjury: {
    'first time': 'first_time',
    'less than 4 weeks ago': 'less_than',
    'more than 4 weeks ago': 'more_than'
  },
  symptomsIntensity: {
    0: 'none',
    8: 'slight',
    17: 'moderate',
    25: 'severe'
  }
  // TODO symptoms' : {
})

export const OSTRC_H_ANSWERS_MAP = Object.freeze({
  1: {
    'Volledige deelname, maar met klachten': 8, // full
    'Verminderde deelname door klachten': 17, // reduced
    'Kan niet deelnemen door klachten': 100 // none
  },
  2: {
    'Niet aangepast': 0, // none
    'In geringe mate aangepast': 8, // slight
    'In redelijke mate aangepast': 17, // moderate
    'In grote mate aangepast': 25 // great
  },
  3: {
    'Geen invloed': 0, // none
    'In geringe mate beïnvloed': 8, // small
    'In redelijke mate beïnvloed': 17, // moderate
    'In grote mate beïnvloed': 25 // great
  },
  4: {
    'Geen symptomen of gezondheidsklachten': 0, // none
    'Geringe symptomen of gezondheidsklachten': 8, // minor
    'Redelijke symptomen of gezondheidsklachten': 17, // reasonable
    'Ernstige symptomen of gezondheidsklachten': 25 // intense
  },
  5: {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7
  },
  6: {
    angstig_onrustig: 'anxious',
    buikpijn: 'stomach ache',
    diarree: 'diarrhea',
    flauwvallen: 'passing out',
    gevoel_van_slapte_vermoeidheid: 'weakness/fatigue',
    hoesten: 'cough',
    hoofdpijn: 'headache',
    jeuk_uitslag: 'itchiness/rash',
    koorts: 'fever',
    kortademig_benauwd: 'shortness of breath',
    misselijkheid: 'nausea',
    onregelmatige_pols_palpitaties: 'irregular pulse/palpitations',
    oogklachten: 'eye problems',
    oorklachten: 'ear problems',
    opgezette_lymfeklieren: 'swollen lymph nodes',
    overgeven: 'throwing up',
    pijn_elders: 'pain elsewhere',
    pijn_op_de_borst: 'chest pain',
    prikkelbaar: 'irritable',
    slapeloosheid: 'insomnia',
    stijf_gevoelloos: 'stiffness/numbness',
    teneergeslagen_depressief: 'depressed',
    urinewegproblemen_of_problemen_met_de_geslachtsdelen: 'urinary/genital problems',
    verstopping: 'constipation',
    verstopte_neus_loopneus_niezen: 'buggy/runny nose/sneezing',
    wil_ik_niet_zeggen: 'do not want to say',
    zere_keel: 'sore throat',
    anders_namelijk: 'other'
  },
  7: {
    Ja: true,
    Nee: false
  }
})

export const OSTRC_O_ANSWERS_MAP = Object.freeze({
  1: {
    'Volledige deelname, maar met klachten': 8,
    'Verminderde deelname door klachten': 17,
    'Kan niet deelnemen door klachten': 100
  },
  2: {
    'Geen aanpassing': 0,
    'In geringe mate aangepast': 8,
    'In redelijke mate aangepast': 17,
    'In grote mate aangepast': 25
  },
  3: {
    'Geen invloed': 0,
    'In geringe mate beïnvloed': 8,
    'In redelijke mate beïnvloed': 17,
    'In grote mate beïnvloed': 25
  },
  4: {
    'Geen pijn': 0,
    'Geringe pijn': 8,
    'Redelijke pijn': 17,
    'Ernstige pijn': 25
  },
  5: {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7
  },
  6: {
    Ja: 'first time',
    'Nee, ik heb hetzelfde probleem korter dan vier weken geleden ook gemeld': 'less than 4 weeks ago',
    'Nee, ik heb hetzelfde probleem langer dan vier weken geleden ook gemeld': 'more than 4 weeks ago'
  },
  7: {
    'Sportspecifieke training': 'sport-specific',
    'Kracht & fitness training': 'strength and fitness',
    Wedstrijd: 'contest',
    Onbekend: 'unknown'
    // User might also fill "Other: <variableinput>" here
  },
  8: {
    'Acuut letsel': 'acute',
    Overbelastingsletsel: 'overload'
  },
  // 9 is a true or false
  // 9a is a textbox
  // 10 is a specific code, thus does not need a mapping
  // 11 is a textbox
  12: {
    Ja: true,
    Nee: false
  },
  13: {
    Fysiotherapeut: 'physiotherapist',
    'Manueel therapeut': 'manual therapist',
    Masseur: 'massagist',
    Sportarts: 'sports doctor',
    Huisarts: 'general practitioner',
    Specialist: 'specialist'
  }
})

export const BODY_PARTS = Object.freeze({
  head: 'head',
  neck: 'neck',
  shoulder: 'shoulder',
  upperArm: 'upperArm',
  elbow: 'elbow',
  forearm: 'forearm',
  hand: 'hand',
  chest: 'chest',
  trunk: 'trunk',
  highBack: 'highBack',
  lowerBack: 'lowerBack',
  pelvis: 'pelvis',
  hip: 'hip',
  thigh: 'thigh',
  knee: 'knee',
  lowerLeg: 'lowerLeg',
  ankle: 'ankle',
  foot: 'foot',
  other: 'other'
})

// Source: https://stackoverflow.com/questions/470690/how-to-automatically-generate-n-distinct-colors
export const UNIQUE_COLORS = [
  'rgb(255, 179, 0, 0.75)', // Vivid Yellow
  'rgb(128, 62, 117, 0.75)', // Strong Purple
  'rgb(255, 104, 0, 0.75)', // Vivid Orange
  'rgb(166, 189, 215, 0.75)', // Very Light Blue
  'rgb(193, 0, 32, 0.75)', // Vivid Red
  'rgb(206, 162, 98, 0.75)', // Grayish Yellow
  'rgb(129, 112, 102, 0.75)', // Medium Gray

  // The following will not be good for people with defective color vision
  'rgb(0, 125, 52, 0.75)', // Vivid Green
  'rgb(246, 118, 142, 0.75)', // Strong Purplish Pink
  'rgb(0, 83, 138, 0.75)', // Strong Blue
  'rgb(255, 122, 92, 0.75)', // Strong Yellowish Pink
  'rgb(83, 55, 122, 0.75)', // Strong Violet
  'rgb(255, 142, 0, 0.75)', // Vivid Orange Yellow
  'rgb(179, 40, 81, 0.75)', // Strong Purplish Red
  'rgb(244, 200, 0, 0.75)', // Vivid Greenish Yellow
  'rgb(127, 24, 13, 0.75)', // Strong Reddish Brown
  'rgb(147, 170, 0, 0.75)', // Vivid Yellowish Green
  'rgb(89, 51, 21, 0.75)', // Deep Yellowish Brown
  'rgb(241, 58, 19, 0.75)', // Vivid Reddish Orange
  'rgb(35, 44, 22, 0.75)' // Dark Olive Green
]

export const BODY_TO_COLOR = Object.freeze({
  head: UNIQUE_COLORS[0],
  neck: UNIQUE_COLORS[1],
  shoulder: UNIQUE_COLORS[2],
  upperArm: UNIQUE_COLORS[3],
  elbow: UNIQUE_COLORS[4],
  forearm: UNIQUE_COLORS[5],
  // Skipped medium gray, so we can assign it to other/location not specified
  hand: UNIQUE_COLORS[7],
  illness: UNIQUE_COLORS[8],
  chest: UNIQUE_COLORS[9],
  trunk: UNIQUE_COLORS[10],
  highBack: UNIQUE_COLORS[11],
  lowerBack: UNIQUE_COLORS[12],
  pelvis: UNIQUE_COLORS[13],
  hip: UNIQUE_COLORS[14],
  thigh: UNIQUE_COLORS[15],
  knee: UNIQUE_COLORS[16],
  lowerLeg: UNIQUE_COLORS[17],
  ankle: UNIQUE_COLORS[18],
  foot: UNIQUE_COLORS[19],
  locationNotSpecified: UNIQUE_COLORS[6], // Medium Gray
  other: UNIQUE_COLORS[6] // Medium Gray
})

export const RESTQ_ANSWERS_MAP = Object.freeze({
  Nooit: 0,
  Zelden: 1,
  Soms: 2,
  Regelmatig: 3,
  Vaak: 4,
  'Heel vaak': 5,
  Altijd: 6
})

export const LIKERT_SCALE_MAX = 6
export const LIKERT_SCALE_MIN = 0

export const DASHBOARD_NAMES = {
  COACH: 'components.dashboards.coach_individual.name',
  CSV: 'sports.generic_csv',
  QUESTIONNAIRE_FULL: 'components.dashboards.questionnaire.sub_dashboards.full',
  QUESTIONNAIRE_FULL_OPT: 'components.dashboards.questionnaire.sub_dashboards.full_opt',
  QUESTIONNAIRE_MULTI_ATHLETE: 'components.dashboards.questionnaire.sub_dashboards.multi_athlete',
  RESEARCH_TOOL: 'analysis.dashboard.research_tools'
}

export const MYLAPS_THIALF_KEY = 'myLapsThialf'

export const maxNoDigitsGroupId: number = 20 // Arbitrary long number, that avoids ReDos
export const groupTagRegexExpression: RegExp = new RegExp(`^g-\\d{1,${maxNoDigitsGroupId}}$`)

export const dataRowSources = Object.freeze({
  GARMIN: 'tags.garmin',
  POLAR: 'tags.polar',
  FITBIT: 'tags.fitbit'
})

export const STATIC_TAGS = Object.freeze([
  collectionTypes.STRAVA,
  dataRowSources.GARMIN,
  dataRowSources.POLAR,
  dataRowSources.FITBIT
])
