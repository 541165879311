import React from 'react'
import ReactTooltip from 'react-tooltip'
import classNames from 'classnames'

export default class MyReactTooltip extends React.Component {
  render () {
    // Rebuilding the tooltips needs to be done after rendering takes place,
    // hence the delay. I've looked into the source code and the project
    // page a little bit, but couldn't quickly find a better way to do this.
    // If we don't call a rebuild that is some time after rendering the page,
    // the tooltips simply don't show up.
    setTimeout(() => { ReactTooltip.rebuild() }, 200)
    // Allow passing through of properties (e.g., `effect`)
    const { className, ...theRest } = this.props
    return <ReactTooltip className={classNames('fixed-width-tooltip', className)} {...theRest} />
  }
}
