import { WithSession } from './SessionProvider'
import { withCookies } from 'react-cookie'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import LoginWrapper from '../login_wrapper/LoginWrapper'

class Session extends React.Component {
  wrapperClassnames (isLoggedIn) {
    return isLoggedIn ? '' : 'logout'
  }

  render () {
    const isLoggedIn = this.props.session.isValid()
    const loggedOutClass = this.wrapperClassnames(isLoggedIn)
    const bodyContainer = document.querySelectorAll('body')[0]
    bodyContainer.className = loggedOutClass

    const loginWrapper = <LoginWrapper cookies={this.props.cookies} isLoggedIn={isLoggedIn} location={this.props.location} loggedOutClass={this.loggedOutClass} />

    if (!process.env.LOGIN_REDIRECT_URL || isLoggedIn) {
      return loginWrapper
    }

    return (
      <Switch>
        <Route
          exact path='/'
          component={() => {
            window.location.href = process.env.LOGIN_REDIRECT_URL
            return null
          }}
        />

        <Route path='' render={() => loginWrapper} />
      </Switch>
    )
  }
}

export default WithSession(withCookies(Session))
