import React, { FC, ReactElement } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface DeleteButtonProps {
  /*
   * What should happen when the button is clicked
   */
  onClick: () => void
  children?: React.ReactNode
  isDisabled?: boolean
  showIcon?: boolean
}

const DeleteButton: FC<DeleteButtonProps> = ({ children, onClick, showIcon, isDisabled }): ReactElement => {
  return (
    <button
      className={
        classNames('button-autowidth waves-effect text-m waves-light button-accent text-background-color background-accent text-medium inline-block nowrap',
          isDisabled !== undefined && isDisabled && 'disabled-button'
        )
      }
      onClick={onClick}
    >
      {showIcon !== undefined && showIcon && <FontAwesomeIcon icon='trash' className='button-icon' />}
      {children}
    </button>
  )
}
DeleteButton.defaultProps = {
  isDisabled: false,
  showIcon: true
}
export default DeleteButton
