import React from 'react'
import SdvRouter from './Router'
import './IconLibrary'
import Appsignal from '@appsignal/javascript'
import FallbackComponent from './FallbackComponent'
import { ErrorBoundary } from '@appsignal/react'
import { LoadScript } from '@react-google-maps/api'
import ReactGA from 'react-ga'
import history from './common/History'
import Modal from 'react-modal'
import { I18nLoader } from './I18nLoader.jsx'
import { Chart } from 'react-chartjs-2'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { gAEnabled } from './common/Utils'
import { QueryClient, QueryClientProvider } from 'react-query'

// noinspection JSUnusedGlobalSymbols
export default class App extends React.Component {
  constructor (props) {
    super(props)
    I18nLoader.load()

    if (gAEnabled()) {
      // Setup analytics
      ReactGA.initialize(process.env.GOOGLE_ANALYTICS_TOKEN)
      // Trigger initial load
      this.logLocation()
      // Listen for changes in pages (and everytime log to analytics)
      history.listen(this.logLocation)
    }

    let appsignalKey = ''
    let currentRevision = ''
    if (process.env.APPSIGNAL_ACTIVE === 'true') {
      if (process.env.APPSIGNAL_FRONTEND_KEY !== undefined) {
        appsignalKey = process.env.APPSIGNAL_FRONTEND_KEY
      }

      if (process.env.REVISION !== undefined) {
        currentRevision = process.env.REVISION
      }
    }

    this.appsignal = new Appsignal({
      key: appsignalKey,
      revision: currentRevision
    })

    this.queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 60000, // Don't update the cache if it is less than 1 minute old
          refetchOnMount: false, // When new queries mount, use the existing cache if not stale. Do not send a request
          refetchOnWindowFocus: false
        }
      }
    })

    Chart.defaults.global.plugins.crosshair = false

    // Register moment-duration-format plugin
    momentDurationFormatSetup(moment)

    this.RegisterServiceWorker()
  }

  componentDidMount () {
    Modal.setAppElement('#app')
  }

  logLocation () {
    if (gAEnabled()) {
      ReactGA.set({ page: window.location.pathname, anonymizeIp: true })
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  }

  RegisterServiceWorker () {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
      })
    }
  }

  render () {
    return (
      <ErrorBoundary instance={this.appsignal} fallback={(error) => <FallbackComponent error={error} />}>
        <QueryClientProvider client={this.queryClient}>
          <div className='app' id='app'>
            <LoadScript
              id='script-loader'
              googleMapsApiKey={process.env.GOOGLE_MAPS_API_KEY}
              loadingElement={React.Fragment}
              libraries={['visualization']}
            >
              <SdvRouter history={history} />
            </LoadScript>
          </div>
        </QueryClientProvider>
      </ErrorBoundary>
    )
  }
}
