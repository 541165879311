import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import PlayerDropdown from './PlayerDropdown'
import Heatmap from '../../../common/Heatmap'
import { getHeatmapData } from '../../../common/SoccerUtils'
import useDeepCompareEffect from 'use-deep-compare-effect'

const SoccerHeatmap = (props) => {
  const ids = {
    dropdown: 'heatmap-player-dropdown'
  }
  const [data, setData] = useState([])

  const { players, positions, teams, datasetId } = props

  // Returns a playerId
  function getPlayerFromDropdown () {
    const e = document.getElementById(ids.dropdown)
    if (e.selectedIndex === -1) {
      return null
    }
    return parseInt(e.options[e.selectedIndex].value)
  }

  function loadData (positions) {
    const playerId = getPlayerFromDropdown()
    if (playerId == null || isNaN(playerId) || !Object.keys(positions).length) {
      setData([])
    } else {
      const heatmapData = getHeatmapData(positions[playerId])
      setData(heatmapData)
    }
  }

  function handlePlayerChange () {
    loadData(positions)
  }

  function afterUpdateReset () {
    const e = document.getElementById(ids.dropdown)
    e.selectedIndex = 0
    loadData(positions)
  }

  useEffect(() => {
    afterUpdateReset()
  }, [datasetId])

  useDeepCompareEffect(() => {
    afterUpdateReset()
  }, [players, teams])

  const teamNames = teams.map(team => { return team.name })
  return (
    <div>
      <div className='row'>
        <div className='col s12'>
          <PlayerDropdown
            id={ids.dropdown}
            datasetId={datasetId}
            players={players}
            onChange={handlePlayerChange.bind(this)}
            defaultValue='0'
            teamNames={teamNames}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <Heatmap id='soccer-heatmap' data={data} />
        </div>
      </div>
    </div>
  )
}
export default SoccerHeatmap

SoccerHeatmap.propTypes = {
  players: PropTypes.array,
  positions: PropTypes.array,
  teams: PropTypes.array,
  datasetId: PropTypes.number
}

SoccerHeatmap.defaultProps = {
  players: [],
  positions: [],
  teams: []
}
