import React, { useState, useEffect } from 'react'
import MyReactTooltip from '../../../MyReactTooltip'
import _ from 'lodash'
import CalendarHeatmap from 'react-calendar-heatmap'
import 'react-calendar-heatmap/dist/styles.css'
import I18n from 'i18n'
import moment from 'moment'
import { dbDateFormat, momentFullDateFormat } from '../../../common/Constants'

// Supports tooltips, as well as clicking a square. See react-calendar-heatmap docs.
const Heatmap = (props) => {
  const getStartDate = () => {
    const newDate = new Date(props.endDate)
    if (window.innerWidth > 992) {
      newDate.setDate(newDate.getDate() - 365) // about a year
    } else if (window.innerWidth > 600) {
      newDate.setDate(newDate.getDate() - 183) // about six months
    } else {
      newDate.setDate(newDate.getDate() - 91) // about three months
    }
    return newDate
  }

  const [startDate, setStartDate] = useState(getStartDate)

  const normalizeDates = (dates) => {
    // Note: We have to use this DB-like format with the year first because otherwise
    //       it doesn't know how to parse it.
    const bins = _.countBy(_.map(dates, (d) => moment(d).format(dbDateFormat)))
    const mapped = _(bins).map(b => b)
    const min = mapped.min()
    const max = mapped.max()
    // When the minimum and maximum for each date that has events are the same, we cannot normalize the values.
    // (our calculation would divide by zero as it is dividing by (max - min).
    // In this case we use the raw dataset count to color the cells.
    if (min === max) {
      let normalizedCount = 1
      if (min < 1) {
        normalizedCount = 0
      } else if (min >= 4) {
        normalizedCount = 4
      } else {
        normalizedCount = min
      }
      return _.map(bins, (v, k) => ({ date: k, count: v, normalizedCount: normalizedCount }))
    }
    // We use Math.round instead of Math.floor so the darkest color is used more frequently
    const normalizedCount = (v) => Math.round((((v - min) / (max - min)) * 3) + 1)
    return _.map(bins, (v, k) => ({ date: k, count: v, normalizedCount: normalizedCount(v) }))
  }

  useEffect(() => {
    const handleResize = () => setStartDate(getStartDate)
    // Add listener when component is created
    window.addEventListener('resize', handleResize)

    // Cleanup when component destroyed
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    setStartDate(getStartDate)
  }, [props.endDate])

  const getTooltipDataAttrs = (value) => {
    // value.date and value.count are sometimes null
    if (!value || !value.date) {
      return null
    }
    // Configuration for react-tooltip
    return {
      'data-tip': `<strong>${moment(value.date).format(momentFullDateFormat)}</strong><br />
                   ${I18n.t('components.data.overview.heatmap.number_of_data_sets')}: ${value.count}`,
      'data-for': 'day-tip'
    }
  }

  // Must be left in, otherwise CalendarHeatmap will crash.
  const onClick = (_value) => {
  }

  const legend = () => (
    <div className='react-calendar-heatmap legend valign-wrapper'>
      <div className='color-empty legend-item' />
      <div className='color-scale-1 legend-item' />
      <div className='color-scale-2 legend-item' />
      <div className='color-scale-3 legend-item' />
      <div className='color-scale-4 legend-item' />
      <p style={{ marginLeft: '0.5rem' }} className='text-m'>{I18n.t('components.data.overview.heatmap.number_of_data_sets')}</p>
    </div>
  )

  return (
    <div className='background-background data-overview-card'>
      <div className='row'>
        <div
          className='text-heavy text-primary-color text-xl col s12'
        >{I18n.t('components.data.overview.heatmap.calendar')}
        </div>
        <div className='col s12'>
          <CalendarHeatmap
            startDate={startDate}
            endDate={props.endDate}
            values={normalizeDates(props.dates)}
            onClick={onClick}
            classForValue={(value) => {
              if (!value) {
                return 'color-empty'
              }
              return `color-scale-${value.normalizedCount}`
            }}
            tooltipDataAttrs={getTooltipDataAttrs}
          />
        </div>
      </div>
      <MyReactTooltip id='day-tip' className='high-z' html />
      <div className='row'>
        {legend()}
      </div>
    </div>
  )
}

export default Heatmap
