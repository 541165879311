import React from 'react'
import I18n from 'i18n'
import { HorizontalBar } from 'react-chartjs-2'
import 'chartjs-chart-box-and-violin-plot/build/Chart.BoxPlot.js'
import { calcErrorBar, calcAverage } from '../ActivityUtils'
import { chartjsRestHeartRateBorderColor, chartjsRestHeartRateColor } from '../../../../common/Constants'
import { uniq } from 'lodash'

const BoxPlotBar = props => {
  const lowerBar = calcErrorBar(0, props.datasets[props.parameter])
  const upperBar = calcErrorBar(1, props.datasets[props.parameter])
  const averageBar = calcAverage(props.datasets[props.parameter])
  const filteredData = props.rawData.filter(dayDatum => dayDatum.parameter === props.parameter).map(dayDatum => dayDatum.value)

  const data = {
    labels: [props.title],
    datasets: [{
      label: props.label,
      type: 'horizontalBoxplot',
      backgroundColor: chartjsRestHeartRateColor,
      borderColor: chartjsRestHeartRateBorderColor,
      borderWidth: 1,
      padding: 0,
      itemRadius: 0,
      data: [filteredData]
    }]
  }
  const options = {
    tooltips: {
      enabled: false
    },
    scales: {
      xAxes: [{
        display: true,
        ticks: {
          beginAtZero: false, // draw up to up to 4 standard deviations away from the boxplot
          suggestedMin: averageBar - 4 * (averageBar - lowerBar),
          suggestedMax: averageBar + 4 * (upperBar - averageBar)
        }
      }],
      yAxes: [{
        display: false
      }]
    },
    legend: {
      display: false
    }
  }

  const notEnoughData = (uniq(filteredData).length < 2)

  return (
    <div className='row'>
      <div className='col s1 horizontal-bar-icon'>
        {props.children}
      </div>
      <div className='col s11 contains-horizontal-boxplot'>
        {notEnoughData && <em className='insufficient-data'>{I18n.t('components.dashboards.steps.summary.not_enough_data')}</em>}
        {!notEnoughData && <HorizontalBar height={15} width={100} data={data} options={options} redraw />}
      </div>
    </div>
  )
}

export default BoxPlotBar
