import React from 'react'
import I18n from 'i18n'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const GenericPlaceholder = (props) => {
  // defaultprops don't work here because they are evaluated before the locale is loaded.
  const { title = I18n.t('components.placeholders.default_title'), message = I18n.t('components.placeholders.default_message') } = props

  return (
    <div className='timeline-card timeline-placeholder'>
      <div className='text-l text-heavy text-primary-color timeline-title center-align'>
        <div className='bigger-icon center-align'>
          <FontAwesomeIcon icon='info-circle' />
        </div>
        <p>{title}</p>
        <p className='text-muted text-m text-medium'>{message}</p>
      </div>
    </div>
  )
}

GenericPlaceholder.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string
}

export default GenericPlaceholder
