import React, { FC, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { hcOrange, hcBlack, hcGreen, hcBlue } from 'components/common/Constants'
import I18n from 'i18n'
import { initializeHighcharts, titleWithTooltip } from 'components/common/Utils'
import GenericPlaceholder from 'components/common/GenericPlaceholder'
import { calculateWeeklyAverages } from '../Utils'
import { DailyLogRow } from 'components/common/types/questionnaires/DailyLogRow'
import _ from 'lodash'

interface DailySleepProps {
  dailyLogRows?: DailyLogRow[]
}

const DailySleepBar: FC<DailySleepProps> = ({ dailyLogRows }) => {
  const minimumDataPointsForAverage = 21

  useEffect(() => {
    initializeHighcharts(true)
  }, [])

  if (_.isEmpty(dailyLogRows) || !Array.isArray(dailyLogRows)) {
    return <GenericPlaceholder title={I18n.t('components.dashboards.questionnaire.daily_sleep.title')} />
  }

  const sleepDurationData = dailyLogRows.map(entry => ({
    x: entry.date_only,
    y: entry.sleep_duration_num,
    formatted_date: entry.formatted_date,
    sleep_duration: entry.sleep_duration
  }))

  const averageWeeklySleepDurationData = calculateWeeklyAverages(sleepDurationData)

  let sleepQualityData = dailyLogRows.map(entry => {
    return {
      x: entry.date_only,
      y: entry.wellness_sleep,
      formatted_date: entry.formatted_date
    }
  })

  if (dailyLogRows?.length > minimumDataPointsForAverage) {
    sleepQualityData = calculateWeeklyAverages(sleepQualityData)
  }

  const xAxisOptions = {
    title: {
      text: ''
    },
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%d %b'
    },
    minPadding: 0.05,
    maxPadding: 0.05
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        title: titleWithTooltip(
          I18n.t('components.dashboards.questionnaire.daily_sleep.title'),
          I18n.t('components.dashboards.questionnaire.daily_sleep.info')
        ),
        subtitle: {
          text: I18n.t('components.dashboards.questionnaire.daily_sleep.subtitle')
        },
        plotOptions: {
          scatter: {
            color: hcOrange
          },
          series: {
            maxPointWidth: 20
          },
          line: {
            color: hcBlack,
            marker: {
              enabled: false
            }
          }
        },
        series: [
          {
            type: 'column',
            data: sleepDurationData,
            name: '⬤',
            pointWidth: 20,
            color: hcBlue,
            tooltip: {
              headerFormat: '',
              pointFormat: I18n.t('components.dashboards.questionnaire.daily_sleep.scatter.tooltip')
            }
          },
          {
            type: 'line',
            data: averageWeeklySleepDurationData,
            lineWidth: 2,
            tooltip: {
              headerFormat: '',
              pointFormat: I18n.t('components.dashboards.questionnaire.daily_sleep.line.tooltip')
            }
          },
          {
            type: 'line',
            data: sleepQualityData,
            name: I18n.t('components.dashboards.questionnaire.daily_sleep.average_sleep_quality_line.name'),
            color: hcGreen,
            yAxis: 1, // Assign the sleep quality line series to the second y-axis
            lineWidth: 2,
            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: true
                }
              }
            },
            tooltip: {
              headerFormat: '',
              pointFormat: I18n.t('components.dashboards.questionnaire.daily_sleep.average_sleep_quality_line.tooltip')
            }
          }
        ],
        yAxis: [
          {
            title: {
              text: I18n.t('components.dashboards.questionnaire.daily_sleep.yaxis'),
              style: {
                color: hcBlue // This changes the color of the first y-axis title to match the first line
              }
            },
            labels: {
              style: {
                color: hcBlue // This changes the color of the first y-axis labels to match the first line
              }
            },
            min: 0,
            opposite: false
          },
          {
            title: {
              text: I18n.t('components.dashboards.questionnaire.daily_sleep.average_sleep_quality_line.yaxis_right'),
              style: {
                color: hcGreen
              }
            },
            labels: {
              style: {
                color: hcGreen
              }
            },
            min: 0,
            max: 6,
            opposite: true
          }
        ],
        xAxis: xAxisOptions
      }}
    />
  )
}

export default DailySleepBar
