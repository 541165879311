import React, { useState } from 'react'
import moment from 'moment'
import { QUESTIONNAIRE_PERIOD } from 'components/common/Constants'

// This component was created to prevent repeated code for the normal and full dashboards.
const DashboardStateWrapper = (Component) => {
  return (props) => {
    // This last selected athlete ID is part of a workaround, necessary for making sure that the compare tab multi athlete
    // picker has an athlete to display. This is because the selectedProfile prop shared between the tabs is unset when
    // a group is selected on the physical complaints tab. This variable ensures that a 'backup ID' is always available.
    const [lastSelectedAthleteId, setLastSelectedAthleteId] = useState()

    const today = new Date()
    const twoMonthsAgo = moment().subtract(2, 'months').startOf(QUESTIONNAIRE_PERIOD)
    // Min/Max date have to be Date objects because of the date-pickers
    const [minDate, setMinDate] = useState(twoMonthsAgo.startOf(QUESTIONNAIRE_PERIOD))
    const [maxDate, setMaxDate] = useState(moment(today).endOf(QUESTIONNAIRE_PERIOD))

    const [dateRange, setDateRange] = useState([
      minDate.clone().startOf(QUESTIONNAIRE_PERIOD),
      maxDate.clone().endOf(QUESTIONNAIRE_PERIOD)
    ])

    const [sliderStartDate, sliderEndDate] = dateRange

    //  Sets the date range smartly
    //  The startDate date is converted to that week's starting date (Monday at 12 am)
    //  The endDate date is converted to that week's ending date (Sunday at 11:59 pm)
    //  E.g. [3rd day, 10th day] -> [1st day at 12 am, 14th day at 11:59 pm]
    const smartSetDateRange = (startDate, endDate) => {
      const smartStartDate = startDate.clone().startOf(QUESTIONNAIRE_PERIOD)
      const smartEndDate = endDate.clone().endOf(QUESTIONNAIRE_PERIOD)
      setDateRange([smartStartDate, smartEndDate])
    }

    // Using the pivot as reference, converts the date difference given by slider
    // into the corresponding moment.
    const weekDifferenceToMoment = (dateDifference, sliderPivot) => {
      return sliderPivot.clone().add(dateDifference, 'week')
    }

    // Reads slider startDate/endDate state and filters list of data_rows based on these dates
    const filterDataRowsBySlider = (data, dateProp) => {
      const startDateMoment = sliderStartDate !== null ? moment(sliderStartDate) : null
      const endDateMoment = sliderEndDate !== null ? moment(sliderEndDate) : null

      return data.filter(dataRows => {
        return (sliderStartDate === null || moment(dataRows[dateProp]) >= startDateMoment) &&
          (sliderEndDate === null || moment(dataRows[dateProp]) <= endDateMoment)
      })
    }

    const hocProps = {
      lastSelectedAthleteId,
      setLastSelectedAthleteId,
      minDate,
      setMinDate,
      maxDate,
      setMaxDate,
      sliderStartDate,
      sliderEndDate,
      setDateRange,
      smartSetDateRange,
      weekDifferenceToMoment,
      filterDataRowsBySlider
    }
    return (
      <Component {...props} {...hocProps} />
    )
  }
}

export default DashboardStateWrapper
