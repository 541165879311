import React from 'react'
import { Link } from 'react-router-dom'
import I18n from 'i18n'
import { WithSession } from '../session/SessionProvider'
import { WithQuestionnaires } from '../questionnaires/QuestionnaireProvider'
import { WithModal } from '../modal/ModalProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SdvLogo from '../images/sdv-logo.svg'
import Uploader from '../data/layout/Uploader'
import Notifications from '../notifications/Notifications'
import { MyAxios as axios } from '../MyAxios'
import ProfileCompleteness from '../profile/ProfileCompleteness'
import { subscriptionTypes } from '../common/Constants'
import _ from 'lodash'
import MyReactTooltip from '../MyReactTooltip'
import Routes from '../common/Routes'

class Navigation extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hamburgerDeployed: false, showNotifications: false, notifications: [] }

    this.handleUploadModal = this.handleUploadModal.bind(this)
    this.nextFetch = () => { }
  }

  // TODO: this is copy paste from TimelineController
  handleRequest (body, method, endpoint, formName, sessionToken, handleSuccess) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: this.props.sessionToken
    }
    axios({ method, url: endpoint, headers: headers, data: body }).then((response) => {
      handleSuccess(response)
    }).catch((err) => {
      console.log(err)
    })
  }

  isTabSelected (tabPath) {
    const re = RegExp((tabPath === Routes.root && `^${Routes.root}$|^${Routes.timeline.index}*`) || '^' + tabPath)
    return (re.exec(this.props.location.pathname) && 'selected') || ''
  }

  toggleHamburger () {
    this.setState({ ...this.state, hamburgerDeployed: !this.state.hamburgerDeployed })
  }

  isDeployed () {
    return (this.state.hamburgerDeployed && 'deployed') || ''
  }

  handleUploadModal () {
    this.props.setModalComponent(Uploader, { onRequest: this.handleRequest.bind(this), submitMethod: 'POST', endpoint: '/api/v1/data' })
  }

  closeNotificationMenu () {
    this.setState({ ...this.state, showNotifications: false, hamburgerDeployed: false })
  }

  componentWillUnmount () {
    clearTimeout(this.nextFetch)
  }

  setNotifications (notifications) {
    this.setState({ notifications: notifications })
  }

  renderProfileLink () {
    const profileImage = (
      <img
        data-tip={I18n.t('navigation.profile')}
        data-for='higher-z-tooltip'
        className='nav-button-img circle responsive-img'
        src={this.props.myProfile?.picture || '/images/pfp-placeholder.png'}
        alt={I18n.t('navigation.profile')}
      />
    )
    if (this.props.myProfile?.slug && this.props.myProfile.slug.length > 0) {
      return (
        <Link to={Routes.profiles.showFn(this.props.myProfile.slug)}>
          {[subscriptionTypes.coach, subscriptionTypes.researcher].includes(_.get(this.props, 'myProfile.subscription.type', subscriptionTypes.free)) && (
            <div
              className='absolute-top-left-icon accent-color'
              data-place='bottom'
              data-tip={I18n.t('premium.types.' + _.get(this.props, 'myProfile.subscription.type'))}
              data-for='higher-z-tooltip'
            >
              <FontAwesomeIcon icon='star' />
            </div>
          )}
          {profileImage}
        </Link>
      )
    }
    return profileImage
  }

  render () {
    if (!this.props.isLoggedIn) {
      return (<></>)
    }

    const showBell = false
    return (
      <>
        <div className='nav-bar z-top'>
          <div className='container'>
            <div className='row'>
              <div className='hide-on-med-and-down'>
                <a className='sdv-branding col l5' href={Routes.root}>
                  <img className='sdv-logo' src={SdvLogo} />
                  <p className='sdv-title'>Sport <b>Data</b> Valley</p>
                </a>
                <div className='col l5 xl6 top-menu-pages'>
                  <div className='row'>
                    {/* routes that require login */}
                    <Link
                      id='timeline-nav-item'
                      className={`col s2 nav-item ${this.isTabSelected(Routes.root)}`}
                      to={Routes.root}
                    >{I18n.t('navigation.timeline')}
                    </Link>
                    <Link
                      className={`col s2 nav-item ${this.isTabSelected(Routes.data.index)}`}
                      to={Routes.data.index}
                    >{I18n.t('navigation.data_overview')}
                    </Link>
                    <Link
                      className={`col s2 nav-item ${this.isTabSelected(Routes.sharing.index)}`}
                      to={Routes.sharing.index}
                    >{I18n.t('navigation.sharing')}
                    </Link>
                    <Link
                      id='network-nav-item'
                      className={`col s3 xl2 nav-item ${this.isTabSelected(Routes.network.index)}`}
                      onClick={() => this.setState({ ...this.state, selectedTab: 'network' })}
                      to={Routes.network.index}
                    >{I18n.t('navigation.network')}
                    </Link>
                    <Link
                      id='analyse-nav-item'
                      className={`col s3 xl2 nav-item ${this.isTabSelected(Routes.analysis.index)}`}
                      to={Routes.analysis.index}
                    >{I18n.t('navigation.snapshots')}
                    </Link>
                  </div>
                </div>
                <div className='nav-buttons-wrapper valign-wrapper col l2'>
                  <Link id='log-out' className='nav-button' to={Routes.root} onClick={() => this.props.handleLogout()}> <FontAwesomeIcon icon='sign-out-alt' data-tip={I18n.t('navigation.signout')} data-for='higher-z-tooltip' /></Link>
                  {this.props.myProfile?.admin && <Link data-cy='admin-link' className={`nav-button ${this.isTabSelected(Routes.admin.index)}`} to={Routes.admin.index}><FontAwesomeIcon icon='user-cog' data-tip={I18n.t('navigation.admin_page')} data-for='higher-z-tooltip' /></Link>}
                  <span className='nav-button' onClick={this.handleUploadModal}> <FontAwesomeIcon icon='plus' data-tip={I18n.t('navigation.add_data')} data-for='higher-z-tooltip' /> </span>
                  <span className='nav-button notifications-nav-button'><Notifications setNotifications={this.setNotifications.bind(this)} questionnaires={this.props.questionnaires} closeNotificationMenu={this.closeNotificationMenu.bind(this)} /></span>
                  <span className='relative' id='profile-picture-nav-item'>{this.renderProfileLink()}</span>
                </div>
              </div>
              <div className='hide-on-large-only'>
                <a className='sdv-branding col s8 offset-s2' href={Routes.root}>
                  <img className='sdv-logo z-top' src={SdvLogo} />
                  <p className='sdv-title z-top hide-on-small-only'>Sport <b>Data</b> Valley</p>
                </a>
                <div className='col s2'>
                  <div className={`hamburger pointer ${this.isDeployed()}`} onClick={() => this.toggleHamburger()}>
                    <span />
                    <span />
                    {showBell && <div className='hamburger-badge' />}
                    <span />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.isDeployed() && (
          <div className={`vertical-nav high-z row hide-on-large-only ${this.isDeployed()}`}>
            <Link
              className={`col s12 nav-item ${this.isTabSelected(Routes.root)}`}
              onClick={() => this.toggleHamburger()}
              to={Routes.root}
            >{I18n.t('navigation.timeline')}
            </Link>
            <Link
              className={`col s12 nav-item ${this.isTabSelected(Routes.data.index)}`}
              onClick={() => this.toggleHamburger()}
              to={Routes.data.index}
            >{I18n.t('navigation.data_overview')}
            </Link>
            <Link
              className={`col s12 nav-item ${this.isTabSelected(Routes.sharing.index)}`}
              onClick={() => this.toggleHamburger()}
              to={Routes.sharing.index}
            >{I18n.t('navigation.sharing')}
            </Link>
            <Link
              className={`col s12 nav-item ${this.isTabSelected(Routes.network.index)}`}
              onClick={() => this.toggleHamburger()}
              to={Routes.network.index}
            >{I18n.t('navigation.network')}
            </Link>
            <Link
              className={`col s12 nav-item ${this.isTabSelected(Routes.analysis.index)}`}
              onClick={() => this.toggleHamburger()}
              to={Routes.analysis.index}
            >{I18n.t('navigation.snapshots')}
            </Link>
            <div className='col s12 nav-item'>
              <div className='nav-buttons-wrapper'>
                <Link className='nav-button' to={Routes.root} onClick={() => { this.toggleHamburger(); this.props.handleLogout() }}> <FontAwesomeIcon icon='sign-out-alt' /></Link>
                {this.props.myProfile?.admin && <Link className='nav-button' to={Routes.admin.index} onClick={() => this.toggleHamburger()}><FontAwesomeIcon icon='user-cog' /></Link>}
                <span className='nav-button' onClick={() => { this.toggleHamburger(); this.handleUploadModal() }}> <FontAwesomeIcon icon='plus' /> </span>
                <Link className='nav-button' id='profile-picture' to={Routes.profiles.showFn(this.props.myProfile?.slug)} onClick={() => this.toggleHamburger()}> <FontAwesomeIcon icon='user' /></Link>
                <div className='nav-button notifications-nav-button'><Notifications onMobile questionnaires={this.props.questionnaires} setNotifications={this.setNotifications.bind(this)} closeNotificationMenu={this.closeNotificationMenu.bind(this)} /></div>
              </div>
            </div>

          </div>)}
        <ProfileCompleteness />
        <MyReactTooltip id='navbar' />
      </>
    )
  }
}

export default WithQuestionnaires(WithSession(WithModal(Navigation)))
