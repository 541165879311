import React, { FC, ReactElement } from 'react'
import classNames from 'classnames'

export interface ChartContainerStyle {
  fixed: boolean
  mapHeight: boolean
}

export interface ChartContainerProps {
  children: React.ReactNode
  size: 's12' | 's6'
  componentStyle?: ChartContainerStyle
}

const ChartContainer: FC<ChartContainerProps> = ({ children, componentStyle, size }): ReactElement => {
  let containerClass = 'chart-container'

  if (componentStyle !== undefined) {
    containerClass = classNames(containerClass, componentStyle.fixed && 'fixed-height')
    containerClass = classNames(containerClass, componentStyle.mapHeight && 'map-height')
  }

  return (
    <div className='row'>
      <div className={`col ${size}`}>
        <div className={containerClass}>
          {children}
        </div>
      </div>
    </div>
  )
}
ChartContainer.defaultProps = {
  componentStyle: {
    fixed: false,
    mapHeight: false
  }
}
export default ChartContainer
