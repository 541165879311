import React from 'react'
import { dataObjectStages } from '../../common/Constants'
import TimelineEntryCompact from './TimelineEntryCompact'
import TimelineEntryFull from './TimelineEntryFull'
import PropTypes from 'prop-types'

export default class TimelineEntry extends React.Component {
  constructor () {
    super()
    this.maxRefetchAttempts = 20
  }

  componentDidMount () {
    this.props.onFetchContent && this.props.onFetchContent(this.props.metadatum && this.props.metadatum.versioned_data_object_id)
  }

  componentDidUpdate () {
    if (this.props.metadatum.stage !== dataObjectStages.brewed_stage && !this.props.isFetching && this.props.fetchedAt && this.props.fetchedAt.attempts < this.maxRefetchAttempts) {
      setTimeout(() => this.props.onRefetchMetaData(this.props.metadatum.id), 1000 * this.props.fetchedAt.attempts)
    }
  }

  render () {
    const { showCompact, data, metadatum } = this.props
    const isLoading = (!data || data.versioned_data_object.data_type.data_type === undefined || !metadatum || ![dataObjectStages.brewed_stage, dataObjectStages.failed_stage].includes(metadatum.stage))

    if (showCompact) {
      return <TimelineEntryCompact {...this.props} isLoading={isLoading} />
    }
    return <TimelineEntryFull {...this.props} isLoading={isLoading} />
  }
}

TimelineEntry.defaultProps = {
  showCompact: false
}

TimelineEntry.propTypes = {
  showCompact: PropTypes.bool,
  data: PropTypes.object,
  metadatum: PropTypes.object,
  fetchedAt: PropTypes.object,
  isFetching: PropTypes.bool,
  onRefetchMetaData: PropTypes.func,
  onFetchContent: PropTypes.func,
  myProfile: PropTypes.object
}
