import React, { useState, useEffect } from 'react'
import I18n from 'i18n'
import { MyAxios as axios } from '../../MyAxios'
import { WithQuestionnaires } from '../../questionnaires/QuestionnaireProvider'
import Spinner from '../../common/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { questionnaireName } from '../../common/Utils'

const QuestionnaireDefinitions = (props) => {
  const [questionnaires, setQuestionnaires] = useState(undefined)

  useEffect(() => {
    axios({
      method: 'GET',
      url: props.questionnaires.questionnaireDefinitionsUrl()
    }).then((response) => {
      setQuestionnaires(response.data)
    }).catch((err) => {
      setQuestionnaires([])
      console.log('Error retrieving questionnaires')
      console.log(err)
    })
  }, [])

  if (!questionnaires) {
    return <Spinner transparent />
  }

  return (
    <div className='row'>
      <div className='download-definitions col s12'>
        {
          questionnaires.map(questionnaire => (
            <a
              key={questionnaire.key}
              style={{ marginRight: '1rem' }}
              id='download-definition-button'
              className='data-button data-detail-page waves-effect waves-light background-background button-primary text-primary-color wider-data-button text-medium text-m'
              href={props.questionnaires.downloadQuestionnaireDefinitionUrl(questionnaire.key)}
              target='_blank'
              rel='noopener noreferrer'
            >
              <FontAwesomeIcon className='button-icon' icon='file-download' />
              {questionnaire.title || questionnaireName(questionnaire.key)}
            </a>
          ))
        }
        {questionnaires.length === 0 &&
          <p className='text-muted'>{I18n.t('group.detail.questionnaires.no_available_questionnaire_definitions')}</p>}
      </div>
    </div>
  )
}

export default WithQuestionnaires(QuestionnaireDefinitions)
