import LineGraph from 'components/atomic/atoms/graphs/LineGraph'
import GenericPlaceholder from 'components/common/GenericPlaceholder'
import { isEmpty } from 'lodash'
import I18n from 'i18n'
import React, { FC, ReactElement } from 'react'
import { chartjsDefaultOptions, chartjsTitleProperties } from 'components/common/Constants'
import Variables from '../../../../stylesheets/variables.module.scss'
import { useAggregatedDataQuery } from 'components/backend/Queries'
import SpinnerWrapper from 'components/common/SpinnerWrapper'

const WielerFitheidGraph: FC = (): ReactElement => {
  const { isLoading, isError, isSuccess, data: wielerData } = useAggregatedDataQuery('WielerFitheid')
  const { primaryColor, green, accentColor, secondaryColor } = Variables

  if (!isLoading && (wielerData === undefined || isEmpty(wielerData))) {
    return (
      <GenericPlaceholder
        title={I18n.t('components.dashboards.multi_activity.wielerfitheid.title')}
        message={I18n.t('components.dashboards.multi_activity.wielerfitheid.not_enough_data')}
      />
    )
  }

  const options = {
    ...chartjsDefaultOptions,
    title: {
      ...chartjsTitleProperties,
      text: I18n.t('components.dashboards.multi_activity.wielerfitheid.title')
    }
  }

  const dataStruct = {
    labels: wielerData?.content?.data?.HRDP?.map((e: any) => e.Date),
    datasets: [
      {
        label: I18n.t('components.dashboards.multi_activity.wielerfitheid.variables.power_lt1'),
        fill: '-1',
        backgroundColor: undefined,
        borderColor: primaryColor,
        data: wielerData?.content?.data?.HRDP.map((e: any) => e.PowerLT1)
      },
      {
        label: I18n.t('components.dashboards.multi_activity.wielerfitheid.variables.power_lt2'),
        fill: '-1',
        backgroundColor: undefined,
        borderColor: secondaryColor,
        data: wielerData?.content?.data?.HRDP?.map((e: any) => e.PowerLT2)
      },
      {
        label: I18n.t('components.dashboards.multi_activity.wielerfitheid.variables.heartrate_lt1'),
        fill: '-1',
        backgroundColor: undefined,
        borderColor: green,
        data: wielerData?.content?.data?.HRDP?.map((e: any) => e.HeartRateLT1)
      },
      {
        label: I18n.t('components.dashboards.multi_activity.wielerfitheid.variables.heartrate_lt2'),
        fill: '-1',
        backgroundColor: undefined,
        borderColor: accentColor,
        data: wielerData?.content?.data?.HRDP?.map((e: any) => e.HeartRateLT2)
      }
    ]
  }

  return (
    <>
      <SpinnerWrapper failed={isError} ready={isSuccess} transparent={false}>
        <LineGraph
          options={options}
          data={dataStruct}
        />
      </SpinnerWrapper>
    </>
  )
}

export default WielerFitheidGraph
