import React from 'react'
import I18n from 'i18n'
import WelcomeController from '../../welcome/WelcomeController'
import SportIcon from '../../icons/sports/SportIcon'
import Spinner from '../../common/Spinner'
import BackButton from '../../common/BackButton'
import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom'
import RecentActivity from './RecentActivity'
import PremiumProfile from '../../premium_membership/layout/PremiumProfile'
import { membershipStates } from '../../common/Constants'
import Modal from 'react-modal'
import Routes from '../../common/Routes'
import TextArea from 'components/atomic/atoms/TextArea'

class ConnectionButton extends React.Component {
  render () {
    return (
      <button
        className='waves-effect waves-light text-medium text-m background-accent button-accent text-background-color button'
        onClick={this.props.handleConnectButton}
      >
        {!this.props.connectionStatus && I18n.t('profile.show.request_connection')}
        {this.props.connectionStatus === membershipStates.ACCEPTED && I18n.t('profile.show.cancel_connection')}
        {this.props.connectionStatus === membershipStates.PENDING && I18n.t('profile.show.cancel_connection_request')}
      </button>
    )
  }
}

export default class ShowProfile extends React.Component {
  constructor (props) {
    super(props)
    this.state = { modal: false }
  }

  componentDidMount () {
    const { match: { params: { slug } } } = this.props
    this.props.fetch(slug)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!prevProps.dataRetrieved && this.props.dataRetrieved) {
      setTimeout(() => { ReactTooltip.rebuild() }, 200)
    }

    const { match: { params: { slug: newSlug } } } = this.props
    const { match: { params: { slug: oldSlug } } } = prevProps
    if (oldSlug !== newSlug || prevProps.currentPage !== this.props.currentPage) {
      this.props.fetch(newSlug)
    }

    M.updateTextFields()
  }

  handleFollowButton () {
    this.props.following
      ? this.props.onUnFollow(this.props.profile.slug)
      : this.props.onFollow(this.props.profile.slug)
  }

  handleConnectButton () {
    !this.props.connectionStatus
      ? this.props.onRequestConnection(this.props.profile)
      : this.props.onCancelConnection(this.props.profile.slug)
  }

  canBeEdited () {
    const { match: { params: { slug } } } = this.props
    return this.props.myProfile !== undefined && slug === this.props.myProfile.slug
  }

  handlePictureClick = () => this.setState({ modal: true })

  handleModalClose = () => this.setState({ modal: false })

  pageShowsMyProfile = (profile) => {
    if (!profile || !this.props.myProfile) {
      return false
    }
    return this.props.myProfile.id === profile.id
  }

  render () {
    const { profile } = this.props
    const { match: { params: { slug } } } = this.props

    if (!this.props.dataRetrieved || !profile || profile.slug !== slug) {
      return (
        <div className='row'>
          <div className='col s12'>
            <Spinner ready={false} />
          </div>
        </div>
      )
    }

    if (profile && profile.error) {
      return (
        <div className='row'>
          <div className='col s12'>
            <div className='text-heavy text-primary-color text-l'>
              {I18n.t('profile.show.invisible')}
            </div>
          </div>
        </div>
      )
    }
    const showsMyProfile = this.pageShowsMyProfile(profile)
    let extraProps = {}
    if (!showsMyProfile) {
      // Only pass the myProfileMetadata object if the profile being shown is not the logged in user's profile.
      // Otherwise, it will overwrite the myProfileMetadata object that is already passed to the WelcomeController.
      const myProfileMetadata = {
        network_dataset_count: 0, // This isn't in the profile serializer, and not used in the WelcomeController.
        follower_count: profile.follower_count,
        own_dataset_count: profile.own_dataset_count,
        connection_count: profile.connection_count
      }
      extraProps = { myProfileMetadata }
    }
    return (
      <>
        <div className='row'>
          <div className='col s12 m4 l4 welcome-left-menu'>
            <WelcomeController
              onPictureClick={this.handlePictureClick}
              welcomeMessage={I18n.t('profile.show.profile_of')}
              profileLink={profile.slug}
              myProfile={profile}
              ModalButton={ConnectionButton}
              modalButtonProps={!showsMyProfile && { connectionStatus: this.props.connectionStatus, handleConnectButton: this.handleConnectButton.bind(this) }}
              dataCount={profile.own_dataset_count}
              connectionCount={profile.connection_count}
              followerCount={profile.follower_count}
              hideTrainingLog
              {...extraProps}
            />
            {showsMyProfile && <PremiumProfile myProfile={this.props.myProfile} />}
            <Modal
              isOpen={this.state.modal}
              onRequestClose={this.handleModalClose}
              overlayClassName='react-modal-overlay flexbox high-z'
              className='modal-centered-content'
              contentLabel='Profile picture'
            >
              <img className='scale-image' onClick={this.handleModalClose} src={profile.picture || '/images/pfp-placeholder.png'} />
            </Modal>
          </div>

          <div className='col s12 m8 l8'>
            <div className='profile-container'>
              <div className='row'>
                <div className='col s12'>
                  <BackButton {...this.props} />
                </div>
                <div className='col s12'>
                  <div className='text-heavy text-primary-color text-xl' id='profile-show-profile'>
                    {I18n.t('profile.profile')}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col s3'>
                  <TextArea label={I18n.t('profile.show.first_name')} value={profile.first_name || ''} name='first_name' disabled />
                </div>
                <div className='col s3'>
                  <TextArea label={I18n.t('profile.show.last_name')} value={profile.last_name || ''} name='last_name' disabled />
                </div>
                <div className='col s3'>
                  <TextArea label={I18n.t('profile.show.organization')} value={profile.organization} name='organization' disabled />
                </div>
                <div className='col s3'>
                  <TextArea label={I18n.t('profile.show.specialization')} value={profile.specialization} name='specialization' disabled />
                </div>
              </div>
              {profile.bio &&
                <div className='row'>
                  <div className='col s12'>
                    <TextArea label={I18n.t('profile.show.bio')} value={profile.bio} name='bio' disabled />
                  </div>
                </div>}
              <div className='row'>
                <div className='col s12'>
                  <label htmlFor='sports-list'>{I18n.t('profile.show.sports')}</label>
                  <div id='sports-list' className='sports-list'>
                    {profile.sports.map((sport) => {
                      return (
                        <SportIcon icon={sport.icon} key={sport.name} data-tip={I18n.t(sport.name)} data-for='higher-z-tooltip' />
                      )
                    })}
                  </div>
                </div>
              </div>
              {this.canBeEdited() &&
                <div className='row'>
                  <div className='col s12'>
                    <Link to={Routes.profiles.edit}>
                      <button className='button-primary background-primary button-autowidth waves-effect waves-light text-background-color'>{I18n.t('profile.show.edit')}</button>
                    </Link>
                  </div>
                </div>}
            </div>
            <div className='profile-container'>
              <RecentActivity
                recentActivity={this.props.recentActivity}
                myProfile={this.props.myProfile}
                title={I18n.t('profile.show.public_data')}
                noDataMessage={I18n.t('profile.show.no_public_data')}
                currentPage={this.props.currentPage}
                pageCount={this.props.pageCount}
                onSetPage={this.props.onSetPage}
                showCompact
              />
            </div>
            {/* follow-enable (remove comment here) */}
            {/*
                <div className='row'>
                  <div className='col s12'>
                    <button
                      className='waves-effect waves-light text-heavy background-primary button-primary text-background-color button-autowidth'
                      onClick={this.handleFollowButton.bind(this)}
                    >{this.props.following ? I18n.t('profile.show.unfollow') : I18n.t('profile.show.follow')}
                    </button>
                  </div>
                </div> */}
          </div>
        </div>
      </>
    )
  }
}
