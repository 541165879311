import React from 'react'
import NotFoundMessage from './NotFoundMessage'

const NotFoundContext = React.createContext()

export default class NotFoundProvider extends React.Component {
  constructor (props) {
    super(props)
    this.state = { registeredComponents: new Set() }
  }

  register (componentSlug) {
    const set = this.state.registeredComponents
    set.add(componentSlug)
    this.setState({ registeredComponents: set })
  }

  deregister (componentSlug) {
    const set = this.state.registeredComponents
    set.delete(componentSlug)
    this.setState({ registeredComponents: set })
  }

  render () {
    return (
      <NotFoundContext.Provider value={{
        register: this.register.bind(this),
        deregister: this.deregister.bind(this)
      }}
      >
        {this.props.children}
        {this.state.registeredComponents.size === 0 && (<NotFoundMessage />)}
      </NotFoundContext.Provider>
    )
  }
}

export const WithNotFound = Component => props => {
  return (
    <NotFoundContext.Consumer>
      {(context) => (<Component {...context} {...props} />)}
    </NotFoundContext.Consumer>
  )
}

class NotFoundCls extends React.Component {
  componentDidMount () {
    this.props.deregister(this.props.componentSlug)
  }

  componentWillUnmount () {
    this.props.register(this.props.componentSlug)
  }

  render () {
    /* do not render anything here. We just want to know about the components' lifecyle (did mount and unmount) */
    return (<></>)
  }
}

export const NotFound = WithNotFound(NotFoundCls)
