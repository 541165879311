import React from 'react'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { WithModal } from '../../modal/ModalProvider'
import { defaultProfilePicture, membershipStates } from '../../common/Constants'
import { TimeAgoPlain } from '../../common/TimeAgo'
import { identity } from '../../common/Units'

const Connection = ({ setConfirmationDialog = identity, connection, ...props }) => {
  if (!connection || !connection.other_membership) {
    return (<></>)
  }

  function showDeleteDialog () {
    setConfirmationDialog({ onConfirmation: () => props.onDeleteConnection(connection), action: 'delete_connection', target: connection.name })
  }

  function handleDelete () {
    showDeleteDialog()
  }

  const name = `${connection.other_membership.profile.first_name || I18n.t('profile.show.first_name')}
                ${connection.other_membership.profile.last_name || I18n.t('profile.show.last_name')}`

  return (
    <div className='col s12 m6 l4'>
      <div className='square-card'>
        <div className='card-content'>
          <Link to={connection.other_membership.profile.public_url || '/'}>
            <div className='row center-align padding-top' style={{ marginBottom: '0.75rem' }}>
              <div className='col s4 offset-s4'>
                <img className='circle responsive-img' src={connection.other_membership.profile.picture || defaultProfilePicture} />
              </div>
            </div>
            <div className='card-vertical-center'>
              <div className='row' style={{ marginBottom: '0.25rem' }}>
                <div className='col s12 center-align text-primary-color text-heavy text-m connection-one-line'>
                  {name}
                </div>
                <div className='col s12 center-align text-muted text-light text-s connection-one-line'>
                  {connection.other_membership.state === membershipStates.ACCEPTED && I18n.t('connections.connection.labels.since', { timestamp: TimeAgoPlain(new Date(connection.created_at)) })}
                  {connection.other_membership.state === membershipStates.PENDING && I18n.t('connections.requests.pending', { name: name })}
                </div>
              </div>
            </div>
          </Link>
          <div className='right-align delete-connection'>
            <button
              onClick={handleDelete}
              className='connection-button waves-effect waves-light button-primary text-primary-color background-background text-medium text-m'
              title={I18n.t('connections.connections.delete')}
            >
              <FontAwesomeIcon icon='trash' />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WithModal(Connection)
