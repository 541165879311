import React from 'react'
import I18n from 'i18n'
import { WithSession } from '../../session/SessionProvider'
import Spinner from '../../common/Spinner'
import Info from '../../atomic/atoms/Info'
import SnapshotCard from './SnapshotCard'
import { MyAxios as axios } from '../../MyAxios'

class SnapshotsOverview extends React.Component {
  /* eslint-disable camelcase */
  UNSAFE_componentWillMount () {
    this.fetch()
  }

  /* eslint-enable camelcase */

  fetch () {
    this.props.onRequest('/api/v1/snapshots')
    this.props.fetchSnapshotsSharedWithMe()
    this.props.onFetchGroups()
  }

  downloadSnapshot (snapshotId) {
    window.open(`/api/v1/snapshots/${snapshotId}.zip?auth=${this.props.sessionToken.substring(7)}`)
  }

  deleteSnapshot (snapshotId) {
    axios.delete(`/api/v1/snapshots/${snapshotId}`, { headers: { Authorization: this.props.sessionToken } })
      .then(res => {
        this.fetch()
      })
  }

  render () {
    const { snapshots, sharedWithMe } = this.props
    return (
      <>
        <div className='row'>
          <div className='col s12 text-heavy muted-header'>
            <h5>
              {I18n.t('components.snapshots.share')}
              <Info text={I18n.t('components.snapshots.tooltip')} tooltipId='snapshots-tooltip' />
            </h5>
          </div>
        </div>

        <div className='row'>
          <div className='col s12'>
            <p className='text-medium text-l text-muted no-margin'>
              {I18n.t('components.snapshots.sharing_explanation')}
            </p>
          </div>
        </div>
        {!this.props.dataRetrieved && (
          <div className='row'>
            <div className='col s12'>
              <Spinner ready={this.props.dataRetrieved} transparent />
            </div>
          </div>
        )}
        {this.props.dataRetrieved && snapshots && (
          <div className='row snapshot-scroll'>
            {snapshots.map(entry => (
              <div key={entry.id} className='row'>
                <SnapshotCard
                  downloadSnapshot={this.downloadSnapshot.bind(this)}
                  deleteSnapshot={this.deleteSnapshot.bind(this)}
                  snapshot={entry}
                  onShareSnapshot={this.props.onShareSnapshot}
                />
              </div>
            ))}
          </div>
        )}
        {this.props.dataRetrieved && snapshots && snapshots.length === 0 && (
          <div className='row'>
            <div className='col s12'>
              <p className='text-muted'>
                <em>{I18n.t('components.snapshots.no_snapshots')}</em>
              </p>
            </div>
          </div>
        )}

        <div className='row'>
          <div className='col s12 text-heavy muted-header'>
            <h5>{I18n.t('components.snapshots.shared_with_me')}</h5>
          </div>
        </div>

        {!this.props.sharedWithMeRetrieved && (
          <div className='row'>
            <div className='col s12'>
              <Spinner ready={this.props.sharedWithMeRetrieved} transparent />
            </div>
          </div>
        )}

        {this.props.sharedWithMeRetrieved && sharedWithMe && (
          <div className='row snapshot-scroll'>
            {sharedWithMe.map(entry => (
              <div key={entry.id} className='row'>
                <SnapshotCard
                  downloadSnapshot={this.downloadSnapshot.bind(this)}
                  deleteSnapshot={this.deleteSnapshot.bind(this)}
                  snapshot={entry}
                  shared
                />
              </div>
            ))}
          </div>
        )}

        {this.props.sharedWithMeRetrieved && sharedWithMe && sharedWithMe.length === 0 && (
          <div className='row'>
            <div className='col s12'>
              <p className='text-muted'>
                <em>{I18n.t('components.snapshots.no_shared_snapshots')}</em>
              </p>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default WithSession(SnapshotsOverview)
