import React from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WithSession } from '../../session/SessionProvider'
import DirectUploader from '../../helpers/DirectUploader'
import { defaultProfilePicture } from '../../common/Constants'

class CroppableFileUpload extends React.Component {
  constructor () {
    super()
    this.cropper = React.createRef()
    this.direct_uploader = new DirectUploader()

    /**
     possible stages: ['init', 'selected', 'cropped', 'complete']
     init -> select a new image
     selected -> show cropper
     complete -> upload completed successfully
     **/

    this.default_state = {
      stage: 'init',
      cropped: null,
      source: null,
      uploaded: null,
      width: 0,
      height: 0,
      filename: 'default.png'
    }

    this.state = this.default_state
  }

  _crop () {
    // image in dataUrl
    const img = this.cropper.current.getCroppedCanvas({ width: 600, height: 600 }).toDataURL()

    this.setState({ ...this.state, cropped: img })
  }

  componentDidMount () {
    this.direct_uploader.setSessionToken(this.props.sessionToken)
    if (this.props.defaultValue) {
      this.setState({ ...this.default_state, cropped: this.props.defaultValue, stage: 'complete' })
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.defaultValue !== prevProps.defaultValue && !!this.props.defaultValue) {
      this.setState({ ...this.default_state, cropped: this.props.defaultValue, stage: 'complete' })
    }
  }

  handleSuccess (response) {
    this.props.handleSuccess()
    this.props.fetchGroups()
  }

  handleError (response) {
    this.props.handleError()
  }

  handleFileSelect (e) {
    this.props.onStartEditing && this.props.onStartEditing()
    const reader = new FileReader()
    // Read in the image file as a data URL.
    const file = e.target.files[0]
    reader.readAsDataURL(file)
    reader.onload = (evt) => {
      const img = new Image()
      if (evt.target.readyState === FileReader.DONE) {
        img.onload = () => {
          this.setState({
            ...this.state,
            source: evt.target.result,
            width: img.width,
            height: img.height,
            filename: file.name,
            stage: 'selected'
          })
        }
        img.src = evt.target.result
      }
    }
  }

  handleClear () {
    this.props.onStopEditing && this.props.onStopEditing()
    this.setState(this.default_state)
  }

  handleAccept () {
    this.props.onStopEditing && this.props.onStopEditing()
    /* convert cropped image to a byte array and then convert to a File instance */
    const arr = this.state.cropped.split(','); const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1]); let n = bstr.length; const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    const image = new File([u8arr], this.state.filename, { type: mime })
    this.setState({ ...this.state, stage: 'cropped' })
    this.props.onAccept(this.getSignedId(image))
  }

  getSignedId (image) {
    return new Promise((resolve, reject) => {
      /* use direct uploader to save cropped image */
      this.direct_uploader.uploadFile(image).then((response) => {
        /* eslint-disable camelcase */
        const [{ signed_id }] = response
        resolve(signed_id)
        /* eslint-enable camelcase */
        this.setState({ ...this.state, stage: 'complete' })
      }).catch((err) => {
        reject(err)
        this.setState(this.default_state)
      })
    })
  }

  render () {
    const aspectRatio = this.state.width / this.state.height
    const container = document.getElementById('cropper-container')
    let targetHeight = 300
    if (container) {
      const cs = getComputedStyle(container)

      const paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight)

      const containerWidth = container.scrollWidth - paddingX
      targetHeight = containerWidth / aspectRatio
    }
    return (
      <>
        <div className='row'>
          <div className='col s4' />
          <div id='cropper-container' className='col s4 center-align' style={{ position: 'relative' }}>
            {this.state.stage === 'init' && (
              <label>
                <input
                  type='file'
                  name='group-image'
                  id='group-image'
                  accept='image/*'
                  style={{ display: 'none' }}
                  onChange={(e) => this.handleFileSelect(e)}
                />
                <img className='responsive-img circle' src={defaultProfilePicture} />
                <FontAwesomeIcon
                  id='change-picture'
                  style={{ position: 'absolute', top: '5px', right: '5px' }}
                  className='pointer-grow'
                  size='2x'
                  icon='edit'
                />
              </label>
            )}
            {this.state.stage === 'selected' && (
              <Cropper
                ref={this.cropper}
                src={this.state.source}
                style={{
                  height: `${targetHeight}px`,
                  maxWidth: '100%',
                  margin: 'auto'
                }}
                aspectRatio={1}
                crop={this._crop.bind(this)}
              />
            )}
          </div>
        </div>
        {this.state.stage === 'selected' && (
          <div className='row'>
            <div className='col s4' />
            <div className='col s2 center-align'>
              <span onClick={this.handleClear.bind(this)} className='text-accent-color'>
                <FontAwesomeIcon id='clear' className='pointer-grow' size='2x' icon='times' />
              </span>
            </div>
            <div className='col s2 center-align'>
              <span onClick={this.handleAccept.bind(this)} className='green-text'>
                <FontAwesomeIcon id='accept' className='pointer-grow' size='2x' icon='check' />
              </span>
            </div>
          </div>
        )}
        {(this.state.stage === 'cropped' || this.state.stage === 'complete') && (
          <div className='row'>
            <div className='col s4' />
            <div className='col s4 center-align text-accent-color' style={{ position: 'relative' }}>
              <img id='profile-pic' className='responsive-img circle' src={this.state.cropped} />
              <FontAwesomeIcon
                id='delete_image'
                style={{ position: 'absolute', top: '5px', right: '5px' }}
                className='pointer-grow'
                onClick={this.handleClear.bind(this)}
                size='2x'
                icon='times'
              />
            </div>
          </div>
        )}
      </>
    )
  }
}

export default WithSession(CroppableFileUpload)
