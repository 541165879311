import React from 'react'
import FileSelector from '../dashboards/common/FileSelector'
import PropTypes from 'prop-types'
import Spinner from '../../common/Spinner'

const Dashboard = props => {
  const { title, collectionType, onFileSelect, children, ready, onBeforeFetchVdo, pickFirstFile, onProfileSelect } = props
  const { match: { params: { id: routeFileId } = {} } = {} } = props
  const selectedFileId = pickFirstFile ? undefined : routeFileId
  return (
    <div className=''>
      <div className='col s12 text-xl text-primary-color text-heavy no-padding'>
        {title}
      </div>
      <div className='row no-margin-bottom'>
        <div className='col s12'>
          <FileSelector collectionType={collectionType} onFileSelect={onFileSelect} onProfileSelect={onProfileSelect} selectedFileId={selectedFileId} onBeforeFetchVdo={onBeforeFetchVdo} />
        </div>
      </div>
      <Spinner ready={ready} />
      {ready && children}
    </div>
  )
}

FileSelector.propTypes = {
  // Not validating other props since they are validated in child components
  title: PropTypes.string,
  // If true the file selector will pick the first file independently of the id of the route. Useful when rendering
  // multiple dashboards under the same path
  pickFirstFile: PropTypes.bool,
  onFileSelect: PropTypes.func,
  onProfileSelect: PropTypes.func
}

export default Dashboard
