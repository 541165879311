import React from 'react'
import moment from 'moment'
import I18n from 'i18n'

import GroupedBarsChart from '../../common/GroupedBarsChart'
import { momentDateFormat } from '../../../../common/Constants'
import { isSevereComplaint } from '../../../../common/utils/QuestionnaireUtils'

const SevereComplaintsChart = (props) => {
  const { questionnaires, barColors } = props

  const severeComplaints = {}

  questionnaires.forEach(questionnaire => {
    const { week, answers } = questionnaire

    const { injury: injuries, illness } = answers || {}

    if (!severeComplaints[week]) {
      severeComplaints[week] = [{ value: 0, param: 'complaints' }]
    }

    const barEntry = severeComplaints[week][0]

    if (injuries) {
      barEntry.value += Object.entries(injuries)?.reduce((result, [_, complaint]) => (
        isSevereComplaint(complaint) ? result + 1 : result
      ), 0)
    }

    if (illness && isSevereComplaint(illness)) {
      barEntry.value += 1
    }
  })

  const entries = Object.entries(severeComplaints).reduce((allEntries, [week, bars]) => {
    allEntries.push({ label: moment(week), bars })
    return allEntries
  }, [])

  const properties = { complaints: { color: 'rgb(68,114,196)' } }
  const tooltips = {
    title: (tooltipItems) => tooltipItems[0].xLabel.format(momentDateFormat),
    label: (item, _data) => {
      return I18n.t('components.dashboards.questionnaire.complaints.severe_complaints.tooltip', {
        value: item.value
      })
    }
  }
  const options = {
    suggestedMin: 0,
    suggestedMax: 7,
    title: '', // Specified outside of the component, so we can use HTML (tooltips).
    yLabel: I18n.t('components.dashboards.questionnaire.complaints.severe_complaints.y_label'),
    tooltipCallbacks: tooltips
  }

  const xAxis = {
    scaleLabel: {
      display: true,
      labelString: I18n.t('components.dashboards.questionnaire.complaints.injuries_over_time.x_axis')
    },
    ticks: { callback: (value) => value.week() }
  }

  return (
    <GroupedBarsChart entries={entries} paramProperties={properties} {...options} xAxis={xAxis} barColors={barColors} />
  )
}

export default SevereComplaintsChart
