import { dailyQuestionnaires, trainingLog, weeklyWellbeing } from 'components/common/Constants'
import { QuestionnaireObject } from 'components/common/types/questionnaires/QuestionnaireObject'
import { StructuredDataObject } from 'components/common/types/questionnaires/StructuredDataObject'
import { DataRow } from 'components/common/types/questionnaires/DataRow'

export const isDailyQuestionnaire = (questionnaire: QuestionnaireObject): boolean => {
  return dailyQuestionnaires.includes(questionnaire?.structured_data_objects?.[0].data_rows?.[0]?.questionnaire?.key)
}

export const isTrainingLog = (questionnaire: QuestionnaireObject): boolean => {
  const dataRow: DataRow | undefined = getFirstDataRowObject(questionnaire)
  return dataRow?.questionnaire?.key === trainingLog &&
    dataRow?.values?.v1 != null && String(dataRow?.values.v1).length > 0 &&
    dataRow?.values?.v2 != null && String(dataRow?.values.v2).length > 0
}

export const isWeeklyWellbeingQuestionnaire = (questionnaire: QuestionnaireObject): boolean => {
  return getFirstDataRowObject(questionnaire)?.questionnaire?.key === weeklyWellbeing
}

// Helper function to extract open_from
export const getOpenFromDate = (questionnaire: QuestionnaireObject): string => {
  return getFirstDataRowObject(questionnaire)?.open_from
}

// Helper function to extract structured_data_object
export const getFirstStructuredDataObject = (questionnaire: QuestionnaireObject): StructuredDataObject => {
  return questionnaire?.structured_data_objects?.[0]
}

// Helper function to extract data_rows
export const getFirstDataRowObject = (questionnaire: QuestionnaireObject): DataRow => {
  return getFirstStructuredDataObject(questionnaire)?.data_rows?.[0]
}
