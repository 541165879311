import React from 'react'
import ProfileRoutes from './ProfileRoutes'
import ApiConnector from '../helpers/ApiConnector'
import { WithSession } from '../session/SessionProvider'
import { MyAxios as axios } from '../MyAxios'
import { interestsExcludedFromFrontend, membershipStates } from '../common/Constants'
import { WithFlashMessages } from '../flashmessages/FlashMessageProvider'
import I18n from 'i18n'
import { errorToString } from '../common/ErrorHandling'

class Profile extends ApiConnector {
  constructor (props) {
    super(props)

    this.state = {
      ...this.state,
      following: false,
      fetchedFollowStatus: false,
      fetchedConnectionStatus: false,
      fetchedProfile: false,
      recentActivity: undefined,
      currentPage: 1,
      pageCount: 1
    }
  }

  /* eslint-disable camelcase */
  profile_editFormMapper (fields) {
    if ('sports' in fields) {
      fields.sports = fields.sports.map((id) => { return { id: id } })
    }
    return { profile: fields }
  }
  /* eslint-enable camelcase */

  /* eslint-disable camelcase */
  profile_editApiMapper (fields, url) {
    if (url === '/api/v1/sports') {
      const newFields = { sports: [] }
      for (const { id, name, icon } of fields) {
        if (interestsExcludedFromFrontend.includes(name)) continue
        newFields.sports.push({ properties: { value: id, name: name, icon: icon } })
      }
      return newFields
    } else if ('sports' in fields) {
      fields.sports = fields.sports.map(({ id, name }) => id)
      return fields
    }
  }
  /* eslint-enable camelcase */

  getProfile (slugParam) {
    axios.get(`/api/v1/profiles/${slugParam}`, { headers: { Authorization: this.props.sessionToken } })
      .then(res => {
        this.setState({ profile_data: res.data, fetchedProfile: true })
      })
      .catch((error) => {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error)
        this.setState({ profile_data: { slug: slugParam, error: true }, fetchedProfile: true })
      })
  }

  // Don't delete
  fetchFollowStatus (slug) {
    axios.get(`/api/v1/profiles/${slug}/follow_status`,
      { headers: { Authorization: this.props.sessionToken } })
      .then(res => { this.setState({ following: res.data.following, fetchedFollowStatus: true }) })
      .catch((error) => {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error)
        this.setState({ fetchedFollowStatus: true })
      })
  }

  fetchConnectionStatus (slug) {
    axios.get(`/api/v1/profiles/${slug}/connection_status`,
      { headers: { Authorization: this.props.sessionToken } })
      .then(res => { this.setState({ connectionStatus: res.data.connection_status, fetchedConnectionStatus: true }) })
      .catch((error) => {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error)
        this.setState({ fetchedConnectionStatus: true })
      })
  }

  fetchAll (slug) {
    this.setState({ fetchedFollowStatus: false, fetchedConnectionStatus: false, fetchedProfile: false }, () => {
      this.getProfile(slug)
      // Commented until we are clear on how following users work
      /* follow-enable (remove comment here) */
      // this.fetchFollowStatus(slug)
      this.fetchConnectionStatus(slug)
      this.fetchRecentActivity(slug)
    })
  }

  setPage (page) {
    this.setState({ currentPage: Math.min(this.state.pageCount, page) })
  }

  fetchRecentActivity (slug) {
    axios.get(`/api/v1/profiles/${slug}/data/public?page=${this.state.currentPage}`,
      { headers: { Authorization: this.props.sessionToken } })
      .then(res => { this.setState({ pageCount: res.data.page.total_pages, recentActivity: res.data.data }) })
      .catch((error) => {
        // The request was made and the server responded with a
        // status code that falls out of the range of 2xx
        console.error(error)
        this.setState({ recentActivity: undefined })
      })
  }

  handleFollow (slug) {
    axios.post(`/api/v1/profiles/${slug}/follow`,
      {},
      { headers: { Authorization: this.props.sessionToken } })
      .then(() => {
        this.setState({ following: true })
        this.fetchAll(slug)
      })
  }

  handleUnfollow (slug) {
    axios.post(`/api/v1/profiles/${slug}/unfollow`,
      {},
      { headers: { Authorization: this.props.sessionToken } })
      .then(() => {
        this.setState({ following: false })
        this.fetchAll(slug)
      })
  }

  handleRequestConnection (profile) {
    axios({
      method: 'POST',
      url: '/api/v1/groups/request_mutual_connection',
      headers: { Authorization: this.props.sessionToken, ContentType: 'application/json' },
      data: { profile_id: profile.id }
    }).then(() => {
      this.setState({ connectionStatus: membershipStates.PENDING })
      this.fetchConnectionStatus(profile.slug)
    })
  }

  handleCancelConnection (slug) {
    axios({
      method: 'POST',
      url: `/api/v1/profiles/${slug}/cancel_connection`,
      headers: { Authorization: this.props.sessionToken, ContentType: 'application/json' }
    }).then(() => {
      this.setState({ connectionStatus: undefined })
    })
  }

  handleUpdatePassword (oldPassword, newPassword, confirmationPassword) {
    axios({
      method: 'PUT',
      url: '/api/v1/signup',
      headers: { Authorization: this.props.sessionToken },
      data: {
        user: {
          current_password: oldPassword,
          password: newPassword,
          password_confirmation: confirmationPassword
        }
      }
    }).then(res => {
      this.props.flashMessages.push(I18n.t('profile.edit.reset_password.success'), this.props.flashMessages.duration.SHORT, this.props.flashMessages.levels.INFO)
    }).catch(e => {
      this.props.flashMessages.push(errorToString(e), this.props.flashMessages.duration.LONG, this.props.flashMessages.levels.ERROR)
    })
  }

  render () {
    return (
      <ProfileRoutes
        showProps={this.defaultFormProps('profile_show', {
          myProfile: this.props.myProfile,
          recentActivity: this.state.recentActivity,
          sessionToken: this.props.sessionToken,
          fetch: this.fetchAll.bind(this),
          profile: this.state.profile_data,
          onFollow: this.handleFollow.bind(this),
          onUnFollow: this.handleUnfollow.bind(this),
          following: this.state.following,
          connectionStatus: this.state.connectionStatus,
          onRequestConnection: this.handleRequestConnection.bind(this),
          onCancelConnection: this.handleCancelConnection.bind(this),
          currentPage: this.state.currentPage,
          pageCount: this.state.pageCount,
          onSetPage: this.setPage.bind(this),
          /* follow-enable (remove comment here) */
          // dataRetrieved: this.state.fetchedFollowStatus && this.state.fetchedProfile && this.state.fetchedConnectionStatus,
          dataRetrieved: this.state.fetchedProfile && this.state.fetchedConnectionStatus
        })}
        editProps={this.defaultFormProps('profile_edit', {
          translateErrors: false,
          submitMethod: 'PATCH',
          endpoint: '/api/v1/profiles/my',
          sessionToken: this.props.sessionToken,
          handleUpdatePassword: this.handleUpdatePassword.bind(this)
        })}
      />
    )
  }
}

export default WithSession(WithFlashMessages(Profile))
