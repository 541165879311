import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'i18n'
import SelectDropdown from '../../../common/SelectDropdown'
import PlayerDropdown from './PlayerDropdown'
import _ from 'lodash'
import OvertimeChart, { OvertimeChartConfigs } from '../OvertimeChart'
import 'materialize-css/extras/noUiSlider/nouislider.min'
import Slider from '../../../common/Slider'
import { getColor } from '../../../common/Utils'
import moment from 'moment'
import { soccerColors, soccerMetrics, soccerOvertimeChartMetrics, metricAvgSpeed } from '../../../common/Constants'
import { average } from '../../../common/Math'

export default class SoccerOvertimeChart extends React.Component {
  constructor (props) {
    super(props)

    this.numRecordsPerMinute = 60

    this.ids = {
      metricsDropdown: 'overtime-metrics-dropdown',
      playersDropdown: 'overtime-players-dropdown',
      rangeSlider: 'overtime-range-slider'
    }
    this.metrics = soccerOvertimeChartMetrics
    this.defaultPlayers = ['0', '1']

    this.state = {
      metric: this.metrics[0],
      data: [],
      selectedPlayers: this.defaultPlayers,
      range: this.getMatchTimeInterval()
    }
  }

  getAggregatedData (dataArray, binSize, aggregationFunc) {
    const binnedData = _.chunk(dataArray, binSize)
    return _.map(binnedData, aggregationFunc)
  }

  getOvertimeData (metric) {
    const overtimeData = []
    if (metric === metricAvgSpeed) {
      for (const playerData of this.props.speedSeries) {
        overtimeData.push(this.getAggregatedData(playerData, this.numRecordsPerMinute, average))
      }
    }
    return overtimeData
  }

  getMatchTimeInterval () {
    const lastPeriod = this.props.periods[this.props.periods.length - 1]
    const endSecond = lastPeriod.interval && lastPeriod.interval.end
    const lastMinute = moment.duration(endSecond, 'seconds').asMinutes() || 1
    return [0, Math.ceil(lastMinute)]
  }

  handleChangeMetric (e) {
    const newMetric = e.target.value
    const overtimeData = this.getOvertimeData(newMetric)

    this.setState({
      metric: newMetric,
      data: overtimeData
    })
  }

  handleChangePlayer (e) {
    const selectedPlayers = _.filter(e.target.options, option => option.selected).map(option => option.value)
    this.setState({
      selectedPlayers: selectedPlayers
    })
  }

  handleChangeRange (values) {
    this.setState({
      range: values
    })
  }

  componentDidMount () {
    const overtimeData = this.getOvertimeData(this.state.metric)
    this.setState({
      data: overtimeData
    })
  }

  componentDidUpdate (prevProps, _prevState, _snapshot) {
    if (JSON.stringify(prevProps.speedSeries) !== JSON.stringify(this.props.speedSeries)) {
      const range = this.getMatchTimeInterval()
      const overtimeData = this.getOvertimeData(this.state.metric)
      this.setState({
        data: overtimeData,
        player: [],
        range: range
      })
    }
  }

  getSelectedPlayerData () {
    let selectedPlayerData = _.pick(this.state.data, this.state.selectedPlayers)
    selectedPlayerData = _.mapKeys(selectedPlayerData, (_playerData, playerId) => this.props.players[playerId].name)
    return selectedPlayerData
  }

  render () {
    const { players, teams, datasetId } = this.props
    const teamNames = teams.map(team => { return team.name })

    const metricsDropdownList = this.metrics.map(metric => { return { value: metric, name: I18n.t(soccerMetrics[metric].title) } })
    const configs = new OvertimeChartConfigs()
    const matchTimeInterval = this.getMatchTimeInterval()
    configs.setXAxis(Array.from(_.range(matchTimeInterval[0], matchTimeInterval[1] + 1).keys()))
    configs.setRange(this.state.range[0], this.state.range[1])
    configs.setColors(soccerColors.map((_color, index) => getColor(index, true)))

    const selectedPlayerData = this.getSelectedPlayerData()
    return (
      <div>
        <div className='row'>
          <div className='col s6'>
            <SelectDropdown
              id={this.ids.metricsDropdown}
              contentId={datasetId}
              content={metricsDropdownList}
              label={I18n.t('components.dashboards.soccer.selectors.metric')}
              placeholder={I18n.t('components.dashboards.soccer.selectors.metric')}
              onChange={this.handleChangeMetric.bind(this)}
              defaultValue={this.metrics[0]}
            />
          </div>
          <div className='col s6'>
            <PlayerDropdown
              id={this.ids.playersDropdown}
              datasetId={datasetId}
              players={players}
              label={I18n.t('components.dashboards.soccer.selectors.player')}
              onChange={this.handleChangePlayer.bind(this)}
              // defaultValue={this.state.selectedPlayers.map(playerId => playerId.toString())}
              defaultValue={this.defaultPlayers}
              teamNames={teamNames}
              isMultipleChoice
            />
          </div>
        </div>
        <div className='row'>
          <div className='col s12'>
            <OvertimeChart datasets={selectedPlayerData} configs={configs} />
          </div>
        </div>
        <div className='row'>
          <div className='col s12'>
            <Slider
              id={this.ids.rangeSlider}
              title={I18n.t('components.dashboards.soccer.overtime_graph.match_interval')}
              range={matchTimeInterval}
              start={this.state.range}
              onChange={this.handleChangeRange.bind(this)}
            />
          </div>
        </div>
      </div>
    )
  }
}

SoccerOvertimeChart.propTypes = {
  periods: PropTypes.array,
  players: PropTypes.array,
  speedSeries: PropTypes.array,
  datasetId: PropTypes.number
}

SoccerOvertimeChart.defaultProps = {
  players: [],
  speedSeries: []
}
