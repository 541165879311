import _ from 'lodash'
import { soccerHeatmapGridSize, soccerFieldHeight, soccerFieldWidth } from './Constants'

export function getHeatmapData (playerPositions) {
  // Initialize Heatmap with 0's
  let heatmap = new Array(soccerHeatmapGridSize.width)
  heatmap = _.map(heatmap, function () { return _.fill(new Array(soccerHeatmapGridSize.height), 0) })

  // Add +1 in the respective place
  for (const position of _.values(playerPositions)) {
    if (!position || !position.x || !position.y) {
      continue
    }

    const normalizedX = position.x / soccerFieldWidth
    const normalizedY = position.y / soccerFieldHeight

    // The width/height are switched because the soccer heatmap is aligned vertically
    let gridPositionWidth = Math.floor(normalizedY * soccerHeatmapGridSize.width)
    let gridPositionHeight = Math.floor(normalizedX * soccerHeatmapGridSize.height)

    // This is to check whether the limit is reached
    gridPositionWidth = Math.min(Math.max(gridPositionWidth, 0), soccerHeatmapGridSize.width - 1)
    gridPositionHeight = Math.min(Math.max(gridPositionHeight, 0), soccerHeatmapGridSize.height - 1)

    heatmap[gridPositionWidth][gridPositionHeight] += 1
  }

  // Y axis in the heatmap is left -> right; but in the vertical SoccerField, is from right -> left
  mirrorHeatmap(heatmap) // In order to be consistent with SoccerField
  return heatmap
}

function mirrorHeatmap (heatmap) {
  for (const row of heatmap) {
    _.reverse(row)
  }
}
