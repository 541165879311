import React from 'react'
import SnapshotsOverview from './layout/SnapshotsOverview'

class SnapshotsController extends React.Component {
  constructor () {
    super()
    this.permissions = [
      { id: 1, name: 'read', icon: 'eye' },
      { id: 2, name: 'edit', icon: 'edit' },
      { id: 3, name: 'download', icon: 'download' }]
  }

  render () {
    return (
      <>
        <div className='row'>
          <div className='col s12'>
            <SnapshotsOverview
              snapshots={this.props.snapshots}
              sharedWithMe={this.props.sharedWithMe}
              fetchSnapshotsSharedWithMe={this.props.fetchSnapshotsSharedWithMe}
              onShareSnapshot={this.props.handleShareSnapshot}
              onFetchGroups={this.props.handleFetchGroups}
              onRequest={this.props.handleFetchSnapshots}
              dataRetrieved={this.props.retrievedSnapshots}
              sharedWithMeRetrieved={this.props.retrieveSharedWithMe}
            />
          </div>
        </div>
      </>
    )
  }
}

export default SnapshotsController
