import React from 'react'
import I18n from 'i18n'
import Spinner from '../../../../common/Spinner'
import QuestionnaireFeedbackTable from './QuestionnaireFeedbackTable'
import DailyWellnessChart from './DailyWellnessChart'
import DailySleepChart from './DailySleepChart'
import DailyReadinessToTrainChart from './DailyReadinessToTrainChart'
import { WithSession } from 'components/session/SessionProvider'
import MyReactTooltip from '../../../../MyReactTooltip'
import Info from '../../../../atomic/atoms/Info'
import GenericPlaceholder from '../../../../common/GenericPlaceholder'
import DailyRestingHeartrateChart from './DailyRestingHeartrateChart'
import { Link } from 'react-router-dom'
import SpinnerWrapper from 'components/common/SpinnerWrapper'

const WellbeingQuestionnaireDashboard = (props) => (
  <>
    <div className='row'>
      <div className='col s12 l6'>
        <div className='chart-container'>
          <SpinnerWrapper ready={!props.isLoading && !props.isError} failed={props.isError && !props.isLoading}>
            <DailyWellnessChart data={props.dailyLogRows} />
          </SpinnerWrapper>
        </div>
      </div>
      <div className='col s12 l6'>
        <div className='chart-container'>
          <SpinnerWrapper ready={!props.isLoading && !props.isError} failed={props.isError && !props.isLoading}>
            <DailyRestingHeartrateChart dailyLogRows={props.dailyLogRows} />
          </SpinnerWrapper>
        </div>
      </div>
    </div>

    <div className='row'>
      <div className='col s12 l6'>
        <div className='chart-container'>
          <SpinnerWrapper ready={!props.isLoading && !props.isError} failed={props.isError && !props.isLoading}>
            <DailyReadinessToTrainChart dailyLogRows={props.dailyLogRows} />
          </SpinnerWrapper>
        </div>
      </div>
      <div className='col s12 l6'>
        <div className='chart-container'>
          <SpinnerWrapper ready={!props.isLoading && !props.isError} failed={props.isError && !props.isLoading}>
            <DailySleepChart dailyLogRows={props.dailyLogRows} />
          </SpinnerWrapper>
        </div>
      </div>
    </div>

    <div className='row'>
      <div className='col s12'>
        <div className='chart-container feedback small-absolute-tabs'>
          {props.isLoading && (
            <div className='my-highcharts-container'><Spinner ready={false} /></div>
          )}
          <ul className='tabs'>
            <li className='tab col s6'>
              <Link className='active' to='#day-feedback'>
                {I18n.t('datetime.prompts.day')}
              </Link>
            </li>
            <li className='tab col s6'><Link to='#week-feedback'>{I18n.t('datetime.prompts.week')}</Link></li>
          </ul>
          <div id='day-feedback' className='col s12 tab-content active'>
            {!props.dailyLogRows && !props.isLoading && (
              <GenericPlaceholder title={I18n.t('components.dashboards.questionnaire.daily_feedback.title')} />
            )}
            {props.dailyLogRows && (
              <>
                <div className='highcharts-plot-title-container'>
                  <div className='highcharts-plot-title'>
                    {I18n.t('components.dashboards.questionnaire.daily_feedback.title')}
                    <Info
                      text={I18n.t('components.dashboards.questionnaire.daily_feedback.info')}
                      tooltipId='questionnaire-tooltip-daily-feedback' inline
                    />
                  </div>
                </div>
                <div className='feedback-table-container'>
                  <SpinnerWrapper ready={!props.isLoading && !props.isError} failed={props.isError && !props.isLoading}>
                    <QuestionnaireFeedbackTable
                      id='daily_experiences'
                      data={props.dailyLogRows}
                      columns={[
                        {
                          column: 'date',
                          label: I18n.t('components.dashboards.questionnaire.daily_feedback.date'),
                          tooltip_column: 'formatted_date',
                          style: { minWidth: '75px' }
                        },
                        {
                          column: 'wellness_sum_p_rounded',
                          label: I18n.t('components.dashboards.questionnaire.daily_feedback.wellness_sum_p_rounded')
                        },
                        {
                          column: 'comments',
                          label: I18n.t('components.dashboards.questionnaire.daily_feedback.comments')
                        }
                      ]}
                      requiredAttribute='comments'
                    />
                  </SpinnerWrapper>
                </div>
              </>
            )}
          </div>
          <div id='week-feedback' className='col s12 tab-content'>
            {!props.sundaySummary && !props.isLoading && (
              <GenericPlaceholder title={I18n.t('components.dashboards.questionnaire.weekly_feedback.title')} />
            )}
            {props.sundaySummary && (
              <>
                <div className='highcharts-plot-title-container'>
                  <div className='highcharts-plot-title'>
                    {I18n.t('components.dashboards.questionnaire.weekly_feedback.title')}
                    <Info
                      text={I18n.t('components.dashboards.questionnaire.weekly_feedback.info')}
                      tooltipId='questionnaire-tooltip-weekly-feedback' inline
                    />
                  </div>
                </div>
                <div className='feedback-table-container'>
                  <SpinnerWrapper ready={!props.isLoading && !props.isError} failed={props.isError && !props.isLoading}>
                    <QuestionnaireFeedbackTable
                      id='weekly_experiences'
                      data={props.sundaySummary}
                      columns={[
                        {
                          column: 'date',
                          label: I18n.t('components.dashboards.questionnaire.weekly_feedback.date'),
                          tooltip_column: 'formatted_date',
                          style: { minWidth: '75px' }
                        },
                        {
                          column: 'weekly_feedback',
                          label: I18n.t('components.dashboards.questionnaire.weekly_feedback.weekly_feedback')
                        }
                      ]}
                      requiredAttribute='weekly_feedback'
                    />
                  </SpinnerWrapper>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
    <MyReactTooltip id='questionnaire-tooltip' effect='solid' className='wider-tooltip' />
  </>
)

export default WithSession(WellbeingQuestionnaireDashboard)
