import React from 'react'
import I18n from 'i18n'
const OauthApplicationEntry = (props) => {
  return (
    <li className='collection-item avatar'>
      <i className='material-icons circle'>lock_outline</i>
      <span className='title'>{props.name}</span>
      <p className='text-muted'> <strong>{I18n.t('components.admin_dashboard.oauth_applications.client_id')}:</strong> {props.client_id}</p>
      <p className='text-muted'> <strong>{I18n.t('components.admin_dashboard.oauth_applications.client_secret')}:</strong> {props.client_secret}</p>
      <p className='text-muted'> <strong>{I18n.t('components.admin_dashboard.oauth_applications.redirect_uri')}:</strong> {props.redirect_uri}</p>
      <p className='text-muted'> <strong>{I18n.t('components.admin_dashboard.oauth_applications.scopes')}:</strong> {props.scopes.join(', ')}</p>
    </li>
  )
}

export default OauthApplicationEntry
