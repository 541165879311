import React, { useState, useEffect, useMemo } from 'react'
import { WithSession } from '../../../session/SessionProvider'
import { WithModal } from '../../../modal/ModalProvider'
import Search from './Search'
import Heatmap from './Heatmap'
import Fuse from 'fuse.js'
import FilteredData from './FilteredData'
import DataOverviewTabs from './DataOverviewTabs'
import useDeepCompareEffect from 'use-deep-compare-effect'
import _, { debounce, get } from 'lodash'
import Uploader from '../Uploader'
import SnapshotCreator from './SnapshotCreator'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'
import WithProfileMetadata from 'components/helpers/WithProfileMetadata'
import IncomingTransferRequestsList from './incoming_transfer_requests/IncomingTransferRequestsList'

const startDateField = 'startDate'
const endDateField = 'endDate'

const DataOverview = (props) => {
  const fuseOptions = {
    shouldSort: true,
    tokenize: true,
    findAllMatches: true,
    // If we set the threshold to 0.0, then it only finds text that matches starting from the start of the string.
    // so if you just type a word that occurs in a title for instance, it won't find it. Hence the 0.3 threshold.
    // It also introduces matches when you wouldn't expect it e.g., things that share 70% of the characters with
    // the search query (because it is fuzzy), but there's no good way around that with fuzzy search.
    threshold: 0.3
  }

  const basicFuseOptions = {
    ...fuseOptions,
    keys: ['title', 'tags', 'owner.first_name', 'owner.last_name', 'sport.name', 'filename']
  }

  const titleFuseOptions = {
    ...fuseOptions,
    keys: ['title']
  }

  const sportFuseOptions = {
    ...fuseOptions,
    // Use exact matching and extended search for sports, because we change the selection from the
    // multi select checkbox into a string like "Sport1" | "Sport2" | "Sport3 with a space".
    // The | to denote OR only works when extended search is enabled.
    // Here we don't need a threshold because we match the full word of the sport every time, not part of a sport.
    threshold: 0.0,
    useExtendedSearch: true,
    keys: ['sport.name']
  }

  const tagsFuseOptions = {
    ...fuseOptions,
    keys: ['tags']
  }

  const firstNameFuseOptions = {
    ...fuseOptions,
    keys: ['owner.first_name']
  }

  const lastNameFuseOptions = {
    ...fuseOptions,
    keys: ['owner.last_name']
  }

  const [basicFuse, setBasicFuse] = useState(new Fuse(props.metadata, basicFuseOptions))

  const [filtered, setFiltered] = useState(undefined)
  const [filtering, setFiltering] = useState(false)
  const defaultIsAdvanced = get(props, 'location.state.advanced', false)
  const [advancedSearch, setAdvancedSearch] = useState(defaultIsAdvanced)
  const [selectedItems, setSelectedItems] = useState([])

  const defaultBasicQuery = get(props, 'location.state.basicQuery', '')
  const [basicQuery, setBasicQuery] = useState(defaultBasicQuery)
  const defaultAdvancedQuery = get(props, 'location.state.advancedQuery', {
    startDate: null,
    endDate: null,
    title: '',
    sport: '',
    tags: '',
    firstName: '',
    lastName: ''
  })
  const [advancedQuery, setAdvancedQuery] = useState(defaultAdvancedQuery)

  /**
   * Extracts the available sports and tags from a list of metadatum objects
   * @param metadata {Array} array of metadatum objects like that returned by processMetadata
   * @returns {Array} an array with two subarrays, one for all unique sports and one for all unique tags
   */
  function extractSportsAndTags (metadata) {
    if (!metadata) return [[], []]

    const zipped = metadata.map(m => [_.get(m, 'sport.name'), m.tags])
    const unzipped = _.unzip(zipped)

    if (unzipped.length !== 2) return [[], []]

    return [_.uniq(_.compact(unzipped[0])).sort(), _.uniq(_.compact(unzipped[1]).flat()).sort()]
  }

  // Refetch metadata when switching between myData/network tabs
  useEffect(() => {
    props.loadMetadata()
  }, [props.network])

  useDeepCompareEffect(() => {
    setBasicFuse(new Fuse(props.metadata, basicFuseOptions))
  }, [props.metadata])

  // Re-apply filter if fuse has changed (e.g. due to clicking on my data/network tab
  useEffect(() => {
    if (advancedSearch) {
      advancedFilter(advancedQuery)
    } else {
      basicFilter(basicQuery)
    }
  }, [basicFuse])

  useEffect(() => {
    // Guard should not be needed, added as extra measure
    if (advancedSearch) {
      advancedFilter(advancedQuery)
    }
  }, [advancedQuery])

  const [availableSports, availableTags] = useMemo(() => { return extractSportsAndTags(props.metadata) }, [props.metadata])

  const startFilter = (metadata, date) => (
    metadata.filter(m => new Date(m.event_start) >= date)
  )

  const endFilter = (metadata, date) => (
    metadata.filter(m => new Date(m.event_end) <= date)
  )

  const basicFilter = debounce((query) => {
    setBasicQuery(query)
    updateHistoryState({ basicQuery: query, advanced: false })
    if (query.length > 0) {
      setFiltered(basicFuse.search(query))
      // set filtering after filtered. Otherwise it can happen that filtering = true but filtered = undefined
      setFiltering(true)
    } else {
      setFiltering(false)
    }
  }, 800, { leading: true })

  // Used to pass filter dates to the heatmap component
  const [endDate, setEndDate] = useState(new Date())

  const advancedFilter = debounce((query) => {
    let filtered = props.metadata
    let nonEmptyValues = false

    const options = {
      title: titleFuseOptions,
      sport: sportFuseOptions,
      tags: tagsFuseOptions,
      firstName: firstNameFuseOptions,
      lastName: lastNameFuseOptions
    }

    let date = new Date()

    for (const [field, value] of Object.entries(query)) {
      if (!value) continue
      nonEmptyValues = true
      if (field === startDateField) {
        filtered = startFilter(filtered, value)
      } else if (field === endDateField) {
        filtered = endFilter(filtered, value)
        date = value
      } else {
        // If extended search is enabled for this search option, then change the query (which is expected to be tokenized
        // by a , (comma)) to a "token1" | "token2" | ... style query.
        // This is currently only enabled for the sport field.
        filtered = (new Fuse(filtered, options[field])).search(options[field].useExtendedSearch ? `"${value.replaceAll('"', '').replaceAll(',', '" | "')}"` : value).map(item => item?.item ? item.item : item)
      }
    }

    setEndDate(date)

    setFiltering(nonEmptyValues)
    setFiltered(filtered)
  }, 800, { leading: true })

  const applyTagFilter = (tag) => {
    setAdvancedSearch(true)
    const updatedQuery = { ...advancedQuery }
    updatedQuery.tags = updatedQuery.tags.concat(` ${tag}`)
    updateHistoryState({ advancedQuery: updatedQuery, advanced: true })
    setAdvancedQuery(updatedQuery)
  }

  const onAdvancedFieldChanged = (field, value) => {
    const updatedQuery = { ...advancedQuery }
    updatedQuery[field] = value
    updateHistoryState({ advancedQuery: updatedQuery, advanced: true })
    setAdvancedQuery(updatedQuery)
  }

  const updateHistoryState = (newState) => {
    props.history.replace(props.location.pathname, newState)
  }

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <DataOverviewTabs
            history={props.history}
            location={props.location}
            myDataObjectCount={props.myProfileMetadata.my_metadata_count || 0}
            networkDataObjectCount={props.myProfileMetadata.my_network_metadata_count || 0}
          />
        </div>
      </div>
      <div className='row'>
        <IncomingTransferRequestsList />
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='data-dropzone'>
            <Uploader {...props} onRequest={props.onRequest} onUploadComplete={() => props.loadMetadata()} justDropzone />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <Search
            onBasicSearch={basicFilter}
            onAdvancedSearch={onAdvancedFieldChanged}
            network={props.network}
            advancedSearch={advancedSearch}
            setAdvancedSearch={setAdvancedSearch}
            advancedQuery={defaultAdvancedQuery}
            setAdvancedQuery={setAdvancedQuery}
            defaultValue={defaultBasicQuery}
            availableSports={availableSports}
            availableTags={availableTags}
            ready={!props.loading}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <Heatmap
            dates={((filtering ? filtered : props.metadata) || []).map(e => e.event_start)}
            endDate={endDate}
          />
        </div>
      </div>
      <CSSTransition timeout={200} classNames='snapshot-card' appear in={selectedItems.length >= 0} mountOnEnter unmountOnExit>
        <div className='row'>
          <div className='col s12'>
            <SnapshotCreator selectedItems={selectedItems} setSelectedItems={value => setSelectedItems(value)} />
          </div>
        </div>
      </CSSTransition>
      <div className='row'>
        <div className='col s12'>
          <FilteredData
            metadata={filtering ? filtered : props.metadata}
            network={props.network}
            ready={!props.loading}
            onTagClick={applyTagFilter}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            onReload={() => props.loadMetadata()}
            searchFilter={basicQuery}
          />
        </div>
      </div>
    </>
  )
}

DataOverview.propTypes = {
  // An array of translated metadatum objects
  metadata: PropTypes.arrayOf(PropTypes.object),
  // Whether the metadata belongs to the profile's network
  network: PropTypes.bool,
  // True if the metadata has not been downloaded yet
  loading: PropTypes.bool,
  // Callback to refetch the metadata
  loadMetadata: PropTypes.func
}

export default WithModal(WithSession(WithProfileMetadata(DataOverview)))
