import React from 'react'
import { MyAxios as axios } from '../MyAxios'
import { WithSession } from '../session/SessionProvider'
import I18n from 'i18n'
import { isEqual } from 'lodash'

export const QuestionnaireContext = React.createContext()

class QuestionnaireProviderCls extends React.Component {
  constructor (props) {
    super(props)
    this._questionnairesBaseUrl = process.env.QUESTIONNAIRES_ENGINE_URL
    this.state = {
      hasOpenResponses: undefined,
      myOpenRestrictedOtrs: [],
      areEnabled: undefined,
      profile: {
        email: undefined,
        mobile_phone: undefined
      },
      pendingProfile: {
        account_active: undefined,
        email: undefined,
        mobile_phone: undefined
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.sessionToken !== this.props.sessionToken && this.isValid()) {
      this.fetchQuestionnaireStatus()
    }
  }

  questionnairesBaseUrl () {
    return this._questionnairesBaseUrl
  }

  componentDidMount () {
    this.isValid() && this.fetchQuestionnaireStatus()
  }

  addBase (url) {
    return `${this.questionnairesBaseUrl()}${url}`
  }

  stateToPendingProfile (state) {
    return {
      account_active: state.areEnabled,
      email: state.profile?.email ?? undefined,
      mobile_phone: state.profile?.mobile_phone ?? undefined
    }
  }

  fetchQuestionnaireStatus () {
    axios({
      method: 'GET',
      url: this.addBase('/api/v1/person/me'),
      headers: { Authorization: this.props.session.sessionToken, 'Content-Type': 'application/json' }
    }).then((response) => {
      const newState = {
        areEnabled: response.data.account_active,
        profile: {
          email: response.data.email,
          mobile_phone: response.data.mobile_phone
        },
        hasOpenResponses: response.data.my_open_responses && response.data.my_open_responses.length > 0,
        myOpenResponses: response.data.my_open_responses,
        myOpenRestrictedOtrs: response.data.my_open_restricted_otrs
      }
      this.setState({ ...newState, pendingProfile: this.stateToPendingProfile(newState) })
    }).catch((err) => {
      console.log('Error retrieving questionnaire status:')
      console.error(err)
      const newState = {
        areEnabled: false,
        hasOpenResponses: false
      }

      this.setState({ ...newState, pendingProfile: this.stateToPendingProfile(newState) })
    })
  }

  fetchMyProtocolSubscriptions (successCallBack = null, errorCallBack = null) {
    axios({
      method: 'GET',
      url: this.addBase('/api/v1/protocol_subscriptions/my_active_and_inactive'),
      headers: { Authorization: this.props.session.sessionToken, 'Content-Type': 'application/json' }
    }).then((response) => {
      this.setState({
        myProtocolSubscriptions: response.data
      })
      if (successCallBack) successCallBack(response.data)
    }).catch((err) => {
      console.log('Error retrieving my protocol subscriptions:')
      console.error(err)
      if (errorCallBack) errorCallBack(err)
    })
  }

  oneTimeResponseUrl (oneTimeResponseToken) {
    return this.addBase(`/api/v1/one_time_response/${oneTimeResponseToken}?token=${encodeURIComponent(this.props.session.sessionToken)}`)
  }

  openQuestionnaireUrl () {
    return this.addBase(`/questionnaire?token=${encodeURIComponent(this.props.session.sessionToken)}`)
  }

  downloadQuestionnaireDefinitionUrl (questionnaireKey) {
    return this.addBase(`/api/v1/questionnaire/${questionnaireKey}.csv?token=${encodeURIComponent(this.props.session.sessionToken)}`)
  }

  questionnaireDefinitionUrl (questionnaireKey) {
    return this.addBase(`/api/v1/questionnaire/${questionnaireKey}?token=${encodeURIComponent(this.props.session.sessionToken)}`)
  }

  questionnaireDefinitionsUrl () {
    return this.addBase(`/api/v1/questionnaire?token=${encodeURIComponent(this.props.session.sessionToken)}`)
  }

  openQuestionnaireUrlForUuid (uuid) {
    return this.addBase(`/questionnaire/${uuid}?token=${encodeURIComponent(this.props.session.sessionToken)}`)
  }

  previewProtocol (protocolName, startDate, endDate) {
    return axios({
      method: 'POST',
      url: this.addBase(`/api/v1/protocol/${protocolName}/preview`),
      headers: { Authorization: this.props.session.sessionToken, 'Content-Type': 'application/json' },
      data: { start_date: startDate, end_date: endDate, open_from_day_uses_start_date_offset: true }
    })
  }

  authenticatedHeaders () {
    return {
      Authorization: this.props.session.sessionToken,
      'Content-Type': 'application/json'
    }
  }

  getMyProfile () {
    const url = this.addBase('/api/v1/person/me')
    return axios({
      method: 'GET',
      url: url,
      headers: this.authenticatedHeaders()
    })
  }

  setPendingProfile (data) {
    this.setState({ ...this.state, pendingProfile: { ...this.state.pendingProfile, ...data } })
  }

  restoreProfileState () {
    this.setState({ ...this.state, pendingProfile: this.stateToPendingProfile(this.state) })
  }

  hasPendingChanges () {
    // compare pendingProfile to the actual profile
    return !isEqual(this.state.pendingProfile, this.stateToPendingProfile(this.state))
  }

  // This does not accept parameters because it syncs the current state to the
  // questionnaire engine. Syncing the state means copying the pending profile
  // to the current state
  async updateMyProfile () {
    const url = this.addBase('/api/v1/person')
    await this.validateProfileEmailAndPhoneNumber()

    const response = await axios({
      method: 'PUT',
      url: url,
      headers: this.authenticatedHeaders(),
      data: this.state.pendingProfile
    })

    // after the update was successful, update the component's state to the pending state.
    const pendingUpdate = {
      areEnabled: this.state.pendingProfile.account_active,
      profile: {
        ...this.state.profile,
        email: this.state.pendingProfile.email,
        mobile_phone: this.state.pendingProfile.mobile_phone
      }
    }

    this.setState({ ...this.state, ...pendingUpdate })

    return response
  }

  async validateProfileEmailAndPhoneNumber () {
    if (this.state.pendingProfile.account_active && (!this.state.pendingProfile.email && !this.state.pendingProfile.mobile_phone)) {
      const error = new Error('validation failed')
      // the below structure represents the same structure that one would see
      // from axios. We emulate a backend response because not every service
      // that uses the questionnaire engine should have this validation.
      error.response = {
        status: 422,
        data: {
          errors: [{
            detail: {
              account_active: [
                I18n.t('questionnaires_edit.toggle.checkbox.need_email_or_phone')
              ]
            }
          }]
        }
      }

      throw error
    }
  }

  isValid () {
    return !!this.props.sessionToken
  }

  render () {
    return (
      <QuestionnaireContext.Provider value={{
        questionnaires: {
          refetchQuestionnaireStatus: this.fetchQuestionnaireStatus.bind(this),
          fetchMyProtocolSubscriptions: this.fetchMyProtocolSubscriptions.bind(this),
          questionnairesBaseUrl: this.questionnairesBaseUrl.bind(this),
          oneTimeResponseUrl: this.oneTimeResponseUrl.bind(this),
          downloadQuestionnaireDefinitionUrl: this.downloadQuestionnaireDefinitionUrl.bind(this),
          questionnaireDefinitionUrl: this.questionnaireDefinitionUrl.bind(this),
          questionnaireDefinitionsUrl: this.questionnaireDefinitionsUrl.bind(this),
          openQuestionnaireUrl: this.openQuestionnaireUrl.bind(this),
          openQuestionnaireUrlForUuid: this.openQuestionnaireUrlForUuid.bind(this),
          previewProtocol: this.previewProtocol.bind(this),
          getMyProfile: this.getMyProfile.bind(this),
          updateMyProfile: this.updateMyProfile.bind(this),
          setPendingProfile: this.setPendingProfile.bind(this),
          restoreProfileState: this.restoreProfileState.bind(this),
          hasPendingChanges: this.hasPendingChanges.bind(this),
          ...this.state
        }
      }}
      >
        {this.props.children}
      </QuestionnaireContext.Provider>
    )
  }
}

export const QuestionnaireProvider = WithSession(QuestionnaireProviderCls)

export const WithQuestionnaires = Component => React.forwardRef((props, ref) => {
  return (
    <QuestionnaireContext.Consumer>
      {(context) => (<Component ref={ref} {...context} {...props} />)}
    </QuestionnaireContext.Consumer>
  )
})
