import React, { useState } from 'react'

// Used for routes that directly link to a certain type of questionnaire dashboard. Since the retrieval
// of questionnaires and the profile selection is now handled outside of these dashboards, they can no longer
// be rendered individually and require this wrapper to keep that state. It also supports reading an ID from
// a route to seed the profile selection.
// These routes are used e.g., when clicking on the "go to dashboard" button for a questionnaire in the
// detailed data view when viewing questionnaire data that another user has shared with you.
const QuestionnaireDashboardProfileWrapper = (props) => {
  // Try to read the user id for whom to open this dashboard from the params if we can
  const { match: { params: { id: routeUserId } = {} } = {} } = props
  // Because parseInt on undefined returns NaN
  const [selectedProfileId, setSelectedProfileId] = useState(routeUserId ? parseInt(routeUserId) : undefined)

  const { component: MyComponent, ...parentProps } = props

  const passedProps = {
    selectedProfileId,
    setSelectedProfileId
  }

  return (
    <MyComponent {...parentProps} {...passedProps} />
  )
}

export default QuestionnaireDashboardProfileWrapper
