import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WithModal } from '../modal/ModalProvider'
import I18n from 'i18n'
import PropTypes from 'prop-types'

const AlertInDashboard = (props) => {
  if (!props.alerts || props.alerts.length === 0) {
    return <></>
  }

  const handleScale = () => {
    props.setModalComponent(AlertContent, props)
  }

  return (
    <div className='alert-icon' onClick={e => handleScale(e)} title=''>
      <FontAwesomeIcon icon='exclamation-triangle' size='lg' className='pointer-grow icon-space-right' />
      {I18n.t('components.dashboards.questionnaire.warnings.show_alerts', { count: props.alerts.length })}
    </div>
  )
}

AlertInDashboard.propTypes = {
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string
    })
  )
}

export default WithModal(AlertInDashboard)

// Private components

const AlertContent = (props) => {
  let alerts = props.alerts
  if (props.mergeTitles) {
    // Create a hash for sorting
    const result = {}
    alerts.forEach((alertEntry) => {
      result[alertEntry.title] = result[alertEntry.title] ? result[alertEntry.title] : []
      result[alertEntry.title].push(alertEntry.description)
    })

    // Convert the hash to an array so we can show it
    let description
    alerts = Object.keys(result).map((key) => {
      description = result[key]
      return { title: key, description: description }
    })
  }

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <div className='text-heavy text-primary-color text-l'>
            {I18n.t('alert.title')}
          </div>
          {props.subTitle && <p>{props.subTitle}</p>}
        </div>
      </div>
      {alerts.map((elem, idx) => <AlertEntry title={elem.title} description={elem.description} key={elem.title} />)}
    </>
  )
}

AlertContent.propTypes = {
  subTitle: PropTypes.string,
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string
    })
  )
}

const AlertEntry = (props) => {
  const renderDescription = (description) => {
    if (!Array.isArray(description)) {
      return description
    }
    return description.join(', ')
  }

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <p>
            <b>{props.title}</b>
          </p>
          {renderDescription(props.description)}
        </div>
      </div>
    </>
  )
}

AlertEntry.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ])
}
