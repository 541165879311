import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { hcRed, hcGreen, hcOrange, hcBlack } from '../../../../common/Constants'
import I18n from 'i18n'
import { initializeHighcharts } from '../../../../common/Utils'

class WeeklyWellnessChart extends React.Component {
  constructor (props) {
    super(props)

    initializeHighcharts(true)
    this.state = {}
  }

  determineColor (wellness) {
    if (wellness <= 40) return hcRed
    if (wellness >= 80) return hcGreen
    if (wellness > 40 && wellness < 80) return hcOrange
    return hcBlack
  }

  nanToNull (num) {
    return Number.isNaN(num) ? null : num
  }

  render () {
    const lineData = this.props.dailyLogRows.map(entry => {
      return {
        x: entry.date_only,
        y: entry.wellness_sum_p,
        formatted_date: entry.formatted_date,
        wellness_sleep: this.nanToNull(entry.wellness_sleep),
        wellness_fatigue: this.nanToNull(entry.wellness_fatigue),
        wellness_stress: this.nanToNull(entry.wellness_stress),
        wellness_soreness: this.nanToNull(entry.wellness_soreness),
        wellness_mood: this.nanToNull(entry.wellness_mood),
        color: this.determineColor(entry.wellness_sum_p)
      }
    })
    return (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              height: 200
            },
            title: I18n.t('components.dashboards.questionnaire.weekly.wellness.title'),
            series: [
              {
                type: 'line',
                data: lineData,
                name: 'Weekly wellness',
                tooltip: {
                  headerFormat: '',
                  pointFormat: I18n.t('components.dashboards.questionnaire.daily_wellness.column.tooltip')
                }
              }
            ],
            plotOptions: {
              series: {
                stacking: 'normal'
              },
              scatter: {
                dataLabels: {
                  enabled: false
                },
                stacking: 'normal',
                enableMouseTracking: true
              },
              line: {
                color: hcBlack,
                marker: {
                  enabled: true
                }
              }
            },
            yAxis: {
              title: {
                text: I18n.t('components.dashboards.questionnaire.daily_wellness.yaxis')
              },
              min: 0
            },
            xAxis: {
              title: {
                text: ''
              },
              type: 'datetime',
              dateTimeLabelFormats: {
                day: '%d %b'
              }
            }
          }}
        />
      </>
    )
  }
}

export default WeeklyWellnessChart
