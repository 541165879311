import React from 'react'
import { defaultProfilePicture } from '../../../common/Constants'
import { Link } from 'react-router-dom'
import I18n from 'i18n'

export interface GroupResultItem {
  id: number
  name: string | null
  picture: string // It's like a path to a blob or an empty string
  slug: string // the public_url but without the /profiles/ part
}

export interface GroupResultProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  item: GroupResultItem
  hide: boolean
}

const GroupResult: React.FC<GroupResultProps> = (props) => {
  const displayName = `${props.item.name ?? ''}`
  return (
    <div className='row no-margin'>
      <div className='col s12 l8 valign-wrapper search-result-margin'>
        <div className='search-result-image-container'>
          <img
            src={props.item.picture !== '' ? props.item.picture : defaultProfilePicture}
            className='responsive-img circle search-result-image'
            alt={displayName}
          />
        </div>
        <div className='overflow-text-elipsis search-result-text'>
          <Link to={`/network/groups/${props.item.id}`} target='_blank'> {displayName}</Link>
        </div>
      </div>
      <div className='col s12 l4 search-result-margin'>
        <button
          onClick={(e) => { props.onClick(e) }}
          className='waves-effect waves-light button-primary text-primary-color background-background text-medium'
        >{I18n.t('network.group.label.open')}
        </button>
      </div>
    </div>
  )
}

export default GroupResult
