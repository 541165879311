import React from 'react'
import Tag from '../../../atomic/atoms/Tag'
import { EMPTY_RECORD } from '../../../common/Constants'

interface SummaryTableEntry {
  key: string
  name: string
  value: any
  units: string
}

interface SummaryTableProps {
  fields?: SummaryTableEntry[]
}

const SummaryTable: React.FC<SummaryTableProps> = ({ fields }): React.ReactElement => {
  if (fields === undefined) return <></>

  const tagify = (field: SummaryTableEntry): React.ReactElement | string => {
    if (field.value === undefined) return (EMPTY_RECORD)

    if (field.key === 'tags') {
      return (<Tag margin='side' tags={field.value.split(',')} />)
    }

    return (<strong>{field.value}</strong>)
  }

  return (
    <div className='collection'>
      {
        fields.map(field => {
          if (field.value === undefined || Number.isNaN(field.value)) return <React.Fragment key={field.name} />
          return (
            <div key={field.name} className='no-margin collection-item' style={{ paddingTop: '20px', paddingBottom: '20px' }}>
              <span className='badge'>{tagify(field)}</span>
              <span className='text-muted'>{field.name}</span>
            </div>
          )
        }
        )
      }
    </div>
  )
}

export default SummaryTable
