import React from 'react'
import I18n from 'i18n'
import HRZones from './HRZones'
import { MyAxios as axios } from '../../../MyAxios'
import jStat from 'jstat'
import HRLaps from './HRLaps'
import { movingAverage } from '../../../common/Math'
import _ from 'lodash'
import moment from 'moment'
import { WithSession } from '../../../session/SessionProvider'
import SelectDropdown from '../../../common/SelectDropdown'

function zipTime (values, dates) {
  return values.map((v, idx) => ({ t: dates[idx], y: v }))
}

class SkatingDashboard extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      yAxisLabel: 'speed_km_h',
      legend: 'speed_legend',
      maxBpm: 200,
      profiles: []
    }

    // The distance between the idx loop and the previous one
    this.splitDistance = {
      1: [11.55, 11.55],
      2: [27.755, 31.945],
      3: [27.755, 31.945],
      4: [27.755, 31.945],
      5: [55.2, 55.2],
      6: [55.2, 55.2],
      7: [27.755, 31.945],
      8: [27.755, 31.945],
      9: [27.755, 31.945],
      10: [23, 23],
      11: [32.31, 32.31],
      12: [43.59, 43.59]
    }
  }

  componentDidMount () {
    M.FormSelect.init(document.querySelector('#skating-dashboard-select-hr'))

    this.fetchSkatingFiles()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevState.hrFiles !== this.state.hrFiles || prevState.lapFiles !== this.state.lapFiles) {
      M.FormSelect.init(document.querySelector('#skating-dashboard-select-hr'))
    }
  }

  fetchSkatingFiles () {
    Promise.all([this.fetchLapFiles(), this.fetchHRFiles()])
      .then((res) => {
        const lapFiles = res[0].data
        const hrFiles = res[1].data
        const files = _.concat(lapFiles, hrFiles)
        const profiles = Object.values(files.reduce((acc, file) => {
          const fullName = `${file.owner.first_name} ${file.owner.last_name}`
          const id = file.owner.id
          acc[id] = { name: fullName, value: id, icon: file.picture }
          return acc
        }, {}))

        const fileFeatures = {
          lapFiles: lapFiles,
          hrFiles: hrFiles,
          profiles: profiles
        }

        if (hrFiles?.length > 0) {
          const hrFeatures = this.processHRData(hrFiles[0].data_rows)
          const defaultHRFile = hrFiles[0].vdo.id
          _.merge(fileFeatures, hrFeatures, { selectedHRFile: defaultHRFile })
        }

        if (lapFiles?.length > 0) {
          const lapFeatures = this.processLapData(lapFiles[0].data_rows)
          const defaultLapFile = lapFiles[0].vdo.id
          _.merge(fileFeatures, lapFeatures, { selectedLapFile: defaultLapFile })
        }

        this.setState(fileFeatures)
      })
  }

  fetchLapFiles () {
    return axios({
      method: 'GET',
      url: '/api/v1/data/find?data_type=ice_skating_lap_type',
      headers: { Authorization: this.props.sessionToken }
    })
  }

  fetchHRFiles () {
    return axios({
      method: 'GET',
      url: '/api/v1/data/find?data_type=ice_skating_hr_type',
      headers: { Authorization: this.props.sessionToken }
    })
  }

  processLapData (dataRows) {
    if (!dataRows) return

    const dates = []

    const vInside = [0]
    const vOutside = [0]
    const aInside = [0]
    const aOutside = [0]

    const loops = {}
    for (let i = 1; i < 13; i++) { loops[i] = [] }

    dataRows.forEach((d, idx) => {
      // Skip if row not understood
      if (!d.timestamp || !d.loop_index) return

      if (idx === 0) {
        loops[d.loop_index].push(parseInt(d.timestamp))
        return
      }

      // Skip if this is a continuation of a lap
      if (d.timestamp === dataRows[idx - 1].timestamp) return

      dates.push(parseInt(d.timestamp))
      loops[d.loop_index].push(parseInt(d.timestamp))
      const deltaT = (d.timestamp - dataRows[idx - 1].timestamp) / 1000 // Increment in seconds

      vInside.push(this.splitDistance[d.loop_index][0] / deltaT * 3.6) // km/h
      vOutside.push(this.splitDistance[d.loop_index][1] / deltaT * 3.6)
      aInside.push((vInside[vInside.length - 1] - vInside[vInside.length - 2]) / deltaT)
      aOutside.push((vOutside[vOutside.length - 1] - vOutside[vOutside.length - 2]) / deltaT)
    })

    return {
      vInside: vInside.slice(1, vInside.length),
      vOutside: vOutside.slice(1, vOutside.length),
      aInside: aInside.slice(1, aInside.length),
      aOutside: aOutside.slice(1, aOutside.length),
      lapDates: dates,
      loopDates: loops
    }
  }

  processHRData (dataRows, newMaxBpm = null) {
    if (!dataRows) return

    const bpms = []
    let baseDate = null
    const dates = []
    dataRows.forEach(r => {
      // If there is no time or bpm field the row has been malformed or is of a type not understood by the dashboard
      if (!r.time || !r.bpm) return
      // For now we can't use the 1st row to set the baseDate because there's now guarantee the row will be the right type
      if (!baseDate) baseDate = new Date(r.date)
      const rowDate = baseDate.setHours(...r.time.split(':'))
      if (!dates.includes(rowDate)) {
        bpms.push(r.bpm)
        dates.push(rowDate)
      }
    })

    const bpms5 = movingAverage(bpms, 5)
    const bpms15 = movingAverage(bpms, 15)
    const bpms30 = movingAverage(bpms, 30)
    const meanBpm = Math.round(jStat.mean(bpms))
    // const maxBpm = jStat.max(bpms)
    const maxBpm = newMaxBpm || this.state.maxBpm
    const varBpm = Math.round(jStat.stdev(bpms, true))

    const zoneBpms = []

    const zoneLimits = []

    // Calculate limits of each hr zone
    for (let z = 0.5; z <= 0.9; z += 0.1) {
      zoneLimits.push([maxBpm * z, maxBpm * (z + 0.1)])
    }

    // Split into each zone
    zoneLimits.forEach(lim => {
      zoneBpms.push(bpms.filter(b => b > lim[0] && b <= lim[1]))
    })

    const minutesHist = _.chain(zoneBpms).map(z => z.length).map(z => z / 60).value() // Interval between each bpm is 1 second
    const percentagesHist = _.chain(zoneBpms).map(z => z.length).map(z => 100 * z / bpms.length).value()

    return {
      bpms: bpms,
      bpms5: bpms5,
      bpms15: bpms15,
      bpms30: bpms30,
      bpmDates: dates,
      meanBpm: meanBpm,
      maxBpm: maxBpm,
      varBpm: varBpm,
      percentagesHist: percentagesHist,
      minutesHist: minutesHist,
      lapsBpms: zipTime(bpms, dates),
      zoneLimits: zoneLimits
    }
  }

  handleSelectProfile () {
    const e = document.getElementById('profile-selector')
    // const selected = e?.target?.value
    let selected = parseInt(e.options[e.selectedIndex].value)
    if (isNaN(selected)) {
      selected = -1
    }

    this.setState({
      selectedProfile: selected
    })
  }

  handleSelectHRFile () {
    const e = document.getElementById('hr-selector')
    let selected = parseInt(e.options[e.selectedIndex].value)
    if (isNaN(selected)) {
      selected = -1
    }
    const hrFeatures = this.processHRData(this.state.hrFiles?.find(f => f.vdo.id === selected)?.data_rows)
    this.setState(_.merge(hrFeatures, { selectedHRFile: selected }))
  }

  handleSelectLapFile () {
    const e = document.getElementById('laps-selector')
    let selected = parseInt(e.options[e.selectedIndex].value)
    if (isNaN(selected)) {
      selected = -1
    }
    const lapFeatures = this.processLapData(this.state.lapFiles?.find(f => f.vdo.id === selected)?.data_rows)
    this.setState(_.merge(lapFeatures, { selectedLapFile: selected }))
  }

  handleMaxBpmChange (e) {
    const newValue = parseInt(e?.target?.value)
    const hrFeatures = this.processHRData(this.state.hrFiles?.find(f => f.vdo.id === this.state.selectedHRFile)?.data_rows, newValue)
    this.setState(hrFeatures)
  }

  handleHRSelect () {
    // const targetHR = parseInt(M.FormSelect.getInstance(e.target).getSelectedValues()[0])
    const e = document.getElementById('laps-select-hr')
    const targetBpms = parseInt(e.options[e.selectedIndex].value)

    let selectedBpms = null

    switch (targetBpms) {
      case 1:
        selectedBpms = this.state.bpms
        break
      case 5:
        selectedBpms = this.state.bpms5
        break
      case 15:
        selectedBpms = this.state.bpms15
        break
      case 30:
        selectedBpms = this.state.bpms30
        break
    }

    this.setState({ lapsBpms: selectedBpms && zipTime(selectedBpms, this.state.bpmDates) })
  }

  handleTransponderSelect () {
    // const targetTransponder =  M.FormSelect.getInstance(e.target).getSelectedValues()[0]
    // this.setState({targetTransponder: targetTransponder})
    const e = document.getElementById('laps-select-speed')
    const targetSpeed = e.options[e.selectedIndex].value

    let selectedSpeed = null
    let yAxisLabel = 'speed_km_h'
    let legend = 'speed_legend'

    switch (targetSpeed) {
      case 'vIn':
        selectedSpeed = this.state.vInside
        break
      case 'vOut':
        selectedSpeed = this.state.vOutside
        break
      case 'aIn':
        selectedSpeed = this.state.aInside
        yAxisLabel = 'accel_m_s'
        legend = 'accel_legend'
        break
      case 'aOut':
        selectedSpeed = this.state.aOutside
        yAxisLabel = 'accel_m_s'
        legend = 'accel_legend'
        break
    }

    this.setState({
      lapsSpeed: selectedSpeed && zipTime(selectedSpeed, this.state.lapDates),
      yAxisLabel: yAxisLabel,
      legend: legend
    })
  }

  getFileOptions (files) {
    if (!files) {
      return []
    }
    return files.map((file) => {
      const optionLabel = `${file.title} (${file.data_rows && file.data_rows.length > 0 && moment(file.start).format('lll')})`
      return { name: optionLabel, value: file.vdo.id }
    })
  }

  render () {
    const profileSelectLabel = I18n.t('components.dashboards.file_selector.profile')
    const lapsSelectLabel = I18n.t('components.dashboards.skating.select_activity')
    const hrSelectLabel = I18n.t('components.dashboards.skating.select_hr')

    const lapFilesDropdownOptions = this.getFileOptions(this.state.lapFiles)
    const hrFilesDropwdownOptions = this.getFileOptions(this.state.hrFiles)

    return (
      <div>
        <div className='row'>
          <div className='col s12 text-xl text-primary-color text-heavy'>
            {I18n.t('components.dashboards.skating.title')}
          </div>
        </div>
        <div className='row'>
          <div className='col s12 m4'>
            <SelectDropdown id='profile-selector' value={this.state.selectedProfile} onChange={this.handleSelectProfile.bind(this)} label={profileSelectLabel} placeholder={profileSelectLabel} content={this.state.profiles} />
          </div>
          <div className='col s12 m4'>
            <SelectDropdown id='laps-selector' value={this.state.selectedLapFile} onChange={this.handleSelectLapFile.bind(this)} label={lapsSelectLabel} placeholder={lapsSelectLabel} content={lapFilesDropdownOptions} />
          </div>
          <div className='col s12 m4'>
            <SelectDropdown id='hr-selector' value={this.state.selectedHRFile} onChange={this.handleSelectHRFile.bind(this)} label={hrSelectLabel} placeholder={hrSelectLabel} content={hrFilesDropwdownOptions} />
          </div>
        </div>

        <div className='row graph-padding'>
          <HRLaps
            {...this.state}
            handleHRSelect={this.handleHRSelect.bind(this)}
            handleTransponderSelect={this.handleTransponderSelect.bind(this)}
          />
        </div>

        <div className='row graph-padding'>
          <HRZones
            {...this.state}
            handleMaxBpmChange={this.handleMaxBpmChange.bind(this)}
          />
        </div>
      </div>
    )
  }
}

export default WithSession(SkatingDashboard)
