import React, { useEffect, useState } from 'react'
import { find, pick, mapValues } from 'lodash'
import I18n from 'i18n'
import { Link } from 'react-router-dom'

import SelectDropdown from '../../../common/SelectDropdown'
import { WithSession } from '../../../session/SessionProvider'
import DashboardMulti from '../../layout/DashboardMulti'
import CoachHomeTab from './CoachHomeTab'
import { WithBackend } from '../../../backend/BackendProvider'
import { COACH_DASHBOARD_TITLES, groupTypes, subscriptionTypes } from '../../../common/Constants'
import CoachTrainingTab from './CoachTrainingTab'
import { willAddAcwr, willAddDatasetsOfChosenDay, willAddScheduledTraining } from './CoachDashboardHelper'
import { getGroupAcceptedMembers, getGroupAcceptedMembersNotAdmin, isCoachOfGroup } from '../../../common/utils/GroupUtils'
import PremiumOverlay from 'components/premium_membership/PremiumOverlay'
import CoachInControlLogo from '../../../../../../public/images/logo_coachincontrol.svg'
import { WithAppsignal } from 'components/appsignal/AppsignalProvider'
import { WithFlashMessages } from 'components/flashmessages/FlashMessageProvider'
import TabPanel, { Tab } from 'components/atomic/atoms/TabPanel'

const CoachDashboardIndividual = (props) => {
  const [groups, setGroups] = useState([])
  const [groupRecentData, setGroupRecentData] = useState([])
  const [selectedGroup, setSelectedGroup] = useState({})
  const [selectedAthlete, setSelectedAthlete] = useState(undefined)
  const [selectedTab, setSelectedTab] = useState('home')
  const [homeTabDay, setHomeTabDay] = useState(new Date())

  const initialCoachAdminState = () => {
    const toggleState = JSON.parse(window.localStorage.getItem('coachAdminToggle'))
    if (typeof toggleState === 'boolean') {
      return toggleState
    }
    window.localStorage.setItem('coachAdminToggle', JSON.stringify(true))
    return true
  }
  const [excludeAdmins, setExcludeAdmins] = useState(initialCoachAdminState)

  const { myProfile, backend, appsignal, flashMessages } = props
  const myProfileId = myProfile?.id
  const groupId = selectedGroup?.id

  const onGroupChange = (e) => {
    const newGroupId = parseInt(e.target.value)
    setSelectedGroup(find(groups, (group) => group.id === newGroupId))
    setSelectedAthlete(undefined)
  }

  const onSelectedAthleteChange = (e) => {
    const newAthleteId = parseInt(e.target.value)
    if (newAthleteId) {
      const newAthlete = find(selectedGroup.group_memberships, (membership) => membership.profile.id === newAthleteId)
      setSelectedAthlete(newAthlete?.profile)
    }
  }

  const onAthleteClick = (athlete) => {
    setSelectedTab('training')
    setSelectedAthlete(athlete)
  }

  const handleHomeTabDayChange = (e) => {
    setHomeTabDay(e)
  }

  // Fetch the groups which the user is, at least, an admin
  useEffect(() => {
    if (!!myProfileId || myProfileId === 0) {
      backend.groups.get().then((fetchedGroups) => {
        const groups = fetchedGroups.data.filter((group) => {
          return [groupTypes.GROUP, groupTypes.ANONYMIZED].includes(group.group_type) && isCoachOfGroup(group.group_memberships, myProfileId)
        })
        setGroups(groups)
      })
    }
  }, [myProfileId])

  // TODO Handle failures?
  // TODO A better way to do this is to know first which people are the athletes  of the group, and then fetch their most recent dataset in the group.
  useEffect(() => {
    setGroupRecentData([])
    setSelectedAthlete(undefined)

    if (!!groupId || groupId === 0) {
      let groupMembers
      if (excludeAdmins) {
        groupMembers = getGroupAcceptedMembersNotAdmin(selectedGroup.group_memberships)
      } else {
        groupMembers = getGroupAcceptedMembers(selectedGroup.group_memberships)
      }
      // Run a data fetching pipeline for each member of the group
      const dataFetchingTasks = groupMembers.map((profile) => {
        const mostRecentDatasets = {}
        return willAddDatasetsOfChosenDay(mostRecentDatasets, backend, groupId, profile.id, homeTabDay)
          .then(() => willAddAcwr(mostRecentDatasets, backend, profile.id, homeTabDay))
          .then(() => willAddScheduledTraining(mostRecentDatasets, backend, homeTabDay))
          .then(() => {
            // Provide an object with the profile and its most recent data
            return Promise.resolve({
              datasets: mostRecentDatasets,
              profile
            })
          })
      })

      // Gather all the athletes and their recent datasets into an object
      Promise.allSettled(dataFetchingTasks).then((fetchedAthletesData) => {
        const groupRecentData = {}
        let errorsFetchingData = false
        fetchedAthletesData.forEach((result) => {
          if (result.status === 'rejected') {
            const errorMessage = 'Could not fetch dataset'
            console.error(errorMessage)
            appsignal.sendError(new Error(errorMessage))
            errorsFetchingData = true
          } else {
            const profile = result?.value?.profile
            groupRecentData[profile?.id] = result?.value
          }
        })

        if (errorsFetchingData) {
          flashMessages.push(
            I18n.t('flashmessages.general.error_retrieving_data'),
            flashMessages.duration.SHORT,
            flashMessages.levels.ERROR
          )
        }
        setGroupRecentData(groupRecentData)
      })
    }
  }, [groupId, homeTabDay, excludeAdmins])

  const groupDropdownOptions = groups.map((group) => {
    const groupOption = pick(group, ['name', 'id'])
    groupOption.value = groupOption.id
    return groupOption
  })

  const atheleteDropdownOptions = selectedGroup?.group_memberships?.map((membership) => (
    {
      name: membership.profile.first_name + ' ' + membership.profile.last_name,
      value: membership.profile.id,
      icon: membership.profile.picture
    }
  )) || []

  const titles = mapValues(COACH_DASHBOARD_TITLES, (translationPath) => I18n.t(translationPath))
  const logos = [
    <Link to={{ pathname: 'https://innovatielab.thialf.nl/projecten/coach-in-control' }} key='cic' target='_blank'>
      <img alt='Coach in Control' src={CoachInControlLogo} className='coach-in-control-logo' />
    </Link>
  ]

  const setExcludeAdminsPersistent = () => {
    window.localStorage.setItem('coachAdminToggle', JSON.stringify(!excludeAdmins))
    setExcludeAdmins(!excludeAdmins)
  }

  return (
    <PremiumOverlay activeFor={[subscriptionTypes.coach, subscriptionTypes.researcher]} showBadge={false}>
      <DashboardMulti
        title={I18n.t('components.dashboards.coach_individual.title')}
        logos={logos}
        ready
      >
        <div className='row'>
          <div className='col s12'>
            <SelectDropdown
              content={groupDropdownOptions}
              placeholder={I18n.t('components.dashboards.select_dropdown.group')}
              id='select-group-dropdown'
              label={I18n.t('components.dashboards.select_dropdown.group')}
              onChange={onGroupChange}
            />
          </div>
        </div>
        <TabPanel id='coach-dashboard-tabs' onShow={setSelectedTab} selectedTab={selectedTab}>
          <Tab id='home' title={I18n.t('components.dashboards.coach_individual.home.title')}>
            <CoachHomeTab
              athletesEntryData={groupRecentData} titles={titles}
              day={homeTabDay} onDayChange={handleHomeTabDayChange}
              onAthleteClick={onAthleteClick} excludeAdmins={excludeAdmins} setExcludeAdmins={setExcludeAdminsPersistent}
            />
          </Tab>
          <Tab id='training' title={I18n.t('components.dashboards.coach_individual.training.title')}>
            <>
              <SelectDropdown
                content={atheleteDropdownOptions}
                placeholder={I18n.t('components.dashboards.select_dropdown.athlete')}
                id='select-athlete-dropdown'
                label={I18n.t('components.dashboards.select_dropdown.athlete')}
                onChange={onSelectedAthleteChange}
                defaultValue={selectedAthlete?.id}
                value={selectedAthlete?.id}
              />
              <CoachTrainingTab athleteProfile={selectedAthlete} titles={titles} groupId={groupId} selectedHomeTabDate={homeTabDay} />
            </>
          </Tab>
        </TabPanel>
      </DashboardMulti>
    </PremiumOverlay>
  )
}

export default WithFlashMessages(WithAppsignal(WithBackend(WithSession(CoachDashboardIndividual))))
