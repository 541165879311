import React from 'react'
import Spinner from './Spinner'
import GenericPlaceholder from 'components/common/GenericPlaceholder'
import I18n from 'i18n'

const SpinnerWrapper = ({ ready, children, transparent, failed = false }) => {
  return (
    <>
      {!failed &&
        <>
          <Spinner ready={ready} transparent={transparent} />
          {ready && children}
        </>}
      {failed && <> <GenericPlaceholder message={I18n.t('components.dashboards.questionnaire.chart.error')} /> </>}
    </>
  )
}

export default SpinnerWrapper
