import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { hcBlack, hcOrange, hcRed, hcGreen, hcLightGreen } from '../../../../common/Constants'
import I18n from 'i18n'
import GenericPlaceholder from '../../../../common/GenericPlaceholder'
import { initializeHighcharts, titleWithTooltip } from '../../../../common/Utils'

const TrainingAcuteChronicWorkloadRatioChart = props => {
  if (!props.weeklySummary || props.weeklySummary.length === 0) {
    return (
      <GenericPlaceholder
        title={I18n.t('components.dashboards.questionnaire.training_acute_chronic_workload_ratio.title')}
      />
    )
  }

  const calculateColor = entry => {
    if (entry.training_ac_radio_rpe_coupled <= 0.8) return hcOrange
    if (entry.training_ac_radio_rpe_coupled >= 1.5) return hcRed
    return hcGreen
  }

  const scatterData = props.weeklySummary.map(entry => {
    return {
      x: entry.date_only,
      y: entry.training_ac_radio_rpe_coupled,
      color: calculateColor(entry),
      wknum: entry.wknum_formatted,
      training_week_date: entry.formatted_date,
      training_ac_ratio_rpe_coupled: entry.training_ac_radio_rpe_coupled,
      training_load_acute: entry.training_load,
      training_load_chronic_coupled: entry.training_load_moving_average
    }
  })
  initializeHighcharts(true)

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          title: titleWithTooltip(
            I18n.t('components.dashboards.questionnaire.training_acute_chronic_workload_ratio.title'),
            I18n.t('components.dashboards.questionnaire.training_acute_chronic_workload_ratio.info')
          ),
          series: [
            {
              type: 'scatter',
              data: scatterData,
              name: '⬤',
              tooltip: {
                headerFormat: '',
                pointFormat: I18n.t('components.dashboards.questionnaire.training_acute_chronic_workload_ratio.scatter.tooltip')
              }
            }
          ],
          plotOptions: {
            line: {
              color: hcBlack,
              marker: {
                enabled: false
              }
            },
            series: {
              animation: false
            }
          },
          yAxis: {
            title: {
              text: I18n.t('components.dashboards.questionnaire.training_acute_chronic_workload_ratio.yaxis')
            },
            min: 0,
            max: 4,
            plotBands: [{
              color: hcLightGreen,
              from: 0.8,
              to: 1.3
            }]
          },
          xAxis: {
            title: {
              text: ''
            },
            type: 'datetime',
            dateTimeLabelFormats: {
              day: '%d %b'
            }
          }
        }}
      />
    </>
  )
}

export default TrainingAcuteChronicWorkloadRatioChart
