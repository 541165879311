import React from 'react'
import classNames from 'classnames'

const Field = (props) => {
  const { title, value, extraClasses, asTableRow, compact } = props
  if (!value || value === '0' || value === '0.0') {
    return <></>
  }
  const titleClasses = 'text-s text-muted'
  const fieldClasses = classNames('text-m text-black text-heavy', extraClasses)

  if (asTableRow) {
    return (
      <tr>
        <td className={titleClasses}>{title}</td>
        <td className={fieldClasses}>{value}</td>
      </tr>
    )
  }
  return (
    <div className={` ${compact ? 'sport-field-compact' : 'sport-field'}`}>
      <div className={titleClasses}>{title}</div>
      <div className={fieldClasses}>{value}</div>
    </div>
  )
}

export default Field
