import React from 'react'
import I18n from 'i18n'
import { Bar } from 'react-chartjs-2'
import { soccerMetrics } from './Constants'

export default class OrderedBarGraph extends React.Component {
  higherThan (a, b) { return b.value - a.value }
  lowerThan (a, b) { return a.value - b.value }

  sortData (values, ordering) {
    if (ordering === 'ascending') {
      return values.sort(this.lowerThan)
    }
    if (ordering === 'descending') {
      return values.sort(this.higherThan)
    }
    return values
  }

  render () {
    const sortedData = this.sortData(this.props.data, this.props.ordering)
    const sortedLabels = sortedData.map(datum => datum.label)
    const sortedValues = sortedData.map(datum => datum.value)
    const sortedColors = sortedData.map(datum => datum.color)
    let title = ''
    if (soccerMetrics[this.props.metric] && 'title' in soccerMetrics[this.props.metric]) {
      title = I18n.t(soccerMetrics[this.props.metric].title)
    }
    const data = {
      labels: sortedLabels,
      datasets: [{
        label: title,
        data: sortedValues,
        backgroundColor: sortedColors
      }]
    }
    const options = {
      scales: {
        yAxes: [{
          display: true,
          ticks: {
            beginAtZero: true // minimum value will be 0.
          }
        }]
      }
    }
    return (
      <div className='ordered-bar-graph'>
        <Bar data={data} options={options} />
      </div>
    )
  }
}
