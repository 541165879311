import React, { useState } from 'react'
import I18n from 'i18n'
import Button from '../../atomic/atoms/Button'
import DeletButton from '../../atomic/atoms/DeleteButton'
const ConfirmUsers = (props) => {
  const [email, setEmail] = useState('')
  return (
    <>
      <div className='row'>
        <div className='col s12 text-heavy muted-header'>
          <h5>
            {I18n.t('components.admin_dashboard.user_controller.title')}
          </h5>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <form>
            <div className='row'>
              <div className='col s12 m6'>
                <div className='input-field'>
                  <input
                    name='email'
                    id='email'
                    type='text'
                    value={email} onChange={e => setEmail(e.target.value)}
                  />
                  <label htmlFor='email'>
                    {I18n.t('components.admin_dashboard.user_controller.email')}
                  </label>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col s12'>
                <Button onClick={(e) => { e.preventDefault(); props.handleSubmit(email) }} buttonPrimitive='button' autowidth>
                  {I18n.t('components.admin_dashboard.user_controller.submit')}
                </Button>
                <DeletButton onClick={(e) => { e.preventDefault(); props.handleDelete(email) }}>
                  {I18n.t('components.admin_dashboard.user_controller.delete.title')}
                </DeletButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default ConfirmUsers
