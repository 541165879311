import React from 'react'
import classNames from 'classnames'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'

const PAGES_IN_VIEW = 10
const HALFPAGES = PAGES_IN_VIEW / 2

interface PaginationPageLinkProps {
  page: number
  onClick: () => void
  active: boolean
}

const PaginationPageLink: React.FC<PaginationPageLinkProps> = ({ page, onClick, active }) => {
  const klassName = classNames('page-link', { active: active })
  return (
    <a className={klassName} onClick={onClick}>
      {page}
    </a>
  )
}

const FillerDots: React.FC = () => {
  return <span className='page-dots'><FontAwesomeIcon icon={faEllipsisH} /></span>
}

interface NumericPaginationNavigationProps {
  firstPage: number
  lastPage: number
  activePage: number
  onNavigate: (arg0: number) => void
}

const NumericPaginationNavigation: React.FC<NumericPaginationNavigationProps> = ({ firstPage, lastPage, activePage, onNavigate }) => {
  const prevDisabled = firstPage === activePage
  const nextDisabled = lastPage === activePage

  // Define extra pages on left/right as the pages that arent used on the
  // other side. For example, if the firstPage = activePage = 1 and there should
  // be 5 pages on either side of the active page, we are currently displaying 0
  // pages left of the active page, so we display 5 extra pages on the right
  const extraRight = HALFPAGES - Math.min(HALFPAGES, activePage - firstPage)
  const extraLeft = HALFPAGES - Math.min(HALFPAGES, lastPage - activePage)

  const pageWithinView = (page: number): boolean => {
    return page > (activePage - HALFPAGES - extraLeft) && page < (activePage + HALFPAGES + extraRight)
  }

  // When display a ... element in the pages, we take the slot of 1 extra
  // middle page. This is because if we optionally add one "..." element we may
  // as well have added an extra page
  const stripFillerSlot = (page: number): boolean => {
    const stripFirst = !pageWithinView(firstPage + 1) && page === activePage - HALFPAGES + 1
    const stripLast = !pageWithinView(lastPage - 1) && page === activePage + HALFPAGES - 1

    return !stripFirst && !stripLast
  }

  const pages = Array(lastPage - firstPage + 1)
    .fill(firstPage)
    .map((page, idx) => parseInt(page) + idx)
    .filter(pageWithinView)
    .filter(stripFillerSlot)

  const handlePrevious = (): void => {
    if (prevDisabled) {
      return
    }

    onNavigate(activePage - 1)
  }

  const handleNext = (): void => {
    if (nextDisabled) {
      return
    }

    onNavigate(activePage + 1)
  }

  const renderSkipFirstPage = !pages.includes(firstPage)
  const renderSkipLastPage = firstPage !== lastPage && !pages.includes(lastPage)
  const renderDotsLeft = pages.length !== PAGES_IN_VIEW && pages[0] > firstPage + 1
  const renderDotsRight = pages.length !== PAGES_IN_VIEW && pages[pages.length - 1] < lastPage - 1

  return (
    <div className='pagination'>
      <a className={classNames('iterate-link', { disabled: prevDisabled })} onClick={handlePrevious}>{I18n.t('pagination.previous')}</a>
      {renderSkipFirstPage && <PaginationPageLink page={firstPage} onClick={() => onNavigate(firstPage)} active={false} />}
      {renderDotsLeft && <FillerDots />}
      {pages.map(p => <PaginationPageLink key={p} page={p} onClick={() => onNavigate(p)} active={activePage === p} />)}
      {renderDotsRight && <FillerDots />}
      {renderSkipLastPage && <PaginationPageLink page={lastPage} onClick={() => onNavigate(lastPage)} active={false} />}
      <a className={classNames('iterate-link', { disabled: nextDisabled })} onClick={handleNext}>{I18n.t('pagination.next')}</a>
    </div>
  )
}

export default NumericPaginationNavigation
