import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { hcBlack, hcOrange } from '../../../../common/Constants'
import I18n from 'i18n'
import { initializeHighcharts, titleWithTooltip } from '../../../../common/Utils'
import GenericPlaceholder from '../../../../common/GenericPlaceholder'

class TrainingIntensityDistributionChart extends React.Component {
  constructor (props) {
    super(props)

    initializeHighcharts(true)
    this.state = {}
  }

  render () {
    if (!this.props.data && !this.props.isLoading) {
      return (
        <GenericPlaceholder title={I18n.t('components.dashboards.questionnaire.training_intensity_distribution.title')} />
      )
    }

    const data = this.props.data.map(entry => {
      return {
        x: entry.value,
        y: entry.percentage
      }
    })
    return (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            title: titleWithTooltip(
              I18n.t('components.dashboards.questionnaire.training_intensity_distribution.title'),
              I18n.t('components.dashboards.questionnaire.training_intensity_distribution.info')
            ),
            subtitle: {
              text: I18n.t('components.dashboards.questionnaire.training_intensity_distribution.subtitle')
            },
            series: [
              {
                type: 'area',
                data: data
              },
              {
                type: 'scatter',
                data: data,
                tooltip: {
                  headerFormat: '',
                  pointFormat: I18n.t('components.dashboards.questionnaire.training_intensity_distribution.scatter.tooltip')
                }
              }
            ],
            plotOptions: {
              series: {
                stacking: 'normal',
                animation: false
              },
              area: {
                color: hcBlack,
                fillOpacity: 0.3
              },
              scatter: {
                color: hcOrange
              }
            },
            yAxis: {
              title: {
                text: I18n.t('components.dashboards.questionnaire.training_intensity_distribution.yaxis')
              },
              min: 0
            },
            xAxis: {
              title: {
                text: I18n.t('components.dashboards.questionnaire.training_intensity_distribution.xaxis')
              },
              min: 0,
              max: 10
            }
          }}
        />
      </>
    )
  }
}

export default TrainingIntensityDistributionChart
