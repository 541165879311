import React from 'react'
import Tabs from '../../../common/Tabs'
import Routes from '../../../common/Routes'

const DataOverviewTabs = (props) => {
  const tabs = [
    {
      title: 'components.timeline.tabs.my_data',
      subtitle: 'components.timeline.tabs.my_data_count',
      subtitleParams: { count: props.myDataObjectCount },
      name: 'my_data',
      path: Routes.data.index
    }, {
      title: 'components.timeline.tabs.my_network',
      subtitle: 'components.timeline.tabs.my_network_count',
      subtitleParams: { count: props.networkDataObjectCount },
      name: 'my_network',
      path: Routes.data.network.index
    }
  ]
  return (
    <Tabs tabs={tabs} history={props.history} location={props.location} defaultActive={Routes.data.index} />
  )
}

export default DataOverviewTabs
