import React from 'react'

function ShowIfStage ({ stage: myStage, currentStage, children }) {
  if (myStage === currentStage) {
    return children
  }

  return <></>
}

export default ShowIfStage
