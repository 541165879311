import React from 'react'
import _, { isEmpty, isObject } from 'lodash'
import SummaryTable from '../../../analysis/dashboards/common/SummaryTable'
import { secondsToHrMin } from 'components/common/Utils'
import I18n from 'i18n'

interface GarminData {
  active_kilocalories: number
  active_time_in_seconds: number
  activity_stress_duration_in_seconds: number
  activity_type: string
  average_heart_rate_in_beats_per_minute: number
  average_stress_level: number
  bmr_kilocalories: number
  calendar_date: string
  distance_in_meters: number
  duration_in_seconds: number
  floors_climbed: number
  floors_climbed_goal: number
  high_stress_duration_in_seconds: number
  intensity_duration_goal_in_seconds: number
  low_stress_duration_in_seconds: number
  max_heart_rate_in_beats_per_minute: number
  max_stress_level: number
  medium_stress_duration_in_seconds: number
  min_heart_rate_in_beats_per_minute: number
  moderate_intensity_duration_in_seconds: number
  rest_stress_duration_in_seconds: number
  resting_heart_rate_in_beats_per_minute: number
  start_time_in_seconds: number
  start_time_offset_in_seconds: number
  steps: number
  steps_goal: number
  stress_duration_in_seconds: number
  stress_qualifier: string
  summary_id: string
  vigorous_intensity_duration_in_seconds: number
  time_offset_body_battery_values: number[]
  sleep_duration_in_seconds: number
  sleep_start_time_in_seconds: number
  sleep_start_time_offset_in_seconds: number
  unmeasurable_sleep_in_seconds: number
  deep_sleep_duration_in_seconds: number
  light_sleep_duration_in_seconds: number
  rem_sleep_in_seconds: number
  awake_duration_in_seconds: number
  time_offset_heart_rate_samples: number[]
  user_id: number
  user_access_token: string
}

type GarminKeys = keyof GarminData

interface GarminDetailViewProps {
  data: {
    data_rows: GarminData[]
  }
}

const GarminDetailView: React.FC<GarminDetailViewProps> = ({ data }) => {
  const summary: GarminData = _.get(data, 'data_rows[0]')
  if (_.isEmpty(summary)) {
    return <p>Summary data not available</p>
  }

  const omittedKeys: GarminKeys[] = [
    'time_offset_heart_rate_samples',
    'user_id',
    'user_access_token',
    'summary_id',
    'calendar_date',
    'activity_type',
    'start_time_in_seconds',
    'start_time_offset_in_seconds',
    'sleep_start_time_in_seconds',
    'sleep_start_time_offset_in_seconds'
  ]

  const durationKeys = [
    'active_time_in_seconds',
    'activity_stress_duration_in_seconds',
    'duration_in_seconds',
    'high_stress_duration_in_seconds',
    'intensity_duration_goal_in_seconds',
    'low_stress_duration_in_seconds',
    'medium_stress_duration_in_seconds',
    'moderate_intensity_duration_in_seconds',
    'rest_stress_duration_in_seconds',
    'stress_duration_in_seconds',
    'vigorous_intensity_duration_in_seconds',
    'sleep_duration_in_seconds',
    'unmeasurable_sleep_in_seconds',
    'deep_sleep_duration_in_seconds',
    'light_sleep_duration_in_seconds',
    'rem_sleep_in_seconds',
    'awake_duration_in_seconds'
  ]

  const sections: Record<string, GarminKeys[]> = {
    physical_activity: [
      'active_time_in_seconds',
      'distance_in_meters',
      'floors_climbed',
      'floors_climbed_goal',
      'steps',
      'steps_goal',
      'intensity_duration_goal_in_seconds',
      'moderate_intensity_duration_in_seconds',
      'vigorous_intensity_duration_in_seconds'
    ],
    energy: [
      'active_kilocalories',
      'bmr_kilocalories'
    ],
    heart_rate: [
      'average_heart_rate_in_beats_per_minute',
      'max_heart_rate_in_beats_per_minute',
      'min_heart_rate_in_beats_per_minute',
      'resting_heart_rate_in_beats_per_minute'
    ],
    stress: [
      'time_offset_body_battery_values',
      'average_stress_level',
      'max_stress_level',
      'stress_qualifier',
      'activity_stress_duration_in_seconds',
      'high_stress_duration_in_seconds',
      'low_stress_duration_in_seconds',
      'medium_stress_duration_in_seconds',
      'rest_stress_duration_in_seconds',
      'stress_duration_in_seconds'
    ],
    sleep: [
      'sleep_duration_in_seconds',
      'unmeasurable_sleep_in_seconds',
      'deep_sleep_duration_in_seconds',
      'light_sleep_duration_in_seconds',
      'rem_sleep_in_seconds',
      'awake_duration_in_seconds'
    ]
  }

  const isIncludedInOmittedKeys = (key: GarminKeys): boolean => {
    return omittedKeys.includes(key)
  }

  const replaceKeyPart = (key: GarminKeys, target: string, replacement: string = ''): GarminKeys => {
    return key.replace(target, replacement) as GarminKeys
  }

  interface ProcessedField {
    name: string
    value: string | number | number[]
    key: string
    units: string
  }
  const processKey = (key: GarminKeys, value: GarminData[GarminKeys]): ProcessedField[] => {
    if (durationKeys.includes(key)) {
      value = secondsToHrMin(value)
      key = replaceKeyPart(key, '_in_seconds')
    }

    if (key === 'time_offset_body_battery_values' && isObject(value) && !isEmpty(value)) {
      const avg = _.mean(Object.values(value))
      value = avg.toFixed(2)
      key = replaceKeyPart(key, 'values', 'average')
    }

    if (isObject(value) && !isEmpty(value)) {
      return Object.entries(value).map(([subKey, subValue]) => ({
        name: `${key}.${subKey}`,
        value: subValue,
        key: `${key}.${subKey}`,
        units: ''
      }))
    } else {
      return [{
        name: I18n.t(`components.dashboards.garmin.${key}`),
        value,
        key,
        units: ''
      }]
    }
  }

  const sectionFields = Object.entries(sections).map(([, keys]) =>
    keys.flatMap((key: keyof GarminData) => {
      if (isEmpty(summary) || isIncludedInOmittedKeys(key) || !(key in summary)) return []

      return processKey(key, summary[key])
    })
  )

  return (
    <div>
      {sectionFields.map((fields, index) => (
        <div key={Object.keys(sections)[index]}>
          <div className='text-heavy text-l text-muted data-header'>
            {I18n.t(`components.dashboards.sections.${Object.keys(sections)[index]}`)}
          </div>
          <SummaryTable fields={fields} />
        </div>
      ))}
    </div>
  )
}

export default GarminDetailView
