import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function SharingIcon ({ item }) {
  let faIcon

  if (item.type === 'tag') {
    faIcon = 'hashtag'
  } else if (item.obj.icon === 'futbol') {
    faIcon = ['far', 'futbol']
  } else {
    faIcon = item.obj.icon
  }

  return (
    <FontAwesomeIcon icon={faIcon} color='#bbb' />
  )
}

export default SharingIcon
