import React from 'react'
import { Route } from 'react-router'

import DataOverview from './layout/data_overview/DataOverview'
import Uploader from './layout/Uploader'
import ShowData from './layout/ShowData'
import EditData from './layout/EditData'
import NotFoundWrapper from '../router/NotFoundWrapper'
import Routes from '../common/Routes'

export default class DataRoutes extends React.Component {
  render () {
    const { uploaderProps, networkDataOverviewProps, showProps, editProps, myDataOverviewProps } = this.props
    return (
      <NotFoundWrapper slug='data'>
        <Route
          exact path={Routes.data.index}
          render={(props) => (<DataOverview {...props} {...myDataOverviewProps} {...uploaderProps} onRequest={this.props.onRequest} sessionToken={this.props.sessionToken} />)}
        />
        <Route
          exact path={Routes.data.network.index}
          render={(props) => (<DataOverview {...props} {...networkDataOverviewProps} sessionToken={this.props.sessionToken} />)}
        />
        <Route
          exact path={Routes.data.upload.index}
          render={(props) => (<Uploader {...props} {...uploaderProps} onRequest={this.props.onRequest} sessionToken={this.props.sessionToken} />)}
        />
        <Route
          exact path={Routes.data.show}
          render={(props) => (<ShowData {...props} {...showProps} />)}
        />
        <Route
          exact path={Routes.data.edit}
          render={(props) => (<EditData {...props} {...editProps} sessionToken={this.props.sessionToken} />)}
        />
      </NotFoundWrapper>
    )
  }
}
