import React, { useEffect, useRef, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import _ from 'lodash'
import PropTypes from 'prop-types'

/**
 * This component can be Controlled or Uncontrolled
 * If controlled, then state is not used. If uncontrolled, the source of truth is in the state
 *
 * Controlled Component: source of truth = values
 * Uncontrolled Component: source of truth = state
 */
const InputGroup = (props) => {
  const [filters, setFilters] = useState({})

  const { onChange, inputComponents, values, disabled } = props
  const isControlled = !!values

  const handleChangeControlled = (name, value) => {
    const currentFilters = _.cloneDeep(values)
    currentFilters[name] = value
    onChange(currentFilters)
  }

  const handleChangeUncontrolled = (name, value) => {
    const currentFilters = _.cloneDeep(filters)
    currentFilters[name] = value
    setFilters(currentFilters)
  }

  // Combining the handlers allows Filters to combine all the filter values in the onChange callback
  // while allowing each input component to perform its own extra functionality in the handler.
  const getCombinedHandler = (inputComponent) => {
    const originalHandler = inputComponent.props.onChange || (() => {})
    const filterChangeHandler = isControlled ? handleChangeControlled : handleChangeUncontrolled

    return (value) => {
      filterChangeHandler(inputComponent.props.name, value)
      originalHandler(value)
    }
  }

  // Notifies parent component of the filters change (Uncontrolled)
  useDeepCompareEffect(() => {
    if (!isControlled) {
      onChange(filters)
    }
  }, [filters]) // WARNING: Does not trigger if new (single-choice) dropdown value is the same the previous one, even if the names of the choice are different

  // Get the previous values
  const getPreviousValues = (values) => {
    const prevValuesRef = useRef()
    useEffect(() => {
      prevValuesRef.current = values
    }, [values])
    return prevValuesRef.current
  }

  // Detects if component changed from controlled to uncontrolled or vice versa
  const warnIfControlChange = (prevValues, values) => {
    const firstUpdate = useRef(true)
    // On the first render, the control cannot have been changed and prevValues is always undefined (even in a controlled component)
    if (firstUpdate.current) {
      firstUpdate.current = false
    } else {
      if ((prevValues === undefined && values !== undefined) || (prevValues !== undefined && values === undefined)) {
        console.warn(
          'Filters components must be either controlled or uncontrolled. ' +
          'Either specify the values prop or leave it undefined for the whole lifetime of the component'
        )
      }
    }
  }

  warnIfControlChange(getPreviousValues(values), values)

  // TODO Make the label active only when a value is selected (for each filter)
  return (
    <div className='row'>
      {inputComponents && inputComponents.map((component) => {
        if (!component.props.name) {
          console.warn(
            `${component.type.name} does not have a name prop. ` +
            'The input values from this component might not show up in the object passed in the onChange callback.'
          )
        }

        // Add extra props to the input components
        return React.cloneElement(component,
          {
            ...(component.props),
            onChange: getCombinedHandler(component),
            value: values?.[component.props.name], // If Filters is a controlled component
            disabled: disabled
          })
      })}
    </div>
  )
}

InputGroup.propTypes = {
  // Callback function that gets called whenever one of the filters changes
  onChange: PropTypes.func,
  // Input components to render
  inputComponents: PropTypes.arrayOf(PropTypes.element),
  // The values of each of the filters. If passed, makes the component behave as a controlled component
  values: PropTypes.object,
  // Disables all the input components
  disabled: PropTypes.bool
}

export default InputGroup
