import { COMPLAINTS_QUESTIONAIRES } from 'components/common/Constants'

const protocolSubscriptionIsOstrc = (protocolSubscription) => {
  // Having multiple questionnaires for protocol subscription is a no-op for calculating compliance, so we also filter
  // those out
  return protocolSubscription.questionnaires?.length === 1 &&
    COMPLAINTS_QUESTIONAIRES.includes(protocolSubscription.questionnaires[0])
}

const isWithinDates = (protocolCompletion, startDate, endDate) => {
  const mDate = new Date(protocolCompletion.open_from)
  return startDate <= mDate && endDate >= mDate
}

export const calculateGroupCompliance = (protocolSubscriptions, startDate, endDate) => {
  if (!protocolSubscriptions || protocolSubscriptions.length < 1) {
    return [undefined, undefined]
  }

  // protocols must be of the correct kind of questionnaire
  const applicableSubscriptions = protocolSubscriptions.filter(sub => protocolSubscriptionIsOstrc(sub))

  // extract all the protocol completion information from each protocol subscription, and filter them based on
  // start and end dates
  const completions = applicableSubscriptions.reduce((completions, subscription) => {
    subscription?.protocol_completion?.forEach((completion) => {
      if (isWithinDates(completion, startDate, endDate)) {
        completions.push(completion)
      }
    })
    return completions
  }, [])

  // Filter for open measurements (measurements where the open_from is within the start date and right now)
  const openMeasurements = completions.filter(completion => {
    return isWithinDates(completion, startDate, new Date())
  })

  const totalCount = openMeasurements.length
  const filledInCount = openMeasurements.filter(completion => completion.completed).length

  return [filledInCount, totalCount === 0 ? undefined : totalCount]
}
