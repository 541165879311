import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { hcRed, hcGreen, hcBlack, MAX_HR, MAX_SLEEP_DURATION } from 'components/common/Constants'
import I18n from 'i18n'
import { initializeHighcharts, titleWithTooltip } from 'components/common/Utils'
import { determineColor, nanToNull } from 'components/analysis/dashboards/questionnaire/weekly/WellbeingUtils'
import GenericPlaceholder from 'components/common/GenericPlaceholder'
import _ from 'lodash'

/**
 * This chart takes dailyLogRows as data
 */

class DailyWellnessChart extends React.Component {
  constructor (props) {
    super(props)

    initializeHighcharts(true)
    this.state = {}
  }

  render () {
    const { data, withRestingHr, withSleepDuration } = this.props

    if (!data) {
      return (
        <GenericPlaceholder title={I18n.t('components.dashboards.questionnaire.daily_wellness.title')} />
      )
    }

    // Remove duplicates
    const dataNoDuplicates = _.uniqBy(data, 'formatted_date')

    // map to graph data without duplicates
    const wellbeingScoreData = dataNoDuplicates.map(entry => {
      return {
        x: entry.date_only,
        y: entry.wellness_sum_p,
        formatted_date: entry.formatted_date,
        wellness_sleep: nanToNull(entry.wellness_sleep),
        wellness_fatigue: nanToNull(entry.wellness_fatigue),
        wellness_stress: nanToNull(entry.wellness_stress),
        wellness_soreness: nanToNull(entry.wellness_soreness),
        wellness_mood: nanToNull(entry.wellness_mood),
        color: determineColor(entry.wellness_sum_p)
      }
    })
    const wellnessMovingAvgData = dataNoDuplicates.map(entry => {
      return {
        x: entry.date_only,
        y: entry.wellness_moving_average,
        formatted_date: entry.formatted_date
      }
    })
    const sicknessData = dataNoDuplicates.map(entry => {
      return {
        x: entry.date_only,
        y: entry.sick === 1 ? -5 : null, // -5 so that it appears below the x-axis on the gray bar, acting as a status indicator.
        formatted_date: entry.formatted_date
      }
    })

    const yAxisOptions = [{
      title: {
        text: I18n.t('components.dashboards.questionnaire.daily_wellness.yaxis')
      },
      min: -9,
      max: 100,
      tickInterval: 10,
      startOnTick: false,
      plotBands: [{
        color: 'rgba(0,0,0, 0.3)',
        borderColor: 'black',
        borderWidth: 0.5,
        from: -7.5,
        to: -2.5,
        label: {
          text: ''
        }
      }]
    }]

    let restingHRSeries
    let restingHrIndex
    if (withRestingHr === true) {
      restingHrIndex = yAxisOptions.length
      yAxisOptions.push(
        {
          title: {
            text: I18n.t('components.dashboards.questionnaire.daily_wellness.resting_hr.yaxis'),
            style: {
              color: hcRed
            }
          },
          labels: {
            style: {
              color: hcRed
            }
          },
          min: -24,
          softMax: MAX_HR,
          tickInterval: 25,
          startOnTick: false,
          opposite: true
        }
      )

      const restingHRData = dataNoDuplicates.map(entry => {
        return {
          x: entry.date_only,
          y: entry.resting_hr,
          formatted_date: entry.formatted_date
        }
      })

      restingHRSeries = {
        type: 'line',
        data: restingHRData,
        name: 'RestingHr',
        tooltip: {
          headerFormat: '',
          pointFormat: I18n.t('components.dashboards.questionnaire.daily_wellness.resting_hr.tooltip')
        },
        color: hcRed,
        yAxis: restingHrIndex
      }
    }

    let sleepDurationSeries
    let sleepDurationIndex
    if (withSleepDuration === true) {
      sleepDurationIndex = yAxisOptions.length
      yAxisOptions.push(
        {
          title: {
            text: I18n.t('components.dashboards.questionnaire.daily_wellness.sleep_duration.yaxis'),
            style: {
              color: hcGreen
            }
          },
          labels: {
            style: {
              color: hcGreen
            }
          },
          min: -0.9,
          softMax: MAX_SLEEP_DURATION,
          tickInterval: 1,
          startOnTick: false,
          opposite: true
        }
      )

      const sleepDurationData = dataNoDuplicates.map(entry => {
        return {
          x: entry.date_only,
          y: entry.sleep_duration_num,
          formatted_date: entry.formatted_date,
          formatted_sleep_duration: entry.sleep_duration
        }
      })

      sleepDurationSeries = {
        type: 'line',
        data: sleepDurationData,
        name: 'SleepDuration',
        tooltip: {
          headerFormat: '',
          pointFormat: I18n.t('components.dashboards.questionnaire.daily_wellness.sleep_duration.tooltip')
        },
        color: hcGreen,
        yAxis: sleepDurationIndex,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true
            }
          }
        }
      }
    }

    const xAxisOptions = {
      title: {
        text: ''
      },
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%d %b'
      }
    }

    const sicknessSeries = {
      type: 'scatter',
      data: sicknessData,
      tooltip: {
        headerFormat: '',
        pointFormat: I18n.t('components.dashboards.questionnaire.daily_wellness.scatter.tooltip')
      }
    }

    const wellbeingSeries = {
      type: 'column',
      data: wellbeingScoreData,
      name: '⬤',
      tooltip: {
        headerFormat: '',
        pointFormat: I18n.t('components.dashboards.questionnaire.daily_wellness.column.tooltip')
      }
    }

    const wellnessMovingAvgSeries = {
      type: 'line',
      data: wellnessMovingAvgData,
      name: 'Weekly wellness', // is not displayed to user
      tooltip: {
        headerFormat: '',
        pointFormat: I18n.t('components.dashboards.questionnaire.weekly_wellness.line.tooltip')
      }
    }

    const datasets = [sicknessSeries, wellbeingSeries, wellnessMovingAvgSeries, restingHRSeries, sleepDurationSeries]
      .filter(series => series !== undefined)

    const chartOptions = {
      title: titleWithTooltip(
        I18n.t('components.dashboards.questionnaire.daily_wellness.title'),
        I18n.t('components.dashboards.questionnaire.daily_wellness.info')
      ),
      subtitle: {
        text: I18n.t('components.dashboards.questionnaire.daily_wellness.subtitle')
      },
      series: datasets,
      plotOptions: {
        series: {
          stacking: 'normal',
          maxPointWidth: 1
        },
        scatter: {
          dataLabels: {
            enabled: false
          },
          color: 'black',
          stacking: 'normal',
          enableMouseTracking: true
        },
        line: {
          color: hcBlack,
          marker: {
            enabled: false
          }
        }
      },
      yAxis: yAxisOptions,
      xAxis: xAxisOptions
    }

    return (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
        />
      </>
    )
  }
}

export default DailyWellnessChart
