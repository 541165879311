import React from 'react'
import { Route } from 'react-router'
import NotFoundWrapper from '../router/NotFoundWrapper'
import Routes from '../common/Routes'
import RedeemVoucher from './layout/RedeemVoucher'

const RedeemRoutes = (props) => {
  return (
    <NotFoundWrapper slug='redeem'>
      <Route exact path={Routes.redeem.voucher.index} render={(props) => <RedeemVoucher {...props} />} />
    </NotFoundWrapper>
  )
}

export default RedeemRoutes
