import React from 'react'
import Tabs from '../common/Tabs'
import Routes from '../common/Routes'

export default class TimelineTabs extends React.Component {
  render () {
    const tabs = [
      {
        title: 'components.timeline.tabs.my_data',
        subtitle: 'components.timeline.tabs.my_data_count',
        subtitleParams: { count: this.props.myDataObjectCount },
        name: 'my_data',
        path: Routes.timeline.index
      }, {
        title: 'components.timeline.tabs.my_network',
        subtitle: 'components.timeline.tabs.my_network_count',
        subtitleParams: { count: this.props.networkDataObjectCount },
        name: 'my_network',
        path: Routes.timeline.network.index
      }
    ]
    return (
      <Tabs id='timeline-tabs' tabs={tabs} history={this.props.history} location={this.props.location} defaultActive={Routes.timeline.index} />
    )
  }
}
