import React, { useState } from 'react'
import moment, { Moment } from 'moment'
import { QUESTIONNAIRE_PERIOD } from 'components/common/Constants'
import { DataRow } from 'components/common/types/questionnaires/DataRow'

// these are the possible date property keys, used to filter questionnaires, which can have a different date property
// depending on the object. A type had to be defined for these according to TS rules.
type DatePropKey = 'training_start_date' | 'date_only' | 'open_from' | 'expires_at' | 'opened_at' // Fill in with other property names if needed

export interface DashboardWrapperProps {
  lastSelectedAthleteId: number | undefined
  setLastSelectedAthleteId: React.Dispatch<React.SetStateAction<number | undefined>>
  minDate: Moment
  setMinDate: React.Dispatch<React.SetStateAction<Moment>>
  maxDate: Moment
  setMaxDate: React.Dispatch<React.SetStateAction<Moment>>
  sliderStartDate: Moment
  sliderEndDate: Moment
  setDateRange: React.Dispatch<React.SetStateAction<[Moment, Moment]>>
  smartSetDateRange: (startDate: Moment, endDate: Moment) => void
  weekDifferenceToMoment: (dateDifference: number, sliderPivot: Moment) => Moment
  filterDataRowsBySlider: <T extends DataRow>(data: T[], dateProp: DatePropKey) => T[] // type = any type that extends DataRow
}

// This component was created to prevent repeated code for the normal and full dashboards by storing the min/max date
// of the dateRangePicker and corresponding slider components, also offers a filtering function for the slider.
function DashboardStateWrapper<P> (Component: React.ComponentType<P>): React.FC<P> {
  return (props: P): JSX.Element => {
    // This last selected athlete ID is part of a workaround, necessary for making sure that the compare tab multi athlete
    // picker has an athlete to display. This is because the selectedProfile prop shared between the tabs is unset when
    // a group is selected on the physical complaints tab. This variable ensures that a 'backup ID' is always available.
    const [lastSelectedAthleteId, setLastSelectedAthleteId] = useState<number | undefined>()
    const today = new Date()
    const twoMonthsAgo = moment().subtract(2, 'months').startOf(QUESTIONNAIRE_PERIOD)
    const [minDate, setMinDate] = useState<Moment>(twoMonthsAgo.startOf(QUESTIONNAIRE_PERIOD))
    const [maxDate, setMaxDate] = useState<Moment>(moment(today).endOf(QUESTIONNAIRE_PERIOD))
    const [dateRange, setDateRange] = useState<[Moment, Moment]>([
      minDate.clone().startOf(QUESTIONNAIRE_PERIOD),
      maxDate.clone().endOf(QUESTIONNAIRE_PERIOD)
    ])
    const [sliderStartDate, sliderEndDate] = dateRange

    //  Sets the date range smartly
    //  The startDate date is converted to that week's starting date (Monday at 12 am)
    //  The endDate date is converted to that week's ending date (Sunday at 11:59 pm)
    //  E.g. [3rd day, 10th day] -> [1st day at 12 am, 14th day at 11:59 pm]
    const smartSetDateRange = (startDate: Moment, endDate: Moment): void => {
      const smartStartDate = startDate.clone().startOf(QUESTIONNAIRE_PERIOD)
      const smartEndDate = endDate.clone().endOf(QUESTIONNAIRE_PERIOD)
      setDateRange([smartStartDate, smartEndDate])
    }

    // Using the pivot as reference, converts the date difference given by slider
    // into the corresponding moment.
    const weekDifferenceToMoment = (dateDifference: number, sliderPivot: Moment): Moment => {
      return sliderPivot.clone().add(dateDifference, 'week')
    }

    // only accepts data that extend DataRow type
    // Reads slider startDate/endDate state and filters list of data_rows based on these dates
    const filterDataRowsBySlider = <T extends DataRow>(data: T[], dateProp: DatePropKey): T[] => {
      function isKeyOfT<T> (prop: any, obj: T): prop is keyof T {
        return prop in obj
      }

      const startDateMoment: Moment | undefined = sliderStartDate != null ? moment(sliderStartDate) : undefined
      const endDateMoment: Moment | undefined = sliderEndDate != null ? moment(sliderEndDate) : undefined

      return data.filter((dataRow) => {
        if (isKeyOfT(dateProp, dataRow)) {
          return (sliderStartDate === null || moment(dataRow[dateProp]).isSameOrAfter(startDateMoment)) &&
            (sliderEndDate === null || moment(dataRow[dateProp]).isSameOrBefore(endDateMoment))
        }
        return false
      })
    }

    const hocProps: DashboardWrapperProps = {
      lastSelectedAthleteId,
      setLastSelectedAthleteId,
      minDate,
      setMinDate,
      maxDate,
      setMaxDate,
      sliderStartDate,
      sliderEndDate,
      setDateRange,
      smartSetDateRange,
      weekDifferenceToMoment,
      filterDataRowsBySlider
    }

    return <Component {...props} {...hocProps} />
  }
}

export default DashboardStateWrapper
