import React, { FC, ReactElement } from 'react'

export interface TitleRowProps {
  title: string
}

const TitleRow: FC<TitleRowProps> = ({ title }): ReactElement => {
  return (
    <div className='row'>
      <div className='col s12'>
        <div className='text-heavy text-primary-color text-l'>
          {title}
        </div>
      </div>
    </div>
  )
}
TitleRow.defaultProps = {
}
export default TitleRow
