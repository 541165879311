import React, { useContext, useState } from 'react'
import I18n from 'i18n'
import { SessionContext } from '../../../session/SessionProvider'
import { ModalContext } from '../../../modal/ModalProvider'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { filterActiveConnections } from '../../../common/Utils'
import { useGroupsQuery } from 'components/backend/Queries'
import Group from 'components/common/types/Group'
import { DropdownInput } from 'components/atomic/atoms/inputs'
import { useCreateTransferRequestMutation } from 'components/backend/transfer_requests/Mutations'
import { NewTransferRequestParams } from 'components/backend/transfer_requests/types'
import GroupMembership from 'components/common/types/GroupMembership'
import { toNumber } from 'lodash'
import { FlashMessageContext } from 'components/flashmessages/FlashMessageProvider'
import { MinimalProfile } from 'components/common/types/Profile'
import Button from 'components/atomic/atoms/Button'

interface Props {
  selectedFiles: any // TODO Type not yet defined from parent
}

interface DropdownElements {
  value: any
  name: string
  icon?: string
}

const CreateTransferRequest: React.FC<Props> = (props: Props): React.ReactElement => {
  const { selectedFiles } = props
  const { flashMessages } = useContext(FlashMessageContext)
  const { setConfirmationDialog, hide: closeModal } = useContext(ModalContext)
  const { myProfile } = useContext(SessionContext)

  const [selectedProfileId, setSelectedProfileId] = useState<number | undefined>(undefined)

  const { data: groups, isSuccess } = useGroupsQuery({
    onError: () => {
      flashMessages.pushErrorMessage(I18n.t('flashmessages.groups.error.fetch_groups'))
      closeModal()
    }
  })

  let connectedProfiles: Group[] = []
  const dropdownElements: DropdownElements[] = []

  if (myProfile !== undefined && isSuccess) {
    connectedProfiles = filterActiveConnections(groups) // TODO move this function to a TS file
    connectedProfiles.forEach((connection: Group) => {
      // connectedProfiles are actually groups, so we need to do a bit of parsing to obtain the connected profile
      const connectedProfile: MinimalProfile | undefined = connection.group_memberships.filter((membership: GroupMembership) => {
        return membership.profile.id !== myProfile.id
      })[0]?.profile

      if (connectedProfile !== undefined) {
        dropdownElements.push({
          value: connectedProfile.id,
          name: connection.name,
          icon: connection.picture
        })
      } else {
        console.warn(`Something unexpected happened while checking the connected profile ${connection.id} of the user.`)
        console.warn(connection.group_memberships)
      }
    })
  }

  const { mutate: createTransferRequest } = useCreateTransferRequestMutation()

  const handleConfirm = (): void => {
    if (selectedProfileId === undefined) {
      console.warn('There is no profile selected')
      return
    }

    const params: NewTransferRequestParams = {
      to: selectedProfileId,
      datasets: selectedFiles.map((file: any) => file.id)
    }

    createTransferRequest(params, {
      onSuccess: () => { flashMessages.pushInfoMessage(I18n.t('flashmessages.transfer_requests.created')) },
      onError: () => { flashMessages.pushErrorMessage(I18n.t('flashmessages.transfer_requests.error_on_creation')) }
    })
  }

  const handleSelectNewOwner = (profileId: number): void => {
    setSelectedProfileId(toNumber(profileId))
  }

  const handleCreateRequestClick = (): void => {
    setConfirmationDialog({
      onConfirmation: handleConfirm,
      action: 'create_transfer_request',
      target: '',
      noQuotes: true
    })
  }

  return (
    <>
      <div className='row'>
        <div className='col s12 offset-m1'>
          <div className='text-heavy text-primary-color text-l'>
            {I18n.t('components.data.ownership.transfer.modal_title')}
          </div>
        </div>
      </div>

      {/* Transfer ownership form */}
      <div className='row'>
        <div className='col s12 m6 offset-m1'>

          <label htmlFor='selected-files'>{I18n.t('components.data.ownership.request.selected_files')}</label>
          <ul className='collection files fixed-height-list'>
            <TransitionGroup className='data-files'>
              {selectedFiles.map((file: any, index: number) => (
                <CSSTransition
                  key={index}
                  timeout={200}
                  classNames='item'
                  appear
                >
                  <div>
                    <li className='collection-item'>
                      <div>
                        {file.title}
                      </div>
                    </li>
                  </div>
                </CSSTransition>
              ))}
            </TransitionGroup>
          </ul>
        </div>
      </div>

      <div className='row'>
        <div className='col s12 m6 offset-m1'>
          <DropdownInput
            id='select-new-owner'
            label={I18n.t('components.data.ownership.transfer.to_whom')}
            placeholder={I18n.t('components.data.ownership.transfer.select_connection')}
            content={dropdownElements}
            onChange={handleSelectNewOwner}
          />

          {
            selectedProfileId !== undefined &&
              <>
                <p>{I18n.t('components.data.ownership.transfer.new_owner')}</p>
                <p><strong>{I18n.t('components.data.ownership.transfer.cannot_undo')}</strong></p>
              </>
          }
        </div>
      </div>

      <div className='row'>
        <div className='col s12 m6 offset-m1'>
          {/* Transfer request button */}
          <Button onClick={handleCreateRequestClick} width='auto' type='primary'>
            {I18n.t('components.data.ownership.transfer.button')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default CreateTransferRequest
