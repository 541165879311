import React from 'react'
import TrainingRoutes from './TrainingRoutes'

const TrainingController: React.FC = () => {
  return (
    <TrainingRoutes
      indexProps={{}}
    />
  )
}

export default TrainingController
