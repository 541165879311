import React, { useContext } from 'react'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { defaultProfileName, defaultProfilePicture } from 'components/common/Constants'
import { TimeAgoPlain } from 'components/common/TimeAgo'
import { ModalContext } from 'components/modal/ModalProvider'
import { TransferRequest } from 'components/common/types/TransferRequest'
import { useProfileQuery } from 'components/backend/profile/Queries'
import { getFullName } from 'components/common/utils/ProfileUtils'
import Button from 'components/atomic/atoms/Button'

interface Props {
  onOpen: (request: TransferRequest) => void
  onReject: (request: TransferRequest) => void
  request: TransferRequest
}

const IncomingTransferRequest: React.FC<Props> = (props: Props): React.ReactElement => {
  const { request, onOpen, onReject } = props
  const { setConfirmationDialog } = useContext(ModalContext)

  const showDeleteDialog = (request: TransferRequest): void => {
    setConfirmationDialog({ onConfirmation: () => onReject(request), action: 'reject_transfer_request', target: '', noQuotes: true })
  }

  const { data: profile } = useProfileQuery(request.from.slug)
  const profilePicture: string = profile?.picture ?? defaultProfilePicture
  const profileName: string = profile !== undefined ? getFullName(profile) : defaultProfileName
  const createdAt: string = TimeAgoPlain(request.created_at)

  return (
    <div className='col s12 group-container'>
      <div className='network-group-container'>
        <div className='row' style={{ marginBottom: '0px' }}>
          <div className='col s1'>
            <img className='circle responsive-img' src={profilePicture} />
          </div>
          <div className='col s10 l6'>
            <div className='text-heavy text-primary-color text-l' style={{ marginTop: '0.75rem' }}>
              {profileName}
            </div>
            <div className='text-m text-light text-muted'>
              {I18n.t('components.data.ownership.request.invited_at', { timestamp: createdAt })}
            </div>
          </div>
          <div className='col s12 l4 right-align'>
            <div>
              <Button onClick={() => onOpen(request)} colour='blue' type='primary' width='group-width'>
                <FontAwesomeIcon icon='check' className='button-icon' />
                {I18n.t('components.data.ownership.request.open')}
              </Button>
            </div>
            <div>
              <Button onClick={() => showDeleteDialog(request)} colour='red' width='group-width' type='secondary'>
                <FontAwesomeIcon icon='times' className='button-icon' />
                {I18n.t('components.data.ownership.request.decline')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IncomingTransferRequest
