import React from 'react'
import classNames from 'classnames'

export default class Tab extends React.Component {
  render () {
    const { title, subtitle, active, onSwitchTab, tab, path } = this.props
    return (
      <div className='col s6'>
        <div className={classNames('tab decoration-primary pointer', active && 'tab-active bottom-rounded background-primary decoration-background')} onClick={() => onSwitchTab(tab, path)}>
          <div className='center-align text-m text-heavy text'>
            {title}
          </div>
          {subtitle && (
            <div className='center-align text-m text-light text'>
              {subtitle}
            </div>
          )}
        </div>
      </div>
    )
  }
}
