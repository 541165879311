import React, { FC, ReactElement } from 'react'

export interface NoResultsRowProps {
  title: string
}

const NoResultsRow: FC<NoResultsRowProps> = ({ title }): ReactElement => {
  return (
    <div className='row'>
      <div className='col s12'>
        <p>
          {title}
        </p>
      </div>
    </div>
  )
}
NoResultsRow.defaultProps = {
  title: 'No results'
}

export default NoResultsRow
