import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'i18n'
import Field from './Field'
import PolylineView from '../PolylineView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TimeAgoElement } from '../../../../common/TimeAgo'
import { Link } from 'react-router-dom'
import Routes from '../../../../common/Routes'

const Summary = ({ metadatum, icon, fields, polyline, compact }) => {
  const fieldRenderer = (fields) => (
    Object.entries(fields).map(([key, value]) =>
      (<Field key={key} title={I18n.t(`components.timeline.card.${key}`)} value={value} compact={icon} />)
    )
  )

  let iconToUse = icon
  if (iconToUse === 'futbol') {
    iconToUse = ['far', 'futbol']
  }

  return (
    <>
      <div className='timeline-compact-wrapper'>
        {icon &&
          <div className='timeline-icon-compact center-align'>
            <FontAwesomeIcon icon={iconToUse} />
          </div>}
        <div style={{ width: '100%' }}>
          {metadatum &&
            <div className='timeline-compact-title-wrapper'>

              <Link to={Routes.data.showFn(metadatum && metadatum.versioned_data_object_id)}>
                <div className='text-l text-heavy text-primary-color pointer  from-group'>
                  {(metadatum && (metadatum.title || metadatum.filename)) || I18n.t('components.data.untitled_file')}
                </div>
              </Link>
              <p className='timeline-profile-details text-s text-muted'>
                <TimeAgoElement date={new Date(metadatum.event_start)} />
              </p>

            </div>}
          {fields &&
            <div className='sport-fields'>
              {fieldRenderer(fields)}
            </div>}
        </div>
      </div>
      {!compact && polyline &&
        <PolylineView polyline={polyline} />}
    </>
  )
}

Summary.propTypes = {
  compact: PropTypes.bool,
  metadatum: PropTypes.object,
  icon: PropTypes.string,
  fields: PropTypes.object,
  polyline: PropTypes.string
}

export default Summary
