import React from 'react'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SdvLogo from '../../images/sdv-logo.svg'

const TimelinePlaceholder = (props) => {
  const type = props.network ? 'network' : 'my'
  return (
    <div className='timeline-card timeline-placeholder'>
      <div className='text-l text-heavy text-primary-color timeline-title center-align'>
        <div className='timeline-icon center-align'>
          <FontAwesomeIcon icon='info-circle' />
        </div>
        <div id='bg_logo'>
          <img alt='Sport Data Valley' className='sdv-logo login-logo breathing' src={SdvLogo} />
        </div>
        <p>{I18n.t(`components.timeline.placeholder.${type}.welcome`)}</p>
        <p className='text-muted text-m text-medium'>
          {I18n.t(`components.timeline.placeholder.${type}.message`)}
        </p>
      </div>
    </div>
  )
}

export default TimelinePlaceholder
