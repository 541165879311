import React, { useEffect } from 'react'
import I18n from 'i18n'
import { WithQuestionnaires } from '../../questionnaires/QuestionnaireProvider'
import Spinner from '../../common/Spinner'
import moment from 'moment'
import { momentFullDateFormatWithTimeAndDay } from '../../common/Constants'
import { questionnaireName } from '../../common/Utils'
import Info from '../../atomic/atoms/Info'
import ReactTooltip from 'react-tooltip'
import { round } from '../../common/Math'

const ProtocolPreview = ({ protocolName, startDate, endDate, responses, setResponses, disableEndDate, defaultProtocolDuration, ...props }) => {
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [responses])

  useEffect(() => {
    props.questionnaires.previewProtocol(protocolName, startDate, disableEndDate ? null : endDate).then(res => {
      setResponses(res.data)
    }).catch(err => {
      setResponses([])
      console.log(err)
    })
  }, [protocolName, startDate, endDate, disableEndDate])

  if (responses === undefined) {
    return <Spinner transparent />
  }

  if (responses.length === []) {
    return <></>
  }

  return (
    <div className='card'>
      <div className='card-content'>
        <p className='margin-bottom'>{I18n.t('group.detail.questionnaires.preview')}</p>
        <table className='striped responsive-table less-padding'>
          <thead>
            <tr>
              <th>
                {I18n.t('time.time')}
                {' '}
                <Info
                  text={I18n.t('group.detail.questionnaires.reminder_tooltip')}
                  tooltipId='higher-z-tooltip'
                  reuseTooltip
                />
              </th>
              <th>{I18n.t('group.detail.questionnaires.questionnaire')}</th>
            </tr>
          </thead>
          <tbody>
            {responses.map(response => (
              <tr key={`${response.questionnaire}-${response.open_from}`}>
                <td>{moment(response.open_from).format(momentFullDateFormatWithTimeAndDay)}</td>
                <td>{questionnaireName(response.questionnaire)}</td>
              </tr>
            ))}
            {/* The protocol previewer limits the number of returned responses to 10 max. So if we receive 10 responses, we assume that there are more.
                Note that if there are exactly 10 responses in the protocol preview, then it will still show the "...", but I don't think people will fall over this. */}
            {responses.length === 10 && <tr><td>...</td><td>&nbsp;</td></tr>}
          </tbody>
        </table>
        {disableEndDate && (
          <p className='margin-top'>
            {I18n.t('group.detail.questionnaires.end_date_disabled', {
              hours: Math.floor(defaultProtocolDuration / 3600),
              minutes: round((defaultProtocolDuration % 3600) / 60, 0)
            })}
          </p>
        )}
      </div>
    </div>
  )
}

export default WithQuestionnaires(ProtocolPreview)
