import I18n from 'i18n'
import { nl, enIE } from 'date-fns/locale'
import { ReactDatePickerProps } from 'react-datepicker'

// If the user locale is english, use the irish locale for dates, since that one matches the date locales of the rest of
// Europe (weeks start on Mondays)
export const getDatePickerLocale = (): Partial<ReactDatePickerProps> => {
  return {
    locale: I18n.locale === 'en' ? enIE : nl,
    timeCaption: I18n.t('time.time')
  }
}
