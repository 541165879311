import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { hcSubTitleColor, hcChartFont } from 'components/common/Constants'
import I18n from 'i18n'
import { initializeHighcharts } from 'components/common/Utils'

class TrainingSessionsChart extends React.Component {
  constructor (props) {
    super(props)
    initializeHighcharts(true)
    this.state = {}
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.setState({ showLabels: false })
    }
  }

  convertToHighchartsData (arr, color) {
    let idx = 0
    const arrlen = arr.length
    return arr.map(entry => {
      let brightness = 0
      if (arrlen > 1) {
        brightness = idx / (arrlen - 1)
      }
      brightness = 0.7 * brightness
      idx += 1
      return {
        name: entry.short_value,
        y: entry.count,
        longer_name: entry.value,
        formatted_percentage: entry.formatted_percentage,
        percentage: entry.percentage,
        color: Highcharts.Color(color).brighten(brightness).get()
      }
    })
  }

  calculateLabels (data) {
    let cumulativePercentage = 0
    return data.map(item => {
      if (item.percentage <= 3) {
        return <div key={item.name} />
      }
      let angle = -90 + (cumulativePercentage + item.percentage / 2) * 360 / 100
      let showRight = true
      if (angle > 90) {
        angle = angle + 180
        showRight = false
      }
      while (angle < 0) angle += 360
      angle %= 360
      const theseProps = {}
      if (item.percentage > 12.5) {
        theseProps.style = { transform: `rotate(${angle > 180 ? 90 : -90}deg)` }
      }
      cumulativePercentage += item.percentage
      return (
        <div key={item.name} className='outerContainer' style={{ transform: `rotate(${angle}deg)`, opacity: (this.state.showLabels ? 1 : 0) }}>
          <span className='leftie' style={{ visibility: !showRight ? 'visible' : 'hidden' }}><span {...theseProps}>{item.name}</span></span>
          <span className='rightie' style={{ visibility: showRight ? 'visible' : 'hidden' }}><span {...theseProps}>{item.name}</span></span>
        </div>
      )
    })
  }

  redrawEvent () {
    if (this.state.showLabels !== true) {
      this.setState({ showLabels: true })
    }
  }

  render () {
    if (!this.props.data) {
      return (
        <div className='my-highcharts-container' />
      )
    }

    const data = this.convertToHighchartsData(this.props.data, this.props.color)
    // const labels = this.calculateLabels(data)
    return (
      <div className='my-highcharts-container'>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              events: {
                render: () => {
                  this.redrawEvent()
                }
              }
            },
            title: {
              text: this.props.title,
              align: 'center',
              verticalAlign: 'middle',
              y: 13,
              style: {
                fontFamily: hcChartFont,
                fontSize: '15px !important',
                color: hcSubTitleColor,
                fontWeight: '100 !important'
              }
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  enabled: false, // disabled for now because we roll our own labels
                  padding: 0,
                  connectorWidth: 0,
                  distance: -10,
                  style: {
                    color: 'white'
                  },
                  crop: false,
                  overflow: 'allow',
                  filter: {
                    property: 'percentage',
                    operator: '>',
                    value: 3
                  }
                },
                size: '120%',
                states: {
                  hover: {
                    halo: null
                  }
                }
              }
            },
            series: [
              {
                type: 'pie',
                name: this.props.title,
                innerSize: '70%',
                data,
                animation: false,
                clickable: false,
                tooltip: {
                  headerFormat: '',
                  pointFormat: I18n.t('components.dashboards.questionnaire.training_sessions.pie.tooltip')
                }
              }
            ],
            tooltip: {
              outside: true,
              hideDelay: 100
            }
          }}
        />
        {/* labels */}
      </div>
    )
  }
}

export default TrainingSessionsChart
