import React from 'react'
import Appsignal from '@appsignal/javascript'

export const AppsignalContext = React.createContext()
class AppsignalProviderCls extends React.Component {
  constructor (props) {
    super(props)

    let appsignalKey = ''
    let currentRevision = ''
    if (process.env.APPSIGNAL_ACTIVE === 'true') {
      if (process.env.APPSIGNAL_FRONTEND_KEY !== undefined) {
        appsignalKey = process.env.APPSIGNAL_FRONTEND_KEY
      }

      if (process.env.REVISION !== undefined) {
        currentRevision = process.env.REVISION
      }
    }

    this.appsignal = new Appsignal({
      key: appsignalKey,
      revision: currentRevision
    })
  }

  sendError (error) {
    this.appsignal.sendError(error)
  }

  render () {
    return (
      <AppsignalContext.Provider value={{
        appsignal: {
          sendError: this.sendError.bind(this)
        }
      }}
      >
        {this.props.children}
      </AppsignalContext.Provider>
    )
  }
}

export const AppsignalProvider = AppsignalProviderCls

export const WithAppsignal = Component => props => {
  return (
    <AppsignalContext.Consumer>
      {(context) => (<Component {...context} {...props} />)}
    </AppsignalContext.Consumer>
  )
}
