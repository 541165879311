import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const useSearchableDropdownLogic = (elements, onAutocomplete) => {
  let mapping = {}

  useEffect(() => {
    createValueMapping()
  }, [elements])

  const generateNewName = (name, idx) => {
    return `${name} (${idx})`
  }

  const findUniqueName = (hash, name) => {
    let idx = 1
    while (hash[name] !== undefined) {
      name = generateNewName(name, idx)
      idx++
    }
    return name
  }

  const createValueMapping = () => {
    const result = {}
    mapping = {}
    elements.forEach((element) => {
      const name = findUniqueName(result, element.name)
      result[name] = null
      mapping[name] = element
    })

    const options = {
      data: result,
      minLength: 0,
      onAutocomplete: handleAutocomplete
    }
    const elems = document.querySelectorAll('.autocomplete')
    M.Autocomplete.init(elems, options)
  }

  const handleAutocomplete = (txtEntry) => {
    onAutocomplete(mapping[txtEntry])
  }
}

const SearchableDropdown = (props) => {
  useSearchableDropdownLogic(props.elements, props.onAutocomplete)

  return (
    <div className='input-field'>
      <input type='text' id='autocomplete-input' className='autocomplete' />
      <label htmlFor='autocomplete-input'>{props.label}</label>
    </div>
  )
}

SearchableDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ),
  onAutocomplete: PropTypes.func.isRequired
}

export default SearchableDropdown
