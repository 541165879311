import React from 'react'
import I18n from 'i18n'

export default class Radio extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      checked: true
    }
  }

  render () {
    const errors = this.props.errors || []
    const { component, type, fieldName, choices, formName, currentValue, defaultChoice } = this.props
    const fieldProps = { ...this.props }
    delete fieldProps.component
    delete fieldProps.fieldName
    delete fieldProps.choices
    delete fieldProps.translateErrors
    delete fieldProps.formName
    delete fieldProps.defaultValue
    delete fieldProps.currentValue
    delete fieldProps.type

    const finalValue = currentValue || defaultChoice

    return (
      <div className='row'>
        <div className='col s12'>
          <label className='active'>
            <span>{I18n.t(`${formName}.${component}.${type}.${fieldName}`)}</span>
          </label>
          {errors.map(err => (
            <div key={err}>
              {this.props.translateErrors ? I18n.t(err) : err}
            </div>
          ))}
          <div className='input-field'>
            {choices.map((choice) => (
              <p key={choice.properties.name}>
                <label>
                  <input {...choice.properties} checked={choice.properties.value === finalValue} name={fieldName} type={type} onChange={this.props.onChange} />
                  <span>{I18n.t(`${component}.${type}.${fieldName}.${choice.properties.name}`)}</span>
                </label>
              </p>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
