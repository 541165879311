import React from 'react'
import PropTypes from 'prop-types'

import Label from '../Label'

export const TextInput = (props) => {
  const {
    id,
    className = '',
    label,
    icon,
    placeholder,
    disabled,
    required,
    value,
    name,
    type,
    onChange = () => {},
    cy,
    errors
  } = props

  const inputHandler = (e) => {
    onChange(e?.target?.value)
  }

  const isLabelActive = !!value || !!placeholder
  return (
    <div className={`input-field ${className}`}>
      {icon && <i className='material-icons prefix'>{icon}</i>}
      <input
        id={id}
        type={type}
        name={name || id}
        placeholder={placeholder}
        onChange={inputHandler}
        disabled={disabled}
        required={required}
        value={value}
        data-cy={cy || 'text-input'}
      />
      {label && <Label isActive={isLabelActive} text={label} />}
      {errors?.map(err => (
        <span className='helper-text' data-error='wrong' data-success='right' key={err} style={{ color: 'red' }}>
          {err}
        </span>
      ))}
    </div>
  )
}

TextInput.propTypes = {
  // Id of the input element
  id: PropTypes.string,
  // Name of the input element
  name: PropTypes.string,
  // Class of the input field container. Useful to define the size of the container in the grid.
  className: PropTypes.string,
  // Label text that shows above the input field
  label: PropTypes.string,
  // Name of the icon that shows to the left of the input element. Should correspond to an Font Awesome icon.
  icon: PropTypes.string,
  // Placeholder text that shows in the input element before user types anything
  placeholder: PropTypes.string,
  // If set, disables the input element
  disabled: PropTypes.bool,
  // If set, requires the input element
  required: PropTypes.bool,
  // Value to show in the input element. If set, this component becomes controlled
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  // Type of the textfield
  type: PropTypes.string,
  // Callback function that gets called when the text in the input box changes
  onChange: PropTypes.func,
  // Attribute for cypress that can be used to find the element
  cy: PropTypes.string,
  // Attribute to add errros to the field (if any)
  errors: PropTypes.arrayOf(PropTypes.string)
}

TextInput.defaultProps = {
  type: 'text',
  required: false,
  disabled: false
}
