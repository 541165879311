import React from 'react'
import I18n from 'i18n'
import Variables from '../../../../../javascript/stylesheets/variables.module.scss'
import TimeSeriesChart from '../common/TimeSeriesChart'
import Table from '../../../common/Table'
import { WithErrorBoundary } from '../../../errorboundary/ErrorBoundaryProvider'
import { SimpleSlider } from '../../../common/Slider'
const { secondaryColor } = Variables

/**
 * Csv specific wrapper fro the TimeSeriesChart. It adds a summary table for the displayed column, as well
 * as a slider to control how many samples are visualized
 */
const CsvTimeseries = ({ summaryBuilder, description, nsamples, onSetSamples, xs, ys, x, y }) => {
  const config = {
    borderColor: secondaryColor
  }

  /**
   * Column headers for the selected column's summary table
   */
  const columns = summaryBuilder.getNumericSummaryColumns()

  /**
   * Values for the selected column's summary table (only a single row because a histogram is for a single variable)
   */
  const rows = summaryBuilder.getNumericSummaryRows([description.name])

  return (
    <div className='row'>
      <div className='col s12'>
        <Table columns={columns} rows={rows} />
      </div>
      <div className='col s12'>
        <SimpleSlider
          value={nsamples}
          onChange={(e) => onSetSamples(e.target.value)}
          minVal={1}
          maxVal={Math.min(ys.length, 10000)}
          toolTipTranslation='components.visualization.csv.slider.samples'
        />
      </div>
      <div className='col s12'>
        <TimeSeriesChart maxSamples={nsamples} x={xs} ys={[ys]} datasetConfigs={[config]} xLabel={x.label} yLabels={[y.label]} />
      </div>
    </div>
  )
}

export default WithErrorBoundary(CsvTimeseries, I18n.t('components.visualization.csv.errors.timeseries'))
