import { useCookies } from 'react-cookie'
import { useState, useEffect } from 'react'

export interface ISdvNextHook {
  toggleSdvNext: () => void
  sdvNextEnabled: boolean
}

const useSdvNextHook = (): ISdvNextHook => {
  const [cookies, setCookie] = useCookies(['sdvNextEnabled'])

  const enabledInCookies = (): boolean => {
    return cookies?.sdvNextEnabled === 'true' ||
      cookies?.sdvNextEnabled === true
  }

  const cookieState = enabledInCookies()

  const [sdvNextEnabled, setSdvNextEnabled] = useState<boolean>(cookieState)

  useEffect(() => {
    setCookie('sdvNextEnabled', sdvNextEnabled, { path: '/' })
  }, [sdvNextEnabled])

  const toggle = (): void => {
    setSdvNextEnabled(!sdvNextEnabled)
  }

  return {
    toggleSdvNext: toggle,
    sdvNextEnabled: sdvNextEnabled
  }
}

export default useSdvNextHook
