import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { hcBlack, hcOrange, hcRed, hcGreen, MINIMUM_WEEKLY_DURATION_TO_SHOW_GAP } from 'components/common/Constants'
import I18n from 'i18n'
import GenericPlaceholder from 'components/common/GenericPlaceholder'
import { initializeHighcharts, titleWithTooltip } from 'components/common/Utils'
import { imputateGapsWithUndefinedRecords } from '../Utils'

const MultiWeeklyInjuryRiskSeverityScoreOSTRCChart = props => {
  if (!props.sundaySummary || props.sundaySummary.length === 0) {
    return (
      <GenericPlaceholder title={I18n.t('components.dashboards.questionnaire.weekly_injury_risk_severity_score_ostrc.title')} message={props.noDataMessage} />
    )
  }

  const calculateColor = entry => {
    if (entry.weekly_ostrc_severity <= 0.25) return hcGreen
    if (entry.weekly_ostrc_severity >= 0.50) return hcRed
    if (entry.weekly_ostrc_severity > 0.25 && entry.weekly_ostrc_severity < 0.50) return hcOrange
    return hcBlack
  }

  const allData = []
  for (const athleteSet of props.sundaySummary) {
    const lineData = imputateGapsWithUndefinedRecords(athleteSet.data, MINIMUM_WEEKLY_DURATION_TO_SHOW_GAP, 7, 'weekly_ostrc_severity').map(entry => {
      return {
        x: entry.date_only,
        y: entry.weekly_ostrc_severity === undefined ? null : entry.weekly_ostrc_severity * 100,
        color: calculateColor(entry),
        daily_date: entry.formatted_date,
        weekly_ostrc_severity: entry.weekly_ostrc_severity * 100,
        weekly_ostrc_participation: entry.participation * 100,
        weekly_ostrc_reduced_training: entry.reduced_training * 100,
        weekly_ostrc_affected_performance: entry.affected_performance * 100,
        weekly_ostrc_symptoms_complaints: entry.symptoms_complaints * 100
      }
    })
    allData.push({
      type: 'line',
      data: lineData,
      name: `${athleteSet.athlete.first_name} ${athleteSet.athlete.last_name}`,
      tooltip: {
        headerFormat: '',
        pointFormat: I18n.t('components.dashboards.questionnaire.weekly_injury_risk_severity_score_ostrc.column.tooltip')
      }
    })
  }
  initializeHighcharts(true)
  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          title: titleWithTooltip(
            I18n.t('components.dashboards.questionnaire.weekly_injury_risk_severity_score_ostrc.title'),
            I18n.t('components.dashboards.questionnaire.weekly_injury_risk_severity_score_ostrc.info')
          ),
          subtitle: {
            text: I18n.t('components.dashboards.questionnaire.weekly_injury_risk_severity_score_ostrc.subtitle')
          },
          series: allData,
          plotOptions: {
            line: {
              marker: {
                enabled: true
              }
            }
          },
          legend: {
            enabled: true
          },
          yAxis: {
            title: {
              text: I18n.t('components.dashboards.questionnaire.weekly_injury_risk_severity_score_ostrc.yaxis')
            },
            min: -9,
            max: 100,
            tickInterval: 10,
            startOnTick: false
          },
          xAxis: {
            title: {
              text: ''
            },
            type: 'datetime',
            dateTimeLabelFormats: {
              day: '%d %b'
            }
          }
        }}
      />
    </>
  )
}

export default MultiWeeklyInjuryRiskSeverityScoreOSTRCChart
