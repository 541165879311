import React from 'react'
import { HorizontalBar } from 'react-chartjs-2'
import I18n from 'i18n'
import {
  chartjsDefaultOptions,
  chartjsTitleProperties
} from '../../../../common/Constants'
import { round } from '../../../../common/Math'

const originalHorizontalBarDraw = Chart.controllers.horizontalBar.prototype.draw

const WeeklyInjuries = (props) => {
  if (!props.sundaySummary || props.sundaySummary.length === 0) {
    return <></>
  }

  Chart.helpers.extend(Chart.controllers.horizontalBar.prototype, {
    draw: function () {
      originalHorizontalBarDraw.apply(this, arguments)

      const chart = this.chart.chart
      const ctx = chart.ctx
      const chartInstance = this.chart

      ctx.font = ctx.font = '12px \'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif'
      ctx.fillStyle = '#999'

      const meta = chartInstance.controller.getDatasetMeta(0)
      meta.data.forEach(function (bar, _index) {
        const label = bar._model.label
        const xOffset = 20
        const yOffset = bar._model.y + 4
        ctx.shadowColor = 'white'
        ctx.shadowBlur = 10
        ctx.strokeText(label, xOffset, yOffset)
        ctx.lineWidth = 1
        ctx.fillStyle = 'black'
        ctx.fillText(label, xOffset, yOffset)
        ctx.shadowBlur = 0
      })
    }
  })

  const params = ['participation', 'symptoms_complaints', 'affected_performance', 'reduced_training']
  const colors = ['#1492BB', '#0A720A', '#BE1111', '#FF7B00']
  const datasets = []

  datasets.push({
    label: I18n.t('components.dashboards.questionnaire.weekly.injuries.severity_score'),
    backgroundColor: colors,
    data: params.map(cParam => round(props.sundaySummary[0][cParam] * 100, 0))
  })
  const options = {
    ...chartjsDefaultOptions,
    title: {
      text: I18n.t('components.dashboards.questionnaire.weekly.injuries.title'),
      ...chartjsTitleProperties
    },
    tooltips: {
      mode: 'index',
      intersect: false
    },
    plugins: {
      crosshair: false
    },
    legend: {
      display: false
    },
    scales: {
      yAxes: [{
        ticks: {
          display: false
        },
        gridLines: {
          display: false
        }
      }],
      xAxes: [{
        ticks: {
          suggestedMin: 0,
          suggestedMax: 100
        },
        scaleLabel: {
          display: true,
          labelString: I18n.t('components.dashboards.questionnaire.weekly.injuries.severity_score')
        }
      }]
    }
  }

  const data = {
    labels: params.map(param => I18n.t(`components.dashboards.questionnaire.weekly.injuries.params.${param}`)),
    datasets: datasets
  }
  return (
    <div className='questionnaire-chartjs-container barchart'>
      <HorizontalBar height={300} data={data} options={options} redraw />
    </div>
  )
}

export default WeeklyInjuries
