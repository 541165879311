import {
  useProtocolSubscriptionsQuery,
  useQuestionnaireGroupsQuery,
  useQuestionnaireProfilesQuery,
  useQuestionnaireResponsesQuery
} from '../../../backend/Queries'
import { omit } from 'lodash'

export const useQuestionnaireData = ({
  params = {},
  profilesOptions = {},
  groupsOptions = {},
  questionnairesOptions = {},
  protocolSubscriptionsOptions = {}
}) => {
  const enabled = !!(params?.start_date && params?.end_date && params?.type?.length > 0)

  // Omitting this for the groups and profiles queries prevents unnecessary reloads
  const groupsOrProfilesParams = omit(params, ['profile_id', 'group_id'])

  const { data: { data: groupsWithData = [] } = {}, ...groupsMetadata } = useQuestionnaireGroupsQuery(groupsOrProfilesParams, { ...groupsOptions, enabled: !!(groupsOptions?.enabled && enabled) })
  const { data: { data: profilesWithData = [] } = {}, ...profilesMetadata } = useQuestionnaireProfilesQuery(groupsOrProfilesParams, { ...profilesOptions, enabled: !!(profilesOptions?.enabled && enabled) })
  const { data: { data: questionnairesData = [] } = {}, ...questionnairesMetadata } = useQuestionnaireResponsesQuery(params, { ...questionnairesOptions, enabled: !!(questionnairesOptions?.enabled && enabled) })
  const { data: { data: protocolSubscriptions } = {}, ...protocolSubscriptionsMetadata } = useProtocolSubscriptionsQuery(params.group_id, { ...protocolSubscriptionsOptions, enabled: !!(protocolSubscriptionsOptions?.enabled && enabled) })

  return {
    groups: {
      data: groupsWithData,
      ...groupsMetadata
    },
    profiles: {
      data: profilesWithData,
      ...profilesMetadata
    },
    questionnaires: {
      data: questionnairesData,
      ...questionnairesMetadata
    },
    protocolSubscriptions: {
      data: protocolSubscriptions,
      ...protocolSubscriptionsMetadata
    }
  }
}
