import _ from 'lodash'
import { computeQuartile } from './Math'

const groupByCategory = (numericColumn, categoryColumn) => {
  return _.zip(numericColumn, categoryColumn).reduce((acc, [value, category]) => {
    if (category in acc) {
      acc[category].push(value)
    } else {
      acc[category] = [value]
    }
    return acc
  }, {})
}

const computeBoxes = (numericColumn, categoryColumn) => {
  const groupedData = groupByCategory(numericColumn, categoryColumn)
  return Object.keys(groupedData).map((key) => {
    /* sort data in ascending order */
    const sorted = groupedData[key].sort((a, b) => a - b)
    const box = {
      num_points: sorted.length,
      q1: computeQuartile(sorted, 1),
      median: computeQuartile(sorted, 2),
      q3: computeQuartile(sorted, 3),
      category: key,
      min: Math.min(...sorted),
      max: Math.max(...sorted)
    }

    /* compute inter-quartile range, and then set upper and lower bound
     * of outliers to quartile + 1.5 * inter-quartile range */
    const IQR = box.q3 - box.q1
    const [lower, upper] = [box.q1 - 1.5 * IQR, box.q3 + 1.5 * IQR]
    box.outliers = sorted.filter((val) => val < lower || val > upper)

    return box
  })
}

export default computeBoxes
