import React from 'react'
import I18n from 'i18n'

/* global M */
export default class Select extends React.Component {
  componentDidMount (prevProps) {
    const elems = document.querySelectorAll('select')
    M.FormSelect.init(elems)
  }

  render () {
    const errors = this.props.errors || []
    const { component, type, fieldName, choices, formName, defaultValue, currentValue } = this.props
    const fieldProps = { ...this.props }
    delete fieldProps.component
    delete fieldProps.fieldName
    delete fieldProps.choices
    delete fieldProps.translateErrors
    delete fieldProps.formName
    delete fieldProps.defaultValue
    delete fieldProps.currentValue
    delete fieldProps.type
    return (
      <div className='row'>
        <div className='col s12'>
          <div className='input-field'>
            <label className='active'>{I18n.t(`${formName}.${component}.${type}.${fieldName}`)}</label>
            {errors.map((err, i) => (
              <div key={i}>
                {this.props.translateErrors ? I18n.t(err) : err}
              </div>
            ))}
            <select {...fieldProps} onChange={(e) => this.props.onChange(M.FormSelect.getInstance(e.target))} defaultValue={currentValue || defaultValue}>
              {choices.map((choice) => (
                <option key={choice.properties.name} {...choice.properties}>{I18n.t(`${component}.${type}.${choice.properties.name}`)}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    )
  }
}
