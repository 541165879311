import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Collapse = (props) => {
  const { onToggle, open, buttonText, children } = props
  const { whenOpen, whenClosed } = buttonText

  return (
    <>
      <button
        className='button-primary background-primary button-autowidth waves-effect waves-light text-background-color'
        onClick={onToggle}
      >
        {
          open && (
            <>
              <FontAwesomeIcon icon='chevron-up' className='button-icon' />
              {whenOpen}
            </>
          )
        }
        {
          !open && (
            <>
              <FontAwesomeIcon icon='chevron-down' className='button-icon' />
              {whenClosed}
            </>
          )
        }
      </button>
      {open && children}
    </>
  )
}

Collapse.propTypes = {
  // Callback function which is called when the toggle button is clicked
  onToggle: PropTypes.func,
  // Set if collapse is open/closed
  open: PropTypes.bool,
  // Object with the texts of the toggle button
  buttonText: PropTypes.shape({
    // Button text when the collapse is open
    whenOpen: PropTypes.string,
    // Button text when the collapse is closed
    whenClosed: PropTypes.string
  }),
  // Components to display only when collapse is open
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ])
}

export default Collapse
