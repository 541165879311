import React from 'react'

const BetaRibbon = ({ children, hidden = false }) => {
  if (hidden) {
    return <>{children}</>
  }

  return (
    <div className='ribbon ribbon-top-right'>
      <span>beta</span>
    </div>
  )
}

export default BetaRibbon
