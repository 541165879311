import React from 'react'
import GarminDefaultFlavour from './GarminDefaultFlavour'

const GarminDetails = (props) => {
  const sdos = props.data.structured_data_objects
  if (!sdos || sdos.length < 1) return <></>
  return <GarminDefaultFlavour sdo={sdos[0]} icon={props.icon} metadatum={props.metadatum} />
}

export default GarminDetails
