import React, { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import I18n from 'i18n'
import DatePicker from 'react-datepicker'
import classNames from 'classnames'

import { WithBackend } from '../../../backend/BackendProvider'
import {
  COACH_DASHBOARD_TRAINING_CATEGORIES,
  datePickerDefaultOptions,
  timeSeries as timeSeriesNames,
  momentDateFormat
} from '../../../common/Constants'
import SelectDropdown from '../../../common/SelectDropdown'
import { fileDescription } from '../../../common/Utils'
import TableInteractive from './TableInteractive'
import moment from 'moment'
import { addFirstDatasetOfEachType, calculateTrimp, createAthleteRowEntry, fetchUserData, isCorrespondingSchedule, isSportsActivity, willAddAcwr, willAddScheduledTraining } from './CoachDashboardHelper'
import CoachTrendsSection from './CoachTrendsSection'
import { isDailyQuestionnaire, isQuestionnaire, isTrainingLog } from '../../../common/utils/QuestionnaireUtils'
import TrainingSessionSummary from './training_session_section/TrainingSessionSummary'
import TrainingSessionZones from './training_session_section/TrainingSessionZones'
import TimeseriesWithSelectors from './training_session_section/TimeseriesWithSelectors'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { round } from '../../../common/Math'
import VDO from '../../../common/VDO'
import { getTrainingLoad } from '../../../common/Calculations'
import TrainingSessionLaps from './training_session_section/TrainingSessionLaps'
import { ms2kmh } from '../../../common/Units'
import { WithFlashMessages } from 'components/flashmessages/FlashMessageProvider'
import { WithAppsignal } from 'components/appsignal/AppsignalProvider'

const CoachTrainingTab = (props) => {
  const [selectedActivity, setSelectedActivity] = useState({})
  const [selectedTrainingLog, setSelectedTrainingLog] = useState(undefined)
  const [selectedTrainingSchedule, setSelectedTrainingSchedule] = useState(undefined)
  const [startDay, setStartDay] = useState(new Date())
  const [endDay, setEndDay] = useState(new Date())
  const [athleteDatasets, setAthleteDatasets] = useState([])
  const [athleteLatestData, setAthleteLatestData] = useState(undefined)
  const [athleteLatestDataDate, setAthleteLatestDataDate] = useState(new Date())
  const [dataIsFetched, setDataIsFetched] = useState(false)
  const isFirstRender = useRef(true)

  const { athleteProfile, titles, groupId, backend, flashMessages, appsignal, selectedHomeTabDate } = props

  // When selectedHomeTabDate changes, update the min and end dates for your date pickers
  useEffect(() => {
    if (selectedHomeTabDate) {
      // Update end date
      setEndDay(new Date(selectedHomeTabDate))

      // Update min date to be a month before the end date
      const newMinDate = new Date(selectedHomeTabDate)
      newMinDate.setMonth(newMinDate.getMonth() - 1)
      setStartDay(newMinDate)
    }
  }, [selectedHomeTabDate])

  const athleteId = athleteProfile?.id

  const requiredTimeSeries = [
    timeSeriesNames.SPEED,
    timeSeriesNames.ELEVATION,
    timeSeriesNames.DISTANCE,
    timeSeriesNames.CADENCE,
    timeSeriesNames.POWER,
    timeSeriesNames.HR
  ]

  // TODO Refactor the current code of Units/Backend to also include these measurements in the summary
  const additionalSummaryFields = {
    speed_load: (summary) => getTrainingLoad(ms2kmh(summary?.time_series_summaries?.speed?.mean), summary?.duration),
    power_load: (summary) => getTrainingLoad(summary?.time_series_summaries?.power?.mean, summary?.duration)
  }
  requiredTimeSeries.forEach((metric) => {
    additionalSummaryFields[`avg_${metric}`] = (summary) => summary?.time_series_summaries?.[metric]?.mean
    additionalSummaryFields[`max_${metric}`] = (summary) => summary?.time_series_summaries?.[metric]?.max
  })

  const extendActivitySummary = (summary) => {
    if (!summary) return summary

    _.forIn(additionalSummaryFields, (getValueFn, field) => {
      summary[field] = round(getValueFn(summary), 2)
    })
    return summary
  }

  const handleSelectActivity = (e) => {
    const selectedFileId = parseInt(e?.target?.value)
    if (selectedFileId) {
      const selectedVdo = _.find(athleteDatasets, (dataset) => { return dataset.id === selectedFileId })
      selectActivity(selectedVdo)
    }
  }

  const handleSelectTrainingLog = (e) => {
    const selectedFileId = parseInt(e?.target?.value)
    if (selectedFileId) {
      const selectedVdo = _.find(athleteDatasets, (dataset) => { return dataset.id === selectedFileId })
      setSelectedTrainingLog(selectedVdo)
    }
  }

  const handleStartChange = (e) => { setStartDay(e) }
  const handleEndChange = (e) => { setEndDay(e) }

  const getFileSelectionOptions = (athleteDatasets) => {
    const dropdownOptions = athleteDatasets?.map((dataset) => ({ value: dataset.id, name: fileDescription(dataset.metadatum) }))
    return dropdownOptions
  }

  const selectActivity = (activityVdo) => {
    const id = VDO.id(activityVdo)
    if (id) {
      extendActivitySummary(VDO.summary(activityVdo))
      const params = {
        keys: [...new Set(requiredTimeSeries)]
      }
      backend.data.timeseries.get(id, params).then((res) => {
        setSelectedActivity({
          vdo: activityVdo,
          data: res.data
        })
      })
    } else {
      setSelectedActivity({})
    }
  }

  const selectTrainingLog = (trainingLog) => {
    const trainingTime = trainingLog?.metadatum?.event_start
    backend.schedules.index(trainingTime).then((response) => {
      const correspondingTrainingSchedule = _.find(response.data.data, (schedule) => isCorrespondingSchedule(schedule, trainingLog))
      setSelectedTrainingSchedule(correspondingTrainingSchedule)
    }).finally(() => {
      setSelectedTrainingLog(trainingLog)
    })
  }

  const fetchAthleteData = (athleteId, groupId, startDay, endDay) => {
    const start = moment(startDay).startOf('day')
    const end = moment(endDay).endOf('day')
    return fetchUserData(backend, groupId, athleteId, start, end)
  }

  const entry = {}
  if (athleteProfile && (athleteLatestData?.activity || athleteLatestData?.trainingLog || athleteLatestData?.dailyQuestionnaire)) {
    entry[athleteId] = createAthleteRowEntry(athleteProfile, athleteLatestData)
  }

  // Get Activity Selection options
  const trainingSessions = _.filter(athleteDatasets, (dataset) => isSportsActivity(dataset))
  const activitySelectorOptions = getFileSelectionOptions(trainingSessions)

  const questionnaires = _.filter(athleteDatasets, (dataset) => isQuestionnaire(dataset))
  const dailyQuestionnaires = _.filter(questionnaires, (dataset) => isDailyQuestionnaire(dataset?.structured_data_objects[0]))
  const trainingLogs = _.filter(questionnaires, (dataset) => isTrainingLog(dataset?.structured_data_objects[0]))
  const matchingTrainingLogs = _.filter(trainingLogs, (dataset) => {
    const trainingLogDay = moment(dataset?.structured_data_objects[0]?.data_rows[0]?.values?.v3)
    const activityDay = moment(VDO.eventStart(selectedActivity?.vdo))
    return trainingLogDay.isSame(activityDay, 'day')
  })
  const trainingLogSelectorOptions = getFileSelectionOptions(matchingTrainingLogs)

  const trimp = selectedActivity?.data && selectedActivity?.vdo && round(calculateTrimp(VDO.elapsedTimePerZone(selectedActivity?.vdo, 'hr'), selectedActivity?.data), 2)

  // Fetch the datasets of the selected athlete for the selected time interval
  useEffect(() => {
    setDataIsFetched(false)
    fetchAthleteData(athleteId, groupId, startDay, endDay)
      .then((datasets) => {
        setAthleteDatasets(datasets)
      })
      .catch((err) => {
        console.error(err)
        appsignal.sendError(err.toJSON()) // TODO: Appsignal says this error is not a valid type! Solve this!
        flashMessages.push(
          I18n.t('flashmessages.general.error_retrieving_data'),
          flashMessages.duration.SHORT,
          flashMessages.levels.ERROR
        )
      })
    setSelectedActivity({}) // Reset activity
  }, [athleteId, groupId, startDay, endDay])

  useEffect(() => {
    if (!isFirstRender.current) {
      setDataIsFetched(true)
    } else {
      isFirstRender.current = false
    }
  }, [athleteDatasets])

  // Fetch the latest data of the selected athlete to show in the table entry
  useDeepCompareEffect(() => {
    const latestDatasets = {}
    addFirstDatasetOfEachType(latestDatasets, athleteDatasets)

    willAddAcwr(latestDatasets, backend, athleteId, endDay)
      .then(() => willAddScheduledTraining(latestDatasets, backend, endDay))
      .then(() => {
        setAthleteLatestData(latestDatasets)
      })
  }, [athleteId, endDay, athleteDatasets])

  // Extract date for selected latest athlete data
  useEffect(() => {
    if (!_.isEmpty(athleteLatestData)) {
      const metadatum = athleteLatestData.trainingLog?.metadatum ?? athleteLatestData.dailyQuestionnaire?.metadatum

      if (metadatum) {
        setAthleteLatestDataDate(metadatum.event_start)
      }
    }
  }, [athleteLatestData])

  // Get default Training log
  useEffect(() => {
    selectTrainingLog(matchingTrainingLogs[0])
  }, [VDO.id(selectedActivity?.vdo)])

  // This forces a re-render of the dropdowns when the parameters change
  const dropdownContentId = `${athleteId}-${groupId}-${startDay}-${endDay}`

  return (
    <>
      <div className='row'>
        <div className='col s6 input-field'>
          <DatePicker
            id='training-tab-start-picker' {...datePickerDefaultOptions()} selected={startDay} selectStart onChange={handleStartChange}
          />
          <label htmlFor='training-tab-start-picker' className={classNames({ active: startDay })}>{I18n.t('components.dashboards.coach_individual.training.start_date')}</label>
        </div>
        <div className='col s6 input-field'>
          <DatePicker
            id='training-tab-end-picker' {...datePickerDefaultOptions()} selected={endDay} selectEnd onChange={handleEndChange}
          />
          <label htmlFor='training-tab-end-picker' className={classNames({ active: endDay })}>{I18n.t('components.dashboards.coach_individual.training.end_date')}</label>
        </div>
      </div>

      <div className='row'>
        <div className='col s12'>
          <div className='titlebar'>{!_.isEmpty(athleteLatestDataDate)
            ? `${I18n.t('components.dashboards.coach_individual.training.latest_athlete_data')} (${moment(athleteLatestDataDate).format(momentDateFormat)})`
            : I18n.t('components.dashboards.coach_individual.training.latest_athlete_data')}
          </div>
          <TableInteractive categories={COACH_DASHBOARD_TRAINING_CATEGORIES} titles={titles} entries={entry} id='training-table' />
        </div>
      </div>

      <div className='row'>
        <div className='col s6'>
          <div className='row'>
            <div className='col s12'>
              <div className='titlebar'>{I18n.t('components.dashboards.coach_individual.training.training_session')}</div>
              <SelectDropdown
                id='activity-selector'
                content={activitySelectorOptions}
                label={I18n.t('components.dashboards.select_dropdown.activity')}
                placeholder={I18n.t('components.dashboards.select_dropdown.activity')}
                contentId={dropdownContentId}
                onChange={handleSelectActivity}
              />
              <SelectDropdown
                id='training-log-selector'
                content={trainingLogSelectorOptions}
                label={I18n.t('components.dashboards.select_dropdown.training_log')}
                placeholder={I18n.t('components.dashboards.select_dropdown.training_log')}
                contentId={dropdownContentId}
                onChange={handleSelectTrainingLog}
              />
            </div>

            <div className='col s12'>
              <div className='text-heavy text-l text-muted data-header'>{I18n.t('components.dashboards.coach_individual.training.summary')}</div>
            </div>
            <div className='col s12'>
              <TrainingSessionSummary activity={selectedActivity} trainingLog={selectedTrainingLog} scheduledTraining={selectedTrainingSchedule} trimp={trimp} />
            </div>

            <div className='col s12'>
              <div className='text-heavy text-l text-muted data-header'>{I18n.t('components.dashboards.coach_individual.training.fastest_lap')}</div>
            </div>
            <div className='col s12'>
              <TrainingSessionLaps activity={selectedActivity} />
            </div>

            <div className='col s12'>
              <div className='text-heavy text-l text-muted data-header'>{I18n.t('components.dashboards.coach_individual.training.hr_zones')}</div>
            </div>
            <div className='col s12'>
              <TrainingSessionZones activity={selectedActivity} />
            </div>

            <div className='col s12'>
              <div className='text-heavy text-l text-muted data-header'>{I18n.t('components.dashboards.coach_individual.training.measurements')}</div>
            </div>
            <div className='col s12'>
              <TimeseriesWithSelectors activity={selectedActivity} />
            </div>
          </div>
        </div>

        <div className='col s6'>
          <div className='titlebar'>{I18n.t('components.dashboards.coach_individual.training.trends')}</div>
          <CoachTrendsSection
            trainingLogs={trainingLogs} dailyQuestionnaires={dailyQuestionnaires}
            start={startDay} end={endDay} athleteId={athleteId} dataIsFetched={dataIsFetched}
          />
        </div>
      </div>
    </>
  )
}

export default WithAppsignal(WithFlashMessages(WithBackend(CoachTrainingTab)))
