import { useContext } from 'react'
import { QueryKey, useQuery } from 'react-query'
import { UseQueryOptions, UseQueryResult } from 'react-query/types'
import { BackendContext } from '../BackendProvider'
import { AxiosResponse } from 'axios'
import { TransferRequestRaw, TransferRequestsList, TransferRequestsQueryParams, TransferRequestsRaw } from './types'
import { TransferRequest } from 'components/common/types/TransferRequest'
import { assign } from 'lodash'

export const transferRequestsQueryId = 'transfer-requests-list'
export const useTransferRequestsQuery = (
  queryParams: TransferRequestsQueryParams,
  page: number = 1,
  options?: UseQueryOptions<TransferRequestsList, Error>
): UseQueryResult<TransferRequestsList, Error> => {
  const { backend } = useContext(BackendContext)
  const { from, to, status } = queryParams

  const convertTransferRequestDateIntoString = (rawTransferRequest: TransferRequestRaw): TransferRequest => {
    const processedDate: Date = new Date(rawTransferRequest.created_at)
    return { ...rawTransferRequest, created_at: processedDate }
  }

  const queryFn = async (): Promise<TransferRequestsList> => {
    return backend.transfer_requests.index(from, to, status, page).then((response: AxiosResponse<TransferRequestsRaw>) => {
      const processedTransferRequests = response.data.data.map(convertTransferRequestDateIntoString)
      return assign(response.data, { data: processedTransferRequests })
    })
  }
  const queryKey: QueryKey = [transferRequestsQueryId, from, to, status?.sort(), page]

  return useQuery<TransferRequestsList, Error>(queryKey, queryFn, options)
}
