import React, { useState } from 'react'
import I18n from 'i18n'
import OauthApplicationEntry from './OauthApplicationEntry'
import SelectMultiple from '../../common/form/SelectMultiple'
import Button from '../../atomic/atoms/Button'
import { TextInput } from '../../atomic/atoms/inputs/TextInput'
const OauthApplicationsOverview = (props) => {
  const [name, setName] = useState('')
  const [redirectUri, setRedirectUri] = useState('')
  const [scopes, setScopes] = useState([])
  const [errors, setErrors] = useState({})

  const oauthApplications = (props.oauthApplications || []).map((application) => {
    return (<OauthApplicationEntry key={application.id} {...application} />)
  })

  const isHtttps = (url) => {
    const result = url?.startsWith('https://')
    if (!result) {
      setErrors({
        ...errors,
        redirectUri: [
          I18n.t('components.admin_dashboard.oauth_applications.create.errors.redirect_uri_should_be_https')
        ]
      })
    }
    return result
  }

  const clearErrors = () => {
    setErrors({})
  }

  const isFormValid = (data) => {
    return isHtttps(data.application.redirect_uri)
  }

  const createApplication = (e) => {
    e.preventDefault()
    const data = { application: { name: name, redirect_uri: redirectUri, scopes: scopes } }
    if (isFormValid(data)) {
      clearErrors()
      props.handleSubmit(data)
    }
  }

  return (
    <>
      <div className='row'>
        <div className='col s12 text-heavy muted-header'>
          <h5>
            {I18n.t('components.admin_dashboard.oauth_applications.title')}
          </h5>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <ul className='collection'>{oauthApplications} </ul>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <form>
            <div className='row'>
              <div className='col s12 m6'>
                <TextInput
                  name='name'
                  id='name'
                  cy='name-input'
                  type='text'
                  value={name}
                  onChange={setName}
                  label={I18n.t('components.admin_dashboard.oauth_applications.application_name')}
                />
              </div>
              <div className='col s12 m6'>
                <TextInput
                  name='redirect_uri'
                  id='redirect_uri'
                  cy='redirect_uri-input'
                  type='text'
                  label={I18n.t('components.admin_dashboard.oauth_applications.redirect_uri')}
                  value={redirectUri}
                  onChange={setRedirectUri}
                  errors={errors.redirectUri}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col s12'>
                <SelectMultiple
                  id='scopes'
                  cy='scope-select'
                  formName='add_application'
                  component='admin_dashboard'
                  type='select_multiple'
                  fieldName='scopes'
                  choices={[
                    { properties: { name: 'read', value: 'read' } },
                    { properties: { name: 'write', value: 'write' } }
                  ]}
                  onChange={e => setScopes(e.getSelectedValues())}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col s12'>
                <Button onClick={(e) => { createApplication(e) }} cy='submit'>
                  {I18n.t('components.admin_dashboard.oauth_applications.submit')}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default OauthApplicationsOverview
