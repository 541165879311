import React, { useState } from 'react'
import { Route } from 'react-router'
import { withRouter } from 'react-router-dom'
import PremiumOptions from './layout/PremiumOptions'
import {
  UrlModal,
  AuthorisationModal,
  StatusModal
} from './layout/PaymentModals'
import NotFoundWrapper from '../router/NotFoundWrapper'
import Routes from '../common/Routes'
import _ from 'lodash'

const PremiumMembershipRoutes = withRouter((props) => {
  const { premiumOptions = {} } = props
  const [sub, setSub] = useState(null)

  const closeCallback = () => {
    props.history.push(Routes.premium.subscribe.index)
  }

  return (
    <NotFoundWrapper slug='premium' switch={false}>
      <Route exact path={Routes.premium.subscribe.index} render={(props) => <PremiumOptions {...props} {...premiumOptions} onSetSubscription={setSub} />} />
      <Route exact path={Routes.premium.subscribe.authorise} render={(props) => <UrlModal component={AuthorisationModal} modalProps={{ ...premiumOptions, closeCallback: closeCallback, type: _.get(sub, 'type'), subscription: _.get(sub, 'subscription') }} />} />
      <Route exact path={Routes.premium.subscribe.status} render={(props) => <UrlModal component={StatusModal} modalProps={{ closeCallback: closeCallback }} />} />
    </NotFoundWrapper>
  )
})

export default PremiumMembershipRoutes
