import { useContext, useEffect, useState, useRef, useMemo } from 'react'
import { MyAxios as axios } from '../MyAxios'
import { SessionContext } from '../session/SessionProvider'
import { FlashMessageContext } from '../flashmessages/FlashMessageProvider'
import ResizeObserver from 'resize-observer-polyfill'
import _ from 'lodash'

export function useFetchData (url, params = {}) {
  const [data, setData] = useState(undefined)
  const [error, setError] = useState(undefined)
  const [fetched, setFetched] = useState(false)
  const session = useContext(SessionContext)

  function fetch (params) {
    setError(undefined)
    setFetched(false)
    axios({
      method: 'GET',
      url: url,
      headers: { Authorization: session.sessionToken },
      params: params
    }).then(res => setData(res.data))
      .catch(e => setError(e))
      .finally(() => setFetched(true))
  }

  useEffect(() => fetch(params), [])

  return { data: data, setData: setData, error: error, fetched: fetched, reFetch: fetch }
}

export function useFetchMultipleData (urls, paramsArray = undefined) {
  const [fetched, setFetched] = useState(false)

  // WARNING: use of hooks within a loop is discouraged by react, but it is safe to do so here because it is always
  // being called in the same order and on the same number of elements (since paramsArray is a constant array.
  // This should be equivalent to manually calling useFetchData multiple times
  const { data, error, fetched: eachFetched, reFetch: eachReFetch } = urls.map((url, urlIdx) => useFetchData(
    url,
    paramsArray[urlIdx]
  )).reduce((acc, curr) => {
    Object.entries(curr).forEach(([k, v]) => {
      const values = _.get(acc, k, [])
      acc[k] = values.concat([v])
    })
    return acc
  }, {})

  useEffect(() => {
    setFetched(_.every(eachFetched))
  }, [eachFetched])

  function reFetch (paramsArray) {
    eachReFetch.forEach((f, idx) => f(paramsArray[idx]))
  }

  return { data: _.compact(data), errors: _.compact(error), fetched: fetched, reFetch: reFetch }
}

export function useDisplayFetchErrors (errors, fetched, message) {
  const { flashMessages } = useContext(FlashMessageContext)

  useEffect(() => {
    const nErrors = _.get(_.compact(errors), 'length', 0)
    if (fetched && nErrors > 0) {
      flashMessages.push(message, flashMessages.duration.LONG, flashMessages.levels.ERROR)
    }
  }, [fetched])
}

/**
 * Same as useEffect but it won't trigger for the default values
 * @param effect EffectCallback
 * @param deps Dependencies
 */
export function useEffectSkipDefault (effect, deps) {
  const [firstUpdate, setFirstUpdate] = useState(true)

  useEffect(() => {
    if (firstUpdate) return setFirstUpdate(false)
    effect()
  }, deps)
}

// modified from https://gist.github.com/morajabi/523d7a642d8c0a2f71fcfa0d8b3d2846#gistcomment-3084362
export function useBbox () {
  const ref = useRef()
  const [bbox, setBbox] = useState({})

  const set = () => setBbox(ref?.current?.getBoundingClientRect() || {})

  const resizeObserver = useMemo(() => {
    return new ResizeObserver(() => set()) // eslint-disable-line no-undef
  }, [])

  useEffect(() => {
    set()
    window.addEventListener('resize', set)
    return () => window.removeEventListener('resize', set)
  }, [])

  useEffect(() => {
    resizeObserver.disconnect()
    resizeObserver.observe(ref?.current)
    return () => resizeObserver.disconnect()
  }, [ref])

  return [bbox, ref]
}

// Initializes the materialize tabs component upon the first render. Gets the html id of the element as input param.
export function useMaterializeTabs (id) {
  const [tabs, setTabs] = useState(undefined)
  useEffect(() => { setTabs(M.Tabs.init(document.getElementById(id), {})) }, [])
  return tabs
}

/**
 * A staging hook that allows for switching between a number of stages through
 * calling nextStage and prevStage. Provides useful callbacks as well. Stage
 * numbers follow a half-open interval.
 *
 * @param numStages      The number of stages
 * @param firstStage     The first stage
 * @param numStages      The last stage will be stage nr. firstStage + numStages - 1
 * @param onStart        A callback that will be run when the first stage is
 *                       reached *again*
 * @param onFinish       A callback thta will be run when the last stage is
 *                       reached *again*
 * @param onClickThrough A callback that runs when clicking *through* the last
 *                       stage (trying to access firstStage + numStages)
 */
export function useStages ({ numStages, firstStage = 0, onStart = null, onFinish = null, onClickThrough = null }) {
  const [stage, setStage] = useState(firstStage)

  const nextStage = (...args) => {
    if (stage === firstStage + numStages - 1 && onClickThrough) {
      // currently on the last stage, trigger click through
      onClickThrough(...args)
      return
    }

    setStage(s => Math.min(numStages - 1, s + 1))

    if (stage === firstStage + numStages - 1 && onFinish) {
      // we are now on the last stage
      onFinish(...args)
    }
  }

  const prevStage = (...args) => {
    setStage(s => Math.max(firstStage, s - 1))

    if (stage === firstStage) {
      // we are back on the first stage
      onStart(...args)
    }
  }

  return { stage, nextStage, prevStage }
}

export function useFlashMessages () {
  const { flashMessages } = useContext(FlashMessageContext)
  return flashMessages
}
