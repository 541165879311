import React, { FC, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { hcOrange, hcBlack, hcGreen, hcBlue } from 'components/common/Constants'
import I18n from 'i18n'
import { initializeHighcharts, titleWithTooltip } from 'components/common/Utils'
import GenericPlaceholder from 'components/common/GenericPlaceholder'
import { WeeklyWellbeingRow } from 'components/common/types/questionnaires/WeeklyWellbeingRow'
import _ from 'lodash'

interface WeeklySleepProps {
  weeklyWellbeingRows?: WeeklyWellbeingRow[]
}

const WeeklySleepBar: FC<WeeklySleepProps> = ({ weeklyWellbeingRows }) => {
  useEffect(() => {
    initializeHighcharts(true)
  }, [])

  if (_.isEmpty(weeklyWellbeingRows) || !Array.isArray(weeklyWellbeingRows)) {
    return <GenericPlaceholder title={I18n.t('components.dashboards.questionnaire.weekly_sleep.title')} />
  }

  const scatterData = weeklyWellbeingRows.map(entry => ({
    x: entry.date_only,
    y: entry.sleep_duration_num,
    formatted_date: entry.formatted_date,
    sleep_duration: entry.sleep_duration
  }))

  const lineData = weeklyWellbeingRows.map(entry => ({
    x: entry.date_only,
    y: entry.sleep_duration_moving_average,
    formatted_date: entry.formatted_date
  }))

  const sleepQualityData = weeklyWellbeingRows.map(entry => ({
    x: entry.date_only,
    y: entry.wellness_sleep,
    formatted_date: entry.formatted_date
  }))

  const xAxisOptions = {
    title: {
      text: ''
    },
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%d %b'
    },
    minPadding: 0.08, // this styling is specific to the DateRangeQuestionnaireDashboard
    maxPadding: 0.08 // this styling is specific to the DateRangeQuestionnaireDashboard
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        title: titleWithTooltip(
          I18n.t('components.dashboards.questionnaire.weekly_sleep.title'),
          I18n.t('components.dashboards.questionnaire.weekly_sleep.info')
        ),
        subtitle: {
          text: I18n.t('components.dashboards.questionnaire.weekly_sleep.subtitle')
        },
        plotOptions: {
          column: { // styling and alignment @DateRangeQuestionnaireDashboard
            pointPadding: 0,
            groupPadding: 0
          },
          scatter: {
            color: hcOrange
          },
          series: {
            maxPointWidth: 21
          },
          line: {
            color: hcBlack,
            marker: {
              enabled: false
            }
          }
        },
        series: [
          {
            type: 'column',
            data: scatterData,
            name: '⬤',
            color: hcBlue,
            tooltip: {
              headerFormat: '',
              pointFormat: I18n.t('components.dashboards.questionnaire.daily_sleep.scatter.tooltip')
            }
          },
          {
            type: 'line',
            data: lineData,
            lineWidth: 2,
            tooltip: {
              headerFormat: '',
              pointFormat: I18n.t('components.dashboards.questionnaire.weekly_sleep.line.tooltip')
            }
          },
          {
            type: 'line',
            data: sleepQualityData,
            name: I18n.t('components.dashboards.questionnaire.weekly_sleep.sleep_quality_line.name'),
            color: hcGreen,
            yAxis: 1,
            lineWidth: 2,
            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: true
                }
              }
            },
            tooltip: {
              headerFormat: '',
              pointFormat: I18n.t('components.dashboards.questionnaire.weekly_sleep.sleep_quality_line.tooltip')
            }
          }
        ],
        yAxis: [
          {
            title: {
              text: I18n.t('components.dashboards.questionnaire.daily_sleep.yaxis'),
              style: {
                color: hcBlue // This changes the color of the first y-axis title to match the first line
              }
            },
            labels: {
              style: {
                color: hcBlue // This changes the color of the first y-axis labels to match the first line
              }
            },
            min: 0,
            opposite: false
          },
          {
            title: {
              text: I18n.t('components.dashboards.questionnaire.weekly_sleep.sleep_quality_line.yaxis_right'),
              style: {
                color: hcGreen
              }
            },
            labels: {
              style: {
                color: hcGreen
              }
            },
            min: 0,
            max: 6,
            opposite: true
          }
        ],
        xAxis: xAxisOptions
      }}
    />
  )
}

export default WeeklySleepBar
