import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import _ from 'lodash'

import TableInteractiveEntry from './TableInteractiveEntry'

/**
 * Receives a collection of dictionaries with the format { type: string, data: Object }
 * Can easily extend the types that are shown in the table
 * @param {*} props
 */
const TableInteractiveRow = (props) => {
  const { id, index } = props

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => {
        const entryProps = _.omit(props, ['id', 'index'])
        return (<TableInteractiveEntry {...entryProps} dragndropProps={provided} />)
      }}
    </Draggable>
  )
}

export default TableInteractiveRow
