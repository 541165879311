import React, { useContext, useState } from 'react'
import I18n from 'i18n'
import IncomingTransferRequest from './IncomingTransferRequest'
import Spinner from 'components/common/Spinner'
import { useTransferRequestsQuery } from 'components/backend/transfer_requests/Queries'
import { SessionContext } from 'components/session/SessionProvider'
import { Status, TransferRequest } from 'components/common/types/TransferRequest'
import NumericPaginationNavigation from 'components/atomic/molecules/NumericPaginationNavigation'
import { isUndefined } from 'lodash'
import { TransferRequestsQueryParams } from 'components/backend/transfer_requests/types'
import { ModalContext } from 'components/modal/ModalProvider'
import IncomingTransferRequestDetails from './IncomingTransferRequestDetails'
import { useRejectTransferRequestMutation } from 'components/backend/transfer_requests/Mutations'
import { FlashMessageContext } from 'components/flashmessages/FlashMessageProvider'

const FIRST_PAGE: number = 1

const IncomingTransferRequestsList: React.FC<void> = (): React.ReactElement => {
  const { myProfile } = useContext(SessionContext)
  const { setModalComponent, hide: hideModalComponent } = useContext(ModalContext)
  const { flashMessages } = useContext(FlashMessageContext)

  const [pageShown, setPageShown] = useState(FIRST_PAGE)

  const queryParams: TransferRequestsQueryParams = {
    to: myProfile?.id,
    status: [Status.PENDING]
  }
  const {
    data: response,
    isLoading
  } = useTransferRequestsQuery(queryParams, pageShown, { keepPreviousData: true, notifyOnChangeProps: ['data'] })

  const transferRequests = response?.data

  const pages = response?.page
  const totalNumberOfTransferRequests: number = pages?.total_count ?? 0

  const handleNavigate = (newPage: number): void => setPageShown(newPage)
  const handleOpenTransferRequest = (request: TransferRequest): void => {
    setModalComponent(
      IncomingTransferRequestDetails,
      {
        request: request,
        afterAccept: hideModalComponent,
        afterReject: hideModalComponent
      })
  }

  const { mutate: reject } = useRejectTransferRequestMutation()
  const handleRejectTransferRequest = (request: TransferRequest): void => {
    reject(request, {
      onSuccess: () => flashMessages.pushInfoMessage(I18n.t('flashmessages.transfer_requests.rejected')),
      onError: () => flashMessages.pushErrorMessage(I18n.t('flashmessages.transfer_requests.error_on_reject'))
    })
  }

  if (totalNumberOfTransferRequests === 0) return <></>
  return (
    <>
      <div className='col s12 text-heavy muted-header'>
        <div className='row'>
          <div className='col s12'>
            <h5>{I18n.t('components.data.ownership.transfer.title', { count: totalNumberOfTransferRequests })}</h5>
          </div>
        </div>
      </div>
      {
        !isUndefined(pages) &&
          <div className='col s12'>
            <NumericPaginationNavigation firstPage={FIRST_PAGE} lastPage={pages.total_pages} onNavigate={handleNavigate} activePage={pages.current_page} />
          </div>
      }
      <div className='col s12'>
        <Spinner ready={!isLoading} transparent />
        {transferRequests?.map(request => (
          <IncomingTransferRequest onOpen={handleOpenTransferRequest} onReject={handleRejectTransferRequest} key={request.id} request={request} />
        ))}

      </div>
    </>
  )
}

export default IncomingTransferRequestsList
