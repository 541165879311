import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-modal'
import classNames from 'classnames'
import { fixBody, unFixBody } from '../common/Utils'

const MyModal = props => {
  // Make sure that the dialog doesn't change the vertical scroll position,
  // and that the background of the modal dialog is not scrollable.
  useEffect(() => {
    if (props.isOpen) {
      fixBody((_) => {})
      // Only do the cleanup if it's us who fixed the body
      return () => {
        unFixBody(true, (_) => {})
      }
    }
  }, [props.isOpen])

  function handleClick () {
    if (props.children && props.children.props && props.children.props.handleClose) {
      props.children.props.handleClose()
    }
    if (props.onClose) {
      props.onClose()
    }
  }

  return (
    <Modal
      id='my-modal'
      className={classNames('custom-modal container', props.modalClassName)}
      overlayClassName={classNames('custom-modal-wrapper', props.overlayClassName)}
      shouldCloseOnOverlayClick
      onRequestClose={handleClick}
      style={{}} {...props}
    >
      <div className='general-content full-height'>
        <div className='row'>
          <div className='col s12'>
            <div className='modal-header'>
              <div className='close-button-wrapper'>
                <div
                  onClick={handleClick}
                  className='close-button-container center pointer-grow text-primary-color'
                >
                  <FontAwesomeIcon icon='times' size='2x' />
                </div>
              </div>
            </div>
            <div>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default MyModal
