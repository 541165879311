import React from 'react'
import Toggle from '../atomic/atoms/Toggle'

// Deprecated, use the atom instead
export default (props) => {
  // Note the very nice !isChecked... This is legacy and should be worked back
  // to all classes including this one.
  const childProps = { ...props }
  delete childProps.isChecked
  return (<Toggle {...childProps} isChecked={!props.isChecked} />)
}
