import I18n from 'i18n'
import React, { useMemo, useState } from 'react'
import DatePicker from 'react-datepicker'
import { datePickerDefaultOptions } from 'components/common/Constants'
import ToggleAdvanced from 'components/common/ToggleAdvanced'
import PropTypes from 'prop-types'
import SelectDropdown from 'components/common/SelectDropdown'
import _ from 'lodash'
import Chips from 'components/common/form/Chips'
import SpinnerWrapper from 'components/common/SpinnerWrapper'

const BASIC_SEARCH_FIELD_ID = 'title'

const AdvancedSearch = (props) => {
  const { availableSports = [], availableTags = [], advancedQuery } = props
  const { startDate, endDate, sport, tags, firstName, lastName } = advancedQuery

  const sportsContent = useMemo(() => {
    return availableSports.map(sport => ({ name: sport, value: sport }))
  }, [availableSports])

  function handleMultipleChoiceUpdate (fieldName, e) {
    const selectedSports = e.target.options ? _.filter(e.target.options, option => option.selected).map(option => option.value) : e.target.value
    props.onUpdateSearchFields(fieldName, selectedSports.join(','))
  }

  function handleChipsUpdate (fieldName, e) {
    props.onUpdateSearchFields(fieldName, e.target.value.join(' '))
  }

  return (
    <>
      <div className='row'>
        <div className='col s12 m6 input-field'>
          <DatePicker
            id='start' {...datePickerDefaultOptions()} selected={startDate} onChange={date => props.onUpdateSearchFields('startDate', date)} selectsStart startDate={startDate} endDate={endDate}
          />
          <label className={startDate && 'active'}>{I18n.t('components.data.overview.search.start')}</label>
        </div>
        <div className='col s12 m6 input-field'>
          <DatePicker
            id='end' {...datePickerDefaultOptions()} selected={endDate} onChange={date => props.onUpdateSearchFields('endDate', date)} selectsEnd startDate={startDate} endDate={endDate} minDate={startDate}
          />
          <label className={endDate && 'active'}>{I18n.t('components.data.overview.search.end')}</label>
        </div>
        <div className='col s12 input-field'>
          <SelectDropdown
            id='sport-search'
            content={sportsContent}
            onChange={e => handleMultipleChoiceUpdate('sport', e)}
            defaultValue={sport.split(',')}
            isMultipleChoice
          />
          <label className={sport && 'active'}>{I18n.t('components.data.overview.search.sport')}</label>
        </div>
        {props.network &&
          <>
            <div className='col s12 m6 input-field'>
              <input name='first-name-search' id='first-name-search' type='text' onChange={e => props.onUpdateSearchFields('firstName', e.target.value)} value={firstName} />
              <label className={firstName && 'active'}>{I18n.t('components.data.overview.search.first_name')}</label>
            </div>
            <div className='col s12 m6 input-field'>
              <input name='last-name-search' id='last-name-search' type='text' onChange={e => props.onUpdateSearchFields('lastName', e.target.value)} value={lastName} />
              <label className={lastName && 'active'}>{I18n.t('components.data.overview.search.last_name')}</label>
            </div>
          </>}
      </div>
      <Chips
        fieldName='tag-chips'
        label={I18n.t('components.data.overview.search.tags')}
        currentValue={tags.split(' ')}
        onChange={e => handleChipsUpdate('tags', e)}
        suggestions={availableTags}
      />
    </>
  )
}

AdvancedSearch.propTypes = {
  // Whether the query is for network data or not. If true the firstName and lastName are applicable. Otherwise they are
  // dismissed
  network: PropTypes.bool,
  advancedQuery: PropTypes.shape({
    startDate: PropTypes.date_only,
    endDate: PropTypes.date_only,
    sport: PropTypes.string,
    tags: PropTypes.string,
    title: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }),
  // A list with the available sports
  availableSports: PropTypes.arrayOf(PropTypes.string),
  // A list with the available tags
  availableTags: PropTypes.arrayOf(PropTypes.string),
  // A function with the signature: field(fieldName, value). FieldName is the field name in the advancedQuery object to
  // which the now value corresponds
  onUpdateSearchFields: PropTypes.func
}

const Search = (props) => {
  const { defaultValue, advancedSearch, onBasicSearch, onAdvancedSearch, setAdvancedSearch } = props

  const [searchValue, setSearchValue] = useState(defaultValue || '')
  const toggleAdvancedSearch = () => {
    if (advancedSearch) {
      onBasicSearch(document.getElementById(BASIC_SEARCH_FIELD_ID).value)
    } else {
      // when switching to advanced search, get value that was already in basic search field (if any) and apply filter
      // this prevents user typing a filter value, then switching to advanced and the filter not being applied.
      onAdvancedSearch(BASIC_SEARCH_FIELD_ID, document.getElementById(BASIC_SEARCH_FIELD_ID).value)
    }
    setAdvancedSearch(!advancedSearch)
  }

  const handleSearch = (e) => {
    const targetSearchValue = e.target.value
    setSearchValue(targetSearchValue)
    advancedSearch ? onAdvancedSearch(BASIC_SEARCH_FIELD_ID, targetSearchValue) : onBasicSearch(targetSearchValue)
  }

  return (
    <div className='background-background data-overview-card'>
      <div className='row'>
        <div className='text-heavy text-primary-color text-xl col s12'>{I18n.t('components.data.overview.search.header')}</div>
        <div className='col s12'>
          <div className='input-field'>
            <i className='material-icons prefix'>search</i>
            <input
              name={BASIC_SEARCH_FIELD_ID}
              id={BASIC_SEARCH_FIELD_ID}
              type='text'
              placeholder={I18n.t('components.data.overview.search.placeholder')}
              onChange={handleSearch}
              value={searchValue}
            />
          </div>
          <ToggleAdvanced onToggle={toggleAdvancedSearch} showAdvanced={props.advancedSearch} ready={props.ready} />
        </div>
      </div>
      {
        props.advancedSearch &&
          <SpinnerWrapper ready={props.ready} transparent>
            <AdvancedSearch {...props} network={props.network} advancedQuery={props.advancedQuery} onUpdateSearchFields={props.onAdvancedSearch} />
          </SpinnerWrapper>
      }
    </div>
  )
}

export default Search
