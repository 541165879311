import React from 'react'
import ChartComponent from 'react-chartjs-2'
import I18n from 'i18n'
import PropTypes from 'prop-types'
import { chartjsDefaultOptions } from 'components/common/Constants'
import { round } from 'components/common/Math'
import GenericPlaceholder from '../../../../common/GenericPlaceholder'

const color = (compliance) => {
  if (compliance === 0) return '#fafafa'
  if (compliance < 50) return '#d61c31'
  if (compliance >= 90) return '#238B13'
  return '#d95c08'
}

const ComplianceChart = ({ filledIn = 0, total }) => {
  const CHART_HEIGHT = 150

  if (!total) {
    return <GenericPlaceholder message={I18n.t('components.placeholders.default_message')} />
  }

  const compliance = 100 * filledIn / total
  const calculatedColor = color(compliance)
  const filledInPercentage = round(compliance, 0)
  const notFilledInPercentage = 100 - filledInPercentage

  const complianceChartData = compliance === 100 ? [100] : [filledInPercentage, notFilledInPercentage]
  const data = {
    labels: [
      I18n.t('components.dashboards.questionnaire.compliance.filled_out'),
      I18n.t('components.dashboards.questionnaire.compliance.not_filled_out')
    ],
    datasets: [
      {
        data: complianceChartData,
        label: I18n.t('components.dashboards.questionnaire.compliance.title'),
        backgroundColor: [calculatedColor, '#fafafa'],
        borderColor: [calculatedColor, '#fafafa']
      }
    ],
    text: `${round(compliance, 0)}`
  }

  const options = {
    ...chartjsDefaultOptions,
    title: {
      display: false
    },
    cutoutPercentage: 70,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    plugins: {
      // The afterDrawPlugin is used to draw custom text in the center of the doughnut chart.
      // This prevents the issue of color clashing when multiple ComplianceCharts are rendered simultaneously.
      // It runs after the chart has been rendered, giving us access to the canvas context (ctx),
      // which allows us to draw the custom text using native canvas APIs.
      // The custom text is based on the compliance rate and is color-coordinated with the doughnut chart.
      afterDrawPlugin: {
        id: 'afterDrawPlugin',
        afterDraw: (chart) => {
          const ctx = chart.ctx
          const width = chart.width
          const height = chart.height
          const fontSize = (height / 86).toFixed(2)

          ctx.font = `700 ${fontSize}em "Raleway", arial, sans-serif`
          ctx.textBaseline = 'hanging'
          ctx.fillStyle = calculatedColor

          const text = chart.config.data.text
          const textX = Math.round((width - ctx.measureText(text).width) / 2)
          const textY = (height / 2) - 10

          ctx.fillText(text, textX, textY)
        }
      }
    },
    legend: {
      display: false
    }
  }

  return (
    <div className='questionnaire-chartjs-container'>
      <ChartComponent type='doughnut' height={CHART_HEIGHT} data={data} options={options} plugins={[options.plugins.afterDrawPlugin]} redraw />
      <p className='text-l center-align text-muted'>% {I18n.t('components.dashboards.questionnaire.compliance.logged_entries')}</p>
    </div>
  )
}

ComplianceChart.propTypes = {
  filledIn: PropTypes.number,
  total: PropTypes.number
}

export default ComplianceChart
