import React from 'react'
import I18n from 'i18n'
import moment from 'moment/moment'
import { QUESTIONNAIRE_PERIOD } from 'components/common/Constants'
import Slider from 'components/common/Slider'
import { getWeekNumber } from 'components/common/Math'

const WeekSlider = (props) => {
  const { minDate, maxDate, sliderStartDate, sliderEndDate, smartSetDateRange, weekDifferenceToMoment } = props

  const sliderId = 'date-filter-slider'
  const sliderPivot = moment('2021-09-20') // It's a fixed arbitrary day

  const getSliderRange = (minDate, maxDate, usedForStart) => {
    // Avoids selection of the same week for min/max, which causes an error with the slider
    const oneWeekBeforeMaxDate = moment(maxDate).subtract(1, 'week')
    const isDateValid = minDate.isSameOrBefore(oneWeekBeforeMaxDate)

    if (usedForStart || isDateValid) {
      const minDateMonday = minDate.clone().startOf(QUESTIONNAIRE_PERIOD)
      const maxDateMonday = maxDate.clone().startOf(QUESTIONNAIRE_PERIOD)
      const pivotMonday = sliderPivot.clone().startOf(QUESTIONNAIRE_PERIOD)
      return [
        minDateMonday.diff(pivotMonday, 'weeks'),
        maxDateMonday.diff(pivotMonday, 'weeks')
      ]
    } else {
      return undefined
    }
  }

  const sliderRange = getSliderRange(minDate, maxDate, false)
  const sliderFormatter = (weekDifference) => {
    const selectedMoment = weekDifferenceToMoment(weekDifference, sliderPivot)
    return getWeekNumber(selectedMoment)[1]
  }

  const weekNotation = () => {
    const [startYear, startWeek] = getWeekNumber(sliderStartDate)
    const [endYear, endWeek] = getWeekNumber(sliderEndDate)
    if (startYear === endYear) {
      if (startWeek === endWeek) return `${startWeek}`

      return `${startWeek} - ${endWeek}`
    }
    return `${startWeek} (${startYear}) - ${endWeek} (${endYear})`
  }

  const handleDateRangeChange = (_range, _handle, unencodedRange) => {
    const newStart = weekDifferenceToMoment(unencodedRange[0], sliderPivot)
    const newEnd = weekDifferenceToMoment(unencodedRange[1], sliderPivot)
    smartSetDateRange(newStart, newEnd)
  }

  return (
    <div className='col s12 no-noui-tooltips'>
      <Slider
        id={sliderId}
        range={sliderRange || [0, 1]}
        start={getSliderRange(sliderStartDate, sliderEndDate, true)}
        onChange={handleDateRangeChange}
        title={I18n.t('components.dashboards.multi_activity.weeks', { value: weekNotation() })}
        customFormatter={sliderFormatter}
        disabled={!sliderRange}
        largerLabel
      />
    </div>
  )
}

export default WeekSlider
