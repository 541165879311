import I18n from 'i18n'
import { Action, ActionType } from 'components/common/types/ConfirmationDialogAction'

// The first item in validActions is going to be used as the defaultAction
export function validateAction (action: Action, actionType: ActionType, validActions: readonly Action[]): string {
  const defaultAction = validActions[0]
  if (!validActions.includes(action)) {
    console.warn(`Action '${action}' is not recognized, defaulting to '${defaultAction}'`)
    action = defaultAction
  }
  return I18n.t(`components.confirmation_dialog.${ActionType[actionType]}.${action}`)
}
