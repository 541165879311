import React from 'react'
import I18n from 'i18n'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export default class ChartView extends React.Component {
  showBarChart (data) {
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            type: 'bar'
          },
          title: {
            text: I18n.t('components.detail_views.volleyball.volleyball_data')
          },
          series: [
            {
              name: I18n.t('components.detail_views.volleyball.max_jump'),
              data: data.map((entry) => entry[1])
            },
            {
              name: I18n.t('components.detail_views.volleyball.mean_jump'),
              data: data.map((entry) => entry[2])
            }
          ],
          yAxis: {
            title: {
              text: 'cm'
            }
          },
          xAxis: {
            categories: data.map((entry) => entry[0]) // the corresponding player id's
          },
          credits: {
            enabled: false
          }
        }}
      />
    )
  }

  processData (data) {
    data.sort(function (a, b) {
      // In some cases the data in the platform did not have these ids.
      // This should only happen in develop, but just to be sure, make sure the
      // whole platform  doesn't crash if these ids are missing.
      if (!a.playerid || !b.playerid) {
        return 0
      }
      return a.playerid.split(' ')[1] - b.playerid.split(' ')[1]
    })
    return (data.map((entry) => {
      return [entry.playerid, parseInt(entry.sprong_max), parseInt(entry.sprong_mean)]
    }))
  }

  render () {
    if (!this.props.data) {
      return (<></>)
    }
    const data = this.processData(this.props.data.data_rows)

    return (
      <div>
        {this.showBarChart(data)}
      </div>
    )
  }
}
