import React, { FC, ReactElement } from 'react'
import Toggle from 'components/atomic/atoms/Toggle'
import useSdvNextHook from './index.hooks'
import I18n from 'i18n'
import Info from 'components/atomic/atoms/Info'

const ToggleSdvNext: FC = (): ReactElement => {
  const { toggleSdvNext, sdvNextEnabled } = useSdvNextHook()
  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <div className='text-heavy text-l text-muted data-header'>
            {I18n.t('profile_edit.sdv_next.title')}
            <Info text={I18n.t('profile_edit.sdv_next.info')} tooltipId='sdv-next-title-tooltip' />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <Toggle id='sdv-next' isChecked={sdvNextEnabled} title={I18n.t('profile_edit.sdv_next.toggle')} onChange={toggleSdvNext} />
        </div>
      </div>
    </>
  )
}

ToggleSdvNext.defaultProps = {
}
export default ToggleSdvNext
