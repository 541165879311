import React from 'react'
import MapView from '../../../../data/layout/detail/MapView'

const FitFileRecordFlavour = (props) => {
  // For now we only know about gps coordinates, so this is the only visualization we implement
  return (
    <div className='timeline-card-visualization'>
      <MapView data={props.sdo} />
    </div>
  )
}

export default FitFileRecordFlavour
