import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { getCumulativeDistribution } from '../../../common/Math'
import { Line } from 'react-chartjs-2'
import _ from 'lodash'

const CumulativeChart = (props) => {
  const datasets = useMemo(() => (
    props.datasets.map((d, idx) => (
      {
        label: `dataset-${idx}`,
        ..._.get(props, `datasetProperties[${idx}]`, {}),
        data: getCumulativeDistribution(d)
      }
    ))
  ), [props.datasets])

  const data = {
    labels: props.labels,
    datasets: datasets
  }

  return (
    <Line data={data} options={props.options} />
  )
}

CumulativeChart.propTypes = {
  // Array of datasets. Each dataset is an array of y values representing the deltas of the cumulative distribution
  datasets: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  labels: PropTypes.array,
  // Properties that can be found in chartjs' dataset object
  datasetProperties: PropTypes.arrayOf(PropTypes.object),
  // A chartjs options object
  options: PropTypes.object
}

export default CumulativeChart
