import axios from 'axios'
import Routes from './common/Routes'
import _ from 'lodash'

export const PREVIOUS_URL_COOKIE = 'previous_url'

const defaultOptions = {
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  }
}

const axiosInstance = axios.create(defaultOptions)

/* Add response interceptor to axios before exporting */
axiosInstance.interceptors.response.use((response) => {
  /* Simply return the response if everything was ok */
  return response
}, (error) => {
  /* check if the response indicates that terms of service are required */
  if (_.get(error, 'response.status') === 401 && _.get(error, 'response.data.error_key') === 'tos_required') {
    /* only redirect to terms of service page if we are not already on a page that lists or accepts the ToS */
    if (!String(window.location).endsWith(Routes.static.acceptTerms) &&
        !(String(window.location).includes(Routes.static.termsAndConditionsVersionless)) &&
        !(String(window.location).includes(Routes.static.termsAndConditionsVersionlessDutchLegacy))) {
      // Store the current url so we can redirect to it later. Note that we
      // don't want to rely on document.referrer for this, as this might cause
      // issues in some browsers.
      document.cookie = `${PREVIOUS_URL_COOKIE}=${window.location.href}; path=/`
      /* Redirect (note: this is in a React component, so we can't use react router) */
      window.location = Routes.static.acceptTerms
    }
  } else {
    /* Return the original error */
    return Promise.reject(error)
  }
})

export const MyAxios = axiosInstance

// Fetches all the pages of a paginated response
export const getAllPages = async (url, headers, response_callback = () => {}) => {
  let response = []
  let hasNext = true
  let nextPage = 1

  while (hasNext) {
    const res = await MyAxios({
      method: 'GET',
      url: `${url}?page=${nextPage}`,
      headers: headers
    })
    response = response.concat(res.data.data)
    hasNext = res.data.page.has_next
    nextPage = res.data.page.current_page + 1

    response_callback(res)
  }

  return response
}

export default MyAxios
