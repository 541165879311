import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { hcDarkOrange, hcBlack, hcOrange } from '../../../../common/Constants'
import I18n from 'i18n'
import { initializeHighcharts, titleWithTooltip } from '../../../../common/Utils'
import GenericPlaceholder from '../../../../common/GenericPlaceholder'

class TrainingPeriodizationChart extends React.Component {
  constructor (props) {
    super(props)

    initializeHighcharts(true)
    this.state = {}
  }

  render () {
    if (!this.props.weeklySummary) {
      return (
        <GenericPlaceholder title={I18n.t('components.dashboards.questionnaire.training_periodization.title')} />
      )
    }
    const areaData = this.props.weeklySummary.map(entry => {
      return {
        x: entry.date_only,
        y: entry.training_strain,
        color: entry.training_monotony >= 2 ? hcDarkOrange : hcBlack,
        training_monotony: entry.training_monotony,
        wknum: entry.wknum_formatted
      }
    })
    const columnData = this.props.weeklySummary.map(entry => {
      return {
        x: entry.date_only,
        y: entry.training_load,
        wknum: entry.wknum_formatted
      }
    })
    return (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          immutable
          options={{
            title: titleWithTooltip(
              I18n.t('components.dashboards.questionnaire.training_periodization.title'),
              I18n.t('components.dashboards.questionnaire.training_periodization.info')
            ),
            subtitle: {
              text: I18n.t('components.dashboards.questionnaire.training_periodization.subtitle')
            },
            series: [
              {
                type: 'area',
                data: areaData,
                tooltip: {
                  headerFormat: '',
                  pointFormat: I18n.t('components.dashboards.questionnaire.training_periodization.area.tooltip')
                }
              },
              {
                type: 'column',
                data: columnData,
                tooltip: {
                  headerFormat: '',
                  pointFormat: I18n.t('components.dashboards.questionnaire.training_periodization.column.tooltip')
                }
              }
            ],
            xAxis: {
              title: {
                text: ''
              },
              type: 'datetime',
              dateTimeLabelFormats: {
                day: '%d %b'
              }
            },
            yAxis: {
              title: {
                text: I18n.t('components.dashboards.questionnaire.training_periodization.yaxis')
              },
              min: 0
            },
            plotOptions: {
              series: {
                stacking: 'normal',
                animation: false
              },
              column: {
                color: hcOrange
              },
              area: {
                color: hcBlack,
                fillOpacity: 0.3
              }
            }
          }}
        />
      </>
    )
  }
}

export default TrainingPeriodizationChart
