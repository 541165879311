import React, { useEffect, useState } from 'react'
import I18n from 'i18n'
import DatePicker from 'react-datepicker'

import { DropdownInput } from 'components/atomic/atoms/inputs'
import { fillMissingSports, getCategoryDropdownContent, getPermissionsDropdownContent, getSharedWithDropdownContent, getTagsDropdownContent } from './helpers'
import Sport from 'components/common/types/Sport'
import { IconMap } from 'components/common/types/Icon'
import CollectionRight from 'components/common/types/Right'
import Group, { GroupType } from 'components/common/types/Group'
import Permission from 'components/common/types/Permission'
import Connection from 'components/common/types/Connection'
import { useGroupsQuery } from 'components/backend/Queries'
import useMaterializeTextField from 'components/hooks/MaterializeTextField'
import useMaterializeSelect from 'components/hooks/MaterializeSelect'
import { datePickerDefaultOptions } from 'components/common/Constants'

interface Props {
  startDate?: Date
  endDate?: Date
  right?: CollectionRight
  sports: Sport[]
  allSports: Sport[]
  sportIcons: IconMap
  permissions: Permission[]
  permissionIcons: IconMap
  publicGroup?: Group
  availableGroups: Group[]
  availableConnections: Connection[]
  onSave: (rightId?: number) => void
  availableTags: string[]
}

// This component allows the user to create or modify existing sharing rules
const DataSharingForm: React.FunctionComponent<Props> = (props) => {
  const [shareByTag, setShareByTag] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)

  const {
    startDate: propsStartDate,
    endDate: propsEndDate,
    right,
    availableTags,
    availableGroups,
    availableConnections,
    sports: propsSports,
    allSports,
    permissions,
    publicGroup,
    permissionIcons,
    sportIcons,
    onSave
  } = props

  useMaterializeTextField()

  useEffect(() => {
    setStartDate((propsStartDate != null) ? new Date(propsStartDate) : null)
    setEndDate((propsEndDate != null) ? new Date(propsEndDate) : null)
    setShareByTag(right !== undefined && right.collection_type === 'tag')
  }, [])

  // Why does swiching the "share by tag" involve reinitializing all selects?
  useMaterializeSelect([shareByTag])

  const sports = fillMissingSports(right, propsSports, allSports)

  // TODO When parent component is functional, add the groups in the props and add this hook to the parent component that fetches the groups
  const { data: groupsAndConnections = new Array<Group>() } = useGroupsQuery()
  const groups: Group[] = groupsAndConnections?.filter((group) => group.group_type === GroupType.GROUP) ?? []

  const categoryDropdownContent = getCategoryDropdownContent(sports, sportIcons)
  const tagsDropdownContent = getTagsDropdownContent(availableTags, groups)
  const permissionsDropdownContent = getPermissionsDropdownContent(permissions, permissionIcons)
  const sharedWithDropdownContent = getSharedWithDropdownContent(publicGroup, availableGroups, availableConnections)

  return (
    <form>
      <div className='container'>
        <div className='row'>
          <div className='col s12 text-primary-color text-xl'>
            {I18n.t('datasharing.form_title')}
          </div>
        </div>
        <div className='row'>
          <div className='col s12 text-primary-color text-l'>
            {I18n.t('datasharing.source_title')}
          </div>
          <div className='col s12'>
            <div className='switch' style={{ display: 'inline' }}>
              <label>
                {I18n.t('datasharing.by_category')}
                {/* TODO: figure out how to decide if right is sharing by tag when editing and existing one (depends on backend model) */}
                <input id='share-by-tag' type='checkbox' checked={shareByTag} onChange={() => setShareByTag(!shareByTag)} />
                <span className='lever' />
                {I18n.t('datasharing.by_tag')}
              </label>
            </div>
          </div>
          {!shareByTag &&
            <div id='source-selector' className='col s12 z-top'>
              <DropdownInput
                id='source'
                label={I18n.t('datasharing.labels.source')}
                defaultValue={right?.collection_type}
                content={categoryDropdownContent}
              />
            </div>}
          {shareByTag &&
            <div id='source-selector' className='col s12 z-top'>
              <DropdownInput
                id='tag'
                label={I18n.t('datasharing.labels.tag')}
                defaultValue={right?.tag}
                content={tagsDropdownContent}
              />
            </div>}

          <div className='col s12 text-primary-color text-l'>
            {I18n.t('datasharing.permission_title')}
          </div>
          <div id='rights-selector' className='col s12 z-middle'>
            <DropdownInput
              id='permission'
              label={I18n.t('datasharing.labels.permission')}
              defaultValue={right?.access_level}
              content={permissionsDropdownContent}
            />
          </div>

          <div className='col s12 text-primary-color text-l'>
            {I18n.t('datasharing.followers_title')}
          </div>
          <div id='group-selector' className='col s12'>
            <DropdownInput
              id='who'
              label={I18n.t('datasharing.labels.shared_with')}
              defaultValue={right?.group?.id}
              content={sharedWithDropdownContent}
            />
          </div>

          <div className='col s12 text-primary-color text-l'>
            {I18n.t('datasharing.time_period_title')}
          </div>
          <div id='start-date-selector' className='input-field col s6'>
            <DatePicker
              id='start'
              {...datePickerDefaultOptions()}
              selected={startDate}
              onChange={date => setStartDate(date)}
            />
            <label htmlFor='start' className={(startDate != null) ? 'active' : ''}>{I18n.t('datasharing.labels.start_date')}</label>
          </div>

          <div id='end-date-selector' className='input-field col s6'>
            <DatePicker
              id='end'
              {...datePickerDefaultOptions()}
              selected={endDate}
              onChange={date => setEndDate(date)}
            />
            <label htmlFor='end' className={(endDate != null) ? 'active' : ''}>{I18n.t('datasharing.labels.end_date')}</label>
          </div>

        </div>

        <div className='row'>
          <div className='col s12 right-align'>
            <button
              className='waves-effect waves-light text-primary-color text-heavy background-primary button-primary button-primary text-background-color button-autowidth'
              onClick={(e) => { e.preventDefault(); onSave(right?.id) }}
            >{I18n.t('datasharing.save_rule')}
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default DataSharingForm
