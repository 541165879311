import I18n from 'i18n'
import React from 'react'
import { QUESTIONNAIRE_ENGINE_TIMEOUT } from '../../common/Constants'
import FeatureFlag, { QUESTIONNAIRES_AUTOMATIC_GROUP_JOIN_PROTOCOL } from '../../feature_flags/FeatureFlag'
import { Link } from 'react-router-dom'
import Poller from '../../common/Poller'
import ListActiveProtocolSubscriptions from './ListActiveProtocolSubscriptions'
import ListInactiveProtocolSubscriptions from './ListInactiveProtocolSubscriptions'
import CreateProtocolSubscription from './CreateProtocolSubscription'
import moment from 'moment'
import AvailableMembers from './AvailableMembers'
import QuestionnaireDefinitions from './QuestionnaireDefinitions'
import SpinnerWrapper from '../../common/SpinnerWrapper'
import ActiveGroupProtocolList from 'components/atomic/organisms/ActiveGroupProtocolList'

const sortInactiveProtocolSubscriptions = (a, b) => {
  const aEndDate = moment(a.end_date)
  const bEndDate = moment(b.end_date)
  if (aEndDate < bEndDate) return 1
  if (aEndDate > bEndDate) return -1
  return 0
}
const sortActiveProtocolSubscriptions = (a, b) => {
  const aEndDate = moment(a.end_date)
  const bEndDate = moment(b.end_date)
  if (aEndDate < bEndDate) return -1
  if (aEndDate > bEndDate) return 1
  return 0
}

class ManageGroupQuestionnaires extends React.Component {
  constructor (props) {
    super(props)
    this.pollerElement = React.createRef()
    this.state = { rotating: false, expectedCount: 0 }
    this.timer = undefined
  }

  componentDidMount () {
    const elems = document.querySelectorAll('.tabs.questionnaires')
    M.Tabs.init(elems)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.protocolSubscriptions.map(protocolSubscription => [protocolSubscription.id, protocolSubscription.state, protocolSubscription.end_date])) !==
      JSON.stringify(this.props.protocolSubscriptions.map(protocolSubscription => [protocolSubscription.id, protocolSubscription.state, protocolSubscription.end_date])) &&
      this.state.rotating && this.props.protocolSubscriptions.length === this.state.expectedCount) {
      clearTimeout(this.timer)
      this.setState({ rotating: false })
    }
  }

  // If we never hit the expected amount, we stop spinning after the specified time out.
  // (the time out is restarted after a change).
  startRotatingForDifference (amount) {
    // When we start a protocol, make the polling process fast again
    this.pollerElement.current.reset()
    clearTimeout(this.timer)
    this.timer = setTimeout(() => this.setState({ rotating: false }), QUESTIONNAIRE_ENGINE_TIMEOUT)
    if (this.state.rotating) {
      // change the expected amount
      this.setState({ expectedCount: this.state.expectedCount + amount })
    } else {
      // calculate the new expected count using the current count
      this.setState({ rotating: true, expectedCount: this.props.protocolSubscriptions.length + amount })
    }
  }

  componentWillUnmount () {
    clearTimeout(this.timer)
  }

  handleCreateProtocolSubscriptions (groupId, protocolName, startDate, endDate, members, invitationTextNl, invitationTextEn) {
    this.startRotatingForDifference(members.length)
    this.props.onCreateProtocolSubscriptions(groupId, protocolName, startDate, endDate, members, invitationTextNl, invitationTextEn)
  }

  handleDestroyProtocolSubscription (groupId, protocolSubscriptionId) {
    // Spin until we detect a change in protocolsubscriptions
    this.startRotatingForDifference(0)
    this.props.onDestroyProtocolSubscription(groupId, protocolSubscriptionId)
  }

  handleUpdateProtocolSubscriptionEndDate (groupId, protocolSubscriptionId, endDate) {
    // Spin until we detect a change in protocolsubscriptions
    this.startRotatingForDifference(0)
    this.props.onUpdateProtocolSubscriptionEndDate(groupId, protocolSubscriptionId, endDate)
  }

  refreshProtocolSubscriptions () {
    this.refreshRotationAnimation(this.props.refreshProtocolSubscriptions)
  }

  refreshGroupProtocolSubscriptions () {
    this.refreshRotationAnimation(() => this.props.groupProtocolSubscriptionHandlers.fetchGroupProtocolSubscriptions(this.props.group.id))
  }

  refreshRotationAnimation (callback) {
    if (!this.state.rotating) {
      this.timer = setTimeout(() => this.setState({ rotating: false }), 1000)
      this.setState({ rotating: true })
    }
    callback()
  }

  render () {
    if (!this.props.group) return <></>
    return (
      <>
        <div className='row'>
          <div className='col s12'>
            <ul className='tabs questionnaires'>
              <li className='tab col s2'>
                <Link className='active' to='#list-active-questionnaires'>
                  {I18n.t('group.detail.questionnaires.active_questionnaires')}
                </Link>
              </li>
              <li className='tab col s4'>
                <Link to='#list-inactive-questionnaires'>
                  {I18n.t('group.detail.questionnaires.inactive_questionnaires')}
                </Link>
              </li>
              <li className='tab col s4'>
                <Link to='#available-members'>
                  {I18n.t('group.detail.questionnaires.available_members')} ({this.props.members.length})
                </Link>
              </li>
              <li className='tab col s2'>
                <Link to='#questionnaire-definitions'>
                  {I18n.t('group.detail.questionnaires.questionnaire_definitions')}
                </Link>
              </li>
            </ul>
          </div>
          <div id='list-active-questionnaires' className='col s12 tab-content active'>
            <SpinnerWrapper ready={this.props.protocolSubscriptionsRetrieved} transparent>
              <div className='row'>
                <div className='col s12'>
                  <CreateProtocolSubscription
                    availableProtocols={this.props.availableProtocols}
                    members={this.props.members}
                    onCreateProtocolSubscriptions={this.handleCreateProtocolSubscriptions.bind(this)}
                    group={this.props.group}
                    groupProtocolSubscriptionHandlers={this.props.groupProtocolSubscriptionHandlers}
                  />
                </div>
              </div>
            </SpinnerWrapper>

            <FeatureFlag name={QUESTIONNAIRES_AUTOMATIC_GROUP_JOIN_PROTOCOL} defaultChildren={<></>}>
              <SpinnerWrapper ready={this.props.groupProtocolSubscriptionHandlers.groupProtocolSubscriptionsRetrieved} transparent>
                <ActiveGroupProtocolList
                  onDestroy={this.props.groupProtocolSubscriptionHandlers.handleDestroyGroupProtocolSubscriptions}
                  onRefresh={this.refreshGroupProtocolSubscriptions.bind(this)}
                  groupProtocolSubscriptions={this.props.groupProtocolSubscriptionHandlers.groupProtocolSubscriptions}
                  group={this.props.group}
                  rotating={this.state.rotating}
                />
              </SpinnerWrapper>
            </FeatureFlag>
            <SpinnerWrapper ready={this.props.protocolSubscriptionsRetrieved} transparent>
              <ListActiveProtocolSubscriptions
                protocolSubscriptions={this.props.protocolSubscriptions.filter(protSub => protSub.state === 'active').sort(sortActiveProtocolSubscriptions)}
                rotating={this.state.rotating}
                createProtocolSubscriptionProps={{
                  group: this.props.group,
                  onUpdateProtocolSubscriptionEndDate: this.handleUpdateProtocolSubscriptionEndDate.bind(this),
                  onDestroyProtocolSubscription: this.handleDestroyProtocolSubscription.bind(this)
                }}
                handleRefreshProtocolSubscriptions={this.refreshProtocolSubscriptions.bind(this)}
              />
            </SpinnerWrapper>
          </div>
          <div id='list-inactive-questionnaires' className='col s12 tab-content'>
            <div className='row'>
              <div className='col s12'>
                <div className='text-heavy text-l text-muted data-header inline-block'>
                  {I18n.t('group.detail.questionnaires.inactive_questionnaires')}
                </div>
              </div>
            </div>
            <ListInactiveProtocolSubscriptions
              protocolSubscriptions={this.props.protocolSubscriptions.filter(protSub => protSub.state !== 'active').sort(sortInactiveProtocolSubscriptions)}
            />
          </div>
          <div id='available-members' className='col s12 tab-content'>
            <div className='row'>
              <div className='col s12'>
                <div className='text-heavy text-l text-muted data-header'>
                  {I18n.t('group.detail.questionnaires.available_members')} ({this.props.members.length})
                </div>
              </div>
            </div>
            <AvailableMembers members={this.props.members} />
          </div>
          <div id='questionnaire-definitions' className='col s12 tab-content'>
            <div className='row'>
              <div className='col s12'>
                <div className='text-heavy text-l text-muted data-header'>
                  {I18n.t('group.detail.questionnaires.questionnaire_definitions')}
                </div>
              </div>
            </div>
            <QuestionnaireDefinitions />
          </div>
        </div>
        <Poller ref={this.pollerElement} functionToPoll={this.props.refreshProtocolSubscriptions} />
      </>
    )
  }
}

export default ManageGroupQuestionnaires
