import React from 'react'
import _ from 'lodash'
import TrainingLog from './questionnaires/TrainingLog'
import DailyQuestionnaire from './questionnaires/DailyQuestionnaire'
import SundayQuestionnaire from './questionnaires/SundayQuestionnaire'
import ComplaintsQuestionnaire from './questionnaires/ComplaintsQuestionnaire'
import WeeklyWellbeingQuestionnaire from './questionnaires/WeeklyWellbeingQuestionnaire'
import Summary from './common/Summary'
import PsychologicalCompetenciesQuestionnaire
  from 'components/timeline/layout/details/questionnaires/PsychologicalCompetenciesQuestionnaire'
import { psychologicalCompetenciesQuestionnaire } from 'components/common/Constants'

const QuestionnaireDetails = (props) => {
  const responseData = _.get(props.data, 'structured_data_objects[0].data_rows[0]', undefined)

  const getDetails = (key) => {
    switch (key) {
      case 'training_log':
        return <TrainingLog data={responseData} />
      case 'daily_questionnaire_maandag':
      case 'daily_questionnaire_dinsdag':
      case 'daily_questionnaire_woensdag':
      case 'daily_questionnaire_donderdag':
      case 'daily_questionnaire_vrijdag':
      case 'daily_questionnaire_zaterdag':
      case 'daily_questionnaire_zondag':
        return <DailyQuestionnaire data={responseData} />
      case 'sunday_questionnaire':
        return <SundayQuestionnaire data={responseData} />
      case 'ostrc_h_o':
      case 'ostrc_o':
        return <ComplaintsQuestionnaire data={responseData} />
      case 'weekly_wellbeing':
        return <WeeklyWellbeingQuestionnaire data={responseData} />
      case psychologicalCompetenciesQuestionnaire:
        return <PsychologicalCompetenciesQuestionnaire data={responseData} />
      default:
        return <></>
    }
  }

  const key = _.get(responseData, 'questionnaire.key', undefined)
  const details = getDetails(key)

  return (
    <>
      <Summary icon={props.icon} metadatum={props.metadatum} />
      {details}
    </>
  )
}

export default QuestionnaireDetails
