import React from 'react'
import QuestionnaireView from './detail/QuestionnaireView'
import ChartView from './detail/ChartView'
import ColumnChartView from './detail/ColumnChartView'
import Tag from '../../atomic/atoms/Tag'
import NotebookView from './detail/NotebookView'
import I18n from 'i18n'
import {
  accessLevelIsAtLeast,
  formatDateTime,
  dashboardLink,
  isMyData,
  doJupyterNotebookDownload,
  shouldShowDashboardLink,
  myHandleRequest
} from '../../common/Utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BackButton from '../../common/BackButton'
import {
  collectionTypes,
  dataTypes,
  subscriptionTypes,
  accessLevel as accessLevelDefinition,
  dataObjectStages
} from '../../common/Constants'
import Spinner from '../../common/Spinner'
import SoccerView from './SoccerView'
import { Link } from 'react-router-dom'
import { WithBackend } from '../../backend/BackendProvider'
import { WithModal } from '../../modal/ModalProvider'
import { WithSession } from '../../session/SessionProvider'
import { WithCallFeedbackMessageHook } from '../../hooks/CallFeedbackMessage'
import ExpandCompressButton from '../../common/ExpandCompressButton'
import RidingView from './detail/RidingView'
import HikingView from './detail/HikingView'
import _ from 'lodash'
import RunningView from './detail/RunningView'
import SwimmingView from './detail/SwimmingView'
import InlineSkatingView from './detail/InlineSkatingView'
import { MyAxios as axios } from '../../MyAxios'
import Download from '../../common/Download'
import CsvDetailView from './detailedview/CsvDetailView'
import NotFoundMessage from '../../router/NotFoundMessage'
import FitBitDetailView from './detail/FitBitDetailView'
import TrainingLogButton from '../../common/TrainingLogButton'
import PremiumOverlay from '../../premium_membership/PremiumOverlay'
import Routes from '../../common/Routes'
import Uploader from './Uploader'
import LinkedDatasets from './detail/LinkedDatasets'
import InfoNotice from './detail/InfoNotice'
import TempView from './detail/TempView'

import FeatureFlag, { EXECUTABLE_NOTEBOOKS } from '../../feature_flags/FeatureFlag'
import IceSkatingView from 'components/data/layout/detail/IceSkatingView'
import GarminDetailView from 'components/data/layout/detail/GarminDetailView'
import PolarDetailView from 'components/data/layout/detail/PolarDetailView'

class ShowData extends React.Component {
  constructor () {
    super()
    this.state = {
      dataId: null
    }
  }

  componentDidMount () {
    const { match: { params: { dataId } } } = this.props
    this.setState({ dataId: dataId })
    this.props.loadData(dataId)
  }

  toEditData (id) {
    this.props.history.push(Routes.data.editFn(id))
  }

  dataType (vdo) {
    if (!vdo || !vdo.data_type || !vdo.data_type.data_type) return I18n.t('components.data.unknown')
    if (vdo.data_type.data_type === 'unstructured') return I18n.t('components.data.unstructured')
    // do not show "we_fitter_type"
    const vdoDataType = vdo.data_type.data_type === dataTypes.we_fitter_type ? 'sdv_connect_type' : vdo.data_type.data_type
    return `${I18n.t('components.data.structured')} (${vdoDataType})`
  }

  showRemoveDialog () {
    const displayTitle = (this.props.data.versioned_data_object && this.props.data.versioned_data_object.metadatum.title) || I18n.t('components.timeline.untitled_activity')
    this.props.setConfirmationDialog({ onConfirmation: this.remove.bind(this), target: displayTitle, action: 'delete' })
  }

  showLink () {
    this.props.setModalComponent(Uploader, {
      onRequest: myHandleRequest,
      submitMethod: 'POST',
      endpoint: '/api/v1/data',
      onUploadComplete: () => {},
      linkedVdoId: this.state.dataId
    })
  }

  handleScheduleNotebook (id) {
    const pm = this.props.backend.notebook.run({ versioned_data_object_id: id })
    this.props.handleCallFeedback(pm,
      I18n.t('flashmessages.data.schedule_notebook.succesful'),
      I18n.t('flashmessages.data.schedule_notebook.failed')
    )
    window.focus()
  }

  remove () {
    this.props.removeData(this.props.data.versioned_data_object.id).then(() => this.props.history.push('/'))
  }

  renderDetails (data, structured) {
    if (data?.roasting_errors?.length > 0) {
      return (
        <>
          <div className='text-heavy text-l text-muted data-header'>{I18n.t('components.data.detailed_overview')}</div>
          {
            data.roasting_errors.map((e, idx) => (
              <InfoNotice key={`${e.message}-${idx}`} className='margin-top' msg={e.message} title={e.error} />
            ))
          }
        </>
      )
    } else if (data?.stage === dataObjectStages.failed_stage) {
      return (
        <>
          <div className='text-heavy text-l text-muted data-header'>{I18n.t('components.data.detailed_overview')}</div>
          <InfoNotice className='margin-top' msg={I18n.t('components.data.failed_stage_error_msg')} />
        </>
      )
    } else {
      return structured
    }
  }

  render () {
    const accessLevel = this.props.data && this.props.data.access_level
    const data = this.props.data?.versioned_data_object // eslint-disable-line camelcase
    const dataRows = _.get(data, 'structured_data_objects[0].data_rows')

    if (this.props.dataNotFound) {
      return <NotFoundMessage />
    }

    if (!data || data.id.toString() !== this.state.dataId) {
      return (
        <div className='container general-container'>
          <div className='col s12 m8 l8'>
            <Spinner ready={false} />
          </div>
        </div>
      )
    }

    let structured = <></>
    // TODO: right now we treat any fit data type as 'running', should filter by sport
    const detailedTypes = [
      dataTypes.fit_data_type,
      dataTypes.fitbit_type,
      dataTypes.garmin_type,
      dataTypes.polar_type,
      dataTypes.volleybal_stats_type,
      dataTypes.volleybal_jump_type,
      dataTypes.questionnaire_type,
      dataTypes.ice_skating_lap_type,
      dataTypes.jupyterhub_type,
      dataTypes.generic_csv_type,
      dataTypes.my_temp_csv_type
    ]

    const hideTrainingLogForType = [
      dataTypes.questionnaire_type,
      dataTypes.jupyterhub_type,
      dataTypes.generic_csv_type,
      dataTypes.polar_type,
      dataTypes.garmin_type,
      dataTypes.fitbit_type
    ]

    const collectionType = _.get(data, 'summary.collection_type')

    if ((data.data_type !== undefined && detailedTypes.includes(data.data_type.data_type)) ||
      (collectionType !== undefined && Object.values(collectionTypes).includes(collectionType))) {
      structured = (
        <>
          <div className='text-heavy text-l text-muted data-header'>{I18n.t('components.data.detailed_overview')}</div>

          {data.data_type.data_type === dataTypes.volleybal_stats_type &&
            <>
              <ChartView data={data.structured_data_objects[0]} />
            </>}
          {data.data_type.data_type === dataTypes.volleybal_jump_type &&
            <>
              <ColumnChartView data={data.structured_data_objects[0]} />
            </>}
          {data.data_type.data_type === dataTypes.fitbit_type &&
            <>
              <FitBitDetailView data={data.structured_data_objects[0]} />
            </>}
          {data.data_type.data_type === dataTypes.garmin_type &&
            <>
              <GarminDetailView data={data.structured_data_objects[0]} />
            </>}
          {data.data_type.data_type === dataTypes.polar_type &&
            <>
              <PolarDetailView data={data.structured_data_objects[0]} />
            </>}
          {data.data_type.data_type === dataTypes.questionnaire_type &&
            <QuestionnaireView data={data.structured_data_objects[0]} />}
          {data.data_type.data_type === dataTypes.jupyterhub_type &&
            <NotebookView data={data.structured_data_objects[0]} />}
          {data.data_type.data_type === dataTypes.generic_csv_type &&
            <CsvDetailView id={data.id} data={data.summary} />}
          {collectionType === collectionTypes.RIDING && <RidingView data={data} />}
          {collectionType === collectionTypes.RUNNING && <RunningView data={data} />}
          {collectionType === collectionTypes.SWIMMING && <SwimmingView data={data} />}
          {collectionType === collectionTypes.INLINE_SKATING && <InlineSkatingView data={data} />}
          {collectionType === collectionTypes.SOCCER && <SoccerView data={data} />}
          {collectionType === collectionTypes.HIKING && <HikingView data={data} />}
          {collectionType === collectionTypes.TEMP && <TempView data={data} />}
          {collectionType === collectionTypes.ICE_SKATING && <IceSkatingView data={data} />}
        </>
      )
    }

    // Note that this button is set to undefined as we can then enable /
    // disable it in the detailed view later.
    let dashboardButton
    let downloadButton
    let removeButton
    let editButton
    let trainingLogButton
    let linkVdoButton
    let runNotebookButton

    if (accessLevelIsAtLeast(accessLevel, accessLevelDefinition.FULL)) {
      editButton = (
        <button style={{ marginRight: '1rem' }} id='edit-data' className='data-button data-detail-page waves-effect text-m waves-light button-primary text-primary-color background-background text-medium' onClick={() => this.toEditData(data.id)}>
          <FontAwesomeIcon icon='pen' className='button-icon' />
          {I18n.t('components.data.edit')}
        </button>
      )
      removeButton = (
        <button
          style={{ marginRight: '1rem' }}
          id='delete-data-button'
          className='data-button data-detail-page waves-effect waves-light background-accent button-accent text-background-color button-autowidth text-medium text-m'
          onClick={this.showRemoveDialog.bind(this)}
        >
          <FontAwesomeIcon className='button-icon' icon='trash' />
          {I18n.t('components.data.remove_dataset')}
        </button>
      )
      linkVdoButton = (
        <button
          style={{ marginRight: '1rem' }}
          id='link-data-button'
          className='data-button data-detail-page waves-effect waves-light background-background button-primary text-primary-color button-autowidth text-medium text-m'
          onClick={this.showLink.bind(this)}
        >
          <FontAwesomeIcon className='button-icon' icon='link' />
          {I18n.t('components.data.link_dataset')}
        </button>
      )
    }

    if (accessLevelIsAtLeast(accessLevel, accessLevelDefinition.FULL)) {
      if (data.data_type.data_type === dataTypes.jupyterhub_type) {
        downloadButton = (
          <Download onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            axios({ method: 'GET', url: `/api/v1/data/${data.id}/download` }).then(doJupyterNotebookDownload)
          }}
          />
        )
        const fakeNotebookButton = (
          <button
            style={{ marginRight: '1rem' }}
            id='run-notebook-button'
            className='data-button data-detail-page waves-effect waves-light background-background button-primary text-primary-color button-autowidth text-medium text-m'
            disabled
          ><FontAwesomeIcon className='button-icon' icon='play' />
            {I18n.t('components.data.run_notebook')}
          </button>
        )

        const actualNotebookButton = (
          <button
            style={{ marginRight: '1rem' }}
            id='run-notebook-button'
            className='data-button data-detail-page waves-effect waves-light background-background button-primary text-primary-color button-autowidth text-medium text-m'
            onClick={() => { this.handleScheduleNotebook(data.id) }}
          >
            <FontAwesomeIcon className='button-icon' icon='play' />
            {I18n.t('components.data.run_notebook')}
          </button>
        )
        runNotebookButton = <FeatureFlag name={EXECUTABLE_NOTEBOOKS} defaultChildren={fakeNotebookButton}>{actualNotebookButton}</FeatureFlag>
      } else {
        downloadButton = <Download url={`/api/v1/data/${data.id}/download`} />
      }
    }

    const sport = _.get(data, 'metadatum.sport.name')
    const shouldShowDashboardButton = shouldShowDashboardLink(sport, dataRows)
    const dataIsMine = isMyData(this.props.myProfile, data)
    const type = data.data_type.data_type
    const dashboardId = type === dataTypes.questionnaire_type ? data.owner.id : data.id

    // TODO The questionnaire type should come in data_type! Change the backend and remove the atrocity below
    const questionnaireType = data?.structured_data_objects?.[0]?.data_rows?.[0]?.questionnaire?.key
    const dashboardUrl = `${dashboardLink(
      collectionType || sport,
      dashboardId,
      dataIsMine,
      questionnaireType
    )}${type === dataTypes.we_fitter_type ? '#activities-0' : ''}`

    let hasDetails = true
    if (type === dataTypes.fitbit_type) {
      hasDetails = dataIsMine
    }
    if (accessLevelIsAtLeast(accessLevel, accessLevelDefinition.READ) && dashboardUrl && shouldShowDashboardButton && hasDetails) {
      const isPremium = type === dataTypes.generic_csv_type || (type === dataTypes.questionnaire_type && !dataIsMine)
      const activeFor = [subscriptionTypes.researcher]
      if (type === dataTypes.questionnaire_type) activeFor.push(subscriptionTypes.coach)
      dashboardButton = (
        <PremiumOverlay isPremium={isPremium} activeFor={activeFor} showBadge={false} showButton={false}>
          <Link to={dashboardUrl} className='data-button data-detail-page waves-effect text-m waves-light button-primary text-primary-color background-background text-medium'>
            <FontAwesomeIcon icon='chart-bar' className='button-icon' />
            {I18n.t('components.data.dashboard')}
          </Link>
        </PremiumOverlay>
      )
    }
    if (accessLevelIsAtLeast(accessLevel, accessLevelDefinition.OWNER)) {
      trainingLogButton = <TrainingLogButton />
    }
    const eventStart = data.metadatum.event_start ? formatDateTime(data.metadatum.event_start) : ''
    const eventEnd = data.metadatum.event_end ? formatDateTime(data.metadatum.event_end) : ''
    const dataType = this.dataType(this.props.data && this.props.data.versioned_data_object)

    const buttons = [
      editButton,
      runNotebookButton,
      downloadButton,
      dashboardButton,
      !(hideTrainingLogForType.includes(data.data_type.data_type)) && trainingLogButton,
      linkVdoButton,
      removeButton
    ]

    return (
      <div className='container general-container'>
        <div className='col s12 m8 l8'>
          <div className='general-content'>
            <div className='col s12'>
              <div className='row contains-expand-compress-button'>
                <ExpandCompressButton />
                <div className='col s12'>
                  <BackButton {...this.props} />
                </div>
                <div className='col s12 text-heavy text-primary-color text-xl'>
                  {data.metadatum.title || data.metadatum.filename || I18n.t('components.timeline.untitled_activity')}
                </div>
              </div>
              <div className='row'>
                <div className='col s12'>
                  <table className='data-table'>
                    <tbody>
                      <tr>
                        <td>{I18n.t('timeline.metadata.start')}</td>
                        <td>{eventStart}</td>
                      </tr>
                      <tr>
                        <td>{I18n.t('timeline.metadata.end')}</td>
                        <td>{eventEnd}</td>
                      </tr>
                      <tr>
                        <td>{I18n.t('components.data.filename')}</td>
                        <td>{data.filename}</td>
                      </tr>
                      <tr>
                        <td>{I18n.t('components.data.tags')}</td>
                        <td><Tag tags={data.metadatum.tags} /></td>
                      </tr>
                      <tr>
                        <td>{I18n.t('components.data.data_type')}</td>
                        <td>{dataType}</td>
                      </tr>
                      {(data.linked_vdos?.length || 0) > 0 &&
                        <tr>
                          <td>{I18n.t('components.data.linked_datasets')}</td>
                          <td><LinkedDatasets datasets={data.linked_vdos} /></td>
                        </tr>}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='row'>
                <div className='col s12'>
                  {this.renderDetails(data, structured)}
                </div>
              </div>
              <div className='row'>
                <div className='col s12 show-data-buttons'>
                  {buttons.map((button, idx) => {
                    return button && (
                      <span key={`button-${idx}`}>
                        {button}
                      </span>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WithCallFeedbackMessageHook(WithSession(WithModal(WithBackend(ShowData))))
