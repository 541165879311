import React from 'react'
import I18n from 'i18n'
import StackedBarChart from './StackedBarChart'
import { stackedChartColors } from '../../../common/Constants'
import { sortedDays } from '../../../common/Utils'
import { filterAndRotateActivityData } from './ActivityUtils'

const WeeklyStackedBarChart = (props) => {
  const filteredData = filterAndRotateActivityData(props.params, props.barParams, props.datasets)
  const datasets = []
  filteredData.filter(serie => props.barParams.includes(serie.param)).forEach(serie => {
    const idx = props.barParams.findIndex(elem => elem === serie.param)
    datasets.push({
      label: I18n.t(`analysis.dashboard.${serie.param}.title`),
      order: idx,
      backgroundColor: stackedChartColors[idx],
      data: serie.mean
    })
  })

  return (
    <StackedBarChart
      labels={sortedDays()}
      datasets={datasets}
      yLabel={props.yLabel}
      title={props.title}
      scaled={props.scaled}
      stacked={props.stacked}
      tooltipCallbacks={props.tooltipCallbacks}
      yTickCallback={props.yTickCallback}
    />
  )
}

export default WeeklyStackedBarChart
