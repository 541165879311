import React from 'react'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WithModal } from '../modal/ModalProvider'
import TrainingSchedulesAthleteView from '../training/layout/TrainingSchedulesAthleteView'

const TrainingsButton = ({ setModalComponent }) => {
  function openModal () {
    setModalComponent(TrainingSchedulesAthleteView)
  }
  return (
    <div className='col s6 m12'>
      <div
        className='waves-effect text-m waves-light button-primary text-primary-color background-background text-medium button welcome-container-button trainings-button'
        onClick={openModal}
      >
        <FontAwesomeIcon icon='calendar-week' className='margin-right' />
        {I18n.t('components.welcome.view_trainings')}
      </div>
    </div>
  )
}

export default WithModal(TrainingsButton)
