import { useContext, useEffect, useState } from 'react'
import { isNumber, isString } from 'lodash'
import { SessionContext } from 'components/session/SessionProvider'

interface TourProps {
  stepIndex: number
  continuous: boolean
  ready: boolean
}
type TourSetFn = (stepIndex: number) => void
type TourHook = [TourProps, TourSetFn]

const getInitStep = (tourKey: string): number => parseInt(window.localStorage.getItem(tourKey) ?? '0')

// Handles the stateful logic of the Tour component.
// Returns props to be passed to the Tour component, along with a function to change the Tour step.
export const useTour = (tourId: string): TourHook => {
  const session = useContext(SessionContext) // TODO: add the type once Session is a type
  const NO_USER_ID = -1
  const userId: number = session?.myProfile?.id ?? NO_USER_ID

  const tourKey = `tour-${tourId}-${userId}`

  const [tourStep, setTourStep] = useState<number>(getInitStep(tourKey))
  const [skipBeacon, setSkipBeacon] = useState<boolean>(false) // A clickable Beacon is shown before each step. It should be skipped after the first interaction with the Tour, for a smoother user experience.
  const [ready, setReady] = useState<boolean>(false) // The Tour is only ready when both tourId and userId have been provided.

  const validArguments: boolean = isString(tourId) && (isNumber(userId) && userId !== -1)

  // Initialize the tour and set it as ready once the required parameters (tourId and userId) are available and valid.
  useEffect(() => {
    if (validArguments) {
      setTourStep(getInitStep(tourKey))
      setReady(true)
    }
  }, [tourId, userId, validArguments])

  const tourProps: TourProps = {
    stepIndex: tourStep,
    continuous: skipBeacon,
    ready: ready
  }

  const setStep: TourSetFn = (stepIndex) => {
    try {
      window.localStorage.setItem(tourKey, `${stepIndex}`)
    } catch (e) {
      console.error(e) // Either local storage is disabled or no more space is available there
    }
    setSkipBeacon(true)
    setTourStep(stepIndex)
  }
  const stepSetter: TourSetFn = validArguments ? setStep : (_stepIndex: number) => {}

  return [tourProps, stepSetter]
}
