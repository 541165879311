import React, { useMemo } from 'react'
import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-community'
import AgGridTable from '../../atomic/organisms/AgGridTable'
import I18n from 'i18n'
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef'
import { momentDateTimeFormatNoSecs } from '../../common/Constants'
import moment from 'moment'
import AthletesRenderer from './renderers/AthletesRenderer'
import NumericEditor from './editors/NumericEditor'
import DateAndTimeEditor from './editors/DateAndTimeEditor'
import { CellValueChangedEvent } from 'ag-grid-community/dist/lib/events'
import { UpdateTrainingProps } from './TrainingSchedulesCoachView'
import NewTraining from '../../common/types/NewTraining'
import AthletesEditor from './editors/AthletesEditor'
import { AgGridReact } from 'ag-grid-react'
import TextRenderer from './renderers/TextRenderer'
import { TrainingTypes } from '../../common/types/TrainingType'
import { SessionTypes } from '../../common/types/SessionType'
import Training from '../../common/types/Training'

interface Props<T> {
  tableData: T[]
  updateTrainingProps: UpdateTrainingProps<Training> | UpdateTrainingProps<NewTraining>
  height?: number
  handleSelectionChanged?: (selectedRows: any[]) => void
  gridRef?: React.RefObject<AgGridReact>
  // The readOnly property is used to indicate the Athlete's view. It should be read only and the expected RPE should be hidden.
  readOnly?: boolean
}

const TrainingSessionsTable: React.FC<Props<Training | NewTraining>> = ({ tableData, updateTrainingProps, height = 400, handleSelectionChanged, gridRef, readOnly = false }) => {
  const refetchTrainingSchedules = (): void => {
    updateTrainingProps.refetch()
  }

  const handleCellValueChanged = (event: CellValueChangedEvent): void => {
    updateTrainingProps.updateTraining(refetchTrainingSchedules, refetchTrainingSchedules, event.data, event.rowIndex)
  }

  const commonProperties: Partial<ColDef | ColGroupDef> = {
    flex: 1,
    editable: !readOnly,
    wrapText: true,
    autoHeight: true,
    minWidth: 110,
    cellStyle: { lineHeight: '20px' }
  }

  // Use memo because we have to define it in class because we need the i18n with the proper locale.
  const columnDefs: GridOptions['columnDefs'] = useMemo(() => {
    return [
      {
        ...commonProperties,
        field: 'start_date',
        headerCheckboxSelection: !readOnly,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: !readOnly,
        headerName: I18n.t('components.trainings.date_picker.start_date'),
        filter: 'agDateColumnFilter',
        valueFormatter: formatDateTime,
        cellEditor: DateAndTimeEditor,
        cellEditorPopup: true,
        minWidth: 177,
        sort: 'asc'
      },
      {
        ...commonProperties,
        field: 'location',
        headerName: I18n.t('components.trainings.text.location'),
        filter: 'agTextColumnFilter'
      },
      {
        ...commonProperties,
        field: 'trainer',
        headerName: I18n.t('components.trainings.text.trainer'),
        filter: 'agTextColumnFilter'
      },
      {
        ...commonProperties,
        field: 'week_type',
        headerName: I18n.t('components.trainings.text.week_type'),
        filter: 'agTextColumnFilter',
        minWidth: 135
      },
      {
        ...commonProperties,
        field: 'training_type',
        headerName: I18n.t('components.trainings.dropdown.training_type'),
        filter: 'agTextColumnFilter',
        valueFormatter: formatTrainingType,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: TrainingTypes
        },
        minWidth: 150
      },
      {
        ...commonProperties,
        field: 'session_type',
        headerName: I18n.t('components.trainings.dropdown.session_type'),
        filter: 'agTextColumnFilter',
        valueFormatter: formatSessionType,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: SessionTypes
        },
        minWidth: 136
      },
      {
        ...commonProperties,
        field: 'expected_duration',
        headerName: I18n.t('components.trainings.number.expected_duration'),
        filter: 'agNumberColumnFilter',
        cellEditor: NumericEditor,
        cellEditorParams: { min: 1 },
        minWidth: 130
      },
      {
        ...commonProperties,
        field: 'expected_rpe',
        headerName: I18n.t('components.trainings.number.expected_rpe'),
        filter: 'agNumberColumnFilter',
        cellEditor: NumericEditor,
        cellEditorParams: { min: 1, max: 10, showRpeExplanation: true },
        cellEditorPopup: true,
        minWidth: 145,
        hide: readOnly
      },
      {
        ...commonProperties,
        field: 'athletes',
        headerName: I18n.t('components.trainings.athletes'),
        cellRenderer: AthletesRenderer,
        cellEditor: AthletesEditor,
        cellEditorPopup: true,
        minWidth: 137
      },
      {
        ...commonProperties,
        field: 'warmup',
        headerName: `${I18n.t('components.trainings.text.warmup') as string} ⓘ`,
        headerTooltip: I18n.t('components.trainings.text.largetext_tooltip'),
        filter: 'agTextColumnFilter',
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        wrapText: readOnly,
        minWidth: 159
      },
      {
        ...commonProperties,
        field: 'instructions',
        headerName: `${I18n.t('components.trainings.text.instructions') as string} ⓘ`,
        headerTooltip: I18n.t('components.trainings.text.largetext_tooltip'),
        filter: 'agTextColumnFilter',
        cellRenderer: TextRenderer,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        wrapText: readOnly,
        minWidth: 159
      },
      {
        ...commonProperties,
        field: 'instructions_is_link',
        headerName: I18n.t('components.trainings.checkbox.instructions_is_link'),
        filter: 'agTextColumnFilter',
        cellEditor: 'agSelectCellEditor',
        valueGetter: params => params.data.instructions_is_link === true ? I18n.t('components.trainings.text.truthy') : I18n.t('components.trainings.text.falsey'),
        valueSetter: params => { params.data.instructions_is_link = (params.newValue === I18n.t('components.trainings.text.truthy')); params.api.refreshCells({ force: true, suppressFlash: true }); return true }, /* This performs a refreshcells because the appearance of another field depends on this field's value. We want to redraw the description field when changing the value of this field (it either has to show up as a hyperlink, or not). */
        cellEditorParams: {
          values: [I18n.t('components.trainings.text.truthy'), I18n.t('components.trainings.text.falsey')]
        },
        minWidth: 141
      },
      {
        ...commonProperties,
        field: 'send_training_log',
        headerName: `${I18n.t('components.trainings.checkbox.send_training_log') as string} ⓘ`,
        headerTooltip: I18n.t('components.trainings.checkbox.send_training_log_tooltip'),
        filter: 'agTextColumnFilter',
        cellEditor: 'agSelectCellEditor',
        valueGetter: params => params.data.send_training_log === true ? I18n.t('components.trainings.text.truthy') : I18n.t('components.trainings.text.falsey'),
        valueSetter: params => { params.data.send_training_log = (params.newValue === I18n.t('components.trainings.text.truthy')); return true },
        cellEditorParams: {
          values: [I18n.t('components.trainings.text.truthy'), I18n.t('components.trainings.text.falsey')]
        },
        minWidth: 141
      }
    ]
  }, [I18n.locale])

  return (
    <div className={readOnly ? 'read-only' : ''}>
      <AgGridTable
        columnDefs={columnDefs}
        rowData={tableData}
        height={height}
        gridRef={gridRef}
        gridOptions={{
          rowHeight: 40,
          headerHeight: 60,
          stopEditingWhenCellsLoseFocus: true,
          enableCellChangeFlash: true,
          rowSelection: 'multiple',
          suppressRowClickSelection: true
        }}
        handleCellValueChanged={handleCellValueChanged}
        handleSelectionChanged={handleSelectionChanged}
        dontSizeToFit={false}
      />
    </div>
  )
}

export const ROW_HEIGHT = 40

const formatDateTime = (params: ValueFormatterParams): string => {
  if (params.value === undefined || params.value === null) return ''

  return moment(params.value).format(momentDateTimeFormatNoSecs)
}

export const translateTrainingType = (trainingType: string): string => {
  return I18n.t(`components.dashboards.questionnaire.training_type.${trainingType}`)
}

export const translateSessionType = (sessionType: string): string => {
  return I18n.t(`components.dashboards.questionnaire.session_type.${sessionType}`)
}

const formatTrainingType = (params: ValueFormatterParams): string => {
  if (params.value === undefined || params.value === null || params.value === '') return ''

  return translateTrainingType(params.value)
}

const formatSessionType = (params: ValueFormatterParams): string => {
  if (params.value === undefined || params.value === null || params.value === '') return ''

  return translateSessionType(params.value)
}

export default TrainingSessionsTable
