import React from 'react'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class BackButton extends React.Component {
  handleClick (e) {
    e.preventDefault()
    this.props.history.goBack()
  }

  render () {
    const backfun = this.props.onGoBack ? this.props.onGoBack : this.handleClick.bind(this)
    return (
      <div className='back-button' onClick={(e) => { e.preventDefault(); backfun(e) }}>
        <FontAwesomeIcon icon='chevron-left' className='button-icon' />
        {this.props.children || I18n.t('back_button')}
      </div>
    )
  }
}
