import React from 'react'
import I18n from 'i18n'
import FormFactory from '../../helpers/FormFactory'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'

class ResetPassword extends FormFactory {
  constructor (props) {
    super(props)
    this.state = { success: false }
    this.form_fields = {
      email: {
        component: 'field',
        properties: {
          type: 'text'
        }
      }
    }
  }

  componentDidMount () {
    this.props.setHeight(187)
    this.props.logout(false)
  }

  handleSuccess (response) {
    this.props.flashMessages.push(
      I18n.t(
        'flashmessages.password_reset.successful',
        { email: this.props.getFields(this.props.formName).email }
      ),
      this.props.flashMessages.duration.SHORT,
      this.props.flashMessages.levels.INFO
    )
    this.setState({ success: true, email: this.props.getFields(this.props.formName).email })
  }

  render () {
    return (
      <>
        <div className='row'>
          <div className='col s12'>
            {this.state.success && (<p>{I18n.t('password.reset.email.sent', { email: this.state.email })}</p>)}
          </div>
        </div>
        <form>
          {this.renderFields()}
          {this.renderSubmit(`${this.props.formName}.submit`, { id: 'reset-password-button', className: 'button-primary background-primary text-background-color waves-effect waves-light' })}
        </form>
      </>
    )
  }
}

export default WithFlashMessages(ResetPassword)
