import React from 'react'
import I18n from 'i18n'
import _ from 'lodash'

import { getFirstDataRow, prepareSummaryFields } from '../../../../common/Utils'
import { prepareTrainingLogData } from '../CoachDashboardHelper'
import { activitySummaryToMetric, s2hms, summaryWithUnitsToString } from '../../../../common/Units'
import { COACH_DASHBOARD_COL1_SUMMARY_FIELDS, COACH_DASHBOARD_COL2_SUMMARY_FIELDS, COACH_DASHBOARD_MAIN_SUMMARY_FIELDS, EMPTY_RECORD } from '../../../../common/Constants'
import SummaryTableMultiple from '../SummaryTableMultiple'
import SummaryTable from '../../common/SummaryTable'
import { round } from '../../../../common/Math'
import { readTrainingQuestionnaire } from '../../../../common/utils/QuestionnaireUtils'
import { getTrainingLoad } from '../../../../common/Calculations'

const TrainingSessionSummary = (props) => {
  const { activity, trainingLog, scheduledTraining, trimp } = props

  const prepareSummaryTableMultiple = (actualSummary = {}, expectedSummary = {}, summaryFields) => {
    const rows = _.reduce(summaryFields, (accum, field) => {
      const summaryRow = {
        label: I18n.t(`components.dashboards.table.${field}`),
        value: actualSummary[field] || EMPTY_RECORD,
        scheduled: expectedSummary[field] || EMPTY_RECORD
      }

      accum.push(summaryRow)
      return accum
    }, [])

    const headers = {
      label: '',
      value: I18n.t('components.dashboards.coach_individual.training.table.actual'),
      scheduled: I18n.t('components.dashboards.coach_individual.training.table.scheduled')
    }

    const fields = ['label', 'value']
    if (!_.isEmpty(expectedSummary)) {
      fields.push('scheduled')
    }

    return {
      fields: fields,
      rows: rows,
      headers: headers
    }
  }

  const summaryWithStrings = {}
  if (trainingLog) {
    const trainingLogValues = getFirstDataRow(trainingLog)?.values
    const trainingLogData = readTrainingQuestionnaire(trainingLogValues)
    const trainingLogWithStrings = prepareTrainingLogData(trainingLogData)
    _.merge(summaryWithStrings, trainingLogWithStrings)
  }
  if (activity) {
    const summaryWithUnits = activitySummaryToMetric(activity?.vdo?.summary)
    const activitySummaryWithStrings = summaryWithUnitsToString(summaryWithUnits)
    _.merge(summaryWithStrings, activitySummaryWithStrings)
  }
  if (trimp || trimp === 0) {
    summaryWithStrings.trimp = `${trimp}`
  }

  const expectedSummary = {}
  if (scheduledTraining) {
    const {
      expected_rpe: rpe = EMPTY_RECORD,
      expected_duration: duration = EMPTY_RECORD,
      training_type: trainingType = EMPTY_RECORD,
      session_type: sessionType = EMPTY_RECORD
    } = scheduledTraining
    const srpe = round(getTrainingLoad(rpe, duration), 2) || EMPTY_RECORD

    expectedSummary.rpe = `${rpe}`
    expectedSummary.duration = `${s2hms(duration * 60)} ${I18n.t('units.h')}`
    expectedSummary.srpe = `${srpe}`
    expectedSummary.training_type = `${I18n.t(`components.dashboards.questionnaire.training_type.${trainingType}`)}`
    expectedSummary.session_type = `${I18n.t(`components.dashboards.questionnaire.session_type.${sessionType}`)}`
  }

  const summaryTableMultipleElems = prepareSummaryTableMultiple(summaryWithStrings, expectedSummary, COACH_DASHBOARD_MAIN_SUMMARY_FIELDS)
  const summaryTableFields1 = prepareSummaryFields(summaryWithStrings, COACH_DASHBOARD_COL1_SUMMARY_FIELDS)
  const summaryTableFields2 = prepareSummaryFields(summaryWithStrings, COACH_DASHBOARD_COL2_SUMMARY_FIELDS)

  const trainingLogAnswers = readTrainingQuestionnaire(getFirstDataRow(trainingLog)?.values)
  const commentsTableField = {
    fields: ['label', 'comments'],
    rows: [{
      label: I18n.t('components.dashboards.coach_individual.training.table.comments'),
      comments: trainingLogAnswers.comments
    }]
  }
  return (
    <div className='row'>
      <div className='col s12'>
        <SummaryTableMultiple {...summaryTableMultipleElems} />
      </div>
      <div className='col s6'>
        <SummaryTable fields={summaryTableFields1} />
      </div>
      <div className='col s6'>
        <SummaryTable fields={summaryTableFields2} />
      </div>
      <div className='col s12'>
        <SummaryTableMultiple {...commentsTableField} />
      </div>
    </div>
  )
}

export default TrainingSessionSummary
