import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Summary from '../common/Summary'

const VideoDetails = ({ icon, metadatum, data, compact }) => {
  const id = _.get(data, 'id')
  const [playing, setPlaying] = useState(false)

  const handlePlayPauseToggle = () => {
    setPlaying(!playing)
  }

  const playingIcon = () => {
    return playing ? 'pause' : 'play'
  }

  if (!id) return <></>
  return (
    <>
      <Summary icon={icon} metadatum={metadatum} />
      {!compact && (
        <div className='row'>
          <div className='col s12'>
            <ReactPlayer url={`/api/v1/data/${id}/download`} playing={playing} width='100%' height='100%' />
            <button className='waves-effect text-m waves-light button-primary text-primary-color background-background text-medium' onClick={handlePlayPauseToggle}>
              <FontAwesomeIcon className='play-pause pointer' icon={playingIcon()} />
            </button>
          </div>
        </div>
      )}
    </>
  )
}

VideoDetails.propTypes = {
  icon: PropTypes.string,
  compact: PropTypes.bool,
  metadata: PropTypes.object,
  data: PropTypes.shape({
    id: PropTypes.integer
  })
}

export default VideoDetails
