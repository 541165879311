import React, { useEffect, useRef, useCallback, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-community'

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { CellValueChangedEvent } from 'ag-grid-community/dist/lib/events'
import { cloneDeep } from 'lodash'

interface Props {
  columnDefs: GridOptions['columnDefs']
  rowData: any[] | null
  height?: number
  gridOptions?: Partial<GridOptions>
  dontSizeToFit?: boolean
  handleCellValueChanged?: (event: CellValueChangedEvent) => void
  handleSelectionChanged?: (selectedRows: any[]) => void
  gridRef?: React.RefObject<AgGridReact>
}

const AgGridTable: React.FunctionComponent<Props> = ({ columnDefs, rowData, height = 500, gridOptions = {}, dontSizeToFit = false, handleCellValueChanged, handleSelectionChanged, gridRef }) => {
  const internalRef = useRef<AgGridReact>(null)
  const actualRef = gridRef ?? internalRef

  const injectClassRules = useCallback((agColumnDefs: GridOptions['columnDefs']) => {
    if (agColumnDefs == null) return

    agColumnDefs.forEach(columnDef => {
      // Typescript compiler is not smart enough to detect that columnDef being of ColDef | ColGroupDef type COULD have
      // a children property, so we force interpreting the type to ColGroupDef which is guaranteed to have the property
      const children = (columnDef as ColGroupDef).children
      if (children !== undefined) {
        return injectClassRules(children)
      }

      // Because of the if above, we know this is a ColDef. Typescript is too dumb to figure it out.
      (columnDef as ColDef).cellClassRules = {
        'ag-cell-not-inline-editing': params => params.colDef.editable === true
      }
    })
  }, [])

  // With our current css theme, all grid cells have a hover selector that displays an edit button independently of
  // whether the cell is editable or not. With injectClassRules we add a class rule to all cell definitions
  // (recursively) to activate this class only if according to the cell definition itself, the cell is editable
  const myColumnDefs = useMemo(() => {
    const editedDefs = cloneDeep(columnDefs)

    injectClassRules(editedDefs)

    return editedDefs
  }, [columnDefs])

  useEffect(() => {
    actualRef.current?.api?.refreshCells()
  }, [rowData, actualRef])

  const sizeToFit = (): void => {
    if (dontSizeToFit) {
      actualRef.current?.columnApi?.autoSizeAllColumns(true)
    } else {
      actualRef.current?.api?.sizeColumnsToFit()
    }
  }

  const onSelectionChanged = useCallback(() => {
    const selectedRows = actualRef.current?.api?.getSelectedRows()
    if (selectedRows !== undefined && handleSelectionChanged != null) {
      handleSelectionChanged(selectedRows)
    }
  }, [handleSelectionChanged, actualRef])

  return (
    <div className='ag-theme-alpine larger-table-header' style={{ height: height }}>
      <AgGridReact
        ref={actualRef}
        rowData={rowData}
        columnDefs={myColumnDefs}
        defaultColDef={defaultColDef}
        defaultColGroupDef={defaultColGroupDef}
        onGridSizeChanged={sizeToFit}
        tooltipShowDelay={500}
        onCellValueChanged={handleCellValueChanged}
        onSelectionChanged={onSelectionChanged}
        {...gridOptions}
      />
    </div>
  )
}

const defaultColDef: GridOptions['defaultColDef'] = {
  sortable: true,
  resizable: true
}

const defaultColGroupDef: GridOptions['defaultColGroupDef'] = {
  marryChildren: true
}

export default AgGridTable
