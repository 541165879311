import React from 'react'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TimelineRefreshReminder = (props) => {
  return (
    <div className='timeline-card' id='timeline-refresh-prompt' onClick={props.onRefresh}>
      <div className='text-l text-heavy text-primary-color timeline-title center-align pointer'>
        <div className='timeline-icon center-align'>
          <FontAwesomeIcon icon='sync' />
        </div>
        <p>{I18n.t('components.timeline.refresh.reminder')}</p>
        <p className='text-muted text-m text-medium'>
          {props.count > 0 ? `${I18n.t('components.timeline.refresh.count', { count: props.count })}` : ''}
        </p>
      </div>
    </div>
  )
}

export default TimelineRefreshReminder
