import React, { FC } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { hcRed, hcGreen, hcBlack } from 'components/common/Constants'
import I18n from 'i18n'
import { initializeHighcharts, titleWithTooltip } from 'components/common/Utils'
import GenericPlaceholder from 'components/common/GenericPlaceholder'
import _ from 'lodash'
import { DailyLogRow } from 'components/common/types/questionnaires/DailyLogRow'
import { getYAxisOptions } from 'components/analysis/dashboards/questionnaire/weekly/WellbeingChartOptions'
import {
  mapToRestingHRData,
  mapToSicknessData, mapToSleepDurationData,
  mapToWellbeingScoreData,
  mapToWellnessMovingAvgData
} from 'components/analysis/dashboards/questionnaire/UtilsTS'

interface WellnessProps {
  dailyLogRows?: DailyLogRow[]
  withRestingHr?: boolean
  withSleepDuration?: boolean
}

const DailyWellness: FC<WellnessProps> = ({ dailyLogRows, withRestingHr, withSleepDuration }) => {
  initializeHighcharts(true)

  if (_.isEmpty(dailyLogRows)) {
    return (
      <GenericPlaceholder title={I18n.t('components.dashboards.questionnaire.daily_wellness.title')} />
    )
  }
  // Remove duplicates
  const dataNoDuplicates: DailyLogRow[] = _.uniqBy(dailyLogRows, 'formatted_date')

  const wellbeingScoreData = mapToWellbeingScoreData(dataNoDuplicates)
  const wellnessMovingAvgData = mapToWellnessMovingAvgData(dataNoDuplicates)
  const sicknessData = mapToSicknessData(dataNoDuplicates)
  const restingHRData = mapToRestingHRData(dataNoDuplicates)
  const sleepDurationData = mapToSleepDurationData(dataNoDuplicates)

  const {
    restingHrOptionsIndex,
    sleepDurationOptionsIndex,
    yAxisOptions
  } = getYAxisOptions(withRestingHr, withSleepDuration)

  const xAxisOptions: Highcharts.XAxisOptions = {
    title: {
      text: ''
    },
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%d %b'
    }
  }

  const sicknessSeries = {
    type: 'scatter',
    data: sicknessData,
    tooltip: {
      headerFormat: '',
      pointFormat: I18n.t('components.dashboards.questionnaire.daily_wellness.scatter.tooltip')
    }
  }

  const wellbeingSeries =
  {
    type: 'column',
    data: wellbeingScoreData,
    name: '⬤',
    pointWidth: 20,
    tooltip: {
      headerFormat: '',
      pointFormat: I18n.t('components.dashboards.questionnaire.daily_wellness.column.tooltip')
    }
  }

  const wellnessMovingAvgSeries = {
    type: 'line',
    data: wellnessMovingAvgData,
    tooltip: {
      headerFormat: '',
      pointFormat: I18n.t('components.dashboards.questionnaire.weekly_wellness.line.tooltip')
    }
  }

  const restingHRSeries = {
    type: 'line',
    data: restingHRData,
    name: 'RestingHr',
    tooltip: {
      headerFormat: '',
      pointFormat: I18n.t('components.dashboards.questionnaire.daily_wellness.resting_hr.tooltip')
    },
    color: hcRed,
    yAxis: restingHrOptionsIndex
  }

  const sleepDurationSeries = {
    type: 'line',
    data: sleepDurationData,
    name: 'SleepDuration',
    tooltip: {
      headerFormat: '',
      pointFormat: I18n.t('components.dashboards.questionnaire.daily_wellness.sleep_duration.tooltip')
    },
    color: hcGreen,
    yAxis: sleepDurationOptionsIndex,
    marker: {
      enabled: false,
      states: {
        hover: {
          enabled: true
        }
      }
    }
  }
  const datasets = [sicknessSeries, wellbeingSeries, wellnessMovingAvgSeries, restingHRSeries, sleepDurationSeries]

  const chartOptions = {
    title: titleWithTooltip(
      I18n.t('components.dashboards.questionnaire.daily_wellness.title'),
      I18n.t('components.dashboards.questionnaire.daily_wellness.info')
    ),
    subtitle: {
      text: I18n.t('components.dashboards.questionnaire.weekly_wellness.subtitle')
    },
    series: datasets,
    plotOptions: {
      series: {
        stacking: 'normal',
        maxPointWidth: 20
      },
      scatter: {
        dataLabels: {
          enabled: false
        },
        color: 'black',
        stacking: 'normal',
        enableMouseTracking: true
      },
      line: {
        color: hcBlack,
        marker: {
          enabled: false
        }
      }
    },
    yAxis: yAxisOptions,
    xAxis: xAxisOptions
  }

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
    </>
  )
}

export default DailyWellness
