import React from 'react'

// If there's not at least one typescript component defined, then it complains.

const MyTypeScriptComponent: React.FunctionComponent = () => {
  return (
    <p>Hello typescript works</p>
  )
}

export default MyTypeScriptComponent
