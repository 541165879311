import React, { useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import {
  chartjsTitleProperties,
  chartjsScaleLabelProperties,
  chartjsDefaultOptions,
  chartjsDefaultArguments
} from '../../../common/Constants'
import { identity } from '../../../common/Units'
import PropTypes from 'prop-types'

const HEIGHT = 238

const StackedBarChart = (props) => {
  const data = {
    labels: props.labels,
    datasets: Array.from(props.datasets)
  }

  useEffect(() => {
    if (props.scaled) {
      const totals = Array(data.datasets[0].data.length).fill(0)
      for (const serie of data.datasets) {
        for (const [idx, value] of serie.data.entries()) {
          totals[idx] += value
        }
      }
      for (const serie of data.datasets) {
        for (const [idx, value] of serie.data.entries()) {
          if (!totals[idx]) continue
          serie.data[idx] = 100.0 * value / totals[idx]
        }
      }
    }
  }, [props.datasets, props.labels, props.scaled])

  const options = {
    ...chartjsDefaultOptions,
    maintainAspectRatio: false,
    responsive: true,
    title: {
      text: props.title,
      ...chartjsTitleProperties
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: props.tooltipCallbacks
    },
    plugins: {
      crosshair: false
    },
    scales: {
      xAxes: [{
        stacked: props.stacked,
        ticks: {
          fontSize: props.xAxisFontSize,
          min: props.range?.[0],
          max: props.range?.[1]
        }
      }],
      yAxes: [{
        stacked: props.stacked,
        scaleLabel: {
          labelString: props.yLabel,
          ...chartjsScaleLabelProperties
        },
        ticks: {
          callback: props.yTickCallback,
          min: props.yMin,
          max: props.yMax,
          precision: props.precision
        }
      }]
    },
    ...props.options
  }
  return (
    <div className='chartjs-container'>
      <Bar {...chartjsDefaultArguments} height={HEIGHT} data={data} options={options} redraw />
    </div>
  )
}

StackedBarChart.propTypes = {
  title: PropTypes.string,
  labels: PropTypes.array,
  datasets: PropTypes.array,
  yLabel: PropTypes.string,
  yTickCallback: PropTypes.func,
  tooltipCallbacks: PropTypes.object,
  yMin: PropTypes.number,
  yMax: PropTypes.number,
  range: PropTypes.array,
  // Override/add additional options
  options: PropTypes.object,
  stacked: PropTypes.bool,
  xAxisFontSize: PropTypes.number,
  precision: PropTypes.number
}

StackedBarChart.defaultProps = {
  yLabel: '',
  yTickCallback: identity,
  // Warning: If this is undefined for one chart tooltips will break for all charts in the page.
  tooltipCallbacks: {},
  stacked: true,
  xAxisFontSize: 17.5
}

export default StackedBarChart
