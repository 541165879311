import React, { useState } from 'react'
import I18n from 'i18n'
import Routes from '../../common/Routes'
import Toggle from 'components/atomic/atoms/Toggle'

const EmailAddressExport = (_props) => {
  const [includeNonMarketing, setIncludeNonMarketing] = useState(false)

  const submitTranslation = includeNonMarketing
    ? I18n.t(
        'components.admin_dashboard.export_users.submit_without_newsletter'
      )
    : I18n.t('components.admin_dashboard.export_users.submit_with_newsletter')

  return (
    <>
      <form
        target='_blank'
        action={Routes.admin.exportEmailAddresses}
        method='GET'
      >
        <div className='row spaced-down'>
          <div className='col s12 text-heavy muted-header'>
            <h5>{I18n.t('components.admin_dashboard.export_users.title')}</h5>
          </div>
        </div>
        <Toggle
          id='include_non_marketing_emails'
          isChecked={includeNonMarketing}
          onChange={() => setIncludeNonMarketing((checked) => !checked)}
          title={I18n.t(
            'components.admin_dashboard.export_users.include_non_marketing_emails'
          )}
        />
        <div className='row'>
          <div className='col s12 input-field'>
            <input
              type='submit'
              className='button-primary background-primary text-background-color waves-effect waves-light button-autowidth'
              value={submitTranslation}
            />
          </div>
        </div>
      </form>
    </>
  )
}

export default EmailAddressExport
