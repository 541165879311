import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import PremiumOverlay from '../../premium_membership/PremiumOverlay'
import classNames from 'classnames'
import { subscriptionTypes, groupTypes } from '../../common/Constants'
import CroppableFileUpload from '../../common/form/CroppableFileUpload'
import I18n from 'i18n'
import FeatureFlag, { INFORMED_CONSENT } from '../../feature_flags/FeatureFlag'
import CreateInformedConsentForm from './CreateInformedConsentForm'
import Info from 'components/atomic/atoms/Info'

const GroupEditOrCreateForm = ({ group, handleSubmit, edit, icOptionalRef, icRequiredRef, icOptionalEnabledRef }) => {
  const checkBoxChecked = group && group.group_type === groupTypes.ANONYMIZED
  const [picture, setPicture] = useState(null)
  const [editingPicture, setEditingPicture] = useState(false)

  const [icEnabled, setIcEnabled] = useState(false)
  const [icBody, setIcBody] = useState('')
  const [icRequired, setIcRequired] = useState(I18n.t('network.groups.required_informed_consent_default'))
  const [icOptional, setIcOptional] = useState(I18n.t('network.groups.optional_informed_consent_default'))
  const [icOptionalEnabled, setIcOptionalEnabled] = useState(true)

  const blankIc = icEnabled && icBody.trim().length === 0
  const blankRequiredIc = icEnabled && icRequired.trim().length === 0
  const blankOptionalIc = icEnabled && icOptionalEnabled && icOptional.trim().length === 0

  useEffect(() => {
    M.updateTextFields()
  })

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <CroppableFileUpload
            defaultValue={group && group.picture}
            onAccept={(result) => setPicture(result)}
            onStartEditing={() => setEditingPicture(true)}
            onStopEditing={() => setEditingPicture(false)}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='input-field'>
            <input type='text' id='group-name' name='group-name' defaultValue={group && group.name} />
            <label htmlFor='group-name' className={group && group.name && 'active'}>
              <span>{I18n.t('network.groups.labels.name')}</span>
            </label>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='input-field'>
            <input type='text' id='group-description' name='group-description' defaultValue={group && group.description} />
            <label htmlFor='group-description' className={group && group.description && 'active'}>
              <span>{I18n.t('network.groups.labels.description')}</span>
            </label>
          </div>
        </div>
      </div>
      <PremiumOverlay showBadge={false} showButton={false} activeFor={[subscriptionTypes.coach, subscriptionTypes.researcher]}>
        <div className='row'>
          <div className='col s12 valign-wrapper toggle'>
            <label htmlFor='group-anonymized' className='switch'>
              <input
                type='checkbox' id='group-anonymized' name='group-anonymized'
                defaultChecked={checkBoxChecked}
              />
              <div className='slider' />
              <span>{I18n.t('network.groups.labels.anonymized')}</span>
              <Info
                text={I18n.t('group_create.anonymized_groups.tooltip')}
                tooltipId='higher-z-tooltip'
                reuseTooltip
                inline
                multiline
              />
            </label>
          </div>
        </div>
      </PremiumOverlay>
      {/* For now we disable informed consents when editing since we don't have logic in place to validate members re-accepting the informed consent */}
      {!edit &&
        <FeatureFlag name={INFORMED_CONSENT}>
          <CreateInformedConsentForm
            checked={icEnabled}
            toggle={() => setIcEnabled(!icEnabled)}
            body={icBody}
            onBodyChange={setIcBody}
            icRequired={icRequired}
            setIcRequired={setIcRequired}
            icRequiredRef={icRequiredRef}
            icOptional={icOptional}
            setIcOptional={setIcOptional}
            icOptionalRef={icOptionalRef}
            icOptionalEnabled={icOptionalEnabled}
            setIcOptionalEnabled={setIcOptionalEnabled}
            icOptionalEnabledRef={icOptionalEnabledRef}
          />
        </FeatureFlag>}
      <div className='row'>
        <div className='col s12 center-align'>
          <button
            onClick={() => handleSubmit(picture)}
            className={classNames('waves-effect waves-light button-primary text-background-color background-primary text-medium submit button-autowidth',
              editingPicture && 'button-disabled')}
            style={{ minWidth: '200px', marginRight: '0' }}
            disabled={editingPicture || blankIc || blankRequiredIc || blankOptionalIc}
          >
            {I18n.t('network.groups.labels.save')}
          </button>
        </div>
      </div>
    </>
  )
}

GroupEditOrCreateForm.propTypes = {
  group: PropTypes.object,
  handleSubmit: PropTypes.func
}

export default GroupEditOrCreateForm
