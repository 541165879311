import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import I18n from 'i18n'
import classNames from 'classnames'
import { interpolateColors } from './Utils'
import Variables from '../../stylesheets/variables.module.scss'
const { accentColorDark, accentColorLight } = Variables

/**
 * A minimized version of a slider. Parameters:
 *    value -> integer between min/max
 *    onChagne -> handler to call when the value changes
 *    minVal -> minimum value for the slider
 *    maxVal -> maximum value for the slider
 *    toolTipTranslation -> a I18n translation key that should map to a translation string that interpolates 'value' into the string
 *    id -> a String representing a unique ID for the component
 */
export const SimpleSlider = ({ value, onChange, minVal, maxVal, toolTipTranslation, id, noDataTip }) => {
  const dataTip = !noDataTip
  return (
    <div data-tip={dataTip && I18n.t(toolTipTranslation, { value: value })} data-for={dataTip && id} className='value'>
      <input onChange={onChange} type='range' min={minVal} max={maxVal} value={value} className='slider' />
    </div>
  )
}

const colors = interpolateColors(accentColorDark, accentColorLight, 6)
/**
 * Wrapper around the noUiSlider.
 * To read the slider selected values, pass a handler in the `onChange` prop, and it will retrieve an array with the values (there may be multiple selected values)
 * @param range Array that defines the min and max values of the slider
 * @param start Array that defines the start selected values of the slider. The number of elements is the number of selectors of the slider
 * @param customFormatter Overrides isTime. Function that transforms a number to the value that should be displayed
 *
 * For more info, check https://refreshless.com/nouislider/
 */
export default class Slider extends React.Component {
  static reset (sliderId) {
    const slider = document.getElementById(sliderId)
    slider.noUiSlider.reset()
  }

  getNumberFormatter (isInteger) {
    return isInteger ? Math.floor : function (value) { return value }
  }

  getTimeFormatter () {
    return function (seconds) {
      const duration = moment.duration(seconds, 'seconds')
      const formattedMin = Math.floor(duration.asMinutes())
      const formattedSec = ('0' + duration.seconds().toString()).slice(-2)
      return (`${formattedMin}:${formattedSec}`)
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (
      this.props &&
      (JSON.stringify(prevProps.range) !== JSON.stringify(this.props.range) ||
      JSON.stringify(prevProps.start) !== JSON.stringify(this.props.start) ||
      this.props.disabled !== prevProps.disabled)
    ) {
      const rangeSlider = document.getElementById(this.props.id)
      rangeSlider.noUiSlider.updateOptions({
        range: {
          min: this.props.range[0],
          max: this.props.range[1]
        },
        // Note that Start can never be an empty array. This causes the Error: noUiSlider: 'start' option is incorrect.
        start: this.props.start
      }, true)

      if (this.props.disabled) {
        rangeSlider.setAttribute('disabled', true)
      } else {
        // simply setting disabled to false is not good enough for noUISlider
        rangeSlider.removeAttribute('disabled')
      }
    }
  }

  componentDidMount () {
    const rangeSlider = document.getElementById(this.props.id)
    let formatter = this.props.customFormatter
    if (!formatter) {
      formatter = this.props.isTime ? this.getTimeFormatter() : this.getNumberFormatter(Number.isInteger(this.props.step))
    }
    window.noUiSlider.create(rangeSlider, {
      // Note that Start can never be an empty array. This causes the Error: noUiSlider: 'start' option is incorrect.
      start: this.props.start ? this.props.start : this.props.range,
      connect: this.props.connect,
      behaviour: 'drag-snap-tap',
      step: this.props.step,
      orientation: this.props.orientation,
      range: {
        min: this.props.range[0],
        max: this.props.range[1]
      },
      format: {
        to: formatter,
        from: function (value) {
          return (value)
        }
      },
      tooltips: !this.props.disableTooltips
    })
    // rangeSlider.noUiSlider.on('set', this.props.onChange)
    rangeSlider.noUiSlider.on('slide', this.props.onChange)

    if (this.props.disabled) {
      rangeSlider.setAttribute('disabled', true)
    }

    if (this.props.id === 'hr_range') {
      const noUIHandle = rangeSlider.querySelectorAll('.noUi-handle')
      const noUITooltip = rangeSlider.querySelectorAll('.noUi-tooltip')

      for (let i = 0; i < noUIHandle.length; i++) {
        noUIHandle[i].setAttribute('style', `background-color:${colors[i + 1]} !important;`)
        noUITooltip[i].setAttribute('style', `background-color:${colors[i + 1]} !important;`)
        noUITooltip[i].classList.add('noUi-tooltip-hr-custom')
      }
    }
  }

  render () {
    return (
      <div className={classNames({ 'grayed-out': this.props.disabled })}>
        <span className={classNames('range-title', { 'text-inactive-color': this.props.disabled, larger: this.props.largerLabel })}>
          {this.props.title}
        </span>
        <div id={this.props.id} />
      </div>
    )
  }
}

Slider.propTypes = {
  customFormatter: PropTypes.func,
  id: PropTypes.string.isRequired,
  isTime: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  range: PropTypes.arrayOf(PropTypes.number).isRequired,
  start: PropTypes.arrayOf(PropTypes.number),
  step: PropTypes.number,
  title: PropTypes.string,
  connect: PropTypes.bool,
  disabled: PropTypes.bool,
  disableTooltips: PropTypes.bool,
  largerLabel: PropTypes.bool
}

Slider.defaultProps = {
  isTime: false,
  connect: true,
  disabled: false,
  orientation: 'horizontal',
  step: 1,
  range: [0, 1],
  disableTooltips: false,
  largerLabel: false
}
