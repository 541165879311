import React from 'react'
import I18n from 'i18n'
import { Toggle } from 'components/common/form'

const CreateInformedConsentForm = ({
  checked,
  toggle,
  body,
  onBodyChange,
  icRequired,
  setIcRequired,
  icOptional,
  setIcOptional,
  icOptionalRef,
  icRequiredRef,
  icOptionalEnabled,
  setIcOptionalEnabled,
  icOptionalEnabledRef
}) => {
  return (
    <>
      <div className='row'>
        <div className='col s12 valign-wrapper toggle'>
          <label htmlFor='group-informed-consent' className='switch'>
            <input
              type='checkbox' id='group-informed-consent' name='group-informed-consent' checked={checked}
              onChange={toggle}
            />
            <div className='slider' />
            <span>{I18n.t('network.groups.labels.require_informed_consent')}</span>
          </label>
        </div>
      </div>
      {checked && (
        <>
          <div className='row'>
            <div className='col s12'>
              <textarea
                id='informed-consent-text'
                className='fixed-textarea-height no-resize-handle'
                value={body}
                onChange={e => onBodyChange(e.target.value)}
                placeholder={I18n.t('network.groups.informed_consent_placeholder')}
              />
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12'>
              <label
                htmlFor='required-informed-consent'
                className='longer-label'
              >{I18n.t('network.groups.required_informed_consent')}
              </label>
              <input
                id='required-informed-consent'
                type='text'
                value={icRequired}
                onChange={e => setIcRequired(e.target.value)}
                ref={icRequiredRef}
              />
            </div>
          </div>
          <Toggle
            currentValue={icOptionalEnabled}
            onChange={() => setIcOptionalEnabled(!icOptionalEnabled)}
            label={I18n.t('network.groups.enable_optional_informed_consent')}
            ref={icOptionalEnabledRef}
          />

          {icOptionalEnabled && (
            <div className='row'>
              <div className='input-field col s12'>
                <label
                  htmlFor='optional-informed-consent'
                  className='longer-label'
                >{I18n.t('network.groups.optional_informed_consent')}
                </label>
                <input
                  id='optional-informed-consent'
                  type='text'
                  value={icOptional}
                  onChange={e => setIcOptional(e.target.value)}
                  ref={icOptionalRef}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default CreateInformedConsentForm
