import React, { useState, useMemo, useEffect } from 'react'
import I18n from 'i18n'
import _ from 'lodash'
import { MyAxios as axios } from '../../MyAxios'

import StatsCard from './detail/StatsCard'
import Heatmap from '../../common/Heatmap'
import { metricAvgSpeed, timeSeries as timeSeriesNames, soccerMetrics, soccerDecimalRounding } from '../../common/Constants'
import { getHeatmapData } from '../../common/SoccerUtils'
import { activitySummaryToMetric } from '../../common/Units'
import SummaryView from './detail/SummaryView'
import { fillParticipantNames } from '../../common/Utils'
import { WithSession } from '../../session/SessionProvider'
import { round } from '../../common/Math'

const SoccerView = (props) => {
  const [heatmap1, setHeatmap1] = useState([])
  const [heatmap2, setHeatmap2] = useState([])

  const fieldsOfInterest = ['start_date', 'end_date', 'duration', 'location']
  const { data, sessionToken } = props
  const { summary, id } = data
  const summaryWithUnits = useMemo(() => activitySummaryToMetric(summary), [summary])
  useMemo(() => fillParticipantNames(summaryWithUnits), [summary])// TODO Transfer this to the Scisports Roaster

  // location: Conversion from the metric to the location in the players' summaries
  const playerMetrics = {
    [metricAvgSpeed]: { isHigherBetter: true, title_locale: 'highest_average_speed' }
    // TODO Uncomment when there is a distance.accumulated
    // [metricDistance]: { location: 'distance.accumulated', isHigherBetter: true, title: I18n.t('components.detail_views.soccer.highest_distance_covered'), unit: 'm' }
  }
  Object.keys(playerMetrics).forEach(metric => _.merge(playerMetrics[metric], soccerMetrics[metric]))

  useEffect(() => {
    const playersTeam1 = getTeamPlayers(summaryWithUnits, 0)
    const playersTeam2 = getTeamPlayers(summaryWithUnits, 1)
    Promise.all([fetchPlayersTimeseries(playersTeam1, id, sessionToken), fetchPlayersTimeseries(playersTeam2, id, sessionToken)])
      .then(responses => {
        const team1Positions = getPlayersPositions(responses[0].data)
        const team2Positions = getPlayersPositions(responses[1].data)
        setHeatmap1(calculateHeatmap(team1Positions))
        setHeatmap2(calculateHeatmap(team2Positions))
      })
      .catch(error => { console.log(error) })
  }, [id])

  const cards = []
  for (const [metric, options] of _.entries(playerMetrics)) {
    const bestScorePlayers = getBestScorePlayers(summaryWithUnits.participant_summaries, options, options.isHigherBetter)
    const title = I18n.t(`components.detail_views.soccer.${options.title_locale}`)
    cards.push(
      <div key={metric} className='col s6 m4 l3'>
        {getBestScoreCard(bestScorePlayers, title)}
      </div>
    )
  }

  // Retrive the player with the best score in a given metric. Multiple players are returned in the event of a tie
  function getBestScorePlayers (players, metric, isHigherBetter) {
    const playersTimeseries = _.map(players, player => {
      const value = _.at(player, metric.path)
      const name = player.name
      return { value: value, name: name }
    })
    const descOrAsc = isHigherBetter ? 'desc' : 'asc'
    const orderedByMetric = _.orderBy(playersTimeseries, ['value'], [descOrAsc])
    const highestScore = []
    for (let playerElem = 0; orderedByMetric[playerElem].value === orderedByMetric[0].value; playerElem++) {
      highestScore.push({
        name: orderedByMetric[playerElem].name,
        value: round(orderedByMetric[playerElem].value, soccerDecimalRounding) + ' ' + metric.unit
      })
    }
    return highestScore
  }

  function getBestScoreCard (playersData, title) {
    const score = playersData[0].value
    const playerNames = _.map(playersData, (playerData) => { return playerData.name })
    return (
      <StatsCard title={title} playerNames={playerNames} score={score} />
    )
  }

  function getPlayersPositions (playersTimeseries) {
    return _.map(playersTimeseries, data => { return data.time_series.time_series.position })
  }

  function calculateHeatmap (playersPositions) {
    const aggregatedPositions = _.concat(...playersPositions)
    return getHeatmapData(aggregatedPositions)
  }

  function fetchPlayersTimeseries (playerIds, datasetId, sessionToken) {
    // TODO Why are the parameters not working?
    return axios({
      method: 'GET',
      url: `/api/v1/data/${datasetId}/player_time_series`,
      params: {
        keys: [
          timeSeriesNames.POSITION
        ],
        player_ids: playerIds
      },
      headers: { Authorization: sessionToken }
    })
  }

  function getTeamPlayers (summary, teamId) {
    return _.map(
      _.filter(summary.participant_summaries, player => (player.team === teamId)),
      player => (player.id)
    )
  }

  return (
    <div className='soccer-detailed-view'>
      <SummaryView fieldsOfInterest={fieldsOfInterest} summary={summary} />
      <div className='row'>
        {cards}
      </div>
      <div className='row'>
        <div className='col m12 l5 heatmap-team-container'>
          <div className='heatmap-team-wrapper'>
            <div className='row'>
              <div className='col s12'>
                <h6 className='heatmap-team-title'>{summaryWithUnits.teams[0].name}</h6>
              </div>
            </div>
            <div className='row'>
              <div className='col s12 heatmap-team-content'>
                <Heatmap data={heatmap1} id='soccer-heatmap-team1' />
              </div>
            </div>
          </div>
        </div>
        <div className='col m12 l5 offset-l2 heatmap-team-container'>
          <div className='heatmap-team-wrapper'>
            <div className='row'>
              <div className='col s12'>
                <h6 className='heatmap-team-title'>{summaryWithUnits.teams[1].name}</h6>
              </div>
            </div>
            <div className='row'>
              <div className='col s12 heatmap-team-content'>
                <Heatmap data={heatmap2} id='soccer-heatmap-team2' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WithSession(SoccerView)
