import React from 'react'
import SummaryTable from '../../../analysis/dashboards/common/SummaryTable'
import { formatTemp, prepareSummaryFields } from '../../../common/Utils'

const TempView = (props) => {
  const {
    data: {
      summary: {
        time_series_summaries: {
          temp: {
            min,
            mean,
            max
          }
        }
      }
    }
  } = props

  const fields = { min: formatTemp(min), mean: formatTemp(mean), max: formatTemp(max) }
  const summaryFields = prepareSummaryFields(fields, Object.keys(fields))
  return (
    <div className='row'>
      <div className='col s12'>
        <SummaryTable fields={summaryFields} />
      </div>
    </div>
  )
}

export default TempView
