import { useEffect } from 'react'
declare let M: any

const useMaterializeTextField = (): void => {
  useEffect(() => {
    M.updateTextFields()
  }, [])
}

export default useMaterializeTextField
