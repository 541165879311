import React from 'react'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { momentDateFormat } from '../../common/Constants'
import moment from 'moment'
import { prettifyTag } from 'components/common/utils/TagUtils'

export default class DataSharingRow extends React.Component {
  renderIcon (icon) {
    return (<FontAwesomeIcon size='1x' icon={icon === 'futbol' ? ['far', 'futbol'] : icon} style={{ marginRight: '10px' }} />)
  }

  renderBigIcon (icon) {
    return (<FontAwesomeIcon size='2x' icon={icon === 'futbol' ? ['far', 'futbol'] : icon} style={{ marginRight: '10px' }} />)
  }

  render () {
    const { sports, allSports, groups, permissions, right } = this.props
    if (!right) {
      return (<></>)
    }
    const sharedByTag = !!right.tag
    let currentSource = {}
    if (!sharedByTag && sports && right.collection_type.startsWith('sports')) {
      const filteredSports = sports.filter((sport) => sport.name === right.collection_type)
      const allFilteredSports = allSports && allSports.filter((sport) => sport.name === right.collection_type)
      currentSource = filteredSports[0] || allFilteredSports[0]
    } else {
      currentSource = { name: prettifyTag(right.tag, groups), icon: 'hashtag' }
    }

    let currentTarget = {}
    if (groups) {
      currentTarget = groups.filter((group) => group.group_type === right.group.group_type && group.id === right.group.id)[0]
    }

    let currentPermission = {}
    if (permissions) {
      currentPermission = permissions.filter((permission) => permission.name === right.access_level)[0]
    }

    return (
      <>
        <td className='data-sharing-c1'>
          <span className='valign-wrapper'>
            {currentSource.icon && (
              <span style={{ color: '#bbb' }}>{this.renderBigIcon(currentSource.icon)}</span>)}
            {sharedByTag ? currentSource.name : I18n.t(currentSource.name)}
          </span>
        </td>
        <td className='data-sharing-c2'>
          <span className='valign-wrapper'>
            {currentPermission.icon && (
              <span style={{ color: '#bbb' }}>{this.renderIcon(currentPermission.icon)}</span>)}
            {I18n.t(`permissions.${currentPermission.name}`)}
          </span>
        </td>
        <td className='data-sharing-c3'>
          <span className='valign-wrapper'>
            {currentTarget && currentTarget.picture && (
              <img
                className='circle'
                style={{ height: '2.5em', marginRight: '10px' }}
                src={currentTarget.picture}
              />
            )}
            {currentTarget && currentTarget.name}
          </span>
        </td>
        <td className='data-sharing-c4'>{right.start_date ? moment(right.start_date).format(momentDateFormat) : ''}</td>
        <td className='data-sharing-c5'>{right.end_date ? moment(right.end_date).format(momentDateFormat) : ''}</td>
        <td className='data-sharing-c6'>
          <span className='sharing-rule-button'>
            <FontAwesomeIcon
              icon='edit' size='1x' className='pointer-grow' onClick={(e) => {
                e.preventDefault()
                this.props.edit(right)
              }}
            />
          </span>
          <span className='sharing-rule-button'>
            <FontAwesomeIcon
              icon='trash' size='1x' className='pointer-grow' onClick={(e) => {
                e.preventDefault()
                this.props.onDelete(this.props.id)
              }}
            />
          </span>
        </td>
      </>
    )
  }
}
