import React from 'react'
import { GoogleMap, Polyline } from '@react-google-maps/api'

// File defaults/statics
const defaultEnv = 'test'
const fallbackMapHeight = '250px'
const mapWidth = '100%'
const defaultLat = 52.156961
const defaultLong = 4.485483
const defaultZoom = 15

const options = {
  strokeColor: '#e24a00',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#e24a00',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1
}

export default class MapView extends React.Component {
  processData (data) {
    return (data.map((entry) => {
      if (entry.position_lat === undefined) {
        return undefined
      }
      return { lat: entry.position_lat, lng: entry.position_long }
    }).filter(function (el) {
      return el !== undefined
    }))
  }

  handleLoad (data) {
    return function (map) {
      const bounds = new window.google.maps.LatLngBounds()
      data.forEach(d => {
        bounds.extend(new window.google.maps.LatLng(d.lat, d.lng))
      })
      map.fitBounds(bounds)
    }
  }

  render () {
    if (!this.props.data || process.env.NODE_ENV === defaultEnv) {
      return (<></>)
    }

    const mapHeight = this.props.mapHeight || fallbackMapHeight
    const data = this.processData(this.props.data.data_rows)

    return (
      <GoogleMap
        mapContainerStyle={{ height: mapHeight, width: mapWidth }}
        defaultZoom={defaultZoom}
        defaultCenter={{ lat: defaultLat, lng: defaultLong }}
        onLoad={this.handleLoad(data)}
      >
        <Polyline
          onLoad={polyline => { }}
          path={data}
          options={options}
        />
      </GoogleMap>
    )
  }
}
