import React from 'react'
import DashboardCard from './DashboardCard'
import PropTypes from 'prop-types'
import MyReactTooltip from '../../MyReactTooltip'

const DashboardGrid = (props) => {
  return (
    <>
      <div className='row'>
        {
          props.dashboards && props.dashboards.map(d => (
            <DashboardCard {...d} key={d.id} dashboards={props.dashboards} />
          ))
        }
      </div>
      <MyReactTooltip id='dashboard-grid' />
    </>
  )
}

DashboardGrid.propTypes = {
  dashboards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      ...DashboardCard.propTypes.type
    })
  )
}

export default DashboardGrid
