import React from 'react'
import { Route } from 'react-router'
import Authorization from './layout/Authorization'
import NotFoundWrapper from '../router/NotFoundWrapper'

const OAuthRoutes = (props) => {
  const { oauthProps } = props
  return (
    <NotFoundWrapper slug='oauth'>
      <Route
        path='/oauths/authorize'
        render={(props) => (<Authorization {...props} {...oauthProps} />)}
      />
    </NotFoundWrapper>
  )
}
export default OAuthRoutes
