import { Link } from 'react-router-dom'
import React from 'react'
import HoverableProfile from '../../common/HoverableProfile'

class Others extends React.Component {
  constructor () {
    super()
    this.state = { show: false }
  }

  handleShow () {
    this.setState({ show: true })
  }

  handleHide () {
    this.setState({ show: false })
  }

  render () {
    if (this.props.avatarOnly) {
      return <div className='circle avatar-overlapping and-more'>+{this.props.count}</div>
    }
    return (
      <div className='collapsable-members-wrapper' onMouseLeave={this.handleHide.bind(this)} onMouseEnter={this.handleShow.bind(this)}>
        <span className='pointer text-primary-color'>
          &nbsp;{'en ' + this.props.count + ' anderen'}
        </span>
        <div className={`collapsable-members ${(!this.state.show && 'hidden') || ''}`}>
          <ul className='collection no-margin'>
            {this.props.memberships.map((membership) => (
              <Link key={`membership-${membership.id}`} to={membership.profile.public_url || '/'}>
                <li className='collection-item'>
                  <div className='row no-margin no-padding text-responsive valign-wrapper'>
                    <div className='col s3 no-padding'>
                      <img src={membership.profile.picture} className='responsive-img circle' />
                    </div>
                    <div className='col s9'>
                      <span>{`${membership.profile.first_name} ${membership.profile.last_name}`}</span>
                    </div>
                  </div>
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

const GroupMember = (props) => (
  <>
    <HoverableProfile profile={props.profile} noWrap avatarOnly={props.avatarOnly} />{props.comma && ', '}
  </>
)

const GroupMembers = (props) => {
  const { memberships } = props
  const countToDisplay = (memberships.length <= 3 && memberships.length) || 2

  const toDisplay = memberships.slice(0, countToDisplay)
  const toHide = memberships.slice(countToDisplay)

  return (
    <span style={{ paddingTop: '2px' }}>
      {toDisplay && toDisplay.map((membership, idx) => (
        <GroupMember
          key={membership.id}
          comma={!props.noComma && !(idx + 1 === toDisplay.length)}
          profile={membership.profile}
          avatarOnly={props.avatarOnly}
        />
      ))}
      {toHide.length > 0 && <Others memberships={toHide} count={toHide.length} avatarOnly={props.avatarOnly} />}
    </span>
  )
}

export default GroupMembers
