import React, { useState } from 'react'
import ToggleAdvanced from '../../../common/ToggleAdvanced'
import PropTypes from 'prop-types'

const AdvancedOptions = ({ advanced, onToggle, children }) => {
  const [isAdvanced, setIsAdvanced] = useState(false)
  // The component may be controlled or uncontrolled
  const deployed = advanced === undefined ? isAdvanced : advanced

  const handleToggle = onToggle || (() => setIsAdvanced(!deployed))

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <ToggleAdvanced onToggle={handleToggle} showAdvanced={deployed} />
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          {deployed && children}
        </div>
      </div>
    </>
  )
}

AdvancedOptions.propTypes = {
  advanced: PropTypes.bool,
  onToggle: PropTypes.func,
  children: PropTypes.node
}
export default AdvancedOptions
