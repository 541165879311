import _ from 'lodash'
import I18n from 'i18n'
import moment from 'moment'

import { EMPTY_RECORD, momentFullTimeFormat } from '../Constants'
import { smartRounding } from '../Math'

export function lapDistance (lap) {
  const distance = _.get(lap, 'summaries.distance')
  if (!distance) return EMPTY_RECORD

  const value = distance.end.value - distance.start.value
  return `${smartRounding(value, 1)} ${I18n.t(distance.start.units, { defaults: [{ message: '' }] })}`
}

export function lapDuration (lap, timeSeriesOffsets) {
  return timeSeriesOffsets[lap.end_index] - timeSeriesOffsets[lap.start_index]
}

export function lapDurationString (lap, timeSeries, format = momentFullTimeFormat) {
  const duration = lapDuration(lap, timeSeries.offset)
  return moment.utc(moment.duration(duration * 1000).asMilliseconds()).format(format)
}
