import React from 'react'
import { Redirect } from 'react-router'
import I18n from 'i18n'
import FormFactory from '../../helpers/FormFactory'
import { addSearchToPath, getCode, getRedirectUri } from '../../common/Utils'
import Routes from '../../common/Routes'
import { loginStages } from '../../common/Constants'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'
import { Link } from 'react-router-dom'
import { I18nLoader } from '../../I18nLoader'
import Button from '../../atomic/atoms/Button'
import _ from 'lodash'
import FeatureFlag, { SURFCONEXT } from '../../feature_flags/FeatureFlag'

class Login extends FormFactory {
  constructor (props) {
    super(props)
    this.addSearchToPath = addSearchToPath.bind(this)
    this.getCode = getCode.bind(this)
    this.getRedirectUri = getRedirectUri.bind(this)
    this.form_fields = {
      username: {
        component: 'field',
        translateErrors: false,
        stage: {
          render: [loginStages.LOGIN],
          submit: [loginStages.LOGIN, loginStages.TWOFA, loginStages.TOS]
        },
        properties: {
          type: 'email',
          autoComplete: 'on'
        }
      },
      password: {
        component: 'field',
        stage: {
          render: [loginStages.LOGIN],
          submit: [loginStages.LOGIN, loginStages.TWOFA, loginStages.TOS]
        },
        properties: {
          type: 'password',
          autoComplete: 'on'
        }
      },
      otp_attempt: {
        component: 'field',
        translateErrors: false,
        stage: {
          render: [loginStages.TWOFA],
          submit: [loginStages.TWOFA, loginStages.TOS]
        },
        properties: {
          type: 'text',
          autoComplete: 'off'
        }
      },
      accept_tos_changed: {
        component: 'checkbox',
        translateErrors: false,
        stage: {
          render: [loginStages.TOS],
          submit: [loginStages.TOS]
        },
        properties: {
          type: 'checkbox'
        }
      }
    }
    this.state = {}
  }

  componentDidMount () {
    this.props.setHeight(386)
  }

  redirectionLogic (response) {
    const code = this.getCode()
    const redirectUri = this.getRedirectUri()

    if (redirectUri !== '') {
      window.location.replace(redirectUri)
      return
    }

    if (code !== '') {
      this.props.history.push(Routes.join.codeFn(code))
      return
    }
    // We get the location from the devise controller. If the user has not
    // logged in too many times before, we redirect him or her to their
    // profile page.
    this.props.history.push(response.headers.location || '/')
  }

  handleSuccess (response) {
    if ('authorization' in response.headers) {
      this.props.updateSession({ sessionToken: response.headers.authorization, myProfile: response.data })
      I18nLoader.loadAsync().then((I18n) => {
        this.props.flashMessages.push(
          I18n.t(
            'flashmessages.login.successful'
          ),
          this.props.flashMessages.duration.SHORT,
          this.props.flashMessages.levels.INFO
        )
        this.redirectionLogic(response)
      })
    }
  }

  handleClick () {
    this.props.setStage(this.props.formName, loginStages.LOGIN)
  }

  handleFieldError (field, message, placeholderMessage = '') {
    let translatedError = ''
    if (placeholderMessage !== '') {
      translatedError = I18n.t(message, placeholderMessage)
    } else {
      translatedError = I18n.t(message)
    }
    this.props.setFieldError(this.props.formName, field, translatedError)
  }

  handleError (error) {
    if ('otp_required_for_login' in _.get(error, 'response.data')) {
      if (this.props.getStage(this.props.formName) === loginStages.TWOFA) {
        /* Already in 2FA stage, but otp still required. This means previous code was invalid */
        this.handleFieldError('otp_attempt', 'login.otp_attempt.invalid')
      } else {
        // go to two-factor authentication stage
        this.props.setStage(this.props.formName, loginStages.TWOFA)
      }
    } else if ('tos_accepted' in _.get(error, 'response.data')) {
      if (this.props.getStage(this.props.formName) === loginStages.TOS) {
        /* Already in terms of service stage, but still not accepted. Means checkbox is not checked */
        this.handleFieldError('accept_tos_changed', 'login.accept_tos_changed.required')
      } else {
        // go to terms of service stage
        this.props.setStage(this.props.formName, loginStages.TOS)
      }
    } else if (_.get(error, 'response.data.account_locked')) {
      this.handleFieldError(this.props.getStage(this.props.formName) === loginStages.TWOFA ? 'otp_attempt' : 'username', 'login.otp_attempt.locked',
        {
          // Number cast is needed here to convert time_remaining str value to int
          // Which I18n needs to show the correct grammar for the amount of minutes: (minute/minutes)
          count: Number(error.response.data.time_remaining)
        })
    } else {
      this.props.clearFieldError(this.props.formName, 'email')
      if (this.props.getStage(this.props.formName) === loginStages.TWOFA) {
        this.handleFieldError('otp_attempt', 'login.otp_attempt.invalid')
      }
    }
  }

  render () {
    return (
      <>
        <form autoComplete='on'>
          {this.props.sessionToken !== 'null' && !!this.props.sessionToken && (<Redirect to='/' />)}
          {this.props.stage === loginStages.TOS && (
            <span>{I18n.t('login.tos_changed')} <Link to={Routes.static.termsAndConditionsLatest}>{I18n.t('login.tos_changed_view')}</Link></span>
          )}
          {this.props.error && <div className='row'><div className='login-warning col s12'>{this.props.error}</div></div>}
          {this.renderFields()}
          <span>{this.renderSubmit(`${this.props.formName}.submit`, { id: 'login-button', className: 'button-primary background-primary text-background-color waves-effect waves-light' })}</span>
          {this.props.stage !== loginStages.LOGIN && <div className='row'><div className='col s12'><span><button id='vorige-stap-btn' className='button-primary background-primary text-background-color waves-effect waves-light' onClick={() => this.handleClick()}>Vorige stap</button></span></div></div>}
          {this.props.stage === loginStages.LOGIN && (
            <div className='row'>
              <div className='col s12'>
                <a
                  className='button-accent background-background text-accent-color waves-effect waves-light'
                  style={{ textAlign: 'center' }}
                  href={this.addSearchToPath('/signup')}
                >
                  {I18n.t('login.link.register')}
                </a>
              </div>
            </div>
          )}

        </form>
        <FeatureFlag name={SURFCONEXT} defaultChildren={<></>}>
          {this.props.stage === loginStages.LOGIN &&
            <div className='row'>
              <div className='col s12'>
                <Button href={process.env.SVC_AUTH_SURFCONEXT_URL} type='secondary'>
                  {I18n.t('login.link.register_with_surfconext')}
                </Button>
              </div>
            </div>}
        </FeatureFlag>
        <div className='row'>
          <div className='col s12'>
            <ul>
              {this.props.stage === loginStages.LOGIN && (
                <li>
                  <Link id='login-forgot-password' to='/password'>
                    {I18n.t('login.link.forgot_password')}
                  </Link>
                </li>
              )}
              {this.props.stage === loginStages.TWOFA && (
                <li>
                  <span>
                    {I18n.t('login.lost_authenticator')}
                  </span>
                </li>
              )}
              <li>
                <Link id='privacy-statement' to={Routes.static.privacyStatement}>
                  {I18n.t('login.link.privacy_statement')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </>
    )
  }
}

export default WithFlashMessages(Login)
