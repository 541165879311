import React from 'react'
import GenericActivityDashboard, { accelerationChartConfiguration } from '../generic/GenericActivityDashboard'
import { collectionTypes } from '../../../common/Constants'
import { seriesRunningMetric } from '../../../common/Units'

const IceSkatingDashboard = (props) => {
  const summaryFields = [
    'start_date',
    'distance',
    'duration',
    'avg_speed',
    'avg_acceleration'
  ]

  const lapFields = [
    { kind: 'path', name: 'components.dashboards.laps_table.min_speed', path: 'summaries.speed.min' },
    { kind: 'path', name: 'components.dashboards.laps_table.avg_speed', path: 'summaries.speed.mean' },
    { kind: 'path', name: 'components.dashboards.laps_table.max_speed', path: 'summaries.speed.max' },
    { kind: 'path', name: 'components.dashboards.laps_table.min_acceleration', path: 'summaries.acceleration.min' },
    { kind: 'path', name: 'components.dashboards.laps_table.avg_acceleration', path: 'summaries.acceleration.mean' },
    { kind: 'path', name: 'components.dashboards.laps_table.max_acceleration', path: 'summaries.acceleration.max' }
  ]

  const charts = [
    accelerationChartConfiguration
  ]

  return (
    <>
      <GenericActivityDashboard
        {...props}
        collectionType={collectionTypes.ICE_SKATING}
        speedUnit={seriesRunningMetric.speed}
        summaryFields={summaryFields}
        lapFields={lapFields}
        charts={charts}
        noRings
      />
    </>
  )
}

export default IceSkatingDashboard
