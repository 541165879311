import React from 'react'
import Variables from '../../../../../javascript/stylesheets/variables.module.scss'
import I18n from 'i18n'
import { Bar } from 'react-chartjs-2'
import { WithErrorBoundary } from '../../../errorboundary/ErrorBoundaryProvider'
const { primaryColor, secondaryColor } = Variables

/**
 * Renders a histogram for the given dataset. If data is numeric, it will be split into bins first
 * The number of bins can be controller with the nbins prop
 */
const Histogram = ({ yLabel, xLabels, data, height }) => {
  /**
   * Data and colors used when rendering a barchart
   */
  const chartData = {
    labels: xLabels,
    datasets: [
      {
        label: yLabel,
        backgroundColor: primaryColor,
        borderColor: secondaryColor,
        borderWidth: 1,
        yAxisId: 'y-axis-1',
        data: data
      }
    ]
  }

  const chartOptions = {
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          position: 'left',
          ticks: {
            min: 0
          },
          scaleLabel: {
            display: true,
            labelString: yLabel
          }
        }
      ]
    }
  }

  return <Bar height={height} data={chartData} options={chartOptions} />
}

export default WithErrorBoundary(Histogram, I18n.t('components.visualization.csv.errors.histogram'))
