import React from 'react'
import { collectionTypes, timeSeries } from '../../../common/Constants'
import GenericActivityDashboard, {
  BORDER_WIDTH,
  hrChartConfiguration,
  tempChartConfiguration
} from '../generic/GenericActivityDashboard'
import { smartRounding } from '../../../common/Math'
import I18n from 'i18n'
import Variables from '../../../../stylesheets/variables.module.scss'
import { seriesRidingMetric } from '../../../common/Units'
const { primaryColor, primaryColorLight, secondaryColor, secondaryColorLight } = Variables

const RidingDashboard = (props) => {
  const summaryFields = [
    'start_date',
    'distance',
    'duration',
    'avg_speed',
    'weighted_avg_watts',
    'elevation_gain',
    'elevation_loss',
    'calories',
    'max_core_temperature',
    'tags'
  ]

  const lapFields = [
    { kind: 'path', name: 'components.dashboards.laps_table.avg_speed', path: 'summaries.speed.mean' },
    { kind: 'path', name: 'components.dashboards.laps_table.max_speed', path: 'summaries.speed.max' },
    { kind: 'path', name: 'components.dashboards.laps_table.avg_power', path: 'summaries.power.mean' },
    { kind: 'path', name: 'components.dashboards.laps_table.avg_hr', path: 'summaries.hr.mean' }
  ]

  const charts = [
    hrChartConfiguration,
    tempChartConfiguration,
    {
      timeSeries: timeSeries.POWER,
      zones: true,
      config: {
        yTickCallback: v => `${smartRounding(v, 1)} ${I18n.t('units.W')}`,
        tooltipCallback: { label: (ti) => (`${smartRounding(ti.value, 1)} ${I18n.t('units.W')}`) },
        datasetConfig: {
          borderColor: primaryColor,
          backgroundColor: primaryColor,
          borderWidth: BORDER_WIDTH,
          fromColor: primaryColorLight,
          toColor: primaryColor
        }
      }
    },
    {
      timeSeries: timeSeries.CADENCE,
      config: {
        yTickCallback: v => `${smartRounding(v, 1)} ${I18n.t('units.rpm')}`,
        tooltipCallback: { label: (ti) => (`${smartRounding(ti.value, 1)} ${I18n.t('units.rpm')}`) },
        datasetConfig: {
          borderColor: secondaryColor,
          backgroundColor: secondaryColor,
          borderWidth: BORDER_WIDTH,
          fromColor: secondaryColorLight,
          toColor: secondaryColor
        }
      }
    }
  ]

  return (
    <GenericActivityDashboard
      {...props}
      collectionType={collectionTypes.RIDING}
      speedUnit={seriesRidingMetric.speed}
      summaryFields={summaryFields}
      lapFields={lapFields}
      charts={charts}
    />
  )
}

export default RidingDashboard
