import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import I18n from 'i18n'

import { BODY_TO_COLOR, momentDateFormat } from '../../../../common/Constants'
import GroupedBarsChart from '../../common/GroupedBarsChart'
import { entryMap2Entries } from './utils'

const addValueToBars = (bars, param, value) => {
  const bar = bars.find(bar => bar.param === param)
  if (bar) {
    bar.value += value
  } else {
    bars.push({
      value,
      param
    })
  }
}

const TimeLossChart = (props) => {
  const { questionnaires, barColors } = props

  const entryMap = {}
  questionnaires.forEach((questionnaire) => {
    const { week, answers } = questionnaire
    const label = moment(week).toString()

    if (!entryMap[label]) {
      entryMap[label] = []
    }

    const bars = entryMap[label]

    Object.entries(answers?.injury || []).forEach(([bodyPart, answers]) => {
      const value = answers?.daysWithoutParticipation
      addValueToBars(bars, bodyPart, value)
    })

    if (answers?.illness) {
      const value = answers?.illness?.daysWithoutParticipation
      addValueToBars(bars, 'illness', value)
    }
  })

  const entries = entryMap2Entries(entryMap)

  const properties = _.mapValues(BODY_TO_COLOR, (color) => { return { color } })
  const tooltips = {
    label: (item, data) => {
      const dataset = data?.datasets?.[item?.datasetIndex]

      const bodyPart = dataset?.label
      const daysLost = dataset?.data?.[item?.index]

      return I18n.t('components.dashboards.questionnaire.complaints.time_loss.tooltip', {
        bodyPart: I18n.t(`components.dashboards.questionnaire.complaints.body_parts.${bodyPart}`),
        daysLost
      })
    },
    title: (tooltipItems) => tooltipItems[0].xLabel.format(momentDateFormat)
  }
  const options = {
    suggestedMin: 0,
    suggestedMax: 7,
    title: I18n.t('components.dashboards.questionnaire.complaints.time_loss.title'),
    tooltipCallbacks: tooltips,
    yLabel: I18n.t('components.dashboards.questionnaire.complaints.time_loss.y_axis')
  }

  const xAxis = {
    scaleLabel: {
      display: true,
      labelString: I18n.t('components.dashboards.questionnaire.complaints.injuries_over_time.x_axis')
    },
    ticks: { callback: (value) => value.week() }
  }

  return (
    <GroupedBarsChart entries={entries} paramProperties={properties} {...options} xAxis={xAxis} barColors={barColors} />
  )
}

export default TimeLossChart
