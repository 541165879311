import React from 'react'
import PremiumTableRow from './PremiumTableRow'
import I18n from 'i18n'
const PremiumTable = (props) => {
  return (
    <div className='premium-table'>
      <table className='table-auto'>
        <thead>
          <tr>
            <td><b>{I18n.t('premium-table.headers.email')}</b></td>
            <td><b>{I18n.t('premium-table.headers.type')}</b></td>
            <td><b>{I18n.t('premium-table.headers.expires')}</b></td>
            <td><b>{I18n.t('premium-table.headers.infinite')}</b></td>
            <td><b>{I18n.t('premium-table.headers.select')}</b></td>
            <td><b>{I18n.t('premium-table.headers.redeem')}</b></td>
            <td />
          </tr>
        </thead>
        <tbody>
          {!!props.profiles && props.profiles.map((profile) => (
            <PremiumTableRow
              key={profile.id}
              isSelected={profile.id in props.selectedProfiles && props.selectedProfiles[profile.id]}
              selectProfile={props.selectProfile}
              profile={profile}
              voucher={props.vouchers[profile.id]}
              setVoucherCode={props.setVoucherCode}
              redeemVoucherCode={props.redeemVoucherCode}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default PremiumTable
