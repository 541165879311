import React from 'react'
import { collectionTypes } from '../../../../common/Constants'
import Summary from './Summary'
import PropTypes from 'prop-types'

const ActivitySummary = ({ summary, metadatum, icon, compact }) => {
  const {
    polyline,
    elevation_gain: height,
    duration: time,
    calories,
    distance,
    avg_speed: pace,
    avg_acceleration: avgAcceleration,
    time_series_summaries: {
      loop_id: { start: startLoop } = {}
    } = {}
  } = summary

  const fields = { distance, time, pace, height, calories }

  const defaultProps = { icon: icon, metadatum: metadatum, compact: compact }

  switch (summary.collection_type) {
    case collectionTypes.HIKING:
    case collectionTypes.RUNNING:
    case collectionTypes.INLINE_SKATING:
    case collectionTypes.RIDING:
      return <Summary {...defaultProps} fields={fields} polyline={polyline} />
    case collectionTypes.SWIMMING:
      return <Summary {...defaultProps} fields={{ distance, time, pace, calories }} polyline={polyline} />
    case collectionTypes.ICE_SKATING:
      return <Summary {...defaultProps} fields={{ distance, time, pace, startLoop, avgAcceleration }} />
    default:
      return <Summary {...defaultProps} />
  }
}

ActivitySummary.propTypes = {
  // Summary as processed by useSummaryStringUnits
  summary: PropTypes.object,
  metadatum: PropTypes.object,
  compact: PropTypes.bool
}

export default ActivitySummary
