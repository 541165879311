import React from 'react'
import { Link } from 'react-router-dom'
import I18n from 'i18n'

export default class NotFoundMessage extends React.Component {
  render () {
    return (
      <div className='container'>
        <div className='general-container center-align notfound-container'>
          <h3>404 - not found</h3>
          <Link to='/'><h5>{I18n.t('notFound.return')}</h5></Link>
        </div>
      </div>
    )
  }
}
