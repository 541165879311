/**
 * Crates a tag for sharing data of a group
 *
 * @function createGroupSharingTag
 * @param {string|number} groupId - The ID of the group to create the tag for
 * @return {string} the tag of the group used for sharing
 */
export const createGroupSharingTag = (groupId: string | number): string => {
  return `g-${groupId}`
}

/**
 * Crates a tagID for sharing data of a group
 *
 * @function createGroupSharingTagId
 * @param {string|number} groupId - The ID of the group to create the tagId for
 * @return {string} the tagID of the group used for sharing
 */
export const createGroupSharingTagId = (groupId: string | number): string => {
  return `tag-${createGroupSharingTag(groupId)}`
}
