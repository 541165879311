import React, { useEffect, useState } from 'react'
import { Field } from '../../common/form'
import { WithSession } from '../../session/SessionProvider'
import _ from 'lodash'
import InfoNotice from '../../data/layout/detail/InfoNotice'
import I18n from 'i18n'
import { WithBackend } from '../../backend/BackendProvider'
import { WithFlashMessages } from '../../flashmessages/FlashMessageProvider'

const UpdateEmail = (props) => {
  const [newEmail, setNewEmail] = useState('')
  const [errors, setErrors] = useState([])

  function handleEmailChange (e) {
    setNewEmail(e.target.value)
  }

  function handleSubmitEmail () {
    props.backend.users.update({ email: newEmail })
      .then(() => {
        props.refetchSession()
        props.flashMessages.push(I18n.t('profile.edit.update_email.success'), props.flashMessages.duration.SHORT, props.flashMessages.levels.INFO)
        setErrors([])
      })
      .catch(e => {
        const validationError = _.get(e, 'response.data.errors[0].detail.email')
        if (validationError) {
          setErrors(validationError)
        } else {
          props.flashMessages.push(I18n.t('profile.edit.update_email.unexpected_error'), props.flashMessages.duration.SHORT, props.flashMessages.levels.ERROR)
        }
      })
  }

  const oldEmail = _.get(props, 'myProfile.email', '')
  const unconfirmedEmail = _.get(props, 'myProfile.unconfirmed_email', '')
  const existingUnconfirmedEmail = unconfirmedEmail !== ''

  useEffect(() => {
    setNewEmail(unconfirmedEmail)
  }, [unconfirmedEmail])

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <Field formName='profile.edit' component='update_email' fieldName='old' type='email' currentValue={oldEmail} disabled />
        </div>
      </div>
      {
        existingUnconfirmedEmail &&
          <div className='row'>
            <div className='col s12'>
              <InfoNotice msg={I18n.t('profile.edit.update_email.unconfirmed_email_notice')} />
            </div>
          </div>
      }
      <div className='row'>
        <div className='col s12'>
          <Field formName='profile.edit' component='update_email' fieldName='new' type='email' errors={errors} currentValue={newEmail} onChange={handleEmailChange} />
        </div>
      </div>

      <div className='row'>
        <div className='col s12'>
          <button
            className='button-primary background-primary button-autowidth waves-effect waves-light text-background-color'
            onClick={handleSubmitEmail}
          >
            {I18n.t('profile.edit.update_email.button')}
          </button>
        </div>
      </div>
    </>
  )
}

export default WithSession(WithBackend(WithFlashMessages(UpdateEmail)))
