import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class SportIcon extends React.Component {
  getClassIcon (icon) {
    if (!icon) {
      return <FontAwesomeIcon icon='question' />
    }
    if (icon === 'futbol') {
      return <FontAwesomeIcon icon={['far', icon]} />
    }
    return <FontAwesomeIcon icon={icon} />
  }

  render () {
    // const icon = require(`./images/${this.props.icon}.svg`)
    const { icon, className, ...theRest } = this.props
    return (
      <span className={`sports-icon center-align${className ? ` ${className}` : ''}`} {...theRest}>
        {this.getClassIcon(icon)}
      </span>
    )
  }
}
