import React from 'react'
import AccountRoutes from './AccountRoutes'
import ApiConnector from '../helpers/ApiConnector'
import { WithSession } from '../session/SessionProvider'

class Account extends ApiConnector {
  /* eslint-disable camelcase */
  reset_passwordFormMapper (fields) {
    return { user: fields }
  }
  /* eslint-enable camelcase */

  loginFormMapper (fields) {
    if ('username' in fields) {
      fields.email = fields.username
      delete fields.username
    }
    return { user: fields }
  }

  signupFormMapper (fields) {
    if (!fields.profile_attributes) {
      fields.profile_attributes = {}
    }
    if ('locale' in fields) {
      fields.profile_attributes.locale = fields.locale
      delete fields.locale
    }
    if ('first_name' in fields) {
      fields.profile_attributes.first_name = fields.first_name
      delete fields.first_name
    }
    if ('last_name' in fields) {
      fields.profile_attributes.last_name = fields.last_name
      delete fields.last_name
    }

    return { user: fields }
  }

  render () {
    return (
      <AccountRoutes
        sessionToken={this.props.sessionToken}
        updateSession={this.props.updateSession}
        resetPasswordProps={this.defaultFormProps(
          'reset_password', {
            translateErrors: false,
            submitMethod: 'POST',
            endpoint: '/api/v1/password',
            logout: this.props.handleLogout
          })}
        resetPasswordEditProps={this.defaultFormProps(
          'reset_password_edit', {
            translateErrors: false,
            submitMethod: 'PATCH',
            endpoint: '/api/v1/password',
            logout: this.props.handleLogout
          })}
        loginProps={this.defaultFormProps(
          'login', {
            translateErrors: true,
            submitMethod: 'POST',
            endpoint: '/api/v1/login'
          })}
        signupProps={this.defaultFormProps(
          'signup', {
            translateErrors: false,
            submitMethod: 'POST',
            endpoint: 'api/v1/signup'
          })}
        confirmationProps={this.defaultFormProps(
          'confirmation', {
            translateErrors: false,
            submitMethod: 'GET',
            endpoint: 'api/v1/confirmation'
          })}
      />
    )
  }
}

export default WithSession(Account)
