import { sortBy } from 'lodash'
import { QuestionnaireObject } from 'components/common/types/questionnaires/QuestionnaireObject'
import { Owner } from 'components/common/types/questionnaires/Owner'
import { hcBlack, hcGreen, hcOrange, hcRed } from 'components/common/Constants'

export const nanToNull = (num: number | null): null | number => {
  return Number.isNaN(num) ? null : num
}

export const determineColor = (wellness: number): string => {
  if (wellness <= 40) return hcRed
  if (wellness >= 80) return hcGreen
  if (wellness > 40 && wellness < 80) return hcOrange
  return hcBlack
}

export const initFormSelect = (elementId: string): void => {
  const element = document.getElementById(elementId)

  if (element !== null) {
    M.FormSelect.init(element)
  }
}

export const readOwnersProfiles = (questionnaires: QuestionnaireObject[]): Owner[] => {
  const ownerSet = new Set(questionnaires.map((questionnaire) => JSON.stringify(questionnaire.owner)))
  const sortedProfiles = sortBy(Array.from(ownerSet).map((str) => JSON.parse(str)), (profile) => profile.first_name)

  return sortedProfiles
}

// considering the principle of cohesion, this is not used anywhere else, thus keeping it in this file
export interface MappedProfile {
  name: string
  value: number
}

export const mapProfiles = (owners: Owner[]): MappedProfile[] => {
  return owners.map(athlete => ({
    name: `${athlete.first_name ?? ''} ${athlete.last_name ?? ''}`,
    value: athlete.id
  }))
}
