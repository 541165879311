import React from 'react'
import MapView from './MapView'
import polyline from '@mapbox/polyline'

// TODO: This is very coupled with the whole 'structured_data_object'. Should be refactored so that it only gets the 'details' data it needs
export default class StravaMapView extends React.Component {
  processLatLong (structuredDataObjects) {
    let latlng = []
    const details = structuredDataObjects[0].data_rows[0].details
    if (details && details.latlng && details.latlng.data) {
      latlng = details.latlng.data
    } else if (structuredDataObjects[0].data_rows[0].map && structuredDataObjects[0].data_rows[0].map.summary_polyline) {
      latlng = polyline.decode(structuredDataObjects[0].data_rows[0].map.summary_polyline)
    }

    const dataRows = latlng.map((entry) => {
      return { position_lat: entry[0], position_long: entry[1] }
    })

    // Create list of possible headers
    return ({ data_rows: dataRows })
  }

  render () {
    if (!this.props.structured_data_objects || this.props.structured_data_objects.length < 1) return <></>
    const latLngData = this.processLatLong(this.props.structured_data_objects)
    if (!latLngData || !latLngData.data_rows.length) {
      return <></>
    }

    return (<MapView data={latLngData} />)
  }
}
