import React from 'react'
import { Route } from 'react-router'
import ShowProfile from './layout/ShowProfile'
import EditProfile from './layout/EditProfile'
import NotFoundWrapper from '../router/NotFoundWrapper'
import Routes from '../common/Routes'

export default class ProfileRoutes extends React.Component {
  render () {
    const { showProps, editProps } = this.props
    return (
      <NotFoundWrapper slug='profile'>
        <Route exact path={Routes.profiles.show} render={(props) => (<ShowProfile {...props} {...showProps} />)} />
        <Route exact path={Routes.profiles.edit} render={(props) => (<EditProfile {...props} {...editProps} />)} />
      </NotFoundWrapper>
    )
  }
}
