import React, { useState, useEffect, useRef } from 'react'
import I18n from 'i18n'
import { RouteComponentProps } from 'react-router'
import TrainingSchedulesCoachView, { State } from './TrainingSchedulesCoachView'
import TrainingSchedulesCalendarView from './TrainingSchedulesCalendarView'
import TabPanel, { Tab } from '../../atomic/atoms/TabPanel'
import { useHistory, useLocation } from 'react-router-dom'
import TitleWithBackButton from '../../atomic/atoms/headers/TitleWithBackButton'
import TrainingSessionsExporter from '../Exporter/TrainingSessionsExporter'
import PlannedPeriodizationView from 'components/training/layout/PlannedPeriodizationView'

interface Props extends RouteComponentProps {
}

type TabName = 'scheduled-trainings' | 'calendar' | 'planned-periodization' | 'exporter'

const TrainingScheduler: React.FC<Props> = (props) => {
  const [selectedTab, setSelectedTab] = useState<TabName|string>('scheduled-trainings')
  const history = useHistory()
  const { onDate } = useLocation<State>().state ?? {}

  // If we set the date through the history parameter, we want to be on the table tab.
  useEffect(() => {
    setSelectedTab('scheduled-trainings')
  }, [onDate])

  const firstUpdate = useRef<boolean>(true)
  useEffect(() => {
    // Don't run on the first render
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    // If we changed the selected tab, then reset the state information,
    // so that then when we go back to the calendar view and click on a training sessions on the same date again,
    // it will register as a state change and trigger the useEffect above.
    history.replace('/training-scheduler')
  }, [selectedTab])

  return (
    <>
      <TitleWithBackButton withExpandCompressButton backButtonProps={props}>{I18n.t('components.trainings.scheduler')}</TitleWithBackButton>
      <TabPanel id='training-scheduler' onShow={setSelectedTab} selectedTab={selectedTab}>
        <Tab id='scheduled-trainings' title={I18n.t('components.trainings.preview_schedule_title')}>
          {selectedTab === 'scheduled-trainings' && <TrainingSchedulesCoachView />}
        </Tab>
        <Tab id='calendar' title={I18n.t('components.trainings.calendar')}>
          {selectedTab === 'calendar' && <TrainingSchedulesCalendarView />}
        </Tab>
        <Tab id='planned-periodization' title={I18n.t('components.trainings.planned_periodization')}>
          {selectedTab === 'planned-periodization' && <PlannedPeriodizationView />}
        </Tab>
        <Tab id='exporter' title={I18n.t('components.trainings.export_training_schedules')}>
          {selectedTab === 'exporter' && <TrainingSessionsExporter />}
        </Tab>
      </TabPanel>
    </>
  )
}

export default TrainingScheduler
