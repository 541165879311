import React from 'react'
import I18n from 'i18n'
import { accessLevel, accessLevelI18nKeys } from '../../../common/Constants'

function AccessToggle ({ item, handleUpdate }) {
  const setPerm = (newPerm) => {
    handleUpdate({ ...item, access: newPerm })
  }

  return (
    <div className='onboarding-access-toggle'>
      <span
        onClick={() => setPerm(accessLevel.READ)}
        className={item.access === accessLevel.READ ? 'active' : ''}
      >
        {I18n.t(accessLevelI18nKeys[accessLevel.READ])}
      </span>
      <span
        onClick={() => setPerm(accessLevel.FULL)}
        className={item.access === accessLevel.FULL ? 'active' : ''}
      >
        {I18n.t(accessLevelI18nKeys[accessLevel.FULL])}
      </span>
    </div>
  )
}

export default AccessToggle
