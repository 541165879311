import React, { useMemo } from 'react'
import PolylineView from '../../../timeline/layout/details/PolylineView'
import ExternalStravaDetails from '../../../timeline/layout/details/StravaDetails/ExternalStravaDetails'
import SummaryTable from '../../../analysis/dashboards/common/SummaryTable'
import { activitySummaryToMetric, summaryWithUnitsToString } from '../../../common/Units'
import { prepareSummaryFields } from '../../../common/Utils'
import PropTypes from 'prop-types'

const SummaryView = (props) => {
  const { summary = {}, fieldsOfInterest = [] } = props
  const { polyline } = summary

  const summaryWithUnits = useMemo(() => activitySummaryToMetric(summary), [summary])

  const summaryWithStrings = summaryWithUnitsToString(summaryWithUnits, 2)

  const summaryFields = useMemo(() => prepareSummaryFields(summaryWithStrings, fieldsOfInterest), [summaryWithStrings])

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <SummaryTable fields={summaryFields} />
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <ExternalStravaDetails summary={summary} />
        </div>
      </div>
      {
        polyline &&
          <div className='row'>
            <div className='col s12'>
              <PolylineView polyline={polyline} />
            </div>
          </div>
      }
    </>
  )
}

SummaryView.propTypes = {
  summary: PropTypes.object.isRequired,
  fieldsOfInterest: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default SummaryView
