import React, { useEffect, useMemo } from 'react'
import Spreadsheet from 'react-spreadsheet'

const NoEdit = ({ getValue, cell }) => (
  <input
    value={getValue({ data: cell })}
    disabled
    style={{ pointerEvents: 'none' }}
  />
)

const MySpreadsheet = (props) => {
  useEffect(() => {
    document.getElementsByClassName('Spreadsheet')[0].style.minWidth = `${props.headers.length * 100}px`
  }, [props.data])

  const formatData = (data) => {
    const result = []
    data.forEach(dataRow => {
      result.push(Object.values(dataRow).map(value => {
        return { value: value, DataEditor: NoEdit }
      }))
    })
    return result
  }
  const formattedData = useMemo(() => formatData(props.data), [props.data])

  return (
    <div className='spreadsheet-container'>
      <Spreadsheet
        data={formattedData}
        columnLabels={props.headers}
      />
    </div>
  )
}

export default MySpreadsheet
