import React from 'react'
import Search from './Search'
import ProfileConnectionResult from './Search/ProfileConnectionResult'
import I18n from 'i18n'
import { WithSession } from '../../session/SessionProvider'

class ConnectionCreate extends React.Component {
  render () {
    return (
      <div className='general-content'>
        <div className='row'>
          <div className='col s12 center-align'>
            <h5 className='text-primary-color text-heavy'>{I18n.t('network.group.label.modal.new_connection')}</h5>
          </div>
        </div>
        <div className='row'>
          <div className='col s12 m7 center-align'>
            <Search
              fieldId='connection-create'
              Component={ProfileConnectionResult}
              onSearch={this.props.onProfileSearch}
              label={I18n.t('network.group.label.search_profile')}
              onClick={this.props.onConnectionRequest}
              infoBox={I18n.t('network.group.label.modal.search_profile_tooltip')}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default WithSession(ConnectionCreate)
