import TabPanel from './TabPanel'
import Tab from './Tab'

// I know it might seem weird to have an atom that exports more than one component and that renders multiple components.
// The reason I called TabPanel an atom is that technically, it is indivisible. The tab components cannot live outside
// a TabPanel, so cannot be atoms themselves. I've found a post on stack overflow that backs this idea:
// https://stackoverflow.com/questions/67246236/atomic-design-should-a-wrapper-be-an-atom-if-its-not-reusable
// Feel free to call this a molecule, organism, etc. if you disagree.
export { Tab }
export default TabPanel
