import { IconName } from '@fortawesome/fontawesome-svg-core'

export default interface Sport {
  id: number
  name: SportType
  icon: IconName
  created_at: Date
  updated_at: Date
}

export enum SportType {
  // Constant for the volleyball sport
  VOLLEYBALL = 'sports.volleyball',

  // Constant for the soccer sport
  SOCCER = 'sports.soccer',

  // Constant for the skating sport
  SKATING = 'sports.skating',

  // Constant for the inline skating sport
  INLINE_SKATING = 'sports.inline_skating',

  // Constant for the running sport
  RUNNING = 'sports.running',

  // Constant for the 'steps' sport (this is just the amount of steps recorded)
  STEPS = 'sports.steps',

  // Constant for the hiking sport
  HIKING = 'sports.hiking',

  // Constant for the riding sport
  RIDING = 'sports.riding',

  // Constant for the swimming sport
  SWIMMING = 'sports.swimming',

  // Constant for the workout sport
  WORKOUT = 'sports.workout',

  // Constant for the questionnaire sport
  QUESTIONNAIRE = 'sports.questionnaire',

  // Constant for the jupyter notebook datatype
  JUPYTERHUB = 'sports.jupyterhub',

  // This is a sport for which we have structured data but whose type we don't know (i.e. Fit files)
  GENERIC = 'sports.generic',

  // CSV data that isn't understood as any other sport
  GENERIC_CSV = 'sports.generic_csv',

  // CSV data that isn't understood as any other sport
  TRIATHLON = 'sports.triathlon',
}
