import React from 'react'
import I18n from 'i18n'
import Info from '../../atomic/atoms/Info'

export default class Toggle extends React.Component {
  render () {
    const errors = this.props.errors || []
    const { infoBox, component, fieldName, formName, currentValue, defaultValue, disabled } = this.props
    const type = this.props.type || 'checkbox'
    const fieldProps = { ...this.props }
    delete fieldProps.component
    delete fieldProps.fieldName
    delete fieldProps.translateErrors
    delete fieldProps.formName
    delete fieldProps.defaultValue
    delete fieldProps.currentValue
    delete fieldProps.type
    delete fieldProps.infoBox

    const isChecked = !disabled && (!!currentValue || (defaultValue && currentValue == null) || false)

    let infoBoxText = infoBox
    if (typeof infoBoxText === 'function') {
      infoBoxText = infoBoxText()
    }

    return (
      <div className='row'>
        <div className='col s12 valign-wrapper toggle'>
          <label htmlFor={fieldName} className='switch'>
            <input
              type='checkbox'
              name={fieldName}
              id={fieldName}
              {...fieldProps}
              checked={isChecked}
              onChange={(e) => this.props.onChange({ target: { type: type } }, formName, fieldName)}
            />
            <div className='slider' />
            <span>
              {fieldProps.label || I18n.t(`${formName}.${component}.${type}.${fieldName}`)}
            </span>
          </label>
          {errors.map(err => (
            <span className='helper-text' data-error='wrong' data-success='right' key={err} style={{ color: 'red' }}>
              {this.props.translateErrors ? I18n.t(err) : err}
            </span>
          ))}
          {infoBox && <Info text={infoBoxText} tooltipId={`${formName}.${component}.${type}.${fieldName}-tooltip`} />}
        </div>
      </div>
    )
  }
}
