import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { hcOrange, hcGreen, hcRed, hcBlack } from '../../../../common/Constants'
import I18n from 'i18n'
import { initializeHighcharts, titleWithTooltip } from '../../../../common/Utils'
import GenericPlaceholder from '../../../../common/GenericPlaceholder'

const MultiDailyReadinessToTrainChart = props => {
  if (!props.dailyLogRows || props.dailyLogRows.length === 0) {
    return (
      <GenericPlaceholder title={I18n.t('components.dashboards.questionnaire.daily_readiness_to_train.title')} message={props.noDataMessage} />
    )
  }

  const calculateColor = readinessToTrain => {
    if (readinessToTrain <= 2) return hcRed
    if (readinessToTrain >= 4) return hcGreen
    if (readinessToTrain > 2 && readinessToTrain < 4) return hcOrange
    return hcBlack
  }

  const allData = []
  for (const athleteSet of props.dailyLogRows) {
    const lineData = athleteSet.data.map(entry => {
      return {
        x: entry.date_only,
        y: entry.readiness_to_train_moving_average,
        color: calculateColor(entry.readiness_to_train_moving_average),
        formatted_date: entry.formatted_date,
        wellness_sum_p: entry.wellness_sum_p,
        sleep_duration: entry.sleep_duration
      }
    })
    allData.push({
      type: 'line',
      data: lineData,
      name: `${athleteSet.athlete.first_name} ${athleteSet.athlete.last_name}`,
      tooltip: {
        headerFormat: '',
        pointFormat: I18n.t('components.dashboards.questionnaire.daily_readiness_to_train.scatter.tooltip')
      }
    })
  }
  initializeHighcharts(true)
  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          title: titleWithTooltip(
            I18n.t('components.dashboards.questionnaire.daily_readiness_to_train.title'),
            I18n.t('components.dashboards.questionnaire.daily_readiness_to_train.info')
          ),
          series: allData,
          plotOptions: {
            line: {
              marker: {
                enabled: false
              }
            }
          },
          legend: {
            enabled: true
          },
          yAxis: {
            title: {
              text: I18n.t('components.dashboards.questionnaire.daily_readiness_to_train.yaxis')
            },
            min: 0,
            max: 5.1
          },
          xAxis: {
            title: {
              text: ''
            },
            type: 'datetime',
            dateTimeLabelFormats: {
              day: '%d %b'
            }
          }
        }}
      />
    </>
  )
}

export default MultiDailyReadinessToTrainChart
