import React from 'react'
import { Line } from 'react-chartjs-2'
import { useTransformedResponses } from './hooks'
import {
  chartjsDefaultOptions, chartjsTitleProperties,
  momentDateFormat,
  momentMonthDayFormat
} from '../../../../common/Constants'
import Variables from '../../../../../../javascript/stylesheets/variables.module.scss'
import { suggestedMaxForPersona } from './logic'
import I18n from 'i18n'
import { roundTooltip } from '../../../../common/Utils'
const { primaryColor } = Variables

const VALID_PERSONAS = Object.freeze(['fatigue', 'pain', 'stress', 'physical', 'cognitive', 'rest', 'sleep'])
const Chart = ({ persona, responses }) => {
  if (!VALID_PERSONAS.includes(persona)) {
    console.warn(`persona must be one of: ${VALID_PERSONAS}`)
    return <></>
  }

  const transformedResponses = useTransformedResponses(responses, persona)

  const title = I18n.t(`components.dashboards.questionnaire.rheuma.persona.${persona}.title`)

  const data = {
    datasets: [{
      label: persona,
      data: transformedResponses,
      spanGaps: false,
      fill: false,
      lineTension: 0,
      borderColor: primaryColor
    }]
  }

  const options = {
    ...chartjsDefaultOptions,
    legend: {
      display: false
    },
    scales: {
      xAxes: [{
        type: 'time',
        ticks: {
          maxRotation: 0,
          autoSkipPadding: 5
        },
        scaleLabel: {
          display: true,
          labelString: I18n.t('components.dashboards.questionnaire.rheuma.xlabel')
        },
        time: {
          unit: 'day',
          displayFormats: {
            day: momentMonthDayFormat
          },
          tooltipFormat: momentDateFormat
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: I18n.t(`components.dashboards.questionnaire.rheuma.persona.${persona}.ylabel`)
        },
        ticks: {
          suggestedMin: 0,
          suggestedMax: suggestedMaxForPersona(persona)
        }
      }]
    },
    title: {
      text: title,
      ...chartjsTitleProperties
    },
    tooltips: {
      callbacks: {
        label: roundTooltip
      }
    }
  }

  return <Line height={300} data={data} options={options} />
}

export default Chart
