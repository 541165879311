/*
 * This component is meant to render activity dashboards for individual sports like those that come from Strava
 */

import React, { useEffect, useMemo, useState } from 'react'
import { WithSession } from '../../../session/SessionProvider'
import PropTypes from 'prop-types'
import {
  timeSeries as timeSeriesNames,
  timeSeriesKeyToZonesKey,
  momentTimeFormat,
  imputation,
  EMPTY_RECORD,
  HR_ZONES_COLORS
} from '../../../common/Constants'
import {
  activitySummaryToMetric,
  s2hms,
  ms2kmh,
  m2km,
  min2mmss,
  summaryWithUnitsToString,
  identity
} from '../../../common/Units'
import { prepareSummaryFields } from '../../../common/Utils'
import { get, mean as lodashMean, max as lodashMax, min as lodashMin, sortBy } from 'lodash'
import { missingValueImputation, smartRounding } from '../../../common/Math'
import I18n from 'i18n'
import moment from 'moment'
import Variables from '../../../../stylesheets/variables.module.scss'
import Dashboard from '../../layout/Dashboard'
import SummaryTable from '../common/SummaryTable'
import ZoneRings from '../common/ZoneRings'
import SDVMapView from '../../../data/layout/detail/SDVMapView'
import TimeSeriesChart, { SECONDARY_Y_AXIS, HIDDEN_AXIS } from '../common/TimeSeriesChart'
import PhysAttrsLink from './PhysAttrsLink'
import { WithBackend } from '../../../backend/BackendProvider'
import { Toggle } from '../../../common/form'
import Spinner from '../../../common/Spinner'
import { lapDistance, lapDurationString } from '../../../common/utils/ActivityDashboardUtils'
import { useMyPhysiologicalAttributesQuery } from 'components/backend/Queries'
import Table from 'components/atomic/molecules/Table'
import classnames from 'classnames'
import { findClosestIndexInSortedArray } from 'components/common/utils/ArrayUtils'
const {
  accentColor,
  accentColorLight,
  accentColorDark,
  secondaryColor,
  primaryColor,
  grayChartjs
} = Variables

const MAX_SAMPLES = 3000
export const BORDER_WIDTH = 2

export function lapMinutesFormat (lap, path) {
  const value = get(lap, `${path}.value`)
  if (!value) return EMPTY_RECORD

  return `${min2mmss(value)} ${I18n.t('units.min/km')}`
}

export const hrChartConfiguration = {
  timeSeries: timeSeriesNames.HR,
  zones: true,
  config: {
    yTickCallback: value => `${smartRounding(value)} ${I18n.t('units.bpm')}`,
    tooltipCallback: { label: (ti) => (`${smartRounding(ti.value, 1)} ${I18n.t('units.bpm')}`) },
    datasetConfig: {
      borderColor: accentColor,
      backgroundColor: accentColor,
      borderWidth: BORDER_WIDTH,
      fromColor: HR_ZONES_COLORS.from,
      toColor: HR_ZONES_COLORS.to
    }
  }
}

export const tempChartConfiguration = {
  timeSeries: timeSeriesNames.TEMPERATURE,
  zones: true,
  suggestedMin: 36,
  suggestedMax: 42,
  zoneAlpha: 0.1,
  zoneColors: ['#000000', '#FF00DB', '#B600FF', '#4900FF', '#0024FF', '#0092FF', '#00FFFF', '#00FF92', '#00FF24', '#49FF00', '#B6FF00', '#FFDB00', '#FF6D00', '#FF0000', '#FF0000', '#FF0000'],
  config: {
    yTickCallback: value => `${smartRounding(value, 1)} ${I18n.t('units.C')}`,
    tooltipCallback: { label: (ti) => (`${smartRounding(ti.value, 1)} ${I18n.t('units.C')}`) },
    datasetConfig: {
      borderColor: accentColor,
      backgroundColor: accentColor,
      borderWidth: BORDER_WIDTH,
      fromColor: accentColorLight,
      toColor: accentColorDark
    }
  }
}

export const accelerationChartConfiguration = {
  timeSeries: timeSeriesNames.ACCELERATION,
  zones: false,
  suggestedMin: 0,
  suggestedMax: 2,
  config: {
    yTickCallback: value => `${smartRounding(value, 3)} ${I18n.t('units.m/s')}`,
    tooltipCallback: { label: (ti) => (`${smartRounding(ti.value, 3)} ${I18n.t('units.m/s')}`) },
    datasetConfig: {
      borderColor: primaryColor,
      backgroundColor: primaryColor,
      borderWidth: BORDER_WIDTH
    }
  }
}

const GenericActivityDashboard = (props) => {
  const [file, setFile] = useState(undefined)
  const [selectedProfile, setSelectedProfile] = useState(undefined)
  const [timeSeries, setTimeSeries] = useState(undefined)
  const [selection, setSelection] = useState(undefined)
  const [selectedLapIdx, setSelectedLapIdx] = useState(undefined)
  const { data: myPhysAttrs } = useMyPhysiologicalAttributesQuery()
  const [otherProfilePhysAttrs, setOtherProfilePhysAttrs] = useState(undefined)
  const [plotAltX, setPlotAltX] = useState(false)
  const [hasTemperature, setHasTemperature] = useState(false)
  const [tempTimeSeries, setTempTimeSeries] = useState([])
  const [tempSummary, setTempSummary] = useState({})
  const [tagsSummary, setTagsSummary] = useState({})

  const { summary = {} } = file || {}
  const { time_series_summaries: { latlong: latlongSummary } = {} } = summary
  // const { time_series: { latlong, distance, speed, elevation } = {} } = timeSeries || {}
  const {
    latlong,
    distance,
    speed,
    elevation,
    speedkmh,
    offset
  } = useMemo(preProcessTimeSeries, [timeSeries])

  // We can plot time series against time (offset) or distance. Offset will always be available. For some datasources,
  // however, distance is only available if the activity was recorded with a gps enabled device and/or outdoors. Thus,
  // we force the plotting against offset (plotAltX) if distance is NOT available.
  useEffect(() => {
    setPlotAltX(!distance)
  }, [distance])

  const plottedX = plotAltX ? offset : distance

  const tempYs = useMemo(() => {
    if (!hasTemperature || !summary?.start_date) return undefined

    const ttempYs = Array(plottedX.length).fill(null)
    const startDateMoment = moment(summary.start_date)
    for (const temperatureRow of tempTimeSeries) {
      const timeIndex = findClosestIndexInSortedArray(Math.round((moment(temperatureRow.timestamp) - startDateMoment) / 1000.0), offset)
      ttempYs[timeIndex] = temperatureRow.temp
    }
    return ttempYs
  }, [tempTimeSeries, plottedX, summary, hasTemperature])

  function handleFileSelect (file) {
    // We send the profile ID of the user that owns the dashboard activity file. Access control is managed on the back end.
    const timeSeriesPromise = props.backend.data.timeseries.getInRange(file.owner.id, file.summary.start_date, file.summary.end_date, ['temp'])
    Promise.all([timeSeriesPromise])
      .then(responses => {
        const [timeSeriesResponse] = responses
        const tempRows = timeSeriesResponse.data
        if (tempRows.length > 0) {
          setTempTimeSeries(tempRows)
          setTempSummary({ max_core_temperature: Math.max(...tempRows.map(item => item.temp)) })
          setHasTemperature(true)
        } else {
          setHasTemperature(false)
          setTempTimeSeries([])
          setTempSummary({})
        }
      })

    setFile(file)
  }

  function handleProfileSelect (profile) { setSelectedProfile(profile) }

  // Keys of the time series needed for the charts
  const timeSeriesKeys = useMemo(() => (props.charts.map(c => c.timeSeries)), [props.charts])

  // We return true if myProfile or selectedProfile are undefined so that no other profile is loaded
  const myProfileSelected = useMemo(() => !props.myProfile || !selectedProfile || props.myProfile.slug === selectedProfile.slug, [selectedProfile, props.myProfile])

  const physAttrs = useMemo(() => {
    return myProfileSelected ? myPhysAttrs : otherProfilePhysAttrs
  }, [myProfileSelected, myPhysAttrs, otherProfilePhysAttrs])

  useEffect(() => {
    // Remove selection
    handleSelectRange()

    const { id } = file || {}
    if (!id) return

    if (file) {
      setTagsSummary({ tags: file.metadatum.tags })
    }

    const params = {
      keys: [...new Set([
        // This is always rendered
        timeSeriesNames.LATLONG,
        timeSeriesNames.SPEED,
        timeSeriesNames.ELEVATION,
        timeSeriesNames.DISTANCE,
        // Needed for the rings
        timeSeriesNames.CADENCE,
        timeSeriesNames.POWER,
        timeSeriesNames.HR,
        ...timeSeriesKeys
      ])]
    }
    props.backend.data.timeseries.get(id, params).then(res => setTimeSeries(res.data))
  }, [file])

  useEffect(() => {
    if (myProfileSelected) return

    props.backend.profiles.getProfilePhysAttrs(selectedProfile?.slug).then((res) => {
      setOtherProfilePhysAttrs(res.data)
    })
  }, [selectedProfile])

  const summaryWithUnits = useMemo(() => {
    return activitySummaryToMetric({ ...summary, ...tempSummary, ...tagsSummary })
  }, [summary, tempSummary, tagsSummary])
  const summaryWithStrings = useMemo(() => summaryWithUnitsToString(summaryWithUnits), [summaryWithUnits])
  const { laps = [] } = summaryWithUnits || {}

  const summaryFields = useMemo(() => prepareSummaryFields(summaryWithStrings, props.summaryFields), [summaryWithStrings])

  function preProcessTimeSeries () {
    let { time_series: { latlong, distance, speed, elevation } = {}, offset = [] } = timeSeries || {}
    latlong = missingValueImputation(latlong, imputation.LINEAR_INTERPOLATION)
    distance = missingValueImputation(distance, imputation.LINEAR_INTERPOLATION)
    speed = missingValueImputation(speed, imputation.CONSTANT, 0)
    elevation = missingValueImputation(elevation, imputation.CONSTANT, 0)

    const defaultSpeed = speed || []

    const speedkmh = Array.from(defaultSpeed.map(ms2kmh))

    return { latlong, distance, speed, speedkmh, elevation, offset }
  }

  function elevationDelta (elevation) {
    if (!elevation) return

    return elevation.map(el => el - elevation[0])
  }

  function initialiseSpeedChart () {
    const startActivity = moment(summary.start_date)

    const elapsedCb = value => `${I18n.t('components.dashboards.elapsed')}: ${s2hms(value)}`
    const timeOfDayCb = value => `${I18n.t('components.dashboards.timeofday')}: ${startActivity.clone().add(value, 'seconds').format(momentTimeFormat)}`
    const speedKmhCb = value => `${smartRounding(value, 1)} ${I18n.t('units.km/h')}`

    const speedData = [speed, elevationDelta(elevation)]
    const speedConf = [speedConfig, elevationConfig]
    const speedTool = [speedYTickCb, elevationTooltipCb]

    // This will later be passed as datasets to the charts, so we can display more data in the tooltips. The way the
    // chart works, we need a dataset per tooltip. In our case, the data is the same, but we do something different in
    // the tooltip callbacks, to display time in different ways. So this is intended, we do want to pass 2 offset arrays
    const timeData = [offset, offset]
    const timeConf = [noLineConfig, noLineConfig]
    const timeTool = [elapsedCb, timeOfDayCb]

    // In addition to the regular speed metric in the tooltip, also display the speed in km/h.
    // However, only do this when the regular speed metric is not already in km/h, otherwise do not
    // add an extra line in the tooltip.
    const speedKmhData = props.speedUnit.units !== 'units.km/h' ? [speedkmh] : []
    const speedKmhConf = props.speedUnit.units !== 'units.km/h' ? [noLineConfig] : []
    const speedKmhTool = props.speedUnit.units !== 'units.km/h' ? [speedKmhCb] : []

    return {
      speedYs: [...speedData, ...timeData, ...speedKmhData],
      speedConf: [...speedConf, ...timeConf, ...speedKmhConf],
      speedTooltips: [...speedTool, ...timeTool, ...speedKmhTool]
    }
  }

  const sortedLaps = sortBy(laps, [(lap) => lap.start_index])

  const lapDurations = useMemo(() => {
    if (!summary || !timeSeries) return undefined

    return sortedLaps.map(lap => lapDurationString(lap, timeSeries))
  }, [laps, timeSeries])

  const lapNumbers = sortedLaps.map((_, lapIdx) => lapIdx + 1)

  const lapFields = [
    { kind: 'values', name: 'components.dashboards.laps_table.lap', values: lapNumbers },
    { kind: 'fn', name: 'components.dashboards.laps_table.distance', fn: lapDistance },
    { kind: 'values', name: 'components.dashboards.laps_table.time', values: lapDurations },
    ...props.lapFields
  ]

  function handleLapClick (idx) {
    setSelectedLapIdx(idx)
    const lap = get(laps, `[${idx}]`)
    if (!lap) return

    setSelection([lap.start_index, lap.end_index])
  }

  const speedConfig = {
    borderColor: secondaryColor,
    borderWidth: BORDER_WIDTH,
    backgroundColor: secondaryColor
  }

  const elevationConfig = {
    borderWidth: 0,
    fill: 'start',
    yAxisID: SECONDARY_Y_AXIS,
    borderColor: grayChartjs
  }

  const noLineConfig = {
    showLine: false,
    yAxisID: HIDDEN_AXIS
  }

  function makeYTickCb (unit) {
    return value => {
      const converted = unit.conversion(value)
      return summaryWithUnitsToString({ yTick: { value: converted, units: unit.units } }, 1).yTick
    }
  }

  function elevationTooltipCb (value) {
    return `${I18n.t('components.dashboards.elevation_tooltip')}: ${elevationFormatCb(value)}`
  }

  function elevationFormatCb (value) {
    return `${smartRounding(value, 1)} ${I18n.t('units.m')}`
  }

  const defaultXTickCb = value => `${smartRounding(m2km(value), 1)} ${I18n.t('units.km')}`
  const timeXTickCb = value => `${s2hms(value)} ${I18n.t('units.h')}`
  const xTickCb = plotAltX ? timeXTickCb : (props.xTickCallback || defaultXTickCb)

  const defaultSpeedYTickCb = value => `${smartRounding(value, 1)} ${I18n.t('units.m/s')}`
  const speedYTickCb = props.speedUnit ? makeYTickCb(props.speedUnit) : defaultSpeedYTickCb

  const commonTooltipCbs = {
    title: (ti) => (plotAltX ? timeXTickCb(ti[0].label) : defaultXTickCb(ti[0].label))
  }

  const { speedYs, speedConf, speedTooltips } = initialiseSpeedChart()

  const speedTooltipCbs = {
    ...commonTooltipCbs,
    label: (ti) => speedTooltips[ti.datasetIndex](ti.value)
  }

  function clearSelection () {
    setSelection(undefined)
    setSelectedLapIdx(undefined)
  }

  function handleSelectRange (start, end) {
    if (!start || !end) {
      clearSelection()
      return
    }

    const startIdx = plottedX.findIndex(d => d >= start) - 1
    const endIdx = plottedX.findIndex(d => d >= end) - 1
    setSelection([startIdx, endIdx])
  }

  function computeForSelection (seriesName, selection, aggregateFn, aggregateFnName) {
    if (!selection) {
      if (seriesName === timeSeriesNames.TEMPERATURE) return (hasTemperature && tempYs) ? aggregateFn(tempYs.filter(val => val !== null)) : undefined

      const result = get(summary, `time_series_summaries.${seriesName}.${aggregateFnName}`)
      if (!result) return undefined

      return result
    }
    const series = seriesName === timeSeriesNames.TEMPERATURE ? tempYs : get(timeSeries, `time_series.${seriesName}`)
    if (!series) return undefined

    const subset = series.slice(selection[0], selection[1])
    return aggregateFn(subset.filter(val => val !== null))
  }

  function getSelectionString (seriesName, selection, formatCb, aggregateFn, aggregateFnName) {
    const result = computeForSelection(seriesName, selection, aggregateFn, aggregateFnName)
    if (result === undefined) return EMPTY_RECORD
    return formatCb(result)
  }

  const speedAvg = useMemo(() => getSelectionString('speed', selection, speedYTickCb, lodashMean, 'mean'), [speed, selection])
  const elevationAvg = useMemo(() => {
    const formatCb = v => `${smartRounding(v, 1)} ${I18n.t('units.m')}`
    return getSelectionString('elevation', selection, formatCb, lodashMean, 'mean')
  }, [elevation, selection])

  const markers = useMemo(() => {
    if (!latlong || !latlongSummary) return []

    if (!selection) {
      return [
        { position: latlongSummary.start, label: 'A' },
        { position: latlongSummary.end, label: 'B' }
      ]
    }

    return [
      { position: latlong[selection[0]], label: 'A' },
      { position: latlong[selection[1]], label: 'B' }
    ]
  }, [latlongSummary, latlong, selection])

  const selectionValue = selection ? selection.map(v => plottedX[v]) : undefined

  function getZoneLimits (timeSeriesKey) {
    // an override for temperature because it is not in the user's profiles
    if (timeSeriesKey === timeSeriesNames.TEMPERATURE) return [0, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45]

    if (!physAttrs || physAttrs.length < 1) return undefined

    const zonesKey = timeSeriesKeyToZonesKey[timeSeriesKey]
    if (!zonesKey) return undefined

    const attr = physAttrs.find(attr => attr.variable === zonesKey)

    return get(attr, 'values')
  }

  function timeSeriesCharts () {
    return (
      <>
        {
          props.charts.map(chart => {
            const timeSeriesKey = chart.timeSeries
            const isTemperature = timeSeriesKey === timeSeriesNames.TEMPERATURE

            // Skip this if we are asked to draw a temperature time series graph and we don't have temperature data.
            if (isTemperature && !hasTemperature) {
              return (
                <React.Fragment
                  key={timeSeriesKey}
                />
              )
            }

            const { zones = false, suggestedMin = 0, zoneColors, zoneAlpha, suggestedMax } = chart || {}
            const { xTickCallback = xTickCb, yTickCallback = identity, datasetConfig = {} } = chart.config || {}
            let { tooltipCallback = {} } = chart.config || {}

            const xTCb = plotAltX ? timeXTickCb : xTickCallback

            tooltipCallback = { ...commonTooltipCbs, ...tooltipCallback }

            // We HAVE to interpolate temperature data. If we don't, we are passing a sparse array to the chart. The
            // chart will make downsampling for performance reasons. Downsampling on a sparse array means that most
            // samples will be null, and the chart will look wrong. We do not interpolate the boundaries though, so if
            // the temperature data starts or ends after/before the activity data, this is made evident in the chart.
            let ys
            if (isTemperature) {
              ys = missingValueImputation(tempYs, imputation.LINEAR_INTERPOLATION, undefined, undefined, false)
            } else {
              ys = missingValueImputation(get(timeSeries, `time_series.${timeSeriesKey}`), imputation.LINEAR_INTERPOLATION)
            }

            const avg = getSelectionString(timeSeriesKey, selection, yTickCallback, lodashMean, 'mean')
            const max = getSelectionString(timeSeriesKey, selection, yTickCallback, lodashMax, 'max')
            const delta = getSelectionString(timeSeriesKey, selection, yTickCallback, (arr) => lodashMax(arr) - lodashMin(arr), 'delta')

            const zoneLimits = zones ? getZoneLimits(timeSeriesKey) : undefined

            // We use this fragment form so we can pass keys
            if (!ys) return <React.Fragment key={timeSeriesKey} />

            return (
              <div key={timeSeriesKey} className='row'>
                <div className='col s12'>
                  <div className='chart-container auto-height chart-container-fullwidth'>
                    <div className='row no-margin-bottom'>
                      <div className='col s12'>
                        <div className='text-heavy text-l text-muted data-header'>
                          {I18n.t(`components.dashboards.${timeSeriesKey}`)}
                        </div>
                      </div>
                      <div className='col s12'>
                        <TimeSeriesChart
                          key={timeSeriesKey}
                          x={plottedX}
                          ys={[ys]}
                          datasetConfigs={[datasetConfig]}
                          yTickCallback={yTickCallback}
                          xTickCallback={xTCb}
                          tooltipCallbacks={tooltipCallback}
                          selection={selectionValue}
                          suggestedMin={suggestedMin}
                          suggestedMax={suggestedMax}
                          zones={zoneLimits}
                          zoneColors={zoneColors}
                          smooth={!isTemperature}
                          maxSamples={MAX_SAMPLES}
                          zoneAlpha={zoneAlpha}
                        />
                      </div>
                      <div className='chartjs-summary'>
                        <div className='col s12 right-align'>
                          {I18n.t('components.dashboards.avg')}: {avg}
                        </div>
                        {isTemperature && max && (
                          <div className='col s12 right-align'>
                            {I18n.t('components.dashboards.max')}: {max}
                          </div>
                        )}
                        {isTemperature && delta && (
                          <div className='col s12 right-align'>
                            {I18n.t('components.dashboards.delta')}: {delta}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </>
    )
  }
  // Don't try to select a file before we loaded our profile
  if (!props.myProfile?.id) return <Spinner transparent />

  const lapsRowColumnsClass = classnames({ 'col s12 m6': !props.noRings, 'col s12': props.noRings })

  return (
    <Dashboard
      title={I18n.t(`components.dashboards.${props.collectionType.replace('collection_type.', '')}.title`)}
      onFileSelect={handleFileSelect}
      onProfileSelect={handleProfileSelect}
      collectionType={props.collectionType}
      history={props.history}
      ready={!!file}
      {...props}
    >
      <div className='questionnaire-dashboard-wrapper no-padding'>
        <div className='row'>
          <div className='col s12'>
            <div className='text-heavy text-l text-muted'>
              {I18n.t('components.dashboards.summary')}
            </div>
            <SummaryTable fields={summaryFields} />
          </div>
        </div>
        <div className='row'>
          <div className='col s12 right-align'>
            <PhysAttrsLink slug={selectedProfile?.slug} name={selectedProfile?.fullName} />
          </div>
        </div>
        <div className='row valign-wrapper flex-start'>
          <div className={lapsRowColumnsClass}>
            <div className='chart-container'>
              <Table
                selectedIdx={selectedLapIdx} entries={laps} fields={lapFields} onClick={handleLapClick}
                onClearSelection={clearSelection}
              />
            </div>
          </div>
          {!props.noRings &&
            <div className={lapsRowColumnsClass}>
              <div className='chart-container fixed-height'>
                <ZoneRings timeSeries={timeSeries} physAttrs={physAttrs} />
              </div>
            </div>}
        </div>
        {
          latlong &&
            <div className='row'>
              <div className='col s12'>
                <SDVMapView path={latlong} markers={markers} />
              </div>
            </div>
        }
        {
          distance && offset &&
            <div className='row'>
              <div className='col s12'>
                <Toggle
                  currentValue={plotAltX} onChange={() => setPlotAltX(!plotAltX)}
                  label={I18n.t('components.dashboards.checkbox.x_toggle')}
                />
              </div>
            </div>
        }
        {
          ((speed || elevation) && plottedX) &&
            <div className='row'>
              <div className='col s12'>
                <div className='chart-container auto-height chart-container-fullwidth'>
                  <div className='row no-margin-bottom'>
                    <div className='col s12'>
                      <div className='text-heavy text-l text-muted data-header'>
                        {I18n.t('components.dashboards.speed_and_elevation')}
                      </div>
                    </div>
                    <div className='col s12'>
                      <TimeSeriesChart
                        x={plottedX} ys={speedYs}
                        datasetConfigs={speedConf}
                        xTickCallback={xTickCb} yTickCallback={speedYTickCb} onSelect={handleSelectRange}
                        tooltipCallbacks={speedTooltipCbs} selection={selectionValue}
                        elTickCallback={elevationFormatCb}
                        smooth
                        maxSamples={MAX_SAMPLES}
                      />
                    </div>
                    <div className='chartjs-padding-right chartjs-summary'>
                      <div className='col s12 right-align'>
                        {I18n.t('components.dashboards.laps_table.avg_speed')}: {speedAvg}
                      </div>
                      <div className='col s12 right-align'>
                        {I18n.t('components.dashboards.avg_elevation')}: {elevationAvg}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        }
        {
          timeSeriesCharts()
        }
      </div>
    </Dashboard>
  )
}

GenericActivityDashboard.propTypes = {
  // One of Constants.jsx collectionTypes. Indicates the sport type of the dashboard.
  collectionType: PropTypes.string.isRequired,
  // List of fields in a SDV Activity Summary that will be rendered in the summary table.
  summaryFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  /* Array of objects defining the name and the value of a lap field. The object looks like:
   * { name: '<i18n string>', [fn|value|path]: <value> }
   * The name field is the i18n string of the variable name. One of [fn, value, path] keys much be provided with the
   * following considerations:
   * - fn must be a function that receives a lap object and returns the string to render for that field.
   * - value must be a ready to be rendered value
   * - path must be the path relative to the lap object to get the value from
   */
  lapFields: PropTypes.arrayOf(PropTypes.object),
  // chartjs x tick callback shared across all charts
  xTickCallback: PropTypes.func,
  // the unit that data should be displayed in on the speed chart
  speedUnit: PropTypes.shape({
    units: PropTypes.string,
    conversion: PropTypes.func
  }),
  // Array of chart configuration object. Each configuration will render a chart at the end of the dashboard page.
  // Note that the speed/elevation chart is always rendered if the data is available.
  charts: PropTypes.arrayOf(PropTypes.shape({
    timeSeries: PropTypes.string.isRequired,
    // Wether to render a zone overlay, if true the zone limits will be gathered from the backend according to timeSeries
    zones: PropTypes.bool,
    config: PropTypes.shape({
      // chartjs x tick callback, pass only if you need to override the global x tick callback (defined above)
      xTickCallback: PropTypes.func,
      // chartjs y tick callback
      yTickCallback: PropTypes.func,
      // chartjs tooltip callbacks object
      tooltipCallback: PropTypes.object,
      // chartjs dataset configuration object
      datasetConfig: PropTypes.object
    })
  }))
}

GenericActivityDashboard.defaultProps = {
  charts: []
}

export default WithSession(WithBackend(GenericActivityDashboard))
