import React, { useState, useEffect } from 'react'
import I18n from 'i18n'
import _ from 'lodash'
import moment from 'moment'
import InfoRequiredData, { DASHBOARD_REQUIRED_DATA_TYPES } from '../../../common/RequiredDataInfo'
import {
  COMPLAINTS_QUESTIONAIRES,
  hcDarkGrey, hcGreen, hcBrightRed,
  QUESTIONNAIRE_PERIOD
} from 'components/common/Constants'
import SelectDropdown from '../../../../../common/SelectDropdown'
import GenericPlaceholder from '../../../../../common/GenericPlaceholder'
import ComplianceChart from '../../common/ComplianceChart'
import WeekSlider from 'components/analysis/dashboards/questionnaire/common/WeekSlider'
import InjuriesOverTimeChart from '../InjuriesOverTimeChart'
import {
  readComplaintsQuestionnaire
} from 'components/common/utils/QuestionnaireUtils'
import InjuryLocationsChart from '../InjuryLocationsChart'
import ReportedComplaintsChart from '../ReportedComplaintsChart'
import SevereComplaintsChart from '../SevereComplaintsChart'
import TimeLossChart from '../TimeLossChart'
import { profileIsPremium } from 'components/common/Utils'
import Info from '../../../../../atomic/atoms/Info'
import { useQuestionnaireData } from '../../Hooks'
import DateRangePicker from 'components/analysis/dashboards/questionnaire/common/DateRangePicker'
import { calculateGroupCompliance } from './logic'
import InfoComplaintsIndicatorBar from 'components/analysis/dashboards/common/InfoComplaintsIndicatorBar'
import SpinnerWrapper from 'components/common/SpinnerWrapper'

/**
 * The week slider of this dashboard works in an unconventional way:
 * To the user, the every slider tick displays the selected week's number (39 = Sep 27th 2021 - October 3rd 2021)
 * Underneath, every slider tick corresponds to the difference (in weeks) between the pivot day and the
 * selected week.
 * What the user sees -> [36, 37, 38, 39 (pivot), 40, 41]
 * The actual values  -> [-3, -2, -1, 0 (pivot), 1, 2]
 *
 * The slider's values are integers and not weeks/week numbers because:
 * 1- The counting of week numbers restarts every year, so there might be 2 "week 39"'s with different dates.
 * 2- The slider does not accept weeks as a range, so this was the way to transform weeks into integers.
 *
 *
 * About the pivot:
 * The pivot is a fixed arbitrary day. It is used as a reference to calculate the week differences.
 * This pivot does not need to be changed. Each year has 52 weeks,
 * so a week number will never be over the JS integer MAXINT limit that justifies a change.
 *
 * "Why the pivot?", you ask. The conventional approach would be slider ticks = [0, ..., weekDiff(maxDate, minDate)].
 * However:
 * 1- noUiSlider reset() method resets the slider values to the start values, but not any of the slider's properties.
 * Also, re-rendering the slider does not change the start values.
 * 2- Changing the min/maxDate changes the slider's range, and subsequently, the slider ticks. Which means that,
 * the week corresponding to tick 0 in the old range might be a tick 4 in the new range.
 *
 * This means that, after changing min/maxDate, the range will change and the values will be reset to the start values.
 * But the new ticks do not correspond to the same weeks, so it will be inconsistent.
 *
 */
export const PhysicalComplaintsDashboard = (props) => {
  const { myProfile, minDate, maxDate, sliderStartDate, sliderEndDate } = props
  // Only show premium charts if user is premium and is accessing the dashboard from the premium tile
  const isPremium = profileIsPremium(myProfile) && props.premium === true

  const selectedProfileId = props.selectedProfileId
  const [selectedGroupId, setSelectedGroupId] = useState(undefined)
  const [selectedSubjectId, setSelectedSubjectId] = useState('')

  useEffect(() => {
    if (!selectedProfileId) return

    handleSelectSubject({ target: { value: `profile-${selectedProfileId}` } })
  }, [selectedProfileId])

  // If we load the page or the profile was changed/loaded and we have our own profile id
  // and we don't have a selected profile or selected group, then select our own profile id.
  useEffect(() => {
    // If we have already made a selection, or if we don't have a profile id yet, do nothing.
    if ((selectedSubjectId && selectedSubjectId !== '') || selectedGroupId || !myProfile?.id) return

    // If we are called as a tab in the full questionnaire dashboard, the props.setSelectedProfileId function will be defined.
    if (props.setSelectedProfileId !== undefined) {
      // This change to selectedProfileId will be detected up by the useEffect above to call handleSelectSubject and set
      // selectedSubjectId.
      props.setSelectedProfileId(myProfile.id)
    } else { // We are called as standalone direct route. So props.selectedProfileId and props.setSelectedProfileId are not defined.
      // Here, we can't change the props.selectedProfileId to keep it in sync with the selectedSubjectId (because there is no props.selectedProfileId),
      // so call handleSelectSubject directly to set selectedSubjectId.
      handleSelectSubject({ target: { value: `profile-${myProfile.id}` } })
    }
  }, [myProfile])

  // NOTE: in the below line, `type` is an array of questionnaire names.
  const queryParams = {
    start_date: minDate.toISOString(),
    end_date: maxDate.toISOString(),
    type: COMPLAINTS_QUESTIONAIRES,
    profile_id: selectedProfileId,
    group_id: selectedGroupId,
    isLoading: true
  }
  const {
    groups: { data: groupsWithData },
    profiles: { data: profilesWithData },
    questionnaires: { data: selectedWeeklyQuestionnaires, isSuccess: questionnairesAreFetched, isError: questionnairesFetchError },
    protocolSubscriptions: { data: protocolSubscriptions }
  } = useQuestionnaireData({
    params: queryParams,
    profilesOptions: { enabled: true },
    groupsOptions: { enabled: isPremium },
    questionnairesOptions: { enabled: selectedSubjectId !== '' },
    protocolSubscriptionsOptions: { enabled: selectedGroupId !== undefined }
  })

  const isLoading = !questionnairesAreFetched
  const isError = questionnairesFetchError
  const [groupResponsesFilledIn, totalExpectedGroupResponses] = calculateGroupCompliance(protocolSubscriptions, sliderStartDate, sliderEndDate)

  const handleSelectSubject = (e) => {
    const subjectId = e?.target?.value || ''
    setSelectedSubjectId(subjectId)
    if (subjectId.includes('profile-')) {
      const profileId = parseInt(subjectId.replace('profile-', ''))
      if (props.setSelectedProfileId !== undefined) props.setSelectedProfileId(profileId)
      if (props.setLastSelectedAthleteId !== undefined) props.setLastSelectedAthleteId(profileId)
      setSelectedGroupId(undefined)
    } else if (subjectId.includes('group-')) {
      setSelectedGroupId(subjectId.replace('group-', ''))
      if (props.setSelectedProfileId !== undefined) props.setSelectedProfileId(undefined)
    }
  }

  const subjectList = []
  if (profilesWithData && profilesWithData.length > 0) {
    subjectList.push(
      {
        groupName: 'athletes',
        elements: _.values(profilesWithData)?.map((profile) => ({
          name: `${profile.first_name} ${profile.last_name}`,
          value: `profile-${profile.id}`,
          icon: profile?.picture
        }))
      }
    )
  }
  if (groupsWithData && groupsWithData.length > 0) {
    subjectList.push(
      {
        groupName: 'groups',
        elements: groupsWithData.map(g => ({
          name: g.name,
          value: `group-${g.id}`,
          icon: g.picture
        }))
      }
    )
  }

  const weeklyQuestionnairesWithEmptyValues = []
  const barColors = [''] // init with empty string 1st value because it is skipped in the custom multi-color-bar
  for (let currentDate = sliderStartDate.clone(); currentDate.isSameOrBefore(sliderEndDate, QUESTIONNAIRE_PERIOD); currentDate.add(1, 'week')) {
    const selectedQuestionnaires = _.filter(selectedWeeklyQuestionnaires, (q) => moment(q.structured_data_objects?.[0]?.data_rows?.[0]?.open_from).isSame(currentDate, QUESTIONNAIRE_PERIOD))
    const currentWeek = currentDate.startOf(QUESTIONNAIRE_PERIOD).toISOString()
    let weekHasData = false
    let weekHasComplaints = false
    selectedQuestionnaires.forEach(selectedQuestionnaire => {
      const questionnaireAnswers = readComplaintsQuestionnaire(selectedQuestionnaire?.structured_data_objects?.[0]?.data_rows?.[0]?.values)
      if (!_.isEmpty(questionnaireAnswers.complaints)) {
        weekHasComplaints = true
      }

      const questionnaire = _.merge(
        selectedQuestionnaire,
        { week: currentWeek, answers: questionnaireAnswers }
      )
      weeklyQuestionnairesWithEmptyValues.push(questionnaire)
      weekHasData = true
    })
    if (!weekHasData) { // week has no data
      weeklyQuestionnairesWithEmptyValues.push({ week: currentWeek, answers: null })
      barColors.push(hcDarkGrey)
    } else if (!weekHasComplaints) { // week has no complaints
      barColors.push(hcGreen)
    } else {
      barColors.push(hcBrightRed) // week has complaints
    }
  }

  const numberOfFilledInWeeks = _.reduce(weeklyQuestionnairesWithEmptyValues, (total, questionnaire) => {
    if (!_.isEmpty(questionnaire?.answers)) {
      total += 1
    }
    return total
  }, 0)
  const totalNumberOfWeeks = _.defaultTo(weeklyQuestionnairesWithEmptyValues?.length, 0)

  const isDashboardActive = !!selectedSubjectId
  const notSelectedAthletePlaceholder = <GenericPlaceholder message={I18n.t('components.placeholders.select_athlete')} />

  let responsesFilledIn
  let totalExpectedResponses

  if (selectedProfileId) {
    responsesFilledIn = numberOfFilledInWeeks
    totalExpectedResponses = totalNumberOfWeeks
  } else {
    responsesFilledIn = groupResponsesFilledIn
    totalExpectedResponses = totalExpectedGroupResponses
  }

  const timeLossChart = (
    <div className='col s12 l6'>
      <div className='chart-container'>
        {!selectedSubjectId && notSelectedAthletePlaceholder}
        {isDashboardActive && (
          <SpinnerWrapper ready={!isLoading && !isError} failed={isError && !isLoading}>
            <TimeLossChart questionnaires={weeklyQuestionnairesWithEmptyValues} barColors={barColors} />
          </SpinnerWrapper>
        )}
      </div>
    </div>
  )

  return (
    <div>
      <div className='row'>
        <div className='col s12 text-xl text-primary-color text-heavy'>
          {I18n.t('components.dashboards.questionnaire.complaints.title')}
          <InfoRequiredData
            id='dataset-types-info'
            dataTypes={DASHBOARD_REQUIRED_DATA_TYPES.complaints_questionnaire}
          />
        </div>
      </div>
      <div className='row'>
        <DateRangePicker {...props} />
        <div className='col s12'>
          <SelectDropdown
            id='profile-selector' onChange={handleSelectSubject}
            label={isPremium
              ? I18n.t('components.dashboards.questionnaire.select_profile_or_group')
              : I18n.t('components.dashboards.questionnaire.select_profile')}
            placeholder={isPremium
              ? I18n.t('components.dashboards.select_dropdown.athlete_or_group')
              : I18n.t('components.dashboards.select_dropdown.athlete')} content={subjectList}
              value={selectedSubjectId}
          />
        </div>
        <WeekSlider {...props} />
      </div>

      <div className='row'>
        <div className='col s12 l6'>
          <div className='chart-container'>
            {!selectedSubjectId && notSelectedAthletePlaceholder}
            {isDashboardActive && (
              <>
                {questionnairesAreFetched && (
                  <div className='highcharts-plot-title-container'>
                    <div className='highcharts-plot-title'>
                      {I18n.t('components.dashboards.questionnaire.complaints.compliance.title')}
                    </div>
                    <p>{I18n.t('components.dashboards.questionnaire.complaints.compliance.description')}</p>
                  </div>
                )}

                <SpinnerWrapper ready={!isLoading && !isError} failed={isError && !isLoading}>
                  <div className='compliance-chart'>
                    <ComplianceChart
                      filledIn={responsesFilledIn}
                      total={totalExpectedResponses}
                    />
                  </div>
                </SpinnerWrapper>
              </>
            )}
          </div>
        </div>
        <div className='col s12 l6'>
          <div className='chart-container fixed-height'>
            {!selectedSubjectId && notSelectedAthletePlaceholder}
            {isDashboardActive && (
              <>
                {questionnairesAreFetched && (
                  <div style={{ position: 'relative', float: 'right' }}>
                    <InfoComplaintsIndicatorBar
                      id='complaints-bar-info'
                    />
                  </div>
                )}
                <SpinnerWrapper ready={!isLoading && !isError} failed={isError && !isLoading}>
                  <InjuriesOverTimeChart questionnaires={weeklyQuestionnairesWithEmptyValues} barColors={barColors} />
                </SpinnerWrapper>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 l6'>
          <div className='chart-container'>
            {!selectedSubjectId && notSelectedAthletePlaceholder}
            {isDashboardActive && (
              <SpinnerWrapper ready={!isLoading && !isError} failed={isError && !isLoading}>
                <InjuryLocationsChart questionnaires={weeklyQuestionnairesWithEmptyValues} />
              </SpinnerWrapper>
            )}
          </div>
        </div>
        {!isPremium && (
          timeLossChart
        )}
        {isPremium && (
          <div className='col s12 l6'>
            <div className='chart-container'>
              {!selectedSubjectId && notSelectedAthletePlaceholder}
              {isDashboardActive && (
                <SpinnerWrapper ready={!isLoading && !isError} failed={isError && !isLoading}>
                  <ReportedComplaintsChart questionnaires={weeklyQuestionnairesWithEmptyValues} barColors={barColors} />
                </SpinnerWrapper>
              )}
            </div>
          </div>
        )}
      </div>
      {isPremium && (
        <div className='row'>
          {timeLossChart}
          <div className='col s12 l6'>
            <div className='chart-container'>
              {!selectedSubjectId && notSelectedAthletePlaceholder}
              {isDashboardActive && (
                <>
                  {questionnairesAreFetched && (
                    <div className='highcharts-plot-title-container'>
                      <div className='highcharts-plot-title'>
                        {I18n.t('components.dashboards.questionnaire.complaints.severe_complaints.title')}
                        <Info
                          text={I18n.t('components.dashboards.questionnaire.complaints.severe_complaints.info')}
                          tooltipId='higher-z-tooltip' inline
                        />
                      </div>
                    </div>
                  )}
                  <SpinnerWrapper ready={!isLoading && !isError} failed={isError && !isLoading}>
                    <SevereComplaintsChart questionnaires={weeklyQuestionnairesWithEmptyValues} barColors={barColors} />
                  </SpinnerWrapper>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
