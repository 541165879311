import React from 'react'
import { MyAxios as axios } from '../MyAxios'
import { WithSession } from '../session/SessionProvider'

export const BackendContext = React.createContext()

class BackendProviderCls extends React.Component {
  addV1BasePath (path) {
    return `/api/v1${path}`
  }

  addV2BasePath (path) {
    return `/api/v2${path}`
  }

  async makeBackendCall (method, path, data = undefined, options = {}, params = undefined) {
    let pathToUse
    if (options.addBasePath === false) {
      pathToUse = path
    } else {
      pathToUse = this.addV1BasePath(path)
    }
    return axios({
      method: method,
      url: pathToUse,
      headers: {
        Authorization: `Bearer ${this.props.session.sessionToken}`,
        'Content-Type': 'application/json'
      },
      data: data,
      params: params
    })
  }

  async makeV2BackendCall (method, path, data = undefined, options = {}, params = undefined) {
    if (options.addBasePath !== false) {
      path = this.addV2BasePath(path)
      options.addBasePath = false
    }

    return this.makeBackendCall(method, path, data, options, params)
  }

  // Admin

  async adminDestroyUser (data) {
    return this.makeBackendCall('POST', '/admin/users/delete', data)
  }

  async adminConfirmUser (data) {
    return this.makeBackendCall('POST', '/admin/users/confirm', data)
  }

  async adminListVouchers () {
    return this.makeBackendCall('GET', '/admin/vouchers')
  }

  async adminCreateVoucher (data) {
    return this.makeBackendCall('POST', '/admin/vouchers', data)
  }

  async adminDestroyVoucher (voucher) {
    return this.makeBackendCall('DELETE', `/admin/vouchers/${voucher}`)
  }

  async adminListOauthApplications () {
    return this.makeBackendCall('GET', '/admin/oauth_applications')
  }

  async adminCreateOauthApplication (data) {
    return this.makeBackendCall('POST', '/admin/oauth_applications', data)
  }

  async adminGetStatistics () {
    return this.makeBackendCall('GET', '/admin/statistics')
  }

  async adminGetStatisticsCurrentYear () {
    return this.makeBackendCall('GET', '/admin/statistics/current_year')
  }

  async adminCancelSubscriptions (ids) {
    return this.makeBackendCall('POST', '/admin/premium/cancel', { profiles: ids })
  }

  async adminMakeInfiniteSubscription (ids, type) {
    return this.makeBackendCall('POST', '/admin/premium/upgrade', { profiles: ids, type: type })
  }

  async adminRedeemVoucherCode (profileId, code) {
    return this.makeBackendCall('POST', '/admin/premium/redeem', { profile: profileId, code: code })
  }

  async adminFilterSubscriptions (filters) {
    return this.makeBackendCall('GET', '/admin/premium' + filters)
  }

  // Aggregated Results
  async showAggregatedResults (source) {
    return this.makeBackendCall('GET', `/aggregated_results/${source}`)
  }

  // Dashboards
  async getAcwr (collectionType, startDate = undefined, endDate = undefined, collectionSubtype = undefined, timeOfDay = undefined, profileIds = undefined) {
    let url = `/dashboards/trends/acwr?collection_type=${collectionType}`
    if (startDate) url += `&start_date=${startDate}`
    if (endDate) url += endDate && `&end_date=${endDate}`
    if (collectionSubtype) url += collectionSubtype && `&collection_subtype=${collectionSubtype}`
    if (timeOfDay) url += timeOfDay && `&time_of_day=${timeOfDay}`
    profileIds?.forEach((id) => { url += `&profile_ids[]=${id}` })
    return this.makeBackendCall('GET', url)
  }

  async getWeeklyTrainingLoad (start, end, profileId) {
    const params = {
      start_date: start,
      end_date: end,
      user: profileId
    }

    return this.makeBackendCall('GET', '/dashboards/training/weekly_training_load', undefined, undefined, params)
  }

  // Notebooks
  async runNotebook (attrs) {
    return this.makeBackendCall('POST', '/notebooks/run', attrs)
  }

  // Data
  async getTimeseries (id, params = undefined) {
    return this.makeBackendCall('GET', `/data/${id}/time_series`, undefined, {}, params)
  }

  async getTimeseriesInRange (profileId, from, to, keys) {
    return this.makeBackendCall('GET', '/data/time_series_in_range', undefined, {}, { profile_id: profileId, from: from, to: to, keys: keys })
  }

  async getData (vdoId) {
    return this.makeBackendCall('GET', `/data/${vdoId}`)
  }

  async findData (type) {
    if (type === 'questionnaire_type') {
      return this.makeBackendCall('GET', '/data/find?data_type=questionnaire_type')
    } else {
      return this.makeBackendCall('GET', `/data/find?collection_type=${type}`)
    }
  }

  // Features
  async checkFeatures (features) {
    return this.makeBackendCall('POST', '/features/check', features)
  }

  // Groups
  async getGroups () {
    return this.makeBackendCall('GET', '/groups')
  }

  async showGroup (id) {
    return this.makeBackendCall('GET', `/groups/${id}`)
  }

  async createGroup (data) {
    return this.makeBackendCall('POST', '/groups', data)
  }

  async updateGroup (groupId, data) {
    return this.makeBackendCall('PATCH', `/groups/${groupId}`, data)
  }

  async addGroupAnnouncement (groupId, data) {
    return this.makeBackendCall('POST', `/groups/${groupId}/group_announcements`, data)
  }

  async deleteGroupAnnouncement (groupId, groupAnnouncementId) {
    return this.makeBackendCall('DELETE', `/groups/${groupId}/group_announcements/${groupAnnouncementId}`)
  }

  async getAllProtocolSubscriptions (groupId) {
    return this.makeBackendCall('GET', `/groups/${groupId}/protocol_subscriptions`)
  }

  async protocolSubscriptionsMembersWithQuestionnairesEnabled (groupId) {
    return this.makeBackendCall('GET', `/groups/${groupId}/protocol_subscriptions/members_with_questionnaires_enabled`)
  }

  async createProtocolSubscriptions (groupId, data) {
    return this.makeBackendCall('POST', `/groups/${groupId}/protocol_subscriptions`, data)
  }

  async destroyProtocolSubscription (groupId, protocolSubscriptionId) {
    return this.makeBackendCall('DELETE', `/groups/${groupId}/protocol_subscriptions/${protocolSubscriptionId}`)
  }

  async updateProtocolSubscription (groupId, protocolSubscriptionId, data) {
    return this.makeBackendCall('PATCH', `/groups/${groupId}/protocol_subscriptions/${protocolSubscriptionId}`, data)
  }

  async getAllGroupProtocolSubscriptions (groupId) {
    return this.makeBackendCall('GET', `/groups/${groupId}/group_protocol_subscriptions`)
  }

  async createGroupProtocolSubscriptions (groupId, data) {
    return this.makeBackendCall('POST', `/groups/${groupId}/group_protocol_subscriptions`, data)
  }

  async destroyGroupProtocolSubscription (groupId, groupProtocolSubscriptionId) {
    return this.makeBackendCall('DELETE', `/groups/${groupId}/group_protocol_subscriptions/${groupProtocolSubscriptionId}`)
  }

  async getRecentActivity (groupId, page, profileIds, start, end) {
    let url = `/groups/${groupId}/recent_activity?page=${page}`
    url += start ? `&start=${start}` : ''
    url += end ? `&end=${end}` : ''
    profileIds?.forEach((id) => { url += `&profile_ids[]=${id}` })
    return this.makeBackendCall('GET', url)
  }

  async getGroupOverview (groupId, start, end) {
    let url = `/groups/${groupId}/overview`
    url += start ? `?start=${start.toISOString()}` : ''
    url += end ? (start ? `&end=${end.toISOString()}` : `?end=${end.toISOString()}`) : ''
    return this.makeBackendCall('GET', url)
  }

  // Group memberships
  async updateGroupMembershipSettings (membershipId, settings) {
    return this.makeBackendCall('PATCH', `/group_memberships/${membershipId}/settings`, settings)
  }

  // Collection rights
  async getCollectionRights () {
    return this.makeBackendCall('GET', '/collection_rights')
  }

  async deleteCollectionRight (idx) {
    return this.makeBackendCall('DELETE', `/collection_rights/${idx}`)
  }

  async createCollectionRight (data) {
    return this.makeBackendCall('POST', '/collection_rights', data)
  }

  async updateCollectionRight (idx, data) {
    return this.makeBackendCall('PATCH', `/collection_rights/${idx}`, data)
  }

  // Sports
  async getSports () {
    return this.makeBackendCall('GET', '/sports')
  }

  // Redeem Vouchers
  async redeemVoucher (data) {
    return this.makeBackendCall('POST', '/vouchers/redeem', data)
  }

  // Notifications
  async markNotificationsRead () {
    return this.makeBackendCall('POST', '/notifications/mark_all_read')
  }

  async markNotificationRead (id) {
    return this.makeBackendCall('PATCH', `/notifications/${id}`, { notification: { read: true } })
  }

  async markBulkNotificationRead (data) {
    return this.makeBackendCall('PATCH', '/notifications/mark_read', data)
  }

  async getAllNotifications () {
    return this.makeBackendCall('GET', '/notifications')
  }

  async getPaginatedNotifications (page) {
    return this.makeV2BackendCall('GET', `/notifications?page=${parseInt(page)}`)
  }

  async getPaginatedUnreadNotifications (page) {
    return this.makeV2BackendCall('GET', `/notifications/unread?page=${parseInt(page)}`)
  }

  // OAuth
  async listOauthApplications () {
    return this.makeBackendCall('GET', '/oauth')
  }

  async destroyOauthApplication (id) {
    return this.makeBackendCall('DELETE', `/oauth/${id}`)
  }

  async authorizeOauthApplication (data) {
    return this.makeBackendCall('POST', '/oauth/authorize', data, { addBasePath: false })
  }

  async getOauthApplicationDetails (clientId) {
    return this.makeBackendCall('GET', `/oauth/${clientId}`)
  }

  // Profiles
  async searchProfiles (targetString) {
    return this.makeBackendCall('GET', `/profiles/search?q=${encodeURIComponent(targetString)}&exclude_existing_mutual_connection=true`)
  }

  async getProfile (slug) {
    return this.makeBackendCall('GET', `/profiles/${slug}`)
  }

  async getProfilePhysAttrs (slug) {
    return this.makeBackendCall('GET', `/profiles/${slug}/physiological_attributes`)
  }

  async getMy () {
    return this.makeBackendCall('GET', '/profiles/my')
  }

  async getMySharing () {
    return this.makeBackendCall('GET', '/profiles/my/sharing')
  }

  async setMySharing (data) {
    return this.makeBackendCall('POST', '/profiles/my/sharing', data)
  }

  async resetMyCache () {
    return this.makeBackendCall('POST', '/profiles/my/reset_cache')
  }

  async getMyProfileMetadata () {
    return this.makeBackendCall('GET', '/profiles/my/metadata')
  }

  async createSchedule (data) {
    return this.makeBackendCall('POST', '/schedules', data)
  }

  async updateSchedule (id, schedule) {
    return this.makeBackendCall('PATCH', `/schedules/${id}`, schedule)
  }

  async deleteSchedule (id) {
    return this.makeBackendCall('DELETE', `/schedules/${id}`)
  }

  async getSchedules (week) {
    return this.makeBackendCall('GET', `/schedules?week=${week}`)
  }

  async getMonthlySchedules (startDate) {
    return this.makeBackendCall('GET', `/schedules/monthly?start_date=${startDate.toISOString()}`)
  }

  async acceptTermsAndConditions () {
    return this.makeBackendCall('POST', '/profiles/accept_terms')
  }

  async updateUser (data) {
    return this.makeBackendCall('PATCH', '/users', data)
  }

  async confirmEmail (token) {
    return this.makeBackendCall('GET', `/confirmation?confirmation_token=${token}`)
  }

  async acceptGroupMembership (invite, consent_responses = undefined) {
    return this.makeBackendCall('PATCH', `/group_memberships/${invite.id}/accept`, consent_responses)
  }

  async getGroupInformedConsent (groupId) {
    return this.makeBackendCall('GET', `/groups/${groupId}/informed_consents`)
  }

  // Tags
  async getTags () {
    return this.makeBackendCall('GET', '/tags')
  }

  // Transfer Requests
  async getTransferRequests (from, to, status, page) {
    const params = {
      from: from,
      to: to,
      status: status
    }
    return this.makeBackendCall('GET', `/transfer_requests?page=${parseInt(page)}`, undefined, undefined, params)
  }

  async acceptTransferRequest (transferRequestId) {
    return this.makeBackendCall('PATCH', `/transfer_requests/${transferRequestId}/accept`)
  }

  async rejectTransferRequest (transferRequestId) {
    return this.makeBackendCall('PATCH', `/transfer_requests/${transferRequestId}/reject`)
  }

  async createTransferRequest (data) {
    return this.makeBackendCall('POST', '/transfer_requests', data)
  }

  // Gets all the questionnaire data a user has access to based on some filter parameters
  async getAllQuestionnaires (params) {
    return this.makeBackendCall('GET', '/questionnaire_responses', undefined, undefined, params)
  }

  // Gets all the profiles of which a user has access to their questionnaire data, based on some filter parameters
  async getQuestionnaireProfiles (params) {
    return this.makeBackendCall('GET', '/questionnaire_responses/profiles', undefined, undefined, params)
  }

  // Gets all the groups fo which a user has access to their questionnaire dta, based on some filter parameters
  async getQuestionnaireGroups (params) {
    return this.makeBackendCall('GET', '/questionnaire_responses/groups', undefined, undefined, params)
  }

  async getMyPhysiologicalAttributes () {
    return this.makeBackendCall('GET', '/physiological_attributes')
  }

  async updateMyPhysiologicalAttributes (attrs) {
    return this.makeBackendCall('PATCH', '/physiological_attributes/my', attrs)
  }

  async getMyTransponder () {
    return this.makeBackendCall('GET', '/transponders')
  }

  async deleteMyTransponder () {
    return this.makeBackendCall('DELETE', '/transponders')
  }

  async createMyTransponder (data) {
    return this.makeBackendCall('POST', '/transponders', data)
  }

  render () {
    return (
      <BackendContext.Provider value={{
        backend: {
          checkFeatures: this.checkFeatures.bind(this),
          showAggregatedResults: this.showAggregatedResults.bind(this),
          listOauthApplications: this.listOauthApplications.bind(this),
          destroyOauthApplication: this.destroyOauthApplication.bind(this),
          authorizeOauthApplication: this.authorizeOauthApplication.bind(this),
          getOauthApplicationDetails: this.getOauthApplicationDetails.bind(this),
          createGroup: this.createGroup.bind(this),
          updateGroup: this.updateGroup.bind(this),
          updateGroupMembershipSettings: this.updateGroupMembershipSettings.bind(this),
          confirm: this.confirmEmail.bind(this),
          dashboards: {
            trends: {
              acwr: this.getAcwr.bind(this)
            },
            training: {
              weeklyTrainingLoad: this.getWeeklyTrainingLoad.bind(this)
            }
          },
          questionnaireResponses: {
            index: this.getAllQuestionnaires.bind(this),
            profiles: this.getQuestionnaireProfiles.bind(this),
            groups: this.getQuestionnaireGroups.bind(this)
          },
          data: {
            find: this.findData.bind(this),
            timeseries: {
              get: this.getTimeseries.bind(this),
              getInRange: this.getTimeseriesInRange.bind(this)
            },
            show: this.getData.bind(this)
          },
          groups: {
            get: this.getGroups.bind(this),
            show: this.showGroup.bind(this),
            recentActivity: this.getRecentActivity.bind(this),
            overview: this.getGroupOverview.bind(this),
            informed_consent: {
              index: this.getGroupInformedConsent.bind(this)
            }
          },
          sports: {
            get: this.getSports.bind(this)
          },
          notifications: {
            markNotificationsRead: this.markNotificationsRead.bind(this),
            markNotificationRead: this.markNotificationRead.bind(this),
            markBulkNotificationRead: this.markBulkNotificationRead.bind(this),
            getAllNotifications: this.getAllNotifications.bind(this),
            getPaginatedNotifications: this.getPaginatedNotifications.bind(this),
            getPaginatedUnreadNotifications: this.getPaginatedUnreadNotifications.bind(this)
          },
          protocolSubscriptions: {
            index: this.getAllProtocolSubscriptions.bind(this),
            create: this.createProtocolSubscriptions.bind(this),
            destroy: this.destroyProtocolSubscription.bind(this),
            update: this.updateProtocolSubscription.bind(this),
            collection: {
              membersWithQuestionnairesEnabled: this.protocolSubscriptionsMembersWithQuestionnairesEnabled.bind(this)
            }
          },
          groupProtocolSubscriptions: {
            index: this.getAllGroupProtocolSubscriptions.bind(this),
            create: this.createGroupProtocolSubscriptions.bind(this),
            destroy: this.destroyGroupProtocolSubscription.bind(this)
          },
          notebook: {
            run: this.runNotebook.bind(this)
          },
          voucher: {
            redeem: this.redeemVoucher.bind(this)
          },
          deleteGroupAnnouncement: this.deleteGroupAnnouncement.bind(this),
          addGroupAnnouncement: this.addGroupAnnouncement.bind(this),
          profiles: {
            show: this.getProfile.bind(this),
            searchProfiles: this.searchProfiles.bind(this),
            getProfilePhysAttrs: this.getProfilePhysAttrs.bind(this),
            acceptTermsAndConditions: this.acceptTermsAndConditions.bind(this),
            getMySharing: this.getMySharing.bind(this),
            setMySharing: this.setMySharing.bind(this),
            resetMyCache: this.resetMyCache.bind(this),
            getMyProfileMetadata: this.getMyProfileMetadata.bind(this)
          },
          users: {
            update: this.updateUser.bind(this)
          },
          admin: {
            getStatistics: this.adminGetStatistics.bind(this),
            getStatisticsCurrentYear: this.adminGetStatisticsCurrentYear.bind(this),
            listOauthApplications: this.adminListOauthApplications.bind(this),
            createOauthApplication: this.adminCreateOauthApplication.bind(this),
            destroyUser: this.adminDestroyUser.bind(this),
            confirmUser: this.adminConfirmUser.bind(this),
            listVouchers: this.adminListVouchers.bind(this),
            createVoucher: this.adminCreateVoucher.bind(this),
            destroyVoucher: this.adminDestroyVoucher.bind(this),
            cancelSubscriptions: this.adminCancelSubscriptions.bind(this),
            makeInfiniteSubscription: this.adminMakeInfiniteSubscription.bind(this),
            redeemVoucherCode: this.adminRedeemVoucherCode.bind(this),
            filterSubscriptions: this.adminFilterSubscriptions.bind(this)

          },
          schedules: {
            create: this.createSchedule.bind(this),
            index: this.getSchedules.bind(this),
            monthly: this.getMonthlySchedules.bind(this),
            update: this.updateSchedule.bind(this),
            destroy: this.deleteSchedule.bind(this)
          },
          groupMemberships: {
            accept: this.acceptGroupMembership.bind(this)
          },
          collectionRights: {
            get: this.getCollectionRights.bind(this),
            delete: this.deleteCollectionRight.bind(this),
            create: this.createCollectionRight.bind(this),
            update: this.updateCollectionRight.bind(this)
          },
          getTags: this.getTags.bind(this),
          physiologicalAttributes: {
            index: this.getMyPhysiologicalAttributes.bind(this),
            update: this.updateMyPhysiologicalAttributes.bind(this)
          },
          transfer_requests: {
            index: this.getTransferRequests.bind(this),
            accept: this.acceptTransferRequest.bind(this),
            reject: this.rejectTransferRequest.bind(this),
            create: this.createTransferRequest.bind(this)
          },
          transponders: {
            show: this.getMyTransponder.bind(this),
            delete: this.deleteMyTransponder.bind(this),
            create: this.createMyTransponder.bind(this)
          },
          ...this.state
        }
      }}
      >
        {this.props.children}
      </BackendContext.Provider>
    )
  }
}

export const BackendProvider = WithSession(BackendProviderCls)

export const WithBackend = Component => props => {
  return (
    <BackendContext.Consumer>
      {(context) => (<Component {...context} {...props} />)}
    </BackendContext.Consumer>
  )
}
