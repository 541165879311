import React from 'react'
import ChartView from '../../../data/layout/detail/ChartView'
import Summary from './common/Summary'

export default class VolleyballDetails extends React.Component {
  render () {
    const structuredDataObjects = this.props.data.structured_data_objects

    return (
      <>
        <Summary icon={this.props.icon} metadatum={this.props.metadatum} />
        <div className='timeline-card-visualization'>
          <ChartView data={structuredDataObjects && structuredDataObjects[0]} />
        </div>
      </>
    )
  }
}
