import React from 'react'
import I18n from 'i18n'
import moment from 'moment'
import _ from 'lodash'
import { WithSession } from '../../session/SessionProvider'
import { Link } from 'react-router-dom'
import { momentDateFormat, subscriptionTypes } from '../../common/Constants'
import SubscriptionBanner from './SubscriptionBanner'
import Routes from '../../common/Routes'

const PremiumProfile = (props) => {
  /* eslint-disable camelcase */

  const { myProfile } = props
  const subscription = myProfile?.subscription
  const expires = subscription?.expires

  /* if a user had a subscription in the past, it returns null instead of undefined */
  const type = _.get(subscription, 'type', subscriptionTypes.free) || subscriptionTypes.free
  return (
    <>
      <div className='premium-profile-container welcome-content background-background'>
        <div className='row profile-header center tall-lines'>
          <Link to={Routes.network.index}>
            <SubscriptionBanner subscription={subscription} />
          </Link>
        </div>
        <div className='row center' style={{ marginTop: '0.5rem' }}>
          <div className='col s4 m4 l4' />
        </div>
        {type !== subscriptionTypes.free &&
          <div className='row profile-info-row text-s'>
            <div className='col s12 text-s no-padding'>
              <div className='center-align text-light text-muted'>
                {(expires && I18n.t('premium.profile.expires')) || I18n.t('premium.profile.renew')}
              </div>
              {expires && (
                <div className='center-align text-heavy'>
                  {moment(Date.parse(expires)).format(momentDateFormat)}
                </div>
              )}
            </div>
          </div>}
        <div className='row button-row'>
          <Link to={Routes.premium.subscribe.index}>
            <div className='waves-effect waves-light text-background-color background-accent button-accent text-medium text-m premium-button'>{I18n.t('premium.profile.edit')}</div>
          </Link>
        </div>
      </div>
    </>
  )
  /* eslint-enable camelcase */
}

export default WithSession(PremiumProfile)
