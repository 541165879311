import React, { useState, useEffect, useReducer, useRef } from 'react'
import Header from '../../atomic/atoms/headers/Header'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NewTraining from '../../common/types/NewTraining'
import { UpdateTrainingProps } from './TrainingSchedulesCoachView'
import TrainingSessionsTable, { ROW_HEIGHT } from './TrainingSessionsTable'
import Button from '../../atomic/atoms/Button'
import DeleteButton from '../../atomic/atoms/DeleteButton'
import { AgGridReact } from 'ag-grid-react'

declare let crypto: any

interface Props {
  onCreate: (newTrainings: NewTraining[], onTrainingCreated: (createdTraining: NewTraining) => void) => void
  update: boolean
}

const NewTrainingSessions: React.FC<Props> = ({ onCreate, update }) => {
  const gridRef = useRef<AgGridReact>(null)

  const [selectedTrainings, setSelectedTrainings] = useState<NewTraining[]>([])

  // Can be given as a callback to force an update to this component
  const forceUpdate = useReducer(() => ({}), {})[1] as () => void

  const scheduleNewTraining = (): void => {
    const newTraining: NewTraining = {
      start_date: null,
      location: null,
      trainer: null,
      week_type: null,
      training_type: null,
      session_type: null,
      expected_duration: null,
      expected_rpe: null,
      athletes: { profiles: [] },
      warmup: null,
      send_training_log: false,
      instructions: '',
      instructions_is_link: false,
      identifier: crypto.randomUUID()
    }
    setNewTrainings([...newTrainings, newTraining], forceUpdate)
  }

  useEffect(() => {
    setNewTrainings([], forceUpdate)
  }, [])

  useEffect(() => {
    forceUpdate()
  }, [update])

  const refetch = (): void => {
    // Refetching is not used because this component deals with new training
    // sessions that do not yet exist on the backend.
  }
  const updateTraining = (updateCallbackSuccess: () => void, _updateCallbackError: () => void, training: NewTraining, rowIndex: number | null): void => {
    if (rowIndex !== null && rowIndex + 1 <= newTrainings.length) {
      const newTrainingsDup = [...newTrainings]
      newTrainingsDup[rowIndex] = training
      setNewTrainings(newTrainingsDup, forceUpdate)
    }
    updateCallbackSuccess()
  }
  const updateTrainingProps: UpdateTrainingProps<NewTraining> = {
    updateTraining: updateTraining,
    refetch: refetch
  }

  const handleSelectionChanged = (selectedRows: NewTraining[]): void => {
    setSelectedTrainings(selectedRows)
  }

  const onTrainingCreated = (createdTraining: NewTraining): void => {
    if (createdTraining.identifier === undefined || createdTraining.identifier === '') return

    const newerTrainings = [...newTrainings.filter(training => training.identifier !== createdTraining.identifier)]
    setNewTrainings(newerTrainings, forceUpdate)
  }

  const submitSelection = (): void => {
    onCreate(selectedTrainings, onTrainingCreated)
    // Reset the selection, both here in our cache and in the actual table.
    gridRef.current?.api?.deselectAll()
    setSelectedTrainings([])
  }

  const duplicateSelection = (): void => {
    const newerTrainings = [...newTrainings]
    for (const newTraining of selectedTrainings) {
      const duplicatedTraining = structuredClone(newTraining)
      duplicatedTraining.identifier = crypto.randomUUID()
      newerTrainings.push(duplicatedTraining)
    }
    setNewTrainings(newerTrainings, forceUpdate)
  }

  const deleteSelection = (): void => {
    const identifiersToBeRemoved = selectedTrainings.map(training => training.identifier)
    const newerTrainings = [...newTrainings.filter(training => !identifiersToBeRemoved.includes(training.identifier))]
    setNewTrainings(newerTrainings, forceUpdate)
    setSelectedTrainings([])
  }

  const buttonsDisabled = selectedTrainings.length <= 0
  // 62 = header height, 18 = scrollbar height
  return (
    <>
      <Header isTopHeader>{I18n.t('components.trainings.new_training_sessions')}</Header>
      {newTrainings.length > 0 && (
        <TrainingSessionsTable
          tableData={newTrainings}
          updateTrainingProps={updateTrainingProps}
          height={Math.max(400, 62 + 18 + ROW_HEIGHT * newTrainings.length)}
          handleSelectionChanged={handleSelectionChanged}
          gridRef={gridRef}
        />
      )}
      <div className='pointer' onClick={scheduleNewTraining}>
        <hr />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className='text-primary-color margin-right'><FontAwesomeIcon icon='plus' size='2x' /></div>
          <div className='text-muted emphasized'>{I18n.t('components.trainings.click_to_schedule')}</div>
        </div>
        <hr />
      </div>
      <div className='row'>
        <div className='col s12' style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <DeleteButton isDisabled={buttonsDisabled} onClick={deleteSelection}>{I18n.t('components.trainings.button.delete')}</DeleteButton>
          <Button autowidth isDisabled={buttonsDisabled} onClick={duplicateSelection}>
            <FontAwesomeIcon icon='copy' className='button-icon' />
            {I18n.t('components.trainings.button.duplicate')}
          </Button>
          <Button autowidth onClick={submitSelection} isDisabled={buttonsDisabled}>
            <FontAwesomeIcon icon='plus' className='button-icon' />
            {I18n.t('components.trainings.button.submit')}
          </Button>
        </div>
      </div>
    </>
  )
}

// The new trainings array lives outside of the component so it has instant state updates. This is needed
// when our callback is called that requires updating the state in sequence, but doesn't wait for it
// to update the state.
export let newTrainings: NewTraining[] = []
export const setNewTrainings = (newerTrainings: NewTraining[], cb: () => void): void => {
  newTrainings = newerTrainings
  cb()
}

export default NewTrainingSessions
