import React, { useState, useEffect, FC, ChangeEvent } from 'react'
import I18n from 'i18n'
import Spinner from '../../../../common/Spinner'
import {
  weeklyWellbeing, trainingLog, dailyQuestionnaires
} from 'components/common/Constants'
import moment, { Moment } from 'moment'
import MyReactTooltip from '../../../../MyReactTooltip'
import ComplianceChart from '../common/ComplianceChart'
import GenericPlaceholder from '../../../../common/GenericPlaceholder'
import {
  preprocessTrainingLogs,
  preprocessWellbeingQuestionnaires
} from '../UtilsTS'
import {
  readOwnersProfiles,
  mapProfiles,
  MappedProfile
} from './WellbeingUtils'
import {
  getOpenFromDate,
  isDailyQuestionnaire,
  isTrainingLog,
  isWeeklyWellbeingQuestionnaire
} from 'components/common/utils/QuestionnaireUtilsTS'
import InfoRequiredData, { DASHBOARD_REQUIRED_DATA_TYPES } from '../../common/RequiredDataInfo'
import DailyReadinessToTrainBar from '../weekly/DailyReadinessToTrainBar'
import DateRangePicker from 'components/analysis/dashboards/questionnaire/common/DateRangePicker'
import SelectDropdown from 'components/common/SelectDropdown'
import DailyWellnessBar from 'components/analysis/dashboards/questionnaire/weekly/DailyWellnessBar'
import WeeklyWellnessBar from 'components/analysis/dashboards/questionnaire/weekly/WeeklyWellnessBar'
import TrainingLoadPerWeekGraph from 'components/atomic/organisms/TrainingLoadPerWeekGraph'
import { QuestionnaireObject } from 'components/common/types/questionnaires/QuestionnaireObject'
import { Owner } from 'components/common/types/questionnaires/Owner'
import { TrainingLogRow } from 'components/common/types/questionnaires/TrainingLogRow'
import { DailyLogRow } from 'components/common/types/questionnaires/DailyLogRow'
import { WeeklyWellbeingRow } from 'components/common/types/questionnaires/WeeklyWellbeingRow'
import StressAndMoodWeeklyChart from 'components/analysis/dashboards/questionnaire/weekly/StressAndMoodWeeklyChart'
import StressAndMoodDailyChart from 'components/analysis/dashboards/questionnaire/weekly/StressAndMoodDailyChart'
import WeeklySleepBar from 'components/analysis/dashboards/questionnaire/weekly/WeeklySleepBar'
import DailySleepBar from 'components/analysis/dashboards/questionnaire/weekly/DailySleepBar'
import { useQuestionnaireData } from 'components/analysis/dashboards/questionnaire/Hooks'
import { isEmpty } from 'lodash'

interface Props {
  minDate: Moment
  maxDate: Moment
  setMinDate: Function
  setMaxDate: Function
  smartSetDateRange: Function
}

const DateRangeQuestionnaireDashboard: FC<Props> = (props: Props) => {
  const [questionnaires, setQuestionnaires] = useState<QuestionnaireObject[]>([])
  const [filteredQuestionnaires, setFilteredQuestionnaires] = useState<QuestionnaireObject[]>([])
  const [profiles, setProfiles] = useState<Owner[] | undefined>(undefined)
  const [selectedProfile, setSelectedProfile] = useState<number | undefined>(undefined)
  const [trainingLogRows, setTrainingLogRows] = useState<TrainingLogRow[] | undefined>(undefined)
  const [dailyLogRows, setDailyLogRows] = useState<DailyLogRow[] | undefined>(undefined)
  const [weeklyWellbeingRows, setWeeklyWellbeingRows] = useState<WeeklyWellbeingRow[] | undefined>(undefined)
  const [mappedProfiles, setMappedProfiles] = useState<MappedProfile[]>([])
  const [numberOfPotentialRecords, setNumberOfPotentialRecords] = useState<number>(0)
  const [dataLength, setDataLength] = useState<number | undefined>(0)

  const SELECT_PROFILE_ID = 'daterange-wellbeing-profile-select'

  const { minDate, maxDate } = props

  const queryParams = {
    start_date: minDate.toISOString(),
    end_date: maxDate.toISOString(),
    type: [trainingLog, weeklyWellbeing, ...dailyQuestionnaires]
  }

  const {
    questionnaires: { data: fetchedQuestionnaires, isLoading }
  } = useQuestionnaireData({
    params: queryParams,
    profilesOptions: { enabled: false },
    groupsOptions: { enabled: false },
    questionnairesOptions: { enabled: true },
    protocolSubscriptionsOptions: { enabled: false }
  })

  useEffect(() => {
    if (!isEmpty(fetchedQuestionnaires)) {
      setQuestionnaires(fetchedQuestionnaires)
    }
  }, [fetchedQuestionnaires])

  useEffect(() => {
    setProfiles(readOwnersProfiles(filteredQuestionnaires))
    filterAndProcessTrainingLogs()
    filterAndProcessWellbeingQuestionnaires()
  }, [filteredQuestionnaires])

  useEffect(() => {
    if (profiles != null && profiles.length > 0) {
      const mappedProfiles = mapProfiles(profiles)
      setMappedProfiles(mappedProfiles)
    } else {
      // reset data to undefined to prevent graphs from displaying ..
      // old selected profile data when no profiles are available anymore
      setWeeklyWellbeingRows(undefined)
      setDailyLogRows(undefined)
    }
  }, [profiles])

  useEffect(() => {
    if (!isEmpty(mappedProfiles) && !mappedProfiles.some(profile => profile.value === selectedProfile)) {
      selectProfile(mappedProfiles[0].value)
    }
  }, [mappedProfiles])

  useEffect(() => {
    calculateComplianceStats()
  }, [dailyLogRows, weeklyWellbeingRows])

  useEffect(() => {
    filterForSelectedDates()
  }, [questionnaires, minDate, maxDate])

  useEffect(() => { // when profile is selected, filter & process
    if (selectedProfile != null) {
      filterAndProcessTrainingLogs()
      filterAndProcessWellbeingQuestionnaires()
    }
  }, [selectedProfile])

  const selectProfile = (selected: number): void => {
    setSelectedProfile(selected)
  }

  const filterForSelectedDates = (): void => {
    // filter questionnaires by min/max date & set list of profiles based on questionnaire owners
    setFilteredQuestionnaires(() => {
      return questionnaires.filter((questionnaire) => {
        const openFromMoment = moment(getOpenFromDate(questionnaire))
        return minDate <= openFromMoment && maxDate >= openFromMoment
      })
    })
  }

  const filterAndProcessTrainingLogs = (): void => {
    const filteredTrainingLogs = filteredQuestionnaires.filter((questionnaire) => {
      return questionnaire.owner.id === selectedProfile && isTrainingLog(questionnaire)
    })

    processTrainingLogs(filteredTrainingLogs)
  }

  const filterAndProcessWellbeingQuestionnaires = (): void => {
    const filteredWellbeingQuestionnaires = filteredQuestionnaires.filter((questionnaire) => {
      return questionnaire.owner.id === selectedProfile && (isDailyQuestionnaire(questionnaire) || isWeeklyWellbeingQuestionnaire(questionnaire))
    })

    processWellbeingQuestionnaires(filteredWellbeingQuestionnaires)
  }

  const processTrainingLogs = (data: QuestionnaireObject[]): void => {
    if (data.length === 0) {
      setTrainingLogRows(undefined)
      return
    }

    const preprocessedTrainingLogs = preprocessTrainingLogs(data) as {
      trainingLogRows: TrainingLogRow[]
    }

    setTrainingLogRows(preprocessedTrainingLogs.trainingLogRows)
  }

  const processWellbeingQuestionnaires = (data: QuestionnaireObject[]): void => {
    if (data.length === 0) {
      setDailyLogRows(undefined)
      setWeeklyWellbeingRows(undefined)
      return
    }
    const preprocessedDailyQuestionnaires = preprocessWellbeingQuestionnaires(data) as {
      dailyLogRows: DailyLogRow[]
      weeklyWellbeingRows: WeeklyWellbeingRow[]
    }

    setDailyLogRows(preprocessedDailyQuestionnaires.dailyLogRows)
    setWeeklyWellbeingRows(preprocessedDailyQuestionnaires.weeklyWellbeingRows)
  }

  const handleSelectProfile = (e: ChangeEvent<HTMLSelectElement>): void => {
    const targetValue = parseInt(e.target.value, 10)

    // Prevent selectedProfile from being set to NaN, as this triggers a warning with the select dropdown
    // Passing -1 to selectProfile here instead will trigger a default to the first person in the dropdown profiles
    if (isNaN(targetValue)) return selectProfile(-1)
    else {
      selectProfile(targetValue)
    }
  }

  const calculateComplianceStats = (): void => {
    let tempMaxDate: moment.Moment = moment(props.maxDate).endOf('day')
    const currentDate: moment.Moment = moment().endOf('day')

    // This is to avoid that, if you select a date in the future (which you cannot have filled questionnaires for),
    // it doesn't take the future days/weeks into account for the compliance calculation.
    if (tempMaxDate.isAfter(currentDate)) {
      tempMaxDate = currentDate
    }

    // The Moment.js .diff() function does not include the end date in its calculation.
    // Since we want to include both the start and end days, we need to add 1 to the result.
    let potentialRecords: number = tempMaxDate.diff(minDate, 'days') + 1
    let recordsLength: number | undefined = dailyLogRows?.length

    if (dataIsWeeklyWellness) {
      potentialRecords = Math.ceil(potentialRecords / 7)
      recordsLength = weeklyWellbeingRows?.length
    }

    setNumberOfPotentialRecords(potentialRecords)
    setDataLength(recordsLength)
  }

  const dropdownContent = !isEmpty(profiles)
    ? mappedProfiles
    : [{
        name: I18n.t('components.dashboards.select_dropdown.no_data'),
        value: '',
        disabled: true
      }] as Array<{ name: string, value: string | number, disabled?: boolean }>

  const dataIsWeeklyWellness: boolean = weeklyWellbeingRows != null ? weeklyWellbeingRows.length > 0 : false

  if (isLoading) {
    return (
      <div>
        <div className='row' />
        <div className='row'>
          <Spinner ready={false} transparent />
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className='row'>
        <div className='col s12 text-xl text-primary-color text-heavy'>
          {I18n.t('components.dashboards.questionnaire.monthly.title')}
          <InfoRequiredData
            id='dataset-types-info'
            dataTypes={DASHBOARD_REQUIRED_DATA_TYPES.weekly_questionnaire}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col s12 m6'>
          <SelectDropdown
            id={SELECT_PROFILE_ID}
            value={selectedProfile}
            onChange={handleSelectProfile}
            label={I18n.t('components.dashboards.questionnaire.select_profile')}
            placeholder={I18n.t('components.dashboards.select_dropdown.athlete')}
            content={dropdownContent}
          />
        </div>
        <div className='col s12 m6'>
          <DateRangePicker {...props} />
        </div>
      </div>
      <div className='row'>
        <div className='col s12 l6'>
          <div className='chart-container'>
            {dailyLogRows == null && <GenericPlaceholder />}
            {dailyLogRows != null && (
              <>
                <div className='highcharts-plot-title-container'>
                  <div className='highcharts-plot-title'>
                    {I18n.t('components.dashboards.questionnaire.weekly.compliance.title')}
                  </div>
                  <p>{I18n.t('components.dashboards.questionnaire.weekly.compliance.description')}
                  </p>
                </div>
                <div className='compliance-chart'>
                  <ComplianceChart
                    filledIn={dataLength}
                    total={numberOfPotentialRecords}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className='col s12 l6'>
          <div className='chart-container'>
            {// if weekly wellness data is present, render chart for weekly data, if not, render daily log chart
              dataIsWeeklyWellness
                ? <WeeklyWellnessBar weeklyWellbeingRows={weeklyWellbeingRows} withSleepDuration />
                : <DailyWellnessBar dailyLogRows={dailyLogRows} withRestingHr withSleepDuration />
            }
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 l6'>
          <div className='chart-container'>
            {// if weekly wellness data is present, render chart for weekly data, if not, render daily log chart
              dataIsWeeklyWellness
                ? <StressAndMoodWeeklyChart weeklyWellbeingRows={weeklyWellbeingRows} />
                : <StressAndMoodDailyChart dailyLogRows={dailyLogRows} />
            }
          </div>
        </div>
        <div className='col s12 l6'>
          <div className='chart-container'>
            {// if weekly wellness data is present, render chart for weekly data, if not, render daily log chart
              dataIsWeeklyWellness
                ? <WeeklySleepBar weeklyWellbeingRows={weeklyWellbeingRows} />
                : <DailySleepBar dailyLogRows={dailyLogRows} />
            }
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 l6'>
          <div className='chart-container'>
            {selectedProfile != null &&
              <TrainingLoadPerWeekGraph
                start={minDate.toDate()} end={maxDate.toDate()} profileId={selectedProfile}
                withTrainingSatisfaction trainingLogRows={trainingLogRows}
              />}
          </div>
        </div>
        <div className='col s12 l6'>
          <div className='chart-container'>
            <DailyReadinessToTrainBar dailyLogRows={dailyLogRows} />
          </div>
        </div>
      </div>
      <MyReactTooltip id='questionnaire-tooltip' effect='solid' className='wider-tooltip' />
    </div>
  )
}

export default DateRangeQuestionnaireDashboard
