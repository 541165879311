import React from 'react'
import I18n from 'i18n'
import Field from '../common/Field'
import { round } from '../../../../common/Math'
import { getWellnessScore } from '../../../../common/utils/QuestionnaireUtils'

export default class DailyQuestionnaire extends React.Component {
  extraClass (wellness) {
    if (wellness <= 40) return 'highcharts-red'
    if (wellness >= 80) return 'highcharts-green'
    if (wellness > 40 && wellness < 80) return 'highcharts-orange'
    return undefined
  }

  render () {
    const values = this.props.data.values
    const wellnessP = getWellnessScore(values)
    const wellness = round(wellnessP, 0)
    const sleepDuration = `${values.v2_uren}h${values.v2_minuten}m`
    return (
      <>
        <div className='sport-fields'>
          <Field title={I18n.t('questionnaires.daily_questionnaire.readiness_to_train')} value={`${parseFloat(values.v7).toFixed(1)} ${I18n.t('questionnaires.daily_questionnaire.out_of')} 5.0`} />
          <Field title={I18n.t('questionnaires.daily_questionnaire.sleep_duration')} value={sleepDuration} />
          <Field title={I18n.t('questionnaires.daily_questionnaire.sick')} value={I18n.t(`questionnaires.daily_questionnaire.translated_values.v9.answer_${values.v9}`)} />
          <Field title={I18n.t('questionnaires.daily_questionnaire.injured')} value={I18n.t(`questionnaires.daily_questionnaire.translated_values.v10.answer_${values.v10}`)} />
          <Field title={I18n.t('questionnaires.daily_questionnaire.wellness')} value={`${wellness}%`} extraClasses={this.extraClass(wellnessP)} />
        </div>
      </>
    )
  }
}
