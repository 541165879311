import React from 'react'
import TimeAgo from 'javascript-time-ago'
import moment from 'moment'
import I18n from 'i18n'
import en from 'javascript-time-ago/locale/en'
import nl from 'javascript-time-ago/locale/nl'
import { momentFullDateFormatWithTimeAndDay } from 'components/common/Constants'
TimeAgo.addLocale(en)
TimeAgo.addLocale(nl)

export const TimeAgoElement = (props) => {
  return (
    <span title={moment(props.date).format(momentFullDateFormatWithTimeAndDay)}>{TimeAgoPlain(props.date)}</span>
  )
}

export const TimeAgoPlain = (date) => {
  const timeAgo = new TimeAgo(I18n.locale)
  return (
    timeAgo.format(date)
  )
}
