import React, { useEffect, useState } from 'react'
import I18n from 'i18n'
import { WithSession } from '../../session/SessionProvider'
import { WithModal } from '../../modal/ModalProvider'
import { withRouter, useLocation } from 'react-router-dom'
import Spinner from '../../common/Spinner'
import _ from 'lodash'
import Routes from '../../common/Routes'
import { subscriptionTypes } from '../../common/Constants'
import moment from 'moment'
import { trans } from '../../common/Utils'
import { fetchAmount } from './PaymentUtils'
import axios from 'axios'
// TODO Other components pass "profile" to this component, but it does not use that prop at all
const SubscriptionModal = (props) => {
  const { title, subtitleOne, subtitleTwo, listItems, buttonText, extra, subscriptionType, cancel } = props
  const href = cancel ? '/api/v1/checkouts/cancel' : `/api/v1/checkouts?subscription_type=${subscriptionType}`
  return (
    <div className='checkout-container container'>
      <div className='row'>
        <div className='col s12 center-align text-xxl text-primary-color text-bold padding-top'>
          {title}
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          {subtitleOne}
          {subtitleTwo}
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <ul className='collection'>
            {listItems.map((item, i) => item)}
          </ul>
        </div>
      </div>
      {extra}
      <div className='subscription-button-container container center-align text-bold'>
        <button
          onClick={() => {
            window.location.href = href
          }}
          className='waves-effect waves-light text-background-color text-medium text-m premium-button background-accent button-accent max-width-250'
        >
          {buttonText}
        </button>
      </div>
    </div>
  )
}

const TrialAuthorisation = (props) => {
  const subTranslation = I18n.t(`premium.types.${props.subscription.type}`)
  const trialEnds = moment(new Date(props.subscription.expires))

  const content = {
    title: subTranslation,
    subtitleOne: <p className='text-l' dangerouslySetInnerHTML={{ __html: trans('premium.trial_auth.subtitle_one', { subscriptionType: subTranslation, date: trialEnds.format('MMM Do YYYY') }) }} />,
    subtitleTwo: <p className='text-m'>{I18n.t('premium.trial_auth.subtitle_two')}</p>,
    listItems: [
      <li key={0} className='collection-item no-margin' dangerouslySetInnerHTML={{ __html: trans('premium.trial_auth.fee', { price: '0,01' }) }} />,
      <li key={1} className='collection-item no-margin' dangerouslySetInnerHTML={{ __html: trans('premium.trial_auth.installment', { price: props.price, date: trialEnds.format('MMM Do YYYY') }) }} />,
      <li key={2} className='collection-item no-margin' dangerouslySetInnerHTML={{ __html: trans('premium.trial_auth.cancel_anytime') }} />,
      <li key={3} className='collection-item no-margin' dangerouslySetInnerHTML={{ __html: trans('premium.terms', { url: Routes.static.termsAndConditionsLatest }) }} />
    ],
    buttonText: I18n.t('premium.trial_auth.button_text')
  }

  return <SubscriptionModal {...content} profile={props.profile} subscriptionType={props.subscriptionType} />
}

const CancelSubscription = (props) => {
  const subTranslation = trans(`premium.types.${props.subscription.type}`)

  const content = {
    title: subTranslation,
    subtitleOne: <p className='text-l' dangerouslySetInnerHTML={{ __html: trans('premium.cancel.subtitle_one', { subscriptionType: subTranslation }) }} />,
    subtitleTwo: <p className='text-m'>{I18n.t('premium.cancel.subtitle_two')}</p>,
    listItems: [
      <li key={0} className='collection-item no-margin' dangerouslySetInnerHTML={{ __html: trans('premium.cancel.lose_access') }} />,
      <li key={1} className='collection-item no-margin' dangerouslySetInnerHTML={{ __html: trans('premium.cancel.premium_active') }} />,
      <li key={2} className='collection-item no-margin' dangerouslySetInnerHTML={{ __html: trans('premium.cancel.renew_anytime') }} />
    ],
    extra: (
      <div className='row'>
        <div className='col s12'>
          <textarea id='reason' className='materialize-textarea' />
          <label htmlFor='reason'>{I18n.t('premium.cancel.reason_label')}</label>
        </div>
      </div>
    ),
    buttonText: I18n.t('premium.cancel.button_text')
  }

  return <SubscriptionModal {...content} cancel profile={props.profile} subscriptionType={props.subscriptionType} />
}

const SubscriptionUpgrade = (props) => {
  const oldSubTranslation = I18n.t(`premium.types.${props.subscription.type}`)
  const newSubTranslation = I18n.t(`premium.types.${props.subscriptionType}`)

  const content = {
    title: newSubTranslation,
    subtitleOne: <p className='text-l' dangerouslySetInnerHTML={{ __html: trans('premium.upgrade.subtitle_one', { oldSubscriptionType: oldSubTranslation, newSubscriptionType: newSubTranslation }) }} />,
    subtitleTwo: <p className='text-m' dangerouslySetInnerHTML={{ __html: trans('premium.upgrade.subtitle_two', { newSubscriptionType: newSubTranslation }) }} />,
    listItems: [
      <li key={1} className='collection-item no-margin'>{I18n.t('premium.upgrade.fee')}</li>,
      <li key={2} className='collection-item no-margin' dangerouslySetInnerHTML={{ __html: trans('premium.upgrade.installment', { price: props.price, date: moment(new Date()).format('MMM Do YYYY') }) }} />,
      <li key={3} className='collection-item no-margin' dangerouslySetInnerHTML={{ __html: trans('premium.upgrade.cancel_anytime') }} />,
      <li key={4} className='collection-item no-margin' dangerouslySetInnerHTML={{ __html: trans('premium.terms', { url: Routes.static.termsAndConditionsLatest }) }} />
    ],
    buttonText: I18n.t('premium.upgrade.button_text')
  }
  props.subscription.type !== 'free' && content.listItems.unshift(<li key={0} className='collection-item no-margin' dangerouslySetInnerHTML={{ __html: trans('premium.upgrade.upgrade') }} />)

  return <SubscriptionModal {...content} profile={props.profile} subscriptionType={props.subscriptionType} />
}

const TrialSubscription = (props) => {
  const subTranslation = I18n.t(`premium.types.${props.subscriptionType}`)

  const content = {
    title: subTranslation,
    subtitleOne: <p className='text-l' dangerouslySetInnerHTML={{ __html: trans('premium.new_trial.subtitle_one', { subscriptionType: subTranslation }) }} />,
    subtitleTwo: <p className='text-m' dangerouslySetInnerHTML={{ __html: trans('premium.new_trial.subtitle_two', { subscriptionType: subTranslation }) }} />,
    listItems: [
      <li key={0} className='collection-item no-margin'>{I18n.t('premium.new_trial.fee', { price: props.price })}</li>,
      <li key={1} className='collection-item no-margin' dangerouslySetInnerHTML={{ __html: trans('premium.new_trial.installment', { price: props.price, date: moment(new Date()).add(3, 'months').format('MMM Do YYYY') }) }} />,
      <li key={2} className='collection-item no-margin' dangerouslySetInnerHTML={{ __html: trans('premium.new_trial.cancel_anytime') }} />,
      <li key={3} className='collection-item no-margin' dangerouslySetInnerHTML={{ __html: trans('premium.terms', { url: Routes.static.termsAndConditionsLatest }) }} />
    ],
    buttonText: I18n.t('premium.new_trial.button_text')
  }

  return <SubscriptionModal {...content} profile={props.profile} subscriptionType={props.subscriptionType} />
}

const AuthorisationModal = withRouter(WithSession((props) => {
  const { myProfile } = props
  const typeFromProps = _.get(props, 'subscription', 'type')

  /* TODO: use import queryString from 'query-string' instead of useLocation */
  const type = props.type || useLocation().search.replace('?', '').split(/[=&]/)[1]
  const subscription = props.subscription || _.get(myProfile, 'subscription')
  const [price, setPrice] = useState('...')
  useEffect(() => {
    typeFromProps !== subscriptionTypes.free && fetchAmount(type, props.sessionToken).then((response) => {
      setPrice(response.data / 100)
    })
  }, [typeFromProps])

  if (!myProfile || (I18n.locale !== myProfile.locale) || !subscription || !type) {
    return <Spinner />
  }

  /* eligible for trial means no active subscription, so show active button with link to activate trial */
  if (_.get(subscription, 'eligible_for_trial')) {
    return <TrialSubscription subscription={subscription} profile={myProfile} subscriptionType={type} price={price} />
  }
  /* either current subscription is invalid, or button is for a different subscription type, so show upgrade now button */
  if (type !== 'free' && !(type === _.get(subscription, 'type') && _.get(subscription, 'valid'))) {
    return <SubscriptionUpgrade subscription={subscription} profile={myProfile} subscriptionType={type} price={price} />
  }
  /* current subscription is renewable, and previous if clauses ensured that the button is for the current subscription, so show cancel button */
  if (subscription.renewable) {
    return <CancelSubscription subscription={subscription} profile={myProfile} subscriptionType={type} price={price} />
  }
  /* subscription is a trial without authorisation (currently not renewable) or canncelled */
  return <TrialAuthorisation subscription={subscription} profile={myProfile} subscriptionType={type} price={price} />
}))

const parseLocation = (props) => {
  const locationParams = {}
  const params = useLocation().search.replace('?', '').split(/[=&]/)
  for (let i = 0; i < params.length; i += 2) {
    locationParams[params[i]] = params[i + 1]
  }
  return locationParams
}

const StatusModal = withRouter(WithSession((props) => {
  /* eslint-disable camelcase */
  const { status, product_slug } = parseLocation()
  const messages = {
    confirmed: trans('premium.status.confirmed', { type: trans(`premium.types.${product_slug}`) }),
    pending: trans('premium.status.pending'),
    error: trans('premium.status.error'),
    failed: trans('premium.status.failed'),
    failedTitle: trans('premium.status.failed_title'),
    defaultTitle: trans('premium.status.default_title')
  }
  /* eslint-enable camelcase */

  if (!props.sessionToken) {
    return <Spinner transparent />
  }

  const [isPolling, setIsPolling] = useState(false)
  const [message, setMessage] = useState(messages.pending)
  const [attempts, setAttempts] = useState(30) // 30 * 5 seconds = poll for 2,5 minutes
  useEffect(() => {
    if (!isPolling && (status === 'completed' || status === 'pending')) {
      axios({
        method: 'GET',
        url: '/api/v1/checkouts/payment_completed',
        headers: {
          Authorisation: props.sessionToken
        }
      }).then((res) => {
        (res.data.retry && !res.data.is_completed && attempts > 0 && setTimeout(() => { setAttempts(attempts - 1); setIsPolling(false) }, 5000)) || setMessage(messages.error)
        res.data.is_completed && setMessage(messages.confirmed) && props.refetchSession()
      }).catch((res) => {
        setMessage(messages.error)
      })
      setIsPolling(true)
    }

    status === 'failed' && setMessage(messages.failed)
  }, [isPolling])

  return (
    <div className='container center-align'>
      <div className='row'>
        <div className='col s12 text-l'>
          <p className='text-xl text-primary-color'>
            {((status === 'failed' || message === messages.error) && messages.failedTitle) || messages.defaultTitle}
          </p>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 text-m'>
          <p className='text-l' dangerouslySetInnerHTML={{ __html: message }} />
        </div>
        {message === messages.pending && (
          <div className='col s12'>
            <Spinner transparent />
          </div>
        )}
      </div>
    </div>
  )
}))

const UrlModal = WithModal((props) => {
  useEffect(() => {
    props.setModalComponent(props.component, { ...props.modalProps, hide: props.hide })
  }, [props.component, props.modalProps])

  return <></>
})

export {
  AuthorisationModal,
  StatusModal,
  UrlModal
}
