import React from 'react'
import _ from 'lodash'

export default class StatsCard extends React.Component {
  render () {
    const { title, playerNames, score, id } = this.props
    return (
      <div className='stats-card' id={id}>
        <div className='stats-card-header'>
          <div className='row'>
            <div className='col s12'>
              <h5>{title}</h5>
            </div>
          </div>
        </div>
        <div className='stats-card-body'>
          <div className='row'>
            <div className='col s12'>
              <p>{score}</p>
            </div>
          </div>
        </div>
        <div className='stats-card-footer'>
          <div className='row'>
            <div className='col s12'>
              {_.map(playerNames,
                (playerName) => {
                  return (
                    <p key={playerName}>{playerName}</p>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
