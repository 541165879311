import React, { ComponentType, useCallback, useEffect, useState } from 'react'
import { RouteComponentProps, StaticContext } from 'react-router'
import ReactMarkdown from 'react-markdown'
import Routes from 'components/common/Routes'
import remarkGfm from 'remark-gfm'
import { HeadingProps } from 'react-markdown/lib/ast-to-react'
import { slugify } from 'components/common/TypedUtils'
import SdvLogo from '../../images/sdv-logo.svg'
import { ReactNode } from 'react-markdown/lib/react-markdown'

type RouteProps = RouteComponentProps<
{
  [x: string]: string | undefined
},
StaticContext,
unknown
>

export default (_props: RouteProps): ReactNode => {
  const [md, setMd] = useState<string>('')

  useEffect(() => {
    console.debug(
      'fetching developer docs from',
      Routes.developer.gettingStartedSource
    )
    fetch(Routes.developer.gettingStartedSource)
      .then((result) => {
        if (result.ok) {
          result
            .text()
            .then(setMd)
            .catch((e) => console.warn(e))
        }
      })
      .catch((e) => console.warn(e))
  }, [])

  const anchorizeHeading = useCallback(
    ({ level, ...heading }: React.PropsWithChildren<HeadingProps>) => {
      let Heading: ComponentType | keyof JSX.IntrinsicElements
      // The switch is needed to bound the range of level values so typescript understand this
      // is a valid html tag
      switch (level) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          Heading = `h${level}`
          break
        default:
          Heading = 'h6'
      }
      const slug = slugify(heading.children.toString())
      return (
        <a id={slug} href={`#${slug}`}>
          <Heading {...heading} />
        </a>
      )
    },
    []
  )

  return (
    <div className='static-page-wrapper'>
      <div className='row'>
        <div className='col s12 markdown-wrapper'>
          <div className='logo-wrapper'>
            <img className='sdv-logo' src={SdvLogo as unknown as string} />
          </div>
          <ReactMarkdown
            components={{
              h1: anchorizeHeading,
              h2: anchorizeHeading,
              h3: anchorizeHeading,
              h4: anchorizeHeading,
              h5: anchorizeHeading,
              h6: anchorizeHeading
            }}
            remarkPlugins={[remarkGfm]}
          >
            {md}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  )
}
