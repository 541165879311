import React from 'react'

/**
 * Renders a generic table given a title, a list of columns and a list of rows
 * length(columns) must be equal to length(first row). columns and title are optional
 */
const Table = ({ title, columns, rows }) => {
  // If we don't have columns and rows, or if the rows are empty, don't render
  if ((!rows && !columns) || (rows && rows.length === 0)) {
    return <></>
  }
  if (columns && columns.length > 0 && rows && rows.length > 0 && rows[0].length !== columns.length) {
    console.log("Generic Table expects the length of 'columns' to match the number of elements in each row")
    return <></>
  }

  return (
    <div className='row'>
      <div className='col s12 csv-table-container'>
        {title && (<h2 className='text-l text-muted'>{title}</h2>)}
        <table className='responsive-table'>
          {columns && columns.length > 0 && (
            <thead>
              <tr>
                {columns.map((col, idx) => <td key={`${col}-${idx}`}><b>{col}</b></td>)}
              </tr>
            </thead>
          )}
          {rows && (
            <tbody>
              {rows.map((row, rowIdx) => (
                <tr key={`row-${rowIdx}`}>
                  {row.map((col, colIdx) => (
                    <td key={`col-${colIdx}`}>{(typeof (col) === 'number' && parseFloat(col.toFixed(4))) || col}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  )
}

export default Table
