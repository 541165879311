import React from 'react'
import I18n from 'i18n'
import Field from '../common/Field'
import { WeeklyWellbeingData } from '../../../../common/types/questionnaires/WeeklyWellbeingData'

interface Props {
  data: WeeklyWellbeingData
}

const WeeklyWellbeingQuestionnaire: React.FC<Props> = (props) => {
  const values = props.data.values
  const sleepDuration = `${values.v2_uren}h${values.v2_minuten}m`
  return (
    <>
      <div className='sport-fields'>
        <Field
          title={I18n.t('questionnaires.weekly_wellbeing_questionnaire.sleep_quality')}
          value={`${parseFloat(values.v1).toFixed(1)} ${I18n.t('questionnaires.daily_questionnaire.out_of') as string} 5.0`}
        />
        <Field title={I18n.t('questionnaires.weekly_wellbeing_questionnaire.sleep_duration')} value={sleepDuration} />
        <Field
          title={I18n.t('questionnaires.weekly_wellbeing_questionnaire.fatigue')}
          value={`${parseFloat(values.v3).toFixed(1)} ${I18n.t('questionnaires.daily_questionnaire.out_of') as string} 5.0`}
        />
        <Field
          title={I18n.t('questionnaires.weekly_wellbeing_questionnaire.stress')}
          value={`${parseFloat(values.v4).toFixed(1)} ${I18n.t('questionnaires.daily_questionnaire.out_of') as string} 5.0`}
        />
        <Field
          title={I18n.t('questionnaires.weekly_wellbeing_questionnaire.mood')}
          value={`${parseFloat(values.v5).toFixed(1)} ${I18n.t('questionnaires.daily_questionnaire.out_of') as string} 5.0`}
        />
      </div>
    </>
  )
}

export default WeeklyWellbeingQuestionnaire
