import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import I18n from 'i18n'
import _ from 'lodash'
import GenericPlaceholder from '../../../../common/GenericPlaceholder'
import 'chartjs-plugin-annotation'
import { Scatter } from 'react-chartjs-2'
import {
  chartjsCrosshairProperties,
  chartjsDefaultOptions,
  chartjsScaleLabelProperties,
  chartjsTitleProperties, hcLightGreen,
  momentDateFormat
} from '../../../../common/Constants'
import Variables from '../../../../../stylesheets/variables.module.scss'
import { smartRounding } from '../../../../common/Math'
import { hexWithAlpha } from '../../../../common/Utils'
const { primaryColor, green, accentColor, secondaryColor } = Variables

function tooltipTitle (tooltipItems) {
  moment(tooltipItems[0].xLabel).format(momentDateFormat)
}

function tooltipLabel (tooltipItem, data) {
  const datasetLabel = data.datasets[tooltipItem.datasetIndex].label
  const value = tooltipItem.datasetIndex === 0 ? tooltipItem.yLabel : smartRounding(tooltipItem.yLabel, 2)
  return `${datasetLabel}: ${value}`
}

function legendLabelFilter (legendItem) {
  return legendItem.datasetIndex < 2
}

const ACWRChart = ({ acwr }) => {
  if (_.get(acwr, 'dates.length', 0) === 0) {
    return (
      <GenericPlaceholder
        title={I18n.t('components.dashboards.multi_activity.acwr.title')}
        message={I18n.t('components.dashboards.multi_activity.acwr.not_enough_data')}
      />
    )
  }

  const srpe = acwr.s_rpe.map(srpe => srpe === 0 ? null : srpe)
  const maxSrpe = _.max(acwr.s_rpe)
  const paddedMax = maxSrpe + maxSrpe * 0.1

  const data = {
    labels: acwr.dates,
    datasets: [
      {
        type: 'scatter',
        data: srpe,
        backgroundColor: primaryColor,
        yAxisID: 'srpe',
        label: 'sRPE'
      }, {
        type: 'line',
        data: acwr.acwr,
        fill: false,
        borderColor: secondaryColor,
        backgroundColor: secondaryColor,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'acwr',
        cubicInterpolationMode: 'monotone',
        label: 'ACWR'
      }, {
        type: 'line',
        data: acwr.acute,
        label: 'Acute',
        borderColor: green,
        backgroundColor: green,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'srpe',
        fill: false,
        cubicInterpolationMode: 'monotone',
        showLine: false
      }, {
        type: 'line',
        data: acwr.chronic,
        label: 'Chronic',
        borderColor: accentColor,
        backgroundColor: accentColor,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'srpe',
        fill: false,
        cubicInterpolationMode: 'monotone',
        showLine: false
      }
    ]
  }

  const xAxis = {
    type: 'time',
    scaleLabel: chartjsScaleLabelProperties,
    ticks: {
      fontSize: 17.5
    }
  }

  const acwrAxis = {
    type: 'linear',
    id: 'acwr',
    gridLines: false,
    scaleLabel: {
      ...chartjsScaleLabelProperties,
      labelString: 'ACWR'
    },
    ticks: {
      suggestedMin: 0,
      suggestedMax: 4,
      stepSize: 1
    }
  }

  const srpeAxis = {
    type: 'linear',
    position: 'right',
    id: 'srpe',
    beginAtZero: true,
    scaleLabel: {
      ...chartjsScaleLabelProperties,
      labelString: 'sRPE'
    },
    ticks: {
      stepSize: paddedMax / 4,
      suggestedMax: paddedMax
    }
  }

  const scales = {
    xAxes: [xAxis],
    yAxes: [acwrAxis, srpeAxis]
  }

  const annotation = {
    drawTime: 'beforeDatasetsDraw',
    annotations: [{
      type: 'box',
      yScaleID: 'acwr',
      yMin: 0.8,
      yMax: 1.3,
      backgroundColor: hexWithAlpha(hcLightGreen, 0.6)
    }]
  }

  const tooltips = {
    mode: 'index',
    intersect: false,
    position: 'nearest',
    callbacks: {
      title: tooltipTitle,
      label: tooltipLabel
    }
  }

  const legend = {
    labels: {
      // We want to hide the labels for the chronic and acute datasets since they only show on the tooltip, not on the
      // chart itself.
      filter: legendLabelFilter
    }
  }

  const options = {
    ...chartjsDefaultOptions,
    title: {
      ...chartjsTitleProperties,
      text: I18n.t('components.dashboards.multi_activity.acwr.title')
    },
    scales: scales,
    tooltips: tooltips,
    legend: legend,
    annotation: annotation,
    plugins: { crosshair: { ...chartjsCrosshairProperties } }
  }

  return (
    <Scatter
      data={data}
      options={options}
    />
  )
}

ACWRChart.propTypes = {
  acwr: PropTypes.shape({
    dates: PropTypes.arrayOf(PropTypes.string),
    acwr: PropTypes.arrayOf(PropTypes.number),
    acute: PropTypes.arrayOf(PropTypes.number),
    chronic: PropTypes.arrayOf(PropTypes.number),
    s_rpe: PropTypes.arrayOf(PropTypes.number)
  })
}

export default ACWRChart
