import React, { useEffect, useState } from 'react'
import CalendarMonthView from 'react-calendar-month-view'
import moment from 'moment'
import { useMonthlySchedules } from '../../backend/Queries'
import TrainingDTO from '../../common/types/DTOs/TrainingDTO'
import { MatchSessionTypes, momentTimeFormat } from '../../common/Constants'
import Spinner from '../../common/Spinner'
import { translateTrainingType } from './TrainingSessionsTable'
import ReactTooltip from 'react-tooltip'
import { useHistory } from 'react-router-dom'
import MyReactTooltip from '../../MyReactTooltip'
import classNames from 'classnames'

const TrainingSchedulesCalendarView: React.FC = () => {
  const [startDate, setStartDate] = useState<Date>(moment().startOf('month').toDate())

  const history = useHistory()
  const { data: monthlySchedules = [], refetch, isSuccess, isError } = useMonthlySchedules(startDate)

  useEffect(() => {
    // There is no reason why the fetch should fail other than connectivity issues. Therefore, if the request fails
    // we ignore it (using void). To the user it should look no different. Because we use react-query, it always does
    // multiple requests so if one of them fails it'll automatically get retried.
    void refetch()
  }, [startDate])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [monthlySchedules])

  const playerList = (training: TrainingDTO): string | undefined => {
    if (training.athletes.length === 0) return undefined

    return `${training.athletes.map(athlete => `${athlete.first_name ?? ''} ${athlete.last_name ?? ''}`).join(', ')}`
  }

  const trainingTimeString = (training: TrainingDTO): string => {
    return moment(training.start_date).format(momentTimeFormat)
  }

  const renderDay = (day: string): JSX.Element | null => {
    const dayMoment = moment(day)
    const beginDay = moment(dayMoment.startOf('day').toDate())
    const endDay = moment(dayMoment.endOf('day').toDate())

    const onThisDay = monthlySchedules.filter(training => {
      const trainingStartDate = moment(training.start_date)
      return trainingStartDate >= beginDay && trainingStartDate <= endDay
    })
    if (onThisDay.length === 0) return null

    const matchDay = determineMatchDay(onThisDay)
    return (
      <div className={classNames('calendar-cell', { match: matchDay })}>
        {onThisDay.slice(0, 4).map(training => (
          <div
            className='training-session-calendar'
            key={training.id}
            data-for='training-sessions-tooltip'
            data-tip={playerList(training)}
            onClick={() => history.replace('/training-scheduler', { onDate: training.start_date })}
          >
            {trainingTimeString(training)}{' '}
            <span className='hide-on-phone'>
              {translateTrainingType(training.training_type)} ({training.location})
            </span>
          </div>
        ))}
        {onThisDay.length > 4 && (
          <span className='hide-on-phone'>+{onThisDay.length - 4} more...</span>
        )}
      </div>
    )
  }

  return (
    <>
      {!isError && <Spinner ready={isSuccess} transparent />}
      <div style={{ display: 'flex' }} className='calendarmonthview-container'>
        <CalendarMonthView
          renderDay={renderDay}
          width='100%'
          onMonthChange={(startDate: string) => setStartDate(moment(startDate).startOf('month').toDate())}
          dayTextStyle={{
            width: '25px',
            height: '25px',
            margin: '0',
            left: 'unset',
            top: '0',
            right: '0'
          }}
          inactiveDayStyle={{
            backgroundColor: '#F8F8F8'
          }}
          activeDayStyle={{
            backgroundColor: '#F0F0F0',
            borderWidth: '0'
          }}
        />
        <MyReactTooltip className='high-z' effect='solid' id='training-sessions-tooltip' />
      </div>
    </>
  )
}

// Determines if the day is a match day based on the given training sessions on this day.
const determineMatchDay = (onThisDay: TrainingDTO[]): boolean => {
  for (const training of onThisDay) {
    if (MatchSessionTypes.includes(training.session_type)) {
      return true
    }
  }
  return false
}

export default TrainingSchedulesCalendarView
