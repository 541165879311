import React from 'react'
import ColumnChartView from '../../../data/layout/detail/ColumnChartView'
import Summary from '../../../timeline/layout/details/common/Summary'
import PropTypes from 'prop-types'

class VolleyballJumpDetails extends React.Component {
  render () {
    const { icon, metadatum, compact, data } = this.props

    const structuredDataObjects = data.structured_data_objects
    // Sanity check to make sure the timeline entry does not crash if no data is provided
    const sessionType = structuredDataObjects[0].data_rows[0].session_type
    let totalJumps = 0
    let differentMatchTypes = []

    structuredDataObjects[0].data_rows.forEach((elem) => {
      totalJumps += elem.sprong_n
      differentMatchTypes = differentMatchTypes.concat(elem.type)
    })
    differentMatchTypes = [...new Set(differentMatchTypes)].join(', ')

    const defaultProps = { icon: icon, metadatum: metadatum, compact: compact }
    return (
      <>
        <Summary {...defaultProps} fields={{ totalJumps, differentMatchTypes, sessionType }} />
        {!compact && (
          <div className='timeline-card-visualization'>
            <ColumnChartView data={structuredDataObjects[0]} />
          </div>
        )}
      </>
    )
  }
}

VolleyballJumpDetails.propTypes = {
  // Summary as processed by useSummaryStringUnits
  data: PropTypes.object,
  metadatum: PropTypes.object,
  compact: PropTypes.bool,
  icon: PropTypes.string
}

export default VolleyballJumpDetails
