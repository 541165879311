import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import SelectDropdown from '../../../common/SelectDropdown'
import Label from '../Label'

// TODO: refactor SelectDropdown so that all the labels/errors/extra stuff beside the actual dropdown are moved here.
// WARNING: This component is uncontrolled
export const DropdownInput = (props) => {
  const {
    id,
    className = '',
    label,
    placeholder,
    content,
    defaultValue,
    isMultipleChoice,
    disabled,
    value,
    onChange = () => {}
  } = props

  const inputHandlerSingleChoice = (e) => {
    // This avoids triggering the onChange when no user input was added
    if (!e?.target?.value) return

    onChange(e?.target?.value)
  }
  const inputHandlerMultipleChoice = (e) => {
    // This avoids triggering the onChange when no user input was added
    if (!e?.target?.value) return

    const selectedValues = _.filter(e?.target?.options, option => option.selected).map(option => option.value)
    onChange(selectedValues)
  }

  const inputHandler = isMultipleChoice ? inputHandlerMultipleChoice : inputHandlerSingleChoice
  return (
    <div className={`input-field ${className}`}>
      <SelectDropdown
        id={id}
        content={content}
        defaultValue={defaultValue}
        isMultipleChoice={isMultipleChoice}
        disabled={disabled}
        onChange={inputHandler}
        placeholder={placeholder}
        value={value}
      />
      {label && <Label isActive text={label} />}
    </div>
  )
}

DropdownInput.propTypes = {
  // Id of the input element
  id: PropTypes.string,
  // Class of the input field container. Useful to define the size of the container in the grid.
  className: PropTypes.string,
  // Label text that shows above the input field
  label: PropTypes.string,
  // TODO implement the optional icon to the left of SelectDropdown
  // Name of the icon that shows to the left of the input element. Should correspond to an Font Awesome icon.
  // icon: PropTypes.string,

  // Placeholder text that shows in the input element before user types anything
  placeholder: PropTypes.string,
  // Options of the dropdown
  content: PropTypes.arrayOf(
    PropTypes.shape({
      // Text to be displayed for that option
      name: PropTypes.string,
      // The value of the option
      value: PropTypes.any
    })
  ),
  // Default option to be shown before anything is selected
  defaultValue: PropTypes.any,
  // Set if dropdown allows to select more than one option
  isMultipleChoice: PropTypes.bool,
  // If set, disables the dropdown
  disabled: PropTypes.bool,
  // Callback function that gets called when the selection options in the dropdown change
  onChange: () => {}
}
