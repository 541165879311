import React, { useEffect, useState } from 'react'
import I18n from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useExpansionHelperHook from 'components/helpers/ExpansionHelper'

// The row that contains this element should have class contains-expand-compress-button
const ExpandCompressButton = (_props) => {
  const [expanded, setExpanded] = useState(false)
  const handleOnClick = _props.handleOnClick
  const propsExpanded = _props.expanded

  // Make sure the expand / contract works
  useExpansionHelperHook(expanded)

  useEffect(() => {
    const resetTabs = setTimeout(() => {
      const elems = document.querySelectorAll('.tabs')
      if (elems && elems.length > 0) { M.Tabs.init(elems) }
    }, 0)
    return () => {
      clearTimeout(resetTabs)
    }
  }, [expanded])

  const handleScale = (e) => {
    e.preventDefault()
    setExpanded(!expanded)
  }

  return (
    <div
      className='scale-icon'
      onClick={handleOnClick || (e => handleScale(e))}
      title={propsExpanded || expanded ? I18n.t('components.dashboards.questionnaire.compress') : I18n.t('components.dashboards.questionnaire.expand')}
    >
      <FontAwesomeIcon icon={propsExpanded || expanded ? 'compress' : 'expand'} size='lg' className='pointer-grow' />
    </div>
  )
}

export default ExpandCompressButton
