import React, { useState, useEffect, useMemo, useContext } from 'react'
import PropTypes from 'prop-types'
import { collectionTypes } from '../../../common/Constants'
import { MyAxios as axios } from '../../../MyAxios'
import { WithSession } from '../../../session/SessionProvider'
import { fileDescription } from '../../../common/Utils'
import I18n from 'i18n'
import _ from 'lodash'
import SelectDropdown from '../../../common/SelectDropdown'
import { FlashMessageContext, WithFlashMessages } from '../../../flashmessages/FlashMessageProvider'

const FileSelector = (props) => {
  const [files, setFiles] = useState([])
  const { flashMessages } = useContext(FlashMessageContext)
  const myProfile = props.myProfile

  const myProfileName = myProfile?.first_name && `${myProfile?.first_name} ${myProfile?.last_name}` // eslint-disable-line camelcase

  const { selectedFileId = '' } = props
  const [selectedFileIdState, setSelectedFileIdState] = useState('')
  const [selectedProfile, setSelectedProfile] = useState('')

  useEffect(() => {
    if (selectedFileId !== '') return
    if (selectedProfile === '' && myProfileName !== undefined) {
      let file = files?.find(f => f.owner === myProfileName)
      // Could be that myProfile has no data but a connection does. In that case we pick the first file of the first
      // connection
      if (!file) file = files?.[0]
      // Sanity check, in case we receive an empty array or a non-array (this should not happen), do not trigger the event
      if (file) setSelectedFile(file)
    }
  }, [myProfileName, files])

  useEffect(() => {
    setSelectedFileId(selectedFileId)
  }, [selectedFileId, files])

  function listFilesByCollectionType () {
    return axios({
      method: 'GET',
      url: '/api/v1/dashboards/available_datasets',
      params: {
        collection_type: props.collectionType
      },
      headers: { Authorization: props.sessionToken }
    })
  }

  function fetchVdo (id) {
    return axios({
      method: 'GET',
      url: `/api/v1/data/${id}`,
      headers: { Authorization: props.sessionToken }
    })
  }

  function handleSelectFile (e) {
    if (!props.onFileSelect) return
    const selectedFile = files.find(f => f.id === parseInt(e.target.value))
    if (!selectedFile) return
    // @Remco I think you added the line bellow to avoid double fetches, but this makes it impossible to select the first
    // entry in the options list after selecting a different one. I think we can live with double fetches for now
    // if (selectedFileIdState === e.target.value) return

    setSelectedFile(selectedFile)
    props.onBeforeFetchVdo && props.onBeforeFetchVdo(e.target.value)
    fetchVdo(selectedFile.id).then(res => {
      const vdo = _.get(res, 'data.versioned_data_object')
      props.onFileSelect(vdo)
    })
  }

  function setSelectedFileId (id) {
    const file = files.find(f => f.id.toString() === id.toString())
    if (file) setSelectedFile(file)
  }

  function setSelectedFile (file) {
    setSelectedProfile(file?.owner || '')
    setSelectedFileIdState(file?.id || '')
    fetchVdo(file.id).then(res => {
      const vdo = _.get(res, 'data.versioned_data_object')
      props.onFileSelect(vdo)
    })
  }

  function handleSelectProfile (e) {
    const file = files.find(f => f.owner === e?.target?.value)
    if (file) {
      setSelectedFile(file)
      props.onProfileSelect({ slug: file.slug, fullName: file.owner })
    }
  }

  useEffect(() => {
    listFilesByCollectionType()
      .then(res => setFiles(res.data))
      .catch(e => {
        console.error(e)
        flashMessages.push(I18n.t('components.dashboards.file_selector.error_retrieving_files'), flashMessages.LONG, flashMessages.ERROR)
      })
  }, [props.collectionType])

  const fileLabel = I18n.t('components.dashboards.file_selector.label')
  const profileLabel = I18n.t('components.dashboards.file_selector.profile')
  const filePlaceholder = I18n.t('components.dashboards.file_selector.default')
  const profileFiles = useMemo(() => (
    files.filter(f => f.owner === selectedProfile).reduce((acc, file) => {
      acc.push({ name: fileDescription(file), value: `${file.id}` })
      return acc
    }, [])
  ), [files, selectedProfile])

  const profiles = useMemo(() => (
    Object.values(files.reduce((acc, file) => {
      acc[file.owner] = { name: file.owner, value: file.owner, icon: file.picture }
      return acc
    }, {}))
  ), [files])

  return (
    <div className='row'>
      <div className='col s6'>
        <SelectDropdown id='profile-selector' value={selectedProfile} onChange={handleSelectProfile} label={profileLabel} placeholder={profileLabel} content={profiles} />
      </div>
      <div className='col s6'>
        <SelectDropdown id='file-selector' value={selectedFileIdState} onChange={handleSelectFile} label={fileLabel} placeholder={filePlaceholder} content={profileFiles} />
      </div>
    </div>
  )
}

FileSelector.propTypes = {
  collectionType: PropTypes.oneOf(Object.values(collectionTypes)).isRequired,
  onFileSelect: PropTypes.func,
  onProfileSelect: PropTypes.func,
  selectedFileId: PropTypes.string
}

export default WithSession(WithFlashMessages(FileSelector))
