import React from 'react'
import _ from 'lodash'
import I18n from 'i18n'
import LineWithConfidenceGraph from '../../../common/graphs/LineWithConfidenceGraph'
import { smartRounding } from '../../../common/Math'
import { WithErrorBoundary } from '../../../errorboundary/ErrorBoundaryProvider'

const PredictionTimelineChart = ({ data }) => {
  // Note that these should be consistent with the values used in fillUpDataArray
  const ACTUAL_AVG7D = 0
  const DATE = 1
  const ERROR_ENTRY = 2
  const PREDICTED_AVG7D = 4
  const NUMBER_OF_DAYS_TO_CUT_OFF = 7

  const renderNoData = () => {
    return <p className='text-s text-muted'>{I18n.t('components.dashboards.steps.predictions.not_available')}</p>
  }

  // This function maps the values of the model to a new array. The reason for
  // this is that the order of the variables is important for how ChartJS
  // interprets the data. The order of the model is stochastic however, so here
  // we map it in order to make sure the ordering is consistent with different
  // inputs.
  const fillUpDataArray = (data) => {
    let result
    return data.map((elem) => {
      result = {
        actual: elem.actual,
        date: elem.date,
        error: elem.error,
        id: elem.id,
        predicted: smartRounding(elem.predicted, 1),
        rowid: elem.rowid
      }
      result['fitbit[dot]day'] = elem['fitbit[dot]day']
      return result
    })
  }

  const splitDataIntoDataArray = (data) => {
    return _(data.slice(NUMBER_OF_DAYS_TO_CUT_OFF, data.length))
      .map(_.values) // map to value arrays
      .unzip() // unzip the arrays
      .value()
  }

  const createErrorBands = (result) => {
    if (result.length < 1) {
      return { hi: [], lo: [] }
    }
    // All entries contain error data, but theres only one needed.
    const error = result[ERROR_ENTRY][0]
    // create the error bands for the predictions
    return result[PREDICTED_AVG7D].reduce((acc, elem) => {
      acc.lo.push(elem - error)
      acc.hi.push(elem + error)
      return acc
    }, { hi: [], lo: [] })
  }

  const createDataSets = (result, bands) => {
    return [
      {
        label: I18n.t('components.dashboards.steps.predictions.label_predicted'),
        data: result[PREDICTED_AVG7D],
        hi: bands.hi,
        lo: bands.lo,
        borderColor: '#000000',
        backgroundColor: '#00000015',
        borderDash: [10, 5]
      },
      {
        label: I18n.t('components.dashboards.steps.predictions.label_actual'),
        data: result[ACTUAL_AVG7D],
        hi: [],
        lo: [],
        borderColor: '#be1c12',
        backgroundColor: 'rgba(221, 55, 173, 1)',
        borderDash: undefined
      }
    ]
  }

  if (_.isEmpty(data)) return renderNoData()
  if (data.length < 1) return renderNoData()

  const preprocessedData = fillUpDataArray(data)
  const result = splitDataIntoDataArray(preprocessedData)
  const bands = createErrorBands(result)
  const datasets = createDataSets(result, bands)

  return (
    <>
      <p className='text-heavy text-l text-muted data-header'>{I18n.t('components.dashboards.steps.predictions.title')}</p>
      <p>{I18n.t('components.dashboards.steps.predictions.subtitle')}</p>
      <LineWithConfidenceGraph datasets={datasets} labels={result[DATE]} />
    </>
  )
}

export default WithErrorBoundary(PredictionTimelineChart, 'components.dashboards.steps.predictions.not_available')
