import NotFoundWrapper from 'components/router/NotFoundWrapper'
import Routes from 'components/common/Routes'
import { Route, RouteComponentProps } from 'react-router'
import React, { ReactElement } from 'react'
import AddTransponder from './layout/AddTransponder'

const TransponderRouter = (): ReactElement => (
  <NotFoundWrapper slug='transponder'>
    <Route exact path={Routes.transponder.add} render={(props: RouteComponentProps) => <AddTransponder {...props} />} />
  </NotFoundWrapper>
)

export default TransponderRouter
