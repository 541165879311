import React from 'react'
import Modal from './Modal'
import ConfirmationDialog from './ConfirmationDialog'
import ConfirmationDialogSafeDelete from './ConfirmationDialogSafeDelete'
import { fixBody, unFixBody } from '../common/Utils'
import _ from 'lodash'

export const ModalContext = React.createContext()

export class ModalProvider extends React.Component {
  constructor (props) {
    super(props)

    this.defaultState = {
      Component: () => (<></>),
      componentProps: {},
      confirmationDialogProps: {},
      show: false
    }
    this.state = { ...this.defaultState, originalFixer: false }

    this.modal = React.createRef()
    this.modalComponent = Modal
  }

  setOriginalFixer (newValue) {
    this.setState({ originalFixer: newValue })
  }

  // Make sure that the dialog doesn't change the vertical scroll position,
  // and that the background of the modal dialog is not scrollable.
  fixTheBody () {
    fixBody(this.setOriginalFixer.bind(this))
  }

  unFixTheBody () {
    unFixBody(this.state.originalFixer, this.setOriginalFixer.bind(this))
  }

  handleSetComponent (component, props = {}) {
    this.modalComponent = Modal
    this.setState({ Component: component, componentProps: props, show: true }, this.fixTheBody.bind(this))
  }

  handleSetConfirmationDialog (props = {}) {
    this.modalComponent = ConfirmationDialog
    this.setState({ confirmationDialogProps: props, show: true }, this.fixTheBody.bind(this))
  }

  handleSetConfirmationDialogSafeDelete (props = {}) {
    this.modalComponent = ConfirmationDialogSafeDelete
    this.setState({ confirmationDialogProps: props, show: true }, this.fixTheBody.bind(this))
  }

  handleShow () {
    this.setState({ ...this.state, show: true }, this.fixTheBody.bind(this))
  }

  handleHide () {
    _.get(this, 'state.componentProps.closeCallback') && this.state.componentProps.closeCallback()
    this.setState(this.defaultState, this.unFixTheBody.bind(this))
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.unFixTheBody()
  }

  render () {
    const { Component, componentProps, show } = this.state
    return (
      <ModalContext.Provider value={{
        setModalComponent: this.handleSetComponent.bind(this),
        show: this.handleShow.bind(this),
        hide: this.handleHide.bind(this),
        setConfirmationDialog: this.handleSetConfirmationDialog.bind(this),
        setConfirmationDialogSafeDelete: this.handleSetConfirmationDialogSafeDelete.bind(this)
      }}
      >
        {this.props.children}
        {show && (
          <this.modalComponent
            show={this.state.show}
            ref={this.modal} onClose={this.handleHide.bind(this)}
            {...this.state.confirmationDialogProps}
          >
            <Component {...componentProps} />
          </this.modalComponent>)}
      </ModalContext.Provider>
    )
  }
}

export const WithModal = Component => props => {
  return (
    <ModalContext.Consumer>
      {(context) => (<Component {...context} {...props} />)}
    </ModalContext.Consumer>
  )
}
