import Highcharts from 'highcharts'
import I18n from 'i18n'
import { hcGreen, hcRed, MAX_HR, MAX_SLEEP_DURATION } from 'components/common/Constants'

/**
 * Generates Y-Axis options for a Highcharts graph based on the parameters provided.
 *
 * @param withRestingHr - A flag to indicate if the Y-Axis should include options related to resting heart rate.
 * @param withSleepDuration - A flag to indicate if the Y-Axis should include options related to sleep duration.
 *
 * @returns {Object} An object containing:
 *  - yAxisOptions: Array of Highcharts.YAxisOptions for configuring the Y-Axis of a graph.
 *  - restingHrIndex (optional): The index in the yAxisOptions array where the resting heart rate options were added. This is useful for data series binding.
 *  - sleepDurationIndex (optional): The index in the yAxisOptions array where the sleep duration options were added. This is useful for data series binding.
 *
 * Note: The function dynamically builds the yAxisOptions array. If 'withRestingHr' is true, it appends the resting heart rate-related options at the end
 * of the array and returns its index. Similarly, if 'withSleepDuration' is true, it appends the sleep duration-related options at the end and returns its index.
 * The indexes are particularly useful when binding data series to specific Y-Axis.
 */
export const getYAxisOptions = (withRestingHr: boolean | undefined, withSleepDuration: boolean | undefined): { yAxisOptions: Highcharts.YAxisOptions[], restingHrOptionsIndex?: number, sleepDurationOptionsIndex?: number } => {
  const yAxisOptions: Highcharts.YAxisOptions[] = [{
    title: {
      text: I18n.t('components.dashboards.questionnaire.daily_wellness.yaxis')
    },
    min: -9,
    max: 100,
    tickInterval: 10,
    startOnTick: false,
    plotBands: [{
      color: 'rgba(0,0,0, 0.3)',
      borderColor: 'black',
      borderWidth: 0.5,
      from: -7.5,
      to: -2.5,
      label: {
        text: ''
      }
    }]
  }]

  let restingHrOptionsIndex
  if (withRestingHr === true) {
    restingHrOptionsIndex = yAxisOptions.length
    yAxisOptions.push(
      {
        title: {
          text: I18n.t('components.dashboards.questionnaire.daily_wellness.resting_hr.yaxis'),
          style: {
            color: hcRed
          }
        },
        labels: {
          style: {
            color: hcRed
          }
        },
        min: -24,
        softMax: MAX_HR,
        tickInterval: 25,
        startOnTick: false,
        opposite: true
      }
    )
  }
  let sleepDurationOptionsIndex
  if (withSleepDuration === true) {
    sleepDurationOptionsIndex = yAxisOptions.length
    yAxisOptions.push(
      {
        title: {
          text: I18n.t('components.dashboards.questionnaire.daily_wellness.sleep_duration.yaxis'),
          style: {
            color: hcGreen
          }
        },
        labels: {
          style: {
            color: hcGreen
          }
        },
        min: -0.9,
        softMax: MAX_SLEEP_DURATION,
        tickInterval: 1,
        startOnTick: false,
        opposite: true
      }
    )
  }

  return {
    yAxisOptions,
    restingHrOptionsIndex,
    sleepDurationOptionsIndex
  }
}
