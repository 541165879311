import React from 'react'
import { defaultPlayerAvatarRadius } from '../../../../common/Constants'

export default class PlayerAvatar extends React.Component {
  render () {
    const { x, y, radius = defaultPlayerAvatarRadius, number, color: { color, labelColor }, id } = this.props
    return (
      <g id={id} key={id} className='player-avatar'>
        <circle cx={x} cy={y} r={radius} fill={color} />
        <text x={x} y={y} textAnchor='middle' alignmentBaseline='middle' fill={labelColor} dy='0.2rem'>{number}</text>
      </g>
    )
  }
}
