import React from 'react'
import I18n from 'i18n'

// TODO: Use the common/Toggle here
export default class DataSharingToggle extends React.Component {
  render () {
    return (
      <div>
        <div className='toggle'>
          <div className='row'>
            <div className='col s12 valign-wrapper'>
              <label htmlFor='toggle-sharing' className='switch'>
                <input
                  type='checkbox'
                  id='toggle-sharing'
                  name='toggle-sharing'
                  checked={!this.props.isChecked}
                  onChange={this.props.onChange}
                />
                <div className='slider' />
              </label>
              <span>
                <h5 className='text-primary muted-header'>
                  {I18n.t('datasharing.labels.toggle.disable')}
                </h5>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
